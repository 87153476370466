/* eslint-disable max-len */

import * as R from "ramda";
import { setToPath } from "../../../utils/ramdaEx";
import * as mp from "../../../mappers/path";
import * as selectors from "../../Workspace/epics/stylesheets/selectors";
import * as backgroundUpdaters from "../../../setters/backgroundSetter";
import * as gradientUpdaters from "../../../setters/gradientSetter";
import * as textUpdaters from "../../../setters/textSetter";
import { TABLE_STYLE_CHANGED } from "../../Workspace/epics/stylesheets/updateReasons";
import { opacityToAlpha } from "../../oneweb/Background/utils/index";
import TableGlobalStyleKind from '../../oneweb/Table/globalStyle/kind';
import { DEFAULT_SHADOW } from '../../oneweb/Text/constants';
import { cellSpacingValidator } from "../../oneweb/Table/validators";
import type { TableStylesheetEpicUpdater } from '../flowTypes';
import type { Path } from "../../../mappers/path";

type TableStylesheetAction = {
    action: string,
    ref: string
}
type EpicUpdaterFactory = (actionTypes: Array<TableStylesheetAction>) => Array<TableStylesheetEpicUpdater>

type ValueExtractor = <T extends any>(arg: T[]) => T;

type Validator = (AnyValue) => boolean;

const
    getStyleToUpdate = (stylesheets, ref) => R.pipe(
        selectors.getAllStylesheets,
        selectors.getStylesByType(TableGlobalStyleKind),
        selectors.getStyleByRef(ref)
    )(stylesheets),
    getStyleIndex = style => R.pipe(R.prop('styles'), R.indexOf(style)),
    updateStyle = (oldStyle, newStyle, styles) => setToPath(['styles', getStyleIndex(oldStyle)(styles)], newStyle, styles),
    updateGlobalstyles = (stylesheets, ref, path, value) => {
        const
            style = getStyleToUpdate(stylesheets, ref),
            updatedStyle = setToPath(path, value, style);

        return updateStyle(style, updatedStyle, stylesheets);
    },
    toggleFontStyle = (stylesheets, ref, toggleUpdater) => {
        const
            style = getStyleToUpdate(stylesheets, ref),
            updatedStyle = toggleUpdater([mp.text], style);

        return updateStyle(style, updatedStyle, stylesheets);
    },
    updateGlobalstylesShadow = (stylesheets, ref, shadowUpdate) => {
        const
            style = getStyleToUpdate(stylesheets, ref),
            updatedStyle = R.evolve({
                text: {
                    shadow: (shadow) => (!R.isNil(shadowUpdate) ? ({ ...DEFAULT_SHADOW, ...shadow, ...shadowUpdate }) : null)
                }
            }, style);

        return updateStyle(style, updatedStyle, stylesheets);
    },
    updateGlobalstylesBackground = (stylesheets, ref, updaterFn, data) => {
        const style = getStyleToUpdate(stylesheets, ref),
            updatedStyle = updaterFn(mp.blockBackground, data, style);

        return updateStyle(style, updatedStyle, stylesheets);
    },
    makePropUpdater = (valueExtractor: ValueExtractor, pathToUpdate: Path, validator?: Validator) => R.map(
        ({ action, ref }) => ({
            conditions: [action],
            reducer: ({ values, state }) => {
                const valueToUpdate = valueExtractor(values);

                if (validator && !validator(valueToUpdate)) {
                    return { state };
                }

                return {
                    state: updateGlobalstyles(state, ref, pathToUpdate, valueToUpdate),
                    updateReason: TABLE_STYLE_CHANGED
                };
            }
        })
    ),
    makeTogglePropUpdater = (updaterFn) => R.map(
        ({ action, ref }) => ({
            conditions: [action],
            reducer: ({ state }) => ({
                state: toggleFontStyle(state, ref, updaterFn),
                updateReason: TABLE_STYLE_CHANGED
            })
        })
    ),
    makeShadowUpdater = (valueExtractor) => R.map(
        ({ action, ref }) => ({
            conditions: [action],
            reducer: ({ values, state }) => ({
                state: updateGlobalstylesShadow(state, ref, valueExtractor(values)),
                updateReason: TABLE_STYLE_CHANGED
            })
        })
    ),
    makeBackgroundUpdater = (valueExtractor, valueUpdater) => R.map(
        ({ action, ref }) => ({
            conditions: [action],
            reducer: ({ values, state }) => ({
                state: updateGlobalstylesBackground(state, ref, valueUpdater, valueExtractor(values)),
                updateReason: TABLE_STYLE_CHANGED
            })
        })
    ),
    extractColor = R.pipe(R.head, R.prop(mp.color)),
    extractAsset = R.pipe(R.head, R.prop(mp.asset)),
    extractNull = R.always(null);

export const
    googleFontEpicUpdater: EpicUpdaterFactory = actions => actions.map(({ action }) => ({
        conditions: [action],
        reducer: ({ values: [{ googleFont, additionalPayload }], state: stylesheets }) => {
            if (additionalPayload && additionalPayload.source === TableGlobalStyleKind) {
                return {
                    state: updateGlobalstyles(stylesheets, additionalPayload.ref, mp.textFont, googleFont),
                    updateReason: TABLE_STYLE_CHANGED
                };
            }

            return { state: stylesheets };
        }
    })), /**/

    getFontFamilyEpicUpdaters = makePropUpdater(R.head, mp.textFont),
    getFontSizeEpicUpdaters = makePropUpdater(R.head, mp.textSize),

    getFontBoldToggleEpicUpdaters = makeTogglePropUpdater(textUpdaters.toggleBold),
    getFontItalicToggleEpicUpdaters = makeTogglePropUpdater(textUpdaters.toggleItalic),
    getFontUnderlineToggleEpicUpdaters = makeTogglePropUpdater(textUpdaters.toggleUnderline),

    getHorizontalAlignmentEpicUpdaters = makePropUpdater(R.head, [mp.horizontalAlign]),
    getVerticalAlignmentEpicUpdaters = makePropUpdater(R.head, [mp.verticalAlign]),

    getBorderStyleEpicUpdaters = makePropUpdater(R.head, mp.blockBorderStyle),
    getBorderColorEpicUpdaters = makePropUpdater(extractColor, mp.blockBorderColor),
    getBorderColorOpacityEpicUpdaters = makePropUpdater(R.pipe(R.head, opacityToAlpha), mp.blockBorderColorOpacity),

    getCellSpacingEpicUpdaters = makePropUpdater(R.head, mp.blockPadding, cellSpacingValidator),

    getTextShadowColorUpdateEpicUpdaters = makeShadowUpdater(R.pipe(extractColor, color => ({ color }))),
    getTextShadowColorRemoveEpicUpdaters = makeShadowUpdater(extractNull),
    getTextShadowBlurRadiusChangeEpicUpdaters = makeShadowUpdater(R.pipe(R.head, blur => ({ blur }))),
    getTextShadowHorizontalOffsetChangeEpicUpdaters = makeShadowUpdater(R.pipe(R.head, left => ({ left }))),
    getTextShadowVerticalOffsetChangeEpicUpdaters = makeShadowUpdater(R.pipe(R.head, top => ({ top }))),

    getBackgroundColorEpicUpdaters = makeBackgroundUpdater(extractColor, backgroundUpdaters.setBackgroundSolidColor),
    getBackgroundColorRemoveEpicUpdaters = makeBackgroundUpdater(extractNull, backgroundUpdaters.setBackgroundSolidColor),
    getBackgroundOpacityEpicUpdaters = makeBackgroundUpdater(R.head, backgroundUpdaters.setBackgroundOpacity),
    getBackgroundGradientColorEpicUpdaters = makeBackgroundUpdater(extractColor, backgroundUpdaters.setBackgroundGradientColor),
    getBackgroundGradientColorRemoveEpicUpdaters = makeBackgroundUpdater(extractNull, backgroundUpdaters.setBackgroundGradientColor),
    getBackgroundGradientDirectionUpdateEpicUpdaters = makeBackgroundUpdater(R.head, gradientUpdaters.setGradientDirectionCorrect),
    getBackgroundGradientFadePointUpdateEpicUpdaters = makeBackgroundUpdater(R.head, gradientUpdaters.setGradientFadePointCorrect),
    getBackgroundAssetEpicUpdaters = makeBackgroundUpdater(extractAsset, backgroundUpdaters.setAsset),
    getBackgroundAssetRemoveEpicUpdater = makeBackgroundUpdater(extractNull, backgroundUpdaters.setAsset),
    getBackgroundAssetRepeatChangeEpicUpdaters = makeBackgroundUpdater(R.head, backgroundUpdaters.setAssetRepeat),
    getBackgroundAssetPositionChangeEpicUpdaters = makeBackgroundUpdater(R.head, backgroundUpdaters.setAssetPosition),
    getBackgroundAssetSizeChangeEpicUpdaters = makeBackgroundUpdater(R.head, backgroundUpdaters.setAssetSize),

    getTextColorEpicUpdaters = makePropUpdater(extractColor, mp.textColor),
    getTextHighlightColorEpicUpdaters = makePropUpdater(extractColor, mp.textHighlight),
    getTextHighlightColorRemoveEpicUpdaters = makePropUpdater(extractNull, mp.textHighlight);
