const { ConfigComponentList } = require("../constants.js");

class ContactFormConfig {
    isContactFormCaptchaEnabled() {
        const contactForm = this._featuresConfig[ConfigComponentList.CONTACT_FORM];
        return contactForm && contactForm.captcha && contactForm.captcha.enabled;
    }

    isContactFormFileUploadEnabled() {
        const contactForm = this./*::#*/_featuresConfig[ConfigComponentList.CONTACT_FORM];
        return contactForm && contactForm.fileUpload && contactForm.fileUpload.enabled;
    }
}

module.exports = ContactFormConfig;
