import { Strips } from "../../../oneweb/Background/extendedPanel/strips/strips";
import { Boxes } from "../../../oneweb/Background/extendedPanel/boxes/boxes";
import { Circles } from "../../../oneweb/Background/extendedPanel/circles/circles";
import { HoverBoxes } from "../../../oneweb/HoverBox/extendedPanel/hoverBox";

export const ALL_CONTAINERS_FILTER_KEY = "all",
    STRIPS = "strips",
    BOXES = "boxes",
    CIRCLES = "circles",
    HOVER_BOX = "hover-box";

export const CONTAINERS_FILTERS = [
    {
        id: ALL_CONTAINERS_FILTER_KEY,
        title: "msg: common.blocks.all {All}"
    },
    {
        id: STRIPS,
        showStylesheetStyles: true,
        title: "msg: panel.containers.filter.strips {Strips}"
    },
    {
        id: BOXES,
        title: "msg: panel.containers.filter.boxes {Boxes}"
    },
    {
        id: CIRCLES,
        title: "msg: panel.containers.filter.circles {Circles}"
    },
    {
        id: HOVER_BOX,
        title: "msg: panel.containers.filter.hoverBox {Hoverbox}"
    }
];

export const ContainersExtendedPanelViews = {
    [STRIPS]: Strips,
    [BOXES]: Boxes,
    [CIRCLES]: Circles,
    [HOVER_BOX]: HoverBoxes,
};
