import React from "react";
import cx from "classnames";
import styles from "./ForgotPassword.css";
import demoPageStyles from "../../main/demoLoginPage/DemoLoginPage.css";
import { DemoLoginMessages } from "../../main/messages";
import { isInvalidEmail, isCompactScreen } from '../../validators';
import Button from "../../../../../src/view/common/Button/Button";
import { demoForgotPasswordEmailAction,
    updateEmailValue,
    cleanUpAllErrors,
    showInvalidEmailError,
    cleanUpForgotPasswordScreen,
    forgotPasswordLoading,
    showResendVerificationEmailSent } from "../actions";
import { showSignInPage } from "../../signUp/actions";
import VerticalSpacer from "../../../../../src/view/common/VerticalSpacer";
import type { ForgotPasswordPagePropTypes } from "../../types";
import { demoResendConfirmationCodeAction } from "../../signIn/actions";
import { normalizeEmail } from "../../../../../../server/shared/utils/normalizeEmail.js";
import { TrialAccountCombo } from "../../common/TrialAccountCombo";
import { getWbtgenTrialHostClientMetadata } from "../../common/getWbtgenTrialHostClientMetadata";

const ForgotPassword = ({
    intl,
    dispatch,
    loading,
    email,
    error,
    expiredPasswordLinkError,
    userNotExistsError,
    isResetEmailSuccessPage,
    invalidEmailErrorMessage,
    isResetEmailPage,
    limitExceededError,
    isResendVerificationEmailSent
}: ForgotPasswordPagePropTypes) => {
    const { invalidEmailCredentialsError,
            expiredLinkErrorMsg,
            resetEmailHeaderLabel,
            resetEmailSuccessHeaderLabel,
            resetEmailHeaderMsg,
            resetEmailSuccessHeaderMsg,
            resetEmailBodyMsg,
            customError,
            checkInboxLabel,
            checkInboxMsg,
            returnToLoginLabel,
            resetPasswordLabel,
            emailVerificationReminderMessage,
            emailVerificationSentMessage,
            sendVerificationEmailMessage,
            attempLimitExceedsError } = DemoLoginMessages,
        accountRef = React.createRef<any>(),

        getVerticalSpacerValues = () => {
            let compactScreen = isCompactScreen();
            let
                VERTICAL_SPACE_BEFORE_EMAIL = compactScreen ? 20 : 49,
                VERTICAL_SPACE_BEFORE_RETURN_TO_LOGIN = compactScreen ? 125 : 247,
                VERTICAL_SPACE_BEFORE_RESET_PASSWORD = compactScreen ? 44 : 192,
                VERTICAL_SPACE_BEFORE_EMAIL_VERIFICATION_REMINDER = 33;

            return {
                VERTICAL_SPACE_BEFORE_EMAIL,
                VERTICAL_SPACE_BEFORE_RETURN_TO_LOGIN,
                VERTICAL_SPACE_BEFORE_RESET_PASSWORD,
                VERTICAL_SPACE_BEFORE_EMAIL_VERIFICATION_REMINDER
            };
        },
        onEmailChange = (updatedEmailValue) => {
            dispatch(cleanUpAllErrors());
            dispatch(updateEmailValue(updatedEmailValue));
        };

    const {
        VERTICAL_SPACE_BEFORE_EMAIL,
        VERTICAL_SPACE_BEFORE_RESET_PASSWORD,
        VERTICAL_SPACE_BEFORE_RETURN_TO_LOGIN,
        VERTICAL_SPACE_BEFORE_EMAIL_VERIFICATION_REMINDER
    } = getVerticalSpacerValues();

    const forgotPasswordPage = () => {
        const
            scanForErrors = () => {
                if (!email || isInvalidEmail(email)) {
                    dispatch(showInvalidEmailError());
                } else {
                    return true;
                }
                return false;
            },
            onAccountChange = username => {
                if (scanForErrors()) {
                    dispatch(forgotPasswordLoading());
                    dispatch(demoForgotPasswordEmailAction(username, getWbtgenTrialHostClientMetadata()));
                }
            },
            initiateForgotPassword = () => {
                accountRef.current.dispatch();
            },
            initiateResendVerificationEmail = () => {
                if (scanForErrors()) {
                    dispatch(demoResendConfirmationCodeAction(normalizeEmail(email)));
                    dispatch(showResendVerificationEmailSent());
                }
            };

        const isInvalidEmailError = (invalidEmailErrorMessage || (email && isInvalidEmail(email)));
        const isError = (userNotExistsError || error || limitExceededError);

        return (
            <React.Fragment>
                <div>
                    <div className={styles.headerLabel}>
                        {intl.msgJoint(resetEmailHeaderLabel)}
                    </div>
                    <div className={styles.headerMsg}>
                        {intl.msgJoint(resetEmailHeaderMsg)}
                    </div>
                </div>
                <VerticalSpacer y={VERTICAL_SPACE_BEFORE_EMAIL} />
                <div>
                    <div className={styles.emailContainer}>
                        <TrialAccountCombo
                            onChange={onAccountChange}
                            onEmailChange={onEmailChange}
                            onEmailEnterPress={initiateForgotPassword}
                            ref={accountRef}
                        />
                        <VerticalSpacer y={VERTICAL_SPACE_BEFORE_EMAIL_VERIFICATION_REMINDER} />

                        {
                            isError &&
                            <span className={styles.invalidEmailError}>
                                {userNotExistsError && intl.msgJoint(invalidEmailCredentialsError)}
                                {limitExceededError && intl.msgJoint(attempLimitExceedsError)}
                                {error && intl.msgJoint(customError)}
                            </span>
                        }
                        {

                            !(isInvalidEmailError || isError) && email && <span className={styles.passwordResetLinkMsg}>
                                {intl.msgJoint(resetEmailBodyMsg)}
                            </span>
                        }

                        {
                            (expiredPasswordLinkError) &&
                            <div className={styles.expiredLinkLabelContainer}>
                                <span className={styles.exclamationIcon} />
                                <span>
                                    {intl.msgJoint(expiredLinkErrorMsg)}
                                </span>
                            </div>
                        }

                        <div className={styles.emailVerificationReminderMessageContainer}>
                            <div className={styles.lightBulbIcon} />
                            <div className={styles.emailVerificationReminderMessage}>
                                <span>
                                    {!isResendVerificationEmailSent ?
                                        intl.msgJoint(emailVerificationReminderMessage) :
                                        intl.msgJoint(emailVerificationSentMessage)}
                                    &nbsp;
                                </span>
                                <span
                                    className={styles.sendVerificationEmailMessage}
                                    onClick={initiateResendVerificationEmail}
                                >
                                    {intl.msgJoint(sendVerificationEmailMessage)}
                                </span>

                            </div>
                        </div>
                    </div>
                </div>
                <VerticalSpacer y={VERTICAL_SPACE_BEFORE_RESET_PASSWORD} />
                <div>
                    <div className={styles.resetEmailfooterContainer}>
                        <Button
                            onClick={initiateForgotPassword}
                            className={cx(styles.resetPasswordButton, {
                                [styles.loadingButton]: loading
                            })}
                        >
                            {loading && <div className={demoPageStyles.loader} />}
                            {!loading && intl.msgJoint(resetPasswordLabel)}
                        </Button>
                        <div
                            onClick={() => {
                                dispatch(cleanUpForgotPasswordScreen());
                                dispatch(showSignInPage());
                            }}
                            className={styles.returnToLoginLabel}
                        >
                            {intl.msgJoint(returnToLoginLabel)}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    };

    const forgotPasswordSuccessPage = () => {
        return (
            <React.Fragment>
                <div>
                    <div className={styles.headerLabel}>
                        {intl.msgJoint(resetEmailSuccessHeaderLabel)}!
                    </div>
                    <div className={styles.headerMsg}>
                        <div>
                            {intl.msgJoint(resetEmailSuccessHeaderMsg)}...
                        </div>
                    </div>
                </div>
                <VerticalSpacer y={VERTICAL_SPACE_BEFORE_EMAIL} />
                <div>
                    <div>
                        <div className={styles.checkInboxLabelContainer}>
                            <span className={styles.checkBoxIcon} />
                            <span className={styles.checkInboxLabel}>
                                {intl.msgJoint(checkInboxLabel)}
                            </span>
                        </div>
                        <div className={styles.checkInboxMsg}>
                            {intl.msgJoint(checkInboxMsg)}
                        </div>
                    </div>
                </div>
                <VerticalSpacer y={VERTICAL_SPACE_BEFORE_RETURN_TO_LOGIN} />
                <div>
                    <div className={styles.returnToLoginContainer}>
                        <Button
                            onClick={() => {
                                dispatch(cleanUpForgotPasswordScreen());
                                dispatch(showSignInPage());
                            }}
                            className={styles.resetPasswordButton}
                        >
                            {intl.msgJoint(returnToLoginLabel)}
                        </Button>
                    </div>
                </div>
            </React.Fragment>
        );
    };
    return (
        <div>
            {isResetEmailPage && forgotPasswordPage()}
            {isResetEmailSuccessPage && forgotPasswordSuccessPage()}
        </div>
    );
};
export {
    ForgotPassword
};
