import { isSeamlessUpgradeDisabledByConfig } from "../../../../wbtgen/src/components/ComponentTierManager/utils";
import { getAppConfig } from '../../../../wbtgen/src/components/App/epics/appConfig/appConfig.js';
import AppConfig from '../../../../wbtgen/src/utils/AppConfig';
import { PartnerName } from '../../../../server/shared/partners/PartnerName.js';

const appConfig = AppConfig(getAppConfig());

export const partnerName = appConfig.get('partnerName');
export const isPartnerHostnet = partnerName === PartnerName.HOSTNET;

export const isFreeUpgradeAllowed = !(isSeamlessUpgradeDisabledByConfig());

export const htmlTheme: string = appConfig.get("oneWeb.frontEnd.partnerConfig.htmlTheme");

export const isShopInAllTiersEnabled = appConfig.getOptional("oneWeb.webshop.features.enableShopInAllTier");
