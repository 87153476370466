import makeEpic from '../../../../epics/makeEpic';
import { withSelector, receiveOnly } from "../../../../epics/makeCondition";
import valueActionType from './valueActionType';
import {
    SITE_SUBSCRIPTION_DATA_LOAD_SUCCESS,
    UPGRADE_SUCCESS,
    SITE_SUBSCRIPTION_BACKUP_RESTORE_DATA_LOAD_SUCCESS,
    SITE_SUBSCRIPTION_METADATA_LOAD_SUCCESS_ACTION,
} from "../../actionTypes";
import {
    loadSubscriptionData,
    loadSubscriptionStatusBackupRestoreData,
    loadSubscriptionMetadataAction,
} from "../../actionCreators/index";
import { SEAMLESS_UPGRADE_SUCCESS } from "../../../TopBar/actionTypes";
import { SubscriptionTypes } from "../../../../constants/app";
import { AUTH_COOKIE_EXISTS } from "../auth/actionTypes";
import makeStateSelectorReducer from "../../../../epics/makeStateSelectorReducer";
import type { SubscriptionData } from "../../../../redux/modules/flowTypes";
import { addChatTagWSBTrialAC } from "../../../TopBar/epics/chat/actionCreators";
import { chatTags } from "../../../TopBar/epics/chat/constants";
import { isTrialSubscription, isBasicSubscription, isNormalSubscription, isPremiumSubscription, isEcommerceSubscription } from "./utils";
import { default as UpgradeValueVAT } from '../../../TopBar/epics/upgrade/valueActionType';
import { default as ComponentTierManagerVAT } from '../../../ComponentTierManager/epics/valueActionType';
import { isBackupRestoreAddOnDisabledByConfig } from '../../../ComponentTierManager/utils';
import { upgradeCompletedAction } from '../../../../../../src/dashboard/src/components/Main/UpgradePlansDialog/actions';
import getAccessManager from '../../../../getAccessManager';

const
    upgradeSuccessUpdaters = [UPGRADE_SUCCESS, SEAMLESS_UPGRADE_SUCCESS]
        .map((condition) => ({
            conditions: [
                receiveOnly(UpgradeValueVAT),
                receiveOnly(ComponentTierManagerVAT),
                condition
            ],
            // Change it to make call for subscriptionType after upgardation. Also needs to handle logic for free Upgrade
            reducer: ({ values: [{ selectedUpgradeType }, { freeTierUpgradeSubscriptionType }], state }) => {
                let updatedSubscriptionType = selectedUpgradeType || freeTierUpgradeSubscriptionType;
                getAccessManager().setCurrentSubscription(updatedSubscriptionType);
                return {
                    state: {
                        ...state,
                        subscriptionType: updatedSubscriptionType
                    },
                    multipleActionsToDispatch: [loadSubscriptionData(), upgradeCompletedAction({
                        fromSubscriptionType: state.subscriptionType,
                        toSubscriptionType: updatedSubscriptionType
                    })]
                };
            }
        })),
    backupRestoreAddOnDisabledByConfig = isBackupRestoreAddOnDisabledByConfig(),
    epicConfig = {
        defaultState: {
            subscriptionType: SubscriptionTypes.UNKNOWN, // non premium is default state for now
            metadata: null,
        },
        valueActionType,
        updaters: [
            {
                conditions: [AUTH_COOKIE_EXISTS],
                reducer: ({ state }) => {
                    return {
                        state,
                        multipleActionsToDispatch: [loadSubscriptionMetadataAction(), loadSubscriptionData()],
                    };
                }
            },
            {
                conditions: [SITE_SUBSCRIPTION_METADATA_LOAD_SUCCESS_ACTION],
                reducer: ({ values: [{ metadata }], state }) => {
                    return {
                        state: { ...state, metadata },
                    };
                }
            },
            {
                conditions: [SITE_SUBSCRIPTION_DATA_LOAD_SUCCESS],
                reducer: ({ values: [subscriptionData], state }) => {
                    getAccessManager().setCurrentSubscription(subscriptionData.subscriptionType);
                    const
                        subscriptionStatusData: SubscriptionData = subscriptionData,
                        multipleActionsToDispatch: Action[] = [];

                    if (isTrialSubscription(subscriptionData.subscriptionType)
                        || isBasicSubscription(subscriptionData.subscriptionType)
                        || isNormalSubscription(subscriptionData.subscriptionType)
                    ) {
                        if (!backupRestoreAddOnDisabledByConfig) {
                            // Request server to check if user has subscription for Backup & Restore
                            multipleActionsToDispatch.push(loadSubscriptionStatusBackupRestoreData());
                        }
                    } else {
                        // Backup & Restore is packaged with PREMIUM & B+E subscription
                        multipleActionsToDispatch.push({
                            type: SITE_SUBSCRIPTION_BACKUP_RESTORE_DATA_LOAD_SUCCESS,
                            payload: { subscribed: true }
                        });
                    }

                    if (isTrialSubscription(subscriptionStatusData.subscriptionType)) {
                        multipleActionsToDispatch.push(addChatTagWSBTrialAC(chatTags.WSB_TRIAL));
                    }

                    return {
                        state: { ...state, ...subscriptionStatusData },
                        multipleActionsToDispatch
                    };
                }
            },
            ...upgradeSuccessUpdaters
        ]
    },
    epic = makeEpic(epicConfig),
    subscriptionTypeSelector = withSelector(valueActionType, state => state.subscriptionType),
    isPremiumUserReducer = makeStateSelectorReducer(
        epic.reducer,
        valueActionType,
        ({ subscriptionType }) => isPremiumSubscription(subscriptionType)
    ),
    isECommerceUserReducer = makeStateSelectorReducer(
        epic.reducer,
        valueActionType,
        ({ subscriptionType }) => isEcommerceSubscription(subscriptionType)
    );

export {
    epic as default,
    subscriptionTypeSelector,
    isPremiumUserReducer,
    isECommerceUserReducer,
};

