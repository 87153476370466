import { applyMappers, makePlainMappers } from "../../utils";
import type { AnyComponentData } from "../../../../src/components/App/flowTypes";
import { BookingsComponent } from "../../../../src/components/oneweb/Bookings/flowTypes";

const plainPropsMapper = makePlainMappers({
    mobileHide: "mobileHide",
    fontFamily: "fontFamily",
    fontColor: "fontColor",
    fontColorTheme: "fontColorTheme",
    titleFontColor: "titleFontColor",
    titleFontColorTheme: "titleFontColorTheme",
    buttonThemeSelected: "buttonThemeSelected",
    buttonStyleId: "buttonStyleId",
    buttonText: "buttonText"
});

export function to(componentData: AnyComponentData): BookingsComponent {
    return applyMappers(
        componentData,
        plainPropsMapper.to
    );
}

export function back(component: BookingsComponent): AnyComponentData {
    return applyMappers(
        component,
        plainPropsMapper.back
    );
}
