import React, { useState } from "react";
import { AIInputFieldId } from "../types";
import { MAX_INPUT_LENGTH, MAX_TEXTAREA_LENGTH } from "../constants";
import styles from "../OnboardingWizard.css";

interface Props {
    inputId: AIInputFieldId;
    inputValue: string;
    label: string;
    onInputChange: (inputId: AIInputFieldId, value: string) => void;
    multiline?: boolean;
}

export const AIInputField = ({
    inputId,
    inputValue,
    label,
    onInputChange,
    multiline = false
}: Props) => {
    const [isFocused, setFocused] = useState<boolean>(false);

    const handleTextInput = (input: HTMLInputElement | HTMLTextAreaElement) => {
        const inputValue = input.value;

        // a visible character needs to be entered
        // trim the value if it only consists of spaces
        let sanitizedValue = inputValue;
        if (!inputValue.trim().length) {
            sanitizedValue = inputValue.trim();
        }

        // limit input to the max length
        sanitizedValue = sanitizedValue.substring(
            0,
            multiline ? MAX_TEXTAREA_LENGTH : MAX_INPUT_LENGTH
        );

        onInputChange(inputId, sanitizedValue);
    };

    const handleFocus = () => {
        setFocused(true);
    };

    const handleBlur = () => {
        setFocused(false);
    };

    return (
        <div className={styles.inputContainer} data-testid="input-container">
            <label htmlFor={inputId}>{label}</label>
            {multiline ? (
                <textarea
                    id={inputId}
                    value={inputValue}
                    onChange={e => handleTextInput(e.target)}
                    onFocus={() => handleFocus()}
                    onBlur={() => handleBlur()}
                    data-testid={"input-field-" + inputId}
                    autoComplete="off"
                />
            ) : (
                <input
                    type="text"
                    id={inputId}
                    value={inputValue}
                    onChange={e => handleTextInput(e.target)}
                    onFocus={() => handleFocus()}
                    onBlur={() => handleBlur()}
                    data-testid={"input-field-" + inputId}
                    autoComplete="off"
                />
            )}
            {isFocused && (
                <div className={styles.characterCounter} data-testid="character-counter">
                    {inputValue.length}/{multiline ? MAX_TEXTAREA_LENGTH : MAX_INPUT_LENGTH}
                </div>
            )}
        </div>
    );
};
