import { put, take, takeLatest } from "redux-saga/effects"; // eslint-disable-line n/file-extension-in-import
import Resource from '../../Resource';
import uploadFromUrlAction from '../../../../../../components/App/actionCreators/uploadFromUrl';
import validateImageResourceSaga from "./validateImageResourceSaga";
import reloadResourcesSaga from "../reloadResourcesSaga";
import * as ActionTypes from "../../actionTypes";
import removeResourceByName from "../../utils/removeResourceByName";
import { FailedErrorDialog, InsufficientStorageErrorDialog } from "../../../../../../view/common/FileChooser/dialogIds";
import resolveFileNameConflictSaga from "./resolveFileNameConflictSaga";
import preUploadSaga from "./preUploadSaga";
import buildWebSpacePathGen from "../../utils/buildWebSpacePathGen";
import getResourcesGen from "./utils/getResourcesGen";
import { openDialogGen, raceGen, closeDialogGen } from "../../../../../../utils/saga/index";
import resetResourcesSaga from "./resetResourcesSaga";
import fcToggleResourceSelection from "../../actionCreators/fcToggleResourceSelection";
import Url from "../../../../../../../utils/Url";
import { extname } from '../../../../../../../utils/path.js';
import { reencodeWebspaceUri } from '../../../../../../../dal/utils/webspaceUriTransformers';
import { DalErrorName } from "../../../../../../../dal/constants";

export const getResourceName = (url: string): string => {
    const urlData = Url(url);
    return Resource.getBaseName(urlData.pathname);
};

export const validateUrlProtocol = (url: string): string => {
    const urlData = Url(url);
    return urlData.protocol ? url : 'http://' + url;
};

export default function* (): any {
    yield takeLatest(ActionTypes.FC_URL_UPLOAD, function* (action: Action<any>) {
        // close url dialog
        yield* closeDialogGen();

        let url = action.payload.url;
        url = validateUrlProtocol(url);
        let resourceName = reencodeWebspaceUri(getResourceName(url));

        // check file name conflict
        const conflict = yield* resolveFileNameConflictSaga(resourceName);
        if (conflict) {
            if (conflict.close) return; // upload canceled
            else if (conflict.rename) resourceName = conflict.rename;
        }

        // do upload
        yield* preUploadSaga(resourceName, `image/${extname(resourceName)}`);
        const resourcePath = yield* buildWebSpacePathGen(resourceName);
        // @ts-ignore
        yield put(uploadFromUrlAction(url, resourcePath, ActionTypes.FC_URL_UPLOAD_ACTION_TYPES));

        const { success, failure } = yield* raceGen({
            success: take(ActionTypes.FC_URL_UPLOAD_SUCCESS),
            failure: take(ActionTypes.FC_URL_UPLOAD_FAILURE)
        });
        if (success) {
            const isValid = yield* validateImageResourceSaga(resourceName, resourcePath);
            if (!isValid) {
                // remove ghost
                yield* resetResourcesSaga(removeResourceByName(yield* getResourcesGen(), resourceName));
                yield* reloadResourcesSaga();
                return;
            }

            yield* reloadResourcesSaga();

            // select uploaded resource
            const
                resources: Array<Resource> = yield* getResourcesGen(),
                uploadedResource = resources.find((r: Resource) => r.getName() === resourceName);

            if (!uploadedResource) {
                throw new Error('Cannot find uploaded resource');
            }

            yield put(fcToggleResourceSelection(uploadedResource));
        } else {
            // remove ghost
            yield* resetResourcesSaga(removeResourceByName(yield* getResourcesGen(), resourceName));
            if (failure.payload.error === DalErrorName.INSUFFICIENT_STORAGE) {
                yield* openDialogGen(InsufficientStorageErrorDialog, { fileNames: [resourceName] });
            } else {
                // retry logic
                yield* openDialogGen(FailedErrorDialog, { fileNames: [resourceName] });
                const { retry } = yield* raceGen({
                    retry: take(ActionTypes.FC_UPLOAD_RETRY),
                    cancel: take(ActionTypes.FC_UPLOAD_CANCEL)
                });
                if (retry) {
                    yield put(action);
                } else {
                    yield* closeDialogGen();
                }
            }
        }
    });
}
