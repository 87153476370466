import React from "react";
import { noValueSet } from "./openingHours.css";
import { openSiteSettingsDialog } from "../../SiteSettings/SiteSettingsDialog/actionCreators";
import { SiteSettingsTabName } from "../../SiteSettings/SiteSettingsDialog/constants";
import { DEFAULT_MCTA_COMBOBOX_MENUSTYLE } from "../../componentMainActions/constants";
import MctaPropertyContainer from "../../componentMainActions/MctaPropertyContainer/index";
import FontSizeSelector from "../../presentational/FontSize/index";
import ColorProperty from "../../presentational/ColorProperty/index";
import HorizontalSpacer from "../../../view/common/HorizontalSpacer";
import { MctaVerticalBar, MctaButton } from "../../componentMainActions/index";
import { THEME_COLOR_PICKER, DEFAULT_COLOR_PICKER } from "../../ColorPicker/constants";
import { DEFAULT_OPENING_HOURS_COLOR_BOX_STYLE, OpeningHours } from "./constants";
import { titleTextSmaller, titleTextSmallerErr, titleTextBigger, titleTextBiggerErr, titleClearFormatting } from "../constants";
import { MAX_FONT_SIZE, MIN_FONT_SIZE } from "../Text/constants";
import type { OpeningHoursComponent } from "./flowTypes";
import type { CTAViewParams } from "../../../view/Workspace/Decorations/ComponentMainActions/flowTypes";
import { injectIntl } from "../../../view/intl/index";
import * as selectors from "../../Workspace/epics/stylesheets/selectors";
import { getThemeColorsFromStylesheet } from "../../Workspace/epics/stylesheets/selectors";
import { getThemeRulesForBackground } from "../../ThemeGlobalData/themeRules";
import { findSuitableTextColorName } from "../../ThemeGlobalData/utils/commonUtils";
import { StatefulAlignmentControls } from "../../componentMainActions/MctaAlignmentControls/alignmentControls";
import mctaStyles from "../../componentMainActions/MctaButton/styles.css";
import {
    OPENING_HOURS_FONT_SIZE_CHANGED,
    OPENING_HOURS_HORIZONTAL_ALIGNMENT_CHANGED,
    OPENING_HOURS_CLEAR_FORMATTING,
    OPENING_HOURS_COLOR_CHANGED_AUTO_COLOR,
    OPENING_HOURS_COLOR_CHANGED,
    OPENING_HOURS_MOBILE_HORIZONTAL_ALIGNMENT_CHANGED,
    OPENING_HOURS_MOBILE_DEC_FONT_BTN_PRESSED,
    OPENING_HOURS_MOBILE_INC_FONT_BTN_PRESSED,
    OPENING_HOURS_MOBILE_CLEAR_FORMATTING
} from "./actionTypes";

const capTextFontSize = size => {
    return Math.min(Math.max(MIN_FONT_SIZE, size), MAX_FONT_SIZE);
};

const EditView = injectIntl(props => {
        const { dispatch, stylesheets, intl, selectedComponent, themeSettingsData, selectedComponentProps, children } = props;
        const textNormalStyle = selectors.textNormalGlobalstyle(stylesheets),
            themeColorsData = getThemeColorsFromStylesheet(stylesheets),
            textColorTitle = intl.msgJoint("msg: mcta.textColor {Text colour}");
        return (
            <div>
                <MctaPropertyContainer
                    label="msg: common.fontSize {Font size}"
                    style={{
                        marginLeft: 5
                    }}
                >
                    <FontSizeSelector
                        onChangeAction={OPENING_HOURS_FONT_SIZE_CHANGED}
                        selected={selectedComponent.textStyle.fontSize || textNormalStyle.size}
                        dispatch={dispatch}
                        searchable={false}
                        menuStyle={DEFAULT_MCTA_COMBOBOX_MENUSTYLE}
                        mcta
                    />
                </MctaPropertyContainer>
                <HorizontalSpacer x={5} />
                <MctaVerticalBar />
                <HorizontalSpacer x={5} />
                <MctaPropertyContainer label="msg: common.color {Colour}">
                    {themeSettingsData.autoColorMode ? (
                        <ColorProperty
                            colorPickerKind={THEME_COLOR_PICKER}
                            themeColorsData={themeColorsData}
                            themeColor={selectedComponent.textStyle.themeColor}
                            defaultThemeColor={findSuitableTextColorName(
                                getThemeRulesForBackground(selectedComponentProps.selectedParentTheme, themeColorsData).background,
                                themeColorsData
                            )}
                            onChangeAction={OPENING_HOURS_COLOR_CHANGED_AUTO_COLOR}
                            dispatch={dispatch}
                            title={textColorTitle}
                            colorBoxStyle={DEFAULT_OPENING_HOURS_COLOR_BOX_STYLE}
                            showCaret
                        />
                    ) : (
                        <ColorProperty
                            colorPickerKind={DEFAULT_COLOR_PICKER}
                            dispatch={dispatch}
                            onChangeAction={OPENING_HOURS_COLOR_CHANGED}
                            onTheFlyChange
                            color={selectedComponent.textStyle.color || textNormalStyle.color}
                            title={textColorTitle}
                            colorBoxStyle={DEFAULT_OPENING_HOURS_COLOR_BOX_STYLE}
                            showCaret
                        />
                    )}
                </MctaPropertyContainer>
                <HorizontalSpacer x={5} />
                <MctaVerticalBar />
                <HorizontalSpacer x={5} />
                <StatefulAlignmentControls
                    actionTypeToDispatch={OPENING_HOURS_HORIZONTAL_ALIGNMENT_CHANGED}
                    alignment={selectedComponent.horizontalAlignment}
                    dispatch={dispatch}
                    kind={OpeningHours}
                />
                <HorizontalSpacer x={5} />
                <MctaVerticalBar />
                <HorizontalSpacer x={5} />
                <MctaButton
                    title={titleClearFormatting}
                    icon="REMOVE_FORMAT"
                    onClick={() =>
                        dispatch({
                            type: OPENING_HOURS_CLEAR_FORMATTING,
                            payload: {
                                textNormalFontSize: textNormalStyle.size
                            }
                        })}
                />
                {children && <HorizontalSpacer x={5} />}
                {children}
            </div>
        );
    }),
    MobileViewEditorV = injectIntl(props => {
        const {
                dispatch,
                stylesheets,
                selectedComponent: {
                    horizontalAlignment,
                    mobileHorizontalAlignment,
                    textStyle: { fontSize, mobileFontSize }
                },
                mobileMCTA
            } = props,
            { availableSpace } = mobileMCTA,
            textNormalStyle = selectors.textNormalGlobalstyle(stylesheets),
            renderFontSize = mobileFontSize || capTextFontSize(fontSize || textNormalStyle.size),
            canIncrease = renderFontSize < MAX_FONT_SIZE,
            canDecrease = renderFontSize > MIN_FONT_SIZE;
        return (
            <div>
                <StatefulAlignmentControls
                    actionTypeToDispatch={OPENING_HOURS_MOBILE_HORIZONTAL_ALIGNMENT_CHANGED}
                    availableSpace={availableSpace}
                    alignment={mobileHorizontalAlignment || horizontalAlignment}
                    dispatch={dispatch}
                    kind={OpeningHours}
                />
                <HorizontalSpacer x={5} />
                <MctaVerticalBar />
                <HorizontalSpacer x={5} />
                <MctaButton
                    btnClass={canDecrease ? "" : mctaStyles.disabled}
                    title={canDecrease ? titleTextSmaller : titleTextSmallerErr}
                    isErrorTitle={!canDecrease || undefined}
                    icon="FONT_SIZE_DECREASE"
                    onClick={() =>
                        canDecrease &&
                        dispatch({
                            type: OPENING_HOURS_MOBILE_DEC_FONT_BTN_PRESSED,
                            payload: renderFontSize - 1
                        })}
                />
                <HorizontalSpacer x={5} />
                <MctaVerticalBar />
                <HorizontalSpacer x={5} />
                <MctaButton
                    btnClass={canIncrease ? "" : mctaStyles.disabled}
                    title={canIncrease ? titleTextBigger : titleTextBiggerErr}
                    isErrorTitle={!canIncrease || undefined}
                    icon="FONT_SIZE_INCREASE"
                    onClick={() =>
                        canIncrease &&
                        dispatch({
                            type: OPENING_HOURS_MOBILE_INC_FONT_BTN_PRESSED,
                            payload: renderFontSize + 1
                        })}
                />
                <HorizontalSpacer x={5} />
                <MctaVerticalBar />
                <HorizontalSpacer x={5} />
                <MctaButton
                    title={titleClearFormatting}
                    icon="CLEARFORMATING"
                    onClick={() =>
                        dispatch({
                            type: OPENING_HOURS_MOBILE_CLEAR_FORMATTING
                        })}
                />
            </div>
        );
    }),
    CtaButtonTextView = injectIntl(props => {
        const {
            globalVariables: { openingHours },
            intl
        } = props;
        let isOpeningHoursSet = openingHours && Object.keys(openingHours).length;
        return (
            <span className={isOpeningHoursSet ? null : noValueSet}>
                {isOpeningHoursSet
                    ? intl.msgJoint("msg: component.openingHours.changeOpeningHours {Change opening hours}")
                    : intl.msgJoint("msg: component.openingHours.addOpeningHours {Add opening hours}")}
            </span>
        );
    }),
    CtaButtonClick = ({ dispatch }: CTAViewParams<OpeningHoursComponent>) => {
        dispatch(
            openSiteSettingsDialog({
                activeTabKey: SiteSettingsTabName.GENERAL,
                activateDivWithId: "opening-hours" // scroll to some element
            })
        );
    };

export const openingHoursComponentMainActions = {
    mobileViewEditorV: MobileViewEditorV,
    editView: EditView,
    editButtonText: "msg: common.settings {Settings}",
    CtaButtonTextView,
    ctaOnClick: CtaButtonClick
};
