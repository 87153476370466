import { EventWSBSources } from "../../EventTracking/constants";
import { selectWebsiteCategories } from "../Text/epics/AITextEpic/selectors";
const { OPTION_AI } = require("../../../../../server/lib/handlers/componentPageCreation/constants.js");

export const blogWizardInitiationHandler = (state) => {
    return {
        "Website category": selectWebsiteCategories(state)
    };
};

export const blogWizardCompletionHandler = (_, payload) => {
    return {
        "AI used": payload.selectedOnboardingOption === OPTION_AI,
        "Source": EventWSBSources.Inserter,
        "Website category": payload.aiData.category.value,
        "Create with Ai shown": !payload.blogHasPosts,
        "Blog description": payload.aiData.topics,
        "Layout type": payload.selectedLayout
    };
};
