import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import cx from "classnames";
import { Dialog, DialogBody, StripTypes } from "../../../../view/common/dialogs/baseDialog";
import styles from "./OnboardingWizard.css";
import getCenteredDialogConfig from "../../../DialogManager/getCenteredDialogConfig";
import { getCenterPositionForDialog } from "../../../DialogManager/utility";
import {
    BLOG_ONBOARDING_DIALOG_HEIGHT,
    BLOG_ONBOARDING_DIALOG_WIDTH,
    OnboardingSteps
} from "./constants";
import { Msg, useIntl } from "../../../../view/intl";
import { AIData, OnboardingOption } from "./types";
import {
    selectGeneralDataSiteSettings,
    selectLanguage,
    selectWebsiteCategories,
    selectWebsiteTitle
} from "../../Text/epics/AITextEpic/selectors";
import { BlogOnboardingError } from "./WarningAndErrorViews/BlogOnboardingError";
import Scrollbar from "../../../../view/common/Scrollbar";
import {
    BLOG_ERROR_RESET,
    BLOG_WIZARD_RESET_AND_CLOSE,
    CHECK_FOR_EXISTING_POSTS,
    CREATE_BLOG_API_CALL
} from "./epic/actionTypes";
import { templateDataAppSel } from "../../Template/epics/template/selectors";
import { siteDataStateAppSel } from "../../../App/epics/siteData/selectors";
import {
    selectBlogError,
    selectBlogHasPosts,
    selectIsBlogLoading,
    selectSelectedLayout,
    selectSelectedOnboardingOption
} from "./epic/selectors";
import dynamicMessages from "../../../../../l10n/messages/dynamicMessages.json";
import { OnboardingLoadingText } from "./OnboardingLoadingText";
import { Loader } from "../../../../../../src/dashboard/src/components/Loader/Loader";

const BlogOnboardingDialog = ({ dispatch }) => {
    const intl = useIntl();
    // states for the onboarding flow
    const blogHasPosts = useSelector(selectBlogHasPosts);
    const [hasStartedOnboarding, setStartOnboarding] = useState<boolean>(blogHasPosts || false);
    const [currentStep, setCurrentStep] = useState<number>(0);
    const isBlogLoading = useSelector(selectIsBlogLoading);
    const blogError = useSelector(selectBlogError);
    const [tryAgainCounter, setTryAgainCounter] = useState<number>(0);

    // blog creation info
    const selectedOption = useSelector(selectSelectedOnboardingOption);
    const selectedLayout = useSelector(selectSelectedLayout);
    const templateData = useSelector(templateDataAppSel);
    const siteMap = useSelector(siteDataStateAppSel());

    // AI data
    const categoryKey = useSelector(selectWebsiteCategories);
    const language = useSelector(selectLanguage);
    const websiteTitle = useSelector(selectWebsiteTitle);
    const { addressName = "" } = useSelector(selectGeneralDataSiteSettings);

    // prefill the AI data with info the user has already provided
    const [aiData, setAiData] = useState<AIData>({
        category: { label: "", value: categoryKey },
        language,
        websiteName: websiteTitle || "",
        location: addressName,
        topics: ""
    });

    useEffect(() => {
        // skip first choice (AI/blank) if the user already has a blog with posts
        // blogHasPosts could change later, depending on response of blog API
        setStartOnboarding(blogHasPosts || false);
    }, [blogHasPosts]);

    const handleDialogClose = () => {
        dispatch({ type: BLOG_WIZARD_RESET_AND_CLOSE });
    };

    // call the blog creation API
    const createBlog = () => {
        let blogPageName = intl.msgJoint("msg: component.blog.label {Blog}");

        // translate the blog page name if the user chose a different blog language in onboarding
        if (
            selectedOption === OnboardingOption.AI &&
            aiData.language !== language &&
            dynamicMessages["component.blog.label"] &&
            dynamicMessages["component.blog.label"][aiData.language]
        ) {
            blogPageName = dynamicMessages["component.blog.label"][aiData.language];
        }

        const pageNames = [
            {
                type: "blog",
                name: blogPageName
            },
            {
                type: "post",
                name: intl.msgJoint("msg: component.post.label {Post}")
            },
            {
                type: "category",
                name: intl.msgJoint("msg: component.blog.category.label {Category}")
            }
        ];

        dispatch({
            type: CREATE_BLOG_API_CALL,
            payload: {
                templateID: templateData.id,
                templateWidth: templateData.width,
                siteMap,
                pageNames,
                editorLanguage: language
            }
        });
    };

    // when the user has selected an onboarding option the step counter will be displayed
    const currentStepView = !hasStartedOnboarding ? (
        <div className={styles.stepWrapper} data-testid="dialog-step-start">
            {OnboardingSteps.start[0].view({
                dispatch,
                selectedOption,
                setStartOnboarding
            })}
        </div>
    ) : (
        <div className={styles.stepWrapper} data-testid={"dialog-step-" + selectedOption}>
            {/* only show step counter when there is more than 1 step */}
            {OnboardingSteps[selectedOption].length > 1 && (
                <div className={styles.stepCounter}>
                    <Msg
                        k="component.blog.onboarding.stepCounter"
                        params={{
                            currentStep: currentStep + 1,
                            maxStep: OnboardingSteps[selectedOption].length
                        }}
                    >
                        {`Step {currentStep} of {maxStep}`}
                    </Msg>
                </div>
            )}
            <div className={OnboardingSteps[selectedOption].length > 1 ? styles.withCounter : styles.noCounter}>
                {OnboardingSteps[selectedOption][currentStep].view({
                    dispatch,
                    selectedOption,
                    setStartOnboarding,
                    currentStep,
                    setCurrentStep,
                    selectedLayout,
                    aiData,
                    setAiData,
                    createBlog,
                    blogHasPosts
                })}
            </div>
        </div>
    );

    // the image on the right side changes, depending on step and selected options
    const sideImageClass = !hasStartedOnboarding
        ? OnboardingSteps.start[0].getImageClass({ selectedOption })
        : OnboardingSteps[selectedOption][currentStep].getImageClass({
            selectedLayout
        });

    const OnboardingMessage = () => {
        if (blogHasPosts !== null) {
            if (selectedOption === OnboardingOption.blank) {
                return <Msg k="component.blog.onboarding.loading">Creating your blog, please wait...</Msg>;
            }
            return <OnboardingLoadingText />;
        }
    };

    return (
        <React.Fragment>
            <Dialog
                stripType={StripTypes.NONE}
                onClose={handleDialogClose}
                // hide close button while blog creation is loading
                showCloseBtn={!isBlogLoading || blogHasPosts === null}
            >
                <DialogBody className={styles.blogDialog} data-testid="dialog-body">
                    <Scrollbar
                        className={cx(styles.interactiveAreaContainer, {
                            [styles.fullWidth]: isBlogLoading || blogError
                        })}
                        data-testid="dialog-step-area"
                    >
                        <div style={{ height: "100%", marginRight: "16px" }} data-testid="dialog-step-area">
                            {/*
                                display error view in case of error
                            */}
                            {blogError && (
                                <BlogOnboardingError
                                    errorType={blogError}
                                    onClose={() => dispatch({ type: BLOG_WIZARD_RESET_AND_CLOSE })}
                                    onTryAgain={() => {
                                        if (blogHasPosts === null) {
                                            // in case the "has posts" checked failed, retry to get blog details
                                            dispatch({ type: CHECK_FOR_EXISTING_POSTS });
                                        } else {
                                            // otherwise retry blog creation
                                            setTryAgainCounter(tryAgainCounter + 1);
                                            createBlog();
                                        }
                                        dispatch({ type: BLOG_ERROR_RESET });
                                    }}
                                    stateProps={{
                                        dispatch,
                                        setCurrentStep,
                                        createBlog
                                    }}
                                    hasRetried={tryAgainCounter > 0}
                                />
                            )}
                            {/*
                                in case the blog is being created show the loader
                            */}
                            {!blogError && isBlogLoading && (
                                <div className={styles.loaderContainer} data-testid="loader-container">
                                    <Loader className={styles.loader}>
                                        <div className={styles.onboardingMessageContainer}>
                                            <OnboardingMessage />
                                        </div>
                                    </Loader>
                                </div>
                            )}
                            {/*
                                if no error and not loading -> show the view of the current step
                            */}
                            {!blogError && !isBlogLoading && (
                                <div style={{ height: "100%" }}>
                                    <div className={styles.header}>
                                        <Msg k="component.blog.onboarding.header">Blog setup</Msg>
                                    </div>
                                    {currentStepView}
                                </div>
                            )}
                        </div>
                    </Scrollbar>
                    {!blogError && !isBlogLoading && (
                        <div className={styles.imageContainer}>
                            <div className={cx(styles.imageWrapper, sideImageClass)} data-testid="dialog-image" />
                        </div>
                    )}
                </DialogBody>
            </Dialog>
        </React.Fragment>
    );
};

const config = {
    // create the dialog with the blog onboarding dialog component
    ...getCenteredDialogConfig({
        component: BlogOnboardingDialog,
        dragEnabled: false,
        dialogClassName: styles.dialogContainer
    }),

    // set the general dialog parameters in configuration factory
    confFactory: ({ browserWidth, browserHeight }) => {
        const width = browserWidth * BLOG_ONBOARDING_DIALOG_WIDTH;
        const height = browserHeight * BLOG_ONBOARDING_DIALOG_HEIGHT;

        const { left, top } = getCenterPositionForDialog(width, height, browserWidth, browserHeight);

        return {
            dimensions: { width, height },
            position: { left, top },
            // modal is set so the dialog is not closed if clicked outside
            // only the x or Cancel will close the dialog
            modal: true
        };
    },

    // recalculate the dialog dimensions in case the browser dimensions change
    updateOnBrowserDimensionsChanged: (dialogConfig, browserDimensions) => {
        // height and width should always be set to the given percentage of the screen
        const width = browserDimensions.width * BLOG_ONBOARDING_DIALOG_WIDTH;
        const height = browserDimensions.height * BLOG_ONBOARDING_DIALOG_HEIGHT;

        return {
            ...dialogConfig,
            dimensions: { width, height }
        };
    }
};

export default config;
