import { types } from './constants';
import * as actionTypes from './actionTypes';
import * as styles from '../GlobalstylesDialog.css';
import type { Color } from '../../../mappers/flowTypes';

import normalCharacterSpacingConfigurationDialogId from './normalCharacterSpacingConfigurationDialogId';
import heading1CharacterSpacingConfigurationDialogId from './heading1CharacterSpacingConfigurationDialogId';
import heading2CharacterSpacingConfigurationDialogId from './heading2CharacterSpacingConfigurationDialogId';
import heading3CharacterSpacingConfigurationDialogId from './heading3CharacterSpacingConfigurationDialogId';

import normalTextShadowConfigurationDialogId from './normalTextShadowConfigurationDialogId';
import heading1TextShadowConfigurationDialogId from './heading1TextShadowConfigurationDialogId';
import heading2TextShadowConfigurationDialogId from './heading2TextShadowConfigurationDialogId';
import heading3TextShadowConfigurationDialogId from './heading3TextShadowConfigurationDialogId';
import logoCharacterSpacingConfigurationDialogId from './logoCharacterSpacingConfigurationDialogId';
import logoTextShadowConfigurationDialogId from './logoTextShadowConfigurationDialogId';
import { getColorDistance } from "../../../utils/colorUtils";

const
    getPreviewBackgroundCss = (color: Color): string => {
        const defaultCss = '';

        if (color) {
            const threshold = 0.3,
                d1 = getColorDistance(color, '#fff'),
                d2 = getColorDistance(color, styles.textStylePreviewBg);

            return d1 > threshold || d1 > d2 ? '' : styles.lightBlueBG;
        }

        return defaultCss;
    },
    getFontFamilyChangeAction = (ref: string): string | null | undefined => {
        switch (ref) {
            case types.NORMAL: return actionTypes.GS_TEXT_NORMAL_FONT_FAMILY_CHANGED;
            case types.HEADING1: return actionTypes.GS_TEXT_HEADING1_FONT_FAMILY_CHANGED;
            case types.HEADING2: return actionTypes.GS_TEXT_HEADING2_FONT_FAMILY_CHANGED;
            case types.HEADING3: return actionTypes.GS_TEXT_HEADING3_FONT_FAMILY_CHANGED;
            case types.LOGO: return actionTypes.GS_TEXT_LOGO_FONT_FAMILY_CHANGED;
            default: return null;
        }
    },
    getToggleBoldAction = (ref: string): string | null => {
        switch (ref) {
            case types.NORMAL: return actionTypes.GS_TEXT_NORMAL_FONT_TOGGLE_BOLD;
            case types.HEADING1: return actionTypes.GS_TEXT_HEADING1_FONT_TOGGLE_BOLD;
            case types.HEADING2: return actionTypes.GS_TEXT_HEADING2_FONT_TOGGLE_BOLD;
            case types.HEADING3: return actionTypes.GS_TEXT_HEADING3_FONT_TOGGLE_BOLD;
            case types.LOGO: return actionTypes.GS_TEXT_LOGO_FONT_TOGGLE_BOLD;
            default: return null;
        }
    },
    getToggleItalicAction = (ref: string): string | null => {
        switch (ref) {
            case types.NORMAL: return actionTypes.GS_TEXT_NORMAL_FONT_TOGGLE_ITALIC;
            case types.HEADING1: return actionTypes.GS_TEXT_HEADING1_FONT_TOGGLE_ITALIC;
            case types.HEADING2: return actionTypes.GS_TEXT_HEADING2_FONT_TOGGLE_ITALIC;
            case types.HEADING3: return actionTypes.GS_TEXT_HEADING3_FONT_TOGGLE_ITALIC;
            case types.LOGO: return actionTypes.GS_TEXT_LOGO_FONT_TOGGLE_ITALIC;
            default: return null;
        }
    },
    getToggleUnderlineAction = (ref: string): string | null => {
        switch (ref) {
            case types.NORMAL: return actionTypes.GS_TEXT_NORMAL_FONT_TOGGLE_UNDERLINE;
            case types.HEADING1: return actionTypes.GS_TEXT_HEADING1_FONT_TOGGLE_UNDERLINE;
            case types.HEADING2: return actionTypes.GS_TEXT_HEADING2_FONT_TOGGLE_UNDERLINE;
            case types.HEADING3: return actionTypes.GS_TEXT_HEADING3_FONT_TOGGLE_UNDERLINE;
            case types.LOGO: return actionTypes.GS_TEXT_LOGO_FONT_TOGGLE_UNDERLINE;
            default: return null;
        }
    },
    getSpacingChangeAction = (ref: string): string | null => {
        switch (ref) {
            case types.NORMAL: return actionTypes.GS_TEXT_NORMAL_TEXT_CHARACTER_SPACING_CHANGED;
            case types.HEADING1: return actionTypes.GS_TEXT_HEADING1_TEXT_CHARACTER_SPACING_CHANGED;
            case types.HEADING2: return actionTypes.GS_TEXT_HEADING2_TEXT_CHARACTER_SPACING_CHANGED;
            case types.HEADING3: return actionTypes.GS_TEXT_HEADING3_TEXT_CHARACTER_SPACING_CHANGED;
            case types.LOGO: return actionTypes.GS_TEXT_LOGO_TEXT_CHARACTER_SPACING_CHANGED;
            default: return null;
        }
    },
    getFontSizeChangeAction = (ref: string): string | null => {
        switch (ref) {
            case types.NORMAL: return actionTypes.GS_TEXT_NORMAL_FONT_SIZE_CHANGED;
            case types.HEADING1: return actionTypes.GS_TEXT_HEADING1_FONT_SIZE_CHANGED;
            case types.HEADING2: return actionTypes.GS_TEXT_HEADING2_FONT_SIZE_CHANGED;
            case types.HEADING3: return actionTypes.GS_TEXT_HEADING3_FONT_SIZE_CHANGED;
            case types.LOGO: return actionTypes.GS_TEXT_LOGO_FONT_SIZE_CHANGED;
            default: return null;
        }
    },
    getTextColorChangeAction = (ref: string): string | null => {
        switch (ref) {
            case types.NORMAL: return actionTypes.GS_TEXT_NORMAL_FONT_COLOR_CHANGED;
            case types.HEADING1: return actionTypes.GS_TEXT_HEADING1_FONT_COLOR_CHANGED;
            case types.HEADING2: return actionTypes.GS_TEXT_HEADING2_FONT_COLOR_CHANGED;
            case types.HEADING3: return actionTypes.GS_TEXT_HEADING3_FONT_COLOR_CHANGED;
            case types.LOGO: return actionTypes.GS_TEXT_LOGO_FONT_COLOR_CHANGED;
            // GS_TEXT_LOGO_FONT_COLOR_CHANGED_AUTO_COLOR is not here because we don't show LOGO in GlobalStyles dialog yet
            default: return null;
        }
    },
    getTextColorRemoveAction = (ref: string): string | null => {
        switch (ref) {
            case types.NORMAL: return actionTypes.GS_TEXT_NORMAL_FONT_COLOR_REMOVED;
            case types.HEADING1: return actionTypes.GS_TEXT_HEADING1_FONT_COLOR_REMOVED;
            case types.HEADING2: return actionTypes.GS_TEXT_HEADING2_FONT_COLOR_REMOVED;
            case types.HEADING3: return actionTypes.GS_TEXT_HEADING3_FONT_COLOR_REMOVED;
            case types.LOGO: return actionTypes.GS_TEXT_LOGO_FONT_COLOR_REMOVED;
            default: return null;
        }
    },
    getShadowColorChangeAction = (ref: string): string | null => {
        switch (ref) {
            case types.NORMAL: return actionTypes.GS_TEXT_NORMAL_TEXT_SHADOW_COLOR_CHANGED;
            case types.HEADING1: return actionTypes.GS_TEXT_HEADING1_TEXT_SHADOW_COLOR_CHANGED;
            case types.HEADING2: return actionTypes.GS_TEXT_HEADING2_TEXT_SHADOW_COLOR_CHANGED;
            case types.HEADING3: return actionTypes.GS_TEXT_HEADING3_TEXT_SHADOW_COLOR_CHANGED;
            default: return null;
        }
    },
    getShadowRemoveAction = (ref: string): string | null => {
        switch (ref) {
            case types.NORMAL: return actionTypes.GS_TEXT_NORMAL_TEXT_SHADOW_REMOVED;
            case types.HEADING1: return actionTypes.GS_TEXT_HEADING1_TEXT_SHADOW_REMOVED;
            case types.HEADING2: return actionTypes.GS_TEXT_HEADING2_TEXT_SHADOW_REMOVED;
            case types.HEADING3: return actionTypes.GS_TEXT_HEADING3_TEXT_SHADOW_REMOVED;
            default: return null;
        }
    },
    getTextShadowBlurRadiusChangeAction = (ref: string): string | null => {
        switch (ref) {
            case types.NORMAL: return actionTypes.GS_TEXT_NORMAL_TEXT_SHADOW_BLUR_RADIUS_CHANGED;
            case types.HEADING1: return actionTypes.GS_TEXT_HEADING1_TEXT_SHADOW_BLUR_RADIUS_CHANGED;
            case types.HEADING2: return actionTypes.GS_TEXT_HEADING2_TEXT_SHADOW_BLUR_RADIUS_CHANGED;
            case types.HEADING3: return actionTypes.GS_TEXT_HEADING3_TEXT_SHADOW_BLUR_RADIUS_CHANGED;
            default: return null;
        }
    },
    getShadowOffsetXChangeAction = (ref: string): string | null => {
        switch (ref) {
            case types.NORMAL: return actionTypes.GS_TEXT_NORMAL_TEXT_SHADOW_HORIZONTAL_OFFSET_CHANGED;
            case types.HEADING1: return actionTypes.GS_TEXT_HEADING1_TEXT_SHADOW_HORIZONTAL_OFFSET_CHANGED;
            case types.HEADING2: return actionTypes.GS_TEXT_HEADING2_TEXT_SHADOW_HORIZONTAL_OFFSET_CHANGED;
            case types.HEADING3: return actionTypes.GS_TEXT_HEADING3_TEXT_SHADOW_HORIZONTAL_OFFSET_CHANGED;
            default: return null;
        }
    },
    getShadowOffsetYChangeAction = (ref: string): string | null => {
        switch (ref) {
            case types.NORMAL: return actionTypes.GS_TEXT_NORMAL_TEXT_SHADOW_VERTICAL_OFFSET_CHANGED;
            case types.HEADING1: return actionTypes.GS_TEXT_HEADING1_TEXT_SHADOW_VERTICAL_OFFSET_CHANGED;
            case types.HEADING2: return actionTypes.GS_TEXT_HEADING2_TEXT_SHADOW_VERTICAL_OFFSET_CHANGED;
            case types.HEADING3: return actionTypes.GS_TEXT_HEADING3_TEXT_SHADOW_VERTICAL_OFFSET_CHANGED;
            default: return null;
        }
    },
    getLineHeightChangeAction = (ref: string): string | null => {
        switch (ref) {
            case types.NORMAL: return actionTypes.GS_TEXT_NORMAL_LINE_HEIGHT_CHANGED;
            case types.HEADING1: return actionTypes.GS_TEXT_HEADING1_LINE_HEIGHT_CHANGED;
            case types.HEADING2: return actionTypes.GS_TEXT_HEADING2_LINE_HEIGHT_CHANGED;
            case types.HEADING3: return actionTypes.GS_TEXT_HEADING3_LINE_HEIGHT_CHANGED;
            case types.LOGO: return actionTypes.GS_TEXT_LOGO_LINE_HEIGHT_CHANGED;
            default: return null;
        }
    },
    getAllActionsForStyle = (ref: string): any => ({
        fontFamilyChangeAction: getFontFamilyChangeAction(ref),
        toggleBoldAction: getToggleBoldAction(ref),
        toggleItalicAction: getToggleItalicAction(ref),
        toggleUnderlineAction: getToggleUnderlineAction(ref),
        textCharacterSpacingChangeAciton: getSpacingChangeAction(ref),
        fontSizeChangeAction: getFontSizeChangeAction(ref),
        colorChangeAction: getTextColorChangeAction(ref),
        colorRemoveAction: getTextColorRemoveAction(ref),
        shadowColorChangeAction: getShadowColorChangeAction(ref),
        shadowRemoveAction: getShadowRemoveAction(ref),
        blurRadiusChangeAction: getTextShadowBlurRadiusChangeAction(ref),
        shadowOffsetXChangeAction: getShadowOffsetXChangeAction(ref),
        shadowOffsetYChangeAction: getShadowOffsetYChangeAction(ref),
        spacingChangeAction: getSpacingChangeAction(ref),
        lineHeightChangeAction: getLineHeightChangeAction(ref),
    }),
    getCharacterSpacingDialogConfigurationDialogId = (ref: string): string | null => {
        switch (ref) {
            case types.NORMAL: return normalCharacterSpacingConfigurationDialogId;
            case types.HEADING1: return heading1CharacterSpacingConfigurationDialogId;
            case types.HEADING2: return heading2CharacterSpacingConfigurationDialogId;
            case types.HEADING3: return heading3CharacterSpacingConfigurationDialogId;
            case types.LOGO: return logoCharacterSpacingConfigurationDialogId;
            default: return null;
        }
    },
    getTextShadowDialogConfigurationDialogId = (ref: string): string | null => {
        switch (ref) {
            case types.NORMAL: return normalTextShadowConfigurationDialogId;
            case types.HEADING1: return heading1TextShadowConfigurationDialogId;
            case types.HEADING2: return heading2TextShadowConfigurationDialogId;
            case types.HEADING3: return heading3TextShadowConfigurationDialogId;
            case types.LOGO: return logoTextShadowConfigurationDialogId;
            default: return null;
        }
    };

export {
    getAllActionsForStyle,
    getCharacterSpacingDialogConfigurationDialogId,
    getTextShadowDialogConfigurationDialogId,
    getPreviewBackgroundCss
};
