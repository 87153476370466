import { v4 as uuidv4 } from 'uuid'; // eslint-disable-line n/file-extension-in-import
import isTestEnv from "../src/debug/isTestEnv";

// TODO: WBTGEN-2618

type MakeUuid = () => string;

const defaultMakeUuidInstance = () => uuidv4().toUpperCase();

let makeUuidInstance: MakeUuid = defaultMakeUuidInstance;
const setMakeUuidInstance = (mock: MakeUuid) => {
    if (!isTestEnv) throw new Error('Can only setMakeUuidInstance() in tests');
    makeUuidInstance = mock;
};

const resetMakeUuidInstance = () => {
    if (!isTestEnv) throw new Error('Can only resetMakeUuidInstance() in tests');
    makeUuidInstance = defaultMakeUuidInstance;
};

const
    makeUuid = () => makeUuidInstance(),
    makeUuidNoDash = () => makeUuid().replace(/-/g, ''),
    makeUuidNoReplaceOnDuplicate = () => `${makeUuidNoDash()}$`;

export {
    makeUuid as default,
    makeUuidNoDash,
    setMakeUuidInstance,
    resetMakeUuidInstance,
    makeUuid,
    makeUuidNoReplaceOnDuplicate
};
