import { take } from 'redux-saga/effects'; // eslint-disable-line n/file-extension-in-import
import getResourceMetadata, { GET_RESOURCE_METADATA_FOR_SAVE_API_TAG } from "../actionCreators/getResourceMetadata";
import { takeSuccessFromApiActionGen } from '../../../../../utils/saga/index';
import type { ResourceMetadata } from "../flowTypes";

const makeMatch = resourcePath => actionType => take(
    // @ts-ignore
    ({ type, endpointParams }) => type === actionType && endpointParams === resourcePath
);

const ResponseMask = {
    'etag': null,
    'contentType': null,
    'filesize': null,
    'width': null,
    'height': null,
    'alpha': null,
    'bpp': null,
    'animated': false,
    'recommendedFormat': null,
    'image': null
};

export default function* (
    resourcePath: string,
    isForSave: boolean = false
): Generator<any, ResourceMetadata | false, any> {
    const getResourceMetadataArgs = [resourcePath];
    if (isForSave) getResourceMetadataArgs.push(GET_RESOURCE_METADATA_FOR_SAVE_API_TAG);
    const response = yield* takeSuccessFromApiActionGen(
        // @ts-ignore
        getResourceMetadata(...getResourceMetadataArgs),
        // dialog will be handled by the caller
        function* () { return false; }, // eslint-disable-line require-yield
        makeMatch(resourcePath)
    );
    if (!response) return false;

    let finalResponse: ResourceMetadata = Object.keys(ResponseMask).reduce((acc, k) => ({
        ...acc,
        [k]: response[k] || ResponseMask[k]
    }), {} as ResourceMetadata);

    // Taking Copyright exif info for the image Asset if its available.
    // Currently setting custom info in the Copyright exif info for the Unsplash images
    if (finalResponse.image) {
        if (finalResponse.image.Copyright) {
            if (typeof finalResponse.image.Copyright === 'object'
                && finalResponse.image.Copyright.type === 'Buffer'
                && Array.isArray(finalResponse.image.Copyright.data)
            ) {
                finalResponse = { ...finalResponse, image: { Copyright: Buffer.from(finalResponse.image.Copyright.data).toString(), } };
            } else {
                finalResponse = { ...finalResponse, image: { Copyright: finalResponse.image.Copyright } };
            }
        } else {
            finalResponse = { ...finalResponse, image: null };
        }
    }
    return finalResponse;
}
