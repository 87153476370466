import React, { useEffect, useState } from "react";
import cx from "classnames";
import styles from "./Dropdown.css";
import Scrollbar from "../../../../../view/common/Scrollbar";

interface Props {
    /**
     * Key value pairs of the options to select from
     */
    options: { [key: string]: string };
    /**
     * Key of the selected value
     */
    selectedOption: string;
    /**
     * Called when an option is selected
     */
    onOptionSelect: (selectedOptionKey: string) => void;
}

export const DropdownSelector = ({ options, selectedOption, onOptionSelect }: Props) => {
    const [isDropdownOpen, setDropdownOpen] = useState<boolean>(false);

    //add a mouse click event listener, to close the dropdown if clicked outside
    useEffect(() => {
        const handleClickOutside: EventListenerOrEventListenerObject = () => {
            if (isDropdownOpen) {
                setDropdownOpen(false);
            }
        };

        // Attach event listener on document mount
        document.addEventListener("click", handleClickOutside);

        // Cleanup function to remove listener on unmount
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, [isDropdownOpen]);

    return (
        <div
            className={cx(styles.dropdownContainer, { [styles.open]: isDropdownOpen })}
            onClick={event => {
                setDropdownOpen(!isDropdownOpen);

                // stop any further events so handleClickOutside is not called
                event.stopPropagation();
            }}
        >
            <div className={styles.selector} data-testid="dropdown-select">
                {options[selectedOption]}
            </div>
            {isDropdownOpen && (
                <Scrollbar className={styles.dropdownWrapper}>
                    {Object.keys(options).map(key => {
                        return (
                            <div
                                key={key}
                                className={styles.optionElement}
                                onClick={() => {
                                    onOptionSelect(key);
                                }}
                                data-testid={"dropdown-option-" + key}
                            >
                                {options[key]}
                            </div>
                        );
                    })}
                </Scrollbar>
            )}
        </div>
    );
};
