import type {
    PageInfoDialogInProps,
    PageInfoDialogStaticProps,
    PageInfoDialogState,
    PageInfoDialogDependencies
} from "../flowTypes";
import { getDAL } from "../../../../../../../dal";
import type { DialogCalcRenderPropsInput } from "../../../../../DialogManager/flowTypes";
import { PageInfoTab, PAGE_DEFAULT_EXTENSION } from "../../../constants";
import * as pathUtils from "../../../../../../../utils/path.js";
import { PAGE_INDEX_URL } from "../../../../../../../dal/model/DataPageRef";
import { isDataPageRefType } from "../../../../../../../dal/model/utils/dataSiteItemUtils";
import urlBuild from "../../../../../../../utils/urlBuild";
import { isDemoSubscriptionType } from '../../../../../App/epics/subscriptionData/isDemoSubscriptionType';
import { getScheme, getFinalURL } from "../../../../../SiteSettings/publishConfig/utils";

type Input = DialogCalcRenderPropsInput<PageInfoDialogState, PageInfoDialogInProps, PageInfoDialogDependencies>;

export default (
    {
        state,
        props,
        dependencies
    }: Input
): PageInfoDialogStaticProps => {
    const
        { activeTabId, [PageInfoTab.NAMES]: { name, url }, [PageInfoTab.SEO]: { title }, isHomePage } = state,
        { site, seoGlobalData, subscriptionData, currentPageId, currentTemplate, publishConfig, websiteTitle } = dependencies,
        { pageRefId } = props,
        page = site.getPageRefById(pageRefId),
        homePage = site.getHomePageRef(),
        isSaveEnabled = !state.isEqToInitialState && title.isValid && name.isValid && url.isValid,
        origPageUrlPath = site.getPageRefUrlPath(page.id),
        allPagesUrlPaths = site.getAllPageRefsUrlPaths(),
        origHomePageUrlPath = homePage ? [homePage.url] : null,
        domainName = getDAL().getDomain(),
        uriParts = {
            domain: domainName,
            fullPath: origPageUrlPath,
            basePath: pathUtils.arrayBasename(origPageUrlPath).join('/'),
            indexFilename: `${PAGE_INDEX_URL}.${PAGE_DEFAULT_EXTENSION}`,
            ext: `.${PAGE_DEFAULT_EXTENSION}`
        },
        isRootPage = origPageUrlPath.length === 1,
        isParentPage = !!page.items.length,
        urlScheme = getScheme(publishConfig),
        extension = publishConfig.dropHtmlExtension ? '' : uriParts.ext,
        suffixString = isParentPage ? '/' : extension,
        suffix = isHomePage ? '' : suffixString,
        urlParams = {
            prefix: urlBuild(
                uriParts.basePath,
                { scheme: urlScheme, host: uriParts.domain }
            ) + (isHomePage ? '' : '/'),
            suffix,
            label: isParentPage
                ? { value: 'msg: pageNameInUrl.homeParent {Folder name in URL:}', translate: true }
                : null,
            subLabel: isParentPage
                ? {
                    value: 'msg: pageNameInUrl.subPagesFolderName {Subpages have this folder name as part of their URL}', // eslint-disable-line max-len
                    translate: true
                }
                : null,
            absoluteUrl: urlBuild(
                isHomePage ? uriParts.indexFilename : origPageUrlPath.join('/'),
                { scheme: urlScheme, host: uriParts.domain }
            ),
            cleanedDisplayUrl: '',
        },
        isDemo = isDemoSubscriptionType(subscriptionData),
        isSeoEnabledForSiteSettings = seoGlobalData.enableSeo;

    urlParams.absoluteUrl = getFinalURL(`${urlParams.absoluteUrl}${suffix}`, publishConfig);
    if (isHomePage) {
        urlParams.cleanedDisplayUrl = decodeURIComponent(pathUtils.trim(
            urlParams.absoluteUrl.replace(`${urlScheme}://`, '').replace(uriParts.indexFilename, '')
        ));
    } else {
        urlParams.cleanedDisplayUrl = decodeURIComponent(pathUtils.trim(
            urlParams.absoluteUrl.replace(`${urlScheme}://`, '')
        ));
    }

    return {
        activeTabId,
        isSaveEnabled,
        pageRefId,
        pageName: page.name,
        isRootPage,
        isCurrentPage: isDataPageRefType(page.type) && page.pageId === currentPageId,
        currentTemplate,
        // page names tab
        origPageUrlPath,
        allPagesUrlPaths,
        origHomePageUrlPath,
        isHomePage,
        isParentPage,
        // @ts-ignore
        urlParams,
        isDemo,
        isSeoEnabledForSiteSettings,
        websiteTitle,
        domainName,
    };
};
