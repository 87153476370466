// @ts-nocheck
import Text from "../../oneweb/Text/kind";
import { PHONE_KIND } from "../../oneweb/TextLike/Phone/kind";
import { ADDRESS_KIND } from "../../oneweb/TextLike/Address/kind";
import { EMAIL_KIND } from "../../oneweb/TextLike/Email/kind";
import Background from "../../oneweb/Background/kind";
import HoverBox from "../../oneweb/HoverBox/kind";
import ContactForm from "../../oneweb/ContactForm/kind";
import WebShop from "../../oneweb/WebShop/kind";
import Code from "../../oneweb/Code/kind";
import Strip from "../../oneweb/Strip/kind";
import Image from "../../oneweb/Image/kind";
import Menu from "../../oneweb/Menu/kind";
import Gallery from "../../oneweb/Gallery/kind";
import Table from "../../oneweb/Table/kind";
import { OPENING_HOURS_KIND } from "../../oneweb/OpeningHours/kind";
import { LOGO_KIND } from "../../oneweb/Logo/kind";
import Section from "../../oneweb/Section/kind";
import FeaturedProducts from '../../oneweb/FeaturedProducts/kind';
import ProductWidget from '../../oneweb/ProductWidget/kind';
import { BLOG, POST, GOOGLE_REVIEWS } from "../../oneweb/componentKinds";
import { WidgetsBookingsKind } from '../../oneweb/Widgets/Bookings/kind';
import BookingsKind from "../../oneweb/Bookings/kind";

const DynamicHeightComponents = {
        [Text]: true,
        [ContactForm]: true,
        [WebShop]: true,
        [Code]: true,
        [Image]: true,
        [Menu]: true,
        [Gallery]: true,
        [Table]: true,
        [LOGO_KIND]: true,
        [ADDRESS_KIND]: true,
        [EMAIL_KIND]: true,
        [PHONE_KIND]: true,
        [OPENING_HOURS_KIND]: true,
        [FeaturedProducts]: true,
        [ProductWidget]: true,
        [GOOGLE_REVIEWS]: true,
        [WidgetsBookingsKind]: true,
        [BookingsKind]: true,
        [BLOG]: true,
        [POST]: true
    },
    DynamicHeightContainers = {
        [Background]: true,
        [HoverBox]: true,
        [Strip]: true,
        [Section]: true,
        [Image]: true,
    },
    DynamicHeightComponentsWithContainers = {
        ...DynamicHeightComponents,
        ...DynamicHeightContainers
    };

export {
    DynamicHeightComponents,
    DynamicHeightContainers,
    DynamicHeightComponentsWithContainers,
    DynamicHeightComponentsWithContainers as default,
};
