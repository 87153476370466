/* global $ */

import * as R from 'ramda';
import { valueActionType as siteDataValueActionType } from "../../siteData/valueActionType";
import { receiveOnly, optional, optionalReceiveOnly } from "../../../../../epics/makeCondition";
import { STYLESHEETS_EPIC_VALUE } from '../../../../Workspace/epics/stylesheets/valueActionType';
import { TINY_MCE_DESTROYED, TINY_MCE_INITIALIZED, TINY_MCE_SELECTION_CHANGED } from "../actionTypes";
import { getEditorState } from "../editorUtils/getEditorState";
import { queryEditor } from "./helpers/queryEditor";
import { ReceiveOnlySelectedComponentSelector } from "../../../../Workspace/epics/componentsEval/selectorActionTypes";
import { selectAllText, putCursorAtTheEndOfEditor } from "../editorUtils/methods/helpers/selection";
import { UNDO, REDO } from '../../../../../epics/undoManager/actionTypes';
import { VALID_ROOT_BLOCK_TAGS, linkedValueTag } from '../../../../oneweb/Text/constants';
import { type TinyMceEpicUpdater, type CursorPosition } from "../flowTypes";
import { isTextNode } from '../../../../../utils/dom';
import { isLeftMouseDownVAT } from '../../../../Workspace/epics/isLeftMouseDown/valueActionType';
import { customSendReport } from '../../../../../customSendCrashReport';
import { COLOR_THEME_SITE_SETTINGS_EPIC_VALUE } from "../../../../SiteSettings/ColorThemeData/colorThemeSiteSettingsVAT";
import { WhenRepositoryDomainSelector } from '../../../../../redux/middleware/cookie/selectorActionTypes';

type NodeType = Node | null | undefined;

const ZWNBS = '\ufeff', // Zero Width Non-Breaking Space
    isLinkedValueElement = (node?: NodeType) => (node && node instanceof HTMLElement && node.matches(linkedValueTag)),
    getLinkedValueEltWhichStartsAtCursor = (editor: TinyMceEditor, strict: boolean): HTMLElement | null | undefined => {
        const { startContainer, startOffset, collapsed } = editor.selection.getRng();
        if (collapsed || !strict) {
            if (isLinkedValueElement(startContainer) && (startOffset === 0 || !strict)) {
                return startContainer as HTMLElement;
            } else if (isTextNode(startContainer) && (startOffset === 0 || !strict)) {
                if (isLinkedValueElement(startContainer.parentNode)) {
                    return startContainer.parentNode as HTMLElement;
                }
            } else if (startContainer instanceof HTMLElement) {
                const startChildNode = startContainer.childNodes[startOffset];
                if (startChildNode && isLinkedValueElement(startChildNode)) {
                    return startChildNode as HTMLElement;
                }
            }
        }
        return null;
    },
    getLinkedValueEltWhichEndsAtCursor = (editor: TinyMceEditor, strict: boolean): HTMLElement | null | undefined => {
        const { endContainer, endOffset, collapsed } = editor.selection.getRng();
        if (collapsed || !strict) {
            if (isLinkedValueElement(endContainer) && (endOffset === endContainer.childNodes.length || !strict)) {
                return endContainer as HTMLElement;
            } else if (isTextNode(endContainer) && (endOffset === endContainer.textContent?.length || !strict)) {
                if (isLinkedValueElement(endContainer.parentNode)) {
                    return endContainer.parentNode as HTMLElement;
                }
            } else if (endContainer instanceof HTMLElement) {
                const endChildNode = endContainer.childNodes[endOffset - 1];
                if (endChildNode && isLinkedValueElement(endChildNode)) {
                    return endChildNode as HTMLElement;
                }
            }
        }
        return null;
    },
    insertBefore = (nodeToInsert: Node, refNode: Node): void => {
        if (refNode.parentNode) {
            refNode.parentNode.insertBefore(nodeToInsert, refNode);
        }
    },
    insertAfter = (nodeToInsert: Node, refNode: Node): void => {
        const parentNode = refNode.parentNode;
        if (parentNode && parentNode instanceof HTMLElement) {
            if (refNode.nextSibling) {
                parentNode.insertBefore(nodeToInsert, refNode.nextSibling);
            } else {
                parentNode.append(nodeToInsert);
            }
        }
    },
    getDescendantTextNode = (node: Node): NodeType => {
        if (node.childNodes.length === 0) return null;

        let textNode: NodeType = null;
        for (const childNode of node.childNodes) {
            if (isTextNode(childNode)) {
                textNode = childNode;
                break;
            } else {
                textNode = getDescendantTextNode(childNode);
                if (textNode) break;
            }
        }
        return textNode;
    },
    previousSibling = (node: Node) => node.previousSibling,
    nextSibling = (node: Node) => node.nextSibling,
    getAdjacentTextNode = (node: NodeType, getSibling: (n: Node) => NodeType) => {
        let sibling: NodeType;
        while (!sibling) {
            if (!node) return null;
            if (node.nodeName === 'DIV') return null;

            if (getSibling(node)) {
                sibling = getSibling(node);
            } else {
                node = node.parentNode; // eslint-disable-line no-param-reassign
            }
        }

        if (isTextNode(sibling)) {
            return sibling;
        } else {
            const textNode = getDescendantTextNode(sibling);
            if (textNode) {
                return textNode;
            } else {
                return getAdjacentTextNode(sibling, getSibling);
            }
        }
    },
    getCursorPosition = (range: Range): CursorPosition | null | undefined => {
        if (range.collapsed && isTextNode(range.endContainer)) {
            return { container: range.endContainer, offset: range.endOffset };
        }
        return null;
    },
    isValidRootBlock = (elt: HTMLElement) => VALID_ROOT_BLOCK_TAGS.includes(elt.tagName),
    onTextSelectionChangedUpdater: TinyMceEpicUpdater = {
        conditions: [
            receiveOnly(siteDataValueActionType),
            receiveOnly(STYLESHEETS_EPIC_VALUE),
            ReceiveOnlySelectedComponentSelector,
            optionalReceiveOnly(isLeftMouseDownVAT),
            optional(WhenRepositoryDomainSelector),
            receiveOnly(COLOR_THEME_SITE_SETTINGS_EPIC_VALUE),
            TINY_MCE_SELECTION_CHANGED,
            optional(UNDO),
            optional(REDO),
        ],
        reducer: ({
            values: [site, stylesheets, selectedComponent, isSelectionChangedUsingMouse, isRepositoryDomain, { autoColorMode }],
            scope,
            state,
        }) => {
            if (isRepositoryDomain && isSelectionChangedUsingMouse) return { state, scope };

            if (state.ignoreNextProgrammaticSelectionChange) {
                return { state: { ...state, ignoreNextProgrammaticSelectionChange: false }, scope };
            }

            // component may have been already deselected by the time tinyMCE dispatchs selection changed action
            if (!selectedComponent) return { state, scope };
            const editorWidth = selectedComponent.width;

            const editor: TinyMceEditor | null | undefined = window.tinyMCE.activeEditor;
            if (!editor || editor.removed) return { state, scope };

            let currentRange: Range = editor.selection.getRng();

            if (currentRange.startContainer.nodeName === 'DIV' || currentRange.endContainer.nodeName === 'DIV') {
                const children = Array.from(editor.getBody().children),
                    firstSelectableChild = R.find(isValidRootBlock)(children),
                    lastSelectableChild = R.findLast(isValidRootBlock)(children),
                    newRange = currentRange.cloneRange();
                try {
                    newRange.setStartBefore(firstSelectableChild);
                    newRange.setEndAfter(lastSelectableChild);
                    editor.selection.setRng(newRange);
                    currentRange = editor.selection.getRng();
                } catch (e: any) {
                    // TODO: WBTGEN-12820: remove try catch once the source of the issue is identified
                    customSendReport({
                        message: `Error when modifying selection as it wraps DIV: ${Math.random()}`,    //NOSONAR
                        additionalInfo: {
                            errorMessage: e.message,
                            errorStack: e.stack,
                            firstSelectableChild,
                            lastSelectableChild,
                        },
                    });
                    throw e;
                }
            }

            if (!isRepositoryDomain) {
                return {
                    state: { ...state, ...getEditorState({ editor, site, stylesheets, editorWidth, autoColorMode }, true) },
                    scope,
                };
            }

            let { endContainer, endOffset, collapsed } = currentRange as any;

            editor.selection.getNode().normalize();

            // if arrow keys are used move cursor, and the cursor just hopped ZWNBS, then hop again programmatically
            if (
                collapsed
                && state.prevCursorPosition
                && isTextNode(endContainer)
                && endContainer === state.prevCursorPosition.container
                && Math.abs(endOffset - state.prevCursorPosition.offset) === 1
                && endContainer.textContent[Math.min(endOffset, state.prevCursorPosition.offset)] === ZWNBS
            ) {
                let newRangeContainer: any = null, newRangeOffset: number | null = null;
                if (endOffset > state.prevCursorPosition.offset) { // cursor moved right
                    if (endContainer.textContent.length === endOffset) { // cursor on the edge
                        newRangeContainer = getAdjacentTextNode(endContainer, nextSibling);
                        newRangeOffset = 0;
                    } else { // cursor not on the edge
                        newRangeContainer = endContainer;
                        newRangeOffset = endOffset + 1;
                    }
                } else if (endOffset === 0) { // cursor moved left and on the edge
                    newRangeContainer = getAdjacentTextNode(endContainer, previousSibling);
                    newRangeOffset = newRangeContainer && newRangeContainer.textContent.length;
                } else { // cursor moved left and not on the edge
                    newRangeContainer = endContainer;
                    newRangeOffset = endOffset - 1;
                }
                if (newRangeContainer !== null && newRangeOffset !== null) {
                    const newRange = currentRange.cloneRange();
                    newRange.setStart(newRangeContainer, newRangeOffset);
                    newRange.setEnd(newRangeContainer, newRangeOffset);
                    editor.selection.setRng(newRange);
                }
            }

            currentRange = editor.selection.getRng();
            let newState = { ...state, prevCursorPosition: getCursorPosition(editor.selection.getRng()) };

            // if the cursor ended up at the start of linkedValue, due to collapse of selection, move to previous node
            const linkedNodeThatStartsAtCursor = getLinkedValueEltWhichStartsAtCursor(editor, true);
            if (linkedNodeThatStartsAtCursor && !state.prevCursorPosition) {
                let linkedNodePreviousSibling = getAdjacentTextNode(linkedNodeThatStartsAtCursor, previousSibling);
                if (linkedNodePreviousSibling) {
                    const newRange = currentRange.cloneRange();
                    // @ts-ignore
                    newRange.setStart(linkedNodePreviousSibling, linkedNodePreviousSibling.textContent.length);
                    // @ts-ignore
                    newRange.setEnd(linkedNodePreviousSibling, linkedNodePreviousSibling.textContent.length);
                    editor.selection.setRng(newRange);
                    newState = {
                        ...newState,
                        ...getEditorState({ editor, site, stylesheets, editorWidth, autoColorMode }, true)
                    };
                    return {
                        state: {
                            ...newState,
                            ignoreNextProgrammaticSelectionChange: true,
                            prevCursorPosition: getCursorPosition(newRange)
                        },
                        scope
                    };
                }
            }

            // if the cursor ended up at the end of linkedValue, due to collapse of selection, move to next node
            const linkedNodeThatEndsAtCursor = getLinkedValueEltWhichEndsAtCursor(editor, true);
            if (linkedNodeThatEndsAtCursor && !state.prevCursorPosition) {
                let linkedNodeNextSibling = getAdjacentTextNode(linkedNodeThatEndsAtCursor, nextSibling);
                if (linkedNodeNextSibling) {
                    const newRange = currentRange.cloneRange();
                    newRange.setStart(linkedNodeNextSibling, 1);
                    newRange.setEnd(linkedNodeNextSibling, 1);
                    editor.selection.setRng(newRange);
                    newState = {
                        ...newState,
                        ...getEditorState({ editor, site, stylesheets, editorWidth, autoColorMode }, true)
                    };
                    return {
                        state: {
                            ...newState,
                            ignoreNextProgrammaticSelectionChange: true,
                            prevCursorPosition: getCursorPosition(newRange)
                        },
                        scope
                    };
                }
            }

            // if start of selection/cursor is in/at linkedValue, make newSelection to have entire linkedValue
            const newRng = currentRange.cloneRange();
            const linkedNodeAtStartOfSelection = getLinkedValueEltWhichStartsAtCursor(editor, false);
            if (linkedNodeAtStartOfSelection) {
                newRng.setStartBefore(linkedNodeAtStartOfSelection);
            }

            // if end of selection/cursor is in/at linkedValue, make newSelection to have entire linkedValue
            const linkedNodeAtEndOfSelection = getLinkedValueEltWhichEndsAtCursor(editor, false);
            if (linkedNodeAtEndOfSelection) {
                newRng.setEndAfter(linkedNodeAtEndOfSelection);
            }

            let linkedNode;
            if (linkedNodeAtStartOfSelection || linkedNodeAtEndOfSelection) {
                let ignoreNextProgrammaticSelectionChange = false;
                // if currentSelection differs from newSelection, set newSelection in editor
                if (
                    currentRange.compareBoundaryPoints(Range.START_TO_START, newRng)
                    || currentRange.compareBoundaryPoints(Range.END_TO_END, newRng)
                ) {
                    editor.selection.setRng(newRng);
                    ignoreNextProgrammaticSelectionChange = true;
                }
                if (linkedNodeAtStartOfSelection === linkedNodeAtEndOfSelection) {
                    linkedNode = linkedNodeAtStartOfSelection;
                }
                newState = {
                    ...newState,
                    ignoreNextProgrammaticSelectionChange,
                    prevCursorPosition: getCursorPosition(editor.selection.getRng())
                };
            }

            return {
                state: { ...newState, ...getEditorState({ editor, site, stylesheets, editorWidth, linkedNode, autoColorMode }, true) },
                scope
            };
        },
    },
    surroundLinkedNodeWithEmptyText = (linkedNodeElt: HTMLElement) => {
        let adjacentTextNode = getAdjacentTextNode(linkedNodeElt, previousSibling);
        if (!adjacentTextNode || isLinkedValueElement(adjacentTextNode.parentNode)) {
            adjacentTextNode = document.createTextNode('');
            insertBefore(adjacentTextNode, linkedNodeElt);
        }
        // @ts-ignore
        if (adjacentTextNode.textContent.slice(-1) !== ZWNBS) {
            adjacentTextNode.textContent += ZWNBS;
        }
        adjacentTextNode = getAdjacentTextNode(linkedNodeElt, nextSibling);
        if (!adjacentTextNode || isLinkedValueElement(adjacentTextNode.parentNode)) {
            adjacentTextNode = document.createTextNode('');
            insertAfter(adjacentTextNode, linkedNodeElt);
        }
        // @ts-ignore
        if (adjacentTextNode.textContent[0] !== ZWNBS) {
            adjacentTextNode.textContent = ZWNBS + adjacentTextNode.textContent;
        }
    },
    onTinyMceInitializedUpdater: TinyMceEpicUpdater = {
        conditions: [
            receiveOnly(siteDataValueActionType),
            receiveOnly(STYLESHEETS_EPIC_VALUE),
            ReceiveOnlySelectedComponentSelector,
            receiveOnly(COLOR_THEME_SITE_SETTINGS_EPIC_VALUE),
            TINY_MCE_INITIALIZED
        ],
        reducer: ({ values: [site, stylesheets, selectedComponent, { autoColorMode }, { removeFocus }], scope, state: prevState }) => {
            // component can be already deselected on the time tinyMCE dispatch initialized action
            if (!selectedComponent) {
                return { state: prevState, scope };
            }

            const state = queryEditor(editor => {
                if (editor.getContent()) {
                    // TODO selectedComponent.width is not correct for table case
                    Array.from<HTMLElement>(editor.getBody().querySelectorAll(linkedValueTag))
                        .forEach(surroundLinkedNodeWithEmptyText);
                    selectAllText(editor);
                } else {
                    putCursorAtTheEndOfEditor(editor);
                }

                if (removeFocus) {
                    setTimeout(() => $('.mce-content-body').blur(), 0);
                }

                return getEditorState({ editor, site, stylesheets, editorWidth: selectedComponent.width, autoColorMode }, true);
            });

            return {
                state: { ...(state || prevState), tinyMceExist: true },
                scope,
            };
        }
    },
    onTinyMceDestroyedUpdater: TinyMceEpicUpdater = {
        conditions: [TINY_MCE_DESTROYED],
        reducer: ({ state, scope }) => {
            return {
                state: { ...state, tinyMceExist: false },
                scope: { ...scope, lastEditModeComponentId: null }
            };
        }
    };

export {
    onTextSelectionChangedUpdater,
    onTinyMceInitializedUpdater,
    onTinyMceDestroyedUpdater,
    surroundLinkedNodeWithEmptyText,
};
