import React from 'react';
import { connect } from "react-redux";
import styles from './FileChooserBody.css';
import { ViewModes } from "../constants";
import List from '../FileChooserList/index';
import Grid from '../FileChooserGrid/index';
import {
    resourcesSelector, ghostResourcesSelector, previewResourceSelector
} from "../../../../redux/modules/children/fileChooser/selectors";
import { Msg } from '../../../intl/index';
import Resource from "../../../../redux/modules/children/fileChooser/Resource";
import type { AppState } from "../../../../redux/modules/flowTypes";
import LoadingIndicator from '../../LoadingIndicator/index';
import type { FileChooserBodyProps } from "../flowTypes";
import { ScaledWebspaceImage } from "../../../../components/presentational/ScaledWebspaceImage/ScaledWebspaceImage";
import getStyleIntValue from "../../../../utils/getStyleIntValue";
import { FileUploader } from '../../../../components/FileUploader/index';
import { uploadFromComputerAction } from "../../../../redux/modules/children/fileChooser/actionCreators/index";

const
    PREVIEW_WIDTH = getStyleIntValue(styles, 'previewWidth'),
    PREVIEW_HEIGHT = getStyleIntValue(styles, 'previewHeight');
const
    dropTitleMessage = 'msg: FileChooser.dropZone.title {Drag and drop your files here}',
    dropHintMessage = 'msg: FileChooser.dropZone.hint {or click the \"Upload\" button to choose from computer}';

const isListView = (viewMode: string, activeViewMode: string): boolean => {
    return (viewMode === ViewModes.COMBINED && activeViewMode === ViewModes.LIST) || viewMode === ViewModes.LIST;
};

class FileChooserBody extends React.Component<FileChooserBodyProps> {
    onKeyUp = event => {
        if (event.keyCode === 13 && this.props.selection.length > 0) {
            this.props.onResourceDoubleClick();
        }
    };

    componentDidMount() {
        window.addEventListener("keyup", this.onKeyUp, false);
    }

    componentWillUnmount() {
        window.removeEventListener("keyup", this.onKeyUp, false);
    }

    renderResourcesView() {
        const {
            viewMode,
            activeViewMode,
            isLoading,
            resources,
            ghostResources,
            listTableHeight,
            gridHeight,
            gridGutter,
            gridRowSize,
            gridContainerClassName,
            ...otherProps
        } = this.props;
        let view,
            allResources = ghostResources ? [...resources, ...ghostResources] : resources;

        if (isLoading) {
            view = <LoadingIndicator />;
        } else {
            const listView = isListView(viewMode, activeViewMode);
            if (listView) {
                // @ts-ignore
                view = <List tableHeight={listTableHeight} {...otherProps} />;
            } else {
                view = (
                    <FileUploader
                        showOverlay={!allResources.length}
                        hideMessage={!!allResources.length}
                        onDrop={this.onDrop}
                        title={dropTitleMessage}
                        hint={dropHintMessage}
                    >
                        <Grid
                            {...otherProps}
                            // @ts-ignore
                            height={gridHeight}
                            gutter={gridGutter}
                            rowSize={gridRowSize}
                            containerClassName={gridContainerClassName}
                        />
                    </FileUploader>
                );
            }
        }

        return <div className={styles.resourcesBox}>{view}</div>;
    }

    renderPreview() {
        const { showPreview, previewResource, viewMode, activeViewMode } = this.props;

        if (showPreview && isListView(viewMode, activeViewMode)) {
            const resource: Resource = previewResource;

            return (
                <div className={styles.previewBox}>
                    {
                        resource && resource.isImage()
                            ? (
                                <ScaledWebspaceImage
                                    path={resource._getFullPath()}
                                    width={PREVIEW_WIDTH}
                                    height={PREVIEW_HEIGHT}
                                    className={styles.previewImg}
                                />
                            )
                            : (
                                <div className={styles.previewMsg}>
                                    <Msg k="fc.noImagesSelected">No image selected</Msg>
                                </div>
                            )
                    }
                </div>
            );
        }

        return null;
    }

    onDrop = (e: DragEvent, files: FileList) => {
        this.props.dispatch(uploadFromComputerAction(files));
    };

    render() {
        return (
            <div className={styles.container}>
                {this.renderResourcesView()}
                {this.renderPreview()}
            </div>
        );
    }
}

const mapStateToProps = ({ fileChooser }: AppState) => ({
    resources: resourcesSelector(fileChooser),
    ghostResources: ghostResourcesSelector(fileChooser),
    previewResource: previewResourceSelector(fileChooser),
    activeViewMode: fileChooser.activeViewMode,
    selection: fileChooser.selection,
});

// @ts-ignore
export default connect(mapStateToProps)(FileChooserBody);
