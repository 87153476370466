import { depthSorter } from "./flattening/util";
import { WebshopKind } from "../oneweb/WebShop/kind";
import { isSectionKind } from "../oneweb/componentKinds";
import isGhost from "../oneweb/Code/isGhost";
import { isContainer } from "../../utils/component";
import { divideInToGroups } from "../oneweb/Section/epics/splitSectionDecoration/divideInToGroups";
import { WEBSHOP_MIN_HEIGHT } from "../oneweb/WebShop/constants";
import type { AnyComponent, ComponentsMap } from "../../redux/modules/children/workspace/flowTypes";

const
    getCmpBottom = (cmp: AnyComponent): number => cmp.top + cmp.height,
    getCmpCenter = (cmp: AnyComponent): number => cmp.top + (cmp.height / 2),
    _updateWebshopHeight = (cmpsMap: ComponentsMap, minWebshopHeight: number): ComponentsMap => {
        try {
            let
                allCmps: AnyComponent[] = [],
                webshop: AnyComponent | null = null,
                parentChildMap: Record<string, any> = {},
                childParentMap: Record<string, any> = {},
                sections: AnyComponent[] = [];
            Object.keys(cmpsMap).forEach(id => {
                const cmp = cmpsMap[id], isGhostCmp = isGhost(cmp);
                if (cmp.kind === WebshopKind) {
                    webshop = cmp;
                }
                if (cmp.relIn && cmp.relIn.id && !isGhostCmp) {
                    childParentMap[cmp.id] = cmp.relIn.id;

                    if (!parentChildMap[cmp.relIn.id]) {
                        parentChildMap[cmp.relIn.id] = [];
                    }

                    parentChildMap[cmp.relIn.id].push(cmp.id);
                }
                if (isSectionKind(cmp.kind)) {
                    sections.push(cmp);
                }
                if (!isGhostCmp) {
                    allCmps.push(cmp);
                }
            });

            if (!webshop || !webshop.relIn || webshop.height <= minWebshopHeight) {
                return cmpsMap;
            }
            const
                webshopVCenter = getCmpCenter(webshop),
                section = sections.find(s => webshopVCenter > s.top && webshopVCenter < getCmpBottom(s));
            if (!section) {
                return cmpsMap;
            }
            let
                childs = parentChildMap[section.id],
                cmps = [section.id],
                childsMap = {};

            for (const child of childs) {
                if (!childsMap[child]) {
                    cmps.push(child);
                    childsMap[child] = true;
                }

                if (parentChildMap[child]) {
                    childs.push(...parentChildMap[child]);
                }
            }
            cmps = cmps.map(id => cmpsMap[id]);

            let newCmpsMap = { ...cmpsMap, [webshop.id]: { ...webshop, height: minWebshopHeight } };
            const process = (cmp, childId) => {
                let childs = parentChildMap[cmp.id].map(id => cmpsMap[id]),
                    groups = divideInToGroups(childs),
                    groupIndex = groups.findIndex(g => g.cmps.find(c => c.id === childId)),
                    group = groups[groupIndex],
                    subGroups = divideInToGroups(group.cmps.map(c => newCmpsMap[c.id])),
                    diff = group.bottom - subGroups[subGroups.length - 1].bottom;

                if (webshop.id === childId) {
                    newCmpsMap[webshop.id] = {
                        ...newCmpsMap[webshop.id],
                        height: cmpsMap[webshop.id].height - diff
                    };
                }
                groups.forEach((g, i) => {
                    if (i > groupIndex) {
                        g.cmps.forEach(c => {
                            newCmpsMap[c.id] = {
                                ...newCmpsMap[c.id],
                                top: newCmpsMap[c.id].top - diff,
                                relIn: {
                                    ...newCmpsMap[c.id].relIn,
                                    top: newCmpsMap[c.id].relIn.top - diff
                                }
                            };
                        });
                    } else {
                        g.cmps.forEach(c => {
                            if (c.id !== childId) {
                                newCmpsMap[c.id] = {
                                    ...newCmpsMap[c.id],
                                    relIn: {
                                        ...newCmpsMap[c.id].relIn,
                                        bottom: newCmpsMap[c.id].relIn.bottom + diff
                                    }
                                };
                            }
                        });
                    }
                });
                newCmpsMap[cmp.id] = {
                    ...newCmpsMap[cmp.id],
                    height: newCmpsMap[cmp.id].height - diff
                };
            };
            let foundWebshopParent = false, firstParent = true, previousProcessedCmpId = null;
            cmps = cmps.sort(depthSorter).reverse();
            cmps.forEach(cmp => {
                if (cmp.id === webshop.relIn.id) {
                    foundWebshopParent = true;
                }
                if (foundWebshopParent && isContainer(cmp.kind)) {
                    process(cmp, firstParent ? webshop.id : previousProcessedCmpId);
                    previousProcessedCmpId = cmp.id;
                    firstParent = false;
                }
            });
            const totalDiff = cmpsMap[section.id].height - newCmpsMap[section.id].height,
                sectionBottom = getCmpBottom(section);
            allCmps.forEach(c => {
                const cmp = newCmpsMap[c.id];
                if (getCmpCenter(cmp) > sectionBottom) {
                    newCmpsMap[c.id] = {
                        ...cmp,
                        top: cmp.top - totalDiff
                    };
                }
            });
            return newCmpsMap;
        } catch (e: any) {
            console.log('Webshop height detection issue', e);
            return cmpsMap;
        }
    },
    updateWebshopHeight = (cmpsMap: ComponentsMap): ComponentsMap => _updateWebshopHeight(cmpsMap, WEBSHOP_MIN_HEIGHT);

export {
    _updateWebshopHeight,
    updateWebshopHeight
};
