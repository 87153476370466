import React from "react";
import { useDispatch } from "react-redux";
import { Dialog, DialogBody, StripTypes } from "../../../../view/common/dialogs/baseDialog";
import getCenteredDialogConfig from "../../../DialogManager/getCenteredDialogConfig";
import { getCenterPositionForDialog } from "../../../DialogManager/utility";
import { Msg } from "../../../../view/intl";
import { PrimaryButton, SecondaryButton } from "../../../../view/common/Button";
import styles from "./EmptyBlogDialog.css";
import { closeDialog } from "../../../App/actionCreators";
import { PUBLISH } from "../../../App/actionTypes";

// This dialog is opened when a user with blog page tries to publish without any published posts
const EmptyBlogDialog = () => {
    const dispatch = useDispatch();

    const onCancelClick = () => {
        dispatch(closeDialog());
    };

    const onPublishClick = () => {
        dispatch({ type: PUBLISH });
    };

    return (
        <React.Fragment>
            <Dialog stripType={StripTypes.NONE}>
                <DialogBody className={styles.dialogContainer} data-testid="dialog-body">
                    <div className={styles.heading} data-testid="dialog-heading">
                        <Msg k="publishDialog.emptyBlog.heading">Your blog is empty</Msg>
                    </div>
                    <div className={styles.description}>
                        <Msg k="publishDialog.emptyBlog.description">
                            You haven’t created any posts to show in the blog section on your
                            website. If you publish your website now, the blog section will be
                            empty.
                        </Msg>
                    </div>
                    <div className={styles.buttonsContainer}>
                        <SecondaryButton
                            onClick={onCancelClick}
                            className={styles.button}
                            isLarge
                            data-testid="cancel-button"
                        >
                            <Msg k="common.cancel">Cancel</Msg>
                        </SecondaryButton>
                        <PrimaryButton
                            onClick={onPublishClick}
                            className={styles.button}
                            isLarge
                            data-testid="publish-button"
                        >
                            <Msg k="common.publishAnyway">Publish anyway</Msg>
                        </PrimaryButton>
                    </div>
                </DialogBody>
            </Dialog>
        </React.Fragment>
    );
};

const config = {
    ...getCenteredDialogConfig({
        component: EmptyBlogDialog,
        dragEnabled: false
    }),

    // set the general dialog parameters in configuration factory
    confFactory: ({ browserWidth, browserHeight }) => {
        const width = 700;
        const height = 240;

        const { left, top } = getCenterPositionForDialog(
            width,
            height,
            browserWidth,
            browserHeight
        );

        return {
            dimensions: { width, height },
            position: { left, top },
            // modal is set so the dialog is not closed if clicked outside
            // only the x or Cancel will close the dialog
            modal: true
        };
    }
};

export default config;
