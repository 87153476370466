import { Dispatch } from "redux";
import type { AppStore } from "../../../../redux/modules/flowTypes";
import {
    EXCHANGE_FOR_FB_CHAT_LONG_ACCESS_TOKEN_FAILURE_ACTION,
    EXCHANGE_FOR_FB_CHAT_LONG_ACCESS_TOKEN_SUCCESS_ACTION,
    INITIATE_FB_CHAT_EXCHANGE_TOKEN_ACTION,
    GET_FB_CHAT_OWN_MANAGED_ACCOUNTS_SUCCESS_ACTION,
    GET_FB_CHAT_OWN_MANAGED_ACCOUNTS_FAILURE_ACTION,
    setFacebookChatAccessCredentialsAction,
    exchangeForFBChatLongAccessTokenAction,
    setManagedFbChatPagesAction,
    setFbChatPageValue,
    SET_FACEBOOK_CHAT_ACCESS_CREDENTIALS_ACTION,
    GET_FACEBOOK_CHAT_ACCESS_TOKEN_SUCCESS_ACTION,
    GET_FACEBOOK_CHAT_ACCESS_TOKEN_FAILURE_ACTION,
    DISCONNECT_FACEBOOK_CHAT_SUCCESS_ACTION,
} from "./actions";
// import { setFacebookChatAccessCredentialsInDemoAction } from "./trialActions";
import { addInfoMessage } from "../../../Toaster/actionCreators";
import { openDialogAction } from "../../../../../../src/dashboard/src/components/Dialog/actions";
import {
    FACEBOOK_NO_PAGES_SELECTED_DIALOG_ID
} from "../../../../../../src/dashboard/src/components/Pages/MessengerChat/FacebookNoPagesSelectedDialog/FacebookNoPagesSelectedDialog";
import {
    FACEBOOK_CHAT_MULTIPLE_PAGES_DIALOG_ID
} from "../../../../../../src/dashboard/src/components/Pages/MessengerChat/FacebookChatMultiplePagesDialog/FacebookChatMultiplePagesDialog";
import {
    MESSNGER_CHAT_SET_DIALOG_ID
} from "../../../../../../src/dashboard/src/components/Pages/MessengerChat/MessengerChatSetDialog/MessengerChatSetDialog";
import { unsetCookie, setCookie } from "../../../../services/cookie";
import { FACEBOOK_FEED_ACCOUNT_DOMAIN_NAME } from "../../../../../../server/shared/facebookFeed/facebookFeedCookie.js";
import { getDAL } from "../../../../../dal/index";
import { openChatWidgetInSiteSettings } from "./actionSelector";
import { fetchTokenMeAccountsData } from "../facebookCommonAction";
import { currentSiteSettingsAppSel } from "../../../App/epics/siteSettings/selectorActionTypes";
import { Lit } from '../../../../lit';
import { isDashboard } from "../../../../../../src/dashboard/src/utils/dasboardUtils";
import { chatWidgetDataSelector } from "../../../../../../src/dashboard/src/common/selectors";

// import { isWsbDemo } from "../../../../debug/isWsbDemo";
// import { TOKEN_EXPIRED_MESSAGE } from "../../../../../../server/shared/facebookFeed/constants";
// import { createScheduledAction } from "../../../../redux/middleware/schedule/actionCreators";
// import FacebookChatDisconnectedDialogId from "./FacebookChatDisconnectedDialog/facebookChatDisconnectedDialogId";

export const facebookChatMiddleware = (store: AppStore) => (next: Dispatch) => (action: Action) => {
    if (action.type === INITIATE_FB_CHAT_EXCHANGE_TOKEN_ACTION) {
        setCookie(FACEBOOK_FEED_ACCOUNT_DOMAIN_NAME, getDAL().getDomain(), { domain: document.location.hostname }, true);
        fetchTokenMeAccountsData({
            dispatch: store.dispatch,
            successAction: GET_FB_CHAT_OWN_MANAGED_ACCOUNTS_SUCCESS_ACTION,
            failureAction: GET_FB_CHAT_OWN_MANAGED_ACCOUNTS_FAILURE_ACTION
        });
    }

    if (action.type === GET_FB_CHAT_OWN_MANAGED_ACCOUNTS_SUCCESS_ACTION) {
        const response = action.payload;
        if (response && response.data) {
            store.dispatch(setManagedFbChatPagesAction(response.data));
        }

        if (response && response.data && response.data.length > 0) {
            store.dispatch(setFbChatPageValue(response.data[0].access_token));
        }

        if (response && response.data && response.data.length > 1) {
            store.dispatch(openDialogAction(FACEBOOK_CHAT_MULTIPLE_PAGES_DIALOG_ID));
        } else if (response && response.data && response.data.length === 1) {
            const accessToken = response.data[0].access_token;
            store.dispatch(exchangeForFBChatLongAccessTokenAction(accessToken));
            openChatWidgetInSiteSettings(store.dispatch, true, !isDashboard(store.getState()));
        } else {
            store.dispatch(openDialogAction(FACEBOOK_NO_PAGES_SELECTED_DIALOG_ID));
        }
    }

    if (action.type === EXCHANGE_FOR_FB_CHAT_LONG_ACCESS_TOKEN_SUCCESS_ACTION) {
        const {
            access_token: longLivedAccessToken,
            fbUsername,
            // eslint-disable-next-line camelcase
            //expires_in,
            fbAccountId,
        } = action.payload;
        unsetCookie(FACEBOOK_FEED_ACCOUNT_DOMAIN_NAME, { domain: '' }, true);
        store.dispatch(setFacebookChatAccessCredentialsAction(longLivedAccessToken, fbUsername, fbAccountId));
        // if (isWsbDemo()) {
        //     if (isPageAccountBool) {
        //         store.dispatch(setFacebookChatAccessCredentialsInDemoAction(managedFbAccounts));
        //     } else {
        //         store.dispatch(setFacebookChatAccessCredentialsInDemoAction([{
        //             access_token: longLivedAccessToken,
        //             id: fbAccountId,
        //             isVisible: true,
        //             name: fbUsername,
        //             expires_in,
        //             isPageAccount: isPageAccountBool
        //         }]));
        //     }
        // }
    }

    if (action.type === EXCHANGE_FOR_FB_CHAT_LONG_ACCESS_TOKEN_FAILURE_ACTION) {
        unsetCookie(FACEBOOK_FEED_ACCOUNT_DOMAIN_NAME, { domain: '' }, true);
    }

    if (action.type === SET_FACEBOOK_CHAT_ACCESS_CREDENTIALS_ACTION) {
        // TODO:(prashant) fetch profile pic
        const state = store.getState();
        let chatWidgetData;
        if (isDashboard(state)) {
            // @ts-ignore
            chatWidgetData = chatWidgetDataSelector(state);
        } else {
            chatWidgetData = currentSiteSettingsAppSel([Lit.chatWidgetData])(store.getState());
        }

        if (chatWidgetData && !chatWidgetData.seenChatWidgetSetDialog) {
            store.dispatch(openDialogAction(MESSNGER_CHAT_SET_DIALOG_ID));
        }
    }

    if (action.type === GET_FACEBOOK_CHAT_ACCESS_TOKEN_SUCCESS_ACTION) {
        store.dispatch(
            setFacebookChatAccessCredentialsAction(
                action.payload.accessToken,
                action.payload.fbUsername,
                action.payload.fbAccountId
            )
        );
        openChatWidgetInSiteSettings(store.dispatch, true, false);
    }

    if (action.type === DISCONNECT_FACEBOOK_CHAT_SUCCESS_ACTION) {
        if (!isDashboard(store.getState())) {
            store.dispatch(addInfoMessage(
                "cookie.banner.footer.tipText",
                "msg: cookie.banner.footer.tipText {Remember to publish your site to make these changes live.}"
            ));
        }
    }
    if (action.type === GET_FACEBOOK_CHAT_ACCESS_TOKEN_FAILURE_ACTION) {
        const chatWidgetData = currentSiteSettingsAppSel([Lit.chatWidgetData])(store.getState());
        if (chatWidgetData && chatWidgetData.enableChatWidget) {
            openChatWidgetInSiteSettings(store.dispatch, false, false);
        }
    }

    return next(action);
};
