import { v4 } from "uuid";
import React, { useEffect, useState } from "react";
import { getBookingsScriptUrl } from "../utils";

const View = (props) => {
    const [uuid] = useState(v4());
    const { locale, bookingParams, isServerPreview } = props;
    useEffect(() => {
        if (!window.refreshBookings) { return; }
        window.refreshBookings();
    }, [bookingParams]);
    return (
        <div style={{ height: "100%", width: "100%" }} data-testid="bookings-view">
            <script defer src={getBookingsScriptUrl(isServerPreview, locale)}> </script>
            <onecom-bookings-main
                id={uuid}
                class="onecom-bookings-main"
                params={bookingParams}
            />
        </div>
    );
};

export default View;

