import React, { useState } from "react";
import { useSelector } from "react-redux";
import cx from "classnames";
import styles from "../OnboardingWizard.css";
import { Msg, useIntl } from "../../../../../view/intl";
import { OPEN_AI_PRIVACY_LINK, OPEN_AI_TERMS_LINK } from "../../../../../constants/app";
import { AICategoryType, AIInputFieldId, OnboardingStepProps } from "../types";
import { selectLanguages } from "../../../Text/epics/AITextEpic/selectors";
import { BlogCategorySelector } from "./BlogCategorySelector";
import { AIInputField } from "./AIInputField";
import { CheckBox } from "../../../../../view/common/CheckBox";
import { PrimaryButton, SecondaryButton } from "../../../../../view/common/Button";
import { DropdownSelector } from "./DropdownSelector";
import { BLOG_AI_INPUT_UPDATE } from "../epic/actionTypes";
import { selectOpenAITermsState } from "../epic/selectors";

/**
 * Step to enter AI data for the generated posts
 */
export const StepAI = ({
    dispatch,
    setStartOnboarding,
    currentStep,
    setCurrentStep,
    aiData,
    setAiData
}: OnboardingStepProps) => {
    const intl = useIntl();
    const supportedLanguages = useSelector(selectLanguages);
    const agreedToOpenAITerms = useSelector(selectOpenAITermsState);

    const [isCategoryMissing, setCategoryMissing] = useState<boolean>(false);
    const [isTermsCheckMissing, setTermsCheckMissing] = useState<boolean>(false);
    const [isOpenAITermsChecked, setOpenAITermsChecked] = useState<boolean>(agreedToOpenAITerms);

    const cancelOnboarding = () => {
        setStartOnboarding(false);
        setCurrentStep(0);
    };

    const goToNextStep = () => {
        // check if the required fields are set, display error message if not
        setCategoryMissing(aiData.category.label.length === 0);
        setTermsCheckMissing(!isOpenAITermsChecked);

        const isSomethingMissing = aiData.category.label.length === 0 || !isOpenAITermsChecked;

        // only allow the user to proceed once the required fields are set
        if (!isSomethingMissing) {
            dispatch({ type: BLOG_AI_INPUT_UPDATE, payload: aiData });
            setCurrentStep(currentStep + 1);
        }
    };

    const handleTextInput = (inputId: AIInputFieldId, value: string) => {
        setAiData({ ...aiData, [inputId]: value });
    };

    const handleCategoryInput = (category: AICategoryType) => {
        setAiData({ ...aiData, category });
    };

    const handleLanguageSelect = (selectedOption: string) => {
        setAiData({ ...aiData, language: selectedOption });
    };

    const terms = (
        <a href={OPEN_AI_TERMS_LINK} target="_blank">
            <Msg k="common.termsAndConditions">terms and conditions</Msg>
        </a>
    );
    const privacy = (
        <a href={OPEN_AI_PRIVACY_LINK} target="_blank">
            <Msg k="common.privacyPolicy">privacy policy</Msg>
        </a>
    );

    return (
        <div data-testid="step-ai-container" className={styles.stepContainer}>
            <div>
                <div className={styles.title} data-testid="step-ai-title">
                    <Msg k="component.blog.onboarding.step.ai.title">Tell us about your blog</Msg>
                </div>
                <div className={styles.stepDescription} data-testid="step-ai-description">
                    <Msg k="component.blog.onboarding.step.ai.description">
                        Tell us more about your blog and the topics you would like to cover. We’ll
                        use this information to write your first blog posts.
                    </Msg>
                </div>
                <div>
                    {/*
                        Category
                    */}
                    <BlogCategorySelector
                        id={AIInputFieldId.category}
                        category={aiData.category}
                        label={intl.msgJoint(
                            "msg: component.blog.onboarding.step.ai.category {What’s your website category?}"
                        )}
                        onInputChange={handleCategoryInput}
                        errorText={
                            isCategoryMissing
                                ? intl.msgJoint(
                                    "msg: component.blog.onboarding.step.ai.category.missing {Choose a category for your website.}"
                                )
                                : null
                        }
                    />
                    {/*
                        Website name
                    */}
                    <AIInputField
                        inputId={AIInputFieldId.websiteName}
                        inputValue={aiData.websiteName}
                        label={intl.msgJoint(
                            "msg: component.blog.onboarding.step.ai.websiteName {What’s your website title? (optional)}"
                        )}
                        onInputChange={handleTextInput}
                    />
                    {/*
                        Location
                    */}
                    <AIInputField
                        inputId={AIInputFieldId.location}
                        inputValue={aiData.location}
                        label={intl.msgJoint(
                            "msg: component.blog.onboarding.step.ai.location {Location (optional)}"
                        )}
                        onInputChange={handleTextInput}
                    />
                    {/*
                        Language
                    */}
                    <div className={styles.inputContainer}>
                        <label>
                            <Msg k="component.blog.onboarding.step.ai.language">Blog language</Msg>
                        </label>
                        <DropdownSelector
                            options={supportedLanguages.map}
                            selectedOption={aiData.language}
                            onOptionSelect={handleLanguageSelect}
                        />
                    </div>
                    {/*
                        Topics
                    */}
                    <AIInputField
                        inputId={AIInputFieldId.topics}
                        inputValue={aiData.topics}
                        label={intl.msgJoint(
                            "msg: component.blog.onboarding.step.ai.topics {What is your blog about? (optional)}"
                        )}
                        onInputChange={handleTextInput}
                        multiline
                    />
                    {/*
                        T&C checkbox - only visible if the user hasn't already accepted the terms previously
                    */}
                    {!agreedToOpenAITerms && (
                        <div className={styles.checkboxContainer}>
                            <CheckBox
                                isChecked={isOpenAITermsChecked}
                                onClick={() => {
                                    setOpenAITermsChecked(!isOpenAITermsChecked);
                                }}
                                error={isTermsCheckMissing}
                                checkboxProps={{ "data-testid": "terms-checkbox" }}
                            />
                            <label
                                className={cx(styles.termsText, {
                                    [styles.error]: isTermsCheckMissing
                                })}
                                onClick={() => {
                                    setOpenAITermsChecked(!isOpenAITermsChecked);
                                }}
                                data-testid="terms-label"
                            >
                                <Msg
                                    k="component.text.createAIText.dialog.textBuilder.terms"
                                    params={{ terms, privacy }}
                                >
                                    {`I understand and agree to the {terms} and {privacy} of OpenAI.`}
                                </Msg>
                            </label>
                        </div>
                    )}
                </div>
            </div>
            <div>
                <SecondaryButton
                    onClick={() => cancelOnboarding()}
                    className={styles.wizardButton}
                    isLarge
                    data-testid="back-button"
                >
                    <Msg k="common.back">Back</Msg>
                </SecondaryButton>
                <PrimaryButton
                    onClick={() => goToNextStep()}
                    className={styles.wizardButton}
                    isLarge
                    data-testid="continue-button"
                >
                    <Msg k="common.continue">Continue</Msg>
                </PrimaryButton>
            </div>
        </div>
    );
};
