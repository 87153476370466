import React from 'react';
import Msg from '../../../../view/intl/Msg';
import styles from './style.css';
import {
    CLOSE_TOOLTIP
} from "../../../Tooltip/actionTypes";
import { SHOW_WEBSHOP_ADMIN_DIALOG } from "../../WebShop/WebshopAdminDialog/actionTypes";
import { PRODUCT_WIDGET_UPDATE_PRODUCT_SETUP_WIDGET_METHOD } from '../actionTypes';

type Props = {
    dispatch: Function,
    componentId: string
}

export const SetupProductTooltip = (props: Props) => {
    const { dispatch, componentId } = props;
    return (
        <div className={styles.tooltipBody}>
            <div className={styles.tooltipContent}>
                <div className={styles.tootltipTitle}>
                    <Msg
                        k="component.webshop.productWidget.tooltipTitle"
                    >
                        Set up your product first
                    </Msg>
                </div>
                <div className={styles.tootltipMessage}>
                    <Msg
                        k="component.webshop.productWidget.tooltipMessage"
                    >
                        Start selling directly from your website by setting up
                        your first product and making it ready for sale.
                    </Msg>
                </div>
                <div className={styles.tootltipActions}>
                    <button
                        className={styles.tootltipButton}
                        type="button"
                        onClick={() => {
                            dispatch({
                                type: PRODUCT_WIDGET_UPDATE_PRODUCT_SETUP_WIDGET_METHOD,
                                payload: { componentId }
                            });
                            dispatch({ type: SHOW_WEBSHOP_ADMIN_DIALOG });
                            dispatch({ type: CLOSE_TOOLTIP });
                        }}
                    >
                        <Msg
                            k="component.webshop.productWidget.setupProduct"
                        >
                            Set up product
                        </Msg>
                    </button>
                    <a
                        className={styles.tootltipLink}
                        onClick={() => {
                            dispatch({ type: CLOSE_TOOLTIP });
                        }}
                    >
                        <Msg k="common.notNow">Not now</Msg>
                    </a>
                </div>
            </div>
            <div className={styles.tooltipImageContainer}>
                <div className={styles.tooltipImage} />
            </div>
        </div>
    );
};
