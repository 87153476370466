import { getDAL, WEBSPACE_PREFIX } from "../../../../dal/index";
import { DATA_PAGE_REF_TYPE, DATA_LINK_PAGE_TYPE, DATA_LINK_SECTION_TYPE } from "../../../../dal/model/utils/dataSiteItemTypes";
import type { DataSiteItem } from "../../../../dal/model/utils/dataSiteItemUtils";
import { DataSiteItemId } from "../../../../dal/constants";
import findPageRecursively from "../Link/utils/findPageRecursively";
import { JS_VOID_VALUE } from "../../../constants";
import menuDefaultPageState from "./menuDefaultPageState";

const getPageId = (id: string, items: DataSiteItem[]) => {
    if (!id || items.length === 0) {
        return null;
    }

    const childItems: any = [];

    for (const item of items) {
        // @ts-ignore
        if (item.id === id && item.pageId) {
            // @ts-ignore
            return item.pageId;
            // @ts-ignore
        } else if (item.items instanceof Array) {
            // @ts-ignore
            childItems.push(...item.items);
        }
    }

    return getPageId(id, childItems);
};

const getPageHref = (dataSiteItem: DataSiteItem, items: Array<DataSiteItem>, previewBackupTime?: number | null) => {
    const query = previewBackupTime
        ? {
            time: previewBackupTime,
            previewbackup: true
        }
        : {};

    if (dataSiteItem.type === DATA_PAGE_REF_TYPE) {
        if (dataSiteItem.url === menuDefaultPageState.url && dataSiteItem.pageId === menuDefaultPageState.pageId) {
            return undefined;
        }

        return getDAL().makePagePreviewUrl(dataSiteItem.pageId, "html", query);
    }

    if (dataSiteItem.type === DATA_LINK_PAGE_TYPE) {
        if (dataSiteItem.linkId) {
            const pageId = getPageId(dataSiteItem.linkId, items);

            if (pageId) {
                return getDAL().makePagePreviewUrl(pageId, "html", query);
            }
        }

        return dataSiteItem.url.replace(WEBSPACE_PREFIX, `/api/v1/${getDAL().getDomain()}/webspace`);
    }

    if (dataSiteItem.id === DataSiteItemId.MORE_CHILD_ID) {
        return JS_VOID_VALUE;
    }

    if (dataSiteItem.type === DATA_LINK_SECTION_TYPE) {
        const { pageId } = dataSiteItem,
            page = findPageRecursively(items, pageId, "pageId");

        if (page && page.public) {
            const sectionId = dataSiteItem.sectionId,
                pageHref = getDAL().makePagePreviewUrl(pageId, "html");

            let result = pageHref;
            if (sectionId) {
                result = `${pageHref}#${sectionId}`;
            }
            return result;
        }

        return undefined;
    }

    // @ts-ignore
    throw new Error(`unknown page type: ${dataSiteItem.type}`);
};

export default getPageHref;
