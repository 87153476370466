import * as R from 'ramda';
import { diff } from 'objectdiff';
import epicsRegistry from './epicsRegistry';
import epicsMap from './epicsMap';
import { ActionType } from './makeCondition';
import { traceEnabled, deepStateCompare, extendedTraceEnabled } from '../utils/isDebug';
import { memoUnlimited } from '../../utils/memo';
import { info, warn, trace, error } from '../../utils/log.js';
import CALL_API from "../redux/middleware/api/CALL_API";
import actionTypeDoesntMatchAction from "./actionTypeDoesntMatchAction";

import type {
    DelayEpicsUpdatersExecQueueMap,
    EpicsState, EpicsStateUpdate, EpicStateUpdate, EpicUpdaterConfig,
    UpdaterStateUpdate
} from './flowTypes';
import type { ExData } from "../debug/flowTypes";
import { MatchAnyActionType } from "./constants";
import { bannedEpicsVats } from "./bannedEpics";
import isTestEnv from "../debug/isTestEnv";
import { NaNErrorName } from "../utils/error";
import { registerException } from "../debug/index";
import { SCHEDULE_ACTION } from '../redux/middleware/schedule/actionTypes';
import { skipTraceActions } from '../debug/skipTraceActions';

type Subscription = {
    valueActionType: string,
    condition: ActionType,
    updaterIndex: number,
    conditionIndex: number
}

type SubscriptionsMap = {
    [actionType: string]: Array<Subscription>
}

const
    _traceEnabled = traceEnabled(),
    subscriptionsArray: Array<Subscription> = R.flatten(epicsRegistry.map(
        ({ valueActionType, updaters }) =>
            updaters.map(({ conditions }, updaterIndex) =>
                conditions.map((condition, conditionIndex) => ({
                    valueActionType,
                    condition,
                    updaterIndex,
                    conditionIndex
                })))
    )),
    subscriptionsMap: SubscriptionsMap = subscriptionsArray.reduce((acc, subscription) => {
        if (!subscription.condition) {
            warn('subscription condition is not defined ', subscription);
            throw new Error('subscription condition is not defined. check prev console msg');
        }
        const actionTypes = subscription.condition.anyOf || [subscription.condition.toString()];

        actionTypes.forEach(actionType => {
            if (!acc[actionType]) {
                acc[actionType] = []; //eslint-disable-line
            }

            acc[actionType].push(subscription);
        });

        return acc;
    }, {});

const stripEpicValue = type => type.replace('_EPIC_VALUE', '');

export function printAction(a: Record<string, any>) {
    return a[CALL_API]
        ? `(api) types: ${a[CALL_API].types.join(',')} | endpoint: ${a[CALL_API].endpoint}`
        : printNormalAction(a);
}
function printNormalAction(a) {
    if (a.type === SCHEDULE_ACTION) {
        return `schedule (${printAction(a.payload.actionToDispatch)})`;
    }
    if (a.type) {
        return stripEpicValue(a.type);
    }

    return `unknown action shape: ${JSON.stringify(a)}`;
}

const
    printOrigin = a =>
        (a.initiatedByEpic ? `${stripEpicValue(a.initiatedByEpic.type)}[${a.initiatedByEpic.updaterIndex}] => ` : ''),
    makeActionsChainToString = (preStep, separator) => R.pipe(
        preStep,
        R.map(a =>
            printOrigin(a) +
            printAction(a) +
            (a.updaterIndex !== undefined ? `[${a.updaterIndex}]` : '') +
            (a.epicUpdateReason !== undefined ? `[${a.epicUpdateReason}]` : '')),
        R.join(separator)
    ),
    actionsChainToString = makeActionsChainToString(R.identity, ' <- '),
    actionsChainToStringReverse = makeActionsChainToString(R.reverse, ' -> ');

function mapConditionToParam(condition, keepFullActions, action) {
    if (keepFullActions) {
        if (condition.selector) {
            return condition.selector(action, action.type);
        } else {
            return action;
        }
    } else if (action) {
        if (condition.selector) {
            return condition.selector(action.payload, action.type);
        } else {
            return action.payload;
        }
    } else {
        return null;
    }
}

const getSubscriptions = memoUnlimited(actionType => {
    const
        allSubs = [...subscriptionsMap[MatchAnyActionType], ...(subscriptionsMap[actionType] || [])],
        receiveOnlySubs: Subscription[] = [],
        liveSubs: Subscription[] = [];

    allSubs.forEach(sub => {
        if (sub.condition.receiveOnly) {
            receiveOnlySubs.push(sub);
        } else {
            liveSubs.push(sub);
        }
    });
    return [...receiveOnlySubs, ...liveSubs];
    // to eliminate out of sync problems for receiveOnly conditions, so we are executing receiveOnly first and only then live subscription
});

let updatersCallsToDebug = {},
    epicVatsToTrace = {
        // WORKSPACE_SAVE_STATUS_EPIC_VALUE: true
    },
    actionsToDispatchDebug = {};

if (_traceEnabled) {
    if (window.$R) {
        window.$R.debugUpdater = function (epicName, updaterIndex) {
            if (arguments.length === 0) {
                info(`Call it like this: window.$R.debugUpdater('WORKSPACE_SAVE_STATUS'[, 0])`);
            }
            const vat = `${epicName}_EPIC_VALUE`;
            if (!epicsMap[vat]) {
                info(`Epic "${vat}" not registered`);
                return;
            }
            if (arguments.length === 1) {
                updatersCallsToDebug[vat] = true;
            }

            if (arguments.length === 2) {
                updatersCallsToDebug =
                    R.assocPath([`${epicName}_EPIC_VALUE`, updaterIndex], true, updatersCallsToDebug);
            }
        };

        window.$R.traceEpic = function (epicName) {
            if (arguments.length !== 1) {
                info(`Call it like this: window.$R.debugUpdater('WORKSPACE_SAVE_STATUS')`);
            }
            const vat = `${epicName}_EPIC_VALUE`;
            if (!epicsMap[vat]) {
                info(`Epic "${vat}" not registered`);
                return;
            }
            epicVatsToTrace[vat] = true;
        };

        window.$R.debugDispatchedAction = function (actionType) {
            actionsToDispatchDebug[actionType] = true;
        };

        window.$R.resetUpdatersDebug = () => {
            updatersCallsToDebug = {};
        };
    }
}

function evalResult({
    epic,
    updater,
    reducerParams,
    prevState,
    prevScope,
    actionsChain,
    sourceAction,
    subVat,
    updaterIndex,
    conditionIndex,
    dispatchAsync
}) {
    if (_traceEnabled) {
        if (updatersCallsToDebug[subVat] === true || R.path([subVat, updaterIndex], updatersCallsToDebug)) {
            debugger; // eslint-disable-line
        }
    }

    const
        conditionActionType = updater.conditions[conditionIndex],
        epicUpdaterResult = updater.reducer({
            values: reducerParams,
            state: prevState,
            scope: prevScope,
            sourceAction,
            conditionActionType,
            dispatchAsync,
        });

    if (R.is(Function, epicUpdaterResult.state)) {
        throw Error(`${subVat}[${updaterIndex}] Function returned as new state of epic`);
    }
    if (R.is(Function, epicUpdaterResult.scope)) {
        throw Error(`${subVat}[${updaterIndex}] Function returned as new scope of epic`);
    }

    if (_traceEnabled) {
        let allActionsToDispatch: any = [];
        if (epicUpdaterResult.actionToDispatch) {
            allActionsToDispatch.push(epicUpdaterResult.actionToDispatch);
        }
        if (epicUpdaterResult.multipleActionsToDispatch) {
            allActionsToDispatch = epicUpdaterResult.multipleActionsToDispatch;
        }
        const matchedActions = allActionsToDispatch.filter((a: any) => a && actionsToDispatchDebug[a.type]);
        if (matchedActions.length) {
            matchedActions.forEach(a => info(`${a.type} action retured from ${subVat}[${updaterIndex}]`));
            debugger; // eslint-disable-line
            updater.reducer({
                values: reducerParams,
                state: prevState,
                scope: prevScope,
                sourceAction,
                conditionActionType,
                dispatchAsync: () => null
            });
        }
    }

    if (epic.undo && !epicUpdaterResult.updateReason && prevState !== epicUpdaterResult.state) {
        throw new Error(`Provide epic change reason for ${subVat}[${updaterIndex}]`);
    }

    const
        nextState = epicUpdaterResult.state,
        nextScope = epicUpdaterResult.scope,
        somethingChanged = prevState !== nextState || (nextScope !== undefined && nextScope !== prevScope);

    if (extendedTraceEnabled()) {
        info(`executed epic updater ${epic.valueActionType}[${updaterIndex}] for ${actionsChain[0].type} and something changed? ${somethingChanged.toString()}`); // eslint-disable-line max-len
    }

    if (prevScope === undefined && nextScope !== undefined && epic.defaultScope === undefined) {
        throw new Error(`${subVat}[${updaterIndex}] Updater returned scope, but defaultScope is undefined. defaultScope should be defined.`); // eslint-disable-line max-len
    }

    if (prevScope !== undefined && nextScope === undefined) {
        warn('scope =', prevScope);
        throw new Error(`${subVat}[${updaterIndex}] Updater returned undefined for scope. If scope is defined once, it can not be undefined`); // eslint-disable-line max-len
    }

    if (epic.afterUpdate && somethingChanged) {
        // todo test scope passing 2155
        const {
            state: adjustedState,
            scope: adjustedScope,
            afterUpdateActions,
            addCustomFieldToEpicChangeAction
        } = epic.afterUpdate({
            prevState,
            prevScope,
            nextState,
            nextScope,
            updateReason: epicUpdaterResult.updateReason,
            sourceAction,
            updaterIndex,
            triggerSubscriptionActionType: subVat
        });

        if (nextScope !== undefined && adjustedScope === undefined) {
            warn('scope =', nextScope);
            throw new Error(`${subVat} Updater returned undefined for scope. If scope is defined once, it can not be undefined`); // eslint-disable-line max-len
        }

        if (adjustedState === undefined) {
            warn('state =', nextState);
            throw new Error(`${subVat} afterUpdate returned undefined for state`); // eslint-disable-line max-len
        }

        return R.pipe(
            R.when(() => adjustedState !== epicUpdaterResult.state, R.assoc('state', adjustedState)),
            R.when(() => adjustedScope !== epicUpdaterResult.scope, R.assoc('scope', adjustedScope)),
            result => {
                if (!afterUpdateActions || !afterUpdateActions.length) {
                    return result;
                }

                let multipleActionsToDispatch: Action[] = [];
                if (epicUpdaterResult.actionToDispatch) {
                    multipleActionsToDispatch = [epicUpdaterResult.actionToDispatch];
                } else if (epicUpdaterResult.multipleActionsToDispatch) {
                    multipleActionsToDispatch = [...epicUpdaterResult.multipleActionsToDispatch];
                }

                multipleActionsToDispatch = multipleActionsToDispatch.concat(afterUpdateActions);

                return R.pipe(
                    R.assoc('actionToDispatch', null),
                    R.assoc('multipleActionsToDispatch', multipleActionsToDispatch),
                )(result);
            },
            R.assoc('addCustomFieldToEpicChangeAction', addCustomFieldToEpicChangeAction)
        )(epicUpdaterResult);
    }

    return epicUpdaterResult;
}

function executeUpdater({
    epicsState,
    sourceAction,
    updaterIndex,
    isDefaultStateInit,
    actionsChain,
    epicsUpdate,
    actionsToDispatch,
    executionParamsUpdate,
    epicUpdate,
    subVat,
    epic,
    updater,
    delayEpicsUpdatersExecQueueMap,
    errors,
    conditionIndex,
    dispatchAsync
}) {
    if (bannedEpicsVats[subVat]) {
        trace(`${subVat} epic execution was skipped, beacuse it was banned`);
        return;
    }

    const
        reducerParams: Array<AnyValue> = executionParamsUpdate,
        prevState = epicUpdate.state === undefined ? epicsState[subVat].state : epicUpdate.state,
        prevScope = epicUpdate.scope === undefined ? epicsState[subVat].scope : epicUpdate.scope;

    let result;

    if (process.env.NODE_ENV !== 'production') { // to eliminate this from production build
        // TODO WBTGEN-4380 devDeepFreeze(reducerParams);
        // TODO WBTGEN-4380 devDeepFreeze(prevState);
        // TODO WBTGEN-4380 devDeepFreeze(prevScope);
    }

    const evalResultProps =
        {
            epic,
            updater,
            reducerParams,
            prevState,
            prevScope,
            sourceAction,
            subVat,
            updaterIndex,
            conditionIndex,
            actionsChain,
            dispatchAsync
        };

    if (
        (process.env.NODE_ENV !== 'production' && window.__testCaseMode)
        || isTestEnv() // while testing we want to fail fast for most accurate stack trace
    ) {
        result = evalResult(evalResultProps);
    } else {
        try {
            result = evalResult(evalResultProps);
        } catch (exception: any) {
            if (exception.name === NaNErrorName) {
                /* @ts-ignore TODO */
                registerException({
                    type: 'EPIC_UPDATER_EVAL',
                    valueActionType: subVat,
                    reducerIndex: updaterIndex,
                    reducerParams,
                    epicState: prevState,
                    epicScope: prevScope,
                    exception,
                    actionsTypesChain: actionsChain.map(a => a.type),
                    subVat
                });

                throw exception;
            }

            error(`EPIC_UPDATER_EVAL err in ${subVat}[${updaterIndex}]`, exception);
            // partly pushing exData without app state and action that cause exception TODO WBTGEN-6211
            errors.push({
                type: 'EPIC_UPDATER_EVAL',
                valueActionType: subVat,
                reducerIndex: updaterIndex,
                reducerParams,
                epicState: prevState,
                epicScope: prevScope,
                exception,
                actionsTypesChain: actionsChain.map(a => a.type),
                subVat
            });

            return;
        }
    }

    if (process.env.NODE_ENV !== 'production') { // to eliminate this from production build
        // TODO WBTGEN-4380 devDeepFreeze(result);
    }

    if (!R.is(Object, result) || !result.hasOwnProperty('state')) {
        throw Error('Return result should be of shape { state: S, actionToDispatch?: AnyAction, multipleActionsToDispatch? Array<AnyAtion>, updateReason?: UpdateReason }'); // eslint-disable-line max-len
    }

    const
        {
            state: newState,
            scope: newScope,
            actionToDispatch,
            multipleActionsToDispatch
        } = result,
        updateReason = result.updateReason;

    if (actionToDispatch && multipleActionsToDispatch) {
        throw new Error('only one of actionToDispatch && multipleActionsToDispatch should be defined');
    }

    if (prevScope !== newScope) {
        epicUpdate.scope = newScope; // eslint-disable-line no-param-reassign
    }

    if (prevState !== newState) {
        epicUpdate.state = newState; // eslint-disable-line no-param-reassign
        epicUpdate.lastUpdateReason = updateReason; // eslint-disable-line no-param-reassign

        if (newState === undefined) {
            throw new Error(`EPIC_UPDATER_RETURNED_UNDEFINED ${subVat}[${updaterIndex}]`);
        }

        if (
            process.env.NODE_ENV === 'development' &&
            deepStateCompare() &&
            R.is(Object, prevState) &&
            R.is(Object, newState)
        ) {
            const stateDiff = diff(prevState, newState);
            if (stateDiff.changed === 'equal') {
                warn(`Epic updater: '${subVat}[${updaterIndex}]'`,
                    'return same state, but recreate object.',
                    'it will cause unnecessary renders. fix it please', stateDiff);
            }
        }

        if (
            epicVatsToTrace[subVat]
        ) {
            let stateDiff;
            if (R.is(Object, prevState) &&
                R.is(Object, newState)) {
                stateDiff = diff(prevState, newState);
            }
            info(
                `${subVat} state changed updater: ${updaterIndex} from ${actionsChainToString(actionsChain)}. diff: `,
                stateDiff === undefined ? newState : stateDiff
            );
        }

        const
            epicChangedAction = {
                type: subVat,
                payload: newState,
                epicUpdateReason: updateReason,
                ...result.addCustomFieldToEpicChangeAction
            };

        if (_traceEnabled) {
            epicChangedAction.updaterIndex = updaterIndex;
        }

        executeAction(
            [epicChangedAction, ...actionsChain],
            epicsState,
            epicsUpdate,
            actionsToDispatch,
            errors,
            dispatchAsync,
            false, /* isRootLevel */
            isDefaultStateInit,
            delayEpicsUpdatersExecQueueMap
        );
    }

    const overallActionsToDispatch = [
        actionToDispatch,
        ...(multipleActionsToDispatch || [])
    ].filter(i => i).map(R.assoc('initiatedByEpic', { updaterIndex, type: subVat }));
    if (overallActionsToDispatch.length) {
        overallActionsToDispatch.forEach(action => {
            const a = action as Action;
            actionsToDispatch.push(a);

            if (a.dedicatedRender) {
                return;
            }

            executeAction(
                [a, ...actionsChain],
                epicsState,
                epicsUpdate,
                actionsToDispatch,
                errors,
                dispatchAsync,
                false, /* isRootLevel */
                isDefaultStateInit,
                delayEpicsUpdatersExecQueueMap
            );
        });
    }
}

function executeAction(actionsChain: Array<AnyAction>,  // NOSONAR
    epicsState: EpicsState,
    epicsUpdate: EpicsStateUpdate,
    actionsToDispatch: Array<AnyAction>,
    errors: Array<ExData>,
    dispatchAsync: Dispatch,
    // TODO WBTGEN-4425 discuss delayed epics execution to reduce reducer invacation count
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    isRootLevel: boolean = true, // eslint-disable-line no-unused-vars
    isDefaultStateInit: boolean = false,
    delayEpicsUpdatersExecQueueMap: DelayEpicsUpdatersExecQueueMap = {}): void {
    if (_traceEnabled && !isDefaultStateInit && !skipTraceActions(actionsChain)) {
        info('redux ->', actionsChainToStringReverse(actionsChain));
    }

    const
        sourceAction = R.last(actionsChain),
        action = actionsChain[0],
        subscriptions = getSubscriptions(action.type);

    if (subscriptions.length === 0) {
        return;
    }

    subscriptions.forEach(subscription => {
        if (actionTypeDoesntMatchAction(subscription.condition, action)) return;

        const
            { valueActionType: subVat, updaterIndex, conditionIndex, condition } = subscription,
            epic = epicsMap[subVat],
            updater: EpicUpdaterConfig<any, any, any, any> = epic.updaters[updaterIndex],
            subscriptionActionType = condition.anyOf ? action.type : condition.toString(),
            epicState = epicsState[subVat],
            updaterState = epicState.updatersState[updaterIndex];

        let epicUpdate: EpicStateUpdate = epicsUpdate[subVat];

        if (!epicUpdate) {
            epicUpdate = ({ updatersState: [] } as EpicStateUpdate);
            epicsUpdate[subVat] = epicUpdate; // eslint-disable-line no-param-reassign
        }

        let updaterStateUpdate: UpdaterStateUpdate = epicUpdate.updatersState[updaterIndex];

        if (!updaterStateUpdate) {
            updaterStateUpdate = ({
                receivedActions: {},
                executionParams: [...updaterState.executionParams]
            } as UpdaterStateUpdate);
            epicUpdate.updatersState[updaterIndex] = updaterStateUpdate;
        }

        const
            receivedActionsUpdate = updaterStateUpdate.receivedActions,
            executionParamsUpdate: Array<AnyValue> = updaterStateUpdate.executionParams;

        // set epic updater receive action
        receivedActionsUpdate[subscriptionActionType] = action;

        let newParam;

        // Should always consider latest epic state if action is epic value (This can occur bacause epic state can be updated in on execution branch, so we need to pass updated state to sibling branch)
        let epicUpdateActionUpToDateOrJustAction;
        try {
            const
                actionIsEpicValue = !!epicsMap[action.type],
                epicUpdate = epicsUpdate[action.type],
                shouldFixEpicUpdateStateUpToDate = actionIsEpicValue
                    && epicUpdate
                    && epicUpdate.hasOwnProperty('state');

            epicUpdateActionUpToDateOrJustAction = shouldFixEpicUpdateStateUpToDate ? {
                ...action,
                payload: epicsUpdate[action.type].state
            } : action;

            newParam = mapConditionToParam(condition, updater.keepFullActions, epicUpdateActionUpToDateOrJustAction);
        } catch (e: any) {
            const errMsg = `${epic.valueActionType}[${updaterIndex}][${conditionIndex}] failed to eval: ${e.message}`;
            error(errMsg, '\naction:', epicUpdateActionUpToDateOrJustAction, '\nconditions:', updater.conditions, e);
            throw new Error(errMsg);
        }

        if (condition.selector && executionParamsUpdate[conditionIndex] === newParam) {
            return;
        }

        if (condition.reset) {
            updaterStateUpdate.isFullfilled = false;
            updater.conditions.forEach((c, ci) => {
                if (ci > conditionIndex) {
                    receivedActionsUpdate[c.toString()] = null;
                    executionParamsUpdate[ci] = undefined;
                }
            });
        }

        executionParamsUpdate[conditionIndex] = newParam;

        const
            receivedActions = updaterState.receivedActions,
            updaterFullfilled = !!(
                updaterStateUpdate.isFullfilled
                || (updaterState.isFullfilled === true && updaterStateUpdate.isFullfilled !== false) // reset may set isFullfilled to false
                || updater.conditions.every(cond => {
                    const isReceived = (c) => receivedActionsUpdate[c]
                        || (receivedActions[c] && receivedActionsUpdate[c] !== null);
                    // @ts-ignore
                    if (cond.anyOf) {
                        /* @ts-ignore anyOf is always array */
                        return cond.anyOf.some(isReceived);
                    } else {
                        const condStr = cond.toString();
                        return (
                            // @ts-ignore
                            cond.isOptional
                            || isReceived(condStr)
                        );
                    }
                })
            );

        if (
            condition.receiveOnly
            || !updaterFullfilled
        ) {
            return;
        }

        updaterStateUpdate.isFullfilled = true;

        /* TODO WBTGEN-4425 discuss delayed epics execution to reduce reducer invacation count
         if (updater.executeImmediately) { */
        executeUpdater({
            epicsState,
            sourceAction,
            updaterIndex,
            isDefaultStateInit,
            actionsChain,
            epicsUpdate,
            actionsToDispatch,
            executionParamsUpdate,
            epicUpdate,
            subVat,
            epic,
            updater,
            delayEpicsUpdatersExecQueueMap,
            errors,
            conditionIndex,
            dispatchAsync
        });
        /* } else {
            if (!delayEpicsUpdatersExecQueueMap[subVat]) {
                delayEpicsUpdatersExecQueueMap[subVat] = { updaterIndexes: [] }; // eslint-disable-line no-param-reassign
            }
            if (_traceEnabled && !isDefaultStateInit) {
                info(`${subVat}[${updaterIndex}] exec delayed`);
            }
            delayEpicsUpdatersExecQueueMap[subVat].updaterIndexes.push(updaterIndex);
        } */
    });

    /* TODO WBTGEN-4425 discuss delayed epics execution to reduce reducer invacation count
    if (isRootLevel) {
        let delayEpicsUpdatersVats = Object.keys(delayEpicsUpdatersExecQueueMap);
        let loopsCounter = 0;
        while (delayEpicsUpdatersVats.length) {
            if (loopsCounter > 100) {
                throw new Error('Unresolvable epics loop detected');
            } else {
                loopsCounter++;
            }

            delayEpicsUpdatersVats.forEach(epicVAT => {
                const updaterIndexes = delayEpicsUpdatersExecQueueMap[epicVAT].updaterIndexes;
                delete delayEpicsUpdatersExecQueueMap[epicVAT]; // eslint-disable-line no-param-reassign

                if (_traceEnabled) {
                    info(`executing updaters of ${epicVAT} [${R.uniq(updaterIndexes).length} instead of ${updaterIndexes.length}]`); // eslint-disable-line max-len
                }

                R.uniq(updaterIndexes).forEach(updaterIndex => {
                    const
                        epic = epicsMap[epicVAT],
                        updater = epic.updaters[updaterIndex],
                        epicUpdate: EpicStateUpdate = epicsUpdate[epicVAT],
                        executionParamsUpdate = epicUpdate.updatersState[updaterIndex].executionParams;

                    executeUpdater({
                        epicsState,
                        sourceAction,
                        updaterIndex,
                        isDefaultStateInit,
                        actionsChain,
                        epicsUpdate,
                        actionsToDispatch,
                        executionParamsUpdate,
                        epicUpdate,
                        subVat: epicVAT,
                        epic,
                        updater,
                        delayEpicsUpdatersExecQueueMap
                    });
                });
            });

            delayEpicsUpdatersVats = Object.keys(delayEpicsUpdatersExecQueueMap);
        }
    }
    */
}

if (process.env.NODE_ENV === 'development') {
    window.getEpicsSubscriptionsGraph = () => {
        const items = subscriptionsArray.reduce((acc, subscription) => {
            const
                actionType = subscription.condition.toString(),
                source = epicsMap[actionType] ? actionType : "UI",
                label = epicsMap[actionType] ? '' : ` [ label = "${actionType}" ]`,
                newItem = `"${source}" -> "${subscription.valueActionType}"${label};`;

            if (acc.indexOf(newItem) === -1) {
                acc.push(newItem);
            }
            return acc;
        }, [] as string[]).sort().join('\r\n');

        return `
digraph finite_state_machine {
size="8,5";
node [shape = circle];
    ${items}
}`;
    };
}

export default executeAction;
