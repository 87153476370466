import type { InputValidationFunction } from "../../../../../components/inputControls/input/flowTypes";
import type {
    LengthInputValidationOptions
} from "../../../../../components/inputControls/input/commonValidations/lengthInputValidation";
import { filename } from '../../../../../../utils/path.js';
import lengthInputValidation from "../../../../../components/inputControls/input/commonValidations/lengthInputValidation";
import {
    validateInputValidationOptions
} from "../../../../../components/inputControls/input/commonValidations/utils/validateInputValidationOptions";
import makeInputValidationResult from '../../../../../components/inputControls/input/commonValidations/utils/makeInputValidationResult';
import { encodeWebspaceUri } from "../../../../../../dal/utils/webspaceUriTransformers";

export type Options = LengthInputValidationOptions & {
    ext: string,
    existingFileNames: Array<string>,
};

const Reason = {
    DUPLICATE_NAME: 'RENAMED_NAME_IS_DUPLICATE_REASON'
};

const Message = {
    [Reason.DUPLICATE_NAME]:
        'msg: fc.renameFileDialog.error.duplicate {Filename already exists. Please choose another name.}'
};

export const uploadRenameDialogInputValidation: InputValidationFunction<Options> = (value, options) => {
    const lengthRes = lengthInputValidation(value);
    if (!lengthRes.isValid) return lengthRes;

    // @ts-ignore
    validateInputValidationOptions(options, ['ext', 'existingFileNames']);

    const
        // @ts-ignore
        fileName = encodeWebspaceUri(filename(value, options.ext)),
        // @ts-ignore
        isValid = !options.existingFileNames.some(f => (f === fileName));

    return makeInputValidationResult(isValid, Reason.DUPLICATE_NAME, Message[Reason.DUPLICATE_NAME]);
};
