import * as R from 'ramda';
import oneColor from "onecolor";
import { toHex } from '../mappers/color';
import {
    generateSimilarButDifferentColors,
    generateContrastAdjustedColor
} from '../components/AutoColorLeftPanel/paletteGenerator';
import type { Color as ColorType } from "../mappers/flowTypes";
import type { ThemeColorTypes, ThemeColorDataType } from "../components/ThemeGlobalData/flowTypes";
import { toHsl } from "../../dal/pageMapAdapter/mappers/Base/color";

const Color = (function () {
    let _ = function (this: any, rgba) {
        if (rgba === 'transparent') {
            rgba = [0, 0, 0, 0]; // eslint-disable-line no-param-reassign
        } else if (typeof rgba === 'string') {
            let rgbaString = rgba;
            rgba = rgbaString.match(/rgba?\(([\d.]+),\s?([\d.]+),\s?([\d.]+)(?:,\s?([\d.]+))?\)/); // eslint-disable-line no-param-reassign

            if (rgba) {
                rgba.shift();
            } else {
                throw new Error('Invalid string: ' + rgbaString);
            }
        }

        if (rgba[3] === undefined) {
            rgba[3] = 1; // eslint-disable-line no-param-reassign
        }

        rgba = rgba.map(function (a) { // eslint-disable-line no-param-reassign
            return floor(a, 3);
        });

        this.rgba = rgba;
    };

    _.prototype = {
        get rgb() {
            return this.rgba.slice(0, 3);
        },

        get alpha() {
            return this.rgba[3];
        },

        set alpha(alpha) {
            this.rgba[3] = alpha;
        },

        get luminance() {
            // Formula: http://www.w3.org/TR/2008/REC-WCAG20-20081211/#relativeluminancedef
            let rgba = this.rgba.slice();

            for (let i = 0; i < 3; i++) {
                let rgb = rgba[i];

                rgb /= 255;

                rgb = rgb < 0.03928 ? rgb / 12.92 : Math.pow((rgb + 0.055) / 1.055, 2.4);

                rgba[i] = rgb;
            }

            return (0.2126 * rgba[0]) + (0.7152 * rgba[1]) + (0.0722 * rgba[2]);
        },

        get inverse() {
            return new _([
                255 - this.rgba[0],
                255 - this.rgba[1],
                255 - this.rgba[2],
                this.alpha
            ]);
        },

        toString() {
            return 'rgb'
            + (this.alpha < 1 ? 'a' : '')
            + '('
            + this.rgba.slice(0, this.alpha >= 1 ? 3 : 4).join(', ')
            + ')';
        },

        clone() {
            return new _(this.rgba);
        },

        // Overlay a color over another

        overlayOn(color) {
            let overlaid = this.clone();

            let alpha = this.alpha;

            if (alpha >= 1) {
                return overlaid;
            }

            for (let i = 0; i < 3; i++) {
                overlaid.rgba[i] = (overlaid.rgba[i] * alpha) + (color.rgba[i] * color.rgba[3] * (1 - alpha));
            }

            overlaid.rgba[3] = alpha + (color.rgba[3] * (1 - alpha));

            return overlaid;
        },

        contrast(color) {
            // Formula: http://www.w3.org/TR/2008/REC-WCAG20-20081211/#contrast-ratiodef
            let alpha = this.alpha;

            if (alpha >= 1) {
                if (color.alpha < 1) {
                    color = color.overlayOn(this); // eslint-disable-line no-param-reassign
                }

                let l1 = this.luminance + 0.05,
                    l2 = color.luminance + 0.05,
                    ratio = l1 / l2;

                if (l2 > l1) {
                    ratio = 1 / ratio;
                }

                ratio = floor(ratio, 2);

                return {
                    ratio,
                    error: 0,
                    min: ratio,
                    max: ratio
                };
            }

            // If we’re here, it means we have a semi-transparent background
            // The text color may or may not be semi-transparent, but that doesn't matter

            // @ts-ignore
            let onBlack = this.overlayOn(_.BLACK),
                // @ts-ignore
                onWhite = this.overlayOn(_.WHITE),
                contrastOnBlack = onBlack.contrast(color).ratio,
                contrastOnWhite = onWhite.contrast(color).ratio;

            let max = Math.max(contrastOnBlack, contrastOnWhite);

            // This is here for backwards compatibility and not used to calculate
            // `min`.  Note that there may be other colors with a closer luminance to
            // `color` if they have a different hue than `this`.
            let closest = this.rgb.map(function (c, i) {
                return Math.min(Math.max(0, (color.rgb[i] - (c * alpha)) / (1 - alpha)), 255);
            });

            closest = new _(closest);

            let min = 1;
            if (onBlack.luminance > color.luminance) {
                min = contrastOnBlack;
            } else if (onWhite.luminance < color.luminance) {
                min = contrastOnWhite;
            }

            return {
                ratio: floor((min + max) / 2, 2),
                error: floor((max - min) / 2, 2),
                min,
                max,
                closest,
                // @ts-ignore
                farthest: onWhite == max ? _.WHITE : _.BLACK // eslint-disable-line eqeqeq
            };
        }
    };

    // @ts-ignore
    _.BLACK = new _([0, 0, 0]);
    // @ts-ignore
    _.GRAY = new _([127.5, 127.5, 127.5]);
    // @ts-ignore
    _.WHITE = new _([255, 255, 255]);

    // Math.floor with precision
    function floor(number, decimals) {
        decimals = +decimals || 0; // eslint-disable-line no-param-reassign

        let multiplier = Math.pow(10, decimals);

        return Math.floor(number * multiplier) / multiplier;
    }

    return _;
}());

export const getColorsContrastInfo = (bgRgba: string, textRgba: string) => {
    const bgColor = new Color(bgRgba);
    const textColor = new Color(textRgba);

    return bgColor.contrast(textColor);
};

export const isGoodEnoughContrast = (bgRgba: string, textRgba: string) => {
    return getColorsContrastInfo(bgRgba, textRgba).min
        // @ts-ignore
        > parseInt((global.localStorage && global.localStorage.getItem('contrastThreshold')) || 2, 10);
};
const black = 'rgba(0,0,0,1)';
export const rgbaWhite = 'rgba(255,255,255,1)';
export const getFallbackContrastColor = (textRgba: string) => {
    const blackContrast = getColorsContrastInfo(black, textRgba);
    const whiteContrast = getColorsContrastInfo(rgbaWhite, textRgba);

    if (blackContrast.min > whiteContrast.min) return black;
    return rgbaWhite;
};

export const mergeColors = (rbgas: Array<string>): string => {
    const layers = rbgas.map(rgba => {
        const [red, green, blue, alpha] = new Color(rgba).rgba;
        return { red, green, blue, alpha };
    });

    const amountOfLightAvailable = { red: 255, green: 255, blue: 255 };

    const outputColor = {
        red: 0,
        green: 0,
        blue: 0
    };

    layers.forEach(function (layer) {
        const layerOpacityInPercent = 100 * layer.alpha,
            percentOfLightWhichPassesThroughThisLayer = 100 - layerOpacityInPercent;

        const amountOfLightToBeConsumedByThisLayer = {
            red: amountOfLightAvailable.red * layer.alpha,
            green: amountOfLightAvailable.green * layer.alpha,
            blue: amountOfLightAvailable.blue * layer.alpha
        };

        const amountOfLightReflectedByThisLevel = {
            red: layer.red * (amountOfLightToBeConsumedByThisLayer.red / 255),
            green: layer.green * (amountOfLightToBeConsumedByThisLayer.green / 255),
            blue: layer.blue * (amountOfLightToBeConsumedByThisLayer.blue / 255)
        };

        outputColor.red += amountOfLightReflectedByThisLevel.red;
        outputColor.green += amountOfLightReflectedByThisLevel.green;
        outputColor.blue += amountOfLightReflectedByThisLevel.blue;

        const amountOfLightPassedThrough = {
            red: amountOfLightAvailable.red * (percentOfLightWhichPassesThroughThisLayer / 100),
            green: amountOfLightAvailable.green * (percentOfLightWhichPassesThroughThisLayer / 100),
            blue: amountOfLightAvailable.blue * (percentOfLightWhichPassesThroughThisLayer / 100)
        };

        amountOfLightAvailable.red = amountOfLightPassedThrough.red;
        amountOfLightAvailable.green = amountOfLightPassedThrough.green;
        amountOfLightAvailable.blue = amountOfLightPassedThrough.blue;
    });

    return `rgba(${outputColor.red},${outputColor.green},${outputColor.blue},1)`;
};

export const isOpaque = (rgba: string): boolean => {
    const color = new Color(rgba);

    return color.alpha >= 1;
};

export const getBrighterColor = (rgba1: string, rgba2: string): string => {
    const color1 = new Color(rgba1);
    const color2 = new Color(rgba2);
    return color1.luminance > color2.luminance ? rgba1 : rgba2;
};

export const getColorDistance = (c1: string| Record<string, any>, c2: string| Record<string, any>) => {
    const { _red: r1, _green: g1, _blue: b1 } = oneColor(c1).rgb(),
        { _red: r2, _green: g2, _blue: b2 } = oneColor(c2).rgb(),
        r = r1 - r2, g = g1 - g2, b = b1 - b2,
        rM = (r1 + r2) / 2;

    return Math.sqrt(
        ((2 + (rM / 256)) * r * r) +
        (4 * g * g) +
        ((2 + ((225 - rM) / 256)) * b * b)
    );
};

export const isNotOpaque = R.complement(isOpaque);

export const findMostRelatedColor = (c1: ColorType, listOfColor: { [k in ThemeColorTypes]: ColorType }): ThemeColorTypes => {
    let smallestColorDistance, colorName;
    Object.keys(listOfColor).forEach((key) => {
        const colorDistance = getColorDistance(c1, listOfColor[key]);
        if (smallestColorDistance !== 0 && (!smallestColorDistance || colorDistance < smallestColorDistance)) {
            smallestColorDistance = colorDistance;
            colorName = key;
        }
    });

    return colorName;
};

export const isExactEqualColors = (c1: ColorType, c2: ColorType): boolean => {
    const
        _c1 = [...c1],
        _c2 = [...c2];
    _c1[4] = 1;
    _c2[4] = 1;
    return oneColor(_c1).equals(oneColor(_c2));
};
export const isEqualColors = (c1: ColorType, c2: ColorType): boolean => oneColor(c1).equals(oneColor(c2), 0.1);
export const isEqualColorsWithoutAlpha = (c1: ColorType, c2: ColorType): boolean => {
    const
        _c1 = [...c1],
        _c2 = [...c2];
    _c1[4] = 1;
    _c2[4] = 1;
    return oneColor(_c1).equals(oneColor(_c2), 0.1);
};
export const isEqualToMultipleColors = (c1: ColorType, colorList: ColorType): boolean => {
    return colorList.some(
        (color) => color && oneColor(c1).equals(oneColor(color), 0.1)
    );
};
export const isEqualToMultipleColorsWithoutAlpha = (c1: ColorType, colorList: Array<ColorType | null>, epsilon: number = 0.1): boolean => {
    const _c1 = [...c1];
    _c1[4] = 1;
    return colorList.some(
        (color) => {
            if (color) {
                const _c2 = [...color];
                _c2[4] = 1;
                return oneColor(_c1).equals(oneColor(_c2), epsilon);
            }
            return false;
        }
    );
};

export const isExactEqualToMultipleColorsWithoutAlpha = (c1: ColorType, colorList: Array<ColorType | null>): boolean =>
    isEqualToMultipleColorsWithoutAlpha(c1, colorList, 0);
export const oneColorLocal = oneColor;

/**
 * @param color
 * @param val : [0..1]
 * @returns {one.color.HSL}
 */
export const adjustLightness = (color: ColorType, val: number): ColorType => {
    const { _hue, _saturation, _lightness, _alpha } = oneColor(color).lighten(val);
    return ["HSL", _hue, _saturation, _lightness, _alpha];
};

export const getCorrectedColor = (color: ColorType): ColorType => {
    if (!color) { return color; }
    // Set hue to zero if lightness is 0 to match black color. Do it for white also if needed
    // Do it for Saturation as well if need arises
    // eslint-disable-next-line no-param-reassign
    const result = [...color] as ColorType;
    result[1] = result[3] ? result[1] : 0;
    return result;
};

export const findThemeColorTypeFromColorObject = (c: ColorType, themeColorsData: ThemeColorDataType): ThemeColorTypes | undefined => {
    const color = getCorrectedColor(c);
    const { blackColor, whiteColor, mainColor, accentColor } = themeColorsData;
    const themeColors = { blackColor, whiteColor, accentColor, mainColor };
    let themeColor = Object.keys(themeColors).find((key) => isExactEqualColors(color, themeColors[key]));
    if (!themeColor) {
        themeColor = Object.keys(themeColors).find((key) => isExactEqualColors(c, themeColors[key]));
    }

    // @ts-ignore
    return themeColor;
};

export const getMainColorsHexFromAccentColor = (accentColor: ColorType): string[] => {
    const inputColorHex = toHex(accentColor);
    const colors = generateSimilarButDifferentColors(inputColorHex, 5);

    return colors;
};

export const getContrastAdjustedColor = (accentColor: ColorType): ColorType | null => {
    const inputColorHex = toHex(accentColor);
    const color = generateContrastAdjustedColor(inputColorHex);

    return toHsl(color);
};

export const getMainColorsHslFromAccentColor = (accentColor: ColorType): Array<ColorType | null> => {
    const listOfMainColors = getMainColorsHexFromAccentColor(accentColor);
    return listOfMainColors.map(toHsl);
};

export const getHexFromRgba = (rgba: string) => {
    const values = rgba
        .replace(/rgba?\(/, '')
        .replace(/\)/, '')
        .replace(/[\s+]/g, '')
        .split(',');
    // @ts-ignore
    const a = parseFloat(values[3] || 1),
        r = Math.floor(a * parseInt(values[0], 10) + (1 - a) * 255),
        g = Math.floor(a * parseInt(values[1], 10) + (1 - a) * 255),
        b = Math.floor(a * parseInt(values[2], 10) + (1 - a) * 255);
    return "#" +
        ("0" + r.toString(16)).slice(-2) +
        ("0" + g.toString(16)).slice(-2) +
        ("0" + b.toString(16)).slice(-2);
};
