import * as React from 'react';
import styles from './Shutterstock.css';
import type {
    ShutterstockDownloadsState,
    ShutterstockImages, ShutterstockKindT,
    ShutterstockSelectedImages
} from '../types';
import Grid from '../../../../view/common/GridLayout/index';
import { parseIntDec } from '../../../../../utils/number';
import { ShutterstockImage } from './ShutterstockImage';
import { ShutterstockSearchNoResult } from './ShutterstockSearchNoResult';
import {
    shutterstockGridScrolledToBottomAction,
    shutterstockGridScrollTopAction,
    selectShutterstockImageAction
} from "../actions";
import LoadingIndicator from "../../../../view/common/LoadingIndicator/index";
import {
    InlineLoadingIndicatorCom,
    InlineLoadingIndicatorSize
} from "../../../../view/common/LoadingIndicator/InlineLoadingIndicatorCom";
import { ThirdPartyImageKind } from "../../unsplash/components/constants";
import { unsplashGridScrolledToBottomAction,
    selectUnsplashImageAction,
    unsplashGridScrollTopAction } from "../../unsplash/actions";
import { ShutterstockKind } from '../constants';

const
    ROW_SIZE = parseIntDec(styles.gridRowSize),
    HEIGHT = styles.gridHeight,
    CELL_HEIGHT = parseIntDec(styles.gridCellHeight),
    GUTTER_WIDTH = parseIntDec(styles.gridGutterWidth),
    GUTTER_HEIGHT = parseIntDec(styles.gridGutterHeight);

// TODO: optimize
type State = {
    scrollTop: null | undefined | number
}

type Props = {
    kind: ShutterstockKindT,
    images: ShutterstockImages,
    isLoading: boolean,
    imagesPageLoading: boolean,
    selectedImages: ShutterstockSelectedImages,
    gridScrollTop: number,
    isMultiSelect?: boolean,
    selectedCategoryId: string,
    searchTerm: string,
    lockImages: boolean,
    downloads: ShutterstockDownloadsState,
    emptySearchResults: boolean,
    isDemo: boolean,
    dispatch: Dispatch,
    getCellChildren: Function,
};

export class ShutterstockGrid extends React.Component<Props, State> {
    state: State = {
        scrollTop: null
    };

    onImageClick(imageId: string) {
        const { kind, isMultiSelect, isDemo, dispatch, lockImages } = this.props,
            isUnsplashImageKind = [ThirdPartyImageKind.UNSPLASH, ShutterstockKind.FREE].includes(kind);

        if ((isDemo && !isUnsplashImageKind) || lockImages) return;
        if (isUnsplashImageKind) {
            dispatch(selectUnsplashImageAction({ imageId, isMultiSelect }));
        } else {
            dispatch(selectShutterstockImageAction({ kind, imageId, isMultiSelect }));
        }
    }

    onScroll = (input: Record<string, any>) => {
        if (this.state.scrollTop !== null) {
            this.setState({ scrollTop: null });
        }

        const { kind, imagesPageLoading, emptySearchResults } = this.props,
            isUnsplashImageKind = [ThirdPartyImageKind.UNSPLASH, ShutterstockKind.FREE].includes(kind);

        if (isUnsplashImageKind) {
            this.props.dispatch(unsplashGridScrollTopAction(input.scrollTop));
        } else {
            this.props.dispatch(shutterstockGridScrollTopAction(kind, input.scrollTop));
        }

        if (input.top >= 0.95 && !imagesPageLoading && !emptySearchResults) {
            if (isUnsplashImageKind) {
                this.props.dispatch(unsplashGridScrolledToBottomAction());
            } else {
                this.props.dispatch(shutterstockGridScrolledToBottomAction(kind));
            }
        }
    };

    componentDidMount() {
        this.setState({
            scrollTop: this.props.gridScrollTop
        });
    }

    renderGrid() {
        const
            {
                images: propImages,
                imagesPageLoading,
                selectedImages,
                searchTerm,
                selectedCategoryId,
                lockImages,
                downloads,
                isDemo,
                getCellChildren,
                dispatch,
                kind
            } = this.props,
            dataSet = propImages.map(image => {
                const locked = lockImages && !downloads.find(d => d.imageId === image.id),
                    children = getCellChildren(locked);
                return {
                    kind,
                    image,
                    locked,
                    selected: selectedImages.find(({ imageId }) => imageId === image.id),
                    onImageClick: () => this.onImageClick(image.id),
                    isDemo,
                    children,
                };
            }),
            { scrollTop } = this.state,
            isEmptyImageList = propImages.length === 0;

        if (isEmptyImageList) {
            return (
                <ShutterstockSearchNoResult
                    searchTerm={searchTerm}
                    selectedCategoryId={selectedCategoryId}
                />
            );
        }

        return (
            <React.Fragment>
                <Grid
                    dataSet={dataSet}
                    // @ts-ignore
                    cell={ShutterstockImage}
                    dispatch={dispatch}
                    rowSize={ROW_SIZE}
                    height={HEIGHT}
                    cellHeight={CELL_HEIGHT}
                    gutterWidth={GUTTER_WIDTH}
                    gutterHeight={GUTTER_HEIGHT}
                    onScroll={this.onScroll}
                    scrollTop={scrollTop}
                    scrollbarTheme={{
                        scrollbar: styles.gridScrollbar
                    }}
                />
                {imagesPageLoading && (
                    <InlineLoadingIndicatorCom
                        size={InlineLoadingIndicatorSize.LARGE}
                        theme={{
                            inlineContainer: styles.gridInlineLoader
                        }}
                    />
                )}
            </React.Fragment>
        );
    }

    render() {
        const { isLoading } = this.props;

        return (
            <div className={styles.gridContainer}>
                {
                    isLoading
                        ? <LoadingIndicator />
                        : this.renderGrid()
                }
            </div>
        );
    }
}
