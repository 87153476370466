import { getDAL } from '../../../dal';
import isTestEnv from '../../debug/isTestEnv';
import { trackingClientFactory } from '../../services/trackingClientFactory';
import AppConfig from '../../utils/AppConfig';
import { getAppConfig } from '../App/epics/appConfig/appConfig.js';
import { MixpanelSlackErrorType } from './constants';
import type { EventFactoryMiddlewareFn, EventsRegistryType, FilterPropsFnType, FrontClientEvent, TrackEventMethodProps } from './types';

const appConfig = AppConfig(getAppConfig());
const isTrackingEnabled = appConfig.getOptional('server.eventTracking.enable');
let batchEvents: Array<FrontClientEvent> = [];

export const getSanitizeProperties = (properties, propsToFilter) => {
    let sanitizedProperties = {};
    Object.keys(properties).forEach((key) => {
        const value = properties[key];
        if (value === null || value === undefined || value === '' || propsToFilter[key]) { return; }
        sanitizedProperties[key] = value;
    });
    return sanitizedProperties;
};

export const trackEvents = ({ events, factoryProps, propsToFilter }: TrackEventMethodProps) => {
    if (events.length === 0) { return; }
    const eventTrackingClient = trackingClientFactory(factoryProps.trialDomain);
    const updatedEvents = events.map(({ name, properties }) => ({
        name,
        properties: getSanitizeProperties({ ...factoryProps, ...properties }, propsToFilter)
    }));
    eventTrackingClient.trackEvents(updatedEvents);
};

export const EventTrackerMiddlewareFactory = (
    registry: EventsRegistryType,
    middlewareFn: EventFactoryMiddlewareFn,
    filterPropsFn?: FilterPropsFnType,
) => (appStore: Store) => (next: Dispatch) => (action: AnyAction): any => {
    const { type, payload } = action;
    try {
        if (!isTrackingEnabled || isTestEnv()) {
            return next(action);
        }
        if (!middlewareFn) {
            console.error('The middlewareFn is not found');
            return next(action);
        }

        const eventTracker = registry[type];
        const appState = appStore.getState();
        if (!eventTracker) {
            return next(action);
        }

        const factoryProps = middlewareFn(appState);
        const propsToFilter = filterPropsFn ? filterPropsFn() : {};

        let { eventName, propsHandler, getEventName, isDisabled } = eventTracker;
        eventName = getEventName ? getEventName(appState, payload) : eventName;
        if (!eventName) {
            return next(action);
        }

        if (isDisabled && isDisabled(appState, payload)) {
            return next(action);
        }

        batchEvents = [{
            name: eventName,
            properties: (propsHandler && propsHandler(appState, payload)) || {}
        }];
        trackEvents({ events: batchEvents, factoryProps, propsToFilter });
    } catch (e: any) {
        const sendSlackAlert = (getDAL() || {}).sendSlackAlert;
        if (sendSlackAlert) {
            sendSlackAlert({
                type: MixpanelSlackErrorType,
                message: `${e.message} \nEvent data:\n ${JSON.stringify(batchEvents, null, 2)}`
            });
        }
        console.error(e.message);
    }
    return next(action);
};
