const { ConfigComponentList } = require("../constants.js");

class OnlineShopConfig {
    isOnlineShopCmpsAllowed() {
        /* This includes all type of components related to webshop */
        const onlineShop = this._featuresConfig[ConfigComponentList.ONLINE_SHOP];
        return onlineShop && onlineShop.enabled;
    }
}

module.exports = OnlineShopConfig;
