import React from 'react';
import cx from 'classnames';
import styles from './Shutterstock.css';
import Input from '../../../inputControls/input/view/Input';
import {
    SHUTTERSTOCK_SEARCH_INPUT_CHANGE_ACTION,
    shutterstockCleanSearchAction,
    shutterstockSearchClickAction,
    shutterstockSearchEnterPressAction,
    shutterstockPortraitOrientationClickedAction,
    shutterstockLandscapeOrientationClickedAction,
    shutterstockTypeImagesClickedAction,
    shutterstockTypeIllustrationsClickedAction
} from '../actions';
import type { ShutterstockKindT, ShutterstockSearchState } from '../types';
import injectIntl from "../../../../view/intl/injectIntl";
import { ShutterstockKind, SHUTTERSTOCK_ACTION_KIND } from "../constants";
import { ThirdPartyImageKind as ThirdPartyKind } from "../../unsplash/components/constants";
import { unsplashSearchClickAction,
    unsplashCleanSearchAction,
    unsplashSearchEnterPressAction,
    unsplashLandscapeOrientationClickedAction,
    unsplashPortraitOrientationClickedAction,
    UNSPLASH_SEARCH_INPUT_CHANGE_ACTION } from "../../unsplash/actions";
import { AllCategoryId } from "./ImagesCategories";

type Props = {
    kind: ShutterstockKindT,
    search: ShutterstockSearchState,
    isTypeImages: boolean,
    isTypeIllustrations: boolean,
    isPortraitMode: boolean,
    isLandscapeMode: boolean,
    showBorder: boolean,
    dispatch: Dispatch,
    selectedCategoryId: string
};

type State = {
    searchBoxInFocus: boolean;
}

const
    ImageTypeButton = injectIntl(({ text, isSelected, onClick, intl }) => (
        <div
            className={cx(styles.button, { [styles.buttonSelected]: isSelected, [styles.buttonHover]: !isSelected })}
            onClick={onClick}
        >
            {intl.msgJoint(text)}
        </div>
    )),
    OrientationBtn = injectIntl(({ className, selected, onClick, title, intl }) => (
        <span
            className={cx(styles.orientationBtn, className, { [styles.selected]: selected })}
            onClick={onClick}
            data-title={intl.msgJoint(title)}
        />
    ));

export class ImagesSearch extends React.Component<Props, State> {
    state = {
        searchBoxInFocus: false
    };

    searchBtnClick = () => {
        const { dispatch, kind } = this.props;
        if ([ThirdPartyKind.UNSPLASH, ShutterstockKind.FREE].includes(kind)) {
            // @ts-ignore it can be a bug
            dispatch(unsplashSearchClickAction());
        } else {
            dispatch(shutterstockSearchClickAction(kind));
        }
    };

    searchEnterPressHandler = () => {
        const { dispatch, kind } = this.props;
        if ([ThirdPartyKind.UNSPLASH, ShutterstockKind.FREE].includes(kind)) {
            dispatch(unsplashSearchEnterPressAction());
        } else {
            dispatch(shutterstockSearchEnterPressAction(kind));
        }
    };

    clear = () => {
        const { dispatch, kind } = this.props;
        if ([ThirdPartyKind.UNSPLASH, ShutterstockKind.FREE].includes(kind)) {
            dispatch(unsplashCleanSearchAction());
        } else {
            dispatch(shutterstockCleanSearchAction(kind));
        }
    };

    onTypeImagesClick = () => {
        const { dispatch, kind } = this.props;
        if (!([ThirdPartyKind.UNSPLASH, ShutterstockKind.FREE].includes(kind))) {
            dispatch(shutterstockTypeImagesClickedAction(kind));
        }
    };

    onTypeIllustrationsClick = () => {
        const { dispatch, kind } = this.props;
        if (!([ThirdPartyKind.UNSPLASH, ShutterstockKind.FREE].includes(kind))) {
            dispatch(shutterstockTypeIllustrationsClickedAction(kind));
        }
    };

    onPortraitBtnClick = () => {
        const { dispatch, kind } = this.props;
        if ([ThirdPartyKind.UNSPLASH, ShutterstockKind.FREE].includes(kind)) {
            dispatch(unsplashPortraitOrientationClickedAction());
        } else {
            dispatch(shutterstockPortraitOrientationClickedAction(kind));
        }
    };

    onLandscapeBtnClick = () => {
        const { dispatch, kind } = this.props;
        if ([ThirdPartyKind.UNSPLASH, ShutterstockKind.FREE].includes(kind)) {
            dispatch(unsplashLandscapeOrientationClickedAction());
        } else {
            dispatch(shutterstockLandscapeOrientationClickedAction(kind));
        }
    };

    onSearchInputChangeAction = () => {
        const { kind } = this.props;
        let actionToDispatch = SHUTTERSTOCK_SEARCH_INPUT_CHANGE_ACTION;
        if ([ThirdPartyKind.UNSPLASH, ShutterstockKind.FREE].includes(kind)) {
            actionToDispatch = UNSPLASH_SEARCH_INPUT_CHANGE_ACTION;
        }
        return actionToDispatch;
    };

    render() {
        const
            {
                kind,
                search,
                isTypeImages,
                isTypeIllustrations,
                isPortraitMode,
                isLandscapeMode,
                showBorder,
                dispatch,
                selectedCategoryId
            } = this.props,
            paneClassName = cx(styles.searchPane, { [styles.border]: showBorder }),
            isUnsplashTab = [ThirdPartyKind.UNSPLASH, ShutterstockKind.FREE].includes(kind);

        return (
            <div className={paneClassName}>
                <div className={styles.imageTypesContainer}>
                    {
                        !isUnsplashTab && <React.Fragment>
                            <ImageTypeButton
                                text="msg: common.images {Images}"
                                isSelected={isTypeImages}
                                onClick={this.onTypeImagesClick}
                            />
                            <ImageTypeButton
                                text="msg: common.illustrations {Illustrations}"
                                isSelected={isTypeIllustrations}
                                onClick={this.onTypeIllustrationsClick}
                            />
                        </React.Fragment>
                    }
                </div>
                <div className={styles.searchContainer}>
                    <div className={cx(styles.searchBox, { [styles.focused]: this.state.searchBoxInFocus })}>
                        <Input
                            state={search}
                            onChangeAction={this.onSearchInputChangeAction()}
                            actionParams={{
                                [SHUTTERSTOCK_ACTION_KIND]: kind,
                            }}
                            onEnterPress={this.searchEnterPressHandler}
                            placeholder="msg: FileChooser.searchBox.placeholder {Search for images and illustrations}"
                            containerClassName={styles.searchBoxInputContainer}
                            inputClassName={styles.searchBoxInput}
                            showErrors={false}
                            onFocus={() => this.setState({
                                searchBoxInFocus: true
                            })}
                            onBlur={() => (this.setState({
                                searchBoxInFocus: false
                            }))}
                            dispatch={dispatch}
                        />
                        {search.isTouched && search.value && (
                            <span className={styles.searchBoxClearBtn} onClick={this.clear} />
                        )}
                        <span className={styles.searchBoxIco} onClick={this.searchBtnClick} />
                    </div>
                </div>

                <div className={styles.orientationContainer}>
                    {
                        (!isUnsplashTab || (isUnsplashTab && selectedCategoryId !== AllCategoryId)) &&
                        <React.Fragment>
                            <OrientationBtn
                                title="msg: common.portraitImages {Portrait images}"
                                className={styles.portraitBtn}
                                selected={isPortraitMode}
                                onClick={this.onPortraitBtnClick}
                            />
                            <OrientationBtn
                                title="msg: common.landscapeImages {Landscape images}"
                                className={styles.landscapeBtn}
                                selected={isLandscapeMode}
                                onClick={this.onLandscapeBtnClick}
                            />
                        </React.Fragment>
                    }
                </div>
            </div>
        );
    }
}
