import { blogOnboardingGlobalDataVAT } from ".";
import { makeEpicStateSelector } from "../../../../../epics/makeEpic";
import { termsAndConditionsGlobalDataVAT } from "../../../../SiteSettings/termsAndConditions/termsAndConditionsEpic";
import { BlogErrorType, BlogLayout, OnboardingOption } from "../types";

const termsAndConditionsEpicSelector = makeEpicStateSelector(termsAndConditionsGlobalDataVAT);

export const selectOpenAITermsState = (state): boolean =>
    termsAndConditionsEpicSelector(state).openAI;

const blogOnboardingEpicSelector = makeEpicStateSelector(blogOnboardingGlobalDataVAT);

export const selectIsBlogLoading = (state): boolean =>
    blogOnboardingEpicSelector(state).isBlogLoading;

export const selectBlogError = (state): BlogErrorType =>
    blogOnboardingEpicSelector(state).errorType;

export const selectBlogHasPosts = (state): boolean | null =>
    blogOnboardingEpicSelector(state).blogHasPosts;

export const selectSelectedOnboardingOption = (state): OnboardingOption =>
    blogOnboardingEpicSelector(state).selectedOnboardingOption;

export const selectSelectedLayout = (state): BlogLayout =>
    blogOnboardingEpicSelector(state).selectedLayout;
