import { VALID_ROOT_BLOCK_TAGS, ROOT_BLOCK_TAG, ROOT_BLOCK_ATTRS } from './constants';
import { JS_PROTOCOL_PATTERN, JS_VOID_VALUE } from '../../../constants';
import { tinyMceHtmlSchema, tinyMceDomParser, tinyMceHtmlSerializer } from "./editorSetup";

const isValidBlockTag = tag => (
    VALID_ROOT_BLOCK_TAGS.indexOf(tag) > -1
);

const makeRootBlockElement = () => {
    const rootBlockElement = document.createElement(ROOT_BLOCK_TAG);
    Object.keys(ROOT_BLOCK_ATTRS).forEach(key => {
        rootBlockElement.setAttribute(key, ROOT_BLOCK_ATTRS[key]);
    });
    return rootBlockElement;
};

const defaultLinkGlobalStyle = 'link1';
const validLinkGlobalStylePattern = /link\d+/;
const validTextGlobalStylePattern = /text(normal|heading\d)/;

function processNode(node: HTMLElement, processChildren) {
    const isAnchorNode = node.tagName === 'A';
    const classNamePattern = isAnchorNode ? validLinkGlobalStylePattern : validTextGlobalStylePattern;

    // eslint-disable-next-line
    node.className = (node.className || '').split(' ')
        .filter(className => classNamePattern.test(className))
        .join(' ');

    if (!node.className) {
        if (isAnchorNode) {
            // eslint-disable-next-line
            node.className = defaultLinkGlobalStyle;
        } else {
            node.removeAttribute('class');
        }
    }

    node.removeAttribute('style');

    // pattern test below returns true for missing href attribute as well
    if (isAnchorNode && JS_PROTOCOL_PATTERN.test(node.getAttribute('href') || '')) {
        node.setAttribute('href', JS_VOID_VALUE);
    }

    if (processChildren) {
        for (const childNode of node.children) {
            // @ts-ignore
            processNode(childNode, true);
        }
    }
}

// Following validations are not possible in tinymce schema so we need to handle them manually
// 1. enforcing class pattern for links /link\d/, and adding default link class
// 2. all divs coming from outside should be removed
// 3. remove #comment nodes
// Hoping tinymce gets rid of other tags
function applyValidationRules(element) {
    const childNodes = Array.from(element.childNodes);
    let i = 0;

    while (i < childNodes.length) {
        const currentNode = childNodes[i];

        // @ts-ignore
        if (currentNode.nodeName === '#comment') {
            element.removeChild(currentNode);
            continue;
        }

        if (!(currentNode instanceof window.HTMLElement) || !isValidBlockTag(currentNode.tagName)) {
            if (currentNode instanceof window.HTMLElement) {
                // @ts-ignore
                processNode(currentNode);
            }

            // @ts-ignore
            if (tinyMceHtmlSchema.isValidChild(ROOT_BLOCK_TAG, currentNode.nodeName)) {
                const rootBlockElement = makeRootBlockElement();
                // @ts-ignore
                rootBlockElement.appendChild(currentNode);

                // Add consecutive valid children to the same root block
                for (let j = i + 1; j < childNodes.length; j++) {
                    const checkNode = childNodes[j];
                    // @ts-ignore
                    if (tinyMceHtmlSchema.isValidChild(ROOT_BLOCK_TAG, checkNode.nodeName)) {
                        i++;
                        // @ts-ignore
                        rootBlockElement.appendChild(checkNode);
                        // @ts-ignore
                        if (checkNode instanceof window.HTMLElement) {
                            // @ts-ignore
                            processNode(checkNode);
                        }
                    } else {
                        break;
                    }
                }

                element.insertBefore(rootBlockElement, childNodes[i + 1]);
            } else {
                // Flatten if block tag
                const nextNode = childNodes[i + 1];
                applyValidationRules(currentNode);

                // @ts-ignore
                while (currentNode.firstChild) {
                    // @ts-ignore
                    element.insertBefore(currentNode.firstChild, nextNode);
                }

                element.removeChild(currentNode);
            }
        } else {
            processNode(currentNode, true);
        }

        i++;
    }
}

// Pasted HTML may contain some tag soup and metadata. This function will parse
// then serialize the input using TinyMCE's functions.

const normalizeHtmlAccordingToTinyMCEHtmlSchema = (text: string): string => {
    const transformNode = tinyMceDomParser.parse(text);
    const nodeSecondBody: Node[] = transformNode.getAll('body');

    let bodyNode;

    // If a second body is found, take the child body
    if (nodeSecondBody.length) {
        bodyNode = nodeSecondBody[0];
    } else {
        bodyNode = transformNode;
    }

    const cleanedContent = tinyMceHtmlSerializer.serialize(bodyNode);
    const psuedoDom = document.createElement('div');
    psuedoDom.innerHTML = cleanedContent;

    applyValidationRules(psuedoDom);
    return psuedoDom.innerHTML;
};

export { normalizeHtmlAccordingToTinyMCEHtmlSchema, normalizeHtmlAccordingToTinyMCEHtmlSchema as default };
