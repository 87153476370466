import React, { useState, useEffect } from 'react';
import styles from '../LinkChooserDialog.css';
import type { InputState } from '../../../../../../components/inputControls/input/flowTypes';
import { Input } from '../../../../../../components/inputControls/input/view/index';
import { LC_PHONE_INPUT_CHANGE_ACTION, LC_PHONE_OPTION_CHANGE_ACTION, LC_RESET_PHONE_INPUT } from '../../actionTypes';
import { Msg } from '../../../../../intl/index';
import { LinkChooserOption } from '../LinkChooserOption';
import HorizontalSpacer from '../../../../HorizontalSpacer';
import VerticalSpacer from '../../../../VerticalSpacer';
import { openSiteSettingsDialog } from '../../../../../../components/SiteSettings/SiteSettingsDialog/actionCreators';
import { SiteSettingsTabName } from '../../../../../../components/SiteSettings/SiteSettingsDialog/constants';
import { KindToInputId } from '../../../../../../components/oneweb/TextLike/kindToInputId';
import { PHONE_KIND } from '../../../../../../components/oneweb/TextLike/Phone/kind';
import { GlobalVariablePhoneNumberKey } from '../../../../../../constants';
import { ENTER } from "../../../../../../components/App/epics/isKeyPressed/keyboardKeys";

import type { SiteSettingsDialogConfigWithInput } from '../../../../../../components/SiteSettings/SiteSettingsDialog/actionCreators';

type Props = {
    input: InputState,
    dispatch: Dispatch,
    currentLinkValue: string,
    phoneNumberFromSiteSettings?: null | string,
    onSave: Function
};

const STORED_IN_SITE_SETTINGS = 'STORED_IN_SITE_SETTINGS',
    CUSTOM = 'CUSTOM';

export const PhoneTabCom = (props: Props) => {
    const { currentLinkValue, dispatch, input, phoneNumberFromSiteSettings } = props;
    const [activeOptionKey, setActiveOptionKey] =
        useState(currentLinkValue === `#${GlobalVariablePhoneNumberKey}` ? STORED_IN_SITE_SETTINGS : CUSTOM);
    const focusPhoneFieldInSiteSettingsDialogDispatcher = () => dispatch(openSiteSettingsDialog(({
        activeTabKey: SiteSettingsTabName.GENERAL, activateInputWithId: KindToInputId[PHONE_KIND],
    } as SiteSettingsDialogConfigWithInput)));

    let customPhoneNumberRef;

    useEffect(() => {
        if (activeOptionKey === CUSTOM && customPhoneNumberRef) {
            customPhoneNumberRef.focus();
        }
    }, [activeOptionKey, customPhoneNumberRef]);

    return (
        <div className={styles.phoneTab}>
            <Msg k="lc.phone.introText" className={styles.introText}>Which phone number should the link go to?</Msg>
            <VerticalSpacer y={23} />
            <div className={styles.optionsWrapper}>
                <LinkChooserOption
                    isActive={activeOptionKey === STORED_IN_SITE_SETTINGS}
                    onClick={() => {
                        setActiveOptionKey(STORED_IN_SITE_SETTINGS);
                        dispatch({ type: LC_PHONE_OPTION_CHANGE_ACTION, payload: `#${GlobalVariablePhoneNumberKey}` });
                    }}
                    title={<Msg k="lc.phone.option.siteSettings">Phone number stored in website settings</Msg>}
                    contentRenderer={() => {
                        if (phoneNumberFromSiteSettings) {
                            return <React.Fragment>
                                {phoneNumberFromSiteSettings}
                                <HorizontalSpacer x={5} inline />
                                <a onClick={focusPhoneFieldInSiteSettingsDialogDispatcher}><Msg k="common.edit">Edit</Msg></a>
                            </React.Fragment>;
                        } else {
                            return <React.Fragment>
                                <Msg k="lc.phone.option.noPhoneInSiteSettings">No number stored yet.</Msg>
                                <HorizontalSpacer x={5} inline />
                                <a onClick={focusPhoneFieldInSiteSettingsDialogDispatcher}>
                                    <Msg k="lc.phone.option.addPhoneInSiteSettings">Add your phone number</Msg>
                                </a>
                            </React.Fragment>;
                        }
                    }}
                />
                <VerticalSpacer y={15} />
                <LinkChooserOption
                    isActive={activeOptionKey === CUSTOM}
                    onClick={() => {
                        setActiveOptionKey(CUSTOM);
                        if (input.value) {
                            dispatch({ type: LC_PHONE_INPUT_CHANGE_ACTION, payload: input.value });
                        } else {
                            dispatch({ type: LC_RESET_PHONE_INPUT });
                        }
                    }}
                    title={<Msg k="lc.phone.option.custom">Custom phone number</Msg>}
                    contentRenderer={() => {
                        return <React.Fragment>
                            <VerticalSpacer y={6} />
                            <Input
                                state={input}
                                onChangeAction={LC_PHONE_INPUT_CHANGE_ACTION}
                                errorsClassName={styles.phoneInputError}
                                placeholder="msg: common.phoneExample {e.g. +44 1234 567890}"
                                dispatch={dispatch}
                                onEnterPress={e => { if (e.key === ENTER) props.onSave(); }}
                                refHandler={(input) => {
                                    customPhoneNumberRef = input;
                                }}
                            />
                        </React.Fragment>;
                    }}
                />
            </div>
        </div>
    );
};
