import React from 'react';
import cx from 'classnames';
import styles from './MctaColorProperty.css';
import colorPickerStyles from "../../ColorPicker/view/ColorPicker.css";
import type { Color } from '../../../mappers/flowTypes';
import * as colorMapper from "../../../mappers/color";
import { showColorPickerAction } from "../../ColorPicker/actionCreators";
import { getPosition } from "../../../utils/mouse";
import { MctaVerticalBar } from '../index';
import { MctaOpacitySlider } from '../MctaSlider/MctaOpacitySlider';
import getStyleIntValue from "../../../utils/getStyleIntValue";
import Label from '../../../view/common/Label';
import { Msg } from '../../../view/intl/index';
import HorizontalSpacer from '../../../view/common/HorizontalSpacer';
import mctaLabelStyle from '../MctaSlider/MctaSlider.css';
import type { MctaColor as MctaColorType, ThemeOverrideBaseMctaColor } from './flowTypes';
import {
    THEME_BG_OVERRIDE_COLOR_PICKER,
    DEFAULT_COLOR_PICKER,
} from '../../ColorPicker/constants';
import type { ThemeColorDataType } from '../../ThemeGlobalData/flowTypes';
import { ColoredSwatch } from '../../ColorPicker/view/ColoredSwatch';
import { getColorsFromBackgroundTheme } from "../../ThemeGlobalData/themeRules";

const
    COLOR_PICKER_WIDTH = getStyleIntValue(colorPickerStyles, 'width'),
    getColorContentForThemeOverride = (props: ThemeOverrideBaseMctaColor) => {
        const { backgroundColor } = getColorsFromBackgroundTheme(props.backgroundTheme, props.themeColorsData);
        return (
            <ColoredSwatch
                color={props.themeColorsData[backgroundColor]}
                style={{ height: 26, width: 26 }}
            />
        );
    };

const MctaColor = (props: MctaColorType) => {
    const
        {
            opacity,
            onColorChangeAction,
            colorPickerOpened,
            onOpacityChangeAction,
            dispatch,
            showOpacity = true,
            isAccentColour = false
        } = props,
        getColor = (): Color => {
            if (props.colorPickerKind === DEFAULT_COLOR_PICKER) {
                const inColor = props.color;
                return inColor && [inColor[0], inColor[1], inColor[2], inColor[3], 1]; // always render with full alpha
            } else {
                /* props.color will not be available for non DEFAULT_COLOR_PICKER */
                return (props.themeColorsData as ThemeColorDataType)[
                    getColorsFromBackgroundTheme(props.backgroundTheme, props.themeColorsData).backgroundColor
                ];
            }
        },
        getColorStyle = () => {
            // @ts-ignore
            return props.color && { backgroundColor: colorMapper.toCss(getColor()) };
        },
        colorClassName = cx(styles.color, {
            [styles.empty]: !getColor(),
            [styles.dark]: getColor() && colorMapper.isDark(getColor()),
            [styles.light]: getColor() && colorMapper.isLight(getColor())
        }),
        onColorClick = e => {
            const
                ePosition = getPosition(e),
                position = { ...ePosition, x: ePosition.x - COLOR_PICKER_WIDTH };

            const commonShowColorPickerActionPayload = { position, onChangeAction: onColorChangeAction };
            if (props.colorPickerKind === THEME_BG_OVERRIDE_COLOR_PICKER) {
                dispatch(showColorPickerAction({
                    ...commonShowColorPickerActionPayload,
                    colorPickerKind: THEME_BG_OVERRIDE_COLOR_PICKER,
                    // @ts-ignore
                    backgroundTheme: props.backgroundTheme,
                    // @ts-ignore
                    themeColorsData: props.themeColorsData,
                    // @ts-ignore
                    tooltipTop: props.tooltipTop
                }));
            } else {
                dispatch(showColorPickerAction({
                    ...commonShowColorPickerActionPayload,
                    colorPickerKind: DEFAULT_COLOR_PICKER,
                    // @ts-ignore
                    color: props.color || undefined, // undefined here will be used by redux to populate color from the ColorPicker's defaultState
                    // @ts-ignore
                    onTheFlyChange: props.onTheFlyChange,
                }));
            }
        },
        onOpacityChange = opacity => {
            // Dispatch payload as object in future if we need at more places and correct all reducers listening to it.
            const payload =
                // @ts-ignore
                (props.colorPickerKind === THEME_BG_OVERRIDE_COLOR_PICKER) ? { opacity, themeColorsData: props.themeColorsData } : opacity;
            return dispatch({ type: onOpacityChangeAction, payload, amendToSelf: true });
        };

    return (
        <div className={styles.container}>
            <HorizontalSpacer x={5} />
            <div className={styles.colorContainer} onClick={onColorClick}>
                {
                    // @ts-ignore
                    props.labelCmp ||
                    <React.Fragment>
                        <Label isValue className={mctaLabelStyle.label}>
                            {isAccentColour ?
                                <span><Msg k="common.accentColor">Accent Colour</Msg>:</span> :
                                <Msg k="common.color">Colour</Msg>}
                        </Label>
                        <HorizontalSpacer x={8} />
                    </React.Fragment>
                }
                {
                    props.colorPickerKind === DEFAULT_COLOR_PICKER
                        ? <div className={colorClassName} style={getColorStyle()} />
                        : getColorContentForThemeOverride(props)
                }
                <HorizontalSpacer x={5} />
                <span className={cx(styles.toggle, { [styles.opened]: colorPickerOpened })}><span /></span>
            </div>
            {
                showOpacity && !isAccentColour && getColor() && [
                    <HorizontalSpacer x={13} key="1" />,
                    <MctaVerticalBar key="2" />,
                    <HorizontalSpacer x={13} key="3" />,
                    <MctaOpacitySlider
                        opacity={opacity}
                        onChange={onOpacityChange}
                        key="4"
                    />
                ]
            }

            <HorizontalSpacer x={5} />
        </div>
    );
};

export {
    MctaColor as default,
    MctaColor
};
