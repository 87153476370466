import React from 'react';
import cx from 'classnames';
import styles from './Signin.css';
import demoPageStyles from "../../main/demoLoginPage/DemoLoginPage.css";
import Msg from "../../../../../src/view/intl/Msg";
import VerticalSpacer from "../../../../../src/view/common/VerticalSpacer";
import { DemoLoginMessages } from "../../main/messages";
import { isPasswordValid, isCompactScreen, isMobileDevice } from "../../validators";
import InputField from "../../../../../src/view/common/Input/InputField";
import Button from "../../../../../src/view/common/Button/Button";
import { demoLogInAction,
    updatePasswordValue,
    showForgotPasswordPage,
    toggleShowPassword,
    showSignUpPage,
    cleanUpAllErrors,
    showInvalidPasswordError,
    cleanUpSigninScreen,
    signInLoading,
    keepLoggedInToggle } from "../actions";
import type { LoginPagePropTypes } from "../../types";
import { showTrialEndedPage } from "../../trialEnded/actions";
import { showVerificationEmailRequiredPageAction } from "../../verificationEmailRequired/actions";
import CheckBoxWithLabel from "../../../../../src/view/common/CheckBox/CheckBoxWithLabel";
import { normalizeEmail } from "../../../../../../server/shared/utils/normalizeEmail.js";
import { makeLinkIntlTag } from "../../../../../src/view/intl/index";
import { TrialAccountCombo } from "../../common/TrialAccountCombo";
import { showMobileBlockingPage } from "../../mobileBlocking/actions";
import { TrialSocialSignup } from "../../socialSignup/TrialSocialSignup";

const Signin = ({
    intl,
    dispatch,
    loading,
    keepLoggedIn,
    error,
    password,
    invalidUsernameOrPasswordError,
    userNotFoundError,
    userDisabledError,
    trialEndedError,
    showPasswordEnabled,
    invalidPasswordErrorMessage,
    userEmailVerificationRequiredError,
    trialAlreadyPurchased,
    appConfig
}: LoginPagePropTypes) => {
    const
        signInLink = (
            <a
                onClick={() => {
                    dispatch(cleanUpSigninScreen());
                    dispatch(showSignUpPage());
                }}
                className={styles.loginActionsLink}
            ><Msg k="common.createAccount">Create account</Msg></a>
        ),
        accountRef = React.createRef<any>(),
        isErrorFree = () => {
            let passwordValidity = isPasswordValid(password);
            let isErrorPresent = false;
            if (!passwordValidity) {
                dispatch(showInvalidPasswordError());
                isErrorPresent = true;
            }
            return !isErrorPresent;
        },
        initiateSignIn = () => {
            if (isErrorFree()) {
                if (isMobileDevice()) {
                    dispatch(showMobileBlockingPage());
                } else {
                    accountRef.current.dispatch();
                }
            }
        },
        onAccountChange = username => {
            dispatch(signInLoading());
            dispatch(demoLogInAction({
                username: normalizeEmail(username),
                password,
            }));
        },
        onEmailChange = () => {
            dispatch(cleanUpAllErrors());
        },
        getVerticalSpacerValues = () => {
            let compactScreen = isCompactScreen();
            let
                VERTICAL_SPACE_BEFORE_EMAIL = compactScreen ? 20 : 49,
                VERTICAL_SPACE_BEFORE_RESET_PASSWORD = compactScreen ? 60 : 137;

            return {
                VERTICAL_SPACE_BEFORE_EMAIL,
                VERTICAL_SPACE_BEFORE_RESET_PASSWORD
            };
        };

    const { VERTICAL_SPACE_BEFORE_EMAIL, VERTICAL_SPACE_BEFORE_RESET_PASSWORD } = getVerticalSpacerValues();

    const {
        signInHeaderLabel,
        passwordLabel,
        invalidPasswordError,
        invalidEmailCredentialsError,
        customError,
        trialSignInButtonLabel,
        forgotPasswordLabel,
        showLabel,
        hideLabel,
        userIsDisabledError,
        incorrectUsernameOrPasswordError
    } = DemoLoginMessages;

    const isError = error
        || userDisabledError
        || userNotFoundError
        || invalidUsernameOrPasswordError
        || trialAlreadyPurchased;

    if (trialEndedError) {
        dispatch(showTrialEndedPage());
    }
    if (userEmailVerificationRequiredError) {
        dispatch(showVerificationEmailRequiredPageAction());
    }

    return (
        <div>
            <div>
                <div className={styles.headerLabel}>
                    {intl.msgJoint(signInHeaderLabel)}
                </div>
                <div className={styles.headerMsg}>
                    <Msg k="demo.signin.header.msg">
                        Build your website now - choose a domain later.
                    </Msg>
                    <Msg k="demo.signin.header.msg.question" params={{ signInLink }}>
                        {`Want to try our trial? {signInLink}`}
                    </Msg>
                </div>
            </div>
            <VerticalSpacer y={VERTICAL_SPACE_BEFORE_EMAIL} />
            <div>
                <TrialAccountCombo
                    onChange={onAccountChange}
                    onEmailChange={onEmailChange}
                    errorChildren={isError && (
                        <span className={styles.invalidEmailError}>
                            {userNotFoundError && intl.msgJoint(invalidEmailCredentialsError)}
                            {invalidUsernameOrPasswordError && intl.msgJoint(incorrectUsernameOrPasswordError)}
                            {userDisabledError && intl.msgJoint(userIsDisabledError)}
                            {error && intl.msgJoint(customError)}
                            {
                                trialAlreadyPurchased &&
                                <Msg
                                    className={styles.trialAlreadyPurchased}
                                    k="demo.login.error.trialAlreadyPurchased"
                                    params={{
                                        cta: makeLinkIntlTag({ href: appConfig?.oneWeb.auth.cpUrl }),
                                    }}
                                >{`
                                    Looks like you already have a domain for your trial,
                                    please access your website via the <cta>control panel</cta>.
                                `}</Msg>
                            }
                        </span>
                    )}
                    ref={accountRef}
                />
                <div className={styles.passwordContainer}>
                    <div className={styles.passwordLabelContainer}>
                        <div className={styles.inputLabel}>
                            {intl.msgJoint(passwordLabel)}
                        </div>
                        <div
                            className={styles.showLabel}
                            onClick={() => {
                                dispatch(cleanUpAllErrors());
                                dispatch(toggleShowPassword());
                            }}
                        >
                            <span
                                className={cx({
                                    [styles.hideIcon]: showPasswordEnabled,
                                    [styles.showIcon]: !showPasswordEnabled,
                                })}
                            />
                            {intl.msgJoint(showPasswordEnabled ? hideLabel : showLabel)}
                        </div>
                    </div>
                    <InputField
                        type={showPasswordEnabled ? 'text' : 'password'}
                        value={password}
                        onChange={(updatedPasswordValue) => {
                            dispatch(cleanUpAllErrors());
                            dispatch(updatePasswordValue(updatedPasswordValue));
                        }}
                        onEnterPress={initiateSignIn}
                        className={styles.inputField}
                        isInvalid={invalidPasswordErrorMessage}
                    />
                    {invalidPasswordErrorMessage && <span className={styles.invalidEmailError}>
                        {intl.msgJoint(invalidPasswordError)}
                    </span>}
                </div>

                <VerticalSpacer y={10} />

                <TrialSocialSignup
                    appConfig={appConfig}
                    isSignIn
                    dispatch={dispatch}
                />

                <VerticalSpacer y={VERTICAL_SPACE_BEFORE_RESET_PASSWORD} />
                <div>
                    <div className={styles.loginButtonFooterContainer}>
                        <Button
                            onClick={initiateSignIn}
                            className={cx(styles.loginButton, {
                                [styles.loadingButton]: loading
                            })}
                        >
                            {!loading && intl.msgJoint(trialSignInButtonLabel)}
                            {loading && <div className={demoPageStyles.loader} />}
                        </Button>
                        <div className={styles.loginActionFooter}>
                            <CheckBoxWithLabel
                                label="msg: demo.login.keepLoggedIn.label {Keep me logged in}"
                                onClick={() => dispatch(keepLoggedInToggle())}
                                isChecked={keepLoggedIn}
                                disabled={false}
                                containerClassName={styles.keepLoggedIn}
                            />

                            <div
                                className={styles.forgotPassword}
                                onClick={() => {
                                    dispatch(showForgotPasswordPage());
                                }}
                            >
                                {intl.msgJoint(forgotPasswordLabel)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export {
    Signin
};
