/* eslint-disable max-len */
import * as R from "ramda";
import type { AnyAction } from "redux";
import makeEpic from "../../epics/makeEpic";
import { DEFAULT, CUSTOM } from "../../constants/app";
import { UiLocaleSelector } from "../TopBar/epics/languages/selectorActionTypes";
import {
    ONBOARDING_TARGET_LOCALE_CHANGED,
    ONBOARDING_CONTINUE_PRESSED,
    ONBOARDING_GOOGLE_PLACE_RESPONSE,
    ONBOARDING_WEBSITE_TITLE_CHANGED,
    ONBOARDING_STATE_UPDATED,
    ONBOARDING_FILL_LATER_PRESSED,
    ONBOARDING_REMOVE_LOGO_PRESSED,
    ONBOARDING_LOGO_CHOOSEN,
    ONBOARDING_STEP_BACK_BUTTON_PRESSED,
    ONBOARDING_ADDRESS_CHANGED,
    ONBOARDING_PHONE_NUMBER_CHANGED,
    ONBOARDING_CONTACT_EMAIL_CHANGED,
    ONBOARDING_ADDRESS_URL_ERASED_BY_USER_INPUT,
    ONBOARDING_TEMPLATE_SELECTOR_STEP_OPENED,
    GOOGLE_ADDRESS_FETCHED_FROM_GEOLOCATION_AFTER_CONTINUE_PRESSED,
    ONBOARDING_DELETE_OR_BACKSPACE_PRESSED_WHEN_LOGO_INPUT_IN_FOCUS,
    SEE_ALL_TEMPLATES_CLICKED,
    ONBOARDING_INITIALIZE_TEMPLATE_PREVIEW,
    TEMPLATE_SELECTOR_CHANGE_THEME_PREVIEW_PAGE,
    CHANGE_COLOR_PICKER_THEME_METHOD,
    CHANGE_COLOR_PICKER_CUSTOM_THEME_COLOR,
    CHANGE_SELECTED_COLOR_PALETTE,
    CANCEL_COLOR_PICKER_CUSTOM_THEME_COLOR,
    ONBOARDING_THEMING_BACK_BUTTON_PRESSED,
    ONBOARDING_GMB_CATEGORY_CHOSEN
} from "./actionTypes";
import loadTemplateSelector, {
    loadTemplateSelectorForOnboarding
} from "../TemplateSelector_DEPRECATED/actionCreators/loadTemplateSelector";
import type { ImageAsset } from "../App/flowTypes";
import "../App/flowTypes";
import { receiveOnly, withSelector, optionalReceiveOnly, optional } from "../../epics/makeCondition";
import { generalGlobalDataEpic, addressFields } from "../SiteSettings/General/generalGlobalDataEpic/generalGlobalDataEpic";
import appStatusVAT from "../App/epics/status/valueActionType";
import { ONBOARDING } from "../App/epics/status/appStatusTypes";
import { telInputValidation } from "../inputControls/input/commonValidations/telInputValidation";
import {
    TEMPLATE_IMPORT_COMPLETE,
    TEMPLATE_SELECTOR_TOPBAR_BACK_PRESSED,
    BACK_TO_TEMPLATE_SELECTOR,
    CHANGE_THEMING_TEMPLATE_PREVIEW_MODE,
    LOAD_TEMPLATE_SELECTOR_REPOSITORY_SITE_SUCCESS_ACTION,
    ONBOARDING_THEMING_BACK_BUTTON_PRESSED_ADD_TEMPLATE
} from "../TemplateSelector_DEPRECATED/actionTypes";
import { ONBOARDING_VAT } from "./onboardingVat";
import { isValidEmail } from "../../utils/validation";
import type { MsgJointInput } from "../../view/intl/index";
import "../../view/intl/index";
import { geocode } from "../googleMaps/googleMaps";
import { OWNER_INFO_SUCCESS } from "../App/actionTypes";
import { makeAddressUrl } from "../oneweb/TextLike/Address/addressFormats";
import { dropSelectedPubicTemplate } from "../TemplateSelector/public/selectedPublicTemplate";
import { ViewTypes } from "../TemplateSelector_DEPRECATED/constants";
import { WORKSPACE_DID_MOUNT } from "../Workspace/actionTypes";
import { computeThemeDataForTemplateSelector } from "../ThemeGlobalData/actionCreators";
import { COMPUTE_COLOR_THEME_TEMPLATE_SELECTOR_SUCCESS } from "../ThemeGlobalData/actionTypes";
import { DataSite } from "../../../dal/model/DataSite";
import { ImportTemplateErrorActions } from "../TemplateSelector_DEPRECATED/epics/importTemplate/importTemplateErrorActions";
import { getThemeColorsFromStylesheet } from "../Workspace/epics/stylesheets/selectors";
import {
    THEME_COLORS,
    blackColorValueHSL,
    whiteColorValueHSL,
    operationDetailsDataPageSetPath,
    operationDetailsLocalizationsByTemplateIdPath,
    operationDetailsSiteMapPath,
    operationDetailsSiteSettingsPath,
    getThemeColorIndex
} from "../ThemeGlobalData/constants";
import { CATEGORIES_INPUT } from "../ThemesAccordion/constants";
import type { selectedColorPickerPaletteType, OnboardingStepType } from "./flowTypes";
import { ONBOARDING_STEPS, getNextStep, getPreviousStep, stepConfig } from "./constants";
import { suggestDesignsByGmbCategories } from "./view/GMBCategoriesLib";
import { TemplateCategory } from "../TemplateSelector/TemplateCategory";
import subscriptionDataVAT from "../App/epics/subscriptionData/valueActionType";
import { isDemoSubscriptionType } from "../App/epics/subscriptionData/isDemoSubscriptionType";
import { ADD_THEME_HISTORY, SET_SELECTED_PALETTE_FOR_ONBOARDING } from "../AutoColorLeftPanel/actionTypes";
import { getDefultLocaleId } from "../Locale/getDefaultLocale";
import { isWsbFeatureEnabled } from "../ComponentTierManager/utils";
import { WsbFeature } from "../../../../server/shared/WsbFeature.js";
import { isOnePageDesign, showOnlyOnePageTemplates } from "./domainHashUtils";
import { EDITOR_ROUTE } from "../../../../server/shared/routes.js";
import { getWindowLocationQuery } from "../../utils/getWindowLocationQuery";
import { AUTH_COOKIE_EXISTS } from "../App/epics/auth/actionTypes";
import { makeKey } from "../../utils/localStorage";
import { isPartnerSubdomain } from "../ComponentTierManager/partnerSubdomainNameUtils";

type multipleActionsToDispatchType = Array<Record<string, string | Object>>;

const DEFAULT_LOCALE = getDefultLocaleId();
export type OnboardingEpicState = {
    step: OnboardingStepType;
    templateCategory: string;
    websiteTitle: string;
    phoneNumber: string;
    phoneValidationErrorMsg: MsgJointInput;
    contactEmail: string;
    contactEmailIsInvalid: boolean;
    audienceLocale: string;
    logoAsset: ImageAsset | null | undefined;
    address: string;
    addressUrl: string;
    addressName: string;
    addressId: string;
    addressPlaceId: string;
    addressLocation: {
        lat: number;
        lng: number;
    };
    addressViewport: {
        south: number;
        west: number;
        north: number;
        east: number;
    };
    addressStreetAddress: string;
    addressCity: string;
    addressArea: string;
    addressZip: string;
    addressCountryCode: string;
    addressFloor: string;
    gmbCategories: string;
    gmbFilteredDesigns: string[];
    isLoading: boolean;
    colorPaletteOpen: boolean;
    themingTemplatePreviewMode: string;
    selectedColorPickerTheme: string;
    selectedColorPickerPalette: selectedColorPickerPaletteType;
    themePreviewDataForOnboarding: Record<string, any>;
    temporarySelectedColorPickerPalette: null;
    isForwardDirection: boolean;
    isShopOnboarding: boolean;
    isBookingsOnboarding: boolean;
};
const defaultState: OnboardingEpicState = {
    step: ONBOARDING_STEPS.WELCOME,
    // ONBOARDING TODO
    templateCategory: "",
    websiteTitle: "",
    phoneNumber: "",
    phoneValidationErrorMsg: "",
    contactEmail: "",
    contactEmailIsInvalid: false,
    address: "",
    addressUrl: "",
    logoAsset: null,
    audienceLocale: DEFAULT_LOCALE,
    addressName: "",
    addressId: "",
    addressPlaceId: "",
    addressLocation: {
        lat: 0,
        lng: 0
    },
    addressViewport: {
        south: 0,
        west: 0,
        north: 0,
        east: 0
    },
    addressStreetAddress: "",
    addressCity: "",
    addressArea: "",
    addressZip: "",
    addressCountryCode: "",
    addressFloor: "",
    gmbCategories: "",
    gmbFilteredDesigns: [],
    isLoading: false,
    // Step 5 onboarding
    colorPaletteOpen: true,
    themingTemplatePreviewMode: ViewTypes.desktop,
    selectedColorPickerTheme: DEFAULT,
    // TODO: intialize based on template colors
    selectedColorPickerPalette: {
        index: "0",
        key: CATEGORIES_INPUT[0].key,
        palette: [...CATEGORIES_INPUT[0].palettes[0], whiteColorValueHSL, blackColorValueHSL],
        type: DEFAULT
    },
    themePreviewDataForOnboarding: {},
    temporarySelectedColorPickerPalette: null,
    isForwardDirection: true,
    isShopOnboarding: false,
    isBookingsOnboarding: false
};

const payloadFromState = state => {
    const payload: { addressName?: string, phoneNumber?: string, contactEmail?: string, addressUrl?: string } = {};
    addressFields.forEach(f => {
        payload[f] = state[f];
    });

    if (state.addressName === state.addressStreetAddress) {
        payload.addressName = defaultState.addressName;
    }

    if (!state.phoneValidationErrorMsg) {
        payload.phoneNumber = String(state.phoneNumber).trim();
    }

    if (state.contactEmail === "" || isValidEmail(state.contactEmail)) {
        payload.contactEmail = state.contactEmail;
    }

    payload.addressUrl = makeAddressUrl(payload);
    return payload;
};

const showShopOnboardingKey = "showShopOnboarding";
const showBookingsOnboardingKey = "showBookingsOnboarding";

export const onboardingEpic = makeEpic({
    defaultState,
    valueActionType: ONBOARDING_VAT,
    updaters: [
        {
            conditions: [AUTH_COOKIE_EXISTS],
            reducer: ({ state }) => {
                const newState = { ...state, isShopOnboarding: false, isBookingsOnboarding: false };
                const urlQuery = getWindowLocationQuery();

                if (window.sessionStorage.getItem(makeKey(showShopOnboardingKey)) === "yes") {
                    newState.isShopOnboarding = true;
                } else if (urlQuery.shop) {
                    window.sessionStorage.setItem(makeKey(showShopOnboardingKey), "yes");
                    newState.isShopOnboarding = true;
                }

                if (window.sessionStorage.getItem(makeKey(showBookingsOnboardingKey)) === "yes") {
                    newState.isBookingsOnboarding = true;
                } else if (urlQuery.bookings) {
                    window.sessionStorage.setItem(makeKey(showBookingsOnboardingKey), "yes");
                    newState.isBookingsOnboarding = true;
                }

                return { state: newState };
            }
        },
        {
            conditions: [WORKSPACE_DID_MOUNT],
            reducer: ({ state }) => {
                const { shop, bookings, ...query } = getWindowLocationQuery();

                if (shop || bookings) {
                    const newQuery = Object.keys(query).length
                        ? "?" + new URLSearchParams(query).toString()
                        : "";
                    history.replaceState(history.state, '', EDITOR_ROUTE + newQuery);
                }

                return { state };
            }
        },
        {
            conditions: [receiveOnly(generalGlobalDataEpic.valueActionType), appStatusVAT],
            reducer: ({ state, values: [generalGlobalData, appStatus], sourceAction }) => {
                if (appStatus !== ONBOARDING) {
                    return {
                        state
                    };
                }
                const { type } = sourceAction;
                let step: string = ONBOARDING_STEPS.WELCOME;

                if (
                    type === TEMPLATE_SELECTOR_TOPBAR_BACK_PRESSED ||
                    (type === BACK_TO_TEMPLATE_SELECTOR &&
                        sourceAction.payload &&
                        sourceAction.payload.backButtonActionType &&
                        sourceAction.payload.backButtonActionType === TEMPLATE_SELECTOR_TOPBAR_BACK_PRESSED)
                ) {
                    step = ONBOARDING_STEPS.TEMPLATE_SELECTOR;
                }

                // TODO: WBTGEN-19715
                const multipleActionsToDispatch = [
                    loadTemplateSelectorForOnboarding({
                        isFirstVisit: true,
                        source: "Onboarding. on load."
                    })
                ];
                return {
                    state: {
                        ...state,
                        websiteTitle: state.websiteTitle || generalGlobalData.websiteTitle || defaultState.websiteTitle,
                        phoneNumber: state.phoneNumber || generalGlobalData.phoneNumber || defaultState.phoneNumber,
                        contactEmail: state.contactEmail || generalGlobalData.contactEmail || defaultState.contactEmail,
                        ...addressFields.reduce((a, f) => ({ ...a, [f]: state[f] || generalGlobalData[f] || defaultState[f] }), {}),
                        logoAsset: state.logoAsset || generalGlobalData.logoAsset || defaultState.logoAsset,
                        audienceLocale: state.audienceLocale || generalGlobalData.audienceLocale || defaultState.audienceLocale,
                        gmbCategories: state.gmbCategories || generalGlobalData.gmbCategories || defaultState.gmbCategories,
                        step,
                        isForwardDirection: false
                    },
                    multipleActionsToDispatch
                };
            }
        },
        {
            conditions: [ONBOARDING_STEP_BACK_BUTTON_PRESSED],
            reducer: ({ state }) => ({
                state: { ...state, step: getPreviousStep(state.step), isForwardDirection: false }
            })
        },
        {
            conditions: [UiLocaleSelector],
            reducer: ({ state, values: [audienceLocale] }) => {
                return {
                    state: { ...state, audienceLocale }
                };
            }
        },
        {
            conditions: [ONBOARDING_TARGET_LOCALE_CHANGED],
            reducer: ({ state, values: [audienceLocale] }) => {
                return {
                    state: { ...state, audienceLocale }
                };
            }
        },
        {
            conditions: [ONBOARDING_LOGO_CHOOSEN],
            reducer: ({ state, values: [{ asset: logoAsset }] }) => {
                return {
                    state: { ...state, logoAsset }
                };
            }
        },
        {
            conditions: [
                optional(ONBOARDING_REMOVE_LOGO_PRESSED),
                optional(ONBOARDING_DELETE_OR_BACKSPACE_PRESSED_WHEN_LOGO_INPUT_IN_FOCUS)
            ],
            reducer: ({ state }) => {
                return {
                    state: { ...state, logoAsset: null }
                };
            }
        },
        {
            conditions: [ONBOARDING_WEBSITE_TITLE_CHANGED],
            reducer: ({ values: [value], state }) => {
                return {
                    state: { ...state, websiteTitle: value }
                };
            }
        },
        {
            conditions: [ONBOARDING_ADDRESS_CHANGED],
            reducer: ({ values: [address], state }) => {
                return {
                    state: { ...state, ...addressFields.reduce((a, f) => ({ ...a, [f]: defaultState[f] }), {}), address },
                    actionToDispatch: state.addressUrl
                        ? {
                            type: ONBOARDING_ADDRESS_URL_ERASED_BY_USER_INPUT
                        }
                        : null
                };
            }
        },
        {
            conditions: [ONBOARDING_PHONE_NUMBER_CHANGED],
            reducer: ({ values: [phoneNumber], state }) => {
                return {
                    state: { ...state, phoneNumber }
                };
            }
        },
        {
            conditions: [ONBOARDING_CONTACT_EMAIL_CHANGED],
            reducer: ({ values: [contactEmail], state }) => {
                return {
                    state: { ...state, contactEmail }
                };
            }
        },
        {
            conditions: [ONBOARDING_GOOGLE_PLACE_RESPONSE],
            reducer: ({ values: [address], state }) => {
                return {
                    state: { ...state, ...address }
                };
            }
        },
        {
            conditions: [optionalReceiveOnly(OWNER_INFO_SUCCESS), ONBOARDING_CONTINUE_PRESSED],
            reducer: ({ state, dispatchAsync, values: [ownerInfo] }) => {
                const multipleActionsToDispatch: multipleActionsToDispatchType = [];
                let updatedState = { ...state, step: getNextStep(state.step), isForwardDirection: true };

                if (state.step === ONBOARDING_STEPS.CONTACT) {
                    if (!state.addressUrl && state.address) {
                        geocode(state.address).then(place => {
                            dispatchAsync({
                                type: GOOGLE_ADDRESS_FETCHED_FROM_GEOLOCATION_AFTER_CONTINUE_PRESSED,
                                payload: place
                            } as AnyAction);
                        });
                    }

                    if (!state.addressCountryCode) {
                        let addressCountryCode;

                        if (ownerInfo) {
                            addressCountryCode = ownerInfo.country;
                        }

                        updatedState = { ...updatedState, addressCountryCode };
                    }

                    if (state.websiteTitle) {
                        updatedState = { ...updatedState, addressName: state.websiteTitle };
                    }

                    const payload = payloadFromState(updatedState);
                    multipleActionsToDispatch.push({
                        type: ONBOARDING_STATE_UPDATED,
                        payload
                    } as any);
                } else if (state.step === ONBOARDING_STEPS.LOGO) {
                    multipleActionsToDispatch.push({
                        type: ONBOARDING_STATE_UPDATED,
                        payload: {
                            logoAsset: state.logoAsset
                        }
                    });
                } else if (state.step === ONBOARDING_STEPS.WEBSITE_TITLE) {
                    multipleActionsToDispatch.push({
                        type: ONBOARDING_STATE_UPDATED,
                        payload: {
                            websiteTitle: state.websiteTitle
                        }
                    });
                } else if (state.step === ONBOARDING_STEPS.GMB_CATEGORIES) {
                    multipleActionsToDispatch.push({
                        type: ONBOARDING_STATE_UPDATED,
                        payload: {
                            gmbCategories: state.gmbCategories
                        }
                    });
                }

                return {
                    state: updatedState,
                    multipleActionsToDispatch
                };
            }
        },
        {
            conditions: [GOOGLE_ADDRESS_FETCHED_FROM_GEOLOCATION_AFTER_CONTINUE_PRESSED],
            reducer: ({ state, values: [address] }) => {
                const newState = { ...state, ...address };
                return {
                    state: newState,
                    multipleActionsToDispatch: [
                        {
                            type: ONBOARDING_STATE_UPDATED,
                            payload: payloadFromState(newState)
                        } as any
                    ]
                };
            }
        },
        {
            conditions: [ONBOARDING_FILL_LATER_PRESSED],
            reducer: ({ state }) => {
                const multipleActionsToDispatch: multipleActionsToDispatchType = [];
                let updatesToState = {};

                if (state.step === ONBOARDING_STEPS.CONTACT) {
                    updatesToState = {
                        address: defaultState.address,
                        addressUrl: defaultState.addressUrl,
                        phoneNumber: defaultState.phoneNumber,
                        contactEmail: defaultState.contactEmail
                    };
                    multipleActionsToDispatch.push({
                        type: ONBOARDING_STATE_UPDATED,
                        payload: updatesToState
                    });
                } else if (state.step === ONBOARDING_STEPS.LOGO) {
                    updatesToState = {
                        logoAsset: defaultState.logoAsset
                    };
                    multipleActionsToDispatch.push({
                        type: ONBOARDING_STATE_UPDATED,
                        payload: updatesToState
                    });
                } else if (state.step === ONBOARDING_STEPS.WEBSITE_TITLE) {
                    updatesToState = {
                        websiteTitle: defaultState.websiteTitle
                    };
                    multipleActionsToDispatch.push({
                        type: ONBOARDING_STATE_UPDATED,
                        payload: updatesToState
                    });
                } else if (state.step === ONBOARDING_STEPS.GMB_CATEGORIES) {
                    updatesToState = {
                        gmbCategories: defaultState.gmbCategories
                    };
                    multipleActionsToDispatch.push({
                        type: ONBOARDING_STATE_UPDATED,
                        payload: updatesToState
                    });
                }

                return {
                    state: { ...state, ...updatesToState, step: getNextStep(state.step), isForwardDirection: true },
                    multipleActionsToDispatch
                };
            }
        },
        {
            conditions: [withSelector(ONBOARDING_VAT, s => s.phoneNumber)],
            reducer: ({ state, values: [phoneNumber] }) => {
                let phoneValidationErrorMsg: MsgJointInput = "";

                if (phoneNumber) {
                    const validationResult = telInputValidation(phoneNumber);

                    if (!validationResult.isValid && validationResult.error) {
                        phoneValidationErrorMsg = validationResult.error.msg;
                    }
                }

                return {
                    state: { ...state, phoneValidationErrorMsg }
                };
            }
        },
        {
            conditions: [withSelector(ONBOARDING_VAT, s => s.contactEmail)],
            reducer: ({ state, values: [contactEmail] }) => {
                let contactEmailIsInvalid = false;

                if (contactEmail) {
                    contactEmailIsInvalid = !isValidEmail(contactEmail);
                }

                return {
                    state: { ...state, contactEmailIsInvalid }
                };
            }
        },
        {
            conditions: [appStatusVAT, withSelector(ONBOARDING_VAT, s => s.step)],
            reducer: ({ state, values: [appStatus, step] }) => {
                let actionToDispatch;

                if (appStatus === ONBOARDING && stepConfig[step].goal) {
                    actionToDispatch = {
                        type: stepConfig[step].goal.triggerOnActionType
                    };
                }

                return {
                    state,
                    actionToDispatch
                };
            }
        },
        {
            conditions: [ONBOARDING_THEMING_BACK_BUTTON_PRESSED],
            reducer: ({ state }) => {
                return {
                    state,
                    multipleActionsToDispatch: [
                        {
                            type: ONBOARDING_STEP_BACK_BUTTON_PRESSED
                        },
                        {
                            type: ONBOARDING_THEMING_BACK_BUTTON_PRESSED_ADD_TEMPLATE
                        }
                    ]
                };
            }
        },
        {
            conditions: [CHANGE_COLOR_PICKER_THEME_METHOD],
            reducer: ({ state }) => {
                let changedText = DEFAULT,
                    updatedSelectedColorPalette = null;

                if (state.selectedColorPickerTheme === DEFAULT) {
                    updatedSelectedColorPalette = state.selectedColorPickerPalette;
                    changedText = CUSTOM;
                }

                return {
                    state: {
                        ...state,
                        selectedColorPickerTheme: changedText,
                        temporarySelectedColorPickerPalette: updatedSelectedColorPalette
                    }
                };
            }
        },
        {
            conditions: [CANCEL_COLOR_PICKER_CUSTOM_THEME_COLOR],
            reducer: ({ state }) => {
                let multipleActionsToDispatch: multipleActionsToDispatchType = [];
                multipleActionsToDispatch.push({
                    type: CHANGE_COLOR_PICKER_THEME_METHOD
                });
                multipleActionsToDispatch.push({
                    type: CHANGE_SELECTED_COLOR_PALETTE,
                    payload: {
                        type: DEFAULT,
                        palette: state.temporarySelectedColorPickerPalette.palette
                    }
                });
                return {
                    state: { ...state, selectedColorPickerPalette: state.temporarySelectedColorPickerPalette },
                    multipleActionsToDispatch
                };
            }
        },
        {
            conditions: [CHANGE_SELECTED_COLOR_PALETTE],
            reducer: ({ values: [selectedPalette], state }) => {
                const updatedPalette = selectedPalette.palette;
                const mappedPalette = THEME_COLORS.reduce((acc, prop, index) => {
                    acc[prop] = updatedPalette[index];
                    return acc;
                }, {});
                return {
                    state: {
                        ...state,
                        themePreviewDataForOnboarding: {
                            ...state.themePreviewDataForOnboarding,
                            computedThemeColorsForNewOnBoarding: mappedPalette
                        },
                        selectedColorPickerPalette: selectedPalette
                    }
                };
            }
        },
        {
            conditions: [CHANGE_THEMING_TEMPLATE_PREVIEW_MODE],
            reducer: ({ values: [previewMode], state }) => {
                return {
                    state: { ...state, themingTemplatePreviewMode: previewMode }
                };
            }
        },
        {
            conditions: [CHANGE_COLOR_PICKER_CUSTOM_THEME_COLOR],
            reducer: ({ values: [{ color, themeColorName }], state }) => {
                const selectedPalette = state.selectedColorPickerPalette.palette;
                const colorIndex = getThemeColorIndex(themeColorName);
                const newSelectedPalette = selectedPalette.map(palette => palette.slice());
                newSelectedPalette[colorIndex] = color.slice();
                const actionToDispatch = {
                    type: CHANGE_SELECTED_COLOR_PALETTE,
                    payload: {
                        type: CUSTOM,
                        palette: newSelectedPalette
                    }
                };
                return {
                    state,
                    actionToDispatch
                };
            }
        }, // TODO: To be removed when a new reducer is created for themeHistoryView.
        {
            conditions: [SET_SELECTED_PALETTE_FOR_ONBOARDING],
            reducer: ({ state, values: [palette] }) => {
                const multipleActionsToDispatch = [
                    {
                        type: CHANGE_SELECTED_COLOR_PALETTE,
                        payload: {
                            type: CUSTOM,
                            palette
                        }
                    },
                    {
                        type: ADD_THEME_HISTORY,
                        payload: palette
                    }
                ];
                return {
                    state,
                    multipleActionsToDispatch
                };
            }
        },
        {
            conditions: [TEMPLATE_IMPORT_COMPLETE],
            reducer: ({ state }) => {
                dropSelectedPubicTemplate();
                return {
                    state
                };
            }
        },
        {
            conditions: [SEE_ALL_TEMPLATES_CLICKED],
            reducer: ({ state, values: [templateCategory] }) => {
                return {
                    state,
                    multipleActionsToDispatch: [
                        loadTemplateSelector({
                            isFirstVisit: true,
                            source: "Onboarding. Continue.",
                            filter: templateCategory
                        })
                    ]
                };
            }
        },
        {
            conditions: [ONBOARDING_INITIALIZE_TEMPLATE_PREVIEW],
            reducer: ({ values: [design], state }) => {
                const { accountName: designName, templateIds: designTemplateIds, uuidOfHomePage, name: designPageName } = design;
                return {
                    state: {
                        ...state,
                        themePreviewDataForOnboarding: {
                            uuidOfHomePage,
                            designPageName
                        }
                    },
                    multipleActionsToDispatch: [computeThemeDataForTemplateSelector(designTemplateIds[0], designName)]
                };
            }
        },
        {
            conditions: [TEMPLATE_SELECTOR_CHANGE_THEME_PREVIEW_PAGE],
            reducer: ({ values: [{ pageId }], state }) => {
                return {
                    state: { ...state, themePreviewDataForOnboarding: { ...state.themePreviewDataForOnboarding, uuidOfHomePage: pageId } }
                };
            }
        },
        {
            conditions: [COMPUTE_COLOR_THEME_TEMPLATE_SELECTOR_SUCCESS],
            reducer: ({ state, values: [result] }) => {
                const computedDataPageSetForNewOnBoarding = R.path(operationDetailsDataPageSetPath)(result) || null,
                    siteMap = R.path(operationDetailsSiteMapPath)(result) || null,
                    siteSettings = R.path(operationDetailsSiteSettingsPath)(result) || null,
                    localizationsByTemplateId = R.path(operationDetailsLocalizationsByTemplateIdPath)(result) || null;
                let palette;

                if (computedDataPageSetForNewOnBoarding) {
                    const themeColors = getThemeColorsFromStylesheet(computedDataPageSetForNewOnBoarding.stylesheet);
                    palette = [themeColors.accentColor, themeColors.mainColor, themeColors.whiteColor, themeColors.blackColor];
                } else {
                    palette = state.selectedColorPickerPalette.palette;
                }

                return {
                    state: {
                        ...state,
                        selectedColorPickerPalette: {
                            type: CUSTOM,
                            palette
                        },
                        themePreviewDataForOnboarding: {
                            ...state.themePreviewDataForOnboarding,
                            computedThemeColorsForNewOnBoarding: null,
                            computedDataPageSetForNewOnBoarding,
                            siteMap: new DataSite(siteMap),
                            siteSettings,
                            localizationsByTemplateId
                        }
                    },
                    actionToDispatch: {
                        type: ADD_THEME_HISTORY,
                        payload: palette
                    }
                };
            }
        },
        ...ImportTemplateErrorActions.map(action => ({
            conditions: [action],
            reducer: ({ state }) => ({
                state: { ...state, isLoading: false }
            })
        })),
        {
            conditions: [ONBOARDING_GMB_CATEGORY_CHOSEN],
            reducer: ({ state, values: [gmbCategories] }) => {
                if (gmbCategories.split(",").length <= 3) {
                    return {
                        state: { ...state, gmbCategories }
                    };
                } else {
                    return {
                        state
                    };
                }
            }
        },
        {
            conditions: [
                LOAD_TEMPLATE_SELECTOR_REPOSITORY_SITE_SUCCESS_ACTION,
                receiveOnly(subscriptionDataVAT),
                ONBOARDING_TEMPLATE_SELECTOR_STEP_OPENED
            ],
            reducer: ({ state, values: [{ designs: allDesigns, subCategoriesToDesignsMap }, { subscriptionType }] }) => {
                const isDemo = isDemoSubscriptionType(subscriptionType);
                const designs = allDesigns.filter(({ accountName }) => !isOnePageDesign(accountName));
                const allowedDesigns = isDemo
                    ? designs.filter(design => !design.categories.includes(TemplateCategory.ONLINE_STORE))
                    : designs;
                const { gmbCategories } = state;

                let allowedDesignsNames: Array<string>;

                if (!isWsbFeatureEnabled(WsbFeature.OnlineShop) || isPartnerSubdomain()) {
                    allowedDesignsNames = allowedDesigns.reduce((list, design) => {
                        if (design.categories?.includes(TemplateCategory.ONLINE_STORE)) {
                            return list;
                        }

                        list.push(design.accountName);

                        return list;
                    }, []);
                } else {
                    allowedDesignsNames = allowedDesigns.map(({ accountName }) => accountName);
                }

                const suggestedDesigns = suggestDesignsByGmbCategories({
                    allowedDesigns: allowedDesignsNames,
                    gmbCategories: gmbCategories ? gmbCategories.split(",").map(gc => gc.replace("gcid:", "")) : [],
                    subCategoriesToDesignsMap
                });
                const showOnePageDesigns = showOnlyOnePageTemplates();
                let gmbFilteredDesigns = suggestedDesigns.map(suggestedDesign => {
                    let design;
                    if (showOnePageDesigns) {
                        design = allDesigns.find(({ accountName }) => accountName === `${suggestedDesign}-onepage`);
                    }
                    return design || allDesigns.find(({ accountName }) => accountName === suggestedDesign);
                });
                if (showOnePageDesigns) {
                    gmbFilteredDesigns.sort((_, { accountName }) => (isOnePageDesign(accountName) ? 1 : -1));
                }

                if (gmbFilteredDesigns.length > 1) {
                    // Swap first and 2nd item
                    const firstDesign = gmbFilteredDesigns[0];
                    gmbFilteredDesigns[0] = gmbFilteredDesigns[1];
                    gmbFilteredDesigns[1] = firstDesign;
                }
                // Is called for all only?

                return {
                    state: { ...state, gmbFilteredDesigns }
                };
            }
        }
    ]
});
