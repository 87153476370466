const { ConfigComponentList } = require("../constants.js");

class ContactsConfig {
    isContactsShown() {
        const displayContacts = this._featuresConfig[ConfigComponentList.CONTACTS];
        return displayContacts && displayContacts.enabled;
    }
}

module.exports = ContactsConfig;
