import React from 'react';
import Measure from "react-measure";
import cx from 'classnames';
import styles, { borderColor } from './FileUploader.css';
import { injectIntl, Intl } from "../../view/intl/index";
import type { MsgJointInput } from "../../view/intl/index";
import { BorderLines } from "../BorderLines/borderLines";

type FileUploaderProps = {
    intl: Intl,
    onDrop: Function;
    title?: MsgJointInput;
    hint?: MsgJointInput;
    children?: any;
    className?: string;
    placeholderClass?: string;
    dropZoneClass?: string;
    uploadIconClass?: string;
    titleClass?: string;
    showOverlay?: boolean;
    noOverlay?: boolean;
    hideMessage?: boolean;
    style?: Record<string, any>
};

type FileUploaderState = {
    dragging: boolean;
    width: number;
    height: number;
}

const FileUploaderDropEffect = 'copy';

const isFilesDragged = (data: DataTransfer) => {
    if (data.types instanceof Array) {
        return data.types.includes('Files');
    }
    // IE: types is instance of DOMStringList
    // @ts-ignore
    for (const dataType of data.types) {
        if (dataType === 'Files') {
            return true;
        }
    }
    return false;
};

class FileUploaderComponent extends React.Component <FileUploaderProps, FileUploaderState> {
    dropRef = React.createRef<HTMLDivElement>();
    dragCounter: number = 0;
    state = {
        dragging: false,
        width: 0,
        height: 0
    };

    setDropEffect = (e) => {
        try {
            e.dataTransfer.dropEffect = FileUploaderDropEffect;
        } catch (e) { /* IE doesn't support dropEffect */ }
    };

    handleDragIn = (e) => {
        e.preventDefault();
        e.stopPropagation();

        this.dragCounter++;
        if (this.dragCounter === 1 && isFilesDragged(e.dataTransfer)) {
            this.setState({ dragging: true });
        }
    };
    handleDragOut = (e) => {
        e.preventDefault();
        e.stopPropagation();

        this.dragCounter--;
        if (this.dragCounter > 0) return;
        this.setState({ dragging: false });
    };
    handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();

        this.setState({ dragging: false });
        this.dragCounter = 0;

        this.props.onDrop(e, e.dataTransfer.files);
    };

    onResize = ({ offset: { width, height } }) => {
        this.setState({ width, height });
    };

    render() {
        const {
            className,
            titleClass,
            dropZoneClass,
            placeholderClass,
            uploadIconClass,
            showOverlay,
            hideMessage,
            noOverlay,
            intl,
            title,
            hint,
            style
        } = this.props;
        const dimensions = {
            width: this.state.width,
            height: this.state.height
        };
        const canShowOverlay = showOverlay || this.state.dragging;
        return <div
            ref={this.dropRef}
            onDragOver={this.setDropEffect}

            onDragEnter={this.handleDragIn}
            onDragLeave={this.handleDragOut}
            onDrop={this.handleDrop}
            className={cx(className, {
                [styles.overlay]: !noOverlay,
                [styles.showOverlay]: canShowOverlay,
                isDragging: this.state.dragging
            }, styles.container)}
            style={style}
        >
            {!noOverlay && <div className={cx(placeholderClass, styles.placeholder)}>
                <Measure offset onResize={this.onResize}>
                    {({ measureRef }) => (
                        <BorderLines
                            curved
                            ref={measureRef}
                            className={cx(styles.dropZone, dropZoneClass, styles.borderLine)}
                            {...dimensions}
                            color={borderColor}
                        >
                            {
                                !hideMessage && <div className={styles.message}>
                                    <div className={cx(styles.uploadIcon, uploadIconClass)} />
                                    {title && <span className={cx(titleClass, styles.title)}>{intl.msgJoint(title)}</span>}
                                    {hint && <span className={styles.hint}>{intl.msgJoint(hint)} </span>}
                                </div>
                            }
                        </BorderLines>
                    )}
                </Measure>

            </div>}
            {this.props.children}
        </div>;
    }
}

const FileUploader = injectIntl(FileUploaderComponent);

export { FileUploader };
