// @ts-nocheck
import { createStore as reduxCreateStore, applyMiddleware, compose } from 'redux';
import rootReducer from './modules';
import * as middleware from './middleware';
import epicMid from '../epics/middleware';
import type { AppState } from "./modules/flowTypes";
import { info } from '../../utils/log.js';
import $R from '../debug/$R';
import isDevEnv from "../debug/isDevEnv";
import { crashTraceCollectorMiddleware, getCrashTrace } from "./middleware/crashTraceCollector";
import { debounceActionWithRafMiddleware } from "./middleware/debounceActionWithRafMiddleware";
import { fileChooserMiddleware } from "../view/common/FileChooser/fileChooserMiddleware";
import {
    initAdjustmentDataDispatchCache
} from '../components/Workspace/epics/componentsEval/adjustmentDataDispatchCache';
import { socialAccountsInstagramMiddleware } from "../components/SocialAccounts/instagram/socialAccountsInstagramMiddleware";
import { instagramGalleryMiddleware } from "../components/oneweb/InstagramGallery/instagramGalleryMiddleware";
import { facebookSdkMiddleware } from "../components/SocialAccounts/Facebook/facebookSdkMiddleware";
import { facebookFeedMiddleware } from "../components/SocialAccounts/Facebook/FacebookFeed/facebookFeedMiddleware";
import { facebookChatMiddleware } from "../components/SocialAccounts/Facebook/FacebookChat/facebookChatMiddleware";
import { facebookFeedGalleryMiddleware } from "../components/oneweb/FacebookFeedGallery/facebookFeedGalleryMiddleware";
import { socialAccountsMiddleware } from "../components/SocialAccounts/socialAccountsMiddleware";
import { publishSiteMiddleware } from "../components/TopBar/epics/publishSite/publishSiteMiddleware";
import { AutosaveMiddleware } from '../components/Autosave/middleware';
import { eventTrackerMiddleware } from "../components/EventTracking";

// see: reduxDevToolsOptionsLoader
const __reduxDevToolsOptions = {};

const
    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__(__reduxDevToolsOptions)
        : compose,
    epicsMiddleware = process.env.NODE_ENV === 'development'
        ? require('redux-replaceable-middleware')(epicMid)
        : epicMid,
    middlewares = window.__testCaseMode
        ? []
        : [
            debounceActionWithRafMiddleware,
            crashTraceCollectorMiddleware,
            middleware.catchMiddlewareExceptionMiddleware,
            // middleware.simplelogger,
            middleware.cookie,
            !window.___nodeTests && middleware.window,
            middleware.api,
            middleware.saga,
            middleware.adjustmentData,
            middleware.schedule,
            middleware.raf,
            middleware.recoverAfterExceptionMiddleware,
            middleware.localeChangeWorkaround,
            middleware.clipboard,
            middleware.topBarTreeStateMiddleware,
            fileChooserMiddleware,
            socialAccountsInstagramMiddleware,
            instagramGalleryMiddleware,
            facebookFeedGalleryMiddleware,
            facebookSdkMiddleware,
            facebookFeedMiddleware,
            facebookChatMiddleware,
            socialAccountsMiddleware,
            eventTrackerMiddleware,
            // IMPORTANT: Since Autosave impacts publish, hence it must always be placed before the publishSiteMiddleware
            AutosaveMiddleware,
            publishSiteMiddleware,
            ...middleware.moduleMiddlewares,
            epicsMiddleware,
            (isDevEnv() && middleware.devInitNewDomain) || undefined,
            middleware.catchReducerExceptionMiddleware,
            middleware.awsMiddleware,
        ].filter(x => x),
    finalCreateStore = composeEnhancers(
        applyMiddleware(...middlewares),
    )(reduxCreateStore);

function restartSagas(store) {
    const sagaManager = require('./SagaManager').default;
    sagaManager.cancelSagas(store);
    sagaManager.startSagas(middleware.saga);
}

function createStore(initialState?: AppState): Store {
    const store = finalCreateStore(rootReducer, initialState);

    initAdjustmentDataDispatchCache(store.dispatch);
    $R.store = store;
    $R.getCrashTrace = getCrashTrace;
    $R.simulateCrashFrom = {
        // TODO remove in WBTGEN-4990
        middleware: () => store.dispatch(({ type: "MIDDLEWARE_CRASH" } as any)),
        render: () => store.dispatch(({ type: "RENDER_CRASH" } as any)),
        reducer: () => store.dispatch(({ type: "REDUCER_CRASH" } as any)),
        epic: () => store.dispatch(({ type: "EPIC_CRASH" } as any)),
        promise: () => {
            (new Promise((resolve) => {
                setTimeout(resolve, 1);
            })).then(() => {
                throw new Error('WBTGEN Promise crash');
            });
        },
        timeout: () => {
            setTimeout(() => {
                throw new Error('WBTGEN timeout crash');
            }, 1);
        }
    };

    if (!window.__testCaseMode) {
        restartSagas(store);
    }

    if (module.hot) {
        module.hot.accept(
            './modules',
            () => {
                info('HOT: replacing reducer');
                store.replaceReducer(require('./modules').default);
            }
        );

        module.hot.accept(
            '../epics/middleware',
            () => {
                info('HOT: replacing epic');

                epicsMiddleware.replaceBy(require('../epics/middleware').default);
            }
        );

        module.hot.accept(
            './middleware/index',
            () => {
                info('HOT: replacing middleware');
                store.replaceReducer(require('./modules').default);
            }
        );
        module.hot.accept('./SagaManager', () => {
            info('HOT: replacing sagas');
            restartSagas(store);
        });
    }

    return store;
}

export {
    createStore
};
