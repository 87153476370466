import { isBlockPageItemType } from "../../../../dal/pageMapAdapter/componentTypesMap";

export type Rect = {
    top: number;
    left: number;
    bottom: number;
    right: number;
};

type Params = {
    planeWidth: number;
    planeHeight: number;
    items: Array<Record<string, any>>;
    includeAll?: boolean;
};

const normalizeItem = ({ type, bbox }: Record<string, any>, planeWidth: number) => ({
    type,
    top: Math.max(bbox.top, 0),
    left: Math.max(bbox.left, 0),
    bottom: bbox.bottom,
    right: Math.min(bbox.right, planeWidth)
});

const SkipBlockThreshold = {
    W: 5,
    H: 50,
};

const isPotentialBackgroundRect = (inRect, planeWidth, planeHeight) => {
    const rect: Record<string, any> = {
        ...inRect,
        bottom: Math.min(inRect.bottom, planeHeight),
    };

    return (
        isBlockPageItemType(rect.type)
        && (
            // check if within width margins
            rect.left >= 0 && rect.left <= SkipBlockThreshold.W
            && (rect.right >= (planeWidth - SkipBlockThreshold.W) && rect.right <= planeWidth)
        ) && (
            // check if within height margin
            rect.bottom >= (planeHeight - SkipBlockThreshold.H) && rect.bottom <= planeHeight
        )
    );
};

const getCorrectedPlaneHeight = (items, planeWidth, planeHeight) => {
    let
        correctedPlaneHeight = 0,
        hasPotentialBackgroundRect = false;

    items.forEach(itm => {
        const rect = normalizeItem(itm, planeWidth);
        if (isPotentialBackgroundRect(rect, planeWidth, planeHeight)) {
            hasPotentialBackgroundRect = hasPotentialBackgroundRect || true;
            return;
        }
        correctedPlaneHeight = Math.max(rect.bottom);
    });

    return hasPotentialBackgroundRect ? correctedPlaneHeight : planeHeight;
};

const
    yIntersection = (a: Rect, b: Rect): boolean => (a.top < b.bottom && b.top < a.bottom),
    xIntersection = (a: Rect, b: Rect): boolean => (a.left < b.right && b.left < a.right),
    intersection = (a: Rect, b: Rect): boolean => xIntersection(a, b) && yIntersection(a, b),
    xContains = (a: Rect, b: Rect): boolean => (a.left < b.left && a.right > b.right),
    yContains = (a: Rect, b: Rect): boolean => (a.top < b.top && a.bottom > b.bottom),
    contains = (a: Rect, b: Rect): boolean => (xContains(a, b) && yContains(a, b));

export const area = (rect: Rect): number => ((rect.right - rect.left) * (rect.bottom - rect.top));

/**
 * TODO: refactor and optimize
 */
export function EmptyRects(this: any, { planeWidth, planeHeight: inPlaneHeight, items: inItems, includeAll = false }: Params) {
    const emptyRects: any[] = [];

    let largestEmptyRect;
    let widestBottomEmptyRect;

    const planeHeight = getCorrectedPlaneHeight(inItems, planeWidth, inPlaneHeight);

    // only deal with items within plane width
    const items = inItems.filter(itm => itm.bbox.left < planeWidth);

    if (items.length) {
        // default edges
        const
            topEdge = { top: 0, bottom: 0, left: 0, right: planeWidth },
            bottomEdge = { top: planeHeight, bottom: planeHeight, left: 0, right: planeWidth },
            leftEdge = { left: 0, right: 0, top: 0, bottom: planeHeight },
            rightEdge = { left: planeWidth, right: planeWidth, top: 0, bottom: planeHeight };

        widestBottomEmptyRect = topEdge;

        const
            xSortedRects: Array<Rect> = [
                leftEdge,
                ...items.map((i: Record<string, any>): Rect => normalizeItem(i, planeWidth)).sort((a, b) => {
                    if (a.left === b.left) return 0;
                    return a.left < b.left ? -1 : 1;
                }),
                rightEdge
            ],
            ySortedRects: Array<Rect> = [
                topEdge,
                ...items.map((i: Record<string, any>): Rect => normalizeItem(i, planeWidth)).sort((a, b) => {
                    if (a.top === b.top) return 0;
                    return a.top < b.top ? -1 : 1;
                }),
                bottomEdge
            ];

        for (let i = 0; i < xSortedRects.length - 1; i++) {
            const leftSupport: Rect = xSortedRects[i];

            if ((leftSupport.right - leftSupport.left) > planeWidth) {
                continue;
            }

            for (let j = i + 1; j < xSortedRects.length; j++) {
                const nextJ = xSortedRects[j];
                if (leftSupport.right < nextJ.left/* && yIntersection(leftSupport, next) */) {
                    let rightSupport = nextJ;

                    // define top/bottom supports
                    const intersectionRect = {
                        top: yIntersection(leftSupport, rightSupport)
                            ? Math.max(leftSupport.top, rightSupport.top)
                            : Math.min(leftSupport.top, rightSupport.top),
                        bottom: yIntersection(leftSupport, rightSupport)
                            ? Math.min(leftSupport.bottom, rightSupport.bottom)
                            : Math.max(leftSupport.bottom, rightSupport.bottom),
                        left: leftSupport.right,
                        right: rightSupport.left
                    };

                    let
                        topSupport = topEdge,
                        bottomSupport = bottomEdge,
                        invalidIntersection = false;

                    for (const nextK of ySortedRects) {
                        if (isPotentialBackgroundRect(nextK, planeWidth, planeHeight)) {
                            continue;
                        }

                        if (intersection(intersectionRect, nextK)) {
                            invalidIntersection = true;
                            break;
                        }

                        if (xIntersection(nextK, intersectionRect)) {
                            if (
                                nextK.bottom < intersectionRect.bottom
                                && nextK.bottom > topSupport.bottom
                            ) {
                                topSupport = nextK;
                            }
                            if (
                                nextK.top > intersectionRect.top
                                && nextK.top < bottomSupport.top
                                && nextK.top > topSupport.bottom
                            ) {
                                bottomSupport = nextK;
                            }
                        }
                    }

                    if (invalidIntersection) continue;

                    const emptyRect = {
                        top: topSupport.bottom,
                        bottom: bottomSupport.top,
                        left: leftSupport.right,
                        right: rightSupport.left
                    };

                    if (includeAll) {
                        emptyRects.push(emptyRect);
                    }

                    if (
                        !(
                            emptyRect.top === topEdge.top
                            && emptyRect.left === leftEdge.left
                            && emptyRect.bottom === bottomEdge.bottom
                            && emptyRect.right === rightEdge.right
                        )
                        && (!largestEmptyRect || area(emptyRect) > area(largestEmptyRect))
                    ) {
                        largestEmptyRect = emptyRect;
                    }
                }
            }
        }

        for (let i = 0; i < ySortedRects.length - 1; i++) {
            const topSupport: Rect = ySortedRects[i];

            for (let j = i + 1; j < ySortedRects.length; j++) {
                const nextJ = ySortedRects[j];

                if (topSupport.bottom < nextJ.top/* && xIntersection(topSupport, next) */) {
                    let bottomSupport = nextJ;

                    // define left / right supports
                    const intersectionRect = {
                        top: topSupport.bottom,
                        bottom: bottomSupport.top,
                        left: Math.min(topSupport.left, bottomSupport.left),
                        right: Math.max(topSupport.right, bottomSupport.right)
                    };

                    let
                        leftSupport = leftEdge,
                        rightSupport = rightEdge,
                        invalidIntersection = false;

                    for (const nextK of xSortedRects) {
                        if (isPotentialBackgroundRect(nextK, planeWidth, planeHeight)) {
                            continue;
                        }

                        if (intersection(intersectionRect, nextK)) {
                            invalidIntersection = true;
                            break;
                        }

                        if (yIntersection(nextK, intersectionRect)) {
                            if (
                                nextK.right < intersectionRect.right
                                && nextK.right > leftSupport.right
                            ) {
                                leftSupport = nextK;
                            }
                            if (
                                nextK.left > intersectionRect.left
                                && nextK.left < rightSupport.left
                                && nextK.left > leftSupport.right
                            ) {
                                rightSupport = nextK;
                            }
                        }
                    }

                    if (invalidIntersection) continue;

                    const emptyRect = {
                        top: topSupport.bottom,
                        bottom: bottomSupport.top,
                        left: leftSupport.right,
                        right: rightSupport.left
                    };

                    if (includeAll) {
                        emptyRects.push(emptyRect);
                    }

                    if (
                        !(
                            emptyRect.top === topEdge.top
                            && emptyRect.left === leftEdge.left
                            && emptyRect.bottom === bottomEdge.bottom
                            && emptyRect.right === rightEdge.right
                        )
                        && (!largestEmptyRect || area(emptyRect) > area(largestEmptyRect))
                    ) {
                        largestEmptyRect = emptyRect;
                    }

                    // TODO: fix
                    if (emptyRect.left === leftEdge.left
                        && emptyRect.right === rightEdge.right
                        && emptyRect.top > widestBottomEmptyRect.bottom
                    ) {
                        widestBottomEmptyRect = emptyRect;
                    }
                }
            }
        }

        // fallback to bottom
        if (!largestEmptyRect) {
            largestEmptyRect = {
                top: planeHeight,
                bottom: planeHeight + 1,
                left: 0,
                right: planeWidth,
            };
        }

        // fixes
        let fixedLargestEmptyRect;

        // y direction
        const yMedian = ((largestEmptyRect.bottom - largestEmptyRect.top) / 2) + largestEmptyRect.top;
        for (const yRect of ySortedRects) {
            if (intersection(largestEmptyRect, yRect) || contains(largestEmptyRect, yRect)) {
                // fix top edge of LER
                if (yRect.bottom < yMedian) {
                    // fix top edge of LER
                    fixedLargestEmptyRect = fixedLargestEmptyRect || { ...largestEmptyRect };
                    fixedLargestEmptyRect.top = Math.max(yRect.bottom, fixedLargestEmptyRect.top);
                } else if (yRect.top > yMedian) {
                    // fix bottom edge of LER
                    fixedLargestEmptyRect = fixedLargestEmptyRect || { ...largestEmptyRect };
                    fixedLargestEmptyRect.bottom = Math.min(yRect.top, fixedLargestEmptyRect.bottom);
                }
            }
        }

        if (fixedLargestEmptyRect) largestEmptyRect = fixedLargestEmptyRect;
    } else {
        largestEmptyRect = {
            top: 0,
            bottom: planeHeight,
            left: 0,
            right: planeWidth
        };
        widestBottomEmptyRect = { top: planeHeight, bottom: planeHeight, left: 0, right: planeWidth };
    }

    this.largest = (): Rect => ({ ...largestEmptyRect });
    this.all = (): Array<Rect> => {
        if (!includeAll) throw new Error("'includeAll' option is disabled");
        return [...emptyRects];
    };
    this.widestBottom = (): Rect => ({ ...widestBottomEmptyRect });
}
