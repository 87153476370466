import { AppStore } from "../../../../redux/modules/flowTypes";
import { closeDialog, openDialog } from "../../../App/actionCreators";
import { PUBLISH } from "../../../App/actionTypes";
import EmptyBlogDialogId from "../EmptyBlogDialog/dialogId";
import { getBlogDetails } from "../OnboardingWizard/epic/actionCreators";
import {
    GET_BLOG_DETAILS_FAILURE,
    GET_BLOG_DETAILS_SUCCESS
} from "../OnboardingWizard/epic/actionTypes";
import { PUBLISHED_POSTS_CHECK_TIMEOUT, postStatus } from "../constants";
import { CHECK_FOR_PUBLISHED_POSTS } from "./actionTypes";

export const blogMiddleware = (store: AppStore) => {
    const scope = {
        isPublishing: false
    };

    let apiTimer: NodeJS.Timeout;

    const startApiTimer = () => {
        apiTimer = setTimeout(() => {
            store.dispatch({ type: PUBLISH });
            scope.isPublishing = false;
        }, PUBLISHED_POSTS_CHECK_TIMEOUT);
    };

    const cancelApiTimer = () => {
        clearTimeout(apiTimer);
    };

    return (next: Dispatch) => (action: Action) => {
        if (action.type === CHECK_FOR_PUBLISHED_POSTS) {
            store.dispatch(closeDialog());
            store.dispatch(getBlogDetails());

            // set flag so the dialog is not opened when the api is called in another place
            scope.isPublishing = true;

            // publish if the api takes too long to respond
            startApiTimer();
        }

        if (action.type === GET_BLOG_DETAILS_SUCCESS && scope.isPublishing) {
            const blogData = action.payload;
            cancelApiTimer();

            // if the blog has at least one published post proceed with publish
            // otherwise show a warning that the blog will be empty
            if (
                blogData.posts &&
                blogData.posts.some(post => post.status === postStatus.PUBLISHED)
            ) {
                store.dispatch({ type: PUBLISH });
            } else {
                store.dispatch(openDialog(EmptyBlogDialogId));
            }

            scope.isPublishing = false;
        }

        if (action.type === GET_BLOG_DETAILS_FAILURE && scope.isPublishing) {
            // don't block the user from publishing when the blog api failed
            cancelApiTimer();
            store.dispatch({ type: PUBLISH });
            scope.isPublishing = false;
        }

        return next(action);
    };
};
