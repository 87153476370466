import { PartnerName } from '../../../../../../server/shared/partners/PartnerName.js';
import { getCookie } from '../../../../services/cookie';
import { AUTH_COOKIE_KEY, CLIENT_TYP_HEADER_NAME, WEBSHOP_ADMIN_CLIENT_TYPE } from '../../../App/epics/auth/constants';

const makeRequest = (requestUrl, data = {}): Promise<boolean> => {
    return new Promise((resolve) => {
        // extra try catch because jQuery doesn't handle 404 incase of jsonp
        // https://forum.jquery.com/topic/jquery-ajax-with-datatype-jsonp-will-not-use-error-callback-if-request-fails
        try {
            window.$.ajax({
                url: requestUrl,
                method: 'GET',
                contentType: 'text/plain',
                data: {
                    ...data,
                    [CLIENT_TYP_HEADER_NAME]: WEBSHOP_ADMIN_CLIENT_TYPE
                },
                dataType: 'jsonp',
                jsonpCallback: 'callbackFnc',
                crossDomain: true,
                xhrFields: {
                    withCredentials: true
                },
                // https://stackoverflow.com/a/19101142/2388706
                timeout: 5000,
                error: (xhr) => {
                    if (xhr && xhr.status === 200) {
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                },
                success: (data, status, xhr) => {
                    if (xhr && xhr.status === 200) {
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                }
            });
        } catch (err: any) {
            resolve(false);
        }
    });
};

const isLoggedIn = (checkLoginStatusURL) => {
    return makeRequest(checkLoginStatusURL + '?');
};

const makeLogin = (loginURL): Promise<boolean> => {
    return makeRequest(loginURL);
};

export const loginToWebshop = async (checkLoginStatusURL, loginURL, partnerName, domain) => {
    let result: boolean = false;
    try {
        if (partnerName && partnerName === PartnerName.ONECOM) {
            const loggedIn = await isLoggedIn(checkLoginStatusURL);
            if (!loggedIn) {
                result = await makeLogin(loginURL);
            } else {
                result = true;
            }
        } else {
            result = true;
            const data = { boneAuthCookie: getCookie(AUTH_COOKIE_KEY), domainName: domain };
            result = await makeRequest(loginURL, data);
        }
    } catch (err: any) {
        result = false;
    }
    return result;
};
