import BlogKind from "../../../oneweb/Blog/BlogComponent/kind";
import Icons from "../../../../view/Icons";
import { isBlogPage } from "../../../../../dal/model/utils/dataSiteItemUtils";
import { DataPageRef } from "../../../../../dal/model";

export default {
    id: "51", // same id from wbtgen/notifications/whatsNewList.ts
    componentKind: BlogKind,
    image: Icons.BLOG_INSERTER,
    disable: (pages: Array<DataPageRef>) => (pages.some(page => isBlogPage(page))),
    header: "msg: tooltip.blogComponent.header {Brand-new feature: Start blogging today!}",
    description: "msg: tooltip.blogComponent.description {Add a blog to your website and grow your business. Our blog is easy to use and offers customised, AI-generated posts to get started.}",
};
