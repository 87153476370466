import {
    AwsCognitoClientStorageRef as CognitoRef,
    AwsCognitoClientStorageRef
} from "../../../../../server/shared/awsServices/cognito/constants.js";
import { SsoTrialCookie } from "../../../../../server/shared/trial/SsoTrialCookie.js";
import { getCookie, unsetCookie } from "../../../../src/services/cookie";

const CookieStorageMap = {
    [SsoTrialCookie.ACCESS_TOKEN]: CognitoRef.ACCESS_TOKEN,
    [SsoTrialCookie.REFRESH_TOKEN]: CognitoRef.REFRESH_TOKEN,
    [SsoTrialCookie.ID_TOKEN]: CognitoRef.ID_TOKEN,
};

function trySsoTrialWbtgen(appClientId: string): null | undefined | string {
    const cookies = [SsoTrialCookie.USERNAME, ...Object.keys(CookieStorageMap)].reduce(
        (acc, c) => {
            const cval = getCookie(c, null);
            return cval ? Object.assign(acc, { [c]: cval }) : acc;
        },
        {},
    );

    const storageData = makeSsoTrialWbtgenStorageData(appClientId, cookies);

    if (!storageData) {
        return null;
    }

    Object.keys(storageData).forEach(key => {
        window.localStorage.setItem(key, storageData[key]);
    });

    Object.keys(cookies).forEach(c => {
        unsetCookie(c, {}, true);
    });

    return cookies[SsoTrialCookie.USERNAME];
}

function makeSsoTrialWbtgenStorageData(appClientId: string, cookies: Record<string, any>): null | undefined | Object {
    const username = cookies[SsoTrialCookie.USERNAME];

    if (!username) {
        return null;
    }

    const res = Object
        .keys(CookieStorageMap)
        .reduce(
            (acc, cookieName) => (
                cookies[cookieName]
                    ? Object.assign(acc, {
                        [key(appClientId, username, CookieStorageMap[cookieName])]: cookies[cookieName],
                    })
                    : acc
            ),
            { [key(appClientId, CognitoRef.LAST_USER_AUTH)]: username },
        );

    if (Object.keys(res).length !== (Object.keys(CookieStorageMap).length + 1)) {
        return null;
    }

    return res;
}

function key(appClientId: string, ...parts: string[]): string {
    return [
        AwsCognitoClientStorageRef.IDENTITY_SERVICE_PROVIDER,
        appClientId,
        ...parts,
    ].join('.');
}

export {
    trySsoTrialWbtgen,
    makeSsoTrialWbtgenStorageData,
    key as makeSsoTrialWbtgenStorageKey,
};
