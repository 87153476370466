/* eslint-disable object-shorthand */
import { $ } from 'tinymce';
import {
    DEFAULT_GLOBAL_CLASS, getTextGlobalStyle, isValidContentBlock
} from '../../../App/epics/tinyMceEpic/editorUtils/utils/nodeUtils/utils';
import { MOBILE_MAX_FONT_SIZE, MOBILE_MAX_HORIZONTAL_SPACING } from '../constants';
import getLinkAbsoluteUrl from '../../../../view/common/dialogs/LinkChooserDialog/utils/getLinkAbsoluteUrl';
import { absoluteUrlToInputLink } from '../../../../view/common/dialogs/LinkChooserDialog/utils/absoluteUrlToInputLink';
import { DataSite } from '../../../../../dal/model/index';
import { JS_VOID_VALUE } from '../../../../constants';
import {
    toArray,
    hasNonEmptyTextChildNodesAtAnyLevel,
    getNonEmptyChildren,
    isFirstChildNodeNonEmptyTextNode
} from "./nodeUtils";

import type { Stylesheet } from '../../../Workspace/epics/stylesheets/flowTypes';

const replaceHeaders = function (node: Element) {
    $(node).children('p').each(function (i: number, paraElt: HTMLParagraphElement) {
        /**
         * We need to convert all non-empty P tags to correct heading irrespective of children.
         * Need to identify regressions for this in the future.
         */
        if (hasNonEmptyTextChildNodesAtAnyLevel(paraElt)) {
            const paraGlobalClass = getTextGlobalStyle(paraElt);
            let childGlobalClass = paraGlobalClass;
            // Scenario: <p class="textheading1">KJEMISKE PEELINGER <span class="textnormal ">Kosmetisk og dermatoglogisk hudpleie</span></p>
            // Result in preview: <h1 class="textheading1">KJEMISKE PEELINGER <span class="textnormal ">Kosmetisk og dermatoglogisk hudpleie</span></h1>
            // Result Explained: Use parent class for the full text preview rendering.
            if (!isFirstChildNodeNonEmptyTextNode(paraElt)) {
                const nonEmptyChildren = getNonEmptyChildren(paraElt);
                const childElt: Element | null | undefined = nonEmptyChildren[0];
                childGlobalClass = (childElt && getTextGlobalStyle(childElt)) || childGlobalClass;
            }
            if (childGlobalClass === DEFAULT_GLOBAL_CLASS || !!paraGlobalClass !== (childGlobalClass === paraGlobalClass)) {
                return;
            }

            // @ts-ignore
            const header = document.createElement(`h${childGlobalClass.match(/\d$/)[0]}`);
            if (paraElt.className) {
                header.className = paraElt.className;
            }

            if (paraElt.style.length) {
                header.style.cssText = paraElt.style.cssText;
            }
            toArray(paraElt.childNodes).forEach(function (childEle) {
                header.appendChild(childEle);
            });
            node.insertBefore(header, paraElt);
            node.removeChild(paraElt);
        }
    });
};

const
    TEXT_STYLE_TYPE = 'web.data.styles.StyleText',
    LINK_STYLE_TYPE = 'web.data.styles.StyleLink',
    styleTypeToGlobalClass = {
        [TEXT_STYLE_TYPE]: function (style) {
            return `text${style.ref.replace(/\./g, '')}`;
        },
        [LINK_STYLE_TYPE]: function (style) {
            return style.ref.replace(/\./g, '');
        }
    },
    getTextStyles = function (globalStyles) {
        return globalStyles.reduce(function (styles, globalStyle) {
            return (
                styleTypeToGlobalClass[globalStyle.type] ? {
                    ...styles,
                    [styleTypeToGlobalClass[globalStyle.type](globalStyle)]: globalStyle
                } : styles
            );
        }, {});
    };

const getClassToModerateFontSizeForMobileView = function (fontSize: number, globalStyle: string): string {
    if (fontSize > MOBILE_MAX_FONT_SIZE[globalStyle]) {
        return 'mobile-oversized';
    } else if (fontSize < 12) {
        return 'mobile-undersized-lower';
    } else if (fontSize < 16) {
        return 'mobile-undersized-upper';
    } else {
        return '';
    }
};

const fixExtremeSpacingsOnMobileView = function (node: HTMLElement) {
    if (parseFloat(node.style.paddingLeft) > MOBILE_MAX_HORIZONTAL_SPACING) {
        $(node).addClass('mobile-overpadded-left');
    }
    if (parseFloat(node.style.paddingRight) > MOBILE_MAX_HORIZONTAL_SPACING) {
        $(node).addClass('mobile-overpadded-right');
    }
};

const fixExtremeFontSizesOnMobileView = function (node: HTMLElement, styles, parentGlobalStyle: string = '') {
    let globalStyle = getTextGlobalStyle(node);
    // @ts-ignore
    let fontSize = parseFloat(node.style.fontSize);

    if (fontSize) {
        // since fontSize is being overridden, we MUST moderate it against some style
        globalStyle = globalStyle || parentGlobalStyle || DEFAULT_GLOBAL_CLASS;
    } else if (globalStyle && styles[globalStyle]) {
        // rendered fontSize will be the same as defined in the globalStyle, moderate according to that
        fontSize = styles[globalStyle].size;
    } else if (isValidContentBlock(node)) {
        // it is possible to end up with blocks without any global styles with tinymce
        // in such cases, the wrapping div's style (DEFAULT_GLOBAL_CLASS) is inherited
        globalStyle = DEFAULT_GLOBAL_CLASS;
        fontSize = styles[DEFAULT_GLOBAL_CLASS].size;
    }

    if (fontSize && globalStyle) {
        $(node).addClass(getClassToModerateFontSizeForMobileView(fontSize, globalStyle));
    }

    return globalStyle;
};

/*
  * NOTE: Be careful while iterating the nodes. Just calling recursion for each child is expensive.
  * It takes around 8 seconds for a text with length 150K.
*/
const fixExtremeAttributesOnMobileView = function (node: HTMLElement, styles = {}, parentGlobalStyle: string = '') {
    fixExtremeSpacingsOnMobileView(node);

    const children = node.children;
    const childLen = children.length;
    const arr = [];
    for (let i = 0; i < childLen; i++) {
        // @ts-ignore
        arr.push(children[i]);
    }

    for (const childElt of arr) {
        // @ts-ignore
        let globalStyle = fixExtremeFontSizesOnMobileView(childElt, styles, parentGlobalStyle);
        // @ts-ignore
        fixExtremeAttributesOnMobileView(childElt, styles, globalStyle);
    }
};

const fixLinks = function (node: HTMLElement, site: DataSite, previewBackupTime?: number) {
    toArray(node.querySelectorAll('a')).forEach(function (elt: HTMLAnchorElement) {
        const link = absoluteUrlToInputLink(elt.getAttribute('href')),
            linkAbsoluteUrl = getLinkAbsoluteUrl(link, site, previewBackupTime);
        if (linkAbsoluteUrl) {
            if (link.type === 'sectionLink' && link.value && link.value.sectionId) {
                elt.setAttribute('sectionid', link.value.sectionId);
            }
            if (linkAbsoluteUrl === JS_VOID_VALUE) {
                elt.removeAttribute('target');
            }
            elt.setAttribute('href', linkAbsoluteUrl);
        } else {
            elt.removeAttribute('href');
        }
    });
};

export const getRenderContent = function (
    content: string, globalStyles: Stylesheet[], site: DataSite, previewBackupTime?: number
): string {
    const textStyles = getTextStyles(globalStyles);
    const psuedoDom: HTMLDivElement = document.createElement('div');
    psuedoDom.innerHTML = content;

    replaceHeaders(psuedoDom);
    fixExtremeAttributesOnMobileView(psuedoDom, textStyles);
    fixLinks(psuedoDom, site, previewBackupTime);

    return psuedoDom.innerHTML;
};
