import React from "react";
import VerticalSpacer from "../../../../view/common/VerticalSpacer";
import { EPSeparator } from "../../../Panel/view/EPSeparator";
import Msg from "../../../../view/intl/Msg";
import { UpgradeUserSubscriptionButton } from "../../../ComponentTierManager/view/UpgradeUserSubscriptionButton";
import { getEcommercePackageNameFromMetadata } from "../../../ComponentTierManager/utils";
import { WebshopKind } from "../kind";
import type { SubscriptionData } from "../../../../redux/modules/flowTypes";
import AppConfig from "../../../../utils/AppConfig";
import { getAppConfig } from "../../../App/epics/appConfig/appConfig.js";
import styles from "./styles.css";

type Props = {
    dispatch: Dispatch;
    intl: Intl;
    checkFreeUpgrade: Record<string, any>;
    subscriptionData: SubscriptionData;
    isSiatAllowedFromShopApi: boolean;
};

export const UpgradeSubscriptionNotification = ({
    dispatch,
    intl,
    isSiatAllowedFromShopApi,
    checkFreeUpgrade,
    subscriptionData
}: Props) => {
    const appConfig = AppConfig(getAppConfig());
    const isShopInAllTiersEnabled = appConfig.getOptional("oneWeb.webshop.features.enableShopInAllTier") && isSiatAllowedFromShopApi;
    const subscriptionMetadata = subscriptionData && subscriptionData.metadata;
    const eCommercePackageNameFromMetadata = getEcommercePackageNameFromMetadata(subscriptionMetadata);
    return (
        <React.Fragment>
            <VerticalSpacer y={20} />
            <div className={styles.setupProductNotification}>
                <div className={styles.messageTitle}>
                    {isShopInAllTiersEnabled ? (
                        <Msg k="webshopInserter.setupProduct.UpgradeToRemoveLimitOnProductsTitle">Upgrade to create more products</Msg>
                    ) : (
                        <Msg k="common.tip.upgradeToPublish.label">Upgrade to publish</Msg>
                    )}
                </div>
                <div className={styles.messageBody}>
                    {isShopInAllTiersEnabled ? (
                        <Msg
                            k="webshopInserter.setupProduct.UpgradeToRemoveLimitOnProducts"
                            params={{
                                maxProducts: 3,
                                packageName:
                                    eCommercePackageNameFromMetadata ||
                                    intl.msgJoint("msg: common.business_ecommerce {Business + Ecommerce}")
                            }}
                        >{`
                        Your current plan allows you to create {maxProducts} products.
                        Get our {packageName} plan to create unlimited products and access great additional features.
                    `}</Msg>
                    ) : (
                        <Msg
                            k="webshopInserter.setupProduct.UpgradeMessage"
                            params={{
                                packageName:
                                    eCommercePackageNameFromMetadata ||
                                    intl.msgJoint("msg: common.business_ecommerce {Business + Ecommerce}")
                            }}
                        >{`
                        This feature is part of the "{packageName}" plan. Try
                        it out now and then upgrade your plan to publish your site.
                    `}</Msg>
                    )}
                </div>
                <div>
                    <UpgradeUserSubscriptionButton
                        dispatch={dispatch}
                        subscriptionData={subscriptionData}
                        isFreeUpgrade={checkFreeUpgrade.isFreeUpgrade}
                        componentKind={WebshopKind}
                        upgradeBtnContainerClass={styles.upgradeBtnContainerClass}
                        source="componentToolbar:upgradeBtn"
                    />
                </div>
            </div>
            <VerticalSpacer y={20} />
            <EPSeparator />
        </React.Fragment>
    );
};
