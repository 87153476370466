import React from 'react';
import AutosizeInput from 'react-input-autosize';
import cx from 'classnames';
import Label from '../../../view/common/Label';
import Icons from '../../../view/Icons/index';
import type { SettingsProps, SizeFieldProps, SizeFieldState } from "../flowTypes";
import * as styles from './PropertiesPanel.css';
import {
    PROP_PANEL_CHANGE_COMPONENT_WIDTH,
    PROP_PANEL_CHANGE_COMPONENT_HEIGHT
} from '../../Workspace/actionTypes';
import { calculateValues } from "./settingsUtility";
import isStretchComponentKind from "../../oneweb/isStretchComponentKind";
import ContextMenuIcon from './ContextMenuIcon';
import {
    SHOW_TOOLTIP_FOR_COMPONENT_MINVALUES,
    CLOSE_TOOLTIP,
    SHOW_TOOLTIP_FOR_SECTION_MINVALUES
} from "../../Tooltip/actionTypes";
import { ComponentResize, SectionResize } from "../../Tooltip/ids";
import * as mouseUtils from '../../../utils/mouse';
import focusHandler from '../../../utils/inputFocusSelect';
import { INPUT_BLUR, INPUT_FOCUSED } from "../../App/actionTypes";
import { GalleryKind } from "../../oneweb/Gallery/kind";
import { isDynamicHeightKind, isSectionKind } from "../../oneweb/componentKinds";
import { MIN_SECTION_HEIGHT } from "../../oneweb/Section/constants";
import { MAX_DIMENSIONS } from "../../Workspace/epics/componentsEval/userInteractionMutations/constants";
import { isModernLayoutSection } from '../../ModernLayouts/preview_utils';

const isHeightGotLimited = (inputValue: string, actualValue: number) => {
    return actualValue === MAX_DIMENSIONS.height && +inputValue !== actualValue;
};
const timeGap = 900;
class Size extends React.Component<SizeFieldProps, SizeFieldState> {
    inp: any;
    timer: null | undefined | NodeJS.Timeout;
    messageTimer: null | undefined | NodeJS.Timeout;
    constructor(props) {
        super(props);
        this.state = {
            w: Math.round(props.width) + '',
            h: Math.round(props.height) + '',
            focused: false,
            border: ''
        };
        this.inp = null;
        this.timer = null;
        this.messageTimer = null;
    }
    cancelScheduledAction() {
        if (this.timer) {
            clearTimeout(this.timer);
            this.timer = null;
        }
    }

    cancelMessageTimer() {
        if (this.messageTimer) {
            clearTimeout(this.messageTimer);
            this.messageTimer = null;
        }
    }

    dispatchScheduleAction(actionType, value, timeout = timeGap) {
        const { selectedComponentId, dispatch } = this.props,
            action = { type: actionType, payload: { value, selectedComponentId } };
        if (timeout === 0) {
            dispatch(action);
            return;
        }
        // SCHEDULE_ACTION is not working in this case - WBTGEN-6673
        this.timer = setTimeout(() => {
            dispatch(action);
        }, timeout);
    }
    componentWillUnmount() {
        if (this.timer) {
            this.onBlur();
        }
        if (this.state.focused) {
            this.props.dispatch({ type: INPUT_BLUR });
        }
        this.cancelScheduledAction();
        this.cancelMessageTimer();
    }
    informComponentBBoxSize(displayValue, boundingClientRect) {
        const
            borderStyle = '1px solid red',
            { kind, editWidth, width, height, dispatch } = this.props;
        let value = 0;
        if (editWidth) {
            this.setState({ w: displayValue, border: borderStyle });
            value = width;
        } else {
            this.setState({ h: displayValue, border: borderStyle });
            value = height;
        }
        let action: Action = { type: SHOW_TOOLTIP_FOR_COMPONENT_MINVALUES,
            payload: {
                position: {
                    x: boundingClientRect.left + 20,
                    y: boundingClientRect.top + 7
                },
                id: ComponentResize,
                msg: { text: 'msg: component.webshop.resize.info {The minimum value is {minValue}}',
                    params: { minValue: Math.round(value) } },
                textStyle: { textAlign: 'center', color: '#ffffff' },
                showIcon: false
            } };
        if (isSectionKind(kind)) {
            let validationMsg = 'msg: component.section.resize.info {To reduce the height further, move up or scale down the lower component of this section.}'; // eslint-disable-line
            if (parseInt(displayValue, 10) < MIN_SECTION_HEIGHT) {
                validationMsg = 'msg: component.section.resize.minHeight {Minimum height of section is 50 px.}'; // eslint-disable-line
            }
            action = { type: SHOW_TOOLTIP_FOR_SECTION_MINVALUES,
                payload: {
                    position: {
                        x: boundingClientRect.left + (boundingClientRect.width / 2),
                        y: boundingClientRect.bottom - 7
                    },
                    id: SectionResize,
                    msg: { text: validationMsg }
                } };
        }
        dispatch(action);
    }

    updateWidth(actualValue) {
        const newWidth = Math.round(actualValue) + '',
            { width } = this.props;
        this.setState({ w: newWidth });
        this.cancelScheduledAction();
        this.dispatchScheduleAction(
            PROP_PANEL_CHANGE_COMPONENT_WIDTH,
            (Math.round(parseInt(newWidth, 10)) || width)
        );
    }

    updateHeight(actualValue) {
        const newHeight = Math.round(actualValue) + '',
            { height } = this.props;
        this.setState({ h: newHeight });
        this.cancelScheduledAction();
        this.dispatchScheduleAction(
            PROP_PANEL_CHANGE_COMPONENT_HEIGHT,
            (Math.round(parseInt(newHeight, 10)) || height)
        );
    }

    handleMessageTimer(e, actualValue, displayValue) {
        const bcr = mouseUtils.getBoundingClientRect(e);

        // Need a delay to get new prop of the component with new width.
        // As per WBTGEN-2001 delay is added to action on width/height change.
        // Info message should also have delay accordingly.
        // The prop will have minimum width if values entered are lesser than minimum
        // for the component
        this.cancelMessageTimer();
        this.messageTimer = setTimeout(() => {
            if (this.props.editWidth) {
                if (actualValue < this.props.width) {
                    this.informComponentBBoxSize(displayValue, bcr);
                } else {
                    this.setState({ w: displayValue, border: '' });
                    this.props.dispatch({ type: CLOSE_TOOLTIP });
                }
            } else if (actualValue < this.props.height) {
                this.informComponentBBoxSize(displayValue, bcr);
            } else {
                this.setState({ h: displayValue, border: '' });
                this.props.dispatch({ type: CLOSE_TOOLTIP });
            }
        }, timeGap + 1);
    }

    onWidthChange(e) {
        const { actualValue, displayValue } = calculateValues(e.target.value, true);
        if (actualValue) {
            this.updateWidth(actualValue);
            this.handleMessageTimer(e, actualValue, displayValue);
        }
    }

    onHeightChange(e) {
        const inputValue = e.target.value;
        const { actualValue, displayValue } = calculateValues(inputValue);
        if (actualValue) {
            if (isDynamicHeightKind(this.props.kind) && isHeightGotLimited(inputValue, actualValue)) {
                this.cancelMessageTimer();
                this.updateHeight(+inputValue);
            } else {
                this.updateHeight(actualValue);
                this.handleMessageTimer(e, actualValue, displayValue);
            }
        }
    }

    onBlur() {
        this.props.dispatch({ type: INPUT_BLUR });
        const { editWidth, width, height } = this.props,
            { w, h } = this.state;
        this.setState({ focused: false });
        if (editWidth) {
            const newWidth = (Math.round(parseInt(w, 10)) || width);
            if (newWidth !== width) {
                this.setState({
                    w: newWidth + '', border: ''
                });
                this.dispatchScheduleAction(
                    PROP_PANEL_CHANGE_COMPONENT_WIDTH,
                    newWidth,
                    0
                );
            }
        } else {
            const newHeight = (Math.round(parseInt(h, 10)) || height);
            if (newHeight !== height) {
                this.setState({
                    h: newHeight + '', border: ''
                });
                this.dispatchScheduleAction(
                    PROP_PANEL_CHANGE_COMPONENT_HEIGHT,
                    newHeight,
                    0
                );
            }
        }
        this.cancelScheduledAction();
        this.props.dispatch({ type: CLOSE_TOOLTIP });
    }

    onFocus(e: any) {
        this.props.dispatch({ type: INPUT_FOCUSED });
        const { width, height } = this.props;
        this.setState({ w: width + '', h: height + '', focused: true });
        focusHandler(e);
    }

    onChangeByKey(e: any): void {
        const { editWidth, kind } = this.props,
            { w, h } = this.state;
        let values: Record<string, any> = {};
        switch (e.key) {
            case "ArrowUp":
                e.preventDefault();
                if (editWidth) {
                    values = calculateValues(Math.round(parseInt(w, 10)) + (e.shiftKey ? 10 : 1) + '', true);
                    this.updateWidth(values.actualValue);
                } else {
                    const inputValue = Math.round(parseInt(h, 10)) + (e.shiftKey ? 10 : 1) + '';
                    values = calculateValues(inputValue);
                    if (isDynamicHeightKind(kind) && isHeightGotLimited(inputValue, values.actualValue)) {
                        this.updateHeight(+inputValue);
                    } else {
                        this.updateHeight(values.actualValue);
                    }
                }
                break;
            case "ArrowDown":
                e.preventDefault();
                if (editWidth) {
                    values = calculateValues((Math.round(parseInt(w, 10)) - (e.shiftKey ? 10 : 1)) + '', true);
                    this.updateWidth(values.actualValue);
                } else {
                    values = calculateValues(Math.round(parseInt(h, 10)) - (e.shiftKey ? 10 : 1) + '');
                    this.updateHeight(values.actualValue);
                }
                this.handleMessageTimer(e, values.actualValue, values.displayValue);
                break;
            case "Enter":
                if (this.inp) {
                    this.inp.blur();
                }
                break;
            case ".":
                e.preventDefault();
                break;
            default:
        }
    }

    render() {
        const { editWidth, isStretchCmp, width, height, disabled } = this.props;
        const { w, h, focused, border } = this.state;

        const widthOfStretchCmp = editWidth && isStretchCmp;
        const inputStyle = border ? { border } : { border: '' };

        let computedWidth;
        if (w === '') {
            computedWidth = w;
        } else {
            computedWidth = Math.round(focused ? (+w) : width);
        }

        if (isStretchCmp) {
            computedWidth = '100%';
        }

        let computedHeight;
        if (h === '') {
            computedHeight = h;
        } else {
            computedHeight = Math.round(focused ? (+h) : height);
        }

        return (
            <AutosizeInput
                inputStyle={inputStyle}
                ref={(inp) => { this.inp = inp; }}
                type="text"
                extraWidth={2}
                inputClassName={cx(styles.sizeField, { [styles.disabled]: widthOfStretchCmp || disabled })}
                value={editWidth ? computedWidth : computedHeight}
                onChange={editWidth ? this.onWidthChange.bind(this) : this.onHeightChange.bind(this)}
                onKeyDown={this.onChangeByKey.bind(this)}
                onBlur={this.onBlur.bind(this)}
                onFocus={this.onFocus.bind(this)}
                disabled={widthOfStretchCmp || disabled}
            />
        );
    }
}

const
    settingsIconsColor = '#cccccc',
    CrossIconSettings = { height: '8px', width: '8px', fill: settingsIconsColor, marginTop: '2px' };

export default ({ activePageIndex, selectedComponent, dispatch }: SettingsProps) => {
    const
        isModernSection = isModernLayoutSection(selectedComponent),
        { id, kind, width, height, stretch = false } = selectedComponent,
        isStretchCmp = isStretchComponentKind(kind, stretch),
        isStretchedGallery = isStretchCmp && kind === GalleryKind,
        isEmptyGallery = kind === GalleryKind && selectedComponent.images && selectedComponent.images.length === 0;

    return (
        <div key={activePageIndex} className={styles.settingsContainer}>
            <div className={styles.settingsSubContainer}>
                <div className={styles.sizesContainer}>
                    <Size
                        {...{ dispatch, editWidth: true, isStretchCmp, width, height, kind, selectedComponentId: id, disabled: isEmptyGallery || isStretchedGallery }} // eslint-disable-line
                    />
                    <Icons.CROSS style={CrossIconSettings} />
                    <Size
                        {...{ dispatch, editWidth: false, width, height, kind, selectedComponentId: id, disabled: isEmptyGallery || isStretchedGallery}} // eslint-disable-line
                    />
                    <Label isValue className={styles.settingsText}>px</Label>
                </div>
                { !isModernSection && <ContextMenuIcon dispatch={dispatch} /> }
            </div>
        </div>
    );
};
