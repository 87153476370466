import type { SubscriptionData } from '../../../../wbtgen/src/redux/modules/flowTypes';
import { checkPublishInProgress, checkPublishDone } from "../../../../wbtgen/src/components/TopBar/epics/checkPublishStatus/utils";

import type { AppState, SaveStatusChanges, SiteDataType, OwnerInfoType } from './types';
import { SaveStatusEnum } from './types';
import type { ServerPublishType } from "../components/Publish/types";
import { AnalyticsSettingsType } from '../components/ThirdPartyConnections/types';

export const
    appState = (state: AppState): AppState => state,
    appNameSelector = (state: AppState): string => state.appName,
    selectDomain = (state: AppState): string => state.login?.domain,
    selectEmail = (state: AppState) => state.login?.email,
    selectAppStatusType = (state: AppState) => state.appStatus?.type,
    selectAppStatusData = (state: AppState) => state.appStatus?.data,
    selectSiteSettings = (state: AppState) => state.siteSettings,
    selectSiteSettingsEnableSeo = (state: AppState) => selectSiteSettings(state)?.seoData?.enableSeo,
    selectPrivacyPolicyContent = (state: AppState) => selectSiteSettings(state)?.privacyPolicy?.content,
    selectPrivacyPolicyEditorContent = (state: AppState) => state.privacyPolicy.editorContent,
    selectAnalyticsAuthToken = (state: AppState) => state.analytics?.authToken,
    selectAnalyticsStatsSummaryState = (state: AppState) => state.analytics?.statsSummary,
    isAnalyticsStatsAvailable = (state: AppState) => {
        const stats = selectAnalyticsStatsSummaryState(state);
        return (
            !!stats &&
            !stats.error &&
            // @ts-ignore this should be fixed
            Object.values(stats.value || {}).some((value) => value > 0)
        );
    },
    selectSiteData = (state: AppState) => state.siteData,
    selectPublish = (state: AppState) => state.publish as ServerPublishType,
    selectPublishStatus = (state: AppState): string => selectPublish(state)?.publishStatus,
    selectPublishCurrentProgress = (state: AppState) => selectPublish(state)?.publishProgress?.current || 0,
    selectLastPublished = (state: AppState) => selectPublish(state)?.lastPublished || 0,
    isSitePublished = (state: AppState) => Boolean(selectLastPublished(state)),
    shouldShowPublishProgress = (state: AppState) => Boolean(selectPublish(state)?.showPublishProgress),
    selectCurrentLanguage = (state: AppState) => state.topBar.languages.current,
    selectTopBarLanguages = (state: AppState) => state.topBar.languages,
    isTopBarBackdropOpenSelector = (state: AppState) => state.topBar.isTopBarBackdropOpen,
    selectSubscriptionData = (state: AppState): SubscriptionData => state.subscriptionData,
    subscriptionTypeSelector = (state: AppState) => selectSubscriptionData(state)?.subscriptionType,
    availableUpgradesSelector = (state: AppState) => selectSubscriptionData(state)?.availableUpgrades,
    selectOwnerInfoData = (state: AppState): OwnerInfoType => state.ownerInfo,
    ownerInfoAdminIdSelector = (state: AppState) => selectOwnerInfoData(state)?.userData?.adminId,
    ownerInfoLoadingSelector = (state: AppState) => selectOwnerInfoData(state)?.loading,
    ownerInfoFailedSelector = (state: AppState) => selectOwnerInfoData(state)?.failed,
    ownerInfoAdminIdFailedSelector = (state: AppState) => selectOwnerInfoData(state)?.adminIdFetchFailed,
    ownerInfoEmailSelector = (state: AppState) => selectOwnerInfoData(state)?.userData?.email,
    selectWhatsNewCount = (state: AppState) => state.whatsNew.count,
    selectWhatsList = (state: AppState) => state.whatsNew.list,
    selectWhatsLatestTimestamp = (state: AppState) => state.whatsNew.latestTimestamp,
    trackingDataSelector = (state: AppState) => selectSiteSettings(state)?.trackingData,
    googleAnalyticsTrackingDataSelector = (state: AppState) => trackingDataSelector(state)?.gaMeasurementId,
    facebookPixelTrackingDataSelector = (state: AppState) => trackingDataSelector(state)?.pixelId,
    googleAdsCampaignEnabledSelector = (state: AppState) => trackingDataSelector(state)?.enableGoogleAds,
    siteWitEnabledSelector = (state: AppState) => state.googleAds?.isSiteWitEnabled,
    trackingScriptPublishedSelector = (state: AppState) => selectPublish(state)?.trackingScriptInPublishedPage,
    saveStatusSelector = (state: AppState): SaveStatusEnum => state.saveStatus.status,
    saveStatusChangesSelector = (state: AppState): SaveStatusChanges => state.saveStatus.changes,
    saveStatusSiteSettingsChangedSelector = (state: AppState): boolean => saveStatusChangesSelector(state).siteSettings,
    saveStatusSiteDataChangedSelector = (state: AppState): boolean => saveStatusChangesSelector(state).siteData,
    saveStatusThirdPartyConnectionsChangedSelector = (state: AppState): boolean => saveStatusChangesSelector(state).thirdPartyConnections,
    selectImageChooserData = (state: AppState) => state.imageChooser,
    socialShareDataSelector = (state: AppState) => selectSiteSettings(state)?.socialShare,
    socialShareAssetSelector = (state: AppState) => socialShareDataSelector(state)?.socialShareAsset,
    publishConfigDataSelector = (state: AppState) => selectSiteSettings(state)?.publishConfig,
    selectPublishWithSSL = (state: AppState) => publishConfigDataSelector(state)?.publishWithSSL,
    chatWidgetDataSelector = (state: AppState) => selectSiteSettings(state)?.chatWidgetData || {},
    chatWidgetLanguageSelector = (state: AppState) => selectSiteSettings(state)?.chatWidgetData?.chatWidgetLanguage,
    selectMarketgoo = (state: AppState) => state.marketgoo,
    selectMarketgooIsLoading = (state: AppState) => selectMarketgoo(state)?.isLoading,
    selectMarketgooIsFailed = (state: AppState) => selectMarketgoo(state)?.isFailed,
    seoDetectedIssuesSelector = (state: AppState) => selectMarketgoo(state)?.detectedIssues,
    seoDetectedIssuesTasksSelector = (state: AppState) => seoDetectedIssuesSelector(state)?.tasks || [],
    seoLastScanSelector = (state: AppState) => seoDetectedIssuesSelector(state)?.lastScan || 0,
    seoScoreSelector = (state: AppState) => {
        const
            tasks = seoDetectedIssuesTasksSelector(state),
            total = tasks.length,
            passed = tasks.filter(task => task.passed).length;

        return { total, passed };
    },
    marketgooTaskBannerOpenSelector = (state: AppState) => selectMarketgoo(state)?.isTaskBannerOpen,
    marketgooTaskBannerIdSelector = (state: AppState) => selectMarketgoo(state)?.taskBannerId,
    selectThirdPartyConnections = (state: AppState) => state.thirdPartyConnections,
    selectMarketgooFromThirdPartyConnections = (state: AppState) => selectThirdPartyConnections(state)?.marketgoo,
    selectMarketgooAccountId = (state: AppState) => selectMarketgooFromThirdPartyConnections(state)?.accountId,
    selectMarketgooDetectedIssues = (state: AppState) => selectMarketgoo(state)?.detectedIssues,
    selectAnalyticsSettings = (state: AppState): AnalyticsSettingsType => selectThirdPartyConnections(state)?.analytics as AnalyticsSettingsType,
    selectAnalyticsEnabledAt = (state: AppState): number => selectAnalyticsSettings(state)?.enabledAt || 0,
    selectConsentBannerData = (state: AppState) => selectThirdPartyConnections(state)?.consentBanner,
    preferencesSelector = (state: AppState) => state.preferences,
    showWelcomeToDashboardDialogSelector = (state: AppState) => preferencesSelector(state)?.showWelcomeToDashboardDialog,
    hideBookingsTrialRemovalTooltipSelector = (state: AppState) => preferencesSelector(state)?.hideBookingsTrialRemovalTooltip,
    hideContactsTooltipSelector = (state: AppState) => preferencesSelector(state)?.hideContactsTooltip,
    contactsIntroSeenSelector = (state: AppState) => preferencesSelector(state)?.dashboardContactsIntroSeen,
    bookingsTrialIntroDialogSeenSelector = (state: AppState) => preferencesSelector(state)?.hideBookingsTrialDialog,
    bookingsIntroDialogSeenSelector = (state: AppState) => preferencesSelector(state)?.dashboardBookingsIntroSeen,
    hideBookingsTooltipSelector = (state: AppState) => preferencesSelector(state)?.hideBookingsTooltip,
    marketgooPreferencesSelector = (state: AppState) => preferencesSelector(state)?.marketgoo,
    isSeoOnboardingDoneSelector = (state: AppState) => preferencesSelector(state)?.isSeoOnboardingDone,
    whatsNewPopupSeenIdSelector = (state: AppState) => preferencesSelector(state)?.whatsNewPopupSeenId,
    isMobileSideBarOpenSelector = (state: AppState) => state.sideBar?.isMobileSideBarOpen,
    isSideBarBackdropOpenSelector = (state: AppState) => state.sideBar?.isSideBarBackdropOpen,
    selectedSideBarItemIdSelector = (state: AppState) => state.sideBar?.selectedSideBarItemId,
    selectedSideBarItemDataSelector = (state: AppState) => state.sideBar?.selectedSideBarItemData,
    marketgooLastRescanSelector = (state: AppState) => preferencesSelector(state)?.marketgooLastRescanTimestamp,
    generalDataSelector = (state: AppState) => selectSiteSettings(state)?.generalData,
    selectWebsiteTitle = (state: AppState) => generalDataSelector(state)?.websiteTitle || '',
    selectContactEmail = (state: AppState) => generalDataSelector(state)?.contactEmail || '',
    selectLogoAltText = (state: AppState) => generalDataSelector(state)?.logoAltText,
    selectLogoAsset = (state: AppState) => generalDataSelector(state)?.logoAsset,
    selectFaviconAsset = (state: AppState) => generalDataSelector(state)?.favicon,
    generalInfoSelector = (state: AppState) => state.generalInfo,
    selectAddress = (state: AppState) => generalDataSelector(state)?.address,
    selectAddressName = (state: AppState) => generalDataSelector(state)?.addressName,
    selectStreetAddress = (state: AppState) => generalDataSelector(state)?.addressStreetAddress,
    selectAddressCountryCode = (state: AppState) => generalDataSelector(state)?.addressCountryCode || '',
    selectAddressZip = (state: AppState) => generalDataSelector(state)?.addressZip,
    selectAddressCity = (state: AppState) => generalDataSelector(state)?.addressCity,
    selectAddressArea = (state: AppState) => generalDataSelector(state)?.addressArea,
    selectOpeningHours = (state: AppState) => generalDataSelector(state)?.openingHours || [],
    selectOnlineShop = (state: AppState) => state.onlineShop,
    selectOnlineShopStatus = (state: AppState) => selectOnlineShop(state).onlineShopStatus,
    isShopDashboardOpenSelector = (state: AppState) => selectOnlineShop(state).openDashboard,
    selectShopAppRoute = (state: AppState) => selectOnlineShop(state).shopAppRoute,
    selectIsSIATCustomer = (state: AppState) => selectOnlineShop(state).isSIATCustomer,
    selectBookingsState = (state: AppState) => state.bookings,
    isBookingsDashboardOpenSelector = (state: AppState) => selectBookingsState(state)?.isDashboardOpen,
    selectBookingsAppRoute = (state: AppState) => selectBookingsState(state)?.appRoute,
    selectBookingsServicesCount = (state: AppState) => selectBookingsState(state)?.servicesCount;

export const
    isAnalyticsEnabled = (analyticsSettings: AnalyticsSettingsType): boolean => {
        return Boolean(analyticsSettings?.enabled);
    },
    isPublishedAfterAnalyticsEnabled = (state: AppState): boolean => {
        if (!isAnalyticsEnabled(selectAnalyticsSettings(state))) {
            return false;
        }

        const
            analyticsEnabledAt = selectAnalyticsEnabledAt(state),
            lastPublished = selectLastPublished(state);

        return lastPublished > analyticsEnabledAt;
    },

    canSave = (state: AppState): boolean => {
        return saveStatusSelector(state) === SaveStatusEnum.CAN_SAVE;
    },
    isSaveInProgress = (state: AppState): boolean => {
        return saveStatusSelector(state) === SaveStatusEnum.IN_PROGRESS;
    },
    publishablePagesCount = (siteData: SiteDataType) => {
        return siteData.getPageIdsNotMarkedAsDontPublish().length;
    },
    hasNoPublishablePages = (appState: AppState) => (
        publishablePagesCount(selectSiteData(appState)) === 0
    ),
    isPublishInProgress = (appState: AppState): boolean => {
        const publishStatus = selectPublishStatus(appState);
        return Boolean(publishStatus && checkPublishInProgress(publishStatus));
    },
    isPublishDone = (appState: AppState): boolean => {
        const publishStatus = selectPublishStatus(appState);
        return Boolean(publishStatus && checkPublishDone(publishStatus));
    },
    canPublishSelector = (appState: AppState): boolean => {
        return !(
            hasNoPublishablePages(appState) ||
            isPublishDone(appState) ||
            isPublishInProgress(appState)
        );
    },
    isNewToDashboard = (appState: AppState) => {
        const showWelcomeDialog = showWelcomeToDashboardDialogSelector(appState);
        return showWelcomeDialog || showWelcomeDialog === undefined;
    };
