import React from "react";
import cx from "classnames";
import { Msg } from "../../../../view/intl";
import { openDialog } from "../../../App/actionCreators";
import BlogManagementDialogId from "./BlogManagementDialog/dialogId";
import { isBlogCompatibleWithSubscription } from "../utils";
import styles from "./BlogMainActions.css";
import { MANAGE_BLOG_TOOLTIP_MSG } from "./constants";

const ctaOnClick = ({ dispatch, subscriptionData }) => {
    if (isBlogCompatibleWithSubscription(subscriptionData.subscriptionType)) {
        dispatch(openDialog(BlogManagementDialogId));
    }
};

const componentMainActions = {
    // hides the standard settings button
    // but makes the custom button red by default
    isCTAUrgent: () => true,

    // custom button that opens the blog management iframe
    CtaButtonTextView: ({ subscriptionData }) => (
        <span
            className={cx(styles.buttonText, {
                [styles.disabled]: !isBlogCompatibleWithSubscription(
                    subscriptionData.subscriptionType
                )
            })}
        >
            <Msg k="component.blog.cta.manageBlog">Manage blog</Msg>
        </span>
    ),
    ctaButtonClass: styles.manageButton,
    getCtaTooltipProps: ({ subscriptionData }) => {
        // show a tooltip when user is not on premium+
        if (!isBlogCompatibleWithSubscription(subscriptionData.subscriptionType)) {
            return {
                text: MANAGE_BLOG_TOOLTIP_MSG,
                class: styles.tooltip,
                position: "top"
            };
        }
        return null;
    },

    // open the blog management dialog on button click
    ctaOnClick
};

export { componentMainActions };
