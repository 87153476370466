import type { SiteSettings, PublishConfig } from "../../App/epics/siteSettings/flowTypes";
import { publishConfigDefaultState } from "./publishConfigGlobalDataEpic";
import strUtils from "../../../../utils/string.js";

const getScheme = (publishConfig?: PublishConfig): string => {
    if (publishConfig && publishConfig.publishWithSSL) {
        return 'https';
    }

    return 'http';
};

const getSchemeFromSiteSettings = (siteSettings?: SiteSettings) => {
    const publishConfig = siteSettings ? siteSettings.publishConfig : publishConfigDefaultState;
    return getScheme(publishConfig);
};

const fixProtocol = (url: string, publishConfig: PublishConfig): string => {
    let finalUrl = url.replace(/^https?:\/\//, '');
    const protocol = getScheme(publishConfig);

    return `${protocol}://${finalUrl}`;
};

const fixExtension = (url: string, { dropHtmlExtension }: PublishConfig): string => {
    if (dropHtmlExtension) {
        return url.replace(/\/index\.html$/g, '').replace(/\.html$/, '');
    }

    return url;
};

const getFinalURL = (url: string, publishConfig: PublishConfig): string => {
    let finalUrl = url;

    finalUrl = fixProtocol(finalUrl, publishConfig);
    finalUrl = fixExtension(finalUrl, publishConfig);

    return finalUrl;
};

const getDomainNameToRenderForSSL = (
    domainName: string,
    publishWithSSL: boolean
): string => {
    const renderLength = publishWithSSL ? 45 : 33;
    return strUtils.ellipsis(domainName, renderLength, '...');
};

const getDomainNameToRenderForBeautifulURL = (
    domainName: string,
    publishWithSSL: boolean,
    dropHtmlExtension: boolean
): string => {
    let renderLength = domainName.length;

    if (publishWithSSL) {
        renderLength = dropHtmlExtension ? 39 : 34;
    } else {
        renderLength = dropHtmlExtension ? 26 : 20;
    }

    return strUtils.ellipsis(domainName, renderLength, '...');
};

const isSSLEnabled = ({ publishWithSSL }: PublishConfig): boolean => publishWithSSL;
const isDropHtmlExtensionEnabled = (siteSettings?: SiteSettings): boolean => {
    if (siteSettings &&
        siteSettings.publishConfig &&
        siteSettings.publishConfig.dropHtmlExtension) {
        return siteSettings.publishConfig.dropHtmlExtension;
    }

    return false;
};

export {
    isSSLEnabled,
    isDropHtmlExtensionEnabled,
    getScheme,
    getSchemeFromSiteSettings,
    getFinalURL,
    getDomainNameToRenderForSSL,
    getDomainNameToRenderForBeautifulURL
};
