import * as R from 'ramda';
import * as interactionModes from './interactionModes';
import { calculateTree } from './applyAdjustingSpaceMutations';
import applyComponentTransientPropertyChanges from './applyComponentTransientPropertyChanges';
import applyMovingComponentsMutations from './applyMovingComponentsMutations';
import applyResizingComponentsMutations from './applyResizingComponentsMutations';
import { receiveOnly, optional } from '../../../../../epics/makeCondition';
import * as appActionTypes from '../../../../App/actionTypes';
import scrollValueActionType from '../../scroll/valueActionType';
import workspaceBBoxValueActionType from "../../workspaceBBox/valueActionType";
import {
    OptionalIsShiftPressedActionType,
    ReceiveOnlyIsCtrlLikePressedActionType,
    ReceiveOnlyIsShiftPressedActionType,
    OptionalIsAltPressedActionType,
    ReceiveOnlyIsAltPressedActionType
} from "../../../../App/epics/isKeyPressed/selectorActionTypes";
import {
    isResizingHandle,
    isComponentHandle,
    isWorkspaceRelatedHandle,
    isHoverShiftBarHandle, isComponentsRelatedHandle, isShiftBarHandle,
    isShiftBarTop,
    isShiftBarBottom
} from '../../../../../utils/handle/index';
import * as workspaceActionTypes from "../../../actionTypes";
import {
    USER_INTERACTION_DONE_ON_COMPONENTS,
    DESELECTED_COMPONENTS_ON_WORKSPACE_MOUSE_DOWN,
    COMPONENT_CHANGING,
    END_COMPONENT_CHANGING_USER_INTERACTION,
    COMPONENTS_MOVED_BY_MOUSE,
    COMPONENTS_RESIZED,
    ADJUSTMENT_DATA_APPLIED, REMOVE_SECTION_DROP_ZONE, COMPONENTS_BOTTOM_SHIFTED
} from '../actionTypes';
import registry from "../../../../../view/oneweb/registry/index";
import type { Position } from '../../../../../redux/modules/flowTypes';
import type {
    BBox, AnyComponent, ComponentsMap, ComponentsIds
} from '../../../../../redux/modules/children/workspace/flowTypes';
import type { ComponentsMutationsHandler } from './flowTypes';
import browserDimensionsValueActionType from '../../../../App/epics/browserDimensions/valueActionType';
import { ReceiveOnlyTemplateWidthActionType } from '../../../../oneweb/Template/epics/template/selectorActionTypes';
import {
    getComponentsBBox,
    getComponentsIdsInBBox,
    getSelectionBBox,
} from '../../../../../utils/componentsMap/index';
import * as updateReasons from '../updateReasons';
import { deselectAllComponents, setSelectedComponentsIds } from '../selectedComponentsIds';
import type { EpicState, ComponentsEvalEpicUpdater, UpdateReason } from '../flowTypes';
import { snap } from "../../../../../utils/snapping/index";
import { snappingDecosDefaultState } from "../../snappingDecos/index";
import {
    ReceiveOnlySelectedComponentIdSelector, ROEditModeComponentIdSelector,
    selectedComponentsIdsSelector,
    componentsMapSelector,
    ResizedComponentIdsSelector,
    userInteractionModeSelector
} from "../selectorActionTypes";
import { handlesUnderMouseValueActionType } from "../../handlesUnderMouse/valueActionType";
import {
    mousePositionWithRespectToTemplateAreaValueActionType
} from "../../mousePositionWithRespectToTemplateArea/valueActionType";
import {
    resetComponentsMapFromTransientMutationHandler, resetStateBeforeTransientChanges,
    setComponentsMap, setComponentsMapFromTransientMutationHandler,
    setDefaultUserInteraction,
    setStateBeforeTransientChanges,
    setUserInteraction, setUserInteractionMode, setUserInteractionPayloadNewMousePosition,
    setMovingComponents,
    setUserInteractionComponentsDuplicated,
    setUserInteractionPayload
} from "../setters";
import {
    getNotSelectedWrappedIdsInsideSelectedComponents,
    userInteractionComponentsIdsPath
} from "../selectors";
import { applyShiftBarMovingComponentsMutations } from "./applyShiftBarMovingComponentsMutations";
import relationsValueActionType from "../../relations/valueActionType";
import { componentsMapFromTransientMutationHandlerPath, userInteractionPath, componentsMapPath } from "../paths";
import { componentAttachmentsVAT } from '../../componentAttachements/valueActionType';
import type { Attachments, ChildrenBBox } from '../../componentAttachements/flowTypes';
import {
    getAllAttachmentsForCmpIds, removeDuplicatesFromAnArrayOfStrings,
    getAttachedContainerCmpId, getTopMostParentId
} from '../../componentAttachements/util';
import { getMaxOrderIndex } from "../../../../../redux/modules/children/workspace/reducers/utils/index";
import {
    getPrevOrNextSectionInfo,
    removeEmptySpacesBetweenSections,
    adjustComponentTops,
    isSectionComponent,
} from "../../../../oneweb/Section/utils";
import * as HandleKinds from "../../../../../utils/handle/kinds";
import { isSectionKind } from "../../../../oneweb/componentKinds";
import {
    getComponentsMap, getSectionInsertion,
    getStateBeforeTransientChanges
} from "../getters";
import { isNonTemplateComponentsInHeaderOrFooter, fixSelectionOverlapWithSectionByMovingSelection } from "./utils";
import { addMessage } from "../../../../Toaster/actionCreators";
import MessageTypes from "../../../../Toaster/MessageTypes";
import { CANNOT_MOVE_TO_TEMPLATE,
    CANNOT_MOVE_MODERN_HEADER_SECTION_COMPONENTS,
    CANNOT_MOVE_MODERN_FOOTER_SECTION_COMPONENTS } from "../messages";
import { validateSectionsArrangement, getSectionAtPosition } from "../../isPageMode/utils";
import { isStickyToHeader, selectedComponentIsStickyToHeader } from '../isStickyToHeader';
import selectionFrameDecorationValueActionType from "../../selectionFrameDecoration/valueActionType";
import { MIN_DRAG_DISTANCE } from "./constants";
import { getComponentsMapForHoverBox } from "../../../../oneweb/HoverBox/utils";
import { getSectionSnappingBBoxes } from "../../SnapEquisistantFromEdgesOfSection/util";
import { workspaceComponentAttachDecorationsValueActionType } from "../../componentAttachDecorations/valueActionType";
import { isModernLayoutActivatedVAT } from "../../isModernLayoutActivatedEpic/valueActionType";
import { selectedComponentIsInsideHeaderOrFooterVAT } from "../../selectedComponentIsInsideHeaderOrFooterEpic/valueActionType";
import { someComponentsPlacedInModernSection } from "../../../../ModernLayouts/utils";
import { isModernLayoutSection, getAllComponentsIdsInModernHeaderAndFooter } from "../../../../ModernLayouts/preview_utils";
import { getWebShopStripCmpIds, someComponentsBelowWebShopFooterStrip,
    someComponentsPlacedInWebShopFooterStrip } from '../../../../ModernLayouts/layoutsData/webshopMHFDataUtils';

type UserInteractionModeToChangeReason = {
    [key: string]: UpdateReason
}

const
    userInteractionModeToChangeReason: UserInteractionModeToChangeReason = {
        [interactionModes.COMPONENT_CHANGING]: updateReasons.PROPERTY_CHANGE,
        [interactionModes.MOVING_COMPONENTS]: updateReasons.MOVED_BY_MOUSE,
        [interactionModes.SHIFTBAR_MOVING]: updateReasons.SHIFTBAR,
        [interactionModes.RESIZE_HANDLE_MOVING]: updateReasons.RESIZED_BY_MOUSE,
        [interactionModes.MOUSE_DOWN_ON_HANDLE]: updateReasons.MOUSE_DOWN_ON_HANDLE
    },
    isMovingComponents = epicState =>
        epicState.scope.userInteraction.mode === interactionModes.MOVING_COMPONENTS,
    isResizingComponents = epicState => {
        const mode = epicState.scope.userInteraction.mode;
        return [interactionModes.RESIZE_HANDLE_MOVING, interactionModes.SHIFTBAR_MOVING].includes(mode);
    },
    getAllMovingComponents = (epicState, attachments) => {
        let movingComponents = {},
            componentIds = epicState.scope.userInteraction.payload.componentsIds || [],
            transientChanges = epicState.scope.stateBeforeTransientChanges;
        if (transientChanges && isMovingComponents(epicState)) {
            componentIds = componentIds.concat(epicState.scope.userInteraction.payload.wrappedIds);
            componentIds.concat(getAllAttachmentsForCmpIds(attachments, componentIds)).forEach((id) => {
                if (transientChanges.componentsMap[id]) {
                    movingComponents[id] = {
                        orderIndex: transientChanges.componentsMap[id].orderIndex,
                        parentId: getAttachedContainerCmpId(id, attachments)
                    };
                }
            });
        }
        return { componentIds, movingComponents };
    },
    getUserInteraction = R.path(userInteractionPath),
    applyInteractionChanges =
        (mutationHandler: ComponentsMutationsHandler) =>
            (newMousePosition: Position, workspaceBBox: BBox, isShift: boolean,
                epicState: EpicState, attachments: Attachments, childrenBBox: ChildrenBBox): EpicState => {
                const transientChanges = epicState.scope.stateBeforeTransientChanges;

                if (!transientChanges) {
                    throw new Error('applyInteractionChanges, should never happen');
                }

                const { movingComponents, componentIds } = getAllMovingComponents(epicState, attachments);

                const
                    newComponentsMap = mutationHandler({
                        components: transientChanges,
                        componentsIds: componentIds,
                        start: epicState.scope.userInteraction.payload.startPosition,
                        current: newMousePosition,
                        handleKind: epicState.scope.userInteraction.payload.handleKind,
                        workspaceBBox,
                        componentsAdjustmentData: epicState.scope.componentsAdjustmentData,
                        isShift,
                        userInteractionPayload: epicState.scope.userInteraction.payload,
                        previousComponentsMapFromMutationHandler: R.path(componentsMapFromTransientMutationHandlerPath, epicState),
                        attachments,
                        childrenBBox,
                        orderIndex: getMaxOrderIndex(transientChanges.componentsMap),
                    }),
                    doIfComponentsMapChanged = R.when(() => newComponentsMap !== null),
                    doIfMovingComponents = R.when(() => isMovingComponents(epicState));

                const newEpicState = R.pipe(
                    doIfComponentsMapChanged(setComponentsMap(newComponentsMap)),
                    doIfComponentsMapChanged(setComponentsMapFromTransientMutationHandler(newComponentsMap)),
                    setUserInteractionPayloadNewMousePosition(newMousePosition),
                    doIfMovingComponents(setMovingComponents(movingComponents))
                )(epicState);

                return newEpicState;
            },
    movingComponentsUpdater = applyInteractionChanges(
        applyMovingComponentsMutations
    ),
    resizingUpdater = applyInteractionChanges(
        applyResizingComponentsMutations
    ),
    shiftBarMovingUpdater = applyInteractionChanges(
        applyShiftBarMovingComponentsMutations
    ),
    setUserInteractionModeToMove = setUserInteractionMode(interactionModes.MOVING_COMPONENTS),
    setUserInteractionModeToResize = setUserInteractionMode(interactionModes.RESIZE_HANDLE_MOVING),
    setUserInteractionModeToShiftbar = setUserInteractionMode(interactionModes.SHIFTBAR_MOVING),
    setUserInteractionModeToIdle = setUserInteractionMode(interactionModes.IDLE),
    getNextUserInteractionModeUpdater = ({
        someOfSelectedComponentsAreUnderMouseAndTopMostComponentIsNotSelected,
        handleKind,
        containsSection
    }) => {
        if (containsSection && !isShiftBarBottom(handleKind)) {
            return setUserInteractionModeToIdle;
        }
        if (someOfSelectedComponentsAreUnderMouseAndTopMostComponentIsNotSelected || isComponentHandle(handleKind)) {
            return setUserInteractionModeToMove;
        }
        if (isResizingHandle(handleKind)) {
            return setUserInteractionModeToResize;
        }
        if (isShiftBarHandle(handleKind)) {
            return setUserInteractionModeToShiftbar;
        }

        return R.identity;
    },
    setComponentChangingInteractionMode = (epicState: EpicState) => R.pipe(
        setUserInteractionMode(interactionModes.COMPONENT_CHANGING),
        setStateBeforeTransientChanges(epicState.state)
    )(epicState),
    onMouseMoveOnComponentAfterMouseDownInEditMode = (newMousePosition: Position,
        component: AnyComponent,
        componentsMap: ComponentsMap,
        onMouseMoveAfterMouseDownInEditMode: Function,
        epicState: EpicState,
        attachments: Attachments) => {
        if (!epicState.scope.stateBeforeTransientChanges) {
            throw new Error('onMouseMoveOnComponentAfterMouseDownInEditMode-1, should never happen');
        }

        if (!epicState.scope.userInteraction.payload.startPosition) {
            throw new Error('onMouseMoveOnComponentAfterMouseDownInEditMode-2, should never happen');
        }
        const
            newComponentsMap = {
                ...componentsMap,
                [component.id]: onMouseMoveAfterMouseDownInEditMode(
                    component,
                    epicState.scope.userInteraction.payload.startPosition,
                    newMousePosition
                )
            },
            { movingComponents } = getAllMovingComponents(epicState, attachments);

        const newEpicState = R.pipe(
            setComponentsMap(newComponentsMap),
            setMovingComponents(movingComponents)
        )(epicState);

        return {
            state: newEpicState,
            actionToDispatch: {
                type: COMPONENTS_MOVED_BY_MOUSE,
                payload: { componentsId: [component.id] }
            },
            updateReason: updateReasons.MOVED_BY_MOUSE
        };
    },
    setSnappingState = R.assocPath(['scope', 'snappingState']),
    setSnappingDefaultState = setSnappingState(snappingDecosDefaultState),
    getTopMostHandleComponentsIds = R.path([0, 'componentsIds']),
    getTopMostHandleKind = R.path([0, 'kind']),
    areSomeOfSelectedComponentsAreUnderMouseAndTopMostComponentIsNotSelected =
        (selectedComponentsIds, handlesUnderMouse) => {
            const
                topMostHandleComponentId = R.path([0], getTopMostHandleComponentsIds(handlesUnderMouse)),
                topMostComponentIsNotSelected = selectedComponentsIds.indexOf(topMostHandleComponentId) === -1,
                someOfSelectedComponentsAreUnderMouse = selectedComponentsIds.some(
                    id => handlesUnderMouse.some(h => h.componentsIds[0] === id)
                );

            return someOfSelectedComponentsAreUnderMouse && topMostComponentIsNotSelected;
        },
    getSelectionStateUpdateStep = ({
        handlesUnderMouse,
        isShiftOrCtrlLikePressed,
        isCtrlLikePressed,
        isShiftPressed,
        isAltLikePressed,
        selectedComponentsIds,
        componentsMap,
        workspaceBBox,
        attachments,
    }) => {
        const topmostHandle = handlesUnderMouse[0];

        if (topmostHandle) {
            const
                handleComponentId = topmostHandle.componentsIds[0],
                isHandleComponentSelected = selectedComponentsIds.indexOf(handleComponentId) !== -1;

            if ((isShiftOrCtrlLikePressed || isAltLikePressed) && isComponentHandle(topmostHandle.kind)) {
                const sectionId = getTopMostParentId(R.last(selectedComponentsIds), attachments);
                const isModernLayoutActivated = isModernLayoutSection(componentsMap[sectionId]);
                if (isModernLayoutActivated) {
                    return setSelectedComponentsIds([handleComponentId]);
                }
                if (isCtrlLikePressed && isHandleComponentSelected) {
                    return setSelectedComponentsIds(selectedComponentsIds.filter(x => x !== handleComponentId));
                }
                if (selectedComponentsIds.length) {
                    const attachedCmpIds = getAllAttachmentsForCmpIds(attachments, [sectionId]);
                    if (!attachedCmpIds.includes(handleComponentId)) {
                        return setSelectedComponentsIds([...selectedComponentsIds]);
                    }
                }
                if (isCtrlLikePressed) {
                    return setSelectedComponentsIds([...selectedComponentsIds, handleComponentId]);
                }
                if (isShiftPressed) {
                    const
                        selectedComponentsAndComponentUnderHandle = [
                            ...selectedComponentsIds,
                            handleComponentId
                        ].map(id => componentsMap[id]),
                        newSelectionBBox = getComponentsBBox(
                            selectedComponentsAndComponentUnderHandle,
                            workspaceBBox
                        ),
                        componentsIdsInsideBBox = getComponentsIdsInBBox(
                            componentsMap,
                            workspaceBBox,
                            newSelectionBBox
                        );

                    return setSelectedComponentsIds(componentsIdsInsideBBox);
                }
                return setSelectedComponentsIds([...selectedComponentsIds]);
            } else {
                if (!isShiftOrCtrlLikePressed && !isAltLikePressed && isWorkspaceRelatedHandle(
                    topmostHandle.kind,
                    handleComponentId ? componentsMap[handleComponentId].kind : "",
                    selectedComponentsIds,
                    handleComponentId
                )) {
                    return deselectAllComponents;
                }

                if (isComponentHandle(topmostHandle.kind) || isHoverShiftBarHandle(topmostHandle.kind)) {
                    return setSelectedComponentsIds([handleComponentId]);
                }
            }
        }

        return null;
    },
    checkIsWorkspaceRelatedHandle = (epicState) => {
        const { userInteraction } = epicState.scope;
        if (userInteraction.payload) {
            const
                topMostComponentId = userInteraction.payload.topMostComponentId,
                selectedComponentsIds = selectedComponentsIdsSelector(epicState);
            if (isWorkspaceRelatedHandle(
                userInteraction.payload.handleKind,
                topMostComponentId ? epicState.state.componentsMap[topMostComponentId].kind : "",
                selectedComponentsIds,
                topMostComponentId
            )) {
                return true;
            }
        }
        return false;
    },
    topShiftBarClickUpdater = (epicState) => {
        const userInteractionObj = getUserInteraction(epicState),
            { payload } = userInteractionObj,
            componentsMap = R.path(componentsMapPath, epicState);
        if (!payload) {
            return epicState;
        }
        const { containsSection, handleKind, topMostComponentId } = payload;
        const { section } = getPrevOrNextSectionInfo(topMostComponentId, componentsMap);
        if (containsSection && isShiftBarTop(handleKind) && section) {
            return R.pipe(
                setUserInteractionPayload({
                    ...payload,
                    componentsIds: [section.id],
                    topMostComponentId: section.id,
                    handleKind: HandleKinds.ShiftBarBottomSelection,
                    wrappedIds: [],
                    someOfSelectedComponentsAreUnderMouseAndTopMostComponentIsNotSelected: false
                }),
                setSelectedComponentsIds([section.id])
            )(epicState);
        }
        return epicState;
    },
    removeStickyMenuIds = (componentIds: ComponentsIds, componentsMap: ComponentsMap): ComponentsIds =>
        componentIds.filter((id) => !isStickyToHeader(componentsMap[id]));

const cleanUpTransientInteraction = R.pipe(
    resetComponentsMapFromTransientMutationHandler,
    resetStateBeforeTransientChanges,
    setDefaultUserInteraction,
);

const fixSelectionOverlapWithSectionByIncreasingSectionHeight = epicState => {
    const componentsMap = componentsMapSelector(epicState);
    const userInteractionComponentIds = R.path(userInteractionComponentsIdsPath, epicState);
    const componentsIds = removeStickyMenuIds(
        userInteractionComponentIds || selectedComponentsIdsSelector(epicState),
        componentsMap
    );
    const selectionBBox = getSelectionBBox(componentsMap, componentsIds);
    const section = getSectionAtPosition({ y: selectionBBox.top, x: 0 }, componentsMap);
    if (section && componentsIds.length) {
        const sectionBottom = section.top + section.height;
        if (sectionBottom < selectionBBox.bottom) {
            const diff = selectionBBox.bottom - sectionBottom;
            let newComponentsMap = adjustComponentTops(
                sectionBottom,
                diff,
                componentsMap
            );
            newComponentsMap = {
                ...newComponentsMap,
                ...componentsIds.reduce((acc, cId) => { // reset chances done to selected components by adjustComponentTops
                    acc[cId] = componentsMap[cId];
                    return acc;
                }, {}),
                [section.id]: { ...section, height: section.height + diff },
            };
            return setComponentsMap(newComponentsMap, epicState);
        }
    }

    return epicState;
};

export const
    handleEdgeCaseOverlapsWithSections: ComponentsEvalEpicUpdater = {
        // Avoid overlapping, handle all edge cases that can cause overlap for selected component
        // example: replace image asset for a image component in fit scale mode
        keepFullActions: true,
        conditions: [
            ResizedComponentIdsSelector
        ],
        reducer: ({ state }) => {
            if (interactionModes.isTransient(userInteractionModeSelector(state))) {
                return { state };
            }

            const newState = fixSelectionOverlapWithSectionByIncreasingSectionHeight(state);

            if (newState !== state) {
                return {
                    state: newState,
                    updateReason: updateReasons.FIXED_OVERLAP_WITH_SECTION
                };
            }

            return {
                state
            };
        }
    },
    onEndTransientUserInteractionUpdater: ComponentsEvalEpicUpdater = {
        conditions: [END_COMPONENT_CHANGING_USER_INTERACTION],
        reducer: ({ state }) => {
            const { mode } = state.scope.userInteraction;
            if (mode !== COMPONENT_CHANGING) return { state };
            return ({
                state: R.pipe(
                    fixSelectionOverlapWithSectionByIncreasingSectionHeight,
                    cleanUpTransientInteraction
                )(state),
                updateReason: userInteractionModeToChangeReason[state.scope.userInteraction.mode]
            });
        }
    },
    onWindowMouseUpUpdater: ComponentsEvalEpicUpdater = {
        conditions: [
            receiveOnly(componentAttachmentsVAT),
            receiveOnly(selectionFrameDecorationValueActionType),
            receiveOnly(handlesUnderMouseValueActionType),
            appActionTypes.WINDOW_MOUSE_UP
        ],
        reducer: ({ values: [{ attachments }, { bBox: selectionBBox }, handlesUnderMouse], state: epicState }) => {
            const
                { userInteraction } = epicState.scope,
                selectedComponentsIds = selectedComponentsIdsSelector(epicState),
                multipleActionsToDispatch: Array<Action> = [
                    { type: USER_INTERACTION_DONE_ON_COMPONENTS }
                ],
                topMostHandle = handlesUnderMouse[0];

            let newComponentsMap = getComponentsMap(epicState);

            if (userInteraction.mode === interactionModes.COMPONENT_CHANGING) {
                return {
                    state: R.pipe(
                        fixSelectionOverlapWithSectionByIncreasingSectionHeight,
                        cleanUpTransientInteraction
                    )(epicState),
                    multipleActionsToDispatch,
                    updateReason: userInteractionModeToChangeReason[userInteraction.mode]
                };
            }
            if (topMostHandle && userInteraction.mode === interactionModes.IDLE && !selectedComponentsIds.length) {
                const topMostComponentId = topMostHandle.componentsIds[0],
                    { left, right, top, bottom } = selectionBBox,
                    selectionBoxSize = Math.max(right - left, bottom - top);
                if (
                    isComponentHandle(topMostHandle.kind) &&
                    isSectionComponent(newComponentsMap[topMostComponentId]) &&
                    selectionBoxSize > MIN_DRAG_DISTANCE
                ) {
                    return {
                        state: setSelectedComponentsIds([topMostComponentId])(epicState),
                        updateReason: updateReasons.CHANGE_SCOPE
                    };
                }
            }

            if (
                userInteraction.mode === interactionModes.IDLE
                || userInteraction.mode === interactionModes.MOVING_COMPONENTS_BY_ARROW_KEYS
            ) {
                return { state: epicState };
            }

            if (userInteraction.mode === interactionModes.SHIFTBAR_MOVING) {
                const validation = validateSectionsArrangement(newComponentsMap);
                if (validation) {
                    console.error('Encountered problems with sections', validation);
                    newComponentsMap = getStateBeforeTransientChanges(epicState).componentsMap;
                }
            }

            let newSelectionComponentsIds: Array<string>;
            if (
                userInteraction.mode === interactionModes.MOUSE_DOWN_ON_HANDLE
                && (
                    userInteraction.payload.someOfSelectedComponentsAreUnderMouseAndTopMostComponentIsNotSelected
                    || selectedComponentsIds.indexOf(epicState.scope.userInteraction.payload.topMostComponentId) !== -1 // multiple components are selected, and we detected click on some of them, so will select top most one
                )
            ) {
                newSelectionComponentsIds = [epicState.scope.userInteraction.payload.topMostComponentId];
            } else {
                newSelectionComponentsIds = epicState.scope.userInteraction.payload.componentsIds;
                if (R.equals(newSelectionComponentsIds, selectedComponentsIds)) {
                    newSelectionComponentsIds = selectedComponentsIds;
                }
            }

            if (isResizingComponents(epicState)) {
                multipleActionsToDispatch.push({
                    type: COMPONENTS_RESIZED,
                    payload: { componentsId: newSelectionComponentsIds }
                });
            }
            if (isShiftBarBottom(userInteraction.payload && userInteraction.payload.handleKind)) {
                multipleActionsToDispatch.push({
                    type: COMPONENTS_BOTTOM_SHIFTED,
                    payload: { componentsId: newSelectionComponentsIds }
                });
            }

            if ((isResizingComponents(epicState) ||
                isMovingComponents(epicState)) &&
                (isNonTemplateComponentsInHeaderOrFooter(selectedComponentsIds, attachments, newComponentsMap) ||
                someComponentsPlacedInModernSection(selectedComponentsIds, newComponentsMap) ||
                someComponentsPlacedInWebShopFooterStrip(selectedComponentsIds, newComponentsMap) ||
                someComponentsBelowWebShopFooterStrip(selectedComponentsIds, newComponentsMap))
            ) {
                newComponentsMap = getStateBeforeTransientChanges(epicState).componentsMap;
                multipleActionsToDispatch.push({
                    type: COMPONENTS_MOVED_BY_MOUSE,
                    payload: { componentsId: selectedComponentsIds }
                },
                addMessage({
                    type: MessageTypes.INFO,
                    text: CANNOT_MOVE_TO_TEMPLATE
                }));
            }

            const doNotAdjustOverlap = epicState.scope.userInteraction.mode === interactionModes.MOUSE_DOWN_ON_HANDLE;

            const
                skipAvoidOverlapping = doNotAdjustOverlap || selectedComponentIsStickyToHeader(epicState),
                nextState = R.pipe(
                    setComponentsMap(newComponentsMap),
                    skipAvoidOverlapping ? R.identity : (state) => {
                        const componentsMap = componentsMapSelector(state);
                        const { movingComponents } = getAllMovingComponents(epicState, attachments);
                        let componentsIdsToAdjust = newSelectionComponentsIds;
                        if (userInteraction.mode === interactionModes.MOVING_COMPONENTS) {
                            componentsIdsToAdjust = Object.keys(movingComponents);
                        }
                        const updatedComponentsMap = fixSelectionOverlapWithSectionByMovingSelection(
                            componentsIdsToAdjust,
                            componentsMap
                        );
                        return setComponentsMap(updatedComponentsMap, state);
                    },
                    skipAvoidOverlapping ? R.identity : fixSelectionOverlapWithSectionByIncreasingSectionHeight,
                    resetComponentsMapFromTransientMutationHandler,
                    resetStateBeforeTransientChanges,
                    setDefaultUserInteraction,
                    setSelectedComponentsIds(newSelectionComponentsIds),
                )(epicState);

            return {
                state: setSnappingDefaultState(nextState),
                multipleActionsToDispatch,
                updateReason: userInteractionModeToChangeReason[epicState.scope.userInteraction.mode]
            };
        }
    },
    onLeftMouseDownUpdater: ComponentsEvalEpicUpdater = {
        conditions: [
            receiveOnly(handlesUnderMouseValueActionType),
            receiveOnly(mousePositionWithRespectToTemplateAreaValueActionType),
            receiveOnly(workspaceBBoxValueActionType),
            ReceiveOnlyIsShiftPressedActionType,
            ReceiveOnlyIsCtrlLikePressedActionType,
            ReceiveOnlyIsAltPressedActionType,
            receiveOnly(relationsValueActionType),
            receiveOnly(componentAttachmentsVAT),
            workspaceActionTypes.WORKSPACE_LEFT_MOUSE_DOWN
        ],
        reducer: ({
            values: [
                handlesUnderMouse,
                startPosition,
                workspaceBBox,
                isShiftPressed,
                isCtrlLikePressed,
                isAltLikePressed,
                { changes: relations },
                { attachments }
            ],
            state: epicState
        }) => {
            const
                newEpicState = setComponentsMap(
                    removeEmptySpacesBetweenSections(attachments, getComponentsMap(epicState))
                )(epicState),
                selectedComponentsIds = selectedComponentsIdsSelector(newEpicState),
                isShiftOrCtrlLikePressed = isShiftPressed || isCtrlLikePressed,
                stateUpdateSteps: Array<any> = [],
                topmostHandleKind = getTopMostHandleKind(handlesUnderMouse),
                { inProgress: sectionAddInProgress } = getSectionInsertion(epicState);

            if (sectionAddInProgress) {
                return {
                    state: setDefaultUserInteraction(epicState),
                    actionToDispatch: { type: REMOVE_SECTION_DROP_ZONE },
                    updateReason: updateReasons.CHANGE_SCOPE
                };
            }

            if (
                isComponentsRelatedHandle(topmostHandleKind)
                && (!isShiftOrCtrlLikePressed || isResizingHandle(topmostHandleKind))
            ) {
                let someOfSelectedComponentsAreUnderMouseAndTopMostComponentIsNotSelected =
                        areSomeOfSelectedComponentsAreUnderMouseAndTopMostComponentIsNotSelected(
                            selectedComponentsIds,
                            handlesUnderMouse
                        ),
                    topMostHandleComponentsIds = getTopMostHandleComponentsIds(handlesUnderMouse) || [],
                    selectedComponentHandleIsUnderMouse = selectedComponentsIds
                        .some(id => topMostHandleComponentsIds[0] === id),
                    componentsIds = removeDuplicatesFromAnArrayOfStrings((
                        selectedComponentHandleIsUnderMouse ||
                        someOfSelectedComponentsAreUnderMouseAndTopMostComponentIsNotSelected
                    ) ? selectedComponentsIds : topMostHandleComponentsIds);
                const componentsMap = getComponentsMap(epicState),
                    handleKind = getTopMostHandleKind(handlesUnderMouse),
                    getShiftBarDecorations = () => {
                        const
                            tree = calculateTree(
                                handleKind, componentsMap, componentsIds, workspaceBBox, relations
                            ),
                            selectionBBox = getComponentsBBox(
                                componentsIds.map(id => componentsMap[id]), workspaceBBox
                            );
                        let maxMovementDistance = -selectionBBox.top,
                            maxMovementDistanceToOverlapped = maxMovementDistance,
                            snappingExcludedIds: Array<string> = [];
                        tree.forEach(treeNode => {
                            if (componentsIds.indexOf(treeNode.id) > -1) {
                                if (treeNode.maxMovementDistance > maxMovementDistance) {
                                    if (treeNode.closestParent.overlappedWithMultiSelection) {
                                        maxMovementDistanceToOverlapped = treeNode.maxMovementDistance;
                                    } else {
                                        maxMovementDistance = treeNode.maxMovementDistance;
                                    }
                                }
                                snappingExcludedIds.push(...treeNode.children.map(
                                    (child) => child.id
                                ));
                            }
                        });

                        return {
                            relations,
                            maxDistance: Math.max(maxMovementDistance, maxMovementDistanceToOverlapped),
                            initialShiftBar: {
                                left: selectionBBox.left,
                                right: selectionBBox.right,
                                top: selectionBBox.top
                            },
                            snappingExcludedIds
                        };
                    };

                if (!isShiftOrCtrlLikePressed && !isAltLikePressed && selectedComponentsIds.length === 1) {
                    someOfSelectedComponentsAreUnderMouseAndTopMostComponentIsNotSelected = false;
                    componentsIds = topMostHandleComponentsIds;
                }

                stateUpdateSteps.push(
                    // @ts-ignore
                    R.pipe(
                        setUserInteraction({
                            mode: interactionModes.MOUSE_DOWN_ON_HANDLE,
                            payload: {
                                startPosition,
                                handleKind,
                                componentsIds,
                                containsSection: componentsIds.some((id) => isSectionKind(componentsMap[id].kind)),
                                wrappedIds: getNotSelectedWrappedIdsInsideSelectedComponents(
                                    componentsIds,
                                    componentsMap
                                ),
                                someOfSelectedComponentsAreUnderMouseAndTopMostComponentIsNotSelected,
                                /* in case someOfSelectedComponentsAreUnderMouseAndTopMostComponentIsNotSelected and use will not move mouse, we have to select topMostComponentOnMouseUp */
                                topMostComponentId: topMostHandleComponentsIds[0],
                                shiftBar: isShiftBarHandle(handleKind) ? getShiftBarDecorations() : null
                            }
                        }),
                        setStateBeforeTransientChanges(newEpicState.state)
                    )
                );
            }

            const selectionUpdateStep = getSelectionStateUpdateStep({
                handlesUnderMouse,
                isShiftOrCtrlLikePressed,
                isCtrlLikePressed,
                isShiftPressed,
                isAltLikePressed,
                selectedComponentsIds,
                componentsMap: newEpicState.state.componentsMap,
                workspaceBBox,
                attachments,
            });

            if (selectionUpdateStep) {
                stateUpdateSteps.push(selectionUpdateStep);
            }

            if (isShiftBarTop(topmostHandleKind)) {
                stateUpdateSteps.push(topShiftBarClickUpdater);
            }

            if (stateUpdateSteps.length > 0) {
                let result: any = {
                    state: R.pipe(...stateUpdateSteps)(newEpicState),
                    updateReason: updateReasons.CHANGE_SCOPE
                };
                if (selectedComponentsIds && selectedComponentsIds.length &&
                    (stateUpdateSteps.indexOf(deselectAllComponents) !== -1)) {
                    result.actionToDispatch = { type: DESELECTED_COMPONENTS_ON_WORKSPACE_MOUSE_DOWN };
                }
                return result;
            }
            return { state: epicState };
        }
    },
    onMouseMoveUpdater: ComponentsEvalEpicUpdater = {
        conditions: [
            mousePositionWithRespectToTemplateAreaValueActionType,
            optional(ADJUSTMENT_DATA_APPLIED), // since during resize operation components could grow in height because of narrowed text content, we need to readjust after we receive new heights
            receiveOnly(scrollValueActionType),
            OptionalIsShiftPressedActionType,
            OptionalIsAltPressedActionType,
            receiveOnly(browserDimensionsValueActionType),
            ReceiveOnlyTemplateWidthActionType,
            receiveOnly(workspaceBBoxValueActionType),
            ROEditModeComponentIdSelector,
            receiveOnly(componentAttachmentsVAT),
            receiveOnly(handlesUnderMouseValueActionType),
            receiveOnly(workspaceComponentAttachDecorationsValueActionType),
            receiveOnly(isModernLayoutActivatedVAT),
            receiveOnly(selectedComponentIsInsideHeaderOrFooterVAT),
        ],
        reducer: ({
            values: [
                mousePosition,
                ,
                scroll,
                isShift,
                isAlt,
                browserDimensions,
                templateWidth,
                workspaceBBox,
                editModeComponentId,
                { attachments, childrenBBox },
                handlesUnderMouse,
                { cmpId: parentContainerId },
                isModernLayoutActivated,
                {
                    isInsideHeaderOrFooter: selectedCmpIsInHeaderOrFooter,
                    isInsideHeader: selectedCmpIsInHeader,
                },
            ],
            state: epicState
        }) => {
            const { userInteraction } = epicState.scope,
                { payload } = userInteraction,
                componentsMap = componentsMapSelector(epicState),
                selectedComponentsIds = selectedComponentsIdsSelector(epicState),
                webShopFooterStripCmpIds = getWebShopStripCmpIds(componentsMap);

            if (
                epicState.scope.userInteraction.mode === interactionModes.IDLE
                || epicState.scope.userInteraction.mode === interactionModes.MOVING_COMPONENTS_BY_ARROW_KEYS
                || epicState.scope.userInteraction.mode === interactionModes.COMPONENT_CHANGING
                || (payload && payload.isCmpInModernSectionMoved)
            ) {
                return { state: epicState };
            }

            if (epicState.scope.userInteraction.mode === interactionModes.MOUSE_DOWN_ON_HANDLE) {
                if (!isAlt && checkIsWorkspaceRelatedHandle(epicState)) {
                    return {
                        state: setDefaultUserInteraction(epicState),
                        updateReason: updateReasons.CHANGE_SCOPE
                    };
                }

                const { startPosition, isDuplicated, handleKind } = payload,
                    currentPos = mousePosition,
                    diffX = startPosition.x - currentPos.x,
                    diffY = startPosition.y - currentPos.y,
                    maxDiff = Math.max(Math.abs(diffX), Math.abs(diffY)),
                    canDuplicateByAlt =
                        !isResizingHandle(handleKind) &&
                        !isShiftBarHandle(handleKind) &&
                        !isDuplicated &&
                        !(isModernLayoutActivated && selectedCmpIsInHeaderOrFooter) &&
                        isAlt;

                if (maxDiff < 2) {
                    return { state: epicState };
                } else if (canDuplicateByAlt) {
                    return {
                        state: setUserInteractionComponentsDuplicated(epicState),
                        actionToDispatch: { type: workspaceActionTypes.DUPLICATE_SELECTED_COMPONENTS_SILENT, payload: 0 },
                        updateReason: updateReasons.CHANGE_SCOPE
                    };
                } else if (isDuplicated) {
                    const someOfSelectedComponentsAreUnderMouseAndTopMostComponentIsNotSelected =
                            areSomeOfSelectedComponentsAreUnderMouseAndTopMostComponentIsNotSelected(
                                selectedComponentsIds,
                                handlesUnderMouse
                            ),
                        topMostComponentId = R.head(getTopMostHandleComponentsIds(handlesUnderMouse) || []),
                        componentsIds = selectedComponentsIdsSelector(epicState),
                        wrappedIds = getNotSelectedWrappedIdsInsideSelectedComponents(
                            componentsIds,
                            componentsMap
                        ),
                        userInteractionModeUpdater = getNextUserInteractionModeUpdater(payload);
                    return {
                        state: R.pipe(
                            userInteractionModeUpdater,
                            setUserInteractionPayload(R.merge(
                                payload,
                                {
                                    componentsIds,
                                    wrappedIds,
                                    topMostComponentId,
                                    someOfSelectedComponentsAreUnderMouseAndTopMostComponentIsNotSelected
                                }
                            )),
                            setStateBeforeTransientChanges(epicState.state),
                            setSelectedComponentsIds(componentsIds)
                        )(epicState),
                        updateReason: updateReasons.CHANGE_SCOPE
                    };
                }
            }

            if ((isModernLayoutActivated && selectedCmpIsInHeaderOrFooter)
                || (selectedComponentsIds.some(id => webShopFooterStripCmpIds.includes(id))
                    && !isShiftBarTop(payload.handleKind))) {
                let message = selectedCmpIsInHeader ?
                        CANNOT_MOVE_MODERN_HEADER_SECTION_COMPONENTS :
                        CANNOT_MOVE_MODERN_FOOTER_SECTION_COMPONENTS,
                    showMsgActionToDispatch;

                if (isModernLayoutActivated && selectedCmpIsInHeaderOrFooter) {
                    showMsgActionToDispatch = addMessage({
                        type: MessageTypes.INFO,
                        text: message
                    });
                }

                return {
                    state: setUserInteractionPayload({
                        ...payload,
                        isCmpInModernSectionMoved: true,
                    }, epicState),
                    actionToDispatch: showMsgActionToDispatch,
                    updateReason: updateReasons.CHANGE_SCOPE
                };
            }

            const userInteractionModeUpdater = getNextUserInteractionModeUpdater(userInteraction.payload),
                nextEpicState = userInteractionModeUpdater(epicState),
                _adjustSnapping = (applyingFunction, shouldNotSnapWrappedComponents?: any) => adjustSnapping({
                    currentEpicState: nextEpicState,
                    applyingFunction,
                    templateWidth,
                    browserDimensions,
                    scrollTop: scroll,
                    mousePosition,
                    workspaceBBox,
                    isShift,
                    shouldNotSnapWrappedComponents,
                    attachments,
                    childrenBBox,
                    parentContainerId
                });

            switch (nextEpicState.scope.userInteraction.mode) {
                case interactionModes.MOVING_COMPONENTS:
                    if (editModeComponentId) {
                        const
                            // @ts-ignore
                            componentsMap = epicState.scope.stateBeforeTransientChanges.componentsMap,
                            component = componentsMap[editModeComponentId],
                            record = registry[component.kind],
                            {
                                willHandleMouseMoveAfterMouseDownInEditMode,
                                // @ts-ignore WBTGEN-2414
                                onMouseMoveAfterMouseDownInEditMode
                            } = record;
                        if (willHandleMouseMoveAfterMouseDownInEditMode(component)) {
                            return onMouseMoveOnComponentAfterMouseDownInEditMode(
                                mousePosition,
                                component,
                                componentsMap,
                                onMouseMoveAfterMouseDownInEditMode,
                                nextEpicState,
                                attachments,
                            );
                        }
                    }
                    return _adjustSnapping(movingComponentsUpdater);
                case interactionModes.SHIFTBAR_MOVING:
                    return _adjustSnapping(shiftBarMovingUpdater);
                case interactionModes.RESIZE_HANDLE_MOVING: {
                    return _adjustSnapping(resizingUpdater, true);
                }
                default:
                    return { state: nextEpicState, updateReason: updateReasons.CHANGE_SCOPE };
            }
        }
    },
    onComponentChangingUpdater: ComponentsEvalEpicUpdater = {
        conditions: [
            ReceiveOnlySelectedComponentIdSelector,
            COMPONENT_CHANGING
        ],
        reducer: ({ values: [selectedComponentId, changeAction], state: epicState }) => {
            const
                nextEpicState =
                    epicState.scope.userInteraction.mode === interactionModes.COMPONENT_CHANGING ?
                        epicState : setComponentChangingInteractionMode(epicState),
                nextComponentsMap = applyComponentTransientPropertyChanges(
                    nextEpicState.state.componentsMap,
                    selectedComponentId,
                    changeAction
                );

            return {
                state: setComponentsMap(nextComponentsMap, nextEpicState),
                updateReason: updateReasons.PROPERTY_CHANGE
            };
        }
    };

function adjustSnapping({
    currentEpicState,
    applyingFunction,
    templateWidth,
    browserDimensions,
    scrollTop,
    mousePosition,
    workspaceBBox,
    isShift,
    shouldNotSnapWrappedComponents,
    attachments,
    childrenBBox,
    parentContainerId
}) {
    if (!interactionModes.transientInteractionModesByMouse[currentEpicState.scope.userInteraction.mode]) {
        return { state: currentEpicState };
    }

    const
        transitionalEpicState = applyingFunction(mousePosition, workspaceBBox, isShift, currentEpicState, attachments,
            childrenBBox),
        transitionalComponentsMap = getComponentsMapForHoverBox(transitionalEpicState.state.componentsMap, attachments),
        componentsIds = transitionalEpicState.scope.userInteraction.payload.componentsIds,
        updateReason = userInteractionModeToChangeReason[currentEpicState.scope.userInteraction.mode],
        anyComponentChangedItsHeightBecauseOfDynamicHeightAdjust = componentsIds.some(cid => {
            const heightBefore =
                currentEpicState.scope.stateBeforeTransientChanges
                && currentEpicState.scope.stateBeforeTransientChanges.componentsMap[cid].height;
            const adjustmentData = currentEpicState.scope.componentsAdjustmentData[cid];
            const currentMinHeight = adjustmentData && adjustmentData.minDimensions && adjustmentData.minDimensions.height;

            return currentMinHeight && heightBefore && currentMinHeight > heightBefore;
        }),
        modernLayoutComponents = getAllComponentsIdsInModernHeaderAndFooter(transitionalComponentsMap);

    let shouldSnap = !shouldNotSnapWrappedComponents || componentsIds.some(componentId => {
        return transitionalComponentsMap[componentId] && !transitionalComponentsMap[componentId].wrap;
    });
    const isScrollingVertically = scrollTop.shouldScrollToVertically;
    if (anyComponentChangedItsHeightBecauseOfDynamicHeightAdjust || isScrollingVertically) {
        // if text component grows in height during the transient user interaction, snapping will not work correctly, so disabling it.
        // additionaly snapping could allow component to overlap over the section in case component starts overlapping the section boundary for less then 10px when text height grown because of narrowing of text
        shouldSnap = false;
    }

    if (!shouldSnap) {
        return {
            state: transitionalEpicState,
            updateReason
        };
    }

    const
        handleKind = currentEpicState.scope.userInteraction.payload.handleKind,
        snappingExcludedIdsForShiftBar = currentEpicState.scope.userInteraction.payload.shiftBar ?
            [
                ...currentEpicState.scope.userInteraction.payload.shiftBar.snappingExcludedIds
            ] : [],
        excludeComponentIds = [
            ...currentEpicState.scope.userInteraction.payload.wrappedIds,
            ...snappingExcludedIdsForShiftBar,
            ...modernLayoutComponents
        ],
        snapCmpIds = removeDuplicatesFromAnArrayOfStrings([...componentsIds,
            ...getAllAttachmentsForCmpIds(attachments || {}, componentsIds)]),

        isSingleSelectedComponent = componentsIds.length === 1,
        sectionSnappingBboxes = isSingleSelectedComponent ? getSectionSnappingBBoxes(
            snapCmpIds[0],
            parentContainerId,
            attachments,
            transitionalComponentsMap,
            templateWidth,
            handleKind
        ) : [];

    const
        snapResult = snap({
            componentsMap: transitionalComponentsMap,
            componentsIds: snapCmpIds,
            templateWidth,
            browserDimensions,
            scrollTop,
            mousePosition,
            workspaceBBox,
            handleKind,
            excludeComponentIds,
            sectionSnappingBboxes
        });

    if (snapResult.snapped === true) {
        let
            { snappedPoints, newMousePosition } = snapResult;
        const
            nextEpicState = applyingFunction(newMousePosition, workspaceBBox, isShift, currentEpicState, attachments,
                childrenBBox),
            nextComponentsMap = nextEpicState.state.componentsMap,
            snappedComponentsBBox = getComponentsBBox(snapCmpIds.map(id => nextComponentsMap[id]), workspaceBBox),
            roundedSnappedComponentsBBox = R.mapObjIndexed(Math.round, snappedComponentsBBox),
            previousRoundedSnappedBBox = R.path(['roundedSnappedComponentsBBox'], currentEpicState.scope.snappingState);

        if (R.equals(previousRoundedSnappedBBox, roundedSnappedComponentsBBox)) {
            return {
                state: currentEpicState,
                updateReason
            };
        }
        return {
            state: setSnappingState(
                { snappedPoints, snappedComponentsBBox, workspaceBBox, roundedSnappedComponentsBBox },
                nextEpicState
            ),
            updateReason
        };
    } else if (currentEpicState.scope.snappingState !== snappingDecosDefaultState) {
        return {
            state: setSnappingDefaultState(currentEpicState),
            updateReason
        };
    }
    return {
        state: transitionalEpicState,
        updateReason
    };
}
