import { WSB_CUSTOMER_PROFILE_COOKIE_NAME } from '../../../../server/shared/wsbCustomerProfile/constants.js';
import { getCookie } from '../../services/cookie';

import type { WsbCustomerProfileCookiePayload } from '../../../../server/shared/wsbCustomerProfile/types';

const decodeProfileCookiePayload = (cookieStr) => {
    // eslint-disable-next-line no-buffer-constructor
    return cookieStr ? JSON.parse(new Buffer(cookieStr, 'base64').toString()) : null;
};

export const readWsbCustomerProfileCookiePayload = (): null | undefined | WsbCustomerProfileCookiePayload =>
    decodeProfileCookiePayload(getCookie(WSB_CUSTOMER_PROFILE_COOKIE_NAME));
