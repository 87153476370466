/* eslint-disable max-len */

import React from "react";
import * as styles from './PublishingInfo.css';
import Msg from '../../../../../view/intl/Msg';
import { CLOSE_PUBLISHING_PROGRESS_POPUP, PUBLISHING_TIP_READ_MORE_CLICKED } from "../../../actionTypes";
import type { PublishingInfoProps } from "../../../flowTypes";
import PublingProgressText from './PublishingProgressText';
import {
    isPremiumSubscription,
    isEcommerceSubscription,
    isBasicSubscription
} from "../../../../App/epics/subscriptionData/utils";
import { upgrade } from "../../../../ComponentTierManager/actionCreators";
import { SubscriptionType } from "../../../../../../../server/shared/accessManager/constants.js";
import { getPremiumPackageNameFromMetadata } from '../../../../ComponentTierManager/utils';

export default ({ subscriptionType, subscriptionMetadata, publishStatus, showReadMoreTip, dispatch, intl }: PublishingInfoProps) => {
    const isEcommerceUser = isEcommerceSubscription(subscriptionType),
        premiumPackageNameFromMetadata = getPremiumPackageNameFromMetadata(subscriptionMetadata);

    if (isEcommerceUser) {
        // WBTGEN-20292 + WBTGEN-20293 Currently no tip for ECOMMERCE users as it needs translations
        return null;
    }

    const isPremiumUser = isPremiumSubscription(subscriptionType);
    const isBasicUser = isBasicSubscription(subscriptionType);

    let infoStyle: { paddingBottom?: number } = {};

    if (showReadMoreTip) {
        infoStyle.paddingBottom = 0;
    }

    return (
        <div className={styles.container}>
            <div className={styles.infoContainer} onClick={e => e.stopPropagation()} data-testid="info-container">
                <div className={styles.pointer} />
                <div className={styles.infoBox} style={infoStyle}>
                    <div className={styles.info} data-testid="info-box">
                        <div className={styles.title}>
                            {isPremiumUser ? <Msg
                                k="publish.publishing.title.premium"
                                params={{ packageName: premiumPackageNameFromMetadata || intl.msgJoint('msg: common.premium {Premium}') }}
                            >{`
                                Publishing a {packageName} website...
                            `}</Msg> : <Msg k="publish.publishing.title.normal">
                                Publishing pages...
                            </Msg>}
                        </div>
                        <div className={styles.subTitle}>
                            <PublingProgressText
                                subscriptionType={subscriptionType}
                                publishStatus={publishStatus}
                                dispatch={dispatch}
                            />
                        </div>
                        {
                            // Starter and above have better image resolution
                            // show a info tip to basic tier users to inform them of this
                            isBasicUser &&
                            <div data-testid="pro-tip">
                                <div className={styles.proTipWrapper}>
                                    <span className={styles.proTip}>
                                        <Msg k="publish.publishing.proTip">Pro tip:</Msg>
                                    </span>
                                    <Msg
                                        k="publish.publishing.proTipNote"
                                        params={{ packageName: intl.msgJoint('msg: common.starter {Starter}') }}
                                    >{`
                                        {packageName} displays your website in the best quality resolution on your visitor’s screens.
                                    `}</Msg>
                                </div>
                                <a
                                    className={styles.readMore}
                                    onClick={(e) => {
                                        dispatch(upgrade({
                                            upgradeEventLabel: 'PublishTip:Link',
                                            upgradeType: SubscriptionType.NORMAL
                                        }));
                                        e.preventDefault();
                                    }}
                                    data-testid="pro-tip-link"
                                >
                                    <Msg
                                        k="publish.publishing.learnAboutPremium"
                                        params={{ packageName: intl.msgJoint('msg: common.starter {Starter}') }}
                                    >{`
                                        Learn more about {packageName}
                                    `}</Msg>
                                </a>
                            </div>
                        }
                        {
                            (!showReadMoreTip && isPremiumUser) &&
                            <a
                                className={styles.readMore}
                                onClick={() => dispatch({ type: PUBLISHING_TIP_READ_MORE_CLICKED })}
                                data-testid="premium-read-more-link"
                            >
                                <Msg k="publish.publishing.readMore">Read more</Msg>
                            </a>
                        }
                    </div>
                </div>
                {
                    showReadMoreTip &&
                    <div className={styles.readMoreText} data-testid="premium-read-more-text">
                        <Msg
                            k="publish.publishing.readMoreText"
                            params={{ packageName: premiumPackageNameFromMetadata || intl.msgJoint('msg: common.premium {Premium}') }}
                        >{`
                            {packageName} websites show optimal image resolutions and load faster due to a more advanced publication. You can continue working while publishing.
                        `}</Msg>
                    </div>
                }
                <div
                    className={styles.close}
                    onClick={() => dispatch({ type: CLOSE_PUBLISHING_PROGRESS_POPUP })}
                    data-testid="close-button"
                >
                    <div className={styles.closeIcon} />
                </div>
            </div>
        </div>
    );
};
