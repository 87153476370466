import valueActionType from './valueActionType';
import makeEpic from "../../../../../epics/makeEpic";
import { SITE_SUBSCRIPTION_DATA_LOAD_SUCCESS } from "../../../../App/actionTypes";
import { SubscriptionStatus } from "../../constants";
import {
    checkOnlineShopSetupAction,
    CHECK_ONLINE_SHOP_SETUP_SUCCESS_ACTION
} from '../../../../../../../src/dashboard/src/components/Pages/OnlineShop/actions';
import { PANEL_ITEM_LEFT_MOUSE_DOWN } from '../../../../Panel/actionTypes';
import { isShopRelatedComponent } from '../../../../ComponentTierManager/utils';
import ExtendedPanelVAT from "../../../../Panel/epics/extendedPanel/valueActionType";
import { isEcommerceSubscription, isTrialSubscription } from "../../../../../../../server/shared/accessManager/utils.js";

const defaultState = {
    loading: true,
    status: SubscriptionStatus.NONE,
    onlineShopSetupApiResponse: null
};
export default makeEpic({
    defaultState,
    valueActionType,
    updaters: [
        {
            conditions: [
                SITE_SUBSCRIPTION_DATA_LOAD_SUCCESS
            ],
            reducer: ({ state, values: [{ subscriptionType }] }) => {
                const actionToDispatch = !isTrialSubscription(subscriptionType) ? checkOnlineShopSetupAction() : null;
                let newStatus = state.status;
                if (isEcommerceSubscription(subscriptionType)) {
                    newStatus = SubscriptionStatus.PAID;
                }
                return {
                    state: {
                        ...state,
                        status: newStatus,
                        loading: false,
                    },
                    actionToDispatch
                };
            }
        },
        {
            conditions: [
                ExtendedPanelVAT
            ],
            reducer: ({ state, values: [{ itemKind, openedOnMouseDown }], sourceAction: { type } }) => {
                if (isShopRelatedComponent(itemKind) && openedOnMouseDown && type === PANEL_ITEM_LEFT_MOUSE_DOWN) {
                    return {
                        state: { ...state, loading: true },
                        actionToDispatch: checkOnlineShopSetupAction()
                    };
                }
                return { state };
            }
        },
        {
            conditions: [
                CHECK_ONLINE_SHOP_SETUP_SUCCESS_ACTION
            ],
            reducer: ({ state, values: [response] }) => {
                return {
                    state: {
                        ...state,
                        loading: false,
                        onlineShopSetupApiResponse: response
                    }
                };
            }
        }
    ]
});
