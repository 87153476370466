import type { HtmlWriterData, Element, Style } from '../../flowTypes';
import { linkMapper } from '../mappers/index';
import { spliceOverToCombinedList, splitElements } from './utils/index';

const HEADING_REGEX = /\[heading.[0-9]+]/;  // NOSONAR

export default (
    component: HtmlWriterData,
    site: Record<string, any>,
    paragraphs: Array<Element>,
    listItems: Array<Element>,
    styles: Array<Style>,
): Array<Element> => {
    let { links: linkStyles } = component;

    // fill required info
    linkStyles = linkStyles.map(linkMapper);
    // split links over paragraphs and lists
    // @ts-ignore
    linkStyles = spliceOverToCombinedList(linkStyles, [...paragraphs, ...listItems]);
    // remove empty links
    linkStyles = linkStyles.filter(style => style.start !== style.end);

    const headingGlobalStyles = styles.filter(style => !!style.globalName && HEADING_REGEX.test(style.globalName));
    const links = linkStyles
        // @ts-ignore
        .reduce((links, link) => [...links, ...splitElements(link)(link, headingGlobalStyles)], [] as Element[]); // NOSONAR

    return links;
};
