import { normalizeLabel } from "./helpers";

type TypoInput = {
    inpStr: string,
    label: string
};

type MatchResult = {
    score: number,
    indices: Array<number>
};

type FinalScore = {
    score: number,
    indices: Array<Array<number>>
};

type Options = {
    value: string,
    label: string
}[];

type ScoreData = {
    labelWord: string,
    inpWord: string,
    score: number,
    indices: Array<number>
}

type ScoreArr = Array<ScoreData>;

type WebsiteCategorySynonyms = {
    [value: string] : {
        synonyms: Array<string>,
        relatedGmbKeys: Array<string>
    }
};

type SearchInput = {
    options: Options,
    inputValue: string,
    websiteCategorySynonyms: WebsiteCategorySynonyms
};

type SearchResults = Array<{ value: string, indices: Array<Array<number>> }>;

const maxMatches = 50;

const matchConsonents = ({ inpStr, label }: TypoInput): MatchResult => {
    // Vowels is specific for english, for other languages,
    // it will just check the sequence of characters
    const vowels = "aeiou";
    let lastIdx = -1, score = 0, i = 0, indices: Array<number> = [];
    let matchingCharsCount = 0;

    // get input string as characters object
    let inpObj = {};
    for (const char of inpStr) {
        if (char !== " ") {
            inpObj[char] = true;
        }
    }

    // get label string as characters object
    let labelObj = {};
    for (const char of label) {
        if (inpObj[char]) {
            if (!labelObj[char]) {
                labelObj[char] = [];
            }
            labelObj[char].push(i);
            matchingCharsCount++;
        }
    }
    if (matchingCharsCount < 2) {
        return { score: 0, indices: [] };
    }

    if (label[0] === inpStr[0]) {
        score += 300;
        lastIdx = 0;
        i++;
        indices.push(0);
    }
    let gap = 0;
    for (; i < inpStr.length; i++) {
        if (vowels.includes(inpStr[i])) {
            gap++;
            continue;
        }
        if (!labelObj[inpStr[i]]) {
            break;
        }

        const nextIdx: number = labelObj[inpStr[i]].find((j: number) => (j > lastIdx));// eslint-disable-line no-loop-func
        if (!nextIdx) {
            break;
        }
        indices.push(nextIdx);
        if (nextIdx - lastIdx - 1 === gap) {
            score += 200;
        } else {
            score += (100 - (nextIdx - lastIdx - 1));
        }
        gap = 0;

        lastIdx = nextIdx;
    }

    return { score, indices };
};

const getScorePerWord = ({ inpStr, label }: TypoInput): MatchResult => {
    let
        c = 0,
        score = 0,
        lastMatchIndex = 0,
        vowels = "aeiou",
        charMatchCount = 0,
        indices: Array<number> = [];
    let index = label.indexOf(inpStr);
    if (index !== -1) {
        for (let i = index; i < index + inpStr.length; i++) {
            indices.push(i);
        }
        return { score: (inpStr.length * 200) + (index === 0 ? 100 : -index), indices };
    }
    // eslint-disable-next-line unicorn/no-for-loop
    for (let i = 0; i < inpStr.length; i++) {
        if (i - lastMatchIndex > 3) break;
        if (label[c] === inpStr[i]) {
            if (i === 0 && c === 0) {
                score += 300;
            } else {
                score += 200;
            }

            indices.push(c);
            c++;
            lastMatchIndex = i;
            charMatchCount++;
            continue;
        }
        if (label[c + 1] === inpStr[i]) {
            score += (100 - 1);
            indices.push(c + 1);
            c = c + 2;
            lastMatchIndex = i;
            charMatchCount++;
            continue;
        }
        if (!vowels.includes(inpStr[i])) {
            break;
        }
    }
    if (charMatchCount !== inpStr.length) {
        let result = matchConsonents({ inpStr, label });
        if (result.score > score) {
            return result;
        }
    }

    return { score, indices };
};

const getScore = ({ inpStr, label }: TypoInput, matchPercentRequired?: boolean): FinalScore => {
    let labelParts = label.split(" "),
        inpStrParts = inpStr.split(" "),
        score = 0,
        alreadyScored = {},
        scoreArr: ScoreArr = [],
        inpStrPartsMap = {};

    inpStrParts.forEach(inpWord => {
        inpStrPartsMap[inpWord] = true;
        for (const labelWord of labelParts) {
            if (alreadyScored[labelWord]) continue;
            let result: MatchResult;
            if (labelWord === inpWord) {
                let indices: Array<number> = [];
                for (let j = 0; j < labelWord.length; j++) {
                    indices.push(j);
                }
                result = { score: labelWord.length * 300, indices };
                alreadyScored[inpWord] = true;
            } else {
                result = getScorePerWord({ label: labelWord, inpStr: inpWord });
            }
            scoreArr.push({ labelWord, inpWord, score: result.score, indices: result.indices });
        }
    });

    scoreArr = scoreArr.sort((a, b) => b.score - a.score);
    scoreArr = scoreArr.filter(item => {
        if (inpStrPartsMap[item.inpWord]) {
            score += item.score;
            delete inpStrPartsMap[item.inpWord];
            return true;
        }
        return false;
    });
    let indices: Array<Array<number>> = [], idxBase = 0, last: Array<number> = [], matchCount = 0;
    labelParts.forEach(labelWordInp => {
        let scoreItem = scoreArr.find(item => item.labelWord === labelWordInp);
        if (scoreItem) {
            if (labelWordInp.includes(scoreItem.inpWord) || scoreItem.indices.length >= labelWordInp.length * 0.5) {
                scoreItem.indices.forEach(idx => {
                    matchCount++;
                    let index = idx + idxBase;
                    if (!last.length || index > last[1] + 1) {
                        last = [index, index];
                        indices.push(last);
                        return;
                    }
                    if (last[1] + 1 === index) {
                        last[1] = index;
                    }
                });
            }
        }

        idxBase += labelWordInp.length + 1;
    });
    //Add to the result if atleast 30% of the chars matches
    if (matchPercentRequired) {
        return { score: matchCount >= inpStr.length * 0.3 ? score : 0, indices: matchCount >= inpStr.length * 0.5 ? indices : [] };
    }
    return { score, indices };
};

export const searchInWebsiteCategories = ({
    options,
    inputValue,
    websiteCategorySynonyms
}: SearchInput): SearchResults => {
    let inpStr = normalizeLabel(inputValue.trim()),
        exactMatch: Array<string> = [],
        allWordsMatch: Array<string> = [],
        someWordsMatch = {},
        matchAtWordStarting = {},
        matchAtMiddleWordEnding = {},
        matchAtLastWordEnding = {},
        matchAtRandomIndex = {},
        exactMatchInSynonyms: Array<string> = [],
        wordsMatchInSynonyms = {},
        partlyMatchingSynonyms = {},
        relatedCategories: Array<string> = [],
        fuzzyMatches = {},
        wcMap = {};
    if (!inpStr.length) return [];

    options.forEach((option) => {
        const { value } = option;
        wcMap[value] = normalizeLabel(option.label);

        const label = wcMap[value];

        // exact match
        if (label === inpStr) {
            exactMatch.push(value);
            if (websiteCategorySynonyms[value]) {
                relatedCategories.push(...websiteCategorySynonyms[value].relatedGmbKeys);
            }
            return;
        }

        // all words match - sequence does not matter
        let
            inpStrParts = inpStr.split(" "),
            labelParts = label.split(" "),
            matchingWordsCount = inpStrParts.filter(
                (word: string) => labelParts.find((wordInLabel: string) => wordInLabel === word)
            ).length;
        if (matchingWordsCount === inpStrParts.length && matchingWordsCount === labelParts.length) {
            allWordsMatch.push(value);
            return;
        }

        // partial match
        const index = label.indexOf(inpStr);
        if (index !== -1) {
            // partial word match at word boundaries
            // if (!inpStr.includes(" ")) {
            if (index === 0 || label[index - 1] === " ") { // beginning of any word
                if (!matchAtWordStarting[index]) {
                    matchAtWordStarting[index] = [];
                }
                matchAtWordStarting[index].push(value);
                return;
            }

            if (label[index + inpStr.length] === " ") { // end of middle word
                if (!matchAtMiddleWordEnding[index]) {
                    matchAtMiddleWordEnding[index] = [];
                }
                matchAtMiddleWordEnding[index].push(value);
                return;
            }

            if (label.length === index + inpStr.length) { // end of last word
                if (!matchAtLastWordEnding[index]) {
                    matchAtLastWordEnding[index] = [];
                }
                matchAtLastWordEnding[index].push(value);
                return;
            }
        }

        //Few words matching
        if (matchingWordsCount > 0) {
            let wordMatchResult = getScore({ label, inpStr });
            if (!someWordsMatch[wordMatchResult.score]) {
                someWordsMatch[wordMatchResult.score] = [];
            }
            someWordsMatch[wordMatchResult.score].push({ value, indices: wordMatchResult.indices });
            return;
        }

        // synonyms
        if (websiteCategorySynonyms[value]) {
            let added = false;
            for (const synonym of websiteCategorySynonyms[value].synonyms) {
                // exact match with synonym
                if (inpStr === synonym) {
                    exactMatchInSynonyms.push(value);
                    relatedCategories.push(...websiteCategorySynonyms[value].relatedGmbKeys);
                    return;
                }

                // Match at the beginning of any word in any synonym
                let sIndex = synonym.indexOf(inpStr);

                if (sIndex === 0 || synonym[sIndex - 1] === " ") {
                    if (!partlyMatchingSynonyms[sIndex]) {
                        partlyMatchingSynonyms[sIndex] = [];
                    }
                    partlyMatchingSynonyms[sIndex].push(value);
                    return;
                }
            }
            if (added) {
                return;
            }
        }

        // match at random index
        if (index !== -1) {
            if (!matchAtRandomIndex[index]) {
                matchAtRandomIndex[index] = [];
            }
            matchAtRandomIndex[index].push(value);
            return;
        }

        // Typo and fuzzy search
        let fuzzySearchResult = getScore({ label, inpStr }, true);
        if (fuzzySearchResult.score >= 300) {
            if (!fuzzyMatches[fuzzySearchResult.score]) {
                fuzzyMatches[fuzzySearchResult.score] = [];
            }
            fuzzyMatches[fuzzySearchResult.score].push({ value, indices: fuzzySearchResult.indices });
        }
    });
    let alreadyAdded = {}, result: SearchResults = [];

    // Exact match
    if (exactMatch.length) {
        exactMatch.forEach(value => {
            if (!alreadyAdded[value] && result.length <= maxMatches) {
                result.push({ value, indices: [[0, wcMap[value].length - 1]] });
                alreadyAdded[value] = true;
            }
        });
    }

    // All words match(sequence does not matter)
    if (allWordsMatch.length) {
        allWordsMatch.forEach(value => {
            if (!alreadyAdded[value] && result.length <= maxMatches) {
                result.push({ value, indices: [[0, wcMap[value].length - 1]] });
                alreadyAdded[value] = true;
            }
        });
    }

    // match at word boundaries
    // Starting
    let keys = Object.keys(matchAtWordStarting).sort((a, b) => parseInt(a, 10) - parseInt(b, 10));
    keys.forEach(k => {
        matchAtWordStarting[k].forEach((value: string) => {
            if (!alreadyAdded[value] && result.length <= maxMatches) {
                const idx = parseInt(k, 10);
                result.push({ value, indices: [[idx, idx + inpStr.length - 1]] });
                alreadyAdded[value] = true;
            }
        });
    });

    // middle word ending
    keys = Object.keys(matchAtMiddleWordEnding).sort((a, b) => parseInt(a, 10) - parseInt(b, 10));
    keys.forEach(k => {
        matchAtMiddleWordEnding[k].forEach(value => {
            if (!alreadyAdded[value] && result.length <= maxMatches) {
                const idx = parseInt(k, 10);
                result.push({ value, indices: [[idx, idx + inpStr.length - 1]] });
                alreadyAdded[value] = true;
            }
        });
    });

    // lastword ending
    keys = Object.keys(matchAtLastWordEnding).sort((a, b) => parseInt(a, 10) - parseInt(b, 10));
    keys.forEach(k => {
        matchAtLastWordEnding[k].forEach(value => {
            if (!alreadyAdded[value] && result.length <= maxMatches) {
                const idx = parseInt(k, 10);
                result.push({ value, indices: [[idx, idx + inpStr.length - 1]] });
                alreadyAdded[value] = true;
            }
        });
    });

    // any word match
    keys = Object.keys(someWordsMatch).sort((a, b) => parseInt(b, 10) - parseInt(a, 10));
    keys.forEach(k => {
        someWordsMatch[k].forEach(({ value, indices }) => {
            if (!alreadyAdded[value] && result.length <= maxMatches) {
                result.push({ value, indices });
                alreadyAdded[value] = true;
            }
        });
    });

    // exact match found in Synonyms
    if (exactMatchInSynonyms.length) {
        exactMatchInSynonyms.forEach(value => {
            if (!alreadyAdded[value] && result.length <= maxMatches) {
                result.push({ value, indices: [] });
                alreadyAdded[value] = true;
            }
        });
    }

    // any word match in synonyms
    keys = Object.keys(wordsMatchInSynonyms).sort((a, b) => parseInt(b, 10) - parseInt(a, 10));
    keys.forEach(k => {
        wordsMatchInSynonyms[k].forEach(value => {
            if (!alreadyAdded[value] && result.length <= maxMatches) {
                result.push({ value, indices: [] });
                alreadyAdded[value] = true;
            }
        });
    });

    // any word starting match in synonyms
    keys = Object.keys(partlyMatchingSynonyms).sort((a, b) => parseInt(b, 10) - parseInt(a, 10));
    keys.forEach(k => {
        partlyMatchingSynonyms[k].forEach(value => {
            if (!alreadyAdded[value] && result.length <= maxMatches) {
                result.push({ value, indices: [] });
                alreadyAdded[value] = true;
            }
        });
    });

    // match at any random index(position)
    keys = Object.keys(matchAtRandomIndex).sort((a, b) => parseInt(a, 10) - parseInt(b, 10));
    keys.forEach(k => {
        matchAtRandomIndex[k].forEach(value => {
            if (!alreadyAdded[value] && result.length <= maxMatches) {
                const idx = parseInt(k, 10);
                result.push({ value, indices: [[idx, idx + inpStr.length - 1]] });
                alreadyAdded[value] = true;
            }
        });
    });

    // Related categories - comes from both exact match key or word match in synonyms
    if (relatedCategories.length) {
        relatedCategories.forEach(value => {
            if (!alreadyAdded[value] && result.length <= maxMatches) {
                result.push({ value, indices: [] });
                alreadyAdded[value] = true;
            }
        });
    }

    // Random matches of characters with sequence of characters as priority - this is for typos and fuzzy search
    keys = Object.keys(fuzzyMatches).sort((a, b) => parseInt(b, 10) - parseInt(a, 10));
    keys.forEach(k => {
        fuzzyMatches[k].forEach(({ value, indices }) => {
            if (!alreadyAdded[value] && result.length <= maxMatches) {
                result.push({ value, indices });
                alreadyAdded[value] = true;
            }
        });
    });

    return result;
};
