import makeEpic from '../../../../../epics/makeEpic';
import valueActionType from './valueActionType';
import {
    PRODUCT_WIDGET_UPDATE_PRODUCT_SETUP_WIDGET_METHOD,
    PRODUCT_WIDGET_UPDATE_PRODUCT_SETUP_INSERTER_METHOD,
    PRODUCT_WIDGET_SHOW_PRODUCT_WIDGET_INSERTER_TOOLTIP,
    PRODUCT_WIDGET_SHOW_MANAGE_SHOP_INSERTER_TOOLTIP,
    PRODUCT_WIDGET_ONBOARDING_SUCCESS,
    PRODUCT_WIDGET_UPDATE_SHOP_SETUP_STATUS,
} from '../../actionTypes';

import {
    FEATURED_PRODUCTS_UPDATE_SHOP_SETUP_STATUS
} from '../../../FeaturedProducts/actionTypes';

import {
    SHOW_TOOLTIP_FOR_SINGLE_PRODUCT_COMPONENT
} from "../../../../Tooltip/actionTypes";

import { openDialog } from '../../../../App/actionCreators/index';
import ProductWidgetOnboardingSuccessDialogId from '../../OnboardingSuccessDialog/dialogId';

export const SetupStatusValues = {
    INITIAL: 'initial',
    STARTED: 'started',
    COMPLETED: 'completed'
} as const;

export const SetupMethod = {
    NONE: 'none',
    INSERTER: 'inserter-panel',
    PRODUCT_WIDGET: 'product-widget'
} as const;

type SETUP_STATUS = 'initial' | 'started' | 'completed';
type SETUP_METHOD = 'none' | 'inserter-panel' | 'product-widget';

const defaultState: {
    setupStatus: SETUP_STATUS,
    setupMethod: SETUP_METHOD,
    componentId: string
} = {
    setupStatus: SetupStatusValues.INITIAL,
    setupMethod: SetupMethod.NONE,
    componentId: '' // used only if the setup is done by product-widget method
};

export default makeEpic({
    defaultState,
    valueActionType,
    updaters: [
        {
            conditions: [
                PRODUCT_WIDGET_UPDATE_PRODUCT_SETUP_WIDGET_METHOD
            ],
            reducer: ({ state, values: [{ componentId }] }) => {
                return {
                    state: {
                        ...state,
                        componentId,
                        setupMethod: SetupMethod.PRODUCT_WIDGET,
                        setupStatus: SetupStatusValues.STARTED
                    }
                };
            }
        },
        {
            conditions: [
                PRODUCT_WIDGET_UPDATE_PRODUCT_SETUP_INSERTER_METHOD
            ],
            reducer: ({ state }) => {
                return {
                    state: {
                        ...state,
                        setupMethod: SetupMethod.INSERTER,
                        setupStatus: SetupStatusValues.STARTED
                    }
                };
            }
        },
        {
            conditions: [
                PRODUCT_WIDGET_SHOW_MANAGE_SHOP_INSERTER_TOOLTIP,
            ],
            reducer: ({ state, dispatchAsync, values: [tooltipPayload] }) => {
                if (state &&
                        state.componentId &&
                        state.setupMethod === SetupMethod.PRODUCT_WIDGET &&
                        state.setupStatus === SetupStatusValues.COMPLETED) {
                    const actionToDispatch: { type: string, payload: Record<string, any> } = {
                        type: SHOW_TOOLTIP_FOR_SINGLE_PRODUCT_COMPONENT,
                        payload: tooltipPayload
                    };
                    setTimeout(() => {
                        dispatchAsync(actionToDispatch);
                    }, 100);
                    return {
                        state: { ...state, setupStatus: SetupStatusValues.INITIAL },
                    };
                }
                return { state };
            }
        },
        {
            conditions: [
                PRODUCT_WIDGET_SHOW_PRODUCT_WIDGET_INSERTER_TOOLTIP,
            ],
            reducer: ({ state, dispatchAsync, values: [tooltipPayload] }) => {
                if (state &&
                        state.setupMethod === SetupMethod.INSERTER &&
                        state.setupStatus === SetupStatusValues.COMPLETED) {
                    const actionToDispatch: { type: string, payload: Record<string, any> } = {
                        type: SHOW_TOOLTIP_FOR_SINGLE_PRODUCT_COMPONENT,
                        payload: tooltipPayload
                    };
                    setTimeout(() => {
                        dispatchAsync(actionToDispatch);
                    }, 100);
                    return {
                        state: { ...state, setupStatus: SetupStatusValues.INITIAL },
                    };
                }
                return { state };
            }
        },
        {
            conditions: [
                PRODUCT_WIDGET_ONBOARDING_SUCCESS,
            ],
            reducer: ({ state, values: [{ setupStatus }] }) => {
                if (state && setupStatus) {
                    return ({
                        state: {
                            ...state,
                            setupStatus
                        },
                        actionToDispatch: openDialog(ProductWidgetOnboardingSuccessDialogId)
                    });
                }
                return { state };
            }
        },
        {
            conditions: [
                PRODUCT_WIDGET_UPDATE_SHOP_SETUP_STATUS
            ],
            reducer: ({ state }) => {
                if (state.componentId) {
                    return {
                        state,
                        actionToDispatch: {
                            type: FEATURED_PRODUCTS_UPDATE_SHOP_SETUP_STATUS,
                            payload: { componentId: state.componentId, isWebshopSetupDone: true }
                        }
                    };
                }
                return { state };
            }
        },
    ]
});
