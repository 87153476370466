import type { AppStore } from "../../../../redux/modules/flowTypes";
import {
    openShutterstockUploadingDialogAction,
    closeShutterstockUploadingDialogAction,
    storeSuccessfulshutterstockPartialDownloadsAction,
    openShutterstockPartialDownloadsDialogAction,
    openMaxDownloadsPerRequestDialogAction
} from "../../shutterstock/actions";
import { shutterstockSelectedImagesAppSel,
    shutterstockSelectedImagesIdsAppSel,
    shutterstockSuccessfulPartialDownloadsAppSel,
    shutterstockInitializedAppSel,
    shutterstockImagesPageLoadingAppSel,
    shutterstockQueryAppSel,
    shutterstockDownloadedImagesLoadingAppSel,
    shutterstockImagesLoadingAppSel } from "../../shutterstock/selectors";
import { subscriptionTypeStateSel } from "../../../App/epics/subscriptionData/selectors";
import { isMultiSelectFcAppSel,
    fcDialogActiveTabIdAppSel } from "../../../../redux/modules/children/fileChooser/selectors";
import { sstockQuotaAppStateSel } from "../../../App/epics/appConfig/selectors";
import { SstockErrorReason, ASSET_VIDEO_DAL_TYPE, DalErrorName } from "../../../../../dal/constants";
import type { PostSstockImageDownloadsResponse, SstockImageDownload } from "../../../../../dal/flowTypes";
import { makeUuid } from "../../../../../utils/makeUuid";
import { ASSET_IMAGE_DAL_TYPE } from "../../../../../dal/index";
import type { ShutterstockSelectedImageItem } from "../../shutterstock/types";
import { sendUnsplashCrashReport } from "../crashReport";
import { closeDialog, openDialog } from "../../../App/actionCreators/index";
import { fcResetAction } from "../../../../redux/modules/children/fileChooser/actionCreators/index";
import { makeActionForwardToSelectedComponent } from "../../../../redux/forwardTo";
import { openApiErrorHandlerDialog } from '../../../../redux/middleware/api/errorHandler/actions';
import {
    DOWNLOAD_UNSPLASH_IMAGES_SUCCESS_ACTION,
    UNSPLASH_SAVE_ACTION,
    DOWNLOAD_UNSPLASH_IMAGES_FAILURE_ACTION,
    DOWNLOAD_UNSPLASH_IMAGES_REQUEST_ACTION,
    UNSPLASH_GRID_SCROLLED_TO_BOTTOM_ACTION,
    DOWNLOAD_FREE_ONE_COM_VIDEOS_ACTION,
    initializeUnsplashAction,
    getUnsplashCategoriesAction,
    searchUnsplashImagesAction,
    loadUnsplashImagesPageAction,
    SELECT_UNSPLASH_CATEGORY_ACTION,
    UNSPLASH_CLEAN_SEARCH_ACTION,
    UNSPLASH_LANDSCAPE_ORIENTATION_CLICKED_ACTION,
    UNSPLASH_PORTRAIT_ORIENTATION_CLICKED_ACTION,
    UNSPLASH_SEARCH_CLICK_ACTION,
    UNSPLASH_SEARCH_ENTER_PRESS_ACTION,
    SELECT_UNSPLASH_IMAGE_ACTION,
    downloadUnsplashImagesApiActionAction,
    getFreeOneComVideosAction
} from "../actions";
import { SWITCH_FM_TAB_ACTION } from "../../dialog/actions";
import { FmTabId } from "../../dialog/constants";
import { Lit } from "../../../../lit";
import { unsplashQueryAppSel } from "../selectors";
import { isUnknownSubscription } from "../../../../../../server/shared/accessManager/utils.js";
import { WebspaceErrors } from "../../../../../../server/shared/webspace/WebspaceErrors.js";
import { InsufficientStorageErrorDialog } from '../../../../view/common/FileChooser/dialogIds';
import { FREE_ONE_COM_SAVE } from "../../../../redux/modules/children/fileChooser/actionTypes";
import uploadToWebspaceFromOneStoreAction from "../../../App/actionCreators/uploadToWebspaceFromOneStore";
import uploadFromUrlAction from "../../../App/actionCreators/uploadFromUrl";
import * as ActionTypes from "../../../../redux/modules/children/fileChooser/actionTypes";

// TODO: refactor
let
    isMultiSelect,
    onSaveAction;

const closeDialogAndSelectComponent = (storeDispatch, assets) => {
    const
        saveAction = makeActionForwardToSelectedComponent({
            type: onSaveAction,
            payload: isMultiSelect ? { assets } : { asset: assets[0] }
        });

    isMultiSelect = null;
    onSaveAction = null;

    storeDispatch(closeDialog());
    storeDispatch(fcResetAction());
    return storeDispatch(saveAction);
};

const completeDownload = ({ selectedImages, downloads, storeDispatch }) => {
    const assets = downloads.map((d: SstockImageDownload) => {
        const anImage: null | undefined | ShutterstockSelectedImageItem = selectedImages.find(img => img.imageId === d.imageId);
        if (!anImage) {
            throw new Error(`Could not find downloaded image ${d.imageId} in state`);
        }

        return {
            id: makeUuid(),
            type: ASSET_IMAGE_DAL_TYPE,
            etag: d.webspaceEtag,
            url: d.webspaceUrl,
            contentType: d.contentType,
            filesize: anImage.fileSize,
            alpha: null,
            bpp: null,
            width: d.imageDimensions.width,
            height: d.imageDimensions.height,
            animated: false,
            recommendedFormat: null,
        };
    });

    closeDialogAndSelectComponent(storeDispatch, assets);
};

export const unsplashMiddleware = (store: AppStore) => (next: Dispatch) => (action: Action) => {
    if (action.type === SWITCH_FM_TAB_ACTION) {
        const { tabId, isVideoFileType } = action.payload;

        if (tabId !== FmTabId.UNSPLASH && tabId !== FmTabId.FREE_ONECOM_IMAGES) {
            return next(action);
        }

        const
            state = store.getState(),
            query = shutterstockQueryAppSel(tabId)(state),
            initialized = shutterstockInitializedAppSel(tabId)(state);
            // isDemo = isDemoSubscriptionTypeAppSel(state);

        if (initialized) {
            return next(action);
        }

        store.dispatch(initializeUnsplashAction());
        store.dispatch(getUnsplashCategoriesAction());
        if (tabId === "freeOnecom" && isVideoFileType) {
            store.dispatch(getFreeOneComVideosAction());
        } else {
            store.dispatch(searchUnsplashImagesAction(query));
        }
        // if (tabId === ShutterstockKind.FREE) {
        //     store.dispatch(getShutterstockDownloadedImagesAction(tabId));
        // }
        //
        // if (tabId === ShutterstockKind.PAID && !isDemo) {
        //     store.dispatch(getPaidShutterstockImagePriceAction());
        // }

        return next(action);
    }
    if (action.type === FREE_ONE_COM_SAVE) {
        ({ isMultiSelect, onSaveAction } = action.payload);

        const res = action.payload.selection[0];

        store.dispatch({
            type: DOWNLOAD_FREE_ONE_COM_VIDEOS_ACTION,
            payload: {
                videoUrl: res.videoUrl,
                thumbnailUrl: res.thumbnailUrl,
                absoluteVideoPath: res._getFullPath(),
                absoluteThumbnailPath: res._getFullPath().slice(0, -3) + 'png',

            }
        });

        return next(action);
    }
    if (action.type === UNSPLASH_SAVE_ACTION) {
        ({ isMultiSelect, onSaveAction } = action.payload);

        const
            state = store.getState(),
            activeTabId = fcDialogActiveTabIdAppSel(state),
            requestImages = shutterstockSelectedImagesAppSel(activeTabId)(state).map(({ imageId, description }) => ({
                imageId,
                description
            })),
            request = {
                images: requestImages
            };

        store.dispatch(downloadUnsplashImagesApiActionAction(request));

        return next(action);
    }

    if (action.type === UNSPLASH_GRID_SCROLLED_TO_BOTTOM_ACTION) {
        const
            state = store.getState(),
            tabId = fcDialogActiveTabIdAppSel(state),
            imagesPageLoading = shutterstockImagesPageLoadingAppSel(tabId)(state);

        // load page after page
        if (imagesPageLoading) return next(action);

        const
            search = shutterstockQueryAppSel(tabId)(state),
            query = {
                ...search,
                [Lit.page]: (search.page || 0) + 1
            };

        store.dispatch(loadUnsplashImagesPageAction(query));

        return next(action);
    }

    if (action.type === DOWNLOAD_FREE_ONE_COM_VIDEOS_ACTION) {
        const { videoUrl, thumbnailUrl, absoluteVideoPath, absoluteThumbnailPath } = action.payload;
        store.dispatch(openShutterstockUploadingDialogAction({ plural: false, videos: true }));
        store.dispatch(uploadToWebspaceFromOneStoreAction(
            videoUrl.split("/").pop(),
            absoluteVideoPath,
            ActionTypes.FC_FILES_UPLOAD_REQUEST_URL_ACTION_TYPES
        ));
        store.dispatch(uploadFromUrlAction(thumbnailUrl, absoluteThumbnailPath, ActionTypes.FC_URL_UPLOAD_ACTION_TYPES));
    }

    if (action.type === ActionTypes.FC_FILES_UPLOAD_REQUEST_URL_SUCCESS) {
        if (action.endpointParams[0].substr(-3) === "mp4") {
            const assetUrlArr = action.endpointParams[0].split("/"),
                assetName = assetUrlArr[assetUrlArr.length - 1];
            store.dispatch(closeShutterstockUploadingDialogAction());

            const asset = {
                id: makeUuid(),
                type: ASSET_VIDEO_DAL_TYPE,
                etag: '',
                url: "webspace:/onewebmedia/" + assetName,
                contentType: "video/mp4",
                filesize: action.payload,
                alpha: null,
                bpp: null,
                animated: false,
                recommendedFormat: null
            };

            return closeDialogAndSelectComponent(store.dispatch, [asset]);
        }
    }

    if (action.type === ActionTypes.FC_FILES_UPLOAD_REQUEST_URL_FAILURE) {
        const assetUrlArr = action.endpointParams[0].split("/"),
            assetName = assetUrlArr[assetUrlArr.length - 1];
        store.dispatch(closeShutterstockUploadingDialogAction());
        if (action.payload.error === DalErrorName.INSUFFICIENT_STORAGE) {
            openDialog(InsufficientStorageErrorDialog, { fileNames: [assetName] });
        } else if (action.endpointParams[0].substr(-3) === "mp4") {
            store.dispatch(openApiErrorHandlerDialog({
                messages: 'msg: common.downloadingFailed {Sorry, downloading failed.}',
            }));
        }
    }

    if (action.type === DOWNLOAD_UNSPLASH_IMAGES_REQUEST_ACTION) {
        const
            // @ts-ignore
            selectedImageIds = shutterstockSelectedImagesIdsAppSel(store.getState()),
            plural = selectedImageIds.length > 1;

        store.dispatch(openShutterstockUploadingDialogAction({ plural, videos: false }));
    }

    if (action.type === DOWNLOAD_UNSPLASH_IMAGES_SUCCESS_ACTION) {
        store.dispatch(closeShutterstockUploadingDialogAction());

        const
            state = store.getState(),
            activeTabId = fcDialogActiveTabIdAppSel(state),
            res: PostSstockImageDownloadsResponse = action.payload,
            { downloads } = res,
            appState = store.getState(),
            successfulPartialDownloads = shutterstockSuccessfulPartialDownloadsAppSel(activeTabId)(appState),
            allDownloads = [...downloads, ...successfulPartialDownloads];

        return completeDownload({
            selectedImages: shutterstockSelectedImagesAppSel(activeTabId)(appState),
            downloads: allDownloads,
            storeDispatch: store.dispatch,
        });
    }

    if (action.type === DOWNLOAD_UNSPLASH_IMAGES_FAILURE_ACTION) {
        store.dispatch(closeShutterstockUploadingDialogAction());

        const { payload: { error } } = action;
        const activeTabId = fcDialogActiveTabIdAppSel(store.getState());

        sendUnsplashCrashReport({
            message: action.payload,
            additionalInfo: {
                actionEndpointParams: action.endpointParams,
                actionApiTag: action.apiTag,
            },
        });

        if (error === SstockErrorReason.MAX_DOWNLOADS_PER_REQUEST) {
            const quotaConfig = sstockQuotaAppStateSel(store.getState());
            store.dispatch(openMaxDownloadsPerRequestDialogAction(activeTabId, quotaConfig.perRequest));
        } else if (error === SstockErrorReason.PARTIAL_DOWNLOAD) {
            const
                { payload: { message: downloads } } = action,
                appState = store.getState(),
                isMultiSelect = isMultiSelectFcAppSel(appState),
                { insufStorErrRes: insufficientStorageErrorImages, retrErrorRes: retriableErrorImages } =
                    shutterstockSelectedImagesAppSel(activeTabId)(appState).reduce(({ insufStorErrRes, retrErrorRes }, img) => {
                        const failure = downloads.find(
                            d => d.imageId === img.imageId && !d.ok,
                        );
                        if (failure) {
                            (failure.webspaceUploadError === WebspaceErrors.INSUFFICIENT_STORAGE ?
                                insufStorErrRes : retrErrorRes).push(img);
                        }
                        return { insufStorErrRes, retrErrorRes };
                    }, { insufStorErrRes: [] as any[], retrErrorRes: [] as any[] }); // NOSONAR

            store.dispatch(storeSuccessfulshutterstockPartialDownloadsAction(activeTabId, downloads));
            if (insufficientStorageErrorImages.length) {
                store.dispatch(openDialog(InsufficientStorageErrorDialog,
                    { fileNames: insufficientStorageErrorImages.map((resource) => resource.description) }));
            }
            if (retriableErrorImages.length) {
                store.dispatch(openShutterstockPartialDownloadsDialogAction({
                    kind: activeTabId,
                    multi: isMultiSelect,
                    images: retriableErrorImages,
                }));
            }
        } else {
            store.dispatch(openApiErrorHandlerDialog({
                messages: 'msg: common.downloadingFailed {Sorry, downloading failed.}',
            }));
        }
    }

    if (
        action.type === UNSPLASH_SEARCH_CLICK_ACTION ||
        action.type === UNSPLASH_SEARCH_ENTER_PRESS_ACTION ||
        action.type === UNSPLASH_PORTRAIT_ORIENTATION_CLICKED_ACTION ||
        action.type === UNSPLASH_LANDSCAPE_ORIENTATION_CLICKED_ACTION ||
        action.type === SELECT_UNSPLASH_CATEGORY_ACTION ||
        action.type === UNSPLASH_CLEAN_SEARCH_ACTION
    ) {
        const
            state = store.getState(),
            activeTabId = fcDialogActiveTabIdAppSel(state),
            nextResult = next(action),
            isCategoryClicked = action.type === SELECT_UNSPLASH_CATEGORY_ACTION,
            search = unsplashQueryAppSel(activeTabId, isCategoryClicked)(store.getState()),
            query = {
                ...search,
                [Lit.page]: 1
            };

        store.dispatch(searchUnsplashImagesAction(query));

        return nextResult;
    }
    if (action.type === SELECT_UNSPLASH_IMAGE_ACTION) {
        const
            state = store.getState(),
            activeTabId = fcDialogActiveTabIdAppSel(state),
            imagesLoading = shutterstockImagesLoadingAppSel(activeTabId)(state),
            downloadedImagesLoading = shutterstockDownloadedImagesLoadingAppSel(activeTabId)(state),
            subscription = subscriptionTypeStateSel(state);

        // Handle the mess of "unknown" subscription
        if (isUnknownSubscription(subscription)) {
            return; // eslint-disable-line consistent-return
        }

        if (downloadedImagesLoading || imagesLoading) {
            throw new Error(`No images to select.`);
        }

        return next(action);
    }

    return next(action);
};
