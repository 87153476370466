import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Msg, useIntl } from "../../../../view/intl";
import {
    CLOSE_STICKY_TOOLTIP,
    SHOW_TOOLTIP_FOR_NEW_COMPONENT
} from "../../../Tooltip/stickyTooltip/actionTypes";
import { selectWorkspaceStatus } from "../../../Workspace/epics/status/selectors";
import { READY } from "../../../Workspace/epics/status/types";
import { NewComponentTooltip } from "../../../Tooltip/ids";
import { preferencesSelector } from "../../../Preferences/selectors";
import { savePreferencesAction } from "../../../Preferences/actions";
import { PANEL_ITEM_CLICK, PANEL_ITEM_LEFT_MOUSE_DOWN } from "../../actionTypes";
import { BOOKINGS_TRY_NOW_CLICKED } from "./../../../oneweb/Bookings/actionTypes";
import tooltipConfig from "./config";

import styles from "./styles.css";
import { makeEpicStateSelector } from "../../../../epics/makeEpic";
import { siteDataValueActionType } from "../../../App/epics/siteData/valueActionType";

let tooltipSeen = false;

export const NewComponentTooltipView = () => {
    const intl = useIntl();
    const dispatch = useDispatch();

    return (
        <div className={styles.tooltipContainer}>
            <tooltipConfig.image className={styles.image} data-testid="new-component-image" />

            <div className={styles.header} data-testid="tooltip-header">
                {intl.msgJoint(tooltipConfig.header)}
            </div>
            <div className={styles.description} data-testid="tooltip-description">
                {intl.msgJoint(tooltipConfig.description)}
            </div>

            <div className={styles.buttonsContainer}>
                <button
                    type="button"
                    className={styles.secondaryButton}
                    onClick={() => {
                        dispatch(
                            savePreferencesAction({
                                newComponentTooltipSeenId: Number(tooltipConfig.id)
                            })
                        );
                        dispatch({
                            type: CLOSE_STICKY_TOOLTIP
                        });
                    }}
                    data-testid="try-later-button"
                >
                    <Msg k="common.tryItLater">I’ll try it later</Msg>
                </button>
                <button
                    type="button"
                    className={styles.primaryButton}
                    onClick={() => {
                        dispatch({
                            type: PANEL_ITEM_LEFT_MOUSE_DOWN,
                            payload: { componentKind: tooltipConfig.componentKind }
                        });
                        dispatch({
                            type: PANEL_ITEM_CLICK,
                            payload: { componentKind: tooltipConfig.componentKind }
                        });
                        dispatch({
                            type: BOOKINGS_TRY_NOW_CLICKED
                        });
                        dispatch(
                            savePreferencesAction({
                                newComponentTooltipSeenId: Number(tooltipConfig.id)
                            })
                        );
                    }}
                    data-testid="try-now-button"
                >
                    <Msg k="common.tryItOut">Try it out</Msg>
                </button>
            </div>
        </div>
    );
};

export const useTooltipEffect = (
    tabRef: React.MutableRefObject<HTMLDivElement | null>,
    itemKind: string
) => {
    const dispatch = useDispatch();
    const workspaceStatus = useSelector(selectWorkspaceStatus);
    const { whatsNewPopupSeenId, newComponentTooltipSeenId } = useSelector(preferencesSelector);
    const siteDataSelector = makeEpicStateSelector(siteDataValueActionType);
    const siteData = useSelector(siteDataSelector);
    const pages = siteData?.folder?.items;

    useEffect(() => {
        // only show the tooltip if the user has seen the whats new popup in the previous session
        // the tooltip must be rendered after the workspace is ready, otherwise it will be closed

        if (
            itemKind === tooltipConfig.componentKind &&
            workspaceStatus === READY &&
            (!newComponentTooltipSeenId || newComponentTooltipSeenId < Number(tooltipConfig.id))
        ){
            if (tooltipConfig.disable(pages)) {
                dispatch(
                    savePreferencesAction({
                        newComponentTooltipSeenId: Number(tooltipConfig.id)
                    })
                );
            } else if (
                !tooltipSeen &&
                itemKind === tooltipConfig.componentKind &&
                tabRef.current &&
                whatsNewPopupSeenId && whatsNewPopupSeenId >= Number(tooltipConfig.id)
            ) {
                const inserterTabRect = tabRef.current.getBoundingClientRect();
                const { top, width, height, left } = inserterTabRect;
                tooltipSeen = true;

                dispatch({
                    type: SHOW_TOOLTIP_FOR_NEW_COMPONENT,
                    payload: {
                        // position is where the tooltip arrow should point to
                        // at the right and in the middle of the inserter tab
                        position: { x: left + width, y: top + height / 2 },
                        elementDimensions: { top, width, height, left },
                        viewId: NewComponentTooltip,
                        backgroundColor: "var(--colorLinkWater)",
                        closeIconProps: {
                            size: "13px",
                            positionOffset: "20px"
                        }
                    }
                });
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [workspaceStatus, tabRef]);
};
