import { FrontClient } from "@one.com/event-tracking-front-client";
import { getDAL } from "../../dal";

let singleton: null | FrontClient = null;

export const trackingClientFactory = (trialDomain?: string): FrontClient => {
    const domain = trialDomain || getDAL().getDomain();

    if (!singleton) {
        const serverBaseUrl = `/api/v1/${domain}/track`;
        singleton = new FrontClient({
            serverBaseUrl,
        });
    }
    return singleton;
};
