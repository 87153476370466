import React from 'react';
import makeAppStandardErrorDialog from "../../../App/dialogs/makeAppStandardErrorDialog";
import { PAGE_TREE_PAGE_DELETE_CONFIRM } from "../actionTypes";
import { WarningConfirmationDialog } from '../../../../view/common/dialogs/WarningConfirmationDialog';
import { Msg } from '../../../../view/intl';
import { blogPageType } from '../../../oneweb/Blog/events';

type Props = {
    dispatch: Dispatch;
};

export const DeleteBlogPageDialog = (({ dispatch }: Props) => (
    <WarningConfirmationDialog
        title="msg: blog.deletePage.title {Delete blog?}"
        confirmLabel="msg: delete {Delete}"
        onConfirm={() => dispatch({ type: PAGE_TREE_PAGE_DELETE_CONFIRM, payload: { pageType: blogPageType } })}
        dispatch={dispatch}
    >
        <Msg k="blog.deletePage.description">
            {"The entire page with your blog will be deleted. This action cannot be undone."}
        </Msg>
    </WarningConfirmationDialog>
));

export default makeAppStandardErrorDialog(DeleteBlogPageDialog);
