import * as R from 'ramda';
import type { ComponentsMap, ComponentsIds } from "../../../redux/modules/children/workspace/flowTypes";
import { isSectionKind } from "../componentKinds";
import type { SectionComponent } from "./flowTypes";
import { memoMaxOne } from "../../../../utils/memo";
import type { Attachments } from "../../Workspace/epics/componentAttachements/flowTypes";
import type { AnyComponent } from "../flowTypes";
import { getComponentsMapNoGhosts } from "../Code/getComponentsMapNoGhosts";
import { getTopMostParentId, getAllAttachmentsForCmpIds } from "../../Workspace/epics/componentAttachements/util";
import { makeUuid } from "../../../../utils/makeUuid";
import sectionKind from './kind';
import { MIN_SECTION_HEIGHT, NON_DELETABLE_COMPONENTS, NON_DUPLICATABLE_COMPONENTS } from "./constants";
import { isStickyToHeader } from "../../Workspace/epics/componentsEval/isStickyToHeader";
import { objectsAreEqual } from "../../../utils/objectsDiffChecks";
import defaultSectionStyle from "./defaultSectionStyle";
import { getCmpRect } from "../../Workspace/epics/componentsEval/utils";

type SectionInfo = {
    sections: Array<AnyComponent>,
    sectionIndex: number,
    section: AnyComponent
};

type PrevNextSectionInfoProps = {
    currentSectionIndex: number,
    sections: Array<AnyComponent>,
    sectionIndex?: number,
    section?: AnyComponent
}

type CreateSectionProps = {
    top: number,
    bottom: number,
    inTemplate: boolean,
    orderIndex: number
};

let _prevHeaderId,
    _prevFooterId;

const isSectionComponent = ({ kind }: Record<string, any>) => isSectionKind(kind),

    /**
     * returns all the sections in the page with sorted tops ascending.
     */
    getSectionsOrderdByTop = (componentsMap: ComponentsMap): Array<AnyComponent> =>
        R.pipe(
            R.values,
            R.filter(isSectionComponent),
            R.sortBy(R.prop('top'))
        )(componentsMap),
    isSectionId = (id: string, componentsMap: ComponentsMap) => isSectionComponent(componentsMap[id] || {}),
    isHeaderSection = (component: AnyComponent): boolean => {
        const { kind, inTemplate, top } = component;
        return isSectionKind(kind) && inTemplate && !top;
    },
    isFooterSection = (component: AnyComponent): boolean => {
        const { kind, inTemplate, top } = component;
        return isSectionKind(kind) && inTemplate && !!top;
    },
    getHeaderSection = (componentsMap: ComponentsMap): SectionComponent => {
        if (_prevHeaderId && componentsMap[_prevHeaderId] && isHeaderSection(componentsMap[_prevHeaderId])) {
            return componentsMap[_prevHeaderId];
        }
        const headerComponent: AnyComponent = Object.values(componentsMap).find(cmp => isHeaderSection(cmp));
        if (headerComponent) {
            _prevHeaderId = headerComponent.id;
        }
        return headerComponent;
    },
    getFooterSection = (componentsMap: ComponentsMap): SectionComponent => {
        if (_prevFooterId && componentsMap[_prevFooterId] && isFooterSection(componentsMap[_prevFooterId])) {
            return componentsMap[_prevFooterId];
        }
        const footerComponent: AnyComponent = Object.values(componentsMap).find(cmp => isFooterSection(cmp));
        if (footerComponent) {
            _prevFooterId = footerComponent.id;
        }
        return footerComponent;
    },
    getHeaderAndFooterSection = (componentsMap: ComponentsMap) => ({
        header: getHeaderSection(componentsMap),
        footer: getFooterSection(componentsMap),
    }),

    getTopMostPageSectionId = (componentsMap: ComponentsMap) => {
        let topMostPageSectionId: string | null = null;

        Object.keys(componentsMap).forEach(componentId => {
            const component = componentsMap[componentId];

            if (component.inTemplate || !isSectionComponent(component)) return;

            if (!topMostPageSectionId || componentsMap[topMostPageSectionId].top > component.top) {
                topMostPageSectionId = componentId;
            }
        });

        return topMostPageSectionId;
    },
    getHeaderSectionId = (componentsMap: ComponentsMap) => {
        if (_prevHeaderId && componentsMap[_prevHeaderId] && isHeaderSection(componentsMap[_prevHeaderId])) {
            return _prevHeaderId;
        }
        _prevHeaderId = Object.keys(componentsMap).find((componentId) => isHeaderSection(componentsMap[componentId])) || null;
        return _prevHeaderId;
    },

    getFooterSectionId = (componentsMap: ComponentsMap) => {
        if (_prevFooterId && componentsMap[_prevFooterId] && isFooterSection(componentsMap[_prevFooterId])) {
            return _prevFooterId;
        }
        _prevFooterId = Object.keys(componentsMap).find((componentId) => isFooterSection(componentsMap[componentId])) || null;
        return _prevFooterId;
    },
    isHeaderOrFooterSection = (component: AnyComponent): boolean => {
        const { kind, inTemplate } = component;
        return isSectionKind(kind) && inTemplate;
    },
    getSectionInfo = (sectionId: string, componentsMap: ComponentsMap): SectionInfo => {
        const section = componentsMap[sectionId],
            sections: Array<AnyComponent> = getSectionsOrderdByTop(componentsMap),
            sectionIndex = R.findIndex(R.propEq('id', sectionId), sections);
        return {
            section,
            sections,
            sectionIndex
        };
    },
    getPrevOrNextSectionInfo =
        (sectionId: string, componentsMap: ComponentsMap, next: boolean = false): PrevNextSectionInfoProps => {
            const { sections, sectionIndex: currentSectionIndex } = getSectionInfo(sectionId, componentsMap);
            let sectionIndex, section;
            if (!next && currentSectionIndex !== 0) {
                sectionIndex = currentSectionIndex - 1;
                section = sections[sectionIndex];
            } else if (next && currentSectionIndex < (sections.length - 1)) {
                sectionIndex = currentSectionIndex + 1;
                section = sections[sectionIndex];
            }

            return {
                currentSectionIndex,
                sections,
                sectionIndex,
                section,
            };
        },
    /**
     * returns the highest bottom value of a containers children which are completely inside the container
     */
    getMaxBottomOfChildrenInContainer = (containerId: string, attachments: Attachments, componentsMap: ComponentsMap) => {
        const sectionAttachments = attachments[containerId] || [],
            { top, height } = componentsMap[containerId],
            sectionBottom = top + height;

        let minBottom = top;

        sectionAttachments.forEach((id) => {
            const { top: cmpTop, height: cmpHeight } = componentsMap[id],
                cmpBottom = cmpTop + cmpHeight;
            minBottom = Math.max(minBottom, cmpBottom);
        });
        return Math.min(sectionBottom, minBottom);
    },
    /**
     * adjusts the top of all the components at and below fromTop value.
     * you can pass other component id's which you want to move along with the above conditions. (eg. attachments).
     */
    adjustComponentTops = (
        fromTop: number,
        changeValue: number,
        componentsMap: ComponentsMap,
        includeCmps: ComponentsIds = [],
        ignoreCmps: ComponentsIds = []
    ): ComponentsMap => {
        return R.pipe(
            getComponentsMapNoGhosts,
            R.filter(
                ({ top, height, id }) => !ignoreCmps.includes(id) &&
                    (includeCmps.includes(id) || (R.gte((top + (height / 2)), fromTop))),
            ),
            R.map(cmp => ({ ...cmp, top: (cmp.top + changeValue) })),
            R.merge(componentsMap)
        )(componentsMap);
    },
    /**
     * Adjusts the section top value with along with their attachments and also the components below it.
     */
    adjustSectionTop = (
        section: AnyComponent,
        changeValue: number,
        componentsMap: ComponentsMap,
        attachments: Attachments,
        ignoreComponents: Array<string> = []
    ): ComponentsMap => {
        const { id: sectionId } = section,
            sectionChildren = getAllAttachmentsForCmpIds(attachments, [sectionId], [sectionId]);
        return adjustComponentTops(section.top, changeValue, componentsMap, sectionChildren, ignoreComponents);
    },
    /**
     * returns closest section endPoint at a given yPosition.
     */
    getClosestSectionEndPoint = (from: number, componentsMap: ComponentsMap): number => {
        const sections = getSectionsOrderdByTop(componentsMap);
        let closestTop = from;
        sections.some(section => {
            const { top, height } = section,
                bottom = top + height,
                center = top + (height / 2);
            if (from >= top && from < bottom) {
                closestTop = from < center ? top : bottom;
                return true;
            }
            return false;
        });
        return closestTop;
    },
    /**
     * returns closest section from the yPosition.
     */
    getClosestSection = (yPosition: number, componentsMap: ComponentsMap): AnyComponent => {
        const sections = getSectionsOrderdByTop(componentsMap);
        const noOfSections = sections.length;
        let result = sections[0];
        sections.some((section, index) => {
            const { top, height } = section,
                bottom = top + height,
                center = top + (height / 2);

            const prevSection = sections[index - 1],
                prevSectionBottom = prevSection ? (prevSection.top + prevSection.height) : top;

            if (yPosition < 0) {
                result = sections[index];
                return true;
            }
            if (index >= noOfSections - 2 && yPosition >= center) {
                result = sections[noOfSections - 1];
                return true;
            }
            if (yPosition >= prevSectionBottom && yPosition < bottom) {
                result = yPosition >= center ? sections[index + 1] : sections[index];
                return true;
            }
            return false;
        });
        return result;
    },
    /**
     * returns componentsMap after the tops of the section is adjusted form a given yPosition.
     * 1. all the tops of attachments of the sections are updated.
     * 2. all the sections/components at & below the yPosition are updated.
     */
    adjustSectionTopsAtPosition = (
        fromTop: number,
        changeValue: number,
        componentsMap: ComponentsMap,
        attachments: Attachments
    ): ComponentsMap => {
        const section = getClosestSection(fromTop, componentsMap);

        if (!section) {
            console.error(`Section: Section not found at position`, fromTop); // eslint-disable-line
        }
        return section ? adjustSectionTop(section, changeValue, componentsMap, attachments) : componentsMap;
    },
    /**
     * returns componentsMaps after all the spaces between the sections are removed.
     */
    removeEmptySpacesBetweenSections = R.curry(memoMaxOne((attachments: Attachments, componentsMap: ComponentsMap): ComponentsMap => {
        let prevSectionBottom = 0;
        const sections = getSectionsOrderdByTop(componentsMap);
        return sections.reduce((acc, section) => {
            const { height, top } = acc[section.id],
                sectionTopDiff = prevSectionBottom - top;

            let newCmpMap = { ...acc };
            if (sectionTopDiff !== 0) {
                newCmpMap = adjustSectionTop(section, sectionTopDiff, componentsMap, attachments);
            }
            prevSectionBottom = prevSectionBottom + height;
            return newCmpMap;
        }, componentsMap);
    })),
    /**
     * returns page sections in the current page.
     */
    getPageSections = (componentsMap: ComponentsMap): Array<AnyComponent> => {
        let sections = getSectionsOrderdByTop(componentsMap);
        return sections.filter(section => !isHeaderSection(section) && !isFooterSection(section));
    },
    getFirstPageSection = (componentsMap: ComponentsMap) => {
        const pageSections = getPageSections(componentsMap);
        return R.head(pageSections);
    },
    getLastPageSection = (componentsMap: ComponentsMap) => {
        const pageSections = getPageSections(componentsMap);
        return R.last(pageSections);
    },
    getSecondPageSection = (componentsMap: ComponentsMap) => {
        const pageSections = getPageSections(componentsMap);
        if (pageSections[1]) {
            return pageSections[1];
        }
        return R.head(pageSections);
    },
    isFirstPageSection = (sectionId: string, componentsMap: ComponentsMap) => {
        let firstPageSection = getFirstPageSection(componentsMap);
        return sectionId === (firstPageSection && firstPageSection.id);
    },
    isLastPageSection = (sectionId: string, componentsMap: ComponentsMap) => {
        const pageSections = getPageSections(componentsMap);
        let lastPageSection = R.last(pageSections);
        return sectionId === (lastPageSection && lastPageSection.id);
    },
    /**
     * returns a section Id if there is one common section for the components else returns empty string
     */
    commonSectionForComponents = (componentIds: ComponentsIds, attachments: Attachments, componentsMap: ComponentsMap): string => {
        if (!componentIds.length) { return ''; }
        const sectionId = getTopMostParentId(componentIds[0], attachments),
            hasCommonParent = componentIds.every(id => sectionId === getTopMostParentId(id, attachments));

        return hasCommonParent && isSectionId(sectionId, componentsMap) ? sectionId : '';
    },
    someComponentIdsAreSections =
        (componentsIds: ComponentsIds, componentsMap: ComponentsMap): boolean =>
            componentsIds.some(id => isSectionComponent(componentsMap[id])),
    getSectionChildren = (section: AnyComponent, componentsMap: ComponentsMap): Array<AnyComponent> => {
        const { top: sectionTop, height: sectionHeight, id: sectionId } = section,
            sectionBottom = sectionTop + sectionHeight;
        return Object.values(getComponentsMapNoGhosts(componentsMap) as Record<string, any>[])
            .filter((cmp) => {
                const { top, height, id } = cmp;
                const center = top + (height / 2);
                return center >= sectionTop && center < sectionBottom && id !== sectionId && !isStickyToHeader(cmp);
            });
    },
    getChildComponentsOverTopAndBottomOfSection = (section: AnyComponent, componentsMap: ComponentsMap): {
        top: Array<AnyComponent>,
        bottom: Array<AnyComponent>
    } => {
        const { top: sectionTop, height: sectionHeight } = section,
            sectionBottom = sectionTop + sectionHeight,
            childComponents = getSectionChildren(section, componentsMap);
        return {
            top: childComponents.filter((cmp) => cmp.top < sectionTop && !isStickyToHeader(cmp)),
            bottom: childComponents.filter((cmp) => {
                const { top, height } = cmp,
                    bottom = top + height;
                return bottom > sectionBottom && !isStickyToHeader(cmp);
            })
        };
    },
    getNearestTopPageSection = (componentsMap: ComponentsMap, { y }: Record<string, any>) => {
        const pageSections = getPageSections(componentsMap);
        if (y <= R.head(pageSections).top) {
            return getHeaderSection(componentsMap);
        }
        const lastpageSection = R.last(pageSections);
        if (y >= (lastpageSection.top + lastpageSection.height)) {
            return lastpageSection;
        }
        for (const section of pageSections) {
            if (y >= section.top && y <= (section.top + section.height)) {
                return section;
            }
        }
        return R.head(pageSections);
    },
    setSectionHeight = (
        height: number,
        sectionId: string,
        componentsMap: ComponentsMap,
        attachments: Attachments,
    ) => {
        const section = componentsMap[sectionId],
            { section: nextSection } = getPrevOrNextSectionInfo(sectionId, componentsMap, true),
            heightDiff = height - section.height,
            ignoreComponents = getAllAttachmentsForCmpIds(attachments, [sectionId]);

        let newComponentsMap = { ...componentsMap };
        if (nextSection) {
            newComponentsMap = adjustSectionTop(nextSection, heightDiff, newComponentsMap, attachments, ignoreComponents);
        }
        newComponentsMap = {
            ...newComponentsMap,
            [sectionId]: { ...section, height }
        };
        return newComponentsMap;
    },
    createSection = ({ top, bottom, inTemplate, orderIndex }: CreateSectionProps) => ({
        id: makeUuid(),
        inTemplate: !!inTemplate,
        top,
        height: Math.max(MIN_SECTION_HEIGHT, bottom - top),
        kind: sectionKind,
        left: 0,
        width: 5000,
        orderIndex,
        relTo: null,
        relIn: null,
        relPage: null,
        relPara: null,
        wrap: false,
        stretch: true,
        //TODO style can be taken from section default state
        style: defaultSectionStyle,
        pin: 0,
        title: ''
    }),
    isComponentBelowFooter = (component: AnyComponent, footer: SectionComponent) => {
        if (!footer) return false;
        const footerTop = footer.top;
        const footerBottom = footerTop + footer.height;
        const componentTop = component.top;
        const componentBottom = component.top + component.height;

        return componentTop > footerTop && componentBottom > footerBottom;
    },
    isAnEmptyContainer = (containerId, componentsMap) =>
        !Object.values(componentsMap).some((cmp: AnyComponent) => cmp.relIn && cmp.relIn.id === containerId),
    shrinkTheOnlyPageSectionHeightIfEmpty = (componentsMap: ComponentsMap) => {
        let newComponentsMap = { ...componentsMap };
        let pageSections = getPageSections(newComponentsMap),
            theOnlyPageSection = pageSections && pageSections.length === 1 ? { ...pageSections[0] } : null,
            footerSection = { ...getFooterSection(newComponentsMap) };
        if (footerSection.height === 0 &&
            theOnlyPageSection &&
            theOnlyPageSection.top &&
            isAnEmptyContainer(theOnlyPageSection.id, newComponentsMap) &&
            theOnlyPageSection.style && objectsAreEqual(theOnlyPageSection.style, defaultSectionStyle)) {
            theOnlyPageSection.height = 0;
            newComponentsMap[theOnlyPageSection.id] = theOnlyPageSection;
            footerSection.top = pageSections[0].top;
            newComponentsMap[footerSection.id] = footerSection;
        }
        return newComponentsMap;
    },
    shrinkHeaderFooterHeightIfInvalid = (componentsMap: ComponentsMap) => {
        const { height: headerHeight, id: headerId } = getHeaderSection(componentsMap);
        let newComponentsMap = { ...componentsMap };
        if (headerHeight === 1) {
            newComponentsMap[headerId] = { ...newComponentsMap[headerId], height: 0 };
            newComponentsMap = adjustComponentTops(headerHeight, -headerHeight, newComponentsMap);
        }
        const { height: footerHeight, id: footerId } = getFooterSection(newComponentsMap);
        if (footerHeight === 1) {
            newComponentsMap[footerId] = { ...newComponentsMap[footerId], height: 0 };
        }
        return newComponentsMap;
    },
    isCmpInsideHeader = (cmpId: string, componentsMap: ComponentsMap, attachments: Attachments) => {
        const topParentId = getTopMostParentId(cmpId, attachments);
        return getHeaderSectionId(componentsMap) === topParentId;
    },
    updateSectionHeightAndAdjustOtherSectionsPositions = (
        newHeight: number,
        sectionId: string,
        componentsMap: ComponentsMap,
        ignoreCmps: string[] = []
    ) => {
        const section = componentsMap[sectionId],
            sectionBox = getCmpRect(section),
            changeValue = newHeight - section.height;
        let newComponentsMap = adjustComponentTops(sectionBox.bottom, changeValue, componentsMap, [], ignoreCmps);
        newComponentsMap = {
            ...newComponentsMap,
            [sectionId]: {
                ...newComponentsMap[sectionId],
                height: newHeight
            }
        };
        return newComponentsMap;
    },
    isSectionContainsNonDeletableItems = (section, componentsMap) => {
        const childComponents = getSectionChildren(section, componentsMap);

        return childComponents.some(component => {
            return NON_DELETABLE_COMPONENTS.includes(component.kind);
        });
    },
    isSectionContainsNonDuplicatableItems = (section, componentsMap) => {
        const childComponents = getSectionChildren(section, componentsMap);

        return childComponents.some(component => {
            return NON_DUPLICATABLE_COMPONENTS.includes(component.kind);
        });
    };

export {
    getSectionChildren,
    isSectionComponent,
    getMaxBottomOfChildrenInContainer,
    getHeaderSection,
    getFooterSection,
    getHeaderAndFooterSection,
    isHeaderSection,
    isFooterSection,
    isHeaderOrFooterSection,
    getFirstPageSection,
    getLastPageSection,
    getSecondPageSection,
    isFirstPageSection,
    isLastPageSection,
    getPrevOrNextSectionInfo,
    getSectionsOrderdByTop,
    removeEmptySpacesBetweenSections,
    getSectionInfo,
    adjustComponentTops,
    isSectionId,
    getClosestSectionEndPoint,
    someComponentIdsAreSections,
    commonSectionForComponents,
    adjustSectionTopsAtPosition,
    getClosestSection,
    getTopMostPageSectionId,
    getHeaderSectionId,
    getFooterSectionId,
    getNearestTopPageSection,
    createSection,
    adjustSectionTop,
    getChildComponentsOverTopAndBottomOfSection,
    isComponentBelowFooter,
    setSectionHeight,
    shrinkTheOnlyPageSectionHeightIfEmpty,
    shrinkHeaderFooterHeightIfInvalid,
    isCmpInsideHeader,
    getPageSections,
    updateSectionHeightAndAdjustOtherSectionsPositions,
    isSectionContainsNonDeletableItems,
    isSectionContainsNonDuplicatableItems
};
