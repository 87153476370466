import * as React from 'react';
import type { SstockImage } from "../../../../../dal/flowTypes";
import { SstockImageModel } from "../../../../../dal/model/SstockImageModel";
import { FmImagePreview } from "../../imagePreview/FmImagePreview";

type Props = {
    image: SstockImage,
};

export class ShutterstockImagePreview extends React.Component<Props> {
    image: SstockImageModel;

    constructor(props: Props) {
        super(props);
        this.image = new SstockImageModel(props.image);
    }

    previewDimensions = () => Promise.resolve(this.image.getOrigDimensions());

    render() {
        return (
            <FmImagePreview
                url={this.image.getPreviewUrl()}
                title={this.image.getFilename()}
                dimensions={this.previewDimensions}
                previewDimensions={this.image.getPreviewDimensions()}
                photographerName={this.image.getUserName()}
                photographerProfileLink={this.image.getUserProfileLink()}
            />
        );
    }
}
