import React from 'react';
import AceEditor from 'react-ace';
import cx from 'classnames';
import 'brace';
import 'brace/mode/html'; // eslint-disable-line n/file-extension-in-import
import kind from './kind';
import 'brace/theme/textmate'; // eslint-disable-line n/file-extension-in-import
import validator from './codeValidator';
import * as styles from './CodeEditor.css';
import { ErrorMessages } from './CodeValidationErrors';
import { Msg } from "../../../view/intl/index";
import { TooltipCom } from "../../presentational/Tooltip/TooltipCom";
import { TooltipPosition } from "../../presentational/Tooltip/constants";

const
    codeEditorId = `${kind}-code-editor`;

type Props = {
    height: number,
    code: string,
    onChange: Function,
};

type State = {
    errorCode: string | null,
    errMsg: string | null,
    isFocused: boolean,
    highlightActiveLine: boolean,
    highlightGutterLine: boolean
};

class CodeEditor extends React.Component<Props, State> {
    ref: React.RefObject<HTMLDivElement>;
    constructor(props: Props) {
        super(props);
        this.state = {
            errorCode: null,
            errMsg: null,
            isFocused: false,
            highlightActiveLine: false,
            highlightGutterLine: false
        };

        this.ref = React.createRef();
        this.onLoad = this.onLoad.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onFocus = this.onFocus.bind(this);
        this.onBlur = this.onBlur.bind(this);
    }

    validate = (code: string) => {
        const [errorCode, ...params]: any = validator(code);
        if (this.state.errorCode !== errorCode) {
            this.setState({
                errorCode,
                // @ts-ignore
                errMsg: errorCode ? (<Msg k={errorCode} params={params}>{ErrorMessages[errorCode]}</Msg>) : null
            });
        }
    };

    onChange = (code: string) => {
        this.props.onChange(code);
        this.validate(code);
    };

    onLoad(editor: Record<string, any>) {
        this.validate(editor.getValue());
    }

    onFocus() {
        this.setState({
            isFocused: true,
            highlightActiveLine: true,
            highlightGutterLine: true
        });
    }

    onBlur() {
        this.setState({
            isFocused: false,
            highlightActiveLine: false,
            highlightGutterLine: false
        });
    }

    render() {
        const
            {
                props: { height, code },
                state: { errMsg, isFocused, highlightActiveLine, highlightGutterLine }
            } = this,
            editorHeight = height,
            tooltipProps = {
                isSmallTipInfo: false,
                distance: 2,
                targetRef: this.ref,
                show: !!errMsg,
                position: TooltipPosition.RIGHT,
                theme: {
                    container: styles.tipContainer,
                    pointer: styles.tipPointer,
                    tipInfo: styles.tipInfo
                }
            };

        return (
            <React.Fragment>
                <div className={cx(styles.codeEditorContainer, { [styles.editorFocused]: isFocused })} style={{ height }} ref={this.ref}>
                    <AceEditor
                        mode="html"
                        theme="textmate"
                        name={codeEditorId}
                        editorProps={{ $blockScrolling: true }}
                        setOptions={{ highlightGutterLine }}
                        focus
                        wrapEnabled
                        showPrintMargin={false}
                        height={`${editorHeight}px`}
                        width="100%"
                        value={code}
                        markers={[]}
                        onLoad={this.onLoad}
                        onChange={this.onChange}
                        onBlur={this.onBlur}
                        onFocus={this.onFocus}
                        highlightActiveLine={highlightActiveLine}
                    />
                </div>

                <TooltipCom {...tooltipProps}>
                    <Msg k="component.code.invalidCodeMessage">
                        Your HTML code does not validate. Please make sure that your page looks okay before publishing.
                    </Msg>
                    <br />
                    {errMsg}
                </TooltipCom>
            </React.Fragment>
        );
    }
}

export default CodeEditor;
