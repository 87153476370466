/* eslint-disable max-len */
import makeEpic from '../../../../../epics/makeEpic';
import { receiveOnly } from "../../../../../epics/makeCondition";
import { default as valueActionType, generalAITextGlobalDataVAT } from './valueActionType';
import * as Actions from "./actionTypes";
import getGeneratedAIText from './actionCreators/getGeneratedAIText';
import { CONTENT_TYPES, ERROR_STATES, ERROR_STATE_CODES, REQUIRED_FIELDS } from "../../AIText/Dialogs/CreateAIText/constants";
import {
    ReceiveOnlySelectedComponentsIdsSelector,
    ReceiveOnlyComponentsMap,
    ReceiveOnlySelectedComponentSelector,
} from "../../../../Workspace/epics/componentsEval/selectorActionTypes";
import WORKSPACE_COMPONENT_KIND from "../../../../Workspace/epics/componentsEval/WORKSPACE_COMPONENT_KIND";
import { closeDialog } from "../../../../App/actionCreators";
import { transformAITextOnReplace, validateInputFields, updateErrorFields } from "./utils";

import type { AITextType } from "./types";
import { REPLACE_DIALOG } from '../../../../../redux/modules/actionTypes';
import { CREATE_AI_TEXT_DIALOG_ID } from '../../AIText/Dialogs/CreateAIText/CreateAITextDialog';
import localStorage from '../../../../../utils/localStorage';
import { LOCAL_TEXT_AI_QUERY_RESULTS, MAX_GENERATED_RESULTS_LIMIT } from './constants';
import { GET_PREFERENCES_SUCCESS_ACTION, savePreferencesAction } from '../../../../Preferences/actions';
import { CLOSE_STICKY_TOOLTIP, SHOW_TOOLTIP_FOR_AI_WRITING_ASSISTANT } from '../../../../Tooltip/stickyTooltip/actionTypes';
import { TEXT_MCTA_UPDATE_CLIENT_POSITION } from '../../actionTypes';
import styles from "../../AIText/Dialogs/CreateAIText/styles.css";
import { dialogManagerVAT } from "../../../../DialogManager/epic/valueActionType";
import { topMostDialogIdSelector } from "../../../../DialogManager/epic/selectors";
import { termsAndConditionsGlobalDataVAT } from '../../../../SiteSettings/termsAndConditions/termsAndConditionsEpic';
import { OPEN_AI_TERMS_UPDATE } from '../../../../SiteSettings/termsAndConditions/actionTypes';
import { PREFERENCES_EPIC_VALUE_VAT } from '../../../../Preferences/preferencesVat';
import { containsWrappedItems } from '../../constants';

const initialState: AITextType = {
    isQueryInProgress: false,
    isScrolledWhileStreaming: false,
    isQuotesRemovedAtStart: false,
    contentType: CONTENT_TYPES.paragraph1,
    queryResults: [],
    isAnyFieldChanged: false,
    agreeTerms: false,
    isFirstTimeUser: true,
    isTextGenerationInProgress: false,
    errorState: null,
    errorFields: [],
    resultStartIndex: 0,
    insertedText: '',
    copiedText: '',
    showTermsCheckbox: true,
    hasWrappedElements: false
};

const getFlattenedResults = resultsList => {
    return resultsList.reduce((acc, result) => {
        if (typeof result === "string") {
            return [...acc, result];
        } else if (Array.isArray(result)) {
            return [...acc, ...getFlattenedResults(result)];
        } else {
            return acc;
        }
    }, []);
};

const makeReducer = field => (state: any, { payload }: any) => ({ ...state, [field]: payload });
const makeFieldsReducer = field => (state: any, { payload }: any) => {
    return {
        ...state,
        [field]: payload,
        isAnyFieldChanged: true,
    };
};

export const aITextEpic = makeEpic({
    defaultState: initialState,
    valueActionType,
    updaters: [
        {
            conditions: [
                ReceiveOnlySelectedComponentSelector,
                receiveOnly(generalAITextGlobalDataVAT),
                receiveOnly(termsAndConditionsGlobalDataVAT),
                receiveOnly(PREFERENCES_EPIC_VALUE_VAT),
                REPLACE_DIALOG
            ],
            reducer: ({ state, values: [selectedComponent, aiData, termsAndConditions, preferences, dialog] }) => {
                const { dialogId, } = dialog;
                if (dialogId !== CREATE_AI_TEXT_DIALOG_ID) {
                    return { state };
                }
                const initialSetupCompleted = Object.keys(aiData).length > 0;
                const queryResults = localStorage.get(LOCAL_TEXT_AI_QUERY_RESULTS);
                const { agreeTerms, ...rest } = aiData;
                let agreeTermsOpenAI = termsAndConditions?.openAI;
                const showTermsCheckbox = !agreeTermsOpenAI;

                let multipleActionsToDispatch: any = [];
                if (!preferences.hideWritingAssistantTooltip) {
                    multipleActionsToDispatch.push(savePreferencesAction({
                        hideWritingAssistantTooltip: true
                    }));
                    multipleActionsToDispatch.push({ type: CLOSE_STICKY_TOOLTIP });
                }

                // TODO: Cleanup this code when no customers have textAIData.agreeTerms in site settings
                // Run the script to check in Dec 2023 and Mar 2024
                if (agreeTerms && !agreeTermsOpenAI) {
                    agreeTermsOpenAI = agreeTerms;
                    multipleActionsToDispatch.push({
                        type: OPEN_AI_TERMS_UPDATE,
                        payload: agreeTermsOpenAI
                    });
                    multipleActionsToDispatch.push({
                        type: Actions.GENERAL_AI_TEXT_GLOBAL_DATA_UPDATE,
                        payload: rest
                    });
                }

                return {
                    state: {
                        ...initialState,
                        queryResults: queryResults || [],
                        agreeTerms: agreeTermsOpenAI || initialSetupCompleted,
                        isFirstTimeUser: !initialSetupCompleted,
                        showTermsCheckbox,
                        hasWrappedElements: containsWrappedItems(selectedComponent.content)
                    },
                    multipleActionsToDispatch
                };
            }
        },
        {
            conditions: [
                receiveOnly(dialogManagerVAT, topMostDialogIdSelector),
                Actions.GENERATE_AI_TEXT_SUCCESS
            ],
            reducer: ({ state, values: [dialogId, { data, done }] }) => {
                const queryResults = state.queryResults;
                let isQueryInProgress,
                    isScrolledWhileStreaming = state.isScrolledWhileStreaming,
                    isQuotesRemovedAtStart = state.isQuotesRemovedAtStart;
                let queryData = '';
                let resultStartIndex = state.resultStartIndex;
                let actionToDispatch;

                if (data && (typeof data === 'string')) {
                    queryData = data.replace(/\n/g, '<br>');
                }

                if (typeof queryData === 'string') {
                    const stateResult = queryResults[queryResults.length - 1];
                    const lastResult = stateResult.pop();
                    let finalQueryData = lastResult ? lastResult + queryData : queryData;
                    if (state.contentType === CONTENT_TYPES.title) {
                        if (!lastResult) {
                            let updatedFinalQueryData = finalQueryData.replace(/^['"]/, '');
                            if (updatedFinalQueryData !== finalQueryData) {
                                isQuotesRemovedAtStart = true;
                                finalQueryData = updatedFinalQueryData;
                            }
                        }
                        if (done && isQuotesRemovedAtStart) {
                            finalQueryData = finalQueryData.replace(/['"]$/, '');
                        }
                    }
                    if (!lastResult) {
                        isScrolledWhileStreaming = false;
                    }
                    queryResults[queryResults.length - 1].push(finalQueryData);
                }

                if (!state.isQueryInProgress) {
                    isScrolledWhileStreaming = false;
                }

                if (done) {
                    if (dialogId !== CREATE_AI_TEXT_DIALOG_ID) {
                        const stateResult = queryResults[queryResults.length - 1];
                        stateResult.pop();
                        if (stateResult.length === 0) {
                            queryResults.pop();
                        }
                        localStorage.set(LOCAL_TEXT_AI_QUERY_RESULTS, queryResults);
                        return { state };
                    }
                    const flattenedResults = getFlattenedResults(queryResults);
                    if (flattenedResults.length > MAX_GENERATED_RESULTS_LIMIT) {
                        const initialQuery = queryResults.shift();
                        if (initialQuery.length > 1) {
                            initialQuery.shift();
                            queryResults.unshift(initialQuery);
                        } else if (resultStartIndex > 0) {
                            resultStartIndex = resultStartIndex - 1;
                        }
                    }
                    isQueryInProgress = false;
                    isScrolledWhileStreaming = false;
                    isQuotesRemovedAtStart = false;
                    localStorage.set(LOCAL_TEXT_AI_QUERY_RESULTS, queryResults);
                    actionToDispatch = { type: Actions.GENERATE_AI_TEXT_COMPLETE_EVENT };
                } else {
                    isQueryInProgress = true;
                }
                return {
                    state: {
                        ...state,
                        isQueryInProgress,
                        isScrolledWhileStreaming,
                        isQuotesRemovedAtStart,
                        isAnyFieldChanged: false,
                        queryResults,
                        resultStartIndex
                    },
                    actionToDispatch
                };
            },
        },
        {
            conditions: [
                receiveOnly(dialogManagerVAT, topMostDialogIdSelector),
                Actions.GENERATE_AI_TEXT_FAILED
            ],
            reducer: ({ state, values: [dialogId, { statusCode }] }) => {
                const queryResults = state.queryResults;
                let resultStartIndex = state.resultStartIndex;
                if (queryResults && queryResults.length > 0) {
                    const stateResult = queryResults[queryResults.length - 1];
                    stateResult.pop();
                    if (stateResult.length === 0) {
                        queryResults.pop();
                        if (resultStartIndex > queryResults.length) {
                            resultStartIndex = 0;
                        }
                    }
                }

                localStorage.set(LOCAL_TEXT_AI_QUERY_RESULTS, queryResults);

                if (dialogId !== CREATE_AI_TEXT_DIALOG_ID) {
                    return { state };
                }
                const errorState = ERROR_STATE_CODES[statusCode] || ERROR_STATES.CONNECTION_ERROR;
                return {
                    state: {
                        ...state,
                        errorState,
                        isQueryInProgress: false,
                        isScrolledWhileStreaming: false,
                        isQuotesRemovedAtStart: false,
                        queryResults,
                        resultStartIndex
                    },
                    actionToDispatch: { type: Actions.GENERATE_AI_TEXT_FAILED_EVENT, payload: errorState }
                };
            }
        },
        {
            conditions: [
                Actions.AI_TEXT_RESULTS_PANEL_SCROLLED
            ],
            reducer: ({ state }) => {
                return {
                    state: {
                        ...state,
                        isScrolledWhileStreaming: true
                    }
                };
            }
        },
        {
            conditions: [
                Actions.AI_TEXT_RESULTS_PANEL_SCROLLED_TO_BOTTOM
            ],
            reducer: ({ state }) => {
                return {
                    state: {
                        ...state,
                        isScrolledWhileStreaming: false
                    }
                };
            }
        },
        {
            conditions: [
                Actions.AI_TEXT_CLOSE_DIALOG
            ],
            reducer: ({ state }) => {
                const isFirstTimeUser = state.isFirstTimeUser && !(state.agreeTerms && state.isAnyFieldChanged);
                const queryResults = state.queryResults;

                return {
                    state: {
                        ...initialState,
                        agreeTerms: state.agreeTerms,
                        isFirstTimeUser,
                        isTextGenerationInProgress: false,
                        queryResults
                    },
                    actionToDispatch: closeDialog()
                };
            }
        },
        {
            conditions: [
                receiveOnly(termsAndConditionsGlobalDataVAT),
                Actions.AI_TEXT_GENERATE_CLICKED
            ],
            reducer: ({ state, values: [termsAndConditions, payload] }) => {
                const data = { ...state, ...payload, agreeTerms: state.agreeTerms };
                const errorFields = validateInputFields(data);
                if (errorFields.length) {
                    return {
                        state: {
                            ...state,
                            errorFields
                        }
                    };
                } else {
                    const { contentType, websiteTitle, topic, gmbKey, targetAudience, extras, language } = data;
                    const queryResults = state.queryResults;

                    let resultStartIndex = state.resultStartIndex;

                    if (!state.queryResults || state.isAnyFieldChanged) {
                        queryResults.push(['']);

                        if (resultStartIndex === 0 && !state.isFirstTimeUser) {
                            resultStartIndex = queryResults.length - 1;
                        }
                    } else {
                        queryResults[queryResults.length - 1].push('');
                    }

                    const multipleActionsToDispatch = [
                        getGeneratedAIText({
                            inputParams: { contentType, websiteTitle, topic, gmbKey, targetAudience, extras, language },
                        }),
                        { type: Actions.GENERAL_AI_TEXT_GLOBAL_DATA_UPDATE, payload }
                    ];

                    if (!termsAndConditions?.openAI) {
                        multipleActionsToDispatch.push({
                            type: OPEN_AI_TERMS_UPDATE,
                            payload: state.agreeTerms
                        });
                    }

                    return {
                        state: {
                            ...state,
                            isQueryInProgress: true,
                            isScrolledWhileStreaming: false,
                            isQuotesRemovedAtStart: false,
                            isTextGenerationInProgress: true,
                            errorState: null,
                            errorFields: [],
                            resultStartIndex
                        },
                        multipleActionsToDispatch
                    };
                }
            }
        },
        {
            conditions: [
                Actions.AI_TEXT_REPLACE_BTN_CLICKED,
                ReceiveOnlySelectedComponentsIdsSelector,
                ReceiveOnlyComponentsMap,
            ],
            reducer: ({
                state,
                values: [
                    { content },
                    selectedComponentsIds,
                    componentsMap
                ]
            }) => {
                const oldContent = componentsMap[selectedComponentsIds[0]].content;
                const newContent = content.replace(/\n/g, '<br>');
                const transformedText = transformAITextOnReplace(oldContent, newContent, state.contentType);

                return {
                    state: {
                        ...state,
                        insertedText: content
                    },
                    actionToDispatch: {
                        type: Actions.AI_TEXT_REPLACE_TEXT_CONTENT,
                        payload: {
                            content: transformedText,
                            componentId: selectedComponentsIds[0]
                        },
                        forwardTo: {
                            kind: WORKSPACE_COMPONENT_KIND,
                            id: selectedComponentsIds[0]
                        }
                    }
                };
            }
        },
        {
            conditions: [
                Actions.AI_TEXT_AGREE_TERMS_CHECKED
            ],
            reducer: ({ state }) => {
                const agreeTerms = !state.agreeTerms;
                const errorFields = updateErrorFields(state.errorFields, REQUIRED_FIELDS.agreeTerms, agreeTerms);

                return {
                    state: {
                        ...state,
                        agreeTerms,
                        errorFields
                    }
                };
            }
        },
        {
            conditions: [
                Actions.AI_TEXT_INPUT_CHANGED
            ],
            reducer: ({ state, values: [payload] }) => {
                const { name, value } = payload;
                const errorFields = updateErrorFields(state.errorFields, name, value);

                return {
                    state: {
                        ...state,
                        isAnyFieldChanged: true,
                        errorFields
                    }
                };
            }
        },
        {
            conditions: [GET_PREFERENCES_SUCCESS_ACTION],
            reducer: ({ values: [{ hideWritingAssistantTooltip }], state }) => {
                return {
                    state: {
                        ...state,
                        hideWritingAssistantTooltip
                    },
                };
            }
        },
        {
            conditions: [
                receiveOnly(PREFERENCES_EPIC_VALUE_VAT),
                TEXT_MCTA_UPDATE_CLIENT_POSITION
            ],
            reducer: ({ state, values: [preferences, { position, elementDimensions }] }) => {
                if (preferences.hideWritingAssistantTooltip) return { state };
                const actionToDispatch = {
                    type: SHOW_TOOLTIP_FOR_AI_WRITING_ASSISTANT,
                    payload: {
                        position,
                        elementDimensions,
                        customClass: styles.aiTextMCTATooltip,
                        customTextClass: styles.aiTextMCTATooltipText,
                        imageClass: styles.aiTextMCTATooltipImage,
                        buttonCustomClass: styles.aiTextMCTATooltipButton
                    }
                };
                return {
                    state,
                    actionToDispatch
                };
            }
        },
        {
            conditions: [
                Actions.AI_TOOLTIP_SEEN
            ],
            reducer: ({ state }) => ({
                state,
                actionToDispatch: savePreferencesAction({
                    hideWritingAssistantTooltip: true
                    // TODO: Cleanup writingAssistantTooltipSeen from preferences
                })
            })
        },
    ],
    handleActions: {
        [Actions.AI_TEXT_CONTENT_TYPE_CHANGED]: makeFieldsReducer('contentType'),
        [Actions.AI_TEXT_TOPIC_TYPE_CHANGED]: makeFieldsReducer('topicType'),
        [Actions.AI_TEXT_TOPIC_CHANGED]: makeFieldsReducer('topic'),
        [Actions.AI_TEXT_EXTRAS_CHANGED]: makeFieldsReducer('extras'),
        [Actions.AI_TEXT_COPY_BTN_CLICKED]: makeReducer('copiedText')
    }
});
