/* eslint-disable react/button-has-type */
/* eslint-disable max-len */
import * as R from 'ramda';
import React, { useState } from "react";
import { Dispatch } from "redux";
import platform from 'platform';
import cx from "classnames";

import { memoUnlimited } from '../../../../../utils/memo';
import { injectIntl } from '../../../../view/intl/index';

import FontSizeSelector from "../../../presentational/FontSize/index";
import ColorProperty from "../../../presentational/ColorProperty/index";
import GlobalstyleSelector from '../../../presentational/GlobalstyleSelector/index';

import * as selectors from '../../../Workspace/epics/stylesheets/selectors';
import { MctaVerticalBar, MctaButton } from '../../../componentMainActions/index';

import * as actionTypes from '../actionTypes';
import optionMapper from '../globalStyle/options';
import TextGlobalstyleKind from '../globalStyle/kind';
import TextKind from '../kind';

import AlignmentControls from '../../../componentMainActions/MctaAlignmentControls/alignmentControls';

import mctaStyles from '../../../componentMainActions/MctaButton/styles.css';
import { DEFAULT_TEXT_TOOLBAR_COLOR_BOX_STYLE, BROKEN_LINK, MAX_FONT_SIZE, MIN_FONT_SIZE } from "../constants";

import HorizontalSpacer from '../../../../view/common/HorizontalSpacer';
import { DEFAULT_MCTA_COMBOBOX_MENUSTYLE } from '../../../componentMainActions/constants';
import openLinkChooserDialog from '../../../../view/common/dialogs/LinkChooserDialog/actionCreators/openLinkChooserDialog';
import { optionRenderer } from "../globalStyle/optionRenderer";
import { MacSymbol } from "../../../ContextMenu/view/MacSymbols";
import { LcTabName } from '../../../../view/common/dialogs/LinkChooserDialog/constants';
import { decodeWebspaceUri } from '../../../../../dal/utils/webspaceUriTransformers';
import type { ComponentExtensions, MCTA_PROPS } from "../../../MobileViewEditor/flowTypes";
import type { TextComponent, TextAutoColorType } from "../flowTypes";
import {
    MOBILE_EDITOR_ALIGNMENT_CHANGE,
    MOBILE_MCTA_ALIGN_MOUSE_ENTER,
    MOBILE_MCTA_ALIGN_MOUSE_LEAVE
} from "../../../MobileViewEditor/actionTypes";
import { ALIGNMENT_TYPES } from "../../../../constants";
import styles from './styles.css';
import {
    titleClearFormatting, titleTextBigger, titleTextBiggerErr, titleTextSmaller, titleTextSmallerErr
} from "../../constants";
import { THEME_COLOR_PICKER, DEFAULT_COLOR_PICKER } from '../../../ColorPicker/constants';
import { findSuitableTextColorName } from "../../../ThemeGlobalData/utils/commonUtils";
import { getThemeRulesForBackground } from '../../../ThemeGlobalData/themeRules';
import { getThemeColorsFromStylesheet } from "../../../Workspace/epics/stylesheets/selectors";
import { replaceDialog } from '../../../App/actionCreators';
import { CREATE_AI_TEXT_DIALOG_ID } from '../AIText/Dialogs/CreateAIText/CreateAITextDialog';
import { SpellcheckTooltip } from '../../../Tooltip/ids';
import { CTAViewParams } from '../../../../view/Workspace/Decorations/ComponentMainActions/flowTypes';
import { MAX_TEXT_LENGTH } from '../AIText/Dialogs/EditAIText/EditingAssistant/constants';
import { EDIT_AI_TEXT_DIALOG_ID } from '../AIText/Dialogs/EditAIText/EditAITextDialog';

export const getButtonLinkClassName = (linkAction: { link: string }, canSetLink: boolean) => {
    if (!linkAction || !canSetLink) {
        return null;
    } else if (linkAction.link === BROKEN_LINK) {
        return mctaStyles.mctaLinkButtonWithBrokenLinkSet;
    }
    return mctaStyles.mctaLinkButtonWithLinkSet;
};

export const
    prefixTextStyle = platform.os.family === 'OS X' ? MacSymbol.commandK : 'Ctrl',
    titleBold = ["msg: mcta.text.fontWeight {Bold ({prefixTextStyle} + B)}", { prefixTextStyle }],
    titleItalic = ["msg: mcta.text.styleItalic {Italic ({prefixTextStyle} + I)}", { prefixTextStyle }],
    titleUnderline = ["msg: mcta.text.styleUnderline {Underline ({prefixTextStyle} + U)}", { prefixTextStyle }],

    titleOrderedList = "msg: mcta.text.orderedList {Numbering}",
    titleUnorderedList = "msg: mcta.text.unorderedList {Bullets}",

    getDispatchAction = memoUnlimited((dispatch, action) => () => dispatch({ type: action }));

type PropTypes = {
    id: string,
    dispatch: Dispatch,
    mobileMCTA: MCTA_PROPS,
    selectedComponent: TextComponent,
    componentExtensions: ComponentExtensions
};

const
    EditView = injectIntl((
        props: {
            autoColor: TextAutoColorType,
            [k: string]: any,
        }
    ) => {
        const {
                dispatch,
                selectedTextState: {
                    expanded
                },
                tinyMceState: {
                    alignment,
                    bold,
                    italic,
                    underline,
                    color,
                    fontSize,
                    unorderedList,
                    orderedList,
                    linkAction,
                    canSetLink,
                    selectedGlobalStyle,
                    selectedText
                },
                stylesheets,
                stylesheetsIdToNameMap,
                colorPickerOpened,
                intl,
                isModernLayoutActivated,
                isInsideHeaderOrFooter,
                children
            } = props,
            themeColorsData = getThemeColorsFromStylesheet(stylesheets),
            textGlobalStyleOptions = optionMapper(selectors.getAllStylesheets(stylesheets), intl),
            textColorTitle = intl.msgJoint("msg: mcta.textColor {Text colour}"),
            optRenderer = (value, label) => optionRenderer({
                value,
                label,
                stylesheetsIdToNameMap,
                selectedParentTheme: props.autoColor && props.autoColor.selectedParentTheme
            }),
            isSelectedCmpInsideMHF = isInsideHeaderOrFooter && isModernLayoutActivated;

        if (linkAction && linkAction.link.type === LcTabName.PHONE) {
            linkAction.link.value = decodeWebspaceUri(linkAction.link.value);
        }

        let styleOptions = textGlobalStyleOptions;
        let onSelectStyleAction = actionTypes.TEXT_COMPONENT_GLOBAL_STYLE_SELECTED;
        let selectedGlobalStyleId = R.invertObj(stylesheetsIdToNameMap)[selectedGlobalStyle];
        let mouseOverHandler;
        let titleLink = '';

        if (linkAction && canSetLink) {
            titleLink = "msg: common.changeLink {Change link}";
        } else if (canSetLink) {
            titleLink = "msg: common.addLink {Add link}";
        } else if (selectedText !== '') {
            titleLink = "msg: mcta.text.selectLessText {Try selecting less text}";
        }

        if (expanded) {
            mouseOverHandler = e => {
                if (e.target && window.$(e.target).parents('table').length === 0) {
                    dispatch({ type: actionTypes.TEXT_MCTA_ALIGN_MOUSE_LEAVE });
                }
            };
        }
        const mctaBold = (<MctaButton
            title={titleBold}
            icon="BOLD"
            selected={Boolean(bold)}
            onClick={getDispatchAction(dispatch, actionTypes.TEXT_MCTA_BOLD_BTN_CLICKED)}
        />);
        const mctaItalic = (<MctaButton
            title={titleItalic}
            icon="ITALIC"
            selected={Boolean(italic)}
            onClick={getDispatchAction(dispatch, actionTypes.TEXT_MCTA_ITALIC_BTN_CLICKED)}
        />);
        const mctaUnderline = (<MctaButton
            title={titleUnderline}
            icon="UNDERLINE"
            selected={Boolean(underline)}
            onClick={getDispatchAction(dispatch, actionTypes.TEXT_MCTA_UNDERLINE_BTN_CLICKED)}
        />);
        const colorBoxStyle = isSelectedCmpInsideMHF
            ? { ...DEFAULT_TEXT_TOOLBAR_COLOR_BOX_STYLE, height: 20, width: 20 }
            : DEFAULT_TEXT_TOOLBAR_COLOR_BOX_STYLE;
        const mctaColorProperty = (props.autoColor
            ? <ColorProperty
                colorPickerKind={THEME_COLOR_PICKER}
                themeColorsData={themeColorsData}
                themeColor={props.autoColor.themeOverrideColor || null}
                defaultThemeColor={findSuitableTextColorName(
                    getThemeRulesForBackground(props.autoColor.selectedParentTheme, themeColorsData).background,
                    themeColorsData
                )}
                onChangeAction={actionTypes.TEXT_MCTA_COLOR_CHANGED_AUTO_COLOR}
                title={textColorTitle}
                colorBoxStyle={colorBoxStyle}
                colorPickerOpened={colorPickerOpened}
                showCaret
                dispatch={dispatch}
            />
            : <ColorProperty
                colorPickerKind={DEFAULT_COLOR_PICKER}
                color={color}
                onChangeDebouncedAction={actionTypes.TEXT_MCTA_COLOR_CHANGED}
                title={textColorTitle}
                colorBoxStyle={colorBoxStyle}
                colorPickerOpened={colorPickerOpened}
                showCaret
                dispatch={dispatch}
            />);
        const mctaLink = (<MctaButton
            title={titleLink}
            className={getButtonLinkClassName(linkAction, canSetLink)}
            icon="LINK"
            disabled={!canSetLink}
            onClick={() => dispatch(openLinkChooserDialog({
                setLinkAction: actionTypes.TEXT_SET_LINK,
                input: linkAction
            }))}
        />);
        if (isSelectedCmpInsideMHF) {
            const horizontalSpacing = 6;
            return (
                <div onMouseOver={mouseOverHandler}>
                    {mctaBold}
                    <HorizontalSpacer x={horizontalSpacing} />
                    <MctaVerticalBar />
                    <HorizontalSpacer x={horizontalSpacing} />
                    {mctaItalic}
                    <HorizontalSpacer x={horizontalSpacing} />
                    <MctaVerticalBar />
                    <HorizontalSpacer x={horizontalSpacing} />
                    {mctaUnderline}
                    <HorizontalSpacer x={horizontalSpacing} />
                    <MctaVerticalBar />
                    <HorizontalSpacer x={horizontalSpacing} />
                    {mctaColorProperty}
                    <HorizontalSpacer x={horizontalSpacing} />
                    <MctaVerticalBar />
                    <HorizontalSpacer x={horizontalSpacing} />
                    {mctaLink}
                </div>
            );
        }

        return (
            <div onMouseOver={mouseOverHandler}>
                <GlobalstyleSelector
                    onSelectAction={onSelectStyleAction}
                    options={styleOptions}
                    dispatch={dispatch}
                    style={{ width: 180 }}
                    menuStyle={DEFAULT_MCTA_COMBOBOX_MENUSTYLE}
                    selected={selectedGlobalStyleId}
                    selectedKind={TextGlobalstyleKind}
                    optionRenderer={optRenderer}
                    showEditLink
                    mcta
                    className={(props.autoColor && 'autoColorTextMctaDropdown') || ''}
                />
                <HorizontalSpacer x={5} />
                <MctaVerticalBar />
                <HorizontalSpacer x={5} />
                {mctaBold}
                <HorizontalSpacer x={5} />
                <MctaVerticalBar />
                <HorizontalSpacer x={5} />
                {mctaItalic}
                <HorizontalSpacer x={5} />
                <MctaVerticalBar />
                <HorizontalSpacer x={5} />
                {mctaUnderline}
                <HorizontalSpacer x={5} />
                <MctaVerticalBar />
                <HorizontalSpacer x={5} />
                <div>
                    <FontSizeSelector
                        onChangeAction={actionTypes.TEXT_MCTA_FONT_SIZE_CHANGED}
                        selected={fontSize}
                        dispatch={dispatch}
                        searchable={false}
                        menuStyle={DEFAULT_MCTA_COMBOBOX_MENUSTYLE}
                        mcta
                    />
                </div>
                <HorizontalSpacer x={5} />
                {mctaColorProperty}
                <HorizontalSpacer x={10} />
                <MctaVerticalBar />
                <HorizontalSpacer x={5} />
                {mctaLink}
                <HorizontalSpacer x={5} />
                <MctaVerticalBar />
                <HorizontalSpacer x={5} />
                <AlignmentControls
                    actionTypeToDispatch={actionTypes.TEXT_MCTA_HORIZONTAL_ALIGN_BTN_CLICKED}
                    onMouseEnterCollapsedViewActionType={actionTypes.TEXT_MCTA_ALIGN_MOUSE_ENTER}
                    onMouseLeaveExpandedViewActionType={actionTypes.TEXT_MCTA_ALIGN_MOUSE_LEAVE}
                    alignment={alignment}
                    expanded={expanded}
                    dispatch={dispatch}
                    kind={TextKind}
                />
                <HorizontalSpacer x={5} />
                <MctaVerticalBar />
                <HorizontalSpacer x={5} />
                <MctaButton
                    title={titleOrderedList}
                    icon="ORDERED_LIST"
                    selected={Boolean(orderedList)}
                    onClick={() => dispatch({
                        type: 'TEXT_MCTA_ORDERED_LIST_BTN_CLICKED',
                        payload: { 'list-style-type': 'decimal' }
                    })}
                />
                <HorizontalSpacer x={5} />
                <MctaVerticalBar />
                <HorizontalSpacer x={5} />
                <MctaButton
                    title={titleUnorderedList}
                    icon="UNORDERED_LIST"
                    selected={Boolean(unorderedList)}
                    onClick={() => dispatch({
                        type: 'TEXT_MCTA_UNORDERED_LIST_BTN_CLICKED',
                        payload: { 'list-style-type': 'disc' }
                    })}
                />
                <HorizontalSpacer x={5} />
                <MctaVerticalBar />
                <HorizontalSpacer x={5} />
                <MctaButton
                    title={titleClearFormatting}
                    icon="REMOVE_FORMAT"
                    onClick={getDispatchAction(dispatch, actionTypes.TEXT_MCTA_REMOVE_FORMAT_BTN_CLICKED)}
                />
                {children && <HorizontalSpacer x={5} />}
                {children}
            </div>
        );
    });

class MobileViewEditorV extends React.PureComponent<PropTypes> {
    bindClearFormatting: Function;
    constructor(props: PropTypes) {
        super(props);
        this.bindClearFormatting = this.clearFormatting.bind(this, props.id);
    }

    clearFormatting(id: string) {
        this.props.dispatch({
            type: actionTypes.TEXT_MOBILE_CLEAR_FORMATTING,
            payload: { id }
        });
    }

    changeTextSize(payload: { id: string, value: number }) {
        this.props.dispatch({ type: actionTypes.TEXT_MOBILE_FONT_CHANGE, payload });
    }

    render() {
        const {
                selectedComponent,
                dispatch,
                selectedComponent: { mobileSettings: { align } },
                componentExtensions,
                mobileMCTA
            } = this.props,
            { mctaHorizontalAlignControlExpanded, availableSpace } = mobileMCTA,
            {
                minSize,
                maxSize,
                textAlign = ALIGNMENT_TYPES.ALIGN_LEFT
            } = componentExtensions[selectedComponent.id] || {},
            currentTextSize = selectedComponent.mobileSettings.font || 0,
            cmpId = selectedComponent.id,
            canIncrease = maxSize < MAX_FONT_SIZE,
            canDecrease = minSize > MIN_FONT_SIZE;
        return (
            <div className={styles.textMctaStyle}>
                <MctaButton
                    btnClass={canDecrease ? '' : mctaStyles.disabled}
                    title={canDecrease ? titleTextSmaller : titleTextSmallerErr}
                    isErrorTitle={!canDecrease || undefined}
                    icon="FONT_SIZE_DECREASE"
                    onClick={canDecrease ?
                        this.changeTextSize.bind(this, { id: cmpId, value: currentTextSize - 1 }) : null}
                />
                <HorizontalSpacer x={5} />
                <MctaVerticalBar />
                <HorizontalSpacer x={5} />
                <MctaButton
                    btnClass={canIncrease ? '' : mctaStyles.disabled}
                    title={canIncrease ? titleTextBigger : titleTextBiggerErr}
                    isErrorTitle={!canIncrease || undefined}
                    icon="FONT_SIZE_INCREASE"
                    onClick={canIncrease ?
                        this.changeTextSize.bind(this, { id: cmpId, value: currentTextSize + 1 }) : null}
                />
                <HorizontalSpacer x={5} />
                <MctaVerticalBar />
                <HorizontalSpacer x={5} />
                <AlignmentControls
                    actionTypeToDispatch={MOBILE_EDITOR_ALIGNMENT_CHANGE}
                    selectedCmpId={selectedComponent.id}
                    availableSpace={availableSpace}
                    onMouseEnterCollapsedViewActionType={MOBILE_MCTA_ALIGN_MOUSE_ENTER}
                    onMouseLeaveExpandedViewActionType={MOBILE_MCTA_ALIGN_MOUSE_LEAVE}
                    alignment={align || textAlign}
                    expanded={mctaHorizontalAlignControlExpanded}
                    dispatch={dispatch}
                    kind={TextKind}
                />
                <HorizontalSpacer x={5} />
                <MctaVerticalBar />
                <HorizontalSpacer x={5} />
                <MctaButton
                    title={titleClearFormatting}
                    icon="CLEARFORMATING"
                    onClick={this.bindClearFormatting}
                />
            </div>
        );
    }
}

const calcPosition = ({ top, width, height, left }: DOMRect) => {
    return { x: left + (width / 2) - 1, y: top + height };
};

const CtaButtonClick = (
    {
        dispatch,
        selectedComponent,
        templateOffset,
        leftPanelWidth
    }: CTAViewParams<TextComponent>,
    dialogId
) => {
    const { x, y: topOffset } = templateOffset;
    const leftOffset = Math.round((x / 2) + leftPanelWidth);
    const { height, width, top, left } = selectedComponent;
    const selectedCmpDimensions = {
        x: left + leftOffset,
        y: top + topOffset,
        w: width,
        h: height
    };

    dispatch(replaceDialog(dialogId, selectedCmpDimensions));
};

const CtaButtonTextView = injectIntl((props) => {
    const mctaRef = React.useRef<HTMLDivElement>(null);
    const { intl, selectedComponent, dispatch, generalPreferences: { hideWritingAssistantTooltip } } = props;
    const selectedCmp = props.selectedComponent.content;
    let div = document.createElement('div');
    div.innerHTML = selectedCmp;
    let selectedCmpText = (div.textContent || '').trim();
    const textTooLong = selectedCmpText.length >= MAX_TEXT_LENGTH;
    const emptyText = !selectedCmpText.length;
    const editDisabled = emptyText || textTooLong;
    const [showDropDown, setShowDropDown] = useState(false);

    const getPosition = () => {
        if (mctaRef.current) {
            const
                clientRect = mctaRef.current && mctaRef.current.getBoundingClientRect(),
                { height } = clientRect || {};
            return { top: height, left: 0 };
        }
        return {};
    };

    React.useEffect(() => {
        if (mctaRef.current) {
            const
                clientRect = mctaRef.current && mctaRef.current.getBoundingClientRect(),
                { top, width, height, left } = clientRect || {};
            dispatch({
                type: actionTypes.TEXT_MCTA_UPDATE_CLIENT_POSITION,
                payload: {
                    position: calcPosition(clientRect),
                    elementDimensions: { top, width, height, left },
                }
            });
        }
    }, [selectedComponent, dispatch]);
    return <div className={cx(styles.aiTextDropdown, { [styles.expanded]: showDropDown })}>
        <div
            className={styles.aiTextIconWrapper}
            ref={mctaRef as React.RefObject<HTMLDivElement>}
            onClick={() => setShowDropDown(!showDropDown)}
        >
            <span
                className={cx(
                    {
                        [styles.aiTextIcon]: hideWritingAssistantTooltip,
                        [styles.aiTextWhiteIcon]: !hideWritingAssistantTooltip,
                    }
                )}
            />
            <span>{intl.msgJoint('msg: component.text.helpMeWrite {Help me write}')}</span>
            <span
                className={cx(styles.aiTextDropdownIcon, {
                    [styles.aiTextDropdownWhiteIcon]: !hideWritingAssistantTooltip
                })}
            />
            {showDropDown ?
                <div className={styles.aiTextDropdownContainer}>
                    <div
                        className={styles.aiTextDropdownCover}
                        onMouseDown={e => e.stopPropagation()}
                        onMouseMove={e => e.stopPropagation()}
                        onClick={() => setShowDropDown(false)}
                    />
                    <div className={cx(styles.aiTextDropdownContent)} style={{ ...getPosition() }}>
                        <button
                            onClick={() => {
                                CtaButtonClick({ dispatch, selectedComponent, ...props }, CREATE_AI_TEXT_DIALOG_ID);
                                setShowDropDown(false);
                            }}
                        >{intl.msgJoint('msg: component.text.writeNew {Write new}')}</button>
                        <button
                            data-title={(editDisabled && textTooLong) ? intl.msgJoint('msg: tcomponent.text.aiWritingAssistant.disableEdit.longTextTooltip {Text too long. Shorten it to use this feature.}') : null}
                            data-title-position="bottom"
                            disabled={editDisabled}
                            onClick={() => {
                                CtaButtonClick({ dispatch, selectedComponent, ...props }, EDIT_AI_TEXT_DIALOG_ID);
                                setShowDropDown(false);
                            }}
                        >{intl.msgJoint('msg: component.text.modifyText {Modify text}')}</button>
                    </div>
                </div> :
                null }
        </div>
    </div>;
});

const SpellcheckButtonView = injectIntl((props) => {
    const mctaRef = React.useRef<HTMLDivElement>(null);
    const { dispatch, intl } = props;
    const title = intl.msgJoint('msg: component.text.browserSpellcheck {Browser spellcheck}');
    const showHelpTooltip = () => {
        const spellcheckButton = mctaRef.current;
        if (!spellcheckButton) return;
        const btnRect = spellcheckButton.getBoundingClientRect(),
            { top, width, height, left } = btnRect;
        dispatch({
            type: actionTypes.SHOW_SPELLCHECK_TOOLTIP,
            payload: {
                position: calcPosition(btnRect),
                elementDimensions: { top, width, height, left },
                id: SpellcheckTooltip,
                customClass: styles.spellcheckTooltip,
                customTextClass: styles.spellcheckTooltipText,
                linkTextCustomClass: styles.spellcheckTooltipLink,
                buttonCustomClass: styles.spellcheckTooltipButton,
            }
        });
    };
    return <div
        ref={mctaRef as React.RefObject<HTMLDivElement>}
        className={styles.spellcheckIcon}
        onClick={showHelpTooltip}
        data-title={title}
    />;
});

export default {
    editView: EditView,
    mobileViewEditorV: (props: PropTypes) => <MobileViewEditorV {...props} />,
    editButtonText: 'msg: component.text.editText {Edit text}',
    ctaButtons: [
        {
            view: CtaButtonTextView
        },
        {
            view: SpellcheckButtonView,
            btnClass: styles.spellcheckButton,
            isVisible: ({ editMode }) => !!editMode
        }
    ]
};
