import { ALL_COUNTRIES_CODES } from "../../../../server/shared/address/constants.js";
import { renderStreetAddress } from "../oneweb/TextLike/Address/addressFormats";
import { loadScript } from "../../utils/loadScript";

import "./style.css";

export type AddressLocation = {
    lat: number;
    lng: number;
};

export type AddressViewport = {
    south: number;
    west: number;
    north: number;
    east: number;
};

export type Address = {
    address: string | null | undefined;
    addressUrl: string | null | undefined;
    addressName?: string;
    addressId: string | null | undefined;
    addressPlaceId: string | null | undefined;
    addressLocation: AddressLocation | null | undefined;
    addressViewport: AddressViewport | null | undefined;
    addressStreetAddress: string | null | undefined;
    addressCity: string | null | undefined;
    addressArea: string | null | undefined;
    addressZip: string | null | undefined;
    addressCountryCode: string | null | undefined;
    addressFloor: string | null | undefined;
};

type GeoCodeObj = {
    location?: AddressLocation;
};

type getPinCodeUsingGeocodeActionType = {
    dispatch: Dispatch;
    geoCodeObj: GeoCodeObj;
    dispatchActionType: string;
};

type AddressParamsBasedOnConditionType = {
    field: string;
    name: "long_name" | "short_name"
};

const GoogleMapsAPI = "https://maps.googleapis.com/maps/api/js?key=AIzaSyDAnpcDBvJYJkFM0645i6-AFJbeWqGALFw&libraries=places";
const GoogleMapsUrl = "https://www.google.com/maps?q=";

export const loadGoogleMapsScript = () => loadScript(GoogleMapsAPI);

export const placeToAddress = (place: Record<string, any>): Address => {
    const address: Address = {
        address: place.formatted_address,
        addressUrl: place.url,
        addressId: place.id,
        addressPlaceId: place.place_id,
        addressLocation: JSON.parse(JSON.stringify(place.geometry.location)),
        addressViewport: JSON.parse(JSON.stringify(place.geometry.viewport)),
        addressStreetAddress: "",
        addressCity: "",
        addressArea: "",
        addressZip: "",
        addressCountryCode: "",
        addressFloor: ""
    };

    if (place.name) {
        address.addressName = place.name;
    }

    if (!address.addressUrl && place.geometry?.location?.toUrlValue()) {
        address.addressUrl = `${GoogleMapsUrl}${place.geometry?.location?.toUrlValue()}`;
    }

    let addressStreetNumber, addressStreetName, addressPremise, addressSubPremise, addressCountryCode;

    let neighborhood, locality, postalTown;
    place.address_components.forEach(function (c) {
        switch (c.types.filter(x => x !== "political")[0]) {
            case "street_number":
                addressStreetNumber = c.long_name;
                break;
            case "route":
                addressStreetName = c.long_name;
                break;
            case "neighborhood":
                neighborhood = c.long_name;
                break;
            case "locality":
                locality = c.long_name;
                break;
            case "postal_town":
                postalTown = c.long_name;
                break;
            case "administrative_area_level_1": //  Note some countries don't have states
                address.addressArea = c.long_name;
                break;
            case "postal_code":
                address.addressZip = c.long_name;
                break;
            case "country":
                addressCountryCode = c.short_name;
                address.addressCountryCode = c.short_name;
                break;
            case "floor":
                address.addressFloor = c.long_name;
                break;
            case "subpremise":
                addressSubPremise = c.long_name;
                break;
            case "premise":
                addressPremise = c.long_name;
                break;
            default:
                break;
        }
    });

    const setAddressParamsBasedOnCondition = (conditions: Array<AddressParamsBasedOnConditionType>) => {
        conditions.forEach((condition: AddressParamsBasedOnConditionType) => {
            place.address_components.forEach((c) => {
                if (condition.field === c.types.filter(x => x !== "political")[0]) {
                    address.addressArea = c[condition.name];
                }
            });
        });
    };

    // Below rules are based on our current knowledge.
    // We can refactor this when we learn about more edge cases for different countries.
    if (addressCountryCode === ALL_COUNTRIES_CODES.IT) {
        //  For italy, we need to set level 2 as province
        setAddressParamsBasedOnCondition([{ field: "administrative_area_level_2", name: "short_name" }]);
    } else if (addressCountryCode === ALL_COUNTRIES_CODES.ES) {
        //  For Spain, we need to set level 2 as province
        setAddressParamsBasedOnCondition([{ field: "administrative_area_level_2", name: "long_name" }]);
    }

    address.addressCity = postalTown || locality || neighborhood || "";

    addressStreetName = [
        addressStreetName,
        address.addressCity === neighborhood ? "" : neighborhood,
        address.addressCity === locality ? "" : locality
    ]
        .filter(x => x)
        .join(", ");

    const addressPremiseAndSubPremise = [addressPremise, addressSubPremise].filter(x => x).join(", ");

    address.addressStreetAddress = renderStreetAddress({
        addressStreetNumber,
        addressStreetName,
        addressPremiseAndSubPremise,
        addressCountryCode
    });

    return address;
};

export function geocode(addressSingleLineStr: string): Promise<Address> {
    return new Promise(
        (resolve, reject) =>
            loadGoogleMapsScript().then(() => {
                const geocoder = new window.google.maps.Geocoder();

                geocoder.geocode({ address: addressSingleLineStr }, (results, status) => {
                    if (status === "OK" && results?.length) {
                        const place = results[0];
                        resolve(placeToAddress(place));
                    } else {
                        reject(status);
                    }
                });
            })
    );
}

export function getPinCodeUsingGeocode(geoCodeObj: GeoCodeObj): Promise<string> {
    return new Promise(
        (resolve, reject) =>
            loadGoogleMapsScript().then(() => {
                const geocoder = new window.google.maps.Geocoder();

                geocoder.geocode(geoCodeObj, (results, status) => {
                    if (status === "OK" && results?.length) {
                        const addressZip = results.reduce((acc, place) => {
                            return acc || placeToAddress(place).addressZip || "";
                        }, "");
                        resolve(addressZip);
                    } else {
                        reject(status);
                    }
                });
            })
    );
}

export function getPinCodeUsingGeocodeAction({ dispatch, geoCodeObj, dispatchActionType }: getPinCodeUsingGeocodeActionType) {
    getPinCodeUsingGeocode(geoCodeObj)
        .then(addressZip => {
            dispatch({
                type: dispatchActionType,
                payload: {
                    addressZip
                }
            });
        })
        .catch(() => {
            /* do nothing */
        });
}
