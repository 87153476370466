/* eslint-disable max-len */

import React from 'react';
import TabDialog, { Tab } from '../../../../view/common/dialogs/TabDialog';
import { NewPageTab } from "../constants";
import { LayoutPageTab, LayoutPageTabFooter } from './tabs/LayoutPageTab';
import LinkPageTab from './tabs/LinkPageTab';
import SectionLinkTab from './tabs/SectionLinkTab';
import { SectionLinkTabSubtitle } from './tabs/SectionLinkTab/SectionLinkTabSubtitle';
import { NEW_PAGE_SWITCH_TAB, NEW_PAGE_LINK_ADD, NEW_SECTION_LINK_ADD } from "../actions";
import { closePersistentDialog } from "../../../App/actionCreators";
import type { NewPageDialogProps } from "../flowTypes";
import { LinkPageFormLinkButton, LinkPageFormIsHiddenCheckBox } from '../../linkPageForm/view';
import { addLayoutPage } from "../actionCreators";
import styles from './newPageDialog.css';
import { StripTypes } from "../../../../view/common/dialogs/baseDialog";
import { shouldShowUpgradeMsg } from "../utils";
import { getPageLimitBySubscriptionType, getSectionLinksLimitBySubscriptionType } from '../../../App/epics/subscriptionData/utils';
import { UpgradeMessageBanner } from './UpgradeMessageBanner';
import { getPublicSectionLinks } from '../../../App/epics/siteData/utils/pages';
import { FEATURE_ADD_NEW_PAGE } from '../../../TopBar/view/Upgrade/tracking/contants';
import SectionLinkFormButton from '../../sectionLinkForm/view/SectionLinkFormButton';

export default class NewPageDialog extends React.Component<NewPageDialogProps> {
    _switchTab = (tab: any) => {
        this.props.dispatch({ type: NEW_PAGE_SWITCH_TAB, payload: tab.props.id });
    };

    _addPage = () => {
        const { activeTab, dispatch, componentsMap } = this.props;
        if (NewPageTab.LAYOUT_PAGE === activeTab) {
            const { [NewPageTab.LAYOUT_PAGE]: { page: { selectedLayout, selectedTemplate } } } = this.props;
            // @ts-ignore
            dispatch(addLayoutPage(selectedLayout, selectedTemplate, componentsMap));
        } else if (NewPageTab.LINK_PAGE === activeTab) {
            const { [NewPageTab.LINK_PAGE]: { result: linkPageResult } } = this.props;
            dispatch({ type: NEW_PAGE_LINK_ADD, payload: linkPageResult });
        } else if (NewPageTab.SECTION_LINK === activeTab) {
            const { [NewPageTab.SECTION_LINK]: { result: sectionLinkResult } } = this.props;
            dispatch({ type: NEW_SECTION_LINK_ADD, payload: sectionLinkResult });
        }
    };

    _closeDialog = () => {
        this.props.dispatch(closePersistentDialog());
    };

    renderLayoutTab() {
        const {
            [NewPageTab.LAYOUT_PAGE]: {
                page: pageProps,
                footer: footerProps,
            },
            subscriptionData,
            site,
            dispatch,
        } = this.props;

        const footer = <LayoutPageTabFooter {...footerProps} dispatch={dispatch} />;
        const maxPageLimit = getPageLimitBySubscriptionType(subscriptionData.subscriptionType);
        const pageAddLimitReached = site.getPageIdsNotMarkedAsDontPublish().length >= maxPageLimit;
        const showUpgradeMsg = shouldShowUpgradeMsg(subscriptionData.subscriptionType) && pageAddLimitReached;

        let tabIcon;
        if (showUpgradeMsg) {
            tabIcon = styles.premiumIcon;
        }

        return (
            <Tab
                label="msg: newPage.newPage.label {New page}"
                tabIcon={tabIcon}
                title="msg: newPage.newPage.newBlankPageTitle {Add a new page}"
                subTitleContent={
                    showUpgradeMsg ? <UpgradeMessageBanner trackingSource={FEATURE_ADD_NEW_PAGE} /> : undefined
                }
                id={NewPageTab.LAYOUT_PAGE}
                footer={footer}
            >
                <LayoutPageTab
                    {...pageProps}
                    addPageHandler={this._addPage}
                    dispatch={dispatch}
                    subscriptionData={subscriptionData}
                    showUpgradeMsg={showUpgradeMsg}
                />
            </Tab>
        );
    }

    renderSectionLinkTab() {
        const { [NewPageTab.SECTION_LINK]: { form }, dispatch, subscriptionData, site } = this.props;

        const maxSectionsLimit = getSectionLinksLimitBySubscriptionType(subscriptionData.subscriptionType);
        const sectionsAddLimitReached = getPublicSectionLinks(site).length >= maxSectionsLimit;
        const showUpgradeMsg = shouldShowUpgradeMsg(subscriptionData.subscriptionType) && sectionsAddLimitReached;

        let tabIcon;
        if (showUpgradeMsg) {
            tabIcon = styles.premiumIcon;
        }

        return (
            <Tab
                label="msg: newPage.sectionLink.label {Section link}"
                tabIcon={tabIcon}
                title="msg: newPage.sectionLink.title {Add a section link to your site menu}"
                titleContents={<SectionLinkFormButton dispatch={dispatch} />}
                subTitleContent={
                    <SectionLinkTabSubtitle
                        newPageLink
                        showUpgradeMsg={showUpgradeMsg}
                    />
                }
                subTitleClass={styles.subTitleClass}
                id={NewPageTab.SECTION_LINK}
                tabBodyCustomStyle={styles.sectionLinkTabBody}
            >
                <SectionLinkTab {...form} dispatch={dispatch} />
            </Tab>
        );
    }

    renderLinkTab() {
        const { [NewPageTab.LINK_PAGE]: { button, form, checkBox }, dispatch } = this.props;

        return (
            <Tab
                label="msg: newPage.link.label {Link}"
                title="msg: newPage.link.title {Add a link in your site menu}"
                titleContents={<LinkPageFormLinkButton {...button} dispatch={dispatch} />}
                footer={<LinkPageFormIsHiddenCheckBox {...checkBox} dispatch={dispatch} />}
                id={NewPageTab.LINK_PAGE}
            >
                <LinkPageTab {...form} dispatch={dispatch} />
            </Tab>
        );
    }

    render() {
        return (
            <TabDialog
                stripType={StripTypes.NONE}
                activeTabKey={this.props.activeTab}
                onTabSwitch={this._switchTab}
                mctaText="msg: common.add {Add}"
                mctaHandler={this._addPage}
                sctaHandler={this._closeDialog}
                onClose={this._closeDialog}
                disabled={!this.props.isReadyToSave}
                dispatch={this.props.dispatch}
                dialogbodyClassName={styles.newPageDialogBody}
                tabHeadingCustomStyle={styles.newPageHeading}
                closeBtnClassName={styles.dialogCloseBtn}
            >
                {this.renderLayoutTab()}
                {this.renderSectionLinkTab()}
                {this.renderLinkTab()}
            </TabDialog>
        );
    }
}
