import { $Values } from "utility-types";
import * as R from "ramda";
import { immerable } from "immer";
import type { Path } from '../../src/mappers/path';
import { mapDataSiteItem, isPageRef, cbGetDataPageRef, isBookingsPage } from "./utils/dataSiteItemUtils";
import type { DataSiteItem } from "./utils/dataSiteItemUtils"; // eslint-disable-line
import { getByPath } from "../../src/utils/ramdaEx";
import type { DataPageRefUrlPath } from "./DataPageRef";
import DataPageRef from './DataPageRef';
import DataSectionLink from './DataSectionLink';
import pathsAreEqual from "../../src/utils/pathsAreEqual";
import { SIMPLESITE } from "../../../server/shared/constants.js";

const DataSiteErrorMessage = {
    MISSING_HOME_PAGE: 'Cannot find home page'
};

type Predicate<ItemType> = (item: ItemType) => boolean;
type Callback<ItemType> = (item: ItemType) => void;
export type DataPageRefUrlPathList = Array<DataPageRefUrlPath>;
export type DataSiteFolder = { id: string, items: Array<DataSiteItem> };
type FindItemParams<ItemType> = {
    predicate: Predicate<ItemType>;
    required?: boolean;
    missingItemMessage?: $Values<typeof DataSiteErrorMessage>;
};
export type SiteDataOrigin = {
    from: string,
    customerId: number,
    version: string
};

const makePageRefParentPredicate = childId =>
    (pageRef: DataPageRef) => !!pageRef.items.find(child => child.id === childId);

const getBottomMostItem = (item: DataSiteItem): DataSiteItem => (
    isPageRef(item) && item.items.length
        ? (getBottomMostItem(item.items.slice(-1)[0]))
        : item
);

const getSectionLinks = (items: Array<DataSiteItem>): Array<DataSectionLink> => {
    const sectionLinks: DataSectionLink[] = [];
    items.forEach(item => {
        if (item instanceof DataSectionLink) {
            sectionLinks.push(item);
        } else if (item instanceof DataPageRef && item.getItems() && item.getItems().length) {
            sectionLinks.push(...getSectionLinks(item.getItems()));
        }
    });
    return sectionLinks;
};

class DataSite {
    type!: "web.data.Site";
    name!: 'universal';
    id!: 'site';
    homePageId!: string;
    folder: DataSiteFolder;
    fonts: Array<string>;
    dataVersionNumber!: number;
    time!: number;
    etag!: string;
    rev!: number;
    activateMobileView!: boolean;
    themingOnByDefault!: boolean;
    createdTimestamp: null | undefined | number;
    origin: null | undefined | SiteDataOrigin;

    constructor(data: Record<string, any>) {
        // https://immerjs.github.io/immer/complex-objects/

        this[immerable] = true;
        Object.assign(this, data);
        this.folder = { ...data.folder };
        // @ts-ignore
        this.fonts = this.fonts || [];  /* WBTGEN-5490 - Old customers' sitemap data may have fonts as null */
        this.folder.items = data.folder.items.map(item => mapDataSiteItem(item));
    }

    getItems(): Array<DataSiteItem> {
        return this.folder.items;
    }

    getPageIds(): Array<string> {
        const ids: string[] = [];
        const find = (item: DataSiteItem) => {
            if (isPageRef(item)) {
                ids.push((item as any).pageId);
                if ((item as any).items.length) {
                    (item as any).items.forEach(find);
                }
            }
        };
        this.getItems().forEach(find);

        return ids;
    }

    getAllItems(): Array<DataSiteItem> {
        const items: DataSiteItem[] = [];
        const find = (item: DataSiteItem) => {
            items.push(item);
            if ((item as any)?.items?.length) {
                (item as any).items.forEach(find);
            }
        };
        this.getItems().forEach(find);

        return items;
    }

    getPageIdsNotMarkedAsDontPublish() {
        const ids: string[] = [];
        const find = (item: DataSiteItem) => {
            if (isPageRef(item)) {
                if (item.public) {
                    ids.push((item as any).pageId);
                }

                if ((item as any).items.length) {
                    (item as any).items.forEach(find);
                }
            }
        };
        this.getItems().forEach(find);

        return ids;
    }

    /**
     * Returns a COPY tree of DataPageRef objects only.
     */
    getPageRefs(): Array<DataPageRef> {
        const pages: DataPageRef[] = [];
        this.getItems().forEach(item => {
            if (isPageRef(item)) pages.push(new DataPageRef({ ...item }, true /* skipLinkPages */));
        });
        return pages;
    }

    /**
     * Returns a COPY of all tree of DataPageRef objects only.
     */
    getAllPageRefs(): Array<DataPageRef> {
        const pages = [];
        this.getItems()
            .filter(item => isPageRef(item))
            .forEach(cbGetDataPageRef.bind(null, pages));
        return pages;
    }

    getAllSectionLinkRefs(): Array<DataSectionLink> {
        return getSectionLinks(this.getItems());
    }

    getAllBookingPages(): Array<DataPageRef> {
        return this.getAllPageRefs().filter(isBookingsPage);
    }

    /**
     * @param inPath - Only indices of items are passed. E.g. [0, 1, 3] or '1.3.0'
     */
    getItemByPath(inPath: Path | string): null | undefined | DataSiteItem {
        const
            indices = Array.isArray(inPath) ? inPath : inPath.split('.'),
            path = R.pipe(R.map, R.flatten)(i => ['items', i], indices);
        return getByPath(path, this.folder) || null;
    }

    getItemById(id: string): DataSiteItem {
        // @ts-ignore
        return this._findItem({
            predicate: item => (item.id === id),
            missingItemMessage: `Cannot find item by id: ${id}`
        });
    }

    getPageRefById(id: string): DataPageRef {
        const page = this.getItemById(id);
        if (!isPageRef(page)) throw new Error(`Expecting PageRef by id: ${id}`);
        return page;
    }

    getOptionalPageRefById(id: string): null | undefined | DataPageRef {
        return this._findPageRef({ predicate: item => item.id === id, required: false });
    }

    getHomePageRef(): null | undefined | DataPageRef {
        if (!this.homePageId) return null;
        return this._findPageRef({
            predicate: page => page.pageId === this.homePageId,
            missingItemMessage: DataSiteErrorMessage.MISSING_HOME_PAGE
        });
    }

    getPageRefParent(childId: string): null | undefined | DataPageRef {
        return this._findPageRef({
            predicate: makePageRefParentPredicate(childId),
            required: false /* not required */
        });
    }

    getPageRefParentPageId(childId: string): null | undefined | string {
        const parent = this.getPageRefParent(childId);
        return parent ? parent.pageId : null;
    }

    getPageRefParentOrRoot(childId: string): DataPageRef | DataSiteFolder {
        let parent;
        if (this.folder.items.find((item: DataSiteItem) => (item.id === childId))) parent = this.folder;
        else parent = this._findPageRef({ predicate: makePageRefParentPredicate(childId) });
        return parent;
    }

    getPageRefOrRoot(id: null | undefined | string): DataPageRef | DataSiteFolder {
        if (id) return this.getPageRefById(id);
        return this.folder;
    }

    isHomePageId(pageId: string): boolean {
        return !!(this.homePageId && this.homePageId === pageId);
    }

    isRootPage(id: string): boolean {
        return !!this.getItems().find((item: DataSiteItem) => (isPageRef(item) && item.id === id));
    }

    isSimpleSiteCustomer(): boolean {
        let isSimepleSiteUser = false;
        if (this.origin) {
            isSimepleSiteUser = this.origin.from === SIMPLESITE;
        }
        return isSimepleSiteUser;
    }

    isLastRootPageRef(id: string): boolean {
        return this.isRootPage(id) && this.getItems().filter(p => isPageRef(p)).length === 1;
    }

    // root page will always be DataPageRef
    getTopMostRootPage() {
        return this.getItems()[0];
    }

    // root page will always be DataPageRef
    getBottomMostRootPage() {
        return this.getItems().slice(-1)[0];
    }

    isBottomMostRootPage(id: string): boolean {
        return this.getBottomMostRootPage().id === id;
    }

    isBottomMostPage(id: string): boolean {
        const items = this.getItems();
        if (!items.length) throw new Error('No data site items found');

        return getBottomMostItem(items.slice(-1)[0]).id === id;
    }

    getPageRefByPageId(pageId: string): DataPageRef {
        // @ts-ignore
        return this._findPageRef({ predicate: page => (page.pageId === pageId) });
    }

    getOptionalPageRefByPageId(pageId: string): null | undefined | DataPageRef {
        return this._findPageRef({ predicate: page => (page.pageId === pageId), required: false /* optional */ });
    }

    getAllPageRefsUrlPaths(excludeItemId?: string): DataPageRefUrlPathList {
        const
            paths: string[][] = [],
            currentPath: string[] = [];

        const find = (item: DataSiteItem) => {
            if (excludeItemId && excludeItemId === item.id) return;

            if (item instanceof DataPageRef) {
                paths.push([...currentPath, item.url]);
                if (item.items.length) {
                    currentPath.push(item.url);
                    item.items.forEach(find);
                    currentPath.pop();
                }
            }
        };

        this.getItems().forEach(find);

        return paths;
    }

    getPageRefUrlPathToRootParent(pageId: string): Array<string> {
        const prefixPath: string[] = [];

        let needle: DataPageRef | boolean = false;
        const find = (item: DataSiteItem) => {
            if (needle) return;

            if (item instanceof DataPageRef) {
                if (item.pageId === pageId) {
                    needle = item;
                    return;
                } else if (item.items.length) {
                    // build path recursively
                    prefixPath.push(item.name);
                    item.items.forEach(find);
                    if (!needle) prefixPath.pop();
                }
            }
        };
        this.getItems().forEach(find);

        if (!needle) throw new Error('Cannot find page ref');

        return [...prefixPath, (needle as DataPageRef).name];
    }

    getPageRefUrlPath(id: string): DataPageRefUrlPath {
        const prefixPath: string[] = [];

        let needle: DataPageRef | boolean = false;
        const find = (item: DataSiteItem) => {
            if (needle) return;

            if (item instanceof DataPageRef) {
                if (item.id === id) {
                    needle = item;
                    return;
                } else if (item.items.length) {
                    // build path recursively
                    prefixPath.push(item.url);
                    item.items.forEach(find);
                    if (!needle) prefixPath.pop();
                }
            }
        };
        this.getItems().forEach(find);

        if (!needle) throw new Error('Cannot find page ref');

        return [...prefixPath, (needle as DataPageRef).url];
    }

    isDuplicatePageRefUrlPath(path: DataPageRefUrlPath): boolean {
        return !!this.getAllPageRefsUrlPaths().find(p => pathsAreEqual(p, path));
    }

    pageRefContainsPageId(pageRefId: string, pageId: string): boolean {
        let needle = false;
        const find = (page: DataSiteItem) => {
            if (needle) return;
            if (isPageRef(page)) {
                if (page.pageId === pageId) {
                    needle = true;
                } else page.items.forEach(find);
            }
        };
        // @ts-ignore
        find(this._findPageRef({ predicate: page => (page.id === pageRefId) }));

        return needle;
    }

    pageRefContainsPageRefId(containerPageRefId: string, pareRefId: string): boolean {
        let needle = false;
        const find = (page: DataSiteItem) => {
            if (needle) return;
            if (isPageRef(page)) {
                if (page.id === pareRefId) {
                    needle = true;
                } else page.items.forEach(find);
            }
        };
        // @ts-ignore
        find(this._findPageRef({ predicate: page => (page.id === containerPageRefId) }));

        return needle;
    }

    forEachPageRefInSubTree(parentPageRefId: string, callback: Callback<DataPageRef>) {
        const walk = (node: DataSiteItem) => {
            if (isPageRef(node)) {
                callback(node);

                node.items.forEach(walk);
            }
        };
        this.getPageRefById(parentPageRefId).items.forEach(walk);
    }

    _findPageRef({ predicate, required, missingItemMessage }: FindItemParams<DataPageRef>): null | undefined | DataPageRef {
        // @ts-ignore
        return this._findItem({
            predicate: (item: DataSiteItem) => (isPageRef(item) && predicate(item)),
            required,
            missingItemMessage
        });
    }

    _findItem({
        predicate,
        required = true,
        missingItemMessage = 'Cannot find site item'
    }: FindItemParams<DataSiteItem>): null | undefined | DataSiteItem {
        let needle: DataSiteItem | null = null;
        const find = (item: DataSiteItem) => {
            if (needle) return;
            else if (predicate(item)) needle = item;

            else if (isPageRef(item)) item.items.forEach(find);
        };
        this.getItems().forEach(find);

        if (required && !needle) throw new Error(missingItemMessage);

        return needle;
    }
}

export { DataSite as default, DataSiteErrorMessage, DataSite };
