/* eslint-disable react/jsx-fragments */
/* eslint-disable max-len */
import type { AnyComponentData } from "../../../App/flowTypes";
import type { ComponentBaseWithKind } from "../../flowTypes";
import { makePlainMappers, applyMappers } from '../../../../../dal/pageMapAdapter/utils';
import makeEpic from '../../../../epics/makeEpic';
import { openSiteSettingsDialog } from '../../../SiteSettings/SiteSettingsDialog/actionCreators';
import { SiteSettingsTabName } from '../../../SiteSettings/SiteSettingsDialog/constants';
import { optional, receiveOnly } from '../../../../epics/makeCondition';
import * as selectors from '../../../Workspace/epics/stylesheets/selectors';
import {
    TEXT_LIKE_COMPONENT_TOOLTIP_GUTTER,
    TEXT_LIKE_COMPONENT_TOOLTIP_POINTER_HEIGHT,
    TEXT_LIKE_COMPONENT_TOOLTIP_WIDTH,
} from '../constants';
import type { CmpSpecificStyles } from "../../../Preview/flowTypes";
import { COMPONENT_ENTERED_EDIT_MODE_USING_DOUBLE_CLICK } from '../../../Workspace/epics/componentsEval/actionTypes';
import { EditModeComponentIdSelector } from '../../../Workspace/epics/componentsEval/selectorActionTypes';
import {
    HIDE_TOOLTIP_FOR_ADDRESS,
    HIDE_TOOLTIP_FOR_EMAIL,
    HIDE_TOOLTIP_FOR_PHONE,
    SHOW_TOOLTIP_FOR_ADDRESS,
    SHOW_TOOLTIP_FOR_EMAIL,
    SHOW_TOOLTIP_FOR_PHONE,
    NEVER_SHOW_TOOLTIP_AGAIN_FOR_ADDRESS,
    NEVER_SHOW_TOOLTIP_AGAIN_FOR_EMAIL,
    NEVER_SHOW_TOOLTIP_AGAIN_FOR_PHONE,
} from '../../../Tooltip/actionTypes';
import { PHONE_KIND } from '../Phone/kind';
import { EMAIL_KIND } from '../Email/kind';
import { ADDRESS_KIND } from '../Address/kind';
import { KindToInputId } from "../kindToInputId";
import templateOffsetVAT from '../../Template/epics/templateOffset/valueActionType';
import { browserDimensionsVAT } from '../../../App/epics/browserDimensions/valueActionType';
import { PointerDirections } from '../../../Tooltip/constants';
import { mobileViewEditorVAT } from '../../../MobileViewEditor/epics/reorder/valueActionType';
import { WORKSPACE_READY } from '../../../Workspace/actionTypes';

import type { SiteSettingsDialogConfigWithInput } from '../../../SiteSettings/SiteSettingsDialog/actionCreators';
import type {
    ComponentState,
    Props,
} from "../types";
import { fontSizeToDefaultIconSpacing } from "../utils/fontSizeToDefaultIconSpacing";
import { capTextFontSize } from "../utils/capTextFontSize";
import { textLikeCalcRenderProps } from "./textLikeCalcRenderProps";
import { computePrefixMarginBottom } from "../utils/computePrefixMarginBottom";

const kindToShowTooltipAction = {
    [PHONE_KIND]: SHOW_TOOLTIP_FOR_PHONE,
    [EMAIL_KIND]: SHOW_TOOLTIP_FOR_EMAIL,
    [ADDRESS_KIND]: SHOW_TOOLTIP_FOR_ADDRESS
};
const kindToHideTooltipAction = {
    [PHONE_KIND]: HIDE_TOOLTIP_FOR_PHONE,
    [EMAIL_KIND]: HIDE_TOOLTIP_FOR_EMAIL,
    [ADDRESS_KIND]: HIDE_TOOLTIP_FOR_ADDRESS
};
const makeDoNotShowAgainStorageItem = kind => `DO_NOT_SHOW_${kind}_EDIT_TOOLTIP_AGAIN`;
const makeNeverShowTooltipAgainUpdater = ({ actionType, kind }) => ({
    conditions: [actionType],
    reducer: ({ values: [isNeverShowChecked], state }) => {
        localStorage.setItem(makeDoNotShowAgainStorageItem(kind), isNeverShowChecked);
        return { state };
    },
});

export const replaceSpaceWithNbsp = (s: string | null | undefined) => s && s.replace(/ /g, " ");

const horizontalAlignmentToMarginProp = horizontalAlignment => {
    if (horizontalAlignment === 'left') return 'margin-right';
    if (horizontalAlignment === 'right') return 'margin-left';
    return 'margin-bottom';
};

export const textLikeComponentFactory = ({
    View,
    kind,
    computeComponentDependenciesForPreview,
    epicUpdaters,
    getPlaceholderText
}: Props<any>) => {
    const TEXT_LIKE_PP_ADD_OR_UPDATE_VALUE_BTN_PRESSED = 'TEXT_LIKE_PP_ADD_OR_UPDATE_VALUE_BTN_PRESSED_' + kind;
    const TEXT_LIKE_MCTA_ADD_OR_UPDATE_VALUE_BTN_PRESSED = 'TEXT_LIKE_MCTA_ADD_OR_UPDATE_VALUE_BTN_PRESSED' + kind;

    const epic = makeEpic({
        valueActionType: kind + "_EPIC_VALUE",
        defaultState: {},
        updaters: [
            {
                conditions: [
                    optional(TEXT_LIKE_PP_ADD_OR_UPDATE_VALUE_BTN_PRESSED),
                    optional(TEXT_LIKE_MCTA_ADD_OR_UPDATE_VALUE_BTN_PRESSED)
                ],
                reducer: ({ state }) => {
                    return {
                        state,
                        multipleActionsToDispatch: [
                            openSiteSettingsDialog(({
                                activeTabKey: SiteSettingsTabName.GENERAL,
                                activateInputWithId: KindToInputId[kind]
                            } as SiteSettingsDialogConfigWithInput)),
                        ]
                    };
                }
            },
            {
                conditions: [EditModeComponentIdSelector, receiveOnly(WORKSPACE_READY)],
                reducer: ({ state, values: [editModeComponentId] }) => (
                    editModeComponentId ? { state } : { state, actionToDispatch: { type: kindToHideTooltipAction[kind] } }
                ),
            },
            {
                conditions: [
                    COMPONENT_ENTERED_EDIT_MODE_USING_DOUBLE_CLICK,
                    receiveOnly(templateOffsetVAT),
                    receiveOnly(browserDimensionsVAT),
                    receiveOnly(mobileViewEditorVAT),
                ],
                reducer: ({
                    values: [dblClickedComponent, templateOffset, browserDimensions, { show: isMVEActive }],
                    state,
                }) => {
                    if (
                        !isMVEActive &&
                        dblClickedComponent.kind === kind &&
                        localStorage.getItem(makeDoNotShowAgainStorageItem(kind)) !== 'true'
                    ) {
                        let x = templateOffset.x + dblClickedComponent.left;
                        let y = templateOffset.y + dblClickedComponent.top + Math.round(dblClickedComponent.height / 2);
                        let pointerDirection = PointerDirections.Right;
                        if (x < browserDimensions.width - (templateOffset.x + dblClickedComponent.left + dblClickedComponent.width)) {
                            x = templateOffset.x + dblClickedComponent.left + dblClickedComponent.width;
                            pointerDirection = PointerDirections.Left;
                        }
                        const spaceForTooltipInHorizontalDirection =
                            (pointerDirection === PointerDirections.Right ? x : (browserDimensions.width - x));
                        // ignoring scrollbar in the calculation as an edge case
                        if (TEXT_LIKE_COMPONENT_TOOLTIP_WIDTH + TEXT_LIKE_COMPONENT_TOOLTIP_POINTER_HEIGHT + TEXT_LIKE_COMPONENT_TOOLTIP_GUTTER > spaceForTooltipInHorizontalDirection) {
                            x = templateOffset.x + dblClickedComponent.left + Math.round(dblClickedComponent.width / 2);
                            y = templateOffset.y + dblClickedComponent.top + dblClickedComponent.height;
                            pointerDirection = PointerDirections.Top;
                            // ignoring the PointerDirections.Bottom as an edge case, and since we don't have the tooltip's height
                        }
                        return {
                            state,
                            actionToDispatch: { type: kindToShowTooltipAction[kind], payload: { x, y, pointerDirection } },
                        };
                    } else {
                        return { state };
                    }
                }
            },
            ...[
                { actionType: NEVER_SHOW_TOOLTIP_AGAIN_FOR_ADDRESS, kind: ADDRESS_KIND },
                { actionType: NEVER_SHOW_TOOLTIP_AGAIN_FOR_EMAIL, kind: EMAIL_KIND },
                { actionType: NEVER_SHOW_TOOLTIP_AGAIN_FOR_PHONE, kind: PHONE_KIND },
            ].map(makeNeverShowTooltipAgainUpdater),
            ...((epicUpdaters as any) || []) // NOSONAR
        ]
    });

    const plainPropsMapper = makePlainMappers({
        mobileHide: 'mobileHide',
        generic: 'generic',
        specific: 'specific',
        modernLayoutOptions: 'modernLayoutOptions',
    });

    const previewConfig = {
        kind,
        view: View,
        calcProps: (props: any) => {
            const { component, siteSettings, stylesheetsIdToNameMap, globalStyles, viewerDomain, messages, globalVariables, locale, selectedParentTheme, siteSettings: { themeSettingsData } } = props;
            return textLikeCalcRenderProps(getPlaceholderText)({
                componentDependencies: {
                    stylesheetsIdToNameMap,
                    stylesheets: globalStyles,
                    ...computeComponentDependenciesForPreview({
                        isTemplatePreview: !!viewerDomain,
                        siteSettings,
                        globalVariables: globalVariables || {}
                    }),
                    globalVariables,
                    themeSettingsData
                },
                selectedParentTheme,
                component,
                isWorkspace: false,
                viewerDomain,
                messages,
                locale
            });
        },
        getSpecificStyles: ({ component, globalStyles: stylesheets }: CmpSpecificStyles<ComponentState<any>>): string => {
            const componentMobileSelector = `.mobileV div[data-id='${component.id}']`;
            const textNormalStyle = selectors.textNormalGlobalstyle(stylesheets);
            const textSize = component.generic.mobileFontSize || capTextFontSize(component.generic.textStyle.fontSize || textNormalStyle.size);
            const iconSize = component.generic.mobileIconSize || component.generic.iconSize;
            const mobileHorizontalAlignmentLeft = component.generic.mobileHorizontalAlignment === 'left';
            const mobileHorizontalAlignmentRight = component.generic.mobileHorizontalAlignment === 'right';
            const mobileHorizontalAlignmentCenter = component.generic.mobileHorizontalAlignment === 'center';
            const mobileHorizontalAlignmentLeftOrRight = mobileHorizontalAlignmentLeft || mobileHorizontalAlignmentRight;

            return (
                (component.generic.horizontalAlignment !== 'center' ? `div[data-id='${component.id}'] { display: flex; width: 100%; }\n` : '') +
                `
                    ${componentMobileSelector} div.textnormal,
                    ${componentMobileSelector} span,
                    ${componentMobileSelector} :not(.svgContainer) a {
                        font-size: ${textSize}px !important;
                    }\n` +
                `
                    ${componentMobileSelector} .svgContainer,
                    ${componentMobileSelector} .svgContainer a {
                        font-size: ${iconSize}px !important;
                    }\n` +
                (component.generic.mobileHorizontalAlignment ? `${componentMobileSelector} .textnormal { text-align: ${component.generic.mobileHorizontalAlignment} !important; }\n` : '') +
                (component.generic.mobileHorizontalAlignment ? `${componentMobileSelector} .svgContainer { ${horizontalAlignmentToMarginProp(component.generic.horizontalAlignment)}: 0px !important; }\n` : '') +
                `${componentMobileSelector} .svgContainer { ${horizontalAlignmentToMarginProp(component.generic.mobileHorizontalAlignment || component.generic.horizontalAlignment)}: ${fontSizeToDefaultIconSpacing(textSize)}px !important; }\n` +
                (mobileHorizontalAlignmentCenter ? `${componentMobileSelector} .textnormal { flex-direction: column !important; }\n` : '') +
                (mobileHorizontalAlignmentLeftOrRight ? `${componentMobileSelector} .textnormal { flex-direction: row !important; }\n` : '') +
                (mobileHorizontalAlignmentRight ? `${componentMobileSelector} .textnormal { flex-direction: row-reverse !important; }\n` : '') +
                `
                    ${componentMobileSelector} .svgContainer,
                    ${componentMobileSelector} .svgContainer a,
                    ${componentMobileSelector} .svgContainer svg {
                        width: ${iconSize}px !important;
                        height: ${iconSize}px !important;
                    }\n` +
                `${componentMobileSelector} .prefix { margin-bottom: ${computePrefixMarginBottom(textSize, component.generic.textStyle.lineHeight)}px !important; }\n`
            );
        }
    };

    return {
        mappers: {
            to: (componentData: AnyComponentData): ComponentBaseWithKind => applyMappers(
                componentData,
                plainPropsMapper.to
            ),
            back: (component: ComponentBaseWithKind): AnyComponentData => {
                return applyMappers(
                    component,
                    plainPropsMapper.back
                );
            }
        },
        epic,
        previewConfig
    };
};

