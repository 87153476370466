import React, { useEffect, useMemo, useRef, useState } from "react";
import { getDAL } from '../../../../../../dal';
import LoadingIndicator from '../../../../../view/common/LoadingIndicator';
import { getAppConfig } from "../../../../App/epics/appConfig/appConfig.js";
import { BACKGROUND_THEME_WHITE } from '../../../../ThemeGlobalData/constants';
import { getBlogPreviewReqData, getThemeColorData } from '../../utils';
import { getBlogHtml } from './getBlogHtml';
import { BlogLoadFailureMessage } from "./errorMessage";
import { BLOG_MIN_HEIGHT } from '../constants';
import Measure from 'react-measure';
import { addComponentAdjustmentDataEntry } from "../../../../Workspace/epics/componentsEval/adjustmentDataDispatchCache";

const View = ({
    component: { id, blogId, layout, height },
    siteData,
    currentPageId,
    locale,
    globalstyles,
    generalData: { websiteCategories },
    selectedParentTheme,
    autoColorMode,
    refreshId,
    isMVEFocus
}) => {
    const contentRef = useRef<HTMLDivElement | null>(null);
    const measureTid = useRef<NodeJS.Timeout | undefined>();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    // Get the theme colors from the global styles
    const themeColors = useMemo(() => {
        return getThemeColorData(globalstyles);
    }, [globalstyles]);

    // Fetch and render the HTML when the component mounts
    useEffect(() => {
        const aborter = new AbortController();
        const signal = aborter.signal;
        const updateContent = async () => {
            setLoading(true);
            setError(false);
            try {
                const data = getBlogPreviewReqData({
                    appConfig: getAppConfig(),
                    siteMap: siteData,
                    pageId: currentPageId,
                    domain: getDAL().getDomain(),
                    blogId,
                    layout,
                    isServerPreview: false,
                    locale,
                    websiteCategory: websiteCategories,
                    selectedParentTheme,
                });
                const { blogHostName, partnerName, siteId, dataToPost } = data;
                const result = await getBlogHtml(`${blogHostName}/preview/${partnerName}/${siteId}`, dataToPost);
                if (signal.aborted) return;

                // This DOM manipulation is required, because script tags does not
                // execute when it is set directly as raw HTML or dangerouslySetHTML
                const el = contentRef.current!; // blog element root
                el.replaceChildren();

                // load and add styles
                await Promise.all(
                    result.styles.map((href: string) => {
                        return new Promise((resolve, reject) => {
                            const link = document.createElement("link");
                            link.rel = "stylesheet";
                            link.addEventListener('load', () => resolve(link));
                            link.addEventListener('error', (err) => reject(err));
                            link.href = href;
                            if (signal.aborted) return;
                            el.appendChild(link);
                        });
                    }),
                );
                if (signal.aborted) return;

                // add content
                const div = document.createElement('div');
                div.innerHTML = result.content;
                el.append(...div.children);

                // add scripts
                result.scripts.forEach((src: string) => {
                    const script = document.createElement("script");
                    script.src = src;
                    el.appendChild(script);
                });
                setLoading(false);
            } catch (err) {
                setError(true);
                setLoading(false);
            }
        };
        updateContent();
        return () => aborter.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [blogId, layout, locale, refreshId]);

    // Makes theme colors available as CSS variable for the Blog elements only
    useEffect(() => {
        if (loading || error) return;
        // This makes the current theme colors available as CSS variable.
        // It is required for functionality in Blog, and effective in workspace only.
        // Doing it here reduces API calls to the Blog server every time theme colors changes.
        const el = contentRef.current; // blog element root
        if (el && themeColors) {
            const { mainColor, accentColor, whiteColor, blackColor } = themeColors;
            el.style.setProperty("--main-color", mainColor, 'important');
            el.style.setProperty("--accent-color", accentColor, 'important');
            el.style.setProperty("--white-color", whiteColor, 'important');
            el.style.setProperty("--black-color", blackColor, 'important');
        }
    }, [loading, error, themeColors]);

    // Updates the current theme class for the Blog elements only
    useEffect(() => {
        if (loading || error) return;
        // This applies the `selectedParentTheme` to all the elements inside Blog section where it
        // is being used, and effective in the workspace only.
        // Doing it here reduces API calls to the Blog server every time the theme changes.
        const theme = autoColorMode ? selectedParentTheme : BACKGROUND_THEME_WHITE;
        const el = contentRef.current as HTMLElement; // blog element root
        el.querySelectorAll("[data-theme-class]").forEach((themeEl: Element) => {
            const currentThemeClass = themeEl.getAttribute('data-theme-class');
            if (currentThemeClass !== selectedParentTheme) {
                if (currentThemeClass) {
                    themeEl.classList.remove(currentThemeClass);
                }
                if (theme) {
                    themeEl.classList.add(theme);
                    themeEl.setAttribute('data-theme-class', theme);
                }
            }
        });
    }, [loading, error, selectedParentTheme, autoColorMode]);

    const onResize = ({ offset }: MeasureBounds) => {
        if (isMVEFocus) { return; }
        clearTimeout(measureTid.current);
        measureTid.current = setTimeout(() => {
            const updatedHeight = Math.max(Math.round(offset.height), BLOG_MIN_HEIGHT);
            addComponentAdjustmentDataEntry(id, { minDimensions: { height: updatedHeight } });
        }, 100);
    };

    return (
        <Measure offset onResize={onResize}>
            {({ measureRef }) => (
                <div ref={measureRef} style={{ width: "100%" }}>
                    {loading && <LoadingIndicator data-testid="loading-indicator" height={height} />}
                    {error && <BlogLoadFailureMessage data-testid="error-message" className={selectedParentTheme} />}
                    <div
                        ref={contentRef}
                        data-testid="blog-view"
                        style={{ width: "100%", display: loading || error ? "none" : undefined }}
                    />
                </div>
            )}
        </Measure>
    );
};

export default View;
