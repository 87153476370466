import React from "react";
import { IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { CloseIcon } from "../../../common/icons";

const useStyles = makeStyles(
    (theme) => ({
        container: {
            padding: "32px",
            userSelect: "none"
        },
        closeButton: {
            position: "absolute",
            right: "0",
            top: "0",
            cursor: "pointer",
        },
        tooltipContent: {
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            [theme.breakpoints.down('md')]: {
                flexDirection: "column",
            },
        },
    }),
    { name: "SideBarItemTooltip" }
);

type SideBarItemTooltipProps = React.PropsWithChildren<{
    onClose: () => void;
}>;

export const SideBarItemTooltip = ({ children, onClose, ...rest }: SideBarItemTooltipProps) => {
    const classes = useStyles();

    const stopChildElementClickAction = (event: React.MouseEvent<HTMLElement>) => event.stopPropagation();

    return (
        <div className={classes.container} onClick={stopChildElementClickAction} {...rest}>
            <IconButton
                aria-label="close"
                className={classes.closeButton}
                onClick={onClose}
                data-testid="tooltip-close-icon"
                size="large"
                disableRipple
            >
                <CloseIcon />
            </IconButton>
            <div className={classes.tooltipContent}>
                {children}
            </div>
        </div>
    );
};
