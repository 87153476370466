import valueActionType from './valueActionType';
import makeEpic from "../../../../../epics/makeEpic";
import { receiveOnly } from '../../../../../epics/makeCondition';
import { SITE_SUBSCRIPTION_DATA_LOAD_SUCCESS } from "../../../../App/actionTypes";
import { FEATURED_PRODUCTS_UPDATE_SUBSCRIPTION_COMPATIBILITY_STATUS } from '../../actionTypes';
import { NEW_COMPONENT_CHECK_COMPONENT_TIER_ON_DROP } from "../../../../ComponentTierManager/actionTypes";
import { SubscriptionTypeSelector } from "../../../../App/epics/subscriptionData/selectorActionTypes";
import FeaturedProductsComponentKind from '../../kind';
import ProductWidgetKind from '../../../ProductWidget/kind';
import { checkSubscriptionCompatibilityFromKind } from "../../../../ComponentTierManager/utils";
import webshopSubscriptionVAT from "../../../WebShop/epics/subscription/valueActionType";

const defaultState = {
    isSubscriptionTypeCompatible: false
};
export default makeEpic({
    defaultState,
    valueActionType,
    updaters: [
        {
            conditions: [
                receiveOnly(webshopSubscriptionVAT),
                SITE_SUBSCRIPTION_DATA_LOAD_SUCCESS,
            ],
            reducer: ({
                state,
                values: [{ webshoptSubscriptionData, subscriptionType }]
            }) => {
                const isSubscriptionTypeCompatible = checkSubscriptionCompatibilityFromKind(
                    FeaturedProductsComponentKind,
                    subscriptionType
                ) || !!webshoptSubscriptionData?.onlineShopSetupApiResponse?.isSIATCustomer;
                return {
                    state: {
                        ...state,
                        isSubscriptionTypeCompatible,
                    },
                    actionToDispatch: {
                        type: FEATURED_PRODUCTS_UPDATE_SUBSCRIPTION_COMPATIBILITY_STATUS,
                        payload: { isSubscriptionTypeCompatible }
                    }
                };
            }
        },
        {
            conditions: [
                receiveOnly(webshopSubscriptionVAT),
                SubscriptionTypeSelector,
                NEW_COMPONENT_CHECK_COMPONENT_TIER_ON_DROP
            ],
            reducer: ({ state, values: [webshoptSubscriptionData, subscriptionType, componentsData] }) => {
                const { kind } = componentsData;
                if (kind === FeaturedProductsComponentKind || kind === ProductWidgetKind) {
                    const isSubscriptionTypeCompatible = checkSubscriptionCompatibilityFromKind(
                        FeaturedProductsComponentKind, // Any one of OS kind is fine. As of now, all are having same behaviour.
                        subscriptionType
                    ) || !!webshoptSubscriptionData?.onlineShopSetupApiResponse?.isSIATCustomer;
                    return {
                        state: {
                            ...state,
                            isSubscriptionTypeCompatible,
                        },
                        actionToDispatch: {
                            type: FEATURED_PRODUCTS_UPDATE_SUBSCRIPTION_COMPATIBILITY_STATUS,
                            payload: { isSubscriptionTypeCompatible }
                        }
                    };
                }

                return {
                    state
                };
            }
        }
    ]
});
