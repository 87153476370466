import * as React from 'react';
import cx from 'classnames';
import styles from './FmCombobox.css';
import type { MsgJointInput } from '../../../view/intl/index';
import { injectIntl, Intl, isMsgJointInput } from '../../../view/intl/index';
import { TooltipPosition } from '../../presentational/Tooltip/constants';
import { DemoTip } from '../../../../demo/modules/tip/DemoTip';
import { FM_UPLOAD_MENU_FROM_COMPUTER_VALUE } from "../../../view/common/FileChooser/constants";
import { SecondaryButton } from '../../../view/common/Button';

export type FmComboboxOption = {
    label: MsgJointInput,
    value: string,
    onClick?: React.MouseEventHandler,
};

type Props = {
    options: Array<FmComboboxOption>,
    placeholder: MsgJointInput | string,
    intl: Intl,
    selectedValue?: null | string,
    className?: string,
    isDemo?: boolean,
    isVideoChooser?: boolean,
    isFreeOneComVideoFileChooser?: boolean,
};

type State = {
    width: number;
    isOpen: boolean;
};

class FmComboboxClass extends React.Component<Props, State> {
    state: State;
    headerRef: React.RefObject<HTMLHeadingElement>;
    listRef: React.RefObject<HTMLUListElement>;

    constructor(props) {
        super(props);
        this.state = { width: 0, isOpen: false };
        this.headerRef = React.createRef();
        this.listRef = React.createRef();
    }

    toggle = (e: React.MouseEvent) => {
        this.setState({ isOpen: !this.state.isOpen });
        e.stopPropagation();
    };

    close = () => {
        this.setState({ isOpen: false });
    };

    componentDidMount() {
        window.addEventListener('click', this.close);

        if (!this.headerRef.current || !this.listRef.current) return;

        this.listRef.current.style.display = 'block';
        const
            headerWidth = this.headerRef.current.getBoundingClientRect().width,
            listWidth = this.listRef.current.getBoundingClientRect().width,
            width = Math.round(Math.max(headerWidth, listWidth));

        this.listRef.current.style.display = '';

        this.setState({ width }); // eslint-disable-line react/no-did-mount-set-state
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.close);
    }

    render() {
        const
            { width, isOpen } = this.state,
            {
                options, className: propClassName,
                placeholder: propPlaceholder,
                selectedValue,
                intl,
                isVideoChooser,
                isFreeOneComVideoFileChooser = false
            } = this.props,
            className = cx(styles.container, propClassName, { [styles.open]: isOpen }),
            style = (width && { width }) || undefined,
            placeholder = isMsgJointInput(propPlaceholder) ? intl.msgJoint(propPlaceholder) : propPlaceholder;

        return (
            isVideoChooser
                ?
                (
                    <nav className={className} style={style}>
                        <SecondaryButton
                            style={{ display: isFreeOneComVideoFileChooser ? 'none' : 'block' }}
                            className={styles.uploadButton}
                            // @ts-ignore
                            onClick={options.find(opt => opt.value === FM_UPLOAD_MENU_FROM_COMPUTER_VALUE).onClick}
                        >
                            { intl.msgJoint("msg: common.upload {Upload}") }
                        </SecondaryButton>
                    </nav>
                )
                :
                (
                    <nav className={className} style={style}>
                        <header onClick={this.toggle} ref={this.headerRef}>
                            <span>{placeholder}</span>
                            <i />
                        </header>
                        <ul ref={this.listRef}>
                            {options.map(({ label, value, onClick }) => {
                                const
                                    isSelected = selectedValue === value,
                                    item = (
                                        <li
                                            className={cx({ [styles.selected]: isSelected })}
                                            onClick={onClick}
                                            key={value}
                                        >
                                            <span>{intl.msgJoint(label)}</span>
                                            {isSelected && <i />}
                                        </li>
                                    );

                                return (
                                    this.props.isDemo && value !== FM_UPLOAD_MENU_FROM_COMPUTER_VALUE
                                        // @ts-ignore
                                        ? <DemoTip position={TooltipPosition.BOTTOM} key={value}>{item}</DemoTip>
                                        : item
                                );
                            })}
                        </ul>
                    </nav>
                )
        );
    }
}

export const FmComboboxCom = injectIntl(FmComboboxClass);
