import { openDialog } from "../../App/actionCreators/index";
import type { MaxMultiSelectValidation } from "../../../view/common/FileChooser/flowTypes";
import { FM_DIALOG_ID } from "../dialog/dialogId";
import { videoChooserDialogDefaultProps } from './constants';
import type { FcContentTypePattern } from "../../../redux/modules/children/fileChooser/utils/testFcContentType";

export type VideoChooserDialogProps = {
    onSaveAction: string,
    preSelection?: null | string,
    isMultiSelect?: boolean,
    maxMultiSelectValidation?: MaxMultiSelectValidation,
    forwardToComponent?: boolean,
    headlessMode?: true,
    extraImageContentTypes?: FcContentTypePattern,
    hideFreeOneComImagesTab?: boolean,
    hideShutterstockImagesTab?: boolean,
    hideMyImagesTab?: boolean,
    activeTabId?: string
};

export const openVideoChooserAction = (props: VideoChooserDialogProps) => openDialog(FM_DIALOG_ID, {
    ...videoChooserDialogDefaultProps,
    ...props,
});
