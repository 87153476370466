import React from "react";
import { useDispatch } from "react-redux";

import { Msg } from "../../../../../view/intl";
import {
    CLOSE_STICKY_TOOLTIP,
} from "../../../../Tooltip/stickyTooltip/actionTypes";
import styles from "./BookingsPublishTooltip.css";

export const BookingsPublishTooltipView = () => {
    const dispatch = useDispatch();

    return (
        <div className={styles.tooltipContainer}>
            <div className={styles.title}>
                <Msg k="tooltip.publish.bookings.title">Ready to accept appointments?</Msg>
            </div>
            <div>
                <Msg k="tooltip.publish.bookings.content">Your bookings page is not live yet. Publish now to start accepting bookings.</Msg>
            </div>
            <div className={styles.gotItBtn} onClick={() => dispatch({ type: CLOSE_STICKY_TOOLTIP })}>
                <Msg k="common.gotIt">Got it</Msg>
            </div>
        </div>
    );
};
