import type { ContactFormComponent } from './flowTypes';
import type { ComponentDependencies } from "../../PropertiesPanel/flowTypes";
import { BUTTON_THEME_PRIMARY } from "../../ThemeGlobalData/constants";
import { getIntl } from '../../../view/intl/injectIntl';
import { DEFAULT_MARKETING_CONSENT_CHECKBOX_TEXT } from './constants';

export default (defaultState: ContactFormComponent, componentDependencies: ComponentDependencies): ContactFormComponent => {
    // @ts-ignore
    const { buttonDefaultStyleId, globalStyles: { styles } } = componentDependencies,
        { name } = styles.filter(({ id }) => id === buttonDefaultStyleId)[0];

    const intl = getIntl();

    return {
        ...defaultState,
        styleButton: {
            ...defaultState.styleButton,
            globalId: buttonDefaultStyleId,
            globalName: name,
            text: { size: null }
        },
        fileUploadButtonStyle: {
            ...defaultState.fileUploadButtonStyle,
            globalId: buttonDefaultStyleId,
            globalName: name,
            text: { size: null }
        },
        fuButtonThemeSelected: BUTTON_THEME_PRIMARY,
        isMarketingConsentEnabled: true,
        marketingConsentCheckBoxText: intl?.msgJoint(DEFAULT_MARKETING_CONSENT_CHECKBOX_TEXT) || "",
        readPrivacyPolicyText: intl?.msgJoint("msg: common.readPrivacyPolicy {Read Privacy policy}") || ""
    };
};

