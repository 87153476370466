import * as R from 'ramda';
import isStretchComponentKind from '../../components/oneweb/isStretchComponentKind';

import _getComponentsIdsInBBox from "./getComponentsIdsInBBox";
import _getComponentsBBox from "./getComponentsBBox";
import _makeBBox, { zeroBBox as _zeroBBox } from "./makeBBoxMemoized";
import _getBBoxesIntersection from './getBBoxesIntersection';
import _getComponentsIntersection from './getComponentsIntersection';
import {
    getTreeFromAllComponents as _getTreeFromAllComponents,
    isParentFor as _isParentFor
} from "./getComponentsTree";
import {
    _doBBoxTouch,
    _getOverlappingComponents,
    _doBBoxIntersect
} from "./getIntersectingComponentsInBBox";
import { memoMaxOne } from "../../../utils/memo";
import type {
    AnyComponent,
    ComponentsCollection,
    ComponentsMap,
    BBox
} from "../../redux/modules/children/workspace/flowTypes";
import rbushWithComponents from '../../components/Workspace/epics/redDecorations/rbushInstance';
import _getComponentBBox from './getComponentBBox';
import type { Position } from "../../redux/modules/flowTypes";

export const makeBBox = _makeBBox;
export const getBBoxesIntersection = _getBBoxesIntersection;
export const getComponentsIntersection = _getComponentsIntersection;
export const getComponentsIdsInBBox = _getComponentsIdsInBBox;
export const getComponentsBBox = _getComponentsBBox;
export const getComponentBBox = _getComponentBBox;
export const doBBoxTouch = _doBBoxTouch;
export const doBBoxIntersect = _doBBoxIntersect;
export const getOverlappingComponents = _getOverlappingComponents;
export const isParentFor = _isParentFor;

export const getTreeFromAllComponents = memoMaxOne(_getTreeFromAllComponents);
export const zeroBBox = _zeroBBox;

export const topMostComponent = (components: Array<AnyComponent>): AnyComponent => components.reduce(
    (prev, component) => (prev.top < component.top ? prev : component), components[0]
);

export function getComponentsAtXY(x: number, y: number) {
    return rbushWithComponents.search({
        minX: x,
        minY: y,
        maxX: x,
        maxY: y
    });
}

export function traverseRelInMap(componentId: string, relationsMap: Record<string, any>) {
    let relInRecord = relationsMap[componentId],
        relInId: any = R.path(['relIn', 'id'], relInRecord);
    const relInChain: any[] = [];
    while (relInId) {
        relInChain.push(relInId);
        relInRecord = relationsMap[relInId];
        relInId = R.path(['relIn', 'id'], relInRecord);
    }
    return relInChain;
}

export const filterComponentsAtPosition = (componentsMap: ComponentsMap, p: Position): ComponentsCollection =>
    Object.keys(componentsMap).reduce((acc, cid) => {
        const
            component = componentsMap[cid],
            { top, left, height, width, stretch = false } = component,
            bottom = top + height,
            right = left + width;

        if (
            (isStretchComponentKind(component.kind, stretch) && top <= p.y && bottom >= p.y) ||
            (top <= p.y && bottom >= p.y && left <= p.x && right >= p.x)
        ) {
            return [...acc, component];
        }

        return acc;
    }, [] as ComponentsCollection);

export const getSelectionBBox = (componentsMap: ComponentsMap, componentsIds: Array<string>): BBox => {
    return getComponentsBBox(componentsIds.map(id => componentsMap[id]), zeroBBox);
};
