import { receiveOnly } from "../../../../../../epics/makeCondition";
import { topMostDialogSelector } from "../../../../../DialogManager/epic/selectors";
import { dialogManagerVAT } from "../../../../../DialogManager/epic/valueActionType";
import { PageInfoDialogId } from "../../ids";
import { openPageInfoUnsavedChangesDialogAction } from "../actions";
import type { PageInfoDialogScope } from "./types";
import { Lit } from '../../../../../../lit';
import { isObject } from '../../../../../../../utils/object.js';

type ReducerParams = {
    state: null,
    scope: PageInfoDialogScope,
    values: [Record<string, any>, string],
};

type ExtraData = {
    unsavedChangesDialogType?: string
}

type Params = {
    sourceAction: string,
    proceedActions: Array<any>,
    extraScope?: Record<string, any>,
    extraData?: ExtraData
};

export const makePageInfoCheckUnsavedChangesUpdater = (
    { sourceAction, proceedActions, extraScope = {}, extraData = {} }: Params
) => ({
    conditions: [
        receiveOnly(dialogManagerVAT, topMostDialogSelector),
        sourceAction,
    ],
    reducer: (
        {
            state,
            scope: inScope,
            values: [pageInfoDialogConfig, pageRefId],
        }: ReducerParams
    ) => {
        if (pageInfoDialogConfig.id !== PageInfoDialogId) {
            return { state, scope: inScope };
        }

        const
            { state: { isEqToInitialState, [Lit.names]: names } } = pageInfoDialogConfig,
            scope = { ...inScope, ...extraScope };

        if (isEqToInitialState) {
            return {
                state,
                scope: { ...scope, pageRefId },
                multipleActionsToDispatch: proceedActions
            };
        }

        const isSaveDisabled = Object
            .keys(names)
            .map((k) => (
                isObject(names[k]) && names[k].hasOwnProperty('isValid')
                    ? names[k].isValid
                    : true
            ))
            .some(v => !v);

        const { unsavedChangesDialogType } = extraData;

        return {
            state,
            scope: { ...scope, unsavedChangesProceedActions: proceedActions, pageRefId },
            actionToDispatch: openPageInfoUnsavedChangesDialogAction({
                pageRefId,
                isSaveDisabled,
                unsavedChangesDialogType
            })
        };
    }
});
