/* eslint-disable max-len */
import cx from 'classnames';
import makeEpic from '../../../../epics/makeEpic';
import { receiveOnly } from "../../../../epics/makeCondition";
import { APP_LEFT_MOUSE_DOWN } from "../../../../redux/modules/actionTypes";

import { APP_RIGHT_MOUSE_DOWN, CTRL_SHIFT_Z_PRESSED, CTRL_Z_PRESSED } from "../../../App/actionTypes";
import browserDimensionsValueActionType from '../../../App/epics/browserDimensions/valueActionType';
import workspaceScrollValueActionType from '../../../Workspace/epics/scroll/valueActionType';

import leftPanelWidthValueActionType from '../../../Panel/epics/width/valueActionType';
import { PANEL_COMPONENT_SHORTCUT_MOUSE_DOWN, PANEL_RESIZE_HANDLE_CLICKED, PANEL_ITEM_CLICK } from "../../../Panel/actionTypes";

import { LOGOUT, UNDO_BUTTON_PRESSED, REDO_BUTTON_PRESSED, TOP_BAR_OPEN_POPUP } from "../../../TopBar/actionTypes";

import * as ids from '../../ids';
import registry from '../../registry';
import {
    SHOW_TOOLTIP,
    SHOW_TOOLTIP_FOR_COMPONENT_MINVALUES,
    SHOW_TOOLTIP_FOR_COMPONENT_SHORTCUT,
    SHOW_TOOLTIP_FOR_UNAVAILABLE_COMPONENT_CLICK,
    SHOW_TOOLTIP_FOR_MVE_MENU,
    CLOSE_TOOLTIP,
    CLOSE_COMPONENT_LIST_TOOLTIP,
    CLOSE_FB_CHAT_TOOLTIP,
    TOOLTIP_CLIENT_RECT, CLOSE_MOBILE_DOWN_TOOLTIP,
    SHOW_TOOLTIP_FOR_MOBILE_DOWN_MASK,
    SHOW_TOOLTIP_FOR_MVE_CMP_REORDER,
    MOBILE_EDITOR_REORDER_TIP_CLOSED,
    MOBILE_EDITOR_REORDER_TIP_CLOSE,
    MOBILE_EDITOR_TOGGLE_TIP_CHKBOX,
    SHOW_TOOLTIP_FOR_STRIP_TITLE,
    SHOW_TOOLTIP_FOR_MVE_GROUP,
    SHOW_TOOLTIP_FOR_EXTENDED_PANEL,
    SHOW_TOOLTIP_FOR_SECTION_ONBOARDING,
    SHOW_TOOLTIP_FOR_PHONE,
    SHOW_TOOLTIP_FOR_EMAIL,
    SHOW_TOOLTIP_FOR_ADDRESS,
    HIDE_TOOLTIP_FOR_PHONE,
    HIDE_TOOLTIP_FOR_EMAIL,
    HIDE_TOOLTIP_FOR_ADDRESS,
    NEVER_SHOW_TOOLTIP_AGAIN_FOR_ADDRESS,
    NEVER_SHOW_TOOLTIP_AGAIN_FOR_EMAIL,
    NEVER_SHOW_TOOLTIP_AGAIN_FOR_PHONE,
    SHOW_TOOLTIP_FOR_HEADER_FOOTER_SECTION,
    SHOW_TOOLTIP_FOR_SECTION_INSERTER,
    SHOW_TOOLTIP_FOR_MODERN_LAYOUT,
    SHOW_TOOLTIP_FOR_SECTION_MINVALUES,
    SHOW_TOOLTIP_FOR_PREMIUM_COMPONENT_SHORTCUT,
    SHOW_TOOLTIP_FOR_SINGLE_PRODUCT_COMPONENT,
    HIDE_TOOLTIP_FOR_SINGLE_PRODUCT_COMPONENT,
    SHOW_DISABLED_FB_CHAT_TOOLTIP,
    SHOW_TOOLTIP_FOR_IMAGE_REPALCE,
    SHOW_FORCE_DISABLED_FB_CHAT_TOOLTIP,
    SHOW_FORCE_DISABLED_INSERTER_TOOLTIP,
    SHOW_TOOLTIP_FOR_ADD_YOUR_SHOP,
    CLOSE_ADD_YOUR_SHOP_TOOLTIP
} from "../../actionTypes";

import valueActionType from './valueActionType';
import componentRegistry from "../../../../view/oneweb/registry/index";
import getTooltipStyles from "./getTooltipStyles";
import { ReceiveOnlyComponentsMap } from "../../../Workspace/epics/componentsEval/selectorActionTypes";
import { MveHeaderToolTipDefaults, ToolTipDefaults, PointerDirections } from "../../constants";
import subscriptionDataVAT from '../../../App/epics/subscriptionData/valueActionType';
import { MOBILE_EDITOR_COMPONENT_MOVED, MOBILE_EDITOR_VIEW_SCROLLED, OPEN_MOBILE_VIEW_EDITOR_CLICKED } from "../../../MobileViewEditor/actionTypes";
import { TEXT_LIKE_COMPONENT_TOOLTIP_WIDTH } from '../../../oneweb/TextLike/constants';
import { hideSectionInfoTooltipAC } from "../sectionInfoTooltip/index";
import styles from "../../../Panel/view/Inserter.css";
import { BACKUP_CLICKED } from "../../../Backup/actionTypes";
import { getFirstPageSection, getSecondPageSection } from "../../../oneweb/Section/utils";
import { TopBarHeight } from "../../../TopBar/constants";
import { AddIconN } from "../../../../utils/handle/kinds";
import templateOffsetVAT from '../../../oneweb/Template/epics/templateOffset/valueActionType';
import { workspaceHandlesValueActionType } from "../../../Workspace/epics/handles/valueActionType";
import { savePreferencesAction } from "../../../Preferences/actions";
import { DUPLICATE_COMPONENTS_BY_IDS, DELETE_SELECTED_SECTION } from "../../../Workspace/actionTypes";
import { START_QUICK_TOUR, START_SECTION_TOUR } from '../../../QuickTour/actionTypes';
import { PAGE_TREE_PAGE_SELECTED, PAGE_TREE_PAGE_DELETE_CONFIRM } from '../../../PagesTree/Tree/actionTypes';
import { PAGE_DATA_LOAD_SUCCESS } from '../../../PagesTree/actionTypes';
import { NEW_PAGE_DIALOG_OPEN } from '../../../PagesTree/NewPageDialog/actions';
import { COMPONENT_EDIT } from '../../../../redux/modules/children/workspace/actionTypes';
import { hideModernLayoutInfoTooltipAC } from "../modernLayoutInfoTooltip/index";

export const MandatoryTipsCloseOnActions = [
    OPEN_MOBILE_VIEW_EDITOR_CLICKED,
    PAGE_TREE_PAGE_SELECTED,
    PAGE_TREE_PAGE_DELETE_CONFIRM,
    NEW_PAGE_DIALOG_OPEN,
    PAGE_DATA_LOAD_SUCCESS,
    START_SECTION_TOUR,
    START_QUICK_TOUR,
    BACKUP_CLICKED,
    REDO_BUTTON_PRESSED,
    UNDO_BUTTON_PRESSED,
    CTRL_SHIFT_Z_PRESSED,
    CTRL_Z_PRESSED,
    LOGOUT,
    DUPLICATE_COMPONENTS_BY_IDS,
    DELETE_SELECTED_SECTION,
    COMPONENT_EDIT,
    TOP_BAR_OPEN_POPUP
];

export const TipsCloseOnActions = [
    ...MandatoryTipsCloseOnActions,
    MOBILE_EDITOR_VIEW_SCROLLED,
    MOBILE_EDITOR_COMPONENT_MOVED,
    PANEL_COMPONENT_SHORTCUT_MOUSE_DOWN,
    PANEL_RESIZE_HANDLE_CLICKED,
    APP_LEFT_MOUSE_DOWN,
    APP_RIGHT_MOUSE_DOWN,
    PANEL_ITEM_CLICK,
    workspaceScrollValueActionType,
    browserDimensionsValueActionType,
    LOGOUT
];

const
    getMessage = (componentId, componentsMap, subscriptionData) => {
        // ! WBTGEN-29702 - Hostnet has been hardcoded temporarily for the fix. This will go when the commit is reverted
        const realComponentId = componentId.replace(/disabled/gi, '').replace(/Hostnet/gi, ''),
            component = componentRegistry[realComponentId] || {};

        if (component.validateComponentAdd) {
            const isNotValidKey = component.validateComponentAdd({ componentsMap, subscriptionData });
            if (isNotValidKey) {
                return component.errorMsgsOnAdd[isNotValidKey] || '';
            }
        }

        return registry[componentId];
    },

    defaultState = { show: false },

    defaultStyle = { left: 0, top: 0, width: 0, opacity: 0 },

    ComponentListTooltipIdsMap = {
        [ids.CodeDisabled]: true,
        [ids.ContactFormDisabled]: true,
        [ids.DocumentDisabled]: true,
        [ids.TableDisabled]: true,
        [ids.WebShopDisabled]: true,
        [ids.FacebookChatDisabled]: true,
        [ids.FacebookChatForceDisabled]: true,
        [ids.FacebookFeedForceDisabled]: true,
        [ids.GoogleReviewsForceDisabled]: true
    };

export const SECTION_INTRO_TOASTER_DELAY = 1000;

export const buildDefaultState = (tipWidth?: number) => ({
    show: true,
    showIcon: false,
    position: { x: 0, y: 0 },
    style: { ...defaultStyle, width: tipWidth },
    pointerStyle: { ...defaultStyle, height: 0 },
    isCloseExplicit: false,
});

export const showTooltipReducer = ({ values: [{
    id,
    position: { x, y },
    msg,
    textStyle = {},
    showIcon = true,
    elementDimensions,
    customClass
}, leftPanelWidth
] }: any) => ({
    state: {
        ...buildDefaultState(ToolTipDefaults.width),
        customClass,
        msg: msg || registry[id],
        context: { x, y, leftPanelWidth, elementDimensions },
        textStyle,
        showIcon,
        id
    }
});

export const getNewTooltipState = ({ width, height }: Record<string, any>, state: any, tooltipClientRect: any = { width: 0, height: 0 }) => {
    let tooltipStyles: any = getTooltipStyles({
        ...state.context,
        tooltipProps: { ...(state.context.tooltipProps || {}), height: tooltipClientRect.height, width: tooltipClientRect.width },
        browserHeight: height,
        browserWidth: width,
        id: state.id
    });
    let customClass = state.customClass;
    if ([ids.ComponentResize, ids.StripTitleErrorTip].includes(state.id)) {
        // TODO: THEMING-WSB-COLORS-JS
        tooltipStyles.style.backgroundColor = '#e08577';
        tooltipStyles.style.border = 'none';
    }
    if (state.id === ids.SectionShortcutInfoTooltip) {
        customClass = cx(customClass, styles.initSectionToolTip);
    }
    return {
        ...state,
        ...tooltipStyles,
        style: { ...state.style, ...tooltipStyles.style },
        tooltipClientRect,
        customClass
    };
};

export const tooltipClientRectReducer = ({ values: [tooltipClientRect, { width, height }], state }: any) => {
    if (!state.show) {
        return { state };
    }
    return { state: getNewTooltipState({ width, height }, state, tooltipClientRect) };
};

const makeShowTooltipForTextLikeComponentReducer =
    (id, msg) => ({ values: [position] }) => ({
        state: {
            ...buildDefaultState(TEXT_LIKE_COMPONENT_TOOLTIP_WIDTH),
            id,
            msg,
            context: position,
            showIcon: true,
            showCrossIcon: true
        }
    });

const makeNeverShowTooltipAgainReducer = tooltipId => (
    ({ values: [chkBoxEnabled], state }) => (state.id === tooltipId ? { state: { ...state, chkBoxEnabled } } : { state })
);

export default makeEpic({
    defaultState,
    valueActionType,
    updaters: [
        {
            conditions: [
                SHOW_TOOLTIP,
                receiveOnly(leftPanelWidthValueActionType)
            ],
            reducer: showTooltipReducer
        },
        {
            conditions: [
                SHOW_TOOLTIP_FOR_MVE_MENU
            ],
            reducer: ({ values: [{ id, position: { x, y }, floorTooltipBbox, crossIconStyle, tipInfoStyle }] }) => {
                return ({
                    state: {
                        ...buildDefaultState(MveHeaderToolTipDefaults.width),
                        id,
                        msg: registry[id],
                        context: { x, y, floorTooltipBbox },
                        showIcon: true,
                        showCrossIcon: true,
                        crossIconStyle,
                        tipInfoStyle
                    }
                });
            }
        },
        {
            conditions: [
                SHOW_TOOLTIP_FOR_MOBILE_DOWN_MASK
            ],
            reducer: ({ values: [{ id, position: { x, y }, tipInfoStyle }] }) => {
                return ({
                    state: {
                        ...buildDefaultState(MveHeaderToolTipDefaults.width),
                        id,
                        msg: registry[id],
                        context: { x, y },
                        showIcon: true,
                        showCrossIcon: true,
                        tipInfoStyle
                    }
                });
            }
        },
        {
            conditions: [
                SHOW_TOOLTIP_FOR_MVE_CMP_REORDER
            ],
            reducer: ({ values: [{ id, position: { x, y }, tipInfoStyle }] }) => {
                return ({
                    state: {
                        ...buildDefaultState(MveHeaderToolTipDefaults.width),
                        id,
                        msg: registry[id],
                        context: { x, y },
                        showIcon: true,
                        showCrossIcon: true,
                        tipInfoStyle,
                        chkBoxEnabled: false
                    }
                });
            }
        },
        {
            conditions: [
                SHOW_TOOLTIP_FOR_MVE_GROUP
            ],
            reducer: ({ values: [{ id, position: { x, y } }] }) => {
                return ({
                    state: {
                        ...buildDefaultState(289),
                        id,
                        msg: registry[id],
                        context: { x, y },
                        showIcon: true,
                        showCrossIcon: true,
                        tipInfoStyle: {},
                        chkBoxEnabled: false
                    }
                });
            }
        },
        {
            conditions: [SHOW_TOOLTIP_FOR_ADDRESS],
            reducer: makeShowTooltipForTextLikeComponentReducer(ids.AddressTip, registry[ids.AddressTip]),
        },
        {
            conditions: [SHOW_TOOLTIP_FOR_EMAIL],
            reducer: makeShowTooltipForTextLikeComponentReducer(ids.EmailTip, registry[ids.EmailTip]),
        },
        {
            conditions: [SHOW_TOOLTIP_FOR_PHONE],
            reducer: makeShowTooltipForTextLikeComponentReducer(ids.PhoneTip, registry[ids.PhoneTip]),
        },
        {
            conditions: [HIDE_TOOLTIP_FOR_ADDRESS],
            reducer: ({ state }) => (state.id === ids.AddressTip ? { state: defaultState } : { state })
        },
        {
            conditions: [HIDE_TOOLTIP_FOR_EMAIL],
            reducer: ({ state }) => (state.id === ids.EmailTip ? { state: defaultState } : { state })
        },
        {
            conditions: [HIDE_TOOLTIP_FOR_PHONE],
            reducer: ({ state }) => (state.id === ids.PhoneTip ? { state: defaultState } : { state })
        },
        {
            conditions: [NEVER_SHOW_TOOLTIP_AGAIN_FOR_ADDRESS],
            reducer: makeNeverShowTooltipAgainReducer(ids.AddressTip),
        },
        {
            conditions: [NEVER_SHOW_TOOLTIP_AGAIN_FOR_EMAIL],
            reducer: makeNeverShowTooltipAgainReducer(ids.EmailTip),
        },
        {
            conditions: [NEVER_SHOW_TOOLTIP_AGAIN_FOR_PHONE],
            reducer: makeNeverShowTooltipAgainReducer(ids.PhoneTip),
        },
        {
            conditions: [MOBILE_EDITOR_TOGGLE_TIP_CHKBOX],
            reducer: makeNeverShowTooltipAgainReducer(ids.MobileEditorReorderTip),
        },
        {
            conditions: [MOBILE_EDITOR_TOGGLE_TIP_CHKBOX],
            reducer: makeNeverShowTooltipAgainReducer(ids.MobileEditorGroupTip),
        },
        {
            conditions: [
                SHOW_TOOLTIP_FOR_COMPONENT_MINVALUES
            ],
            reducer: ({ values: [{ id, position: { x, y }, textStyle = {}, msg, showIcon = true }] }) => ({
                state: {
                    ...buildDefaultState(ToolTipDefaults.width),
                    msg,
                    context: { x, y },
                    textStyle,
                    showIcon,
                    id,
                    tipInfoStyle: { padding: '4px 8px' }
                }
            })
        },
        {
            conditions: [
                SHOW_TOOLTIP_FOR_SECTION_MINVALUES
            ],
            reducer: ({ values: [{ id, position: { x, y }, msg }] }) => {
                const defaultState = buildDefaultState(ToolTipDefaults.width);
                return {
                    state: {
                        ...defaultState,
                        id,
                        msg,
                        context: { x, y },
                        textStyle: { textAlign: 'left', color: '#fff', fontSize: '13px', fontWeight: 'semibold' },
                        showIcon: false,
                        tipInfoStyle: { padding: '10px 18px' },
                        style: {
                            ...defaultState.style,
                            backgroundColor: '#F00',
                            border: 'none'
                        }
                    }
                };
            }
        },
        {
            conditions: [
                SHOW_TOOLTIP_FOR_STRIP_TITLE
            ],
            reducer: ({ values: [{ id, position: { x, y }, elementDimensions, customClass, msg, showIcon = false }] }) => ({
                state: {
                    ...buildDefaultState(),
                    textStyle: { textAlign: 'center', color: '#ffffff' },
                    tipInfoStyle: { padding: '4px 8px' },
                    context: { x, y, elementDimensions },
                    timeout: 0,
                    customClass,
                    showIcon,
                    msg,
                    id
                }
            })
        },
        {
            conditions: [SHOW_TOOLTIP_FOR_EXTENDED_PANEL],
            reducer: ({ values: [{ id, position: { x, y }, elementDimensions, pointerDirection = PointerDirections.Left, customClass, msg }] }) => ({
                state: {
                    ...buildDefaultState(),
                    tipInfoStyle: { padding: '15px' },
                    context: { x, y, elementDimensions, pointerDirection },
                    timeout: 0,
                    customClass,
                    msg,
                    id
                }
            })
        },
        {
            conditions: [SHOW_TOOLTIP_FOR_ADD_YOUR_SHOP],
            reducer: ({ values: [{ id, position: { x, y }, elementDimensions, pointerDirection = PointerDirections.Left, customClass, customHTML }] }) => {
                return {
                    state: {
                        ...buildDefaultState(),
                        textStyle: { display: 'none' },
                        show: true,
                        tipInfoStyle: { padding: '30px' },
                        context: { x, y, elementDimensions, pointerDirection },
                        timeout: 0,
                        customClass,
                        msg: registry[id],
                        id,
                        customHTML
                    }
                };
            }
        },
        {
            conditions: [CLOSE_ADD_YOUR_SHOP_TOOLTIP],
            reducer: ({ state }) => {
                return state.id === ids.addYourShopToolTipSeen ? {
                    state: { ...defaultState },
                    actionToDispatch: savePreferencesAction({
                        addYourShopToolTipSeen: true
                    })
                } : { state };
            }
        },
        {
            conditions: [SHOW_TOOLTIP_FOR_IMAGE_REPALCE],
            reducer: ({ values: [{ id, position: { x, y }, elementDimensions, pointerDirection = PointerDirections.Bottom, customClass, msg }] }) => ({
                state: {
                    ...buildDefaultState(),
                    textStyle: { color: '#262626' },
                    tipInfoStyle: { padding: '15px' },
                    context: { x, y, elementDimensions, pointerDirection },
                    timeout: 0,
                    showIcon: true,
                    customClass,
                    msg,
                    id
                }
            })
        },
        {
            conditions: [SHOW_TOOLTIP_FOR_HEADER_FOOTER_SECTION],
            reducer: ({ values: [{ position: { x, y }, elementDimensions, customClass, isHeaderSection }] }) => {
                const id = isHeaderSection ? ids.HeaderSectionMCTATooltip : ids.FooterSectionMCTATooltip;
                return {
                    state: {
                        ...buildDefaultState(),
                        text2Style: { paddingTop: '10px', opacity: 0.8 },
                        tipInfoStyle: { padding: '22px 30px' },
                        context: { x, y, elementDimensions },
                        timeout: 0,
                        showCrossIcon: true,
                        showIcon: true,
                        showTipText: false,
                        tipIconSize: 'large',
                        customClass,
                        id,
                        msg: registry[id],
                        crossIconStyle: { position: 'absolute', top: '17px', right: '17px', opacity: 0.5 },
                    }
                };
            }
        },
        {
            conditions: [
                ReceiveOnlyComponentsMap,
                receiveOnly(workspaceScrollValueActionType),
                receiveOnly(templateOffsetVAT),
                receiveOnly(workspaceHandlesValueActionType),
                SHOW_TOOLTIP_FOR_SECTION_ONBOARDING,
            ],
            reducer: ({ values: [componentsMap, scroll, templateOffset, handles, isFirstSectionSelected] }) => {
                const id = ids.SectionFirstIntroToaster,
                    topOffset = 20,
                    height = 250,
                    width = 416,
                    scrollY = scroll.y,
                    offsetX = templateOffset.x,
                    { minX, maxX } = handles.find(({ kind }) => kind === AddIconN),
                    firstPageSection = isFirstSectionSelected ? getFirstPageSection(componentsMap) : getSecondPageSection(componentsMap),
                    y = TopBarHeight + firstPageSection.top - scrollY + topOffset + (height / 2),
                    x = offsetX + minX + ((maxX - minX) / 2) - (width / 2),
                    defaultState = buildDefaultState();
                return {
                    state: {
                        ...defaultState,
                        style: { ...defaultState.style, backgroundColor: '#2976D0', border: '1px solid #2976D0' },
                        headerStyle: { color: '#FFFFFF' },
                        textStyle: { color: '#FFFFFF' },
                        text2Style: { color: '#FFFFFF', paddingTop: '10px' },
                        text3Style: { color: '#FFFFFF', paddingTop: '10px' },
                        tipInfoStyle: { padding: '18px 20px' },
                        context: { x, y },
                        tipIconIsWhite: true,
                        timeout: 0,
                        showCrossIcon: true,
                        showIcon: true,
                        showTipText: false,
                        tipIconSize: 'medium',
                        isActionLink: true,
                        showPointer: false,
                        id,
                        msg: registry[id],
                        crossIconStyle: { position: 'absolute', top: '17px', right: '17px', fill: 'white' },
                    },
                    actionToDispatch: savePreferencesAction({
                        hideSectionIntroToaster: true
                    })
                };
            }
        },
        {
            conditions: [SHOW_TOOLTIP_FOR_SECTION_INSERTER],
            reducer: ({ values: [{ id, position: { x, y }, elementDimensions, pointerDirection = PointerDirections.Left, customClass, customHTML }] }) => ({
                state: {
                    ...buildDefaultState(),
                    textStyle: { display: 'none' },
                    tipInfoStyle: { padding: '10px' },
                    context: { x, y, elementDimensions, pointerDirection },
                    customClass,
                    showCrossIcon: false,
                    showIcon: false,
                    showTipText: false,
                    customHTML,
                    msg: registry[id],
                    timeout: 0,
                    id
                }
            })
        },
        {
            conditions: [SHOW_TOOLTIP_FOR_MODERN_LAYOUT],
            reducer: ({ values: [{ id, position: { x, y }, elementDimensions, pointerDirection = PointerDirections.Left, customClass, customHTML }] }) => ({
                state: {
                    ...buildDefaultState(),
                    textStyle: { display: 'none' },
                    tipInfoStyle: { padding: '10px' },
                    context: { x, y, elementDimensions, pointerDirection },
                    customClass,
                    showCrossIcon: false,
                    showIcon: false,
                    showTipText: false,
                    customHTML,
                    msg: registry[id],
                    timeout: 0,
                    id
                }
            })
        },
        {
            conditions: [
                subscriptionDataVAT,
                SHOW_TOOLTIP_FOR_COMPONENT_SHORTCUT,
                ReceiveOnlyComponentsMap
            ],
            reducer: ({ values: [
                subscriptionData,
                { id, boundingClientRect: bcr },
                componentsMap
            ] }) => {
                const msg = getMessage(id, componentsMap, subscriptionData);
                return ({
                    state: {
                        ...buildDefaultState(289),
                        msg,
                        showIcon: false,
                        context: {
                            x: (bcr.left + bcr.width),
                            y: (bcr.top + (bcr.height / 2)),
                            leftPanelWidth: 0,
                            tooltipProps: { gap: 0, width: 160 }
                        },
                        id
                    }
                });
            }
        },
        {
            conditions: [
                receiveOnly(subscriptionDataVAT),
                SHOW_TOOLTIP_FOR_PREMIUM_COMPONENT_SHORTCUT,
                ReceiveOnlyComponentsMap
            ],
            reducer: ({ values: [
                subscriptionData,
                { id, boundingClientRect: bcr },
                componentsMap
            ] }) => {
                const msg = getMessage(id, componentsMap, subscriptionData);
                return ({
                    state: {
                        ...buildDefaultState(289),
                        msg,
                        showCrossIcon: true,
                        showHeadersWithoutTip: true,
                        useCustomTextStyle: true,
                        context: {
                            x: (bcr.left + bcr.width),
                            y: (bcr.top + (bcr.height / 2)),
                            leftPanelWidth: 0,
                            tooltipProps: { gap: 12, width: 160 }
                        },
                        id
                    }
                });
            }
        },
        {
            conditions: [
                receiveOnly(subscriptionDataVAT),
                SHOW_TOOLTIP_FOR_UNAVAILABLE_COMPONENT_CLICK,
                ReceiveOnlyComponentsMap
            ],
            reducer: ({ values: [subscriptionData, { id, boundingClientRect: bcr }, componentsMap] }) => ({
                state: {
                    ...buildDefaultState(160),
                    msg: getMessage(id, componentsMap, subscriptionData),
                    context: {
                        x: (bcr.left + bcr.width),
                        y: (bcr.top + (bcr.height / 2)),
                        leftPanelWidth: 0,
                        tooltipProps: { gap: 0, width: 160 }
                    },
                    id
                }
            })
        },
        {
            conditions: [
                TOOLTIP_CLIENT_RECT,
                receiveOnly(browserDimensionsValueActionType)
            ],
            reducer: tooltipClientRectReducer
        },
        {
            conditions: [CLOSE_COMPONENT_LIST_TOOLTIP],
            reducer: ({ state }) => (ComponentListTooltipIdsMap[state.id] ? { state: defaultState } : { state })
        },
        ...[CLOSE_TOOLTIP, ...TipsCloseOnActions].map(action => ({
            conditions: [action],
            reducer: ({ state }) => {
                const hasOwnCloseToolTipAction = (id: string) => {
                    const toolTipsWithOwnCloseAction = [
                        ids.addYourShopToolTipSeen,
                    ];
                    return toolTipsWithOwnCloseAction.includes(id);
                };
                let actionToDispatch: Action | null = null;
                if (state.id === ids.MobileEditorReorderTip) {
                    actionToDispatch = {
                        type: MOBILE_EDITOR_REORDER_TIP_CLOSED
                    };
                }
                if (state.id === ids.SectionShortcutInfoTooltip) {
                    actionToDispatch = hideSectionInfoTooltipAC();
                }
                if (state.id === ids.ModernLayoutInfoTooltip) {
                    actionToDispatch = hideModernLayoutInfoTooltipAC();
                }
                if (hasOwnCloseToolTipAction(state.id)) {
                    return { state };
                }
                return {
                    state: state.show ? defaultState : state,
                    actionToDispatch
                };
            }
        })),
        {
            conditions: [CLOSE_MOBILE_DOWN_TOOLTIP],
            reducer: ({ state }) => {
                return state.id === ids.MobileDownDisabled ? { state: defaultState } : { state };
            }
        },
        {
            conditions: [MOBILE_EDITOR_REORDER_TIP_CLOSE],
            reducer: ({ state }) => {
                return state.id === ids.MobileEditorReorderTip ? { state: defaultState,
                    actionToDispatch: {
                        type: MOBILE_EDITOR_REORDER_TIP_CLOSED
                    } } : { state };
            }
        },
        {
            conditions: [SHOW_TOOLTIP_FOR_SINGLE_PRODUCT_COMPONENT],
            reducer: ({ values: [tooltipProps] }) => {
                const defaultState = buildDefaultState(ToolTipDefaults.width);
                const state = {
                    ...defaultState,
                    ...tooltipProps,
                    context: tooltipProps.position
                };

                return {
                    state
                };
            }
        },
        {
            conditions: [HIDE_TOOLTIP_FOR_SINGLE_PRODUCT_COMPONENT],
            reducer: ({ state }) => (state.id === ids.SingleProductComponentTip ? { state: defaultState } : { state })
        },
        {
            conditions: [SHOW_DISABLED_FB_CHAT_TOOLTIP],
            reducer: ({ values: [
                { id, boundingClientRect: bcr, customClass, customTextClass, linkTextCustomClass },
            ] }) => {
                const msg = registry[id];
                return ({
                    state: {
                        ...buildDefaultState(248),
                        msg,
                        showIcon: false,
                        context: {
                            x: (bcr.left + bcr.width),
                            y: (bcr.top + (bcr.height / 2)),
                            leftPanelWidth: 0,
                            tooltipProps: { gap: 0, width: 160 },
                        },
                        customClass,
                        customTextClass,
                        useCustomTextStyle: true,
                        linkTextCustomClass,
                        id
                    }
                });
            }
        },
        {
            conditions: [SHOW_FORCE_DISABLED_FB_CHAT_TOOLTIP],
            reducer: ({ values: [
                { id, boundingClientRect: bcr },
            ] }) => {
                const msg = registry[id];
                return ({
                    state: {
                        ...buildDefaultState(248),
                        msg,
                        showIcon: false,
                        context: {
                            x: (bcr.left + bcr.width),
                            y: (bcr.top + (bcr.height / 2)),
                            leftPanelWidth: 0,
                            tooltipProps: { gap: 0, width: 160 },
                        },
                        id
                    }
                });
            }
        },
        {
            conditions: [CLOSE_FB_CHAT_TOOLTIP],
            reducer: ({ state }) => (ComponentListTooltipIdsMap[state.id] ? { state: defaultState } : { state })
        },
        {
            conditions: [SHOW_FORCE_DISABLED_INSERTER_TOOLTIP],
            reducer: ({ values: [
                { id, boundingClientRect: bcr, tipWidth },
            ] }) => {
                const msg = registry[id];
                return ({
                    state: {
                        ...buildDefaultState(tipWidth),
                        msg,
                        showIcon: false,
                        context: {
                            x: (bcr.left + bcr.width),
                            y: (bcr.top + (bcr.height / 2)),
                            leftPanelWidth: 0,
                            tooltipProps: { gap: 0, width: 160 },
                        },
                        id
                    }
                });
            }
        }
    ]
});
