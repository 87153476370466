import * as R from 'ramda';
import { MaxComponentsOnPage } from "../../../../constants/app";
import { memoMax, memoMaxDynamicNumOfArgs } from "../../../../../utils/memo";
import { debugComponentsBbox } from "../../../../utils/isDebug";

import type { AnyComponent } from '../../../../redux/modules/children/workspace/flowTypes';
import type { ComponentBase } from '../../../oneweb/flowTypes';

// Base component will be required in future to make Handles recompute more robust. If nothing of base is changed handles should not be recomputed.
// Mind this use cases
// - Typing in text/table component (20 TEXT_CHANGED action per second)
// - Changing some component property using slider (30 times per second)
const _getComponentBase = memoMax((component: AnyComponent) => {
    const { id, top, left, width, height, orderIndex, inTemplate, stretch, kind, wrap, isStickyToHeader } = component;
    return { id, top, left, width, height, orderIndex, inTemplate, stretch, kind, wrap, isStickyToHeader };
}, MaxComponentsOnPage);

export const getComponentBase = memoMax((component: AnyComponent): ComponentBase => {
    const base = _getComponentBase(component);
    if (debugComponentsBbox()) {
        base.debugBbox = component.debugBbox;
    }
    return base;
}, MaxComponentsOnPage * 3);

const _getComponentsMapBases = memoMaxDynamicNumOfArgs((...bases) =>
    bases.reduce((acc, base) => { acc[base.id] = base; return acc; }, {}), 3);
export const getComponentsMapBases = memoMax(componentsMap =>
    _getComponentsMapBases(...R.values(componentsMap).map(getComponentBase)), 3);
