import * as selectors from '../../Workspace/epics/stylesheets/selectors';
import { toHex } from "../../../mappers/color";
import { DIMENSIONS, Layouts, ReviewViewType, REVIEW_HEADER_HEIGHT, REVIEW_FOOTER_HEIGHT } from './constants';
import type { GoogleReview, ReviewsMap, IdMap } from "./flowTypes";
import { formatMessage } from '../../../view/intl/CurrentLocale';
import { ComboboxOptions } from '../../../view/common/Combobox/flowTypes';

const CARDS_PER_ROW = 3;

export const generateLeaveReviewUrl = (placeId: string) => `https://search.google.com/local/writereview?placeid=${placeId}`;
export const generateGoogleStoreUrl = (placeId: string) => `https://www.google.com/maps/place/?q=place_id:${placeId}`;

const getListDimensions = ({ width, height, borderWidth, reviewsCount, extraHeight }) => ({
    height: (height + borderWidth) * reviewsCount + borderWidth + extraHeight,
    width: width + 2 * borderWidth
});

const getSliderDimensions = ({ width, height, borderWidth, extraHeight }) => ({
    height: height + borderWidth + extraHeight,
    width: width + borderWidth
});

const getCardDimensions = ({ width, height, borderWidth, reviewsCount, extraHeight }) => {
    let newHeight = height + borderWidth + extraHeight,
        newWidth = (width + borderWidth) * reviewsCount;

    if (reviewsCount > 3) {
        newHeight = extraHeight + (height + borderWidth) * Math.ceil(reviewsCount / CARDS_PER_ROW);
        newWidth = (width + borderWidth) * CARDS_PER_ROW;
        if (reviewsCount === 4) {
            newWidth = (width + borderWidth) * 2;
        }
    }
    return {
        height: newHeight,
        width: newWidth
    };
};

export const getUpdatedDimension = (layoutType: string, reviewsCount: number, showHeader = true): {
    minDimensions: { width:number, height:number }
} => {
    const borderWidth = 1;
    const headerAndFooterHeight = (showHeader ? REVIEW_HEADER_HEIGHT : 0) + REVIEW_FOOTER_HEIGHT;
    let minDimensions;
    const { MIN_WIDTH, MIN_HEIGHT } = DIMENSIONS[layoutType];
    const minDimensionsProps = {
        width: MIN_WIDTH, height: MIN_HEIGHT, borderWidth, extraHeight: headerAndFooterHeight
    };
    switch (layoutType) {
        case ReviewViewType.SLIDER:
            minDimensions = getSliderDimensions(minDimensionsProps);
            break;
        case ReviewViewType.CARD:
            minDimensions = getCardDimensions({ ...minDimensionsProps, reviewsCount });
            break;
        case ReviewViewType.LIST:
        default:
            minDimensions = getListDimensions({ ...minDimensionsProps, reviewsCount });
            break;
    }
    return { minDimensions };
};

export const getTextStyles = (stylesheets) => {
    const {
        bold,
        color,
        font: fontFamily,
        italic,
        letterspacing: letterSpacing,
        lineHeight,
        size: fontSize,
        underline
    } = selectors.textNormalGlobalstyle(stylesheets);

    let textStyle = {
        fontFamily,
        fontSize,
        color: toHex(color),
        letterSpacing,
        lineHeight,
        fontStyle: 'inherit',
        fontWeight: 400,
        textDecoration: 'inherit',
    };

    if (italic) {
        textStyle.fontStyle = "italic";
    }
    if (bold) {
        textStyle.fontWeight = 700;
    }
    if (underline) {
        textStyle.textDecoration = "underline";
    }
    return textStyle;
};

export const getLayoutOptions = () => {
    const layoutOptions: ComboboxOptions = [];
    Layouts.forEach(data => layoutOptions.push({
        label: data.label,
        value: data.id
    }));
    return layoutOptions;
};

export const getReviewIds = reviews => {
    const reviewIds: IdMap = [];
    reviews.forEach(({ reviewId }) => reviewIds.push(reviewId));
    return reviewIds;
};

export const getSelectedReviews = (
    selectedReviewIds,
    reviews
) => reviews.filter(({ reviewId }) => selectedReviewIds.includes(reviewId));

export const getSelectedReviewIds = (
    selectedReviewIds,
    reviews
) => {
    const availableReviewIds: IdMap = [];
    reviews.forEach(({ reviewId }) => availableReviewIds.push(reviewId));
    return selectedReviewIds.filter(selectedReviewId => availableReviewIds.includes(selectedReviewId));
};

export const getRearrangedReviews = (allReviews, selectedReviewIds) => {
    const reviewMap: ReviewsMap = allReviews.reduce((acc, review) => {
        acc[review.reviewId] = review;
        return acc;
    }, {});
    const updatedReviews: Array<GoogleReview> = [];

    allReviews.forEach((review, index) => {
        if (selectedReviewIds.includes(review.reviewId)) {
            delete reviewMap[review.reviewId];
            updatedReviews.push(allReviews[index]);
        }
    });
    updatedReviews.push(...Object.values(reviewMap));
    return updatedReviews;
};

export const getReviewTimeText = () => ({
    day: formatMessage({
        id: 'common.dayAgo',
        defaultMessage: '{count} day ago'
    }),
    days: formatMessage({
        id: 'common.daysAgo',
        defaultMessage: '{count} days ago'
    }),
    month: formatMessage({
        id: 'common.monthAgo',
        defaultMessage: '{count} month ago'
    }),
    months: formatMessage({
        id: 'common.monthsAgo',
        defaultMessage: '{count} months ago'
    }),
    year: formatMessage({
        id: 'common.yearAgo',
        defaultMessage: '{count} year ago'
    }),
    years: formatMessage({
        id: 'common.yearsAgo',
        defaultMessage: '{count} years ago'
    })
});
