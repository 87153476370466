import Auth from "@aws-amplify/auth";
import {
    USER_DISABLED_EXCEPTION,
    USER_EMAIL_VERIFICATION_REQUIRED_EXCEPTION,
    USER_LAMBDA_VALIDATION_EXCEPTION
} from "../../../demo/constants";
import type { AWSError } from "../../redux/middleware/aws/types";
import type { AwsServiceNameT } from "./types";
import { TRIAL_FAILURE_MESSAGES } from "../../../../server/shared/trial/constants.js";

export const callAwsAmplifyAuth = (serviceMethod: AwsServiceNameT, serviceMethodParams: Array<any> = []) => {
    return Auth[serviceMethod](...serviceMethodParams)
        .catch((error: AWSError) => {
            let customError;
            if (error.code === USER_LAMBDA_VALIDATION_EXCEPTION &&
                error.message.indexOf('PreTokenGeneration failed with error') > -1) {
                customError = {
                    code: error.code,
                    message: error.message
                        .replace('PreTokenGeneration failed with error', '')
                        .replace('..', '.')
                        .trim(),
                    name: error.name
                };
            } else if (error.code === USER_LAMBDA_VALIDATION_EXCEPTION &&
                error.message.indexOf(`PreAuthentication failed with error ${TRIAL_FAILURE_MESSAGES.trialUserIsDisabled}`) > -1) {
                customError = {
                    code: USER_DISABLED_EXCEPTION,
                    message: error.message
                        .replace(`PreAuthentication failed with error ${TRIAL_FAILURE_MESSAGES.trialUserIsDisabled}`, '')
                        .replace(/.$/, '')
                        .trim(),
                    name: error.name
                };
            } else if (error.code === USER_LAMBDA_VALIDATION_EXCEPTION &&
                error.message.indexOf('PreAuthentication failed with error You need to verify your email before continuing') > -1) {
                customError = {
                    code: USER_EMAIL_VERIFICATION_REQUIRED_EXCEPTION,
                    message: error.message
                        .replace('PreAuthentication failed with error You need to verify your email before continuing', '')
                        .replace('.', '')
                        .trim(),
                    name: error.name
                };
            } else if (error.code === USER_LAMBDA_VALIDATION_EXCEPTION &&
                error.message.indexOf('PreAuthentication failed with error') > -1) {
                customError = {
                    code: error.code,
                    message: error.message
                        .replace('PreAuthentication failed with error', '')
                        .replace('..', '.')
                        .trim(),
                    name: error.name
                };
            } else if (error.code === USER_LAMBDA_VALIDATION_EXCEPTION &&
                error.message.indexOf('PreSignUp failed with error') > -1) {
                customError = {
                    code: error.code,
                    message: error.message
                        .replace('PreSignUp failed with error', '')
                        .replace('..', '.')
                        .trim(),
                    name: error.name
                };
            }
            throw customError || error;
        });
};
