import * as R from 'ramda';
import makeEpic from '../../../../epics/makeEpic';
import { receiveOnly } from '../../../../epics/makeCondition';
import valueActionType from './valueActionType';
import workspaceBBoxValueActionType from '../workspaceBBox/valueActionType';
import * as interactionModes from '../componentsEval/userInteractionMutations/interactionModes';
import { isHoverShiftBarOrComponentHandle } from '../../../../utils/handle/index';
import { memoMaxOne } from '../../../../../utils/memo';
import {
    ReceiveOnlyComponentsMap,
    UserInteractionModeSelector,
    ReceiveOnlySelectedComponentsIdsSelector,
    ReceiveOnlySectionInsertionState
} from "../componentsEval/selectorActionTypes";
import { topmostHandlesQueueValueActionType } from "../topmostHandlesQueue/valueActionType";
import { getComponentBBox, zeroBBox } from "../../../../utils/componentsMap/index";
import {
    CONTEXT_MENU_MOUSE_ENTER_OVERLAPPING_COMPONENT, CONTEXT_MENU_MOUSE_LEAVE_OVERLAPPING_COMPONENT
} from '../../../ContextMenu/actionTypes';

import type { ComponentHoverDecorationState, SectionDecorations } from './flowTypes';
import type { Epic } from '../../../../epics/flowTypes';
import type { ComponentsIds, ComponentsMap, Handle } from "../../../../redux/modules/children/workspace/flowTypes";
import type { Attachments } from "../componentAttachements/flowTypes";
import {
    isFooterSection, isHeaderSection,
    getPrevOrNextSectionInfo,
    commonSectionForComponents,
} from "../../../oneweb/Section/utils";
import type { BBox } from "../../../App/flowTypes";
import { ReceiveOnlyAttachments } from "../componentAttachements/selectorActionTypes";
import { addIconHandleCreatorN, addIconHandleCreatorS } from "../../../../utils/handle/add";
import type { HandleCreator } from "../../../../utils/handle/flowTypes";
import { mousePositionWithRespectToTemplateAreaValueActionType } from "../mousePositionWithRespectToTemplateArea/valueActionType";
import type { Position } from "../../../../redux/modules/flowTypes";
import { getSectionAtPosition } from "../isPageMode/utils";

type NullableSectionDecorations = null | undefined | SectionDecorations;
export const
    defaultState: ComponentHoverDecorationState = {
        isVisible: false,
        topmostHandlesQueue: [],
        makeInvisible: false,
        workspaceBBox: zeroBBox,
        componentBBox: zeroBBox,
        sectionDecorations: null
    };

const makeHiddenStateFactory = isInstant =>
        memoMaxOne((state: ComponentHoverDecorationState, topmostHandlesQueue, sectionDecorations) => ({
            ...state,
            isVisible: false,
            makeInvisible: isInstant,
            componentBBox: zeroBBox,
            topmostHandlesQueue,
            sectionDecorations
        })),
    makeHiddenState = makeHiddenStateFactory(false),
    makeInstantHiddenState = makeHiddenStateFactory(true),
    constructSectionHoverDecorations = memoMaxOne((
        sectionId: string,
        selectedComponentIds: ComponentsIds,
        componentsMap: ComponentsMap,
        workspaceBBox: BBox,
        decoration: NullableSectionDecorations
    ): NullableSectionDecorations => {
        if (sectionId && !selectedComponentIds.includes(sectionId)) {
            const section = componentsMap[sectionId],
                sectionBBox = getComponentBBox(section, workspaceBBox);

            if (decoration) {
                const { bBox, section: { id } } = decoration;
                if (R.equals(bBox, sectionBBox) && section.id === id) {
                    return decoration;
                }
            }
            const isHeader = isHeaderSection(section),
                isFooter = isFooterSection(section),
                { section: nextSection } = getPrevOrNextSectionInfo(sectionId, componentsMap, true),
                { section: prevSection } = getPrevOrNextSectionInfo(sectionId, componentsMap),
                handlers: Array<HandleCreator> = [];

            if (!isHeader && !(prevSection && selectedComponentIds.includes(prevSection.id))) {
                handlers.push(addIconHandleCreatorN);
            }
            if (!isFooter && !(nextSection && selectedComponentIds.includes(nextSection.id))) {
                handlers.push(addIconHandleCreatorS);
            }
            const addIconHandles: Array<Handle> = handlers.map((creatHandle: HandleCreator): Handle => creatHandle({
                bBox: sectionBBox,
                componentsIds: selectedComponentIds,
                initialZIndex: 10000
            }));
            return {
                section,
                bBox: sectionBBox,
                addIconHandles,
            };
        }
        return null;
    }),
    getSectionDecorations = (
        mousePosition: Position,
        selectedComponentIds: ComponentsIds,
        attachments: Attachments,
        componentsMap: ComponentsMap,
        workspaceBBox: BBox,
        decoration: NullableSectionDecorations
    ) => {
        const topMostSection = getSectionAtPosition(mousePosition, componentsMap, true),
            parentSectionOfSelectedComponents = commonSectionForComponents(selectedComponentIds, attachments, componentsMap);

        if (!topMostSection) {
            return null;
        }

        return constructSectionHoverDecorations(
            topMostSection.id,
            [...selectedComponentIds, parentSectionOfSelectedComponents],
            componentsMap,
            workspaceBBox,
            decoration
        );
    };

const epic: Epic<ComponentHoverDecorationState, void, string> = makeEpic({
    defaultState,
    valueActionType,
    updaters: [
        {
            conditions: [
                CONTEXT_MENU_MOUSE_ENTER_OVERLAPPING_COMPONENT,
                receiveOnly(workspaceBBoxValueActionType),
                ReceiveOnlyComponentsMap,
            ],
            reducer: ({ values: [{ componentId }, workspaceBBox, componentsMap] }) => {
                return {
                    state: {
                        ...defaultState,
                        isVisible: true,
                        workspaceBBox,
                        componentBBox: getComponentBBox(componentsMap[componentId], workspaceBBox),
                        sectionDecorations: null,
                    }
                };
            }
        },
        {
            conditions: [CONTEXT_MENU_MOUSE_LEAVE_OVERLAPPING_COMPONENT],
            reducer: () => ({ state: defaultState }),
        },
        {
            conditions: [
                receiveOnly(mousePositionWithRespectToTemplateAreaValueActionType),
                ReceiveOnlyComponentsMap,
                receiveOnly(workspaceBBoxValueActionType),
                ReceiveOnlySelectedComponentsIdsSelector,
                ReceiveOnlyAttachments,
                ReceiveOnlySectionInsertionState,
                UserInteractionModeSelector,
                topmostHandlesQueueValueActionType
            ],
            reducer: ({
                values: [
                    mousePosition,
                    componentsMap,
                    workspaceBBox,
                    selectedComponentIds,
                    attachments,
                    { inProgress: addSectionProgress },
                    userInteractionMode,
                    topmostHandlesQueue,
                ],
                state: epicState
            }) => {
                const topMostHandle = topmostHandlesQueue[0],
                    isIdle = userInteractionMode === interactionModes.IDLE,
                    isMouseDown = userInteractionMode === interactionModes.MOUSE_DOWN_ON_HANDLE,
                    isMoving = userInteractionMode === interactionModes.MOVING_COMPONENTS;
                let sectionDecorations = null;
                if ((isIdle || isMoving || isMouseDown) && topMostHandle) {
                    if (!componentsMap[topMostHandle.componentsIds[0]]) {
                        // out of sync possible after recovery from exception
                        return { state: { ...epicState, componentBBox: zeroBBox, sectionDecorations } };
                    }

                    sectionDecorations = !addSectionProgress ? getSectionDecorations(
                        mousePosition,
                        selectedComponentIds,
                        attachments,
                        componentsMap,
                        workspaceBBox,
                        epicState.sectionDecorations
                    ) : null;

                    if (isIdle && isHoverShiftBarOrComponentHandle(topMostHandle.kind)) {
                        return {
                            state: {
                                isVisible: true,
                                topmostHandlesQueue,
                                workspaceBBox,
                                makeInvisible: false,
                                componentBBox: getComponentBBox(
                                    componentsMap[topMostHandle.componentsIds[0]],
                                    workspaceBBox
                                ),
                                sectionDecorations
                            }
                        };
                    }
                }
                if (epicState.isVisible) {
                    if (userInteractionMode === interactionModes.SHIFTBAR_MOVING
                        || userInteractionMode === interactionModes.MOVING_COMPONENTS) {
                        return { state: makeInstantHiddenState(epicState, topmostHandlesQueue, sectionDecorations) };
                    }
                    return { state: makeHiddenState(epicState, topmostHandlesQueue, sectionDecorations) };
                }
                if (epicState.sectionDecorations !== sectionDecorations) {
                    return { state: { ...epicState, sectionDecorations } };
                }
                return { state: epicState };
            }
        }
    ]
});

export {
    epic as default,
    constructSectionHoverDecorations
};
