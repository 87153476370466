import React from 'react';
import cx from 'classnames';
import VerticalSpacer from "../../../../view/common/VerticalSpacer";
import { EPSeparator } from "../../../Panel/view/EPSeparator";
import Msg from "../../../../view/intl/Msg";
import { SHOW_WEBSHOP_ADMIN_DIALOG } from "../WebshopAdminDialog/actionTypes";
import { EXTENDED_PANEL_CLOSE } from "../../../Panel/actionTypes";
import { PRODUCT_WIDGET_UPDATE_PRODUCT_SETUP_INSERTER_METHOD } from '../../ProductWidget/actionTypes';
import { SHOW_TOOLTIP_FOR_EXTENDED_PANEL, CLOSE_TOOLTIP } from "../../../Tooltip/actionTypes";
import { ExtendedPanelTooltip } from "../../../Tooltip/ids";

import styles from './styles.css';

type Props = {
    dispatch: Function;
    dismissSetupProductNotification: Function;
    isTrialUser?: boolean;
};

const calcToRightAndVCenter = ({ top, width, height, left }) => {
    // position relative to the help tip
    return { x: left + width, y: (top + (height / 2)) - 4 };
};

export const SetupProductNotification = ({
    dispatch,
    isTrialUser,
    dismissSetupProductNotification
}: Props) => {
    const ref = React.useRef<HTMLButtonElement>(null);

    const [buttonPosition, setButtonPosition] = React.useState<DOMRect | null>(null);

    React.useEffect(() => {
        if (ref && ref.current) {
            const clientRect = ref.current.getBoundingClientRect();
            setButtonPosition(clientRect);
        }
    }, []);

    const showDisabledTooltip = () => {
        if (isTrialUser && buttonPosition) {
            dispatch({
                type: SHOW_TOOLTIP_FOR_EXTENDED_PANEL,
                payload: {
                    position: calcToRightAndVCenter(buttonPosition),
                    elementDimensions: buttonPosition,
                    id: ExtendedPanelTooltip,
                    customClass: styles.helpTipContainer,
                    msg: { text: 'msg: demo.restrictedFeature'
                    + ' {This feature is not available in the trial version.}' },
                }
            });
        }
    };

    const hideTooltip = () => {
        dispatch({ type: CLOSE_TOOLTIP });
    };

    const openSetupShopDialog = (dispatch) => {
        if (!isTrialUser) {
            return () => {
                dispatch({ type: PRODUCT_WIDGET_UPDATE_PRODUCT_SETUP_INSERTER_METHOD });
                dispatch({ type: SHOW_WEBSHOP_ADMIN_DIALOG });
                dispatch({ type: EXTENDED_PANEL_CLOSE });
            };
        }
        return () => null;
    };

    return (
        <React.Fragment>
            <VerticalSpacer y={20} />
            <div className={styles.setupProductNotification}>
                <div className={styles.closeBtn}>
                    <div
                        className={styles.closeIconBlack}
                        onClick={() => {
                            dismissSetupProductNotification();
                        }}
                    />
                </div>
                <div className={styles.messageTitle}>
                    <Msg k="webshopInserter.setupProduct.MessageTitle">
                        Set up your first product
                    </Msg>
                </div>
                <div className={styles.messageBody}>
                    <Msg k="webshopInserter.setupProduct.Message">
                        Start selling directly from your website by adding a product
                        your visitors can purchase
                    </Msg>
                </div>
                <div>
                    <button
                        type="button"
                        onClick={openSetupShopDialog(dispatch)}
                        className={isTrialUser ? cx(styles.manageShopButtonDisabled) : cx(styles.manageShopButton, styles.setupProductBtn)}
                        onMouseEnter={showDisabledTooltip}
                        onMouseLeave={hideTooltip}
                        ref={ref}
                    >
                        <Msg k="component.webshop.productWidget.setupProduct">
                            Set up product
                        </Msg>
                    </button>
                </div>
            </div>
            <VerticalSpacer y={20} />
            <EPSeparator />
        </React.Fragment>
    );
};
