import * as main from './pages/main';
import * as font from './pages/font';
import * as button from './pages/button';
import makePages from '../../../PropertiesPanel/view/makePages';
import addGoogleFont from '../../../PropertiesPanel/view/AddGoogleFont';

const propertiesPanelConfig = {
    pages: makePages(main, font, addGoogleFont, button),
    defaultPageId: main.id
};

export default propertiesPanelConfig;
