/* @flow */

const { TRIAL_URI_ENTRY } = require("../constants.js");
const { PartnerTldRegexGroup } = require('../../partners/partnerTld.js');

const HostnamePrefixRegex = new RegExp(`^(?:https?://)?${TRIAL_URI_ENTRY}-.+${PartnerTldRegexGroup}`);

const checkTrialUriPrefix = (uri/*: string */)/*: boolean */ => HostnamePrefixRegex.test(uri);

module.exports = {
    checkTrialUriPrefix,
};
