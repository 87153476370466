import * as R from 'ramda';
import undoManagerValueActionType from '../../epics/undoManager/valueActionType';
import { warn } from '../../../utils/log.js';
import type { AppState } from "../modules/flowTypes";
import type { UndoEpicState } from "../../epics/undoManager/factory";
import * as exceptionTypes from "./exceptionTypes";
import { RECOVER_AFTER_EXCEPTION, RENDER_EXCEPTION } from "./actionTypes";
import { getEpicStateFromAppStateSelector } from "../modules/selectors";
import { WORKSPACE_READY } from "../../components/Workspace/actionTypes";
import {
    WORKSPACE_SAVE_STATUS_CANT_SAVE_AFTER_SUCCESS_SAVE
} from "../../components/Workspace/epics/saveStatus/actionTypes";
import { arrayToTrueMap } from "../../utils/arrayToTrueMap";
import { WINDOW_MOUSE_UP } from "../../components/App/actionTypes";
import { REDO, UNDO } from "../../epics/undoManager/actionTypes";
import { disableRecoverAfterException } from "../../utils/isDebug";

export type RecoverySnapshot = {
    appState: AppState,
    timestamp: number
}

export type RecoveryData = {
    snapshots: Array<RecoverySnapshot>,
    recoveredTimestamps: Array<number>,
    rerenderBrokenUI: boolean,
    hasError: false,
    readyForRecovery: boolean
}

const
    recoveryDataDefaultState: RecoveryData = {
        snapshots: [],
        recoveredTimestamps: [],
        rerenderBrokenUI: false,
        hasError: false,
        readyForRecovery: false
    },
    MaxSnapshotsCount = 25,
    // TODO WBTGEN-2985 RecoveryFromException should collapse close undo states to prevent possible recursive bugs
    // TODO WBTGEN-2988 RecoveryFromException should collapse initial app state to one, just after workspace is ready
    // lastSnapshotTimestampIsTooClose = (currentTimestamp) =>
    //    R.pipe(R.last, R.path(['timestamp']), (timestamp) => timestamp && currentTimestamp - timestamp < 1),
    appendRecoverySnapshot = appState => {
        const timestamp = Date.now();
        return R.evolve({
            recoveryData: {
                snapshots: R.pipe(
                    // R.when(lastSnapshotTimestampIsTooClose(timestamp), R.dropLast(1)),
                    R.append({ appState, timestamp }),
                    R.when(({ length }) => length > MaxSnapshotsCount, R.drop(1))
                )
            }
        }, appState);
    },

    makeRecoverToPrevSnapshot = (howMuchStepsBack: number) => (appState: AppState) => {
        const
            { snapshots } = appState.recoveryData,
            prevSnapshot = snapshots[Math.max(0, snapshots.length - howMuchStepsBack)];

        if (prevSnapshot) {
            return prevSnapshot.appState;
        } else {
            return appState;
        }
    },

    setRerenderBrokenUI = R.assocPath(['recoveryData', 'rerenderBrokenUI']),
    setHasError = R.assocPath(['recoveryData', 'hasError']),
    setReadyForRecovery = R.assocPath(['recoveryData', 'readyForRecovery']),
    rerenderBrokenUi = setRerenderBrokenUI(true),
    clearRerenderBrokenUi = setRerenderBrokenUI(false),
    createSnapshotActionsMap = arrayToTrueMap([WORKSPACE_READY, WORKSPACE_SAVE_STATUS_CANT_SAVE_AFTER_SUCCESS_SAVE]),
    tryToRerenderBrokenUiActionTypes = arrayToTrueMap([
        WORKSPACE_READY, /* after page switch */
        WINDOW_MOUSE_UP, /* after user interaction finished */
        UNDO,
        REDO
    ]),
    appendRecoveredTimestamp = R.evolve({
        recoveryData: {
            recoveredTimestamps: (recoveredTimestamps) => [...recoveredTimestamps, Date.now()]
        }
    }),
    clearRecoveredTimestamps = R.evolve({
        recoveryData: {
            recoveredTimestamps: () => []
        }
    }),
    reducer = (appState: AppState, action: Action): AppState => {
        if (createSnapshotActionsMap[action.type]) {
            return R.pipe(
                setReadyForRecovery(true),
                appendRecoverySnapshot
            )(appState);
        }

        const { recoveryData } = appState;
        if (!disableRecoverAfterException() && action.type === RECOVER_AFTER_EXCEPTION) {
            const
                recoveredTimes = recoveryData.recoveredTimestamps.length;

            if (recoveredTimes > recoveryData.snapshots.length) {
                // give up
                return R.pipe(
                    setReadyForRecovery(false),
                    setHasError(true)
                )(appState);
            }
            const
                recoverySteps = [
                    makeRecoverToPrevSnapshot(recoveredTimes + 1),
                    appendRecoveredTimestamp
                ],
                exceptionType = action.payload.type;

            switch (exceptionType) {
                case exceptionTypes.RENDER:
                    recoverySteps.push(rerenderBrokenUi);
                    break;
                case exceptionTypes.EPIC:
                case exceptionTypes.REDUCER:
                case exceptionTypes.MIDDLEWARE:
                case exceptionTypes.SAGA:
                    break;
                default:
                    throw new Error(`Unknown RECOVER_AFTER_EXCEPTION type: ${exceptionType}`);
            }

            const recoveredState = R.pipe(...recoverySteps)(appState);
            warn('State recovered to: ', recoveredState, 'after', exceptionType, 'exception');
            return appendRecoverySnapshot(recoveredState);
        }

        const appStateUpdaters: any = [];
        if (recoveryData.rerenderBrokenUI) {
            appStateUpdaters.push(clearRerenderBrokenUi);
        }

        const
            {
                undoState: {
                    commandsStack,
                    commandsStackIndex
                }
            }: UndoEpicState = getEpicStateFromAppStateSelector(appState, undoManagerValueActionType),
            lastSnapshot = R.last(recoveryData.snapshots);

        if (commandsStack.length === 0 || !recoveryData.readyForRecovery) {
            // nothing to add to recovery stack
        } else if (!lastSnapshot) {
            appStateUpdaters.push(appendRecoverySnapshot);
        } else {
            const
                {
                    undoState: {
                        commandsStack: lastSnapshotCommandsStack,
                        commandsStackIndex: lastSnapshotCommandsStackIndex
                    }
                }: UndoEpicState = getEpicStateFromAppStateSelector(lastSnapshot.appState, undoManagerValueActionType);

            if (commandsStack[commandsStackIndex] !== lastSnapshotCommandsStack[lastSnapshotCommandsStackIndex]) {
                appStateUpdaters.push(appendRecoverySnapshot);
                appStateUpdaters.push(clearRecoveredTimestamps);
            }
        }

        if (action.type === RENDER_EXCEPTION) {
            appStateUpdaters.push(setHasError(true));
        }

        if (tryToRerenderBrokenUiActionTypes[action.type] && recoveryData.hasError) {
            appStateUpdaters.push(rerenderBrokenUi);
            appStateUpdaters.push(setHasError(false));
        }

        if (appStateUpdaters.length === 0) {
            return appState;
        }

        return R.pipe(...appStateUpdaters)(appState);
    };

export {
    recoveryDataDefaultState,
    reducer
};
