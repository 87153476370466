import { calcGalleryMaxThumbnailDimensions } from "./calcRenderProps";
import type { GalleryComponent } from "./flowTypes";

const buildNumberMatrix = (items: Array<number>, numberOfColumns: number): Array<Array<number>> => {
    const itms = [...items];
    return itms.reduce((matrix:Array<Array<number>>, item, currentIndex) => {
        const currentRowIndex = Math.floor(currentIndex / numberOfColumns);
        const currentMatrix = [...matrix];
        if (!currentMatrix[currentRowIndex]) {
            currentMatrix[currentRowIndex] = [];
        }
        currentMatrix[currentRowIndex].push(item);
        return currentMatrix;
    }, []);
};
const getMaxValueByRow = (matrix: Array<Array<number>>): Array<number> => matrix.map(rowHeights => Math.max(...rowHeights));
export const calcGalleryHeight =
    ({ component, highestCaptionPx, captionHeightsMap }:
    { component: GalleryComponent, highestCaptionPx: number, captionHeightsMap: ObjectMap }) => {
        const
            rows = Math.ceil(component.images.length / component.columns),
            accountedTotalGapValue = (component.spacingPx * (rows - 1)),
            { maxThumbnailHeight } = calcGalleryMaxThumbnailDimensions(component);
        if (!component.compactView) {
            return Math.round(((
                maxThumbnailHeight
                + (component.captionsEnabled ? highestCaptionPx : 0)
            ) * rows) + accountedTotalGapValue);
        }

        if (captionHeightsMap) {
            const captionHeights = component.captionsEnabled
                ? Object.keys(captionHeightsMap).sort().map(key => captionHeightsMap[key])
                : (new Array(component.images.length)).fill(0);
            const cellHeights: Array<number> = captionHeights
                /*.map(({ asset: { height, width } }) => Math.min(height, maxThumbnailHeight, height * (maxThumbnailWidth / width)))*/
                .map((ht) => ht + maxThumbnailHeight);
            const heightMatrix = buildNumberMatrix(cellHeights, component.columns);
            const maxHeightsPerRow = getMaxValueByRow(heightMatrix);

            return maxHeightsPerRow
                .reduce((galleryHeight, height) => galleryHeight + height, accountedTotalGapValue);
        }

        return component.height;
    };
