import * as React from 'react';
import { connect } from 'react-redux';
import { PopupCom } from '@sepo27/react-redux-lib';
import styles from './UnwrapTip.css';
import tooltipStyles from '../presentational/Tooltip/Tooltip.css';
import type { AppState } from '../../redux/modules/flowTypes';
import { DemoTipHandler } from '../../../demo/modules/tip/DemoTipHandler';
import { parseIntDec } from '../../../utils/number';
import type { ReactElementRef } from '../../globalTypes';
import Msg from '../../view/intl/Msg';
import { makeEpicStateSelector } from '../../epics/makeEpic';
import wrapVat from '../Workspace/epics/wrap/valueActionType';
import { Lit } from '../../lit';
import { HIDE_UNWRAPED_TIP } from '../Workspace/epics/wrap/actionTypes';

const TIP_TRANSITION_DURATION = parseIntDec(tooltipStyles.tipTransitionDuration);

type Props = {
    show: boolean,
    dispatch: Dispatch,
};

export class UnwrapTipComponent extends React.Component<Props> {
    popupRef: ReactElementRef<any>;

    constructor() {
        // @ts-ignore
        super();
        this.popupRef = React.createRef();
    }

    render() {
        return (
            <DemoTipHandler
                show={this.props.show}
                hide={() => this.props.dispatch({ type: HIDE_UNWRAPED_TIP })}
                render={
                    ({ show, ...renderProps }) => (
                        <PopupCom
                            show={show}
                            top={0} // see DemoSaveTip.css note
                            left={0} // see DemoSaveTip.css note
                            theme={{
                                container: styles.container,
                                show: styles.show,
                            }}
                            removeTimeout={TIP_TRANSITION_DURATION}
                            ref={this.popupRef}
                            {...renderProps}
                        >
                            <Msg k="wrap.unwrapTip" className={styles.text}>
                                To wrap the element with text again, place it on top of text and right-click.
                            </Msg>
                            <span className={styles.hintIcn} />
                        </PopupCom>
                    )
                }
                autoHide={12000}
            />
        );
    }
}

const unwrapTipVisibleSelector = makeEpicStateSelector(wrapVat);

const mapStateToProps = (appState: AppState) => ({
    show: unwrapTipVisibleSelector(appState)[Lit.unwrapedTipVisible],
});

export const UnwrapTip = connect(mapStateToProps)(UnwrapTipComponent);
