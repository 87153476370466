import { call, take } from "redux-saga/effects"; // eslint-disable-line n/file-extension-in-import
import Resource from "../../../Resource";
import FileListValidator, {
    FilterResult,
    Invalids
} from "../../../../../../../utils/FileListValidator";
import { openDialogGen, selectGen } from "../../../../../../../utils/saga/index";
import { CLOSE_DIALOG } from "../../../../../actionTypes";
import {
    SvgSizeErrorDialogId,
    ImageSizeErrorDialogId,
    InvalidErrorDialogId,
    UnsupportedErrorDialogId
} from "../../../../../../../view/common/FileChooser/dialogIds";
import getResourcesGen from "../utils/getResourcesGen";
import { encodeWebspaceUri } from "../../../../../../../../dal/utils/webspaceUriTransformers";
import resolveFileNameConflictSaga from "../resolveFileNameConflictSaga";
import type { AppState } from "../../../../../flowTypes";
import { fileChooserBasePathSelector } from "../../../../../selectors";
import { isWsbDemo } from "../../../../../../../debug/isWsbDemo";
import {
    WEBSPACE_MAX_IMAGE_RESOLUTION,
    MAX_ALLOWED_SVG_SIZE_BYTES,
    ONE_MB_BYTES
} from "../../../../../../../../../server/shared/webspace/constants.js";
import getFileExtensionFromFileName from "../../../utils/getFileExtensionFromFileName";
import { VideoSizeLimitExceededDialogId, VideoUploadLimitDialogId } from "../../../../../../../components/oneweb/Video/dialogIds";

export type ResourceData = {
    resource: Resource,
    file: File
};

export type ResolveUploadFilesSagaResult = {
    allResources: Array<Resource>,
    uploadResources: Array<ResourceData>,
    cancelResourcesCnt: number,
    totalUploadResourcesCnt: number,
    webpaceCurrentPath: string
};

const showErrorGen = function* (dialogId: string, files: Array<File>, props: Record<string, any> = {}) {
    const fileNames = files.map(f => f.name);
    yield* openDialogGen(dialogId, { ...props, fileNames });
    yield take(CLOSE_DIALOG);
};

const notifyErrorsSaga = function* (invalids: Invalids) {
    if (invalids.unsupported.length) {
        yield* showErrorGen(UnsupportedErrorDialogId, invalids.unsupported);
    }

    if (invalids.svgSize.length) {
        yield* showErrorGen(
            SvgSizeErrorDialogId,
            invalids.svgSize,
            { maxSvgFileSize: Math.round(MAX_ALLOWED_SVG_SIZE_BYTES / ONE_MB_BYTES) }
        );
    }

    if (invalids.pixelSize.length) {
        yield* showErrorGen(
            ImageSizeErrorDialogId,
            invalids.pixelSize,
            { maxPixelSize: Math.floor(WEBSPACE_MAX_IMAGE_RESOLUTION / 1000000) }
        );
    }

    if (invalids.badImage.length) {
        yield* showErrorGen(InvalidErrorDialogId, invalids.badImage);
    }

    if (invalids.bigVideos.length) {
        yield* showErrorGen(VideoSizeLimitExceededDialogId, invalids.bigVideos);
    }

    if (invalids.videoUploadLimitExceeded.length) {
        yield* showErrorGen(VideoUploadLimitDialogId, invalids.bigVideos);
    }
};

const validateGen = function* (files: FileList, props: any = {}): Generator<any, FilterResult, any> {
    const
        fcContentTypes = yield* selectGen((appState: AppState) => appState.fileChooser.contentTypes),
        flv = new FileListValidator();

    flv.setSupportedTypes(fcContentTypes);

    return yield call([flv, flv.filter], files, props);
};

// eslint-disable-next-line max-len
export const resolveUploadFilesSaga = function* (files: any, props: any = {}): Generator<any, ResolveUploadFilesSagaResult | undefined | null, any> {
    // validate
    const validation = yield* validateGen(files, props);

    yield* notifyErrorsSaga(validation.invalids);
    if (!validation.valids.length) return null;

    let
        allResources = yield* getResourcesGen(),
        cancelResourcesCnt = 0;

    const uploadResources: { resource: Resource; file: any; }[] = [];

    // resolve filename conflicts or cancels
    const currentPath = yield* selectGen(fileChooserBasePathSelector);
    for (const validationValid of validation.valids) {
        let theFile = validationValid;

        if (getFileExtensionFromFileName(theFile.name) === 'jfif') {
            theFile = new File([theFile], theFile.name.replace(".jfif", ".jpeg"), { type: theFile.type });
        }
        const
            resourceName = encodeWebspaceUri(theFile.name),
            conflict = yield* resolveFileNameConflictSaga(resourceName);

        if (conflict) {
            if (conflict.close) {
                allResources = allResources.reduce((acc, res: Resource) => (
                    res.getName() === resourceName ? acc : [...acc, res]
                ), []);
                cancelResourcesCnt++;
                continue;
            } else if (conflict.rename) {
                allResources = allResources.reduce((acc, res: Resource) => {
                    if (res.getName() === resourceName) {
                        const ghost = new Resource(
                            res.basePath,
                            { ...res, isGhost: true, href: conflict.rename }
                        );
                        uploadResources.push({
                            resource: ghost,
                            file: theFile
                        });
                        return [...acc, res, ghost];
                    }
                    return [...acc, res];
                }, []);
            } else {
                // overwrite
                allResources = allResources.map((res: Resource) => {
                    if (res.getName() === resourceName) {
                        const ghost = new Resource(res.basePath, { ...res, isGhost: true });
                        uploadResources.push({
                            resource: ghost,
                            file: theFile
                        });
                        return ghost;
                    }
                    return res;
                });
            }
        } else {
            const resource = new Resource(currentPath, {
                isGhost: true,
                href: resourceName,
                contentType: theFile.type,
                size: 0,
                createdDate: new Date().getTime(),
                isTrial: isWsbDemo(),
            });
            allResources = [...allResources, resource];
            uploadResources.push({
                resource,
                file: theFile
            });
        }
    }

    if (!uploadResources.length) return null;

    return {
        allResources,
        uploadResources,
        cancelResourcesCnt,
        totalUploadResourcesCnt: validation.valids.length,
        webpaceCurrentPath: currentPath
    };
};
