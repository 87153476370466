import * as React from 'react';
import styles from './DocumentConfigDialogComponent.css';
import { DocumentConfigDialogTabIdEnum } from "../constants";
import TabDialog, { Tab } from '../../../../../view/common/dialogs/TabDialog/index';
import { StripTypes } from '../../../../../view/common/dialogs/baseDialog/index';
import type { DocumentConfigDialogProps } from "../flowTypes";
import {
    CHANGE_DOCUMENT_EXTERNAL_URL_ACTION,
    saveDocumentConfigAction,
    switchDocumentConfigTabAction,
    updateDocumentConfigAction
} from "../../actions";
import FileChooser from '../../../../../view/common/FileChooser/index';
import { ViewModes } from "../../../../../view/common/FileChooser/constants";
import getStyleIntValue from "../../../../../utils/getStyleIntValue";
import { DocumentConfigDialogFooterComponent } from "./DocumentConfigDialogFooterComponent";
import { LabeledUrlInput } from "../../../../inputControls/input/view/index";
import { makeActionForwardToSelectedComponent } from "../../../../../redux/forwardTo";
import type { TabProps } from "../../../../../view/common/dialogs/TabDialog/flowTypes";
import { closeDocumentConfigDialogAction } from "../actions";

const
    FC_WIDTH = getStyleIntValue(styles, 'fcWidth'),
    FC_TABLE_LIST_HEIGHT = getStyleIntValue(styles, 'fcTableListHeight');

export class DocumentConfigDialogComponent extends React.Component<DocumentConfigDialogProps> {
    onTabSwitch = (tab: React.Component<TabProps>) => {
        this.props.dispatch(switchDocumentConfigTabAction(tab.props.id));
    };

    onSaveHandler = () => {
        if (this.isSaveDisabled()) return;

        const
            { state, state: { currentTabId }, src, dispatch } = this.props,
            nextValue = state[currentTabId],
            action = src
                // $FlowFixMe: checks on value is set is done in isSaveDisabled()
                ? makeActionForwardToSelectedComponent(updateDocumentConfigAction(nextValue))
                // $FlowFixMe: checks on value is set is done in isSaveDisabled()
                : saveDocumentConfigAction(nextValue);

        dispatch(action);
    };

    isSaveDisabled(): boolean {
        const { state, state: { currentTabId } } = this.props;
        return currentTabId === DocumentConfigDialogTabIdEnum.FILE
            ? !state[DocumentConfigDialogTabIdEnum.FILE]
            : !state[DocumentConfigDialogTabIdEnum.URL]
                || !state[DocumentConfigDialogTabIdEnum.URL].isValid
                || !state[DocumentConfigDialogTabIdEnum.URL].isTouched;
    }

    onClose = () => this.props.dispatch(closeDocumentConfigDialogAction());

    render() {
        const
            { state: { currentTabId, file, url }, dispatch } = this.props,
            fcSelection = file && [file];

        return (
            <TabDialog
                stripType={StripTypes.NONE}
                dialogbodyClassName={styles.dialogBody}
                tabHeadingCustomStyle={styles.pageHeading}
                activeTabKey={currentTabId}
                onTabSwitch={this.onTabSwitch}
                mctaHandler={this.onSaveHandler}
                sctaHandler={this.onClose}
                onClose={this.onClose}
                disabled={this.isSaveDisabled()}
                footer={<DocumentConfigDialogFooterComponent />}
                dispatch={dispatch}
                isFooterRestrictedForDemo
            >
                <Tab
                    id={DocumentConfigDialogTabIdEnum.FILE}
                    label="msg: document.fileTab.label {File}"
                    className={styles.fcTab}
                >
                    <FileChooser
                        title="msg: document.fileTab.title {Document list}"
                        width={FC_WIDTH}
                        listTableHeight={FC_TABLE_LIST_HEIGHT}
                        // @ts-ignore
                        viewMode={ViewModes.LIST}
                        // @ts-ignore
                        preSelection={fcSelection}
                        showPreview={false}
                        isMultiSelect={false}
                        onResourceDoubleClick={this.onSaveHandler}
                        theme={{
                            uploadComboboxClassName: styles.uploadMenu,
                        }}
                    />
                </Tab>
                <Tab
                    id={DocumentConfigDialogTabIdEnum.URL}
                    title="msg: document.urlTab.title {Link to an external document}"
                    label="msg: document.urlTab.label {External URL}"
                >
                    <LabeledUrlInput
                        label="msg: document.urlTab.inputLabel {Link to an external URL:}"
                        state={url}
                        onChangeAction={CHANGE_DOCUMENT_EXTERNAL_URL_ACTION}
                        onEnterPress={this.onSaveHandler}
                        containerClassName={styles.urlInputContainer}
                        dispatch={dispatch}
                    />
                </Tab>
            </TabDialog>
        );
    }
}
