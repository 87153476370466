import * as R from 'ramda';
import type { Block, Column } from "../flowTypes";

import type { AnyComponent } from "../../oneweb/flowTypes";
import type { BBox, ComponentsIds, ComponentsMap } from '../../../redux/modules/children/workspace/flowTypes';
import { getComponentZIndex } from '../../Workspace/zIndex';
import isStretchComponentKind from "../../oneweb/isStretchComponentKind";
import { ContainerKinds } from "../../../utils/containerKinds";
import type { Attachments } from "../../Workspace/epics/componentAttachements/flowTypes";
import { isSectionComponent } from "../../oneweb/Section/utils";

const ContainerKindsForBorderOffsetAdjustment: { [k: string]: boolean } = { ...ContainerKinds };

export type DynamicHeightsComponentsInfoMap = MapT<{ id: string, bottomOverlap: number }>;

export const
    depthSorter = (c1: AnyComponent, c2: AnyComponent): number => getComponentZIndex(c1) - getComponentZIndex(c2),
    leftSorter = (c1: AnyComponent, c2: AnyComponent): number => c1.left - c2.left,
    topSorter = (c1: AnyComponent, c2: AnyComponent): number => {
        const
            { top: top1, left: left1, height: height1 } = c1,
            { top: top2, left: left2, height: height2 } = c2;
        if (top1 === top2) {
            if (left1 === left2) {
                return height2 - height1; // bigger height should appear first in array. TODO Some issues in Flat
            }
            return left1 - left2; // lower left should appear first in array
        }
        return top1 - top2;
    },
    getComponentBBox = (component: Block, isBlock?: boolean, templateWidth?: number): BBox => {
        let { top, left, height, width, kind } = component;

        if (isBlock) { // block will be fixed after child layer, but children are positioned w.r.t block(parent)
            top = 0;
            left = 0;
        }

        let right = left + width;
        return {
            top,
            bottom: top + height,
            right: (isStretchComponentKind(kind, component.stretch || false) ? templateWidth : right),
            left
        };
    },
    isFloating = (cmp: Block, cmpToSearchOutsideOf: Block, templateWidth: number): boolean => {
        if (isStretchComponentKind(cmpToSearchOutsideOf.kind, cmpToSearchOutsideOf.stretch || false)) {
            return false;
        }

        let
            { top: t1, left: l1, right: r1, bottom: b1 } = getComponentBBox(cmp, true, templateWidth),
            { top: t2, left: l2, right: r2, bottom: b2 } = getComponentBBox(cmpToSearchOutsideOf, false, templateWidth);

        return !(
            (r1 <= l2 || l1 >= r2 || t1 >= b2 || b1 <= t2) ||
            (t1 <= t2 && r1 >= r2 && b1 >= b2 && l1 <= l2)
        );
    },
    overlapsVertically = (box1: BBox, box2: BBox): boolean => (box1.left < box2.right && box1.right > box2.left),
    overlapsHorizontally = (box1: BBox, box2: BBox): boolean => (box1.top < box2.bottom && box1.bottom > box2.top),
    overlapsAboveVertically = (box1: BBox, box2: BBox): boolean =>
        (box1.top >= box2.top && box1.left < box2.right && box1.right > box2.left),
    overlapsAboveVertically2 = (box1: BBox, box2: BBox): boolean => (box1.left < box2.right && box1.right > box2.left),
    // $FlowFixMe: skip
    getTopLeftBorder = ({ kind, style, stretch = false }: Block): any => {
        if (!ContainerKindsForBorderOffsetAdjustment[kind]) {
            return null; // we are only concerned with container components which can influence offsets of children
        }

        if (!style || !style.border || style.border.style === "none") {
            return null;
        }

        const borderWidth = style.border.width;

        if (borderWidth) {
            return {
                top: borderWidth[0],
                left: isStretchComponentKind(kind, stretch) ? 0 : borderWidth[3]
            };
        }

        return null;
    },
    getAttachmentsForDynamicHeightCmp = (attachments: Attachments, DHComponentsMap: DynamicHeightsComponentsInfoMap): Attachments => {
        return Object.keys(attachments).reduce((acc, id) => {
            const children = attachments[id];
            if (DHComponentsMap[id] && children && children.length) {
                return {
                    ...acc,
                    [id]: children
                };
            }
            return acc;
        }, {});
    },
    // returns map of the components and all its patents and if a component overlaps over section (bottom) then bottomOverlap value is populated.
    getChangedHeightsCmpInfoMap = (
        cmpIds: ComponentsIds,
        componentsMap: ComponentsMap,
        childToParentMap: Object
    ): DynamicHeightsComponentsInfoMap => {
        return cmpIds.reduce((acc, id) => {
            let parentId = id;
            while (parentId && !acc[parentId]) {
                const { id, top, height } = componentsMap[parentId];
                let bottomOverlap = 0;
                parentId = childToParentMap[parentId];
                const parentComponent = componentsMap[parentId];
                if (parentComponent && isSectionComponent(parentComponent)) {
                    const cmpBottom = top + height,
                        parentBottom = parentComponent.top + parentComponent.height,
                        overlapDiff = cmpBottom - parentBottom;
                    bottomOverlap = overlapDiff > 0 ? overlapDiff : 0;
                }
                acc[id] = { id, bottomOverlap };
            }
            return acc;
        }, {});
    };

export function findPathToComponent(group: Column, componentId: string): Array<string | number> {
    let i = 0,
        j = 0;
    // no floats needed, as they are not supposed to push down
    if (!group.rows) {
        return [];
    }
    for (; i < group.rows.length; i++) {
        let row = group.rows[i];
        for (j = 0; j < row.columns.length; j++) {
            let column = row.columns[j];
            if (column.component && column.component.id === componentId) {
                return ['rows', i, 'columns', j];
            } else if (column.components && column.components.length > 0 &&
                (R.findIndex(R.propEq('id', componentId))(column.components) > -1)) {
                if (column.rows && column.rows.length > 0) {
                    const nestedPath = findPathToComponent(column, componentId);
                    if (nestedPath && nestedPath.length > 0) {
                        return ['rows', i, 'columns', j].concat(nestedPath);
                    }
                } else {
                    return ['rows', i, 'columns', j];
                }
            }
        }
    }
    return [];
}
// Takes path to current component's column. Shifts to the next row. Collects components inside those rows traversing further within group.
// Structure of the path is ['rows', i, 'columns', j, ['rows', i, 'columns', j]]
export function findComponentsFurtherInPath(group: Column, pathInitial: Array<string | number>): Array<string> {
    let path = [...pathInitial],
        components = [];

    while (path.length > 0) {
        // shift to the next row happens here
        // $FlowFixMe array has different types
        // @ts-ignore
        let rowCounter: number = path[path.length - 3] + 1;
        path[path.length - 3] = rowCounter;
        let row = R.path(path.slice(0, path.length - 2))(group);
        while (row) {
            let columnCounter = 0;
            path[path.length - 1] = columnCounter;
            let column = R.path(path)(group);
            while (column) {
                components = components
                    .concat(column.component ? [column.component.id] : R.pluck('id', column.components))
                    .concat(column.floating ? R.pluck('id', column.floating) : []);
                columnCounter++;
                path[path.length - 1] = columnCounter;
                column = R.path(path)(group);
            }
            path[path.length - 1] = 0;
            rowCounter++;
            path[path.length - 3] = rowCounter;
            row = R.path(path.slice(0, path.length - 2))(group);
        }
        // level up in path
        path.splice(-4, path.length);
    }
    return components;
}
