import { connect, useDispatch } from 'react-redux';
import React, { useState, useEffect } from "react";
import cx from "classnames";
import { useIntl } from "../../../view/intl/index";
import { type AppState } from "../../../redux/modules/flowTypes";
import templateSelectorValueActionType from '../epics/templatesList/valueActionType';
import subscriptionTypeValueActionType from '../../App/epics/subscriptionData/valueActionType';
import previewStyles from '../../Preview/View/Preview.css';
import loadingStyles from '../../../view/App.css';
import LoadingIndicator from '../../../view/common/LoadingIndicator/index';
import * as Actions from '../actionTypes';
import { type TemplateSelectorPropsType } from "../flowTypes";
import Preview from './Preview/index';
import previewValueActionType from '../epics/preview/valueActionType';
import { TopBarHeight } from "../../TopBar/constants";
import browserDimensionsValueActionType from '../../App/epics/browserDimensions/valueActionType';
import { DemoBar } from '../../../../demo/modules/bottomBar/DemoBar';
import { siteDataValueActionType } from '../../App/epics/siteData/valueActionType';
import { TopBar } from './TopBar';
import { colorThemeDataEpicStateFromAppStateSelector } from "../../SiteSettings/ColorThemeData/selectorActionTypes";
import { TemplateSelectorMenu } from './TemplateSelectorMenu';
import { TemplatesList } from "./TemplatesList";

import styles from './TemplateSelector.css';
import { TemplateSearchBar } from './TemplateSearchBar';

let templatesList;

const useOnScroll = () => {
    const [hideHeader, setHideHeader] = useState(false);
    const onScroll = e => {
        if (e.scrollTop > 0 && !hideHeader) {
            setHideHeader(true);
        } else if (e.scrollTop === 0 && hideHeader) {
            setHideHeader(false);
        }
    };

    return [hideHeader, onScroll];
};

const Selector = (props) => {
    const [hideHeader, onScroll] = useOnScroll();
    const dispatch = useDispatch();
    const
        {
            selectorListState,
            subscriptionType,
            subscriptionData,
            siteHasPages,
            previewTemplate,
            onePageDesignMap,
            filteredDesigns,
            themeSettings,
            width
        } = props,
        {
            searchText = '',
            templates,
        } = selectorListState;

    if (!templatesList || templatesList !== templates) {
        templatesList = templates;
    }

    useEffect(() => {
        dispatch({ type: Actions.TEMPLATE_SELECTOR_DID_MOUNT });
    }, [dispatch]);

    return (
        <React.Fragment>
            <TopBar
                backButtonActionType={Actions.TEMPLATE_SELECTOR_TOPBAR_BACK_PRESSED}
                showBackButton={siteHasPages}
            />
            <TemplateSearchBar hideHeader={hideHeader} searchText={searchText} />
            {
                // @ts-ignore}
                <div className={cx(styles.templatesContainer, { [styles.hideHeader]: hideHeader })}>
                    <TemplateSelectorMenu
                        selectorListState={selectorListState}
                        subscriptionType={subscriptionType}
                        subscriptionData={subscriptionData}
                    />
                    <TemplatesList
                        width={width}
                        selectorListState={selectorListState}
                        onePageDesignMap={onePageDesignMap}
                        filteredDesigns={filteredDesigns}
                        subscriptionData={subscriptionData}
                        themeSettings={themeSettings}
                        previewTemplate={previewTemplate}
                        subscriptionType={subscriptionType}
                        onScroll={onScroll}
                    />
                </div>
            }
        </React.Fragment>
    );
};

function mapDispatchToProps(dispatch: Dispatch) {
    return {
        previewTemplate: (template, isNormalType: boolean, autoColorMode: boolean) => dispatch({
            type: Actions.TEMPLATE_SELECTOR_SHOW_PREVIEW,
            payload: { template, isNormalType, autoColorMode }
        })
    };
}

const
    selectorMapStateToProps = ({ epics }: AppState): { width: number } => {
        const browserDimensions = epics[browserDimensionsValueActionType].state,
            thumbWidth = 412,
            cols = Math.floor(browserDimensions.width / thumbWidth);
        return ({
            width: cols * thumbWidth
        });
    },
    SelectorList = connect(selectorMapStateToProps, mapDispatchToProps)(Selector);

const TemplateSelector = (props: TemplateSelectorPropsType) => {
    const intl = useIntl();
    const
        {
            selectorListState,
            previewData,
            siteHasPages,
            subscriptionData: { metadata: subscriptionMetadata },
            dispatch
        } = props,
        {
            show,
            onePageDesignMap,
            filteredDesigns,
        } = selectorListState,
        //@ts-ignore
        loading = selectorListState.loading || previewData.loading;

    const
        previewProps = {
            ...previewData,
            subscriptionMetadata,
            intl,
            dispatch
        };

    let
        loadingTop = previewData.show ? 0 : TopBarHeight,
        loadingStyle = {
            top: loadingTop,
            height: `calc(100vh - ${loadingTop}px)`
        };

    return (
        <div className={cx(previewStyles.fullScreen, styles.templateSelector)} style={{ opacity: show ? 1 : 0 }}>
            { /* @ts-ignore */ }
            {previewData.show && <Preview {...previewProps} /> }
            <div className={cx({ [styles.hide]: previewData.show })}>
                <SelectorList {...props} filteredDesigns={filteredDesigns} onePageDesignMap={onePageDesignMap} />
            </div>
            {loading &&
                <LoadingIndicator
                    className={cx(loadingStyles.loadingBox, styles.loader)}
                    // @ts-ignore
                    style={loadingStyle}
                />}
            {siteHasPages && <DemoBar />}
        </div>
    );
};

const mapStateToProps = (appState: AppState) => {
    const { epics, subscriptionData } = appState;
    return {
        selectorListState: epics[templateSelectorValueActionType].state,
        subscriptionType: epics[subscriptionTypeValueActionType].state.subscriptionType,
        previewData: epics[previewValueActionType].state,
        subscriptionData,
        designsData: epics[templateSelectorValueActionType].state.designsData,
        siteHasPages: !!epics[siteDataValueActionType].state.getAllPageRefs().length,
        themeSettings: colorThemeDataEpicStateFromAppStateSelector(appState),
    };
};

export default connect(mapStateToProps)(TemplateSelector);
