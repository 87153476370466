import type { AdjustmentHook, AdjustmentHookConfig, ShouldCallAdjustmentHook } from "../../../Workspace/epics/componentsEval/flowTypes";
import type { Dimensions } from '../../../../redux/modules/children/workspace/flowTypes';
import type { BlogComponent } from './flowTypes';

type AdjustmentData = {
    minDimensions: Dimensions,
}
type Hook = AdjustmentHook<BlogComponent, null, null, AdjustmentData>
type HookConfig = AdjustmentHookConfig<BlogComponent, null, null, AdjustmentData>
type ContactFormShouldCallAdjustmentHook = ShouldCallAdjustmentHook<BlogComponent, null, null, AdjustmentData>

const
    hook: Hook = ({ component, componentExtension, adjustmentData, isTransient }) => {
        const { minDimensions: { height } } = adjustmentData || { minDimensions: { height: 0 } };
        if (!isTransient && (component.height < height)) {
            return [{
                ...component,
                height: Math.max(height, component.height),
            }, componentExtension];
        }
        return [component, componentExtension];
    },
    shouldCallHook: ContactFormShouldCallAdjustmentHook = (prevProps, nextProps) => prevProps.adjustmentData !== nextProps.adjustmentData,
    hookConfig: HookConfig = {
        hook,
        shouldCallHook
    };

export default hookConfig;
