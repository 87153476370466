import { getAppConfig } from "../../../App/epics/appConfig/appConfig.js";
import { getBlogPreviewReqData, getThemeColorData } from "../utils";
import BlogKind from "./kind";
import BlogPreview from "./view/preview";

// config for how the component should behave in preview
// e.g. what it should render
export default {
    kind: BlogKind,
    view: BlogPreview,
    calcProps: ({
        isServerPreview,
        isPublicPreview,
        selectedParentTheme,
    }) => {
        return {
            isServerPreview,
            isPublicPreview,
            selectedParentTheme,
        };
    },
    getSpecificScript: ({
        isServerPreview,
        component: { blogId, layout },
        site,
        siteSettings,
        domain,
        flagPublish,
        pageId,
        locale,
        globalStyles,
        selectedParentTheme,
    }) => {
        if (flagPublish) return '';
        const data = getBlogPreviewReqData({
            appConfig: getAppConfig(),
            siteMap: site,
            pageId,
            domain,
            blogId,
            layout,
            isServerPreview,
            locale,
            websiteCategory: siteSettings?.generalData?.websiteCategories,
            selectedParentTheme,
            themeColors: getThemeColorData(globalStyles),
        });
        return `document.addEventListener('DOMContentLoaded', async function () {
            await blogUtilsInPreview.updateBlog(${JSON.stringify(data)});
        });`;
    },
    //only if it is not for publish
    //Never add async or defer to these scripts as these are required to be loaded and executed before DOMContentLoaded triggered
    generateScripts: ({ flagPublish }) => ([`/renderStatic/blog/${flagPublish ? 'publishUtils' : 'previewUtils'}.js`])
};
