/* eslint-disable max-len */
import { connect } from 'react-redux';
import React from "react";
import cx from "classnames";
import { injectIntl, Intl } from "../../../view/intl/index";
import * as siteSettingsStyles from '../SiteSettingsDialog/view/SiteSettingsDialog.css';
import * as styles from './GeneralInfoTab.css';
import Scrollbar from '../../../view/common/Scrollbar/index';
import InputField from '../../../view/common/Input/InputField';
import { generalGlobalDataEpic } from './generalGlobalDataEpic/generalGlobalDataEpic';
import type { GeneralGlobalData } from './generalGlobalDataEpic/generalGlobalDataEpic';
import { generalInfoTabEpic } from './GeneralInfoTabEpic';
import type { GeneralInfoTabData } from './types';
import { makeEpicStateSelector } from '../../../epics/makeEpic';
import {
    GENERAL_REMOVE_LOGO_PRESSED,
    GENERAL_INFO_LOGO_CHOOSEN,
    GENERAL_INFO_WEBSITE_TITLE_INPUT_CHANGED,
    GENERAL_INFO_LOGO_ALT_TEXT_INPUT_CHANGED,
    GENERAL_INFO_TAB_MOUNT,
    GENERAL_INFO_WEBSITE_TITLE_CHANGED_ON_BLUR,
    GENERAL_INFO_LOGO_ALT_TEXT_CHANGED_ON_BLUR,
    GENERAL_INFO_FAVICON_CHOOSEN,
    GENERAL_REMOVE_FAVICON_PRESSED,
    GENERAL_INFO_CONTACT_EMAIL_INPUT_CHANGED,
    GENERAL_INFO_CONTACT_EMAIL_CHANGED_ON_BLUR,
    GENERAL_INFO_PHONE_NUMBER_INPUT_CHANGED,
    GENERAL_INFO_PHONE_NUMBER_CHANGED_ON_BLUR,
    GENERAL_INFO_ADDRESS_NAME_INPUT_CHANGED,
    GENERAL_INFO_ADDRESS_NAME_INPUT_CHANGED_ON_BLUR
} from './actionTypes';
import { ImageChooserBtnPP } from '../../FileManager/imageChooser/ImageChooserBtnPP';
import { generateFieldNameInputId, generateFieldNameDivId } from './generalInfoServiceEpic';
import { QuestionTip } from "../../presentational/Tooltip/questionTip/QuestionTip";
import { TooltipPosition } from "../../presentational/Tooltip/constants";
import { LogoImagePreview } from '../../Onboarding/view/LogoImagePreview';
import { MVE_WEBSITE_TITLE_MAX_LENGTH } from '../../MobileViewEditor/header/propertiesPanel/pages/main/constants';
import LazyImage from "../../../view/common/LazyImage/index";
import { FcContentTypes } from "../../../redux/modules/children/fileChooser/FcContentTypes";
import { getImageSrc } from "../../oneweb/Image/utils";
import HorizontalSpacer from '../../../view/common/HorizontalSpacer';
import VerticalSpacer from '../../../view/common/VerticalSpacer';
import LongTextTip from "../../../view/common/LongTextTip/index";
import getStyleIntValue from "../../../utils/getStyleIntValue";
import { ValidatedInput } from '../../../view/common/Input/ValidatedInputField';
import { isValidEmail } from '../../../utils/validation';
import { telInputValidation } from '../../inputControls/input/commonValidations/telInputValidation';
import { simpleScrollIntoViewWithOffsetTop } from '../../../utils/scrollIntoView';
import { EMAIL_KIND } from '../../oneweb/TextLike/Email/kind';
import { PHONE_KIND } from '../../oneweb/TextLike/Phone/kind';
import { ADDRESS_KIND } from '../../oneweb/TextLike/Address/kind';
import { KindToInputId } from "../../oneweb/TextLike/kindToInputId";
import { FileUploader } from "../../FileUploader/index";
import { uploadFilesAction } from "../../FileUploader/actionCreators";
import { FileManagerMode } from "../../FileManager/constants";
import { SVGContentType } from "../../oneweb/constants";
import missingAssetsEpicVAT from "../../MissingAssets/epic/valueActionType";
import Image from "../../oneweb/Image/view/Image";
import OpeningHours from "./OpeningHours/index";
import imgStyles from "../../oneweb/Image/view/Image.css";
import renderAddress from "./renderAddress";

type Props = {
    dispatch: Dispatch;
    intl: Intl;
    missingAssetUrls: Array<string>;
    generalGlobalData: GeneralGlobalData;
    generalInfoTabData: GeneralInfoTabData;
    activateInputWithId: number | string | undefined;
    activateDivWithId: string | undefined;
}

export const FAVICON_DIV_ID = 'favicon';

const YOUR_TITLE_WIDTH = getStyleIntValue(styles, 'yourTitleWidth');
const inputHighlightAnimation = '2s ease-in-out 0.2s 1 normal none running input-highlight';

const logoContainerWidth = 272,
    logoContainerHeight = 118,
    missingLogoContainerHeight = logoContainerHeight + 20,

    missingFaviconWidth = 20,
    missingFaviconHeight = 38,

    inputScrollOffset = 42,
    divScrollOffset = 18;

const renderField = (intl, name, spacingAboveDesc, desc, spacingBelowDesc, learnMoreLink, contents) => {
    return (
        <div style={{ display: 'flex' }}>
            <div className={siteSettingsStyles.fieldLabel}>
                <h3 className={siteSettingsStyles.fieldName}>{intl.msgJoint(name)}</h3>
                <VerticalSpacer y={spacingAboveDesc} />
                <p className={siteSettingsStyles.fieldDescription}>{intl.msgJoint(desc)}</p>
                {learnMoreLink && <VerticalSpacer y={spacingBelowDesc} />}
                {
                    learnMoreLink &&
                    <a href={intl.msgJoint(learnMoreLink)} className={siteSettingsStyles.learnMoreLink} target="_blank">{intl.msgJoint("msg: common.learnMore {Learn more}")}</a>
                }
            </div>
            <div className={siteSettingsStyles.fieldContent}>{contents}</div>
        </div>
    );
};

class GeneralInfoTabComponent extends React.Component<Props> {
    componentDidMount() {
        this.props.dispatch({ type: GENERAL_INFO_TAB_MOUNT });
        const { activateInputWithId, activateDivWithId } = this.props;
        if (activateInputWithId) {
            const focus = () => {
                const input = document.getElementById(generateFieldNameInputId(activateInputWithId));
                if (input) {
                    simpleScrollIntoViewWithOffsetTop(input, inputScrollOffset);
                    input.focus();
                }
            };

            focus(); // instant scroll to input
            setTimeout(focus); // actual focus
        } else if (activateDivWithId) {
            const scrollIntoView = () => {
                const div = document.getElementById(generateFieldNameDivId(activateDivWithId));
                if (div) {
                    simpleScrollIntoViewWithOffsetTop(div, divScrollOffset);
                }
            };

            scrollIntoView();
            setTimeout(scrollIntoView);
        }
    }

    render() {
        const
            { generalGlobalData, generalInfoTabData, missingAssetUrls, intl, dispatch, activateInputWithId } = this.props,
            { websiteTitle, logoAsset, favicon, logoAltText } = generalGlobalData,
            { contactEmail, phoneNumber } = generalInfoTabData,
            faviconPlaceholderSize = 20,
            { src: faviconSrc, srcSet: faviconSrcSet } = getImageSrc(favicon, faviconPlaceholderSize, faviconPlaceholderSize, true),
            isContactEmailValid = !contactEmail || isValidEmail(contactEmail),
            phoneValidationResult = phoneNumber ? telInputValidation(phoneNumber) : { isValid: true },
            // @ts-ignore
            phoneValidationErrorMsg = !phoneValidationResult.isValid ? phoneValidationResult.error.msg : '';

        const logoAssetMissing = logoAsset && logoAsset.url ? missingAssetUrls.includes(logoAsset.url) : false,
            faviconAssetMissing = favicon && favicon.url ? missingAssetUrls.includes(favicon.url) : false;

        const renderAddressFields = () => {
            return <div data-test-id="address-form">
                <div className={siteSettingsStyles.inputLabel}>
                    {intl.msgJoint("msg: generalInfo.addressName.label {Company name / Your name (Optional)}")}
                </div>
                <VerticalSpacer y={6} />
                <InputField
                    id={generateFieldNameInputId(KindToInputId[ADDRESS_KIND])}
                    style={{ animation: activateInputWithId === KindToInputId[ADDRESS_KIND] ? inputHighlightAnimation : null }}
                    placeholder="msg: generalInfo.addressName.placeholder {e.g. your company name}"
                    value={generalGlobalData.addressName || ''}
                    onChange={value => {
                        dispatch({ type: GENERAL_INFO_ADDRESS_NAME_INPUT_CHANGED, payload: value, amendToSelf: true });
                    }}
                    changedByUserOnBlur={() => dispatch({ type: GENERAL_INFO_ADDRESS_NAME_INPUT_CHANGED_ON_BLUR })}
                />
                <VerticalSpacer y={15} />
                {renderAddress({ intl, dispatch, generalGlobalData })}
            </div>;
        };

        return (
            <Scrollbar
                height="100%"
                className={styles.scrollbar}
            >
                <div className={siteSettingsStyles.bodyContainer}>
                    <VerticalSpacer y={51} />
                    {renderField(
                        intl,
                        "msg: common.websiteTitle {Website title}",
                        13,
                        "msg: generalInfo.websiteTitle.description {The title is used by templates and the mobile version of your websites.}",
                        5,
                        "",
                        (
                            <div>
                                <div className={siteSettingsStyles.inputLabel}>
                                    {intl.msgJoint("msg: common.websiteTitle {Website title}")}
                                </div>
                                <VerticalSpacer y={6} />
                                <div style={{ position: 'relative' }}>
                                    <InputField
                                        id={generateFieldNameInputId('websiteTitle')}
                                        style={{ animation: this.props.activateInputWithId === 'websiteTitle' ? inputHighlightAnimation : null }}
                                        placeholder="msg: generalInfo.websiteTitle.placeholder {Add your website title}"
                                        value={websiteTitle || ''}
                                        onChange={value => {
                                            dispatch({ type: GENERAL_INFO_WEBSITE_TITLE_INPUT_CHANGED, payload: value, amendToSelf: true });
                                        }}
                                        maxLength={MVE_WEBSITE_TITLE_MAX_LENGTH}
                                        counter={{ maxLength: MVE_WEBSITE_TITLE_MAX_LENGTH }}
                                        changedByUserOnBlur={() => dispatch({ type: GENERAL_INFO_WEBSITE_TITLE_CHANGED_ON_BLUR })}
                                    />
                                </div>
                            </div>
                        )
                    )}
                    <VerticalSpacer y={38.5} />
                    <div className={siteSettingsStyles.hr} />
                    <VerticalSpacer y={31.5} />
                    {renderField(
                        intl,
                        "msg: generalInfo.logo.title {Logo}",
                        15,
                        "msg: generalInfo.logo.description {The logo is used by templates, the logo component and the mobile version of your website.}",
                        5,
                        "msg: generalInfo.logo.learnMoreLink {https://help.one.com/hc/en-us/articles/360003212478}",
                        (
                            <div>
                                <div style={{ display: 'flex' }}>
                                    {!logoAssetMissing &&
                                        <div style={{ position: 'relative' }}>
                                            <LogoImagePreview
                                                logoAsset={logoAsset}
                                                alt={logoAltText}
                                                height={logoContainerHeight}
                                                width={logoContainerWidth}
                                                removeLogoActionType={GENERAL_REMOVE_LOGO_PRESSED}
                                                logoChoosenActionType={GENERAL_INFO_LOGO_CHOOSEN}
                                                dispatch={this.props.dispatch}
                                                extraImageContentTypes={FcContentTypes.SVG}
                                            />
                                        </div>}
                                    {logoAssetMissing &&
                                    <Image
                                        isWorkspace
                                        divClassName={cx(imgStyles.imageComponent, imgStyles.imgNotFound)}
                                        imageAvailable={!logoAssetMissing}
                                        width={logoContainerWidth}
                                        height={missingLogoContainerHeight}
                                    />}
                                    <HorizontalSpacer x={30} />
                                    <div>
                                        <VerticalSpacer y={12} />
                                        <ImageChooserBtnPP
                                            asset={logoAsset}
                                            headLabel="msg: common.logoImage {Logo image}"
                                            chooseLabel="msg: addImage {Add image}"
                                            changeAction={GENERAL_INFO_LOGO_CHOOSEN}
                                            removeAction={GENERAL_REMOVE_LOGO_PRESSED}
                                            removeButtonTitle={intl.msgJoint("msg: logo.pp.deleteLogo {Delete logo}")}
                                            dispatch={dispatch}
                                            labelMaxWidth={110}
                                            extraImageContentTypes={FcContentTypes.SVG}
                                            isImageEditorAllowed={!logoAssetMissing && !!logoAsset && logoAsset.contentType !== SVGContentType}
                                            className={cx({ [styles.assetMissing]: logoAssetMissing })}
                                        />
                                        <VerticalSpacer y={8} />
                                        <div style={{ color: '#9e9e9c' }}>
                                            {intl.msgJoint('msg: logo.asset.hint {Get the best result by using a .png with transparent background.}')}
                                        </div>
                                    </div>
                                </div>
                                <VerticalSpacer y={28} />
                                <div className={siteSettingsStyles.inputLabel}>
                                    {intl.msgJoint("msg: generalInfo.logo.altText {Alternative text for logo}")}
                                    <HorizontalSpacer x={5} />
                                    <QuestionTip
                                        position={TooltipPosition.TOP}
                                        distance={3}
                                        theme={{ container: siteSettingsStyles.helpTipContainer }}
                                    >
                                        {intl.msgJoint("msg: generalInfo.logo.altText.hint {This text will be used as \"alt\" text for the image. Add \"alt\" texts to all your images to boost your SEO.}")}
                                    </QuestionTip>
                                </div>
                                <VerticalSpacer y={6} />
                                <InputField
                                    id={generateFieldNameInputId('logoAltText')}
                                    placeholder="msg: generalInfo.logo.altText.placeholder {Add an alternative text for logo}"
                                    value={logoAltText || ''}
                                    onChange={value => dispatch({ type: GENERAL_INFO_LOGO_ALT_TEXT_INPUT_CHANGED, payload: value, amendToSelf: true })}
                                    changedByUserOnBlur={() => dispatch({ type: GENERAL_INFO_LOGO_ALT_TEXT_CHANGED_ON_BLUR })}
                                />
                            </div>
                        )
                    )}
                    <VerticalSpacer y={31.5} />
                    <div className={siteSettingsStyles.hr} />
                    <VerticalSpacer y={31.5} />
                    {renderField(
                        intl,
                        "msg: generalInfo.favicon.title {Favicon}",
                        15,
                        "msg: generalInfo.favicon.description {A favicon is a small icon that represents your website. It's placed next to the title in a browser tab, and is a great way to help visitors locate your site.}",
                        5,
                        "msg: generalInfo.favicon.learnMoreLink {https://help.one.com/hc/en-us/articles/360001410337-How-do-I-add-a-favicon-to-my-Website-Builder-site-}",
                        (
                            <div id={generateFieldNameDivId(FAVICON_DIV_ID)}>
                                <div className={styles.faviconWrapper}>
                                    <div>
                                        <div className={styles.yourTitleBackground}>
                                            <div className={styles.grayDots} />
                                            <div className={styles.tabStart} />
                                            <FileUploader
                                                noOverlay
                                                className={styles.fileUploader}
                                                onDrop={(e, asset) => {
                                                    dispatch(
                                                        uploadFilesAction({
                                                            closeModalOnSave: false,
                                                            isMultiSelect: false,
                                                            forwardToComponent: true,
                                                            headlessMode: true,
                                                            contentTypes: FcContentTypes.SUPPORTED_IMAGE,
                                                            mode: FileManagerMode.IMAGE_CHOOSER,
                                                            onSaveAction: GENERAL_INFO_FAVICON_CHOOSEN,
                                                            files: ([asset[0]] as any),
                                                        })
                                                    );
                                                }}
                                            >
                                                <div className={cx(styles.faviconDropZone, { [styles.iconSelected]: faviconSrc })}> </div>
                                            </FileUploader>
                                            <div className={styles.tabArea}>
                                                <div className={styles.faviconDisplayArea}>
                                                    { !faviconAssetMissing && faviconSrc &&
                                                        <LazyImage
                                                            src={faviconSrc}
                                                            srcSet={faviconSrcSet}
                                                            shouldLoad
                                                            showTransparency
                                                            className={styles.transparentContainerReset}
                                                            transparentClassName={styles.faviconImgDimensions}
                                                        />}
                                                    {faviconAssetMissing &&
                                                        <Image
                                                            isWorkspace
                                                            divClassName={cx(imgStyles.imageComponent, imgStyles.imgNotFound)}
                                                            imageAvailable={!faviconAssetMissing}
                                                            width={missingFaviconWidth}
                                                            height={missingFaviconHeight}
                                                        />}
                                                </div>
                                                <div className={styles.yourTitle}>
                                                    <LongTextTip maxWidth={YOUR_TITLE_WIDTH}>
                                                        {intl.msgJoint("msg: generalInfo.favicon.yourTitle {Your title}")}
                                                    </LongTextTip>
                                                </div>
                                                <div className={styles.closeBtn} />
                                            </div>
                                            <div className={styles.tabEnd} />
                                            <div className={styles.tabPlus} />
                                        </div>
                                    </div>
                                    <div className={styles.faviconChooser}>
                                        <ImageChooserBtnPP
                                            asset={favicon}
                                            headLabel="msg: generalInfo.favicon.title {Favicon}"
                                            chooseLabel="msg: addImage {Add image}"
                                            changeAction={GENERAL_INFO_FAVICON_CHOOSEN}
                                            removeAction={GENERAL_REMOVE_FAVICON_PRESSED}
                                            extraImageContentTypes={[FcContentTypes.ICON]}
                                            removeButtonTitle={intl.msgJoint("msg: logo.pp.deleteLogo {Delete logo}")}
                                            dispatch={dispatch}
                                            labelMaxWidth={110}
                                            className={cx({ [styles.assetMissing]: faviconAssetMissing })}
                                        />
                                        <div className={styles.inputHint}>
                                            {intl.msgJoint("msg: generalInfo.favicon.hint {Use a .png or .ico file. Keep in mind that some browsers, like IE, do not support .png as favicon.}")}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    )}
                    <VerticalSpacer y={29.5} />
                    <div className={siteSettingsStyles.hr} />
                    <VerticalSpacer y={31.5} />
                    <div data-test-id="site-settings-email-section">
                        {renderField(
                            intl,
                            "msg: common.email {Email}",
                            15,
                            "msg: generalInfo.email.description {This is the email address for your business. It\'s automatically inserted in some templates and can be used by the email component and links.}",
                            5,
                            "",
                            (
                                <div>
                                    <div className={siteSettingsStyles.inputLabel}>
                                        {intl.msgJoint("msg: common.emailAddress {Email address}")}
                                    </div>
                                    <VerticalSpacer y={6} />
                                    <ValidatedInput
                                        style={{ animation: this.props.activateInputWithId === KindToInputId[EMAIL_KIND] ? inputHighlightAnimation : null }}
                                        id={generateFieldNameInputId(KindToInputId[EMAIL_KIND])}
                                        placeholder="msg: common.emailExample {e.g. hi@example.com}"
                                        value={contactEmail || ''}
                                        isInvalid={!isContactEmailValid}
                                        invalidInputClass={siteSettingsStyles.validationLabel}
                                        validationMsg="msg: generalInfoTab.emailInvalid {Check that the email is valid.}"
                                        onChange={value => {
                                            dispatch({ type: GENERAL_INFO_CONTACT_EMAIL_INPUT_CHANGED, payload: value, amendToSelf: true });
                                        }}
                                        intl={intl}
                                        changedByUserOnBlur={() => dispatch({ type: GENERAL_INFO_CONTACT_EMAIL_CHANGED_ON_BLUR })}
                                    />
                                </div>
                            )
                        )}
                    </div>
                    <VerticalSpacer y={31.5} />
                    <div className={siteSettingsStyles.hr} />
                    <VerticalSpacer y={31.5} />
                    {renderField(
                        intl,
                        "msg: common.phone {Phone}",
                        15,
                        "msg: generalInfo.phone.description {This is the phone number for your business. It\'s automatically inserted in some templates and can be used by the phone number component and links.}",
                        5,
                        "",
                        (
                            <div>
                                <div className={siteSettingsStyles.inputLabel}>
                                    {intl.msgJoint("msg: common.phoneNumber {Phone number}")}
                                </div>
                                <VerticalSpacer y={6} />
                                <ValidatedInput
                                    style={{ animation: this.props.activateInputWithId === KindToInputId[PHONE_KIND] ? inputHighlightAnimation : null }}
                                    id={generateFieldNameInputId(KindToInputId[PHONE_KIND])}
                                    placeholder="msg: common.phoneExample {e.g. +44 1234 567890}"
                                    value={phoneNumber || ''}
                                    isInvalid={!!phoneValidationErrorMsg}
                                    invalidInputClass={siteSettingsStyles.validationLabel}
                                    validationMsg={phoneValidationErrorMsg}
                                    onChange={value => {
                                        dispatch({ type: GENERAL_INFO_PHONE_NUMBER_INPUT_CHANGED, payload: value, amendToSelf: true });
                                    }}
                                    intl={intl}
                                    changedByUserOnBlur={() => dispatch({ type: GENERAL_INFO_PHONE_NUMBER_CHANGED_ON_BLUR })}
                                />
                            </div>
                        )
                    )}
                    <VerticalSpacer y={31.5} />
                    <div className={siteSettingsStyles.hr} />
                    <VerticalSpacer y={31.5} />
                    {renderField(
                        intl,
                        "msg: common.address {Address}",
                        15,
                        "msg: generalInfo.address.description {This is the physical address of your business. It\'s automatically inserted in some templates and can be used by the address component and links.}",
                        5,
                        "",
                        (
                            <div>
                                { renderAddressFields() }
                            </div>
                        )
                    )}
                    <VerticalSpacer y={31.5} />
                    <div className={siteSettingsStyles.hr} />
                    <VerticalSpacer y={31.5} />
                    {renderField(
                        intl,
                        "msg: common.openingHours {Opening hours}",
                        15,
                        "msg: generalInfo.openingHours.description {These are the hours during which your business is open.}",
                        5,
                        "",
                        <div data-test-id="opening-hours" id={generateFieldNameDivId('opening-hours')}>
                            <VerticalSpacer y={35} />
                            <OpeningHours intl={intl} dispatch={dispatch} generalGlobalData={generalGlobalData} />
                        </div>
                    )}
                    <VerticalSpacer y={29.5} />
                    <div className={siteSettingsStyles.hr} />
                </div>
            </Scrollbar>
        );
    }
}

const generalGlobalDataSelector = makeEpicStateSelector(generalGlobalDataEpic.valueActionType);
const generalInfoTabDataSelector = makeEpicStateSelector(generalInfoTabEpic.valueActionType);
const missingAssetsStateSelector = makeEpicStateSelector(missingAssetsEpicVAT);

const mapStateToProps = (appState) => ({
    generalGlobalData: generalGlobalDataSelector(appState),
    generalInfoTabData: generalInfoTabDataSelector(appState),
    missingAssetUrls: missingAssetsStateSelector(appState).missingAssetUrls
});
export const GeneralInfoTab = injectIntl(connect(mapStateToProps)(GeneralInfoTabComponent));
