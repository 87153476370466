import * as R from 'ramda';
import componentsRegistry from '../../../../view/oneweb/registry/index';
import isStretchComponentKind from '../../../oneweb/isStretchComponentKind';
import { SOCIAL_KIND } from '../../../oneweb/Social/kind';
import TextKind from '../../../oneweb/Text/kind';
import ButtonKind from '../../../oneweb/Button/kind';
import { getTextContrast, getAllStylesheets, getTextColorOnPriority, getTextColors } from '../stylesheets/selectors';
import { getAlpha, setColorAlpha } from "../../../oneweb/Background/utils/index";
import { toHsl } from "../../../../../dal/pageMapAdapter/mappers/Base/color";
import { getColorType/*, getHSLFromHex*/ } from "../../../../mappers/color";
import { SocialColorSourceType } from "../../../oneweb/Social/constants";
import { getAddressPlaceholder, getEmailPlaceholder, getPhonePlaceholder } from '../../../oneweb/TextLike/placeholders';
import type { AnyComponent, ComponentsMap } from "../../../../redux/modules/children/workspace/flowTypes";
import { createAttachments } from "../componentAttachements/util";
import { SELECTED_GLOBAL_STYLE_REGEX } from "../../../App/epics/tinyMceEpic/editorUtils/constants";
import { types as textTypes, globalStyleTextClass } from '../../../Globalstyles/Text/constants';
import type { Stylesheet } from "../stylesheets/flowTypes";
import { socialComponentComputeWidthOnDrop } from "../../../oneweb/Social/index";
import copyObjectAndReplaceWithNewUUIDs from "../../../../utils/copyObjectAndReplaceWithNewUUIDs";
import { SOCIAL_MIN_SIZE, SOCIAL_MIN_SPACING } from "../../../oneweb/constants";
import { updateComponentsRelIns } from "../relations/updateComponentsRelIns";
import { updateComponentsTheme } from "../../../ThemeGlobalData/utils/commonUtils";
import type { ThemeBackgroundType } from "../../../ThemeGlobalData/flowTypes";
import { isBoxKind, isContactComponentKind } from "../../../oneweb/componentKinds";

const { renderAddressToStringSingleline } = require('../../../../../../server/shared/address/utils.js');

const SOCIAL_CMP_ALIGNMENT = {
    CENTER: "center",
    RIGHT: "right",
    LEFT: "left",
};
const SOCIAL_CMP_ALIGN_TRESHOLD = 20;

export const applyGlobalStylesToComponent = (component: AnyComponent, { defaultStyleId, globalstyles }: Record<string, any>) => {
        const
            globalName = R.path(['style', 'globalName'], component),
            matchingGlobalStyle = R.pipe(getAllStylesheets, R.find(({ name }) => name === globalName))(globalstyles),
            styleId = matchingGlobalStyle ? matchingGlobalStyle.id : defaultStyleId;

        return R.evolve({ style: { globalId: R.always(styleId) } }, component);
    },

    adjustCmpIds = (cmpsMap: ComponentsMap, newCmpIds: Array<string>, oldToNewIdsMap: Record<string, any>) => {
        let newCmpsMap = { ...cmpsMap };
        newCmpIds.forEach(id => {
            const { copiedObject } = copyObjectAndReplaceWithNewUUIDs(newCmpsMap[id], oldToNewIdsMap, true, {}, true);
            newCmpsMap[id] = copiedObject;
        });
        return newCmpsMap;
    },

    getTextColorFromCmps = (stylesheets: Stylesheet, componentsMap: ComponentsMap, allColors?: boolean, cmpIds?: Array<string>) => {
        let textStyles: Array<string> = [];
        const newCmpIds = cmpIds || Object.keys(componentsMap);
        newCmpIds.forEach((id) => {
            const cmp = componentsMap[id];
            if (cmp.kind === TextKind) {
                // TODO: Check if this logic to find out paragraphs can be improved. In some cases, paragraph has no class. So, checking for the content.
                if (cmp.content.match(/<p>[^<]/) || cmp.content.includes('paragraph') || cmp.content.includes('text block')) {
                    textStyles = [...textStyles, globalStyleTextClass[textTypes.NORMAL]];
                }

                textStyles = [...textStyles, ...(cmp.content.match(SELECTED_GLOBAL_STYLE_REGEX) ||
                    [globalStyleTextClass[textTypes.NORMAL]])];
            } else if (isContactComponentKind(cmp.kind)) {
                textStyles = [...textStyles, globalStyleTextClass[textTypes.NORMAL]];
            }
        });
        return allColors ? getTextColors(stylesheets, textStyles) :
            getTextColorOnPriority(stylesheets, textStyles);
    },

    getSectionWithUpdatedBgColor = (component: AnyComponent, color: string, opacityValue: number | null = null) => {
        const path = ["style", "background", "colorData", "color"],
            currentColor = R.path(path, component);
        let opacity = currentColor ? getAlpha(currentColor) : 1;
        if (opacityValue !== null) {
            opacity = opacityValue;
        }
        const value = setColorAlpha(toHsl(color), opacity);
        return R.assocPath(path, value, component);
    },
    getSectionWithNoGradientColor = (component: AnyComponent) => {
        const path = ["style", "background", "colorData", "gradient"];
        return R.assocPath(path, null, component);
    },
    getSectionWithNoBgImage = (component: AnyComponent) => {
        const path = ["style", "background", "assetData"];
        return R.assocPath(path, null, component);
    },
    applyStylesBasedOnTextColor = (
        componentsMap: ComponentsMap,
        newCmpIds: Array<string>,
        stylesheets: Stylesheet,
        onlyWhenNotVisible?: boolean,
        defaultColor: [string, number, number, number, number] = ["HSL", 0, 0, 1, 1]
    ) => {
        let newCmpsMap = { ...componentsMap };

        const attachments = createAttachments({ componentsMap }),
            textContrast = getTextContrast(getTextColorFromCmps(stylesheets, componentsMap, false, newCmpIds));

        newCmpIds.forEach((id) => {
            const component = componentsMap[id],
                { kind } = component,
                children = attachments[id];
            if ((isStretchComponentKind(kind) || isBoxKind(kind)) && children && children.length) {
                const
                    path = ["style", "background", "colorData", "color"],
                    currentColor = R.path(path, component) || defaultColor;

                if (!(onlyWhenNotVisible && !(currentColor && getColorType(currentColor) === textContrast))) {
                    const color = textContrast === "dark" ? "#FFFFFF" : "#333333";
                    newCmpsMap[id] = getSectionWithUpdatedBgColor(component, color);
                }
            } else if (kind === SOCIAL_KIND) {
                const path = ["colorType"],
                    value = textContrast === "dark" ? SocialColorSourceType.DARK : SocialColorSourceType.LIGHT;
                newCmpsMap[id] = R.assocPath(path, value, component);
            }
        });
        return newCmpsMap;
    },

    replaceLinkedTagsWithValues = (componentsMap: ComponentsMap, newCmpIds: Array<string>, globalVariables: Record<string, any>) => {
        let newCmpsMap = { ...componentsMap };
        newCmpIds.forEach((id) => {
            const cmp = componentsMap[id];
            if (cmp.kind !== TextKind) {
                return;
            }
            const { changeOldComponentDataBeforeImport } = componentsRegistry[cmp.kind];
            if (!changeOldComponentDataBeforeImport) {
                return;
            }
            const globalData = {
                ...globalVariables,
                address: globalVariables.address || renderAddressToStringSingleline(globalVariables)
                || renderAddressToStringSingleline(getAddressPlaceholder()),
                phoneNumber: globalVariables.phoneNumber || getPhonePlaceholder(),
                contactEmail: globalVariables.contactEmail || getEmailPlaceholder(),
            };

            const { content } = changeOldComponentDataBeforeImport({
                componentsMap: {},
                component: cmp,
                globalVariables: globalData,
                enGlobalVariables: globalData,
                forceEn: false,
            });
            newCmpsMap[id] = { ...cmp, content };
        });
        return newCmpsMap;
    },

    getSocialCmpLeft = (cmp: any, width: number, parentCmpLeft?: number, parentCmpWidth?: number, alignment?: string) => {
        if (!alignment || alignment === SOCIAL_CMP_ALIGNMENT.CENTER) {
            return cmp.left + ((cmp.width - width) / 2);
        }
        if (alignment === SOCIAL_CMP_ALIGNMENT.LEFT) {
            return cmp.left;
        }
        let relInRight = Math.abs(cmp.relIn.right);
        // @ts-ignore
        return parentCmpLeft + parentCmpWidth - width - relInRight;
    },

    getSocialComponentDimensions = (componentsMap: ComponentsMap, cmp: any, socialData: any, templateWidth: number) => {
        let width = socialComponentComputeWidthOnDrop({ component: cmp, socialData }),
            { size, spacing } = cmp,
            left = getSocialCmpLeft(cmp, width),
            { id: relInId, left: relInLeft, right: relInRight } = cmp.relIn || {};

        relInRight = relInRight && Math.abs(relInRight);
        if (!cmp.relIn || !relInRight) {
            return { width, left, size, spacing };
        }

        const parentCmp = componentsMap[relInId],
            parentCmpLeft = isStretchComponentKind(parentCmp.kind) ? 0 : parentCmp.left,
            parentCmpWidth = isStretchComponentKind(parentCmp.kind) ? templateWidth : parentCmp.width,
            calculateNewDimension = (alignment: string) => {
                size = size <= SOCIAL_MIN_SIZE ? SOCIAL_MIN_SIZE : size - 1;
                spacing = spacing <= SOCIAL_MIN_SPACING ? SOCIAL_MIN_SPACING : spacing - 1.5;
                width = socialComponentComputeWidthOnDrop({ component: { ...cmp, size, spacing }, socialData });
                left = getSocialCmpLeft(cmp, width, parentCmpLeft, parentCmpWidth, alignment);
            },
            checkMinSizeSpace = () => {
                return (size > SOCIAL_MIN_SIZE || spacing > SOCIAL_MIN_SPACING);
            };

        if (relInLeft >= (relInRight - SOCIAL_CMP_ALIGN_TRESHOLD) && relInLeft <= (relInRight + SOCIAL_CMP_ALIGN_TRESHOLD)) {
            left = getSocialCmpLeft(cmp, width, parentCmpLeft, parentCmpWidth, SOCIAL_CMP_ALIGNMENT.CENTER);
            while (left < parentCmpLeft && checkMinSizeSpace()) {
                calculateNewDimension(SOCIAL_CMP_ALIGNMENT.CENTER);
            }
        } else if (relInLeft < relInRight) {
            left = getSocialCmpLeft(cmp, width, parentCmpLeft, parentCmpWidth, SOCIAL_CMP_ALIGNMENT.LEFT);
            while ((width > (parentCmpWidth - (2 * relInLeft))) && checkMinSizeSpace()) {
                calculateNewDimension(SOCIAL_CMP_ALIGNMENT.LEFT);
            }
        } else {
            left = getSocialCmpLeft(cmp, width, parentCmpLeft, parentCmpWidth, SOCIAL_CMP_ALIGNMENT.RIGHT);
            while ((left < (parentCmpLeft + relInRight)) && checkMinSizeSpace()) {
                calculateNewDimension(SOCIAL_CMP_ALIGNMENT.RIGHT);
            }
        }
        return { width, left, size, spacing };
    },

    computeSocialComponents = (
        componentsMap: ComponentsMap,
        newCmpIds: Array<string>,
        componentsDependencies: Object,
        templateWidth: number,
        forMHFLayoutItemInLeftPanel: boolean = false
    ) => {
        let newCmpsMap = { ...componentsMap };
        newCmpIds.forEach((id) => {
            const cmp = componentsMap[id];
            if (cmp.kind !== SOCIAL_KIND) {
                return;
            }
            const { socialData } = componentsDependencies[cmp.kind],
                links = forMHFLayoutItemInLeftPanel ? cmp.links : socialData.links.map((link) => ({ ...link, hidden: false })),
                { width, left, size, spacing } =
                    getSocialComponentDimensions(componentsMap, cmp, socialData, templateWidth);
            newCmpsMap[id] = { ...cmp, width, links, left, size, spacing };
        });
        return newCmpsMap;
    },

    applyGlobalStyles = (componentsMap: ComponentsMap, newCmpIds: Array<string>, componentsDependencies: Object) => {
        let newCmpsMap = { ...componentsMap };
        newCmpIds.forEach((id) => {
            const cmp = componentsMap[id];
            if (cmp.kind !== ButtonKind) {
                return;
            }
            newCmpsMap[id] = applyGlobalStylesToComponent(cmp, componentsDependencies[cmp.kind]);
        });
        return newCmpsMap;
    },

    processSectionBlockComponents = ({
        componentsMap,
        newCmpIds,
        stylesheets,
        globalVariables,
        componentsDependencies,
        templateWidth,
        templateSelectedTheme,
        isParentFullContainer = false,
        forMHFLayoutItemInLeftPanel = false
    }: {
        componentsMap: ComponentsMap;
        newCmpIds: Array<string> | null;
        stylesheets: Stylesheet;
        globalVariables: Record<string, any>;
        componentsDependencies: Record<string, any>;
        templateWidth: number;
        templateSelectedTheme: ThemeBackgroundType;
        isParentFullContainer?: boolean;
        forMHFLayoutItemInLeftPanel?: boolean;
    }) => {
        let newCmpsMap = { ...componentsMap };
        const cmpIds = newCmpIds || Object.keys(newCmpsMap);
        if (!isParentFullContainer) {
            newCmpsMap = applyStylesBasedOnTextColor(newCmpsMap, cmpIds, stylesheets);
        }
        // newCmpsMap = applyStylesBasedOnTextColor(newCmpsMap, cmpIds, stylesheets, true, getHSLFromHex("#e3edf6").toJSON());
        newCmpsMap = applyGlobalStyles(newCmpsMap, cmpIds, componentsDependencies);
        newCmpsMap = replaceLinkedTagsWithValues(newCmpsMap, cmpIds, globalVariables);
        newCmpsMap = computeSocialComponents(newCmpsMap, cmpIds, componentsDependencies, templateWidth, forMHFLayoutItemInLeftPanel);
        newCmpsMap = updateComponentsRelIns(newCmpsMap, templateWidth);
        newCmpsMap = updateComponentsTheme({ componentsMap: newCmpsMap, cmpIds, templateSelectedTheme, componentsDependencies });
        return newCmpsMap;
    };
