const { ConfigComponentList } = require("../constants.js");

class DashboardConfig {
    isDashboardEnabled() {
        const dashboard = this._featuresConfig[ConfigComponentList.DASHBOARD];
        return dashboard && dashboard.enabled;
    }
}

module.exports = DashboardConfig;
