/* @flow */

const npath = require('path');
const S3 = require('aws-sdk/clients/s3');

/*::
    import type { AwsServiceOptions, AWSRequest } from './types'

    type AwsS3Options = { ...AwsServiceOptions }

    type CopyObjectParams = {|
        Bucket?: string,
        CopySource: string,
        Key: string,
    |}
 */

/**
 * See AWS.S3 API: https://docs.aws.amazon.com/AWSJavaScriptSDK/latest/AWS/S3.html.
 *
 * TODO: refactor similar to AwsSes improving inheritance and testing
 */
function AwsS3(options/*: AwsS3Options */) {
    /*=== Constructor ===*/

    // Need this nasty inheritance since AWS.S3 is not a constructor function
    function AwsS3Class() {
        // This is intentional
    }
    AwsS3Class.prototype = new S3(options);

    const self = new AwsS3Class();

    /*=== Public ===*/

    // TODO: handler errors ?
    // TODO: parallel ?
    AwsS3Class.prototype.emptyDirectory = async (dir/*: string */)/*: Promise<boolean> */ => {
        const objectsRes = await self.listObjectsV2({ Prefix: dir }).promise();
        if (objectsRes.Contents.length === 0) {
            return true;
        }

        const deleteParams = {
            Delete: {
                Objects: objectsRes.Contents.map(({ Key }) => ({ Key })),
            },
        };
        await self.deleteObjects(deleteParams).promise();

        if (objectsRes.isTruncated) {
            return await self.emptyDirectory(dir);
        }

        return true;
    };

    AwsS3Class.prototype.copySameBucketObject = (params/*: CopyObjectParams */)/*: AWSRequest */ => {
        if (options && options.params && options.params.Bucket) {
            return self.copyObject({
                ...params,
                CopySource: npath.join('/', options.params.Bucket, '/', params.CopySource),
            });
        }
        throw new Error('copySameBucketObject() requires options.params.Bucket to be set');
    };

    return self;
}

module.exports = { AwsS3 };
