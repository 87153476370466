import React from "react";
import cx from "classnames";
import styles from "../OnboardingWizard.css";
import { Msg } from "../../../../../view/intl";
import { closeDialog } from "../../../../App/actionCreators";
import { OnboardingOption, OnboardingStepProps } from "../types";
import { PrimaryButton, SecondaryButton } from "../../../../../view/common/Button";
import { BLOG_ONBOARDING_OPTION_UPDATE } from "../epic/actionTypes";

/**
 * The first step of the onboarding, choose between AI generated posts or a blank blog
 */
export const StepStart = ({
    dispatch,
    selectedOption,
    setStartOnboarding
}: OnboardingStepProps) => {
    const HandleOptionClick = (option: OnboardingOption) => {
        dispatch({ type: BLOG_ONBOARDING_OPTION_UPDATE, payload: option });
    };

    const StartOnboarding = () => {
        setStartOnboarding(true);
    };

    return (
        <div className={styles.stepContainer} data-testid="step-start-container">
            <div>
                <div className={styles.title} data-testid="step-start-title">
                    <Msg k="component.blog.onboarding.title">
                        How do you want to start with your posts?
                    </Msg>
                </div>
                <div className={styles.description} data-testid="step-start-description">
                    <Msg k="component.blog.onboarding.description">
                        You can choose to get customised, AI-generated posts about your topic or to
                        write all posts yourself.
                    </Msg>
                </div>
                <div>
                    <div
                        className={styles.radioElement}
                        onClick={() => HandleOptionClick(OnboardingOption.AI)}
                    >
                        <div
                            className={cx(styles.radioBox, {
                                [styles.checked]: selectedOption === OnboardingOption.AI
                            })}
                            data-testid="radio-AI"
                        />
                        <div className={cx(styles.radioImage, styles.ai)} />
                        <div>
                            <div className={styles.radioTitle}>
                                <Msg k="component.blog.onboarding.radio.ai.title">
                                    Get AI-generated posts
                                </Msg>
                            </div>
                            <div className={styles.radioDescription}>
                                <Msg k="component.blog.onboarding.radio.ai.description">
                                    Get customised AI-generated posts with fitting images, tailored
                                    to your topic. You can edit everything later.
                                </Msg>
                            </div>
                        </div>
                    </div>
                    <div
                        className={styles.radioElement}
                        onClick={() => HandleOptionClick(OnboardingOption.blank)}
                    >
                        <div
                            className={cx(styles.radioBox, {
                                [styles.checked]: selectedOption === OnboardingOption.blank
                            })}
                            data-testid="radio-blank"
                        />
                        <div className={cx(styles.radioImage, styles.blank)} />
                        <div>
                            <div className={styles.radioTitle}>
                                <Msg k="component.blog.onboarding.radio.blank.title">
                                    Write posts yourself
                                </Msg>
                            </div>
                            <div className={styles.radioDescription}>
                                <Msg k="component.blog.onboarding.radio.blank.description">
                                    Create your blog from scratch and write all posts yourself.
                                </Msg>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <SecondaryButton
                    onClick={() => dispatch(closeDialog())}
                    className={styles.wizardButton}
                    isLarge
                    data-testid="cancel-button"
                >
                    <Msg k="common.cancel">Cancel</Msg>
                </SecondaryButton>
                <PrimaryButton
                    onClick={() => StartOnboarding()}
                    className={styles.wizardButton}
                    isLarge
                    data-testid="continue-button"
                >
                    <Msg k="common.continue">Continue</Msg>
                </PrimaryButton>
            </div>
        </div>
    );
};
