import React from 'react';
import cx from 'classnames';
import { Flex } from '../../view/reflexbox/index';
import HorizontalSpacer from '../../view/common/HorizontalSpacer';
import GlobalstyleSelector from '../presentational/GlobalstyleSelector/index';
import Icons, { customizedIcon } from '../../view/Icons/index';
import TextIconButton from '../presentational/TextIconButton/index';
import * as actionTypes from '../Workspace/epics/stylesheets/actionTypes';
import { getAllStylesheets } from '../Workspace/epics/stylesheets/selectors';
import type { Stylesheets } from '../Workspace/epics/stylesheets/flowTypes';
import stylesCss from './GlobalstylesDialog.css';

type Props = {
    messages: { [key: string]: string },
    globalStyleSelectorChangeAction: string,
    optionMapper: Function,
    stylesheets: Stylesheets,
    currentStylesheetId: string,
    autoColorMode?: boolean,
    dispatch: Dispatch,
    intl: any
}

const
    duplicateBtnStyle = { height: '14px', width: '14px' },
    deleteBtnStyle = { height: '14px', width: '14px' };

export default ({
    messages,
    globalStyleSelectorChangeAction,
    optionMapper,
    stylesheets,
    currentStylesheetId,
    autoColorMode,
    dispatch,
    intl
}: Props) => {
    const styles = getAllStylesheets(stylesheets);

    return (
        <Flex
            align="center"
            justify={(autoColorMode && "flex-start") || "space-between"}
            className={cx(stylesCss.borderBottom, stylesCss.infoBar)}
        >
            <span>
                {intl.msgJoint(messages.select)}
            </span>
            <Flex align="right" style={{ marginLeft: (autoColorMode && "227px") || "" }} className={stylesCss.btnGroupMenuItems}>
                <GlobalstyleSelector
                    onChangeAction={globalStyleSelectorChangeAction}
                    options={optionMapper(styles, intl)}
                    selected={currentStylesheetId}
                    style={{ height: 36, width: 155 }}
                    dispatch={dispatch}
                    dropDownClassName={stylesCss.infoBarDropDown}
                />
                {
                    !autoColorMode &&
                    <React.Fragment>
                        <HorizontalSpacer x={10} />
                        <TextIconButton
                            iconBeforeText
                            className={stylesCss.topBarBtn}
                            Icon={customizedIcon(Icons.PLUS_LARGE, { style: duplicateBtnStyle })}
                            text={intl.msgJoint(messages.duplicate)}
                            onClick={() => dispatch({ type: actionTypes.DUPLICATE_CURRENT_STYLESHEET })}
                        />
                    </React.Fragment>
                }

                {
                    !autoColorMode &&
                    <React.Fragment>
                        <HorizontalSpacer x={10} />
                        <TextIconButton
                            iconBeforeText
                            className={stylesCss.topBarBtn}
                            Icon={customizedIcon(Icons.CLOSE_LIGHT, { style: deleteBtnStyle })}
                            text={intl.msgJoint(messages.delete)}
                            onClick={() => dispatch({ type: actionTypes.DELETE_CURRENT_STYLESHEET })}
                        />
                    </React.Fragment>
                }
            </Flex>
        </Flex>
    );
};
