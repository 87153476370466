import React from "react";
import { IntlShape } from "react-intl";
import getCenteredDialogConfig from "../../DialogManager/getCenteredDialogConfig";
import { closeDialog } from "../../App/actionCreators/index";
import { DialogV2 } from "../../../view/common/dialogs/DialogV2/index";
import styles from "../view/Backup.css";
import InputField from "../../../view/common/Input/InputField";
import lengthInputValidation from "../../inputControls/input/commonValidations/lengthInputValidation";
import { NAME_SAVEPOINT } from "../actionTypes";
import NamedBackup from "../../../../dal/model/NamedBackup";
import LoadingIndicator from "../../../view/common/LoadingIndicator";

const dialogId = "NamedBackupTitleDialog";
const width = 700;
const height = 300;

interface NamedBackupTitleDialogProps {
    dispatch: (action: any) => void;
    timestamp: string;
    existingTitle?: string;
    intl: Intl & IntlShape;
}

const NamedBackupTitleDialog: React.FC<NamedBackupTitleDialogProps> = ({
    dispatch,
    intl,
    timestamp,
    existingTitle
}) => {
    const [titleValue, setTitleValue] = React.useState(existingTitle || "");
    const [isSaveClicked, setIsSaveClicked] = React.useState(false);

    const savedDate = {
        date: `${intl.formatDate(new Date(timestamp), {
            day: "numeric",
            month: "short",
            year: "numeric",
            weekday: undefined
        }).replace(',', '').toUpperCase()}, ${intl.formatTime(new Date(timestamp), {
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
            hour12: false
        })}`
    };

    const handleSave = () => {
        setIsSaveClicked(true);
        dispatch({
            type: NAME_SAVEPOINT,
            payload: {
                payload: new NamedBackup({ title: titleValue, timestamp }),
                requestType: existingTitle ? "edit" : "add"
            }
        });
    };

    const handleCancel = () => {
        if (isSaveClicked) {
            return;
        }
        dispatch(closeDialog());
    };

    return (
        <div className={styles.namedBackupDialogContainer}>
            <DialogV2
                title="msg: component.backup.description {Backup description}"
                titleClassName={styles.namedBackupDialogTitle}
                subTitle={intl.msgJoint(["msg:component.backup.backup {Backup {date}}", savedDate ])}
                mctaText="msg: common.save {Save}"
                mctaHandler={handleSave}
                sctaHandler={handleCancel}
                sctaText="msg: common.cancel {Cancel}"
                disabled={titleValue === ""}
            >
                <div className={styles.inputBoxContainer}>
                    <InputField
                        className={styles.inputBox}
                        placeholder={`${intl.msgJoint("msg: common.description {Description}")}`}
                        intl={intl}
                        counter={{ maxLength: 100 }}
                        inputValidations={[
                            {
                                validation: lengthInputValidation,
                                options: { max: 100 }
                            }
                        ]}
                        value={titleValue}
                        onChange={setTitleValue}
                        usePropsValue
                        stopOnKeyDownEventPropagation
                        rejectInvalidValue
                        trimValueOnPaste
                    />
                </div>
            </DialogV2>
            {isSaveClicked && (
                <div className={styles.namedBackupLoadingOverlay}>
                    <LoadingIndicator />
                </div>
            )}
        </div>
    );
};

export { NamedBackupTitleDialog };

const dialog = getCenteredDialogConfig(width, height, NamedBackupTitleDialog);
export { dialogId, dialog };
