import { fork } from 'redux-saga/effects'; // eslint-disable-line n/file-extension-in-import

import duplicatePageSaga from "./duplicatePageSaga";
import duplicateSectionLinkSaga from "./duplicateSectionLinkSaga";
import deletePageSaga from "./deletePageSaga";
import savePageInfoSaga from "./savePageInfoSaga";
import updateLinkPageSaga from "./updateLinkPageSaga";
import updateSectionLinkSaga from "./updateSectionLinkSaga";
import hidePageSaga from './hidePageSaga';

export default function* (): ForksSaga {
    yield* [
        fork(duplicatePageSaga),
        fork(duplicateSectionLinkSaga),
        fork(deletePageSaga),
        fork(hidePageSaga),
        fork(savePageInfoSaga),
        fork(updateLinkPageSaga),
        fork(updateSectionLinkSaga),
    ];
}
