import { getDAL } from "../../../../../dal";
import AppConfig from "../../../../utils/AppConfig";
import { getAppConfig } from "../../../App/epics/appConfig/appConfig.js";

const shouldShowBlogWidget = () => {
    const appConfig = AppConfig(getAppConfig());
    const whiteListedDomains = appConfig.getOptional('oneWeb.apps.blog.whitelistedDomains', []);
    let showBlogWidget = true;
    if (whiteListedDomains.length > 0) {
        const domain = getDAL().getDomain();
        showBlogWidget = whiteListedDomains.includes(domain);
    }
    return showBlogWidget;
};

export {
    shouldShowBlogWidget
};
