import React from "react";
import cx from "classnames";
import styles from "./Preview.css";

export default function PoliciesDialogComponent({
    isServerPreview,
    privacyPolicyText,
}) {
    return (
        <div className="policiesDialogVisibilityContainer" data-testid="PrivacyPolicyDialog">
            <div className={cx(styles.policiesDialogContainer)}>
                <div className={styles.policiesDialogModal}>
                    <div className="policiesDialogClose">
                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M1 0.751953L11 10.752M1 10.752L11 0.751953"
                                stroke="#3C3C3C"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                            />
                        </svg>
                    </div>
                    <div className={styles.policiesDialogHeader}>
                        <div className={styles.policiesDialogHeaderText} title="">
                            {privacyPolicyText}
                        </div>
                    </div>
                    <div className={cx(styles.policiesDialogBody, "policiesDialogBody", { [styles.noPtrEvents]: !isServerPreview })}>
                        <p />
                    </div>
                    <div className={styles.policiesDialogFooter}>
                        <div role="button" className="policiesDialogFooterBtn">
                            OK
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
