import type { BorderStyle } from "./types";

export const VERTICAL_PADDING = 4;
export const HORIZONTAL_PADDING = 16;

const getLeft = (components) => {
    let position = components[0].getBoundingClientRect().left;

    if (components.length > 1) {
        for (let i = 1; i < components.length; i++) {
            position = Math.min(position, components[i].getBoundingClientRect().left);
        }
    }

    return position;
};

const getTop = (components) => {
    let position = components[0].getBoundingClientRect().top;

    if (components.length > 1) {
        for (let i = 1; i < components.length; i++) {
            position = Math.min(position, components[i].getBoundingClientRect().top);
        }
    }

    return position;
};

const getWidth = (components, left) => {
    const rect = components[0].getBoundingClientRect();
    let width = rect.left + rect.width - left;

    if (components.length > 1) {
        for (let i = 1; i < components.length; i++) {
            const componentRect = components[i].getBoundingClientRect();
            width = Math.max(width, componentRect.left + componentRect.width - left);
        }
    }

    return width;
};

const getHeight = (components, top) => {
    const rect = components[0].getBoundingClientRect();
    let height = rect.top + rect.height - top;

    if (components.length > 1) {
        for (let i = 1; i < components.length; i++) {
            const componentRect = components[i].getBoundingClientRect();
            height = Math.max(height, componentRect.top + componentRect.height - top);
        }
    }

    return height;
};

export const getBorder = (components) => {
    const
        left = getLeft(components),
        top = getTop(components),
        width = getWidth(components, left),
        height = getHeight(components, top);

    return {
        left: left - HORIZONTAL_PADDING,
        top: top - VERTICAL_PADDING,
        width: width + (2 * HORIZONTAL_PADDING),
        height: height + (2 * VERTICAL_PADDING)
    };
};

export const filterTextComponents = (components) => {
    const textComponents = Array.from(components).filter(component => {
        // @ts-ignore
        return component.attributes['data-specific-kind'].value === 'TEXT';
    });

    return textComponents;
};

export const filterOtherComponents = (components) => {
    const otherComponents = Array.from(components).filter(component => {
        // @ts-ignore
        return component.attributes['data-specific-kind'].value !== 'TEXT' && !component.closest('div[data-specific-kind="TEXT"]');
    });

    return otherComponents;
};

interface RectPositions {
    left: number;
    top: number;
    right: number;
    bottom: number;
}

const checkForIntersection = (rect1: RectPositions, rect2: RectPositions) => {
    return !(rect1.right <= rect2.left || rect1.left >= rect2.right || rect1.bottom <= rect2.top || rect1.top >= rect2.bottom);
};

const componentWithLessDimension = (component, type, dimension) => {
    return component.attributes['data-specific-kind'].value === type &&
        (component.getBoundingClientRect().height <= dimension || component.getBoundingClientRect().width <= dimension);
};

const filterOutComponentsWithLessDimension = (component) => {
    const componentTypeMap = [
        { type: "BACKGROUND", dimension: 50 },
        { type: "SVG", dimension: 50 },
        { type: "IMAGE", dimension: 50 }
    ];

    return componentTypeMap.reduce((acc, el) => {
        return !componentWithLessDimension(component, el.type, el.dimension) && acc;
    }, true);
};

export const filterOutChildComponents = (components) => {
    const filteredOutComponents = Array.from(components).filter(component => {
        return (
            // @ts-ignore
            component.attributes['data-specific-kind'].value === 'TEXT' ||
            (
                filterOutComponentsWithLessDimension(component) &&
                // @ts-ignore
                !component.closest('div[data-specific-kind="TEXT"]') &&
                // @ts-ignore
                !component.attributes.class.value.startsWith("Preview_mobileHide")
            )
        );
    });

    return filteredOutComponents;
};

export const isTextComponent = (component) => {
    return component.attributes['data-specific-kind'].value === 'TEXT';
};

const isBackgroundTextWithLessContent = (component) => {
    return component.closest('div[data-kind="BACKGROUND"]') && component.textContent.length < 2;
};

const isValidTextComponent = (component) => {
    const nonValidParentKindList = ['HOVERBOX'];
    const query = nonValidParentKindList.map(el => `div[data-kind="${el}"]`).join(' ');

    return (isTextComponent(component) && !component.closest(query));
};

export const isStaticTextComponent = (component, staticTextComponents) => {
    return staticTextComponents.includes(component.attributes['data-id'].value);
};

export const getValidTextComponents = (components, staticTextComponents) => {
    return components.filter(
        component =>
            isValidTextComponent(component) &&
            !isStaticTextComponent(component, staticTextComponents) &&
            !isBackgroundTextWithLessContent(component)
    );
};

export const groupTextComponents = (components) => {
    const groupedTextComponents: Array<any> = [];
    const groupedParentList: Array<any> = [];

    for (const component of components) {
        const parent = component.closest('div[data-kind="Block"]');

        const index = groupedParentList.indexOf(parent);
        if (index === -1) {
            groupedParentList.push(parent);
            groupedTextComponents.push([component]);
        } else {
            groupedTextComponents[index].push(component);
        }
    }

    return groupedTextComponents;
};

const isBorderBoundingWithComponent = (component, borderStyles) => {
    const
        left = borderStyles.left + HORIZONTAL_PADDING,
        top = borderStyles.top + VERTICAL_PADDING,
        right = left + borderStyles.width - (2 * HORIZONTAL_PADDING),
        bottom = top + borderStyles.height - (2 * VERTICAL_PADDING);

    const borderRect = { left, top, right, bottom };

    const cRect = component.getBoundingClientRect();
    const isIntercect = checkForIntersection(borderRect, cRect);

    return isIntercect;
};

export const isBoundingCrossedWithOtherComponents = (components, otherBlocks, borderStyles) => {
    return (
        (
            components.length &&
            components.some(component => {
                return isBorderBoundingWithComponent(component, borderStyles);
            })
        ) ||
        (
            otherBlocks.length &&
            otherBlocks.some(component => {
                return isBorderBoundingWithComponent(component, borderStyles);
            })
        )
    );
};

export const generateComponentsPartition = (components, otherComponents, otherBlocks) => {
    let partitions: Array<any> = [];
    let subPartitions: Array<any> = [];

    if (components.length <= 1) {
        return [components];
    }

    subPartitions = [components[0]];
    for (let i = 1; i < components.length; i++) {
        let component = components[i];
        let newPartition = [...subPartitions, component];
        let styles: BorderStyle = getBorder(newPartition);
        if (isBoundingCrossedWithOtherComponents(otherComponents, otherBlocks, styles)) {
            if (subPartitions.length) {
                partitions.push(subPartitions);
                subPartitions = [];
            }
        }
        subPartitions.push(component);
    }

    if (subPartitions.length) {
        partitions.push(subPartitions);
    }

    return partitions;
};

const generateBorderStyles = (components, otherComponents, otherBlocks) => {
    let borderStyles: Array<BorderStyle> = [];
    let componentsPartitions = generateComponentsPartition(components, otherComponents, otherBlocks);
    if (componentsPartitions.length) {
        borderStyles = componentsPartitions.map(components => getBorder(components));
        return borderStyles;
    }

    if (!borderStyles.length) {
        borderStyles = components.map(component => getBorder([component]));
    }

    return borderStyles;
};

const getIntersctionWidth = (borderStylei, borderStylej) => {
    if (borderStylei.left <= borderStylej.left + borderStylej.width) {
        return Math.abs(borderStylej.left + borderStylej.width - borderStylei.left);
    } else {
        return Math.abs(borderStylei.left + borderStylei.width - borderStylej.left);
    }
};

const getIntersctionHeight = (borderStylei, borderStylej) => {
    if (borderStylei.top <= borderStylej.top + borderStylej.height) {
        return Math.abs(borderStylej.top + borderStylej.height - borderStylei.top);
    } else {
        return Math.abs(borderStylei.top + borderStylei.height - borderStylej.top);
    }
};

const mergeBorderStyles = (borderStylei, borderStylej) => {
    return {
        left: Math.min(borderStylei.left, borderStylej.left),
        top: Math.min(borderStylei.top, borderStylej.top),
        width: borderStylei.width + borderStylej.width - getIntersctionWidth(borderStylei, borderStylej),
        height: borderStylei.height + borderStylej.height - getIntersctionHeight(borderStylei, borderStylej)
    };
};

const getMergedBorderStyles = (borderStyles) => {
    const intersects = (style1, style2) => {
        let rect1 = { left: style1.left, top: style1.top, right: style1.left + style1.width, bottom: style1.top + style1.height };
        let rect2 = { left: style2.left, top: style2.top, right: style2.left + style2.width, bottom: style2.top + style2.height };
        return checkForIntersection(rect1, rect2);
    };
    const mergeBorders = (styles) => {
        for (let i = 0; i < styles.length - 1; i++) {
            for (let j = i + 1; j < styles.length; j++) {
                if (intersects(styles[i], styles[j])) {
                    const mergedStyle = mergeBorderStyles(styles[i], styles[j]);
                    const newStyles = [
                        ...styles.slice(0, i),
                        mergedStyle,
                        ...styles.slice(i + 1, j),
                        ...styles.slice(j + 1)
                    ];
                    return mergeBorders(newStyles);
                }
            }
        }
        return styles;
    };

    return mergeBorders(borderStyles);
};

export const getBorderStyles = (groupedTextComponents, otherComponents, allChildBlocks) => {
    let borderStyles: Array<BorderStyle> = [];
    for (const textComponents of groupedTextComponents) {
        const parentBlock = textComponents[0].closest('div[data-kind="Block"]');
        const otherBlocks = Array.from(allChildBlocks)
            // @ts-ignore
            .filter(block => block !== parentBlock && !block.contains(parentBlock));

        if (textComponents.length) {
            let styles: BorderStyle = getBorder(textComponents);
            if (
                (isBoundingCrossedWithOtherComponents(otherComponents, otherBlocks, styles))
            ) {
                borderStyles.push(...generateBorderStyles(textComponents, otherComponents, otherBlocks));
            } else {
                borderStyles.push(styles);
            }
        }
    }

    return getMergedBorderStyles(borderStyles);
};

export const isCordsEnclosedInBorder = (coords, borderStyle) => {
    const
        left = borderStyle.left,
        top = borderStyle.top,
        right = left + borderStyle.width,
        bottom = top + borderStyle.height;

    const _borderStyle = { left, top, right, bottom };

    const cordsRect = {
        left: coords.x,
        top: coords.y,
        right: coords.x,
        bottom: coords.y
    };

    return checkForIntersection(_borderStyle, cordsRect);
};
