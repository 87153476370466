import { ImageFormat } from 'photoeditorsdk/js/PhotoEditorSDK'; // eslint-disable-line n/file-extension-in-import
import { getAssetUrl } from "../../../../utils/assetUtils";

interface DataInterface {
    image: {
        data: any,
        asset: any,
        height: number;
        width: number;
    }
}
interface AssetInterface {
    data: DataInterface;
    contentType: string;
    url: string;
}
class ImageAsset {
    asset: AssetInterface;

    constructor(asset: AssetInterface) {
        this.asset = asset;
    }

    hasSerializedData() {
        return !!(this.asset && this.asset.data);
    }

    getContentType() {
        const { contentType } = this.asset;
        return (
            contentType === ImageFormat.PNG
            || contentType === ImageFormat.JPEG
                ? contentType : ImageFormat.PNG
        );
    }

    getRawAssetUrl() {
        if (this.hasSerializedData()) {
            return (
                this.asset.data
                && this.asset.data.image
                && this.asset.data.image.data
            );
        }
        return this.asset.url;
    }

    getAssetUrl() {
        // @ts-ignore
        return getAssetUrl({
            ...this.asset
        });
    }

    getSerializedAsset() {
        const
            { data } = this.asset,
            serializedAsset = data && data.image && data.image.asset;
        let tmpAsset;
        if (serializedAsset) {
            tmpAsset = {
                ...serializedAsset
            };
        } else {
            tmpAsset = {
                ...this.asset
            };
        }
        delete tmpAsset.url; // prevent missing asset dialog if original image from edited image is missing
        return tmpAsset;
    }

    getSerializedAssetUrl() {
        const
            { data } = this.asset,
            url = data && data.image && data.image.data;
        if (url) {
            return getAssetUrl({
                ...this.getSerializedAsset(),
                url
            });
        }
        return '';
    }

    getSerializeData() {
        if (!this.hasSerializedData()) return null;

        const serializeData = {
            ...this.asset.data
        };

        return {
            ...serializeData,
            image: {
                height: serializeData.image.height,
                width: serializeData.image.width
            }
        };
    }

    mergeAssetWithSerializedData(serializedData: Object) {
        const copyData = JSON.parse(JSON.stringify(serializedData));
        return {
            ...this.asset,
            data: {
                ...copyData,
                image: {
                    ...copyData.image,
                    data: this.getRawAssetUrl(),
                    asset: this.getSerializedAsset()
                }
            }
        };
    }
}

export default ImageAsset;
