const { ConfigComponentList } = require("../constants.js");

class SocialWidgetsConfig {
    isInstagramComponentEnabled() {
        const socialWidgets = this._featuresConfig[ConfigComponentList.SOCIAL_WIDGETS];
        return socialWidgets && socialWidgets.instagramComponent && socialWidgets.instagramComponent.enabled
    }

    isInstagramComponentForceDisabled() {
        const socialWidgets = this._featuresConfig[ConfigComponentList.SOCIAL_WIDGETS];
        return socialWidgets && socialWidgets.instagramComponent && socialWidgets.instagramComponent.forceDisabled;
    }

    isFacebookComponentEnabled() {
        const socialWidgets = this._featuresConfig[ConfigComponentList.SOCIAL_WIDGETS];
        return socialWidgets && socialWidgets.facebookComponent && socialWidgets.facebookComponent.enabled;
    }

    isGoogleReviewsComponentEnabled() {
        const socialWidgets = this._featuresConfig[ConfigComponentList.SOCIAL_WIDGETS];
        return socialWidgets && socialWidgets.googleReviewsComponent && socialWidgets.googleReviewsComponent.enabled;
    }

    isGoogleReviewsComponentShown() {
        const socialWidgets = this._featuresConfig[ConfigComponentList.SOCIAL_WIDGETS];
        return socialWidgets && socialWidgets.googleReviewsComponent && socialWidgets.googleReviewsComponent.show;
    }

    isSocialWidgetsShown() {
        const socialWidgets = this._featuresConfig[ConfigComponentList.SOCIAL_WIDGETS];
        return socialWidgets && socialWidgets.show;
    }
}

module.exports = SocialWidgetsConfig;
