import React from "react";
import { onlyUpdateForKeys } from 'recompose';
import componentsRegistry from "../../oneweb/registry/index";
import { checkSubscriptionCompatibilityFromKind } from "../../../components/ComponentTierManager/utils";
import { isShopRelatedComponent } from "../../../components/ComponentTierManager/utils";
import { ComponentTierTypes } from "../../../components/ComponentTierManager/componentTierTypes";

const
    renderOnlyIfPropsChanged = onlyUpdateForKeys([
        "component",
        "componentDependencies",
        "componentId",
        "componentExtension",
        "renderProps",
        "inEditMode",
        "inSelectedMode",
        "isHiddenComponent",
    ]),
    ComponentRender = renderOnlyIfPropsChanged(({
        componentId,
        component,
        componentDependencies,
        componentExtension,
        renderProps,
        inEditMode,
        inSelectedMode,
        dispatch,
        subscriptionType,
        isHiddenComponent,
        children = null,
        mhfCmpStyle,
    }) => {
        const
            record = componentsRegistry[component.kind],
            getComponentTierData = () => {
                if (isShopRelatedComponent(component.kind) && componentDependencies?.webshopSubscription?.onlineShopSetupApiResponse?.isSIATCustomer) {
                    return {
                        componentTierType: ComponentTierTypes.BASIC_TIER_COMPONENT,
                        isCompatible: true,
                    };
                } else {
                    return {
                        ...record.componentTierData,
                        isCompatible: checkSubscriptionCompatibilityFromKind(component.kind, subscriptionType),
                    };
                }
            },

            finalProps = record.calcRenderProps
                ? renderProps
                : { ...componentDependencies, component, dispatch, componentId, componentExtension, inEditMode },
            componentTierData = getComponentTierData();

        return (
            <record.workspace
                {...finalProps}
                isHiddenComponent={isHiddenComponent}
                inEditMode={inEditMode}
                dispatch={dispatch}
                componentTierData={componentTierData}
                inSelectedMode={inSelectedMode}
                mhfCmpStyle={mhfCmpStyle}
            >
                {children}
            </record.workspace>
        );
    });

export default ComponentRender;
