import React, { Component } from 'react';
import { connect } from "react-redux";
import styles from "./VerifyEmailToaster.css";
import type { VerifyEmailToasterPropTypes } from "../types";
import { DemoLoginMessages } from "../login/main/messages";
import type { AppState } from "../../../src/redux/modules/flowTypes";
import { isDemoSubscriptionTypeAppSel, trialUserEmailStatusSel, trialUserEmailSel } from "../selectors";
import { injectIntl } from "../../../src/view/intl/index";
import { addHtmlToasterMessage, deleteMessage } from "../../../src/components/Toaster/actionCreators";
import { demoResendConfirmationCodeAction } from "../login/signIn/actions";
import MessageTypes from "../../../src/components/Toaster/MessageTypes";
import {
    showWelcomeMessageOnTemplateImportKey
} from "../../../src/components/WelcomeMessage/epic/index";
import { normalizeEmail } from "../../../../server/shared/utils/normalizeEmail.js";

class VerifyEmailToasterClass extends Component <VerifyEmailToasterPropTypes> {
    verifyEmailToasterHtml({
        intl,
        email,
        dispatch,
        verifyEmailToasterMessage,
        userEmailVerificationRequiredResendLabel,
        userVerificationEmailSentMessage
    }) {
        const emailVerificationSuccessfulToasterHtml = this.updateToasterOnResendEmailClick(
            {
                userVerificationEmailSentMessage,
                intl
            }
        );
        return (
            <div className={styles.verifyEmailTextContainer}>
                <div className={styles.verifyEmailMessageContainer}>
                    {intl.msgJoint(verifyEmailToasterMessage)}
                </div>
                <div
                    className={styles.verifyEmailResendEmail}
                    onClick={() => {
                        dispatch(deleteMessage(0));
                        dispatch(demoResendConfirmationCodeAction(normalizeEmail(email)));
                        setTimeout(() => {
                            dispatch(addHtmlToasterMessage(
                                emailVerificationSuccessfulToasterHtml,
                                '',
                                styles.toasterMessage,
                                styles.toasterClose,
                                true,
                                MessageTypes.SUCCESS
                            ));
                        }, 3000);
                    }}
                >
                    {intl.msgJoint(userEmailVerificationRequiredResendLabel)}
                </div>
            </div>
        );
    }

    // eslint-disable-next-line class-methods-use-this
    updateToasterOnResendEmailClick({
        userVerificationEmailSentMessage,
        intl
    }) {
        return (
            <div className={styles.verifyEmailTextContainer}>
                <div className={styles.verifyEmailMessageContainer}>
                    {intl.msgJoint(userVerificationEmailSentMessage)}
                </div>
            </div>
        );
    }

    render() {
        const {
            verifyEmailToasterMessage,
            userEmailVerificationRequiredResendLabel,
            userVerificationEmailSentMessage
        } = DemoLoginMessages;
        const { intl, dispatch, isDemo, trialUserEmailVerified, email } = this.props;
        const verifyEmailToaster = this.verifyEmailToasterHtml(
            {
                intl,
                dispatch,
                email,
                verifyEmailToasterMessage,
                userEmailVerificationRequiredResendLabel,
                userVerificationEmailSentMessage
            }
        );
        const welcomeMessageSessionStorage = window.sessionStorage.getItem(showWelcomeMessageOnTemplateImportKey) === 'yes';

        if (
            isDemo
            && !trialUserEmailVerified
            && !welcomeMessageSessionStorage
        ) {
            setTimeout(() => {
                dispatch(addHtmlToasterMessage(
                    verifyEmailToaster,
                    '',
                    styles.toasterMessage,
                    styles.toasterClose,
                    true,
                    MessageTypes.INFO
                ));
            }, 10000);
            window.sessionStorage.removeItem(showWelcomeMessageOnTemplateImportKey);
        }
        return null;
    }
}

const mapStateToProps = (appState: AppState) => ({
    isDemo: isDemoSubscriptionTypeAppSel(appState),
    trialUserEmailVerified: trialUserEmailStatusSel(appState),
    email: trialUserEmailSel(appState)
});

export const VerifyEmailToaster = connect(mapStateToProps)(injectIntl(VerifyEmailToasterClass));
