import { $Keys } from "utility-types";
/*
 * Let's make abstraction of workspace Handles
 * Whole component is move handle
 * There 8 component resize handles
 * There 4 shift bar handles
 * Selection handle
 * Workspace handle
 * */

import * as R from 'ramda';
import {
    getComponentZIndex, SelectionZIndex, WorkspaceZIndex, WrapPositionControlsZIndex
} from "../../components/Workspace/zIndex";
import { getComponentsBBox } from "../componentsMap/getComponentsBBox";
import getComponentBBox from "../componentsMap/getComponentBBox";
import makeBBox from "../componentsMap/makeBBoxMemoized";
import { resizeHandleCreators, resizeHandleCreatorsForStrip } from "./resize";
import * as shiftBarHandleCreators from "./shiftBar";
import * as HandleKinds from "./kinds";
import makeHandle, { emptyArray, getComponentsIdsMemo } from './makeHandle';
import { memoMax } from '../../../utils/memo';
import { WPC_BUTTON_EDGE_LENGTH } from '../../components/Workspace/WrapPositionControls/epic/index';

import type { ComponentsMap, Handle, BBox } from "../../redux/modules/children/workspace/flowTypes";
import type { HandleCreator } from "./flowTypes";
import type {
    WpcButtonType, WrapPositionControlsState
} from '../../components/Workspace/WrapPositionControls/flowTypes';
import getTemplateLinesHandles from './templateLines';
import { dontShowSelectionHandles } from "../../components/Workspace/epics/componentsEval/editModeComponentId";
import { TextComponentKind } from "../../components/oneweb/Text/kind";
import isStretchComponentKind from "../../components/oneweb/isStretchComponentKind";
import { arrayToTrueMap } from "../arrayToTrueMap";
import { TableComponentKind } from "../../components/oneweb/Table/kind";
import { BOTTOM_DRAG_STRIP_HEIGHT } from "../../view/Workspace/index";
import { GalleryKind } from "../../components/oneweb/Gallery/kind";
import { addIcon, addIconHandleCreatorN, addIconHandleCreatorS } from "./add";
import { isHoverBoxKind, isSectionKind, isStripKind } from "../../components/oneweb/componentKinds";
import { isFooterSection, isHeaderSection } from "../../components/oneweb/Section/utils";
import { getSectionWhoseEdgeIsInBoxArea } from "../../components/Workspace/epics/isPageMode/utils";
import { isStickyToHeader } from "../../components/Workspace/epics/componentsEval/isStickyToHeader";
import { isBoxInModernHeaderOrFooter } from "../../components/ModernLayouts/utils";
import { getCmpsRect } from "../../components/Workspace/epics/componentsEval/utils";
import type { SectionComponent } from "../../components/oneweb/Section/flowTypes";
import { Lit } from '../../lit';
import type { HeaderFooterSectionsPosition } from "../../components/oneweb/Section/epics/headerFooterSectionsPositionEpic";

const selectionHandleCreatorsFactory =
    (selectedCmpKinds, sectionOptions, noShiftBar = false, noBottomShiftBar = false,
        noResizeHandles = false) => (bBox: BBox): HandleCreator[] => {
        const { isHeader, isFooter } = sectionOptions;
        let containsStrip = selectedCmpKinds.some(kind => isStretchComponentKind(kind)),
            onlyBottomShiftBar = isHeader,
            onlyTopShiftBar = noBottomShiftBar ? true : selectedCmpKinds.includes(GalleryKind),
            shiftBarHandles =
            noShiftBar ? [] : shiftBarHandleCreators.selectionCreatorsFactory(bBox, containsStrip, { onlyBottomShiftBar, onlyTopShiftBar });

        if (isHeader) {
            return [...shiftBarHandles, addIconHandleCreatorS];
        }
        if (isFooter) {
            return [...shiftBarHandles, addIconHandleCreatorN];
        }
        let isSection = false,
            isHoverBox = false;
        if (selectedCmpKinds.length === 1) {
            isSection = isSectionKind(selectedCmpKinds[0]);
            isHoverBox = isHoverBoxKind(selectedCmpKinds[0]);
        }

        if (isSection) {
            return [...shiftBarHandles, ...addIcon];
        }
        if (isHoverBox) {
            shiftBarHandles = shiftBarHandles.slice(1);
        }

        if (noResizeHandles) {
            return [
                ...shiftBarHandles
            ];
        }
        return [
            ...shiftBarHandles,
            ...(containsStrip ? resizeHandleCreatorsForStrip : resizeHandleCreators)
        ];
    };

const headerHoverHandlesCreators = [
    ...shiftBarHandleCreators.headerHoverCreator
];

const hoverHandlesCreators = [
    ...shiftBarHandleCreators.hoverCreators
];

const
    _getHandlesForBBox = (handleCreatorsFactory,
        bBox: BBox,
        componentsIds: Array<string>,
        initialZIndex: number,
        isHeader: boolean = false): Array<Handle> => {
        return handleCreatorsFactory(isHeader)
            .map(createHandle => createHandle({ bBox, componentsIds, initialZIndex }));
    },
    getHandlesForBBox = memoMax(_getHandlesForBBox, 1000),
    getHoverHandlesCreators = (isHeader) => (isHeader && headerHoverHandlesCreators) || hoverHandlesCreators;

function getNotSelectedComponentsHandles(componentsMap: ComponentsMap,
    selectedComponentsIds: Array<string>,
    workspaceBBox: BBox,
    disableMoveHandle: boolean) {
    return Object.keys(componentsMap).reduce((handles, componentId) => {
        const
            component = componentsMap[componentId],
            componentBBox = getComponentBBox(componentsMap[componentId], workspaceBBox),
            componentZIndex = getComponentZIndex(component),
            componentsIdsArray = getComponentsIdsMemo(componentId),
            isHeader = isHeaderSection(component),
            hideShiftBarHandles = !!getSectionWhoseEdgeIsInBoxArea(componentBBox, componentsMap) || !!isStickyToHeader(component);

        if (selectedComponentsIds.indexOf(componentId) > -1) {
            return [
                ...handles,
                ...(disableMoveHandle ? [makeHandle(
                    componentsIdsArray,
                    HandleKinds.DisableMoveStub,
                    componentBBox,
                    SelectionZIndex + 1000 // In edit mode text will not have handles inside component area, this dummy handle is on top of that part of handles and blocks it
                )] : [makeHandle(
                    componentsIdsArray,
                    HandleKinds.Component,
                    componentBBox,
                    componentZIndex
                )])
            ];
        }

        return [
            ...handles,
            ...(hideShiftBarHandles ? [] : getHandlesForBBox(
                getHoverHandlesCreators,
                componentBBox,
                componentsIdsArray,
                componentZIndex,
                isHeader
            )),
            makeHandle(
                componentsIdsArray,
                HandleKinds.Component,
                componentBBox,
                componentZIndex
            )
        ];
    }, [] as string[]); // NOSONAR
}

const
    incBottomBy120 = R.evolve({ bottom: R.add(BOTTOM_DRAG_STRIP_HEIGHT) }),
    moveHandleDisableLayerComponentKindsMap = arrayToTrueMap([TextComponentKind, TableComponentKind]);

export function getHandles( // NOSONAR
    // Added "NOSONAR" to avoid SonarQube issue because these function parameters should not be converted into an object (being passed) as it will break the memoization
    selectedComponentsMap: ComponentsMap,
    selectedComponentsIds: Array<string>,
    workspaceBBox: BBox,
    editModeComponentId: string,
    interactionMode: string,
    templateLinesActive: boolean,
    templateWidth: number,
    bodyHeight: number,
    headHeight: number,
    wrapPositionControls: WrapPositionControlsState,
    sections: Array<SectionComponent>,
    noShiftBar: boolean,
    isModernLayoutActivated: boolean,
    headerFooterPositions: HeaderFooterSectionsPosition,
    webshopFooterStripCmpIds: Array<string>,
) {
    const
        workspaceBBoxWidth = workspaceBBox.right - workspaceBBox.left,
        components = selectedComponentsIds.map(id => selectedComponentsMap[id]),
        selectedComponentsBBox = getComponentsBBox(components, workspaceBBox),
        selectedCmpKinds = selectedComponentsIds.map(id => selectedComponentsMap[id].kind),
        isSection = !!selectedComponentsIds.length && isSectionKind(components[0].kind),
        isHeader = isSection && isHeaderSection(components[0]),
        isFooter = isSection && isFooterSection(components[0]),
        isModernHFCmps = !isSection && components.length && isBoxInModernHeaderOrFooter(getCmpsRect(components), {
            ...sections.reduce((acc, c) => ({ ...acc, [c.id]: c }), {}),
            ...selectedComponentsMap,
        }),
        isWebshopStripCmpsExists = selectedComponentsIds.some(id => webshopFooterStripCmpIds.includes(id)),
        isOnlyEmptyGallery = selectedComponentsIds.length === 1 && selectedComponentsIds.some(
            id => selectedComponentsMap[id].kind === GalleryKind
                && selectedComponentsMap[id].images && !selectedComponentsMap[id].images.length
        ),
        noResizeHandles = (selectedComponentsIds.length === 1 && selectedComponentsIds
            .some(id => (selectedComponentsMap[id].kind === GalleryKind && selectedComponentsMap[id].stretch)))
            || selectedComponentsIds.some(id => isStripKind(selectedComponentsMap[id].kind)),
        noBottomShiftBar = isWebshopStripCmpsExists,
        sectionOptions = {
            isSection,
            isHeader,
            isFooter,
        };

    let selectedComponentsBBoxHandles = [];

    if (selectedComponentsIds.length === 0 || dontShowSelectionHandles(interactionMode)
        || isOnlyEmptyGallery || isModernHFCmps ||
            (isWebshopStripCmpsExists && selectedComponentsIds.every(id => !isStripKind(selectedComponentsMap[id].kind)))) {
        selectedComponentsBBoxHandles = [];
    } else {
        selectedComponentsBBoxHandles = getHandlesForBBox(
            selectionHandleCreatorsFactory(selectedCmpKinds, sectionOptions, noShiftBar, noBottomShiftBar,
                noResizeHandles),
            selectedComponentsBBox,
            selectedComponentsIds,
            SelectionZIndex
        );
    }

    const workspaceHandle = makeHandle(
            emptyArray,
            HandleKinds.Workspace,
            incBottomBy120(workspaceBBox), // we can drop component any where below workspace, so let's make it long
            WorkspaceZIndex
        ),
        codeBodyHandle = makeHandle(
            emptyArray,
            HandleKinds.GhostComponentsBodyEndSection,
            makeBBox(
                workspaceBBox.left,
                workspaceBBox.bottom + BOTTOM_DRAG_STRIP_HEIGHT,
                workspaceBBoxWidth,
                bodyHeight
            ),
            WorkspaceZIndex + 1
        ),
        codeHeadHandle = makeHandle(
            emptyArray,
            HandleKinds.GhostComponentsHeadSection,
            makeBBox(
                workspaceBBox.left,
                -headHeight,
                workspaceBBoxWidth,
                headHeight
            ),
            WorkspaceZIndex + 1
        ),
        componentInEditModeKind = R.path([editModeComponentId, 'kind'], selectedComponentsMap),
        disableMoveHandle = moveHandleDisableLayerComponentKindsMap[componentInEditModeKind],
        wpcButtonTypeList: WpcButtonType[] = ['above', 'below', 'left', 'right'],
        wpcButtonTypeToHandleKindsMap: { [key in WpcButtonType]: $Keys<typeof HandleKinds.ComponentHandleKinds> } = {
            above: HandleKinds.WpcAbove,
            below: HandleKinds.WpcBelow,
            left: HandleKinds.WpcLeft,
            right: HandleKinds.WpcRight,
        },
        getBBoxForWpc = (type: WpcButtonType) => {
            const { x, y } = wrapPositionControls.wpcButtonPositions[type];
            return wrapPositionControls.isVisible ?
                makeBBox(x, y, WPC_BUTTON_EDGE_LENGTH, WPC_BUTTON_EDGE_LENGTH) :
                makeBBox(-1000, -1000, 0, 0);
        },
        makeWpcHandle = (type: WpcButtonType) => (
            makeHandle(emptyArray, wpcButtonTypeToHandleKindsMap[type], getBBoxForWpc(type), WrapPositionControlsZIndex)
        ),
        result = [
            ...selectedComponentsBBoxHandles,
            ...getNotSelectedComponentsHandles(selectedComponentsMap, selectedComponentsIds, workspaceBBox, disableMoveHandle),
            ...wpcButtonTypeList.map(makeWpcHandle),
            workspaceHandle,
            codeHeadHandle,
            codeBodyHandle
        ];

    if (templateLinesActive) {
        let templateLinesBbox = { ...workspaceBBox };
        if (isModernLayoutActivated) {
            templateLinesBbox = {
                ...templateLinesBbox,
                top: workspaceBBox.top + headerFooterPositions[Lit.headerSection].height,
                bottom: workspaceBBox.bottom - headerFooterPositions[Lit.footerSection].height,
            };
        }
        result.push(...getTemplateLinesHandles(templateLinesBbox, templateWidth));
    }
    return result;
}

export function isSelectionShiftBarHandle(handleKind: string) {
    return [HandleKinds.ShiftBarTopSelection, HandleKinds.ShiftBarBottomSelection].indexOf(handleKind) > -1;
}

export function isHoverShiftBarHandle(handleKind: string) {
    return [HandleKinds.ShiftBarBottomHover, HandleKinds.ShiftBarTopHover].indexOf(handleKind) > -1;
}

export function isComponentHandle(handleKind: string) {
    return handleKind === HandleKinds.Component;
}

export function isWorkspaceHandle(handleKind: string) {
    return handleKind === HandleKinds.Workspace;
}

export function isWorkspaceRelatedHandle(
    handleKind: string, componentKind: string, selectedComponentsIds: Array<string>, componentId: string,
    checkSelectedSection: boolean = false
) {
    return (isWorkspaceHandle(handleKind) ||
        (
            isStretchComponentKind(componentKind) &&
            isComponentHandle(handleKind) &&
            (selectedComponentsIds.indexOf(componentId) === -1 ||
                (checkSelectedSection && isSectionKind(componentKind)))
        )
    );
}

export function isHoverShiftBarOrComponentHandle(handleKind: string) {
    return isHoverShiftBarHandle(handleKind) || isComponentHandle(handleKind);
}

export function isShiftBarHandle(handleKind: string) {
    return isSelectionShiftBarHandle(handleKind) || isHoverShiftBarHandle(handleKind);
}

export function isShiftBarTop(handleKind: string) {
    return [HandleKinds.ShiftBarTopSelection, HandleKinds.ShiftBarTopHover].indexOf(handleKind) > -1;
}

export function isAddHandle(handleKind: string) {
    return [HandleKinds.AddIconN, HandleKinds.AddIconS].indexOf(handleKind) > -1;
}

export function isShiftBarBottom(handleKind: string) {
    return [HandleKinds.ShiftBarBottomHover, HandleKinds.ShiftBarBottomSelection].indexOf(handleKind) > -1;
}

export function isComponentsRelatedHandle(handleKind: string) {
    return (
        isComponentHandle(handleKind) ||
        isShiftBarHandle(handleKind) ||
        isResizingHandle(handleKind)
    );
}

export const resizeHandleKinds = [
    HandleKinds.ResizeE,
    HandleKinds.ResizeN,
    HandleKinds.ResizeNE,
    HandleKinds.ResizeNW,
    HandleKinds.ResizeS,
    HandleKinds.ResizeSE,
    HandleKinds.ResizeSW,
    HandleKinds.ResizeW
];

export function isResizingHandle(handleKind: string) {
    return resizeHandleKinds.indexOf(handleKind) > -1;
}

export function isTopResizingHandle(handleKind: string) {
    return [HandleKinds.ResizeN, HandleKinds.ResizeNE, HandleKinds.ResizeNW].indexOf(handleKind) > -1;
}

export function isBottomResizingHandle(handleKind: string) {
    return [HandleKinds.ResizeS, HandleKinds.ResizeSE, HandleKinds.ResizeSW].indexOf(handleKind) > -1;
}
