import type { ScrollTopAdjustProps, Sequence, CmpHeights } from "../../flowTypes";

const isSelectedCmpInsideContainer = (parent, data, selectedCmpId) => {
    let isFound = false;
    const processChildren = (children) => {
        if (children && children.length) {
            for (const child of children) {
                if (child === selectedCmpId) {
                    isFound = true;
                    break;
                }
                processChildren(data[child]);
            }
        }
    };
    processChildren(data[parent]);
    return isFound;
};

export const getSelectedCmpTopWRTContent = (root: string, latestData: Sequence, cmpsHeights: CmpHeights,
    selectedCmpId: string, cmpStyles: Record<string, any>) => {
    let found = false, top = 0;
    const processChildren = (children) => {
        let childrenHeight = 0;
        if (!found && children) {
            for (const child of children) {
                if (child === selectedCmpId) {
                    found = true;
                    break;
                }
                if (!found && latestData[child] && latestData[child].length) {
                    if (isSelectedCmpInsideContainer(child, latestData, selectedCmpId)) {
                        processChildren(latestData[child]);
                    } else {
                        top += cmpsHeights[child];
                    }
                } else if (!found) {
                    childrenHeight = cmpsHeights[child] +
                        (cmpStyles[child].marginTop || 0) +
                        (cmpStyles[child].marginBottom || 0);
                    top += childrenHeight;
                    childrenHeight = 0;
                }
            }
        }
    };

    processChildren(latestData[root]);
    return top;
};

export default ({
    root,
    currentScrollTop,
    cmpHeights,
    oldData,
    oldStyles,
    latestData,
    cmpStyles,
    selectedCmpId,
    down
}: ScrollTopAdjustProps) => {
    let newScrollTopValue = currentScrollTop;
    if (cmpHeights) {
        const newTopWRTContent = getSelectedCmpTopWRTContent(root, latestData, cmpHeights, selectedCmpId, cmpStyles),
            oldTopWRTOldContent = getSelectedCmpTopWRTContent(root, oldData, cmpHeights, selectedCmpId, oldStyles);

        if (down) {
            newScrollTopValue = currentScrollTop + newTopWRTContent - oldTopWRTOldContent;
        } else {
            newScrollTopValue = currentScrollTop - oldTopWRTOldContent + newTopWRTContent;
        }
    }
    return newScrollTopValue;
};
