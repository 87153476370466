import React from 'react';
import FourFields from '../index';
import type { FourFieldCornersProps } from "../flowTypes"; // eslint-disable-line
import Icons from '../../../../view/Icons/index';
import makeFourFieldIcons from "../makeFourFieldIcons";

const MAX_CORNER_VALUE = 999;

const validators = [
    {
        // !> instead of <= in consideration of NaN
        validator: value => !(value > MAX_CORNER_VALUE),    // NOSONAR
        message: [
            'msg: common.maxBorderSizeError {The maximum value is {maxValue}}',
            { maxValue: MAX_CORNER_VALUE }
        ] as MsgJointInput
    }
];

export default (props: FourFieldCornersProps) => (
    <FourFields
        label="msg: common.borderRadius {Rounded corners (px)}"
        title="msg: common.border.cornerLock {Link together corner radius values}"
        icons={makeFourFieldIcons(Icons.CORNER, [0, 90, 180, -90])}
        validators={validators}
        restrictDispatchTillAnimationFrame
        {...props}
    />
);
