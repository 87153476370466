import getAccessManager from "../../../getAccessManager";
import { AppStore } from "../../../redux/modules/flowTypes";
import { siteDataStateAppSel } from "../../App/epics/siteData/selectors";
import { BookingsPublishButtonTooltip } from "../../Tooltip/ids";
import { SHOW_PUBLISH_BUTTON_TOOLTIP, TO_SHOW_PUBLISH_BUTTON_TOOLTIP } from "../../Tooltip/stickyTooltip/actionTypes";
import { publishStateSelector } from "../../TopBar/epics/checkPublishStatus/selectors";
import { shouldPublishButtonBeDisabled } from "../../TopBar/view/SavePreviewPublishBtns/utils";

export const bookingsTooltipMiddleware = (store: AppStore) => {
    return (next: Dispatch) => (action: AnyAction) => {
        const appState = store.getState();
        switch (action.type) {
            case TO_SHOW_PUBLISH_BUTTON_TOOLTIP: {
                const publishState = publishStateSelector(appState);
                const siteData = siteDataStateAppSel()(appState);
                const noPagesMarkedForPublish = siteData.getPageIdsNotMarkedAsDontPublish().length === 0;
                const disablePublishButton = (
                    shouldPublishButtonBeDisabled(publishState.publishStatus) ||
                    noPagesMarkedForPublish ||
                    !getAccessManager().isPublishButtonEnabled()
                );
                if (!disablePublishButton) {
                    store.dispatch({
                        type: SHOW_PUBLISH_BUTTON_TOOLTIP,
                        payload: { viewId: BookingsPublishButtonTooltip },
                    });
                }
                break;
            }
        }

        return next(action);
    };
};
