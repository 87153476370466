import type { FileManagerState } from "../flowTypes";
import pathUtils from '../../../../../../utils/path.js';
import getNavigateDirectoryState from "../utils/getNavigateDirectoryState";

export default (state: FileManagerState, action: any) => {
    const
        { currentPath: oldPath } = state,
        { payload: { directoryLevel } } = action,
        parts = oldPath.split('/').slice(0, directoryLevel);

    return getNavigateDirectoryState(state, pathUtils.build(parts));
};
