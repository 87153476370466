/* @flow */

const { timeParse } = require("./timeParse.js");

/*::
    import type { TimeParseInput } from './timeParse'
 */

const setHandyTimeout = (
    callback/*: Function | string */,
    delay/*:? TimeParseInput */,
    ...args/*: any[] */
// $FlowFixMe
)/*: TimeoutID */ => setTimeout(callback, timeParse(delay), ...args);

module.exports = {
    setHandyTimeout,
};
