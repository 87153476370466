import {
    GoogleReviewsDialogId,
    ConnectToGoogleDialogId,
    FailedGoogleReviewsDialogId,
    ExceptionGoogleReviewsDialogId,
    GoogleReviewsPremiumComponentDialogId
} from "./dialogIds";
import GoogleReviewsDialog from "./GoogleReviewsDialog";
import ConnectToGoogleDialog from "./ConnectToGoogleDialog";
import FailedGoogleReviewsDialog from "./FailedGoogleReviewsDialog";
import ExceptionGoogleReviewsDialog from "./ExceptionGoogleReviewsDialog";
import { WidgetsPremiumComponentDialog } from "../../Widgets/dialogs/premiumComponent";

const GoogleReviewWidgetDialogRegistry = [
    [
        GoogleReviewsDialogId,
        GoogleReviewsDialog
    ],
    [
        ConnectToGoogleDialogId,
        ConnectToGoogleDialog
    ],
    [
        FailedGoogleReviewsDialogId,
        FailedGoogleReviewsDialog
    ],
    [
        ExceptionGoogleReviewsDialogId,
        ExceptionGoogleReviewsDialog
    ],
    [
        GoogleReviewsPremiumComponentDialogId,
        WidgetsPremiumComponentDialog
    ]
];

export {
    GoogleReviewWidgetDialogRegistry
};
