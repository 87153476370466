import * as R from 'ramda';
import makeEpic, { pipeAfterUpdate } from '../../../../epics/makeEpic';
import makeStateSelectorReducer from '../../../../epics/makeStateSelectorReducer';
import {
    receiveOnly,
    optionalReceiveOnly,
    optionalResetReceiveOnly,
    optional
} from '../../../../epics/makeCondition';
import valueActionType from "./valueActionType";
import adjustAfterUpdate from './adjustAfterUpdate';
import adjustContentBBoxAfterUpdate from './adjustContentBBoxAfterUpdate';
import adjustComponentsBoundariesAfterUpdate from './adjustComponentsBoundariesAfterUpdate';
import componentUpdater from './componentUpdater';
import {
    onAddComponentUpdater,
    onAddComponentDropUpdater,
    addByShortcutDblClickUpdater,
    onComponentReqiresAdditionalConfiguration,
    onComponentConfigurationComplete,
    onComponentConfigurationCanceled,
    onAddSectionStartUpdater,
    flushSectionDataUpdaters,
    adjustInTemplateOnComponentAdded,
    onWorkspaceScroll,
    onComponentTierChecking,
    addCmpInHoverboxUpdater
} from './addComponent';
import { onComponentMovedUpdater } from './movedComponent';
import { onSectionMovedUpdaters } from './userInteractionMutations/moveSectionsUpDown';
import * as deleteSelectedComponents from './deleteSelectedComponents';
import * as workspaceActionTypes from "../../actionTypes";
import * as userInteractionMutations from './userInteractionMutations/index';
import * as changeComponentsOrder from './changeComponentsOrder';
import * as moveComponentsBetweenPageAndTemplate from './moveComponentsBetweenPageAndTemplate';
import * as changeByHotKeys from './userInteractionMutations/changeByHotKeys';
import * as propPanelChangeComponentDimensions from './propPanelChangeComponentDimensions';
import * as pasteComponentsUpdater from './pasteComponents';
import pageDataSetPublicRequestActionType from "../../../App/epics/pageDataset/pageDataSetPublicRequestActionType";
import { deepMerge } from "../../../../../utils/deepMerge";
import {
    SET_SELECTED_COMPONENTS,
    COMPONENTS_MAP_INITIALIZED,
    COMPONENTS_MAP_REPLACE_MISSING_ASSETS,
    COMPONENTS_MAP_DELETE_MISSING_ASSETS,
    FLUSH_ADJUSTMENT_CACHE, USER_INTERACTION_DONE_ON_COMPONENTS,
    TEMPLATE_TEAM_PAGE_TRANSLATION_COMPLETE,
    PROCESS_CMPS_MAP_AFTER_ATTACHMENTS_UPDATE,
    COMPONENTS_WRAPPED,
    ADJUSTMENT_DATA_APPLIED,
    UPDATE_COMPONENT_EXTENSION_DATA,
} from "./actionTypes";
import registry from "../../../../view/oneweb/registry/index";
import initAction from "../../../../redux/initAction";
import {
    getAssetsReplacementTransformations,
    getAssetsDeleteTransformations,
    fetchAssetsUrls
} from '../../../../utils/assetUtils';
import type { AssetsReplacements } from '../../../../utils/assetUtils';
import VideoFileComponentKind from '../../../oneweb/Video/VideoFile/kind';
import ImageComponentKind from '../../../oneweb/Image/kind';
import GalleryComponentKind from '../../../oneweb/Gallery/kind';
import ImageSliderComponentKind from '../../../oneweb/ImageSlider/kind';
import { getDefaultVideoFileAsset } from '../../../oneweb/Video/constants';
import { repositoryTransparentImageMetadata as transparentImgMetadata } from '../../../../constants/components';
import relationsValueActionType from "../relations/valueActionType";
import pageDatasetValueActionType from '../../../App/epics/pageDataset/valueActionType';
import syncRelation, { isInProjection } from '../relations/syncRelationsWithComponentsMap';
import {
    SAVE_REQUEST,
    SAVE,
    APP_LEFT_MOUSE_DOWN,
    WINDOW_MOUSE_MOVE,
    WINDOW_MOUSE_UP
} from "../../../App/actionTypes";
import {
    TemplateWidthActionType,
    TemplateSelectedThemeActionType
} from '../../../oneweb/Template/epics/template/selectorActionTypes';
import currentPageIdValueActionType from '../../../App/epics/currentPageId/valueActionType';
import pageDatasetLoadedActionType from "../../../App/epics/pageDataset/pageDatasetLoadedActionType";
import isUndoableChange, { componentEvalUndoAmendCasesMap } from './isUndoableChange';
import * as undoManagerUpdateReasons from '../../../../epics/undoManager/updateReasons';
import * as updateReasons from './updateReasons';
import * as selectedComponentsIds from './selectedComponentsIds';
import type { EpicState, State } from './flowTypes';
import templateValueActionType from '../../../oneweb/Template/epics/template/valueActionType';
import { ShiftBarBottomSelection } from '../../../../utils/handle/kinds';
import applyAdjustingSpaceMutations from './userInteractionMutations/applyAdjustingSpaceMutations';
import { changeComponentsMap } from "./utils";
import { applyShiftUpOnTextCmpHeightReduction } from './userInteractionMutations/applyShiftUpOnTextCmpHeightReduction';
import TextComponentKind from '../../../oneweb/Text/kind';
import workspaceBBoxValueActionType from '../workspaceBBox/valueActionType';
import { getBBoxDimensions } from "../../../../utils/bBox";
import type {
    ComponentsMap,
    ComponentsMapExtension
} from '../../../../redux/modules/children/workspace/flowTypes';
import {
    selectedComponentIdSelector, selectedComponentSelector, selectedComponentsIdsSelector,
    EditModeComponentIdSelector, componentsMapSelector,
    ReceiveOnlySelectedComponentIdSelector,
    OptionalResetEditModeComponentIdSelector as OptionalResetEditModeComponentIdROSelector,
} from "./selectorActionTypes";
import { arrayToTrueMap } from "../../../../utils/arrayToTrueMap";
import { workspaceHandlesValueActionType } from "../handles/valueActionType";
import { topMostHandleVAT } from "../topMostHandle/valueActionType";
import {
    workspaceComponentHoverDecorationsValueActionType
} from "../componentHoverDecorations/valueActionType";
import {
    workspaceComponentAttachDecorationsValueActionType
} from "../componentAttachDecorations/valueActionType";
import { workspaceSaveStatusValueActionType } from "../saveStatus/valueActionType";
import { componentsMainActionsVAT } from "../componentMainActions/valueActionType";
import { componentsPanelActionsVAT } from "../componentPanelActions/valueActionType";
import {
    workspaceComponentsSelectionDecorationValueActionType
} from "../componentsSelectionDecoration/valueActionType";
import { workspaceComponentsOutsideTemplateValueActionType } from "../componentsOutsideTemplateWarning/valueActionType";
import { propertiesPanelValueActionType } from "../../../PropertiesPanel/epic/valueActionType";
import { workspaceShiftBarValueActionType } from "../shiftBarDecos/valueActionType";
import { isKeyPressedValueActionType } from "../../../App/epics/isKeyPressed/valueActionType";
import { workspaceClickValueActionType } from "../workspaceClick/valueActionType";
import {
    mousePositionWithRespectToTemplateAreaValueActionType
} from "../mousePositionWithRespectToTemplateArea/valueActionType";
import { cursorValuActionType } from "../../../App/epics/cursor/valueActionType";
import { workspaceDoubleClickValueActionType } from "../workspaceDoubleClick/valueActionType";
import { makeReplaceAssetsUpdaters } from "./makeReplaceAssetsUpdaters";
import { onGhostComponentDragEndUpdater } from "./codeComponentUpdaters";
import {
    resetEditModeComponentId, removeEditModeComponentId,
    setEditModeComponentIdOnComponentEditUpdater,
    setEditModeComponentIdOnWorkspaceDblClickUpdater,
    resetEditModeComponentIdUpdater, openPropertiesPanelHandler
} from "./editModeComponentId";
import { resetScope, setComponentsMap, setComponentsMapExtension, setSelectedComponentsIds } from "./setters";
import { MatchAnyActionType } from "../../../../epics/constants";
import { editModeComponentIdSelector } from "./selectors";
import { memoMaxOne } from "../../../../../utils/memo";
import { UserFocusValueActionType } from "../../../App/epics/userFocus/valueActionType";
import {
    shouldResetEditModeComponentId,
    getUpdateReasonForResetComponentModeId
} from "./shouldResetEditModeComponentId";
import { siteDataValueActionType } from "../../../App/epics/siteData/valueActionType";
import {
    verifyComponentsAfterUpdate,
    verifyComponentsOnceAfterUpdateHooksComplete
} from "./verifyComponentsAfterUpdate";
import { addComponentDimenionsChangeIds } from "./addComponentDimensionsChangeIds";
import { componentsEvalDefaultState } from "./constants";
import { _componentsDependenciesReducer } from "./_componentsDependenciesReducer";
import styleSheetsValueActionType, { STYLESHEETS_EPIC_VALUE } from "../stylesheets/valueActionType";
import * as globalStylesMapper from "../../../../../dal/pageMapAdapter/mappers/Globalstyles/index";
import { TEXT_HANDLE_EDITOR_CHANGE } from '../../../oneweb/Text/actionTypes';
import { UPDATE_WRAP_REQUEST } from '../wrap/actionTypes';
import { MOBILE_EDITOR_TOGGLE_HIDE, MOBILE_EDITOR_TOGGLE_BOTTOM_LOCK,
    MOBILE_EDITOR_CHANGE_BG_POSITION } from '../../../MobileViewEditor/actionTypes';
import { SiteSettingsSelector } from '../../../App/epics/siteSettings/selectorActionTypes';
import { componentAttachmentsVAT } from '../componentAttachements/valueActionType';
import { customSendReport } from '../../../../customSendCrashReport';
import getComponentsMap from "./getComponentsMap";
import { isStretchComponentKind } from "../../../oneweb/isStretchComponentKind";
import { getCrashTraceStringified } from "../../../../redux/middleware/crashTraceCollector";
import { truncateIfLonger256KB } from "../../../../../utils/string.js";
import * as SectionUpdaters from "./sectionUpdaters";
import { getComponentsMapExtension, getSectionInsertion } from "./getters";
import { setDefaultHeightForHeaderFooter } from "../defaultHeaderFooterHeights/utils";
import {
    getHeaderAndFooterSection,
    removeEmptySpacesBetweenSections
} from "../../../oneweb/Section/utils";
import supportToolCmpEvalUpdaters from '../../../TopBar/Support/supportToolCmpEvalUpdaters';
import { setSessionStorageIsCmpsMapValid, isCmpsDataHasValidOnChange } from '../../../App/epics/pageDataset/isCmpsMapValidBeforeSave';
import { isBoxKind } from "../../../oneweb/componentKinds";
import { processCmpsMapAfterAttachmentUpdateReducer } from "./processCmpsMapAfterAttachmentUpdateReducer";
import {
    MODERN_FOOTER_COMPONENTS_INITIALIZED, MODERN_HEADER_COMPONENTS_INITIALIZED,
    MODERN_HEADER_TOGGLE_CMPS_INITIALIZED, MODERN_FOOTER_TOGGLE_CMPS_INITIALIZED,
} from "../../../ModernLayouts/actionTypes";
import { modernHFLayoutUpdaters } from "../../../ModernLayouts/componentsEvalUpdaters";
import { templateOffsetVAT } from "../../../oneweb/Template/epics/templateOffset/valueActionType";
import * as fullWidthUpdaters from "./fullWidthUpdater";
import { AI_TEXT_REPLACE_TEXT_CONTENT } from '../../../oneweb/Text/epics/AITextEpic/actionTypes';

const
    GAP_BEFORE_EDIT_MODE_TO_AVOID_PUSHDOWN = 70,
    getBelowOrParentComponent = (componentsMap, refComponentId, relations): any => {
        const
            refComponent = componentsMap[refComponentId],
            { id: refId } = refComponent;

        const refRelIn = ((relations[refId] || {}).relIn) || {};
        let belowSibling = Object.keys(componentsMap).reduce((belowSibling, otherCmpId) => {
            if (refId === otherCmpId) {
                return belowSibling;
            }

            const
                otherCmp = componentsMap[otherCmpId],
                otherCmpRelIn = ((relations[otherCmpId] || {}).relIn) || {};

            if (
                refRelIn.id === otherCmpRelIn.id &&
                isInProjection(otherCmp, refComponent) && // find below siblings in projection
                // @ts-ignore
                (!belowSibling || otherCmp.top < belowSibling.top) // find closest sibling
            ) {
                return otherCmp;
            }

            return belowSibling;
        }, null);

        if (belowSibling) {
            return { component: belowSibling, hasBelowSibling: true };
        } else if (refRelIn && componentsMap[refRelIn.id]) {
            return { component: componentsMap[refRelIn.id], hasBelowSibling: false };
        }

        return { component: null };
    },
    makeComponentsMapExtension = (componentsMap: ComponentsMap): ComponentsMapExtension =>
        Object.keys(componentsMap).reduce((acc, key) => {
            const reducerForExtension = registry[componentsMap[key].kind].reducerForExtension;
            if (reducerForExtension) {
                acc[key] = reducerForExtension(undefined, initAction());
            }
            return acc;
        }, {}),
    sanitizeComponentsByReducer = R.evolve({
        state: state => componentUpdater({
            state,
            action: initAction(),
            selectedComponentId: '',
            componentsDependencies: {}
        })
    }),
    actionsToSyncWithDispatch = [
        [SAVE_REQUEST, SAVE]
    ],
    makeSyncRelationsUpdater = ([action, actionToDispatch]: Array<string>) => ({
        conditions: [
            receiveOnly(componentAttachmentsVAT),
            receiveOnly(relationsValueActionType),
            receiveOnly(templateValueActionType),
            receiveOnly(currentPageIdValueActionType),
            action
        ],
        reducer: ({ values: [{ attachments }, { changes }, template, currentPageId], state: epicState }) => {
            let { state: { componentsMap } } = epicState;

            componentsMap = removeEmptySpacesBetweenSections(attachments, componentsMap);

            if (!Object.keys(componentsMap).length) {
                return {
                    state: epicState,
                    actionToDispatch: actionToDispatch ? { type: actionToDispatch } : null
                };
            }

            return {
                state: setComponentsMap(syncRelation(changes, componentsMap, template, currentPageId, true), epicState),
                actionToDispatch: actionToDispatch ? { type: actionToDispatch } : null,
                updateReason: updateReasons.SYNC_RELATIONS
            };
        }
    }),
    getAssetsReplacementsWithTransparentImage = (urls, missingAssets) => urls.reduce(
        (acc, url) => missingAssets.indexOf(url) > -1 ? { ...acc, [url]: transparentImgMetadata } : acc, {}), // eslint-disable-line
    getAssetsReplacementsWithDefaultVideo = (urls, missingAssets) => urls.reduce(
        (acc, url) => missingAssets.indexOf(url) > -1 ? { ...acc, [url]: getDefaultVideoFileAsset() } : acc, {}), // eslint-disable-line
    replaceAssetsInState = (state: State,
        assetsReplacements: AssetsReplacements,
        mergeAssets: boolean = false): State => {
        const
            componentsAssetsTransformations = getAssetsReplacementTransformations(
                assetsReplacements,
                state.componentsMap,
                mergeAssets
            ),
            componentsMap = R.pipe(...componentsAssetsTransformations)(state.componentsMap);

        return { ...state, componentsMap };
    },
    replaceAssetsReducer = (state: EpicState, assetsReplacements: AssetsReplacements): EpicState => ({
        ...state,
        state: replaceAssetsInState(state.state, assetsReplacements, true /* mergeAssets */)
    }),
    broadcastActionTypesToIgnore = arrayToTrueMap([
        /* Perf optimization only */
        /* If one of this actions is required in individual component updater, it can be removed from this list */
        valueActionType,
        workspaceHandlesValueActionType,
        topMostHandleVAT,
        workspaceComponentHoverDecorationsValueActionType,
        workspaceComponentAttachDecorationsValueActionType,
        workspaceSaveStatusValueActionType,
        workspaceComponentsSelectionDecorationValueActionType,
        componentsMainActionsVAT,
        componentsPanelActionsVAT,
        workspaceComponentsOutsideTemplateValueActionType,
        propertiesPanelValueActionType,
        workspaceShiftBarValueActionType,
        isKeyPressedValueActionType,
        workspaceClickValueActionType,
        workspaceDoubleClickValueActionType,
        mousePositionWithRespectToTemplateAreaValueActionType,
        cursorValuActionType,
        workspaceActionTypes.WORKSPACE_LEFT_MOUSE_DOWN,
        workspaceActionTypes.WORKSPACE_CLICK,
        workspaceActionTypes.WORKSPACE_DOUBLE_CLICK,
        workspaceActionTypes.WORKSPACE_MOUSE_UP,
        USER_INTERACTION_DONE_ON_COMPONENTS,
        APP_LEFT_MOUSE_DOWN,
        WINDOW_MOUSE_UP,
        WINDOW_MOUSE_MOVE,
        workspaceActionTypes.SET_COMPONENT_ADJUSTMENT_DATA
    ]),
    makeUpdateRelIns = (changes) => (epicState: EpicState) => {
        const componentsMap = componentsMapSelector(epicState);

        let atLeastOneChange = false;

        const updatedComponentsMap = Object.keys(componentsMap).reduce((acc, cmpId) => {
            const
                component = epicState.state.componentsMap[cmpId],
                relIn = R.path([cmpId, 'relIn'], changes) || null,
                relInIsChanged = !R.equals(component.relIn, relIn);

            if (relInIsChanged) {
                atLeastOneChange = true;
                acc[cmpId] = R.assoc('relIn', relIn, component);
            } else {
                acc[cmpId] = component;
            }

            return acc;
        }, {});

        return atLeastOneChange ? setComponentsMap(updatedComponentsMap, epicState) : epicState;
    },
    epic = makeEpic({
        defaultState: componentsEvalDefaultState,
        valueActionType,
        // @ts-ignore
        afterUpdate: pipeAfterUpdate(
            verifyComponentsAfterUpdate,
            adjustAfterUpdate,
            selectedComponentsIds.fixSelectedComponentsIdsAndEditModeComponentId,
            adjustComponentsBoundariesAfterUpdate,
            adjustContentBBoxAfterUpdate,
            verifyComponentsOnceAfterUpdateHooksComplete,
            addComponentDimenionsChangeIds,
            (props) => { // report text components which have null content
                const textComponentIdListWithNullContent = R.pipe(
                    R.pathOr({}, ['nextState', 'componentsMap']),
                    R.values,
                    R.filter(({ kind, content }) => kind === 'TEXT' && content === null),
                    R.map(({ id }) => id)
                )(props);
                textComponentIdListWithNullContent.forEach(id => {
                    customSendReport({
                        message: "Text component with null content found in component eval",
                        additionalInfo: {
                            id,
                            wasNullInPrevAsWell: R.pathEq(['prevState', 'componentsMap', id, 'content'], null, props),
                            actionsTraceStr: truncateIfLonger256KB(getCrashTraceStringified()),
                        }
                    });
                });
                return { state: props.nextState };
            },
            (props) => {
                // TODO: remove after PoS data corruption issues are fixed
                try {
                    isCmpsDataHasValidOnChange(props);
                } catch (e) {
                    // do nothing
                }
                return { state: props.nextState };
            },
        ),
        undo: {
            isUndoableChange,
            undoablePaths: [
                ['state', 'componentsMap'],
                ['scope', 'componentsAdjustmentData']
            ],
            amendToLatestPointCases: componentEvalUndoAmendCasesMap
        },
        updaters: [
            {
                conditions: [COMPONENTS_MAP_REPLACE_MISSING_ASSETS],
                reducer: ({ values: [assetsReplacements], state: { state: epicState, ...rest } }) => ({
                    state: {
                        ...rest,
                        state: replaceAssetsInState(epicState, assetsReplacements)
                    },
                    updateReason: updateReasons.REPLACE_MISSING_ASSETS
                })
            },
            {
                conditions: [relationsValueActionType],
                reducer: ({ values: [{ changes }], state: epicState }) => ({
                    // TODO WBTGEN-5607 relationsMap may be removed from componentsEval scope
                    state: R.pipe(
                        R.assocPath(['scope', 'relationsMap'], changes),
                        makeUpdateRelIns(changes)
                    )(epicState),
                    updateReason: updateReasons.RELATIONS_UPDATED
                })
            },
            {
                conditions: [currentPageIdValueActionType],
                reducer: ({ values: [currentPageId], state: epicState }) => ({
                    state: R.assocPath(['scope', 'currentPageId'], currentPageId)(epicState),
                    updateReason: updateReasons.CHANGE_SCOPE
                })
            },
            {
                conditions: [siteDataValueActionType],
                reducer: ({ values: [siteMap], state: epicState }) => ({
                    state: R.assocPath(['scope', 'siteMap'], siteMap)(epicState),
                    updateReason: updateReasons.CHANGE_SCOPE
                })
            },
            {
                conditions: [componentAttachmentsVAT],
                reducer: ({ values: [{ attachments }], state: epicState }) => ({
                    state: R.assocPath(['scope', 'attachments'], attachments)(epicState),
                    updateReason: updateReasons.CHANGE_SCOPE
                })
            },
            {
                conditions: [SiteSettingsSelector],
                reducer: ({ values: [siteSettings], state: epicState }) => ({
                    state: R.assocPath(['scope', 'siteSettings'], siteSettings)(epicState),
                    updateReason: updateReasons.CHANGE_SCOPE
                })
            },
            {
                conditions: [styleSheetsValueActionType],
                reducer: ({ values: [styles], state: epicState }) => ({
                    state: R.assocPath(['scope', 'styles'], styles)(epicState),
                    updateReason: updateReasons.CHANGE_SCOPE
                })
            },
            {
                conditions: [SET_SELECTED_COMPONENTS],
                reducer: ({ values: [cmpIds], state: epicState }) => ({
                    state: setSelectedComponentsIds(cmpIds)(epicState),
                    updateReason: updateReasons.SET_SELECTED_COMPONENTS
                })
            },
            {
                conditions: [COMPONENTS_MAP_DELETE_MISSING_ASSETS],
                reducer: ({ values: [missingAssets], state: epicState }) => {
                    const
                        { state, state: { componentsMap }, ...rest } = epicState,
                        updatedComponentsMap = Object.keys(componentsMap).reduce((acc, componentId) => {
                            let component = componentsMap[componentId];

                            if (component.kind === ImageComponentKind ||
                                isBoxKind(component.kind) ||
                                isStretchComponentKind(component.kind)) {
                                const urls = R.pipe(fetchAssetsUrls, R.uniq)(component);
                                if (urls.length) {
                                    const assetsReplacements = getAssetsReplacementsWithTransparentImage(urls, missingAssets);

                                    if (!R.isEmpty(assetsReplacements)) {
                                        const componentsAssetsTransformations =
                                            getAssetsReplacementTransformations(assetsReplacements, component);

                                        component = R.pipe(...componentsAssetsTransformations)(component);
                                    }
                                }
                            } else if (component.kind === ImageSliderComponentKind ||
                                component.kind === GalleryComponentKind) {
                                const componentsAssetsTransformations = getAssetsDeleteTransformations(missingAssets, component);

                                if (!R.isEmpty(componentsAssetsTransformations)) {
                                    component = R.pipe(...componentsAssetsTransformations)(component);
                                }
                            } else if (component.kind === VideoFileComponentKind) {
                                const urls = R.pipe(fetchAssetsUrls, R.uniq)(component);
                                if (urls.length) {
                                    const assetsReplacements = getAssetsReplacementsWithDefaultVideo(urls, missingAssets);
                                    if (!R.isEmpty(assetsReplacements)) {
                                        const componentsAssetsTransformations =
                                            getAssetsReplacementTransformations(assetsReplacements, component);

                                        component = R.pipe(...componentsAssetsTransformations)(component);
                                    }
                                }
                            }

                            return { ...acc, [componentId]: component };
                        }, componentsMap),
                        newState = { ...rest, state: { ...state, componentsMap: updatedComponentsMap } };

                    return {
                        state: newState,
                        updateReason: updateReasons.DELETE_MISSING_ASSETS
                    };
                }
            },
            {
                conditions: [
                    pageDatasetLoadedActionType,
                    receiveOnly(templateValueActionType),
                    receiveOnly(templateOffsetVAT),
                    receiveOnly(siteDataValueActionType)
                ],
                reducer: ({ values: [pageDataSet, template, templateOffset, site], state: epicState }) => {
                    const mappedStylesheet = globalStylesMapper.to(pageDataSet.stylesheet),
                        result = getComponentsMap(site, pageDataSet, template),
                        { componentsMap: newComponentsMap, actionsToDispatch } =
                            setDefaultHeightForHeaderFooter(result.componentsMap),
                        newComponentsMapExtension = makeComponentsMapExtension({
                            ...newComponentsMap,
                            ...(result.oldHeaderCmpsMap || {}),
                            ...(result.oldFooterCmpsMap || {}),
                        }),
                        oldHeaderCmpsMapExtension = {},
                        oldFooterCmpsMapExtension = {};
                    if (result.oldHeaderCmpsMap) {
                        Object.keys(result.oldHeaderCmpsMap).forEach(id => {
                            if (newComponentsMapExtension.hasOwnProperty(id)) {
                                oldHeaderCmpsMapExtension[id] = newComponentsMapExtension[id];
                                delete newComponentsMapExtension[id];
                            }
                        });
                    }
                    if (result.oldFooterCmpsMap) {
                        Object.keys(result.oldFooterCmpsMap).forEach(id => {
                            if (newComponentsMapExtension.hasOwnProperty(id)) {
                                oldFooterCmpsMapExtension[id] = newComponentsMapExtension[id];
                                delete newComponentsMapExtension[id];
                            }
                        });
                    }
                    const newState = R.pipe(
                            R.assocPath(['scope', 'componentsAdjustmentData'], {}),
                            setComponentsMap(newComponentsMap),
                            setComponentsMapExtension(newComponentsMapExtension),
                            sanitizeComponentsByReducer,
                            R.assocPath(['scope', 'template'], template),
                            R.assocPath(['scope', 'templateWidth'], template.width),
                            R.assocPath(['scope', 'templateOffset'], templateOffset),
                            R.assocPath(['scope', 'stylesheets'], mappedStylesheet),
                            selectedComponentsIds.deselectAllComponents,
                            resetEditModeComponentId
                        )(epicState),
                        components: Record<string, any> = newState.state;

                    // TODO: remove after identifying root cause for corrupted data issue
                    setSessionStorageIsCmpsMapValid(components.componentsMap);

                    const multipleActionsToDispatch = [
                        ...actionsToDispatch,
                        { type: COMPONENTS_MAP_INITIALIZED, payload: components },
                    ];
                    const { header, footer } = getHeaderAndFooterSection(components.componentsMap);
                    if (result.oldHeaderCmpsMap && header.modernLayout) {
                        const { active, layoutId } = header.modernLayout;
                        multipleActionsToDispatch.push({
                            type: MODERN_HEADER_COMPONENTS_INITIALIZED,
                            payload: {
                                active,
                                layoutId,
                                cmpsMap: result.oldHeaderCmpsMap,
                                cmpsMapExtension: oldHeaderCmpsMapExtension
                            }
                        });
                    }
                    if (result.oldFooterCmpsMap && footer.modernLayout) {
                        const { active, layoutId } = footer.modernLayout;
                        multipleActionsToDispatch.push({
                            type: MODERN_FOOTER_COMPONENTS_INITIALIZED,
                            payload: {
                                active,
                                layoutId,
                                cmpsMap: result.oldFooterCmpsMap,
                                cmpsMapExtension: oldFooterCmpsMapExtension
                            }
                        });
                    }
                    if (result.toggleOffHeaderCmpsMap && header.modernLayout && header.modernLayout.active) {
                        const { layoutId } = header.modernLayout;
                        multipleActionsToDispatch.push({
                            type: MODERN_HEADER_TOGGLE_CMPS_INITIALIZED,
                            payload: {
                                layoutId,
                                toggleOffHeaderCmpsMap: result.toggleOffHeaderCmpsMap,
                                initialState: true
                            }
                        });
                    }
                    if (result.toggleOffFooterCmpsMap && footer.modernLayout && footer.modernLayout.active) {
                        const { layoutId } = footer.modernLayout;
                        multipleActionsToDispatch.push({
                            type: MODERN_FOOTER_TOGGLE_CMPS_INITIALIZED,
                            payload: {
                                layoutId,
                                toggleOffFooterCmpsMap: result.toggleOffFooterCmpsMap,
                                initialState: true
                            }
                        });
                    }
                    return {
                        state: newState,
                        multipleActionsToDispatch,
                        updateReason: undoManagerUpdateReasons.UNDO_INITIAL_STATE
                    };
                }
            },
            {
                conditions: [pageDataSetPublicRequestActionType()],
                reducer: ({ state: epicState }) => ({
                    state: resetScope(epicState),
                    updateReason: updateReasons.CHANGE_SCOPE
                })
            },
            {
                keepFullActions: true,
                conditions: [workspaceActionTypes.SET_COMPONENT_ADJUSTMENT_DATA],
                reducer: ({ values: [{ payload, forwardTo: { id: componentId } }], state: epicState }) => {
                    const
                        adjustmentDataPath = ['scope', 'componentsAdjustmentDataCache', componentId],
                        newAdjustmentData = deepMerge(R.path(adjustmentDataPath, epicState), payload),
                        newState = R.assocPath(adjustmentDataPath, newAdjustmentData, epicState);

                    return {
                        state: newState,
                        updateReason: updateReasons.CHANGE_SCOPE
                    };
                }
            },
            {
                conditions: [UPDATE_COMPONENT_EXTENSION_DATA],
                reducer: ({ values: [extensionData], state }) => {
                    const componentsMapExtension = getComponentsMapExtension(state);
                    const newComponentsMapExtension = R.mergeDeepRight(
                        componentsMapExtension,
                        extensionData
                    );
                    const newState = setComponentsMapExtension(newComponentsMapExtension, state);
                    return {
                        state: newState,
                        updateReason: updateReasons.CHANGE_SCOPE
                    };
                }
            },
            {
                conditions: [
                    workspaceActionTypes.SET_COMPONENT_ADJUSTMENT_DATA_BATCHED,
                    receiveOnly(relationsValueActionType),
                    receiveOnly(workspaceBBoxValueActionType),
                    optionalResetReceiveOnly(STYLESHEETS_EPIC_VALUE),
                    OptionalResetEditModeComponentIdROSelector,
                    optionalReceiveOnly(TEXT_HANDLE_EDITOR_CHANGE),
                    optionalReceiveOnly(AI_TEXT_REPLACE_TEXT_CONTENT),
                    optional(ReceiveOnlySelectedComponentIdSelector), // TODO: remove this when WBTGEN-12434 is fixed
                ],
                reducer: ({
                    values: [
                        adjustmentDataBatch,
                        { changes: relations },
                        workspaceBBox,
                        _stylesheetsChanged, // eslint-disable-line
                        _editModeComponentIdChanged,
                        typingInTextPayload,
                        aiTextReplacePayload,
                        _selectedComponentId, // TODO: remove this when WBTGEN-12434 is fixed
                    ],
                    state: epicState
                }) => {
                    const { componentId } = aiTextReplacePayload || typingInTextPayload || {};

                    if (componentId && adjustmentDataBatch[componentId]) {
                        const
                            textAdjustmentData = adjustmentDataBatch[componentId],
                            adjustmentDataPath = ['scope', 'componentsAdjustmentData', componentId],
                            newAdjustmentData = R.merge(R.path(adjustmentDataPath, epicState), textAdjustmentData),
                            newState = R.assocPath(adjustmentDataPath, newAdjustmentData, epicState),
                            component = epicState.state.componentsMap[componentId],
                            componentHeightIncreased = textAdjustmentData.height > component.height,
                            componentHeightDecreased = (textAdjustmentData.height > 0) &&
                                (textAdjustmentData.height < epicState.state.componentsMapExtension[componentId]?.minDimensions?.height),
                            componentsMap = epicState.state.componentsMap;

                        let shouldPushDown, hasBelowSibling, closestComponentId;
                        try {
                            const cmpExt = R.path(['state', 'componentsMapExtension', componentId], epicState);
                            shouldPushDown = cmpExt.shouldPushDown;
                            hasBelowSibling = cmpExt.hasBelowSibling;
                            closestComponentId = cmpExt.closestComponentId;
                        } catch (e: any) {
                                // TODO: remove this when WBTGEN-12434 is fixed
                            customSendReport({
                                message: `Error in component extension for Text with content changed: ${Math.random()}`,    //NOSONAR
                                additionalInfo: {
                                    errorMessage: e.message,
                                    errorStack: e.stack,
                                    componentId,
                                    _editModeComponentIdChanged,
                                    _selectedComponentId,
                                },
                            });
                            throw e;
                        }

                        if (componentHeightDecreased) {
                            const pullUpChanges = applyShiftUpOnTextCmpHeightReduction(
                                epicState.state.componentsMap,
                                componentId,
                                textAdjustmentData.height,
                                epicState.state.componentsMapExtension
                            );
                            return {
                                state: setComponentsMap(
                                    changeComponentsMap(epicState.state.componentsMap, pullUpChanges),
                                    newState
                                ),
                                updateReason: updateReasons.SHIFTDOWN_DURING_TYPING
                            };
                        }

                            // calculate if pushdown is needed only if pushdown computed on getting into edit mode is false
                        if (!shouldPushDown && closestComponentId && componentHeightIncreased) {
                            // as user keeps typing we need to check if we need to pushdown
                            const
                                textComponentBottom = component.top + textAdjustmentData.height,
                                closestComponent = componentsMap[closestComponentId];
                            if (hasBelowSibling) {
                                shouldPushDown = (closestComponent.top - textComponentBottom) <= 15;
                            } else { // parent case
                                shouldPushDown = (closestComponent.top + closestComponent.height)
                                    - textComponentBottom <= 15;
                            }
                        }

                        if ((shouldPushDown && !componentHeightIncreased) || !shouldPushDown) {
                            if (componentHeightIncreased && !aiTextReplacePayload) { // just update height of text component
                                return {
                                    state: R.assocPath(
                                        ['state', 'componentsMap', componentId, 'height'],
                                        textAdjustmentData.height,
                                        newState
                                    ),
                                    updateReason: updateReasons.COMPONENT_HEIGHT_CHANGE_DURING_TYPING
                                };
                            }
                            return { state: newState, updateReason: updateReasons.CHANGE_SCOPE };
                        }

                        // we can't call shift bar epic since it will cause decorations
                        const shiftBarChanges = applyAdjustingSpaceMutations({
                            componentsMap: epicState.state.componentsMap,
                            selectedComponentsIds: [componentId],
                            start: component.top + component.height,
                            current: component.top + textAdjustmentData.height,
                            handleKind: ShiftBarBottomSelection,
                            workspaceBBox,
                            adjustmentData: newAdjustmentData,
                            componentsMapExtension: epicState.state.componentsMapExtension,
                            relationsMap: relations
                        });

                        return {
                            state: setComponentsMap(
                                changeComponentsMap(epicState.state.componentsMap, shiftBarChanges),
                                newState
                            ),
                            updateReason: updateReasons.SHIFTDOWN_DURING_TYPING
                        };
                    } else {
                        const
                            adjustmentDataPath = ['scope', 'componentsAdjustmentDataCache'],
                            newAdjustmentData = deepMerge(R.path(adjustmentDataPath, epicState), adjustmentDataBatch),
                            newState = R.assocPath(adjustmentDataPath, newAdjustmentData, epicState);

                        return {
                            state: newState,
                            updateReason: updateReasons.CHANGE_SCOPE
                        };
                    }
                }
            },
            {
                conditions: [receiveOnly(pageDatasetValueActionType), FLUSH_ADJUSTMENT_CACHE],
                reducer: ({
                    values: [{ page: { items: serverComponentsMap } }],
                    state: epicState
                }) => {
                    const cachedAdjustedComponentsIds = R.keys(epicState.scope.componentsAdjustmentDataCache);
                    let updateReason = updateReasons.ADJUSTMENT_DATA_APPLIED;

                    if (cachedAdjustedComponentsIds.length === 1
                        && !epicState.scope.componentsAdjustmentData[cachedAdjustedComponentsIds[0]]
                        && R.pluck('id', serverComponentsMap).indexOf(cachedAdjustedComponentsIds[0]) === -1
                    ) {
                        updateReason = updateReasons.ADJUSTMENT_DATA_APPLIED_AFTER_COMPONENT_ADD;
                    }

                    const
                        allKeys = R.uniq([
                            ...Object.keys(epicState.scope.componentsAdjustmentData),
                            ...Object.keys(epicState.scope.componentsAdjustmentDataCache)
                        ]),
                        newComponentsAdjustmentData = allKeys.reduce((acc, key) => {
                            acc[key] = R.merge(
                                epicState.scope.componentsAdjustmentData[key],
                                epicState.scope.componentsAdjustmentDataCache[key],
                            );

                            return acc;
                        }, {});

                    return {
                        state: R.evolve({
                            scope: {
                                componentsAdjustmentDataCache: () => ({}),
                                componentsAdjustmentData: () => newComponentsAdjustmentData
                            }
                        }, epicState),
                        updateReason,
                        actionToDispatch: { type: ADJUSTMENT_DATA_APPLIED }
                    };
                }
            },
            {
                conditions: [
                    receiveOnly(relationsValueActionType),
                    EditModeComponentIdSelector
                ],
                reducer: ({
                    values: [
                        { changes: relations },
                        editModeComponentId
                    ], state: epicState
                }) => {
                    const componentsMap = epicState.state.componentsMap,
                        editModeComponent = componentsMap[editModeComponentId];
                    if (!editModeComponent || componentsMap[editModeComponent.id].kind !== TextComponentKind) {
                        return { state: epicState };
                    }

                    const { component, hasBelowSibling } = getBelowOrParentComponent(
                        componentsMap, editModeComponentId, relations
                    );

                    let
                        shouldPushDown = false,
                        editModeComponentBottom = editModeComponent.top + editModeComponent.height;

                    if (component) {
                        if (hasBelowSibling) {
                            shouldPushDown = (component.top - editModeComponentBottom) < GAP_BEFORE_EDIT_MODE_TO_AVOID_PUSHDOWN;
                        } else {
                            // is parent
                            shouldPushDown = (component.top + component.height - editModeComponentBottom) <= 15;
                        }
                    }

                    const
                        previousExtension = R.path(['state', 'componentsMapExtension', editModeComponentId], epicState),
                        newEpicState = R.assocPath(
                            ['state', 'componentsMapExtension', editModeComponentId],
                            R.merge(previousExtension, {
                                shouldPushDown,
                                closestComponentId: component && component.id,
                                hasBelowSibling
                            }),
                            epicState
                        );
                    return { state: newEpicState, updateReason: updateReasons.CHANGE_SCOPE };
                }
            },
            {
                conditions: [TemplateWidthActionType],
                reducer: ({ values: [templateWidth], state: epicState }) => ({
                    state: R.assocPath(['scope', 'templateWidth'], templateWidth, epicState),
                    updateReason: updateReasons.CHANGE_SCOPE
                })
            },
            {
                conditions: [templateOffsetVAT],
                reducer: ({ values: [templateOffset], state: epicState }) => ({
                    state: R.assocPath(['scope', 'templateOffset'], templateOffset, epicState),
                    updateReason: updateReasons.CHANGE_SCOPE
                })
            },
            {
                conditions: [TemplateSelectedThemeActionType],
                reducer: ({ values: [selectedTheme], state: epicState }) => ({
                    state: R.assocPath(['scope', 'templateSelectedTheme'], selectedTheme, epicState),
                    updateReason: updateReasons.CHANGE_SCOPE
                })
            },
            {
                keepFullActions: true,
                conditions: [MatchAnyActionType],
                reducer: ({ values: [action], state: epicState }) => {
                    if (broadcastActionTypesToIgnore[action.type]) { // perf optimization
                        return { state: epicState };
                    }
                    const
                        newState = componentUpdater({
                            state: epicState.state,
                            action,
                            selectedComponentId: epicState.scope.selectedComponentsIds[0],
                            componentsDependencies: epicState.scope.componentsDependencies
                        }),
                        newComponentsDependencies =
                            _componentsDependenciesReducer(epicState.scope.componentsDependencies, action),
                        depsChanged = newComponentsDependencies !== epicState.scope.componentsDependencies,
                        componentsStateChanged = newState !== epicState.state;

                    if (!depsChanged && !componentsStateChanged) {
                        return { state: epicState };
                    }

                    if (depsChanged && componentsStateChanged) {
                        return {
                            state: {
                                scope: {
                                    ...epicState.scope,
                                    componentsDependencies: newComponentsDependencies
                                },
                                state: newState
                            },
                            updateReason: updateReasons.COMPONENT_REDUCER_AND_DEPS
                        };
                    }

                    if (depsChanged) {
                        return {
                            state: {
                                scope: {
                                    ...epicState.scope,
                                    componentsDependencies: newComponentsDependencies
                                },
                                state: epicState.state
                            },
                            updateReason: updateReasons.COMPONENT_DEPS
                        };
                    }

                    if (componentsStateChanged) {
                        let actionToDispatch;
                        if (action.type === UPDATE_WRAP_REQUEST) {
                            const allCmpIds = Object.keys(epicState.state.componentsMap),
                                wrapChangedIds = Object.keys(action.payload.wrappedComponentsUpdateInfo || {})
                                    .filter(id => allCmpIds.includes(id));
                            actionToDispatch = wrapChangedIds.length ? {
                                type: COMPONENTS_WRAPPED,
                                payload: { componentsId: wrapChangedIds }
                            } : null;
                            const txtCmpUpdateInfo = action.payload.textComponentUpdateInfo,
                                textCmpId = txtCmpUpdateInfo && Object.keys(txtCmpUpdateInfo)[0];
                            if (textCmpId) {
                                actionToDispatch = {
                                    type: TEXT_HANDLE_EDITOR_CHANGE,
                                    payload: {
                                        content: txtCmpUpdateInfo[textCmpId].content,
                                        componentId: textCmpId
                                    }
                                };
                            }
                        }
                        return {
                            state: { scope: epicState.scope, state: newState },
                            actionToDispatch,
                            updateReason:
                                (action.type === UPDATE_WRAP_REQUEST && !action.payload.explicit)
                                    ? updateReasons.IMPLICIT_WRAP_UPDATE
                                    : updateReasons.COMPONENT_REDUCER
                        };
                    }

                    throw new Error('This case is not possible');
                }
            },
            {
                conditions: [
                    MOBILE_EDITOR_TOGGLE_HIDE
                ],
                reducer: ({ values: [{ componentId, children = [], unhide, skipUndo }], state }) => {
                    if (!componentId && !children.length) {
                        return { state };
                    }
                    let newState = state;
                    [componentId, ...children].forEach((cmpId) => {
                        if (cmpId && newState.state.componentsMap[cmpId]) {
                            const pathToUpdate = ['state', 'componentsMap', cmpId, 'mobileHide'];
                            newState = R.assocPath(pathToUpdate, !unhide, newState);
                        }
                    });
                    return { state: newState,
                        updateReason: skipUndo ? updateReasons.PROPERTY_CHANGE_ON_HIDDEN_CMP_HOVER :
                            updateReasons.PROPERTY_CHANGE };
                }
            },
            {
                conditions: [
                    MOBILE_EDITOR_TOGGLE_BOTTOM_LOCK
                ],
                reducer: ({ values: [{ componentId, children = [], unlock }], state }) => {
                    if (!componentId && !children.length) {
                        return { state };
                    }
                    let newState = state;
                    [componentId, ...children].forEach((cmpId) => {
                        if (cmpId && newState.state.componentsMap[cmpId]) {
                            const pathToUpdate = ['state', 'componentsMap', cmpId, 'mobileDown'];
                            newState = R.assocPath(pathToUpdate, !unlock, newState);
                        }
                    });
                    return { state: newState,
                        updateReason: updateReasons.PROPERTY_CHANGE };
                }
            },
            {
                conditions: [
                    MOBILE_EDITOR_CHANGE_BG_POSITION
                ],
                reducer: ({ values: [{ cmpId, selectedCmpBgPosition }], state }) => {
                    if (!cmpId) {
                        return { state };
                    }
                    let newState = state;
                    if (cmpId && newState.state.componentsMap[cmpId]) {
                        const pathToUpdate = ['state', 'componentsMap', cmpId, 'mobileSettings', 'position'];
                        const bgPosition = R.path(pathToUpdate, newState);
                        if (R.equals(bgPosition, selectedCmpBgPosition)) {
                            return { state };
                        }
                        newState = R.assocPath(pathToUpdate, selectedCmpBgPosition, newState);
                    }
                    return { state: newState,
                        updateReason: updateReasons.PROPERTY_CHANGE };
                }
            },
            {
                conditions: [UserFocusValueActionType],
                reducer: ({ values: [userFocus], state }) => {
                    if (shouldResetEditModeComponentId(userFocus.kind)) {
                        return {
                            state: resetEditModeComponentId(state),
                            updateReason: getUpdateReasonForResetComponentModeId(userFocus.kind)
                        };
                    }

                    return { state };
                }
            },
            {
                conditions: [TEMPLATE_TEAM_PAGE_TRANSLATION_COMPLETE],
                reducer: ({ state, values: [componentsMap] }) => {
                    return {
                        state: setComponentsMap(componentsMap, state),
                        updateReason: TEMPLATE_TEAM_PAGE_TRANSLATION_COMPLETE,
                    };
                }
            },
            {
                conditions: [PROCESS_CMPS_MAP_AFTER_ATTACHMENTS_UPDATE],
                reducer: processCmpsMapAfterAttachmentUpdateReducer
            },
            ...supportToolCmpEvalUpdaters,
            onComponentTierChecking,
            onAddComponentUpdater,
            onAddSectionStartUpdater,
            ...flushSectionDataUpdaters,
            onAddComponentDropUpdater,
            addByShortcutDblClickUpdater,
            addCmpInHoverboxUpdater,
            onComponentReqiresAdditionalConfiguration,
            onComponentConfigurationComplete,
            onComponentConfigurationCanceled,
            onWorkspaceScroll,
            adjustInTemplateOnComponentAdded,
            changeComponentsOrder.bringToFrontUpdater,
            changeComponentsOrder.moveDownUpdater,
            changeComponentsOrder.moveUpUpdater,
            changeComponentsOrder.sendToBackUpdater,
            changeComponentsOrder.hoverboxFloatingComponentsOrderIndexUpdater,
            moveComponentsBetweenPageAndTemplate.toPageUpdater,
            moveComponentsBetweenPageAndTemplate.toTemplateUpdater,
            ...onComponentMovedUpdater,
            ...onSectionMovedUpdaters,
            propPanelChangeComponentDimensions.heightUpdater,
            propPanelChangeComponentDimensions.widthUpdater,
            userInteractionMutations.onComponentChangingUpdater,
            userInteractionMutations.onLeftMouseDownUpdater,
            userInteractionMutations.onMouseMoveUpdater,
            userInteractionMutations.onWindowMouseUpUpdater,
            userInteractionMutations.onEndTransientUserInteractionUpdater,
            userInteractionMutations.handleEdgeCaseOverlapsWithSections,
            pasteComponentsUpdater.createFromClipboard,
            pasteComponentsUpdater.duplicateSelected,
            pasteComponentsUpdater.duplicateComponentsByIDs,
            pasteComponentsUpdater.duplicateSelectedSilent,
            pasteComponentsUpdater.componentsPasted,
            pasteComponentsUpdater.componentsDuplicated,
            pasteComponentsUpdater.resetCopyCounter,
            deleteSelectedComponents.deleteUpdater,
            deleteSelectedComponents.onKbdBackspaceUpdater,
            deleteSelectedComponents.onKbdDelUpdater,
            deleteSelectedComponents.deleteSectionUpdater,
            selectedComponentsIds.selectComponentInsideBBoxUpdater,
            selectedComponentsIds.selectAllComponentsUpdater,
            selectedComponentsIds.selectOverlapingComponentUpdater,
            selectedComponentsIds.selectComponentOnShowContextMenu,
            selectedComponentsIds.clearOnAddNewComponent,
            selectedComponentsIds.removeSelectedComponentIdsOnTemplateSettings,
            selectedComponentsIds.removeSelectedComponentIdsOnChangeTemplateBackground,
            selectedComponentsIds.clearOnGhostComponentDragStart,
            selectedComponentsIds.selectedOnMobileEditorComponentPressed,
            selectedComponentsIds.onSelectedComponentsIdsChangedUpdater,
            selectedComponentsIds.selectAndUpdateHoverBoxHoverState,
            selectedComponentsIds.selectHoverBoxOnUndo,
            selectedComponentsIds.selectHoverBoxOnRedo,
            SectionUpdaters.addSectionUpdater,
            SectionUpdaters.cancelAddSectionUpdater,
            SectionUpdaters.dropZoneUpdater,
            SectionUpdaters.splitSectionUpdater,
            SectionUpdaters.sectionResizeUpdater,
            SectionUpdaters.sharedBackgroundUpdater,
            ...SectionUpdaters.removeDropZone,
            SectionUpdaters.resizeFooterUpdater,
            setEditModeComponentIdOnComponentEditUpdater,
            setEditModeComponentIdOnWorkspaceDblClickUpdater,
            openPropertiesPanelHandler,
            resetEditModeComponentIdUpdater,
            removeEditModeComponentId,
            onGhostComponentDragEndUpdater,
            fullWidthUpdaters.resizeUpdater,
            fullWidthUpdaters.fullWidthUpdater,
            ...modernHFLayoutUpdaters,
            ...changeByHotKeys.hotkeyReducers,
            ...actionsToSyncWithDispatch.map(payload => makeSyncRelationsUpdater(payload)),
            ...makeReplaceAssetsUpdaters(replaceAssetsReducer, true /* useUpdateReason */),
        ]
    }),
    reducer = makeStateSelectorReducer(epic.reducer, valueActionType),
    getBBoxDimensionsMemo = memoMaxOne(getBBoxDimensions),
    contentDimensionsReducer = makeStateSelectorReducer(
        epic.reducer,
        valueActionType,
        (epicState: EpicState) => getBBoxDimensionsMemo(epicState.scope.contentBBox)
    ),
    userInteractionReducer = makeStateSelectorReducer(
        epic.reducer,
        valueActionType,
        (epicState: EpicState) => epicState.scope.userInteraction
    ),
    attachmentsReducer = makeStateSelectorReducer(
        epic.reducer,
        valueActionType,
        (epicState: EpicState) => epicState.scope.attachments
    ),
    sectionInsertion = makeStateSelectorReducer(
        epic.reducer,
        valueActionType,
        (epicState: EpicState) => getSectionInsertion(epicState)
    ),
    componentsMapReducer = makeStateSelectorReducer(
        epic.reducer,
        valueActionType,
        (epicState: EpicState) => epicState.state.componentsMap
    ),
    componentsDependenciesReducer = makeStateSelectorReducer(
        epic.reducer,
        valueActionType,
        (epicState: EpicState) => epicState.scope.componentsDependencies
    ),
    selectedComponentReducer = makeStateSelectorReducer(
        epic.reducer,
        valueActionType,
        selectedComponentSelector
    ),
    selectedComponentIdReducer = makeStateSelectorReducer(
        epic.reducer,
        valueActionType,
        selectedComponentIdSelector
    ),
    selectedComponentsIdsReducer = makeStateSelectorReducer(
        epic.reducer,
        valueActionType,
        selectedComponentsIdsSelector
    ),
    editModeComponentIdReducer = makeStateSelectorReducer(
        epic.reducer,
        valueActionType,
        editModeComponentIdSelector
    );

export {
    epic as default,
    reducer,
    contentDimensionsReducer,
    makeComponentsMapExtension,
    userInteractionReducer,
    componentsDependenciesReducer,
    selectedComponentReducer,
    selectedComponentIdReducer,
    selectedComponentsIdsReducer,
    editModeComponentIdReducer,
    sectionInsertion,
    componentsMapReducer,
    attachmentsReducer
};
