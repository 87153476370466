import { path, assocPath } from 'ramda';
import type { ModernLayoutOptions, ModernLayoutToggleOptions, MHFDataEpicState, LayoutData } from "./flowTypes";
import type { ComponentsMap, ComponentsMapExtension, ComponentsDependencies } from "../../redux/modules/children/workspace/flowTypes";
import { COMPONENTS_DELETED, WORKSPACE_NEW_COMPONENT_ADDED } from "../Workspace/actionTypes";
import {
    MODERN_HEADER_FOOTER_TOGGLE_CMPS_SET,
    MODERN_HEADER_FOOTER_TOGGLE_CMPS_UNSET,
    MHF_COMPONENTS_TOGGLE_COMPLETED,
} from "./actionTypes";
import { createNewComponentsInSection } from "./utils";
import getLayoutsMap from "./getLayoutsMap";
import { sectionNames } from "./constants";
import { isHeaderSection } from "../oneweb/Section/utils";
import type { WebShopMHFEpicState } from "../oneweb/WebShopMHF/flowTypes";

type CmpsState = {
    componentsMap: ComponentsMap,
    componentsMapExtension: ComponentsMapExtension
};
type NewCmpMapWithID = {
    componentsMap: ComponentsMap,
    oldToNewIdMap: { [key: string]: string; }
};

const isRequiredComponent = (
    currentCmpId: string,
    requiredCmpId: string
) => {
    return currentCmpId === requiredCmpId;
};

const getRequiredComponent = (
    options: ModernLayoutOptions,
    requiredCmpId: string
) => {
    return options.find(option => option.id === requiredCmpId);
};

const toggleChildComponents = (
    toggleOption: ModernLayoutToggleOptions,
    toggleCmpFlag: boolean
): ModernLayoutToggleOptions => {
    const children = toggleOption.children && toggleOption.children.map(childOption => {
        return {
            ...childOption,
            show: toggleCmpFlag
        };
    });
    return {
        ...toggleOption,
        show: toggleCmpFlag,
        children
    };
};

const toggleSelectedComponent = (
    options: ModernLayoutOptions,
    requiredCmpId: string,
    toggleFlag: boolean
): ModernLayoutOptions => {
    return options && options.map(currentOption => {
        if (isRequiredComponent(currentOption.id, requiredCmpId)) {
            return {
                ...currentOption,
                show: toggleFlag
            };
        }
        return currentOption;
    });
};

const getUpdatedOption = (
    option: ModernLayoutToggleOptions,
    requiredCmpId: string,
    toggleFlag: boolean
) => {
    if (isRequiredComponent(option.id, requiredCmpId)) {
        return toggleChildComponents(option, toggleFlag);
    }
    if (option.children && getRequiredComponent(option.children, requiredCmpId)) {
        let children = toggleSelectedComponent(option.children, requiredCmpId, toggleFlag);
        return {
            ...option,
            show: children.some(childOption => childOption.show),
            children
        };
    }
    return option;
};

const fetchAddedComponentsMap = (
    cmpsState: CmpsState,
    componentsDependencies: ComponentsDependencies,
    toggleOffCmpMap: Object,
    sectionId: string,
    toggleCmpId: string,
    layoutItem: LayoutData,
    children: ModernLayoutOptions
) => {
    let addedCmpIds = children ? children.map(childOption => childOption.id) : [toggleCmpId];
    let newCmps = { ...cmpsState.componentsMap };
    const cmpsToBeCreated: Array<string> = [];

    addedCmpIds.forEach(cmpId => {
        if (toggleOffCmpMap && toggleOffCmpMap[cmpId]) {
            newCmps = { ...newCmps, [cmpId]: toggleOffCmpMap[cmpId] };
        } else {
            cmpsToBeCreated.push(cmpId);
        }
    });
    if (cmpsToBeCreated.length) {
        const updatedCmpState = { ...cmpsState, componentsMap: newCmps };
        const result: NewCmpMapWithID = createNewComponentsInSection(
            updatedCmpState,
            componentsDependencies,
            sectionId,
            layoutItem,
            cmpsToBeCreated
        );
        const availableCmps = addedCmpIds.filter(id => !cmpsToBeCreated.includes(id));

        addedCmpIds = [...availableCmps, ...Object.values(result.oldToNewIdMap)];
        newCmps = result.componentsMap;
    }
    return { addedCmpIds, newCmps };
};

const fetchDeletedComponentsMap = (
    componentsMap: ComponentsMap,
    toggleCmpId: string,
    children: ModernLayoutOptions
) => {
    let newCmps = { ...componentsMap };
    const availableChildren = children && children.filter(childOption => childOption.show),
        deletedCmpIds: Array<string> = children ? availableChildren.map(childOption => childOption.id) : [toggleCmpId],
        deletedCmps: Array<Object> = deletedCmpIds.map(cmpId => {
            const deletedCmpMap = newCmps[cmpId];
            delete newCmps[cmpId];
            return deletedCmpMap;
        });

    return { deletedCmpIds, deletedCmps, newCmps };
};

const toggleCmpWithChildren = (
    componentsMap: ComponentsMap,
    sectionId: string,
    toggleCmpId: string,
    toggleCmpFlag: boolean,
    webshopMHFData: WebShopMHFEpicState,
) => {
    let updatedCmpsMap = { ...componentsMap },
        updatedOptions = path(['modernLayout', 'options'], updatedCmpsMap[sectionId]);
    let requiredCmp = getRequiredComponent(updatedOptions, toggleCmpId);
    let children;
    const layoutsMap = getLayoutsMap(webshopMHFData),
        sectionName = isHeaderSection(componentsMap[sectionId]) ? sectionNames.header : sectionNames.footer,
        layoutId = componentsMap[sectionId].modernLayout.layoutId,
        layoutItem = layoutsMap[sectionName] && layoutsMap[sectionName][layoutId],
        layoutItemCmpsMap = layoutItem ? layoutItem.componentsMap : {};

    if (requiredCmp) {
        children = requiredCmp.children;
        if (children && layoutItemCmpsMap[requiredCmp.bindingId]) { // required cmp has a strip assosiated
            children = [requiredCmp, ...children];
        }
    } else {
        updatedOptions.forEach(option => {
            if (!option.children) {
                return;
            }
            requiredCmp = getRequiredComponent(option.children, toggleCmpId);
            if (requiredCmp) {
                children = [requiredCmp];
                if (layoutItemCmpsMap[option.bindingId] && option.show !== option.children.some(childOption =>
                    (childOption.id === toggleCmpId ? toggleCmpFlag : childOption.show))) {
                    children = [...children, option];
                }
            }
        });
    }

    if (updatedOptions) {
        updatedOptions = updatedOptions.map(currentOption => getUpdatedOption(currentOption, toggleCmpId, toggleCmpFlag));
        updatedCmpsMap[sectionId] = assocPath(
            ['modernLayout', 'options'],
            updatedOptions,
            updatedCmpsMap[sectionId]
        );
    }

    return {
        updatedCmpsMap,
        children
    };
};

interface UpdateAfterToggleParams {
    cmpsState: CmpsState;
    componentDependencies: ComponentsDependencies;
    mfhData: MHFDataEpicState;
    sectionName: string;
    sectionId: string;
    layoutId: string;
    toggleCmpId: string;
    toggleCmpFlag: boolean;
    children: ModernLayoutOptions;
    webshopMHFData: WebShopMHFEpicState;
}

const updateCmpsMapAfterToggle = (params: UpdateAfterToggleParams) => {
    const {
        cmpsState,
        componentDependencies,
        mfhData,
        sectionName,
        sectionId,
        layoutId,
        toggleCmpId,
        toggleCmpFlag,
        children,
        webshopMHFData
    } = params;

    const multipleActionsToDispatch: Array<any> = [{ type: MHF_COMPONENTS_TOGGLE_COMPLETED }],
        toggleOffCmpMap = mfhData.toggleOffData[sectionName][layoutId],
        layoutMap = getLayoutsMap(webshopMHFData),
        layoutItem = layoutMap[sectionName][layoutId];

    if (toggleCmpFlag) {
        const { addedCmpIds, newCmps } = fetchAddedComponentsMap(
            cmpsState,
            componentDependencies,
            toggleOffCmpMap,
            sectionId,
            toggleCmpId,
            layoutItem,
            children
        );
        multipleActionsToDispatch.push({
            type: MODERN_HEADER_FOOTER_TOGGLE_CMPS_UNSET,
            payload: { cmpIdList: addedCmpIds, layoutId, sectionName }
        }, {
            type: WORKSPACE_NEW_COMPONENT_ADDED,
            payload: {
                id: addedCmpIds[0],
                component: newCmps[addedCmpIds[0]],
                newCmpIds: addedCmpIds
            }
        });
        return { newCmps, multipleActionsToDispatch };
    }

    const { deletedCmpIds, deletedCmps, newCmps } = fetchDeletedComponentsMap(
        cmpsState.componentsMap,
        toggleCmpId,
        children
    );
    multipleActionsToDispatch.push(
        {
            type: MODERN_HEADER_FOOTER_TOGGLE_CMPS_SET,
            payload: { cmpList: deletedCmps, layoutId, sectionName }
        },
        {
            type: COMPONENTS_DELETED,
            payload: deletedCmpIds
        }
    );
    return { newCmps, multipleActionsToDispatch };
};

export {
    toggleCmpWithChildren,
    updateCmpsMapAfterToggle
};
