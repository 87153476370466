import makeEpic from "../../../../../epics/makeEpic";
import { closeDialog, openDialog } from "../../../../App/actionCreators";
import { createComponentPage } from "../../../../App/epics/siteData/actionCreators";
import {
    CREATE_COMPONENT_PAGE_FAILURE,
    CREATE_COMPONENT_PAGE_SUCCESS
} from "../../../../App/epics/siteData/actionTypes";
import { checkUnsavedChangesAction } from "../../../../UnsavedChanges/actionCreators";
import {
    CANCEL_UNSAVED_CHANGES,
    UNSAVED_CHANGES_CHECKED
} from "../../../../UnsavedChanges/actionTypes";
import BlogKind from "../../BlogComponent/kind";
import { AIData, BlogErrorType, BlogLayout, OnboardingOption } from "../types";
import {
    BLOG_AI_INPUT_UPDATE,
    BLOG_ERROR_RESET,
    BLOG_LAYOUT_UPDATE,
    BLOG_ONBOARDING_OPTION_UPDATE,
    BLOG_WIZARD_OPEN,
    BLOG_WIZARD_RESET_AND_CLOSE,
    BLOG_WIZARD_COMPLETED,
    CHECK_FOR_EXISTING_POSTS,
    CREATE_BLOG_API_CALL,
    GET_BLOG_DETAILS_FAILURE,
    GET_BLOG_DETAILS_SUCCESS
} from "./actionTypes";
import BlogOnboardingDialogId from "../dialogId";
import { getBlogDetails } from "./actionCreators";

export const blogOnboardingGlobalDataVAT = "BLOG_ONBOARDING_GLOBAL_DATA_EPIC";

const initialState = {
    aiData: {
        category: { label: "", value: "" },
        websiteName: "",
        language: "en",
        location: "",
        topics: ""
    } as AIData,
    selectedOnboardingOption: OnboardingOption.AI,
    selectedLayout: BlogLayout.list,
    // start with loading screen until the check for existing posts is done
    isBlogLoading: true,
    errorType: null,
    blogHasPosts: null // becomes a boolean if the has post check is done
};

export const blogOnboardingEpic = makeEpic({
    defaultState: initialState,
    defaultScope: {
        openingWizard: false,
        waitingForBlogResponse: false
    },
    valueActionType: blogOnboardingGlobalDataVAT,
    updaters: [
        {
            // before opening the wizard check for unsaved changes in the current page
            conditions: [BLOG_WIZARD_OPEN],
            reducer: ({ state, scope }) => ({
                state,
                scope: {
                    ...scope,
                    openingWizard: true
                },
                actionToDispatch: checkUnsavedChangesAction()
            })
        },
        {
            conditions: [UNSAVED_CHANGES_CHECKED],
            reducer: ({ state, scope }) => {
                // only open the wizard when the unsaved changes were checked while opening the wizard
                if (scope.openingWizard) {
                    return {
                        // start with a fresh wizard
                        state: initialState,
                        scope: {
                            ...scope,
                            openingWizard: false
                        },
                        // open the onboarding dialog, but stay in loading screen until the blog details fetch is done
                        multipleActionsToDispatch: [
                            { type: CHECK_FOR_EXISTING_POSTS },
                            openDialog(BlogOnboardingDialogId)
                        ]
                    };
                }
                return { state, scope };
            }
        },
        {
            // scope flag needs to be reset when the unsaved changes dialog is canceled
            // so that the blog wizard is not opened when the unsaved changes are checked next time
            conditions: [CANCEL_UNSAVED_CHANGES],
            reducer: ({ state, scope }) => ({
                state,
                scope: {
                    ...scope,
                    openingWizard: false
                }
            })
        },
        {
            conditions: [BLOG_AI_INPUT_UPDATE],
            reducer: ({ state, scope, values: [payload] }) => ({
                state: {
                    ...state,
                    aiData: {
                        ...payload
                    }
                },
                scope,
                updateReason: BLOG_AI_INPUT_UPDATE
            })
        },
        {
            conditions: [BLOG_LAYOUT_UPDATE],
            reducer: ({ state, scope, values: [payload] }) => ({
                state: {
                    ...state,
                    selectedLayout: payload
                },
                scope,
                updateReason: BLOG_LAYOUT_UPDATE
            })
        },
        {
            conditions: [BLOG_ONBOARDING_OPTION_UPDATE],
            reducer: ({ state, scope, values: [payload] }) => ({
                state: {
                    ...state,
                    selectedOnboardingOption: payload
                },
                scope,
                updateReason: BLOG_ONBOARDING_OPTION_UPDATE
            })
        },
        {
            conditions: [CREATE_BLOG_API_CALL],
            reducer: ({
                state,
                scope,
                values: [{ templateID, templateWidth, siteMap, pageNames, editorLanguage }]
            }) => ({
                state: {
                    ...state,
                    isBlogLoading: true
                },
                multipleActionsToDispatch: [
                    // call the API which creates the Blog itself and the Blog/Post page
                    createComponentPage({
                        componentKind: BlogKind,
                        componentInput: state,
                        templateID,
                        templateWidth,
                        siteMap,
                        pageNames,
                        // don't update the language if the blog has posts already
                        editorLanguage: state.blogHasPosts ? undefined : editorLanguage,
                        timeout: '2m',
                    })
                ],
                scope,
                updateReason: CREATE_BLOG_API_CALL
            })
        },
        {
            // called when the createComponentPage API was successful
            conditions: [CREATE_COMPONENT_PAGE_SUCCESS],
            reducer: ({ state, scope, values: [response] }) => {
                // only process response when it was for blog
                if (response.componentKind === BlogKind) {
                    // close the onboarding wizard
                    const multipleActionsToDispatch = [
                        { type: BLOG_WIZARD_RESET_AND_CLOSE },
                        { type: BLOG_WIZARD_COMPLETED, payload: { ...state, created: response.created } }
                    ];

                    return {
                        state: {
                            ...state,
                            isBlogLoading: false
                        },
                        multipleActionsToDispatch,
                        scope,
                        updateReason: CREATE_COMPONENT_PAGE_SUCCESS
                    };
                } else {
                    return { state, scope };
                }
            }
        },
        {
            // called when the createComponentPage API ran into an error
            conditions: [CREATE_COMPONENT_PAGE_FAILURE],
            reducer: ({ state, scope, values: [response] }) => {
                // only process response when it was for blog
                if (response.componentKind === BlogKind) {
                    return {
                        state: {
                            ...state,
                            isBlogLoading: false,
                            // always use defined error types
                            // in case the response code is not defined default to internal error
                            errorType: Object.values(BlogErrorType).includes(response.status)
                                ? response.status
                                : BlogErrorType.internal
                        },
                        scope,
                        updateReason: CREATE_COMPONENT_PAGE_FAILURE
                    };
                } else {
                    return { state, scope };
                }
            }
        },
        {
            // reset the onboarding wizard state and close the dialog
            conditions: [BLOG_WIZARD_RESET_AND_CLOSE],
            reducer: ({ scope }) => {
                return {
                    state: initialState,
                    multipleActionsToDispatch: [closeDialog()],
                    scope,
                    updateReason: BLOG_WIZARD_RESET_AND_CLOSE
                };
            }
        },
        {
            conditions: [BLOG_ERROR_RESET],
            reducer: ({ state, scope }) => {
                return {
                    state: {
                        ...state,
                        errorType: null
                    },
                    scope,
                    updateReason: BLOG_ERROR_RESET
                };
            }
        },
        {
            conditions: [CHECK_FOR_EXISTING_POSTS],
            reducer: ({ state, scope }) => {
                return {
                    state: {
                        ...state,
                        isBlogLoading: true
                    },
                    // skip API call if one is still executing
                    // CHECK_FOR_EXISTING_POSTS could be called twice in a row
                    // if user closes dialog while loading and reopens it again
                    actionToDispatch: scope.waitingForBlogResponse ? null : getBlogDetails(),
                    scope: {
                        ...scope,
                        waitingForBlogResponse: true
                    }
                };
            }
        },
        {
            conditions: [GET_BLOG_DETAILS_SUCCESS],
            reducer: ({ state, scope, values: [response] }) => {
                // TODO: needs to be updated once the new get post count API is available
                const blogHasPosts = response.posts.length > 0;

                return {
                    state: {
                        ...state,
                        isBlogLoading: false,
                        blogHasPosts,
                        // AI option is no longer possible in case the blog has posts
                        // therefore set the option to blank beforehand
                        selectedOnboardingOption: blogHasPosts
                            ? OnboardingOption.blank
                            : OnboardingOption.AI
                    },
                    scope: {
                        ...scope,
                        waitingForBlogResponse: false
                    },
                    updateReason: GET_BLOG_DETAILS_SUCCESS
                };
            }
        },
        {
            conditions: [GET_BLOG_DETAILS_FAILURE],
            reducer: ({ state, scope, values: [response] }) => {
                let errorType: any = null;

                // if the return code is not 404 (= no blog exists) there was an issue with the API
                if (response.statusCode !== 404) {
                    errorType = BlogErrorType.internal;
                }

                return {
                    state: {
                        ...state,
                        // only set the has posts flag when we know that no blog exists
                        blogHasPosts: errorType ? null : false,
                        errorType,
                        isBlogLoading: false
                    },
                    scope: {
                        ...scope,
                        waitingForBlogResponse: false
                    },
                    updateReason: GET_BLOG_DETAILS_FAILURE
                };
            }
        }
    ]
});
