/* @flow */

const { FuncProxy } = require('./FuncProxy.js');

/*** Main ***/

const BagProxy = (target/*: Object */, Func/*: Object */ = {}) => FuncProxy(target, {
    ...Func,
    get(k/*: string | string[] */)/*: any */ {
        let res = { ...target };
        const keys = Array.isArray(k) ? k : [k];

        for (const key of keys) {
            if (res[key] === undefined) {
                throw new UndefinedBagKeyError(k);
            }
            res = res[key];
        }

        return res;
    },

    getOptional(k/*: string */, defaultValue/*: any */ = null)/*: any */ {
        try {
            return this.get(k);
        } catch (e) {
            if (e instanceof UndefinedBagKeyError) {
                return defaultValue;
            }
            throw e;
        }
    },
});

/*** Lib ***/

class UndefinedBagKeyError extends Error {
    constructor(key/*: string | string[] */) {
        const
            printKey = Array.isArray(key) ? key.join('->') : key,
            message = `'${printKey}' key is undefined`;

        super(message);
        this.name = this.constructor.name;
        if (typeof Error.captureStackTrace === 'function') {
            Error.captureStackTrace(this, this.constructor);
        } else {
            this.stack = (new Error(message)).stack;
        }
    }
}

module.exports = { BagProxy, UndefinedBagKeyError };
