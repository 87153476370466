/* eslint-disable max-len */
import React from "react";
import cx from "classnames";
import styles from "./styles.css";
import { OnboardingTheming } from "./OnboardingTheming/index";
import { RightSideTemplatePreviewView } from "./RightSideTemplatePreviewView";
import {
    CHANGE_COLOR_PICKER_THEME_METHOD,
    CHANGE_SELECTED_COLOR_PALETTE,
    CANCEL_COLOR_PICKER_CUSTOM_THEME_COLOR,
    ONBOARDING_THEMING_BACK_BUTTON_PRESSED,
    CHANGE_COLOR_PICKER_CUSTOM_THEME_COLOR,
    ONBOARDING_TEMPLATE_SELECTED
} from "../actionTypes";
import { CHANGE_THEMING_TEMPLATE_PREVIEW_MODE } from "../../TemplateSelector_DEPRECATED/actionTypes";
import stepStyles from "./PreviewStep.css";

export type PreviewStepProps = any;
export const PreviewStep = (props: any) => {
    const {
        dispatch,
        selectedColorPickerTheme,
        selectedColorPickerPalette,
        design,
        isNormalSubscription,
        themePreviewDataForOnboarding,
        themingTemplatePreviewMode,
        changeThemePreviewPage,
        generalData,
        previewTemplate,
        autoColorMode,
        uiLocale
    } = props;
    return (
        <div className={styles.contentContainer}>
            <div className={cx(styles.leftContentContainer, stepStyles.leftContainerColorPicker)}>
                {autoColorMode ? (
                    <OnboardingTheming
                        dispatch={dispatch}
                        selectedColorPickerTheme={selectedColorPickerTheme}
                        selectedColorPickerPalette={selectedColorPickerPalette}
                        changeColorPickerThemeMethodActionType={CHANGE_COLOR_PICKER_THEME_METHOD}
                        onboardingThemingFooterCustomMctaHandler={CHANGE_COLOR_PICKER_THEME_METHOD}
                        onboardingThemingFooterDefaultMctaHandler={ONBOARDING_TEMPLATE_SELECTED}
                        onboardingThemingFooterCustomSctaHandler={CANCEL_COLOR_PICKER_CUSTOM_THEME_COLOR}
                        onboardingThemingFooterDefaultSctaHandler={ONBOARDING_THEMING_BACK_BUTTON_PRESSED}
                        changeSelectedColorPaletteActionType={CHANGE_SELECTED_COLOR_PALETTE}
                        changeColorPickerCustomThemeColorActionType={CHANGE_COLOR_PICKER_CUSTOM_THEME_COLOR}
                        viewType={themingTemplatePreviewMode}
                        changePreviewActionType={CHANGE_THEMING_TEMPLATE_PREVIEW_MODE}
                    />
                ) : (
                    previewTemplate(design, isNormalSubscription)
                )}
            </div>
            <RightSideTemplatePreviewView
                themePreviewDataForOnboarding={themePreviewDataForOnboarding}
                themingTemplatePreviewMode={themingTemplatePreviewMode}
                changeThemePreviewPage={changeThemePreviewPage}
                generalData={generalData}
                uiLocale={uiLocale}
            />
        </div>
    );
};
