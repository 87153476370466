import React from "react";
import { connect } from "react-redux";
import styles from "./styles.css";
import { Intl } from "../../../../view/intl/injectIntl";
import type { AppState, SubscriptionData } from "../../../../redux/modules/flowTypes";
import { makeEpicStateSelector } from "../../../../epics/makeEpic";
import webshopSubscriptionVAT from "../epics/subscription/valueActionType";
import ComponentsDisabledReasonMapVAT from "../../../Panel/epics/componentsDisabledReasonMap/valueActionType";
import { getDAL } from "../../../../../dal/index";

import { subscriptionDataVat } from "../../../App/epics/subscriptionData/valueActionType";
import { checkSubscriptionCompatibilityFromKind } from "../../../ComponentTierManager/utils";
import ComponentTypes from "../../../../view/oneweb/registry/ComponentTypes";
import localStorage from "../../../../utils/localStorage";

import { SingleProduct } from "./SingleProduct";
import { FeaturedProducts } from "./FeaturedProducts";
import { AllProducts } from "./AllProducts";
import { ManageShopButton } from "./ManageShopButton";
import { SetupProductButton } from "./SetupProductButton";
import { SetupProductNotification } from "./SetupProductNotification";
import { UpgradeSubscriptionNotification } from "./UpgradeSubscriptionNotification";
import LoadingIndicator from "../../../../view/common/LoadingIndicator/index";
import checkFreeUpgradeValueActionType from "../../../TopBar/epics/checkFreeUpgrade/valueActionType";
import expandedPanelStyles from "./expandedPanelContent.css";
import kind from "../kind";
import { WebshopIsDisabledInDemo } from "../errorMessagesOnAdd";
import { isEcommerceSubscription } from "../../../App/epics/subscriptionData/utils";
import { SubscriptionTypes } from "../../../../constants/app";
import { isOnlineShopCmpsNotAllowedBySubscriptionType } from "../../../TemplateSelector_DEPRECATED/view/utils";

type OnlineShopSetupApiResponse = {
    isWebshopSetupDone: boolean;
    isDigitalShopSetupDone: boolean;
    isPaymentRequired: boolean;
    isSIATCustomer: boolean;
};

type WebshopSubscriptionData = {
    onlineShopSetupApiResponse: OnlineShopSetupApiResponse;
    loading: boolean;
};

type Props = {
    intl: Intl;
    dispatch: Dispatch;
    subscriptionData: SubscriptionData;
    checkFreeUpgrade: Record<string, any>;
    componentsDisabledReasonMap: Record<string, any>;
    webshopSubscriptionData: WebshopSubscriptionData;
};

type SetupState = {
    hasOnlyDigitalShop: boolean;
    isWebshopSetupDone: boolean;
    webshopSetupFetchStatus: "intial" | "inprogress" | "completed";
    isProductAdded: boolean;
    isSIATCustomer: boolean;
};

const localStorageKey = "setupProductInserterNotificationStatus";

const isSetupProductNotificationDismissed = () => {
    let result = false;
    const status = localStorage.get(localStorageKey);
    if (status) {
        result = status.value === "dismissed";
    }
    return result;
};

const checkAndinitializeSetupProductNotificationStatus = () => {
    const status = localStorage.get(localStorageKey);
    if (!status) {
        localStorage.set(localStorageKey, { value: "initial" });
    }
};

const dismissSetupProductNotification = () => {
    localStorage.set(localStorageKey, { value: "dismissed" });
};

const isTrialUser = (subscriptionType) => {
    return (subscriptionType === SubscriptionTypes.DEMO || subscriptionType === SubscriptionTypes.TRIAL);
};

const OnlineShopExpandedContent = ({
    intl,
    dispatch,
    subscriptionData,
    checkFreeUpgrade,
    componentsDisabledReasonMap,
    webshopSubscriptionData: { onlineShopSetupApiResponse, loading }
}: Props) => {
    const [disabled, setDisabled] = React.useState(false);
    const [disabledUpgrade, setDisabledUpgrade] = React.useState(false);
    const [isNotificationDismissed, setIsNotificationDismissed] = React.useState(false);
    const isOnlineShopCmpsAllowed = !isOnlineShopCmpsNotAllowedBySubscriptionType();
    const webshopSetupInitialState: SetupState = {
        isWebshopSetupDone: false,
        hasOnlyDigitalShop: false,
        webshopSetupFetchStatus: "intial",
        isProductAdded: false,
        isSIATCustomer: false
    };
    const [shopSetupState, setShopSetupState] = React.useState(webshopSetupInitialState);

    const { subscriptionType } = subscriptionData;
    const isSubscriptionTypeCompatible = checkSubscriptionCompatibilityFromKind(
        ComponentTypes.WEBSHOP, subscriptionType
    ) || onlineShopSetupApiResponse?.isSIATCustomer;

    const updateWebshopSetupStatus = async () => {
        try {
            setShopSetupState({
                ...shopSetupState,
                webshopSetupFetchStatus: "inprogress"
            });

            if (!loading) {
                if (isSubscriptionTypeCompatible) {
                    const onboardStatus = await getDAL().fetchWebshopOnboardingStatus();
                    const isProductAdded = onboardStatus.body && onboardStatus.body.productStatus;
                    dispatch({
                        type: "UPDATE_WEBSHOP_PRODUCT_STATUS",
                        payload: {
                            isProductAdded
                        }
                    });
                    let shopSetupData:SetupState;
                    if (onlineShopSetupApiResponse?.isWebshopSetupDone) {
                        shopSetupData = {
                            ...shopSetupState,
                            isWebshopSetupDone: true,
                            isSIATCustomer: true,
                            webshopSetupFetchStatus: "completed",
                            hasOnlyDigitalShop: false
                        };
                        dispatch({
                            type: "UPDATE_SHOW_DIGITAL_PRODUCT_FLAG",
                            payload: {
                                hasOnlyDigitalShop: false
                            }
                        });
                        dispatch({
                            type: "UPDATE_WEBSHOP_ONBOARD_STATUS",
                            payload: {
                                isWebshopSetupDone: true
                            }
                        });
                    } else {
                        const hasOnlyDigitalShop = onlineShopSetupApiResponse.isDigitalShopSetupDone;
                        shopSetupData = {
                            ...shopSetupState,
                            isSIATCustomer: true,
                            webshopSetupFetchStatus: "completed",
                            hasOnlyDigitalShop
                        };
                        dispatch({
                            type: "UPDATE_SHOW_DIGITAL_PRODUCT_FLAG",
                            payload: {
                                hasOnlyDigitalShop
                            }
                        });
                    }

                    setShopSetupState({
                        ...shopSetupData,
                        isProductAdded
                    });
                } else {
                    setShopSetupState({
                        ...shopSetupState,
                        webshopSetupFetchStatus: "completed"
                    });
                }
            }
        } catch (err: any) {
            console.log('err: ', err);
            setShopSetupState({
                ...shopSetupState,
                webshopSetupFetchStatus: "completed"
            });
        }
    };

    React.useEffect(() => {
        checkAndinitializeSetupProductNotificationStatus();
        setIsNotificationDismissed(isSetupProductNotificationDismissed());
        updateWebshopSetupStatus();
        if (componentsDisabledReasonMap && componentsDisabledReasonMap.hasOwnProperty(kind)) {
            const disabledReason = componentsDisabledReasonMap[kind];
            if (disabledReason === WebshopIsDisabledInDemo) {
                if (isOnlineShopCmpsAllowed) {
                    setDisabled(false);
                    setDisabledUpgrade(true);
                } else {
                    setDisabled(true);
                    setDisabledUpgrade(true);
                }
            }
        }
    }, [subscriptionData, checkFreeUpgrade, componentsDisabledReasonMap, loading]);

    return shopSetupState.webshopSetupFetchStatus === "completed" ? (
        <div className={styles.container}>
            {!disabledUpgrade &&
                !isEcommerceSubscription(subscriptionType) &&
                <UpgradeSubscriptionNotification
                    dispatch={dispatch}
                    intl={intl}
                    checkFreeUpgrade={checkFreeUpgrade}
                    subscriptionData={subscriptionData}
                    isSiatAllowedFromShopApi={shopSetupState.isSIATCustomer}
                />}
            {!disabled &&
                isSubscriptionTypeCompatible &&
                !(shopSetupState.hasOnlyDigitalShop || shopSetupState.isWebshopSetupDone) &&
                !isNotificationDismissed && (
                <SetupProductNotification
                    dispatch={dispatch}
                    isTrialUser={isTrialUser(subscriptionType)}
                    dismissSetupProductNotification={() => {
                        dismissSetupProductNotification();
                        setIsNotificationDismissed(true);
                    }}
                />
            )}
            {!disabled &&
                    isSubscriptionTypeCompatible &&
                    !(shopSetupState.hasOnlyDigitalShop || shopSetupState.isWebshopSetupDone) &&
                    isNotificationDismissed && <SetupProductButton dispatch={dispatch} isTrialUser={isTrialUser(subscriptionType)} />}
            {isSubscriptionTypeCompatible && (shopSetupState.hasOnlyDigitalShop || shopSetupState.isWebshopSetupDone) && (
                <ManageShopButton dispatch={dispatch} isTrialUser={isTrialUser(subscriptionType)} />
            )}
            <AllProducts dispatch={dispatch} disabled={disabled} />
            {!disabled && (shopSetupState.isWebshopSetupDone || shopSetupState.isProductAdded) && (
                <FeaturedProducts dispatch={dispatch} />
            )}
            <SingleProduct
                intl={intl}
                dispatch={dispatch}
                hasOnlyDigitalShop={shopSetupState.hasOnlyDigitalShop}
                isProductAdded={shopSetupState.isProductAdded}
                disabled={disabled}
            />
        </div>
    ) : (
        <div className={expandedPanelStyles.loadingIndicator}>
            <LoadingIndicator />
        </div>
    );
};

export default connect((appState: AppState) => {
    return {
        webshopSubscriptionData: makeEpicStateSelector(webshopSubscriptionVAT)(appState),
        componentsDisabledReasonMap: makeEpicStateSelector(ComponentsDisabledReasonMapVAT)(appState),
        subscriptionData: makeEpicStateSelector(subscriptionDataVat)(appState),
        checkFreeUpgrade: makeEpicStateSelector(checkFreeUpgradeValueActionType)(appState)
    };
})(OnlineShopExpandedContent);
