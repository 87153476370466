import React from "react";
import { DemoLoginMessages } from '../../main/messages';
import styles from "./TrialEndedPage.css";
import Button from "../../../../../src/view/common/Button/Button";
import type { TrialEndedPropTypes } from "../../types";
import { demoTrialEndedSelectPlanAction } from "../../../actions";
import { signInSetTrialEndedData } from "../../signIn/actions";
import { isMobileDevice } from "../../validators";
import {
    loadSubscriptionMetadataAction,
} from "../../../../../src/components/App/actionCreators/loadSubscriptionData";
import { getTrialDomainName } from "../../../../../../server/shared/trial/utils/trialUtils.js";
import { init as initDAL } from '../../../../../dal/dalWithTrial';

const TrialEnded = ({
    intl,
    dispatch,
    userTrialEndedDetails,
    appConfig
}: TrialEndedPropTypes) => {
    const {
        trialEndedLabel,
        trialEndedMsg,
        trialProgressKeeperMsg,
        trialSelectPlanLabel
    } = DemoLoginMessages;
    const TrialEndedPage = () => {
        if (!userTrialEndedDetails) {
            // @ts-ignore
            // eslint-disable-next-line no-param-reassign
            userTrialEndedDetails = JSON.parse(window.localStorage.getItem('userTrialEndedDetails'));
            dispatch(signInSetTrialEndedData(userTrialEndedDetails));
        } else {
            window.localStorage.setItem('userTrialEndedDetails', JSON.stringify(userTrialEndedDetails));
        }
        const expiryFullDate = new Date(parseInt(userTrialEndedDetails.trialEndDate, 10));

        const partner = appConfig.partnerName;
        const trialExampleDomain = getTrialDomainName(partner),
            trialUserIdentityId = userTrialEndedDetails.trialId;
        initDAL(trialExampleDomain, trialUserIdentityId);

        React.useEffect(() => {
            dispatch(loadSubscriptionMetadataAction());
        }, []);

        const screenShotUrl = isMobileDevice() && userTrialEndedDetails.screenshotMobileUrl ?
            userTrialEndedDetails.screenshotMobileUrl :
            userTrialEndedDetails.screenshotUrl;

        const dynamicScreenshotStyle = {
            background: `url(${screenShotUrl}) center top no-repeat`,
        };

        return (
            [
                <div>
                    <div className={styles.headerLabel}>
                        {intl.msgJoint(trialEndedLabel)}
                    </div>
                    <div className={styles.headerMsg}>
                        <div>
                            {intl.msgJoint(trialEndedMsg)}
                        </div>
                    </div>
                </div>,
                <div>
                    <div className={styles.websitePreviewContainer}>
                        <span className={styles.websitePreviewIconHeader} />
                        {
                            screenShotUrl &&
                            <span className={styles.websitePreviewIcon} style={dynamicScreenshotStyle} />
                        }
                        {!screenShotUrl && <span className={styles.websitePreviewIcon} />}
                    </div>
                </div>,
                <div>
                    { userTrialEndedDetails && <div className={styles.trialProgressKeeperMsgContainer}>
                        <span>
                            {intl.msgJoint(trialProgressKeeperMsg)}
                        </span>
                        <span className={styles.expiryDateContainer}>
                            {intl.date(expiryFullDate, 'LL')}.
                        </span>

                    </div> }
                    <br />
                    <Button
                        onClick={() => {
                            dispatch(demoTrialEndedSelectPlanAction());
                        }}
                        className={styles.selectPlanButton}
                    >
                        {intl.msgJoint(trialSelectPlanLabel)}
                    </Button>
                </div>
            ]
        );
    };

    return (
        <div>
            {TrialEndedPage()}
        </div>
    );
};
export {
    TrialEnded
};
