import {
    makeCombineReducer,
    makeDefaultStateReducers,
    makeComponentBaseReducers
} from "../../../../../redux/makeReducer/index";
import { WEBSHOP_FOOTER_CMP_COLOR_CHANGED, WEBSHOP_FOOTER_CMP_COLOR_CHANGED_AUTO_COLOR } from "../../actionTypes";
import kind from '../kind';
import webShopFooterCmpColorReducer from "./webShopFooterCmpColorReducer";
import webShopFooterCmpThemeColorReducer from "./webShopFooterCmpThemeColorReducer";
import { getIntl } from "../../../../../view/intl/injectIntl";
import { WORKSPACE_READY } from "../../../../Workspace/actionTypes";

export default makeCombineReducer({
    combineReducers: {
        ...makeComponentBaseReducers(kind),
        ...makeDefaultStateReducers({
            width: 300,
            height: 40,
            mobileHide: false,
            mobileDown: false,
        })
    },
    handleActions: {
        [WEBSHOP_FOOTER_CMP_COLOR_CHANGED_AUTO_COLOR]: webShopFooterCmpThemeColorReducer,
        [WEBSHOP_FOOTER_CMP_COLOR_CHANGED]: webShopFooterCmpColorReducer,
        [WORKSPACE_READY]: (state) => {
            return {
                ...state,
                privacyPolicyText: (state.privacyPolicyText ||
                    getIntl()?.msgJoint("msg: demo.signup.termsAndCondition.privacyPolicy {Privacy policy}")) || '',
            };
        }
    }
});
