import React from 'react';
import cx from "classnames";
import { getWbtgenTrialAbtAttrs } from "../../../../utils/getWbtgenTrialAbtAttrs";
import styles from './Signup.css';
import demoPageStyles from "../../main/demoLoginPage/DemoLoginPage.css";
import Msg from "../../../../../src/view/intl/Msg";
import { PasswordChecker } from '../../main/PasswordChecker';
import InputField from "../../../../../src/view/common/Input/InputField";
import { DemoLoginMessages } from "../../main/messages";
import { isPasswordValid, isInvalidEmail, validateNoSpaces, isCompactScreen } from '../../validators';
import { CheckBoxWithLabel } from "../../../../../src/view/common/CheckBox/index";
import Button from "../../../../../src/view/common/Button/Button";
import {
    demoSignUpAction,
    updateEmailValue,
    updatePasswordValue,
    toggleShowPassword,
    enableTermsToggle,
    showSignInPage,
    cleanUpAllErrors,
    showInvalidEmailError,
    showTermsNotCheckedError,
    cleanUpSignupScreen,
    showInvalidPasswordError,
    signUpLoading,
    demoGetClientIpRequestAction,
    mailCampaignAgreementToggle
} from "../actions";
import VerticalSpacer from "../../../../../src/view/common/VerticalSpacer";
import type { SignupPagePropTypes } from "../../types";
import { GoogleInvisibleReCaptchaView } from "../../../../../src/components/GoogleRecaptcha/view/index";
import { GoogleReCaptchaClientKey } from "../../../../../src/components/GoogleRecaptcha/constants";
import { getCookie } from "../../../../../src/services/cookie";
import { ONE_LANG_COOKIE_KEY } from "../../../../../src/constants/app";
import { getWindowOrigin } from "../../../../../src/utils/windowHelper";
import { TermsForTrialTip } from "../../../tip/TermsForTrialTip";
import { normalizeEmail } from "../../../../../../server/shared/utils/normalizeEmail.js";
import { TrialSocialSignup } from "../../socialSignup/TrialSocialSignup";
import { sendDemoLoginGTMEvent } from "../../../gtm/actions";
import { DEMO_SIGNUP_LANDING_PAGE_CATEGORY, DEMO_LOGIN_GTM_EVENT, DEMO_SIGNUP_STARTED_SIGNUP } from "../../../gtm/events";
import { TrialLoginGTMAction, TrialLoginGTMCategory } from "../../../gtm/GTMAction";
import { getTrialConfig } from "../../../../../../trial/services/getTrialConfig.js";
import TermsnConditionsUrlLink from '../../../dataPrivacyLinks/TermsnConditionsUrlLink';
import PrivacyPolicyUrlLink from '../../../dataPrivacyLinks/PrivacyPolicyUrlLink';
import { partnerIsCheckdomain } from '../../../../../../server/shared/partners/PartnerName.js';

const Signup = ({
    intl,
    dispatch,
    error,
    loading,
    invalidEmailErrorMessage,
    termsNotCheckedError,
    email,
    password,
    invalidCaptchaError,
    termsAgreement,
    mailCampaignAgreement,
    showPasswordEnabled,
    userExistsError,
    invalidPasswordErrorMessage,
    currentLanguage,
    clientIPAddress,
    appConfig,
}: SignupPagePropTypes) => {
    if (!clientIPAddress) {
        dispatch(demoGetClientIpRequestAction());
    }

    const
        recaptchaRef = React.createRef<any>(),
        inputRef = React.createRef<HTMLInputElement>(),
        signUpLink = (
            <a
                onClick={() => {
                    dispatch(cleanUpSignupScreen());
                    dispatch(showSignInPage());
                }}
                className={styles.loginActionsLink}
            ><Msg k="common.logIn">Log in</Msg></a>
        ),
        isErrorFree = () => {
            let emailValidity = !isInvalidEmail(email);
            let passwordValidity = isPasswordValid(password);
            let isErrorPresent = false;
            if (!emailValidity) {
                dispatch(showInvalidEmailError());
                isErrorPresent = true;
            }
            if (!passwordValidity) {
                dispatch(showInvalidPasswordError());
                isErrorPresent = true;
            }
            if (!termsAgreement) {
                dispatch(showTermsNotCheckedError());
                isErrorPresent = true;
            }
            return !isErrorPresent;
        },
        initiateSignup = () => {
            if (!loading && isErrorFree() && inputRef.current) {
                inputRef.current.disabled = true;

                recaptchaRef.current.reset();
                setTimeout(() => {
                    if (inputRef.current) {
                        inputRef.current.disabled = false;
                    }
                }, 300);

                recaptchaRef.current.execute();
                dispatch(sendDemoLoginGTMEvent({
                    eventCategory: TrialLoginGTMCategory.CLICK,
                    eventLabel: DEMO_SIGNUP_STARTED_SIGNUP,
                    eventAction: TrialLoginGTMAction.CLICKED_START_FREE_TRIAL,
                    event: DEMO_LOGIN_GTM_EVENT
                }));
            }
        },
        getVerticalSpacerValues = () => {
            let compactScreen = isCompactScreen();
            let
                VERTICAL_SPACE_BEFORE_EMAIL = compactScreen ? 20 : 49,
                VERTICAL_SPACE_AFTER_PASSWORD_CHECKER = 30;

            return {
                VERTICAL_SPACE_BEFORE_EMAIL,
                VERTICAL_SPACE_AFTER_PASSWORD_CHECKER
            };
        };
    const { VERTICAL_SPACE_BEFORE_EMAIL, VERTICAL_SPACE_AFTER_PASSWORD_CHECKER } = getVerticalSpacerValues();

    const {
            signUpHeaderLabel,
            emailLabel,
            passwordLabel,
            invalidEmailError,
            trialSignUpButtonLabel,
            showLabel,
            hideLabel,
            termsConditionNotCheckedError,
            userAlreadyExistsError,
            customError,
            invalidCaptchaErrorMessage,
            mailCampaignAgreementMsg
        } = DemoLoginMessages,
        trialConfig = getTrialConfig(),
        partnerName = trialConfig.label,
        termsForTrial = (
            <a target="_blank" className={styles.termsPolicyLink}>
                <TermsForTrialTip intl={intl} />
            </a>
        ),
        termsOfUse = (
            <TermsnConditionsUrlLink className={styles.termsPolicyLink} isTermsOfUse />
        ),
        privacyPolicy = (
            <PrivacyPolicyUrlLink className={styles.termsPolicyLink} isPrivacyPolicy />
        ),
        termsAndConditionLabel = partnerIsCheckdomain(partnerName.toLowerCase()) ?
            <Msg
                k="demo.signup.termsAndConditionWithoutPrivacyPolicy.msg"
                params={{ partnerName, termsForTrial, termsOfUse }}
            >
                {`I agree to {partnerName}\'s {termsForTrial} and {termsOfUse}.`}
            </Msg> :
            <Msg
                k="demo.signup.termsAndCondition.msg"
                params={{ partnerName, termsForTrial, termsOfUse, privacyPolicy }}
            >
                {`I agree to {partnerName}\'s {termsForTrial}, {termsOfUse} and {privacyPolicy}.`}
            </Msg>;

    React.useEffect(() => {
        dispatch(sendDemoLoginGTMEvent({
            eventCategory: TrialLoginGTMCategory.VIEW,
            eventAction: TrialLoginGTMAction.VIEWED_SIGNUP_PAGE,
            eventLabel: DEMO_SIGNUP_LANDING_PAGE_CATEGORY,
            event: DEMO_LOGIN_GTM_EVENT
        }));
    }, []);

    return (
        <div>
            <div>
                <div className={styles.headerLabel}>
                    {intl.msgJoint(signUpHeaderLabel)}
                </div>
                <div className={styles.headerMsg}>
                    <Msg k="demo.login.signup.header.msg">
                        Build your website now - choose a domain later.
                    </Msg>
                    <Msg k="demo.login.signup.header.msg.question" params={{ signUpLink }}>
                        {`Already have an account? {signUpLink}`}
                    </Msg>
                </div>
            </div>
            <VerticalSpacer y={VERTICAL_SPACE_BEFORE_EMAIL} />
            <div>
                <div className={styles.emailContainer}>
                    <div className={styles.inputLabel}>
                        {intl.msgJoint(emailLabel)}
                    </div>
                    <InputField
                        autoFocus
                        inputRef={inputRef}
                        value={email}
                        onChange={(updatedEmailValue) => {
                            dispatch(cleanUpAllErrors());
                            dispatch(updateEmailValue(updatedEmailValue));
                        }}
                        className={styles.inputField}
                        isInvalid={(email && isInvalidEmail(email)) || invalidEmailErrorMessage || userExistsError}

                    />
                    {(invalidEmailErrorMessage ||
                        (email && isInvalidEmail(email)))
                    && <span className={styles.invalidEmailError}>
                        {intl.msgJoint(invalidEmailError)}
                    </span>}
                    {
                        (error || userExistsError) &&
                        <span className={styles.invalidEmailError}>
                            {userExistsError && intl.msgJoint(userAlreadyExistsError)}
                            {error && intl.msgJoint(customError)}
                        </span>
                    }
                    {
                        (invalidCaptchaError) &&
                            <span className={styles.invalidCaptchaError}>
                                {invalidCaptchaError && intl.msgJoint(invalidCaptchaErrorMessage)}
                            </span>
                    }
                </div>
                <div className={styles.passwordContainer}>
                    <div className={styles.passwordLabelContainer}>
                        <div className={styles.inputLabel}>
                            {intl.msgJoint(passwordLabel)}
                        </div>
                        <div
                            className={styles.showLabel}
                            onClick={() => {
                                dispatch(cleanUpAllErrors());
                                dispatch(toggleShowPassword());
                            }}
                        >
                            {/* eslint-disable-next-line max-len */}
                            <span className={cx({ [styles.hideIcon]: showPasswordEnabled, [styles.showIcon]: !showPasswordEnabled })} />
                            {intl.msgJoint(showPasswordEnabled ? hideLabel : showLabel)}
                        </div>
                    </div>

                    <InputField
                        type={showPasswordEnabled ? 'text' : 'password'}
                        value={password}
                        onEnterPress={initiateSignup}
                        onChange={(updatedPasswordValue) => {
                            dispatch(cleanUpAllErrors());
                            dispatch(updatePasswordValue(updatedPasswordValue));
                        }}
                        className={styles.inputField}
                        isInvalid={invalidPasswordErrorMessage || !validateNoSpaces(password)}
                    />
                </div>
            </div>
            <PasswordChecker
                intl={intl}
                password={password}
            />

            <TrialSocialSignup
                appConfig={appConfig}
                dispatch={dispatch}
                mailCampaignAgreed={mailCampaignAgreement ? 'true' : 'false'}
            />

            <VerticalSpacer y={VERTICAL_SPACE_AFTER_PASSWORD_CHECKER} />

            <div>
                <div className={styles.signUpButtonFooterContainer}>
                    <CheckBoxWithLabel
                        checkboxProps={{
                            tabIndex: 0
                        }}
                        label={intl.msgJoint(
                            [
                                mailCampaignAgreementMsg,
                                {
                                    partnerInfo: partnerName.includes("one.com") ? intl.msgJoint('msg: common.onecom.group {one.com Group AB}') : partnerName    //eslint-disable-line
                                }
                            ]
                        )}
                        isHtmlLabel
                        onClick={() => {
                            dispatch(mailCampaignAgreementToggle());
                        }}
                        isChecked={mailCampaignAgreement}
                        disabled={false}
                        containerClassName={styles.mailCampaignContainer}
                        className={styles.mailCampaignLabel}
                    />
                    { termsNotCheckedError &&
                    <span className={styles.invalidEmailError}>
                        {intl.msgJoint(termsConditionNotCheckedError)}
                    </span>}
                    <CheckBoxWithLabel
                        checkboxProps={{
                            tabIndex: 0
                        }}
                        label={termsAndConditionLabel}
                        isHtmlLabel
                        onClick={() => {
                            dispatch(cleanUpAllErrors());
                            dispatch(enableTermsToggle());
                        }}
                        isChecked={termsAgreement}
                        disabled={false}
                        containerClassName={styles.termsPolicyContainer}
                    />
                    <GoogleInvisibleReCaptchaView
                        currentLanguage={currentLanguage}
                        sitekey={GoogleReCaptchaClientKey}
                        recaptchaRef={recaptchaRef}
                        additionalProps={{ email, password }}
                        onChangeFunction={({ response, email, password }) => {
                            if (response) {
                                dispatch(signUpLoading());
                                dispatch(demoSignUpAction({
                                    email: normalizeEmail(email),
                                    password,
                                    captchaResponse: response,
                                    locale: getCookie(ONE_LANG_COOKIE_KEY),
                                    currentHostName: getWindowOrigin(window),
                                    clientIPAddress,
                                    abtAttrs: getWbtgenTrialAbtAttrs(),
                                    mailCampaignAgreed: mailCampaignAgreement ? 'true' : 'false'
                                }));
                            }
                        }}
                    />
                    <Button
                        onClick={initiateSignup}
                        className={cx(styles.signUpButton, {
                            [styles.loadingButton]: loading
                        })}
                    >
                        {!loading && intl.msgJoint(trialSignUpButtonLabel)}
                        {loading && <div className={demoPageStyles.loader} />}
                    </Button>
                </div>
            </div>
        </div>
    );
};

export {
    Signup
};
