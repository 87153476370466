/* global $ */

import * as R from 'ramda';
import * as React from 'react';
import cx from 'classnames';
import styles from "./hiddenComponent.css";
import panelStyles from "./styles.css";
import Icons from "../../../../view/Icons/index";
import componentsRegistry from "../../../../view/oneweb/registry/index";
import type { AnyComponent } from "../../../../redux/modules/children/workspace/flowTypes";
import type { Intl } from "../../../../view/intl";
import { injectIntl } from "../../../../view/intl";
import { ComponentNames } from "../../../oneweb/constants";
import { MOBILE_EDITOR_UN_HIDE_COMPONENTS, MOBILE_EDITOR_MOUSE_ACTION_HIDDEN_PANEL } from '../../actionTypes';
import { HiddenPanelMouseOverDelay, cmpTypes } from '../../constants';
import getCmpTypeById from "../../getCmpTypeById";
import { ADDRESS_KIND } from "../../../oneweb/TextLike/Address/kind";
import { PHONE_KIND } from "../../../oneweb/TextLike/Phone/kind";
import { EMAIL_KIND } from "../../../oneweb/TextLike/Email/kind";
import { SVG_KIND } from "../../../oneweb/Svg/kind";
import FACEBOOKPAGE from "../../../oneweb/FacebookFeedGallery/kind";
import CONTACTFORM from "../../../oneweb/ContactForm/kind";
import { OPENING_HOURS_KIND } from "../../../oneweb/OpeningHours/kind";
import { BOOKINGS, VIDEO_FILE } from "../../../oneweb/componentKinds";

type Props = {
    cmpProps: AnyComponent,
    componentsDependencies: Record<string, any>,
    intl: Intl,
    dispatch: Dispatch,
    isLatestHiddenCmp: boolean
};

const KindToIconMap: Record<string, any> = {
    [ADDRESS_KIND]: 'ADDRESS_INSERTER_ICON',
    [PHONE_KIND]: 'PHONE_INSERTER_ICON',
    [EMAIL_KIND]: 'EMAIL_INSERTER_ICON',
    [SVG_KIND]: 'IMAGE',
    [CONTACTFORM]: 'CONTACT_FORM',
    [FACEBOOKPAGE]: 'FACEBOOK_SMALL_GRAY',
    [OPENING_HOURS_KIND]: 'OPENING_HOURS_ICON',
    [VIDEO_FILE]: 'VIDEO',
    [BOOKINGS]: 'BOOKINGS_ICON',
};

const getComponentData = (props: AnyComponent, componentsDependencies: any, intl) => {
    let Icon;

    if (getCmpTypeById(props.id) === cmpTypes.group) {
        Icon = Icons.GROUP_ICON;
        return {
            icon: <Icon />,
            content: intl.msgJoint('msg: mve.group.title {Group}')
        };
    }

    const kind = props.kind,
        icon = KindToIconMap[kind] || kind;
    Icon = Icons[icon];

    const fn = R.path([kind, 'mobileEditorConfig', 'getHiddenElementData'], componentsRegistry);

    let data: any = {};
    if (fn) {
        data = fn(props, componentsDependencies[kind]);
    }

    let content = intl.msgJoint(ComponentNames[kind]);
    content += data.content ? `: ${data.content}` : '';

    return {
        icon: data.icon || <Icon />,
        content,
    };
};

export default injectIntl(class HiddenComponent extends React.PureComponent<Props> {
    ref;
    timeoutOnMouseEnter: ReturnType<typeof setTimeout> | undefined;
    isCmpUnHiddenOnHover: boolean = false;
    constructor(props) {
        super(props);
        this.ref = React.createRef();
    }

    clearMouseEnterTimeout() {
        if (this.timeoutOnMouseEnter) {
            clearTimeout(this.timeoutOnMouseEnter);
        }
    }

    onMouseEnter = () => {
        const { dispatch, cmpProps } = this.props;
        this.clearMouseEnterTimeout();
        this.isCmpUnHiddenOnHover = false;
        this.timeoutOnMouseEnter = setTimeout(() => {
            dispatch({
                type: MOBILE_EDITOR_MOUSE_ACTION_HIDDEN_PANEL,
                payload: {
                    componentId: cmpProps.id,
                    hover: true
                }
            });
            this.isCmpUnHiddenOnHover = true;
            this.clearMouseEnterTimeout();
        }, HiddenPanelMouseOverDelay);
    };

    onMouseLeave = () => {
        const { dispatch, cmpProps } = this.props;
        this.clearMouseEnterTimeout();
        if (this.isCmpUnHiddenOnHover) {
            this.isCmpUnHiddenOnHover = false;
            dispatch({
                type: MOBILE_EDITOR_MOUSE_ACTION_HIDDEN_PANEL,
                payload: {
                    componentId: cmpProps.id,
                    hover: false
                }
            });
        }
    };

    onMouseClick = () => {
        const { dispatch, cmpProps } = this.props;
        this.isCmpUnHiddenOnHover = false;
        dispatch({
            type: MOBILE_EDITOR_UN_HIDE_COMPONENTS,
            payload: {
                componentId: cmpProps.id,
                unhide: true
            }
        });
    };

    componentDidMount() {
        const { isLatestHiddenCmp } = this.props;
        if (isLatestHiddenCmp) {
            const parent = $('.' + panelStyles.panelBody);
            parent.animate({
                scrollTop: parent.scrollTop()! + $(this.ref.current).offset()!.top - parent.offset()!.top
            });
        }
    }

    componentWillUnmount() {
        this.onMouseLeave();
    }

    render() {
        const { cmpProps, intl, isLatestHiddenCmp, componentsDependencies } = this.props,
            { icon, content } = getComponentData(cmpProps, componentsDependencies, intl);

        return (
            <div
                className={cx(styles.component, { [styles.latestHiddenCmp]: isLatestHiddenCmp })}
                onMouseEnter={this.onMouseEnter}
                onMouseLeave={this.onMouseLeave}
                data-hidden-cmp-id={cmpProps.id}
                ref={this.ref}
            >
                <div className={styles.kindIcon}>
                    {icon}
                </div>
                <div className={styles.text}>
                    <span>{content}</span>
                </div>
                <span
                    data-title={intl.msgJoint("msg: mve.hiddenElements.showElement {Show element}")}
                    className={styles.plusIcon}
                    onClick={this.onMouseClick}
                />
            </div>
        );
    }
});

export {
    getComponentData
};
