import React from 'react';
import cx from 'classnames';
import { connect } from "react-redux";
import styles from './FileChooser.css';
import type { FileChooserPropTypes } from "./flowTypes";
import type { AppState } from "../../../redux/modules/flowTypes";
import { ViewModes } from "./constants";
import Header from './FileChooserHeader/index';
import Body from './FileChooserBody/index';
import fcInit from "../../../redux/modules/children/fileChooser/actionCreators/fcInit";
import { FcContentTypes } from "../../../redux/modules/children/fileChooser/FcContentTypes";
import { FileManagerMode } from "../../../components/FileManager/constants";

class FileChooser extends React.Component<FileChooserPropTypes> {
    static defaultProps = {
        viewMode: ViewModes.COMBINED,
        contentTypes: FcContentTypes.ANY
    };

    UNSAFE_componentWillMount() {
        const
            {
                isMultiSelect,
                maxMultiSelectValidation,
                contentTypes = '',
                stateActiveViewMode,
                activeViewMode,
                preSelection,
                isInitialized,
                mode = FileManagerMode.FILE_MANAGER,
                headlessMode,
                forwardToComponent,
                onSaveAction,
            } = this.props;

        const isVideoFileDialog = this.props.contentTypes && this.props.contentTypes[0]
            && this.props.contentTypes[0].startsWith(FcContentTypes.VIDEO);

        if (isInitialized && !isVideoFileDialog) {
            return;
        }

        // @ts-ignore
        this.props.initialize({
            isMultiSelect,
            maxMultiSelectValidation,
            contentTypes,
            activeViewMode: stateActiveViewMode || activeViewMode || ViewModes.LIST,
            preSelection,
            mode,
            headlessMode,
            forwardToComponent,
            onSaveAction,
        });
    }

    render() {
        const {
            // public api
            width,
            listTableHeight,
            title,
            showPreview,
            onResourceDoubleClick,
            viewMode,
            resourcesLoadProgress,
            hideUploadPane,
            gridHeight,
            gridGutter,
            gridRowSize,
            isFreeOneComVideoFileChooser,
            theme = {}
        } = this.props;

        return (
            <div className={cx(styles.container, theme.container)}>
                <Header
                    title={title}
                    // @ts-ignore
                    viewMode={viewMode}
                    isFreeOneComVideoFileChooser={isFreeOneComVideoFileChooser}
                    hideUploadPane={hideUploadPane}
                    className={theme.headerContainer}
                    breadCrumbsClassName={theme.bredCrumbsPaneContainer}
                    uploadComboboxClassName={theme.uploadComboboxClassName}
                    headlessMode={this.props.headlessMode}
                />
                <Body
                    width={width}
                    gridHeight={gridHeight}
                    isFreeOneComVideoFileChooser={isFreeOneComVideoFileChooser}
                    listTableHeight={listTableHeight}
                    // @ts-ignore
                    viewMode={viewMode}
                    showPreview={showPreview}
                    onResourceDoubleClick={onResourceDoubleClick}
                    // @ts-ignore
                    isLoading={resourcesLoadProgress}
                    gridGutter={gridGutter}
                    gridRowSize={gridRowSize}
                    gridContainerClassName={theme.gridContainer}
                    emptyFolderArrowClassName={theme.emptyFolderArrow}
                />
            </div>
        );
    }
}

const mapStateToProps = ({ fileChooser }: AppState) => ({
    isInitialized: fileChooser.isInitialized,
    stateActiveViewMode: fileChooser.activeViewMode,
    resourcesLoadProgress: fileChooser.resourcesLoadProgress,
    appConfig: fileChooser.appConfig
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    initialize: initConfig => dispatch(fcInit(initConfig))
});

export default connect(mapStateToProps, mapDispatchToProps)(FileChooser);
