import BlogKind from "../Blog/BlogComponent/kind";
import BookingsKind from "../Bookings/kind";

const MIN_SECTION_HEIGHT = 50;
const HEADER_SECTION = 'HEADER_SECTION',
    FOOTER_SECTION = 'FOOTER_SECTION',
    SectionTypes = {
        HeaderSection: HEADER_SECTION,
        FooterSection: FOOTER_SECTION
    };

const SectionTypeNames = {
    [HEADER_SECTION]: 'msg: common.section.type.header {Header}',
    [FOOTER_SECTION]: 'msg: common.section.type.footer {Footer}',
};

const NON_DELETABLE_COMPONENTS: Array<string> = [BookingsKind, BlogKind];
const NON_DUPLICATABLE_COMPONENTS: Array<string> = [BookingsKind, BlogKind];
const CLIPBOARD_RESTRICTED_COMPONENTS: Array<string> = [BookingsKind, BlogKind];

export {
    SectionTypes,
    SectionTypeNames,
    MIN_SECTION_HEIGHT,
    NON_DELETABLE_COMPONENTS,
    NON_DUPLICATABLE_COMPONENTS,
    CLIPBOARD_RESTRICTED_COMPONENTS
};
