import type { ApiAction } from '../../../../redux/middleware/api/flowTypes';
import CALL_API from '../../../../redux/middleware/api/CALL_API';
import * as actionTypes from '../actionTypes';
import { dialogId } from '../../../Backup/dialogs/loadError';
import { RAW_ASSET_ENDPOINT } from '../../../../../../server/shared/constants.js';

export default function (url: string, successAction: string, extraPayload?: any): ApiAction {
    return {
        [CALL_API]: {
            types: [
                actionTypes.SVG_RAW_ASSET_REQUEST,
                successAction,
                actionTypes.SVG_RAW_ASSET_FAILURE,
            ],
            endpoint: RAW_ASSET_ENDPOINT,
            endpointParams: url,
            errorHandler: {
                dialog: dialogId,
            },
            extraPayload,
        },
    };
}
