/* eslint-disable max-len */

import makeEpic from '../../../epics/makeEpic';
import valueActionType from "./valueActionType";
import * as Actions from '../actionTypes';
import type { OwnerEmailConfig } from "../flowTypes";
import getOwnerEmails from '../actionCreators/getOwnerEmailIds';
import { openWindowAction } from "../../../redux/middleware/window/actionCreators";
import { AppConfigSelector } from "../../App/epics/appConfig/selectors";
import { readWsbCustomerProfileCookiePayload } from '../../../utils/wsbCustomerProfile/wsbCustomerProfileUtils';
import { partnerIsOnecom } from "../../../../../server/shared/partners/PartnerName.js";

const
    defaultState: OwnerEmailConfig = { emailIdsLoading: false, emails: [] },
    makeGetOwnerEmailsUpdater = actionType => ({
        conditions: [actionType],
        reducer: ({ state }) => ({ state, actionToDispatch: getOwnerEmails() })
    });

export default makeEpic({
    defaultState,
    valueActionType,
    updaters: [
        makeGetOwnerEmailsUpdater(Actions.OWNER_EMAILS_DROPDOWN_ONLOAD),
        makeGetOwnerEmailsUpdater(Actions.OWNER_EMAILS_DROPDOWN_ONOPEN),
        {
            conditions: [Actions.OWNER_EMAIL_IDS_FETCH_REQUEST],
            reducer: ({ state }) => ({ state: { ...state, emailIdsLoading: true } })
        },
        {
            conditions: [Actions.OWNER_EMAIL_IDS_FETCH_SUCCESS],
            reducer: ({ values: [response] }) => ({ state: { emailIdsLoading: false, emails: response.email } })
        },
        {
            conditions: [Actions.OWNER_EMAIL_IDS_FETCH_FAILURE],
            reducer: () => ({ state: { emailIdsLoading: false, emails: [] } })
        },
        {
            conditions: [
                AppConfigSelector("partnerName"),
                AppConfigSelector('oneWeb.contactForm.createEmailUrl'),
                Actions.OWNER_EMAILS_OPEN_CREATE_EMAIL_URL
            ],
            reducer: ({ state, values: [partnerName, createEmailUrl] }) => {
                let createEmailLink = createEmailUrl;

                if (partnerIsOnecom(partnerName) === false) {
                    const customerProfileData = readWsbCustomerProfileCookiePayload(),
                        { profileId } = customerProfileData || {};

                    createEmailLink = createEmailLink.replace("{profileId}", profileId);
                }

                return {
                    state,
                    actionToDispatch: openWindowAction(createEmailLink)
                };
            }
        }
    ]
});
