import * as React from "react";
import cx from "classnames";
import { SketchPicker } from "react-color";
import positionCalculator, { Positions, CollisionAlignment } from "../../../utils/Position";
import * as parseColor from "../utils/parseColor";
import { OnlyMouseUp } from '../../../view/common/NoMouseEventsPropagation';
import * as actionTypes from "../actionTypes";
import type { ColorPickerColor } from "../../../mappers/flowTypes";
import type { ColorPickerPropTypes, ColorPickerKindType, InvalidThemeColorType } from "../flowTypes";
import type { ReactElementRef } from '../../../globalTypes';
import styles from "./ColorPicker.css";
import { INPUT_FOCUSED, INPUT_BLUR } from "../../App/actionTypes";
import { SuggestedColors } from "./SuggestedColors";
import { THEME_COLORS, ALL_BACKGROUND_THEMES } from "../../ThemeGlobalData/constants";
import HorizontalSpacer from '../../../view/common/HorizontalSpacer';
import VerticalSpacer from '../../../view/common/VerticalSpacer';
import { TooltipPosition } from "../../presentational/Tooltip/constants";
import { Msg } from "../../../view/intl/index";
import { ColoredSwatch } from './ColoredSwatch';
import { QuestionTip } from "../../presentational/Tooltip/questionTip/QuestionTip";
import showAutoColorLeftPanel from "../../AutoColorLeftPanel/actionCreators/showAutoColorLeftPanel";
import {
    DEFAULT_COLOR_PICKER, THEME_COLOR_PICKER, THEME_BG_OVERRIDE_COLOR_PICKER, THEME_COLOR_OVERRIDE_COLOR_PICKER
} from '../constants';
import type { ThemeColorDataType, ThemeColorTypes, ThemeBackgroundType } from "../../ThemeGlobalData/flowTypes";
import { getColorsFromBackgroundTheme } from '../../ThemeGlobalData/themeRules';

const DIMENSIONS: { [k in ColorPickerKindType]: { height: number, width: number } } = {
    [THEME_COLOR_PICKER]: { height: 195, width: 230 },
    [THEME_BG_OVERRIDE_COLOR_PICKER]: { height: 95, width: 210 },
    [THEME_COLOR_OVERRIDE_COLOR_PICKER]: { height: 95, width: 210 },
    [DEFAULT_COLOR_PICKER]: { height: 475, width: 270 },
};

const addEventListenerList = (list: NodeList, event: string, fn: Function) => {
    for (let i = 0, len = list.length; i < len; i++) {
        // @ts-ignore
        list[i].addEventListener(event, fn);
    }
};

export default class ColorPicker extends React.PureComponent<ColorPickerPropTypes> {
    _debounceTimeoutId: ReturnType<typeof setTimeout> | undefined = undefined;
    changeCompleteActionPayload: any;
    containerRef: ReactElementRef<HTMLDivElement>;

    constructor(props: ColorPickerPropTypes) {
        super(props);
        this.containerRef = React.createRef();
    }

    _getPositionStyle(dimension: { height: number, width: number }) {
        const {
            position: { x, y },
            browserDimensions,
        } = this.props;
        const { width: bw, height: bh } = (browserDimensions || { width: 1000, height: 800 });

        const position = positionCalculator(
            {
                position: Positions.TopLeft,
                dimension
            }, {
                position: Positions.BottomLeft,
                bbox: {
                    left: x, top: y, right: (x + 1), bottom: (y + 1)
                }
            },
            { left: 0, top: 0, right: bw, bottom: bh },
            CollisionAlignment.Intelligent
        );

        return {
            ...dimension,
            top: position.y,
            left: position.x,
        };
    }

    _getCurrentPickerColor() {
        if (this.props.colorPickerKind !== DEFAULT_COLOR_PICKER) return parseColor.toColorPicker(this.props.themeColorsData.blackColor); // this should never happen

        const { color } = this.props,
            pickerColor = parseColor.toColorPicker(color);
        return {
            ...pickerColor,
            a: 1 // ignore alpha always set to 1 to prevent 'transparent' hex
        };
    }

    onChange = (color: ColorPickerColor|ThemeColorTypes|ThemeBackgroundType|null) => {
        if (this._debounceTimeoutId) {
            clearTimeout(this._debounceTimeoutId);
        }

        if ('onTheFlyChange' in this.props && this.props.onTheFlyChange) {
            this.onChangeComplete(color);
        }
    };

    setChangeCompleteActionPayload = (color: ColorPickerColor|ThemeColorTypes|ThemeBackgroundType|null) => {
        if (this.props.colorPickerKind !== DEFAULT_COLOR_PICKER) {
            this.changeCompleteActionPayload = { color };
        } else {
            // @ts-ignore
            const { hsl, ...rest } = color;
            const { color: [, , , , a] } = this.props,
                colorToParse = { ...rest, hsl: { h: hsl.h, s: hsl.s, l: hsl.l, a } }; // Dont' accept opacity from color picker widget
            const
                payload = { color: parseColor.fromColorPicker(colorToParse) };
            this.changeCompleteActionPayload = payload;
        }
    };

    onChangeComplete = (data) => {
        const {
            onChangeCompleteAction,
            additionalPayload,
        } = this.props;

        if (this.props.colorPickerKind !== DEFAULT_COLOR_PICKER) {
            this.props.dispatch({
                type: actionTypes.THEME_COLOR_OVERRIDE_CHANGE,
                payload: {
                    themeOverrideColor: { ...additionalPayload, ...data },
                    onChangeAction: onChangeCompleteAction,
                },
                amendToSelf: true,
                amendToSelfTag: onChangeCompleteAction
            });
        } else if (data) {
            this.setChangeCompleteActionPayload(data);

            if (this.props.onChangeCompleteDebounced) {
                this.onChangeCompleteDebounced();
            }

            let payload = this.changeCompleteActionPayload;
            if (onChangeCompleteAction) {
                payload = { ...payload, ...additionalPayload, onChangeAction: onChangeCompleteAction };
            }

            this.props.dispatch({
                type: actionTypes.COLOR_CHANGE_COMPLETE,
                payload,
                amendToSelf: true,
                amendToSelfTag: onChangeCompleteAction
            });
        }
    };

    dispatchColorChangeCompleteDebounced = () => {
        const { additionalPayload } = this.props;
        this.props.dispatch({
            type: actionTypes.COLOR_CHANGE_COMPLETE_DEBOUNCED,
            payload: {
                ...this.changeCompleteActionPayload,
                ...additionalPayload,
                // @ts-ignore
                onChangeDebouncedAction: this.props.onChangeCompleteDebounced && this.props.onChangeCompleteDebounced.action,
            },
            amendToSelf: true,
            // @ts-ignore
            amendToSelfTag: this.props.onChangeCompleteDebounced && this.props.onChangeCompleteDebounced.action,
        });
    };

    onChangeCompleteDebounced = () => {
        if (this._debounceTimeoutId) {
            clearTimeout(this._debounceTimeoutId);
        }
        // @ts-ignore
        if (this.props.onChangeCompleteDebounced) {
            this._debounceTimeoutId = setTimeout(
                this.dispatchColorChangeCompleteDebounced,
                // @ts-ignore
                this.props.onChangeCompleteDebounced.timeout || 1500
            );
        }
    };

    componentWillUnmount() {
        if (this._debounceTimeoutId) {
            clearTimeout(this._debounceTimeoutId);
            this.dispatchColorChangeCompleteDebounced();
        }
    }

    componentDidMount() {
        if (this.containerRef && this.containerRef.current) {
            const inputs = this.containerRef.current.querySelectorAll('input');
            if (!inputs) return;
            addEventListenerList(inputs, 'focus', () => this.onInputFocus());
            addEventListenerList(inputs, 'blur', () => this.onInputBlur());
        }
    }

    onInputFocus() {
        this.props.dispatch({ type: INPUT_FOCUSED });
    }

    onInputBlur() {
        this.props.dispatch({ type: INPUT_BLUR });
    }

    render() {
        const { dispatch, additionalPayload = {} } = this.props;
        const { isColorFeedbackOn, themeColorName } = additionalPayload;
        const dimension = { ...DIMENSIONS[this.props.colorPickerKind] };
        const overrideStyles: Record<string, any> = {};

        if (themeColorName) {
            // Make color picker container adapt to content height
            overrideStyles.height = 'auto';

            if (isColorFeedbackOn) {
                // Increasing height to accommodate feedback text
                dimension.height = 550;
            } else {
                // Increasing height to accommodate translated text
                dimension.height = 495;
            }
        }

        const positionStyles = this._getPositionStyle(dimension);

        return (
            <div>
                <div
                    ref={this.containerRef}
                    className={cx(styles.colorPicker, styles.arrow_box)}
                    style={{ ...positionStyles, ...overrideStyles }}
                    onMouseDown={e => e.stopPropagation()}
                >
                    {this.props.colorPickerKind === THEME_COLOR_PICKER
                        && this.makeThemeColorPicker(this.props.themeColor, this.props.defaultThemeColor, this.props.themeColorsData)}
                    {this.props.colorPickerKind === THEME_BG_OVERRIDE_COLOR_PICKER
                        && this.makeBackgroundOverrideColorPicker(this.props.backgroundTheme, this.props.themeColorsData)}
                    {this.props.colorPickerKind === THEME_COLOR_OVERRIDE_COLOR_PICKER
                        && this.makeColorOverrideColorPicker(this.props.themeColor, this.props.themeColorsData)}
                    {this.props.colorPickerKind === DEFAULT_COLOR_PICKER && this.makeDefaultColorPicker()}
                </div>
                <div
                    className={styles.colorPickerCover}
                    {...OnlyMouseUp}
                    onClick={() => dispatch({ type: actionTypes.HIDE_COLOR_PICKER })}
                />
            </div>
        );
    }

    openThemeSettings = () => {
        this.props.dispatch(showAutoColorLeftPanel());
        this.props.dispatch({ type: actionTypes.HIDE_COLOR_PICKER });
    };

    makeThemeColorPicker(
        pickerColor: null | undefined | ThemeColorTypes|InvalidThemeColorType,
        defaultThemeColor: ThemeColorTypes,
        themeColorsData: ThemeColorDataType,
    ) {
        return (<div style={{ padding: '20px 25px', width: '100%' }}>
            <div style={{ display: 'flex', alignItems: 'center', height: 17 }}>
                <Msg k="common.recommended" className={styles.colorPickerTitle}>Recommended</Msg>
                <HorizontalSpacer x={5} inline />
                <QuestionTip
                    preserveWhenHover
                    position={TooltipPosition.RIGHT}
                    theme={{ container: styles.toolTipContainer, tipInfo: styles.toolTipInfo }}
                >
                    {/* eslint-disable-next-line max-len */}
                    {'msg: colorPicker.recommended.tip {Makes sure that text is readable and automatically aligns with the background colour.}'}
                </QuestionTip>
            </div>
            <VerticalSpacer y={8} />
            <ColoredSwatch
                color={themeColorsData[defaultThemeColor]}
                selected={!pickerColor}
                onClick={() => this.onChangeComplete({ themeColor: null })}
            />
            <VerticalSpacer y={18} />
            <div className={styles.separator} />
            <VerticalSpacer y={18} />
            <div style={{ display: 'flex', alignItems: 'center', height: 17 }}>
                <Msg k="common.themeColor.label" className={styles.colorPickerTitle}>Colour theme</Msg>
                <HorizontalSpacer x={5} inline />
                <QuestionTip
                    preserveWhenHover
                    position={TooltipPosition.RIGHT}
                    theme={{ container: styles.toolTipContainer, tipInfo: styles.toolTipInfo }}
                >
                    <React.Fragment>
                        <Msg k="colorPicker.colorTheme.tip">
                            The colour theme is set by you and is used to style elements across your site.
                        </Msg>
                        <VerticalSpacer y={18} />
                        <Msg k="common.editColourTheme" className={styles.tooltipLink} onClick={this.openThemeSettings}>
                            Edit colour theme
                        </Msg>
                    </React.Fragment>
                </QuestionTip>
            </div>
            <VerticalSpacer y={8} />
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                {THEME_COLORS.map((tc) => <ColoredSwatch
                    key={tc}
                    color={themeColorsData[tc]}
                    selected={tc === pickerColor}
                    onClick={() => this.onChangeComplete({ themeColor: tc })}
                />)}
            </div>
        </div>);
    }

    makeBackgroundOverrideColorPicker(backgroundTheme: ThemeBackgroundType, themeColorsData: ThemeColorDataType) {
        return (<div style={{ padding: 15, width: '100%' }}>
            <div style={{ display: 'flex', alignItems: 'center', height: 17 }}>
                <Msg k="common.themeColor.label" className={styles.colorPickerTitle}>Colour theme</Msg>
                <HorizontalSpacer x={5} inline />
                <QuestionTip
                    preserveWhenHover
                    position={TooltipPosition.RIGHT}
                    theme={{ container: styles.toolTipContainer, tipInfo: styles.toolTipInfo }}
                >
                    <React.Fragment>
                        <Msg k="colorPicker.colorTheme.tip">
                            The colour theme is set by you and is used to style elements across your site.
                        </Msg>
                        <VerticalSpacer y={18} />
                        <Msg k="common.editColourTheme" className={styles.tooltipLink} onClick={this.openThemeSettings}>
                            Edit colour theme
                        </Msg>
                    </React.Fragment>
                </QuestionTip>
            </div>
            <VerticalSpacer y={12} />
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                {ALL_BACKGROUND_THEMES.map((bt) => <ColoredSwatch
                    key={bt}
                    color={themeColorsData[getColorsFromBackgroundTheme(bt, themeColorsData).backgroundColor]}
                    selected={bt === backgroundTheme}
                    onClick={() => this.onChangeComplete({ backgroundTheme: bt })}
                />)}
            </div>
        </div>);
    }

    makeColorOverrideColorPicker(pickerColor: null | undefined | ThemeColorTypes, themeColorsData: ThemeColorDataType) {
        return <div style={{ padding: 15, width: '100%' }}>
            <div style={{ display: 'flex', alignItems: 'center', height: 17 }}>
                <Msg k="common.themeColor.label" className={styles.colorPickerTitle}>Colour theme</Msg>
                <HorizontalSpacer x={5} inline />
                <QuestionTip
                    preserveWhenHover
                    position={TooltipPosition.RIGHT}
                    theme={{ container: styles.toolTipContainer, tipInfo: styles.toolTipInfo }}
                >
                    <React.Fragment>
                        <Msg k="colorPicker.colorTheme.tip">
                            The colour theme is set by you and is used to style elements across your site.
                        </Msg>
                        <VerticalSpacer y={18} />
                        <Msg k="common.editColourTheme" className={styles.tooltipLink} onClick={this.openThemeSettings}>
                            Edit colour theme
                        </Msg>
                    </React.Fragment>
                </QuestionTip>
            </div>
            <VerticalSpacer y={12} />
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                {THEME_COLORS.map((tc) => <ColoredSwatch
                    key={tc}
                    color={themeColorsData[tc]}
                    selected={tc === pickerColor}
                    onClick={() => this.onChangeComplete({ themeColor: tc })}
                />)}
            </div>
        </div>;
    }

    makeDefaultColorPicker() {
        const pickerColor = this._getCurrentPickerColor();
        const { styles: customStyles = {}, isColorFeedbackOn, colorToCompare, className } = this.props.additionalPayload || {};
        const { Saturation = {}, ...restCustomStyles } = customStyles;

        return <React.Fragment>
            {
                // @ts-ignore
                this.props.suggestedColors && this.props.suggestedColors.length ?
                    <SuggestedColors
                        // @ts-ignore
                        label={this.props.suggestedColorsLabel}
                        // @ts-ignore
                        colors={this.props.suggestedColors}
                        isColorFeedbackOn={isColorFeedbackOn}
                        colorToCompare={colorToCompare}
                        selectedColor={parseColor.fromColorPicker({ hsl: pickerColor })}
                        onChange={this.onChangeComplete}
                    />
                    :
                    <SuggestedColors
                        label="msg: colorPicker.recentColor.label {Recent colours}"
                        colors={this.props.recentColors}
                        selectedColor={parseColor.fromColorPicker({ hsl: pickerColor })}
                        onChange={this.onChangeComplete}
                    />
            }
            { /* $FlowFixMe: WBTGEN-16368 */ }
            <SketchPicker
                styles={{
                    Saturation: {
                        circle: {
                            boxShadow: pickerColor.l > 0.5 ? 'rgb(0, 0, 0) 0px 0px 0px 1px inset' :
                                'rgb(255, 255, 255) 0px 0px 0px 1px inset ',
                        },
                        ...Saturation
                    },
                    ...restCustomStyles
                }}
                presetColors={[]}
                color={pickerColor}
                onChange={this.onChange}
                onChangeComplete={this.onChangeComplete}
                disableAlpha
                className={className}
            />
        </React.Fragment>;
    }
}
