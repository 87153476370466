import { trialUserDomainSel, trialUserEmailSel, trialUserSubIDSel } from '../../../demo/modules/selectors';
import type { AppState } from '../../redux/modules/flowTypes';
import { adminIdSelector, ownerEmailSelector } from '../App/epics/ownerData/selectors';
import { subscriptionTypeStateSel } from '../App/epics/subscriptionData/selectors';
import { currentLanguageAppSel } from '../TopBar/epics/languages/selectors';
import { EventTrackerMiddlewareFactory } from './EventTrackerMiddlewareFactory';
import { EventTrackingProps, ModuleSource } from './constants';
import { EventsRegistry } from './eventsRegistry';

const commonPropsFn = (appState: AppState) => ({
    [EventTrackingProps.module]: ModuleSource.WSB_EDITOR,
    [EventTrackingProps.subscriptionType]: subscriptionTypeStateSel(appState),
    [EventTrackingProps.editorLanguage]: currentLanguageAppSel(appState),
    [EventTrackingProps.trialId]: trialUserSubIDSel(appState),
    [EventTrackingProps.trialEmail]: trialUserEmailSel(appState),
    [EventTrackingProps.trialDomain]: trialUserDomainSel(appState),
    [EventTrackingProps.email]: ownerEmailSelector(appState),
    [EventTrackingProps.adminId]: adminIdSelector(appState),
});

const propsToFilerFn = () => ({
    [EventTrackingProps.trialEmail]: true,
    [EventTrackingProps.email]: true,
    [EventTrackingProps.adminId]: true,
});

export const eventTrackerMiddleware = EventTrackerMiddlewareFactory(
    EventsRegistry,
    commonPropsFn,
    propsToFilerFn,
);
