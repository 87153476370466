import React from 'react';

const View = () => {
    // Since, we are now showing the Post page in the view, this is not required.
    // An empty element is returned regardless as required by the existing setup.
    return (
        <div data-testid="post-view" />
    );
};

export default View;
