/* eslint-disable no-multi-assign */
import himalaya from 'himalaya';
import ent from 'ent';
import { identity, ifElse, is, map, trim, values } from 'ramda';
import {
    absoluteUrlToInputLink,
    absoluteUrlToInputLinkOld
} from "../../../../view/common/dialogs/LinkChooserDialog/utils/absoluteUrlToInputLink";
import styleLink from '../../Link/globalStyle/kind';
import { BROKEN_LINK_URL, LcTabName } from '../../../../view/common/dialogs/LinkChooserDialog/constants';
import assignInterestingTextStyles from './assignInterestingTextStyles';
import getInterestingStyle from './getInterestingStyle';
import getSquashedStyles from './getSquashedStyles';
import getPaddingFromStyle from './getPaddingFromStyle';
import type {
    HtmlDataWithGSAndSite,
    Paragraph,
    Link,
    Style
} from './flowTypes';
import { parseLineHeight } from "../../../App/epics/tinyMceEpic/editorUtils/utils/helpers/parseLineHeight";
import { THEME_LINK_CLASS_LIST, themeaccent } from '../../../App/epics/tinyMceEpic/editorUtils/constants';

const getNodeStyle = ({ attributes = { style: {} }, tagName }) => {
    // Trim string values
    const styleAttribute = map(
        ifElse(prop => is(String, prop), trim, identity), attributes.style || {}
    );

    if (tagName === 'b' || tagName === 'strong') {
        return { ...styleAttribute, fontWeight: 'bold' };
    } else if (tagName === 'em' || tagName === 'i') {
        return { ...styleAttribute, fontStyle: 'italic' };
    } else if (tagName === 'u') {
        return { ...styleAttribute, textDecoration: 'underline' };
    }

    return styleAttribute;
};

const getNodeMceDataStyle = ({ attributes }) => {
    const mceStyle = attributes && attributes.dataset && attributes.dataset.mceStyle;
    const styles = {};

    if (mceStyle) {
        ent.decode(mceStyle).split(';').forEach(style => {
            const [propName, value] = style.split(':');

            if (propName) {
                // hyphenated-prop-name to camelCase
                styles[propName.replace(/-([a-z])/g, hyphenated => hyphenated[1].toUpperCase()).trim()] = value;
            }
        });
    }

    return styles;
};

const ensureNewlineIsLastChar = text => {
    if (!text.length) {
        return text;
    }

    return (text.charAt(text.length - 1) !== '\n') ? text + '\n' : text;
};

// Get total text length for text nodes contained within the given node
const getInnerTextLength = (node): number => {
    let text = '';
    const nodes = [node];

    while (nodes.length) {
        const n = nodes.shift();
        if (n.children && n.children.length) {
            nodes.push(...n.children);
        }

        if (n.type === 'Text') {
            let { content } = n;
            content = content.replace(/&nbsp;/g, ' ')
                .replace(/&lt;/g, '<')
                .replace(/&gt;/g, '>')
                .replace(/&amp;/g, '&');

            text += content;
        }
    }

    return text.length;
};

const hasInnerText = (node): boolean => {
    let textFound = false;
    const nodes = [node];

    while (nodes.length && !textFound) {
        const n = nodes.shift();
        if (n.children && n.children.length) {
            nodes.push(...n.children);
        }

        if (n.type === 'Text') {
            textFound = true;
        }
    }

    return textFound;
};

/*
 Convert HTML string content to OneWeb JSON.
 */
function htmlToJson({ content, globalStylesMap, site }: HtmlDataWithGSAndSite): Record<string, any> {
    // Remove &quot; introduced by font family
    const contentAsJson = himalaya.parse(content.replace(/&quot;/g, ''));

    const styles: Style[] = [];
    const links: any = [];
    const paras: any = [];
    const cells = [];
    let text = '';

    const traverseTree = (tree, parent: any = {}, parentIndent = 0) => {
        if (Array.isArray(tree)) {
            tree.forEach(node => traverseTree(node));
            return false;
        } else {
            const shouldInheritFromParent = (
                tree.type === 'Text' || tree.tagName === 'br' ||
                (parent.tagName !== 'p' && parent.tagName !== 'li')
            );

            const parentStyle = { ...parent.style };
            const nodeStyle = getNodeStyle(tree);
            const inheritedStyle = { ...(shouldInheritFromParent ? parentStyle : {}), ...nodeStyle };

            const parentMceDataStyle = { ...parent.mceStyle };
            const nodeMceDataStyle = getNodeMceDataStyle(tree);
            const inheritedMceStyle = { ...(shouldInheritFromParent ? parentMceDataStyle : {}), ...nodeMceDataStyle };

            if (tree.type === 'Comment') {
                return false;
            }

            if (tree.type === 'Text' || tree.tagName === 'br') {
                // When encountering a Text node, add it to accumulated `text` variable ...
                const content = (tree.content || "")
                    .replace(/&lt;/g, '<')
                    .replace(/&gt;/g, '>')
                    .replace(/&amp;/g, '&')
                    .replace(/&nbsp;/g, ' ');
                text += content;
                assignInterestingTextStyles(text, content, styles, inheritedStyle, inheritedMceStyle);

                if (tree.tagName === 'br') text += '\n';
                const textLength = text.length;

                // ... and update previous end markers (if not yet set).
                // Newlines trigger Text node but must not overwrite existing ending.
                if (paras.length) {
                    // For text descended from a block element, we should update the ancestor block-element's
                    // end marker. eg: <p><span>text</span><span>text</span></p>
                    // The para object should include the length for both <span> elements.
                    let parentNode = parent;
                    while (parentNode) {
                        if (/^(?:p|ul|ol)$/.test(parentNode.tagName)) {
                            paras[paras.length - 1].end = textLength;
                            paras.forEach(para => {
                                if (para.end === undefined) {
                                    para.end = textLength;  // eslint-disable-line no-param-reassign
                                }
                            });
                            break;
                        }
                        parentNode = parentNode.parentNode;
                    }

                    if (paras[paras.length - 1].end === undefined) {
                        paras[paras.length - 1].end = textLength;
                    }
                }

                if (links.length && links[links.length - 1].end === undefined) {
                    links[links.length - 1].end = textLength;
                }
            }

            if (tree.type === 'Element') {
                const { tagName, attributes } = tree;
                const classNames = attributes.className || [];
                const id = attributes.id;
                // TODO type is missing, find out why, and fix failing test if type is there
                // @ts-ignore
                const style: Style = { atype: 'style', ...getInterestingStyle(inheritedStyle, inheritedMceStyle) };

                let indent = parentIndent;
                let listItemAdded = false;
                let paraAdded = false;
                let newlineAdded = false;

                if (classNames.includes('mceNonEditable')) {
                    // Don't try to parse non-editable blocks
                    return false;
                }

                if (classNames.length) {
                    // Find globalId and globalName using className
                    Object.keys(globalStylesMap).some(styleId => {
                        const styleName = globalStylesMap[styleId];
                        const styleNameAsClass = styleName.toLowerCase().replace(/[[\]. ]+/g, '');

                        let index = -1;
                        if (styleName === styleNameAsClass) {
                            index = classNames.indexOf(styleNameAsClass);
                        } else {
                            index = classNames.indexOf('text' + styleNameAsClass);
                        }
                        if (index !== -1) {
                            style.globalId = styleId;
                            style.globalName = styleName;
                            return true;
                        }
                        return false;
                    });
                }

                if (tagName === 'p' || /^h[123456]$/.test(tagName)) {
                    paraAdded = true;
                    text = ensureNewlineIsLastChar(text);

                    const paragraph: Paragraph = {
                        atype: 'para',
                        start: text.length,
                        align: inheritedStyle.textAlign || 'left'
                    };
                    const padding = getPaddingFromStyle(nodeStyle);

                    if (parent && parent.tagName === 'div') {
                        const parentStyle = (parent.attributes && parent.attributes.style) || {};

                        // paragraph.align = (parentStyle && parentStyle.textAlign) || paragraph.align;
                        // paragraph.align = paragraph.align || (parentStyle && parentStyle.textAlign);

                        // if parent has set style && inheritedStyle doesnt have use parent style
                        if (parentStyle && parentStyle.textAlign && !inheritedStyle.textAlign) {
                            paragraph.align = parentStyle.textAlign;
                        }

                        // Only include interesting padding
                        if (parentStyle) {
                            const parentPadding = getPaddingFromStyle(parentStyle);
                            paragraph.padding = padding.map((side, index) => side + parentPadding[index]);
                            if (paragraph.padding.join('') === '0000') {
                                delete paragraph.padding;
                            }
                        }
                    } else if (padding.join('') !== '0000') {
                        paragraph.padding = padding;
                    }

                    // left-align is default so don't include it
                    if (paragraph.align === 'left') {
                        delete paragraph.align;
                    }

                    if (inheritedStyle.lineHeight) {
                        const lineHeight = parseLineHeight(inheritedStyle.lineHeight);

                        if (lineHeight !== null) {
                            paragraph.height = lineHeight;
                        }
                    }

                    if (indent) {
                        paragraph.indent = indent;
                    }

                    paras.push(paragraph);
                } else if (tagName === 'ul' || tagName === 'ol') {
                    paraAdded = true;
                    text = ensureNewlineIsLastChar(text);

                    // When we encounter a <ul> or <ol>, we must increase the indent by 1 for every nesting level.
                    // We pass the current indent level on to the next call to traverseTree()
                    indent += 1;

                    const list: Paragraph = {
                        atype: 'para',
                        start: text.length,
                        bullet: inheritedStyle.listStyleType,
                        indent
                    };

                    paras.push(list);
                } else if (/^(?:b|i|u|strong|em|span)$/.test(tagName)) {
                    if (id === '_mce_caret') {
                        text += '\n';
                        newlineAdded = true;
                    }
                    // const interestingStyle = getInterestingStyle(inheritedStyle, inheritedMceStyle);

                    // if (interestingStyle !== null) {
                    //     interestingStyle.start = text.length;

                    //     // If last style added has the same start marker, append current style to it instead
                    //     if (styles.length && styles[styles.length - 1].start === interestingStyle.start) {
                    //         Object.assign(styles[styles.length - 1], interestingStyle);
                    //     } else {
                    //         styles.push(interestingStyle);
                    //     }
                    // }
                } else if (tagName === 'a') {
                    const linkAction: { openInNewWindow?: any, link: Record<string, string> } = { openInNewWindow: false, link: {} };

                    linkAction.openInNewWindow = Boolean(attributes.target);
                    if (attributes.href) {
                        try {
                            linkAction.link = absoluteUrlToInputLinkOld(attributes.href.replace(/^api/, '/api'), site);
                        } catch (e) {
                            linkAction.link = absoluteUrlToInputLink(attributes.href);
                            if (!values(LcTabName).includes(linkAction.link.type)) {
                                throw e;
                            }
                        }
                    } else {
                        linkAction.link = {
                            type: LcTabName.PAGE,
                            value: BROKEN_LINK_URL
                        };
                    }

                    // Find global link style using className
                    const { className } = attributes;
                    const linkClassname = className ? className.find(name => /link\d+/.test(name)) : '';
                    const linkStyle = {
                        atype: 'style',
                        inactive: {},
                        visited: {},
                        hover: {},
                        press: {},
                        type: styleLink,
                        themeStyle: '',
                        globalId: '',
                        globalName: ''
                    };

                    if (linkClassname) {
                        linkStyle.themeStyle = className.find(name => THEME_LINK_CLASS_LIST.includes(name)) || themeaccent;
                        Object.keys(globalStylesMap).some(styleId => {
                            const styleName = globalStylesMap[styleId];
                            const styleNameAsClass = styleName.toLowerCase().replace(/[[\]. ]+/g, '');

                            if (linkClassname === styleNameAsClass) {
                                linkStyle.globalId = styleId;
                                linkStyle.globalName = styleName;
                                return true;
                            }
                            return false;
                        });
                    }

                    const link: Link = {
                        atype: 'link',
                        start: text.length,
                        end: text.length + getInnerTextLength(tree),
                        style: linkStyle,
                        linkAction
                    };
                    links.push(link);
                } else if (tagName === 'sup') {
                    style.start = text.length;
                    style.superscript = true;
                    styles.push(style);
                } else if (tagName === 'sub') {
                    style.start = text.length;
                    style.subscript = true;
                    styles.push(style);
                } else if (tagName === 'li') {
                    listItemAdded = true;

                    const lastPara = paras[paras.length - 1];
                    if (lastPara && lastPara.bullet && lastPara.start === text.length &&
                        (inheritedStyle.textAlign || inheritedStyle.lineHeight)) {
                        lastPara.align = inheritedStyle.textAlign;

                        if (inheritedStyle.lineHeight) {
                            const lineHeight = parseLineHeight(inheritedStyle.lineHeight);
                            if (lineHeight !== null) {
                                lastPara.height = lineHeight;
                            }
                        }
                    } else if (
                        lastPara && (inheritedStyle.textAlign !== lastPara.align ||
                        inheritedStyle.lineHeight !== lastPara.height)
                    ) {
                        const list: Paragraph = {
                            atype: 'para',
                            start: text.length,
                            bullet: inheritedStyle.listStyleType,
                            indent
                        };
                        if (inheritedStyle.textAlign) {
                            list.align = inheritedStyle.textAlign;
                        }
                        if (inheritedStyle.lineHeight) {
                            const lineHeight = parseLineHeight(inheritedStyle.lineHeight);
                            if (lineHeight !== null) {
                                list.height = lineHeight;
                            }
                        }

                        paras.push(list);
                    }
                } else if (tagName === 'br') {
                    // Line break is added above in the text section
                    newlineAdded = true;
                }

                // Add style if it has a non-link global style
                if (style.type !== styleLink && style.globalId && style.globalName &&
                        !/link/i.test(style.globalName)) {
                    style.start = text.length;
                    if (hasInnerText(tree)) {
                        styles.push(style);
                    } else if (tree.children.length && tree.children[0].tagName === 'br') {
                        style.end = text.length;
                        styles.push(style);
                    }
                }

                if (Array.isArray(tree.children)) {
                    const propsToInherit = [
                        'fontWeight', 'textDecoration', 'fontSize', 'fontStyle', 'fontFamily', 'color',
                        'lineHeight', 'textShadow', 'backgroundColor', 'verticalAlign', 'letterSpacing',
                        'listStyleType', 'globalId', 'globalName'
                    ];

                    let treeWithInheritedStyle;

                    // Traverse through each child
                    if ((style.globalId && style.globalName) || propsToInherit.some(prop => prop in inheritedStyle)) {
                        treeWithInheritedStyle = {
                            ...tree,
                            style: { ...inheritedStyle },
                            mceStyle: inheritedMceStyle
                        };
                        if (style.globalName && !/link/i.test(style.globalName)) {
                            treeWithInheritedStyle.style.globalId = style.globalId;
                            treeWithInheritedStyle.style.globalName = style.globalName;
                        }
                    } else {
                        treeWithInheritedStyle = tree;
                    }

                    tree.children.forEach(child => {
                        newlineAdded = traverseTree({
                            parentNode: tree,
                            ...child
                        }, treeWithInheritedStyle, indent) || newlineAdded;
                    });

                    // Paragraphs and lists should update last para end marker
                    if (paraAdded || listItemAdded) {
                        if (!newlineAdded) {
                            if (listItemAdded) {
                                // List items should be delimited by line breaks
                                text += '\n';
                            }
                            newlineAdded = true;
                        }

                        // Set last paragraph end marker
                        if (paras.length && paras[paras.length - 1].end === undefined) {
                            paras[paras.length - 1].end = text.length;
                        }
                    }

                    return newlineAdded;
                }
            }
        }

        return false;
    };

    traverseTree(contentAsJson);

    // Last paragraph adds a newline character that isn't needed
    if (text.charAt(text.length - 1) === "\n") {
        text = text.slice(0, text.length - 1);
    }

    // Make sure all styles have an end marker
    const textLength = text.length;
    styles.forEach(style => {
        if (style.end === undefined) {
            style.end = Math.max(textLength, style.start || -Infinity); // eslint-disable-line no-param-reassign
        }
    });

    const squashedStyles = getSquashedStyles(styles);

    return {
        text,
        styles: squashedStyles,
        links,
        paras: paras
            .filter(paragraph => {
                // We only care about paragraphs that have properties apart from 'start' and 'indent'.
                // If a paragraph only has start and/or indent, it's uninteresting and adds nothing.
                return (paragraph.start !== paragraph.end) && (
                    paragraph.align || paragraph.padding || paragraph.height || paragraph.indent || paragraph.bullet);
            }),
        cells
    };
}

export default htmlToJson;
