import * as R from 'ramda';
import makeUuid from '../../../../../utils/makeUuid';
import { receiveOnly, optionalReceiveOnly, optional } from '../../../../epics/makeCondition';
import { getDefaultReducerState } from '../../../../redux/makeReducer/index';
import * as workspaceActionTypes from '../../actionTypes';
import componentsClipboardValueActionType from '../../../App/epics/componentsClipboard/valueActionType';
import workspaceBBoxEpicValueActionType from "../workspaceBBox/valueActionType";
import contextMenuValueActionType from '../../../ContextMenu/epic/valueActionType';
import scrollValueActionType from '../scroll/valueActionType';
import rightMouseDownPositionWithRespectToTemplateArea from '../rightMouseDownPositionWithRespectToTemplateArea/valueActionType';
import { getMaxOrderIndex } from '../../../../redux/modules/children/workspace/reducers/utils/index';
import { getComponentsBBox } from "../../../../utils/componentsMap/index";
import isStretchComponentKind from '../../../oneweb/isStretchComponentKind';
import { isSectionComponent } from '../../../oneweb/isSectionComponent';
import componentsRegistry from '../../../../view/oneweb/registry/index';
import { COMPONENTS_PASTED, COMPONENTS_DUPLICATED, SILENT_COMPONENTS_DUPLICATED, COMPONENTS_MAP_INITIALIZED } from "./actionTypes";
import getAddComponentsNotAllowedAction from "./actionCreators/componentsAddNotAllowed";
import * as updateReasons from './updateReasons';
import { setSelectedComponentsIds } from './selectedComponentsIds';
import type { ComponentsEvalEpicUpdater } from './flowTypes';
import { TextComponentKind } from '../../../oneweb/Text/kind';
import { TableComponentKind } from '../../../oneweb/Table/kind';
import makeComponentsFromClipboard from '../../../../utils/parseClipboardData';
import {
    ReceiveOnlyComponentsDependenciesSelector, ReceiveOnlySelectedComponentSelector,
    ROEditModeComponentIdSelector, userInteractionModeSelector, componentsMapSelector
} from "./selectorActionTypes";
import { isTransient } from "./userInteractionMutations/interactionModes";
import { getWrappedComponentIds } from '../../../../utils/htmlWriter/html/render/wrapper/wrapperNodeUtils';
import { UserFocusValueActionType } from "../../../App/epics/userFocus/valueActionType";
import { resetEditModeComponentId } from "./editModeComponentId";
import {
    shouldResetEditModeComponentId,
    getUpdateReasonForResetComponentModeId
} from "./shouldResetEditModeComponentId";
import { getSelectedComponentIdsAlongWithWrappedIds } from './selectors';
import { isAnyDialogInFocus } from "./isAnyDialogInFocus";
import {
    getAllAttachmentsForCmpIds, removeDuplicatesFromAnArrayOfStrings,
    createAttachments, getTopMostParentId
} from "../componentAttachements/util";
import { componentAttachmentsVAT } from "../componentAttachements/valueActionType";
import viewportDimensionsValueActionType from "../viewportDimensions/valueActionType";
import { isHeaderOrFooterSection, getNearestTopPageSection } from "../../../oneweb/Section/utils";
import type { ComponentsCollection, ComponentsIds } from "../../../../redux/modules/children/workspace/flowTypes";
import type { Attachments } from "../componentAttachements/flowTypes";
import { NonTemplateComponents } from "../../../oneweb/Template/constants";
import {
    isNonTemplateComponentsInHeaderOrFooter,
    fixSelectionOverlapWithSectionByMovingSelection
} from "./userInteractionMutations/utils";
import { CANNOT_ADD_COMPONENT_TO_HEADER, CANNOT_ADD_COMPONENT_TO_FOOTER } from "./messages";
import { getSectionAtPosition } from '../isPageMode/utils';
import { setComponentsMap } from './setters';
import { PASTE_OFFSET_WHEN_THERE_IS_NOT_ENOUGH_SECTION_HEIGHT } from './constants';
import {
    ReceiveOnlyTemplateWidthActionType
} from '../../../oneweb/Template/epics/template/selectorActionTypes';
import {
    allComponentsInHoverMode,
    getComponentsCommonHoverBox, getNearestParentHoverBoxCmp, sanitizeSelectedComponentsForHoverBox,
    someComponentsAreInHoverAndDefaultModes
} from "../../../oneweb/HoverBox/utils";
import { isPointInsideBBox } from "../../../../utils/bBox";
import {
    someComponentsPlacedInModernSection,
    isComponentPlacedInHeader,
    tryToPlaceComponentInToNonModernSection
} from "../../../ModernLayouts/utils";

const
    pastePixelDiff = 20,
    incComponentsAddedByPasteCounter = R.evolve({
        scope: {
            addingComponent: {
                componentsAddedByPasteCounter: R.inc
            }
        }
    }),
    mergeComponentsMapToEpicState = (componentMapUpdate) => ({ state, scope }) => {
        return {
            scope,
            state: {
                ...state,
                componentsMap: R.merge(state.componentsMap, componentMapUpdate)
            }
        };
    },
    mergeComponentsMapExtensionToEpicState = componentMapExtensionUpdate => R.evolve({
        state: {
            componentsMapExtension: R.merge(componentMapExtensionUpdate)
        }
    }),
    getComponentsMapFromEpicState = R.path(['state', 'componentsMap']),
    createUpdaterResultFactory = (actionType, finalStateUpdater = R.identity) =>
        (resultAfterPasteOrDuplicate, epicState) => {
            if (isTransient(userInteractionModeSelector(epicState))) {
                return { state: epicState };
            }
            const
                { newComponentsState, failedComponentsErrors } = resultAfterPasteOrDuplicate,
                resultedAction = {
                    type: actionType,
                    payload: { failedComponentsErrors, newComponentsIds: [] }
                };

            if (newComponentsState) {
                const
                    { newComponentsMap, newComponentsIds, newSelectedComponentsIds, newSectionId } = newComponentsState,
                    componentsMapExtensionUpdate = newComponentsIds.reduce(
                        (acc, componentId) => {
                            const { reducerForExtension } = componentsRegistry[newComponentsMap[componentId].kind];

                            if (reducerForExtension) {
                                return R.assoc(componentId, getDefaultReducerState(reducerForExtension), acc);
                            }
                            return acc;
                        },
                        {}
                    ),
                    updateEpicState = R.pipe(
                        mergeComponentsMapToEpicState(newComponentsMap),
                        mergeComponentsMapExtensionToEpicState(componentsMapExtensionUpdate),
                        setSelectedComponentsIds(newSelectedComponentsIds),
                        epicState => {
                            const componentsMap = componentsMapSelector(epicState);
                            const updatedComponentsMap = fixSelectionOverlapWithSectionByMovingSelection(
                                newComponentsIds,
                                componentsMap,
                                'selection-top',
                                true
                            );
                            return setComponentsMap(updatedComponentsMap, epicState);
                        },
                        finalStateUpdater
                    );

                resultedAction.payload.newComponentsIds = newComponentsIds;

                const multipleActionsToDispatch: Array<Action> = [resultedAction];
                if (newSectionId) {
                    multipleActionsToDispatch.push({
                        type: workspaceActionTypes.WORKSPACE_SCROLL_AFTER_SECTION_MOVEMENT,
                        payload: {
                            top: newComponentsMap[newSectionId].top,
                            height: newComponentsMap[newSectionId].height,
                        }
                    });
                }
                return {
                    state: updateEpicState(epicState),
                    multipleActionsToDispatch,
                    updateReason: updateReasons.PASTE
                };
            }

            if (failedComponentsErrors.length > 0) {
                return { state: epicState, actionToDispatch: resultedAction };
            }

            return { state: epicState };
        },
    createUpdaterResultAfterPaste = createUpdaterResultFactory(COMPONENTS_PASTED, incComponentsAddedByPasteCounter),
    createUpdaterResultAfterDuplicateMap = {
        [COMPONENTS_DUPLICATED]: createUpdaterResultFactory(COMPONENTS_DUPLICATED),
        [SILENT_COMPONENTS_DUPLICATED]: createUpdaterResultFactory(SILENT_COMPONENTS_DUPLICATED)
    },
    checkToPasteInViewPort = (updatedComponents, viewportDimensions, scroll) => {
        let isAdjustToPasteInViewPort = false;
        if (!viewportDimensions || (viewportDimensions.width === 0 && viewportDimensions.height === 0)) {
            return false;
        }
        for (const component of updatedComponents) {
            if (component.top > scroll.y &&
                (component.top + component.height < scroll.y + viewportDimensions.height)) {
                continue;
            }
            isAdjustToPasteInViewPort = true;
            break;
        }
        return isAdjustToPasteInViewPort;
    },
    createNewComponents = ({
        templateWidth,
        workspaceBBox,
        components,
        componentsMap,
        mousePosition,
        counter,
        componentsDependencies,
        selectedComponentsIds,
        attachments,
        viewportDimensions = { width: 0, height: 0 },
        scroll = {} as Record<string, any>
    }) => {
        let failedComponentsErrors: Array<any> = [],
            failedComponentsMap = {},
            oldToNewCmpIdMap = {},
            newSectionId,
            copiedSection = components.find(cmp => isSectionComponent(cmp)) || {},
            isDuplicateSection = !R.isEmpty(copiedSection),
            nearestSection: Record<string, any> = {};

        const componentIdChangesMap = {},
            copiedComponentsBBox = getComponentsBBox(components, workspaceBBox),
            copiedNonStretchCmpsBBox =
                getComponentsBBox(components.filter(({ kind, stretch = false }) => !isStretchComponentKind(kind, stretch)), workspaceBBox),
            maxPageComponentsOrderIndex = getMaxOrderIndex(componentsMap),
            componentsSortedByOrderIndex = R.sortBy(R.prop('orderIndex'))(components),
            updatedComponents = componentsSortedByOrderIndex.filter(({ kind }) => {
                // @ts-ignore this can be a bug, subscription data is not passed
                const componentAddError = componentsRegistry[kind].validateComponentAdd({ componentsMap });
                if (componentAddError) {
                    failedComponentsErrors.push({ componentKind: kind, error: componentAddError });
                    return false;
                }
                return true;
            });

        if (!updatedComponents.length) {
            return { newComponentsState: null, failedComponentsErrors, componentIdChangesMap };
        }
        const isAdjustToPasteInViewPort = checkToPasteInViewPort(updatedComponents, viewportDimensions, scroll);

        let moveDownComponents = {};
        if (isDuplicateSection) {
            if (mousePosition) {
                nearestSection = getNearestTopPageSection(componentsMap, mousePosition);
            } else if (isAdjustToPasteInViewPort || !componentsMap[copiedSection.id]) {
                nearestSection = getNearestTopPageSection(componentsMap, { y: scroll.y + 100 });
            }
            nearestSection = R.isEmpty(nearestSection) ? copiedSection : nearestSection;

            Object.keys(componentsMap).forEach(cmpId => {
                const cmp = componentsMap[cmpId];
                if ((cmp.top + (cmp.height / 2)) >= (nearestSection.top + nearestSection.height)) {
                    moveDownComponents = {
                        ...moveDownComponents,
                        [cmpId]: {
                            ...cmp,
                            top: cmp.top + copiedSection.height
                        }
                    };
                }
            });
        }
        const updatedComponentsState = updatedComponents.reduce(
            (acc, component, index) => {
                const { newComponentsMap, newComponentsIds, newSelectedComponentsIds } = acc;
                const newComponentId = makeUuid();

                componentIdChangesMap[component.id] = newComponentId;
                oldToNewCmpIdMap[newComponentId] = component.id;

                let newCompLeft;
                let insertionRight = copiedNonStretchCmpsBBox.right;
                if (isDuplicateSection) {
                    newCompLeft = component.left;
                } else if (isStretchComponentKind(component.kind, component.stretch)) {
                    newCompLeft = 0;
                } else if (mousePosition) {
                    newCompLeft = mousePosition.x + component.left - copiedComponentsBBox.left;
                } else if (isAdjustToPasteInViewPort) {
                    const offset = (counter - 1) * pastePixelDiff;
                    newCompLeft = component.left + offset;
                    insertionRight = copiedNonStretchCmpsBBox.right + offset;
                } else {
                    const offset = counter * pastePixelDiff;
                    newCompLeft = component.left + (counter * pastePixelDiff);
                    insertionRight = copiedNonStretchCmpsBBox.right + offset;
                }

                if (!isStretchComponentKind(component.kind, component.stretch) && insertionRight > templateWidth) {
                    const componentLeftRelativeToCopiedComponentsBox = component.left - copiedNonStretchCmpsBBox.left;
                    const copiedComponentsWidth = copiedNonStretchCmpsBBox.right - copiedNonStretchCmpsBBox.left;
                    newCompLeft = Math.max(0, templateWidth - copiedComponentsWidth) + componentLeftRelativeToCopiedComponentsBox;
                }

                let newCompTop = 0;
                if (isDuplicateSection) {
                    newCompTop = nearestSection.top + nearestSection.height + component.top - copiedComponentsBBox.top;
                } else if (mousePosition) {
                    newCompTop = mousePosition.y + component.top - copiedComponentsBBox.top;
                } else if (isAdjustToPasteInViewPort) {
                    const middleOfViewportY = scroll.y + (viewportDimensions.height / 2);
                    const section = getSectionAtPosition({ y: middleOfViewportY, x: 0 }, componentsMap);

                    const copiedComponentsHeight = copiedComponentsBBox.bottom - copiedComponentsBBox.top;
                    if (section) {
                        const componentTopRelativeToCopiedComponentsBox = component.top - copiedComponentsBBox.top;

                        if (section.height < copiedComponentsHeight) {
                            newCompTop = section.top
                                + PASTE_OFFSET_WHEN_THERE_IS_NOT_ENOUGH_SECTION_HEIGHT
                                + componentTopRelativeToCopiedComponentsBox; // section expansion and selection alignment will be handled later on in flow
                        } else {
                            const sectionBottom = section.top + section.height;

                            if (
                                middleOfViewportY + copiedComponentsHeight >
                                sectionBottom - PASTE_OFFSET_WHEN_THERE_IS_NOT_ENOUGH_SECTION_HEIGHT
                            ) {
                                newCompTop = sectionBottom
                                    - copiedComponentsHeight
                                    + componentTopRelativeToCopiedComponentsBox
                                    - PASTE_OFFSET_WHEN_THERE_IS_NOT_ENOUGH_SECTION_HEIGHT;
                            } else {
                                newCompTop = Math.max(
                                    section.top + PASTE_OFFSET_WHEN_THERE_IS_NOT_ENOUGH_SECTION_HEIGHT,
                                    middleOfViewportY
                                ) + componentTopRelativeToCopiedComponentsBox;
                            }
                        }
                    }
                } else {
                    newCompTop = (component.top + (counter * pastePixelDiff));
                }

                if (isDuplicateSection && isSectionComponent(component)) {
                    newSectionId = newComponentId;
                }

                const
                    adjustComponentOnPaste = componentsRegistry[component.kind].adjustComponentOnPaste,
                    onBeforeComponentAdded = componentsRegistry[component.kind].onBeforeComponentAdded;

                let newComponentData = tryToPlaceComponentInToNonModernSection({
                    ...component,
                    id: newComponentId,
                    left: newCompLeft,
                    top: newCompTop,
                    orderIndex: maxPageComponentsOrderIndex + index + 1,
                }, componentsMap, scroll, viewportDimensions.height, counter * pastePixelDiff);

                newComponentData = onBeforeComponentAdded(newComponentData, componentsMap);

                let updatedComponent = adjustComponentOnPaste(newComponentData, componentsDependencies[component.kind]);

                updatedComponent = componentsRegistry[updatedComponent.kind].copyHook(updatedComponent);
                const updatedComponentsMap = {
                    ...newComponentsMap,
                    [newComponentId]: updatedComponent
                };

                if (
                    (
                        updatedComponent.relIn && failedComponentsMap[updatedComponent.relIn.id]
                    ) || (NonTemplateComponents[updatedComponent.kind] &&
                        isNonTemplateComponentsInHeaderOrFooter(
                            [newComponentId],
                            attachments,
                            { ...componentsMap, ...updatedComponentsMap }
                        )) || someComponentsPlacedInModernSection([updatedComponent.id], { ...componentsMap, ...updatedComponentsMap })
                ) {
                    const placedInHeader = isComponentPlacedInHeader(updatedComponent.id, { ...componentsMap, ...updatedComponentsMap });
                    failedComponentsErrors.push({
                        componentKind: updatedComponent.kind,
                        message: placedInHeader ? CANNOT_ADD_COMPONENT_TO_HEADER : CANNOT_ADD_COMPONENT_TO_FOOTER,
                        showToaster: true
                    });
                    failedComponentsMap[oldToNewCmpIdMap[updatedComponent.id]] = true;
                    return acc;
                }

                return {
                    newComponentsMap: updatedComponentsMap,
                    newComponentsIds: [...newComponentsIds, newComponentId],
                    newSelectedComponentsIds: selectedComponentsIds.includes(component.id) ?
                        [...newSelectedComponentsIds, newComponentId] : newSelectedComponentsIds
                };
            },
            { newComponentsMap: moveDownComponents, newComponentsIds: [], newSelectedComponentsIds: [] }
        );
        const allCmpsmap = { ...componentsMap, ...updatedComponentsState.newComponentsMap };
        const newAttachments = createAttachments({
            componentsMap: allCmpsmap,
            newCmps: updatedComponentsState.newComponentsIds.map(cmpId => allCmpsmap[cmpId]),
            attachmentsOld: attachments,
        });

        // Updated relIn ids of wrapped components and wrap id references in wrapper components
        // TODO: find a better place for this
        updatedComponentsState.newComponentsIds.forEach(newComponentId => {
            const
                { newComponentsMap } = updatedComponentsState,
                component = newComponentsMap[newComponentId];

            if (component.wrap) {
                const
                    oldWrapperComponentId = component.relIn.id,
                    newWrapperComponentId = componentIdChangesMap[oldWrapperComponentId];

                if (newWrapperComponentId) {
                    component.relIn = {
                        ...component.relIn,
                        id: newWrapperComponentId
                    };
                } else {
                    component.wrap = false;
                }
            }

            if (component.kind === TextComponentKind) {
                const wrappedIds = getWrappedComponentIds(component.content);

                let newContent = component.content;
                wrappedIds.forEach(oldWrappedId => {
                    const newWrappedId = componentIdChangesMap[oldWrappedId];
                    newContent = newContent.replace(oldWrappedId, newWrappedId);
                });
                component.content = newContent;
            }
            component.inTemplate = allCmpsmap[getTopMostParentId(component.id, newAttachments)].inTemplate;
            if (component.hasOwnProperty('onHover') &&
                !getNearestParentHoverBoxCmp(component.id, newAttachments, newComponentsMap)) {
                component.onHover = null;
            }
        });

        if (newSectionId && updatedComponentsState.newComponentsIds.length > 1) {
            updatedComponentsState.newSelectedComponentsIds = [newSectionId];
        }

        return {
            newComponentsState: {
                ...updatedComponentsState,
                newSectionId,
                newSelectedComponentsIds: sanitizeSelectedComponentsForHoverBox(
                    updatedComponentsState.newSelectedComponentsIds,
                    newAttachments,
                    allCmpsmap
                )
            },
            failedComponentsErrors,
            componentIdChangesMap
        };
    },
    createFromClipboard: ComponentsEvalEpicUpdater = {
        conditions: [
            workspaceActionTypes.PASTE_FROM_SYSTEM_CLIPBOARD,
            receiveOnly(componentsClipboardValueActionType),
            receiveOnly(workspaceBBoxEpicValueActionType),
            optionalReceiveOnly(rightMouseDownPositionWithRespectToTemplateArea),
            optionalReceiveOnly(contextMenuValueActionType),
            receiveOnly(scrollValueActionType),
            ReceiveOnlyComponentsDependenciesSelector,
            ReceiveOnlySelectedComponentSelector,
            ROEditModeComponentIdSelector,
            receiveOnly(UserFocusValueActionType),
            receiveOnly(viewportDimensionsValueActionType),
            receiveOnly(componentAttachmentsVAT),
            ReceiveOnlyTemplateWidthActionType
        ],
        reducer: ({
            values: [
                clipboardData,
                components,
                workspaceBBox,
                rightMouseDownPosition,
                contextMenu,
                scroll,
                componentsDependencies,
                selectedComponent,
                editModeComponentId,
                userFocus,
                viewportDimensions,
                { attachments },
                templateWidth
            ],
            state: epicState
        }) => {
            if (shouldResetEditModeComponentId(userFocus.kind)) {
                return {
                    state: resetEditModeComponentId(epicState),
                    updateReason: getUpdateReasonForResetComponentModeId(userFocus.kind)
                };
            } else if (isAnyDialogInFocus(userFocus.kind)) {
                return { state: epicState };
            }

            let plaintext;
            let selectedComponents: ComponentsCollection;

            if (clipboardData) {
                const parsedClipboard = makeComponentsFromClipboard(clipboardData, scroll);

                plaintext = parsedClipboard.plaintext;
                selectedComponents = parsedClipboard.selectedComponents;
            } else {
                plaintext = false;
                selectedComponents = components;
            }

            // Don't create new components if text component is being edited and a single text component is being pasted
            if (
                plaintext
                && selectedComponent
                && (selectedComponent.kind === TextComponentKind || selectedComponent.kind === TableComponentKind)
                && selectedComponent.id === editModeComponentId
            ) {
                return { state: epicState };
            }

            const
                { opened = false } = contextMenu,
                componentsMap = getComponentsMapFromEpicState(epicState),
                commonHoverBox = getComponentsCommonHoverBox(selectedComponents.map(({ id }) => id), attachments, componentsMap),
                // @ts-ignore
                hoverBoxCmp = componentsMap[commonHoverBox];

            let counter = R.path(['scope', 'addingComponent', 'componentsAddedByPasteCounter'], epicState),
                position = (opened ? rightMouseDownPosition : null);

            if (
                commonHoverBox &&
                !someComponentsAreInHoverAndDefaultModes(selectedComponents) &&
                (!opened || isPointInsideBBox(
                    rightMouseDownPosition.x,
                    rightMouseDownPosition.y,
                    getComponentsBBox([hoverBoxCmp], workspaceBBox)
                ))
            ) {
                const inHoverMode = allComponentsInHoverMode(selectedComponents);
                if (hoverBoxCmp.hoverMode !== inHoverMode) {
                    const { left, top } = getComponentsBBox(selectedComponents, workspaceBBox);
                    counter = 0;
                    position = { x: left, y: top };
                    selectedComponents = selectedComponents.map(cmp => {
                        const onHover = { ...(cmp.onHover || {}), show: hoverBoxCmp.hoverMode };
                        return { ...cmp, onHover };
                    });
                }
            }

            const resultAfterPaste = createNewComponents({
                templateWidth,
                workspaceBBox,
                components: selectedComponents,
                componentsMap,
                mousePosition: position,
                counter,
                componentsDependencies,
                selectedComponentsIds: selectedComponents.map(({ id }) => id),
                attachments,
                viewportDimensions,
                scroll,
            });

            return createUpdaterResultAfterPaste(resultAfterPaste, epicState);
        }
    },
    duplicateComponents = ({
        componentIds,
        attachments,
        action,
        workspaceBBox,
        componentsDependencies,
        templateWidth,
        epicState,
        counter = 1,
    }: {
        componentIds: ComponentsIds,
        attachments: Attachments,
        action: string,
        workspaceBBox: any,
        componentsDependencies: any,
        templateWidth: number,
        epicState: any,
        counter?: number,
    }) => {
        const afterDuplicateUpdater = createUpdaterResultAfterDuplicateMap[action],
            componentsMap = getComponentsMapFromEpicState(epicState);
        const componentIdsToProcess = removeDuplicatesFromAnArrayOfStrings([
                ...getAllAttachmentsForCmpIds(attachments, componentIds),
                ...componentIds
            ]),
            selectedComponents = componentIdsToProcess.map(id => componentsMap[id]),
            sectionSelected = selectedComponents.some((cmp) => isSectionComponent(cmp)),
            resultAfterDuplicate = createNewComponents({
                templateWidth,
                workspaceBBox,
                components: selectedComponents,
                componentsMap,
                mousePosition: null,
                counter,
                componentsDependencies,
                selectedComponentsIds: componentIds,
                attachments,
            });
        const { componentIdChangesMap, newComponentsState } = resultAfterDuplicate;

        // when hoverBox child component is duplicated it should stay within the boundaries of hoverBox.
        if (action === COMPONENTS_DUPLICATED && !sectionSelected && newComponentsState) {
            const { newComponentsMap } = newComponentsState;
            componentIdsToProcess.forEach(cmpId => {
                const newCmpId = componentIdChangesMap[cmpId],
                    parentHoverBoxId = getNearestParentHoverBoxCmp(cmpId, attachments, componentsMap);
                if (newCmpId && parentHoverBoxId && !componentIdsToProcess.includes(parentHoverBoxId)) {
                    const newCmp = newComponentsMap[newCmpId],
                        { top, left } = componentsMap[cmpId],
                        parentBBox = getComponentsBBox([componentsMap[parentHoverBoxId]], workspaceBBox);
                    if (newCmp) {
                        const newCmpBBox = getComponentsBBox([newCmp], workspaceBBox),
                            position = {
                                top: parentBBox.bottom > newCmpBBox.bottom ? newCmp.top : top,
                                left: parentBBox.right > newCmpBBox.right ? newCmp.left : left,
                            };
                        newComponentsMap[newCmpId] = { ...newComponentsMap[newCmpId], ...position };
                    }
                }
            });
        }
        return afterDuplicateUpdater(resultAfterDuplicate, epicState);
    },
    duplicateComponentReducerFactory = (action) => {
        return ({
            values: [templateWidth, { attachments }, workspaceBBox, componentsDependencies, counter],
            state: epicState
        }) => {
            const
                componentsMap = getComponentsMapFromEpicState(epicState),
                selectedComponentsIds = getSelectedComponentIdsAlongWithWrappedIds(epicState)
                    .filter(id => !isHeaderOrFooterSection(componentsMap[id]));
            return duplicateComponents({
                componentIds: selectedComponentsIds,
                attachments,
                action,
                workspaceBBox,
                componentsDependencies,
                templateWidth,
                epicState,
                counter
            });
        };
    },
    duplicateComponentsByIDs: ComponentsEvalEpicUpdater = {
        conditions: [
            ReceiveOnlyTemplateWidthActionType,
            receiveOnly(componentAttachmentsVAT),
            receiveOnly(workspaceBBoxEpicValueActionType),
            ReceiveOnlyComponentsDependenciesSelector,
            workspaceActionTypes.DUPLICATE_COMPONENTS_BY_IDS
        ],
        reducer: ({
            values: [templateWidth, { attachments }, workspaceBBox, componentsDependencies, componentIds],
            state: epicState
        }) => {
            return duplicateComponents({
                componentIds,
                attachments,
                action: COMPONENTS_DUPLICATED,
                workspaceBBox,
                componentsDependencies,
                templateWidth,
                epicState
            });
        }
    },
    duplicateSelected: ComponentsEvalEpicUpdater = {
        conditions: [
            ReceiveOnlyTemplateWidthActionType,
            receiveOnly(componentAttachmentsVAT),
            receiveOnly(workspaceBBoxEpicValueActionType),
            ReceiveOnlyComponentsDependenciesSelector,
            workspaceActionTypes.DUPLICATE_SELECTED_COMPONENTS
        ],
        reducer: duplicateComponentReducerFactory(COMPONENTS_DUPLICATED)
    },
    duplicateSelectedSilent: ComponentsEvalEpicUpdater = {
        conditions: [
            ReceiveOnlyTemplateWidthActionType,
            receiveOnly(componentAttachmentsVAT),
            receiveOnly(workspaceBBoxEpicValueActionType),
            ReceiveOnlyComponentsDependenciesSelector,
            workspaceActionTypes.DUPLICATE_SELECTED_COMPONENTS_SILENT
        ],
        reducer: duplicateComponentReducerFactory(SILENT_COMPONENTS_DUPLICATED)
    },
    failedToAddComponentsUpdaterReducer = ({ values: [{ failedComponentsErrors }], state }) => ({
        state,
        actionToDispatch: failedComponentsErrors.length > 0 ?
            getAddComponentsNotAllowedAction(failedComponentsErrors) : null
    }),
    componentsPasted: ComponentsEvalEpicUpdater = {
        conditions: [COMPONENTS_PASTED],
        reducer: failedToAddComponentsUpdaterReducer
    },
    componentsDuplicated: ComponentsEvalEpicUpdater = {
        conditions: [COMPONENTS_DUPLICATED],
        reducer: failedToAddComponentsUpdaterReducer
    },
    resetCopyCounter: ComponentsEvalEpicUpdater = {
        conditions: [
            optional(workspaceActionTypes.COPY_SELECTED_COMPONENTS),
            optional(COMPONENTS_MAP_INITIALIZED)
        ],
        reducer: ({ state }) => ({
            state: R.evolve({
                scope: {
                    addingComponent: {
                        componentsAddedByPasteCounter: R.always(1)
                    }
                }
            }, state),
            updateReason: updateReasons.CHANGE_SCOPE
        })
    };

export {
    createFromClipboard,
    duplicateComponentsByIDs,
    duplicateSelected,
    componentsPasted,
    componentsDuplicated,
    resetCopyCounter,
    duplicateSelectedSilent
};
