import { normalizeLocale } from "../../../../../../server/shared/utils/normalizeLocale.js";
import { pathSelector } from "../../../../utils/pathSelector";
import languagesVAT from './valueActionType';
import { Lit } from "../../../../lit";
import type { AppState } from "../../../../redux/modules/flowTypes";

export const
    currentLanguageAppSel = pathSelector([
        Lit.epics,
        languagesVAT,
        Lit.state,
        Lit.current
    ]),
    appLanguagesState = (appState: AppState) => {
        const { epics } = appState;
        return epics[languagesVAT].state;
    },
    currentReadableLanguageAppSel = (appState: AppState) => {
        const currentLanguage = currentLanguageAppSel(appState);
        return appLanguagesState(appState).map[currentLanguage];
    },
    normalizedCurrentLangaugeAppSel = (appState: AppState) =>
        normalizeLocale(currentLanguageAppSel(appState));
