import * as R from "ramda";
import { makeRecursiveWalker } from "../../../utils/ramdaEx";
import { memoMaxOne } from "../../../../utils/memo";
import { DataSite } from "../../../../dal/model/index";
import { replaceTagsWithContent } from "../Text/view/replaceTagsWithContent";
import { isDataPageRefType } from "../../../../dal/model/utils/dataSiteItemUtils";
import MenuKind from "./kind";
import type { MenuComponent } from "./flowTypes";
import { isMediumLight, toHex } from "../../../mappers/color";
import { toHsl } from "../../../../dal/pageMapAdapter/mappers/Base/color";
import { isModernLayoutSection } from "../../ModernLayouts/preview_utils";
import { THRESHOLD_PARENT_OPACITY } from "../../ThemeGlobalData/constants";
import p from "../../../utils/pipePath";
import { styleBackgroundColor, inActiveBlockBackgroundColorDataColor } from "../../../mappers/path";
import type { SectionComponent } from "../Section/flowTypes";
import { WHITE_COLOR, BLACK_COLOR } from "./constants/colors";
import { textNormalFontSize, textNormalBold, textNormalItalic, textNormalFont } from "../../Workspace/epics/stylesheets/selectors";
import type { Color } from "../../../mappers/flowTypes";
import { doesFeaturedProductsExist } from "../FeaturedProducts/utils";
import { doesShopComponentExists } from "../WebShop/utils";
import { SiteSettings } from "../../App/epics/siteSettings/flowTypes";
import { AnyComponent } from "../../../utils/htmlWriter/flowTypes";

const { isBlogSubPage } = require("../../../../../server/shared/blog/utils.js");

const filterHiddenPagesRecursive = makeRecursiveWalker({
        filter: (page) => !isBlogSubPage(page) && !page.hidden
    }),
    makeSetCurrentPageIdAndHasChildren = memoMaxOne((currentPageId: string) =>
        makeRecursiveWalker({
            map: R.pipe(
                page => R.assoc("hasChildren", page.items && filterHiddenPagesRecursive(page.items).length, page),
                page => R.assoc("isCurrentPage", isDataPageRefType(page.type) && page.pageId && page.pageId === currentPageId, page)
            )
        })),
    makeSetPageNamesFromGlobalVariables = memoMaxOne((globalVariables: Record<string, any>, site: DataSite) => {
        return makeRecursiveWalker({
            map: page => {
                return R.evolve(
                    {
                        name: name =>
                            replaceTagsWithContent(name, {
                                site,
                                globalVariables
                            })
                    },
                    page
                );
            }
        });
    }),
    getDefaultAccentColor = (globalstyles: Record<string, any>) => {
        let color = toHsl(WHITE_COLOR),
            i = 1;

        while (color && isMediumLight(color)) {
            const btnRef = "button." + i;
            let style = globalstyles.styles.find(({ name, ref }) => name === btnRef || ref === btnRef);

            if (!style) {
                color = toHsl(BLACK_COLOR);
                break;
            }

            color = R.path(p(inActiveBlockBackgroundColorDataColor))(style) || toHsl(WHITE_COLOR);
            i++;
        }

        return color || toHsl(BLACK_COLOR);
    },
    getContrastMenuColor = (backgroundColor: Color, templateBackgroundColor?: Color) => {
        if (backgroundColor.length && backgroundColor[4] < THRESHOLD_PARENT_OPACITY) {
            return templateBackgroundColor && isMediumLight(templateBackgroundColor) ? BLACK_COLOR : WHITE_COLOR;
        } else {
            return isMediumLight(backgroundColor) ? BLACK_COLOR : WHITE_COLOR;
        }
    },
    getComputedStylesForMenu = (
        parentSection: SectionComponent,
        autoColorMode: boolean,
        component: MenuComponent,
        globalstyles: Record<string, any>,
        templateStyles?: Record<string, any>
    ) => {
        if (!parentSection || !isModernLayoutSection(parentSection) || !globalstyles) {
            return null;
        }

        let styles = {
            fontSize: textNormalFontSize(globalstyles),
            fontFamily: textNormalFont(globalstyles),
            bold: textNormalBold(globalstyles),
            italic: textNormalItalic(globalstyles)
        };

        if (!autoColorMode) {
            const backgroundColor = R.path(p(styleBackgroundColor))(parentSection);
            const color =
                backgroundColor && getContrastMenuColor(backgroundColor, templateStyles && templateStyles.background.colorData.color);
            styles = {
                ...styles,
                // @ts-ignore
                inActiveColor: color,
                activeColor: toHex((component.style && component.style.accentColor) || getDefaultAccentColor(globalstyles))
            };
        }

        return styles;
    },
    /**
     * Checks if the menu cart is allowed to be displayed in the menu.
     * The menu cart is allowed to be displayed only if the following conditions are met:
     * 1. There is only one menu on the page.
     * 2. The menu is located in the header section.
     * 3. There is a webshop component in any of the pages.
     */
    getIsMenuCartAllowed = (
        componentsMap: Record<string, any>,
        siteSettings: SiteSettings,
        siteMap: DataSite,
        cmpsInHeader: AnyComponent[]
    ) => {
        const featuredProductsExist = doesFeaturedProductsExist(siteSettings, siteMap);
        const shopComponentExists = doesShopComponentExists(siteSettings, siteMap);
        const isWebShopExists = featuredProductsExist || shopComponentExists;
        const menus: any = Object.values(componentsMap).filter((cmp: any) => cmp.kind === 'MENU');

        let isMenuCartAllowed = true;
        if (isWebShopExists && menus.length === 1) {
            const [menu] = menus;
            const isMenuInHeader = cmpsInHeader.findIndex(cmp => cmp.id === menu.id) !== -1;
            if (!isMenuInHeader) {
                isMenuCartAllowed = false;
            }
        } else {
            isMenuCartAllowed = false;
        }
        return isMenuCartAllowed;
    },
    isVerticalMenu = ({ kind, layoutType }: MenuComponent) => kind === MenuKind && layoutType.indexOf("VERTICAL_") > -1,
    isHorizontalMenu = ({ kind, layoutType }: MenuComponent) => kind === MenuKind && layoutType.indexOf("VERTICAL_") <= -1;

export {
    makeSetCurrentPageIdAndHasChildren,
    filterHiddenPagesRecursive,
    makeSetPageNamesFromGlobalVariables,
    getComputedStylesForMenu,
    getDefaultAccentColor,
    getContrastMenuColor,
    isVerticalMenu,
    isHorizontalMenu,
    getIsMenuCartAllowed
};
