import { connect } from "react-redux";
import React from "react";
import { pipe } from "ramda";
import cx from "classnames";
import { PaletteList } from "../../ThemesAccordion/ThemesAccordion";
import Scrollbar from "../../../view/common/Scrollbar/index";
import {
    HISTORY_POPUP,
    SET_SELECTED_PALETTE,
    SET_SELECTED_PALETTE_FOR_ONBOARDING,
    SET_SELECTED_PALETTE_FOR_TEMPLATE_SELECTOR
} from "../actionTypes";
import ICONS from "../../../view/Icons/index";
import Popup from "../../TopBar/view/Popup/index";
import * as styles from "../AutoColorLeftPanel.css";
import { injectIntl, Intl } from "../../../view/intl/index";
import { makeEpicStateSelector } from "../../../epics/makeEpic";
import AUTO_COLOR_LEFT_PANEL_EPIC_VALUE from "../epic/valueActionType";
import type { FullPaletteHsl } from "../../ThemesAccordion/flowTypes";
import { ThemeHistoryContext, useThemeHistoryContext } from "./ThemeHistoryContext";
import topBarPopupVAT from "../../TopBar/epics/popup/valueActionType";
import { Popups } from "../constants";
import appStatusValueActionType from "../../App/epics/status/valueActionType";
import { ONBOARDING } from "../../App/epics/status/appStatusTypes";
import templateSelectorValueActionType from "../../TemplateSelector_DEPRECATED/epics/templatesList/valueActionType";
import { CUSTOM } from "../../../constants/app";
import { templateSelectorPreviewStateSelector } from "../../TemplateSelector_DEPRECATED/epics/preview/selector";

type ThemeHistoryPopupProps = {
    intl: Intl;
    dispatch: Dispatch;
    leftPanel: {
        themeHistory: Array<FullPaletteHsl>;
    };
    appStatus: string;
    templateSelector: {
        show: boolean;
    };
    preview: {
        selectedColorPickerTheme: string;
    }
};
type ThemeHistoryPopupProviderProps = ThemeHistoryPopupProps & {
    popupId: string;
};

const ThemeHistoryIcon = ({ dispatch }: { dispatch: Dispatch }) => (
    <ICONS.REFRESH
        onClick={() =>
            dispatch({
                type: HISTORY_POPUP
            })}
    />
);

const DisplayThemeHistory = ({
    history,
    onPaletteSelected
}: {
    history: Array<FullPaletteHsl>;
    onPaletteSelected: (selectedPalette: FullPaletteHsl) => null;
}) => {
    if (!Array.isArray(history)) {
        return null;
    }

    const singlePaletteHeight = 43,
        scrollbarHeight = Math.min(history.length, 6) * singlePaletteHeight + 4;
    return (
        <Scrollbar height={scrollbarHeight + "px"}>
            <PaletteList palettes={history} selectedPaletteIndex={0} onPaletteSelected={onPaletteSelected} />
        </Scrollbar>
    );
};

const ThemeHistoryPopup_ = (props: ThemeHistoryPopupProps) => {
    const {
            intl,
            dispatch,
            leftPanel: { themeHistory },
            appStatus,
            templateSelector: { show: isFromTemplateSelector },
            preview: { selectedColorPickerTheme }
        } = props,
        // Refactor to context or pure redux later
        { show } = useThemeHistoryContext(),
        edit = selectedColorPickerTheme === CUSTOM;

    if (!show) {
        return null;
    }

    let setSelectedPaletteAction = SET_SELECTED_PALETTE;

    if (isFromTemplateSelector) {
        if (appStatus === ONBOARDING) {
            setSelectedPaletteAction = SET_SELECTED_PALETTE_FOR_ONBOARDING;
        } else {
            setSelectedPaletteAction = SET_SELECTED_PALETTE_FOR_TEMPLATE_SELECTOR;
        }
    }

    return (
        <Popup
            containerClassName={edit ? styles.editPopupContainer : styles.popupContainer}
            tipClassName={styles.popupTip}
            bodyClassName={styles.popupBodyContainer}
            includeCloseButton
            onClose={() => {
                dispatch({
                    type: HISTORY_POPUP
                });
            }}
        >
            <div className={cx(styles.historyTitle)}>
                {intl.msgJoint("msg: autoColor.leftPanel.colorThemeHistory {Colour theme history}")}
            </div>
            <DisplayThemeHistory
                history={themeHistory}
                // @ts-ignore
                onPaletteSelected={selectedPalette => {
                    dispatch({
                        type: setSelectedPaletteAction,
                        payload: selectedPalette
                    });
                }}
            />
        </Popup>
    );
};

const ThemeHistoryPopupWithProvider_ = (props: ThemeHistoryPopupProviderProps) => {
    const { popupId, ...rest } = props;
    return (
        <ThemeHistoryContext.Provider
            value={{
                show: popupId === Popups.THEME_HISTORY
            }}
        >
            <ThemeHistoryPopup_ {...rest} />
        </ThemeHistoryContext.Provider>
    );
};

const topBarPopupIdSelector = pipe(makeEpicStateSelector(topBarPopupVAT), popupIdState => popupIdState && popupIdState.id);

const mapStateToProps = appState => ({
    leftPanel: makeEpicStateSelector(AUTO_COLOR_LEFT_PANEL_EPIC_VALUE)(appState),
    popupId: topBarPopupIdSelector(appState),
    appStatus: makeEpicStateSelector(appStatusValueActionType)(appState),
    templateSelector: makeEpicStateSelector(templateSelectorValueActionType)(appState),
    preview: templateSelectorPreviewStateSelector(appState)
});

const ThemeHistoryPopup = injectIntl(connect(mapStateToProps)(ThemeHistoryPopupWithProvider_));
export { DisplayThemeHistory, ThemeHistoryIcon, ThemeHistoryPopup };
