import React from "react";
import { SideBarItemTooltip } from "../../SideBar/DesktopSideBar/SideBarItemTooltip";
import { useDispatch } from "react-redux";
import { makeStyles } from "@mui/styles";

import { useIntl } from "../../../../../../wbtgen/src/view/intl";
import { hideContactsTooltipAction } from "./actions";

const useStyles = makeStyles(
    (theme) => ({
        tooltipText: {
            display: "flex",
            flexDirection: "column",
            gap: "8px",
            color: theme.palette.custom.colorGray_3c
        },
        title: {
            fontSize: "20px",
            lineHeight: "24px"
        },
        description: {
            fontSize: "14px",
            lineHeight: "22px"
        },
    }),
    { name: "ContactsTooltip" }
);

export const ContactsTooltip = () => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const classes = useStyles();

    const hideTooltip = () => dispatch(hideContactsTooltipAction());

    return (
        <SideBarItemTooltip onClose={hideTooltip} data-testid="contacts-tooltip">
            <div className={classes.tooltipText}>
                <span className={classes.description}>
                    {intl.msgJoint("msg: contacts.tooltip {Manage your contacts here. Publish your website to start collecting data and growing your contact list.}")}
                </span>
            </div>
        </SideBarItemTooltip>
    );
};
