import type { Position } from "../redux/modules/flowTypes";

export const MouseDownWhich = {
    LEFT: 1,
    RIGHT: 3
};

type PositionCallback = (position: Position, e: React.MouseEvent<any>) => any

export function onMouseDown(
    e: React.MouseEvent<any>,
    onLeftMouseDown: PositionCallback,
    onRightMouseDown: PositionCallback
): void {
    // @ts-ignore
    switch (e.which || e.nativeEvent.which) {
        case MouseDownWhich.LEFT:
            onLeftMouseDown(getPosition(e), e);
            break;
        case MouseDownWhich.RIGHT:
            onRightMouseDown(getPosition(e), e);
            break;
        default:
            break;
    }
}

export function getPosition(e: React.MouseEvent<any>): Position {
    return {
        x: e.clientX,
        y: e.clientY
    };
}

export function getCurrentTarget(e: React.MouseEvent<any>): HTMLElement {
    return e.currentTarget;
}

export function getBoundingClientRect(e: React.MouseEvent<any>): DOMRect {
    const target = getCurrentTarget(e);

    return target.getBoundingClientRect();
}

export function isRightClick(e: React.MouseEvent<any>): boolean {
    // @ts-ignore
    const which = e.which || e.nativeEvent.which;
    return which === MouseDownWhich.RIGHT;
}
