const { ConfigComponentList } = require("../constants.js");

class TopBarConfig {
    isPublishButtonEnabled() {
        const topBar = this._featuresConfig[ConfigComponentList.TOP_BAR];
        return topBar && topBar.publishButton && topBar.publishButton.enabled;
    }

    isSubscriptionTypeLabelShown() {
        const topBar = this._featuresConfig[ConfigComponentList.TOP_BAR];
        return topBar && topBar.subscriptionTypeLabel && topBar.subscriptionTypeLabel.show;
    }

    isUpgradeButtonShown() {
        const topBar = this._featuresConfig[ConfigComponentList.TOP_BAR];
        return topBar && topBar.upgradeButton && topBar.upgradeButton.show;
    }

    isTopBarCPLinkShown() {
        const topBar = this._featuresConfig[ConfigComponentList.TOP_BAR];
        return topBar && topBar.goToCPLink && topBar.goToCPLink.show;
    }
}

module.exports = TopBarConfig;
