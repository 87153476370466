import makeEpic from '../../../../epics/makeEpic';
import { optional, receiveOnly } from "../../../../epics/makeCondition";
import localStorage from "../../../../utils/localStorage";
import { showBetaWelcomeMessageKey } from "../../../WelcomeMessage/epic/index";
import valueActionType from "./valueActionType";
import * as appStatusTypes from "./appStatusTypes";
import { loadSiteData } from "../../actionCreators/index";
import { AUTH_COOKIE_EXISTS, AUTH_COOKIE_MISSING } from "../auth/actionTypes";
import { TOP_BAR_LANGUAGE_HAS_SELECTED } from "../../../TopBar/actionTypes";
import {
    SITE_DATA_LOAD_FAIL,
    SITE_DATA_LOAD_SUCCESS_NO_PAGES,
    SITE_DATA_LOAD_SUCCESS_PAGES_EXIST,
    SITE_DATA_UPDATE_SUCCESS_NO_PAGES,
    SITE_DATA_LOAD_SUCCESS
} from "../../actionTypes";
import {
    SHOW_DEMO_LOGIN_SCREEN_ACTION,
    DEMO_LOGIN_AUTHENTICATION_SUCCESSFUL_ACTION,
    showDemoLoginScreenAction,
} from "../../../../../demo/actions";
import { createScheduledAction } from "../../../../redux/middleware/schedule/actionCreators";
import { WbtgenMigrationTimer } from "../../../../view/WbtgenMigration/WbtgenMigration.css";
import UserDataMigrationVAT from "../../../UserDataMigration/epic/valueActionType";
import {
    noUserDataMigrationRequired,
    userDataMigratedSuccessfully
} from "../../../UserDataMigration/utils";
import { SITE_SETTINGS_LOADED } from '../siteSettings/actionTypes';
import {
    DYNAMIC_TEMPLATE_EXIT_ONBOARDING,
    LOAD_TEMPLATE_SELECTOR,
    RETURN_TO_ONBOARDING,
    TEMPLATE_IMPORT_COMPLETE,
    PRE_SELECTED_TEMPLATE_PREVIEW_CLICKED
} from '../../../TemplateSelector_DEPRECATED/actionTypes';
import { generalGlobalDataEpic } from '../../../SiteSettings/General/generalGlobalDataEpic/generalGlobalDataEpic';
import { ONBOARDING_OPENED_ON_APP_LOAD } from './actionTypes';
import { AWS_AUTHENTICATION_UNSUCCESSFUL } from "../../../../redux/middleware/aws/actions";
import {
    LOAD_TRIAL_IMPORT_AVAILABILITY_REQUEST_ACTION,
    LOAD_TRIAL_IMPORT_AVAILABILITY_FAILURE_ACTION,
    LOAD_TRIAL_IMPORT_AVAILABILITY_SUCCESS_ACTION
} from "../../../../../demo/modules/import/actions";
import { isWsbDemo } from "../../../../debug/isWsbDemo";
import { isTrialImportAvailableSessionKey } from "./constants";
import { readSelectedPublicTemplateAccountName,
    dropSelectedPubicTemplate, readTrialOrigin, dropTrialOrigin } from "../../../TemplateSelector/public/selectedPublicTemplate";
import { TRIAL_ORIGIN, TRIAL_PRE_SELECTED_TEMPLATES } from '../../../Onboarding/Dynamic/constants';
import subscriptionDataVAT from '../subscriptionData/valueActionType';
import { isTrialSubscription } from '../subscriptionData/utils';
import { SET_TRIAL_USER_EMAIL_ACTION } from '../../../../../demo/modules/actions';
import { clearGptDataInUrl } from '../../../Onboarding/Dynamic/Epic/utils';

const WBTGEN_MIGRATION_SCREEN_TIMER = parseInt(WbtgenMigrationTimer, 10) * 1000,
    HIDE_WBTGEN_MIGRATION_SCREEN = 'HIDE_WBTGEN_MIGRATION_SCREEN',
    makeDelayedAppStatusLoadingShowWbtgenMigrationAction = scope => ({
        scope,
        state: appStatusTypes.SHOW_WBTGEN_MIGRATION_SCREEN,
        actionToDispatch: createScheduledAction({
            actionToDispatch: { type: HIDE_WBTGEN_MIGRATION_SCREEN },
            timeout: WBTGEN_MIGRATION_SCREEN_TIMER
        })
    }),
    AppStatusLoadingLoadSiteDataAction = {
        state: appStatusTypes.LOADING,
        actionToDispatch: loadSiteData()
    };

const shouldShowMigrationScreen = () => {
    return localStorage.get(showBetaWelcomeMessageKey, true) === 'yes';
};

export default makeEpic({
    defaultState: appStatusTypes.AUTHENTICATING,
    defaultScope: {},
    valueActionType,
    updaters: [
        {
            conditions: [
                optional(SITE_DATA_LOAD_SUCCESS_NO_PAGES),
                optional(SITE_DATA_LOAD_SUCCESS_PAGES_EXIST),
                optional(TEMPLATE_IMPORT_COMPLETE),
                optional(SITE_DATA_LOAD_SUCCESS),
                optional(SET_TRIAL_USER_EMAIL_ACTION),
                receiveOnly(subscriptionDataVAT),
                SITE_SETTINGS_LOADED,
                AUTH_COOKIE_EXISTS
            ],
            reducer: ({
                state,
                scope,
                values: [noPages, pagesExist, templateImportComplete, site, userInfo, subscriptionData]
            }) => {
                const trialImportIsAvailable = window.sessionStorage.getItem(isTrialImportAvailableSessionKey) === 'true';
                if (pagesExist || templateImportComplete) {
                    if (isTrialSubscription(subscriptionData.subscriptionType) && userInfo?.trialUserEmail) {
                        dropSelectedPubicTemplate();
                        const selectedTemplateLocalStorage = localStorage.get(TRIAL_PRE_SELECTED_TEMPLATES) || {};
                        const trialEmail = userInfo?.trialUserEmail;
                        if (selectedTemplateLocalStorage[trialEmail]) {
                            delete selectedTemplateLocalStorage[trialEmail];
                            localStorage.set(TRIAL_PRE_SELECTED_TEMPLATES, selectedTemplateLocalStorage);
                        }
                        // remove trial origin from local storage
                        localStorage.remove(TRIAL_ORIGIN);
                        dropTrialOrigin();
                    }
                    return { state: appStatusTypes.READY, scope };
                } else if ((!site.fonts || !site.fonts.length) && !site.folder.items.length && !isWsbDemo() && trialImportIsAvailable) {
                    window.sessionStorage.removeItem(isTrialImportAvailableSessionKey);
                    return {
                        state: appStatusTypes.TRIAL_IMPORT,
                        scope,
                    };
                } else if (noPages) {
                    if (isTrialSubscription(subscriptionData.subscriptionType) && userInfo?.trialUserEmail) {
                        const accountName = readSelectedPublicTemplateAccountName();
                        const trialOrigin = readTrialOrigin();
                        if (trialOrigin) {
                            localStorage.set(TRIAL_ORIGIN, trialOrigin);
                        }
                        if (accountName) {
                            const { trialUserEmail } = userInfo;
                            const selectedTemplateLocalStorage = localStorage.get(TRIAL_PRE_SELECTED_TEMPLATES) || {};
                            if (
                                trialUserEmail &&
                                selectedTemplateLocalStorage[trialUserEmail] !== accountName
                            ) {
                                selectedTemplateLocalStorage[trialUserEmail] = accountName;
                                localStorage.set(TRIAL_PRE_SELECTED_TEMPLATES, selectedTemplateLocalStorage);
                            }
                        }
                    }
                    return {
                        state: appStatusTypes.ONBOARDING,
                        scope,
                        multipleActionsToDispatch: [{ type: ONBOARDING_OPENED_ON_APP_LOAD }]
                    };
                } else {
                    return { state, scope };
                }
            }
        },
        {
            conditions: [
                LOAD_TRIAL_IMPORT_AVAILABILITY_SUCCESS_ACTION,
            ],
            reducer: ({
                scope,
            }) => {
                window.sessionStorage.setItem(isTrialImportAvailableSessionKey, "true");

                return {
                    state: appStatusTypes.TRIAL_IMPORT,
                    scope,
                };
            }
        },
        {
            conditions: [
                receiveOnly(generalGlobalDataEpic.valueActionType),
                SITE_DATA_UPDATE_SUCCESS_NO_PAGES,
                LOAD_TRIAL_IMPORT_AVAILABILITY_REQUEST_ACTION,
            ],
            reducer: ({ scope }) => {
                return { state: appStatusTypes.TRIAL_IMPORT, scope };
            }
        },
        {
            conditions: [
                receiveOnly(generalGlobalDataEpic.valueActionType),
                SITE_DATA_UPDATE_SUCCESS_NO_PAGES,
            ],
            reducer: ({ scope }) => {
                return { state: appStatusTypes.ONBOARDING, scope };
            }
        },
        {
            conditions: [
                LOAD_TRIAL_IMPORT_AVAILABILITY_FAILURE_ACTION
            ],
            reducer: ({ scope }) => {
                window.sessionStorage.removeItem(isTrialImportAvailableSessionKey);
                return { state: appStatusTypes.ONBOARDING, scope };
            }
        },
        {
            conditions: [AUTH_COOKIE_MISSING],
            reducer: ({ scope }) => ({ state: appStatusTypes.REDIRECTING_TO_LOGIN, scope })
        },
        {
            conditions: [AUTH_COOKIE_EXISTS],
            reducer: ({ scope }) => {
                return {
                    state: appStatusTypes.MIGRATING_USER_DATA,
                    scope,
                };
            }
        },
        {
            conditions: [UserDataMigrationVAT],
            reducer: ({ values: [userDataMigrationStatus], state, scope }) => {
                if (
                    noUserDataMigrationRequired(userDataMigrationStatus) ||
                    userDataMigratedSuccessfully(userDataMigrationStatus)
                ) {
                    /**
                     * NOTE: shouldShowMigrationScreen reads a key from localstorage which is set by the old WSB when user choses to migrate to new WSB.
                     *       At the time in future, when it is decided to stop showing the migration screen, AppStatusLoadingLoadSiteDataAction can be
                     *       directly returned and removed the following variables and their corresponding usages and the dependencies.
                     *          - shouldShowMigrationScreen.
                     *          - WBTGEN_MIGRATION_SCREEN_TIMER
                     *          - HIDE_WBTGEN_MIGRATION_SCREEN
                     *          - makeDelayedAppStatusLoadingShowWbtgenMigrationAction
                     */
                    if (shouldShowMigrationScreen()) {
                        return makeDelayedAppStatusLoadingShowWbtgenMigrationAction(scope);
                    }

                    return { ...AppStatusLoadingLoadSiteDataAction, scope };
                }

                return { state, scope };
            }
        },
        {
            conditions: [LOAD_TEMPLATE_SELECTOR], // TODO template selector should be a separate app state (if not possible comment why)
            reducer: ({ scope }) => ({ state: appStatusTypes.READY, scope })
        },
        {
            conditions: [DYNAMIC_TEMPLATE_EXIT_ONBOARDING],
            reducer: ({ scope }) => ({ state: appStatusTypes.READY, scope })
        },
        {
            conditions: [PRE_SELECTED_TEMPLATE_PREVIEW_CLICKED],
            reducer: ({ scope }) => ({ state: appStatusTypes.READY, scope })
        },
        {
            conditions: [RETURN_TO_ONBOARDING],
            reducer: ({ scope }) => {
                clearGptDataInUrl();

                return { state: appStatusTypes.ONBOARDING, scope };
            }
        },
        {
            conditions: [HIDE_WBTGEN_MIGRATION_SCREEN],
            reducer: ({ scope }) => ({ ...AppStatusLoadingLoadSiteDataAction, scope })
        },
        {
            conditions: [SITE_DATA_LOAD_FAIL, AUTH_COOKIE_EXISTS],
            reducer: ({ scope }) => ({ state: appStatusTypes.READY, scope })
        },
        {
            conditions: [TOP_BAR_LANGUAGE_HAS_SELECTED],
            reducer: ({ scope }) => ({ state: appStatusTypes.RELOADING, scope })
        },
        {
            conditions: [AWS_AUTHENTICATION_UNSUCCESSFUL],
            reducer: ({ state, scope }) => ({
                state,
                scope,
                actionToDispatch: (showDemoLoginScreenAction())
            })
        },
        {
            conditions: [SHOW_DEMO_LOGIN_SCREEN_ACTION],
            reducer: ({ scope }) => ({ state: appStatusTypes.DEMO_LOGIN_AUTHENTICATION, scope })
        },
        {
            conditions: [DEMO_LOGIN_AUTHENTICATION_SUCCESSFUL_ACTION],
            reducer: ({ scope }) => ({ state: appStatusTypes.AUTHENTICATING, scope })
        }
    ]
});
