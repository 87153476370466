import * as R from 'ramda';
import makeEpic from '../../../../epics/makeEpic';
import { receiveOnly } from '../../../../epics/makeCondition';
import { SAVE_CLICKED, CTRL_S_PRESSED, SAVE_REQUEST, SAVE_AND_PUBLISH_BUTTON_CLICKED } from '../../../App/actionTypes';
import valueActionType from './valueActionType';
import makeStateSelectorReducer from '../../../../epics/makeStateSelectorReducer';
import { PAGE_DATASET_SAVE_FAILURE,
    PAGE_DATASET_SAVE_SUCCESS,
    UPDATE_PAGE_SAVE_STATUS_ON_NOCHANGE } from '../../../App/epics/pageDataset/actions';
import * as actionTypes from "./actionTypes";
import { undoManagerValueActionType } from "../../../../epics/undoManager/valueActionType";
import { SaveStatus } from "./SaveStatus";

import { customSendReport } from "../../../../customSendCrashReport";
import checkReservedContent from './actionCreators/checkReservedContent';
import { truncateIfLonger256KB, truncateIfLonger64KB } from "../../../../../utils/string.js";
import {
    getCrashTraceStringified
} from "../../../../redux/middleware/crashTraceCollector";
import { getNetworkLog } from "../../../../../dal/index";
import { USER_PUBLISH_ACTION_SUCCESSFUL } from "../../../TopBar/actionTypes";
import { UNDO_STACK_MODIFIED } from '../../../../epics/undoManager/actionTypes';
import { SAVE_VIA_AUTOSAVE } from "../../../Autosave/actions";
import { topMostDialogIdSelector } from "../../../DialogManager/epic/selectors";
import { dialogManagerVAT } from "../../../DialogManager/epic/valueActionType";
import { UnsavedChangesDialogId } from '../../../UnsavedChanges/dialog';
import { closeDialog } from '../../../App/actionCreators';

const
    defaultState = SaveStatus.CAN_NOT_SAVE,
    defaultScope = {
        saveInProgress: false,
        undoManagerSavePointIndex: 0,
        undoManagerCurrentIndex: 0,
        componentReservedContent: false
    },
    setSaveInProgress = R.assoc('saveInProgress'),
    setComponentReservedContent = R.assoc('componentReservedContent'),
    syncUndoManagerSavePointIndexToCurrentSavePointIndex =
        (scope) => R.assoc('undoManagerSavePointIndex', scope.undoManagerCurrentIndex, scope),
    setUndoManagerCurrentSavePointIndex = R.assoc('undoManagerCurrentIndex'),
    epic = makeEpic({
        defaultState,
        defaultScope,
        valueActionType,
        afterUpdate: ({ nextState, nextScope }) => {
            if (nextScope.saveInProgress) {
                if (nextState === SaveStatus.IN_PROGRESS) {
                    return { state: nextState, scope: nextScope };
                } else {
                    return { state: SaveStatus.IN_PROGRESS, scope: nextScope };
                }
            }

            if (nextScope.undoManagerSavePointIndex === nextScope.undoManagerCurrentIndex) {
                return { state: SaveStatus.CAN_NOT_SAVE, scope: nextScope };
            } else {
                return { state: SaveStatus.CAN_SAVE, scope: nextScope };
            }
        },
        updaters: [
            {
                conditions: [UNDO_STACK_MODIFIED],
                reducer: ({ state, scope, values: [modifiedIndex] }) => {
                    if (scope.undoManagerSavePointIndex >= modifiedIndex) {
                        return { state, scope: { ...scope, undoManagerSavePointIndex: -1 } };
                    } else {
                        return { state, scope };
                    }
                },
            },
            {
                conditions: [undoManagerValueActionType],
                reducer: ({ values: [{ undoState }], state, scope }) => {
                    if (undoState.commandsStack.length === 1) {
                        // after page load command stack will always have 1 command only
                        return {
                            state,
                            scope: defaultScope
                        };
                    }
                    if (scope.undoManagerCurrentIndex !== undoState.commandsStackIndex) {
                        return {
                            state,
                            scope: setUndoManagerCurrentSavePointIndex(undoState.commandsStackIndex, scope)
                        };
                    }
                    return { state, scope };
                }
            },
            ...[CTRL_S_PRESSED, SAVE_CLICKED, SAVE_AND_PUBLISH_BUTTON_CLICKED, SAVE_VIA_AUTOSAVE].map(triggerAction => ({
                conditions: [
                    triggerAction,
                    receiveOnly(dialogManagerVAT, topMostDialogIdSelector),
                ],
                reducer: ({ state, scope, values: [, dialogId] }) => {
                    if (state === SaveStatus.CAN_SAVE) {
                        const multipleActionsToDispatch: Array<Action> = [];

                        if (dialogId && dialogId === UnsavedChangesDialogId) {
                            multipleActionsToDispatch.push(closeDialog());
                        }

                        multipleActionsToDispatch.push({ type: SAVE_REQUEST });

                        return {
                            state,
                            scope: setSaveInProgress(true, scope),
                            multipleActionsToDispatch,
                        };
                    }

                    return { state, scope };
                }
            })),
            {
                keepFullActions: true,
                conditions: [PAGE_DATASET_SAVE_FAILURE],
                reducer: ({ state, scope, values: [{ response }] }) => {
                    const responseTruncator = response.useLenientTruncation ? truncateIfLonger256KB : truncateIfLonger64KB;
                    customSendReport({
                        message: "SAVE_FAILED",
                        additionalInfo: {
                            response: responseTruncator(JSON.stringify(response)),
                            networkLog: getNetworkLog(),
                            actionsTraceStr: truncateIfLonger256KB(getCrashTraceStringified())
                        }
                    });

                    return {
                        state,
                        scope: setSaveInProgress(false, scope)
                    };
                }
            },
            {
                conditions: [
                    PAGE_DATASET_SAVE_SUCCESS
                ],
                reducer: ({ state, scope }) => {
                    return {
                        state,
                        scope: R.pipe(
                            setSaveInProgress(false),
                            syncUndoManagerSavePointIndexToCurrentSavePointIndex,
                        )(scope),
                        actionToDispatch: {
                            type: actionTypes.WORKSPACE_SAVE_STATUS_CANT_SAVE_AFTER_SUCCESS_SAVE
                        }
                    };
                }
            },
            {
                conditions: [UPDATE_PAGE_SAVE_STATUS_ON_NOCHANGE],
                reducer: ({ state, scope }) => ({
                    state,
                    scope: R.pipe(
                        setSaveInProgress(false),
                        setUndoManagerCurrentSavePointIndex(scope.undoManagerSavePointIndex)
                    )(scope)
                })
            },
            {
                conditions: [USER_PUBLISH_ACTION_SUCCESSFUL],
                reducer: ({ state, scope }) => {
                    return {
                        state,
                        scope,
                        actionToDispatch: checkReservedContent()
                    };
                }
            },
            {
                conditions: [actionTypes.CHECK_RESERVED_CONTENT_SUCCESS],
                reducer: ({ values: [reservedContentStatus], state, scope }) => {
                    let actionToDispatch: Action | null = null;
                    if (reservedContentStatus && !reservedContentStatus.reservedContentPresent) {
                        actionToDispatch = { type: actionTypes.TRACK_RESERVED_CONTENT_NOT_IN_WEBSITE };
                    }
                    return {
                        state: setComponentReservedContent(reservedContentStatus.reservedContentPresent)(state),
                        scope,
                        actionToDispatch
                    };
                }
            }
        ]
    }),
    saveStatusReducer = makeStateSelectorReducer(
        epic.reducer,
        valueActionType,
        R.identity
    );

export {
    epic as default,
    saveStatusReducer
};
