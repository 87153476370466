/* @flow */

const extend = require('extend');
const { Ctor } = require('../../../server/shared/utils/ctor/Ctor.js');
const { AwsDynamoDb } = require("../../../server/shared/awsServices/dynamoDb/AwsDynamoDb.js");
const { getTrialRuntime } = require("../trialRuntime.js");

/*::
    import type { AwsDynamoDbOptions } from '../../../server/shared/awsServices/dynamoDb/types';
    import type { AWSRequest } from '../../../server/shared/awsServices/types';
    import type { AwsTrialConfig } from '../types'
 */

function BaseTrialDb(config/*: AwsTrialConfig */) {
    /*=== Constructor ===*/

    const defaultDbOptions/*: AwsDynamoDbOptions */ = makeDefaultDbOptions();

    /*=== Protected ===*/

    const pm = {};

    pm.dyDb = (extraOptions/*: AwsDynamoDbOptions */) => {
        const dbOptions = extend(true, {}, defaultDbOptions, extraOptions);
        return new AwsDynamoDb(dbOptions);
    };

    /**
     * Fallback method to overcome Ctor.protected problem.
     */
    this.dyDb = (extraOptions/*: AwsDynamoDbOptions */) => {
        const dbOptions = extend(true, {}, defaultDbOptions, extraOptions);
        return new AwsDynamoDb(dbOptions);
    };

    pm.defaultTableName = config.dynamoDbTableName;

    Ctor.protected(BaseTrialDb, pm);

    /*=== Private ===*/

    function makeDefaultDbOptions() {
        const dbOptions/*: AwsDynamoDbOptions */ = {
            region: config.region, // TODO: figure out how to use with endpoint
            convertEmptyValues: true,
            params: {
                TableName: config.dynamoDbTableName,
            },
        };

        if (config.localstackEndpoints && config.localstackEndpoints.DynamoDB) {
            let endpoint = config.localstackEndpoints.DynamoDB;
            if (config.localstackEndpoints.serverHostName && getTrialRuntime().isServer()) {
                endpoint = endpoint.replace('localhost', config.localstackEndpoints.serverHostName);
            }
            dbOptions.endpoint = endpoint;
        }

        if (config.server && config.server.accessKeyId && config.server.secretAccessKey
            && !getTrialRuntime().isWbtgen()) {
            dbOptions.accessKeyId = config.server.accessKeyId;
            dbOptions.secretAccessKey = config.server.secretAccessKey;
        }

        return dbOptions;
    }
}

module.exports = {
    BaseTrialDb,
};
