import BookingsKind from "./kind";
import { getBookingParams } from "./utils";
import view from "./view/index";

// config for how the component should behave in preview
// e.g. what it should render
export default {
    kind: BookingsKind,
    view,
    calcProps: (props: any) => {
        const { isServerPreview, component, selectedParentTheme, stylesheetsIdToNameMap } = props;
        const bookingParams = getBookingParams({ component, selectedParentTheme, stylesheetsIdToNameMap });
        return { isServerPreview, bookingParams };
    }
};
