/* eslint-disable max-len */

import React from "react";
import { pathOr } from 'ramda';

import { Msg } from "../../../view/intl";
import { SubscriptionType } from "../../../../../server/shared/accessManager/constants.js";
import type { SubscriptionTypeT } from "../../App/epics/subscriptionData/flowTypes";
import { isNormalSubscription, isPremiumSubscription } from "../../../../../server/shared/accessManager/utils.js";
import { SaveStatus } from "../../Workspace/epics/saveStatus/SaveStatus";
import type { ComponentTierManagerStateType } from "../../ComponentTierManager/epics";
import type { SubscriptionMetadata } from "../../../redux/modules/flowTypes";
import { getPremiumPackageNameFromMetadata, getEcommercePackageNameFromMetadata } from "../../ComponentTierManager/utils";
import type { Intl } from "../../../view/intl/injectIntl";
import { getPageLimitBySubscriptionType, getSectionLinksLimitBySubscriptionType } from "../../App/epics/subscriptionData/utils";
import { getPublicSectionLinks } from "../../App/epics/siteData/utils/pages";

type TopBarUpgradeMsgProps = {
    subscriptionType: SubscriptionTypeT;
    subscriptionMetadata?: SubscriptionMetadata;
    componentTierManagerState: ComponentTierManagerStateType;
    isPagesOrSectionsExceeded: boolean;
    saveStatus: string;
    intl: Intl;
};

export const getSubscriptionLabel = (subscriptionType: SubscriptionTypeT, subscriptionMetadata: SubscriptionMetadata | undefined, intl: Intl) => {
    const eCommercePackageNameFromMetadata = getEcommercePackageNameFromMetadata(subscriptionMetadata);
    const SubscriptionLabels = {
        [SubscriptionType.UNKNOWN]: intl.msgJoint("msg: topbar.subscription.unknown {UNKNOWN}"),
        [SubscriptionType.TRIAL]: intl.msgJoint("msg: topbar.subscription.trial {TRIAL}"),
        [SubscriptionType.BASIC]: intl.msgJoint("msg: topbar.subscription.basic {BASIC}"),
        [SubscriptionType.NORMAL]: intl.msgJoint("msg: topbar.subscription.normal {STARTER}"),
        [SubscriptionType.PREMIUM]: intl.msgJoint("msg: topbar.subscription.premium {PREMIUM}"),
        [SubscriptionType.ECOMMERCE]: intl.msgJoint(["msg: topbar.subscription.packageName {{packageName}}", { packageName: eCommercePackageNameFromMetadata || intl.msgJoint("msg: topbar.subscription.ecommerce {BUSINESS + ECOMMERCE}") }]),
        [SubscriptionType.DEMO]: intl.msgJoint("msg: topbar.subscription.trial {TRIAL}"),
        [SubscriptionType.FREE]: intl.msgJoint("msg: topbar.subscription.free {FREE}"),
        [SubscriptionType.PAID]: intl.msgJoint("msg: topbar.subscription.paid {PAID}"),
        [SubscriptionType.ECOMMERCE_DIFM]: intl.msgJoint("msg: topbar.subscription.ecommerce_dim {Ecommerce DIFM}")
    };

    return pathOr(
        SubscriptionLabels[subscriptionType],
        ['subscriptionLabels', subscriptionType],
        subscriptionMetadata,
    );
};

export const getTopbarUpgradeMsg = ({
    subscriptionType,
    subscriptionMetadata,
    isPagesOrSectionsExceeded,
    componentTierManagerState,
    saveStatus,
    intl
}: TopBarUpgradeMsgProps) => {
    const {
            freeTierUpgradeSubscriptionType,
            isIncompatibleComponentPresent,
            isIncompatibleComponentPresentInCurrentPage,
            isIncompatibleComponentPresentInOtherPages
        } = componentTierManagerState,
        premiumPackageNameFromMetadata = getPremiumPackageNameFromMetadata(subscriptionMetadata),
        eCommercePackageNameFromMetadata = getEcommercePackageNameFromMetadata(subscriptionMetadata);
    let upgradeMsg = (
        <Msg k="upgrade.upgradeOption.standardSubscription.incompatibleComponentAbsentAndNotExceedPagesOrSections.msg">
            Access great features like Facebook gallery, up to 200 pages and a faster website.
        </Msg>
    );
    let incompatibleComponentPresent;

    if (saveStatus === SaveStatus.CAN_NOT_SAVE) {
        incompatibleComponentPresent = isIncompatibleComponentPresent;
    } else if (saveStatus === SaveStatus.CAN_SAVE) {
        incompatibleComponentPresent = isIncompatibleComponentPresentInCurrentPage || isIncompatibleComponentPresentInOtherPages;
    }
    if (isPremiumSubscription(subscriptionType)) {
        if (incompatibleComponentPresent) {
            upgradeMsg = (
                <Msg
                    k="upgrade.upgradeOption.premiumSubscription.incompatibleComponentPresent.msg"
                    params={{
                        packageName:
                            eCommercePackageNameFromMetadata || intl.msgJoint("msg: common.business_ecommerce {Business + Ecommerce}")
                    }}
                >{`
                It looks like you’ve added components from the {packageName} plan. You’ll need to upgrade to publish your site with these features.
            `}</Msg>
            );
        } else {
            upgradeMsg = (
                <Msg k="upgrade.upgradeOption.premiumSubscription.incompatibleComponentAbsent.msg">
                    Access more great features like Google Analytics, Facebook Pixel and Online Shop.
                </Msg>
            );
        }
    } else if (isNormalSubscription(subscriptionType)) {
        if (incompatibleComponentPresent && freeTierUpgradeSubscriptionType === SubscriptionType.ECOMMERCE) {
            upgradeMsg = (
                <Msg
                    k="upgrade.upgradeOption.standardSubscription.incompatibleEcommerceComponentPresent.msg"
                    params={{
                        packageName:
                            eCommercePackageNameFromMetadata || intl.msgJoint("msg: common.business_ecommerce {Business + Ecommerce}")
                    }}
                >{`
                It looks like you’ve added components from the {packageName} plan. You’ll need to upgrade to publish your site with these features.
            `}</Msg>
            );
        } else if (
            (!incompatibleComponentPresent && !isPagesOrSectionsExceeded) ||
            (incompatibleComponentPresent && isPagesOrSectionsExceeded)
        ) {
            upgradeMsg = (
                <Msg k="upgrade.upgradeOption.standardSubscription.incompatibleComponentAbsentAndNotExceedPagesOrSections.msg">
                    Access great features like Facebook gallery, up to 200 pages and a faster website.
                </Msg>
            );
        } else if (!incompatibleComponentPresent && isPagesOrSectionsExceeded) {
            upgradeMsg = (
                <Msg
                    k="upgrade.upgradeOption.standardSubscription.incompatibleComponentAbsentAndExceedPagesOrSections.msg"
                    params={{ packageName: premiumPackageNameFromMetadata || intl.msgJoint("msg: common.premium {Premium}") }}
                >{`
                Your current Website Builder can only publish up to 5 pages and up to 5 section links. Upgrade to {packageName} and publish up to 200 pages.
            `}</Msg>
            );
        } else if (incompatibleComponentPresent && !isPagesOrSectionsExceeded) {
            upgradeMsg = (
                <Msg
                    k="upgrade.upgradeOption.standardSubscription.incompatibleComponentPresentAndNotExceedPagesOrSections.msg"
                    params={{ packageName: premiumPackageNameFromMetadata || intl.msgJoint("msg: common.premium {Premium}") }}
                >{`
                It looks like you’ve added components from the {packageName} plan. You’ll need to upgrade to publish your site with these features.
            `}</Msg>
            );
        }
    }
    return upgradeMsg;
};

export const shouldShowUpgradeBanner = (site, subscriptionType) => {
    const pageAddLimitExceeded = site.getPageIdsNotMarkedAsDontPublish().length > getPageLimitBySubscriptionType(subscriptionType);
    const sectionsAddLimitExceeded = getPublicSectionLinks(site).length > getSectionLinksLimitBySubscriptionType(subscriptionType);

    return pageAddLimitExceeded || sectionsAddLimitExceeded;
};
