import CALL_API from '../../../redux/middleware/api/CALL_API';
import type { ApiAction, ApiActionTypes } from "../../../redux/middleware/api/flowTypes";

export default function (
    path: string,
    forceFetch: boolean,
    types: ApiActionTypes,
    opts?: Record<string, any>,
): ApiAction {
    return {
        [CALL_API]: {
            types,
            endpoint: 'getWebSpaceContent',
            endpointParams: [path, forceFetch, opts]
        }
    };
}
