import React, { useState } from 'react';
import { InputBase } from '@mui/material';
import { Msg, useIntl } from '../../../../../../../view/intl';
import { MAX_PROMPT_LENGTH } from './constants';
import styles from './styles.css';

export const TextPromptField = ({ onSend, prompt, onPromptChange }) => {
    const intl = useIntl();
    const [text, setText] = useState("");
    const [exceededMaxLimit, setExceededMaxLimit] = useState(false);
    const [emptyPromptError, setEmptyPromptError] = useState(false);

    const onAction = (event, fn) => {
        if (!exceededMaxLimit) {
            fn(event);
        }
    };
    const onSendAction = (event) => (text.trim().length ? onAction(event, onSend) : setEmptyPromptError(true));

    return (
        <div>
            <div
                className={`${styles.textPropmtContainer} ${(exceededMaxLimit || emptyPromptError) ? styles.redBorder : ''}`}
                data-testid="ai-edit-text-input-prompt"
            >
                <InputBase
                    className={styles.textPropmtField}
                    placeholder={
                        // eslint-disable-next-line max-len
                        intl.msgJoint('msg: component.text.editAIText.dialog.textPrompt.placeHolder {Want to change something else? Write your own instructions here.}')
                    }
                    multiline
                    minRows={2}
                    maxRows={2}
                    classes={{
                        input: styles.input
                    }}
                    value={prompt}
                    onChange={(event) => {
                        setEmptyPromptError(false);
                        setText(event.target.value);
                        setExceededMaxLimit(event.target.value.length > MAX_PROMPT_LENGTH);
                        onPromptChange(event.target.value);
                    }}
                    onKeyDown={(e) => {
                        const target = e.target as HTMLTextAreaElement;
                        // ignore shift enter
                        if (!e.shiftKey && e.key === 'Enter') {
                            e.preventDefault();
                            onSendAction(e);
                            return;
                        }
                        if (['PageUp', 'PageDown'].includes(e.key)) {
                            e.preventDefault();
                            const isPageUp = e.key === 'PageUp';
                            const cursorPosition = isPageUp ? 0 : target.textLength;
                            target.setSelectionRange(cursorPosition, cursorPosition);
                            target.scrollTo(0, cursorPosition);
                        }
                    }}
                />
                <div
                    className={`${styles.sendButton} ${exceededMaxLimit ? styles.sendNotAllowed : ''}`}
                    onClick={onSendAction}
                >
                    <div className={styles.rightArrow} />
                </div>
            </div>
            {exceededMaxLimit ? <div className={styles.textLimitExceededErrorMsg}>
                <Msg k="component.text.editAIText.dialog.textPrompt.textLimitExceededMsg">
                    The instruction you entered is too long. Shorten it to continue.
                </Msg>
                <div>{`${text.length}/${MAX_PROMPT_LENGTH}`}</div>
            </div> : null}
        </div>
    );
};
