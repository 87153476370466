import React from "react";
import { DndContext, DragOverlay, useSensors, useSensor, PointerSensor } from "@dnd-kit/core";
import { arrayMove } from "@dnd-kit/sortable";
import cx from "classnames";
import { Dialog, DialogTitleBox, DialogBody, DialogFooter } from '../../../../../view/common/dialogs/baseDialog/index';
import getCenteredDialogConfig from "../../../../DialogManager/getCenteredDialogConfig";
import { EditFieldsSettings, UndefinedFields } from "../../constants";
import * as styles from './EditFieldsDialog.css';
import * as Actions from "../../actionTypes";
import fieldsEpic from '../../epics/editFields/index';
import saveFieldsEpic from '../../epics/saveFields/index';
import type { EditFieldsDialogProps } from "../flowTypes";
import { PrimaryButton, SecondaryButton } from "../../../../../view/common/Button/index";
import FieldProperties from '../Fields/Properties/index';
import { FieldsList } from '../Fields/FieldsList';
import { removeDisplayValues } from "../../utility";
import { makeActionForwardToSelectedComponent } from '../../../../../redux/forwardTo';
import AddFields, { DraggableField } from '../AddContactFormFields/index';
import DefaultEditFields from '../defaultStateEditFields/index';
import { useIntl } from '../../../../../view/intl/index';

export const EditFieldsDialog = ({ dispatch, fields, isFieldsChanged }: EditFieldsDialogProps) => {
    const intl = useIntl();
    const defaultDragCtnStyle = {
        border: '2px dashed transparent',
        backgroundColor: 'inherit',
    };
    const [activeId, setActiveId] = React.useState(null);
    const [dragOverFieldVal, setDragOverFieldVal] = React.useState('');
    const [draggingStyles, setDraggingStyles] = React.useState(defaultDragCtnStyle);

    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                distance: 10,
            },
        })
    );
    if (!fields) {
        return null;
    }
    const {
        formElements,
        formElementsOrder,
        selectedField,
        minOptionsCount,
        addFields,
        isDisplayValEmpty,
        allFields
    } = fields;

    const handleDragStart = (event) => {
        if (!event.active.data.current) {
            setActiveId(event.active.id.split("_")[0]);
            setDraggingStyles({
                border: '2px dashed #0078C8',
                backgroundColor: '#F3F9FD',
            });
        } else {
            setActiveId(null);
        }
    };

    const handleDragOver = ({ active, over }) => {
        if (!active.data.current && over && over.id) {
            setDragOverFieldVal(over.id);
        }
    };

    const handleDragEnd = ({ active, over }) => {
        setDraggingStyles(defaultDragCtnStyle);
        setActiveId(null);
        setDragOverFieldVal('');

        if (!active || !over) return;

        const getAdjustedIndex = (data) => (data?.current?.sortable?.index ?? 0) - 1;

        if (active.data?.current) {
            const activeIndex = getAdjustedIndex(active.data);
            const overIndex = getAdjustedIndex(over.data);
            // Handle reordering of form elements
            if (activeIndex !== overIndex) {
                const newOrder = arrayMove(formElementsOrder, activeIndex, overIndex).filter(Boolean);
                dispatch({
                    type: Actions.CONTACT_FORM_CHANGED_FIELDS_ORDER,
                    payload: { formElementsOrder: newOrder }
                });
            }
        } else {
            // Handle dragging to add a field
            const calculateDropIndex = () => {
                if (over.data?.current) return getAdjustedIndex(over.data); // Adjust for zero-indexed arrays
                if (over.id === formElementsOrder[0]) return 0;
                return formElementsOrder.length; // Drop at the end
            };

            const dropIdx = calculateDropIndex();
            dispatch({
                type: Actions.CONTACT_FORM_ADD_FIELDS_DND_SAVE,
                payload: {
                    fieldName: active.id.split("_")[0],
                    dropIdx
                }
            });
        }
    };

    return (
        <Dialog onClose={() => { dispatch({ type: Actions.CONTACT_FORM_EDIT_FIELDS_CANCELED }); }}>
            <DialogTitleBox title={EditFieldsSettings.title} titleClass={styles.dialogTitle}>
                <div className={styles.contactsToggle} />
            </DialogTitleBox>
            <DialogBody className={styles.container}>
                <DndContext
                    sensors={sensors}
                    onDragStart={handleDragStart}
                    onDragEnd={handleDragEnd}
                    onDragOver={handleDragOver}
                >
                    <div className={styles.fieldsContainer}>
                        <div className={styles.fieldsHeader}>
                            <div className={styles.fieldsTitle}>
                                {intl.msgJoint("msg: component.contactForm.fields {Fields}")}
                            </div>
                            <div className={styles.addFieldsBtn}>
                                {addFields ?
                                    <PrimaryButton
                                        onClick={() => dispatch({ type: Actions.CONTACT_FORM_ADD_FIELDS_SAVE })}
                                        className={cx(styles.addFields, styles.addFieldsBtnDn)}
                                    >
                                        {intl.msgJoint("msg: component.contactForm.done {Done}")}
                                    </PrimaryButton> :
                                    <SecondaryButton
                                        onClick={() => dispatch({ type: Actions.CONTACT_FORM_ADD_FIELDS })}
                                        className={styles.addFields}
                                    >
                                        {intl.msgJoint("msg: component.contactForm.add {Add}")}
                                    </SecondaryButton>}
                            </div>
                        </div>
                        <div style={draggingStyles} className={styles.addedFieldsContainer}>
                            <FieldsList
                                selectedField={selectedField}
                                formElementsOrder={formElementsOrder}
                                formElements={formElements}
                                dispatch={dispatch}
                                dragOverFieldVal={dragOverFieldVal}
                            />
                        </div>
                    </div>
                    <div className={styles.propertiesContainer}>
                        {!selectedField && !selectedField.length && !addFields && <DefaultEditFields />}
                        {selectedField && selectedField.length && !addFields && <FieldProperties
                            field={selectedField}
                            minOptionsCount={minOptionsCount}
                            element={formElements[selectedField]}
                            dispatch={dispatch}
                        />}
                        {addFields && <AddFields formElementsOrder={formElementsOrder} dispatch={dispatch} allFields={allFields} />}
                    </div>
                    {activeId && <DragOverlay>
                        <DraggableField
                            contactDetailsFields={
                                allFields.filter(field => UndefinedFields.indexOf(field.name) === -1).map(({ name }) => name)
                            }
                            formElementsOrder={formElementsOrder}
                            name={activeId}
                            dispatch={dispatch}
                        />
                    </DragOverlay>}
                </DndContext>
            </DialogBody>
            <DialogFooter
                mctaText={EditFieldsSettings.mctaText}
                disabled={!isFieldsChanged || !formElementsOrder.length || addFields || isDisplayValEmpty}
                mctaHandler={() => dispatch(makeActionForwardToSelectedComponent({
                    type: Actions.CONTACT_FORM_EDIT_FIELDS_SAVE,
                    payload: { formElements: removeDisplayValues(formElements), formElementsOrder }
                }))}
                isBtnLarge
                className={styles.dialogFooter}
                sctaHandler={() => { dispatch({ type: Actions.CONTACT_FORM_EDIT_FIELDS_CANCELED }); }}
            />
        </Dialog>
    );
};

export default {
    ...getCenteredDialogConfig(EditFieldsSettings.width, EditFieldsSettings.height, EditFieldsDialog),
    dependsOn: {
        fields: fieldsEpic.reducer,
        isFieldsChanged: saveFieldsEpic.reducer
    }
};
