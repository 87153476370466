import React from 'react';
import { path } from 'ramda';
import LazyImage from '../../../../view/common/LazyImage/index';
import getLinkAbsoluteUrl from '../../../../view/common/dialogs/LinkChooserDialog/utils/getLinkAbsoluteUrl';
import { BrokenMultimedia } from '../../../BrokenMultimedia/BrokenMultimedia';
import { mapSvgJsonWithColors, svgJsonToSvgText, svgTextToDataUri } from '../utils';
import { MAX_COLOR_SUPPORTED } from '../constants';
import { getThemeRulesForSvg } from '../../../ThemeGlobalData/themeRules';
import { BACKGROUND_THEME_WHITE } from '../../../ThemeGlobalData/constants';

import type { SvgViewProps } from '../flowTypes';

export const svgWorkspaceView = (props: SvgViewProps) => {
    const {
        component, isAssetMissing, isWorkspace, previewBackupTime, site, selectedParentTheme, themeSettingsData, themeColorsData
    } = props;
    const { asset, colors, defaultFillColor, height, linkAction, opacity, seo, svgJson, useOriginalColors, width } = component,
        svgJsonWithColors = (() => {
            if (
                colors.length <= MAX_COLOR_SUPPORTED &&
                (!themeSettingsData.autoColorMode || !useOriginalColors)
            ) {
                const svgColorMap = (() => {
                    let colorList = colors;
                    if (themeSettingsData.autoColorMode) {
                        colorList = getThemeRulesForSvg(selectedParentTheme || BACKGROUND_THEME_WHITE, colors, themeColorsData);
                    }
                    return colorList.reduce((acc, c) => ({ ...acc, [c.fromColor]: c.toColor }), {});
                })();
                return mapSvgJsonWithColors(
                    svgJson,
                    svgColorMap,
                    defaultFillColor
                );
            }
            return svgJson;
        })(),
        dataUri = svgTextToDataUri(svgJsonToSvgText(svgJsonWithColors, { readThroughStyleAttributes: true })),
        imgDimensionStyles = (asset.width / asset.height) > (width / height) ? { width: '100%' } : { height: '100%' },
        pointerEventsStyles = isWorkspace ? { pointerEvents: 'none' } : {},
        additionalImgStyles = { opacity: opacity / 100, ...pointerEventsStyles },
        makeImgView = props => (isWorkspace ? <LazyImage {...props} /> : <img {...props} />),
        imgView = isAssetMissing
            ? <BrokenMultimedia width={width} height={height} text="msg: component.svg.brokenSvg {Broken SVG}" />
            : makeImgView({ src: dataUri, alt: seo, title: seo, style: { ...imgDimensionStyles, ...additionalImgStyles } }),
        // $FlowFixMe: flow fails to understand the path fn will also check if linkAction is null or not
        hyperlinkProps = path(['link', 'value', 'sectionId'], linkAction) ? { sectionid: linkAction.link.value.sectionId } : {};
    return !linkAction ? imgView : (
        <a
            // @ts-ignore
            href={getLinkAbsoluteUrl(linkAction.link, site, previewBackupTime)}
            target={linkAction.openInNewWindow ? '_blank' : '_self'}
            title={seo}
            // @ts-ignore
            style={pointerEventsStyles}
            {...hyperlinkProps}
        >
            {imgView}
        </a>
    );
};
