import * as React from "react";
import cx from 'classnames';
import styles from './PreviewTree.css';
import DataSite from "../../../../dal/model/DataSite";
import { PagePreviewParams, previewType } from './constants';
import { getDAL } from "../../../../dal";
import DataPageRef from "../../../../dal/model/DataPageRef";
import LazyImage from '../../../view/common/LazyImage';
import LoadingIndicator from '../../../view/common/LoadingIndicator';
import { Msg } from "../../../view/intl";
import Scrollbar from '../../../view/common/Scrollbar';
import { PagesTree } from "..";
import type { SubscriptionData } from '../../../redux/modules/flowTypes';
import { isDemoSubscriptionType } from '../../App/epics/subscriptionData/isDemoSubscriptionType';

type Props = {
    globalVariables: Record<string, any>;
    site: DataSite;
    subscriptionData: SubscriptionData;
    containerHeight: number;
    dispatch: Dispatch;
    selectedId?: string;
    onSelectAction?: string;
    expandedIds?: Array<string>;
    autoExpandParent?: true;
    expandAll?: true;
    onExpandAction?: string;
    accentOnlyPageIds?: Array<string>;
};

type State = {
    scrollTo: number | null | undefined;
};

export default class PreviewTree extends React.Component<Props, State> {
    treeRef: any = React.createRef();

    constructor(props) {
        super(props);
        this.state = { scrollTo: 0 };
    }

    _getPreviewUrl(pageId: string) { // eslint-disable-line class-methods-use-this
        const params = {
            ...PagePreviewParams,
            cropw: PagePreviewParams.width,
            croph: PagePreviewParams.height
        };
        return getDAL().makePagePreviewUrl(pageId, previewType, params);
    }

    _selectPage({ id }: DataPageRef) {
        const { onSelectAction } = this.props;
        if (onSelectAction) this.props.dispatch({ type: onSelectAction, payload: id });
    }

    _onExpand(pageIds: Array<string>) {
        const { onExpandAction } = this.props;
        if (onExpandAction) this.props.dispatch({ type: onExpandAction, payload: { pageIds } });
    }

    renderPreview() {
        const { selectedId, site, subscriptionData } = this.props;

        let children;

        if (selectedId && isDemoSubscriptionType(subscriptionData)) {
            children = (
                <div className={styles.previewMsg}>
                    {site.getItemById(selectedId).name}
                </div>
            );
        } else if (selectedId) {
            children = (
                <LazyImage
                    // @ts-ignore only DataPageRef is passed into PageTab
                    src={this._getPreviewUrl(site.getItemById(selectedId).pageId)}
                    proxy={LoadingIndicator}
                />
            );
        } else {
            children = (
                <div className={styles.previewMsg}>
                    <Msg k="common.selectPage">Select page</Msg>
                </div>
            );
        }

        return (
            <div className={styles.preview}>
                {children}
            </div>
        );
    }

    componentDidMount() {
        const { selectedId } = this.props;
        if (!selectedId) return;

        this.setState({ scrollTo: this.treeRef.current.getPageOffsetTop(selectedId) }); // eslint-disable-line react/no-did-mount-set-state
    }

    UNSAFE_componentWillReceiveProps() {
        this.setState({ scrollTo: undefined });
    }

    render() {
        const
            {
                globalVariables,
                site,
                subscriptionData: { subscriptionType },
                containerHeight,
                selectedId,
                expandedIds,
                autoExpandParent,
                expandAll,
                accentOnlyPageIds,
                dispatch
            } = this.props;
        return (
            <div className={styles.container} style={{ height: containerHeight }}>
                <div className={styles.treeContainer}>
                    <Scrollbar height={styles.treeHeight} scrollToVertical={this.state.scrollTo}>
                        <PagesTree
                            ref={this.treeRef}
                            site={site}
                            subscriptionType={subscriptionType}
                            onSelect={this._selectPage.bind(this)}
                            selectedId={selectedId}
                            useSelectionTag={false}
                            expandPageIds={expandedIds}
                            expandAll={expandAll}
                            autoExpandParent={autoExpandParent}
                            onExpand={this._onExpand.bind(this)}
                            itemLeftShift={20}
                            skipLinkPages
                            className={cx(styles.tree, { [styles.grayedOut]: accentOnlyPageIds })}
                            pageCustomClassName={(accentOnlyPageIds && styles.accentPage) || undefined}
                            pageCustomClassNameIds={accentOnlyPageIds}
                            dispatch={dispatch}
                            globalVariables={globalVariables}
                        />
                    </Scrollbar>
                </div>
                {this.renderPreview()}
            </div>
        );
    }
}
