const { ConfigComponentList } = require("../constants.js");

class BookingsConfig {
    isBookingsEnabled() {
        const bookingsConfig = this./*::#*/_featuresConfig[ConfigComponentList.BOOKINGS];
        return bookingsConfig && bookingsConfig.enabled;
    }
}

module.exports = BookingsConfig;
