import { pure } from 'recompose';
import * as React from "react";
import cx from 'classnames';
import { SAVE_CLICKED, MOUSE_UP_ON_PREVIEW_BUTTON, MOUSE_DOWN_ON_PREVIEW_BUTTON } from "../../../App/actionTypes";
import { PUBLISH_STATUS } from "../../constants";
import * as styles from './SavePreviewPublishBtns.css';
import Msg from '../../../../view/intl/Msg';
import PublishingInfo from './PublishingInfo/index';
import type { SavePreviewPublishButtonProps } from "../../flowTypes";
import PublishingSuccessPopup from './PublishingSuccess/index';
import { SaveStatus } from "../../../Workspace/epics/saveStatus/SaveStatus";
import injectIntl from "../../../../view/intl/injectIntl";
import { PREVIEW_BUTTON_TEXT } from "../translations";
import {
    SAVE_PUBLISH_BTN_CLIENT_RECT,
    PUBLISH_BUTTON_CLICKED,
} from "../../actionTypes";
import { MouseDownWhich } from "../../../../utils/mouse";
import { checkPublishInProgress } from "../../epics/checkPublishStatus/utils";
import { DemoPublishTip } from "../../../../../demo/modules/tip/DemoPublishTip";
import { Separator } from "../Separator";
import {
    PREVIEW_MOUSE_ENTER, PREVIEW_MOUSE_LEAVE
} from "../../../ModernLayouts/actionTypes";
import getAccessManager from "../../../../getAccessManager";
import { shouldPublishButtonBeDisabled } from './utils';
import { AutosavePopup } from '../../../Autosave/views/popup';

const publishingBtnWidth = parseInt(styles.publishBtnWidth, 10);

const PublishProgressBar = ({ publishStatus, publishProgress }) => {
    if (checkPublishInProgress(publishStatus)) {
        // const btnWidth = Math.floor((publishProgress * publishingBtnWidth) / 100);
        const btnWidth = Math.min((publishProgress / 100), 1) * publishingBtnWidth;   // Quick fix for WBTGEN-26987
        return (<div className={styles.publishInProgressBg} style={{ width: btnWidth }}>&nbsp;</div>);
    }

    return null;
};

const PublishText = ({ publishStatus }) => {
    if (checkPublishInProgress(publishStatus)) {
        return (<Msg className={styles.publishInProgress} k="publish.publishing">Publishing...</Msg>);
    } else if (publishStatus === PUBLISH_STATUS.PUBLISHED) {
        return (<Msg className={styles.published} k="publish.published">Published</Msg>);
    } else {
        return (<Msg k="publish.publishText">Publish</Msg>);
    }
};

export default injectIntl<SavePreviewPublishButtonProps>(
    pure(class SavePreviewPublishBtns extends React.Component<SavePreviewPublishButtonProps, void> {
        saveBtnRef: null|Element;
        publishBtnRef: null | Element;

        constructor(props: SavePreviewPublishButtonProps) {
            super(props);
            this.saveBtnRef = null;
            this.publishBtnRef = null;
        }

        componentDidUpdate(prevProps: SavePreviewPublishButtonProps) {
            if (
                !prevProps.saveBtnGetClientRect &&
            this.props.saveBtnGetClientRect &&
            this.saveBtnRef &&
            this.publishBtnRef
            ) {
                const saveBtnClientRect = this.saveBtnRef.getBoundingClientRect();
                const publishBtnClientRect = this.publishBtnRef.getBoundingClientRect();
                this.props.dispatch({
                    type: SAVE_PUBLISH_BTN_CLIENT_RECT,
                    payload: {
                        saveBtnClientRect,
                        publishBtnClientRect
                    }
                });
            }
        }

        render() {
            const {
                    publishStatus: {
                        publishStatus,
                        showPublishProgress,
                        publishProgress,
                        showReadMoreTip,
                        showPublishSuccessPopup,
                        publishSiteLink
                    },
                    noPagesMarkedForPublish,
                    saveStatus,
                    subscriptionData: { subscriptionType, metadata: subscriptionMetadata },
                    showAutosavePopup,
                    intl,
                    dispatch
                }: SavePreviewPublishButtonProps = this.props,
                disablePublishButton = (
                    shouldPublishButtonBeDisabled(publishStatus) ||
                noPagesMarkedForPublish ||
                !getAccessManager().isPublishButtonEnabled()
                ),
                dataTitle = noPagesMarkedForPublish
                    ? intl.msgJoint("msg: publish.noPagesMarkedForPublish {All pages are marked as 'Don't publish this page'. Please ensure that at least one page is enabled to publish and try again.}") // eslint-disable-line max-len
                    : null,
                onClickPublish = () => dispatch({ type: PUBLISH_BUTTON_CLICKED }),
                // @ts-ignore
                progress = parseInt(publishProgress.progressAnimation.current, 10);

            return (
                <div className={styles.savePreviewPublishContainer}>
                    <div className={cx(styles.savePreviewContainer, styles.displaySave)}>
                        <Separator />
                        <div
                            onClick={() => dispatch({ type: SAVE_CLICKED })}
                            className={cx(
                                styles.saveBtn,
                                { [styles.btnDisabled]: saveStatus !== SaveStatus.CAN_SAVE },
                            )}
                            id="saveBtnWrapper"
                            ref={(saveBtnRef) => { this.saveBtnRef = saveBtnRef; }}
                        >
                            {showAutosavePopup && <AutosavePopup dispatch={dispatch} />}
                            {
                                saveStatus === SaveStatus.CAN_SAVE ?
                                    <Msg k="common.save">Save</Msg> :
                                    <Msg k="common.saved">Saved</Msg>
                            }
                        </div>
                    </div>
                    <div className={cx(styles.savePreviewContainer, styles.displayPreview)}>
                        <Separator />
                        <div
                            className={cx(styles.previewBtn)}
                            onMouseDown={(e) => {
                                // TODO: WBTGEN-7154: Use generic left mouse down event.
                                // @ts-ignore
                                if ([e.which, e.nativeEvent.which].indexOf(MouseDownWhich.LEFT) > -1) {
                                    localStorage.setItem("previewPageHref", "");
                                    return dispatch({ type: MOUSE_DOWN_ON_PREVIEW_BUTTON });
                                }
                                return "";
                            }}
                            onMouseUp={(e) => {
                            // @ts-ignore
                                return [e.which, e.nativeEvent.which].indexOf(MouseDownWhich.LEFT) > -1 ?
                                    dispatch({ type: MOUSE_UP_ON_PREVIEW_BUTTON }) : "";
                            }}
                            onMouseEnter={() => dispatch({ type: PREVIEW_MOUSE_ENTER })}
                            onMouseLeave={() => dispatch({ type: PREVIEW_MOUSE_LEAVE })}
                        >
                            <span>{intl.msgJoint(PREVIEW_BUTTON_TEXT)}</span>
                        </div>
                    </div>
                    <DemoPublishTip
                        intl={intl}
                    >
                        <div
                            className={cx(
                                styles.publishBtn,
                                { [styles.publishBtnDisabled]: disablePublishButton },
                                styles.displayPublish
                            )}
                            onClick={disablePublishButton ? () => {} : onClickPublish}
                            // @ts-ignore
                            disabled={disablePublishButton}
                            data-title={disablePublishButton ? dataTitle : null}
                            id="publishBtnWrapper"
                            ref={(publishBtnRef) => { this.publishBtnRef = publishBtnRef; }}
                        >
                            <PublishProgressBar publishStatus={publishStatus} publishProgress={progress} />
                            <PublishText publishStatus={publishStatus} />
                            {showPublishProgress && <PublishingInfo
                                publishStatus={publishStatus}
                                subscriptionType={subscriptionType}
                                subscriptionMetadata={subscriptionMetadata}
                                showReadMoreTip={showReadMoreTip}
                                dispatch={dispatch}
                                intl={intl}
                            />}
                            {showPublishSuccessPopup && <PublishingSuccessPopup
                                publishSiteLink={publishSiteLink}
                                dispatch={dispatch}
                            />}
                        </div>
                    </DemoPublishTip>
                </div>
            );
        }
    })
);
