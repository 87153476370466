import * as R from 'ramda';
import type { ComponentsMutationsHandler } from './flowTypes';
import type { BBox, ComponentChange, ComponentsIds, ComponentsMap } from "../../../../../redux/modules/children/workspace/flowTypes";
import type { Position } from "../../../../../redux/modules/flowTypes";
import applyAdjustingSpaceMutations from "./applyAdjustingSpaceMutations";
import { changeComponentsMap } from "../utils";
import { getComponentsIdsWithoutWrappedOnes } from "../selectors";
import {
    getChildComponentsOverTopAndBottomOfSection, getHeaderSection,
    getFooterSection,
    getPrevOrNextSectionInfo,
    getSectionChildren
} from "../../../../oneweb/Section/utils";
import { isShiftBarBottom } from "../../../../../utils/handle/index";
import {
    getParentSectionForBBox,
} from "../../isPageMode/utils";
import { getComponentsBBox } from "../../../../../utils/componentsMap/index";
import { MIN_SECTION_HEIGHT } from "../../../../oneweb/Section/constants";
import type { AnyComponent } from "../../../../oneweb/flowTypes";
import { isStickyToHeader } from "../isStickyToHeader";
import { getWebShopStripCmpIds } from '../../../../ModernLayouts/layoutsData/webshopMHFDataUtils';

const getStickyMenus = (componentsMap: ComponentsMap): Array<AnyComponent> => {
    // @ts-ignore
    return Object.keys(componentsMap).reduce((acc, id) => {
        const cmp = componentsMap[id];
        if (isStickyToHeader(cmp)) {
            return [...acc, cmp];
        }
        return acc;
    }, []);
};

const getShiftBarChangesForSections = (
    componentsIds: ComponentsIds,
    { start, current }: { start: Position, current: Position },
    isTopShiftBar: boolean,
    componentsMap: ComponentsMap,
    shiftBarChanges: ComponentChange[],
    workspaceBBox: BBox
): ComponentChange[] => {
    let changesMap: MapT<ComponentChange> = shiftBarChanges.reduce((acc, change) => ({ ...acc, [change.id]: change }), {});
    const
        components = componentsIds.map((id) => componentsMap[id]),
        movingUp = current.y < start.y,
        movingDown = current.y > start.y,
        newCmpsMap = shiftBarChanges.reduce((acc: any, change: ComponentChange): any => {
            const { id, value } = change;
            if (value) return { ...acc, [id]: { ...acc[id], ...value } };
            return acc;
        }, componentsMap),
        cmpsBBox = getComponentsBBox(components, workspaceBBox),
        headerSection = getHeaderSection(componentsMap),
        footerSection = getFooterSection(componentsMap),
        stickyMenus = getStickyMenus(componentsMap),
        parentSection = getParentSectionForBBox(cmpsBBox, componentsMap);

    const updateChangesMap = (id, valueUpdates) => {
        const value = (changesMap[id] || {}).value || {};
        changesMap[id] = { id, value: { ...value, ...valueUpdates } };
    };

    if (!parentSection) {
        console.warn('There are gaps between sections');
        return [];
    }

    const { bottom: bottomOverlapCmps } = getChildComponentsOverTopAndBottomOfSection(parentSection, componentsMap),
        webShopStripFooterCmpIds = getWebShopStripCmpIds(componentsMap),
        { top: sectionTop, height: sectionHeight, id: sectionId } = parentSection,
        sectionBottom = sectionTop + sectionHeight,
        { section: nextSection } = getPrevOrNextSectionInfo(sectionId, componentsMap, true);

    if (movingDown) {
        const updatedSection = newCmpsMap[sectionId],
            updatedSectionBottom = updatedSection.top + updatedSection.height;
        let newHeight = updatedSectionBottom - sectionTop;
        const nexSectionTop = nextSection && R.path([nextSection.id, 'value', 'top'], changesMap);
        if (nexSectionTop && nexSectionTop > sectionTop + newHeight) {
            newHeight = nexSectionTop - sectionTop;
        }

        if (newHeight !== updatedSection.height) {
            updateChangesMap(sectionId, { top: sectionTop, height: newHeight });
        }
        const heightDiff = newHeight - sectionHeight;
        // @ts-ignore
        Object.values(componentsMap).forEach(({ id, top, height }) => {
            const center = top + (height / 2);
            if (center >= sectionBottom ||
                 (!isTopShiftBar && componentsIds.includes(footerSection.id) && webShopStripFooterCmpIds.includes(id))) {
                updateChangesMap(id, { top: top + heightDiff });
            }
        });
    } else if (movingUp) {
        let currentSectionBottom;
        if (bottomOverlapCmps.length) {
            updateChangesMap(sectionId, { top: sectionTop, height: sectionHeight });
            currentSectionBottom = sectionTop + sectionHeight;
        } else {
            const parentSectionId = parentSection.id,
                parentSectionAfterUpdates = newCmpsMap[parentSectionId],
                parentSectionBottom = parentSectionAfterUpdates.top + parentSectionAfterUpdates.height,
                children = getSectionChildren(parentSection, componentsMap),
                childrenBBox = getComponentsBBox(children.map(({ id }) => newCmpsMap[id]), workspaceBBox),
                sectionMaxBottom = childrenBBox.bottom,
                spaceAtBottom = sectionMaxBottom - parentSectionBottom;

            const changedHeight = sectionHeight - (spaceAtBottom > 0 ? sectionMaxBottom - sectionTop : parentSectionBottom - sectionTop);
            let finalHeight = sectionHeight - changedHeight;

            if (finalHeight < MIN_SECTION_HEIGHT) {
                finalHeight = MIN_SECTION_HEIGHT;
            }

            updateChangesMap(sectionId, {
                height: finalHeight,
                top: sectionTop,
            });
            currentSectionBottom = sectionTop + finalHeight;
        }
        const bottomOverlapCmpIds = bottomOverlapCmps.map(({ id }) => id);
        changesMap = Object.keys(componentsMap).reduce((acc, cmpId) => {
            const change = changesMap[cmpId],
                { top, height } = componentsMap[cmpId],
                center = top + (height / 2);
            if (bottomOverlapCmpIds.includes(cmpId)) {
                return acc;
            }
            if (center >= sectionBottom) {
                const value = (change && change.value) || {},
                    isOverlappingSection = top < sectionBottom,
                    overlappingTop = (sectionBottom - top);
                let changeDiff = currentSectionBottom - sectionBottom;
                changeDiff = isOverlappingSection ? Math.min(overlappingTop + changeDiff, 0) :
                    changeDiff;
                return { ...acc, [cmpId]: { id: cmpId, value: { ...value, top: top + changeDiff } } };
            }
            if (!change) return acc;
            return { ...acc, [cmpId]: change };
        }, {});
    }

    stickyMenus.forEach(({ id }) => {
        const headerBottom = headerSection.height,
            { top: menuTop } = componentsMap[id],
            currentHeaderChange = changesMap[headerSection.id];

        if (currentHeaderChange) {
            const currentHeaderBottom = currentHeaderChange.value.height,
                diff = currentHeaderBottom - headerBottom;
            changesMap = { ...changesMap, [id]: { id, value: { top: menuTop + diff } } };
            return;
        }
        changesMap = R.omit([id], changesMap);
    });
    // $FlowFixMe
    return Object.values(changesMap);
};

const applyShiftBarMovingComponentsMutations: ComponentsMutationsHandler = ({
    components: { componentsMap, componentsMapExtension },
    componentsIds,
    start,
    current,
    handleKind,
    workspaceBBox,
    componentsAdjustmentData,
    userInteractionPayload:  { shiftBar: { relations }, startPosition: { y: initialMouseY } }
}) => {
    let positionY = current.y;

    let shiftBarChanges = applyAdjustingSpaceMutations({
        componentsMap,
        selectedComponentsIds: getComponentsIdsWithoutWrappedOnes(componentsMap, componentsIds),
        start: initialMouseY,
        current: positionY,
        handleKind,
        workspaceBBox,
        adjustmentData: componentsAdjustmentData,
        // TODO: Unlike componentsMap which we need to have a state beforehand, extension must be received updated. Clarify this because mutation handler passed old extension
        componentsMapExtension,
        relationsMap: relations
    });

    if (positionY !== initialMouseY) {
        shiftBarChanges = getShiftBarChangesForSections(
            componentsIds, { start, current },
            !isShiftBarBottom(handleKind),
            componentsMap,
            shiftBarChanges,
            workspaceBBox
        );
    }
    return changeComponentsMap(componentsMap, shiftBarChanges);
};

export {
    applyShiftBarMovingComponentsMutations,
    getShiftBarChangesForSections
};

