export const getBlogHtml = async (url, data) => {
    const abortCtrl = new AbortController();
    const signal = abortCtrl.signal;
    const timeoutId = setTimeout(() => abortCtrl.abort("Timeout after 90 seconds"), 90000);

    try {
        const response = await window.fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
            signal
        });
        if (!response.ok) {
            return '';
        }
        const result = await response.json();
        return result;
    } catch (e) {
        return '';
    } finally {
        clearTimeout(timeoutId);
    }
};
