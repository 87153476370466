import CALL_API from "../../../redux/middleware/api/CALL_API";
import {
    POST_DYNAMIC_TEMPLATE_REQUEST,
    POST_DYNAMIC_TEMPLATE_SUCCESS,
    POST_DYNAMIC_TEMPLATE_FAILURE
} from "../actionTypes";
import type { ApiAction } from "../../../redux/middleware/api/flowTypes";

const postDynamicTemplateAction = (workspaceData: any): ApiAction => ({
    [CALL_API]: {
        types: [
            POST_DYNAMIC_TEMPLATE_REQUEST,
            POST_DYNAMIC_TEMPLATE_SUCCESS,
            POST_DYNAMIC_TEMPLATE_FAILURE
        ],
        endpoint: 'postDynamicTemplate',
        endpointParams: workspaceData
    }
});

export {
    postDynamicTemplateAction
};
