import { getAppConfig } from "../../../App/epics/appConfig/appConfig.js";
import { facebookFeedStockImagesList } from "./facebookFeedStockImagesList";
import { FB_POST_IMAGE_MEDIA_TYPE } from "../../../oneweb/FacebookFeedGallery/constants/index";

const
    appConfig = getAppConfig(),
    stockImagesCdnUrl = appConfig.oneWeb && appConfig.oneWeb.facebook ? appConfig.oneWeb.facebook.stockImagesCdnUrl : '';

export const getFacebookFeedStockData = () => {
    return facebookFeedStockImagesList.reverse().map((imageName, index) => {
        return {
            id: index,
            created_time: new Date().toISOString(),
            message: 'The description here is taken from the text you added when you created your Facebook post.',
            full_picture: stockImagesCdnUrl ? `${stockImagesCdnUrl}/${imageName}` : '',
            attachments: {
                data: [
                    {
                        type: FB_POST_IMAGE_MEDIA_TYPE,
                        media: {
                            image: {
                                src: stockImagesCdnUrl ? `${stockImagesCdnUrl}/${imageName}` : '',
                            }
                        }
                    }
                ]
            }
        };
    });
};
