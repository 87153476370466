// todo refactor
export default (nodes: Array<any>) => {
    const result: any[] = [];

    for (let i = 0; i < nodes.length; i++) {
        if (i + 1 < nodes.length && !nodes[i].tag && !nodes[i + 1].tag) {
            result.push({
                ...nodes[i],
                end: nodes[i + 1].end,
                text: nodes[i].text.concat(nodes[i + 1].text)
            });
            i++;    // NOSONAR
        } else {
            result.push(nodes[i]);
        }
    }
    return result;
};
