import React from 'react';
import cx from 'classnames';
import Msg from "../../../../../view/intl/Msg";
import { openWindowAction } from "../../../../../redux/middleware/window/actionCreators";
import { getAppConfig } from "../../../../App/epics/appConfig/appConfig.js";
import { getLocaleCookie } from "../../../../Locale/cookie";
import { getDAL } from "../../../../../../dal/index";
import AppConfig from "../../../../../utils/AppConfig";
import { EPSeparator } from "../../../../Panel/view/EPSeparator";
import { Flex } from "../../../../../view/reflexbox/index";
import styles from './ExpandedPanelBookings.css';

export const GoToBookings = ({ ownerEmail, dispatch }) => {
    const openTrafftPage = () => {
        const appConfig = AppConfig(getAppConfig());

        const locale = getLocaleCookie();

        const bookingsBaseUrl = appConfig.getOptional("oneWeb.bookings.url");
        const urlParams = locale === "en_gb" ? "" : `/${locale}`;
        const urlSearchParams = new URLSearchParams({
            domain: getDAL().getDomain(),
            email: ownerEmail,
        }).toString();
        const bookingsUrl = `${bookingsBaseUrl}${urlParams}?${urlSearchParams}`;
        dispatch(openWindowAction(bookingsUrl));
    };

    return (
        <React.Fragment>
            <Flex justify="center" className={cx(styles.goTobookingsContainer)}>
                <button
                    className={cx(styles.goToBookingsButton)}
                    type="button"
                    onClick={openTrafftPage}
                >
                    <Msg k="widgets.bookings.extendedPanel.goToBookings">
                        Go to Bookings
                    </Msg>
                    <div className={styles.goToBookingsArrow} />
                </button>
            </Flex>
            <EPSeparator />
        </React.Fragment>
    );
};
