/* @flow */

const Separator = ['-', '_'];

const normalizeLocale = (locale/*: string */)/*: string */ => {
    if (!locale) {
        return locale;
    }

    for (const sep of Separator) {
        if (locale.indexOf(sep) > -1) {
            return locale.split(sep)[0];
        }
    }

    return locale;
};

module.exports = { normalizeLocale };
