import React from "react";
import { injectIntl, Intl } from '../../../src/view/intl/index';
import Msg from "../../../src/view/intl/Msg";
import { getAppConfig } from "../../../src/components/App/epics/appConfig/appConfig.js";
import { PartnerName } from '../../../../server/shared/partners/PartnerName.js';

type Props = {
    intl: Intl,
    className: ''
};

const
    CookiePolicyUrlLink = injectIntl((props: Props) => {
        const appConfig = getAppConfig(),
            trialConfig = appConfig.oneWeb.trial;
        const { intl, className } = props;
        return (
            <a
                href={trialConfig.partnerName === PartnerName.ONECOM ?
                    intl.msgJoint(trialConfig.cookiePolicyUrl) : trialConfig.cookiePolicyUrl}
                target="_blank"
                className={className}
            ><Msg k="common.cookiePolicy">Cookie Policy</Msg></a>
        );
    });

export default CookiePolicyUrlLink;
