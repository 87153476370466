import React from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import cx from 'classnames';
import TetheredComponent from 'react-tether';
import type { ComboboxEpicStateTypes, ComboboxPropTypes } from "./flowTypes";
import { COMBO_BOX_OPEN, COMBO_BOX_CLOSE } from './actionTypes';
import { AppState } from '../../../redux/modules/flowTypes';

import 'react-select/dist/react-select.css';
import './Combobox.css';

class TetheredSelectWrap extends Select {
    renderOuter: () => React.JSX.Element | null;
    wrapper?: { offsetWidth: number };
    props!: ComboboxPropTypes;

    constructor(props: ComboboxPropTypes) {
        super(props);
        this.renderOuter = this._renderOuter;
    }

    _renderOuter() {
        const menu = super.renderOuter.apply(this, arguments),
            /**
             * WBTGEN-4745: IE11 - Dropdown text/numbers become blurry when moving mouse out of options.
             * Issue is caused by translateZ() used in 'react-tether'. translateZ will enable gpu by default.
             * IE is creating blurred text while using gpu.
             * Link: https://github.com/HubSpot/tether/issues/265
             * @type {{gpu: boolean}}
             */
            tetheredComponentOptimizations = { gpu: false };

        if (menu) {
            return (
                <TetheredComponent
                    attachment="top left"
                    targetAttachment="top left"
                    constraints={[{ to: 'window', pin: true }]}
                    optimizations={tetheredComponentOptimizations}
                >
                    <div />
                    {React.cloneElement(
                        menu,
                        {
                            style: {
                                position: 'static',
                                width: this.wrapper ? this.wrapper.offsetWidth : null
                            },
                            className: cx(menu.props.className, this.props.dropDownClassName, this.props.className)
                        }
                    )}
                </TetheredComponent>
            );
        }
        return null;
    }
}

type Props = ComboboxEpicStateTypes & ComboboxPropTypes & { dispatch: Dispatch };

const Combobox = connect(({ epics: { COMBO_BOX_STATE_EPIC_VALUE: { state } } }: AppState): ComboboxEpicStateTypes =>
    (state))((props: Props) => {
    const
        { value, onOpen, onChange, onSelect, renderComboList, ...rest } = props,
        onChangeHandler = (next => {
            if (onSelect) {
                onSelect(next);
            }
            if (next && onChange && next.value !== value) {
                onChange(next);
            }
        }),
        setComboRef = (el) => {
            if (el && !renderComboList) {
                el.closeMenu();
            }
        },
        // @ts-ignore
        tetheredSelectCombo = <TetheredSelectWrap
            value={value}
            onChange={onChangeHandler}
            {...rest}
            onOpen={() => {
                if (onOpen) {
                    onOpen();
                }
                props.dispatch({ type: COMBO_BOX_OPEN });
            }}
            onClose={() => props.dispatch({ type: COMBO_BOX_CLOSE })}
            ref={setComboRef}
        />;
    return tetheredSelectCombo;
});

export {
    Combobox as default,
    Combobox
};
