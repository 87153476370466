import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import View from "./index";
import BaseComponent from "../../../../../view/oneweb/BaseComponent";
import ComponentMask from "../../../../presentational/ComponentMask/index";
import { ComponentMaskBackground } from "../../../../presentational/ComponentMask/constants";
import { isBlogManagementDialogOpenSelector } from "../selectors";
import makeUuid from "../../../../../../utils/makeUuid";

export default (props: any) => {
    const isBlogManagementDialogOpen = useSelector(isBlogManagementDialogOpenSelector);
    const [refreshId, setRefreshId] = useState<string>();
    const { component } = props;

    // update blog component if the blog management dialog was closed
    useEffect(() => {
        if (isBlogManagementDialogOpen) {
            return;
        }
        setRefreshId(makeUuid());
    }, [isBlogManagementDialogOpen]);

    return (
        <BaseComponent {...props}>
            <ComponentMask
                maskStyle={ComponentMaskBackground.LIGHT}
                // width is required in order to show the "Premium feature" text in the premium overlay
                componentWidth={component.width}
                {...props}
            >
                <View {...props} refreshId={refreshId} />
            </ComponentMask>
        </BaseComponent>
    );
};
