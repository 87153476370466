import React from 'react';
import Msg from '../../../../view/intl/Msg';
import * as ids from '../../../Tooltip/ids';
import styles from './styles.css';
import {
    CLOSE_ADD_YOUR_SHOP_TOOLTIP,
} from "../../../Tooltip/actionTypes";
import PrimaryButton from '../../../../view/common/Button/PrimaryButton';

export enum ToolTipTypes {
    // @ts-ignore
    addYourShopToolTipSeen = ids.addYourShopToolTipSeen,
}

type Props = {
    dispatch: Function,
    type: ToolTipTypes
}

const WebshopOnBoardingToolTip = (props: Props) => {
    const { dispatch } = props;
    let onClose;

    if (props.type === ToolTipTypes.addYourShopToolTipSeen) {
        onClose = () => {
            dispatch({ type: CLOSE_ADD_YOUR_SHOP_TOOLTIP });
        };
    }

    return (
        <div className={styles.addYourShopToolTipSeenContainer}>
            <div className={styles.addYourShopToolTipSeenHeader}>
                {
                    (props.type === ToolTipTypes.addYourShopToolTipSeen) && (
                        <Msg
                            k="tooltip.addYouShop.header"
                        >
                            Launch your shop on your website!
                        </Msg>
                    )

                }
            </div>
            <div className={styles.addYourShopToolTipSeenBody}>
                {
                    (props.type === ToolTipTypes.addYourShopToolTipSeen) && (
                        <Msg
                            k="tooltip.addYouShop.text"
                        >
                            You can show all, featured or a single product.
                            Simply add the component to your website and publish to start selling today.
                        </Msg>
                    )

                }
            </div>
            <div>
                <PrimaryButton onClick={onClose} className={styles.addYourShopToolTipSeenCloseBtn}>
                    <Msg k="common.gotIt">
                        Got it
                    </Msg>
                </PrimaryButton>
            </div>
        </div>
    );
};

export default WebshopOnBoardingToolTip;
