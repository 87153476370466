import * as R from 'ramda';
import { overPath, traverse } from './ramdaEx';
import { WEBSPACE_PREFIX, getDAL } from "../../dal/index";
import path from '../../utils/path.js';
import type { Path } from "../mappers/path";
import type { ImageAsset } from "../components/App/flowTypes";
import { isObj } from "../isType";
import { TRIAL_STORAGE_PROTOCOL } from "../../../trial/services/storage/constants.js";

type DirectoryAndFile = {
    directory: string,
    file: string
}

export type AssetsReplacements = MapT<ImageAsset>

type ContextualValues = {
    path: Path,
    asset: ImageAsset
};

export type PathsObject = {
    paths: Array<ContextualValues>
}

const getQueryParams = (asset, query) => {
    /**
     * Do not process if its already .ico files.
     * Icon files need not be processed using impro. Passing at least one query param will trigger impro.
     * It will be already small in size and we do not want to process it again. It might reduce the image quality.
     * One issue noted: Since etag is not passed we may face cache issue if icon with same name is uploaded for the second time.
     * WBTGEN-7084
     **/
    if (asset.contentType !== "image/x-icon" && asset.contentType !== 'image/vnd.microsoft.icon') {
        const
            etag = encodeURIComponent(asset.etag),
            sourceContentType = encodeURIComponent(asset.contentType); // sourceContentType required in case of file extension mismatch
        return { etag, sourceContentType, ...query };
    }
    return {};
};

export function getAssetUrl(asset: ImageAsset, query: Record<string, any> = {}, domain?: string): string {
    if (query.ignoreAspectRatio === true) {
        // URL parameter "ignoreAspectRatio" is not supposed to be assigned any value
        // Setting it to null effectively does that when the query is built
        query.ignoreAspectRatio = null;     // eslint-disable-line no-param-reassign
    }

    if (query.resize && isObj(query.resize)) {
        query.resize = `${query.resize.width},${query.resize.height}`; // eslint-disable-line no-param-reassign
    }

    const
        { url } = asset,
        finalUrl = getDAL().makeWebSpaceUrl({
            path: url,
            query: getQueryParams(asset, query),
            domain
        });

    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/encodeURIComponent
    // encodeURIComponent skips ', (, )
    return finalUrl;
}

export function getDirectoryAndFileFromAssetUrl(assetUrl: string): DirectoryAndFile {
    const file = path.basename(assetUrl);
    return { directory: assetUrl.replace(file, ''), file };
}

export function extractPathsToReplaceAssets(path: Path, value: any, context: PathsObject): PathsObject {
    if (
        value instanceof Object
        && value.url
        && (
            value.url.indexOf(WEBSPACE_PREFIX) === 0
            || value.url.indexOf(TRIAL_STORAGE_PROTOCOL) === 0
        )
    ) {
        context.paths.push({ path, asset: value });
    }
    return context;
}

export function getAssetsReplacementTransformations(
    assetsReplacements: AssetsReplacements,
    replaceIn: Record<string, any>,
    merge: boolean = false
) {
    const
        { paths } = traverse(replaceIn, extractPathsToReplaceAssets, { paths: [] }),
        groupedAssetsRefs = R.groupBy(({ asset: { url } }) => url, paths);

    return R.pipe(
        R.keys,
        R.map(oldAssetKey => {
            if (groupedAssetsRefs[oldAssetKey]) {
                const paths = groupedAssetsRefs[oldAssetKey].map(({ path }) => path);
                return overPath(paths)(old => (
                    merge ? { ...old, ...assetsReplacements[oldAssetKey] } : assetsReplacements[oldAssetKey]
                ));
            }
            return R.identity;
        })
    )(assetsReplacements);
}

const isLastElementInteger = R.pipe(R.last, R.is(Number));

export function extractPathsToDeleteAssets(path: Path, value: any, context: PathsObject) {
    if (value instanceof Object && value.asset && value.asset.url && value.asset.url.indexOf(WEBSPACE_PREFIX) === 0) {
        const pathToPush = isLastElementInteger(path) ? path : path.concat('asset');
        context.paths.push({ path: pathToPush, asset: value.asset });
    }
    return context;
}

export function getAssetsDeleteTransformations(assetsToDelete: Array<string>, deleteIn: Record<string, any>) {
    const
        { paths } = traverse(deleteIn, extractPathsToDeleteAssets, { paths: [] }),
        updatedPaths = paths.filter(({ asset: { url } }) => assetsToDelete.indexOf(url) > -1);

    return updatedPaths
        .sort(({ path: p1 }, { path: p2 }) => R.last(p2) - R.last(p1))
        .map(({ path }) => R.dissocPath(path));
}

export function fetchAssetsUrls(obj: Record<string, any>) {
    const { paths } = traverse(obj, extractPathsToReplaceAssets, { paths: [] });
    return paths.map(({ asset: { url } }) => url);
}

export function fetchAssets(obj: Record<string, any>) {
    const { paths } = traverse(obj, extractPathsToReplaceAssets, { paths: [] });
    return paths.map(({ asset }) => asset);
}

export function getRelativeUrl(url: string, baseUrl: string): string {
    let origin = url.match(/^(webspace|repository)\:\/(.*)$/); // eslint-disable-line

    if (origin) {
        if (origin[1] === 'webspace') {
            return baseUrl + '/webspace/' + origin[2];
        }
        return '/api/v1/repository/webspace/' + origin[2];
    }
    return '';
}

function getFileName(asset: ImageAsset): string {
    let filename = asset.url.match(/\/([^\/]+$)/); // eslint-disable-line

    if (filename) {
        return filename[1];
    }
    return asset.url;
}

export type AssetFileInfo = {
    dir: string,
    name: string,
    extn: string
}

export function getAssetFileInfo(asset: ImageAsset): AssetFileInfo {
    let imageName = getFileName(asset);
    let dotIdx = imageName.lastIndexOf('.');

    let url = asset.url;
    let fullPath = url.replace(/^(webspace|repository):/, ''); // remove webspace:
    let lastSlashIdx = fullPath.lastIndexOf('/'); // will always have a slash

    let extn = imageName.substring(dotIdx + 1);
    if (extn === 'gif') {
        // if original file was gif, image editor always returns a png file with a gif extension
        extn = 'png';
    }

    let dir = fullPath.substring(0, lastSlashIdx + 1) || '/';
    if (url.match(/^repository/)) {
        dir += 'onewebmedia/';
    }
    return {
        dir,
        name: imageName.substring(0, dotIdx),
        extn
    };
}
