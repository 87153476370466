import * as actionTypes from '../actions';
import { makeValueReducer } from "../../../../../src/redux/makeReducer/index";
import type { LoginPagePropTypes } from "../../types";
import {
    NOT_AUTHORIZED_EXCEPTION,
    USER_DISABLED_EXCEPTION,
    USER_EMAIL_VERIFICATION_REQUIRED_EXCEPTION,
    USER_LAMBDA_VALIDATION_EXCEPTION
} from "../../../../constants";
import { TRIAL_FAILURE_MESSAGES } from "../../../../../../server/shared/trial/constants.js";

export const defaultSigninState = {
    loading: false,
    error: null,
    email: '',
    password: '',
    invalidUsernameOrPasswordError: false,
    userNotFoundError: false,
    userDisabledError: false,
    userTrialEndedDetails: null,
    invalidEmailErrorMessage: false,
    invalidPasswordErrorMessage: false,
    keepLoggedIn: true,
    showPasswordEnabled: false,
    trialEndedError: false,
    userEmailVerificationRequiredError: false,
    trialAlreadyPurchased: false,
};

export const signinReducer = makeValueReducer({
    defaultState: defaultSigninState,
    handleActions: {
        [actionTypes.DEMO_TOGGLE_LOGGED_IN_CHECKBOX_ACTION]: (state: LoginPagePropTypes) => {
            return {
                ...state,
                keepLoggedIn: !state.keepLoggedIn
            };
        },
        [actionTypes.DEMO_TOGGLE_SHOW_PASSWORD_VALUE_ACTION]: (state: LoginPagePropTypes) => {
            return {
                ...state,
                showPasswordEnabled: !state.showPasswordEnabled
            };
        },
        [actionTypes.DEMO_UPDATE_SIGNIN_PASSWORD_VALUE_ACTION]: (state: LoginPagePropTypes, action: Record<string, any>) => {
            return {
                ...state,
                password: action.payload.passwordValue
            };
        },
        [actionTypes.DEMO_UPDATE_SIGNIN_EMAIL_VALUE_ACTION]: (state: LoginPagePropTypes, action: Record<string, any>) => {
            return {
                ...state,
                email: action.payload.emailValue
            };
        },
        [actionTypes.DEMO_SIGNIN_SHOW_INVALID_EMAIL_ERROR_ACTION]: (state: LoginPagePropTypes) => {
            return {
                ...state,
                invalidEmailErrorMessage: true
            };
        },
        [actionTypes.DEMO_SIGNIN_SHOW_INVALID_PASSWORD_ERROR_ACTION]: (state: LoginPagePropTypes) => {
            return {
                ...state,
                invalidPasswordErrorMessage: true
            };
        },
        [actionTypes.DEMO_SIGNIN_CLEAN_ALL_ERRORS_ACTION]: (state: LoginPagePropTypes) => {
            return {
                ...state,
                error: null,
                invalidUsernameOrPasswordError: false,
                userDisabledError: false,
                userNotFoundError: false,
                invalidEmailErrorMessage: false,
                invalidPasswordErrorMessage: false,
                trialEndedError: false,
                userEmailVerificationRequiredError: false,
                trialAlreadyPurchased: false,
            };
        },
        [actionTypes.DEMO_LOGIN_FAILURE_ACTION]: (state: LoginPagePropTypes, action: Record<string, any>) => {
            let newState: Record<string, any> = {};
            const errorCode = action.payload.code;
            const errorMessage = action.payload.message;

            if (errorCode === USER_LAMBDA_VALIDATION_EXCEPTION &&
                errorMessage.indexOf('User does not exist') > -1) {
                newState.userNotFoundError = true;
            } else if (errorCode === NOT_AUTHORIZED_EXCEPTION || errorCode === USER_LAMBDA_VALIDATION_EXCEPTION) {
                if (errorMessage === 'Incorrect username or password.') {
                    newState.invalidUsernameOrPasswordError = true;
                } else if (errorMessage === TRIAL_FAILURE_MESSAGES.userDisabled) {
                    newState.userDisabledError = true;
                } else if (errorMessage === TRIAL_FAILURE_MESSAGES.trialAlreadyPurchased) {
                    newState.trialAlreadyPurchased = true;
                }
            } else if (errorCode === USER_DISABLED_EXCEPTION) {
                newState.trialEndedError = true;
                newState.userTrialEndedDetails = JSON.parse(errorMessage);
            } else if (errorCode === USER_EMAIL_VERIFICATION_REQUIRED_EXCEPTION) {
                newState.userEmailVerificationRequiredError = true;
            } else {
                newState.error = action.payload.message;
            }
            return {
                ...state,
                ...newState,
                loading: false
            };
        },
        [actionTypes.DEMO_SIGNIN_SET_TRIAL_ENDED_DATA]: (state: LoginPagePropTypes, action: Record<string, any>) => {
            const userTrialEndedDetails = action.payload;
            return {
                ...state,
                userTrialEndedDetails,
            };
        },
        [actionTypes.DEMO_CLEAN_UP_SIGNIN_SCREEN_ACTIONS]: () => {
            return defaultSigninState;
        },
        [actionTypes.DEMO_SIGNIN_LOADING_ACTION]: (state: LoginPagePropTypes) => {
            return {
                ...state,
                loading: true
            };
        }
    }
});
