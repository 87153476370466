import { combineDiReducers } from '@sepo27/redux-di';
import { makeRequestReducer, getDefaultReducerState } from '../makeReducer';
import selectedWorkspace from './children/workspace';
import tips from './children/tips';
import contextMenuEpic from '../../components/ContextMenu/epic';
import { reducer as colorPicker } from "../../components/ColorPicker";
import * as debugReducers from './reducers/debug';
import { SET_APP_STATE, UPDATE_APP_STATE } from "./actionTypes";
import panel from '../../components/Panel/reducer';
import topbarEpic from '../../components/TopBar/epics/topBar';
import logoutEpic from '../../components/TopBar/epics/logout';
import dndAddComponentEpic from '../../components/DndAddComponent/epic';
import dimensions from './children/dimensions';
import actionReducer from '../../components/Action/reducer';
import { siteDataLoadActionTypes } from "../../components/App/actionTypes";
import { reducer as propertiesPanel } from '../../components/PropertiesPanel';
import fileChooser from './children/fileChooser';
import previewEpic from '../../components/Preview/epics/preview';
import epicsReducer from '../../epics/reducer';
import appStatusEpic from '../../components/App/epics/status';
import backupReducer from '../../components/Backup/reducer';
import cursorEpic from '../../components/App/epics/cursor';
import { showMousePosition } from "../../utils/isDebug";
import mousePositionReducer from "../../components/Debug/mousePositionReducer";
import { saveStatusReducer as workspaceSaveStatusReducer } from '../../components/Workspace/epics/saveStatus';
import tooltipEpic from '../../components/Tooltip/epics/tooltip';
import stickyTooltipEpic from '../../components/Tooltip/stickyTooltip/epics';
import quickTourEpic from '../../components/QuickTour/epics/quickTour';
import subscriptionDataEpic from '../../components/App/epics/subscriptionData';
import { recoveryDataDefaultState, reducer as recoverAfterExceptionReducer } from "../recoverAfterException";
import type { AppState } from "./flowTypes";
import isTestEnv from "../../debug/isTestEnv";
import { BACKUP_RESTORE_SUCCESS } from "../../components/Backup/actionTypes";
import handleActions from '../makeReducer/handleActions';
import { dialogsManagerEpic } from "../../components/DialogManager/epic/dialogManagerEpic";
import { demoReducer } from '../../../demo/modules/reducer/demoReducer';
import { preferencesReducer } from '../../components/Preferences/reducer/preferencesReducer';
import { whatsNewReducer } from '../../components/WhatsNew/reducer/whatsNewReducer';
import { Lit } from '../../lit';
import fontStatusReducer from "../../components/Fonts/fontStatusReducer";
import siteDataEpic from '../../components/App/epics/siteData';
import {
    actionTypesSkippedForReduxReducerMapIfEpicsStateNotChanged
} from './actionTypesSkippedForReduxReducerMapIfEpicsStateNotChanged';
import { flowFriendlySpread } from '../../utils/flowFriendlySpread';
import { socialAccountsReducer } from "../../components/SocialAccounts/socialAccountsReducer";
import { autosaveReducer } from '../../components/Autosave/reducer';

const appReducerTree: Record<string, any> = {
    action: actionReducer,
    autosave: autosaveReducer,
    preferences: preferencesReducer,
    backup: backupReducer,
    isRestoring: handleActions({
        [BACKUP_RESTORE_SUCCESS]: () => true
    }, false),
    topBar: topbarEpic.reducer,
    tooltip: tooltipEpic.reducer,
    stickyTooltip: stickyTooltipEpic.reducer,
    quickTour: quickTourEpic.reducer,
    logout: logoutEpic.reducer,
    propertiesPanel,
    selectedWorkspace,
    panel,
    tips,
    contextMenu: contextMenuEpic.reducer,
    colorPicker,
    dimensions,
    dialogs: dialogsManagerEpic.reducer,
    fileChooser,
    dndAddComponent: dndAddComponentEpic.reducer,
    loadSiteMapRequest: makeRequestReducer(siteDataLoadActionTypes),
    preview: previewEpic.reducer,
    appStatus: appStatusEpic.reducer,
    workspaceSaveStatus: workspaceSaveStatusReducer,
    cursor: cursorEpic.reducer,
    subscriptionData: subscriptionDataEpic.reducer,
    recoveryData: (state = recoveryDataDefaultState) => state,
    // TODO remove in WBTGEN-4990
    crash: (s, a) => {
        if (a.type === 'RENDER_CRASH') {
            return true;
        }
        return s || false;
    },
    // TODO: this should only be included for Demo env
    demo: demoReducer,
    [Lit.whatsNew]: whatsNewReducer,
    fontStatus: fontStatusReducer,
    siteMap: siteDataEpic.reducer,
    [Lit.socialAccounts]: socialAccountsReducer,
    epics: (s = getDefaultReducerState(epicsReducer)) => s,
};

if (isTestEnv()) {
    appReducerTree.lastAction = ((_, action) => action);
}
if (showMousePosition()) {
    appReducerTree.mousePositionWithRespectToBrowser = mousePositionReducer;
}

const appReducer = combineDiReducers(appReducerTree);

export default (appState: AppState, action: Action): AppState => { // eslint-disable-line consistent-return
    // TODO remove in WBTGEN-4990
    if (action.type === 'REDUCER_CRASH') {
        throw new Error('WBTGEN reducer crash');
    }
    // handle actions
    switch (action.type) {
        // debug reducers
        case SET_APP_STATE:
            return debugReducers.setState(appState, action);
        case UPDATE_APP_STATE:
            return debugReducers.updateState(appState, action);

        default:
            break;
    }

    let nextAppState: AppState = appState;

    const epicsStateNotChanged = !action.epicsUpdate || Object.keys(action.epicsUpdate.updatedEpicsTypes).length === 0;
    if (epicsStateNotChanged && actionTypesSkippedForReduxReducerMapIfEpicsStateNotChanged[action.type]) {
        nextAppState = appState;
    } else {
        nextAppState = appReducer(appState, action);
    }
    let nextEpicsState = epicsReducer(nextAppState.epics, action);
    if (epicsStateNotChanged) {
        nextAppState.epics = nextEpicsState; // HACK, performance optimization to stop react rerendering on every action, when epic state is not updated, but only service info is updated. Epics v2 is required to remove this HACK.
    } else {
        nextAppState = flowFriendlySpread((nextAppStateClone) => { nextAppStateClone.epics = nextEpicsState; }, nextAppState); // eslint-disable-line no-param-reassign
    }

    nextAppState = recoverAfterExceptionReducer(nextAppState, action);
    return nextAppState;
};
