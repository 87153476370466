import * as R from 'ramda';
import makeSpec from '../makeSpec/index';
import * as path from '../path';
import * as text from './cssConfig';

const
    makeTextSpec = makeSpec(text.cssConfig),
    makeTextSpecInTheme = makeSpec(text.cssConfigInTheme),
    makeTextSpecForDropdownOption = makeSpec({
        fontSize: [path.size, fs => `${R.clamp(9, 20)(fs)}px !important`]
    }),
    textSpec = (autoColorMode: boolean, additionalPath: Path = [], cssPrefix: string = '') => ({
        [cssPrefix]: autoColorMode ? makeTextSpecInTheme(additionalPath) : makeTextSpec(additionalPath),
        [`${cssPrefix}-ddo`]: makeTextSpecForDropdownOption(additionalPath)
    });

export {
    textSpec
};
