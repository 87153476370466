import * as R from "ramda";

import Image from "../../../oneweb/Image/kind";
import { dataKinds } from "../getComponentClass";
import TemplateKind from "../../../oneweb/Template/kind";
import { isBoxKind } from "../../../oneweb/componentKinds";
import { updateMobileGlobalDataOnTemplateImport } from '../../../SiteSettings/General/generalGlobalDataEpic/generalGlobalDataEpic';
import styles from "../Preview.css";
import isStretchComponentKind from '../../../oneweb/isStretchComponentKind';
import {
    getAllStylesheets,
    getStyleById
} from '../../../Workspace/epics/stylesheets/selectors';
import { getGlobalstyleClassNameFromStyle } from "../../../RenderGlobalstyles/getGlobalstyleClassName";
import getId from "../../../MobileViewEditor/getId";
import urlBuild from '../../../../../utils/urlBuild';
import * as pathUtils from "../../../../../utils/path.js";
import { PAGE_INDEX_URL } from "../../../../../dal/model/DataPageRef";
import { PAGE_DEFAULT_EXTENSION } from '../../../PagesTree/Tree/constants';
import { getSchemeFromSiteSettings, isDropHtmlExtensionEnabled } from "../../../SiteSettings/publishConfig/utils";

export const getStripStyles = (serverPreview, template: { width: number }, activateMobileView) => {
    // in _generateHtml in the row we make it as 100% to span the full width
    type styleObject = {
        width: string,
        left: string,
        minWidth?: number
    }
    let style: styleObject = { width: '100%', left: 'auto' };
    if (serverPreview && !activateMobileView) {
        style.minWidth = template.width;
    }
    return style;
};

export const calcDataKind = (id, kind, children, structure: Record<string, any>) => {
    if ((isStretchComponentKind(kind)) ||
        (isBoxKind(kind) && children) ||
        (kind === Image && structure[id] && structure[id].rows)
    ) {
        return dataKinds.block;
    }
    return dataKinds.component;
};

export const updateSiteSettingsForTemplatePreview = (globalVariables, siteSettings) => {
    const { logoAsset, websiteTitle } = globalVariables;

    return {
        ...siteSettings,
        mobile: updateMobileGlobalDataOnTemplateImport({ logoAsset, websiteTitle })(siteSettings.mobile),
        generalData: { ...siteSettings.generalData, logoAsset, websiteTitle }
    };
};

export const getColStyle = (hoverCol) => `${styles.column} col ${hoverCol ? styles.hoverCol : ''}`;

export const isAnyComponentAStrip = (nodes) => nodes && nodes.some(node => node.props.style.width === '100%');

export const getLastRowBottomMargin = (block, rows) => {
    let bottomVals = rows[rows.length - 1].columns.reduce((acc, col) => {
        acc.push(...(col.component ? [col.component] : col.components));
        return acc;
    }, []).filter(c => c.relIn && c.relIn.hasOwnProperty('bottom')).map(c => c.relIn.bottom);
    return (bottomVals.length > 0) ?
        -(bottomVals.reduce((b1, b2) => Math.max(b1, b2))) : 0;
};

export const shouldSetBottomStylesForInserterBlock = (block, parentCmpsHeightUpdatesMap) => {
    return ((!parentCmpsHeightUpdatesMap || parentCmpsHeightUpdatesMap[block.id])
            && block.height && block.kind !== TemplateKind);
};

export const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];   //NOSONAR
    }
    return color;
};

export const getGlobalStyleId = ({ style }: { style: any }, globalStyles: Record<string, any>) => {
    const
        stylesheets = getAllStylesheets(globalStyles),
        stylesheet = style && style.globalId ? getStyleById(style.globalId)(stylesheets) : null;

    return stylesheet ? getGlobalstyleClassNameFromStyle(stylesheet) : '';
};

export const isComponentIdPresent = (mobileData, id) => {
    const { data, wrappedCmpsMap, groups } = mobileData;

    const allIds = [
        ...R.toPairs(data),
        ...R.toPairs(wrappedCmpsMap),
        ...R.toPairs(groups),
    ];

    return R.any(compId => getId(compId) === id, R.flatten(allIds));
};

export const getFaviconAsset = (siteSettings) => {
    return siteSettings && siteSettings.generalData && siteSettings.generalData.favicon;
};

export const isAutoColorModeOn = (siteSettings): boolean => {
    return siteSettings && siteSettings.themeSettingsData &&
        siteSettings.themeSettingsData.autoColorMode;
};

export const getPageUrl = ({ pageRef, siteMap, domain, siteSettings }) => {
    let absoluteUrl = '';
    if (pageRef.id) {
        const
            origPageUrlPath = siteMap.getPageRefUrlPath(pageRef.id),
            isHomePage = siteMap.isHomePageId(pageRef.pageId),
            uriParts = {
                domain,
                fullPath: origPageUrlPath,
                basePath: pathUtils.arrayBasename(origPageUrlPath).join('/'),
                indexFilename: `${PAGE_INDEX_URL}.${PAGE_DEFAULT_EXTENSION}`,
                ext: `.${PAGE_DEFAULT_EXTENSION}`
            },
            isParentPage = !!pageRef.items.length,
            pageUrlPathSuffix = isParentPage ? `/${uriParts.indexFilename}` : uriParts.ext,
            urlScheme = getSchemeFromSiteSettings(siteSettings);

        absoluteUrl = urlBuild(
            isHomePage ? uriParts.indexFilename : `${origPageUrlPath.join('/')}${pageUrlPathSuffix}`,
            { scheme: urlScheme, host: uriParts.domain }
        );

        if (siteSettings &&
            isDropHtmlExtensionEnabled(siteSettings)
        ) {
            absoluteUrl = absoluteUrl.replace(/\/index\.html/g, '/').replace(/\.html/, '');
        }
    }
    return absoluteUrl;
};
