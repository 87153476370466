import { all, fork, put } from "redux-saga/effects"; // eslint-disable-line n/file-extension-in-import

import { DataSite, DataPage, DataPageRef, DataPageSet } from "../../../../../dal/model";
import { takePayloadFromDialogGen, takeSuccessFromApiActionGen, openDialogGen } from "../../../../utils/saga";
import { NewPageNameDialogId } from "../../NewPageDialog/newPageDialogIds";
import { NEW_PAGE_LAYOUT_NAME_ENTERED } from "../../NewPageDialog/actions";
import createPageSaga from "../../saga/createPageSaga";
import loadPageDataSet from "../../actionCreators/loadPageDataSet";
import makeResolveWhenFulfilledSaga from "../../../../epics/makeResolveWhenFulfilledSaga";
import { siteDataReceiveOnlyExternalValueActionType } from '../../../App/epics/siteData/valueActionType';
import { PAGE_TREE_PAGE_DUPLICATE, PAGE_TREE_PAGE_DO_DUPLICATE } from "../actionTypes";
import { PageLoadFailureDialogId } from "../../dialogs/pagesTreeDialogIds";
import checkUnsavedChangesSaga from "../../../UnsavedChanges/checkUnsavedChangesSaga";
import pageDatasetVAT from "../../../App/epics/pageDataset/valueActionType";
import { receiveOnly } from "../../../../epics/makeCondition";
import { copyPage } from '../../../../../dal/model/utils/copyPage';
import { addPageToTemplate } from '../../../../../dal/model/utils/addPageToTemplate';
import { ROSiteSettingsSelector } from "../../../App/epics/siteSettings/selectorActionTypes";
import { type SiteSettings } from "../../../App/epics/siteSettings/flowTypes";
import { doDuplicatePageAction } from "../actionCreators";

const duplicatePageSagaWorker = function* (
    site: DataSite,
    siteSettings: SiteSettings,
    pageData: DataPageSet,
    srcPageRef: DataPageRef
): Generator<any, void, any> {
    const response = yield* takeSuccessFromApiActionGen(
        loadPageDataSet(srcPageRef.pageId, false /* private api tag */)
    );
    if (!response) {
        yield* openDialogGen(PageLoadFailureDialogId);
        return;
    }
    const srcPage: DataPage = response.page;

    const pageName = yield* takePayloadFromDialogGen(
        NewPageNameDialogId,
        NEW_PAGE_LAYOUT_NAME_ENTERED,
        true /* replaceDialog */
    );

    const
        pageCopy = copyPage(srcPage, { name: pageName }),
        parentPageId = site.getPageRefParentPageId(srcPageRef.id);

    const createPageSagaParams: any = {
        site,
        siteSettings,
        srcPage: pageCopy.copy,
        generateNewId: false,
        parentPageId
    };

    const nextTemplate = addPageToTemplate(pageCopy, pageData.template);
    if (nextTemplate) {
        createPageSagaParams.updatedTemplate = nextTemplate;
    }

    // create new page
    yield* createPageSaga(createPageSagaParams);
};

const workerSaga = makeResolveWhenFulfilledSaga(
    siteDataReceiveOnlyExternalValueActionType,
    ROSiteSettingsSelector,
    receiveOnly(pageDatasetVAT),
    PAGE_TREE_PAGE_DO_DUPLICATE,
    duplicatePageSagaWorker
);

const checkSaga = makeResolveWhenFulfilledSaga(PAGE_TREE_PAGE_DUPLICATE, function* (srcPageRef: DataPageRef) {
    const checked = yield* checkUnsavedChangesSaga();
    if (!checked) return;

    yield put(doDuplicatePageAction(srcPageRef));
});

function* listener(): Generator<any, void, any> {
    yield all([
        fork(workerSaga),
        fork(checkSaga),
    ]);
}

export {
    listener as default,
    duplicatePageSagaWorker
};
