import { AssetFileInfo } from "../../utils/assetUtils";
import { ResourceMetadata } from "../../redux/modules/children/fileChooser/flowTypes";

const blobToFile = (theBlob: Blob, fileName: string) => {
        const newBlob = theBlob.slice(),
            ts = Date.now();

        // @ts-ignore
        newBlob.lastModifiedDate = new Date(ts);
        // @ts-ignore
        newBlob.lastModified = ts;
        // @ts-ignore
        newBlob.name = fileName;

        return newBlob;
    },
    contentTypeFilter = /image\/(?:gif|png|jpg|jpeg|x-icon|vnd.microsoft.icon)/, // should actually be globalised for image, but it aint
    /*
     * Returns the new image path based on the old name and newId
     * @param {object} fileInfo
     * @param {number} newId the number to append to get the new file name from the old
     */
    _getNewImageName = (fileInfo: AssetFileInfo, newId: number, fileNameSuffix: string): string => {
        let imageName = fileInfo.name;
        let suffixFileNamePattern = new RegExp(`${fileNameSuffix}\\d*\.[a-zA-Z]*$`);
        if (suffixFileNamePattern.test(imageName)) {
            // @ts-ignore
            imageName = imageName.replace(/(\d+)$/, newId) + "." + fileInfo.extn; //NOSONAR
        } else {
            imageName = imageName + fileNameSuffix + newId + "." + fileInfo.extn;
        }
        return fileInfo.dir + imageName;
    },
    getNewFileName = (entries: Array<any>, fileInfo: AssetFileInfo, fileNameSuffix: string): string => {
        let maxFileNumber = 0;
        let fileName = escape(decodeURI(fileInfo.name));
        let suffixFileNamePattern = new RegExp(`${fileNameSuffix}\\d*\.[a-zA-Z]*$`);
        let hasHiddenEditedImageNameSuffix = suffixFileNamePattern.test(fileName);
        // eslint-disable-next-line
        let fileNameWithoutNumberSuffix = hasHiddenEditedImageNameSuffix ? fileName.replace(/\d*$/g, "") : fileName + fileNameSuffix; //NOSONAR

        let regex = new RegExp("^" + fileNameWithoutNumberSuffix + "(\\d*)\\." + fileInfo.extn + "$", "g"); // eslint-disable-line
        entries.forEach(function (entry) {
            if (contentTypeFilter.test(entry.contentType)) {
                let match = regex.exec(escape(decodeURI(entry.href)));
                if (match) {
                    let num = parseInt(match[1], 10);
                    if (!isNaN(num) && num > maxFileNumber) {
                        maxFileNumber = num;
                    }
                }
            }
            regex.lastIndex = 0;
        });
        return _getNewImageName(fileInfo, maxFileNumber + 1, fileNameSuffix);
    },
    getDisplayFileName = (filename: string, extn: string, fileNameSuffix: string): string => {
        const ellipsis = "...",
            suffixFileNamePattern = new RegExp(`${fileNameSuffix}\\d*\.[a-zA-Z]*$`),
            indexOfSuffix = filename.lastIndexOf(fileNameSuffix);

        let maxNornalNameWithoutExtension = 8; // 8 "m"

        if (extn.length === 4) {
            maxNornalNameWithoutExtension -= 1;
        }

        if (!suffixFileNamePattern.test(filename)) {
            return filename.substring(0, maxNornalNameWithoutExtension) + ellipsis + extn;
        }

        const fileSuffixAndExtension = filename.substring(indexOfSuffix),
            originalFilenameWithoutExtension = filename.substring(0, indexOfSuffix);

        // if file name suffix length changes then subtract the change to max length of allowed characters
        // for example filename suffix changes to "_edited100.jpg"
        maxNornalNameWithoutExtension -= fileSuffixAndExtension.length - (fileNameSuffix.length + 4);

        if (maxNornalNameWithoutExtension < 6) {
            maxNornalNameWithoutExtension = 6;
        }
        if (originalFilenameWithoutExtension.length > maxNornalNameWithoutExtension) {
            // to cover with ellipsis
            const shortenedFilenameWithoutExtension = originalFilenameWithoutExtension.substring(0, maxNornalNameWithoutExtension);

            return shortenedFilenameWithoutExtension + ellipsis + fileSuffixAndExtension;
        }

        return filename;
    },
    getResourceMetadataFormatted = response => {
        const ResponseMask = {
            etag: null,
            contentType: null,
            filesize: null,
            width: null,
            height: null,
            alpha: null,
            bpp: null,
            animated: false,
            recommendedFormat: null,
            image: null
        };
        let finalResponse: ResourceMetadata = Object.keys(ResponseMask).reduce(
            (acc, k) => ({
                ...acc,
                [k]: response[k] || ResponseMask[k]
            }),
            {} as ResourceMetadata
        );

        // Taking Copyright exif info for the image Asset if its available.
        // Currently setting custom info in the Copyright exif info for the Unsplash images
        if (finalResponse.image) {
            if (finalResponse.image.Copyright) {
                if (
                    typeof finalResponse.image.Copyright === "object" &&
                    finalResponse.image.Copyright.type === "Buffer" &&
                    Array.isArray(finalResponse.image.Copyright.data)
                ) {
                    finalResponse = { ...finalResponse, image: { Copyright: Buffer.from(finalResponse.image.Copyright.data).toString() } };
                } else {
                    finalResponse = { ...finalResponse, image: { Copyright: finalResponse.image.Copyright } };
                }
            } else {
                finalResponse = { ...finalResponse, image: null };
            }
        }

        return finalResponse;
    },
    getUpdatedUrl = url => {
        if (/repository:\/dt\//.test(url)) {
            return url.replace(/repository:\/dt\//, "repository:/");
        }
        return url;
    };

export {
    blobToFile,
    getNewFileName,
    getDisplayFileName,
    getResourceMetadataFormatted,
    getUpdatedUrl
};
