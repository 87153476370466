import type { BBox } from "../../src/components/App/flowTypes";
import { getDAL } from "../init";
import type { MobileData } from "../../src/components/MobileViewEditor/flowTypes";

export default class DataPageTemplate {
    type!: "web.data.components.Template";
    id!: string;
    stylesheetId!: string;
    name!: string;
    width!: number;
    height!: number;
    bbox!: BBox;
    items!: Record<string, any>[];
    categories!: Array<string>;
    orderIndex!: number;
    align!: string;
    locale!: string;
    mobileDown!: boolean;
    mobileData: MobileData = { relations: [], styles: {}, groups: {}, settings: {} };
    wrap!: boolean;
    stretch!: boolean;
    verticalScroll!: string;
    mobile!: boolean;
    time!: number;
    etag!: string;
    rev!: number;
    _preViewUrls: { [key: string]: string } = {};

    constructor(data: Record<string, any>) {
        Object.assign(this, data);
    }

    getPreviewUrl(width: number, height: number, screenWidth: number = 1280) {
        const key = width + 'x' + height + 'x' + screenWidth;
        if (!this._preViewUrls[key]) {
            this._preViewUrls[key] = getDAL().makePageTemplatePreviewUrl(
                this.id,
                { width, height, cropw: width, croph: height, screenWidth }
            );
        }
        return this._preViewUrls[key];
    }
}
