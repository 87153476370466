import CALL_API from '../../../redux/middleware/api/CALL_API';
import { getLocaleCookie } from "../../Locale/cookie";
import * as Actions from '../actionTypes';
import type { ApiAction } from '../../../redux/middleware/api/flowTypes';

type PublishSiteACPropTypes = {
    force: boolean,
    forceAllowInvalidEmails ?: boolean,
    forceAndGmbForce ?: boolean,
};

export default function publishSiteActionCreator(
    endpointParams: PublishSiteACPropTypes
): ApiAction {
    return {
        [CALL_API]: {
            types: [
                Actions.PUBLISH_SITE_REQUEST,
                Actions.PUBLISH_SITE_SUCCESS,
                Actions.PUBLISH_SITE_FAILURE
            ],
            endpoint: 'publishSite',
            endpointParams: { locale: getLocaleCookie(), ...endpointParams },
        }
    };
}
