import { Lit } from "../../../../lit";
import type { SocialAccountsFBChatState, FacebookChatSocialAccountDefaultState } from "./types";
import type { ReducerConfig } from "../../../../../../src/dashboard/src/common/types";
import {
    SET_FACEBOOK_CHAT_ACCESS_CREDENTIALS_ACTION,
    UNSET_FACEBOOK_CHAT_ACCESS_TOKEN_ACTION,
    DISCONNECT_FACEBOOK_CHAT_SUCCESS_ACTION,
    SET_MANAGED_FB_CHAT_PAGES_ACTION,
    UNSET_MANAGED_FB_CHAT_PAGES_ACTION,
    SET_SELECTED_FB_CHAT_PAGE_VALUE
} from "./actions";
import { makeInitialStateReducer } from "../../../../redux/makeReducer/makeInitialStateReducer";

const facebookChatSocialAccountDefaultState: FacebookChatSocialAccountDefaultState = {
    [Lit.facebookChatConnected]: false,
    [Lit.facebookChatAccessToken]: '',
    [Lit.facebookChatAccountName]: '',
    [Lit.facebookChatAccountId]: '',
    [Lit.fbPageProfilePicUrl]: '',
    [Lit.managedFbPages]: [],
    [Lit.fbSelectedPageValue]: '',
};

export const socialAccountsDefaultState: SocialAccountsFBChatState = {
    [Lit.facebookChat]: facebookChatSocialAccountDefaultState
};

export const socialAccountsFacebookChatReducer = makeInitialStateReducer(
    // @ts-ignore
    socialAccountsDefaultState[Lit.facebookChat],
    (state: SocialAccountsFBChatState, action: Action) => {
        switch (action.type) {
            case SET_FACEBOOK_CHAT_ACCESS_CREDENTIALS_ACTION:
                return {
                    ...state,
                    [Lit.facebookChatConnected]: true,
                    [Lit.facebookChatAccessToken]: action.payload.facebookChatAccessToken,
                    [Lit.facebookChatAccountName]: action.payload.facebookChatAccountName,
                    [Lit.facebookChatAccountId]: action.payload.facebookChatAccountId,
                };
            case UNSET_FACEBOOK_CHAT_ACCESS_TOKEN_ACTION:
            case DISCONNECT_FACEBOOK_CHAT_SUCCESS_ACTION:
                return {
                    ...state,
                    [Lit.facebookChatConnected]: false,
                    [Lit.facebookChatAccessToken]: '',
                    [Lit.fbPageProfilePicUrl]: '',
                    [Lit.facebookChatAccountName]: '',
                    [Lit.facebookChatAccountId]: '',
                    [Lit.managedFbPages]: [],
                };
            case SET_MANAGED_FB_CHAT_PAGES_ACTION:
                return {
                    ...state,
                    [Lit.facebookChatConnected]: true,
                    [Lit.managedFbPages]: action.payload,
                };
            case UNSET_MANAGED_FB_CHAT_PAGES_ACTION:
                return {
                    ...state,
                    [Lit.facebookChatConnected]: false,
                    [Lit.managedFbPages]: [],
                };
            case SET_SELECTED_FB_CHAT_PAGE_VALUE:
                return {
                    ...state,
                    [Lit.fbSelectedPageValue]: action.payload,
                };
            default:
                return state;
        }
    }
);

export const socialAccountsFacebookChatReducerConfig: ReducerConfig = { reducer: socialAccountsFacebookChatReducer };
