// @ts-nocheck
import isTestEnv from '../../../../../../debug/isTestEnv';
import type { Node } from '../../../../flowTypes';
import makeIsSizeOverriden from './makeIsSizeOverriden';

export default (defaultGlobalStyles: Array<Stylesheet>) => {

    return (styleName: string, node: Node) => {
        if (isTestEnv()) {
            return false;
        } else if (styleName === 'size') {
            const isSizeOverriden = makeIsSizeOverriden(defaultGlobalStyles);
            return isSizeOverriden(node);
        }

        return false;
    };
};
