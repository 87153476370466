import * as React from 'react';

import styles from './PageInfoDialog.css';
import type { PageInfoDialogProps } from "../flowTypes";
import TabDialog, { Tab, type TabDialogOnTabSwitch } from "../../../../../../view/common/dialogs/TabDialog";
import { PageInfoNamesTab, PageInfoSeoTab, PageInfoVisibilityTab, PageInfoTemplateTabCom, PageInfoSocialShareTab } from './tabs';
import { PageInfoTab } from "../../../constants";
import DialogCheckBox from '../../../../../../view/common/dialogs/baseDialog/DialogCheckBox';
import {
    closePageInfoDialogAction,
    navigatePageInfoToSiteSettingsAction,
    savePageInfoAction,
    switchPageInfoTabAction
} from "../actions";
import { toggleIsHomePage } from "../../../actionCreators";
import Icons from '../../../../../../view/Icons';
import HorizontalSpacer from '../../../../../../view/common/HorizontalSpacer';
import { Msg } from "../../../../../../view/intl";
import { TooltipPosition } from "../../../../../presentational/Tooltip/constants";
import { parseIntDec } from "../../../../../../../utils/number";
import { QuestionTip } from "../../../../../presentational/Tooltip/questionTip/QuestionTip";
import { StripTypes } from '../../../../../../view/common/dialogs/baseDialog';
import Scrollbar from "../../../../../../view/common/Scrollbar";

const
    TEMPLATE_TIP_DISTANCE = parseIntDec(styles.templateTipDistance),
    // eslint-disable-next-line max-len
    TEMPLATE_TIP_MSG = 'msg: pageInfo.templateTip {Your template contains components and styles shared across pages. It usually includes the menu, background, header and footer.}';

export default class PageInfoDialog extends React.Component<PageInfoDialogProps> {
    onTabSwitch: TabDialogOnTabSwitch = (tab: any) => {
        this.props.dispatch(switchPageInfoTabAction(tab.props.id));
    };

    _onSave = () => {
        if (this._isSaveDisabled()) return;

        const { pageRefId, dispatch } = this.props;
        dispatch(savePageInfoAction({ pageRefId }));
    };

    onDialogCloseClick: () => void = () => {
        const { pageRefId, dispatch } = this.props;
        dispatch(closePageInfoDialogAction(pageRefId));
    };

    onSettingsClickHandler: SimpleFunction = () => {
        const { pageRefId, dispatch } = this.props;
        dispatch(navigatePageInfoToSiteSettingsAction(pageRefId));
    };

    _isSaveDisabled() {
        return !this.props.isSaveEnabled;
    }

    renderNamesTab() {
        const
            {
                state: {
                    [PageInfoTab.NAMES]: namesState,
                    [PageInfoTab.SEO]: seoState,
                },
                origPageUrlPath,
                allPagesUrlPaths,
                origHomePageUrlPath,
                isHomePage,
                isParentPage,
                urlParams,
                isDemo,
                dispatch,
                websiteTitle,
                domainName,
            } = this.props,
            props = {
                state: namesState,
                seoState: {
                    title: seoState.title,
                    customTitle: seoState.customTitle,
                    defaultNonHomePageNameInBrowserTab: seoState.defaultNonHomePageNameInBrowserTab,
                    defaultHomePageNameInBrowserTab: seoState.defaultHomePageNameInBrowserTab,
                    pageNameOptionInBrowserTab: seoState.pageNameOptionInBrowserTab,
                },
                origPageUrlPath,
                allPagesUrlPaths,
                origHomePageUrlPath,
                isHomePage,
                isParentPage,
                urlParams,
                isDemo,
                dispatch,
                websiteTitle,
                domainName,
            };

        return (
            <Tab
                id={PageInfoTab.NAMES}
                label="msg: pageNams {Page names}"
                title="msg: editPageNames {Edit page names}"
            >
                <PageInfoNamesTab {...props} />
            </Tab>
        );
    }

    renderSeoTab() {
        const
            {
                state: {
                    [PageInfoTab.NAMES]: namesState,
                    [PageInfoTab.SEO]: seoState
                },
                origPageUrlPath,
                allPagesUrlPaths,
                origHomePageUrlPath,
                isHomePage,
                isParentPage,
                urlParams,
                isDemo,
                dispatch,
                isSeoEnabledForSiteSettings,
                websiteTitle,
                domainName,
            } = this.props,
            props = {
                namesState: {
                    name: namesState.name,
                },
                state: seoState,
                origPageUrlPath,
                allPagesUrlPaths,
                origHomePageUrlPath,
                isHomePage,
                isParentPage,
                urlParams,
                isDemo,
                dispatch,
                websiteTitle,
                domainName,
                isSeoEnabledForSiteSettings,
                settingsClickHandler: this.onSettingsClickHandler
            };

        return (
            <Tab
                id={PageInfoTab.SEO}
                label="msg: seo {SEO}"
                title="msg: seoLong {SEO (Search Engine Optimization)}"
            >
                <Scrollbar height="450px">
                    <PageInfoSeoTab {...props} />
                </Scrollbar>
            </Tab>
        );
    }

    renderSocialShareTab() {
        const
            {
                state: {
                    [PageInfoTab.SOCIAL_SHARE]: socialShareState,
                    [PageInfoTab.SEO]: seoState,
                    [PageInfoTab.NAMES]: namesState,
                },
                origPageUrlPath,
                allPagesUrlPaths,
                origHomePageUrlPath,
                isHomePage,
                isParentPage,
                urlParams,
                isDemo,
                dispatch,
                websiteTitle,
                domainName,
            } = this.props,
            props = {
                state: socialShareState,
                seoState: {
                    description: seoState.description,
                    title: seoState.title,
                    customTitle: seoState.customTitle,
                    defaultNonHomePageNameInBrowserTab: seoState.defaultNonHomePageNameInBrowserTab,
                    defaultHomePageNameInBrowserTab: seoState.defaultHomePageNameInBrowserTab,
                    pageNameOptionInBrowserTab: seoState.pageNameOptionInBrowserTab,
                },
                namesState: {
                    name: namesState.name,
                },
                origPageUrlPath,
                allPagesUrlPaths,
                origHomePageUrlPath,
                isHomePage,
                isParentPage,
                urlParams,
                isDemo,
                dispatch,
                websiteTitle,
                domainName,
            };
        return (
            <Tab
                id={PageInfoTab.SOCIAL_SHARE}
                label="msg: common.socialShare {Social share}"
                title="msg: common.socialShare {Social share}"
            >
                <Scrollbar height="450px">
                    <PageInfoSocialShareTab {...props} />
                </Scrollbar>
            </Tab>
        );
    }

    renderVisibilityTab() {
        const
            { state: { [PageInfoTab.VISIBILITY]: visibilityState }, dispatch } = this.props,
            props = { state: visibilityState, dispatch };
        return (
            <Tab
                id={PageInfoTab.VISIBILITY}
                label="msg: hidePage {Hide page}"
                title="msg: hidePage {Hide page}"
            >
                <PageInfoVisibilityTab {...props} />
            </Tab>
        );
    }

    renderTemplateTabTitle() { // eslint-disable-line class-methods-use-this
        return (
            <div className={styles.templateTabTitle}>
                <Msg k="common.template">Template</Msg>
                <QuestionTip
                    position={TooltipPosition.BOTTOM}
                    distance={TEMPLATE_TIP_DISTANCE}
                    className={styles.templateTipIcn}
                    theme={{
                        container: styles.templateTipContainer,
                        tipInfo: styles.templateTipInfo
                    }}
                >{TEMPLATE_TIP_MSG}</QuestionTip>
            </div>
        );
    }

    renderTemplateTab() {
        const {
            isCurrentPage,
            pageRefId,
            pageName,
            state: { [PageInfoTab.TEMPLATE]: templateState },
            dispatch
        } = this.props;

        return (
            <Tab
                id={PageInfoTab.TEMPLATE}
                label="msg: common.template {Template}"
                title={this.renderTemplateTabTitle()}
            >
                <PageInfoTemplateTabCom
                    isCurrentPage={isCurrentPage}
                    pageRefId={pageRefId}
                    pageName={pageName}
                    state={templateState}
                    dispatch={dispatch}
                />
            </Tab>
        );
    }

    renderFooter() {
        const { state: { isHomePage }, isRootPage, origPageUrlPath, allPagesUrlPaths, dispatch } = this.props;

        if (!isRootPage) return null;

        return (
            <div style={{ display: 'flex' }}>
                <DialogCheckBox
                    label="msg: makeThisPageYourStart {Make this page your Start Page}"
                    isChecked={isHomePage}
                    onClick={() => dispatch(toggleIsHomePage(origPageUrlPath, allPagesUrlPaths))}
                />
                <HorizontalSpacer x={5} />
                <Icons.HOME_PAGE />
            </div>
        );
    }

    render() {
        const { activeTabId } = this.props;

        return (
            <TabDialog
                activeTabKey={activeTabId}
                mctaHandler={this._onSave}
                disabled={this._isSaveDisabled()}
                footer={this.renderFooter()}
                onTabSwitch={this.onTabSwitch}
                sctaHandler={this.onDialogCloseClick}
                onClose={this.onDialogCloseClick}
                dispatch={this.props.dispatch}
                stripType={StripTypes.NONE}
                dialogbodyClassName={styles.newPageDialogBody}
                tabHeadingCustomStyle={styles.pageHeading}
                switchWithActiveTabKey
            >
                {this.renderNamesTab()}
                {this.renderSeoTab()}
                {this.renderSocialShareTab()}
                {this.renderVisibilityTab()}
                {this.renderTemplateTab()}
            </TabDialog>
        );
    }
}
