
import { $Keys } from "utility-types";
import * as R from "ramda";
import {
    calcA,
    getMediaAssetsToBeShown,
} from './utils';
import type {
    InstagramGalleryComponent,
    InstagramGalleryCalcRenderPropsResult,
    InstagramGalleryComponentDependsOn,
    InstagramGalleryImageRenderProps,
    GalleryComponentExtension,
} from './flowTypes';
import type { CalcRenderer } from '../../Workspace/epics/componentsProps/flowTypes';
import { IMAGE_RATIO_NUM_MAP, MinThumbnailSize, INSTAGRAM_VIDEO } from "./constants/index";
import { makeComponentBorderStyle } from "../../../mappers/border/index";
import { IMAGE_RATIO } from "../../presentational/ImageRatioComboBox/constants";
import { trunc1 } from "../../../../utils/number";
import type { InstagramMediaAsset } from "../../SocialAccounts/instagram/types";
import { BACKGROUND_THEME_WHITE } from '../../ThemeGlobalData/constants';

type CalcMaxThumbnalDimensionParams = {
    width: number,
    columns: number,
    spacingPx: number,
    imageRatio: $Keys<typeof IMAGE_RATIO>,
    images: Array<InstagramMediaAsset>,
};

type InstagramGalleryCalcRenderProps = CalcRenderer<
    InstagramGalleryComponent, GalleryComponentExtension,
    InstagramGalleryComponentDependsOn, InstagramGalleryCalcRenderPropsResult
>

const emptyInstagramMediaAsset: InstagramMediaAsset = {
    caption: 'caption',
    id: '',
    media_type: "IMAGE",
    media_url: '',
    permalink: '',
    timestamp: '',
    username: '',
    thumbnail_url: '',
};

const
    calcImageProps =
        ({
            image, maxThumbnailWidth, maxThumbnailHeight,
            onClickAction, componentId, index, col, row, spacingPx,
            site,
            imageStyle = {}, previewBackupTime,
        }) => {
            const
                thumbnailWidth = maxThumbnailWidth,
                thumbnailHeight = maxThumbnailHeight,
                src = image.media_type === INSTAGRAM_VIDEO ? image.thumbnail_url : image.media_url,
                isFirstRow = row === 1,
                isFirstCol = col === 1,
                offsetLeft = isFirstCol ? 0 : spacingPx,
                offsetTop = isFirstRow ? 0 : spacingPx,
                imageBorderStyle = makeComponentBorderStyle({ style: { ...imageStyle } }),
                containerStyle = {
                    width: maxThumbnailWidth,
                    marginLeft: offsetLeft,
                    marginTop: offsetTop,
                },
                imgStyle = {
                    width: thumbnailWidth,
                    height: thumbnailHeight
                },
                result: InstagramGalleryImageRenderProps = {
                    container: { style: containerStyle },
                    img: { src, srcSet: '', style: imgStyle },
                    imageAvailable: true,
                    divStyle: {
                        ...imageBorderStyle,
                        width: thumbnailWidth,
                        height: thumbnailHeight
                    },
                    mediaType: image.media_type
                };

            const a = calcA({
                onClickAction,
                caption: image.caption,
                componentId,
                index,
                image,
                previewBackupTime,
                site
            });

            // @ts-ignore
            if (a) result.a = a;

            return result;
        },
    calcGalleryMaxThumbnailDimensions = (
        { width, columns, spacingPx }: CalcMaxThumbnalDimensionParams
    ) => {
        const
            imageRatio = IMAGE_RATIO.SQUARE,
            imagesAvailableWidth = width - ((columns - 1) * spacingPx),
            maxThumbnailWidth = imagesAvailableWidth / columns,
            maxThumbnailWidthTrunc = trunc1(maxThumbnailWidth),
            ratio = IMAGE_RATIO_NUM_MAP[imageRatio],
            thumbnailHeight = Math.max(trunc1(maxThumbnailWidth / ratio), MinThumbnailSize.height);
        return {
            maxThumbnailWidth: Math.max(maxThumbnailWidthTrunc, MinThumbnailSize.width),
            maxThumbnailHeight: thumbnailHeight
        };
    },
    calcRenderProps: InstagramGalleryCalcRenderProps = ({
        componentId,
        component,
        componentExtension,
        componentDependencies: {
            site,
            socialAccounts,
            stylesheetsIdToNameMap,
            themeSettingsData,
        },
        previewBackupTime,
        isServerPreview,
        selectedParentTheme,
    }) => {
        const
            {
                columns,
                rows,
                spacingPx,
                showMoreBtn,
                fontSizePx,
                captionsEnabled,
                onClickAction,
                imageStyle,
                id,
                mobileSettings,
                buttonThemeSelected,
                width,
                showMoreTxt,
            } = component,
            globalStyleId = R.path(['style', 'globalId'], component),
            globalStyleClass = stylesheetsIdToNameMap[globalStyleId],
            { maxThumbnailWidth, maxThumbnailHeight } = calcGalleryMaxThumbnailDimensions(component),
            isSocialAccountsInstagramAssetsLoading = socialAccounts.instagram.instagramAssetsLoading;
        let instagramMediaAssets;
        if (showMoreBtn) {
            instagramMediaAssets = getMediaAssetsToBeShown(socialAccounts.instagram.instagramAssetUrls, columns, rows * 2);
        } else {
            instagramMediaAssets = getMediaAssetsToBeShown(socialAccounts.instagram.instagramAssetUrls, columns, rows);
        }

        if (isServerPreview) {
            // Adding empty media assets as placeholders while publishing so that those can be replaced and
            // populated while information is fetched dynamically from the PHP handler
            if (showMoreBtn) {
                instagramMediaAssets = Array(columns * rows * 2).fill(emptyInstagramMediaAsset);
            } else {
                instagramMediaAssets = Array(columns * rows).fill(emptyInstagramMediaAsset);
            }
        }

        const
            imagesProps = instagramMediaAssets.map((mediaAsset, index) => calcImageProps({
                image: mediaAsset,
                maxThumbnailWidth,
                maxThumbnailHeight,
                spacingPx,
                col: 1 + (index % columns),
                row: 1 + Math.floor(index / columns),
                index,
                onClickAction,
                componentId,
                site,
                previewBackupTime,
                imageStyle,
            }));

        return {
            width,
            columns,
            rows,
            componentId: id,
            captionsEnabled,
            images: imagesProps,
            mobileSettings,
            captionsContainerHeight: componentExtension ? componentExtension.highestCaptionPx : 0,
            isSocialAccountsInstagramAssetsLoading,
            isServerPreview: isServerPreview || false,
            showMoreBtn,
            fontSizePx,
            globalStyleClass,
            selectedParentTheme: selectedParentTheme || BACKGROUND_THEME_WHITE,
            themeSettingsData,
            buttonThemeSelected,
            showMoreTxt,
        };
    };

export default calcRenderProps;

export {
    calcGalleryMaxThumbnailDimensions
};
