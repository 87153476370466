import { getMouseMovedDiff } from '../../../../../redux/modules/children/workspace/reducers/utils/index';
import isStretchComponentKind from '../../../../oneweb/isStretchComponentKind';
import type { ComponentsMutationsHandler } from './flowTypes';
import { getAllAttachmentsForCmpIds } from '../../componentAttachements/util';
import { ContainerKinds } from '../../../../../utils/containerKinds';

const getNewY = (y, componentsMap, componentIds) => {
    if (!componentIds.length) return y;
    let minTop = Infinity;
    componentIds.forEach(id => {
        minTop = Math.min(componentsMap[id].top, minTop);
    });
    minTop = -1 * minTop;
    return y < minTop ? minTop : y;
};

const applyMovingComponentsMutations: ComponentsMutationsHandler = ({
    components: { componentsMap },
    componentsIds,
    start,
    current,
    attachments,
    orderIndex: maxOrderIndex = 0
}) => {
    let orderIndexCounter = 0;
    const oldOrderIndexes = {},
        isStretchComponentPresent = componentsIds.some(cmpId =>
            isStretchComponentKind(componentsMap[cmpId].kind, componentsMap[cmpId].stretch)),
        { x, y } = getMouseMovedDiff(start, current),
        newY = getNewY(y, componentsMap, componentsIds),
        updateComponentPosition = (prev, id, component, skipLeftUpdate, orderIndex) => ({
            ...prev,
            [id]: {
                ...component,
                left: skipLeftUpdate ? component.left : component.left + x,
                top: component.top + newY,
                orderIndex
            }
        }),
        affectedComponentsMap = componentsIds
            .sort((id1, id2) => componentsMap[id1].orderIndex - componentsMap[id2].orderIndex)
            .reduce((prev, id) => {
                const component = componentsMap[id];
                oldOrderIndexes[id] = component.orderIndex;
                orderIndexCounter++;
                return updateComponentPosition(
                    // @ts-ignore this need to be fixed, it may be causing a bug
                    prev,
                    id,
                    component,
                    isStretchComponentPresent,
                    maxOrderIndex + orderIndexCounter
                );
            }, {});

    let attachedComponentsMap = {};
    componentsIds.forEach(parentId => {
        const parentCmp = componentsMap[parentId];
        if (ContainerKinds[parentCmp.kind]) {
            let attachedCmpsToMove = getAllAttachmentsForCmpIds(attachments || {}, [parentId], componentsIds);
            attachedComponentsMap = attachedCmpsToMove.reduce((prev, id) => {
                const component = componentsMap[id];
                return updateComponentPosition(
                    prev,
                    id,
                    component,
                    isStretchComponentPresent || isStretchComponentKind(parentCmp.kind, parentCmp.stretch),
                    component.orderIndex -
                        oldOrderIndexes[parentId] +
                        (affectedComponentsMap[parentId] || componentsMap[parentId]).orderIndex
                );
            }, attachedComponentsMap);
        }
    });

    return { ...componentsMap, ...affectedComponentsMap, ...attachedComponentsMap };
};

export default applyMovingComponentsMutations;
