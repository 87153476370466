
import React from 'react';
import { useSelector } from 'react-redux';
import cx from 'classnames';
import { Msg } from "../../../../../../view/intl/index";
import styles from './UpgradePlansDialogImages.css';
import { isShopInAllTiersEnabled } from "../../../../../ComponentTierManager/utils";
import { makeEpicStateSelector } from '../../../../../../epics/makeEpicStateSelector';
import { AppState } from '../../../../../../redux/modules/flowTypes';
import webshopSubscriptionVAT from '../../../../../oneweb/WebShop/epics/subscription/valueActionType';

const RenderPlanSellOnlineDescription = () => {
    const webshopSubscriptionData = useSelector((state: AppState) => makeEpicStateSelector(webshopSubscriptionVAT)(state));
    const isSiatAllowedFromShopApi = webshopSubscriptionData.onlineShopSetupApiResponse?.isSIATCustomer;

    return isShopInAllTiersEnabled() && isSiatAllowedFromShopApi ?
        (<Msg className={cx(styles.txtStyle, styles.imageBodyTxtStyle)} k="upgrade.plans.dialog.std.sellOnline.body">
            Expand your Online Shop and create as many products and variants as you need
        </Msg>) :
        (<Msg className={cx(styles.txtStyle, styles.imageBodyTxtStyle)} k="upgrade.plans.dialog.std.img3.body">
            Easily set up an online shop and sell products or services online
        </Msg>);
};

export {
    RenderPlanSellOnlineDescription
};
