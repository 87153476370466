import getAccessManager from "../../../getAccessManager";
import { isBasicSubscription } from "../../App/epics/subscriptionData/utils";
import { isPartnerSubdomain } from "../../ComponentTierManager/partnerSubdomainNameUtils";

export const isPremiumTemplateBasedOnSubscription = (template, subscriptionType) => {
    const isBasicUser = isBasicSubscription(subscriptionType);
    const isPremiumTemplate = isBasicUser && !template.categories.includes('one_page');

    return isPremiumTemplate;
};

export const isOnlineShopCmpsNotAllowedBySubscriptionType = () =>
    !getAccessManager().isOnlineShopCmpsAllowed() || isPartnerSubdomain();
