import { savePreferencesAction } from "../../Preferences/actions";

export const
    HIDE_CONTACTS_TOOLTIP = 'hideContactsTooltip',

    OPEN_CONTACT_DETAILS_ACTION = "OPEN_CONTACT_DETAILS_ACTION";

export const
    hideContactsTooltipAction = () => savePreferencesAction({ [HIDE_CONTACTS_TOOLTIP]: true }),

    openContactDetailsAction = ({ email }: { email: string; }) => ({
        type: OPEN_CONTACT_DETAILS_ACTION,
        payload: { email }
    });
