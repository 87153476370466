/* @flow */
const extend = require('extend');
const { getTrialRuntime } = require('./trialRuntime.js');

const { normalizeLocale } = require("../../server/shared/utils/normalizeLocale.js");
const { DEFAULT_LOCALE } = require("../../server/shared/constants.js");

// Do not remove below require statements. Needed to map config files in trial dist bundle.
// $FlowFixMe
require('../trialConfig/filesList.js'); // eslint-disable-line import/extensions

/*::
    // TODO: proper type
    type AwsTrialConfig = Object
 */

let trialConfigSingleton;

const getTrialConfig = ()/*: AwsTrialConfig */ => {
    if (trialConfigSingleton) {
        return trialConfigSingleton;
    }

    let trialConfig,
        trialConfigPatch;

    const runtime = getTrialRuntime();
    if (runtime.isLambda()) {
        const trialConfigStr = process.env.TRIAL_CONFIG;
        if (!trialConfigStr) {
            throw new Error('Missing trial lambda config at: process.env.TRIAL_CONFIG');
        }

        try {
            trialConfig = JSON.parse(trialConfigStr);
        } catch (e) { // eslint-disable-line no-unused-vars
            throw new Error(`Trial config process.env.TRIAL_CONFIG must be a valid json`);
        }

    // !!! DO NOT REMOVE #if / #end COMMENTS BELOW !!! (USED TO CUT THE CODE FROM WEBPACK BUNDLE)
    // #if process.env.NODE_ENV === 'blah'
//     } else if (runtime.isTestEnv()) {
//         trialConfig = require('../../wbtgen/getConfig.js')('development', false).oneWeb.trial; // eslint-disable-line global-require
//     } else if (runtime.isCommandLine()) {
//         if (process.env.CONFIG_FILE_NAME) {
//             const configFileName = process.env.CONFIG_FILE_NAME.replace('.cjson', '');
//             trialConfig = require('../../wbtgen/getConfig.js')(configFileName, false, true).oneWeb.trial; // eslint-disable-line global-require
//         } else {
//             trialConfig = require('../../wbtgen/getConfig.js')('config', false, true).oneWeb.trial; // eslint-disable-line global-require
//         }
    // #endif
    } else if (runtime.isServer()) {
        // $FlowFixMe
        if (!process.appConfig) {
            throw new Error('Undefined process.appConfig');
        }

        trialConfig = process.appConfig.oneWeb.trial;
    } else if (runtime.isWbtgen()) {
        const { getAppConfig } = require('../../wbtgen/src/components/App/epics/appConfig/appConfig.js'); // eslint-disable-line global-require
        trialConfig = getAppConfig().oneWeb.trial;
    } else {
        throw new Error(`Config for trial runtime '${runtime.value}' is not implemented yet`);
    }

    const partnerName = trialConfig.partnerName,
        environment = trialConfig.env;
    if (runtime.isLambda()) {
        // $FlowFixMe
        trialConfigPatch = require(`./data#trialConfig#${partnerName}.${environment}.config.js`);  // eslint-disable-line
    } else {
        // $FlowFixMe
        trialConfigPatch = require(`../trialConfig/${partnerName}.${environment}.config.js`);   // eslint-disable-line
    }

    trialConfigSingleton = extend(true, {}, trialConfig, trialConfigPatch);

    return trialConfigSingleton;
};

const getTrialAwsConfig = () => getTrialConfig().awsConfig;

const getTrialConfigWithFallback = (appConfig/*:? Object */ = null) => (
    appConfig
        ? appConfig.oneWeb.trial
        : getTrialConfig()
);

const getTrialAwsConfigWithFallback = (appConfig/*:? Object */ = null) =>
    getTrialConfigWithFallback(appConfig).awsConfig;

const getDefultLocaleId = () => {
    const { defaultLocaleId = DEFAULT_LOCALE } = getTrialConfig();
    return defaultLocaleId;
};

const getDefultLocaleIdNormalized = () => normalizeLocale(getDefultLocaleId());

module.exports = {
    getTrialConfig,
    getTrialAwsConfig,
    getTrialConfigWithFallback,
    getTrialAwsConfigWithFallback,
    getDefultLocaleId,
    getDefultLocaleIdNormalized,
};
