import React, { useContext, useEffect, useMemo, useState } from "react";
import { Autocomplete, TextField } from "@mui/material";
import { useSelector } from "react-redux";
import cx from "classnames";
import { useIntl } from "../../../../../view/intl";
import {
    convertLabelAsPerLanguage,
    createGMBOption,
    getDefaultGMBOption,
    getGMBCategoryOptions
} from "../../../../Onboarding/Dynamic/view/Steps/WebsiteCategory/helpers";
import { DynamicTemplateContext } from "../../../../Onboarding/Dynamic/view/Context";
import { searchInWebsiteCategories } from "../../../../Onboarding/Dynamic/view/Steps/WebsiteCategory/searchInWebsiteCategories";
import { synonymsSelector } from "../../../../Onboarding/Dynamic/Epic/selectors";
import { RenderCategoryOption } from "../../../../Onboarding/Dynamic/view/Steps/WebsiteCategory/RenderCategoryOption";
import styles from "../OnboardingWizard.css";
import { AICategoryType } from "../types";
import { MAX_INPUT_LENGTH } from "../constants";

interface Props {
    id: string;
    category: AICategoryType;
    label: string;
    onInputChange: (category: AICategoryType) => void;
    errorText: string | null;
}

let prevSearch = "";
let wcSearchResults: Array<any> = [];

export const BlogCategorySelector = ({ id, category, label, onInputChange, errorText }: Props) => {
    const intl = useIntl();
    const { shopDisabled } = useContext(DynamicTemplateContext);
    const options = useMemo(() => getGMBCategoryOptions(intl, shopDisabled), [intl, shopDisabled]);
    const synonyms = useSelector(synonymsSelector);

    const [isFocused, setFocused] = useState<boolean>(false);

    // set the input to the preselected category in case there is one
    const [inputField, setInputFieldValue] = useState<AICategoryType>(
        getDefaultGMBOption(options, category.value)
    );

    // update the AI data with the preselected category label
    useEffect(() => {
        onInputChange(inputField);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const filterOptions = (options, { inputValue }) => {
        if (!inputValue) return [];

        let inpVal = inputValue.trim().toLowerCase(),
            filteredOptions = [];

        if (prevSearch !== inpVal) {
            wcSearchResults = searchInWebsiteCategories({
                options,
                inputValue,
                websiteCategorySynonyms: synonyms
            });
            prevSearch = inpVal;
        }
        filteredOptions = convertLabelAsPerLanguage(wcSearchResults, intl);

        return filteredOptions;
    };

    const handleFocus = () => {
        setFocused(true);
    };

    const handleBlur = () => {
        setFocused(false);
    };

    return (
        <div className={styles.inputContainer} data-testid="input-container">
            <label htmlFor={id} className={errorText ? styles.errorLabel : ""}>
                {label}
            </label>
            <Autocomplete
                id={id}
                freeSolo
                autoComplete
                disableClearable
                options={options}
                filterOptions={filterOptions}
                inputValue={inputField.label}
                renderInput={params => <TextField {...params} />}
                getOptionLabel={option => {
                    return typeof option === "string" ? option : option.label;
                }}
                renderOption={(props, option, { inputValue, index }) => {
                    const optionProps = {
                        props,
                        label: option.label,
                        value: inputValue,
                        indices: wcSearchResults[index].indices
                    };

                    return <RenderCategoryOption {...optionProps} />;
                }}
                onInputChange={(_e, _value) => {
                    // don't let the user input unallowed special characters
                    let r = new RegExp(/[\^!@#$%*()_?.,|\\[\]]/);
                    if (_value.match(r)) {
                        return;
                    }

                    // a visible character needs to be entered
                    // trim the value if it only consists of spaces
                    let value = _value;
                    if (!_value.trim().length) {
                        value = _value.trim();
                    }

                    // limit input to the max length
                    value = value.substring(0, MAX_INPUT_LENGTH);

                    const option = createGMBOption(value);
                    setInputFieldValue(option);
                    onInputChange(option);
                }}
                onChange={(_e, el) => {
                    // in case the user did not select a specific option
                    // get the first option that matches the input
                    // otherwise create a new option object
                    const selectedOption =
                        typeof el === "string" ? getDefaultGMBOption(options, el) : el;

                    setInputFieldValue(selectedOption);
                    onInputChange(selectedOption);
                }}
                classes={{
                    inputRoot: styles.autoCompleteInputRoot,
                    input: styles.autoCompleteInput,
                    endAdornment: styles.autoCompleteEndAdornment,
                    popper: styles.autoCompletePopper,
                    paper: cx(styles.autoCompletePaper, {
                        [styles.displayNone]: !inputField.value
                    }),
                    listbox: styles.autoCompleteListBox,
                    noOptions: styles.autoCompleteNoOptions,
                    root: errorText && styles.textfieldError
                }}
                onFocus={() => handleFocus()}
                onBlur={() => handleBlur()}
            />
            {(isFocused || errorText) && (
                <div className={cx(styles.belowInputText, { [styles.spaceBetween]: errorText })}>
                    {errorText && <div className={styles.errorLabel}>{errorText}</div>}
                    {isFocused && (
                        <div className={styles.characterCounter} data-testid="character-counter">
                            {inputField.label.length}/{MAX_INPUT_LENGTH}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};
