import { takeLatest, put, take, call } from "redux-saga/effects"; // eslint-disable-line n/file-extension-in-import
import * as R from "ramda";
import {
    _FC_SET_FILE_LIST_UPLOAD_PROGRESS,
    FC_COMPUTER_UPLOAD,
    FC_COMPUTER_UPLOAD_FAILURE,
    FC_COMPUTER_UPLOAD_SUCCESS,
    FC_RESOURCE_UPLOADING_SUCCESS,
    FC_RESOURCE_UPLOADING,
    FC_RESOURCE_UPLOADING_FAILED,
    FC_UPLOAD_CANCEL,
    FC_UPLOAD_RETRY,
    FC_RESOURCE_UPLOAD_CANCELED,
    FC_RESOURCES_FETCH_SUCCESS,
    FC_COMPUTER_UPLOAD_STARTED,
    FC_COMPUTER_UPLOAD_FINISHED,
    FC_ADD_GHOST_RESOURCE,
    FC_REMOVE_GHOST_RESOURCE,
} from "../../../actionTypes";
import type { UploadFromComputerAction } from "../../../actionCreators/index";
import { resolveUploadFilesSaga } from "./resolveUploadFilesSaga";
import resetResourcesSaga from "../resetResourcesSaga";
import reloadResourcesSaga from "../../reloadResourcesSaga";
import getResourcesGen from "../utils/getResourcesGen";
import { closeDialogGen, openDialogGen, putGen, raceGen, selectGen } from "../../../../../../../utils/saga/index";
import getSelectionGen from "../utils/getSelectionGen";
import setSelectionGen from "../utils/setSelectionGen";
import Resource from "../../../Resource";
import type { AppState } from "../../../../../flowTypes";
import uploadFromComputerApiAction from "../../../actionCreators/uploadFromComputerApiAction";
import uploadFromComputerVideoApiAction from "../../../actionCreators/uploadFromComputerVideoApiAction";
import { unghostResourceByName } from "../../../utils/unghostResourceByName";
import * as pathUtils from "../../../../../../../../utils/path.js";
import { setResourceIsLoading } from "../utils/setResourceIsLoading";
import getResourceMetadataSaga from "../../getResourceMetadataSaga";
import type { ResolveUploadFilesSagaResult, ResourceData } from './resolveUploadFilesSaga';
import { FailedErrorDialog, InsufficientStorageErrorDialog } from '../../../../../../../view/common/FileChooser/dialogIds';
import { CLOSE_DIALOG } from '../../../../../actionTypes';
import { selectedResources } from "../utils/selectedResources";
import { DalErrorName } from "../../../../../../../../dal/constants";
import checkTranscodeStatus from "../../../../../../../components/oneweb/Video/actionCreators/checkTranscodeStatus";
import { CHECK_TRANSCODE_STATUS_SUCCESS, VIDEO_UPLOAD_FAILED, VIDEO_UPLOAD_STARTED, FETCH_TRANSCODE_STATUS_UNTIL_VIDEO_UPLOADS,
    CHECK_TRANSCODE_STATUS_FAILURE } from "../../../../../../../components/oneweb/Video/actionTypes";
import { VideoUtils } from '../../../../../../../utils/fileUtils';

type FailedResourceData = ResourceData & {
    error: string,
};

const makeFileApiTakeEffect = (actionType: string, fileName: string) => take(action => (
    action.type === actionType && action.endpointParams[0].name === fileName
));

const makeVideoApiTakeEffect = (actionType: string, fileName: string) => take(action => {
    return (
        action.type === actionType
        && action.payload
        && action.payload[encodeURIComponent(fileName)]
        && action.payload[encodeURIComponent(fileName)].status.toLowerCase() === "completed"
    ) || (
        action.type === actionType
        && action.responseType === "failure"
    );
});

const putProgressGen = function* (progress) {
    yield put({
        type: _FC_SET_FILE_LIST_UPLOAD_PROGRESS,
        payload: { fileListUploadProgress: { ...progress } }
    });
};

export const selectResourcesSaga = function* (finalResourceNames: Array<string>): Generator<any, any, any> {
    const
        resources = yield* getResourcesGen(),
        {
            isMultiSelect,
            maxMultiSelectValidation
        } = yield* selectGen((appState: AppState) => appState.fileChooser);

    const resourceList = finalResourceNames.map(rn => resources.find((r: Resource) => r.getName() === rn));

    let selection = isMultiSelect ? resourceList : resourceList.slice(-1),
        currentSelection;
    if (isMultiSelect && maxMultiSelectValidation) {
        currentSelection = yield* getSelectionGen();
        selection = R.unionWith(R.eqBy(R.invoker(0, 'getName')), currentSelection, selection)
            .slice(0, maxMultiSelectValidation.remaniningLimit);
    }
    yield* setSelectionGen(selection.filter(el => (el instanceof Object)));
};

const doUploadResourcesSaga = function* (params: ResolveUploadFilesSagaResult): Generator<any, any, any> {
    const {
        allResources,
        uploadResources,
        cancelResourcesCnt,
        totalUploadResourcesCnt,
        webpaceCurrentPath
    } = params;

    let nextResources = allResources;

    // keep track of failed ones to retry
    const failedResources: Array<FailedResourceData> = [];

    // advance progress
    const progress = {
        total: totalUploadResourcesCnt,
        done: 0
    };

    const finishProgressSaga = function* () {
        yield* reloadResourcesSaga();
        yield take(FC_RESOURCES_FETCH_SUCCESS);
        yield put({ type: FC_COMPUTER_UPLOAD_FINISHED });
        yield* selectResourcesSaga(uploadResources.map(({ resource }) => resource.getName()));
    };

    const handleFailureSaga = function* () {
        const { insufStorErrRes: insufficientStorageErrorResources, retrErrorRes: retriableErrorResources } : {
            insufStorErrRes: any[],
            retrErrorRes: any[]
        } =
            failedResources.reduce(({ insufStorErrRes, retrErrorRes }, { error, resource, file }) => {
                if (error === DalErrorName.INSUFFICIENT_STORAGE) {
                    // @ts-ignore
                    insufStorErrRes.push({ resource, file });
                } else {
                    // @ts-ignore
                    retrErrorRes.push({ resource, file });
                }
                return { insufStorErrRes, retrErrorRes };
            }, { insufStorErrRes: [], retrErrorRes: [] });

        if (insufficientStorageErrorResources.length) {
            yield* openDialogGen(
                InsufficientStorageErrorDialog,
                { fileNames: insufficientStorageErrorResources.map(({ resource }) => resource.getName()) }
            );
        }

        // retry logic
        if (retriableErrorResources.length) {
            yield* openDialogGen(
                FailedErrorDialog,
                { fileNames: retriableErrorResources.map(({ resource }) => resource.getName()) }
            );
            const { retry, cancel } = yield* raceGen({
                retry: take(FC_UPLOAD_RETRY),
                cancel: take(FC_UPLOAD_CANCEL),
                close: take(CLOSE_DIALOG)
            });
            if (retry) {
                yield* closeDialogGen();

                const params = {
                    allResources: nextResources,
                    uploadResources: retriableErrorResources,
                    cancelResourcesCnt: 0,
                    totalUploadResourcesCnt: retriableErrorResources.length,
                    webpaceCurrentPath
                };
                yield* doUploadResourcesSaga(params);
            } else if (cancel) {
                yield* putGen(FC_RESOURCE_UPLOAD_CANCELED);
                yield* closeDialogGen();
                return;
            }
        }
    };

    const progressSaga = function* (sucess: boolean) {
        if (sucess) {
            progress.done++;
            yield* putProgressGen(progress);
        }

        if (progress.total === progress.done + cancelResourcesCnt + failedResources.length) {
            if (failedResources.length) {
                yield handleFailureSaga();
            }
            // finish
            yield finishProgressSaga();
        }
    };

    // do upload resource
    const uploadResourceSaga = function* (resource: Resource, file: File) {
        const resourcePath = pathUtils.build([webpaceCurrentPath, resource.getName()]);
        const { resources, currentResource } = setResourceIsLoading(resource.getId(), nextResources);
        nextResources = resources;
        yield* resetResourcesSaga(nextResources);
        yield* putGen(FC_ADD_GHOST_RESOURCE, { resource: currentResource });
        yield* putGen(FC_RESOURCE_UPLOADING, { resource: currentResource });

        if (resource.isVideo()) {
            // @ts-ignore
            yield put(uploadFromComputerVideoApiAction(file, resourcePath));
            yield putGen(VIDEO_UPLOAD_STARTED, { fileName: file.name });
        } else {
            // @ts-ignore
            yield put(uploadFromComputerApiAction(file, resourcePath));
        }
        const { success, failure } = yield* raceGen({
            success: makeFileApiTakeEffect(FC_COMPUTER_UPLOAD_SUCCESS, file.name),
            failure: makeFileApiTakeEffect(FC_COMPUTER_UPLOAD_FAILURE, file.name)
        });
        if (success) {
            let resourcePatch = {};
            if (resource.isVideo()) {
                // @ts-ignore
                yield put(checkTranscodeStatus());

                // Wait for the video to be transcoded & downloaded in webspace
                // for updating the UI in file chooser

                yield* raceGen({
                    videoSuccess: makeVideoApiTakeEffect(CHECK_TRANSCODE_STATUS_SUCCESS, file.name),
                    videoFailure: makeVideoApiTakeEffect(CHECK_TRANSCODE_STATUS_FAILURE, file.name)
                });
            } else {
                let imageMetadata;
                if (resource.isImage()) {
                    imageMetadata = yield* getResourceMetadataSaga(resourcePath);
                }
                resourcePatch = { etag: (imageMetadata && imageMetadata.etag) || '' };
            }
            const { resources, currentResource } = unghostResourceByName({
                resources: nextResources,
                resourceName: resource.getName(),
                resourcePatch
            });
            yield putGen(FC_REMOVE_GHOST_RESOURCE, { resource: currentResource });
            nextResources = resources;
            yield* putGen(FC_RESOURCE_UPLOADING_SUCCESS, { resource: currentResource });

            resetResourcesSaga(nextResources);

            yield* progressSaga(true);
        } else if (failure.payload.error === DalErrorName.WebspaceUploadLimitReached) {
            yield* reloadResourcesSaga();
        } else {
            failedResources.push({ resource, file, error: failure.payload.error });
            if (resource.isVideo()) {
                yield putGen(VIDEO_UPLOAD_FAILED, { fileName: file.name });
            }
            yield* putGen(FC_RESOURCE_UPLOADING_FAILED, { resource: currentResource });
            yield* progressSaga(false);
        }
    };

    // render all resources
    yield* resetResourcesSaga(allResources);
    yield* putProgressGen(progress);
    yield put({ type: FC_COMPUTER_UPLOAD_STARTED });

    // upload resources
    for (const uploadResource of uploadResources) {
        const { resource, file } = uploadResource;
        yield call(uploadResourceSaga, resource, file);
    }
};

const isVideoUploadInProgress = (activeTabId: string, clientUploadQ: Array<string>) =>
    (activeTabId === "myVideos" && clientUploadQ.length > 0);

export const uploadFromComputerSaga = function* (): Generator<any, any, any> {
    yield takeLatest(FC_COMPUTER_UPLOAD, function* (action: UploadFromComputerAction) {
        const
            { activeTabId } = yield selectGen((appState: AppState) => appState.fileChooser), // TODO
            { clientUploadQ } = yield selectGen((appState: AppState) => appState.epics.VIDEO_COMPONENT_VAT.state),
            videoUploadInProgress = isVideoUploadInProgress(activeTabId, clientUploadQ),
            resolveUploadFilesProps = { activeTabId, videoUploadInProgress },
            { payload: files } = action;
        if (!VideoUtils.hasMultipleVideos(files)) {
            const resolvedResources = yield* resolveUploadFilesSaga(files, resolveUploadFilesProps);

            if (!resolvedResources) {
                yield* putGen(FC_RESOURCE_UPLOAD_CANCELED);
                return;
            }

            yield* selectedResources(resolvedResources.uploadResources);

            yield* doUploadResourcesSaga(resolvedResources);
        } else {
            yield* putGen(FETCH_TRANSCODE_STATUS_UNTIL_VIDEO_UPLOADS);
            return;
        }
    });
};
