import ReactDOMServer from 'react-dom/server'; // eslint-disable-line n/file-extension-in-import
import type { AnyComponent } from '../../../flowTypes';

function getDefaultStyles(alignment: string): string {
    const defaultMargins = 'margin-left:auto; margin-right:auto;';
    return defaultMargins.replace(new RegExp(`(margin-${alignment}):auto`), '$1:0px');
}

export default (
    alignment: string,
    component: AnyComponent,
    styles: string = getDefaultStyles(alignment),
    renderer?: Function
) => {
    const
        // min-height to accomodate for button height inconsistency
        styleAttr = `style="${styles} width:${component.width}px; min-height:${component.height}px; font-size:0px;"`,
        dataWrapIdAttr = `data-wrap-id="${component.id}"`,
        content = renderer ? ReactDOMServer.renderToString(renderer(component)) : '';

    return `<div ${styleAttr} ${dataWrapIdAttr}>${content}</div>`;
};
