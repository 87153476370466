import React from 'react';
import cx from 'classnames';
import { connect } from 'react-redux';
import Measure from 'react-measure';
import { propertiesPanelValueActionType } from '../epic/valueActionType';
import { PROPERTIES_PANEL_PAGE_HEIGHT } from "../epic/actionTypes";
import { SCHEDULE_ACTION } from '../../../redux/middleware/schedule/actionTypes';
import * as styles from './PropertiesPanel.css';
import type { AppState } from '../../../../../src/dashboard/src/common/types';

type PropTypes = {
    opacity: number,
    children?: any,
    dispatch: Dispatch,
    halfSpacing?: true
}

const Page = ({ opacity, children, halfSpacing, dispatch }: PropTypes) => (
    <Measure
        offset
        onResize={({ offset }) => {
            const actionToDispatch = { type: PROPERTIES_PANEL_PAGE_HEIGHT, payload: offset.height };
            if (opacity === 0) {
                dispatch({ type: SCHEDULE_ACTION, payload: { actionToDispatch, timeout: 50 } });
            } else {
                dispatch(actionToDispatch);
            }
        }}
    >
        {
            ({ measureRef }) =>
                <div className={cx(styles.page, { [styles.halfSpacing]: halfSpacing })} ref={measureRef} data-testid="page">
                    {children}
                </div>
        }
    </Measure>
);

// $FlowFixMe: TODO: fix connect
export default connect((appState: AppState) => {
    const
        { epics } = appState,
        { state: { state: { opacity } } } = epics[propertiesPanelValueActionType];

    return { opacity };
})(Page);
