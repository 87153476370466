import InputField from './InputField';
import type { NumberFieldProps } from "./flowTypes";

function onKeyDown(e: any): void {
    switch (e.key) {
        case "ArrowUp":
            e.preventDefault();
            // @ts-ignore
            this.updateValue(this.checkValue(parseFloat(this.getValue() || 0) + (e.shiftKey ? 10 : 1)), e.target);
            break;
        case "ArrowDown":
            e.preventDefault();
            // @ts-ignore
            this.updateValue(this.checkValue(parseFloat(this.getValue() || 0) - (e.shiftKey ? 10 : 1)), e.target);
            break;
        default:
    }
}

class NumberInputField extends InputField<NumberFieldProps> {
    constructor(props: NumberFieldProps) {
        super(props);
        this.state = {
            ...this.state,
            // @ts-ignore
            allowDecimals: this.props.allowDecimals !== null ? this.props.allowDecimals : true
        };
        this.onKeyDown = onKeyDown.bind(this);
    }

    getValue(): any {
        return this.state.value;
    }

    checkValue(value: number) {
        const { min = 0, max = 100 } = this.props;

        if (value < min) return min;

        if (value > max) return max;

        return value;
    }

    parseValue(value: any) {
        let v = value;

        // @ts-ignore
        if (v && !this.state.allowDecimals) {
            v = parseInt(String(v), 10); // get only the non decimal part
        }
        v = parseFloat(String(v));
        return isNaN(v) ? '' : v;
    }
}

export default NumberInputField;
