import * as R from 'ramda';
import React from 'react';
import ScaleStrategy from '../scaleStrategy';
import Image from './Image';
import type { ImageCalcRenderPropsResult } from '../flowTypes';

const
    renderEditCropModeImage = props => (
        <div style={{ position: 'relative' }}>
            <Image
                imageAvailable={props.imageAvailable}
                imageAttributes={props.fgImage.imageAttributes}
                divStyle={props.fgImage.divStyle}
                width={props.width}
                height={props.height}
                isWorkspace={props.isWorkspace}
            />
            <Image
                imageAvailable={props.imageAvailable}
                imageAttributes={props.bgImage.imageAttributes}
                divStyle={props.bgImage.divStyle}
                width={props.width}
                height={props.height}
                isWorkspace={props.isWorkspace}
            />
        </div>
    ),
    renderImageInSelectedMode = props => (
        <Image
            a={props.a}
            divClassName={props.divClassName}
            divStyle={props.divStyle}
            imageAttributes={props.imageAttributes}
            imageAvailable={props.imageAvailable}
            width={props.width}
            height={props.height}
            isWorkspace={props.isWorkspace}
        />
    );

export default (props: ImageCalcRenderPropsResult) => {
    const finalProps = R.assoc('isWorkspace', true, props);
    if (finalProps.mode === 'edit' && finalProps.scaleStrategy === ScaleStrategy.CROP) {
        return renderEditCropModeImage(finalProps);
    } else if (finalProps.mode === 'view') {
        return renderImageInSelectedMode(finalProps);
    }
    throw new Error('Should never happen !');
};
