import React from "react";
import { Msg } from "../../../view/intl";
import { openDialog } from "../../App/actionCreators";
import BookingsManagementDialogId from "./BookingsManagementDialog/dialogId";
import {
    isEcommerceSubscription,
    isPremiumSubscription
} from "../../App/epics/subscriptionData/utils";

const ctaOnClick = ({ dispatch }) => {
    return dispatch(openDialog(BookingsManagementDialogId));
};

const componentMainActions = {
    editButtonText: "msg: common.editBookings {Edit bookings}",

    // custom button that opens the bookings management iframe
    // props don't include properties from calcRender props like showTryForFree
    CtaButtonTextView: props => (
        <span style={{ color: "var(--colorScienceBlue)" }}>
            {
                isPremiumSubscription(props.subscriptionData.subscriptionType) ||
                isEcommerceSubscription(props.subscriptionData.subscriptionType) ?
                    <Msg k="component.bookings.cta.manage">Manage</Msg> :
                    <Msg k="extendedPanel.bookings.button.tryForFree">Try for free</Msg>
            }
        </span>
    ),

    // open the bookings management dialog on button click
    ctaOnClick
};

export { componentMainActions };
