import CALL_API from "../../../../../redux/middleware/api/CALL_API";
import {
    GET_BOOKINGS_STATE_FAILURE,
    GET_BOOKINGS_STATE_REQUEST,
    GET_BOOKINGS_STATE_SUCCESS,
    GET_BOOKINGS_SERVICES_REQUEST,
    GET_BOOKINGS_SERVICES_SUCCESS,
    GET_BOOKINGS_SERVICES_FAILURE
} from "./actionTypes";

/**
 * Dispatch action to get the bookings inserter state
 */
export const getBookingsInserterState = () => {
    return {
        [CALL_API]: {
            // types define the actions which are called
            // when the request is called, request succeeded and failed
            types: [
                GET_BOOKINGS_STATE_REQUEST,
                GET_BOOKINGS_STATE_SUCCESS,
                GET_BOOKINGS_STATE_FAILURE
            ],
            endpoint: "getBookingsData",
            endpointParams: "inserter-state"
        }
    };
};

/**
 * Dispatch action to get the list bookings services
 */
export const getBookingsServicesAction = () => {
    return ({
        [CALL_API]: {
            types: [
                GET_BOOKINGS_SERVICES_REQUEST,
                GET_BOOKINGS_SERVICES_SUCCESS,
                GET_BOOKINGS_SERVICES_FAILURE
            ],
            endpoint: "getBookingsData",
            endpointParams: "services"
        }
    } as unknown as Action);
};
