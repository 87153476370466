import { getDAL } from "../../../../dal/index";
import CALL_API from "./CALL_API";
import {
    failureApiAction,
    requestApiAction,
    SERVER_INVALID_OR_MISSING_COOKIE_ACTION,
    successApiAction
} from "./actions";
import {
    FETCH_FAILED_ERROR,
    INVALID_OR_MISSING_COOKIE_ERROR,
    JSON_PARSE_FAILED_MSG,
    WSB_STREAM_ERROR
} from '../../../../dal/dalErrors';
import type { DalResponseSet, DalResponseSetGenericBody } from "../../../../dal/flowTypes";
import isSuccessfulResponseSet from "../../../../dal/isSuccessfulResponseSet";
import { testError } from '../../../../../server/shared/utils/testError.js';
import { error } from '../../../../utils/log.js';
import { joinDisaptchResults } from "../joinDispatchResults";
import type { ApiActionParams } from './flowTypes';
import { apiErrorHandler } from './errorHandler/apiErrorHandler';
import isTestEnv from '../../../debug/isTestEnv';
import { AppStore } from "../../modules/flowTypes";

export const ApiResponseType = {
    SUCCESS: 'success',
    FAILURE: 'failure'
};

// A Redux middleware that interprets actions with CALL_API info specified.
// Performs the call and promises when such actions are dispatched.
export default (store: AppStore) => (next: Dispatch) => (action: Action) => { // eslint-disable-line
    const callAPI: ApiActionParams = action[CALL_API],
        amendToPrevious = !!action.amendToPrevious;

    if (typeof callAPI === 'undefined') {
        return next(action);
    }

    const { types, isStream } = callAPI;

    if (!Array.isArray(types) || types.length !== 3) {
        throw new Error('Expected an array of three action types.');
    }
    if (!types.every(type => typeof type === 'string')) {
        throw new Error('Expected action types to be strings.');
    }

    const
        [requestType, successType, failureType] = types,
        { endpoint, endpointParams = {}, tag: apiTag, errorHandler, extraPayload } = callAPI;

    const
        params = !Array.isArray(callAPI.endpointParams) ? [callAPI.endpointParams] : callAPI.endpointParams,
        dal = getDAL();

    if (!dal[endpoint]) throw new Error('Unknown dal method: ' + endpoint);

    const requestActionDispatchResults = store.dispatch(requestApiAction({
        type: requestType,
        payload: endpointParams,
        extraPayload,
    }));

    const errorHandlerWrapper = (e, resolve, reject) => {
        // @ts-ignore
        apiErrorHandler(store, errorHandler, e);

        // skip reject for some tests
        if (isTestEnv({ withSkipReject: true })) return;

        if (testError(e, FETCH_FAILED_ERROR) || testError(e, JSON_PARSE_FAILED_MSG)) {
            // TODO: handle generic network error separately: WBTGEN-1485
            resolve(store.dispatch(failureApiAction({
                type: failureType,
                endpointParams,
                payload: e,
                apiTag,
                extraPayload,
                amendToPrevious,
            })));
        } else if (testError(e, WSB_STREAM_ERROR)) {
            let statusCode = e.message.split('WSBSTREAMERROR: ')[1];
            if (statusCode && isNaN(statusCode)) {
                statusCode = 500;
            } else {
                statusCode = parseInt(statusCode, 10);
            }
            store.dispatch(failureApiAction({
                type: failureType,
                endpointParams,
                payload: {
                    statusCode,
                    error: e.message,
                },
                apiTag,
                extraPayload,
                amendToPrevious,
            }));
        } else {
            store.dispatch(failureApiAction({
                type: failureType,
                endpointParams,
                payload: e,
                apiTag,
                extraPayload,
                amendToPrevious,
            }));
            // this should never happen
            error('Unexpected fetch error: ', e);
            // we still want app to continue
            reject(e);
        }
    };

    const responsePromise = new Promise((resolve, reject) => {
        if (isStream) {
            dal[endpoint](...params)
                .then(res => {
                    if (res.status !== 200 || !res.ok) {
                        throw new Error(`WSBSTREAMERROR: ${res.status}`);
                    }
                    return res.body.getReader();
                })
                .then((reader: ReadableStreamDefaultReader) => {
                    async function read() {
                        const { done, value } = await reader.read();
                        const result = new TextDecoder().decode(value);
                        if (result.includes("WSBSTREAMERROR:")) {
                            throw new Error(result);
                        }
                        let dispatchResult = store.dispatch(
                            successApiAction({
                                type: successType,
                                endpointParams,
                                payload: {
                                    data: result,
                                    done
                                },
                                apiTag,
                                extraPayload,
                                amendToPrevious,
                            })
                        );
                        if (!done) {
                            // recursively call function to read the next chunk
                            dispatchResult = await read();
                        }
                        return dispatchResult;
                    }
                    return read().then(resolve); // it will resolve with the last dispatch result
                })
                .catch((err: any) => {
                    const errorObj = err instanceof Error ? err : new Error(`${err}`);
                    errorHandlerWrapper(errorObj, resolve, reject);
                });
            return;
        }
        dal[endpoint](...params)
            .then((responseSet: DalResponseSet<DalResponseSetGenericBody>) => {
                const { body } = responseSet;
                let responseActionDispatchResult;

                if (isSuccessfulResponseSet(responseSet, apiTag)) {
                    responseActionDispatchResult =
                        store.dispatch(successApiAction({
                            type: successType,
                            endpointParams,
                            payload: body,
                            apiTag,
                            extraPayload,
                            amendToPrevious,
                        }));
                } else if (body.error === INVALID_OR_MISSING_COOKIE_ERROR) {
                    responseActionDispatchResult = store.dispatch({ type: SERVER_INVALID_OR_MISSING_COOKIE_ACTION });
                } else {
                    apiErrorHandler(store, errorHandler, body);

                    responseActionDispatchResult =
                        store.dispatch(failureApiAction({
                            type: failureType,
                            endpointParams,
                            payload: body,
                            apiTag,
                            extraPayload,
                            amendToPrevious,
                        }));
                }
                resolve(responseActionDispatchResult);
            })
            .catch((e: Error) => {
                errorHandlerWrapper(e, resolve, reject);
            });
    });

    return joinDisaptchResults(requestActionDispatchResults, responsePromise);
};
