import {
    checkIncompatibleComponents as checkStandardOrPremiumOrEcommerceComponentUsed,
    isPremiumComponentUsed,
    isEcommerceComponentUsed,
    isEcommerceFeatureActiveInSiteSetting
} from "../../../../../server/shared/premiumFeatures/helper.js";
import {
    isBasicSubscription,
    isNormalSubscription,
    isPremiumSubscription,
    isEcommerceSubscription,
} from "../../App/epics/subscriptionData/utils";
import { DataSite, DataPageRef } from '../../../../dal/model/index';
import type { SiteSettings } from "../../App/epics/siteSettings/flowTypes";
import type { SubscriptionTypeT } from "../../../redux/modules/flowTypes";

export const mergeFeaturesData = (source: Object, dest: Object): Object => {
    return Object.keys(dest).reduce((acc: Object, itemId: string): Object => {
        if (Array.isArray(acc[itemId])) {
            acc[itemId] = [...acc[itemId], ...dest[itemId]];
        } else {
            acc[itemId] = dest[itemId];
        }

        return acc;
    }, source);
};

export const checkIncompatibleComponents = (siteMap, subscriptionType, params, siteSettings: any = null) => {
    let isIncompatibleComponentPresent = false;

    const
        isBasicUser = isBasicSubscription(subscriptionType),
        isNormalUser = isNormalSubscription(subscriptionType),
        isPremiumUser = isPremiumSubscription(subscriptionType),
        isBnEFeatureInSiteSettings = isEcommerceFeatureActiveInSiteSetting(siteSettings);

    if ((isBasicUser || isNormalUser || isPremiumUser) && isBnEFeatureInSiteSettings) {
        isIncompatibleComponentPresent = isBnEFeatureInSiteSettings;
    } else if (isBasicUser) {
        isIncompatibleComponentPresent = checkStandardOrPremiumOrEcommerceComponentUsed(params);
    } else if (isNormalUser) {
        isIncompatibleComponentPresent = isPremiumComponentUsed(params) || isEcommerceComponentUsed(params);
    } else if (isPremiumUser) {
        isIncompatibleComponentPresent = isEcommerceComponentUsed(params);
    }
    return isIncompatibleComponentPresent;
};

type Row = {} | Record<string, Array<string>>;
type RowPT = {
    pages: Row;
    templates: Row;
}

export type PaidFeaturesDoc = {
    standardFeaturesData: RowPT;
    premiumFeaturesData: RowPT;
    ecommerceFeaturesData: RowPT;
}

export const checkIsIncompatibleComponentPresent = (
    siteMap: DataSite,
    subscriptionData: { subscriptionType: SubscriptionTypeT },
    { standardFeaturesData, premiumFeaturesData, ecommerceFeaturesData }: PaidFeaturesDoc,
    publicPages: Array<DataPageRef>,
    siteSettings: SiteSettings,
) => {
    const { subscriptionType } = subscriptionData;

    let pages = {}, templates = {}, isIncompatibleComponentPresent = false;

    // For ECOMMERCE subscription, always returns false;
    if (isEcommerceSubscription(subscriptionType)) {
        return isIncompatibleComponentPresent;
    }

    // Calculate for other subscription levels
    if (isPremiumSubscription(subscriptionType)) {
        if (ecommerceFeaturesData) {
            pages = mergeFeaturesData(pages, ecommerceFeaturesData.pages);
            templates = mergeFeaturesData(templates, ecommerceFeaturesData.templates);
        }
    } else if (isNormalSubscription(subscriptionType)) {
        if (premiumFeaturesData) {
            pages = premiumFeaturesData.pages;
            templates = premiumFeaturesData.templates;
        }

        if (ecommerceFeaturesData) {
            pages = mergeFeaturesData(pages, ecommerceFeaturesData.pages);
            templates = mergeFeaturesData(templates, ecommerceFeaturesData.templates);
        }
    } else if (isBasicSubscription(subscriptionType)) {
        if (standardFeaturesData) {
            pages = standardFeaturesData.pages;
            templates = standardFeaturesData.templates;
        }

        if (premiumFeaturesData) {
            pages = mergeFeaturesData(pages, premiumFeaturesData.pages);
            templates = mergeFeaturesData(templates, premiumFeaturesData.templates);
        }

        if (ecommerceFeaturesData) {
            pages = mergeFeaturesData(pages, ecommerceFeaturesData.pages);
            templates = mergeFeaturesData(templates, ecommerceFeaturesData.templates);
        }
    }

    return checkIncompatibleComponents(
        siteMap,
        subscriptionType,
        { pages, templates, publicPages },
        siteSettings
    );
};
