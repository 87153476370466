/* @flow */

const Lambda = require('aws-sdk/clients/lambda');

/*::
    import type { AwsServiceOptions } from './types'
 */

/**
 * AWS.Lambda API: https://docs.aws.amazon.com/AWSJavaScriptSDK/latest/AWS/Lambda.html
 */
function AwsLambda(options/*: AwsServiceOptions */) {
    /*=== Constructor ===*/

    // Need this nasty inheritance since AWS.S3 is not a constructor function
    function AwsLambdaClass() {
        // This is intentional
    }
    AwsLambdaClass.prototype = new Lambda(options);

    const self = new AwsLambdaClass();

    // For flow to be happy
    return self;
}

module.exports = {
    AwsLambda,
};
