import React from 'react';
import cx from 'classnames';
import type { ReactElementRef } from "../../../../globalTypes";
import styles from './SectionLinkForm.css';
import type { SectionLinkFormProps } from "../flowTypes";
import { InputLabel } from '../../../inputControls/common/view';
import Combobox from '../../../../view/common/Combobox';
import { Intl, injectIntl } from '../../../../view/intl';
import InputField from "../../../../view/common/Input/InputField";
import CheckBox from '../../../../view/common/CheckBox/CheckBoxWithLabel';
import { loadSectionComponentsForPageId } from '../../Tree/actionCreators';
import { showOutDatedLinkToolTipAC, showPageDeletedToolTipAC } from "../actions";
import { CLOSE_STICKY_TOOLTIP } from "../../../Tooltip/stickyTooltip/actionTypes";
import { isSectionKind, isStripKind } from "../../../oneweb/componentKinds";
import { BrokenLink } from "./BrokenLink";

type Props = SectionLinkFormProps & {
    dispatch: Dispatch;
    className?: string;
    intl: Intl;
    isSettings?: boolean;
};

class SectionLinkForm extends React.Component<Props, any> {
    sectionComboBoxRef: ReactElementRef<HTMLDivElement> = React.createRef();
    tooltipUpdatedAtPage?: string;
    oldSelectedSectionId?: string;
    stripWithLinks: Array<Record<string, any>> = [];

    componentDidUpdate() {
        const { sectionOptions = [], isLoading, dispatch, sectionId, pageId, pageOptions } = this.props,
            sectionEle: HTMLDivElement | null = this.sectionComboBoxRef.current,
            showOutdatedToolTip = !isLoading && !!sectionOptions.find(({ value, kind }) => sectionId === value && isStripKind(kind)),
            isInValidPageId = !pageOptions.find(({ value }) => value === pageId);

        if (!sectionEle || !pageId) {
            return;
        }

        const formEle = sectionEle.closest('.sectionLinkForm'),
            pageComboBox = formEle && formEle.querySelector('.pageComboBox');

        const focus = () => {
            const focusEle: HTMLElement | null = sectionEle.querySelector(`.${styles.focusEle}`);
            if (focusEle) {
                focusEle.focus();
                // $FlowFixMe
                focusEle.addEventListener('blur', () => dispatch({ type: CLOSE_STICKY_TOOLTIP }));
            }
        };

        if (pageComboBox && isInValidPageId && this.tooltipUpdatedAtPage !== pageId) {
            const { top, right, height } = pageComboBox.getBoundingClientRect();
            this.tooltipUpdatedAtPage = pageId;
            dispatch(showPageDeletedToolTipAC({ top: (top + (height / 2)), left: right, customClass: styles.toolTipCustomCls }));
            focus();
            return;
        }

        if (sectionId && showOutdatedToolTip && this.oldSelectedSectionId !== sectionId) {
            this.oldSelectedSectionId = sectionId;
            const { top, right, height } = sectionEle.getBoundingClientRect();
            const action = showOutDatedLinkToolTipAC({ top: (top + (height / 2)), left: right, customClass: styles.toolTipCustomCls });
            dispatch(action);
            focus();
        }
    }

    componentWillUnmount() {
        this.props.dispatch({ type: CLOSE_STICKY_TOOLTIP });
    }

    render() {
        const {
                pageId,
                isValidPageId,
                sectionId,
                name,
                hidden,
                nameOnChangeAction,
                pageOnChangeAction,
                sectionOnChangeAction,
                hiddenOnChangeAction,
                dispatch,
                className = '',
                pageOptions,
                sectionOptions = [],
                intl,
                isInvalidName,
                resetAction,
                isLoading,
                isSettings = false,
            } = this.props,
            stripSelectionDisabled = isValidPageId && !sectionOptions.length,
            isInValidPageId = pageId && !pageOptions.find(({ value }) => value === pageId),
            isInvalidSectionId = !isLoading && sectionId && !sectionOptions.find(({ value }) => sectionId === value),
            stripSelectionPlaceholder = intl.msgJoint('msg: newPage.sectionLink.sectionplaceholder {Choose from your sections}'),
            noStripsOnPage = intl.msgJoint('msg: newPage.sectionLink.noSectionsOnPage {No sections on the page selected}');

        let finalSectionOptions = sectionOptions.filter(({ kind }) => isSectionKind(kind)),
            linkedStrip = sectionOptions.find(({ value, kind }) => value === sectionId && isStripKind(kind));
        if (!this.stripWithLinks.length && linkedStrip) {
            this.stripWithLinks = [linkedStrip];
        }
        finalSectionOptions = [...finalSectionOptions, ...this.stripWithLinks];

        return (
            <div className={`${className} sectionLinkForm`}>
                <div
                    className={cx(styles.formLinkRow, styles.nameRow, { [styles.settingsNameInput]: isSettings })}
                >
                    <InputLabel>{'msg: newPage.sectionLink.name {Title in menu}'}</InputLabel>
                    <InputField
                        placeholder={intl.msgJoint('msg: newPage.sectionLink.namePlaceholder {e.g. “Contact”}')}
                        value={name}
                        onChange={text => dispatch({
                            type: nameOnChangeAction,
                            payload: text,
                            amendToSelf: true
                        })}
                        className={styles.nameInput}
                        isInvalid={isInvalidName}
                    />
                    {
                        isInvalidName &&
                        <div className={styles.validationLabel}>
                            {intl.msgJoint('msg: newPage.sectionLink.name.error {Menu title is required}')}
                        </div>
                    }
                </div>
                <div className={styles.formLinkRow}>
                    <InputLabel className={styles.label}>
                        {'msg: newPage.sectionLink.page {On what page is the section located?}'}
                    </InputLabel>
                    <div className={`${styles.sectionComboBoxWrapper} pageComboBox`}>
                        <Combobox
                            options={pageOptions}
                            searchable
                            placeholder={intl.msgJoint('msg: newPage.sectionLink.pageplaceholder {Choose from your pages}')}
                            value={pageId}
                            onChange={({ value }) => {
                                dispatch({ type: pageOnChangeAction, payload: value });
                                dispatch(loadSectionComponentsForPageId(value));
                            }}
                            className={cx(styles.pageComboBox, { [styles.invalidComboBox]: isInValidPageId })}
                        />
                    </div>
                </div>
                <div className={styles.formLinkRow}>
                    <InputLabel className={styles.label}>
                        {'msg: newPage.sectionLink.section {Which section should the link lead to?}'}
                    </InputLabel>
                    <div ref={this.sectionComboBoxRef} className={styles.sectionComboBoxWrapper}>
                        <Combobox
                            options={finalSectionOptions}
                            searchable
                            disabled={stripSelectionDisabled}
                            placeholder={stripSelectionDisabled ? noStripsOnPage : stripSelectionPlaceholder}
                            value={sectionId}
                            onChange={({ value }) => dispatch({ type: sectionOnChangeAction, payload: value })}
                            className={styles.sectionComboBox}
                        />
                        <input className={styles.focusEle} />
                    </div>
                    {
                        !isInValidPageId && isInvalidSectionId && <BrokenLink onClose={() => dispatch({ type: resetAction })} />
                    }
                </div>
                {isSettings && <div className={styles.formLinkRow}>
                    <CheckBox
                        label="msg: newPage.sectionLink.show {Show section link in menu}"
                        isChecked={!hidden}
                        onClick={() => dispatch({ type: hiddenOnChangeAction })}
                        isSliderStyle
                        className={styles.checkboxLabel}
                    />
                </div>}
            </div>
        );
    }
}

export default injectIntl(SectionLinkForm);

