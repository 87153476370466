import React from 'react';
import * as R from 'ramda';
import cx from 'classnames';
import { Flex } from "../../../../../view/reflexbox/index";
import Page from '../../../../PropertiesPanel/view/PropertiesPage';
import pagesTitles from "./titles";
import * as pagesIds from "./ids";
import VerticalSpacer from '../../../../../view/common/VerticalSpacer';
import PropertyContainer from '../../../../../view/common/PropertyContainer/index';
import * as ActionTypes from "../../actionTypes";
import * as selectors from '../../../../Workspace/epics/stylesheets/selectors';
import LinkButton from '../../../../../view/common/Button/LinkButton';
import { linkOptionMapper, createThemeLinkOptions } from '../../../Link/globalStyle/options';
import GlobalstyleSelector from '../../../../presentational/GlobalstyleSelector/index';
import { GlobalstyleDialogInvoker } from '../../../../presentational/GlobalstyleDialogInvoker/index';
import injectIntl from "../../../../../view/intl/injectIntl";
import LinkGlobalstyleKind from "../../../Link/globalStyle/kind";
import { optionRenderer as linkOptionRenderer } from "../../../Link/globalStyle/optionRenderer";
import { themeColorOptionRenderer } from "../../../Link/globalStyle/themeColorOptionRenderer";
import type { Props } from '../flowTypes';
import { THEME_LINK_CLASS_LIST, themeunderlined } from '../../../../App/epics/tinyMceEpic/editorUtils/constants';
import * as colorMapper from "../../../../../mappers/color";
import { themeRulesBackgroundForLinkColor } from "../../../../ThemeGlobalData/themeRules";
import { getThemeColorsFromStylesheet } from "../../../../Workspace/epics/stylesheets/selectors";
import { GLOBAL_STYLES_DIALOG_OPENED } from '../../../../presentational/GlobalstyleDialogInvoker/actionTypes';
import linkStyles from '../styles.css';

// TODO WBTGEN-5341, replace after using iframe
// const isEmptyString = R.always(false); // e R.pipe(R.trim, R.isEmpty);

const
    id: any = pagesIds.Link,
    title = pagesTitles[id],
    view = injectIntl(({
        stylesheets,
        stylesheetsIdToNameMap,
        tinyMceState,
        site,
        sectionComponents,
        intl,
        dispatch,
        autoColor,
        globalVariables,
    }: Props) => {
        const
            { linkAction, selectedLinkGlobalStyle, canSetLink, selectedText } = tinyMceState,
            styles = selectors.getAllStylesheets(stylesheets),
            linkButtonTitle = canSetLink || selectedText === '' ? '' : intl.msg('mcta.text.selectLessText', 'Try selecting less text');  // eslint-disable-line max-len

        let
            optRenderer,
            linkOptions;

        if (autoColor) {
            const linkThemeColors = {};
            const themeColorsData = getThemeColorsFromStylesheet(stylesheets);
            const themeRulesForLink = themeRulesBackgroundForLinkColor(themeColorsData);

            THEME_LINK_CLASS_LIST.forEach((linkClass) => {
                linkThemeColors[linkClass] = colorMapper.toCss(
                    themeColorsData[themeRulesForLink[linkClass][autoColor.selectedParentTheme]]
                );
            });

            let classname = '';

            optRenderer = (value, label) => {
                classname = '';

                if (value === themeunderlined) {
                    classname = linkStyles.linkPPunderline;
                }

                return themeColorOptionRenderer(value, label, linkThemeColors[value], classname);
            };

            linkOptions = createThemeLinkOptions(intl);
        } else {
            optRenderer = (value, label) => linkOptionRenderer(value, label, stylesheetsIdToNameMap);
            linkOptions = linkOptionMapper(styles, intl);
        }

        return (
            <Page>
                <VerticalSpacer />
                <PropertyContainer label="msg: common.link {Link}">
                    <LinkButton
                        linkAction={linkAction}
                        dispatch={dispatch}
                        setLinkAction={ActionTypes.TEXT_SET_LINK}
                        clearLinkAction={ActionTypes.TEXT_REMOVE_LINK}
                        site={site}
                        sectionComponents={sectionComponents}
                        enabled={canSetLink}
                        title={linkButtonTitle}
                        globalVariables={globalVariables}
                    />
                    <VerticalSpacer y={10} />
                    <Flex align="center" justify="space-between">
                        <GlobalstyleSelector
                            disabled={R.isNil(selectedLinkGlobalStyle)}
                            //  @ts-ignore
                            selected={selectedLinkGlobalStyle}
                            selectedKind={LinkGlobalstyleKind}
                            options={linkOptions}
                            optionRenderer={optRenderer}
                            onChangeAction={ActionTypes.TEXT_COMPONENT_LINK_GLOBAL_STYLE_SELECTED}
                            dispatch={dispatch}
                            className={(autoColor && cx("autoColorLinkPPDropdown", autoColor.selectedParentTheme)) || ''}
                            showEditLink={!autoColor}
                        />
                        <GlobalstyleDialogInvoker
                            onClick={() => {
                                dispatch({
                                    type: GLOBAL_STYLES_DIALOG_OPENED,
                                    payload: { stylesheetId: selectedLinkGlobalStyle, kind: LinkGlobalstyleKind }
                                });
                            }}
                            disabled={R.isNil(selectedLinkGlobalStyle)}
                        />
                    </Flex>
                </PropertyContainer>
            </Page>
        );
    });

export { id, title, view };
