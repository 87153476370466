import * as R from "ramda";
import type { Attachments } from "../../Workspace/epics/componentAttachements/flowTypes";
import { getAllAttachmentsForCmpIds, getChildToParentMap } from "../../Workspace/epics/componentAttachements/util";
import type {
    ComponentsCollection,
    ComponentsIds,
    ComponentsMap
} from "../../../redux/modules/children/workspace/flowTypes";
import { isHoverBoxKind } from "../componentKinds";
import { ComponentsNotAllowedInHoverBox } from "./constants";
import type { AnyComponent } from "../flowTypes";
import getComponentBBox from "../../../utils/componentsMap/getComponentBBox";
import type { BBox } from "../../App/flowTypes";
import type { HoverBoxComponent } from "./flowTypes";

export const
    getHoverBoxAttachments =
        (cmpId: string, attachments: Attachments) => getAllAttachmentsForCmpIds(attachments, [cmpId]),
    getHoverComponentIdsOfHoverBox =
        (cmpId: string, attachments: Attachments, componentsMap: ComponentsMap): Array<string> => {
            const allAttachments = getAllAttachmentsForCmpIds(attachments, [cmpId]);
            return allAttachments.filter(id => componentsMap[id] && componentsMap[id].onHover && componentsMap[id].onHover.show);
        },
    getDefaultComponentIdsOfHoverBox =
        (cmpId: string, attachments: Attachments, componentsMap: ComponentsMap): Array<string> => {
            const allAttachments = getAllAttachmentsForCmpIds(attachments, [cmpId]);
            return allAttachments.filter(id => !componentsMap[id] || !componentsMap[id].onHover || !componentsMap[id].onHover.show);
        },
    getAllHoverBoxComponentIds = (componentMap: ComponentsMap): ComponentsIds =>
        Object.keys(componentMap).filter((id) => isHoverBoxKind(componentMap[id].kind)),
    getAllCmpIdsInDefaultMode = (componentMap: ComponentsMap, attachments: Attachments) => {
        const hoverBoxIds = getAllHoverBoxComponentIds(componentMap);
        return hoverBoxIds.reduce((acc, id) => {
            return [...acc, ...getDefaultComponentIdsOfHoverBox(id, attachments, componentMap)];
        }, [] as ComponentsIds);
    },
    getHiddenComponentsForHoverBox = (cmpId: string, attachments: Attachments, componentsMap: ComponentsMap): Array<string> => {
        const cmp = componentsMap[cmpId];
        return cmp && cmp.hoverMode ?
            getDefaultComponentIdsOfHoverBox(cmpId, attachments, componentsMap) :
            getHoverComponentIdsOfHoverBox(cmpId, attachments, componentsMap);
    },
    getAllHiddenComponentsInHoverBox = (componentsMap: ComponentsMap, attachments: Attachments): Array<string> => {
        let strArr: string[] = [];
        const hoverBoxIds = getAllHoverBoxComponentIds(componentsMap);
        return hoverBoxIds.reduce((acc, id) => [...acc, ...getHiddenComponentsForHoverBox(id, attachments, componentsMap)], strArr);
    },
    getComponentsMapForHoverBox =
        (componentMap: ComponentsMap, attachments: Attachments): ComponentsMap => {
            const ignoreCmps = getAllHiddenComponentsInHoverBox(componentMap, attachments);
            return R.omit(ignoreCmps, componentMap);
        },
    getNearestParentHoverBoxCmp = (cmpId: string, attachments: Attachments, componentsMap: ComponentsMap): string | null => {
        const childToParentMap = getChildToParentMap(attachments),
            getParentHoverBox = (childId: string): string | null => {
                const parentId = childToParentMap[childId];
                if (!parentId) {
                    return null;
                }
                if (isHoverBoxKind(componentsMap[parentId] && componentsMap[parentId].kind)) {
                    return parentId;
                }
                return getParentHoverBox(parentId);
            };
        return getParentHoverBox(cmpId);
    },
    getOnHoverShow = (cmpId: string, attachments: Attachments, componentsMap: ComponentsMap) => {
        const hoverBoxId = getNearestParentHoverBoxCmp(cmpId, attachments, componentsMap),
            hoverBoxCmp = hoverBoxId ? componentsMap[hoverBoxId] : null;
        if (!hoverBoxCmp) {
            return null;
        }
        return hoverBoxCmp.hoverMode || false;
    },
    isComponentKindAllowedInHoverBox = (kind: string): boolean => !ComponentsNotAllowedInHoverBox.includes(kind),
    getComponentsCommonHoverBox =
        (componentIds: ComponentsIds, attachments: Attachments, componentsMap: ComponentsMap): string | null => {
            return componentIds.reduce((commonHoverBoxId: string | null, id: string) => {
                if (commonHoverBoxId === null) { return null; }
                const hoverBoxId = getNearestParentHoverBoxCmp(id, attachments, componentsMap);
                if ((!commonHoverBoxId && hoverBoxId) || hoverBoxId === commonHoverBoxId) {
                    return hoverBoxId;
                }
                return null;
            }, '');
        },
    sanitizeSelectedComponentsForHoverBox =
        (ids: ComponentsIds, attachments: Attachments, componentsMap: ComponentsMap): ComponentsIds => {
            return ids.reduce((acc: ComponentsIds, id: string) => {
                const cmp = componentsMap[id],
                    isHoverMode = !!(cmp.onHover && cmp.onHover.show);
                const hoverBoxId = getNearestParentHoverBoxCmp(id, attachments, componentsMap),
                    hoverBoxCmp = (hoverBoxId && componentsMap[hoverBoxId]) || null;
                if (hoverBoxCmp && hoverBoxCmp.hoverMode !== isHoverMode) {
                    return acc;
                }
                return [...acc, id];
            }, []);
        },
    allComponentsInHoverMode = (components: ComponentsCollection): boolean =>
        components.every((cmp: AnyComponent) => cmp.onHover && cmp.onHover.show),
    allComponentsInDefaultMode = (components: ComponentsCollection): boolean =>
        components.every((cmp: AnyComponent) => !cmp.onHover || !cmp.onHover.show),
    someComponentsAreInHoverAndDefaultModes = (components: ComponentsCollection): boolean =>
        !allComponentsInHoverMode(components) && !allComponentsInDefaultMode(components),
    getHoverBoxDecorations = (
        cmpIds: ComponentsIds,
        workspaceBBox: BBox,
        attachments: Attachments,
        componentsMap: ComponentsMap
    ) => {
        const isHoverBoxSelected = cmpIds.length === 1 && isHoverBoxKind(componentsMap[cmpIds[0]].kind),
            hoverBoxId = isHoverBoxSelected ? cmpIds[0] : getComponentsCommonHoverBox(cmpIds, attachments, componentsMap),
            hoverBox = hoverBoxId && ((componentsMap[hoverBoxId] as Record<string, any>) as HoverBoxComponent);

        if (!hoverBox) { return null; }
        return {
            component: hoverBox,
            bBox: getComponentBBox(hoverBox, workspaceBBox),
            isHoverBox: isHoverBoxSelected
        };
    };

