import { getDAL } from "../../../dal";
import { isPartnerOneCom } from "./utils";
import { getAppConfig } from '../App/epics/appConfig/appConfig.js';
import AppConfig from '../../utils/AppConfig';

const isIgnoredDomain = () => {
    const appConfig = AppConfig(getAppConfig());
    const domainName = getDAL().getDomain();
    return appConfig.getOptional('oneWeb.partnerWhitelistedSubDomains', []).includes(domainName);
};

// Eg: "wsb-blabla.plesk-hosting-testen21.nl"
const isHostnetSubdomain = () => {
    const domainName = getDAL().getDomain();
    const parts = domainName.split(".");
    return parts.length === 3 && /^wsb-/.test(parts[0]);
};

// Eg: "w1082734.checkdomainwsb.one"
const isCheckDomainSubdomain = () => {
    const domainName = getDAL().getDomain();
    const parts = domainName.split(".");
    return parts.length === 3 && parts[0] && /checkdomainwsb.one$/.test(domainName);
};

// Eg: "{uniqueid}.heroldwebsite.at or {uniqueId}.dev.websitebuilder.herold.at"
const isHeroldSubdomain = () => {
    const domainName = getDAL().getDomain();
    return /.+\.(heroldwebsite\.at|dev\.websitebuilder\.herold\.at)$/.test(domainName);
};

// Eg: "{uniqueid}.zoner.website or {uniqueId}.dev.websitebuilder.zoner.fi"
const isZonerSubdomain = () => {
    const domainName = getDAL().getDomain();
    return /.+\.(zoner\.website|dev\.websitebuilder\.zoner\.fi)$/.test(domainName);
};

const isPartnerSubdomain = () => !isIgnoredDomain() && !isPartnerOneCom() &&
(isHostnetSubdomain() ||
isCheckDomainSubdomain() ||
isHeroldSubdomain() ||
isZonerSubdomain());

export {
    isPartnerSubdomain,
    isHostnetSubdomain,
    isCheckDomainSubdomain,
    isHeroldSubdomain,
    isZonerSubdomain,
    isIgnoredDomain
};
