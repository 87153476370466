export const FETCH_BOOKINGS_STATE = "FETCH_BOOKINGS_STATE";
export const GET_BOOKINGS_STATE_REQUEST = "GET_BOOKINGS_STATE_REQUEST";
export const GET_BOOKINGS_STATE_SUCCESS = "GET_BOOKINGS_STATE_SUCCESS";
export const GET_BOOKINGS_STATE_FAILURE = "GET_BOOKINGS_STATE_FAILURE";
export const RESET_BOOKINGS_INSERTER = "RESET_BOOKINGS_INSERTER";
export const CREATE_BOOKINGS_PAGE = "CREATE_BOOKINGS_PAGE";

export const GET_BOOKINGS_SERVICES_REQUEST = "GET_BOOKINGS_SERVICES_REQUEST";
export const GET_BOOKINGS_SERVICES_SUCCESS = "GET_BOOKINGS_SERVICES_SUCCESS";
export const GET_BOOKINGS_SERVICES_FAILURE = "GET_BOOKINGS_SERVICES_FAILURE";
