export const BOOKINGS_MIN_WIDTH = 680;
export const BOOKINGS_MIN_HEIGHT = 500;

export const BOOKINGS_DEFAULT_WIDTH = 1050;
export const BOOKINGS_DEFAULT_HEIGHT = 600;

// dialog height will be reduced by that amount compared to the screen height
export const BOOKINGS_DIALOG_HEIGHT_OFFSET = 40;
export const BUTTON_TEXT_MAX_LENGTH = 50;

export const DEFAULT_BUTTON_TEXT = "Book";

export const BOOKINGS_SERVICES_PATH = "/services";
export const BOOKINGS_AVAILABILITY_PATH = "/availability";
