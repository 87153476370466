/* @flow */

const { PartnerName } = require("../partners/PartnerName.js");
const SubscriptionType = {
    UNKNOWN: 'UNKNOWN',
    BASIC: 'BASIC',
    NORMAL: 'NORMAL',
    PREMIUM: 'PREMIUM',
    ECOMMERCE: 'ECOMMERCE',
    ECOMMERCE_DIFM: 'ECOMMERCE_DIFM',
    DEMO: 'DEMO',
    TRIAL: 'TRIAL',
    FREE: 'FREE',
    PAID: 'PAID',
    DESIGNER: 'DESIGNER',
};
const SUBSCRIPTIONERR = 'subscriptionErr';

const WSBSubToPartnerConfigType = {
    [PartnerName.ONECOM]: {
        [SubscriptionType.UNKNOWN]: '$unknown',
        [SubscriptionType.TRIAL]: '$trial',
        [SubscriptionType.BASIC]: '$basic',
        [SubscriptionType.NORMAL]: '$starter',
        [SubscriptionType.PREMIUM]: '$premium',
        [SubscriptionType.ECOMMERCE]: '$ecommerce',
        [SubscriptionType.ECOMMERCE_DIFM]: '$ecommerce_difm'
    },
    [PartnerName.HOSTNET]: {
        [SubscriptionType.UNKNOWN]: '$hostnet_unknown',
        [SubscriptionType.TRIAL]: '$hostnet_trial',
        [SubscriptionType.NORMAL]: '$hostnet_start',
        [SubscriptionType.PREMIUM]: '$hostnet_pro',
        [SubscriptionType.ECOMMERCE]: '$hostnet_plus',
        [SubscriptionType.ECOMMERCE_DIFM]: '$hostnet_plus_difm'
    },
    [PartnerName.CHECKDOMAIN]: {
        [SubscriptionType.UNKNOWN]: '$checkdomain_unknown',
        [SubscriptionType.TRIAL]: '$checkdomain_trial',
        [SubscriptionType.NORMAL]: '$checkdomain_starter',
        [SubscriptionType.PREMIUM]: '$checkdomain_premium',
        [SubscriptionType.ECOMMERCE]: '$checkdomain_ecommerce'
    },
    [PartnerName.HEROLD]: {
        [SubscriptionType.UNKNOWN]: '$herold_unknown',
        [SubscriptionType.NORMAL]: '$herold_starter',
        [SubscriptionType.PREMIUM]: '$herold_premium',
        [SubscriptionType.ECOMMERCE]: '$herold_business+webshop',
        [SubscriptionType.ECOMMERCE_DIFM]: '$herold_ecommerce_difm'
    },
    [PartnerName.SITETEMPLATES]: {
        [SubscriptionType.UNKNOWN]: '$sitetemplates_unknown',
        [SubscriptionType.ECOMMERCE]: '$sitetemplates_designer'
    },
    [PartnerName.ZONER]: {
        [SubscriptionType.UNKNOWN]: '$zoner_unknown',
        [SubscriptionType.NORMAL]: '$zoner_starter',
        [SubscriptionType.PREMIUM]: '$zoner_pro',
        [SubscriptionType.ECOMMERCE]: '$zoner_ecommerce'
    }
}

// Below list of components should match the list of components in the subscriptions inside
// the common.cjson
const ConfigComponentList = {
    VIDEO_FILE: "VIDEO_FILE",
    HD_IMAGES: "HD_IMAGES",
    ONLINE_SHOP: "ONLINE_SHOP",
    GOOGLE_REVIEWS: "GOOGLE_REVIEWS",
    WIDGETS_BOOKINGS: "WIDGETS_BOOKINGS",
    GOOGLE_MY_BUSINESS: "GOOGLE_MY_BUSINESS",
    GENERIC_WIDGETS: "GENERIC_WIDGETS",
    MULTIPAGE_ONBOARDING: "MULTIPAGE_ONBOARDING",
    GENERATE_SCREENSHOT: "GENERATE_SCREENSHOT",
    CONTACTS: "CONTACTS",
    BOOKINGS: "BOOKINGS",
    BACKUP_RESTORE: "BACKUP_RESTORE",
    DASHBOARD: "DASHBOARD",
    SOCIAL_WIDGETS: "SOCIAL_WIDGETS",
    SITE_SETTINGS: "SITE_SETTINGS",
    FILE_CHOOSER: "FILE_CHOOSER",
    TOP_BAR: "TOP_BAR",
    CONTACT_FORM: "CONTACT_FORM",
    BLOG: "BLOG"
};

module.exports = {
    SubscriptionType,
    SUBSCRIPTIONERR,
    WSBSubToPartnerConfigType,
    ConfigComponentList
};
