/* eslint-disable max-len */
import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import cx from "classnames";
import Lottie from "react-web-lottie";
import animationData from "./animations.json";

import { ResultCard } from "./ResultCard";
import { ReturnUserView } from "./ReturnUserView";
import { AdjustSettingsBanner } from "./AdjustSettingsBanner";

import { useIntl } from '../../../../../../../view/intl/index';

import { ERROR_STATES, ERROR_STATE_SCREENS } from "../constants";

import styles from '../styles.css';
import errorStyles from '../ErrorMessages/styles.css';
import { aiTextSelector } from '../../../../epics/AITextEpic/selectors';

type QueryResultsProps = {
    queryResults: any[],
    queryResultStartIndex: number,
    isQueryInProgress: boolean,
    errorState: string,
    dispatch: Dispatch,
    insertedText: string,
    copiedText: string,
    hasWrappedElements: boolean
}

const QueryResults = ({ queryResults, queryResultStartIndex, isQueryInProgress, errorState, dispatch, insertedText, copiedText, hasWrappedElements }: QueryResultsProps) => {
    const queryResultsToShow = (queryResults || []).slice(queryResultStartIndex);
    const isConnectionError = errorState === ERROR_STATES.CONNECTION_ERROR;
    let ErrorScreen;
    if (errorState) {
        ErrorScreen = ERROR_STATE_SCREENS[errorState];
    }
    return (
        <React.Fragment>
            {
                !!queryResultsToShow.length && queryResultsToShow.map((queryResultArray, arrayIndex) => {
                    const isLatestQuerySet = arrayIndex === queryResultsToShow.length - 1;
                    return (
                        <React.Fragment key={arrayIndex}>
                            {
                                queryResultArray && queryResultArray.map((queryResult, index) => {
                                    const isLatestQuery = isLatestQuerySet && index === queryResultArray.length - 1;
                                    return (
                                        <React.Fragment key={index}>
                                            <ResultCard
                                                queryResult={queryResult}
                                                index={index}
                                                isLatestQuery={isLatestQuery}
                                                isQueryInProgress={isQueryInProgress}
                                                dispatch={dispatch}
                                                insertedText={insertedText}
                                                copiedText={copiedText}
                                                hasWrappedElements={hasWrappedElements}
                                            />
                                        </React.Fragment>
                                    );
                                })
                            }
                        </React.Fragment>
                    );
                })
            }
            {errorState &&
                <div className={cx(styles.queryErrorContainer,
                    { [errorStyles.connectionErrorContainer]: isConnectionError,
                        [errorStyles.otherErrorContainer]: !isConnectionError })}
                >
                    <ErrorScreen />
                </div>}
        </React.Fragment>
    );
};

export const ResultsPanelContent = () => {
    const intl = useIntl();
    const dispatch = useDispatch();

    const aiTextState = useSelector(aiTextSelector);

    const {
        queryResults,
        isFirstTimeUser,
        isTextGenerationInProgress,
        isQueryInProgress,
        errorState,
        resultStartIndex,
        insertedText,
        copiedText,
        hasWrappedElements
    } = aiTextState;

    const [showPrevious, setShowPrevious] = useState(false);
    const [queryResultStartIndex, setQueryResultStartIndex] = useState(resultStartIndex);
    const animationRef = useRef(null);

    useEffect(() => {
        if (showPrevious && queryResults) {
            setQueryResultStartIndex(0);
        } else {
            setQueryResultStartIndex(resultStartIndex);
        }
    }, [showPrevious, resultStartIndex, queryResults]);

    const onClickHandler = () => {
        setShowPrevious(true);
    };

    let ErrorScreen;
    if (errorState) {
        ErrorScreen = ERROR_STATE_SCREENS[errorState];
    }

    // Show complete error on right panel when there are no previous query results available
    if (errorState && queryResults && queryResults.length === 0) {
        return <ErrorScreen />;
    } else if (!isFirstTimeUser && isTextGenerationInProgress && !showPrevious) {
        return (
            <React.Fragment>
                {resultStartIndex !== 0 && <div
                    className={styles.seeMoreTextContainer}
                    onClick={onClickHandler}
                >
                    {intl.msgJoint("msg: component.text.createAIText.dialog.seeTexts {See previously created texts}")}
                </div>}
                <QueryResults
                    queryResults={queryResults}
                    queryResultStartIndex={queryResultStartIndex}
                    isQueryInProgress={isQueryInProgress}
                    dispatch={dispatch}
                    errorState={errorState}
                    insertedText={insertedText}
                    copiedText={copiedText}
                    hasWrappedElements={hasWrappedElements}
                />
            </React.Fragment>
        );
    } else if (!isFirstTimeUser && !showPrevious) {
        return <ReturnUserView onClickHandler={onClickHandler} />;
    } else if (queryResults && queryResults.length) {
        return (
            <React.Fragment>
                <QueryResults
                    queryResults={queryResults}
                    queryResultStartIndex={queryResultStartIndex}
                    isQueryInProgress={isQueryInProgress}
                    dispatch={dispatch}
                    errorState={errorState}
                    insertedText={insertedText}
                    copiedText={copiedText}
                    hasWrappedElements={hasWrappedElements}
                />
                {
                    isFirstTimeUser && queryResults.length === 1 && queryResults[0].length === 3 ?
                        <AdjustSettingsBanner /> :
                        null
                }

            </React.Fragment>
        );
    } else {
        const defaultOptions = {
            animationData,
            autoplay: true,
            loop: true,
            rendererSettings: {
                preserveAspectRatio: "xMidYMid meet"
            }
        };
        return (
            <div className={styles.animationContainer}>
                <Lottie
                    lottiRef={animationRef}
                    options={defaultOptions}
                    isClickToPauseDisabled
                    width="100%"
                    height="auto"
                />
                <div className={styles.animationText}>
                    {intl.msgJoint("msg: component.text.createAIText.dialog.animationText {Tell us what you want to write about and get a customised text.}")}
                </div>
            </div>
        );
    }
};
