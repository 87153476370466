import {
    makeCombineReducer,
    makeDefaultStateReducers,
    makeComponentBaseReducers
} from "../../../../redux/makeReducer/index";
import * as actionTypes from "../actionTypes";
import kind from '../kind';
import {
    TOGGLE_MOBILE_DOWN_ACTION
} from "../../../PropertiesPanel/view/MobileView/actions";
import mobileDownReducer from '../../../PropertiesPanel/view/MobileView/mobileDownReducer';
import { mobileSettingsReducer } from '../../../PropertiesPanel/view/MobileView/mobileSettingsReducer';
import { UPDATE_WRAP_REQUEST } from '../../../Workspace/epics/wrap/actionTypes';
// $FlowFixMe: due to an export statement that is compatible with server side code as well (module.exports)
import { contentKey } from '../../../../../../server/shared/textContentHasLinkGlobalstyle.js';
import { MOBILE_EDITOR_ALIGNMENT_CHANGE } from "../../../MobileViewEditor/actionTypes";
import { types, DefaultComponentDimensions } from "../../../Globalstyles/Text/constants";
import { DEFAULT_BLUR_RADIUS, DEFAULT_SHADOW_OFFSET_X, DEFAULT_SHADOW_OFFSET_Y } from '../constants';
import { AI_TEXT_REPLACE_TEXT_CONTENT } from "../epics/AITextEpic/actionTypes";

export default makeCombineReducer({
    combineReducers: {
        ...makeComponentBaseReducers(kind),
        ...makeDefaultStateReducers({
            text: '', // TODO WBTGEN-9767 remove once db migration from old to new complete
            styles: [], // TODO WBTGEN-9767 remove once db migration from old to new complete
            paras: [], // TODO WBTGEN-9767 remove once db migration from old to new complete
            links: [], // TODO WBTGEN-9767  remove once db migration from old to new complete
            width: DefaultComponentDimensions.width[types.NORMAL],
            height: DefaultComponentDimensions.height[types.NORMAL],
            mobileSettings: {
                font: 0,
                align: null
            },
            [contentKey]: null,
            globalStyleId: null,
            mobileDown: false, // TODO move mobileDown into makeComponentBaseReducers
            mobileHide: false, // TODO move mobileHide into makeComponentBaseReducers,
            themeOverrideColor: null,
            themeHighlightColor: null,
            themeShadowBlurRadius: DEFAULT_BLUR_RADIUS,
            themeShadowColor: null,
            themeShadowOffsetX: DEFAULT_SHADOW_OFFSET_X,
            themeShadowOffsetY: DEFAULT_SHADOW_OFFSET_Y,
            verticalAlignment: 'top'
        })
    },
    handleActions: {
        [actionTypes.TEXT_CHANGED]: (state, { payload }) => {
            if (state[contentKey] === payload.content) {
                return state;
            }

            return { ...state, [contentKey]: payload.content };
        },
        [AI_TEXT_REPLACE_TEXT_CONTENT]: (state, { payload }) => {
            window.tinyMCE.activeEditor.setContent(payload.content);
            return { ...state, [contentKey]: payload.content };
        },
        [actionTypes.TEXT_MOBILE_FONT_CHANGE]: mobileSettingsReducer('font'),
        [MOBILE_EDITOR_ALIGNMENT_CHANGE]: mobileSettingsReducer('align'),
        [TOGGLE_MOBILE_DOWN_ACTION]: mobileDownReducer,
        [actionTypes.TEXT_MOBILE_CLEAR_FORMATTING]: (state, { payload }) => {
            if (state.id !== payload.id) {
                return state;
            }
            return { ...state, mobileSettings: { font: 0, align: null } };
        },
        [UPDATE_WRAP_REQUEST]: (state, { payload }) => {
            const update = payload.textComponentUpdateInfo && payload.textComponentUpdateInfo[state.id];

            if (update) {
                return { ...state, ...update };
            }

            return state;
        },
        [actionTypes.TEXT_VERTICAL_ALIGNMENT_CHANGED]: (state, { payload: verticalAlignment }) => {
            return { ...state, verticalAlignment };
        },
        [actionTypes.TEXT_MCTA_COLOR_CHANGED_AUTO_COLOR]: (state, { payload }) => ({ ...state, themeOverrideColor: payload.themeColor }),
        [actionTypes.TEXT_HIGHLIGHT_COLOR_CHANGED_AUTO_COLOR]: (state, { payload }) => ({
            ...state, themeHighlightColor: payload.themeColor,
        }),
        [actionTypes.TEXT_HIGHLIGHT_COLOR_REMOVED_AUTO_COLOR]: (state) => ({ ...state, themeHighlightColor: null }),
        [actionTypes.TEXT_SHADOW_COLOR_CHANGED_AUTO_COLOR]: (state, { payload }) => ({
            ...state, themeShadowColor: payload.themeColor,
        }),
        [actionTypes.TEXT_SHADOW_COLOR_REMOVED_AUTO_COLOR]: (state) => ({ ...state, themeShadowColor: null }),
        [actionTypes.TEXT_COMPONENT_BLUR_RADIUS_CHANGED_AUTO_COLOR]: (state, { payload }) => ({
            ...state, themeShadowBlurRadius: payload,
        }),
        [actionTypes.TEXT_COMPONENT_SHADOWOFFSETX_CHANGED_AUTO_COLOR]: (state, { payload }) => ({
            ...state, themeShadowOffsetX: payload,
        }),
        [actionTypes.TEXT_COMPONENT_SHADOWOFFSETY_CHANGED_AUTO_COLOR]: (state, { payload }) => ({
            ...state, themeShadowOffsetY: payload,
        }),
    }
});
