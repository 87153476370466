import PostKind from "./kind";
import PostPreview from "./view/preview";
import { getPostPreviewReqData, getThemeColorData } from "../utils";
import { getAppConfig } from "../../../App/epics/appConfig/appConfig.js";

// config for how the component should behave in preview
// e.g. what it should render
export default {
    kind: PostKind,
    view: PostPreview,
    calcProps: ({
        isServerPreview,
        isPublicPreview,
        selectedParentTheme,
    }) => {
        return {
            isServerPreview,
            isPublicPreview,
            selectedParentTheme,
        };
    },
    getSpecificScript: ({
        component: { blogId },
        site,
        siteSettings,
        domain,
        flagPublish,
        isServerPreview,
        pageId,
        locale,
        globalStyles,
        selectedParentTheme,
    }) => {
        if (flagPublish) return `
            document.addEventListener('DOMContentLoaded', async function () {
                await blogUtilsInPublish.updatePostLinks();
            });`;
        const data = getPostPreviewReqData({
            appConfig: getAppConfig(),
            siteMap: site,
            postPageId: pageId,
            domain,
            blogId,
            isServerPreview,
            locale,
            websiteCategory: siteSettings?.generalData?.websiteCategories,
            selectedParentTheme,
            themeColors: getThemeColorData(globalStyles),
        });
        return `
        document.addEventListener('DOMContentLoaded', async function () {
            await blogUtilsInPreview.updatePost(${JSON.stringify(data)});
        });`;
    },
    //only if it is not for publish
    //Never add async or defer to these scripts as these are required to be loaded and executed before DOMContentLoaded triggered
    generateScripts: ({ flagPublish }) => {
        return flagPublish ? ['/renderStatic/blog/publishUtils.js'] : ['/renderStatic/blog/previewUtils.js'];
    }
};
