import { uniqBy, uniqWith } from 'ramda';
import { isNotEmpty } from '../../../../../../../utils/ramdaEx';
import { LINK_CLASS_NAME_REGEX, THEME_LINK_CLASS_LIST } from '../../constants';
import { getClosestContentBlock, isValidContentBlock } from '../../utils/nodeUtils/utils';
import { getSelectedNodes } from '../../utils/nodeUtils/getSelectedNodes';
import type { TinyMceEditor } from "../../../flowTypes";

const getClosestAnchorNode = (node: Node | null | undefined): HTMLAnchorElement | null | undefined => {
    if (!node) {
        return null;
    } else if (node.nodeName === 'A') {
        // $FlowFixMe: WBTGEN-9962: flow failes to infer return type from the check above
        return node as HTMLAnchorElement;
    } else if (isValidContentBlock(node)) {
        return null;
    } else {
        return getClosestAnchorNode(node.parentNode);
    }
};

/** returns list of the links related to the selection from the first selected block */
export const getSelectedLinkNodes = (
    editor: TinyMceEditor, limitToOneContentBlock: boolean
): HTMLAnchorElement[] => {
    const selectedNodes: HTMLElement[] = getSelectedNodes(editor);
    const linkNodeList: HTMLElement[] = [];
    if (isNotEmpty(selectedNodes)) {
        const contentBlock = getClosestContentBlock(selectedNodes[0]);

        // eslint-disable-next-line no-restricted-syntax
        for (const node of Array.from(selectedNodes)) {
            // continue if there's just one content block, break otherwise
            if (!limitToOneContentBlock || getClosestContentBlock(node) === contentBlock) {
                const linkNode: HTMLElement | null | undefined = getClosestAnchorNode(node);
                if (linkNode) {
                    linkNodeList.push(linkNode);
                } else if (node.querySelectorAll) { // TEXT nodes don't have querySelectorAll
                    linkNodeList.push(...Array.from(node.querySelectorAll('a')));
                }
            } else {
                linkNodeList.length = 0;
                break;
            }
        }
    }
    // @ts-ignore
    return uniqWith((a: HTMLElement, b: HTMLElement): boolean => a === b, linkNodeList);
};

/** returns THE LINK related to selections in THE BLOCK OR NULL */
export const getOnlySelectedLinkNode = (editor: TinyMceEditor): HTMLAnchorElement | null | undefined => {
    // maxCount is kept 2 to identify if we have multiple links in the selection
    const links: HTMLAnchorElement[] = getSelectedLinkNodes(editor, false);
    return (links.length === 1) ? links[0] : null;
};

export const getOnlyLinkFromDom = (editor: TinyMceEditor) => {
    const linkNode = getOnlySelectedLinkNode(editor);
    if (linkNode) {
        // $FlowFixMe: WBTGEN-9962: getOnlySelectedLinkNode should be updated to return HTMLAnchorElement
        return { href: linkNode.getAttribute('href') || '', openInNewWindow: linkNode.target === '_blank' };
    } else {
        return null;
    }
};

const isLinkClassName = (c: string): boolean => LINK_CLASS_NAME_REGEX.test(c);

/** returns THE LINK STYLE related to the selection in THE BLOCK OR NULL */
export const getOnlyLinkStyleFromSelection = (editor: TinyMceEditor, autoColorMode: boolean): string | null | undefined => {
    const linkNodeList: HTMLElement[] = uniqBy(
        (linkNode: HTMLElement) => Array.from(linkNode.classList).find(isLinkClassName),
        getSelectedLinkNodes(editor, false)
    );
    if (linkNodeList.length === 1) {
        const linkNode = linkNodeList[0];
        let linkClass = Array.from(linkNode.classList).find(isLinkClassName);
        if (autoColorMode) {
            linkClass = THEME_LINK_CLASS_LIST.find(c => linkNode.classList.contains(c)) || linkClass;
        }
        return linkClass;
    } else {
        return null;
    }
};

/** returns THE LINK related to the selection in THE BLOCK OR NULL */
export const getOnlyLinkFromSelection = (editor: TinyMceEditor, limitToOneContentBlock: boolean) => {
    const linkNodeList: HTMLAnchorElement[] = uniqBy(
        (linkNode: HTMLAnchorElement) => [linkNode.getAttribute('href'), linkNode.target],
        getSelectedLinkNodes(editor, limitToOneContentBlock)
    );
    if (linkNodeList.length === 1) {
        const linkNode = linkNodeList[0];
        return { href: linkNode.getAttribute('href') || '', openInNewWindow: linkNode.target === '_blank' };
    } else {
        return null;
    }
};
