import cx from "classnames";
import { getAppConfig } from "../../../App/epics/appConfig/appConfig.js";
import AppConfig from "../../../../utils/AppConfig";
import { getLocaleCookie } from "../../../Locale/cookie";
import getCenteredDialog from "../../../DialogManager/getCenteredDialogConfig";
import { configurationDialogFactory } from '../configurationDialogFactory';
import { BookingsDialogHeight, DialogWidth } from '../constants';
import { WIDGETS_BOOKINGS_SET_LINK } from "./actionTypes";
import { validateLinkOrCode } from '../utils';
import { parseTrafftCode } from './utils/parseTrafftCode';
import Icons from '../view/Icons.css';

const validateBookingsData = validateLinkOrCode([], parseTrafftCode);

const generateTrafftLink = (domain, email) => {
    const appConfig = AppConfig(getAppConfig());
    const locale = getLocaleCookie();
    const bookingsBaseUrl = appConfig.getOptional("oneWeb.bookings.url");
    const urlParams = locale === "en_gb" ? "" : `/${locale}`;
    const urlSearchParams = new URLSearchParams({ domain, email }).toString();
    const bookingsUrl = `${bookingsBaseUrl}${urlParams}?${urlSearchParams}`;
    return bookingsUrl;
};

const
    configurationDialogId = "WIDGETS_BOOKINGS_CONFIG_DIALOG",
    configurationDialogView = configurationDialogFactory({
        saveAction: WIDGETS_BOOKINGS_SET_LINK,
        dialogIconClass: cx(Icons.trafft, Icons.dialog),
        dialogTitle: "msg: widgets.bookings.configDialog.title {Bookings}",
        info: "msg: widgets.bookings.config.info {Add the Trafft code below to offer services on your website or go to Trafft to copy the code. }", // eslint-disable-line max-len
        subInfo: "msg: widgets.bookings.configDialog.subInfo {Once the code is added, any changes you make to your Trafft services will automatically be shown on your site. }", // eslint-disable-line max-len
        inputPlaceHolder: "msg: widgets.bookings.configDialog.placeHolder {Paste Trafft embed code.}",
        isLinkValid: validateBookingsData,
        serviceProviderText: "msg: widgets.bookings.config.serviceProviderText {go to Trafft}",
        serviceProviderLink: generateTrafftLink,
        registerHere: "msg: widgets.bookings.config.registerHere {Don’t have an account yet? {registerHereLink}.}",
        registerHereLink: generateTrafftLink,
    }),
    configurationDialog = getCenteredDialog({
        width: DialogWidth,
        height: BookingsDialogHeight,
        component: configurationDialogView
    });

export { configurationDialogId, configurationDialog };
