import * as R from 'ramda';
import oneColor from "onecolor";
import type { ComponentsMap } from "../../../redux/modules/children/workspace/flowTypes";
import type { SiteSettings } from "../../App/epics/siteSettings/flowTypes";
import FeaturedProductsKind from './kind';
import { reducerDefaultData } from './reducer/reducerDefaultData';
import ProductWidgetKind from '../ProductWidget/kind';
import { WIDTH_ONE_PRODUCT } from './constants';
import {
    getAllStylesheets,
    getFirstButtonStyleId,
    getThemeColorsFromStylesheet,
} from '../../Workspace/epics/stylesheets/selectors';
import {
    FEATURED_PRODUCTS_VIEW_DETAILS_UPDATE_BUTTON_STYLE_THEME,
    FEATURED_PRODUCTS_VIEW_DETAILS_UPDATE_BUTTON_STYLE,
    FEATURED_PRODUCTS_BUY_NOW_UPDATE_BUTTON_STYLE,
    FEATURED_PRODUCTS_BUY_NOW_UPDATE_BUTTON_STYLE_THEME
} from './actionTypes';
import { MCTA_THEME_OPTIONS_BUTTON } from "../../componentMainActions/constants";
import { themeColoroptionRenderer } from "../Button/globalStyle/themeColoroptionRenderer";
import { optionRenderer } from "../Button/globalStyle/optionRenderer";
import buttonOptionsMapper from '../Button/globalStyle/options';
import type { Stylesheets } from '../../Workspace/epics/stylesheets/flowTypes';
import { Intl } from '../../../view/intl/injectIntl';
import type {
    ThemeBackgroundType,
} from "../../ThemeGlobalData/flowTypes";
import type { FeaturedProductsComponent } from './flowTypes';
import { getThemeRulesForBackground } from '../../ThemeGlobalData/themeRules';
import { DataSite } from "../../../../dal/model/index";
import { getValidPageIds } from "../WebShop/utils";

export const getProductsCount = (productsProperty: string) => {
    let result = 0;
    if (productsProperty) {
        result = productsProperty.split(',').length;
    }
    return result;
};

export const getHeight = (productsCount: number, componentProductsPerRow: null | undefined | number): number => {
    let result = 0;
    const defaultHeight = reducerDefaultData.height;
    if (componentProductsPerRow) {
        result = ((productsCount < componentProductsPerRow) ? defaultHeight :
            Math.ceil(productsCount / componentProductsPerRow) * defaultHeight);
    } else {
        result = ((productsCount < 3) ? defaultHeight : Math.ceil(productsCount / 3) * defaultHeight);
    }
    return result;
};

export const getWidth = (productsCount: number, componentWidth?: number): number => {
    let result = 0;
    const defaultWidth = WIDTH_ONE_PRODUCT;
    if (componentWidth) {
        result = componentWidth;
    } else if (!productsCount) {
        result = defaultWidth * 3;
    } else {
        result = ((productsCount < 3) ? defaultWidth * productsCount : defaultWidth * 3);
    }
    return result;
};

export const getProductsPerRow = (productsCount: number, componentProductsPerRow?: number): number => {
    let result = 1;
    if (componentProductsPerRow) {
        result = componentProductsPerRow;
    } else if (!productsCount) {
        result = 4;
    } else {
        result = ((productsCount < 4) ? productsCount : 4);
    }
    return result;
};

export const getComponentHeightInMobileView = (productsCount: number, productsPerRowInMobile: number): number => {
    let mobilePreviewHeight: number = 400 * productsCount;
    if (productsPerRowInMobile > 1) {
        mobilePreviewHeight = (275 * productsCount) / productsPerRowInMobile;
    }
    return mobilePreviewHeight;
};

export const getWidgetId = (componentId: string) => `featured-products-widget-${componentId}`;

export const findFeaturedProductComponent = (componentsMap: ComponentsMap) =>
    R.filter(comp => comp.kind === FeaturedProductsKind || comp.kind === ProductWidgetKind)(componentsMap);

export const isFeaturedProductsComponentFound = (componentsMap: ComponentsMap) => !R.isEmpty(
    findFeaturedProductComponent(componentsMap)
);

export const doesFeaturedProductsExist = (siteSettings: SiteSettings, siteMap: DataSite): boolean => {
    if (siteSettings && Array.isArray(siteSettings.featuredProductsPageIds)) {
        const featuredProductsPageIds = getValidPageIds(
            siteSettings.featuredProductsPageIds || [],
            siteMap.getPageIdsNotMarkedAsDontPublish()
        );
        return featuredProductsPageIds.length > 0 &&
            featuredProductsPageIds.some((pageId) => !!pageId);
    }
    return false;
};

export const getButtonStyleGlobalPropertiesSelector = (
    dispatch: Function,
    intl: Intl,
    component: FeaturedProductsComponent,
    selectedParentTheme: ThemeBackgroundType,
    globalStyles: Stylesheets,
    autoColorMode: boolean,
    stylesheetsIdToNameMap: MapT<string>,
) => {
    const stylesheets = getAllStylesheets(globalStyles);
    let
        buttonStyleOptRenderer,
        buttonStyleOtions,
        buttonStyleColorThemeOnChangeAction,
        buttonStyleOnChangeAction,
        buttonStyleCurrentStylesheetId,
        buyNowButtonStyleColorThemeOnChangeAction,
        buyNowButtonStyleOnChangeAction;

    if (autoColorMode) {
        buttonStyleCurrentStylesheetId = getFirstButtonStyleId(globalStyles);
        buttonStyleOtions = MCTA_THEME_OPTIONS_BUTTON;

        const btnName = stylesheetsIdToNameMap[buttonStyleCurrentStylesheetId];
        buttonStyleOptRenderer = (value: any, label: any) => themeColoroptionRenderer(value, label, selectedParentTheme, btnName);

        buttonStyleOnChangeAction = FEATURED_PRODUCTS_VIEW_DETAILS_UPDATE_BUTTON_STYLE_THEME;
        buyNowButtonStyleOnChangeAction = FEATURED_PRODUCTS_BUY_NOW_UPDATE_BUTTON_STYLE_THEME;
        buttonStyleColorThemeOnChangeAction = (value: any) => {
            dispatch({ type: buttonStyleOnChangeAction, payload: value });
        };
        buyNowButtonStyleColorThemeOnChangeAction = (value: any) => {
            dispatch({ type: buyNowButtonStyleOnChangeAction, payload: value });
        };
    } else {
        buttonStyleOtions = buttonOptionsMapper(stylesheets, intl);
        buttonStyleOnChangeAction = FEATURED_PRODUCTS_VIEW_DETAILS_UPDATE_BUTTON_STYLE;
        buyNowButtonStyleOnChangeAction = FEATURED_PRODUCTS_BUY_NOW_UPDATE_BUTTON_STYLE;
        buttonStyleOptRenderer = (value: any, label: any) => optionRenderer(value, label, stylesheetsIdToNameMap);
    }

    return {
        buttonStyleOptRenderer,
        buttonStyleOtions,
        buttonStyleColorThemeOnChangeAction,
        buttonStyleOnChangeAction,
        buttonStyleCurrentStylesheetId,
        buyNowButtonStyleOnChangeAction,
        buyNowButtonStyleColorThemeOnChangeAction
    };
};

export const getBackgroundColor = (
    selectedParentTheme: ThemeBackgroundType,
    globalStyles: Stylesheets,
) => {
    let backgroundColor = 'rgba(255,255,255,1)';
    if (selectedParentTheme && globalStyles) {
        const themeColorsData = getThemeColorsFromStylesheet(globalStyles);
        const { background } = getThemeRulesForBackground(selectedParentTheme, themeColorsData);
        const color = oneColor(themeColorsData[background]);
        if (color && typeof color.cssa === 'function') {
            backgroundColor = color.cssa();
        }
    }
    return backgroundColor;
};

export const isIconImage = (imageUrl: string = ''): boolean => {
    let result = false;
    if (
        (/audio\.\w+\.svg/.test(imageUrl))
        || (/image\.\w+\.svg/.test(imageUrl))
        || (/document\.\w+\.svg/.test(imageUrl))
        || (/zip\.\w+\.svg/.test(imageUrl))
    ) {
        result = true;
    }
    return result;
};
