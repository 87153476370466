import * as React from 'react';
import cx from 'classnames';
import { composeStylesTheme } from "@sepo27/react-redux-lib";
import Scrollbars from 'react-custom-scrollbars';
import styles from './Scrollbar.css';
import type { ReactElementRef } from "../../../globalTypes";

// TODO: autoHeight

export type ScrollbarTheme = {
    scrollbar?: string,
    trackVertical?: string,
};

type ScrollbarPropTypes = {
    height?: string | number,
    width?: number,
    children?: React.JSX.Element | React.JSX.Element[],
    className?: string,
    onScroll?: (arg: Record<string, any>) => void,
    scrollToVertical?: null | number,
    theme?: ScrollbarTheme,
    scrollRef?: ReactElementRef<any>,
    fitHeight?: boolean,
    autoHeight?: boolean
};

export default class extends React.Component<ScrollbarPropTypes> {
    scrollRef: ReactElementRef<any>;

    theme: ScrollbarTheme;

    constructor({ scrollRef, theme = {} }: ScrollbarPropTypes) {
        // @ts-ignore
        super();
        this.scrollRef = scrollRef || React.createRef();
        this.onScroll = this.onScroll.bind(this);
        this.theme = composeStylesTheme(styles, theme);
    }

    scrollToVertical(y: number) {
        if (this.scrollRef.current) this.scrollRef.current.scrollTop(y);
    }

    _scrollToVertical(props: ScrollbarPropTypes) {
        const { scrollToVertical: scrollTo } = props;
        if (scrollTo === undefined || scrollTo === null) return;

        this.scrollToVertical(scrollTo);
    }

    onScroll() {
        const { onScroll } = this.props;

        if (!onScroll) return;

        if (this.scrollRef.current) {
            onScroll(this.scrollRef.current.getValues());
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps: ScrollbarPropTypes) {
        this._scrollToVertical(nextProps);
    }

    componentDidMount() {
        this._scrollToVertical(this.props);
    }

    render() {
        const
            { children, height, width = '100%', className = '', fitHeight = false } = this.props,
            // onScrollHandler = onScroll ? () => onScroll(this.refs[SCROLL_ID].getValues()) : null,
            scrollbarClasName = cx(this.theme.scrollbar, className),
            style = fitHeight ? { width } : { height, width };

        // WBTGEN-5399 Changing thumbMinSize to 0. This causes IE11 to show horizontal thumbbar.
        // If we don't pass then it uses default value 30 as pointed in link below.
        // (https://github.com/malte-wessel/react-custom-scrollbars/blob/master/docs/API.md).
        // Currently, we don't have horizontal custom scroll anywhere in the app. So setting,
        // it to 0.
        return (
            <Scrollbars
                autoHeight={fitHeight}
                autoHeightMax={height}
                ref={this.scrollRef}
                style={style}
                className={scrollbarClasName}
                thumbMinSize={0}
                renderTrackVertical={props => <div {...props} className={this.theme.trackVertical} />}
                renderThumbVertical={
                    props =>
                        <div {...props} className={styles.thumbVertical}>
                            <div className={styles.thumbVerticalBackground} />
                        </div>
                }
                autoHide
                autoHideTimeout={1000}
                autoHideDuration={200}
                onScroll={this.onScroll}
            >
                {children}
            </Scrollbars>
        );
    }
}
