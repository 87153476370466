import React from "react";
import * as R from "ramda";

import { MctaVerticalBar, MctaPropertyContainer, MctaCheckbox, MctaCombobox, MctaColumnGrid } from "../../../componentMainActions/index";
import { MctaSlider } from "../../../componentMainActions/MctaSlider/MctaSlider";
import mctaStyles from "./componentMainActions.css";
import actionTypes from "../reducer/actionTypes";
import { imageRatioOptions } from "../options";
import type { WebshopComponent } from "../flowTypes";
import HorizontalSpacer from "../../../../view/common/HorizontalSpacer";
import Msg from '../../../../view/intl/Msg';
import { ProductListStyles } from "../constants";
import { SHOW_WEBSHOP_ADMIN_DIALOG } from "../WebshopAdminDialog/actionTypes";
import { SubscriptionTypes } from "../../../../constants/app";
import { ExtendedPanelTooltip } from "../../../Tooltip/ids";
import { CLOSE_TOOLTIP, SHOW_TOOLTIP } from "../../../Tooltip/actionTypes";

type Props = {
    selectedComponent: WebshopComponent,
    dispatch: Function
};

const EditView = ({ selectedComponent, dispatch }: Props) => {
    const productAppearanceChangedPayload =
        selectedComponent.productListStyle === ProductListStyles.Default ? ProductListStyles.Card : ProductListStyles.Default; // eslint-disable-line max-len

    return (
        <div>
            <MctaPropertyContainer label="msg: webshop.ImageRatioMCTA {Image ratio:}">
                <MctaCombobox
                    options={imageRatioOptions}
                    value={selectedComponent.imageRatio}
                    className={mctaStyles.imageStyle}
                    onChange={({ value }) => {
                        dispatch({ type: actionTypes.WEBSHOP_IMAGE_RATIO_CHANGED, payload: value });
                    }}
                    searchable={false}
                />
            </MctaPropertyContainer>
            {selectedComponent.imageRatio !== imageRatioOptions[3].value && (
                <div className={mctaStyles.imageStyleMCTACrop}>
                    <MctaCheckbox
                        label="msg: webshop.Crop {Crop}"
                        onClick={() => dispatch({ type: actionTypes.WEBSHOP_CROP_IMAGES_CHANGED })}
                        isChecked={selectedComponent.cropImages}
                    />
                    <HorizontalSpacer x={12} />
                </div>
            )}
            <MctaVerticalBar />
            <HorizontalSpacer x={12} />
            <div className={mctaStyles.imageStyleMCTACrop}>
                <MctaCheckbox
                    label="msg: component.webshop.ProductListStyles.Card {Card}"
                    onClick={() =>
                        dispatch({
                            type: actionTypes.WEBSHOP_PRODUCT_APPEARANCE_CHANGED,
                            payload: productAppearanceChangedPayload
                        })}
                    isChecked={selectedComponent.productListStyle === ProductListStyles.Card}
                />
                <HorizontalSpacer x={12} />
            </div>
            <MctaVerticalBar />
            <HorizontalSpacer x={12} />
            <MctaSlider
                min={2}
                max={5}
                label="msg: webshop.ProductsPerRow {Products per row:}"
                valueLabel
                value={selectedComponent.productsPerRow}
                defaultValue={selectedComponent.productsPerRow}
                onChange={value =>
                    dispatch({
                        type: actionTypes.WEBSHOP_PRODUCTS_PER_ROW_CHANGED,
                        payload: value,
                        amendToSelf: true
                    })}
                sliderContainerClassName={mctaStyles.sliderContainerClassName}
            />
        </div>
    );
};

class MobileViewEditorV extends React.PureComponent<Props> {
    bindOnRowCountChange: Function;
    constructor(props: Props) {
        super(props);
        this.bindOnRowCountChange = this.onRowCountChange.bind(this);
    }

    onRowCountChange(count: number) {
        this.props.dispatch({
            type: actionTypes.WEBSHOP_PRODUCTS_PER_ROW_IN_MOBILE_CHANGED,
            payload: count
        });
    }
    render() {
        let productsPerRow;
        const { selectedComponent } = this.props;
        if (selectedComponent) {
            productsPerRow = selectedComponent.productsPerRowInMobile;
        }
        return (
            <div>
                <MctaColumnGrid count={1} selected={productsPerRow === 1} onClick={this.bindOnRowCountChange} />
                <HorizontalSpacer x={5} />
                <MctaVerticalBar />
                <HorizontalSpacer x={5} />
                <MctaColumnGrid count={2} selected={productsPerRow === 2} onClick={this.bindOnRowCountChange} />
                {/*TODO: Uncomment below code when spacingItemsInMobile is implemented by WEBSHOP Team in WEBSHOP-8238 */}
                <HorizontalSpacer x={5} />
                <MctaVerticalBar />
                <HorizontalSpacer x={10} />
                <MctaSlider
                    label="msg: common.spacing {Spacing}"
                    min={0}
                    max={20}
                    sliderContainerClassName={mctaStyles.mobileMctaWebshopSlider}
                    value={selectedComponent.spacingItemsInMobile}
                    valueLabel="px"
                    onChange={spacing =>
                        this.props.dispatch({
                            type: actionTypes.WEBSHOP_SPACING_ITEMS_IN_MOBILE_CHANGED,
                            payload: spacing,
                            amendToSelf: true
                        })}
                />
            </div>
        );
    }
}

const calcToRightAndVCenter = ({ top, width, height, left }) => {
    return { x: left + width + 20, y: (top + (height / 2)) - 4 };
};

export const CtaButtonTextView = ({ dispatch, subscriptionData }) => {
    const isTrialUser = subscriptionData.subscriptionType === SubscriptionTypes.DEMO;
    const ref = React.useRef<HTMLSpanElement>(null);
    const [buttonPosition, setButtonPosition] = React.useState<DOMRect | null>(null);
    const clientRect = ref.current && ref.current.getBoundingClientRect();

    const showDisabledTooltip = () => {
        if (isTrialUser && buttonPosition) {
            dispatch({
                type: SHOW_TOOLTIP,
                payload: {
                    position: calcToRightAndVCenter(buttonPosition),
                    elementDimensions: buttonPosition,
                    id: ExtendedPanelTooltip,
                    customClass: mctaStyles.helpTipContainer,
                    showIcon: false,
                    msg: { text: 'msg: demo.restrictedFeature'
                    + ' {This feature is not available in the trial version.}' },
                }
            });
        }
    };

    const hideTooltip = () => {
        dispatch({ type: CLOSE_TOOLTIP });
    };

    React.useEffect(() => {
        const clientRect = ref.current && ref.current.getBoundingClientRect();
        if (!R.equals(buttonPosition?.toJSON(), clientRect?.toJSON())) {
            setButtonPosition(clientRect);
        }
    }, [clientRect]);

    return (
        <span
            className={isTrialUser ? mctaStyles.disabled : ''}
            onMouseEnter={showDisabledTooltip}
            onMouseLeave={hideTooltip}
            ref={ref}
        >
            <Msg k="msg: component.webshop.manageProductsAndOrders">Manage products and orders</Msg>
        </span>
    );
};

const ctaOnClick = ({ dispatch, subscriptionData }) => {
    if (subscriptionData.subscriptionType !== SubscriptionTypes.DEMO) {
        return dispatch({ type: SHOW_WEBSHOP_ADMIN_DIALOG });
    }
    return null;
};

export default {
    editView: EditView,
    mobileViewEditorV: (props: Props) => <MobileViewEditorV {...props} />,
    editButtonText: "msg: component.webshop.editWebshop {Edit Online Shop}",
    CtaButtonTextView,
    ctaOnClick,
};
