import AppConfig from "../../../../../../wbtgen/src/utils/AppConfig";
import { getAppConfig } from "../../../../../../wbtgen/src/components/App/epics/appConfig/appConfig.js";
import { getDAL } from "../../../../../../wbtgen/dal";

import type { AppState } from "../../../common/types";
import { contactsIntroSeenSelector, hideContactsTooltipSelector, isNewToDashboard } from "../../../common/selectors";
import { showBookingsTooltipFactory } from "../../../apps/Bookings/utils";

export function getContactsViewToken() {
    return getDAL().getAppViewToken('contacts');
}

type GetContactsUrlWithToken = {
    subscriptionType: string;
    adminId: string;
    token: string;
    email?: string;
};

export function getContactsUrlWithToken(params: GetContactsUrlWithToken): string {
    const appConfig = AppConfig(getAppConfig());
    const contactsAppUrl = new URL(appConfig.get("oneWeb.apps.contacts.appUrl"));
    contactsAppUrl.searchParams.set('iframeAuthToken', params.token);
    contactsAppUrl.searchParams.set('parent', 'wsb');
    contactsAppUrl.searchParams.set('parentUrl', location.origin);
    contactsAppUrl.searchParams.set('subscriptionType', params.subscriptionType);
    contactsAppUrl.searchParams.set('adminId', params.adminId);
    if (params.email) contactsAppUrl.searchParams.set('email', params.email);
    return contactsAppUrl.toString();
}

export const showContactsTooltipFactory = () => {
    const showBookingsTooltip = showBookingsTooltipFactory();

    let _showContactsTooltip: boolean | null = null;

    const showContactsTooltip = (appState: AppState) => {
        // preferences take priority if set
        if (hideContactsTooltipSelector(appState)) {
            return false;
        }

        // this value is memoized for the lifetime of the app
        if (_showContactsTooltip === null) {
            _showContactsTooltip = Boolean(
                !isNewToDashboard(appState) &&
                // TODO: WBTGEN-35920 implement a better mechanism
                !showBookingsTooltip(appState) &&
                contactsIntroSeenSelector(appState)
            );
        }

        return _showContactsTooltip;
    };

    return showContactsTooltip;
};
