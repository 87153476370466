import React from "react";
import { DemoLoginMessages } from '../../main/messages';
import styles from "./EmailVerificationPage.css";
import Button from "../../../../../src/view/common/Button/Button";
import VerticalSpacer from "../../../../../src/view/common/VerticalSpacer";
import type { EmailVerificationPagePropTypes } from "../../types";
import { showSignInPage } from "../../signUp/actions";
import { getNotificationActions } from "../../../../../src/components/App/epics/analyticsGoalsTracker/index";
import { SubscriptionTypes } from "../../../../../src/constants/app";
import { REMEMBER_GOAL_ACHIEVED } from "../../../../../src/components/App/epics/analyticsDependencies/actions";
import { GOOGLE_ANALYTICS_EMAIL_VERIFIED_LOCAL_STORAGE_KEY } from "../../constants";

const EmailVerification = ({
    intl,
    dispatch,
    userCreatedDate
}: EmailVerificationPagePropTypes) => {
    const {
        doneLabel,
        emailConfirmedLabel,
        resetPasswordSuccessHeaderMsg,
        returnToLoginLabel,
    } = DemoLoginMessages;

    React.useEffect(() => {
        let millisecondsSinceComputedUserCreationClientTimestamp = Date.now() - parseInt(userCreatedDate, 10);
        if (window.localStorage && !window.localStorage.getItem(GOOGLE_ANALYTICS_EMAIL_VERIFIED_LOCAL_STORAGE_KEY)) {
            let goalActions = getNotificationActions({
                goalId: 'days_seen_verified_email_trial',
                millisecondsSinceComputedUserCreationClientTimestamp,
                subscriptionType: SubscriptionTypes.DEMO
            });
            goalActions.forEach(goalAction => {
                if (!goalAction.type || (goalAction.type !== REMEMBER_GOAL_ACHIEVED)) {
                    dispatch(goalAction);
                }
            });
            // @ts-ignore
            window.localStorage.setItem(GOOGLE_ANALYTICS_EMAIL_VERIFIED_LOCAL_STORAGE_KEY, true);
        }
    }, []);

    return (
        <div>
            <div>
                <div className={styles.headerLabel}>
                    {intl.msgJoint(doneLabel)}
                </div>
                <div className={styles.headerMsg}>
                    <div>
                        {intl.msgJoint(resetPasswordSuccessHeaderMsg)}
                    </div>
                </div>
            </div>
            <VerticalSpacer y={49} />
            <div>
                <div>
                    <div className={styles.doneLabelContainer}>
                        <span className={styles.checkBoxIcon} />
                        <span className={styles.checkInboxLabel}>
                            {intl.msgJoint(emailConfirmedLabel)}
                        </span>
                    </div>
                </div>
            </div>
            <div>
                <div className={styles.actionsContainer}>
                    <div className={styles.returnToLoginContainer}>
                        <VerticalSpacer y={284} />
                        <Button
                            onClick={() => {
                                history.pushState("", document.title, window.location.pathname);
                                dispatch(showSignInPage());
                            }}
                            className={styles.returnToLoginButton}
                        >
                            {intl.msgJoint(returnToLoginLabel)}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};
export {
    EmailVerification
};
