import React from "react";
import makeAppStandardErrorDialog from '../../../src/components/App/dialogs/makeAppStandardErrorDialog';
import { demoBuyButtonClickAction } from '../actions';
import { ApiErrorHandlerCommonDialog } from '../../../src/redux/middleware/api/errorHandler/commonDialog/ApiErrorHandlerCommonDialog';
import { calculateUpgradeTierBeforeSeamlessUpgrade } from "../../../src/components/ComponentTierManager/actionCreators";

type Props = {
    dispatch: Dispatch,
};

class TrialUploadLimitReachedWarningDialog extends React.Component<Props> {
    mctaHandler = () => {
        this.props.dispatch(calculateUpgradeTierBeforeSeamlessUpgrade("trial:buynow", "", "", false));
        this.props.dispatch(demoBuyButtonClickAction());
    };

    render() {
        return (
            <ApiErrorHandlerCommonDialog
                title="msg: common.webspaceUploadLimitReached.title {Upgrade to get more storage}"
                messages={[
                    'msg: common.webspaceUploadLimitReached {Sorry, you have used all the storage available for the trial version of Website Builder.}', // eslint-disable-line max-len
                    'msg: common.webspaceUploadLimitReached.info {Upgrade to the full version and get more storage.}',
                ]}
                mctaText="msg: common.upgradeNow {Upgrade now}"
                mctaHandler={this.mctaHandler}
                sctaText="msg: common.cancel {Cancel}"
            />
        );
    }
}

export default makeAppStandardErrorDialog(TrialUploadLimitReachedWarningDialog);
