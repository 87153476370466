import * as R from "ramda";
import { overPath } from "../../../../src/utils/ramdaEx";
import * as mp from "../../../../src/mappers/path";
import * as colorMapper from "../Base/color";
import { applyMappers, makePlainMappers } from '../../utils';
import TextTransform from '../../../../src/components/Globalstyles/Menu/TextTransformTypes';
import * as fontFamilyMapper from "../Base/fontFamily";
import styleCell from "../../../../src/components/oneweb/Table/globalStyle/kind";
import LinkGlobalStyleKind from '../../../../src/components/oneweb/Link/globalStyle/kind';
import * as textLinkMapper from '../Text/textLinksMapper';
import textStyleParaMapper from '../Text/textStyleParaMapper';
import * as selectors from '../../../../src/components/Workspace/epics/stylesheets/selectors';
import * as backgroundMapper from "../Common/background";
import * as borderMapper from "../Common/border";
import { getNumRowsAndCols } from "../../../../src/components/oneweb/Table/utils";
import htmlWriter from "../../../../src/utils/htmlWriter/index";
import htmlToJson from "../../../../src/components/oneweb/Text/htmlToJson/index";
import { makeTextGlobalStylesNamesMap } from "../../../../src/components/oneweb/Text/makeTextGlobalStylesNamesMap";
import type {
    OldTableComponent,
    TableComponent,
    CommonTableComponentCell,
    TableComponentCell,
} from "../../../../src/components/oneweb/Table/flowTypes";
import type { ToDeps, BackDeps } from "../index";
import processOldTextContents from '../../../../src/components/oneweb/Text/utils/processOldTextContents';
import { customSendReport } from '../../../../src/customSendCrashReport';

const
    plainPropsMapper = makePlainMappers({
        cells: 'cells',
        cellsData: "cellsData",
        commonCellsData: "commonCellsData",
        mobileDown: "mobileDown",
        mobileHide: 'mobileHide'
    }),
    styleMapper = textStyleParaMapper('style', 'styles'),
    paraMapper = textStyleParaMapper('para', 'paras'),
    cellMapper = textStyleParaMapper('cell', 'cells');

const
    checkStartAndEndBeforeReturn = (start, end, rowInfo) => (end > start ? [start, end, rowInfo] : []),
    matchByIndex = (rows, startIndex, endIndex) => rows.map(([rowStartIndex, rowEndIndex, rowInfo]) => {
        if (startIndex <= rowStartIndex && rowEndIndex <= endIndex) {
            return checkStartAndEndBeforeReturn((rowStartIndex - startIndex), (rowEndIndex - startIndex), rowInfo);
        } else if (startIndex <= rowStartIndex && rowStartIndex < endIndex && rowEndIndex > endIndex) {
            return checkStartAndEndBeforeReturn((rowStartIndex - startIndex), (endIndex - startIndex), rowInfo);
        } else if (startIndex > rowStartIndex && startIndex < rowEndIndex && rowEndIndex <= endIndex) {
            return checkStartAndEndBeforeReturn(0, (rowEndIndex - startIndex), rowInfo);
        } else if (startIndex > rowStartIndex && rowEndIndex > endIndex) {
            return checkStartAndEndBeforeReturn(0, (endIndex - startIndex), rowInfo);
        }
        return [];
    }).filter(row => row.length),

    fixByIndex = (increment, rows) => rows.map(([start, end, row]) => ([start + increment, end + increment, row])),

    fixGlobalstyleName = (styles, id) => globalName => {
        const matchingStyle = styles.find(style => style.id === id);
        return matchingStyle ? matchingStyle.name : globalName;
    },

    matchLinks = matchByIndex,
    matchParas = matchByIndex,
    matchStyles = (styles, startIndex, endIndex) => {
        const updatedStyles = matchByIndex(styles, startIndex, endIndex);
        return updatedStyles.map(([startIndex, endIndex, style]) => ([
            startIndex,
            endIndex,
            R.evolve({
                color: (color) => colorMapper.toHsl(color),
                highlight: (highlight) => colorMapper.toHsl(highlight),
                shadow: {
                    color: (shadowColor) => colorMapper.toHsl(shadowColor)
                },
                font: R.pipe(fontFamilyMapper.back, fontFamilyMapper.to)
            }, style)
        ]));
    };

const
    fontFamilyMap = {
        to: overPath([mp.textFont])(fontFamilyMapper.to),
        back: overPath([mp.textFont])(fontFamilyMapper.back)
    },
    textMap = {
        to: overPath([[mp.text]])(
            R.evolve({
                transform: tt => R.or(tt, TextTransform.none),
                shadow: shadow => {
                    if (R.is(Object, shadow)) {
                        return R.evolve({ color: colorMapper.toHsl }, shadow);
                    }

                    return shadow;
                },
                fontFamily: fontFamilyMap.to
            })
        ),
        back: overPath([[mp.text]])(R.evolve({ shadow: R.identity }))
    };

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const removeStyle = ({ style, ...rest }) => rest,
    fixFlexWidth = sumFlexWidth => R.evolve({
        flexWidth: (flexWidth) => flexWidth / sumFlexWidth
    });

const mapToNewDataStructure = tableGlobalStyles => ({ cells, links, paras, styles, text, ...rest }) => {
    const numCols = cells.reduce((acc, cell) => R.max(acc, cell[2].colIndex), 0) + 1;

    let sumFlexWidth = 0;

    for (let i = 0; i < numCols; i++) {
        sumFlexWidth += cells[i][2].flexWidth;
    }

    return {
        ...rest,
        cells: cells.map(([startIndex, endIndex, cellInfo]) => ({
            cellInfo: R.pipe(removeStyle, fixFlexWidth(sumFlexWidth))(cellInfo),
            text: text.substr(startIndex, (endIndex - startIndex - 1)),
            links: matchLinks(links, startIndex, endIndex),
            paras: matchParas(paras, startIndex, endIndex),
            styles: matchStyles(styles, startIndex, endIndex),
            style: R.evolve({
                block: {
                    background: backgroundMapper.to,
                    border: borderMapper.to,
                    text: textMap.to
                },
                globalName: fixGlobalstyleName(tableGlobalStyles, cellInfo.style.globalId)
            }, cellInfo.style)
        }))
    };
};

const
    applyTableNormalGlobalstyleIfStyleMissing = ({ id, name }) => (cell) => {
        if (!cell.style) {
            return R.pipe(
                R.assocPath([mp.style, mp.globalId], id),
                R.assocPath([mp.style, mp.globalName], name)
            )(cell);
        }

        return cell;
    },
    fixEndIndexForLastItem = (prop) => (cell) => {
        const
            textLength = cell.text.length,
            lastItem = R.pipe(
                R.prop(prop),
                R.last,
                R.evolve({ end: R.min(textLength) })
            )(cell);

        if (!R.isEmpty(lastItem)) {
            return R.evolve({
                [prop]: R.pipe(R.dropLast(1), R.append(lastItem))
            }, cell);
        }

        return cell;
    },
    getCellStart = c => c[0],
    getCellEnd = c => c[1],
    getCellInfo = c => c[2],
    findClosestCell = (cells, rowIndex, colIndex) => {
        return {
            horizontally: R.pipe(
                R.filter(c => getCellInfo(c).rowIndex === rowIndex),
                R.sort((a, b) => {
                    return Math.abs(getCellInfo(a).colIndex - colIndex) - Math.abs(getCellInfo(b).colIndex - colIndex);
                }),
                R.head
            )(cells),
            vertically: R.pipe(
                R.filter(c => getCellInfo(c).colIndex === colIndex),
                R.sort((a, b) => {
                    return Math.abs(getCellInfo(a).rowIndex - rowIndex) - Math.abs(getCellInfo(b).rowIndex - rowIndex);
                }),
                R.head
            )(cells)
        };
    },
    fixMissingCells = component => {
        let
            { cells } = component,
            { numRows, numCols } = cells.reduce(function (acc, cell) {
                const
                    [,, cellInfo] = cell,  // eslint-disable-line no-unused-vars
                    numRows = Math.max(acc.numRows, cellInfo.rowIndex),
                    numCols = Math.max(acc.numCols, cellInfo.colIndex);

                return { numRows, numCols };
            }, { numRows: 0, numCols: 0 });

        numRows = numRows + 1;
        numCols = numCols + 1;

        let missingIndices: { index: number, rowIndex: number, colIndex: number, start: number, end: number }[] = [];

        for (let i = 0; i < numRows; i++) {
            for (let j = 0; j < numCols; j++) {
                const
                    index = (numCols * i) + j - missingIndices.length,
                    cellInfo = cells[index] ? getCellInfo(cells[index]) : null;

                if (!cellInfo || cellInfo.rowIndex !== i || cellInfo.colIndex !== j) {
                    const prevCell = cells[index - 1] || missingIndices.find(m => m.index === index - 1),
                        nextCell = cells[index + 1],
                        start = getCellEnd(prevCell),
                        end = nextCell ? getCellStart(nextCell) : start;
                    missingIndices.push({ index: (numCols * i) + j, rowIndex: i, colIndex: j, start, end });
                }
            }
        }

        let cellsClone = R.clone(cells);

        missingIndices.forEach(function (missingIndex) {
            let closestCell = findClosestCell(cells, missingIndex.rowIndex, missingIndex.colIndex);
            const cell = [
                missingIndex.start,
                missingIndex.end,
                {
                    colIndex: missingIndex.colIndex,
                    rowIndex: missingIndex.rowIndex,
                    flexWidth: getCellInfo(closestCell.vertically).flexWidth,
                    minHeight: getCellInfo(closestCell.horizontally).minHeight,
                    style: R.clone(getCellInfo(closestCell.horizontally).style),
                }
            ];
            cellsClone.splice(missingIndex.index, 0, cell);
        });

        return R.assoc('cells', cellsClone, component);
    };

const validateAndFixStyleId = (style, stylesheets) => {
    let existingStyle = stylesheets.styles.find(s => (
        s.type === style.type && (s.id === style.globalId || s.name === style.globalName)
    ));

    if (!existingStyle) {
        customSendReport({
            message: 'Table cell style does not match any from stylesheets.',
            additionalInfo: { styleGlobalId: style.globalId },
        });
    }

    return existingStyle ? { ...style, globalId: existingStyle.id } : style;
};

const findCommonCellStyle = ({
    cellsData: cells,
    commonCellsData
}: { cellsData: Array<TableComponentCell>, commonCellsData?: CommonTableComponentCell }) => {
    let commonStyleMap = {};

    const currentCommonStyle: string = commonCellsData ?
        JSON.stringify(commonCellsData.style) : JSON.stringify(cells[0].style);

    const addToCommonStyleMap = (style: string) => {
        if (style in commonStyleMap) {
            commonStyleMap[style] += 1;
        } else {
            commonStyleMap[style] = 1;
        }
    };
    cells.forEach(cell => {
        if (cell.style) {
            addToCommonStyleMap(JSON.stringify(cell.style));
        } else {
            addToCommonStyleMap(currentCommonStyle);
        }
    });

    let
        c = 0,
        commonStyle = '';
    Object.keys(commonStyleMap).forEach(style => {
        if (commonStyleMap[style] > c) {
            commonStyle = style;
            c = commonStyleMap[style];
        }
    });

    return commonStyle;
};

const modifyCellsDataStylesWithCommonStyles =
    (component: OldTableComponent, commonCellStyle: string): OldTableComponent => {
        const oldCommonCellStyle = component.commonCellsData && component.commonCellsData.style;
        const isOldCommonCellStyleSameAsNew =
            oldCommonCellStyle && JSON.stringify(oldCommonCellStyle) === commonCellStyle;
        return R.evolve({
            cellsData: R.map((cell: TableComponentCell) => {
                if (cell.style) {
                    const styleString = JSON.stringify(cell.style);
                    if (styleString === commonCellStyle) {
                        // eslint-disable-next-line @typescript-eslint/no-unused-vars
                        const { style, ...rest } = cell;
                        return rest;
                    }
                } else if (!isOldCommonCellStyleSameAsNew) {
                    return { ...cell, style: oldCommonCellStyle };
                }
                return cell;
            })
        }, component);
    };

export const
    to = (inComponent: OldTableComponent, { stylesheets, site }: ToDeps): TableComponent => {
        let component = inComponent;
        if (component.cellsData) {
            let commonCellsDataStyle;

            let commonCellStyle = findCommonCellStyle(component);
            component = modifyCellsDataStylesWithCommonStyles(component, commonCellStyle);
            commonCellsDataStyle = JSON.parse(commonCellStyle);

            const toData = R.pipe(
                R.omit(["text", "cells", "styles", "paras", "links"]),
                ({ cellsData, commonCellsData, ...rest }) => ({
                    ...rest,
                    commonCellsData: {
                        ...commonCellsData,
                        style: validateAndFixStyleId(commonCellsDataStyle, stylesheets)
                    },
                    cells: !site ?
                        cellsData :
                        cellsData.map(({ content, style, ...rest }) => {
                            let cell: Record<string, any> = {
                                ...rest,
                                content: processOldTextContents(content, site)
                            };
                            if (style) {
                                cell.style = validateAndFixStyleId(style, stylesheets);
                            }
                            return cell;
                        }),
                })
            )(component);

            return toData;
        }

        const
            tableGlobalStyles = R.pipe(
                selectors.getAllStylesheets,
                selectors.getStylesByType(styleCell)
            )(stylesheets),
            linkGlobalStyles = R.pipe(
                selectors.getAllStylesheets,
                selectors.getStylesByType(LinkGlobalStyleKind)
            )(stylesheets),
            tableNormal = selectors.tableNormalGlobalstyle(stylesheets);

        const
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            { cells, links, paras, styles, text, ...rest } = applyMappers(
                component,
                plainPropsMapper.to,
                R.pipe(
                    fixMissingCells,
                    mapToNewDataStructure(tableGlobalStyles)
                )
            ),
            finalCells = cells.map(cell => applyMappers(
                cell,
                R.pipe(
                    textLinkMapper.to(linkGlobalStyles),
                    styleMapper.to,
                    paraMapper.to,
                    cellMapper.to
                )
            )),
            finalCells2 = R.map(
                R.pipe(
                    applyTableNormalGlobalstyleIfStyleMissing(tableNormal),
                    fixEndIndexForLastItem('styles'),
                    fixEndIndexForLastItem('links')
                )
            )(finalCells),
            finalCells3 = finalCells2.map(cell => {
                const
                    { styles } = stylesheets,
                    { text: cellText, styles: cellStyles, paras: cellParas, links: cellLinks, ...rest } = cell,
                    htmlWriterData = {
                        text: cellText,
                        styles: cellStyles,
                        paras: cellParas,
                        links: cellLinks
                    },
                    content = !site ?
                        htmlWriter(htmlWriterData, styles, site, {}) :
                        processOldTextContents(htmlWriter(htmlWriterData, styles, site, {}), site);

                return { ...rest, content };
            });

        return { ...rest, cells: finalCells3 };
    },
    back = (component: TableComponent, { stylesheets, site }: BackDeps): OldTableComponent => {
        const
            { cells } = component,
            { numCols } = getNumRowsAndCols(cells),
            globalStylesMap = stylesheets ? makeTextGlobalStylesNamesMap(stylesheets) : {},
            // @ts-ignore
            getJson = (content) => htmlToJson({ content, site, globalStylesMap });

        const { finalText, finalCells, finalStyles, finalParas, finalLinks } = cells.reduce((acc, cell, loopIndex) => {
            const
                { cellInfo, style, content } = cell,
                json = getJson(content),
                { startIndex, finalText, finalCells, finalStyles, finalParas, finalLinks } = acc,
                endIndex = startIndex + json.text.length + 1,

                backMappedStyle = R.evolve({
                    block: {
                        background: backgroundMapper.back,
                        border: borderMapper.back
                    },
                    text: textMap.back
                }, style),

                updatedCellInfo = { ...cellInfo, style: backMappedStyle },
                updatedCell = [startIndex, endIndex, updatedCellInfo],

                { styles } = styleMapper.back({ styles: json.styles }),
                updatedStyles = fixByIndex(startIndex, styles).map(([start, end, style]) => {
                    const updatedStyle = R.when(
                        R.has(mp.font),
                        R.evolve({ font: fontFamilyMapper.back })
                    )(style);

                    return [start, end, updatedStyle];
                }),

                { paras } = paraMapper.back({ paras: json.paras }),
                updatedParas = fixByIndex(startIndex, paras),

                { links } = textLinkMapper.back({ links: json.links }),
                updatedLinks = fixByIndex(startIndex, links);

            return {
                startIndex: endIndex,
                finalText: finalText + json.text + ((loopIndex + 1) % numCols === 0 ? "\n" : "\t"),
                finalCells: R.append(updatedCell, finalCells),
                finalLinks: updatedLinks.length ? [...finalLinks, ...updatedLinks] : finalLinks,
                finalParas: updatedParas.length ? [...finalParas, ...updatedParas] : finalParas,
                finalStyles: updatedStyles.length ? [...finalStyles, ...updatedStyles] : finalStyles
            };
        }, { startIndex: 0, finalText: "", finalCells: [], finalStyles: [], finalParas: [], finalLinks: [] });

        const updatedComponent = {
            ...component,
            text: finalText,
            cells: finalCells,
            cellsData: cells,
            styles: finalStyles,
            paras: finalParas,
            links: finalLinks
        };

        const output = applyMappers(
            updatedComponent,
            plainPropsMapper.back
        );

        return output;
    };
