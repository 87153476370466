import React from "react";
import { useDispatch, useSelector } from "react-redux";
import cx from "classnames";
import {
    BookingsInserterButtonTypes,
    BookingsInserterDetails,
    BookingsInserterViewTypes
} from "./types";
import styles from "./expandedPanelContent.css";
import { Msg, useIntl } from "../../../../view/intl";
import VerticalSpacer from "../../../../view/common/VerticalSpacer";
import { EXTENDED_PANEL_CLOSE } from "../../../Panel/actionTypes";
import BookingsKind from "../kind";
import { openDialog } from "../../../App/actionCreators";
import BookingsManagementDialogId from "../BookingsManagementDialog/dialogId";
import { selectPageAction } from "../../../PagesTree/Tree/actionCreators";
import { CREATE_BOOKINGS_PAGE, FETCH_BOOKINGS_STATE } from "./epic/actionTypes";
import { selectBookingsPageId, siteDataStateAppSel } from "../../../App/epics/siteData/selectors";
import { templateDataAppSel } from "../../Template/epics/template/selectors";
import Icons from "../../../../view/Icons";
import { selectBookingsOnboardingSteps } from "./epic/selectors";
import { selectIsOnBookingsPage } from "../../../App/epics/pageDataset/selectors";
import { BOOKINGS_AVAILABILITY_PATH, BOOKINGS_SERVICES_PATH } from "../constants";
import { TO_SHOW_PUBLISH_BUTTON_TOOLTIP } from "../../../Tooltip/stickyTooltip/actionTypes";
import { BookingsSourceTypes } from "../events";

const buttonHandlers = {
    [BookingsInserterButtonTypes.CREATE_PAGE]: ({ dispatch, intl, siteMap, templateData, source }) => {
        // create the bookings page
        dispatch({
            type: CREATE_BOOKINGS_PAGE,
            payload: {
                source,
                componentKind: BookingsKind,
                siteMap,
                templateID: templateData.id,
                templateWidth: templateData.width,
                pageNames: [
                    {
                        type: "bookings",
                        name: intl.msgJoint("msg: component.bookings.pageName {Book now}")
                    }
                ]
            }
        });
    },
    [BookingsInserterButtonTypes.MANAGE]: ({
        dispatch,
        iframePath
    }: {
        dispatch: any;
        iframePath?: string;
    }) => {
        // open bookings management dialog iframe with the selected path (for onboarding steps)
        dispatch({ type: EXTENDED_PANEL_CLOSE });
        dispatch(openDialog(BookingsManagementDialogId, { iframePath }));
    },
    [BookingsInserterButtonTypes.GO_TO_PAGE]: ({ dispatch, bookingsPageId }) => {
        // load the bookings page
        dispatch({ type: EXTENDED_PANEL_CLOSE });

        // GO_TO_PAGE button should only be displayed if a bookings page exists already
        // therefore bookingsPageId should always be a string here
        if (bookingsPageId) {
            dispatch(selectPageAction(bookingsPageId));
        }
    },
    [BookingsInserterButtonTypes.TRY_AGAIN]: ({ dispatch }) => {
        // call the bookings api again
        dispatch({ type: FETCH_BOOKINGS_STATE });
    },
    [BookingsInserterButtonTypes.CLOSE_INSERTER]: ({ dispatch }) => {
        dispatch({ type: EXTENDED_PANEL_CLOSE });
        dispatch({ type: TO_SHOW_PUBLISH_BUTTON_TOOLTIP });
    }
};

const InserterImage = () => {
    return (
        <div>
            <Icons.BOOKINGS_INSERTER className={styles.image} />
            <VerticalSpacer y={16} />
        </div>
    );
};

const ErrorImage = () => {
    return (
        <div>
            <Icons.SAD_SQUARE className={styles.image} />
            <VerticalSpacer y={16} />
        </div>
    );
};

const AddonTag = () => {
    return (
        <div className={cx(styles.infoTag, styles.addOn)} data-testid="add-on-tag">
            <Msg k="common.addOn">Add-on</Msg>
        </div>
    );
};

const PremiumTag = () => {
    return (
        <div className={cx(styles.infoTag, styles.premium)} data-testid="premium-tag">
            <Msg k="common.premium">Premium</Msg>
        </div>
    );
};

const BnETag = () => {
    return (
        <div className={cx(styles.infoTag, styles.bne)} data-testid="bne-tag">
            <Msg k="common.business_ecommerce">Business + Ecommerce</Msg>
        </div>
    );
};

const CompletedTag = () => {
    return (
        <div className={styles.completedTag} data-testid="completed-tag">
            <Msg k="extendedPanel.bookings.onboardingSteps.completed">Completed</Msg>
        </div>
    );
};

/**
 * Views for every possible bookings inserter scenario
 */
export const BookingsInserterViews: BookingsInserterDetails = {
    [BookingsInserterViewTypes.GET_STARTED]: () => {
        const dispatch = useDispatch();
        const intl = useIntl();
        const siteMap = useSelector(siteDataStateAppSel());
        const templateData = useSelector(templateDataAppSel);

        const buttonType = BookingsInserterButtonTypes.CREATE_PAGE;

        return (
            <div className={styles.viewContainer}>
                <InserterImage />
                <div data-testid="panel-heading" className={styles.heading}>
                    <Msg k="extendedPanel.bookings.getStarted.heading">
                        Appointment scheduling made easy
                    </Msg>
                </div>
                <VerticalSpacer y={8} />
                <div data-testid="panel-description" className={styles.description}>
                    <Msg k="extendedPanel.bookings.getStarted.description">
                        Let customers book your services directly from your website, easily keep
                        track of your upcoming appointments and streamline your booking management.
                    </Msg>
                </div>
                <VerticalSpacer y={32} />
                <button
                    type="button"
                    className={styles.primaryButton}
                    onClick={() => {
                        buttonHandlers[buttonType]({
                            dispatch, intl, siteMap, templateData, source: BookingsSourceTypes.getStarted
                        });
                    }}
                    data-testid={"button-" + buttonType}
                >
                    <Msg k="extendedPanel.bookings.button.getStarted">Get started</Msg>
                </button>
            </div>
        );
    },
    [BookingsInserterViewTypes.ADD_BOOKINGS_PAGE]: () => {
        const dispatch = useDispatch();
        const intl = useIntl();
        const siteMap = useSelector(siteDataStateAppSel());
        const templateData = useSelector(templateDataAppSel);

        const buttonType = BookingsInserterButtonTypes.CREATE_PAGE;

        return (
            <div className={styles.viewContainer}>
                <InserterImage />
                <div data-testid="panel-heading" className={styles.heading}>
                    <Msg k="extendedPanel.bookings.addPage.heading">
                        Appointment scheduling made easy with the Bookings add-on
                    </Msg>
                </div>
                <VerticalSpacer y={8} />
                <div data-testid="panel-description" className={styles.description}>
                    <Msg k="extendedPanel.bookings.addPage.description">
                        Adding the booking page to your site is a great way to automate your
                        appointment scheduling. You can preview the booking page, but you need to
                        upgrade to add your services and publish them on your site.
                    </Msg>
                    &nbsp;
                    <a
                        onClick={() => {
                            dispatch(openDialog(BookingsManagementDialogId));
                        }}
                        data-testid="read-more-link"
                    >
                        <Msg k="extendedPanel.bookings.addPage.link">
                            Read more about your upgrade options
                        </Msg>
                    </a>
                </div>
                <VerticalSpacer y={32} />
                <button
                    type="button"
                    className={styles.primaryButton}
                    onClick={() => {
                        buttonHandlers[buttonType]({
                            dispatch, intl, siteMap, templateData, source: BookingsSourceTypes.addPage
                        });
                    }}
                    data-testid={"button-" + buttonType}
                >
                    <Msg k="extendedPanel.bookings.button.addPage">Add booking page</Msg>
                </button>
            </div>
        );
    },
    [BookingsInserterViewTypes.TRY_FOR_FREE_BASIC]: () => {
        const dispatch = useDispatch();

        const buttonType = BookingsInserterButtonTypes.MANAGE;

        return (
            <div className={styles.viewContainer}>
                <InserterImage />
                <div className={styles.tagContainer}>
                    <PremiumTag />
                    <AddonTag />
                </div>
                <VerticalSpacer y={16} />
                <div data-testid="panel-heading" className={styles.heading}>
                    <Msg k="extendedPanel.bookings.addPage.heading">
                        Appointment scheduling made easy with the Bookings add-on
                    </Msg>
                </div>
                <VerticalSpacer y={8} />
                <div data-testid="panel-description" className={styles.description}>
                    <Msg k="extendedPanel.bookings.tryBasic.description">
                        Let customers book your services directly from your website and easily keep
                        track of your upcoming appointments. Get Bookings and Website Builder
                        Premium now to try it.
                    </Msg>
                </div>
                <VerticalSpacer y={32} />
                <button
                    type="button"
                    className={styles.primaryButton}
                    onClick={() => {
                        buttonHandlers[buttonType]({ dispatch });
                    }}
                    data-testid={"button-" + buttonType}
                >
                    <Msg k="extendedPanel.bookings.button.tryForFree">Try for free</Msg>
                </button>
            </div>
        );
    },
    [BookingsInserterViewTypes.TRY_FOR_FREE_PREMIUM]: () => {
        const dispatch = useDispatch();

        const buttonType = BookingsInserterButtonTypes.MANAGE;

        return (
            <div className={styles.viewContainer}>
                <InserterImage />
                <div className={styles.tagContainer}>
                    <AddonTag />
                </div>
                <VerticalSpacer y={16} />
                <div data-testid="panel-heading" className={styles.heading}>
                    <Msg k="extendedPanel.bookings.addPage.heading">
                        Appointment scheduling made easy with the Bookings add-on
                    </Msg>
                </div>
                <VerticalSpacer y={8} />
                <div data-testid="panel-description" className={styles.description}>
                    <Msg k="extendedPanel.bookings.tryPremium.description">
                        Let customers book your services directly from your website and easily keep
                        track of your upcoming appointments. Get the Bookings add-on now to try it.
                    </Msg>
                </div>
                <VerticalSpacer y={32} />
                <button
                    type="button"
                    className={styles.primaryButton}
                    onClick={() => {
                        buttonHandlers[buttonType]({ dispatch });
                    }}
                    data-testid={"button-" + buttonType}
                >
                    <Msg k="extendedPanel.bookings.button.tryForFree">Try for free</Msg>
                </button>
            </div>
        );
    },
    [BookingsInserterViewTypes.ACTIVATE_BOOKINGS_PREMIUM]: () => {
        const dispatch = useDispatch();

        const buttonType = BookingsInserterButtonTypes.MANAGE;

        return (
            <div className={styles.viewContainer}>
                <InserterImage />
                <div className={styles.tagContainer}>
                    <AddonTag />
                </div>
                <VerticalSpacer y={16} />
                <div data-testid="panel-heading" className={styles.heading}>
                    <Msg k="extendedPanel.bookings.addPage.heading">
                        Appointment scheduling made easy with the Bookings add-on
                    </Msg>
                </div>
                <VerticalSpacer y={8} />
                <div data-testid="panel-description" className={styles.description}>
                    <Msg k="extendedPanel.bookings.activatePremium.description">
                        Get our Bookings add-on to let customers book your services directly from
                        your website and easily keep track of your upcoming appointments.
                    </Msg>
                </div>
                <VerticalSpacer y={32} />
                <button
                    type="button"
                    className={styles.primaryButton}
                    onClick={() => {
                        buttonHandlers[buttonType]({ dispatch });
                    }}
                    data-testid={"button-" + buttonType}
                >
                    <Msg k="extendedPanel.bookings.button.activatePremium">Get Bookings</Msg>
                </button>
            </div>
        );
    },
    [BookingsInserterViewTypes.ACTIVATE_BOOKINGS_BASIC]: () => {
        const dispatch = useDispatch();

        const buttonType = BookingsInserterButtonTypes.MANAGE;

        return (
            <div className={styles.viewContainer}>
                <InserterImage />
                <div className={styles.tagContainer}>
                    <BnETag />
                    <AddonTag />
                </div>
                <VerticalSpacer y={16} />
                <div data-testid="panel-heading" className={styles.heading}>
                    <Msg k="extendedPanel.bookings.addPage.heading">
                        Appointment scheduling made easy with the Bookings add-on
                    </Msg>
                </div>
                <VerticalSpacer y={8} />
                <div data-testid="panel-description" className={styles.description}>
                    <Msg k="extendedPanel.bookings.activateBasic.description">
                        Let customers book your services directly from your website and easily keep
                        track of your upcoming appointments. Get Bookings and Website Builder
                        Business + Ecommerce now to try it.
                    </Msg>
                </div>
                <VerticalSpacer y={32} />
                <button
                    type="button"
                    className={styles.primaryButton}
                    onClick={() => {
                        buttonHandlers[buttonType]({ dispatch });
                    }}
                    data-testid={"button-" + buttonType}
                >
                    <Msg k="extendedPanel.bookings.button.tryForFree">Try for free</Msg>
                </button>
            </div>
        );
    },
    [BookingsInserterViewTypes.ONBOARDING_STEPS]: () => {
        const dispatch = useDispatch();
        const onboardingSteps = useSelector(selectBookingsOnboardingSteps);

        const availabilityButtonType = BookingsInserterButtonTypes.MANAGE;
        const servicesButtonType = BookingsInserterButtonTypes.MANAGE;
        const publishButtonType = BookingsInserterButtonTypes.CLOSE_INSERTER;

        return (
            <div className={styles.viewContainer}>
                <InserterImage />
                <div data-testid="panel-heading" className={styles.heading}>
                    <Msg k="extendedPanel.bookings.onboardingSteps.heading">
                        You’re almost ready to accept bookings
                    </Msg>
                </div>
                <VerticalSpacer y={8} />
                <div data-testid="panel-description" className={styles.description}>
                    <Msg k="extendedPanel.bookings.onboardingSteps.description">
                        Make sure to complete all setup steps before you publish the Bookings page
                        to your website.
                    </Msg>
                </div>
                <VerticalSpacer y={32} />
                <div className={styles.onboardingStepsHeader}>
                    <Msg k="extendedPanel.bookings.button.getStarted">Get started</Msg>
                </div>
                <VerticalSpacer y={16} />
                {/*
                    Onboarding steps buttons
                */}
                <div className={styles.onboardingStepsContainer}>
                    <div
                        className={styles.stepContainer}
                        onClick={() => {
                            buttonHandlers[availabilityButtonType]({
                                dispatch,
                                iframePath: BOOKINGS_AVAILABILITY_PATH
                            });
                        }}
                        data-testid="button-availability"
                    >
                        <div>
                            {onboardingSteps.find(step => step.step === "ADD_AVAILABILITY")
                                ?.status === "COMPLETED" && <CompletedTag />}
                            <Msg k="extendedPanel.bookings.button.onboardingSteps.availability">
                                Add availability
                            </Msg>
                        </div>
                        <Icons.ARROW_RIGHT_LONG />
                    </div>
                    <div
                        className={styles.stepContainer}
                        onClick={() => {
                            buttonHandlers[servicesButtonType]({
                                dispatch,
                                iframePath: BOOKINGS_SERVICES_PATH
                            });
                        }}
                        data-testid="button-services"
                    >
                        <div>
                            {onboardingSteps.find(step => step.step === "ADD_SERVICES")?.status ===
                                "COMPLETED" && <CompletedTag />}
                            <Msg k="extendedPanel.bookings.button.onboardingSteps.services">
                                Add services
                            </Msg>
                        </div>
                        <Icons.ARROW_RIGHT_LONG />
                    </div>
                    <div
                        className={styles.stepContainer}
                        onClick={() => {
                            buttonHandlers[publishButtonType]({ dispatch });
                        }}
                        data-testid="button-publish"
                    >
                        <div>
                            {onboardingSteps.find(step => step.step === "PUBLISH_TO_SITE")
                                ?.status === "COMPLETED" && <CompletedTag />}
                            <Msg k="extendedPanel.bookings.button.onboardingSteps.publish">
                                Publish to site
                            </Msg>
                        </div>
                        <Icons.ARROW_RIGHT_LONG />
                    </div>
                </div>
            </div>
        );
    },
    [BookingsInserterViewTypes.ALL_DONE]: () => {
        const dispatch = useDispatch();
        const isOnBookingsPage = useSelector(selectIsOnBookingsPage);
        const bookingsPageId = useSelector(selectBookingsPageId);

        const manageButtonType = BookingsInserterButtonTypes.MANAGE;
        const goToButtonType = BookingsInserterButtonTypes.GO_TO_PAGE;

        return (
            <div className={styles.viewContainer}>
                <InserterImage />
                <div data-testid="panel-heading" className={styles.heading}>
                    <Msg k="extendedPanel.bookings.allDone.heading">Manage your appointments</Msg>
                </div>
                <VerticalSpacer y={8} />
                <div data-testid="panel-description" className={styles.description}>
                    <Msg k="extendedPanel.bookings.allDone.description">
                        Keep track of your upcoming appointments, edit your services and adjust your
                        availability to keep your Bookings page up to date.
                    </Msg>
                </div>
                <VerticalSpacer y={32} />
                <button
                    type="button"
                    className={styles.primaryButton}
                    onClick={() => {
                        buttonHandlers[manageButtonType]({ dispatch });
                    }}
                    data-testid={"button-" + manageButtonType}
                >
                    <Msg k="extendedPanel.bookings.button.manage">Manage Bookings</Msg>
                </button>
                {/* go to bookings button should only be visible if not already on the page */}
                {!isOnBookingsPage && (
                    <div>
                        <VerticalSpacer y={10} />
                        <button
                            type="button"
                            className={styles.secondaryButton}
                            onClick={() => {
                                buttonHandlers[goToButtonType]({ dispatch, bookingsPageId });
                            }}
                            data-testid={"button-" + goToButtonType}
                        >
                            <Msg k="extendedPanel.bookings.button.goToPage">
                                Go to Bookings page
                            </Msg>
                        </button>
                    </div>
                )}
            </div>
        );
    },
    [BookingsInserterViewTypes.ERROR]: () => {
        const dispatch = useDispatch();

        const buttonType = BookingsInserterButtonTypes.TRY_AGAIN;

        return (
            <div className={styles.viewContainer}>
                <ErrorImage />
                <div data-testid="panel-heading" className={styles.heading}>
                    <Msg k="extendedPanel.bookings.error.description">
                        We couldn't load the content.
                    </Msg>
                </div>
                <VerticalSpacer y={8} />
                <div data-testid="panel-description" className={styles.description}>
                    <Msg k="common.pleaseTryAgain">Please try again.</Msg>
                </div>
                <VerticalSpacer y={32} />
                <button
                    type="button"
                    className={styles.primaryButton}
                    onClick={() => {
                        buttonHandlers[buttonType]({ dispatch });
                    }}
                    data-testid={"button-" + buttonType}
                >
                    <Msg k="common.tryAgain">Try again</Msg>
                </button>
            </div>
        );
    }
};
