import { take } from 'redux-saga/effects'; // eslint-disable-line n/file-extension-in-import
import { openDialogGen } from "./index";

export default function* (
    dialogId: string,
    confirmAction: string,
    dialogProps: AnyMap = {}
): Generator<any, void, any> {
    yield* openDialogGen(dialogId, dialogProps);
    yield take(confirmAction);
}
