import React from "react";
import { ComponentNames } from "../../oneweb/constants";
import * as Icon from "./Icons.css";
import type { ExtendedPanelContentProps } from "../view/inserter";
import BookingsKind from "../../oneweb/Bookings/kind";
import { BookingsViews } from "../../oneweb/Bookings/expandedPanel/expandedPanelContent";

export default {
    kind: BookingsKind,
    shortCut: {
        iconClassName: Icon.bookingsNew,
        text: ComponentNames[BookingsKind]
    },
    extendedPanelData: {
        title: "msg: extendedPanel.bookingsTitle {Bookings}",
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        content: (props: ExtendedPanelContentProps) => <BookingsViews />
    }
};
