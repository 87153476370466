import { getDAL } from "../../../dal/index";
import { createScheduledAction, cancelScheduledAction } from "../../redux/middleware/schedule/actionCreators";
import { WORKSPACE_READY } from '../Workspace/actionTypes';
import { SAVE_REQUEST } from "../App/actionTypes";
import { PAGE_DATASET_SAVE_FAILURE, PAGE_DATASET_SAVE_SUCCESS, } from '../App/epics/pageDataset/actions';
import { getAppConfig } from "../App/epics/appConfig/appConfig.js";
import AppConfig from "../../utils/AppConfig";
import { SaveStatus } from '../Workspace/epics/saveStatus/SaveStatus';
import WorkspaceStatusVAT from '../Workspace/epics/status/valueActionType';
import { READY } from '../Workspace/epics/status/types';
import { isTrialSubscription } from '../App/epics/subscriptionData/utils';
import { Lit } from '../../lit';
import { savePreferencesAction } from '../Preferences/actions';
import { PUBLISH_REQUESTED } from '../TopBar/epics/publishSite/actionTypes';
import { openDialog } from '../App/actionCreators/index';
import { PublishConfirmationDialogId } from '../TopBar/view/dialogIds';
import { preferencesSettingAppSel } from "../Preferences/selectors";
import { SiteDataSaveFailureDialogId } from '../App/dialogs/appDialogIds';
import { OPEN_DIALOG, CLOSE_DIALOG } from '../../redux/modules/actionTypes';
import { openBanner, closeBanner } from '../Banner/utils';
import * as Actions from './actions';
import { InternetDisconnectedWarnings } from './banner';
import type { AppStore } from "../../redux/modules/flowTypes";
import * as BannerStyles from './views/banner.css';

// Global state of middleware
const scope = {
    enabled: false,
    timeInterval: 0,
    canSave: false,
    scheduled: false,
    inProgress: false,
    saveFailedDialogOpen: false,
    introDialogClosed: false,
    userOnline: true,
    userOfflineBannerShown: false,
};

const scheduledNeworkConnectionCheck = {
    actionToDispatch: Actions.checkNetworkConnection(),
    timeout: 1000,
};

export const AutosaveMiddleware = (store: AppStore) => (next: Dispatch) => (action: Action) => {
    if (
        // @ts-ignore
        (store.subscriptionData && isTrialSubscription(store.subscriptionData.subscriptionType)) || // Disable autosave for Trial
        (store.getState().epics[WorkspaceStatusVAT].state !== READY) // Dislable autosave when user is not on WSB editor screen
    ) {
        return next(action);
    }

    if (action.type === WORKSPACE_READY) {
        const
            domainName = getDAL().getDomain(),
            config = AppConfig(getAppConfig()).getOptional('oneWeb.autosave', {}),
            enabled = config.getOptional('enabled', false),
            whiteListedDomains = config.getOptional('whiteListedDomains', []),
            supportedTLDs = config.getOptional('supportedTLDs', []),
            timeInterval = parseInt(config.getOptional('timeInterval', 0), 10);

        scope.enabled = !isNaN(timeInterval) && timeInterval > 0 && (
            enabled ||
                whiteListedDomains.includes(domainName) ||
                supportedTLDs.includes(domainName.split('.').pop())
        );

        if (scope.enabled) {
            scope.timeInterval = timeInterval * 1000;
            const state = store.getState();
            const hidePopup = preferencesSettingAppSel(Lit.hideAutosavePopUp)(state);
            if (!hidePopup) {
                store.dispatch(Actions.showAutosavePopup());
            }

            // First dispatch to check network connection and handle autosave
            store.dispatch(createScheduledAction(scheduledNeworkConnectionCheck));
        }
    }

    if (scope.enabled) {
        if (action.type === Actions.CHECK_NETWORK_CONNECTION) {
            scope.userOnline = window.navigator.onLine;
            if (scope.userOnline === false && scope.userOfflineBannerShown === false) {
                scope.userOfflineBannerShown = true;
                store.dispatch(
                    openBanner({
                        iconCss: BannerStyles.bannerIcon,
                        texts: InternetDisconnectedWarnings,
                        warning: true,
                    })
                );
            }
            store.dispatch(createScheduledAction(scheduledNeworkConnectionCheck));
        }

        if (scope.userOnline) {
            if (scope.userOfflineBannerShown === true) {
                scope.userOfflineBannerShown = false;
                store.dispatch(closeBanner());
            }

            if (action.type === Actions.AUTOSAVE_HIDE_POPUP) {
                scope.introDialogClosed = true;
                store.dispatch(savePreferencesAction({
                    [Lit.hideAutosavePopUp]: true,
                }));
            } else if ([PAGE_DATASET_SAVE_FAILURE, PAGE_DATASET_SAVE_SUCCESS].includes(action.type)) {
                scope.inProgress = false;
            } else if (action.type === SAVE_REQUEST) { // && action.payload.triggerAction === Actions.SAVE_VIA_AUTOSAVE) {
                scope.inProgress = true;
                if (scope.introDialogClosed === false) {
                    // Auto close the Autosave intro popup on first autosave
                    scope.introDialogClosed = true;
                    store.dispatch({ type: Actions.AUTOSAVE_HIDE_POPUP });
                }
            } else if (action.type === PUBLISH_REQUESTED) {
                return next(openDialog(PublishConfirmationDialogId, { publishViaAutosave: scope.canSave, }));
            } else if (action.type === OPEN_DIALOG && action.payload.dialogId === SiteDataSaveFailureDialogId) {
                // The last save failed and "Save failed", dialog is shown
                scope.saveFailedDialogOpen = true;
            } else if (action.type === CLOSE_DIALOG && scope.saveFailedDialogOpen === true) {
                // The last save failed and "Save failed" dialog shown is now closed
                scope.saveFailedDialogOpen = false;
                scope.scheduled = false;
            }

            if (scope.saveFailedDialogOpen === false && scope.inProgress === false) {
                const state = store.getState();

                if (scope.scheduled === false && state.workspaceSaveStatus === SaveStatus.CAN_SAVE) {
                    store.dispatch(createScheduledAction({
                        actionToDispatch: Actions.saveViaAutosave(),
                        timeout: scope.timeInterval,
                    }));
                    scope.canSave = true;
                    scope.scheduled = true;
                } else if (scope.scheduled === true && state.workspaceSaveStatus === SaveStatus.CAN_NOT_SAVE) {
                    store.dispatch(cancelScheduledAction(Actions.saveViaAutosave()));
                    scope.canSave = false;
                    scope.scheduled = false;
                }
            }
        }
    }

    return next(action);
};
