import React from 'react';
import { getDAL } from "../../../../dal/index";
import Desktop from './Desktop';
import Mobile from './Mobile';
import { BACKUP_MOBILE_PREVIEW_ORIENTATION_TOGGLE } from "../actionTypes";
import type { RightPanelProps } from '../flowTypes';
import styles from './Backup.css';

export default ({
    pageIdToLoad,
    timestamp,
    loading,
    isMobileView,
    mobileView,
    uiLocale,
    siteMap,
    dispatch
}: RightPanelProps) => {
    if (!loading) {
        // @ts-ignore
        const src = '' || (pageIdToLoad && getDAL().makePagePreviewUrl(pageIdToLoad, 'html', {
            uiLocale,
            previewbackup: true,
            time: timestamp
        }));

        if (isMobileView) {
            return (
                <Mobile
                    src={src}
                    preview={{ mobileView, template: {} }}
                    hasPages={!!src}
                    showMobileActivateOptions={false}
                    extraContainerClassName={styles.mobileViewContainer}
                    siteMap={siteMap}
                    onClickOrientation={() => dispatch({ type: BACKUP_MOBILE_PREVIEW_ORIENTATION_TOGGLE })}
                />
            );
        }
        return (<Desktop src={src} hasPages={!!src} />);
    }

    return (<div className={styles['desktop-container']} />);
};
