import React from "react";
import { BlogErrorButtonType, BlogErrorType, ErrorBackButton, OnboardingOption } from "../types";
import { BlogErrorTexts } from "../constants";
import { Msg, useIntl } from "../../../../../view/intl";
import styles from "../OnboardingWizard.css";
import { PrimaryButton, SecondaryButton } from "../../../../../view/common/Button";
import { BLOG_ERROR_RESET, BLOG_ONBOARDING_OPTION_UPDATE } from "../epic/actionTypes";

interface Props {
    /**
     * Which error to display,
     * the corresponding texts and buttons are defined in "../constants"
     */
    errorType: BlogErrorType;
    /**
     * Function of the Close button - usually closes the error and creates a blank blog without AI
     */
    onClose: SimpleFunction;
    /**
     * Function of the Try Again button - usually retries the blog creation
     */
    onTryAgain: SimpleFunction;
    /**
     * Props to manipulate the view state
     */
    stateProps: any;
    /**
     * Set this to true once the user has pressed try again and the retry text should be displayed
     */
    hasRetried?: boolean;
}

export const BlogOnboardingError = ({
    errorType,
    onClose,
    onTryAgain,
    stateProps,
    hasRetried = false
}: Props) => {
    const intl = useIntl();
    const { dispatch, setCurrentStep, createBlog } = stateProps;

    const currentErrorText = BlogErrorTexts[errorType];

    const backButtonsConfig = {
        [ErrorBackButton.startWithBlankBlog]: {
            label: (
                <Msg k="component.blog.onboarding.error.button.writeOwnPosts">Write own posts</Msg>
            ),
            action: () => {
                // change the selected option and create the blog
                dispatch({ type: BLOG_ONBOARDING_OPTION_UPDATE, payload: OnboardingOption.blank });
                createBlog();
            }
        },
        [ErrorBackButton.returnToPreviousStep]: {
            label: <Msg k="common.back">Back</Msg>,
            action: () => {
                // removing the blog error automatically shows the last step
                // since error reset is done for every back action, no further actions are needed here
            }
        },
        [ErrorBackButton.returnToInputStep]: {
            label: (
                <Msg k="component.blog.onboarding.error.button.returnToInputStep">
                    Rewrite details
                </Msg>
            ),
            action: () => {
                // go to the AI input step = step 0
                setCurrentStep(0);
            }
        }
    };

    const buttons = {
        [BlogErrorButtonType.back]: {
            props: {
                onClick: () => {
                    if (currentErrorText.backButtonType) {
                        // execute the action specified for this error type
                        backButtonsConfig[currentErrorText.backButtonType].action();
                    }

                    // reset the error so it is not shown anymore
                    dispatch({ type: BLOG_ERROR_RESET });
                },
                "data-testid": "back-button"
            },
            msg: currentErrorText.backButtonType
                ? backButtonsConfig[currentErrorText.backButtonType].label
                : backButtonsConfig[ErrorBackButton.returnToPreviousStep].label
        },
        [BlogErrorButtonType.close]: {
            props: { onClick: onClose, "data-testid": "close-button" },
            msg: <Msg k="common.close">Close</Msg>
        },
        [BlogErrorButtonType.tryAgain]: {
            props: { onClick: onTryAgain, "data-testid": "try-again-button" },
            msg: <Msg k="common.tryAgain">Try again</Msg>
        }
    };

    return (
        <div className={styles.errorContainer} data-testid="error-container">
            <div className={styles.image}>
                <img src={`/wbtgen/images/${currentErrorText.icon}`} />
            </div>
            <div>
                {currentErrorText.title && (
                    <div data-testid="error-title" className={styles.title}>
                        {intl.msgJoint(currentErrorText.title)}
                    </div>
                )}
                {hasRetried && currentErrorText.descriptionAfterRetry ? (
                    <div data-testid="error-retry-description" className={styles.description}>
                        {intl.msgJoint(currentErrorText.descriptionAfterRetry)}
                    </div>
                ) : (
                    <div data-testid="error-description" className={styles.description}>
                        {intl.msgJoint(currentErrorText.description)}
                    </div>
                )}
                {hasRetried && currentErrorText.retrySuffix && (
                    <div data-testid="error-retry" className={styles.description}>
                        {intl.msgJoint(currentErrorText.retrySuffix)}
                    </div>
                )}
            </div>
            <div>
                {hasRetried && currentErrorText.secondaryButtonAfterRetry ? (
                    <SecondaryButton
                        {...buttons[currentErrorText.secondaryButtonAfterRetry].props}
                        className={styles.wizardButton}
                        isLarge
                    >
                        {buttons[currentErrorText.secondaryButtonAfterRetry].msg}
                    </SecondaryButton>
                ) : (
                    <SecondaryButton
                        {...buttons[currentErrorText.secondaryButton].props}
                        className={styles.wizardButton}
                        isLarge
                    >
                        {buttons[currentErrorText.secondaryButton].msg}
                    </SecondaryButton>
                )}
                <PrimaryButton
                    {...buttons[currentErrorText.primaryButton].props}
                    className={styles.wizardButton}
                    isLarge
                >
                    {buttons[currentErrorText.primaryButton].msg}
                </PrimaryButton>
            </div>
        </div>
    );
};
