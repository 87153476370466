import {
    include,
    except,
    hasPadding
} from '../../helpers/index';
import {
    PARAGRAPH_STYLE,
    HEADER_STYLE_EXCLUDED
} from '../../parsers/constants';
import type {
    Element
} from '../../../flowTypes';

const includeParagraphStyles = include(PARAGRAPH_STYLE);
const exceptParagraphStyles = except(HEADER_STYLE_EXCLUDED);

export default (paragraphs: Array <Element>, styles: Array < Element >): Element[] => {
    return styles.map(style => {
        const paragraph = paragraphs.find(p => p.start === style.start && p.end === style.end);
        if (!paragraph || !style.globalName) {
            return style;
        }

        return {
            ...exceptParagraphStyles(style),
            ...includeParagraphStyles(paragraph),
            spacing: hasPadding(paragraph)
        } as Element; // NOSONAR
    });
};
