import { isString } from "../../../../../../utils/string.js";
import { FcContentTypes } from "../FcContentTypes";

export type FcContentTypePattern = string | Array<string>;

export const testFcContentType = (resourceContentType: string, contentTypePattern: FcContentTypePattern) => {
    if (isString(contentTypePattern)) {
        return new RegExp(contentTypePattern as string, 'i').test(resourceContentType);
    }
    for (const item of contentTypePattern) {
        if (new RegExp(item, 'i').test(resourceContentType)) {
            return true;
        }
    }
    return false;
};

export const testFcDirectoryContentType = (resourceContentType: string) =>
    testFcContentType(resourceContentType, FcContentTypes.DIRECTORY);

export const testFcImageContentType = (resourceContentType: string) =>
    testFcContentType(resourceContentType, FcContentTypes.IMAGE);

export const testFcVideoContentType = (resourceContentType: string) =>
    testFcContentType(resourceContentType, FcContentTypes.VIDEO);
