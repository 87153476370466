import { getAssetUrl } from '../../../utils/assetUtils';
import { memoMaxOne } from '../../../../utils/memo';
import {
    calcA,
    makeCaptionStyle,
    getStepedDimensions,
    getReduceFactor,
    resizeForRetinaFn,
    getCaptionFontStyle,
    makeCaptionStylePadding,
    getCaptionBoxBorderColorForTheme,
} from './utils';
import type {
    GalleryComponent,
    GalleryCalcRenderPropsResult,
    GalleryComponentDependsOn,
    GalleryImageRenderProps,
    GalleryComponentExtension,
    GalleryImage
} from './flowTypes';
import type { CalcRenderer } from '../../Workspace/epics/componentsProps/flowTypes';
import { IMAGE_PIXEL_RATIOS, IMAGE_RATIO_NUM_MAP, MinThumbnailSize, captionDefaults } from "./constants";
import { makeComponentBorderStyle } from "../../../mappers/border/index";
import makeStyle from "../Background/makeStyle";
import { IMAGE_RATIO } from "../../presentational/ImageRatioComboBox/constants";
import { CaptionStyles } from "../../presentational/CaptionStyle/constants";
import { trunc1 } from "../../../../utils/number";
import { replaceTagsWithContent } from '../Text/view/replaceTagsWithContent';
import { getThemeRulesForBackground } from '../../ThemeGlobalData/themeRules';
import { BACKGROUND_THEME_WHITE } from '../../ThemeGlobalData/constants';

type CalcMaxThumbnalDimensionParams = {
    width: number,
    columns: number,
    spacingPx: number,
    imageRatio: keyof typeof IMAGE_RATIO,
    images: Array<GalleryImage>,
};

type GalleryCalcRenderProps =
    CalcRenderer<GalleryComponent, GalleryComponentExtension, GalleryComponentDependsOn, GalleryCalcRenderPropsResult>

const
    makeUrl = function ({
        hdImages,
        crop,
        asset,
        containerWidth,
        containerHeight,
        compactView,
        scale = 1,
        isWorkspace = false,
    }) {
        let
            { width: finalContainerWidth, height: finalContainerHeight } = isWorkspace ?
                getStepedDimensions(containerWidth, containerHeight) :
                { width: containerWidth, height: containerHeight };

        const
            assetWidth = asset.width,
            assetHeight = asset.height,
            assetRatio = assetWidth / assetHeight,
            containerRatio = finalContainerWidth / finalContainerHeight,
            imageRationSameAsContainerRatio = assetRatio === containerRatio;

        let
            resizedWidth,
            resizedHeight,
            reduceFactor;

        if (crop) {
            // Determine orientation and which dimension to crop
            if (assetRatio > containerRatio) {
                resizedHeight = finalContainerHeight;
                resizedWidth = assetRatio * resizedHeight;
            } else if (assetRatio < containerRatio) {
                resizedWidth = finalContainerWidth;
                resizedHeight = resizedWidth / assetRatio;
            } else {
                resizedWidth = finalContainerWidth;
                resizedHeight = finalContainerHeight;
            }
        } else {
            resizedHeight = finalContainerHeight;
            resizedWidth = assetRatio * resizedHeight;
        }

        if (hdImages) {
            resizedWidth = resizedWidth * scale;
            resizedHeight = resizedHeight * scale;
            reduceFactor = getReduceFactor(resizedWidth, resizedHeight, assetWidth, assetHeight);
            resizedWidth = resizedWidth * reduceFactor;
            resizedHeight = resizedHeight * reduceFactor;
            finalContainerWidth = finalContainerWidth * scale * reduceFactor;
            finalContainerHeight = Math.round(finalContainerHeight * scale * reduceFactor);
        }

        const query: Record<string, any> = {
            // TODO: Ideally, we shouldn't need this (ignoreAspectRatio parameter). But, upscaling of gif images is not working as
            // expected with current gifsicle implementation. Since we are calculating the image resize dimensions based on required scaling,
            // it is fine to ignore the aspect ratio. This workaround does not seem to have any side effects.
            // We might want to remove this parameter in future when we get upscaling to work correctly.
            // Example of problematic request: /api/v1/example.com/webspace/onewebmedia/1x1.gif?sourceContentType=image%2Fgif&resize=160,160
            // This request doesn't upscale the image and return only a 1x1 px size image.
            // It can be fixed by: /api/v1/example.com/webspace/onewebmedia/1x1.gif?sourceContentType=image%2Fgif&resize=160,160&ignoreAspectRatio
            ignoreAspectRatio: true,
            resize: `${Math.round(resizedWidth)},${Math.round(resizedHeight)}`
        };

        if (compactView && !crop) {
            delete query.ignoreAspectRatio;
            query.withoutEnlargement = null;
        }

        if (crop && !imageRationSameAsContainerRatio) {
            const
                extractCenterX = Math.max(Math.floor((resizedWidth - finalContainerWidth) / 2), 0),
                extractCenterY = Math.max(Math.floor((resizedHeight - finalContainerHeight) / 2), 0);
            query.extract = `${extractCenterX},${extractCenterY},${Math.floor(finalContainerWidth)},${Math.floor(finalContainerHeight)}`; //eslint-disable-line
        }

        return getAssetUrl(asset, query);
    },
    getScaledImageUrl = (hdImages, crop, asset, containerDimensions, isWorkspace, compactView) => {
        const
            { width: containerWidth, height: containerHeight } = containerDimensions,
            { url1x, srcSet } =
                IMAGE_PIXEL_RATIOS.reduce((result: { url1x: string, srcSet: string[] }, pixelRatio) => {
                    const url = makeUrl({
                        hdImages,
                        crop,
                        asset,
                        containerWidth,
                        containerHeight,
                        isWorkspace,
                        compactView,
                        scale: pixelRatio,
                    });

                    if (pixelRatio === 1) {
                        result.url1x = url; // eslint-disable-line no-param-reassign
                    }

                    if (hdImages) {
                        result.srcSet.push(url + ' ' + pixelRatio + 'x');
                    }

                    return result;
                }, { url1x: '', srcSet: [] });

        return {
            src: url1x,
            srcSet: srcSet.length ? srcSet.join(', ') : ''
        };
    },
    getObjectFit = (crop, compactView) => {
        if (crop) {
            return 'cover';
        } else if (compactView) {
            return 'scale-down';
        }
        return 'contain';
    },
    getImageElDimension = (compactView, crop, asset, tWidth, tHeight) => {
        if (compactView) {
            if (crop) {
                return {
                    width: 'auto',
                    height: 'auto',
                };
            }
            if (asset.width <= tWidth && asset.height <= tHeight) {
                return {
                    width: asset.width,
                    height: asset.height,
                };
            }
            if (asset.width > asset.height) {
                return {
                    width: '100%',
                    height: 'auto'
                };
            }
            return {
                width: 'auto',
                height: '100%'
            };
        }
        if (!crop) {
            if (asset.width > asset.height) {
                return {
                    width: '100%',
                    height: 'auto'
                };
            }
            return {
                width: 'auto',
                height: '100%'
            };
        }
        return {
            width: '100%',
            height: '100%'
        };
    },
    calcImageProps =
        ({
            hdImages, image, maxThumbnailWidth, maxThumbnailHeight, compactView,
            onClickAction, componentId, index, col, row, spacingPx, captionsEnabled, captionsAlignment,
            isWorkspace, site, missingAssetUrls, captionTitleTextStyle, captionDescriptionTextStyle,
            imageStyle, crop, imageRatio, previewBackupTime, globalVariables, themeColorsData, themeSettingsData, selectedParentTheme
        }) => {
            const
                { asset } = image,
                isActualRatio = imageRatio === IMAGE_RATIO.ACTUAL_RATIO,
                thumbnailWidth = isActualRatio
                    ? trunc1(maxThumbnailHeight * (asset.width / asset.height))
                    : maxThumbnailWidth,
                thumbnailHeight = maxThumbnailHeight,
                retinaThumbSize = resizeForRetinaFn(thumbnailWidth, thumbnailHeight),
                { src, srcSet } = getScaledImageUrl(hdImages, crop, image.asset, retinaThumbSize, isWorkspace, compactView),
                imageAvailable = !!(image && image.asset && image.asset.url && missingAssetUrls &&
                    missingAssetUrls.indexOf(image.asset.url) === -1),
                isFirstRow = row === 1,
                isFirstCol = col === 1,
                offsetLeft = isFirstCol ? 0 : spacingPx,
                offsetTop = isFirstRow ? 0 : spacingPx,
                imageBorderStyle = makeComponentBorderStyle({
                    style: {
                        ...imageStyle,
                        ...(themeSettingsData.autoColorMode ? {
                            border: {
                                ...imageStyle.border,
                                color: (() => {
                                    let color;
                                    if (imageStyle.border && imageStyle.border.themeColor) {
                                        color = [...themeColorsData[imageStyle.border && imageStyle.border.themeColor]];
                                    } else {
                                        color = [...themeColorsData[getThemeRulesForBackground(selectedParentTheme, themeColorsData).text]];
                                    }
                                    if (imageStyle.border && imageStyle.border.color) {
                                        color[4] = imageStyle.border.color[4]; // opacity
                                    }
                                    return color;
                                })()
                            }
                        } : {})
                    }
                }),
                containerStyle = {
                    width: maxThumbnailWidth,
                    marginLeft: offsetLeft,
                    marginTop: offsetTop,
                    textAlign: captionsAlignment
                },
                imgStyle = {
                    /*width: compactView && !crop ? Math.min(thumbnailWidth, asset.width) : thumbnailWidth,
                    height: compactView && !crop ? Math.min(thumbnailHeight, asset.height) : thumbnailHeight,*/
                    ...getImageElDimension(compactView, crop, asset, thumbnailWidth, thumbnailHeight),
                    objectFit: getObjectFit(crop, compactView),
                },
                result: GalleryImageRenderProps = {
                    container: { style: containerStyle },
                    img: { src, srcSet, style: imgStyle },
                    imageAvailable,
                    divStyle: {
                        ...imageBorderStyle,
                        width: /*compactView ? 'auto' : */thumbnailWidth,
                        height: /*compactView ? 'auto' : */thumbnailHeight,
                        display: 'flex'
                    }
                };

            result.img.title = image.title;
            result.img.alt = image.title;

            if (captionsEnabled) {
                result.captionWrapperStyle = makeCaptionStyle(maxThumbnailWidth);
                result.title = {
                    text: replaceTagsWithContent(image.title, { globalVariables, site }),
                    props: {
                        style: captionTitleTextStyle,
                    }
                };
                result.caption = {
                    text: replaceTagsWithContent(image.caption, { globalVariables, site }),
                    props: {
                        style: captionDescriptionTextStyle
                    }
                };
            }

            result.isHeightGreaterThanWidth = image.asset.height > image.asset.width
                    || (imageRatio === IMAGE_RATIO.LANDSCAPE && image.asset.height === image.asset.width);

            const a = calcA({
                onClickAction,
                caption: image.title,
                componentId,
                index,
                image,
                previewBackupTime,
                globalVariables,
                site
            });

            if (a) result.a = a;

            return result;
        },
    getImagesMaxHeight = memoMaxOne((maxThumbnailWidth, images) => {
        const imagesHeights = images.map(({ asset }) => asset.height * (maxThumbnailWidth / asset.width));
        return Math.min(...imagesHeights);
    }),
    calcGalleryMaxThumbnailDimensions = (
        { width, columns, spacingPx, imageRatio, images }: CalcMaxThumbnalDimensionParams
    ) => {
        const
            imagesAvailableWidth = width - ((columns - 1) * spacingPx),
            maxThumbnailWidth = imagesAvailableWidth / columns,
            maxThumbnailWidthTrunc = trunc1(maxThumbnailWidth),
            ratio = IMAGE_RATIO_NUM_MAP[imageRatio] || IMAGE_RATIO_NUM_MAP.LANDSCAPE,
            thumbnailHeight = imageRatio === IMAGE_RATIO.ACTUAL_RATIO
                ? trunc1(getImagesMaxHeight(maxThumbnailWidth, images))
                : Math.max(trunc1(maxThumbnailWidth / ratio), MinThumbnailSize.height);
        return {
            maxThumbnailWidth: Math.max(maxThumbnailWidthTrunc, MinThumbnailSize.width),
            maxThumbnailHeight: thumbnailHeight
        };
    },
    calcWorkspaceWidthWithMargin = (margin: number = 0, maxWidth: number, workspaceWidth: number, templateWidth: number) => {
        return Math.max(
            Math.min(workspaceWidth - (workspaceWidth * ((margin * 2) / 100)), maxWidth),
            templateWidth
        );
    },
    calcRenderProps: GalleryCalcRenderProps = ({
        componentId,
        component,
        componentExtension,
        componentDependencies: {
            hdImages,
            site,
            missingAssetUrls,
            globalStyles,
            globalVariables,
            themeColorsData,
            themeSettingsData,
            workspaceBBoxWidth,
            templateWidth
        },
        previewBackupTime,
        isWorkspace,
        selectedParentTheme
    }) => {
        const
            {
                columns,
                spacingPx,
                captionsAlignment,
                images,
                crop,
                captionsEnabled,
                onClickAction,
                imageStyle = {},
                captionMinHeight,
                id,
                captionBoxStyle = {},
                imageRatio,
                captionStyle,
                previousCaptionStyle,
                mobileSettings,
                stretch,
                fullWidthOption,
                width,
                compactView
            } = component,
            boxStyle = captionStyle === CaptionStyles.CUSTOM
                ? { ...captionDefaults.box[previousCaptionStyle], ...captionBoxStyle }
                : { ...captionDefaults.box[captionStyle] },
            captionBoxBGBorderStyle = makeStyle(
                { ...component, style: { ...boxStyle } },
                themeSettingsData.autoColorMode ? {
                    backgroundColorFromTheme: (() => {
                        if (captionStyle === CaptionStyles.CUSTOM) {
                            if (
                                captionBoxStyle.background
                                && captionBoxStyle.background.colorData
                                && captionBoxStyle.background.colorData.themeColor
                            ) {
                                return themeColorsData[captionBoxStyle.background.colorData.themeColor];
                            } else if (previousCaptionStyle === CaptionStyles.CLASSIC) {
                                // @ts-ignore
                                return themeColorsData[captionDefaults.box[CaptionStyles.CLASSIC].background?.colorData.themeColor];
                            } else {
                                return null;
                            }
                        } else if (captionStyle === CaptionStyles.TEMPLATE) {
                            return null;
                        } else { // CaptionStyles.CLASSIC
                            // @ts-ignore
                            return themeColorsData[captionDefaults.box[CaptionStyles.CLASSIC].background?.colorData.themeColor];
                        }
                    })(),
                    borderColorFromTheme: themeColorsData[
                        // @ts-ignore
                        getCaptionBoxBorderColorForTheme(
                            // @ts-ignore
                            captionStyle, previousCaptionStyle, captionBoxStyle.border, selectedParentTheme, themeColorsData
                        )
                    ],
                } : {}
            ),
            captionBoxPaddingStyle = makeCaptionStylePadding({ ...boxStyle, background: null /*since we are only interested in padding*/ }),
            captionBoxStyles = {
                ...captionBoxBGBorderStyle,
                ...captionBoxPaddingStyle
            },
            { captionTitleTextStyle, captionDescriptionTextStyle } = getCaptionFontStyle(
                // @ts-ignore
                component, globalStyles, themeColorsData, themeSettingsData.autoColorMode, selectedParentTheme
            ),
            { maxThumbnailWidth, maxThumbnailHeight } = calcGalleryMaxThumbnailDimensions({
                ...component,
                width: stretch && workspaceBBoxWidth ?
                    calcWorkspaceWidthWithMargin(fullWidthOption.margin, fullWidthOption.maxWidth, workspaceBBoxWidth, templateWidth)
                    : width
            }),
            imagesProps = images.map((image, index) => calcImageProps({
                hdImages,
                crop,
                image,
                maxThumbnailWidth,
                maxThumbnailHeight,
                spacingPx,
                captionsEnabled,
                col: 1 + (index % columns),
                row: 1 + Math.floor(index / columns),
                index,
                captionsAlignment,
                onClickAction,
                isWorkspace,
                componentId,
                site,
                previewBackupTime,
                missingAssetUrls,
                imageStyle,
                captionTitleTextStyle,
                captionDescriptionTextStyle,
                imageRatio,
                globalVariables,
                themeColorsData,
                themeSettingsData,
                selectedParentTheme,
                compactView
            })),
            componentExtensionCaptionPx = componentExtension ? componentExtension.highestCaptionPx : 0;

        return {
            componentId: id,
            captionsEnabled,
            images: imagesProps,
            captionBoxStyles,
            captionMinHeight: compactView ? null : captionMinHeight,
            crop,
            mobileSettings,
            captionsContainerHeight: compactView ? null : componentExtensionCaptionPx,
            selectedParentTheme: selectedParentTheme || BACKGROUND_THEME_WHITE,
            themeColorsData,
            stretch,
            fullWidthOption,
            spacingPx,
            columns,
            imageRatio,
            width,
            templateWidth,
            compactView
        };
    };

export default calcRenderProps;

export {
    calcGalleryMaxThumbnailDimensions,
    calcWorkspaceWidthWithMargin,
};
