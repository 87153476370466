
import * as React from "react";
import * as styles from './supportPopup.css';
import * as Actions from "../actions";
import type { SupportView } from "../flowTypes";
import { Separator } from "../../view/Separator";
import { Flex } from "../../../../view/reflexbox/index";

export default ({ support, dispatch }: SupportView) => {
    if ((window && window.location.href.indexOf('setSupportOn') === -1) && (!support || !support.show)) {
        return null;
    }

    const [groupTextMinGap, setGroupTextMinGap] = React.useState(30);
    const onGroupTextMinGapChange = (e) => {
        e.stopPropagation();
        e.preventDefault();
        if (e.target.value !== groupTextMinGap) {
            setGroupTextMinGap(e.target.value);
        }
    };

    const Support = [
        <Flex
            justify="space-between"
            key={1}
            className={`${styles.item} ${styles.support}`}
            onClick={() => dispatch({ type: Actions.OPEN_SUPPORT_TOOL })}
        >
            <span>Support</span>
            <Separator />
        </Flex>
    ];

    if (support?.showPopUp) {
        Support.push(
            <div key={2} className={styles.container}>
                <Flex
                    align="center"
                    justify="center"
                    className={styles.close}
                    onClick={() => dispatch({ type: Actions.CLOSE_SUPPORT_TOOL })}
                >
                    X
                </Flex>
                <div
                    className={styles.item}
                    onClick={() => dispatch({ type: Actions.SEPARATE_FOOTER_BY_FOOTER_TMP_CMPS })}
                >
                    Separate footer template components in to footer
                </div>
                <div
                    className={styles.item}
                    style={{ marginTop: 20 }}
                    onClick={() => dispatch({ type: Actions.REMOVE_EMPTY_GAPS })}
                >
                    Remove large empty gaps - This would take few secs to reflect changes
                </div>
                <div
                    className={styles.item}
                    style={{ marginTop: 20 }}
                    onClick={() => dispatch({ type: Actions.REMOVE_EMPTY_STRIPS_OVERLAPPING_WITH_HEADER })}
                >
                    Remove page strips overlapping with header(only strip not its content) - Save and refresh after this
                </div>
                <div
                    className={styles.item}
                    style={{ marginTop: 20 }}
                    onClick={() => dispatch({ type: Actions.SUPPORT_DELETE_SELECTED_SECTION })}
                >
                    Delete selected section
                </div>
                {window.location.href.indexOf('SetTopsAlso') !== -1 &&
                <div
                    className={styles.item}
                    style={{ marginTop: 20 }}
                    onClick={() => dispatch({ type: Actions.SUPPORT_FIX_TOPS })}
                >
                    Fix tops
                </div>}
                <div
                    className={styles.item}
                    style={{ marginTop: 20 }}
                    onClick={() => dispatch({ type: Actions.SUPPORT_DELETE_SELECTED_CONTAINER_ONLY })}
                >
                    Delete selected container only(strip and box)
                </div>
                <div
                    className={styles.item}
                    style={{ marginTop: 20 }}
                    onClick={() => dispatch({ type: Actions.DELETE_STRIP_AND_ADD_BACKGROUND_TO_SECTION })}
                >
                    Delete selected strip and add background style to section
                    <div>
                        <sub>(Applies only if strip is the only child of section)</sub>
                    </div>
                </div>
                <div
                    className={styles.item}
                    style={{ marginTop: 20 }}
                    onClick={() => dispatch({ type: Actions.ADD_BOXES_TO_PAGE_SECTION })}
                >
                    Add boxes to the components in pages section
                    <div>
                        <sub>(Applies only if page section has one box with box top same as section top)</sub>
                    </div>
                </div>
                <div
                    className={styles.item}
                    style={{ marginTop: 20 }}
                    onClick={() => dispatch({ type: Actions.GROUP_TEXT_COMPONENTS_INTO_ONE, payload: groupTextMinGap })}
                >
                    Group text components into one
                    <div>
                        <sub>
                            <input
                                type="number"
                                value={groupTextMinGap}
                                onChange={onGroupTextMinGapChange}
                                onClick={onGroupTextMinGapChange}
                            />
                            <span> px gap between text components to not group</span>
                        </sub>
                    </div>
                </div>

                <div
                    className={styles.item}
                    style={{ marginTop: 20 }}
                    onClick={() => dispatch({ type: Actions.WRAP_IMG_TEXT_IN_BOX })}
                >
                    Wrap images and text in box (grouped by top)
                </div>
            </div>
        );
    }

    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <React.Fragment>{Support}</React.Fragment>;
};
