import { DataSite } from "../../../../../../dal/model/index";
import * as editorCommands from './editorCommands';
import { isBold } from "./methods/state/isBold";
import { isItalic } from "./methods/state/isItalic";
import { queryCommandState } from "./methods/helpers/queryCommandState";
import { getHighLightColor } from "./methods/getters/getHighLightColor";
import {
    getBlurRadius,
    getShadowOffsetX, getShadowOffsetY,
    getTextShadow
} from "./methods/getters/getShadow";
import { getAlignment } from "./methods/getters/getAlignment";
import {
    isSubscript,
    isSuperscript
} from "./methods/state/isVerticalAlign";
import { getFontSize } from "./methods/getters/getFontSize";
import { getFontFamily } from "./methods/getters/getFontFamily";
import { getPadding } from "./methods/getters/getPadding";
import { getLinkAction } from "./methods/getters/getLinkAction";
import { canSetLink } from "./methods/getters/canSetLink";
import { getSelectedGlobalStyle } from "./methods/getters/getSelectedGlobalStyle";
import { getSelectedLinkGlobalStyle } from "./methods/getters/getSelectedLinkGlobalStyle";
import { getSelectedLineHeight } from "./methods/getters/getSelectedLineHeight";
import { getSelectedCharacterSpacing } from "./methods/getters/getSelectedCharacterSpacing";
import { getSelectedText } from "./methods/getters/getSelectedText";
import { isLinkable } from "./methods/state/isLinkable";
import { isLinkBroken } from "./methods/state/isLinkBroken";
import { getColor } from "./methods/getters/getColor";
import { canBeIndented } from "./methods/getters/canBeIndented";
import type { Stylesheets } from '../../../../Workspace/epics/stylesheets/flowTypes';
import { type TinyMceEditor, type TinyMceEpicState } from "../flowTypes";
import { linkedValueAttr } from "../../../../oneweb/Text/constants";

type Props = {
    autoColorMode: boolean;
    editor: TinyMceEditor;
    site: DataSite;
    stylesheets: Stylesheets;
    editorWidth: number;
    linkedNode?: HTMLElement | null;
};

const getNodeBottomPosition = (node: HTMLElement) => {
    // $FlowFixMe
    const { bottom, left } = node.getBoundingClientRect();

    return { left, top: bottom };
};

export const getEditorState =
    ({ editor, stylesheets, editorWidth, linkedNode, autoColorMode }: Props, limitToOneContentBlock: boolean): TinyMceEpicState => {
        const linkAction = getLinkAction(editor, limitToOneContentBlock);
        return {
            bold: isBold(editor),
            italic: isItalic(editor),
            underline: queryCommandState(editor, editorCommands.UNDERLINE),
            color: getColor(editor),
            highLightColor: getHighLightColor(editor),
            shadowColor: getTextShadow(editor),
            shadowOffsetX: getShadowOffsetX(editor),
            shadowOffsetY: getShadowOffsetY(editor),
            blurRadius: getBlurRadius(editor),
            alignment: getAlignment(editor),
            superscript: isSuperscript(editor),
            subscript: isSubscript(editor),
            fontSize: getFontSize(editor),
            fontFamily: getFontFamily(editor),
            spacing: getPadding(editor),
            unorderedList: queryCommandState(editor, editorCommands.UNORDERED_LIST),
            orderedList: queryCommandState(editor, editorCommands.ORDERED_LIST),
            linkAction,
            canSetLink: !!linkAction || canSetLink(editor),
            selectedGlobalStyle: getSelectedGlobalStyle(editor),
            selectedLinkGlobalStyle: getSelectedLinkGlobalStyle(editor, stylesheets, autoColorMode),
            lineHeight: getSelectedLineHeight(editor),
            characterSpacing: getSelectedCharacterSpacing(editor),
            selectedText: getSelectedText(editor),
            isLinkable: isLinkable(editor),
            isLinkBroken: isLinkBroken(editor, limitToOneContentBlock),
            isIndented: queryCommandState(editor, editorCommands.OUTDENT),
            canBeIndented: canBeIndented(editor, editorWidth),
            tinyMceExist: true,
            selectedLinkedNode: linkedNode ? {
                bottomPosition: getNodeBottomPosition(linkedNode),
                // $FlowFixMe
                fieldName: linkedNode.getAttribute(linkedValueAttr)
            } : null
        };
    };
