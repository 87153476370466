import AppConfig from "../../utils/AppConfig";
import { getAppConfig } from "../App/epics/appConfig/appConfig.js";

const
    GOOGLE_RECAPTCHA_TITLE = "msg: components.googlerecaptcha.dialog.title {One more thing}",
    GOOGLE_RECAPTCHA_SUB_TITLE = "msg: components.googlerecaptcha.dialog.subtitle {Let us know you are human before we take you to Website Builder}", // eslint-disable-line max-len
    appConfig = AppConfig(getAppConfig()),
    GoogleReCaptchaConfig = appConfig.get('oneWeb.trial.googleReCaptcha'),
    GoogleReCaptchaEnabled = GoogleReCaptchaConfig.get('enabled'),
    GoogleReCaptchaClientKey = GoogleReCaptchaConfig.get('secrets.clientKey');

export {
    GOOGLE_RECAPTCHA_TITLE,
    GOOGLE_RECAPTCHA_SUB_TITLE,
    GoogleReCaptchaConfig,
    GoogleReCaptchaEnabled,
    GoogleReCaptchaClientKey
};
