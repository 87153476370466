import React from "react";
import styles from "../../openingHours.css";
import pagesTitles from "./titles";
import * as pagesIds from "./ids";
import Page from "../../../../PropertiesPanel/view/PropertiesPage";
import NavigationGroup from "../../../../PropertiesPanel/view/IntlNavigationGroup";
import PropertiesButton from "../../../../../view/common/Button/PropertiesButton";
import VerticalSpacer from "../../../../../view/common/VerticalSpacer";
import HorizontalSpacer from "../../../../../view/common/HorizontalSpacer";
import Icons from "../../../../../view/Icons/index";
import { injectIntl } from "../../../../../view/intl/index";
import { openSiteSettingsDialog } from "../../../../SiteSettings/SiteSettingsDialog/actionCreators";
import { SiteSettingsTabName } from "../../../../SiteSettings/SiteSettingsDialog/constants";
import { MOBILE_VIEW } from "../../../../PropertiesPanel/view/MobileView/constants";

export const OPENING_HOURS_DIV_ID = "opening-hours";
const id = pagesIds.MAIN,
    title = pagesTitles[pagesIds.MAIN],
    view = injectIntl(props => {
        const {
            globalVariables: { openingHours },
            navigateToPage,
            dispatch,
            intl
        } = props;

        const makeBtn = text => (
            <PropertiesButton
                text={text}
                onClick={() => {
                    dispatch(
                        openSiteSettingsDialog({
                            activeTabKey: SiteSettingsTabName.GENERAL,
                            activateDivWithId: OPENING_HOURS_DIV_ID
                        })
                    );
                }}
            />
        );
        const isOpeningHoursSet = openingHours && Object.keys(openingHours).length;

        if (isOpeningHoursSet) {
            return (
                <Page>
                    <VerticalSpacer y={15} />
                    <div className={styles.mainPageIntroText}>
                        {intl.msgJoint(
                            "msg: component.openingHours.mainPageIntroText {This component needs opening hours stored in website settings.}"
                        )}
                    </div>
                    <VerticalSpacer y={18.5} />
                    {makeBtn("msg: component.openingHours.updateOpeningHours {Update opening hours}")}
                    <VerticalSpacer y={21.5} />
                    <NavigationGroup
                        navigateToPage={navigateToPage}
                        pagesTitles={pagesTitles}
                        targetPagesIds={[pagesIds.FORMAT, pagesIds.STYLE, MOBILE_VIEW]}
                    />
                </Page>
            );
        }

        return (
            <Page>
                <VerticalSpacer y={15} />
                <div className={styles.mainPageIntroText}>
                    {intl.msgJoint(
                        "msg: component.openingHours.mainPageIntroText {This component needs opening hours stored in website settings.}"
                    )}
                </div>
                <VerticalSpacer y={18.5} />
                {makeBtn("msg: component.openingHours.addOpeningHours {Add opening hours}")}
                <VerticalSpacer y={21.5} />
                <div className={styles.mainPageFooterText}>
                    <div>
                        <VerticalSpacer y={4} />
                        <Icons.HINT_LARGE
                            style={{
                                height: 22,
                                width: 22,
                                fill: "#262626"
                            }}
                        />
                    </div>
                    <HorizontalSpacer x={12} />
                    {intl.msgJoint(
                        "msg: component.openingHours.mainPageFooterText {You will get more styling options as soon as you have added the hours.}" //eslint-disable-line max-len
                    )}
                </div>
            </Page>
        );
    });
export { id, view, title };
