import React from "react";
import Measure from 'react-measure';
import type { ContactFormUIProps } from './flowTypes';
import BaseComponent from '../../../../view/oneweb/BaseComponent';
import View from './index';
import styles from './ContactForm.css';
import { MinWidth, MinWidthWithCaptcha, MinWidthWithCaptchaES } from '../constants';
import { addComponentAdjustmentDataEntry } from "../../../Workspace/epics/componentsEval/adjustmentDataDispatchCache";

class ContactForm extends React.Component<ContactFormUIProps> {
    constructor(props: ContactFormUIProps) {
        super(props);
        this.onResize = this.onResize.bind(this);
    }

    onResize({ offset: { height } }: MeasureBounds) {
        const getWidth = ({ isCaptchaEnabled, captchaLang }) => {
            if (isCaptchaEnabled) {
                return captchaLang === "es" ? MinWidthWithCaptchaES : MinWidthWithCaptcha;
            }
            return MinWidth;
        };

        const width = getWidth(this.props.component);

        const minDimensions = {
            width,
            height: Math.round(height)
        };

        addComponentAdjustmentDataEntry(this.props.component.id, { minDimensions: minDimensions });
    }

    render() {
        const viewProps = { ...this.props, showMask: true, isWorkspace: true };

        return (
            <BaseComponent {...this.props}>
                <Measure offset onResize={this.onResize}>
                    {({ measureRef }) => (
                        <View {...viewProps} measureRef={measureRef} styles={styles} />
                    )}
                </Measure>
            </BaseComponent>
        );
    }
}

export default ContactForm;
