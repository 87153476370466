/* eslint-disable max-len */
import * as object from "../../../utils/object.js";

const excludeKeys = ['rev', 'time'];

export default (model: Record<string, any>, patch: undefined | Record<string, any> = {}, byPassRedundantKeys: boolean = false) => {
    // 'excludeKeys' fields will create couchdb conflict if posted
    // and 'private' fields should not be posted as well
    const newModel = object.filter(model, (_, key) => {
        return excludeKeys.indexOf(key) === -1 && key.indexOf('_') !== 0;
    });

    // merge patch
    const patchKeys = Object.keys(patch);
    if (patchKeys.length) {
        const
            modelKeys = Object.keys(newModel),
            diff = patchKeys.filter(k => modelKeys.indexOf(k) === -1);
        if (diff.length && !byPassRedundantKeys) throw new Error(`Redundant patch keys: ${diff.join(',')}`);

        patchKeys.forEach(k => { newModel[k] = patch[k]; });
    }

    return newModel;
};
