const crypto = require('crypto');

const CodeComponentType = 'web.data.components.Code';
const ShopComponentType = 'web.data.components.WebShop';
const GoogleReviewsComponentType = 'GOOGLE_REVIEWS'
const ProductWidget = 'PRODUCT_WIDGET';
const FeaturedProducts = 'web.data.components.FeaturedProducts';

const standardFeaturesList = [
    'web.data.components.VideoFile'
];

const premiumFeaturesList = [
    'web.data.components.InstagramGallery',
    'web.data.components.FacebookFeedGallery',
    'WIDGETS_TWITTER',
    'WIDGETS_INSTAGRAM',
    'INSTAGRAM_GALLERY',
    'WIDGETS_FACEBOOK',
    'WIDGETS_VIMEO',
    'WIDGETS_SPOTIFY',
    'WIDGETS_SOUNDCLOUD',
    'WIDGETS_PINTEREST',
    'WIDGETS_TIKTOK',
    'WIDGETS_EVENTBRITE',
    'WIDGETS_GOFUNDME',
    'WIDGETS_MAILCHIMP',
    'WIDGETS_PAYPAL',
    CodeComponentType,
    "BLOG"
];

const ecommerceFeaturesList = [
    ShopComponentType,
    FeaturedProducts,
    ProductWidget,
    GoogleReviewsComponentType,
    'FACEBOOK_CHAT',
    'WIDGETS_BOOKINGS'
];

const getStandardFeaturesList = () => {
    return standardFeaturesList;
}

const getPremiumFeaturesList = () => {
    return premiumFeaturesList;
}

const getEcommerceFeaturesList = () => {
    return ecommerceFeaturesList;
}

const getHashOfFeaturesList = (featuresList) => {
    const salt = '_JUST_RANDOM_PREMIUM_SALT_TEXT',
        stringToGenerateHash = featuresList + salt;

    const shasum = crypto.createHash('sha1');

    shasum.update(stringToGenerateHash);
    const hash = shasum.digest('hex');

    return hash;
}

const getHashOfPaidFeaturesList = () =>
    getHashOfFeaturesList(JSON.stringify({
        standard: standardFeaturesList,
        premium: premiumFeaturesList
    }))


const isEcommerceFeatureActiveInSiteSetting = (/*siteSettings*/) => {
    let isActive = false;
    return isActive;
};

const checkComponentsUsage = (featuresList) => ({ pages, templates: templatesData, publicPages }) => {
    const
        publicPageIds = publicPages.map(page => page.pageId),
        publicPagesTemplateIds = publicPages.map(page => page.templateId),
        pagesData = Object.keys(pages).filter(pageId => publicPageIds.includes(pageId)).reduce((pagesData, pageId) => {
            return {
                ...pagesData,
                [pageId]: pages[pageId]
            }
        }, {}),
        templatesDataFiltered = Object
            .keys(templatesData)
            .filter(templateId => publicPagesTemplateIds.includes(templateId))
            .reduce((acc, k) => {
                return { ...acc, [k]: templatesData[k] }
            }, {});

    let nonStandardFeaturesOfDomain = new Set();

    Object.values({
        ...pagesData,
        ...templatesDataFiltered,
    }).forEach(function(value) {
        value.forEach(item => nonStandardFeaturesOfDomain.add(item));
    });

    nonStandardFeaturesOfDomain = Array.from(nonStandardFeaturesOfDomain);

    // Drop Shop related features for WBTGEN-24996.
    const updatedFeaturesList = featuresList.filter(feature => feature !== ShopComponentType && feature !== ProductWidget && feature !== FeaturedProducts);
    return nonStandardFeaturesOfDomain.some(
        nonStandardFeature => updatedFeaturesList.includes(nonStandardFeature)
    );
};

const checkIncompatibleComponents = checkComponentsUsage([...standardFeaturesList, ...premiumFeaturesList, ...ecommerceFeaturesList]);
const isStandardComponentsUsed = checkComponentsUsage(standardFeaturesList);
const isPremiumComponentUsed = checkComponentsUsage(premiumFeaturesList);
const isEcommerceComponentUsed = checkComponentsUsage(ecommerceFeaturesList);

module.exports = {
    standardFeaturesList,
    premiumFeaturesList,
    ecommerceFeaturesList,
    getStandardFeaturesList,
    getPremiumFeaturesList,
    getEcommerceFeaturesList,
    getHashOfPaidFeaturesList,
    checkIncompatibleComponents,
    isStandardComponentsUsed,
    isPremiumComponentUsed,
    isEcommerceComponentUsed,
    isEcommerceFeatureActiveInSiteSetting
}
