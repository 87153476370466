import { take, put, takeLatest } from 'redux-saga/effects'; // eslint-disable-line n/file-extension-in-import
import Resource from "../Resource";
import openDialogGen from "../../../../../utils/saga/openDialogGen";
import {
    FileChooserDeleteConfirmationDialog,
    FileChooserDeleteErrorDialog
} from "../../../../../view/common/FileChooser/dialogIds";
import {
    FC_DELETE_RESOURCE,
    FC_DELETE_CONFIRM,
    FC_DELETE_CANCEL,
    FC_DELETE_SUCCESS,
    FC_DELETE_FAILURE,
    FC_DELETE_RETRY,
} from "../actionTypes";
import { raceGen, closeDialogGen } from "../../../../../utils/saga/index";
import reloadResourcesSaga from "./reloadResourcesSaga";
import { deleteWebspaceResourceApiAction } from '../actionCreators/deleteWebspaceResourceApiAction';

const doDelete = function* (resource: Resource): Generator<any, any, any> {
    const resourcePath = resource.isDirectory() ? resource.getWebspacePath().replace(/\/?$/, '/')
        : resource.getWebspacePath();
    // @ts-ignore
    yield put(deleteWebspaceResourceApiAction({
        webSpacePath: resourcePath,
        recursive: resource.isDirectory(),
    }));

    const { success } = yield* raceGen({
        success: take(FC_DELETE_SUCCESS),
        failure: take(FC_DELETE_FAILURE)
    });
    if (success) {
        yield* reloadResourcesSaga();
    } else {
        // retry logic
        yield* openDialogGen(FileChooserDeleteErrorDialog, { resource });
        const { retry } = yield* raceGen({
            retry: take(FC_DELETE_RETRY),
            cancel: take(FC_DELETE_CANCEL)
        });
        yield* closeDialogGen();
        if (retry) {
            yield* doDelete(resource);
        }
    }
};

export const deleteResourceSaga = function* (): Generator<any, any, any> {
    yield takeLatest(FC_DELETE_RESOURCE, function* (action: Action<any>) {
        const { resource } = action.payload;
        // open delete confirmation dialog
        yield* openDialogGen(FileChooserDeleteConfirmationDialog, { resource });
        const { confirmed } = yield* raceGen({
            confirmed: take(FC_DELETE_CONFIRM),
            cancelled: take(FC_DELETE_CANCEL),
        });
        // close delete confirmation dialog
        yield* closeDialogGen();
        if (confirmed) {
            yield* doDelete(resource);
        }
    });
};
