import * as React from 'react';
import { omit } from "ramda";
import workspace, { ImageSliderView } from './view/workspace';
import reducer from './reducer/index';
import propertiesPanel from './propertiesPanel/index';
import calcRenderProps from './calcRenderProps';
import adjustmentHookConfig from './adjustmentHookConfig';
import kind from './kind';
import siteDataEpic from '../../App/epics/siteData/index';
import componentMainActions from './componentMainActions/index';
import { missingAssetUrls } from '../../MissingAssets/epic/index';
import { isPremiumUserReducer } from "../../App/epics/subscriptionData/index";
import { IMAGE_SLIDER_SET_IMAGES_ON_COMPONENT_DROP } from "./actionTypes";
import { message } from "../Gallery/constants/index";
import type { ImageSliderComponent, ImageSliderComponentDependsOn } from "./flowTypes";
import { widthReducer as templateWidth } from "../Template/epics/template/index";
import {
    DEFAULT_MULTI_SELECT_LIMIT,
    DEFAULT_MULTI_SELECT_VALIDATION_TITLE,
} from "../../../view/common/FileChooser/constants";
import { makeImageChooserConfigDialog } from "../../FileManager/imageChooser/makeImageChooserConfigDialog";
import stylesheetsIdToNameMapEpic from '../../Workspace/epics/stylesheets/idToNameMap';
import stylesheetsEpic from "../../Workspace/epics/stylesheets/index";
import { ComponentNames } from "../constants";
import Icons from '../../../view/Icons/index';
import { minSliderDimensions } from "./constants";
import PropsTypeMap from "./sliderTypes";
import { globalVariablesEpic } from "../../App/epics/globalVariablesEpic";
import { replaceTagsWithContent } from "../Text/view/replaceTagsWithContent";

import type { ChangeOldComponentDataBeforeImportProps } from "../../../view/oneweb/flowTypes";
import { workspaceLeftReducer, workspaceWidthReducer } from "../../Workspace/epics/workspaceBBox/selectors";

export default {
    workspace,
    kind,
    label: ComponentNames[kind],
    shortcutIconName: 'slider',
    requireConfigurationOnDrop: () => true,
    configurationDialog: makeImageChooserConfigDialog({
        onSaveAction: IMAGE_SLIDER_SET_IMAGES_ON_COMPONENT_DROP,
        isMultiSelect: true,
        hideMyVideosTab: true,
        maxMultiSelectValidation: {
            title: DEFAULT_MULTI_SELECT_VALIDATION_TITLE,
            label: 'msg: component.imageSlider.label {Image slider}',
            limit: DEFAULT_MULTI_SELECT_LIMIT,
            message,
            remaniningLimit: DEFAULT_MULTI_SELECT_LIMIT
        },
    }),
    propertiesPanel,
    adjustmentHookConfig,
    calcRenderProps,
    componentMainActions,
    reducer,
    adjustComponentOnAdd: (
        defaultState: ImageSliderComponent & { type: string },
        { templateWidth: width }: ImageSliderComponentDependsOn
    ): ImageSliderComponent => {
        const type = defaultState.type;
        const specificPropsForType = PropsTypeMap[type] || {};

        return {
            ...omit(['type'], defaultState),
            width,
            left: 0,
            height: width * (1 / 2),
            ...specificPropsForType,
            fullWidthOption: {
                ...defaultState.fullWidthOption,
                originalWidth: width,
                originalLeft: 0
            }
        };
    },
    dependsOn: {
        hdImages: isPremiumUserReducer,
        site: siteDataEpic.reducer,
        missingAssetUrls,
        stylesheetsIdToNameMap: stylesheetsIdToNameMapEpic.reducer,
        globalStyles: stylesheetsEpic.reducer,
        globalVariables: globalVariablesEpic.reducer,
        templateWidth,
        workspaceBBoxWidth: workspaceWidthReducer,
        workspaceBBoxLeft: workspaceLeftReducer,
    },
    minDimensions: minSliderDimensions,
    mobileEditorConfig: {
        kind,
        view: ImageSliderView,
        calcProps: calcRenderProps,
        getHiddenElementData: () => {
            const Icon = Icons.SLIDER;
            return { icon: <Icon /> };
        }
    },
    changeOldComponentDataBeforeImport:
        ({ component, globalVariables, site, }: ChangeOldComponentDataBeforeImportProps<ImageSliderComponent>) => ({
            ...component,
            slides: component.slides.map(slide => ({
                ...slide,
                title: replaceTagsWithContent(slide.title, { globalVariables, site }),
                caption: replaceTagsWithContent(slide.caption, { globalVariables, site }),
                altText: replaceTagsWithContent(slide.altText, { globalVariables, site }),
            }))
        }),
};
