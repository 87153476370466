import { EventsRegistryType } from "../../EventTracking/types";
import { PAGE_TREE_PAGE_DELETE_CONFIRM } from "../../PagesTree/Tree/actionTypes";
import { BOOKINGS_TRY_NOW_CLICKED } from "./actionTypes";
import { CREATE_BOOKINGS_PAGE } from "./expandedPanel/epic/actionTypes";

const BookingsSourceTypes = {
    tryItOut: 'Bookings tooltip in editor - try it out',
    getStarted: 'Bookings inserter - get started',
    addPage: 'Booking component in workspace'
};

const bookingsPageType = 'Book now';

const BookingsProps = {
    source: 'Source',
    pageType: 'pageType'
};

const BookingsEvents = {
    bookingsPromo: 'WSB Bookings promo clicked',
    bookingsPageDeleted: 'WSB Bookings page deleted'
};

const BookingsMixpanelEvents: EventsRegistryType = {
    [BOOKINGS_TRY_NOW_CLICKED]: {
        eventName: BookingsEvents.bookingsPromo,
        propsHandler: () => ({ [BookingsProps.source]: BookingsSourceTypes.tryItOut })
    },
    [CREATE_BOOKINGS_PAGE]: {
        eventName: BookingsEvents.bookingsPromo,
        propsHandler: (_, { source }) => ({ [BookingsProps.source]: source })
    },
    [PAGE_TREE_PAGE_DELETE_CONFIRM]: {
        eventName: BookingsEvents.bookingsPageDeleted,
        isDisabled: (_, payload) => !payload || payload.pageType !== bookingsPageType,
        propsHandler: () => ({ [BookingsProps.pageType]: bookingsPageType })
    }
};

export {
    BookingsMixpanelEvents,
    BookingsSourceTypes,
    bookingsPageType,
};
