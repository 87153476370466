import { receiveOnly } from "../../../../epics/makeCondition";
import { AppState } from "../../../../redux/modules/flowTypes";
import valueActionType from "./valueActionType";

const helpBtnClientRectSelector = (appState: AppState) => appState.epics[valueActionType].state.helpBtnClientRect;

const ROPublishBtnClient = receiveOnly(valueActionType, (state) => state.publishBtnClientRect);

export {
    helpBtnClientRectSelector,

    ROPublishBtnClient,
};
