import { put } from "redux-saga/effects"; // eslint-disable-line n/file-extension-in-import

import { DataSite } from "../../../../../dal/model";
import { preparePersistentModel } from "../../../../../dal/model/utils";
import {
    applyPatchToSitePageChildren,
    loadSitePageWithValues,
    defineExcludeFromIndexing,
    defineRobots
} from "../utils";
import { PageInfoTab } from "../constants";
import { selectGen } from "../../../../utils/saga";
import updateSiteDataSaga from "../../saga/updateSiteDataSaga";
import makeResolveWhenFulfilledSaga from "../../../../epics/makeResolveWhenFulfilledSaga";
import { siteDataReceiveOnlyExternalValueActionType } from '../../../App/epics/siteData/valueActionType';
import { PAGE_INFO_SAVE, type SavePageInfoActionParams } from "../dialogs/PageInfoDialog/actions";
import pathsAreEqual from "../../../../utils/pathsAreEqual";
import { switchPageTemplateAction } from "../../PageTemplateSwitch/actions";
import type { AppState } from "../../../../redux/modules/flowTypes";
import { dialogManagerStateFromAppStateSelector } from "../../../DialogManager/epic/selectors";
import checkUnsavedChangesSaga from "../../../UnsavedChanges/checkUnsavedChangesSaga";
import replaceDialog from '../../../App/actionCreators/replaceDialog';
import { PageInfoDialogId } from '../dialogs/ids';
import { closeDialogByIdGen } from "../../../../utils/saga/closeDialogByIdGen";

// TODO: the selectors will be removed along with the saga
const
    dialogsRenderPropsSelector = (appState: AppState) =>
        dialogManagerStateFromAppStateSelector(appState).computed.renderProps,
    topDialogSelector = (appState: AppState) => {
        const
            renderProps = dialogsRenderPropsSelector(appState),
            keys = Object.keys(renderProps);

        return renderProps[keys.slice(-1)[0]];
    },
    topDialogStateSelector = (appState: AppState) => topDialogSelector(appState).state;

const checkDuplicatePageUrlPath = (
    { site, pageRefId, newPageUrl }: {
        site: DataSite,
        pageRefId: string,
        newPageUrl: string
    }
) => {
    const
        origUrlPath = site.getPageRefUrlPath(pageRefId),
        newUrlPath = [...origUrlPath.slice(0, -1), newPageUrl];

    if (!pathsAreEqual(origUrlPath, newUrlPath)) {
        if (site.isDuplicatePageRefUrlPath(newUrlPath)) {
            throw new Error(`Duplicate url path detected: '${newUrlPath.join('.')}'`);
        }
    }
};

const savePageInfoSagaWorker = function* (
    site: DataSite,
    { pageRefId, keepDialogOpenOnSave }: SavePageInfoActionParams
): Generator<any, void, any> {
    // TODO: should be removed after WBTGEN-6474 is done
    const checked = yield* checkUnsavedChangesSaga();
    if (!checked) return;

    const state = yield* selectGen(topDialogStateSelector);

    checkDuplicatePageUrlPath({ site, pageRefId, newPageUrl: state[PageInfoTab.NAMES].url.value });

    const
        newSite = preparePersistentModel(new DataSite(site)),
        targetPageRef = newSite.getPageRefById(pageRefId),
        {
            isHomePage,
            [PageInfoTab.SEO]: { excludeFromIndexing },
            [PageInfoTab.VISIBILITY]: { isHidden, isPublished },
            [PageInfoTab.TEMPLATE]: {
                templateIsChanged,
                selectedTemplate: {
                    id: selectedTemplateId
                }
            }
        } = state,
        currentExcludeFromIndexing = defineExcludeFromIndexing(targetPageRef.robots);

    // yield switch template logic
    if (templateIsChanged) {
        yield put(switchPageTemplateAction(selectedTemplateId));
    }

    // update site with new home page
    if (isHomePage && newSite.homePageId !== targetPageRef.pageId) {
        newSite.homePageId = targetPageRef.pageId;
    } else if (!isHomePage && newSite.homePageId === targetPageRef.pageId) {
        newSite.homePageId = null;
    }

    // apply values to sub tree
    const patch: Record<string, any> = {};

    if (excludeFromIndexing !== currentExcludeFromIndexing) {
        patch.robots = defineRobots(excludeFromIndexing);
    }

    if (!isPublished && !isHidden) throw new Error('Page cannot be NOT published AND visible');
    if (isHidden !== targetPageRef.hidden) patch.hidden = isHidden;

    applyPatchToSitePageChildren(newSite, pageRefId, patch);

    // update target page ref values
    loadSitePageWithValues(targetPageRef, state);

    yield* updateSiteDataSaga({ site: newSite });

    if (keepDialogOpenOnSave) {
        yield put(replaceDialog(PageInfoDialogId, { pageRefId, tabId: PageInfoTab.TEMPLATE }));
    } else {
        yield* closeDialogByIdGen(PageInfoDialogId);
    }
};

const listener = makeResolveWhenFulfilledSaga(
    siteDataReceiveOnlyExternalValueActionType,
    PAGE_INFO_SAVE,
    savePageInfoSagaWorker
);

export {
    listener as default,
    savePageInfoSagaWorker
};
