import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import Scrollbar from '../../../../../view/common/Scrollbar/index';
import LoadingIndicator from '../../../../../view/common/LoadingIndicator/index';
import { openApiErrorHandlerDialog } from '../../../../../redux/middleware/api/errorHandler/actions';
import { getDAL } from '../../../../../../dal/index';
import { IState } from '../../SelectorDialog/reducer';

import styles from './Products.css';
import ProductItem from './ProductItem';
import NoResults from './NoResults';
import NotReady from './NotReady';
import { ADD_PRODUCT, REMOVE_PRODUCT, SET_IS_DIRTY } from '../../SelectorDialog/actions';

const DOMAIN_ERR = 'DomainNotCreated';
const PAGE_LIMIT = 15;
const DIGITAL = 'DIGITAL,GIFTCARD';

const buildQueryParam = data => {
    const esc = encodeURIComponent;
    return Object.keys(data)
        .map(k => esc(k) + "=" + esc(data[k]))
        .join("&");
};

const loadProducts = async ({ filters, productsCount = 0, hasOnlyDigitalShop }): Promise<any> => {
    const pageNumber = Math.ceil((productsCount + PAGE_LIMIT) / PAGE_LIMIT);
    const dal = getDAL();
    const queryParams: Record<string, any> = {
        categoryId: filters.categoryId ? filters.categoryId : '',
        status: 'VISIBLE',
        sortBy: 'NAME',
        sort: 'ASC',
        pageSize: PAGE_LIMIT,
        pageNumber
    };

    if (filters.productName) {
        queryParams.search = filters.productName;
    }
    const shopAPIResponse = await dal.getWebshopData("/shop");
    const shopCurrency = shopAPIResponse.body ? shopAPIResponse.body.currency : "USD";

    let fetchedProduct: any = [];
    let totalRecords = null;

    if (hasOnlyDigitalShop) {
        let digitalProduct: Record<string, any> | null = null;
        try {
            const queryForDigital = buildQueryParam({ ...queryParams, type: DIGITAL });
            digitalProduct = await dal.getWebshopData(`/product?${queryForDigital}`);
        } catch (err: any) {
            throw new Error("Response error");
        }
        if (
            !(digitalProduct?.body) || !(digitalProduct.body.status) ||
            digitalProduct.body.status !== "Success"
        ) {
            throw new Error("Response error");
        }
        fetchedProduct = [...digitalProduct.body.data.result];
        totalRecords = digitalProduct.body.data.totalCount;
    } else {
        const query = buildQueryParam(queryParams);
        const productsAPIResponse = await dal.getWebshopData(`/product?${query}`);
        if (!productsAPIResponse.body || !productsAPIResponse.body.status || productsAPIResponse.body.status !== "Success") {
            if (productsAPIResponse.body.error === DOMAIN_ERR) {
                throw new Error(productsAPIResponse.body.error);
            }
            throw new Error('Response error');
        }

        if (
            !productsAPIResponse.body.data ||
            (!Array.isArray(productsAPIResponse.body.data.result) && !productsAPIResponse.body.data.result.length)
        ) {
            throw new Error("Empty response body");
        }
        fetchedProduct = [...productsAPIResponse.body.data.result];
        totalRecords = productsAPIResponse.body.data.totalCount;
    }

    const result = {
        totalRecords,
        products: fetchedProduct
    };

    result.products.forEach((product) => {
        if (Array.isArray(product.images) && product.images.length > 0) {
            product.images.forEach((productImage) => {
                if (productImage.url && productImage.url.indexOf('/webshopmedia/') !== -1) {
                    const webshopmediaIndex = productImage.url.indexOf('/webshopmedia/');
                    productImage.url = 'webshop:' + productImage.url.substring(webshopmediaIndex); // eslint-disable-line no-param-reassign
                }
            });
        }
        const priceDetails = {
            currency: shopCurrency,
            minRegularPrice: null, // oldPrice
            minSalePrice: 0, // price
            hasVariedPrice: false,
            soldOut: false
        };
        let previousPrice = null;
        const variantPrices = product.variants.map((v) => {
            const price = v.price;
            if (previousPrice === null) {
                previousPrice = price;
            }
            if (previousPrice !== price && !priceDetails.hasVariedPrice) {
                priceDetails.hasVariedPrice = true;
            }
            return price;
        }).sort((p1, p2) => p1 - p2);
        priceDetails.minSalePrice = variantPrices[0];
        const variantOldPrices = product.variants.map((v) => v.oldPrice).filter((p) => Boolean(p)).sort((p1, p2) => p1 - p2);
        priceDetails.minRegularPrice = variantOldPrices[0];
        priceDetails.soldOut = product.variants.every((v) => !v.unlimited && v.quantity === 0);
        product.priceDetails = priceDetails; // eslint-disable-line no-param-reassign
    });

    return result;
};

interface IProps {
    appDispatch: Dispatch
    products: any[]
    productsTotal: number
    filters: any
    setProductRecords: any
    hasOnlyDigitalShop: boolean
    state: IState,
    dispatch: Dispatch
}

const ProductList = ({
    appDispatch,
    products,
    productsTotal,
    filters,
    state,
    dispatch,
    setProductRecords,
    hasOnlyDigitalShop
}: IProps) => {
    const [loading, setLoading] = useState(true);
    const [domainErr, setDomainErr] = useState(false);
    const [appending, setAppending] = useState(false);

    const appendProducts = async filters => {
        if (productsTotal > products.length) {
            setAppending(true);
            try {
                setProductRecords(await loadProducts({ filters, productsCount: products.length, hasOnlyDigitalShop }));
            } catch (err: any) {
                setProductRecords({ products: [], totalRecords: productsTotal });
                appDispatch(
                    openApiErrorHandlerDialog({
                        messages: 'msg: component.webshop.featuredProducts.fetchError {Sorry, loading failed.}'
                    })
                );
            }
            setAppending(false);
        }
    };

    useEffect(() => {
        let isSubscribed = true;
        const fetchProducts = async () => {
            setLoading(true);
            setAppending(false);
            setDomainErr(false);
            try {
                const resp = await loadProducts({ filters, hasOnlyDigitalShop });
                if (isSubscribed) {
                    setProductRecords(resp, true);
                }
            } catch (err: any) {
                if (isSubscribed) {
                    setProductRecords({ products: [], totalRecords: 0 }, true);
                    if (err.message !== DOMAIN_ERR) {
                        appDispatch(
                            openApiErrorHandlerDialog({
                                messages: 'msg: component.webshop.featuredProducts.fetchError {Sorry, loading failed.}'
                            })
                        );
                    } else {
                        setDomainErr(true);
                    }
                }
            }
            if (isSubscribed) {
                setLoading(false);
            }
        };

        fetchProducts();
        return () => {
            isSubscribed = false;
        };
    }, [filters]);

    const onScroll = debounce(([sInfo]) => {
        if (sInfo.scrollHeight - sInfo.scrollTop - sInfo.clientHeight < 50 && !loading) {
            appendProducts(filters);
        }
    }, 200);

    if (loading) {
        return <LoadingIndicator />;
    }

    if (domainErr) {
        return <NotReady appDispatch={appDispatch} />;
    }

    if (products.length === 0) {
        return <NoResults searchTerm={filters.productName} category={filters.categoryId ? filters.categoryName : ''} />;
    }

    const ulClasses = classNames(
        styles.list,
        state.categories.length ? styles.listDisabled : ''
    );

    return (
        <Scrollbar onScroll={(...props) => onScroll(props)} className={styles.listContainer}>
            <ul className={ulClasses}>
                {products.map((p, i) => (
                    <li key={i}>
                        <ProductItem
                            product={p}
                            toggleSelected={pid => {
                                if (state.products.includes(pid)) {
                                    dispatch({
                                        type: REMOVE_PRODUCT,
                                        payload: pid
                                    });
                                } else {
                                    dispatch({
                                        type: ADD_PRODUCT,
                                        payload: pid
                                    });
                                }
                                dispatch({ type: SET_IS_DIRTY });
                            }}
                            selected={state.products.includes(p.id)}
                        />
                    </li>
                ))}
                {appending && (
                    <li className={styles.paginationLoadingIndicator}>
                        <LoadingIndicator />
                    </li>
                )}
            </ul>
        </Scrollbar>
    );
};

export default ProductList;
