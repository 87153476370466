import { BROKEN_LINK_MSG, LcTabName } from "../constants";
import type { LinkChooserLinkType } from "../flowTypes";
import { DataSite } from "../../../../../../dal/model/index";
import pathUtil from '../../../../../../utils/path.js';
import {
    JS_VOID_PATTERN, GlobalVariableAddressUrlKey, GlobalVariableContactEmailKey, GlobalVariablePhoneNumberKey, isAddressSet
} from "../../../../../constants";
import { Lit } from "../../../../../lit";
import type { SectionComponents } from '../../../../../components/PagesTree/sectionComponents/epic/sectionComponentsEpic';

const { renderAddressToStringSingleline } = require('../../../../../../../server/shared/address/utils.js');

function getRelativePath(data) {
    let
        prefix = '/',
        basePath = pathUtil.trim(data.basePath);

    if (basePath) prefix += `${basePath}/`;

    return prefix + pathUtil.trim(data.href);
}

export const getSectionLinkInfo = (value: any, sectionComponents: SectionComponents): any => {
    let name;
    if (sectionComponents.isLoading) {
        name = '';
    } else {
        const page = sectionComponents.pageOptions.find(page => page.value === value.pageId);
        if (page) {
            const section = sectionComponents.pageSections[value.pageId] &&
                sectionComponents.pageSections[value.pageId].find(a => a.value === value.sectionId);
            name = section ? section.label : BROKEN_LINK_MSG;
        } else {
            name = BROKEN_LINK_MSG;
        }
    }
    return { name, isBrokenLink: name === BROKEN_LINK_MSG };
};

export const getLinkName = (
    link: LinkChooserLinkType, site: DataSite, sectionComponents: SectionComponents, globalVariables: Record<string, any>
): string => {
    const { type, value } = link;
    let name;

    if (type === LcTabName.PAGE) {
        try {
            name = site.getItemById(value).name;
        } catch (e: any) {
            name = BROKEN_LINK_MSG;
        }
    } else if (type === LcTabName.FILE) {
        name = getRelativePath(value);// value.getRelativePath(); WBTGEN-3315
    } else if (type === LcTabName.URL) {
        name = JS_VOID_PATTERN.test(value) ? BROKEN_LINK_MSG : value;
    } else if (type === LcTabName.PHONE) {
        if (value === `#${GlobalVariablePhoneNumberKey}`) {
            name = globalVariables[GlobalVariablePhoneNumberKey] ? globalVariables[GlobalVariablePhoneNumberKey] : BROKEN_LINK_MSG;
        } else {
            name = value;
        }
    } else if (type === LcTabName.EMAIL) {
        if (value[Lit.selection] === `#${GlobalVariableContactEmailKey}`) {
            name = globalVariables[GlobalVariableContactEmailKey] ? globalVariables[GlobalVariableContactEmailKey] : BROKEN_LINK_MSG;
        } else {
            name = value[Lit.selection];
        }
    } else if (type === LcTabName.LOCATION) {
        if (value[Lit.selection] === `#${GlobalVariableAddressUrlKey}`) {
            // @ts-ignore
            name = isAddressSet(globalVariables) ? renderAddressToStringSingleline(globalVariables) : BROKEN_LINK_MSG;
        } else {
            name = value[Lit.name];
        }
    } else if (type === LcTabName.SECTION_LINK) {
        name = getSectionLinkInfo(value, sectionComponents).name;
    } else {
        throw new Error(`Unknown link chooser link type: ${type}`);
    }

    return name;
};
