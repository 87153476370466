import { SubscriptionType } from "../../../server/shared/accessManager/constants.js";

export const Modes = {
    EDIT_PAGE: "EDIT_PAGE",
    EDIT_LAYOUT: "EDIT_LAYOUT"
};

export const MaxComponentsOnPage = 1100; // 1000 component + 100 safety part
export const DraggingNewComponentOutWorkspaceZIndex = 10003; // more than Left Panel and Top Bar but less than modals
export const DraggingNewComponentInWorkspaceZIndex = 4500; // less than Red Decoractions
export const DraggingNewComponentInWorkspaceIconZIndex = 9000; // more than Red Decoractions

/*
If we set this value as 1px, it would work fine in 100% zoom scenario, but in case of zoom-out, browsers (eg: Chrome and Firefox) make such lines invisible intermittently.
The value of 1.49 fixes it in a good enough manner for most of the cases.
because/wsb/hacks/browser-zoom-out/browser-zoom-out.md
(See WBTGEN-11630)
*/
export const ComponentSelectionBorderWidth = 1.49;

/*
If we set this value as 1px, it would work fine in 100% zoom scenario, but in case of zoom-out, browsers (eg: Chrome and Firefox) make such lines invisible intermittently.
The value of 1.49 fixes it in a good enough manner for most of the cases.
because/wsb/hacks/browser-zoom-out/browser-zoom-out.md
(See WBTGEN-11630)
*/
export const SelectionFrameBorderWidth = 1.49;
export const SelectionFrameThickBorderWidth = 3;
export const SelectionFrameMargin = 0;
export const AligningEdgesStep = 1;
export const SpaceAdjusterMinimumBetweenComponentsPx = 0;
export const SpaceAdjusterLockingDistance = 70;

export const JpegCompressionQuality = 100;

export const COOKIE_LOCALE_EN_GB = 'en_gb';
export const COOKIE_LOCALE_EN_US = 'en_us';
export const COOKIE_LOCALE_EN = 'en';
export const COOKIE_LOCALE_PT = 'pt';
export const COOKIE_LOCALE_PT_BR = 'pt_br';
export const COOKIE_LOCALE_TO_DROP = {
    'cs': 'Cestina',
    'pl': 'Polski',
};

export const ONE_LANG_COOKIE_KEY = 'OneLang';
export const TOP_BAR_LANGUAGES_COOKIE_CALLER_ID = 'TOP_BAR_LANGUAGES_COOKIE_CALLER_ID';

// This needs refactoring
const SubscriptionTypes = SubscriptionType;
export { SubscriptionTypes };

export const WIDGET_LOADED = 'WIDGET_LOADED';
export const WIDGET_CANNOT_LOAD = 'WIDGET_CANNOT_LOAD';
export const SUBSCRIPTION_LINK = "https://one.com/admin/subscription/wsb.do";

export const HIGH_PAGE_COUNT = 100;

export const UPGRADE_TO_PREMIUM_EVENT_CATEGORY = 'UpgradeToPremium';
export const UPGRADE_TO_PREMIUM_EVENT_LABEL_SEAMLESS_SUCCESS = 'seamlessupgrade.success';
export const UPGRADE_TO_PREMIUM_EVENT_LABEL_SEAMLESS_FAILURE = 'seamlessupgrade.failure';

export const DOUBLE_CLICK_THRESHOLD = 300;

export const DEFAULT = 'Onboarding_Default';
export const CUSTOM = 'Onboarding_Custom';
export const RECOMMENDED = 'Onboarding_Recommended';

export const OPEN_AI_TERMS_LINK = "https://openai.com/policies/eu-terms-of-use";
export const OPEN_AI_PRIVACY_LINK = "https://openai.com/policies/eu-privacy-policy";
