import {
    parse as svgsonParse,
    parseSync as svgsonParseSync,
    stringify as svgsonStringify
} from 'svgson';
import { EMPTY_SVG } from '../../../../../server/shared/constants.js';

const parse = async (svgText: string) => {
    let output;
    try {
        output = await svgsonParse(svgText);
    } catch (error) {
        output = await svgsonParse(EMPTY_SVG);
    }

    return output;
};

const parseSync = (svgText: string) => {
    try {
        return svgsonParseSync(svgText);
    } catch (error) {
        return svgsonParseSync(EMPTY_SVG);
    }
};

const stringify = (svgJson: any) => {
    try {
        return svgsonStringify(svgJson);
    } catch (error) {
        return '';
    }
};

export {
    parse,
    parseSync,
    stringify
};
