import type { Color } from '../flowTypes';
import type { ImageAsset } from "../../components/App/flowTypes";

export const GradientOrigin = {
    LEFT: 'left',
    TOP: 'top'
};

export type GradientOriginType = 'left' | 'top';

export type GradientDirection = 'top' | 'bottom' | 'left' | 'right';

export type Gradient = {
    color: Color,
    fadePoint: number,
    degree: number
}

export type AssetPosition = [string | number, string | number];
export type AssetRepeat = [boolean, boolean];
export type AssetOverlay = string;
export type AssetSize = string | number;
export type AssetData = {
    asset: null | undefined | ImageAsset,
    position: AssetPosition,
    repeat: AssetRepeat,
    overlay?: AssetOverlay,
    size: AssetSize,
    scrollEffect?: string | null,
    fixed?: null | boolean,
    opacity: number
};

export type Background = {
    colorData?: {
        color: null | undefined | Color,
        gradient: null | undefined | Gradient
    },
    assetData?: AssetData
}

export type BgCss = null | undefined | Record<string, any>;

export type Alpha = null | undefined | number;
