import defaultState from "../defaultState";
import type { FileManagerState } from "../flowTypes";
import pathUtil from '../../../../../../utils/path.js';

export default (state: FileManagerState, currentPath: string) => {
    const { resources, allIsSelected, fileListUploadProgress } = defaultState;

    return {
        ...state,
        currentPath: pathUtil.trim(currentPath),
        resources,
        allIsSelected,
        fileListUploadProgress
    };
};
