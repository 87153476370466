import type { ComponentsIds, ComponentsMap, BBox } from "../../../../../redux/modules/children/workspace/flowTypes";
import type { Attachments } from "../../componentAttachements/flowTypes";
import { NonTemplateComponents } from "../../../../oneweb/Template/constants";
import { ContainerKinds } from "../../../../../utils/containerKinds";
import { getHeaderAndFooterSection, adjustComponentTops } from "../../../../oneweb/Section/utils";
import { isSectionComponent } from '../../../../oneweb/isSectionComponent';
import {
    getComponentsBBox,
    zeroBBox
} from '../../../../../utils/componentsMap/index';
import { getSectionAtPosition } from '../../isPageMode/utils';
import { PASTE_OFFSET_WHEN_THERE_IS_NOT_ENOUGH_SECTION_HEIGHT } from '../constants';
import { isContainerKind } from "../../../../../convertToSections/util";
import { getBBox } from "../../../../../utils/bBox";
import { getParentChildrenMap } from "./getParentChildrenMap";
import { getCmpCenter } from "../utils";

export const filterNonTemplateComponentsIncludingWrappers =
    (cmpIds: ComponentsIds, attachments: Attachments, componentsMap: ComponentsMap): ComponentsIds => {
        return cmpIds.filter(cmpId => {
            const { id, kind } = componentsMap[cmpId];
            if (NonTemplateComponents[kind]) {
                return true;
            }
            if (ContainerKinds[kind]) {
                const children = attachments[id] || [];
                return children.some((id) =>
                    !!filterNonTemplateComponentsIncludingWrappers([id], attachments, componentsMap).length);
            }
            return false;
        });
    },
    isNonTemplateComponentsInHeaderOrFooter =
        (cmpIds: ComponentsIds, attachments: Attachments, componentsMap: ComponentsMap): boolean => {
            const nonTemplateCmp = filterNonTemplateComponentsIncludingWrappers(cmpIds, attachments, componentsMap);
            if (!nonTemplateCmp.length) { return false; }
            const { header, footer } = getHeaderAndFooterSection(componentsMap);
            return nonTemplateCmp.some(id => {
                const { height, top } = componentsMap[id],
                    center = top + (height / 2);
                const inHeader = top < header.height,
                    inFooter = center >= footer.top;
                return inHeader || inFooter;
            });
        };

const moveComponentsVertically = (yDiff: number, componentsIds: ComponentsIds, componentsMap: ComponentsMap) => {
    return {
        ...componentsMap,
        ...componentsIds.reduce((a, cid) => {
            a[cid] = { ...componentsMap[cid], top: componentsMap[cid].top + yDiff };
            return a;
        }, {})
    };
};

/**
 * Checks if 2 components are intersecting vertically
 * @param rect1, dimensions for component 1
 * @param rect2, dimensions for component 2
 * @returns {boolean}
 */
const isRectsIntersectingVertically = (rect1: BBox, rect2: BBox) => !(rect2.left >= rect1.right || rect2.right <= rect1.left);

const getIntersectingCmps = (
    cmps: Array<string>,
    componentsMap: ComponentsMap,
    parentChildrenMap: Object,
    selectedComponentsIds: Array<string>,
    selectionBBox: BBox
) => {
    let intersectingCmps: Array<string> = [];
    (cmps || []).forEach((cmpId) => {
        const cmpBBox = getBBox(componentsMap[cmpId]);
        if (isContainerKind(componentsMap[cmpId].kind)) {
            intersectingCmps = intersectingCmps.concat(
                getIntersectingCmps(
                    parentChildrenMap[cmpId],
                    componentsMap,
                    parentChildrenMap,
                    selectedComponentsIds,
                    selectionBBox
                )
            );
        }
        if (!selectedComponentsIds.includes(cmpId) && isRectsIntersectingVertically(selectionBBox, cmpBBox)) {
            intersectingCmps.push(cmpId);
        }
    });
    return intersectingCmps;
};

/**
 * This function returns an array of components that are vertically intersecting with the given component/s
 * @param parentChildrenMap
 * @param targetSection, section in which intersection is to be found
 * @param selectedComponentsIds, components selection
 * @param selectionBBox, BBox for selected components
 * @param componentsMap
 */
const verticallyIntersectingCmps = (
    parentChildrenMap: Record<string, any>,
    targetSection: Record<string, any>,
    selectedComponentsIds: Array<string>,
    selectionBBox: BBox,
    componentsMap: ComponentsMap
) => {
    const sectionComponents = parentChildrenMap[targetSection.id];
    if (sectionComponents) {
        return getIntersectingCmps(sectionComponents, componentsMap, parentChildrenMap, selectedComponentsIds, selectionBBox);
    }
    return [];
};

/**
 * Generate next components map by moving components vertically.
 * @param newComponentsMap
 * @param targetSection
 * @param newSectionHeight
 * @param yAdjustment
 * @param selectedComponentsIds
 */
const generateNextComponentsMap = (
    newComponentsMap: ComponentsMap,
    targetSection: Record<string, any>,
    newSectionHeight: number,
    yAdjustment: number,
    selectedComponentsIds: Array<string>,
    isPushDown: boolean = false
) => {
    let nextComponentsMap = {
        ...newComponentsMap,
        [targetSection.id]: {
            ...targetSection,
            height: newSectionHeight
        }
    };

    nextComponentsMap = moveComponentsVertically(yAdjustment, selectedComponentsIds, nextComponentsMap);
    if (isPushDown) {
        (Object.keys(nextComponentsMap)).forEach((cmpId) => {
            const cmpCenter = getCmpCenter(nextComponentsMap[cmpId]);
            if (cmpCenter >= targetSection.top && cmpId !== targetSection.id && !selectedComponentsIds.includes(cmpId)) {
                nextComponentsMap = moveComponentsVertically(yAdjustment, [cmpId], nextComponentsMap);
            }
        });
    }

    const selectedComponentsAfterAdjustmentMap = selectedComponentsIds.reduce((a, cid) => {
        a[cid] = nextComponentsMap[cid];
        return a;
    }, {});

    if (!isPushDown) {
        nextComponentsMap = adjustComponentTops(
            targetSection.top + targetSection.height,
            newSectionHeight - targetSection.height,
            nextComponentsMap, [], [...selectedComponentsIds, targetSection.id]
        );
    }

    nextComponentsMap = {
        ...nextComponentsMap,
        ...selectedComponentsAfterAdjustmentMap
    };
    return nextComponentsMap;
};

export const fixSelectionOverlapWithSectionByMovingSelection = (
    selectedComponentsIds: Array<string>,
    componentsMap: ComponentsMap,
    sectionIntesectionLine: 'selection-middle' | 'selection-top' = 'selection-middle',
    updateInTemplateProp: boolean = false,
    isAddingNewCmp: boolean = false,
) => {
    if (!selectedComponentsIds.length) {
        return componentsMap;
    }
    try {
        let parentChildrenMap = getParentChildrenMap(componentsMap);
        let selComponentIds = [...selectedComponentsIds];

        // get all child components as selected components, for container kind components.
        // Because all child components will be moved along with container.
        let selectedComponentsLength = selComponentIds.length;
        let i = 0;

        while (selectedComponentsLength) {
            let reduceSelectedComponentsCount = true;
            const cid = selComponentIds[i];
            if (parentChildrenMap[cid]) {
                parentChildrenMap[cid].forEach(compId => {
                    if (!selComponentIds.includes(compId)) {
                        selComponentIds.push(compId);
                        reduceSelectedComponentsCount = false;
                    }
                });
            }
            i++;
            selectedComponentsLength = reduceSelectedComponentsCount ? selectedComponentsLength - 1 : selectedComponentsLength;
        }

        if (selComponentIds.some(cid => componentsMap[cid] && isSectionComponent(componentsMap[cid]))) { // do not adjust if sectionis selected
            return componentsMap;
        }

        const selectionBBox = getComponentsBBox(selComponentIds.map(cid => componentsMap[cid]), zeroBBox);

        let selectionIntersectionLineY;

        switch (sectionIntesectionLine) {
            case 'selection-top':
                selectionIntersectionLineY = selectionBBox.top;
                break;
            case 'selection-middle':
                selectionIntersectionLineY = Math.floor((selectionBBox.bottom + selectionBBox.top) / 2);
                break;
            default:
                selectionIntersectionLineY = selectionBBox.top;
        }

        const targetSection = getSectionAtPosition({
            y: selectionIntersectionLineY,
            x: 0
        }, componentsMap);
        const selectionHeight = selectionBBox.bottom - selectionBBox.top;

        if (!targetSection) {
            return componentsMap;
        }

        let intersectingCmps: Array<string> = [];
        // Find Verically Intersecting components
        if (parentChildrenMap && selComponentIds.length) {
            intersectingCmps =
                verticallyIntersectingCmps(parentChildrenMap, targetSection, selComponentIds, selectionBBox, componentsMap);
        }

        let newComponentsMap = componentsMap,
            targetSectionInTemplate = targetSection.inTemplate;

        if (updateInTemplateProp) {
            selComponentIds.forEach((id) => {
                const cmp = newComponentsMap[id];
                newComponentsMap = { ...newComponentsMap, [id]: { ...cmp, inTemplate: targetSectionInTemplate } };
            });
        }

        const targetSectionBottom = targetSection.top + targetSection.height;

        // If there is a component which crosses the section bottom but has cehecnter inside section
        // then on click of that component we push it inside the section making sure it doesn't
        // overlap with any other component in the section.
        if (intersectingCmps && intersectingCmps.length) {
            if (selectionBBox.top < targetSection.top && selectionBBox.bottom > targetSectionBottom) {
                let intersectionPoint = targetSectionBottom; // Assuming there are no intersecting components
                intersectingCmps.forEach((cmpId) => {
                    const component = componentsMap[cmpId];
                    const componentTop = component.top;
                    if (intersectionPoint > componentTop) {
                        intersectionPoint = componentTop;
                    }
                });

                const newSectionHeight = selectionHeight +
                    (2 * PASTE_OFFSET_WHEN_THERE_IS_NOT_ENOUGH_SECTION_HEIGHT);

                const yAdjustment = targetSection.top - selectionBBox.top + PASTE_OFFSET_WHEN_THERE_IS_NOT_ENOUGH_SECTION_HEIGHT;
                return generateNextComponentsMap(
                    newComponentsMap,
                    targetSection,
                    newSectionHeight,
                    yAdjustment,
                    selComponentIds
                );
            } else if (selectionBBox.bottom > targetSectionBottom) {
                let intersectionPoint = targetSection.top; // Assuming there are no intersecting components
                intersectingCmps.forEach((cmpId) => {
                    const component = componentsMap[cmpId];
                    const componentBottom = component.top + component.height;
                    if (intersectionPoint < componentBottom) {
                        intersectionPoint = componentBottom;
                    }
                });
                if (intersectionPoint > selectionBBox.top) {
                    intersectionPoint = selectionBBox.top;
                }
                if (targetSectionBottom - intersectionPoint >= selectionHeight) {
                    const yAdjustment = targetSectionBottom - selectionBBox.bottom;
                    return moveComponentsVertically(yAdjustment, selComponentIds, newComponentsMap);
                }
                const newSectionHeight = targetSection.height +
                    ((selectionBBox.bottom - targetSectionBottom) - (selectionBBox.top - intersectionPoint)) +
                    PASTE_OFFSET_WHEN_THERE_IS_NOT_ENOUGH_SECTION_HEIGHT;

                const yAdjustment = intersectionPoint - selectionBBox.top;
                return generateNextComponentsMap(
                    newComponentsMap,
                    targetSection,
                    newSectionHeight,
                    yAdjustment,
                    selComponentIds
                );
            } else if (selectionBBox.top < targetSection.top) { // overlap at top
                let intersectionPoint = targetSectionBottom; // Assuming there are no intersecting components
                intersectingCmps.forEach((cmpId) => {
                    const component = componentsMap[cmpId];
                    const componentTop = component.top;
                    if (intersectionPoint > componentTop) {
                        intersectionPoint = componentTop;
                    }
                });
                // If there is no need to increase section height
                if (intersectionPoint - targetSection.top <= selectionHeight) {
                    const yAdjustment = targetSection.top - selectionBBox.top;
                    return moveComponentsVertically(yAdjustment, selComponentIds, newComponentsMap);
                }
                const newSectionHeight = targetSection.height +
                    (targetSection.top - selectionBBox.top);

                const yAdjustment = targetSection.top - selectionBBox.top;
                return generateNextComponentsMap(
                    newComponentsMap,
                    targetSection,
                    newSectionHeight,
                    yAdjustment,
                    selComponentIds,
                    true
                );
            }
        }

        const isSectionHeightLess = isAddingNewCmp ? targetSection.height <= selectionHeight :
            targetSection.height < selectionHeight;

        if (isSectionHeightLess) { // expand section height
            const newSectionHeight = selectionHeight + (2 * PASTE_OFFSET_WHEN_THERE_IS_NOT_ENOUGH_SECTION_HEIGHT);

            const yAdjustment = targetSection.top - selectionBBox.top + PASTE_OFFSET_WHEN_THERE_IS_NOT_ENOUGH_SECTION_HEIGHT;
            return generateNextComponentsMap(newComponentsMap, targetSection, newSectionHeight, yAdjustment, selComponentIds);
        }
        if (targetSection.top <= selectionBBox.top && targetSectionBottom >= selectionBBox.bottom) { // contained
            return newComponentsMap;
        }
        if (targetSection.top > selectionBBox.top) { // snap to top
            const yAdjustment = targetSection.top - selectionBBox.top;
            return moveComponentsVertically(yAdjustment, selComponentIds, newComponentsMap);
        }
        const yAdjustment = targetSectionBottom - selectionBBox.bottom;
        return moveComponentsVertically(yAdjustment, selComponentIds, newComponentsMap);
    } catch (e) {
        return componentsMap;
    }
};
