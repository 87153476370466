import React from "react";

import type { TextLikeViewType, CalcRenderPropsResult } from "../types";
import { textLikeComponentViewFactory } from "../factories/textLikeComponentViewFactory";
import { ReactElement } from "../types";
import { PhoneComponentIcon } from "../svgs";
import { replaceSpaceWithNbsp } from "../factories/textLikeComponentFactory";

const renderForPhone = (props: CalcRenderPropsResult<any, any>): ReactElement<any> => {
    const { specific: { showPlaceholderValue } = {} } = props,
        phoneText = replaceSpaceWithNbsp(
            (!showPlaceholderValue && props.deps.phoneNumber) || props.placeholderText
        );
    return <span>{phoneText}</span>;
};

const iconRenderForPhone = (
    { style }: any
):React.ReactElement<React.ComponentProps<any>, any> => <PhoneComponentIcon style={style} />;

const getHrefForPhone = (props: any): string | null => (props.deps.phoneNumber ? `tel:${props.deps.phoneNumber}` : null);

export const phoneComponentView: TextLikeViewType<any> = textLikeComponentViewFactory({
    defaultLineSpacing: 0.5,
    getHref: getHrefForPhone,
    // @ts-ignore
    render: renderForPhone,
    iconRender: iconRenderForPhone
});
