const BOOKINGS_KIND = "BOOKINGS";
const BLOG_KIND = "BLOG";
const POST_KIND = "POST";

// page add and site map updates will be retried this many times
const DB_UPDATE_RETRIES = 3;
// timeout of the whole API for each component kind in ms
const CREATE_PAGE_TIMEOUT = {
    [BOOKINGS_KIND]: 30 * 1000, // 30s
    [BLOG_KIND]: 90 * 1000, // 90s for blog, as blog creation itself has a 60s timeout
};

/**
 * BOOKINGS constants
 */
const BOOKINGS_LAYOUT_FOLDER_NAME = "page-layout-bookings";
const LAYOUT_BOOKINGS_PAGE_ID = "B5EB17FC-3780-48E4-AF4D-40BCF972497B";
const LAYOUT_BOOKINGS_TEMPLATE_ID = "9B3501FD-7A1F-4F8C-9B22-1E375EB7DB7C";
const LAYOUT_BOOKINGS_STYLESHEET_ID = "BB1FFC2A-23E6-46D9-A67E-F6FD819F953B";

/**
 * BLOG constants
 */
const LAYOUT_BLOG_PAGE_ID = "E5EB17FC-3780-48E4-AF4D-40BCF972497C";
const LAYOUT_POST_PAGE_ID = "DB74F62A-8DBB-48C8-874D-142582A8ECF6";
const LAYOUT_BLOG_TEMPLATE_ID = "9A3501FD-7A1F-4F8C-9B22-1E375EB7DB7C";
const LAYOUT_BLOG_STYLESHEET_ID = "711FFC2A-23E6-46D9-A67E-F6FD819F953B";
const BLOG_LAYOUT_FOLDER_NAME = "page-layout-blog";
const OPTION_AI = "ai";
const CATEGORY_PAGE_NAME_TYPE = "category";

module.exports = {
    BOOKINGS_KIND,
    DB_UPDATE_RETRIES,
    CREATE_PAGE_TIMEOUT,
    BOOKINGS_LAYOUT_FOLDER_NAME,
    LAYOUT_BOOKINGS_PAGE_ID,
    LAYOUT_BOOKINGS_TEMPLATE_ID,
    LAYOUT_BOOKINGS_STYLESHEET_ID,
    LAYOUT_BLOG_PAGE_ID,
    LAYOUT_POST_PAGE_ID,
    BLOG_LAYOUT_FOLDER_NAME,
    OPTION_AI,
    BLOG_KIND,
    POST_KIND,
    CATEGORY_PAGE_NAME_TYPE,
    LAYOUT_BLOG_TEMPLATE_ID,
    LAYOUT_BLOG_STYLESHEET_ID
};
