import React from 'react';
import { pure } from 'recompose';
import cx from 'classnames';
import { Intl, injectIntl } from "../../intl/index";
import { blue95 } from '../../../components/variables.css';
import type {
    ComponentAttachDecorationState
} from '../../../components/Workspace/epics/componentAttachDecorations/flowTypes';
import { getWidth } from '../../../utils/bBox';
import styles from './Decorations.css';
import { ComponentNames } from '../../../components/oneweb/constants';
import { BorderLines } from "../../../components/BorderLines/borderLines";
import { SectionTypeNames, SectionTypes } from "../../../components/oneweb/Section/constants";

const Z_INDEX = 1000005;

type Props = {
    state: ComponentAttachDecorationState,
    intl: Intl
}

const AttachDeco = ({
    component: {
        componentBBox,
        kind,
        attachTop,
    },
    intl
}) => {
    const
        width = getWidth(componentBBox),
        height = componentBBox.bottom - attachTop,
        borderProps = {
            color: blue95,
            size: 10,
            width,
            height,
            zIndex: Z_INDEX
        },
        attachToBoxStyle = {
            left: componentBBox.left,
            top: attachTop,
            width,
            height,
            zIndex: Z_INDEX
        },
        attachToMsg = intl.msgJoint("msg: common.attachTo {Attach to}"),
        isHeaderOrFooter = kind === SectionTypes.HeaderSection || kind === SectionTypes.FooterSection,
        containerMsg = kind && intl.msgJoint(ComponentNames[kind] || SectionTypeNames[kind]),
        attachToLabel = (<div className={styles.attachLabelBox}>
            <span>{attachToMsg} {containerMsg}</span>
        </div>);

    const headerFooterContainer = (<div
        style={attachToBoxStyle}
        className={cx(styles.attachContainer, { [styles.headerFooterContainer]: isHeaderOrFooter })}
    >
        {attachToLabel}
    </div>);

    const cmpContainer = (<div style={attachToBoxStyle} className={styles.attachContainer}>
        <BorderLines className={styles.border} {...borderProps}>
            <div className={styles.attachLabelBox}>
                <span>{attachToMsg} {containerMsg}</span>
            </div>
        </BorderLines>
    </div>);

    return (isHeaderOrFooter ? headerFooterContainer : cmpContainer);
};

export default pure(injectIntl(({
    state: {
        isVisible,
        mainCmp,
        headerFooterCmp,
    },
    intl,
}: Props) => {
    if (!isVisible) {
        return <div />;
    }

    return (
        <div>
            <AttachDeco component={mainCmp} intl={intl} />
            {headerFooterCmp && <AttachDeco component={headerFooterCmp} intl={intl} />}
        </div>
    );
}));
