/* @flow */

const DynamoDB = require('aws-sdk/clients/dynamodb');

/*::
    import type { AwsServiceOptions } from '../types'

    type AwsDynamoDbOptions = { ...AwsServiceOptions }
 */

/**
 * See DocumentClient API: https://docs.aws.amazon.com/AWSJavaScriptSDK/latest/AWS/DynamoDB/DocumentClient.html.
 */
function AwsDynamoDb(options/*: AwsDynamoDbOptions */) {
    /*=== Constructor ===*/

    // Need this nasty inheritance since AWS.S3 is not a constructor function
    function AwsDynamoDbClass() {
        // This is intentional
    }
    AwsDynamoDbClass.prototype = new DynamoDB.DocumentClient(options);

    const self = new AwsDynamoDbClass();

    /*=== Public ===*/
    return self
}

module.exports = { AwsDynamoDb };
