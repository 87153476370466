import { DataSite, DataPageRef, DataSectionLink } from "../../../../dal/model";
import type { DataSiteItem } from "../../../../dal/model/utils/dataSiteItemUtils";
import type { DataSiteFolder } from "../../../../dal/model/DataSite";
import { disallowedToMoveHomePage } from "./disallowedToMoveHomePage";
import makeNewPageUrl from "./makeNewPageUrl";
import { isSiteDataRoot } from "../../../../dal/model/utils/isSiteDataRoot";
import { isBlogPage, isLinkPage, isSectionLink } from "../../../../dal/model/utils/dataSiteItemUtils";
import { PageLayoutCategoryId } from "../../../../dal/model/DataPageLayout";

type Params = {
    siteData: DataSite;
    itemId: string;
    toItemId: string;
    gap: -1 | 1 | 0;
};

type ItemAndParentResult = {
    item: DataSiteItem;
    itemIndex: number;
    parent: DataPageRef | DataSiteFolder;
};

const getItemAndParent = (site: DataSite, itemId: string): ItemAndParentResult => {
    const
        parent = site.getPageRefParentOrRoot(itemId),
        itemIndex = parent.items.findIndex(item => item.id === itemId),
        item = parent.items[itemIndex];
    return { item, itemIndex, parent };
};

export default ({ siteData, itemId, toItemId, gap }: Params): DataSite | false => {
    const
        newSiteData = new DataSite(siteData), // clone
        { item, itemIndex, parent } = getItemAndParent(newSiteData, itemId),
        itemDropped = siteData.getItemById(toItemId);

    // home page is only allowed to be on root level
    if (
        disallowedToMoveHomePage({ siteData, itemId, toItemId, gap }) ||
        (gap === 0 && (isLinkPage(itemDropped) || isSectionLink(itemDropped) || isBlogPage(itemDropped)))
    ) {
        return false;
    }

    let newParent;
    if (gap === 0) {
        // insert into new item
        const toItem = newSiteData.getPageRefById(toItemId);

        parent.items.splice(itemIndex, 1);
        toItem.items.push(item);
        newParent = toItem;
    } else {
        // insert into gap
        const { itemIndex: toItemIndex, parent: toParent } = getItemAndParent(newSiteData, toItemId);

        // Handle use cases when item is tried to be moved into itself
        if (
            parent.id === toParent.id
            && (itemIndex === toItemIndex || (itemIndex === (toItemIndex - 1) && gap === -1))
        ) {
            return false;
        }

        // remove item from old position
        parent.items.splice(itemIndex, 1);

        // define new position and insert item to new position
        let toPosition = gap === 1 ? toItemIndex + 1 : toItemIndex;
        if (parent.id === toParent.id && itemIndex < toItemIndex) toPosition--;
        toParent.items.splice(toPosition, 0, item);
        newParent = toParent;
    }

    if (item instanceof DataSectionLink) {
        return newSiteData;
    }
    // deduplicate new url
    const
        newParentUrlPath = isSiteDataRoot(newParent) ? [] : newSiteData.getPageRefUrlPath(newParent.id),
        newUrlPath = [...newParentUrlPath, item.url],
        allPagesUrlPaths = newSiteData.getAllPageRefsUrlPaths(item.id);

    if (newSiteData.isDuplicatePageRefUrlPath(newUrlPath)) {
        item.url = makeNewPageUrl(item.url, newParentUrlPath, allPagesUrlPaths, PageLayoutCategoryId.BLANK_PAGE);
    }

    return newSiteData;
};
