import React from "react";
import cx from "classnames";

import {
    isLinkPage,
    isSectionLink,
    DataSiteItem,
    isPageStateHidden,
    isPageStateLocked
} from "../../../../../dal/model/utils/dataSiteItemUtils";
import * as object from "../../../../../utils/object.js";

import styles from './PagesTree.css';
import { PageOptionsTitles } from "../constants";
import { useIntl } from "../../../../view/intl";

type Props = {
    page: DataSiteItem
}

export const PageStates = ({ page }: Props) => {
    const intl = useIntl();
    const
        states = {
            locked: isPageStateLocked(page),
            hidden: isPageStateHidden(page)
        },
        renderStates = object.filter(states, s => s),
        renderStatesLength = object.length(renderStates);

    if (!renderStatesLength) return null;

    return (
        <span className={styles.pageStates}>
            {object.mapToArray(renderStates, (_, state) => {
                let title = PageOptionsTitles[state];
                if (state === 'locked' && isLinkPage(page)) {
                    title = PageOptionsTitles[`link_${state}`];
                }
                if (state === 'hidden' && isSectionLink(page)) {
                    title = PageOptionsTitles[`section_link_${state}`];
                }
                title = intl.msgJoint(title);
                return <span
                    className={cx(styles.pageStateIcn, styles[state])}
                    key={state}
                    data-title-position="top"
                    data-title={title}
                />;
            })}
        </span>
    );
};
