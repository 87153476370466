import makeEpic from '../../../../epics/makeEpic';
import valueActionType from './valueActionType';
import { getAppConfig } from "./appConfig.js";
import { ReceiveOnlyComponentsMap } from "../../../Workspace/epics/componentsEval/selectorActionTypes";
import AppConfig from "../../../../utils/AppConfig";
import makeStateSelectorReducer from '../../../../epics/makeStateSelectorReducer';
import { PartnerName, partnerIsSiteTemplates } from "../../../../../../server/shared/partners/PartnerName.js";

const appConfigEpic = makeEpic({
    valueActionType,
    defaultState: getAppConfig(),
    updaters: [
        {
            // TODO remove in WBTGEN-4990
            conditions: [ReceiveOnlyComponentsMap, "EPIC_CRASH"],
            reducer: () => {
                throw new Error(`WBTGEN epic crash`);
            }
        }
    ]
});

const contactFormAllowArbitraryEmailReducer = makeStateSelectorReducer(
    appConfigEpic.reducer,
    valueActionType,
    appConfig => AppConfig(appConfig).get("oneWeb.contactForm.allowArbitraryEmail")
);

const withSSLReducer = makeStateSelectorReducer(
    appConfigEpic.reducer,
    valueActionType,
    appConfig => AppConfig(appConfig).get("oneWeb.publish.withSSL")
);

const isOneComPartnerReducer = makeStateSelectorReducer(
    appConfigEpic.reducer,
    valueActionType,
    appConfig => AppConfig(appConfig).get("partnerName") === PartnerName.ONECOM
);

const isSiteTemplatePartnerReducer = makeStateSelectorReducer(
    appConfigEpic.reducer,
    valueActionType,
    appConfig => partnerIsSiteTemplates(appConfig.partnerName)
);

export {
    appConfigEpic as default,
    withSSLReducer,
    contactFormAllowArbitraryEmailReducer,
    isOneComPartnerReducer,
    isSiteTemplatePartnerReducer
};
