// @ts-nocheck
// TODO: flow

import * as colorMapper from "../color";
import { getAssetUrl } from '../../utils/assetUtils';
import backgroundMapperConfig from "../block/background";
import configMappers from '../configMappers';
import type { Background, BgCss, Alpha } from "./flowTypes";
import { fadePointToStops } from "../../../dal/pageMapAdapter/mappers/Common/gradient";
import { DefaultGradient } from "../../components/presentational/ColorProperty/constants";
import { getAlpha } from '../../components/oneweb/Background/utils/index';
import { scrollEffects } from "../../components/oneweb/commonView/ScrollEffects/constants";

// TODO: test

type Options = {
    assetUrlQuery?: Record<string, any>,
};

const BackgroundImageStyles = ['backgroundImage', 'backgroundRepeat', 'backgroundPosition', 'backgroundSize',
    'backgroundAttachment', 'backgroundImageOpacity'];

const getBackgroundImageStyles = (styles: Record<string, any>) => {
    const allStyles = Object.keys(styles).filter(style => /(Radius)$/.test(style) || BackgroundImageStyles.includes(style));
    return allStyles.reduce((result, style) => {
        if (style === 'backgroundImageOpacity') {
            return { ...result, opacity: styles[style] };
        }
        return { ...result, [style]: styles[style] };
    }, {});
};

const toCss = (background: null | undefined | Background, options: Options = {}): BgCss => {
    if (!background) return null;

    const
        style = {},
        { colorData = {}, assetData } = background,
        { color, gradient } = colorData;

    style.backgroundImage = (assetData && assetData.asset) || gradient ? [] : 'none';
    if (color && !gradient) style.backgroundColor = colorMapper.toCss(color);

    if (gradient && gradient.color) {
        const
            solidColor = color || DefaultGradient.color,
            { color: gradientColor, fadePoint, degree } = gradient,
            stopValues = fadePointToStops(fadePoint),
            stops = [{ color: gradientColor, stop: stopValues[0] }, { color: solidColor, stop: stopValues[1] }],
            cssStops = stops.map(s => `${colorMapper.toCss(s.color)} ${s.stop}`).join(', ');

        style.backgroundImage.push(`linear-gradient(${degree}deg, ${cssStops})`);

        /**
         * This has been added because of the specific case of table, but does not implacts other clients
         * using it.
         */
        if (!color) style.backgroundColor = 'transparent';
    }

    if (assetData && assetData.asset) {
        const
            bgMapper = configMappers(backgroundMapperConfig),
            {
                asset,
                repeat,
                position,
                size,
                scrollEffect,
                opacity
            } = assetData;

        // asset image should be on top, that's why shift
        style.backgroundImage.unshift(`url("${getAssetUrl(asset, options.assetUrlQuery)}")`);
        if (repeat) style.backgroundRepeat = bgMapper.repeat.toCss(repeat);
        if (position) style.backgroundPosition = bgMapper.position.toCss(position);
        if (size) style.backgroundSize = bgMapper.size.toCss(asset, size);
        style.backgroundAttachment = scrollEffect === scrollEffects.reveal ? 'fixed' : 'scroll';
        style.backgroundImageOpacity = opacity;
    }

    return style;
};

const toAlpha = (background: Background, defaultAlpha: Alpha): Alpha => {
    let alpha = defaultAlpha;

    if (background) {
        const { colorData: { color, gradient } = {} } = background;
        if (color && !gradient) {
            alpha = getAlpha(color);
        } else if (gradient) {
            alpha = getAlpha(gradient.color);
        }
    }

    return alpha;
};

const toAlphaWithAutoColorMode = (
    background: Background,
    gradientTheme,
    defaultAlpha: Alpha
): Alpha => {
    let alpha = defaultAlpha;

    if (background) {
        const { colorData: { color, gradient } = {} } = background;
        if (color && !(gradient && gradientTheme)) {
            alpha = getAlpha(color);
        } else if (gradientTheme && gradient) {
            alpha = getAlpha(gradient.color);
        }
    }

    return alpha;
};

export { toCss, toAlpha, toAlphaWithAutoColorMode, BackgroundImageStyles, getBackgroundImageStyles };
