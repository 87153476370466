import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FETCH_BOOKINGS_STATE, RESET_BOOKINGS_INSERTER } from "./epic/actionTypes";
import { selectBookingsInserterView, selectIsWaitingForBookingsAPI } from "./epic/selectors";
import LoadingIndicator from "../../../../view/common/LoadingIndicator";
import styles from "./expandedPanelContent.css";
import { BookingsInserterViews } from "./inserterConfig";
import VerticalSpacer from "../../../../view/common/VerticalSpacer";

const BookingsViews = (): AnyReactElement => {
    const dispatch = useDispatch();

    const isWaitingForAPI = useSelector(selectIsWaitingForBookingsAPI);
    const inserterView = useSelector(selectBookingsInserterView);

    const View = BookingsInserterViews[inserterView];

    useEffect(() => {
        // call bookings api to get the information on which view to display
        dispatch({
            type: FETCH_BOOKINGS_STATE
        });

        // on dismount reset the inserter state
        return () => {
            dispatch({ type: RESET_BOOKINGS_INSERTER });
        };
    }, [dispatch]);

    return !isWaitingForAPI ? (
        <div className={styles.panelContainer}>
            <VerticalSpacer y={24} />

            <View />
        </div>
    ) : (
        <div className={styles.loadingIndicator}>
            <LoadingIndicator data-testid="loader" />
        </div>
    );
};

export { BookingsViews };
