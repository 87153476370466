// @ts-nocheck
import COPY_TO_CLIPBOARD from './actionTypes';
import { DELETE_SELECTED_COMPONENTS } from '../../../components/Workspace/actionTypes';

export default (store: Store) => (next: Dispatch) => (action: Action) => {
    if (action.type !== COPY_TO_CLIPBOARD) {
        return next(action);
    }

    const { payload } = action;
    const el = document.createElement('span');
    const range = document.createRange();
    const selection = document.getSelection();

    // Create an element, insert payload contents, and try to copy

    el.style.position = 'absolute';
    el.style.opacity = 0;
    el.style.userSelect = 'text';
    el.style.whiteSpace = 'pre';
    el.textContent = payload.contents;

    document.body.appendChild(el);
    range.selectNode(el);
    selection.removeAllRanges();
    selection.addRange(range);

    try {
        const copied = document.execCommand('copy');    // NOSONAR

        if (copied && payload.deleteComponents) {
            store.dispatch({ type: DELETE_SELECTED_COMPONENTS });
        }
    } catch (e: any) {
        // Could not copy
    }

    document.body.removeChild(el);

    return next(action);
};
