/* global TraceKit, CONFIG */

import tracking from '@one.com/tracking-analytics/lib/tracking'; // eslint-disable-line n/file-extension-in-import
import { getAppConfig } from './components/App/epics/appConfig/appConfig.js';
import isTestEnv from './debug/isTestEnv';

const appConfig = getAppConfig();

global.CONFIG = {
    crashReporter: appConfig.crashReporter,
    appEventCollector: appConfig.appEventCollector
};

if (!isTestEnv()) {
    require('../../http-pub/3rdparty/crashreporter/3rdparty/tracekit.js');

    require('../../http-pub/3rdparty/crashreporter/crashReporter.js');

    require('../../http-pub/3rdparty/crashreporter/crashLogger.js');
    // require('../../http-pub/3rdparty/integrateCrashReporterWithTraceKit.js');
    const { reportError } = require("./reportError");

    // @ts-ignore
    TraceKit.report.subscribe(function (traceKitError) {
        reportError(traceKitError);
        // @ts-ignore
    }, !CONFIG.crashReporter.disabled);
    setInterval(function () {
        tracking.sendHeartbeat();
    }, appConfig.appEventCollector.heartbeatInterval);
    tracking.sendHeartbeat();
}
