import React from 'react';
import * as styles from "./PropertiesPanel.css";
import { injectIntl } from "../../../view/intl/index";
import { NoMouseDownAndUp } from "../../../view/common/NoMouseEventsPropagation";
import { MCTA_ON_MOUSE_LEAVE } from "../../../view/Workspace/Decorations/ComponentMainActions/actionTypes";
import { SHOW_TOOLTIP_FOR_HEADER_FOOTER_SECTION } from "../../Tooltip/actionTypes";
import { SHOW_BLANK_HEADER_FOOTER_TOOLTIP } from "../../oneweb/Section/epics/blankHeaderFooter/actionTypes";

const
    MenuIconStyle = {
        transition: 'background 100ms',
    };

const ContextInfoIcon = injectIntl(({
    onClick,
    dispatch,
    MCTA,
    isHeaderSection,
    isFooterSection,
    isHeaderBlank,
    isFooterBlank
}) => {
    const iconRef = React.useRef<HTMLSpanElement>(null);
    const iconProps = {
        className: `${styles.infoIcon}`,
        style: MenuIconStyle
    };
    let props: Record<string, any> = {
        className: styles.infoIconContainer
    };

    let calcPosition = ({ top, width, height, left }: DOMRect) => {
        return { x: left + (width / 2) - 1, y: top + height };
    };

    if (iconRef.current) {
        const
            clientRect = iconRef.current.getBoundingClientRect(),
            { top, width, height, left } = clientRect || {};
        props = {
            ...props,
            onClick: onClick || ((e) => {
                e.stopPropagation();
                dispatch({
                    type: SHOW_TOOLTIP_FOR_HEADER_FOOTER_SECTION,
                    payload: {
                        position: calcPosition(clientRect),
                        elementDimensions: { top, width, height, left },
                        isHeaderSection,
                    }
                });
            }),
        };
        if ((isHeaderSection && isHeaderBlank) || (isFooterSection && isFooterBlank)) {
            dispatch({
                type: SHOW_BLANK_HEADER_FOOTER_TOOLTIP,
                payload: clientRect
            });
        }
    }

    if (MCTA) {
        props = {
            ...props,
            ...NoMouseDownAndUp,
            onMouseLeave: () => dispatch({ type: MCTA_ON_MOUSE_LEAVE })
        };
    }

    return (
        <div {...props}>
            <span {...iconProps} ref={iconRef} />
        </div>
    );
});

export {
    ContextInfoIcon as default,
    ContextInfoIcon
};

