import * as R from "ramda";
import React from "react";
import { connect } from "react-redux";
import cx from "classnames";
import styles from "./ExpandedPanelText.css";
import inserterStyle, { extendedPanelBGColor, contrastBgColor } from "../../../Panel/view/Inserter.css";
import { getAllStylesheets, getStylesByType, stylesheetAppSel } from "../../../Workspace/epics/stylesheets/selectors";
import TextGlobalStyleKind from "../globalStyle/kind";
import {
    types,
    globalStyleLabels,
    globalStyleTextClass,
    DefaultComponentDimensions
} from "../../../Globalstyles/Text/constants";
import BaseComponentPreview from "../../../../view/oneweb/BaseComponentPreview";
import { makeEpicStateSelector } from "../../../../epics/makeEpic";
import { stylesheetIdToNameMapVAT } from "../../../Workspace/epics/stylesheets/idToNameMapValueActionTypes";
import componentsEvalValueActionType from "../../../Workspace/epics/componentsEval/valueActionType";
import { DroppableCmpShortcut } from "../../../Panel/view/DroppableCmpShortcut";
import TextKind from "../kind";
import { EXTENDED_PANEL_TEXT_STYLE_STATE_EPIC_VALUE } from "../epics/ExtendedPanelScaleStateEpic/valueActionType";
import VerticalSpacer from "../../../../view/common/VerticalSpacer";
import EPSeparator from "../../../Panel/view/EPSeparator";
import { EditGlobalStyle } from "../../../Panel/view/EditExtendedPanelContent";
import MeasuredText from "./MeasuredText";
import {
    EXTENDED_PANEL_TEXT_MEASURED_HEIGHT,
    EXTENDED_PANEL_TEXT_MEASURED_SIZE
} from "../epics/ExtendedPanelScaleStateEpic/actionTypes";
import { EXTENDED_PANEL_AVAILABLE_WIDTH } from "../../../Panel/constants";
import { getColorDistance } from "../../../../utils/colorUtils";
import {
    ALL_FILTER_KEY,
    FILTERS,
    TEXT_LAYOUTS,
    LISTS,
    TEXT_AND_IMAGE,
    BlocksCategorySequence,
    REVIEWS
} from "../epics/ExtendedPanelScaleStateEpic/constants";
import { getWindowLocationQuery } from "../../../../utils/getWindowLocationQuery";
import { globalVariablesEpic } from '../../../App/epics/globalVariablesEpic';
import Blocks, { BlocksByColumn } from "../../../Panel/configs/Blocks";
import ConstrastBgHelpTip from "../../../Panel/view/ConstrastBgHelpTip";
import HelpTip from "../../../Panel/view/HelpTip";
import useDelayHook from "../../../Panel/useDelayHook";
import LoadingIndicator from "../../../../view/common/LoadingIndicator/index";
import { colorThemeSiteSettingsEpic } from "../../../SiteSettings/ColorThemeData/colorThemeSiteSettingsEpic";
import { BACKGROUND_THEME_WHITE, THEME_TEXT_CLASS } from "../../../ThemeGlobalData/constants";
import SectionBlockError from "../../../Panel/view/SectionBLockError/index";
import SectionBlockErrorMessage from "../../../Panel/view/SectionBLockError/SectionBlockErrorMessage";
import SectionBlockErrorStyles from "../../../Panel/view/SectionBLockError/styles.css";

const tipMsg = 'msg: extendedPanel.textLayout {Add blocks with combinations of text styles. Your personal template styles will be added once you drop a block on the workspace.}'; // eslint-disable-line

const getAllTextStyles = (styleSheets) => {
    return R.pipe(
        getAllStylesheets,
        getStylesByType(TextGlobalStyleKind),
        R.filter(({ ref }) => ref !== types.LOGO)
    )(styleSheets);
};
export const showBGLayer = (txtColor: string) => {
    const threshold = 0.12;
    const d1 = getColorDistance(txtColor, extendedPanelBGColor),
        d2 = getColorDistance(txtColor, contrastBgColor);

    return d1 > threshold || d1 > d2;
};

const textTemplates = ({
    styleSheets,
    stylesheetsIdToNameMap,
    extendedPanelStyle = {},
    autoColorMode,
    ...rest
}) => {
    const
        globalTextStyles = getAllTextStyles(styleSheets),
        { intl, dispatch, category } = rest,
        textStyles = globalTextStyles.map(({ id, ref, color }, index) => {
            const
                text = intl.msgJoint(globalStyleLabels[ref]),
                dropTextContent =
                    intl.msgJoint('msg: component.text.initialText {Double-click here to add your own text.}'),
                className = globalStyleTextClass[ref],
                { scale, scaledHeight } = extendedPanelStyle[ref] || {},
                cmpDefaultWidth = DefaultComponentDimensions.width[ref];

            let cmpDefaultHeight = DefaultComponentDimensions.height[ref],
                actualTextContainerStyle = {},
                actualTextStyle = {};
            if (scale) {
                actualTextContainerStyle = {
                    width: EXTENDED_PANEL_AVAILABLE_WIDTH,
                    height: scaledHeight
                };
                actualTextStyle = {
                    transform: `scale(${scale})`,
                    transformOrigin: '0 0'
                };
            }

            if (extendedPanelStyle[ref] && extendedPanelStyle[ref].height &&
                extendedPanelStyle[ref].height > cmpDefaultHeight) {
                cmpDefaultHeight = extendedPanelStyle[ref].height;
            }

            const payload = {
                    globalStyleId: id,
                    content: `<p class="${className}">
                        ${dropTextContent}
                    </p>`,
                    width: cmpDefaultWidth,
                    height: cmpDefaultHeight,
                },
                showContrastBgColor = !autoColorMode && !showBGLayer(color);

            return (
                // @ts-ignore
                <DroppableCmpShortcut
                    key={index}
                    kind={TextKind}
                    className={cx(styles.item,
                        { [inserterStyle.contrastBgLayer]: showContrastBgColor })}
                    payload={payload}
                    isOverlayExtended
                    {...rest}
                >
                    {showContrastBgColor && <ConstrastBgHelpTip dispatch={dispatch} />}

                    <BaseComponentPreview
                        globalStyleIds={[stylesheetsIdToNameMap[id]]}
                        className={cx(
                            styles.textPreview,
                            styles.inserter,
                            { [`${BACKGROUND_THEME_WHITE} ${THEME_TEXT_CLASS}`]: autoColorMode }
                        )}
                    >
                        <div style={actualTextContainerStyle}>
                            <div style={actualTextStyle}>
                                {text}
                            </div>
                        </div>
                        <MeasuredText
                            dispatch={dispatch}
                            className={styles.duplicateTextPreview}
                            onResize={
                                ({ width, height }) =>
                                    dispatch({
                                        type: EXTENDED_PANEL_TEXT_MEASURED_SIZE,
                                        payload: { width, height, textStyleName: ref }
                                    })
                            }
                        >
                            {text}
                        </MeasuredText>
                        <MeasuredText
                            dispatch={dispatch}
                            className={cx(styles.duplicateTextPreview, styles.dropText, className)}
                            onResize={
                                ({ height }) =>
                                    dispatch({
                                        type: EXTENDED_PANEL_TEXT_MEASURED_HEIGHT,
                                        payload: { height, textStyleName: ref }
                                    })
                            }
                            width={cmpDefaultWidth}
                        >
                            {dropTextContent}
                        </MeasuredText>
                    </BaseComponentPreview>
                </DroppableCmpShortcut>
            );
        });

    return (
        <div key={category.title}>
            <VerticalSpacer y={23} />
            <EditGlobalStyle globalStyleKind={TextGlobalStyleKind} intl={intl} dispatch={dispatch}>
                <h3 className={inserterStyle.categoryHeader}>
                    {intl.msgJoint(category.title)}
                </h3>
            </EditGlobalStyle>
            {textStyles}
        </div>
    );
};

const PanelContentView = ({
    styleSheets,
    stylesheetsIdToNameMap,
    extendedPanelStyle = { filterKey: undefined },
    componentsEval,
    globalVariables,
    themeSettingsData: { autoColorMode },
    intl,
    dispatch
}) => {
    // Time for animation if 0.2s so that by the time animation completes it will render
    const canRender = useDelayHook(200);
    if (!canRender) {
        return <LoadingIndicator className={inserterStyle.loadingIndicator} />;
    }

    const
        filterKey = extendedPanelStyle.filterKey,
        // @ts-ignore
        blocksByCategory = extendedPanelStyle.blocks;

    let categories = filterKey === ALL_FILTER_KEY ?
        R.tail(FILTERS) : FILTERS.filter(({ id }) => id === filterKey);

    //TODO:: Remove once we are ready to release this.
    const count = getWindowLocationQuery().blocks || 1;
    const blocksDyanmic = (new Array(Number(count))).fill(categories).reduce((acc, value) => {
        acc.push(...value);
        return acc;
    }, []);

    return [<div key={0} className={inserterStyle.panelWithFilter}>{
        blocksDyanmic.map((category, index) => {
            if (category.showStylesheetStyles) {
                return textTemplates({
                    styleSheets,
                    stylesheetsIdToNameMap,
                    extendedPanelStyle,
                    componentsEval,
                    intl,
                    dispatch,
                    category,
                    autoColorMode
                });
            }

            const
                filteredCategory = blocksByCategory.find(categoryObj => categoryObj.key === category.id);

            if (!filteredCategory) return null;

            if (category.id === TEXT_AND_IMAGE) {
                const blocks = BlocksCategorySequence[TEXT_AND_IMAGE](filteredCategory.items);
                return <BlocksByColumn
                    showSeperator={index !== 0}
                    key={category.id}
                    category={category}
                    leftBlocks={blocks.left}
                    rightBlocks={blocks.right}
                    componentsEval={componentsEval}
                    styleSheets={styleSheets}
                    globalVariables={globalVariables}
                    intl={intl}
                    dispatch={dispatch}
                />;
            }

            if (category.id === LISTS) {
                const blocks = BlocksCategorySequence[LISTS](filteredCategory.items);
                return <React.Fragment key="textListCategoryParent">
                    <BlocksByColumn
                        showSeperator={index !== 0}
                        key={category.id}
                        category={category}
                        leftBlocks={blocks.left}
                        rightBlocks={blocks.right}
                        componentsEval={componentsEval}
                        styleSheets={styleSheets}
                        globalVariables={globalVariables}
                        intl={intl}
                        dispatch={dispatch}
                        verticallyCenter
                        canTransparentBackground
                    />
                    <Blocks
                        noHeader
                        showSeperator={false}
                        showSeperatorBetweenBlocks={false}
                        key={category.id + "by_row"}
                        category={category}
                        noMultiColumn
                        blocks={blocks.row}
                        componentsEval={componentsEval}
                        styleSheets={styleSheets}
                        globalVariables={globalVariables}
                        intl={intl}
                        dispatch={dispatch}
                    />
                </React.Fragment>;
            }

            return <React.Fragment key={`layout-${category.id}`}>
                {
                    category.id === TEXT_LAYOUTS && <div
                        key="1"
                        className={cx(
                            inserterStyle.contactHelpTip,
                            {
                                [styles.textLayoutHelpIcon]: filterKey === ALL_FILTER_KEY
                            }
                        )}
                    >
                        <HelpTip
                            msg={tipMsg}
                            dispatch={dispatch}
                        />
                    </div>
                }
                <Blocks
                    showSeperator={index !== 0}
                    showSeperatorBetweenBlocks={category.id === TEXT_LAYOUTS}
                    canTransparentBackground={category.id === REVIEWS}
                    key={category.id}
                    category={category}
                    noMultiColumn={category.id === TEXT_LAYOUTS}
                    blocks={filteredCategory ? filteredCategory.items : []}
                    componentsEval={componentsEval}
                    styleSheets={styleSheets}
                    globalVariables={globalVariables}
                    intl={intl}
                    dispatch={dispatch}
                    autoColorMode={autoColorMode}
                />
            </React.Fragment>;
        })
    }</div>,
    !blocksByCategory.length &&
    (
        <div key={1}>
            <VerticalSpacer y={27} />
            <EPSeparator />
            <VerticalSpacer y={27} />
            <SectionBlockError
                containerClass={SectionBlockErrorStyles.textSectionsErrorContainer}
                iconClass={SectionBlockErrorStyles.textSectionsErrorIconPlaceholder}
            >
                <SectionBlockErrorMessage intl={intl} dispatch={dispatch} />
            </SectionBlockError>
        </div>
    )
    ];
};

const mapStateToProps = (appState) => ({
    styleSheets: stylesheetAppSel(appState),
    stylesheetsIdToNameMap: makeEpicStateSelector(stylesheetIdToNameMapVAT)(appState),
    extendedPanelStyle: makeEpicStateSelector(EXTENDED_PANEL_TEXT_STYLE_STATE_EPIC_VALUE)(appState),
    componentsEval: makeEpicStateSelector(componentsEvalValueActionType)(appState),
    globalVariables: makeEpicStateSelector(globalVariablesEpic.valueActionType)(appState),
    themeSettingsData: makeEpicStateSelector(colorThemeSiteSettingsEpic.valueActionType)(appState),
});

// @ts-ignore
const TextTemplateView = connect(mapStateToProps)(PanelContentView);
export { TextTemplateView };
