import { $Values } from "utility-types";
import * as R from 'ramda';
import { createSelector } from 'reselect';
import Resource from "./Resource";
import { FmGridSortOptionValue, SortColumns, SortDirections, sortFunctions } from "./sortFunctions";
import type { FileManagerState } from './flowTypes';
import { testFcContentType, testFcDirectoryContentType } from "./utils/testFcContentType";
import pathUtil from "../../../../../utils/path.js";
import type { AppState } from "../../flowTypes";
import type { AppStateSel } from "../../../../globalTypes";
import { FileManagerMode } from "../../../../components/FileManager/constants";
import { hiddenEditedImagePattern } from "../../../../components/ImageEditor/constants";
import { PUBLISH_TEMP_FOLDER_PREFIX } from '../../../../../../server/shared/constants.js';
import { FcContentTypes } from "./FcContentTypes";
import { FmTabId } from "../../../../components/FileManager/dialog/constants";

const filterContentType = (contentType: string, contentTypePatterns) => {
    if (testFcDirectoryContentType(contentType)) return false;
    return !testFcContentType(contentType, contentTypePatterns);
};

const selectionSelector = (fileChooser: FileManagerState) => fileChooser.selection;

const resourcesSelector = createSelector(
    (fileChooser: FileManagerState) => fileChooser,
    (fileChooser: FileManagerState) => {
        const isFreeOneComVideos = fileChooser.activeTabId === "freeOnecom" &&
            fileChooser.freeOneComVideos && fileChooser.freeOneComVideos.length;
        const {
                resources,
                currentPath,
                sort: { currentColumn: sortColumn, currentDirectoryColumn: dirSortColumn, columns },
                contentTypes
            } = fileChooser,
            direction = columns[sortColumn],
            dirDirection = columns[dirSortColumn],
            collection: Resource[] = [],
            dirs: Resource[] = [];

        const finalResources = isFreeOneComVideos ? fileChooser.freeOneComVideos : resources;

        const
            collectionSortFn = (a: Resource, b: Resource) => direction * sortFunctions[sortColumn](a, b),
            dirSortFn = (a: Resource, b: Resource) => dirDirection * sortFunctions[dirSortColumn](a, b);

        finalResources.forEach((resRaw: { [any: string]: any }) => {
            // Adding this condition of excluding php files from the file chooser here as this function is already doing the filtering href and contentType
            // This is required to avoid linking of php files to any button or link in the content as the php pages requires input parameters to be passed
            if (resRaw.href === '.' || resRaw.href === '..' || (resRaw.href || '').endsWith(".php") || filterContentType(resRaw.contentType, contentTypes)) {
                return;
            } else if (pathUtil.trim(resRaw.href) === 'onewebstatic') { // skip onewebstatic
                return;
            } else if (pathUtil.trim(resRaw.href).indexOf(PUBLISH_TEMP_FOLDER_PREFIX) === 0) { // skip temp publish dirs
                return;
            } else if (fileChooser.mode === FileManagerMode.IMAGE_CHOOSER
                && hiddenEditedImagePattern.test(resRaw.href)) {
                return;
            } else if (fileChooser.activeTabId === FmTabId.MY_VIDEOS && filterContentType(resRaw.contentType, FcContentTypes.MP4_VIDEO)) {
                return;
            }

            const res = new Resource(currentPath, resRaw);

            if (testFcDirectoryContentType(resRaw.contentType)) {
                dirs.push(res);
            } else {
                collection.push(res);
            }
        });

        return [
            ...dirs.sort(dirSortFn),
            ...collection.sort(collectionSortFn)
        ];
    }
);

const ghostResourcesSelector = createSelector(
    (fileChooser: FileManagerState) => fileChooser,
    (fileChooser: FileManagerState) => {
        const {
                ghostResources,
                currentPath,
                sort: { currentColumn: sortColumn, currentDirectoryColumn: dirSortColumn, columns },
                contentTypes
            } = fileChooser,
            direction = columns[sortColumn],
            dirDirection = columns[dirSortColumn],
            collection: Resource[] = [],
            dirs: Resource[] = [];

        const
            collectionSortFn = (a: Resource, b: Resource) => direction * sortFunctions[sortColumn](a, b),
            dirSortFn = (a: Resource, b: Resource) => dirDirection * sortFunctions[dirSortColumn](a, b);

        ghostResources.forEach((resRaw: { [any: string]: any }) => {
            if (resRaw.href === '.' || resRaw.href === '..' || filterContentType(resRaw.contentType, contentTypes)) {
                return;
            } else if (pathUtil.trim(resRaw.href) === 'onewebstatic') { // skip onewebstatic
                return;
            } else if (pathUtil.trim(resRaw.href).indexOf(PUBLISH_TEMP_FOLDER_PREFIX) === 0) { // skip temp publish dirs
                return;
            } else if (fileChooser.mode === FileManagerMode.IMAGE_CHOOSER
                && hiddenEditedImagePattern.test(resRaw.href)) {
                return;
            }

            const res = new Resource(currentPath, resRaw);

            if (testFcDirectoryContentType(resRaw.contentType)) {
                dirs.push(res);
            } else {
                collection.push(res);
            }
        });

        return [
            ...dirs.sort(dirSortFn),
            ...collection.sort(collectionSortFn)
        ];
    }
);

const resourceFilesSelector = createSelector(
    resourcesSelector,
    (resources: Array<Resource>) => resources.filter(r => !r.isDirectory())
);

const previewResourceSelector = createSelector(
    resourcesSelector,
    selectionSelector,
    (resources: Array<Resource>, selection: Array<Resource>) => {
        let result: Resource | null | undefined = null;
        if (selection.length > 0) {
            // find last selected resource for currently loaded resources (mind navigate directories case)
            for (let i = selection.length - 1; i >= 0; i--) {
                result = resources.find((r: Resource) => r.id === selection[i].id);
                if (result) break;
            }
        }

        return result;
    }
);

const singleSelectedResourceSelector = createSelector(
    resourcesSelector,
    selectionSelector,
    (resources: Array<Resource>, selection: Array<Resource>) => {
        return selection.length > 0 ? selection[0] : null;
    }
);

const lastSelectedResourceIndexSelector = createSelector(
    resourcesSelector,
    selectionSelector,
    (resources: Array<Resource>, selection: Array<Resource>) => {
        if (!selection.length) return null;

        const selectedResource = R.last(selection);
        // eslint-disable-next-line unicorn/no-for-loop
        for (let i = 0; i < resources.length; i++) {
            if (resources[i].id === selectedResource.id) {
                return i;
            }
        }
        return null;
    }
);

const currentPathSelector = ({ currentPath }: FileManagerState) => currentPath;

const ghostResourceIndexSelector = createSelector(
    resourcesSelector,
    (resources: Array<Resource>) => {
        // eslint-disable-next-line unicorn/no-for-loop
        for (let i = 0; i < resources.length; i++) {
            if (resources[i].isGhost) {
                return i;
            }
        }
        return null;
    }
);

const isInitializedFcSelector = ({ fileChooser }: AppState) => fileChooser.isInitialized;

export const fcDialogActiveTabIdAppSel = (appState: AppState) =>
    appState.fileChooser.activeTabId;

export const fcDialogIsVideoDialog = (appState: AppState) =>
    appState.fileChooser.contentTypes && appState.fileChooser.contentTypes[0]
    && appState.fileChooser.contentTypes[0].startsWith(FcContentTypes.VIDEO);

export const isMultiSelectFcAppSel = (appState: AppState) =>
    appState.fileChooser.isMaxMultiSelect;

export const
    fcModeAppSel: AppStateSel<$Values<typeof FileManagerMode>> = (appState: AppState) =>
        appState.fileChooser.mode;

export const fmGridSortOptionSel = (fmState: FileManagerState): null | undefined | string => {
    const
        {
            sort: {
                currentColumn,
                columns,
            },
        } = fmState,
        direction = columns[currentColumn];

    let res;

    if (currentColumn === SortColumns.NAME && direction === SortDirections.ASC) {
        res = FmGridSortOptionValue.NAME;
    } else if (currentColumn === SortColumns.ADDED) {
        res = direction === SortDirections.DESC ? FmGridSortOptionValue.NEWEST : FmGridSortOptionValue.OLDEST;
    }

    return res;
};

export const fcSingleSelectedResourceAppSel = (appState: AppState) =>
    singleSelectedResourceSelector(appState.fileChooser);

export {
    selectionSelector,
    resourcesSelector,
    ghostResourcesSelector,
    resourceFilesSelector,
    previewResourceSelector,
    singleSelectedResourceSelector,
    lastSelectedResourceIndexSelector,
    currentPathSelector,
    ghostResourceIndexSelector,
    isInitializedFcSelector
};
