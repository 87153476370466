import React from 'react';
import cx from 'classnames';
import styles from './Dialog.css';
import CloseBtn from './DialogCloseBtn';

export type PropTypes = {
    className?: string,
    containerClassName?: string,
    style?: Record<string, any>,
    showCloseBtn?: boolean,
    applyOldStyleForCloseBtn?: boolean,
    closeBtnClassName?: string,
    onClose?: () => void,
    stripType?: string | null,
    children?: React.JSX.Element | React.JSX.Element[],
}

export const StripTypes = {
    INFO: styles.stripInfo,
    WARNING: styles.stripWarning,
    ADVISORY: styles.stripAdvisory,
    NONE: null
};

class Dialog extends React.Component<PropTypes> {
    render() {
        const {
            style,
            className = '',
            containerClassName = '',
            showCloseBtn = true,
            applyOldStyleForCloseBtn = false,
            closeBtnClassName = '',
            onClose,
            stripType = StripTypes.INFO,
            children
        } = this.props;

        return (
            <div className={cx(styles.dialog, className)} style={style} role="dialog">
                {stripType && <div className={cx(styles.strip, stripType)} />}
                {showCloseBtn && <CloseBtn
                    stripType={stripType}
                    applyOldStyleForCloseBtn={applyOldStyleForCloseBtn}
                    className={closeBtnClassName}
                    onClick={onClose}
                />}
                <div className={cx(styles.container, containerClassName)}>
                    {children}
                </div>
            </div>
        );
    }
}

export default Dialog;
