import React from "react";
import cx from 'classnames';
import * as R from 'ramda';
import Image from '../../Image/view/Image';
import type { BackgroundViewProps, BgImageStylesType, BgImageStylesMVEType } from '../flowTypes';
import imgStyles from '../../Image/view/Image.css';
import { debugComponentsBbox } from '../../../../utils/isDebug';
import { BboxDebugger } from '../../../Debug/BboxDebugger/BboxDebugger';
import { BackgroundImageStyles, getBackgroundImageStyles } from "../../../../mappers/background/index";
import parallaxStyle from '../../Strip/view/parallax.css';
import { scrollEffects } from "../../commonView/ScrollEffects/constants";
import bgStyles from "./Background.css";
import { DefaultSectionContent } from "../../Section/view/DefaultSectionContent";
import { isBoxWithNoAssetOrSmallSize } from "../utils";
import { isBoxKind } from "../../componentKinds";
import { LazyBackgroundImage } from "./lazyBackgroundImage";

const mveEditModeLayOverStyles = {
    zIndex: 10000000001,
    cursor: 'move'
};

type StylesObject = {
    backgroundComponent: string;
    hoverBoxComponent: string;
    bgHeight: string;
    bgImageContainer: string;
}

export default (styles: StylesObject) => (props: BackgroundViewProps) => {
    const {
        isWorkspace,
        isMVEFocus,
        isMVEEditMode,
        selectedCmpBgPosition = [],
        selectedCmpId,
        component,
        style,
        showMissingImage,
        width,
        height,
        children,
        kind,
        base,
        asset,
        scrollEffect,
        className,
        disableSectionBgImage,
        disableSectionBgColor,
        defaultHeaderFooter,
        isHeaderSection,
        paddingBottom,
        modernLayout,
        mhfCmpStyle,
        url,
        videoStyle,
        dimVideoBg,
        hasSharedBg,
    } = props;
    let updatedStyle = style ? { ...style } : {};
    let bgImageStyle: BgImageStylesType = getBackgroundImageStyles(updatedStyle),
        hasBgImage = bgImageStyle.backgroundImage && bgImageStyle.backgroundImage !== "none",
        cmpId = component && component.id,
        isMVEBgEditMode = isMVEFocus && isMVEEditMode && selectedCmpId === cmpId,
        bgImageStyleMVE: BgImageStylesMVEType = { ...bgImageStyle, ...mveEditModeLayOverStyles },
        videoStyleMVE = { ...videoStyle, ...mveEditModeLayOverStyles };

    if (isMVEBgEditMode) {
        bgImageStyleMVE = {
            ...bgImageStyleMVE,
            backgroundPosition: `${selectedCmpBgPosition[0]} ${selectedCmpBgPosition[1]}`
        };
        videoStyleMVE = {
            ...videoStyleMVE,
            objectPosition: `${selectedCmpBgPosition[0]} ${selectedCmpBgPosition[1]}`
        };
    }

    updatedStyle = R.omit(BackgroundImageStyles, updatedStyle);
    updatedStyle.minHeight = height;
    if (mhfCmpStyle && mhfCmpStyle.height) {
        updatedStyle.minHeight = mhfCmpStyle.height;
    }
    if (!isWorkspace) {
        updatedStyle.paddingBottom = paddingBottom;
        if (modernLayout) {
            updatedStyle.display = "flex";
        }
    }
    const _isBoxWithNoAssetOrSmallSize = isBoxWithNoAssetOrSmallSize({ kind, asset, width, height }),
        emptyBoxWithAsset = isBoxKind(kind) && !children && !_isBoxWithNoAssetOrSmallSize;
    if (emptyBoxWithAsset) {
        updatedStyle.maxWidth = width;
    }
    let composeClassName = cx(
            styles.backgroundComponent,
            styles.hoverBoxComponent,
            className,
            {
                [styles.bgHeight]: isBoxKind(kind) && _isBoxWithNoAssetOrSmallSize,
                hasChildren: !!children,
                emptyBoxWithAsset,
                [bgStyles.disableSectionBgColor]: disableSectionBgColor,
            }
        ),
        bgImageClassName = cx(styles.bgImageContainer, {
            [parallaxStyle.workspace]: isWorkspace,
            [parallaxStyle.revealEffect]: scrollEffect === scrollEffects.reveal,
            [parallaxStyle.parallaxBG]: scrollEffect === scrollEffects.parallax,
            [bgStyles.disableSectionBgImage]: disableSectionBgImage,
            [bgStyles.disableSectionBgColor]: disableSectionBgColor,
        });

    let dim = dimVideoBg ? 'rgba(0, 0, 0, 0.5)' : 'rgba(0, 0, 0, 0)';
    dim = disableSectionBgColor ? 'none' : dim;

    return (
        <div className={composeClassName} style={updatedStyle} data-width={width} data-height={height}>
            {
                url &&
                <div className={cx(parallaxStyle.scrollEffectContainer, { "sectionSharedBg": hasSharedBg })}>
                    <div style={{ width: '100%', height: '100%', opacity: bgImageStyle.opacity }}>
                        <div style={{
                            backgroundColor: dim,
                            position: 'relative',
                            height: '100%'
                        }}
                        />
                        <video
                            controls={false}
                            autoPlay={!isWorkspace && !isMVEFocus}
                            playsInline
                            muted
                            loop
                            src={url}
                            style={isMVEBgEditMode ? videoStyleMVE : videoStyle}
                            data-mve={isMVEBgEditMode ? "true" : "false"}
                        />
                    </div>
                </div>
            }
            { !url && hasBgImage && !showMissingImage && (
                <div className={cx(parallaxStyle.scrollEffectContainer, { "sectionSharedBg": hasSharedBg })}>
                    {
                        isWorkspace ?
                            <div
                                data-scroll-effect={scrollEffect}
                                className={bgImageClassName}
                                style={bgImageStyle}
                                data-opacity="true"
                            /> :
                            <React.Fragment>
                                <LazyBackgroundImage
                                    kind={kind}
                                    backgroundImage={bgImageStyle.backgroundImage}
                                    data-scroll-effect={scrollEffect}
                                    className={bgImageClassName}
                                    style={bgImageStyle}
                                    data-opacity="true"
                                />
                                {isMVEBgEditMode && <div
                                    data-scroll-effect={scrollEffect}
                                    className={bgImageClassName}
                                    style={bgImageStyleMVE}
                                    data-opacity="true"
                                    data-mve="true"
                                />}
                            </React.Fragment>
                    }
                </div>
            )}
            {debugComponentsBbox() && base.debugBbox && <BboxDebugger {...base.debugBbox} />}
            {children}
            {
                showMissingImage &&
                <Image
                    imageAvailable={!showMissingImage}
                    divClassName={cx(imgStyles.imageComponent, { [`${imgStyles.imgNotFound}`]: showMissingImage })}
                    width={width}
                    height={height}
                    isWorkspace
                />
            }
            {
                defaultHeaderFooter &&
                ((defaultHeaderFooter.isHeader && isHeaderSection) || (defaultHeaderFooter.isFooter && !isHeaderSection))
                && (
                    <DefaultSectionContent isHeader={isHeaderSection} />
                )
            }
        </div>
    );
};
