import cx from "classnames";
import styles from "./BlogManagementDialog.css";

import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { blogTypes } from "../../../../../../../server/shared/blog/utils.js";
import { ErrorMessage } from "../../../../../../../src/dashboard/src/components/Error/ErrorBoundary/ErrorMessage";
import { RenderWhen } from "../../../../../../../src/dashboard/src/components/Helpers/RenderWhen";
import { Loader } from "../../../../../../../src/dashboard/src/components/Loader/Loader";
import { getDAL } from '../../../../../../dal';
import { getLoginEmail } from "../../../../../../utils/loginData";
import { makeEpicStateSelector } from '../../../../../epics/makeEpicStateSelector';
import { Dialog, DialogBody, StripTypes } from "../../../../../view/common/dialogs/baseDialog";
import closeDialogAC from "../../../../App/actionCreators/closeDialog";
import { getAppConfig } from "../../../../App/epics/appConfig/appConfig.js";
import { colorGlobalDataEpic } from '../../../../ColorPicker/epic/colorGlobalDataEpic';
import getCenteredDialogConfig from "../../../../DialogManager/getCenteredDialogConfig";
import { getCenterPositionForDialog } from "../../../../DialogManager/utility";
import { getLocaleCookie } from "../../../../Locale/cookie";
import { colorThemeDataEpicStateFromAppStateSelector } from '../../../../SiteSettings/ColorThemeData/selectorActionTypes';
import { stylesheetAppSel } from "../../../../Workspace/epics/stylesheets/selectors";
import { getRecentColorData, getThemeColorData } from '../../utils';
import { BLOG_DIALOG_HEIGHT_OFFSET } from "../constants";
import { Message, MessageHandler } from './message';
import { subscriptionDataSelector } from "../../../../App/epics/subscriptionData/selectors";
import { currentLanguageAppSel } from "../../../../TopBar/epics/languages/selectors";

const IFRAME_LOAD_TIMEOUT_MS = 30000;

const buildIframeSrc = async (retries = 2, interval = 100) => {
    try {
        const { oneWeb: { blog: { hostname } } } = getAppConfig();
        const { body: { blogToken } } = await getDAL().getBlogToken();
        return `${hostname}/app?authToken=${encodeURIComponent(blogToken)}`;
    } catch (error) {
        if (retries <= 0) {
            throw error;
        }
        await new Promise<void>((resolve) => {
            setTimeout(() => resolve(), interval);
        });
        const src = await buildIframeSrc(retries - 1, interval + 10);
        return src;
    }
};

const BlogManagementDialog = () => {
    const dispatch = useDispatch();

    const iframeTimer = useRef<NodeJS.Timeout | undefined>();

    const stylesheets = useSelector(stylesheetAppSel);
    const themeSettings = useSelector(colorThemeDataEpicStateFromAppStateSelector);
    const colorGlobalDataState = useSelector(makeEpicStateSelector(colorGlobalDataEpic.valueActionType));

    const { subscriptionType } = useSelector(subscriptionDataSelector);

    const editorLanguage = useSelector(currentLanguageAppSel);

    const [reloadId, setReloadId] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<string>();
    const [handler, setHandler] = useState<MessageHandler | undefined>();
    const [iframeSrc, setIframeSrc] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(true);
    const [failed, setFailed] = useState<boolean>(false);

    const showCloseBtn = useMemo(
        () => Boolean(failed || (loading && iframeSrc) || currentPage !== blogTypes.post),
        [loading, failed, iframeSrc, currentPage]
    );

    // get auth token and make manage blog app url
    useEffect(() => {
        setLoading(true);
        setFailed(false);
        setIframeSrc('');
        clearTimeout(iframeTimer.current);
        buildIframeSrc().then(setIframeSrc).catch(() => {
            setLoading(false);
            setFailed(true);
        });
    }, [reloadId]);

    // pass configuration to iframe on change
    useEffect(() => {
        if (!handler) return;
        handler.send(Message.AUTOCOLOR, themeSettings.autoColorMode);
    }, [handler, themeSettings.autoColorMode]);

    useEffect(() => {
        if (!handler) return;
        handler.send(Message.THEME_COLORS, getThemeColorData(stylesheets));
    }, [handler, stylesheets]);

    useEffect(() => {
        if (!handler) return;
        handler.send(Message.RECENT_COLORS, getRecentColorData(colorGlobalDataState));
    }, [handler, colorGlobalDataState]);

    useEffect(() => {
        if (!handler) return;
        handler.send(Message.LOCALE, getLocaleCookie());
    }, [handler]);

    useEffect(() => {
        if (!handler) return;
        const { oneWeb: { filemanager: { loginTarget } } } = getAppConfig();
        handler.send(Message.FILE_MANAGER_LINK, {
            loginTarget,
            username: getLoginEmail(),
            targetDomain: getDAL().getDomain(),
        });
    }, [handler]);

    useEffect(() => {
        if (!handler) return;
        handler.send(Message.SUBSCRIPTION_TYPE, subscriptionType);
    }, [handler, subscriptionType]);

    useEffect(() => {
        if (!handler) return;
        handler.send(Message.EDITOR_LANGUAGE, editorLanguage);
    }, [handler, editorLanguage]);

    const reloadIframe = () => {
        setReloadId(v => v + 1);
    };

    const handleIframeLoad: React.ReactEventHandler<HTMLIFrameElement> = (e) => {
        clearTimeout(iframeTimer.current);
        const iframe = e.currentTarget as HTMLIFrameElement;
        const window = e.currentTarget.contentWindow;
        if (iframe && iframe.src && window) {
            const handler = new MessageHandler(window);

            iframeTimer.current = setTimeout(() => {
                setHandler(undefined);
                setLoading(false);
                setFailed(true);
                handler.close();
            }, IFRAME_LOAD_TIMEOUT_MS);

            handler.addListener(Message.INIT, () => {
                clearTimeout(iframeTimer.current);
                setHandler(handler);
                setLoading(false);
            }, { immediate: true, once: true });

            handler.addListener(Message.PAGE_CHANGE, (e) => {
                setCurrentPage((e as any)?.page);
            }, { immediate: true });

            handler.addListener(Message.REFRESH_APP, () => {
                reloadIframe();
            });
        }
    };

    const handleDialogClose = () => {
        handler?.close();
        dispatch(closeDialogAC());
        clearTimeout(iframeTimer.current);
        // if (handler) {
            // TODO: update recent colors from Blog
            // const recentColors = handler.getLatest(Message.RECENT_COLORS);
        // }
    };

    return (
        <Dialog stripType={StripTypes.NONE} showCloseBtn={showCloseBtn} closeBtnClassName={styles.closeButton} onClose={handleDialogClose}>
            <DialogBody className={styles.blogDialog} data-testid="blog-dialog">
                <RenderWhen when={loading}>
                    <Loader className={styles.loader} />
                </RenderWhen>

                <RenderWhen when={failed}>
                    <ErrorMessage
                        classes={{ root: styles.error }}
                        icon="large"
                        reloadButton
                        reloadHandler={reloadIframe}
                    />
                </RenderWhen>

                <iframe
                    className={cx(styles.blogIframe, { [styles.hidden]: loading || failed })}
                    data-testid="blog-iframe"
                    onLoad={handleIframeLoad}
                    src={iframeSrc}
                />
            </DialogBody>
        </Dialog>
    );
};

/**
 * Calculcates the dimensions of the blog management dialog \
 * It occupies the full view width and a reduced amount of the height
 * @param browserWidth - Browser width in pixel
 * @param browserHeight - Brower height in pixel
 * @returns Dimensions
 */
const GetDialogDimensions = (browserWidth: number, browserHeight: number) => {
    const width = browserWidth;
    const height = browserHeight - BLOG_DIALOG_HEIGHT_OFFSET;
    return {
        width,
        height,
    };
};

const config = {
    // create the dialog with the blog management dialog component
    ...getCenteredDialogConfig({
        component: BlogManagementDialog,
        dragEnabled: false,
        dialogClassName: styles.dialogContainer,
    }),

    // set the general dialog parameters in configuration factory
    confFactory: ({ browserWidth, browserHeight }) => {
        const { width, height } = GetDialogDimensions(browserWidth, browserHeight);
        const { left } = getCenterPositionForDialog(width, height, browserWidth, browserHeight);
        return {
            dimensions: { width, height },
            // stick dialog to the bottom center
            position: { left, bottom: 0 },
            // modal is set so the dialog is not closed if clicked outside
            // only the x will close the dialog
            modal: true,
        };
    },

    // recalculate the dialog dimensions in case the browser dimensions change
    updateOnBrowserDimensionsChanged: (dialogConfig, browserDimensions) => {
        const { width, height } = GetDialogDimensions(browserDimensions.width, browserDimensions.height);
        return {
            ...dialogConfig,
            dimensions: { width, height },
        };
    },

    // the dialog should keep the position at the bottom center
    // otherwise it would be set to middle centered position by default
    keepPositionAtBottomCenter: true,
};

export default config;
