import * as R from "ramda";
import { applyMappers, makePlainMappers } from "../../utils";
import textStyleParaMapper from './textStyleParaMapper';
import htmlWriter from "../../../../src/utils/htmlWriter/index";
import * as textLinkMapper from './textLinksMapper';
import * as selectors from '../../../../src/components/Workspace/epics/stylesheets/selectors';
import styleText from '../../../../src/components/oneweb/Text/globalStyle/kind';
import LinkGlobalStyleKind from '../../../../src/components/oneweb/Link/globalStyle/kind';

import type { TextComponent } from "../../../../src/components/oneweb/Text/flowTypes";
import type { AnyComponentData } from "../../../../src/components/App/flowTypes";
import type { BackDeps, ToDeps } from "../index";
import { makeTextGlobalStylesNamesMap } from "../../../../src/components/oneweb/Text/makeTextGlobalStylesNamesMap";
import htmlToJson from "../../../../src/components/oneweb/Text/htmlToJson/index";
import processOldTextContents from "../../../../src/components/oneweb/Text/utils/processOldTextContents";
import {
    DefaultContentOnDrop, DEFAULT_BLUR_RADIUS, DEFAULT_SHADOW_OFFSET_X, DEFAULT_SHADOW_OFFSET_Y
} from '../../../../src/components/oneweb/Text/constants';
import { customSendReport } from '../../../../src/customSendCrashReport';
import { getCrashTraceStringified } from "../../../../src/redux/middleware/crashTraceCollector";
import { truncateIfLonger256KB } from "../../../../utils/string.js";

const
    plainPropsMapper = makePlainMappers({
        verticalAlignment: 'verticalAlignment',
        mobileDown: "mobileDown",
        mobileHide: 'mobileHide',
        mobileSettings: 'mobileSettings',
        content: 'content', // Key 'content' is also used in server/lib/dal/couchdb/index.js#createPropertyFilter()
        text: 'text', // TODO WBTGEN-9767 remove once db migration from old to new complete
        styles: 'styles', // TODO WBTGEN-9767 remove once db migration from old to new complete
        paras: 'paras', // TODO WBTGEN-9767 remove once db migration from old to new complete
        links: 'links', // TODO WBTGEN-9767 remove once db migration from old to new complete
        themeOverrideColor: 'themeOverrideColor',
        themeHighlightColor: 'themeHighlightColor',
        themeShadowBlurRadius: 'themeShadowBlurRadius',
        themeShadowColor: 'themeShadowColor',
        themeShadowOffsetX: 'themeShadowOffsetX',
        themeShadowOffsetY: 'themeShadowOffsetY',
    }),
    styleMapper = textStyleParaMapper('style', 'styles'),
    paraMapper = textStyleParaMapper('para', 'paras'),
    getWrappedItemBlocks = (wrappedItems: Array<AnyComponentData>) => {
        if (!wrappedItems) {
            return {};
        }
        const wrappedItemBlocks = wrappedItems.reduce((acc, wrappedItem) => {
            const relPara = wrappedItem.relPara;
            if (relPara) {
                acc[relPara.index] = acc[relPara.index] || [];// eslint-disable-line no-param-reassign
                acc[relPara.index].push(wrappedItem);
            }
            return acc;
        }, {});
        return wrappedItemBlocks;
    },
    calcWrappedItems = (componentsData, wrappedInComponentId) => {
        const wrappedItems = componentsData.reduce(
            (items, componentData) => {
                const
                    { bbox: { left, top, right, bottom }, wrap, relIn } = componentData,
                    width = right - left,
                    height = bottom - top;

                if (!wrap || !relIn || !relIn.id || relIn.id !== wrappedInComponentId) {
                    return items;
                }

                const
                    wrappingComponent = componentsData.find(cd => cd.id === relIn.id);

                if (!wrappingComponent) {
                    return items;
                }

                const
                    wrappingComponentRight = wrappingComponent.bbox.right,
                    wrappingComponentBottom = wrappingComponent.bbox.bottom,
                    horizontalMiddle = left + (width / 2),
                    verticalMiddle = top + (height / 2),
                    componentCenterInsideWrappingComponent =
                        horizontalMiddle >= wrappingComponent.bbox.left
                        && horizontalMiddle <= wrappingComponentRight &&
                        verticalMiddle >= wrappingComponent.bbox.top
                        && verticalMiddle <= wrappingComponentBottom;

                return componentCenterInsideWrappingComponent ? [...items, componentData] : items;
            }, []
        );

        return wrappedItems;
    };

export function to(
    componentData: any,
    { stylesheets: { styles }, componentsData, site }: ToDeps,
    calledFromUpdateItemsRelations?: true
): TextComponent {
    if (
        site && site.origin &&
        site.origin.from === "simplesite" &&
        /<div id="text-grouped-div">((.|\n)*?)<\/div>/.exec(componentData.content)
    ) {
        // eslint-disable-next-line no-param-reassign
        componentData.content = componentData.content.replace(/<div id="text-grouped-div">((.|\n)*?)<\/div>/, "$1");
    }

    return applyMappers(
        componentData,
        plainPropsMapper.to,
        existingComponentData => {
            const updatesToComponentData: Record<string, any> = {};
            if (existingComponentData.themeShadowBlurRadius === undefined) {
                updatesToComponentData.themeShadowBlurRadius = DEFAULT_BLUR_RADIUS;
            }
            if (existingComponentData.themeShadowOffsetX === undefined) {
                updatesToComponentData.themeShadowOffsetX = DEFAULT_SHADOW_OFFSET_X;
            }
            if (existingComponentData.themeShadowOffsetY === undefined) {
                updatesToComponentData.themeShadowOffsetY = DEFAULT_SHADOW_OFFSET_Y;
            }
            return updatesToComponentData;
        },
        existingComponentData => {
            if (calledFromUpdateItemsRelations) {
                return {};
            }

            const
                textGlobalStyle = selectors.getStylesByType(styleText)(styles),
                normalStyle = R.pipe(R.filter(style => style.ref === 'normal'), R.head)(textGlobalStyle),
                linkGlobalStyles = selectors.getStylesByType(LinkGlobalStyleKind)(styles),
                globalStyleId = normalStyle.id,
                { content } = existingComponentData;

            if (content === null) {
                customSendReport({
                    message: "Text component with null content fixed with empty content",
                    additionalInfo: {
                        actionsTraceStr: truncateIfLonger256KB(getCrashTraceStringified())
                    }
                });
                return { content: DefaultContentOnDrop, globalStyleId };
            }

            if (content) {
                return {
                    // @ts-ignore
                    content: processOldTextContents(content, site),
                    globalStyleId
                };
            }

            const htmlWriterData = {
                text: existingComponentData.text,
                styles: styleMapper.to(existingComponentData).styles,
                paras: paraMapper.to(existingComponentData).paras,
                links: (textLinkMapper.to(linkGlobalStyles)(existingComponentData)).links
            };

            return ({
                content: processOldTextContents(
                    htmlWriter(
                        htmlWriterData,
                        styles,
                        site,
                        getWrappedItemBlocks(calcWrappedItems(componentsData, existingComponentData.id))
                    ),
                    // @ts-ignore
                    site
                ),
                globalStyleId
            });
        }
    );
}

export function back(
    component: TextComponent,
    { stylesheets, site }: BackDeps,
    calledFromUpdateItemsRelations?: true
): any {
    return applyMappers(
        component,
        plainPropsMapper.back,
        existingComponent => {
            if (calledFromUpdateItemsRelations) {
                return {};
            }

            const jsonData = htmlToJson({
                globalStylesMap: stylesheets ? makeTextGlobalStylesNamesMap(stylesheets) : {},
                // @ts-ignore
                site,
                content: existingComponent.content
            });

            return R.pipe(
                textLinkMapper.back,
                styleMapper.back,
                paraMapper.back
            )(jsonData);
        }
    );
}
