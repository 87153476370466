const { ConfigComponentList } = require("../constants.js");

class GoogleMyBusinessConfig {
    isGoogleMyBusinessAllowed() {
        const googleMyBusiness = this._featuresConfig[ConfigComponentList.GOOGLE_MY_BUSINESS];
        return googleMyBusiness && googleMyBusiness.enabled;
    }
}

module.exports = GoogleMyBusinessConfig;
