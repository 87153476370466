import { getSectionsOrderdByTop } from "../../../../oneweb/Section/utils";
import { isInProjection } from "../../relations/syncRelationsWithComponentsMap";
import type { ComponentsMap, ComponentsMapExtension } from "../../../../../redux/modules/children/workspace/flowTypes";
import type { AnyComponent } from "../../../../oneweb/flowTypes";
import { isStretchComponentKind } from "../../../../oneweb/isStretchComponentKind";
import { getNonGhostCmps } from "../../componentAttachements/util";
import TextComponentKind from "../../../../oneweb/Text/kind";
import { getParentChildrenMap } from "./getParentChildrenMap";

const
    getCmpBottom = cmp => cmp.top + cmp.height,
    getCmpRight = cmp => cmp.left + cmp.width,
    getCmpCenter = cmp => cmp.top + (cmp.height / 2),
    isOverlappingVertically = (cmp1, cmp2) => (cmp1.left < getCmpRight(cmp2) && getCmpRight(cmp1) > cmp2.left),
    findBlockerCmpIfAny = (
        projectionCmps: Array<AnyComponent>,
        children: Array<AnyComponent>,
        selectedCmp: AnyComponent,
        diff: number
    ) => {
        let blockerCmps = {};
        const selectedCmpBottom = getCmpBottom(selectedCmp) - diff;
        projectionCmps.forEach((projectedCmp) => {
            children.forEach((cmp) => {
                const cmpBottom = getCmpBottom(cmp);
                if (
                    (cmp.id !== projectedCmp.id) &&
                    (cmp.id !== selectedCmp.id) &&
                    (cmpBottom > selectedCmpBottom) &&
                    (projectedCmp.top >= cmpBottom) &&
                    (isStretchComponentKind(cmp.kind, cmp.stretch) || (isOverlappingVertically(projectedCmp, cmp))) &&
                    (!blockerCmps[projectedCmp.id] || (cmpBottom > getCmpBottom(blockerCmps[projectedCmp.id])))
                ) {
                    blockerCmps[projectedCmp.id] = cmp;
                }
            });
        });
        return blockerCmps;
    },
    pullUpProjectedCmps = (
        projectionCmps: Array<AnyComponent>,
        blockerCmps: Object,
        diffInp: number,
        cmpsMap: ComponentsMap
    ) => {
        let diff = diffInp,
            updatedCmpsMap = { ...cmpsMap };
        projectionCmps.forEach((projectedCmp) => {
            const blockingCmp = blockerCmps[projectedCmp.id],
                blockingCmpBottom = blockingCmp && getCmpBottom(blockingCmp);
            if (blockingCmp) {
                diff = Math.min(diff, projectedCmp.top - blockingCmpBottom);
            }
            updatedCmpsMap[projectedCmp.id] = {
                ...updatedCmpsMap[projectedCmp.id],
                top: updatedCmpsMap[projectedCmp.id].top - diff
            };
        });
        return { diff, updatedCmpsMap };
    },
    getProjectionCmps = (selectedCmp: AnyComponent, cmps: Array<AnyComponent>, cmpsMap: ComponentsMap, parentChildMap: Object) => {
        const projectionCmps: Array<AnyComponent> = [];
        cmps.forEach((cmp) => {
            if (cmp.id !== selectedCmp.id && isInProjection(cmp, selectedCmp)) {
                projectionCmps.push(cmp);
                let children = parentChildMap[cmp.id] || [];
                for (const child of children) {
                    projectionCmps.push(cmpsMap[child]);
                    children.push(...(parentChildMap[child] || []));
                }
            }
        });
        return projectionCmps;
    };
export const applyShiftUpOnTextCmpHeightReduction = (
    componentsMap: ComponentsMap,
    selectedComponentId: string,
    textAdjustmentDataHeight: number,
    componentsMapExtension: ComponentsMapExtension
) => {
    try {
        let
            diff = componentsMapExtension[selectedComponentId].minDimensions.height - textAdjustmentDataHeight,
            selectedCmp = componentsMap[selectedComponentId],
            sections = getSectionsOrderdByTop(componentsMap),
            selectedCmpSection: AnyComponent = sections.find(section => {
                const sectionBottom = getCmpBottom(section),
                    cmpCenter = getCmpCenter(selectedCmp);
                return (cmpCenter >= section.top && cmpCenter < sectionBottom);
            }),
            parentChildMap = getParentChildrenMap(componentsMap),
            blockerCmps = {},
            updatedCmpsMap = { ...componentsMap },
            projectionCmps: Array<AnyComponent> = [],
            mapIdToCmp = id => updatedCmpsMap[id],
            selectedChild = selectedCmp;
        while (selectedChild) {
            let parentId = '',
                children: Array<any> = [],
                bottomGapInit = 0;
            if (selectedChild.id !== selectedCmpSection.id) {
                parentId = selectedChild.relIn && selectedChild.relIn.id;
                children = (parentId && parentChildMap[parentId].map(id => mapIdToCmp(id))) || [];
                if (selectedChild.kind === TextComponentKind) {
                    children.push(...((parentChildMap[selectedChild.id] || []).map(id => mapIdToCmp(id))));
                }
                let lastBottomInit = 0;
                children.forEach(cmp => {
                    lastBottomInit = Math.max(lastBottomInit, getCmpBottom(cmp));
                });
                bottomGapInit = getCmpBottom(updatedCmpsMap[parentId]) - lastBottomInit;
            }
            updatedCmpsMap[selectedChild.id] = {
                ...updatedCmpsMap[selectedChild.id],
                height: updatedCmpsMap[selectedChild.id].height - diff
            };
            if (selectedChild.kind === TextComponentKind) {
                // $FlowFixMe
                let { left, right, top, bottom } = selectedChild.relIn;
                if (left < 0 || right > 0 || top < 0 || bottom > 0) {
                    diff = 0;
                }
            }
            if (selectedChild.id === selectedCmpSection.id || diff === 0) {
                break;
            }
            projectionCmps = getProjectionCmps(selectedChild, children, updatedCmpsMap, parentChildMap);
            if (projectionCmps.length) {
                blockerCmps = findBlockerCmpIfAny(projectionCmps, children, selectedChild, diff);
                let result = pullUpProjectedCmps(projectionCmps, blockerCmps, diff, updatedCmpsMap);
                diff = result.diff;
                updatedCmpsMap = result.updatedCmpsMap;
            }
            let lastBottom = 0;
            children.map(cmp => mapIdToCmp(cmp.id)).forEach(cmp => {
                lastBottom = Math.max(lastBottom, getCmpBottom(cmp));
            });
            const bottomGap = getCmpBottom(updatedCmpsMap[parentId]) - lastBottom;
            if (bottomGap >= bottomGapInit) {
                diff = bottomGap - bottomGapInit;
            }
            selectedChild = updatedCmpsMap[parentId];
        }
        const sectionBottom = getCmpBottom(updatedCmpsMap[selectedChild.id]),
            cmps = getNonGhostCmps(updatedCmpsMap);
        let changes: Array<{ id: any; value: Record<string, any>; }> = [];
        cmps.forEach(cmp => {
            if (getCmpCenter(cmp) > sectionBottom) {
                updatedCmpsMap[cmp.id] = {
                    ...updatedCmpsMap[cmp.id],
                    top: updatedCmpsMap[cmp.id].top - diff
                };
            }
            let oldCmp = componentsMap[cmp.id],
                newCmp = updatedCmpsMap[cmp.id],
                changesMap: Record<string, any> = {};
            if (oldCmp.top !== newCmp.top) {
                changesMap.top = newCmp.top;
            }
            if (oldCmp.height !== newCmp.height) {
                changesMap.height = newCmp.height;
            }
            if (Object.keys(changesMap).length) {
                changes.push({
                    id: cmp.id,
                    value: changesMap
                });
            }
        });
        return changes;
    } catch (error) {
        console.log("Error in pullup ", error);
        return [];
    }
};
