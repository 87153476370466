import React from 'react';
import cx from 'classnames';
import LoadingIndicator from '../../../../view/common/LoadingIndicator/index';
import Msg from "../../../../view/intl/Msg";
import styles from './styles.css';
import { getAppConfig } from '../../../App/epics/appConfig/appConfig.js';
import AppConfig from "../../../../utils/AppConfig";
import { getImageURL } from '../../FeaturedProducts/ProductSelector/components/ProductItem';

type Props = {
    product: Record<string, any>,
    selected: boolean,
    toggleSelected: Function
}

const isIconImage = (url) => {
    return (/^icon:/.test(url));
};

type ImgPropTypes = {
    children: React.ReactNode,
    imageURL?: string
}

const Img = ({ children, imageURL }: ImgPropTypes) => {
    const [imageError, setImageError] = React.useState(false);
    const [imageLoading, setImageLoading] = React.useState(!!imageURL);

    React.useEffect(() => {
        if (imageURL) {
            const img = new Image();
            img.onload = () => {
                setImageLoading(false);
            };
            img.onerror = () => {
                setImageError(true);
                setImageLoading(false);
            };
            img.src = imageURL;
            // @ts-ignore
            if (img.complete) img.onload();
        }
    }, []);

    return (
        <div
            className={styles.itemThumb}
            style={imageURL ? { backgroundImage: `url('${imageURL}')` } : undefined}
        >
            { imageLoading && <LoadingIndicator className={styles.imageLoadingIndicator} /> }
            { /* eslint-disable-next-line react/jsx-no-useless-fragment */ }
            { !imageLoading && (!imageURL || imageError) && <React.Fragment>{children}</React.Fragment> }
        </div>
    );
};

export const ProductItem = ({ product, selected, toggleSelected }: Props) => {
    const appConfig = AppConfig(getAppConfig());
    const enableImproService = Boolean(appConfig.getOptional('oneWeb.webshop.enableImproService'));
    const improUrl = appConfig.getOptional('oneWeb.webshop.improUrl');
    const imageURL = product.image && !isIconImage(product.image) ? getImageURL(product.image, enableImproService, improUrl) : undefined;

    return (
        <div
            className={cx(styles.item, { [styles.selected]: selected })}
            onClick={() => { toggleSelected(product.productId); }}
        >
            <div className={styles.itemThumbCard}>
                <div className={styles.itemOverlay}>
                    <Msg
                        k="component.productWidget.ProductsDialog.Select"
                    >
                        Select
                    </Msg>
                </div>
                <Img imageURL={imageURL}>
                    <div className={styles.itemImagePlaceholderText}>{product.productName}</div>
                </Img>
                {selected && <div className={styles.imageSelectedCornerTick} />}
            </div>
            <p className={styles.itemTitle}>{product.productName}</p>
        </div>
    );
};
