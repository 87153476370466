import { normalizeLocale } from "../../../../server/shared/utils/normalizeLocale.js";
import { DEFAULT_LOCALE } from "../../../../server/shared/constants.js";
import { getAppConfig } from "../App/epics/appConfig/appConfig.js";

export const getDefultLocaleId = () => {
    const { i18n: { defaultLocaleId = DEFAULT_LOCALE } = {} } = getAppConfig();
    return defaultLocaleId;
};

export const getDefultLocaleIdNormalized = () => normalizeLocale(getDefultLocaleId());
