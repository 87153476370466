import makeEpic from "../../../epics/makeEpic";
import {
    generalGlobalDataEpic,
    type GeneralGlobalData
} from '../../SiteSettings/General/generalGlobalDataEpic/generalGlobalDataEpic';
import {
    WhenRepositoryDomainSelector
} from "../../../redux/middleware/cookie/selectorActionTypes";
import { receiveOnly } from "../../../epics/makeCondition";
import { ANIMATION_FRAME } from '../../../redux/middleware/raf';
import { Locale2Selector } from "../../oneweb/Template/epics/template/selectorActionTypes";
import { DesignI18nSelector } from "./designLocalization/selectorActionTypes";
import makeStateSelectorReducer from "../../../epics/makeStateSelectorReducer";
import {
    GlobalVariableAddressNameKey,
    GlobalVariableAddressStreetAddressKey,
    GlobalVariableAddressZipKey,
    GlobalVariableAddressCityKey,
    GlobalVariableAddressAreaKey,
    GlobalVariableAddressCountryCodeKey,
    GlobalVariableAddressUrlKey,
    GlobalVariableContactEmailKey,
    GlobalVariablePhoneNumberKey,
} from '../../../constants';
import { getDefultLocaleIdNormalized } from "../../Locale/getDefaultLocale";

const DEFAULT_LOCALE_NORMALIZED = getDefultLocaleIdNormalized();

export const makeI18nKey = (key: string) => `i18n.${key}`;

export const GlobalVariableKeys = [
    'logoAsset',
    'logoAltText',
    'websiteTitle',
    'websiteDescription',
    GlobalVariablePhoneNumberKey,
    GlobalVariableContactEmailKey,
    'address',
    'addressDescription',
    GlobalVariableAddressUrlKey,
    'streetNumber',
    'streetName',
    'city',
    'area',
    'zip',
    'country',
];

export const GlobalVariablesKeysMap = GlobalVariableKeys.reduce((a, k) => ({ ...a, [k]: true }), {});

const fromI18n = ({ locale, i18n = {} }) => ({
    ...Object.keys(i18n).reduce((acc, key) => {
        acc[makeI18nKey(key)] = i18n[key][locale] || i18n[key][DEFAULT_LOCALE_NORMALIZED];
        return acc;
    }, {})
});

type GlobalVariablesFromArg = {
    generalGlobalData: GeneralGlobalData;
    locale: string;
    i18n: Record<string, any>;
};

export const globalVariablesFrom = ({
    generalGlobalData,
    locale,
    i18n
}: GlobalVariablesFromArg) => ({
    ...fromI18n({ locale, i18n }),
    ...generalGlobalData
});

const GENERAL_GLOBAL_DATA_DEBOUNCE_INTERVAL_MS = 750;

export const globalVariablesEpic = makeEpic({
    valueActionType: 'GLOBAL_VARIABLES_EPIC_VALUE',
    defaultState: {},
    defaultScope: {
        lastUpdatedGeneralGlobalData: null,
        lastNotCommitedChangeTimestamp: 0,

    },
    updaters: [
        {
            conditions: [
                Locale2Selector,
                DesignI18nSelector,
                WhenRepositoryDomainSelector,
            ],
            reducer: ({ state, scope, values: [locale, i18n] }) => (locale && i18n ? ({
                state: {
                    ...state,
                    ...fromI18n({ locale, i18n })
                },
                scope,
            }) : { state, scope })
        },
        {
            conditions: [
                receiveOnly(generalGlobalDataEpic.valueActionType),
                ANIMATION_FRAME,
            ],
            reducer: ({ state, scope, values: [generalGlobalData, { ts: now }] }) => {
                if (scope.lastUpdatedGeneralGlobalData === generalGlobalData) {
                    return { state, scope };
                } else if (scope.lastNotCommitedChangeTimestamp === 0) {
                    // begin waiting
                    return {
                        state,
                        scope: {
                            ...scope,
                            lastNotCommitedChangeTimestamp: now
                        },
                    };
                } else if (now - scope.lastNotCommitedChangeTimestamp > GENERAL_GLOBAL_DATA_DEBOUNCE_INTERVAL_MS) {
                    // flush
                    return {
                        state: { ...state, ...generalGlobalData },
                        scope: {
                            ...scope,
                            lastUpdatedGeneralGlobalData: generalGlobalData,
                            lastNotCommitedChangeTimestamp: 0
                        },
                    };
                }

                return {
                    state,
                    scope
                };
            },
        },
        {
            conditions: [
                receiveOnly(ANIMATION_FRAME),
                generalGlobalDataEpic.valueActionType,
            ],
            reducer: ({ state, scope, values: [{ ts: now }] }) => {
                if (scope.lastNotCommitedChangeTimestamp) {
                    return {
                        state,
                        scope: {
                            ...scope,
                            lastNotCommitedChangeTimestamp: now
                        }
                    };
                }

                // changes will be commited on ANIMATION_FRAME
                return { state, scope };
            },
        }
    ]
});

const makePropReducer = prop => makeStateSelectorReducer(
    globalVariablesEpic.reducer,
    globalVariablesEpic.valueActionType,
    state => state[prop] || null
);

export const contactEmailReducer = makePropReducer(GlobalVariableContactEmailKey);
export const phoneNumberReducer = makePropReducer(GlobalVariablePhoneNumberKey);
export const addressNameReducer = makePropReducer(GlobalVariableAddressNameKey);
export const addressStreetAddressReducer = makePropReducer(GlobalVariableAddressStreetAddressKey);
export const addressZipReducer = makePropReducer(GlobalVariableAddressZipKey);
export const addressCityReducer = makePropReducer(GlobalVariableAddressCityKey);
export const addressAreaReducer = makePropReducer(GlobalVariableAddressAreaKey);
export const addressCountryCodeReducer = makePropReducer(GlobalVariableAddressCountryCodeKey);
export const addressUrlReducer = makePropReducer(GlobalVariableAddressUrlKey);
export const websiteTitleReducer = makePropReducer('websiteTitle');
