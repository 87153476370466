import type { TinyMceEpicUpdater } from "../flowTypes";

import { getMatchingClassNames } from '../../../../../utils/dom';
import { getSelectedLinkNodes } from '../editorUtils/methods/getters/getLinkFromEditorSelection';
import { getLinkUrlForText } from "../../../../oneweb/Text/getLinkUrl";
import { setLink, removeLink } from "../setLink";
import { makeApplyEditorChangesReducer } from "./helpers/helpers";
import {
    TINY_MCE_REMOVE_LINK, TINY_MCE_SET_LINK_IN_CONTENT_BLOCK, TINY_MCE_SET_LINK_ACROSS_CONTENT_BLOCKS
} from "../actionTypes";
import { LINK_CLASS_NAME_REGEX } from '../editorUtils/constants';
import { linkGlobalStyleKind } from '../../../../oneweb/Link/globalStyle/kind';
import { getGlobalstyleClassNameFromStyle } from '../../../../RenderGlobalstyles/getGlobalstyleClassName';

const
    makeSetLinkUpdater = (condition, limitToOneContentBlock: boolean): TinyMceEpicUpdater => ({
        conditions: [condition],
        reducer: makeApplyEditorChangesReducer(
            ({ state, scope, values: [{ linkAction }], editor }) => {
                if (!linkAction) {
                    removeLink(editor);
                    return { state, scope };
                }

                const { stylesheets } = scope,
                    linkGlobalStyles = stylesheets.styles.filter(s => s.type === linkGlobalStyleKind),
                    linkGlobalStyleClasses = linkGlobalStyles.map(getGlobalstyleClassNameFromStyle),
                    linkEltList: HTMLAnchorElement[] = getSelectedLinkNodes(editor, limitToOneContentBlock);
                let shouldRemoveFormatting: boolean = false,
                    linkClassName: string | null | undefined;
                for (const linkElt of linkEltList) {
                    const linkClassNameList = getMatchingClassNames(linkElt, LINK_CLASS_NAME_REGEX);
                    linkClassName = linkGlobalStyleClasses.find(c => linkClassNameList.includes(c));
                    if (linkClassName) break;
                }
                if (!linkClassName) {
                    linkClassName = linkGlobalStyleClasses[0];
                    shouldRemoveFormatting = true; // new link, link style is being set for the first time
                }
                if (!linkClassName) {
                    throw new Error('No link styles found. Cannot set link.');
                }

                const url = getLinkUrlForText(linkAction);
                setLink(editor, url, linkClassName, shouldRemoveFormatting, limitToOneContentBlock);

                return {
                    state: {
                        ...state,
                        selectedLinkGlobalStyle: linkGlobalStyles[linkGlobalStyleClasses.indexOf(linkClassName)].id
                    },
                    scope
                };
            }
        )
    }),
    setLinkInContentBlockUpdater = makeSetLinkUpdater(TINY_MCE_SET_LINK_IN_CONTENT_BLOCK, true),
    setLinkAcrossContentBlocksUpdater = makeSetLinkUpdater(TINY_MCE_SET_LINK_ACROSS_CONTENT_BLOCKS, false),
    removeLinkUpdater = {
        conditions: [
            TINY_MCE_REMOVE_LINK
        ],
        reducer: makeApplyEditorChangesReducer(
            ({ state, scope, editor }) => {
                removeLink(editor);

                return {
                    state: {
                        ...state,
                        selectedLinkGlobalStyle: null,
                        linkAction: null
                    },
                    scope
                };
            }
        )
    };

export {
    setLinkInContentBlockUpdater,
    setLinkAcrossContentBlocksUpdater,
    removeLinkUpdater
};
