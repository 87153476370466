import * as R from "ramda";
import * as mp from "../../../mappers/path";
import * as selectors from "../../Workspace/epics/stylesheets/selectors";
import { DEFAULT_SHADOW } from "../Text/constants";
import LinkGlobalstyleType from "../Link/globalStyle/kind";
import { DefaultCellContentOnDrop, DEFAULT_VERTICAL_ALIGN } from "./constants";
import { makeCellBlockSpec } from "./globalStyle/mapper";
import type { Color } from "../../../mappers/flowTypes";
import type {
    TableComponent,
    TableComponentCell,
    CommonTableComponentCell,
    TableComponentCellWithStyle
} from './flowTypes';
import type {
    HorizontalAlignment, VerticalAlignment, Stylesheet, Stylesheets, LinkStylesheet, Shadow
} from "../../Workspace/epics/stylesheets/flowTypes";
import type { TableEditModeState } from "./epics/tableEditMode/flowTypes";
import type { TinyMceEpicState } from "../../App/epics/tinyMceEpic/flowTypes";
import type { MapV } from "../../../globalTypes";
import type { Path } from "../../../mappers/path";
import { memoMaxOne } from "../../../../utils/memo";

type GetFontProp = {
    tinyMceState: TinyMceEpicState,
    stylesheets: Stylesheets
}

type RowsAndCols = {
    numRows: number,
    numCols: number
}

type GetFontPropResult = {
    value: AnyValue;
    removable: boolean
}

type GetDefaultStyleProp = {
    bold?: boolean,
    italic?: boolean,
    underline?: boolean,
    size?: number,
    color?: null | Color,
    highlight?: null | Color,
    shadow?: Shadow
}

type getDefaultParaProps = {
    align: HorizontalAlignment
}

type Cells = Array<TableComponentCell>
const
    getGlobalstyleByRef = (stylesheets: Stylesheets) => (globalStyleRef: string): Stylesheet => R.pipe(
        selectors.getAllStylesheets,
        selectors.getStyleByRef(globalStyleRef)
    )(stylesheets);

export const
    getGlobalstyleById = (stylesheets: Stylesheets) => (globalStyleId: string): Stylesheet => R.pipe(
        selectors.getAllStylesheets,
        selectors.getStyleById(globalStyleId)
    )(stylesheets),
    getPropFromGlobalstyle = (stylesheets: Stylesheets) => (globalStyleRef: string, propPath: Path): AnyValue =>
        R.pipe(
            (stylesheets) => getGlobalstyleByRef(stylesheets)(globalStyleRef),
            R.path(propPath)
        )(stylesheets),
    getNumRowsAndCols = memoMaxOne((cells: Cells): RowsAndCols => {
        const { cellInfo: { rowIndex, colIndex } } = cells[cells.length - 1];
        return { numRows: rowIndex + 1, numCols: colIndex + 1 };
    }),

    fixRowAndColumnIndices = ({ numRows, numCols }: RowsAndCols) => (cells: Cells): Cells => {
        const updatedCells: any = [];

        for (let i = 0; i < numRows; i++) {
            for (let j = 0; j < numCols; j++) {
                const index = (numCols * i) + j;
                updatedCells.push(R.evolve({
                    cellInfo: {
                        rowIndex: R.always(i),
                        colIndex: R.always(j)
                    }
                }, cells[index]));
            }
        }

        return updatedCells;
    },

    cloneCellWithEmptyText = R.pipe(R.clone, R.evolve({ content: R.always(DefaultCellContentOnDrop) })),

    getCellWidthInPx = ({ cellInfo: { flexWidth } }: TableComponentCellWithStyle, componentWidth: number): number =>
        flexWidth * componentWidth,

    getConfigCell = ({ cells }: TableComponent): TableComponentCell => {
        // Return the first cell with non-empty text or the first cell
        return cells.find(cell => cell.content.length) || cells[0];
    },

    getNonEmptyCells = ({ cells }: TableComponent): TableComponentCell[] => {
        // Return all the cells with non-empty text or an array with just the first cell
        const nonEmptyCells = cells.filter(cell => cell.content.length);
        return nonEmptyCells.length ? nonEmptyCells : [cells[0]];
    },

    updateColorAlpha = ([format, H, S, L]: Color, alpha: number): Color => ([format, H, S, L, alpha]),

    getDefaultStyle = (props: GetDefaultStyleProp = {}): Record<string, any> => ({
        atype: "style",
        type: "web.data.styles.StyleText",
        ...props
    }),

    getDefaultPara = (props: getDefaultParaProps): Record<string, any> => ({
        atype: "para",
        ...props
    }),

    getDefaultShadowStyle = (shadowProp: Record<string, any>): Record<string, any> => ({
        type: "web.data.styles.TextShadow",
        ...DEFAULT_SHADOW,
        ...shadowProp
    }),

    getStyleToApply = (defaultStyle: Record<string, any>, propName: string): Function => (component: TableComponent) => {
        const firstCell = getConfigCell(component),
            styles = firstCell.styles;

        return styles.length > 0
            ? R.evolve({ [propName]: () => !(styles[0][propName]) }, defaultStyle)
            : defaultStyle;
    },

    createGetStyleToApply = (props: Record<string, any>): Function => R.always(getDefaultStyle(props)),

    getFullCellData = (
        { commonCellsData }: { commonCellsData: CommonTableComponentCell },
        cell: TableComponentCell
    ): TableComponentCellWithStyle => {
        return { ...commonCellsData, ...cell };
    },

    getCellToReadValues = (component: TableComponent, tableEditModeState: TableEditModeState): TableComponentCellWithStyle => {
        const
            { cells } = component,
            { cellInEditModeIndex, selectedCellsIndexes } = tableEditModeState;

        let configCell;
        if (cellInEditModeIndex > -1) {
            configCell = cells[cellInEditModeIndex];
        } else if (selectedCellsIndexes.length > 0) {
            configCell = cells[selectedCellsIndexes[0]];
        } else {
            configCell = getConfigCell(component);
        }
        return getFullCellData(component, configCell);
    },

    getCellsToReadValues = (component: TableComponent, tableEditModeState: TableEditModeState): TableComponentCell[] => {
        const
            { cells } = component,
            { cellInEditModeIndex, selectedCellsIndexes } = tableEditModeState;

        if (cellInEditModeIndex > -1) {
            return [cells[cellInEditModeIndex]];
        } else if (selectedCellsIndexes.length > 0) {
            return cells.filter((cell, index) => selectedCellsIndexes.includes(index));
        }

        return getNonEmptyCells(component);
    },

    getFont = ({
        tinyMceState,
        stylesheets
    }: GetFontProp) => (fontProp: string, defaultValue: any = null, skipGlobalStyles: boolean = false): GetFontPropResult => {
        let fontPropValue;

        if (fontProp === mp.shadow && !R.isNil(tinyMceState.shadowColor)) {
            fontPropValue = {
                color: tinyMceState.shadowColor,
                blur: tinyMceState.blurRadius,
                left: tinyMceState.shadowOffsetX,
                top: tinyMceState.shadowOffsetY
            };
        } else if (fontProp === mp.highlight) {
            fontPropValue = tinyMceState.highLightColor;
        } else if (fontProp === mp.size) {
            fontPropValue = tinyMceState.fontSize;
        } else if (fontProp === mp.font) {
            fontPropValue = tinyMceState.fontFamily;
        } else {
            fontPropValue = tinyMceState[fontProp];
        }

        if (fontPropValue || (
            [mp.bold, mp.italic, mp.underline].indexOf(fontProp) > -1 && R.is(Boolean, fontPropValue))
        ) {
            return { value: fontPropValue, removable: true };
        }

        if (!skipGlobalStyles) {
            const getPropFromGS = getPropFromGlobalstyle(stylesheets);

            if (!fontPropValue) {
                // Read from the first style in json.styles
                if (tinyMceState.selectedGlobalStyle) {
                    fontPropValue = getPropFromGS(tinyMceState.selectedGlobalStyle, [fontProp]);
                }

                // Finally get it from table normal global style
                if (!fontPropValue) {
                    fontPropValue = R.pipe(
                        selectors.tableNormalGlobalstyle,
                        R.path([mp.text, fontProp])
                    )(stylesheets);
                }
            }
        }

        return { value: fontPropValue || defaultValue, removable: false };
    },

    getFirstLinkStyleBySortedOrder = (stylesheets: Stylesheets): LinkStylesheet => {
        const styles = selectors.getAllStylesheets(stylesheets),
            linkGlobalstyles = selectors.getStylesByType(LinkGlobalstyleType)(styles);

        const { style } = linkGlobalstyles.reduce((acc, style) => {
            const index = parseInt(style.ref.match(/\d+/ig)[0], 10);
            return index <= acc.index ? { index, style } : acc;
        }, { style: null, index: Infinity });

        return style;
    },

    vaPath = [mp.style, mp.verticalAlign],
    getHorizontalAlign = ({ alignment }: TinyMceEpicState): HorizontalAlignment => alignment,
    getVerticalAlign = (cell: TableComponentCellWithStyle, stylesheets: Stylesheets): VerticalAlignment => {
        let va = R.path(vaPath, cell);

        if (!va) {
            va = getPropFromGlobalstyle(stylesheets)(cell.style.globalId, [mp.verticalAlign]);
        }

        return (va || DEFAULT_VERTICAL_ALIGN);
    },

    getRowAndColumnForAddOrRemoveAction = (
        { numRows, numCols }: RowsAndCols,
        fisrtSelectedCellIndex: number
    ) => {
        const index = R.is(Number, fisrtSelectedCellIndex) ? fisrtSelectedCellIndex : ((numRows * numCols) - 1);

        return {
            rowIndex: Math.floor(index / numCols),
            columnIndex: index % numCols
        };
    },

    generateInlineStyle = R.applySpec(makeCellBlockSpec([])),

    dropUndefinedValues = (config: MapV<string>): MapV<string> => Object.keys(config).reduce((acc, key) => {
        return R.isNil(acc[key]) ? R.omit([key], acc) : acc;
    }, config),

    dropValues = (config: MapV<string>, propsToDrop: MapV<string>): MapV<string> =>
        Object.keys(propsToDrop).reduce((acc, prop) => {
            return config[prop] === propsToDrop[prop] ? R.omit([prop], acc) : acc;
        }, config);
