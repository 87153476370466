import {
    makeCombineReducer,
    makeComponentBaseReducers,
    makeDefaultStateReducers,
    makePathPayloadToSetterReducer
} from "../../../../redux/makeReducer";

import BookingsKind from "../kind";
import { defaultState } from "../defaultState";
import * as actionTypes from "../actionTypes";

const makePayloadToPropReducer = prop => (component, { payload }) => ({
    ...component,
    [prop]: payload
});

const makeThemeColorReducer
    = (prop: string) => (component, { payload: { themeColor } }) => ({ ...component, [prop]: themeColor });

const makeColorReducer
    = (prop: string) => (component, { payload: { color } }) => ({ ...component, [prop]: color });

export default makeCombineReducer({
    combineReducers: {
        ...makeComponentBaseReducers(BookingsKind),

        // component has these default dimensions when inserted
        ...makeDefaultStateReducers(defaultState)
    },
    handleActions: {
        [actionTypes.BOOKINGS_FONT_FAMILY_CHANGED]: makePayloadToPropReducer("fontFamily"),
        [actionTypes.BOOKINGS_COLOR_CHANGED]: makeColorReducer("fontColor"),
        [actionTypes.BOOKINGS_TITLE_COLOR_CHANGED]: makeColorReducer("titleFontColor"),
        [actionTypes.BOOKINGS_COLOR_CHANGED_AUTO_COLOR]: makeThemeColorReducer("fontColorTheme"),
        [actionTypes.BOOKINGS_TITLE_COLOR_CHANGED_AUTO_COLOR]: makeThemeColorReducer("titleFontColorTheme"),
        [actionTypes.BOOKINGS_BUTTON_THEME_CHANGED]: makePayloadToPropReducer("buttonThemeSelected"),
        [actionTypes.BOOKINGS_BUTTON_STYLE_CHANGED]: makePathPayloadToSetterReducer(['buttonStyleId'], ['stylesheetId']),
        [actionTypes.BOOKINGS_BUTTON_TEXT_CHANGED]: makePayloadToPropReducer("buttonText"),
    }
});
