// @ts-nocheck
import React from 'react';
import {
    STRIP, SECTION, BACKGROUND, BUTTON,
    CODE, CONTACT_FORM, GALLERY, FACEBOOK,
    DOCUMENT, SHARE_BUTTONS, MENU,
    IMAGE_SLIDER, IMAGE, WEB_SHOP, VIDEO,
    TEXT, TABLE, SOCIAL_KIND, ADDRESS_KIND,
    WEBSHOP_POLICIES, WEBSHOP_PAYMENT_METHODS,
    PHONE_KIND, EMAIL_KIND, LOGO_KIND,
    SVG_KIND, HOVERBOX, OPENING_HOURS_KIND, VIDEO_FILE, GOOGLE_REVIEWS,
    BOOKINGS, BLOG, POST
} from "./componentKinds";
import FEATURED_PRODUCTS_KIND from './FeaturedProducts/kind';
import INSTAGRAM_GALLERY_KIND from "./InstagramGallery/kind";
import FACEBOOK_FEED_GALLERY_KIND from "./FacebookFeedGallery/kind";
import PRODUCT_WIDGET_KIND from './ProductWidget/kind';
import FACEBOOK_CHAT_KIND from "./FacebookChat/kind";
import { WidgetsComponentNames } from './Widgets/componentNames';

export const ComponentNames = {
    [BACKGROUND]: 'msg: common.box {Box}',
    [HOVERBOX]: 'msg: common.hoverBox {Hoverbox}',
    [BUTTON]: 'msg: component.button.title {Button}',
    [CODE]: 'msg: component.code.label {Code}',
    [CONTACT_FORM]: 'msg: component.contactForm.label {Contact form}',
    [DOCUMENT]: 'msg: component.document.label {Document}',
    [FACEBOOK]: 'msg: component.facebook.label {Facebook page}',
    [GALLERY]: 'msg: component.gallery.label {Gallery}',
    [IMAGE]: 'msg: component.image.label {Image}',
    [IMAGE_SLIDER]: 'msg: component.imageSlider.label {Image slider}',
    [MENU]: 'msg: shortcut.navigation {Navigation}',
    [SHARE_BUTTONS]: 'msg: component.shareButtons.label {Share buttons}',
    [STRIP]: 'msg: component.strip.label {Strip}',
    [SECTION]: 'msg: component.section.label {Section}',
    [TABLE]: 'msg: component.table.label {Table}',
    [TEXT]: 'msg: component.text.label {Text}',
    [VIDEO]: 'msg: common.video {Video}',
    [VIDEO_FILE]: 'msg: common.video {Video}',
    'YOUTUBE': 'msg: common.youtube {YouTube}',
    [WEB_SHOP]: 'msg: component.webshop {Online Shop}',
    [WEBSHOP_PAYMENT_METHODS]: 'msg: common.paymentMethods {Payment methods}',
    [WEBSHOP_POLICIES]: 'msg: common.termsAndPrivacyPolicy {Terms and Privacy Policy}',
    [GOOGLE_REVIEWS]: 'msg: common.googleReviews {Google reviews}',
    [SOCIAL_KIND]: 'msg: component.social.label {Social links}',
    [LOGO_KIND]: 'msg: common.logo {Logo}',
    [EMAIL_KIND]: 'msg: common.email {Email}',
    [PHONE_KIND]: 'msg: common.phone {Phone}',
    [ADDRESS_KIND]: 'msg: common.address {Address}',
    [SVG_KIND]: 'msg: common.svg {SVG}',
    [OPENING_HOURS_KIND]: 'msg: common.openingHours {Opening hours}',
    [FEATURED_PRODUCTS_KIND]: 'msg: component.webshop.FeaturedProducts {Featured products}',
    [INSTAGRAM_GALLERY_KIND]: 'msg: common.instagramGallery {Instagram gallery}',
    [FACEBOOK_FEED_GALLERY_KIND]: 'msg: common.facebookFeed {Facebook feed gallery}',
    [PRODUCT_WIDGET_KIND]: 'msg: component.webshop.ProductWidget {Product widget}',
    [FACEBOOK_CHAT_KIND]: 'msg: common.chatWidget.label {Messenger chat}',
    [BOOKINGS]: 'msg: component.bookings.label {Bookings}',
    [BLOG]: 'msg: component.blog.label {Blog}',
    [POST]: 'msg: component.post.label {Post}',
    ...WidgetsComponentNames,
};

export const
    titleTextBigger = "msg: mcta.text.mve.textBigger {Make text bigger}",
    titleTextSmaller = "msg: mcta.text.mve.textSmaller {Make text smaller}",
    titleTextSmallerErr = "msg: mcta.text.minFontSize {Minimum font size}",
    titleTextBiggerErr = "msg: mcta.text.maxFontSize {Maximum font size}",

    titleClearFormatting = "msg: common.clearFormatting {Clear formatting}";

export const getTestingWithScreenshotPreview = (kind: string) => {
    const style = {
        border: '1px solid black', display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%'
    };
    return (
        <div style={style}>
            Screenshot testing preview for {kind}
        </div>
    );
};

export const SOCIAL_MIN_SIZE = 20,
    SOCIAL_MAX_SIZE = 100,
    SOCIAL_MIN_SPACING = 12,
    SOCIAL_MAX_SPACING = 100;

export const SVGContentType = 'image/svg';

export const getConditionalScript = (scriptUrl: string, shouldReturnScript: boolean) => {
    return shouldReturnScript ? scriptUrl : '';
};

