import { put } from "redux-saga/effects"; // eslint-disable-line n/file-extension-in-import
import { currentPathSelector } from "../selectors";
import { reloadResources } from "../actionCreators/index";
import type { AppState } from "../../../flowTypes";
import selectGen from "../../../../../utils/saga/selectGen";

export default function* (): any {
    const currentPath = yield* selectGen((appState: AppState) => currentPathSelector(appState.fileChooser));
    // @ts-ignore
    yield put(reloadResources(currentPath));
}
