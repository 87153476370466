import { getDAL } from "../init";

export type DataPageTemplateRefPage = {
    id: string;
    name: string;
    time: number;
    rev: number;
    etag: string;
};

export default class DataPageTemplateRef {
    id!: string;
    stylesheetId!: string;
    name!: string;
    pages!: Array<DataPageTemplateRefPage>;

    // TODO: We shall refactor some parts here due to the updated data structure
    pageId!: string;

    time!: number;
    rev!: number;
    etag!: string;

    _preViewUrls: { [key: string]: string } = {};

    constructor(data: Record<string, any>) {
        Object.assign(this, data);
    }

    hasPages(): boolean {
        return !!this.pages.length;
    }

    hasNoPages(): boolean {
        return !this.hasPages();
    }

    // TODO: WBTGEN-2190
    getPreviewUrl(width: number, height: number, screenWidth: number = 1280): string {
        // const key = width + 'x' + height + 'x' + screenWidth;
        // if (!_previewUrls[key]) {
        //     _previewUrls[key] = getDAL().makePageTemplatePreviewUrl(
        //         this.id,
        //         { width, height, cropw: width, croph: height, screenWidth }
        //     );
        // }
        // return _previewUrls[key];

        return getDAL().makePageTemplatePreviewUrl(
            this.id,
            { width, height, cropw: width, croph: height, screenWidth }
        );
    }
}
