import { all, fork } from 'redux-saga/effects'; // eslint-disable-line n/file-extension-in-import
import { NEW_PAGE_DIALOG_OPEN } from "../actions";
import { receiveOnly } from '../../../../epics/makeCondition';
import makeResolveWhenFulfilledSaga from '../../../../epics/makeResolveWhenFulfilledSaga';
import addLayoutPageSaga from "./addLayoutPageSaga";
import handleLayoutPageTemplateSaga from './handleLayoutPageTemplateSaga';
import addLinkPageSaga from "./addLinkPageSaga";
import addSectionLinkSaga from "./addSectionLinkSaga";
import pageDataSetValueActionType from "../../../App/epics/pageDataset/valueActionType";

export default makeResolveWhenFulfilledSaga(
    receiveOnly(pageDataSetValueActionType),
    NEW_PAGE_DIALOG_OPEN,
    function* (
    ): Generator<any, void, void> {
        yield all([
            fork(addLayoutPageSaga),
            fork(handleLayoutPageTemplateSaga),
            fork(addLinkPageSaga),
            fork(addSectionLinkSaga),
        ]);
    }
);
