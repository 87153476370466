/* @flow */

const extend = require('extend');
const { Ctor } = require('../../server/shared/utils/ctor/Ctor.js');
const AwsLambdaModule = require("../../server/shared/awsServices/AwsLambda.js");
const GetTrialAwsConfigModule = require("./getTrialConfig.js");

/*::
    import type { AwsTrialConfig } from './types'
    import type { AwsServiceOptions } from '../../server/shared/awsServices/types';

    type InvokeFunctionType = {
        functionName: string,
        functionInvokeArgs: Object,
        lambdaOptions?: Object,
    }
 */

function TrialLambda(awsConfig/*: AwsTrialConfig */) {
    /*** Constructor ***/

    const
        defaultLambdaOptions = makeDefaultOptions();

    /*** Public ***/

    this.invokeFunction = ({
        functionName,
        functionInvokeArgs,
        lambdaOptions,
    }/*: InvokeFunctionType*/) => {
        const invokeFunctionParams = {
            FunctionName: functionName,
            Payload: Buffer.from(JSON.stringify(functionInvokeArgs)),
            LogType: 'None',
        };
        return TrialLambda.$protected(this)
            .lambda(lambdaOptions)
            .invoke(invokeFunctionParams).promise();
    };

    /*=== Protected ===*/
    const pm = {};

    pm.lambda = (extraOptions/*: AwsServiceOptions */) => {
        const lambdaOptions = extend(true, {}, defaultLambdaOptions, extraOptions);
        return new AwsLambdaModule.AwsLambda(lambdaOptions);
    };

    Ctor.protected(TrialLambda, pm);

    function makeDefaultOptions() {
        return {
            region: awsConfig.region,
        };
    }
}

const Module = {};

Module.TrialLambda = TrialLambda;

Module.getTrialLambda = () => new Module.TrialLambda(
    GetTrialAwsConfigModule.getTrialAwsConfig(),
);

module.exports = Module;
