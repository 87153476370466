import React from "react";
import cx from "classnames";
import styles from "../OnboardingWizard.css";
import { Msg } from "../../../../../view/intl";
import { BlogLayout, OnboardingOption, OnboardingStepProps } from "../types";
import { OPEN_AI_TERMS_UPDATE } from "../../../../SiteSettings/termsAndConditions/actionTypes";
import { PrimaryButton, SecondaryButton } from "../../../../../view/common/Button";
import { BLOG_LAYOUT_UPDATE, BLOG_WIZARD_RESET_AND_CLOSE } from "../epic/actionTypes";

/**
 * Last step of the onboarding, choose between list and grid layout
 */
export const StepBlogView = ({
    dispatch,
    selectedLayout,
    currentStep,
    setCurrentStep,
    setStartOnboarding,
    selectedOption,
    createBlog,
    blogHasPosts
}: OnboardingStepProps) => {
    const HandleOptionClick = (option: BlogLayout) => {
        dispatch({ type: BLOG_LAYOUT_UPDATE, payload: option });
    };

    const previousStep = () => {
        if (blogHasPosts) {
            // if this is the only view (only when blog has posts already) close the wizard
            dispatch({ type: BLOG_WIZARD_RESET_AND_CLOSE });
        } else if (currentStep === 0) {
            // in case this is the first step return to the option chooser
            setStartOnboarding(false);
        } else {
            setCurrentStep(currentStep - 1);
        }
    };

    const completeOnboarding = () => {
        // update open AI terms, if the user chose AI onboarding
        if (selectedOption === OnboardingOption.AI) {
            dispatch({ type: OPEN_AI_TERMS_UPDATE, payload: true });
        }

        createBlog();
    };

    return (
        <div className={styles.stepContainer} data-testid="step-blog-view-container">
            <div>
                <div className={styles.title} data-testid="step-blog-view-title">
                    {blogHasPosts ? (
                        <Msg k="component.blog.onboarding.step.blogView.returningUser.title">Welcome back!</Msg>
                    ) : (
                        <Msg k="component.blog.onboarding.step.blogView.title">Choose a layout</Msg>
                    )}
                </div>
                <div className={styles.description} data-testid="step-blog-view-description">
                    {blogHasPosts ? (
                        <Msg k="component.blog.onboarding.step.blogView.returningUser.description">
                            We can see that you’ve previously created posts. Select how the posts
                            should be shown on your website and let us set up your blog.
                        </Msg>
                    ) : (
                        <Msg k="component.blog.onboarding.step.blogView.description">
                            Select how your blog posts should be shown on your website.
                        </Msg>
                    )}
                </div>
                <div>
                    <div className={styles.radioElement} onClick={() => HandleOptionClick(BlogLayout.list)}>
                        <div
                            className={cx(styles.radioBox, {
                                [styles.checked]: selectedLayout === BlogLayout.list
                            })}
                            data-testid="radio-list"
                        />
                        <div className={cx(styles.radioImage, styles.listView)} />
                        <div>
                            <div className={styles.radioTitle}>
                                <Msg k="component.blog.onboarding.radio.listView.title">List view</Msg>
                            </div>
                            <div className={styles.radioDescription}>
                                <Msg k="component.blog.onboarding.radio.listView.description">Shows all posts below each other.</Msg>
                            </div>
                        </div>
                    </div>
                    <div className={styles.radioElement} onClick={() => HandleOptionClick(BlogLayout.grid)}>
                        <div
                            className={cx(styles.radioBox, {
                                [styles.checked]: selectedLayout === BlogLayout.grid
                            })}
                            data-testid="radio-grid"
                        />
                        <div className={cx(styles.radioImage, styles.gridView)} />
                        <div>
                            <div className={styles.radioTitle}>
                                <Msg k="component.blog.onboarding.radio.gridView.title">Card view</Msg>
                            </div>
                            <div className={styles.radioDescription}>
                                <Msg k="component.blog.onboarding.radio.gridView.description">
                                    Shows several posts next to each other in small boxes.
                                </Msg>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <SecondaryButton onClick={() => previousStep()} className={styles.wizardButton} isLarge data-testid="back-button">
                    {blogHasPosts ? <Msg k="common.cancel">Cancel</Msg> : <Msg k="common.back">Back</Msg>}
                </SecondaryButton>
                <PrimaryButton onClick={() => completeOnboarding()} className={styles.wizardButton} isLarge data-testid="continue-button">
                    {blogHasPosts ? (
                        <Msg k="component.blog.onboarding.button.createBlog">Create blog</Msg>
                    ) : (
                        <Msg k="component.blog.onboarding.button.finish">Complete setup</Msg>
                    )}
                </PrimaryButton>
            </div>
        </div>
    );
};
