import * as React from 'react';
import cx from 'classnames';

import pageInfoStyles from '../../../PageInfoDialog.css';
import styles from './PageInfoTemplateTabCom.css';
import { injectIntl, Intl, makeBoldIntlTag } from "../../../../../../../../../view/intl";
import type { PageInfoTemplateTabProps } from "../../../../flowTypes";
import {
    openTemplateTabPageTemplateSelectorAction, switchPageFromTemplateAction,
    // switchToTemplateContentAction,
    switchToTemplateSettingsAction,
    switchToTemplateStylesAction
} from "../actions";
import { LinkButtonCom } from "../../../../../../../../presentational/LinkButton/LinkButton";
import { InlineLoadingIndicatorCom } from "../../../../../../../../../view/common/LoadingIndicator/InlineLoadingIndicatorCom";
import { PageInfoTemplateTabMessages } from "./messages";
import { DemoTip } from '../../../../../../../../../../demo/modules/tip/DemoTip';
import { TooltipPosition } from '../../../../../../../../presentational/Tooltip/constants';

type WidgetProps = {
    title: string,
    desc: string,
    className: string,
    onClick: SimpleFunction,
    intl: Intl,
};

const Widget = injectIntl(({ title, desc, className, onClick, intl }: WidgetProps) => (
    <div className={cx(styles.widget, className)} onClick={onClick}>
        <span />
        <h3>{intl.msgJoint(title)}</h3>
        <article>{intl.msgJoint(desc)}</article>
    </div>
));

class PageInfoTemplateTabComClass extends React.Component<PageInfoTemplateTabProps> {
    onSettingsClick: SimpleFunction = () => {
        this.props.dispatch(switchToTemplateSettingsAction(this.props.pageRefId));
    };

    onStylesClick: SimpleFunction = () => {
        this.props.dispatch(switchToTemplateStylesAction(this.props.pageRefId));
    };

    /*
    onContentClick: SimpleFunction = () => {
        this.props.dispatch(switchToTemplateContentAction(this.props.pageRefId));
    };
    */

    onChangeTemplateClick: React.MouseEventHandler = () => {
        const { state: { selectedTemplate: { id }, templates }, dispatch } = this.props;
        dispatch(openTemplateTabPageTemplateSelectorAction({
            selectedTemplateId: id,
            templates
        }));
    };

    onSwitchPageClick: React.MouseEventHandler = () => {
        const { pageRefId, dispatch } = this.props;
        dispatch(switchPageFromTemplateAction(pageRefId));
    };

    renderCurrentTemplate() {
        const { state: { isLoading, selectedTemplate }, intl } = this.props;
        return (
            <div className={styles.currentTemplateContainer}>
                <span className={styles.currentTemplatePrefix}>
                    {intl.msgJoint(PageInfoTemplateTabMessages.currentTemplate.prefix)}
                </span>
                {
                    isLoading
                        ? (
                            <InlineLoadingIndicatorCom />
                        )
                        : (
                            <React.Fragment>
                                <span className={styles.currentTemplateName}>
                                    {selectedTemplate.name}
                                </span>
                                {/* @ts-ignore */}
                                <DemoTip position={TooltipPosition.BOTTOM}>
                                    <LinkButtonCom
                                        className={styles.changeLink}
                                        onClick={this.onChangeTemplateClick}
                                    >
                                        {PageInfoTemplateTabMessages.currentTemplate.change}
                                    </LinkButtonCom>
                                </DemoTip>
                            </React.Fragment>
                        )
                }
            </div>
        );
    }

    renderCurrentPage() {
        return (
            <React.Fragment>
                <div className={styles.panel}>
                    <Widget
                        title={PageInfoTemplateTabMessages.widget.settings.title}
                        desc={PageInfoTemplateTabMessages.widget.settings.desc}
                        className={styles.settings}
                        onClick={this.onSettingsClick}
                    />
                    <Widget
                        title={PageInfoTemplateTabMessages.widget.styles.title}
                        desc={PageInfoTemplateTabMessages.widget.styles.desc}
                        className={styles.styles}
                        onClick={this.onStylesClick}
                    />
                    {/*
                        // hiding template content option as there is not functionality for it as of now.
                        <Widget
                            title={PageInfoTemplateTabMessages.widget.content.title}
                            desc={PageInfoTemplateTabMessages.widget.content.desc}
                            className={styles.content}
                            onClick={this.onContentClick}
                        />
                    */}
                </div>
                {this.renderCurrentTemplate()}
            </React.Fragment>
        );
    }

    renderSwitchPage() { // eslint-disable-line
        const { pageName, intl } = this.props;

        return (
            <React.Fragment>
                <div className={styles.switchPageParagraphs}>
                    <p>{intl.msgJoint(PageInfoTemplateTabMessages.switchPage.p1)}</p>
                    <p>{intl.msgJoint([PageInfoTemplateTabMessages.switchPage.p2, { pageName, b: makeBoldIntlTag }])}</p>
                    <p>{intl.msgJoint([PageInfoTemplateTabMessages.switchPage.p3, { pageName, b: makeBoldIntlTag }])}</p>
                </div>
                <span className={styles.switchPageBtn} onClick={this.onSwitchPageClick}>
                    {intl.msgJoint(PageInfoTemplateTabMessages.switchPage.btn)}
                </span>
            </React.Fragment>
        );
    }

    render() {
        const { isCurrentPage } = this.props;

        return (
            <div className={pageInfoStyles.tab}>
                {isCurrentPage ? this.renderCurrentPage() : this.renderSwitchPage()}
            </div>
        );
    }
}

export const PageInfoTemplateTabCom = injectIntl(PageInfoTemplateTabComClass);
