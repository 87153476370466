import { ReactElement } from "react";

export enum OnboardingOption {
    AI = "ai",
    blank = "blank"
}

export enum BlogLayout {
    list = "list",
    grid = "grid"
}

export enum AIInputFieldId {
    category = "category",
    websiteName = "websiteName",
    location = "location",
    topics = "topics"
}

export enum BlogErrorType {
    openAIConnection = 501,
    openAIHighTraffic = 503,
    openAILimitReached = 429,
    openAILanguage = 451,
    internal = 500
}

export enum BlogErrorButtonType {
    back = "back",
    close = "close",
    tryAgain = "tryAgain"
}

export enum ErrorBackButton {
    startWithBlankBlog = "startWithBlankBlog",
    returnToPreviousStep = "returnToPreviousStep",
    returnToInputStep = "returnToInputStep"
}

type OnboardingState = {
    view: (props: any) => ReactElement;
    getImageClass: (props: any) => string;
};

export interface OnboardingStates {
    start: OnboardingState[];
    [key: string]: OnboardingState[];
}

export type AICategoryType = {
    value: string;
    label: string;
};

export interface AIData {
    category: AICategoryType;
    websiteName: string;
    location: string;
    language: string;
    topics: string;
}

export interface OnboardingStepProps {
    dispatch: any;
    selectedOption: OnboardingOption;
    setStartOnboarding: React.Dispatch<React.SetStateAction<boolean>>;
    currentStep: number;
    setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
    selectedLayout: BlogLayout;
    aiData: AIData;
    setAiData: React.Dispatch<React.SetStateAction<AIData>>;
    createBlog: SimpleFunction;
    blogHasPosts: boolean;
}

export type BlogErrorTextsType = {
    [key in BlogErrorType]: {
        icon: string;
        title?: string;
        description: string;
        descriptionAfterRetry?: string; // replaces the original description after a retry
        retrySuffix?: string;
        primaryButton: BlogErrorButtonType;
        secondaryButton: BlogErrorButtonType;
        secondaryButtonAfterRetry?: BlogErrorButtonType;
        backButtonType?: ErrorBackButton;
    };
};
