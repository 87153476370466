export const
    SHOW_STICKY_TOOLTIP = 'SHOW_STICKY_TOOLTIP',
    SHOW_CUSTOM_STICKY_TOOLTIP = 'SHOW_CUSTOM_STICKY_TOOLTIP',
    STICKY_TOOLTIP_CLIENT_RECT = 'STICKY_TOOLTIP_CLIENT_RECT',
    SHOW_TOOLTIP_FOR_AI_WRITING_ASSISTANT = 'SHOW_TOOLTIP_FOR_AI_WRITING_ASSISTANT',
    SHOW_TOOLTIP_FOR_NEW_COMPONENT = 'SHOW_TOOLTIP_FOR_NEW_COMPONENT',
    SHOW_PUBLISH_BUTTON_TOOLTIP = 'SHOW_PUBLISH_BUTTON_TOOLTIP',
    TO_SHOW_PUBLISH_BUTTON_TOOLTIP = 'TO_SHOW_PUBLISH_BUTTON_TOOLTIP',
    CLOSE_STICKY_TOOLTIP = 'CLOSE_STICKY_TOOLTIP',
    SHOW_TOOLTIP_FOR_DISABLED_SHOP_COMPONENT = "SHOW_TOOLTIP_FOR_DISABLED_SHOP_COMPONENT";
