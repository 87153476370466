import { depthSorter } from "../../../Preview/flattening/util";
import { isSectionKind, isStripKind } from "../../../oneweb/componentKinds";
import type { ComponentsMap } from "../../../../redux/modules/children/workspace/flowTypes";
import isGhost from "../../../oneweb/Code/isGhost";
import { isIntersecting } from "../componentsEval/utils";

const getCmpRect = ({ top, left, width, height, kind }) => ({
        top,
        left: (isStripKind(kind) || isSectionKind(kind)) ? -100000 : left,
        right: (isStripKind(kind) || isSectionKind(kind)) ? 1000000 : (left + width),
        bottom: (top + height)
    }),
    getInteractionsBelowCmp = (cmp, cmps, i) => {
        let interactions: any = [], cmpRect = getCmpRect(cmp);
        for (let j = i + 1; j < cmps.length; j++) {
            if (cmp.onHover && cmps[j].onHover && cmp.onHover.show !== cmps[j].onHover.show) {
                continue;
            }
            if (isIntersecting(cmpRect, getCmpRect(cmps[j]))) {
                interactions.push(cmps[j]);
            }
        }
        return interactions;
    },
    getRelIn = (parent, child) => ({
        id: parent.id,
        top: child.top - parent.top,
        bottom: (child.top + child.height) - (parent.top + parent.height),
        left: parent.stretch ? child.left : (child.left - parent.left),
        right: parent.stretch ? Infinity : ((child.left + child.width) - (parent.left + parent.width))
    }),
    getCommonParent = (cmp1, cmp2, newComponentsMap, childParentMap) => {
        let c1 = cmp1, c2 = cmp2, c1ParentsIdsMap = {}, c2ParentsIdsMap = {}, c1Seq: any = [];
        while (childParentMap[c1] || childParentMap[c2]) {
            c1ParentsIdsMap[childParentMap[c1]] = true;
            c1Seq.push(childParentMap[c1]);
            c2ParentsIdsMap[childParentMap[c2]] = true;
            c1 = childParentMap[c1];
            c2 = childParentMap[c2];
        }
        const parentId = c1Seq.find(id => c2ParentsIdsMap[id]);
        return parentId ? newComponentsMap[parentId] : null;
    };

export const updateRelationWhenComponentCutsOff = (componentMap: ComponentsMap): ComponentsMap => {
    try {
        let newComponentsMap = { ...componentMap },
            cmps: any = [],
            childParentMap = {};
        Object.keys(newComponentsMap).forEach(id => {
            const cmp = newComponentsMap[id];
            if (!isGhost(cmp)) {
                cmps.push(cmp);
                if (cmp.relIn) {
                    childParentMap[cmp.id] = cmp.relIn.id;
                }
            }
        });
        cmps = cmps.sort(depthSorter).reverse();
        cmps.forEach((cmp, i) => {
            let c = newComponentsMap[cmp.id];
            if (!c.relIn) {
                return;
            }
            let interactions = getInteractionsBelowCmp(c, cmps, i);
            for (const interaction of interactions) {
                let item = newComponentsMap[interaction.id];
                if (item.id === c.relIn.id) {
                    break;
                }
                if (
                    item.relIn &&
                    item.relIn.id !== c.relIn.id &&
                    isIntersecting(getCmpRect(newComponentsMap[c.relIn.id]), getCmpRect(item))
                ) {
                    const parent = getCommonParent(c.id, item.id, newComponentsMap, childParentMap);
                    newComponentsMap[c.id] = {
                        ...newComponentsMap[c.id],
                        relIn: parent ? getRelIn(parent, c) : null
                    };
                }
            }
        });
        return newComponentsMap;
    } catch (e) {
        console.log('Error in calculating relIn cutoff', e);
        return componentMap;
    }
};
