const { ConfigComponentList } = require("../constants.js");

class FileChooserConfig {
    isFileChooserShown() {
        const fileChooser = this._featuresConfig[ConfigComponentList.FILE_CHOOSER];
        return fileChooser && fileChooser.goToFileManager && fileChooser.goToFileManager.show;
    }

    isShutterstockShown() {
        const fileChooser = this._featuresConfig[ConfigComponentList.FILE_CHOOSER];
        return (
            fileChooser &&
            fileChooser.shutterstock &&
            fileChooser.shutterstock.show
        );
    }

    isUnsplashShown() {
        const fileChooser = this._featuresConfig[ConfigComponentList.FILE_CHOOSER];
        return (
            fileChooser &&
            fileChooser.unsplash &&
            fileChooser.unsplash.show
        );
    }
}

module.exports = FileChooserConfig;
