// TODO: use tree-shakeable imports (import contains from 'validator/es/lib/contains';)
// eslint-disable-next-line n/file-extension-in-import
import contains from 'validator/lib/contains';
import pathUtil from '../utils/path.js';
import * as Types from './flowTypes';
import { TRIAL_STORAGE_PROTOCOL } from "../../trial/services/storage/constants.js";
import { makeUrl } from './makeUrl';
import { DAL_API_PREFIX, REPOSITORY_PREFIX, WEBSPACE_PREFIX } from './constants';

export const makeWebSpaceUrlFactory = (version: string, defaultPath: string) => {
    return ({ path, query = {}, options, domain }: Types.MakeDalUrlParams): string => {
        let
            prefix = domain ? [DAL_API_PREFIX, version, domain].join('/') : defaultPath, suffix = path;

        if (contains(path, REPOSITORY_PREFIX)) {
            prefix = DAL_API_PREFIX + '/' + version + '/repository';
            suffix = suffix.replace(REPOSITORY_PREFIX, '');
        } else if (contains(path, WEBSPACE_PREFIX)) {
            suffix = suffix.replace(WEBSPACE_PREFIX, '');
        } else if (contains(path, TRIAL_STORAGE_PROTOCOL)) {
            suffix = suffix.replace(TRIAL_STORAGE_PROTOCOL, '');
        }

        suffix = options && options.keepTrailingSlash ? pathUtil.ltrim(suffix) : pathUtil.trim(suffix);

        let webSpacePath = prefix + '/webspace/' + suffix;
        return makeUrl({ path: webSpacePath, query, options });
    };
};
