/* eslint-disable max-len */

import tracking from '@one.com/tracking-analytics/lib/tracking'; // eslint-disable-line n/file-extension-in-import
import * as windowActions from "./actionTypes";
import * as object from "../../../../utils/object.js";
import { WIDGET_CANNOT_LOAD, WIDGET_LOADED } from "../../../constants/app";
import { loadChat, loadChatScript } from "../../../utils/loadChat";
import { SEND_EVENT_AEC, UPGRADE_COMPLETE } from "../../../components/App/actionTypes";
import { pushGtmLayerItem } from "../../../components/GoogleTagManager/pushGtmLayerItem";
import { logGtmLayerItem } from "../../../components/GoogleTagManager/logGtmLayerItem";
import { getAppConfig } from "../../../components/App/epics/appConfig/appConfig.js";
import { PartnerName } from '../../../../../server/shared/partners/PartnerName.js';
import { getWindowOrigin } from '../../../utils/windowHelper';
import { upgradeCanceledAction } from '../../../../../src/dashboard/src/components/Main/UpgradePlansDialog/actions';
import { UpgradeCancelReason } from '../../../components/EventTracking/constants';

const appConfig = getAppConfig();

const getMatchingElementOffsetTop = (attribute, value) => {
    // Get offsetTop of matching element
    const results = window.document.querySelectorAll(`[${attribute}="${value}"]`);

    let top = 0;

    if (results.length) {
        const el = results[0];
        // @ts-ignore
        top = el.offsetParent.offsetTop + el.offsetTop;
    }

    return top;
};

const getMatchingElementWrapIndex = (dataWrapId: string): number => {
    const results = window.document.querySelectorAll(`[data-wrap-id="${dataWrapId}"]`);

    if (!results.length) {
        return 0;
    }

    // @ts-ignore
    const [dataWrapEl] = results;

    // To get the wrap index, find the index of <p> element that contains the result element
    let paragraphWrapper = dataWrapEl.parentNode;

    // @ts-ignore
    while (paragraphWrapper && !/textnormal/.test(paragraphWrapper.className) &&
    // @ts-ignore
        paragraphWrapper.tagName !== 'P' && !/mceNonEditable/.test(paragraphWrapper.className)) {
        paragraphWrapper = paragraphWrapper.parentNode;
    }

    if (!paragraphWrapper) {
        return 0;
    }

    const textEl = paragraphWrapper.parentNode;
    // @ts-ignore
    const paragraphs = Array.from(textEl.childNodes);

    // @ts-ignore
    return paragraphs.indexOf(paragraphWrapper);
};

type WindowDataMiddlewareScope = {
    windowRef: Window | null;
    isUpgradeCompleteListenerAttached: boolean;
    isUpgradeCompleted: boolean;
};

export default (store: Store) => {
    const scope: WindowDataMiddlewareScope = {
        windowRef: null,
        isUpgradeCompleteListenerAttached: false,
        isUpgradeCompleted: false,
    };

    return (next: Dispatch) => (action: Action) => { // eslint-disable-line consistent-return
        const { type, payload } = action;

        switch (type) {
            case windowActions.LOCATION_RELOAD: {
                window.document.location.reload();
                break;
            }
            case windowActions.WINDOW_OPEN: {
                let url, name, specs, replace;

                if (object.isObject(payload)) {
                    ({ url, name, specs, replace } = payload);
                } else {
                    url = payload;
                }

                // @ts-ignore
                scope.windowRef = window.open(url, name, specs, replace);

                if (scope.windowRef && !scope.windowRef.closed) { // Sanity check as if popup blocker is enabled, window maynot open
                    scope.windowRef.focus();
                }

                break;
            }

            case windowActions.WINDOW_NAVIGATE_TO_LOCATION: {
                window.location.assign(payload);
                break;
            }

            case windowActions.WINDOW_REPLACE_LOCATION: {
                window.location.replace(payload);
                break;
            }

            case windowActions.WINDOW_NAVIGATE_TO_BLANK_LOCATION_ACTION: {
                window.open(payload);
                break;
            }

            case windowActions.WINDOW_NAVIGATE_TO_POPUP_LOCATION_ACTION: {
                window.open(payload.targetUrl, payload.windowTitle, payload.windowSettings);
                break;
            }

            case windowActions.WINDOW_NAVIGATE_TO_SELF: {
                window.skipUnsavedChangesPrompt = true;
                window.location.reload();
                break;
            }

            case windowActions.WINDOW_DELETE_DOM_BY_ID: {
                payload.forEach(domId => {
                    const elem = window.document.getElementById(domId);
                    if (elem) {
                        elem.remove();
                    }
                });
                break;
            }

            case windowActions.WINDOW_DELETE_GLOBAL_OBJECTS: {
                payload.forEach(globalObjectRef => {
                    if (window[globalObjectRef]) {
                        delete window[globalObjectRef];
                    }
                });
                break;
            }

            case windowActions.WINDOW_REMOVE_SCRIPT_TAGS: {
                payload.forEach(url => {
                    const scripts = window.document.getElementsByTagName('script');
                    for (const script of scripts) {
                        if (script.src && script.src.indexOf(url) > -1) {
                            // @ts-ignore
                            script.parentNode.removeChild(script);
                            break;
                        }
                    }
                });
                break;
            }

            case windowActions.WINDOW_MEASURE_DOM_ELEMENT: {
                const { attribute, value } = payload;
                const top = getMatchingElementOffsetTop(attribute, value);

                store.dispatch({
                    type: windowActions.DOM_ELEMENT_MEASURE,
                    payload: {
                        "data-wrap-id": value,
                        "value": top
                    }
                });

                setTimeout(() => store.dispatch({ type: windowActions.AFTER_DOM_ELEMENT_MEASURE }), 0);

                break;
            }

            case windowActions.WINDOW_BATCH_MEASURE_DOM_ELEMENTS: {
                // Get offsetTop of matching elements
                const topValues = payload.map(({ attribute, value }) => ({
                    [attribute]: value,
                    top: getMatchingElementOffsetTop(attribute, value),
                    index: getMatchingElementWrapIndex(value)
                }));

                store.dispatch({
                    type: windowActions.BATCH_DOM_ELEMENT_MEASURE,
                    payload: topValues
                });

                break;
            }

            // send tracking only to AEC
            case SEND_EVENT_AEC: {
                const {
                    category,
                    action,
                    opt_label: label,
                    opt_value: value
                } = payload;
                tracking.sendGoogleAnalyticsEventToAppEventCollector(['_trackEvent', category, action, label, value]);
                break;
            }

            case windowActions.WINDOW_SHOW_WIDGET: {
                if (window.zE) {
                    window.zE.activate();
                }
                break;
            }

            case windowActions.WINDOW_CHAT_ADD_TAG: {
                if (window.zE && payload) {
                    window.zE('webWidget', 'chat:addTags', payload);
                }
                break;
            }

            case windowActions.WINDOW_CHAT_REMOVE_TAG: {
                if (window.zE && payload) {
                    window.zE('webWidget', 'chat:removeTags', payload);
                }
                break;
            }

            case windowActions.WINDOW_LOAD_CHAT: {
                if (appConfig.partnerName === PartnerName.ONECOM) {
                    loadChatScript(() => {
                        loadChat(payload).then(() => {
                            if (window.zE) {
                                window.zE.hide();
                            }
                            store.dispatch({ type: WIDGET_LOADED });
                        }, () => {
                            if (window.zE) {
                                window.zE.hide();
                            }
                            store.dispatch({ type: WIDGET_CANNOT_LOAD });
                        });
                    });
                } else {
                    store.dispatch({ type: WIDGET_CANNOT_LOAD });
                }
                break;
            }

            case windowActions.WINDOW_SCROLL_INTO_VIEW: {
                if (payload) {
                    const element = window.document.querySelector(payload);
                    if (element) {
                        element.scrollIntoView();
                    }
                }
                break;
            }

            case windowActions.WINDOW_LISTEN_FOR_UPGRADE_COMPLETE: {
                scope.isUpgradeCompleted = false;

                if (!scope.isUpgradeCompleteListenerAttached) {
                    window.addEventListener('message', (event) => { // NOSONAR
                        if (event.origin !== getWindowOrigin(window)) {
                            return;
                        }

                        if (event.data?.purpose !== 'upgrade-to-premium') {
                            return;
                        }

                        scope.isUpgradeCompleted = true;
                        store.dispatch({ type: UPGRADE_COMPLETE, payload: event });
                    });

                    scope.isUpgradeCompleteListenerAttached = true;
                }

                const upgradeWindowRef = scope.windowRef;

                if (upgradeWindowRef) {
                    const interval = setInterval(() => {
                        if (upgradeWindowRef.closed) {
                            clearInterval(interval);

                            if (!scope.isUpgradeCompleted) {
                                store.dispatch(upgradeCanceledAction(UpgradeCancelReason.UserCancelled));
                            }
                        }
                    }, 1000);
                }

                break;
            }

            case windowActions.GOOGLE_TAG_MANAGER_EVENT: {
                if (payload && window.dataLayer && window.dataLayer.push) {
                    pushGtmLayerItem(payload);
                } else {
                    logGtmLayerItem(payload);
                }
                break;
            }

            case windowActions.WINDOW_FORCE_REPAINT_REFLOW: {
                // From Stackoverflow:
                // https://stackoverflow.com/questions/46976821/safari-11-text-center-letter-spacing-issue
                // Found while debugging WBTGEN-5188
                // letter-spacing in Safari is buggy and requires a forced repaint/reflow to properly
                // reflect the style changes.
                window.document.body.style.display = 'none';
                window.document.body.offsetWidth; // eslint-disable-line
                window.document.body.style.display = 'block';
                break;
            }

            case windowActions.SET_WINDOW_TITLE: {
                const { windowTitle } = payload;

                // Do not overwrite if empty title is being set - WBTGEN-13552
                if (windowTitle.length) {
                    window.document.title = windowTitle;
                }
                break;
            }

            default:
                break;
        }

        return next(action);
    };
};
