const { ConfigComponentList } = require("../constants.js");

class BackupRestoreConfig {
    isNamedBackupRestoreAllowed() {
        const backupRestore = this._featuresConfig[ConfigComponentList.BACKUP_RESTORE];
        return backupRestore && backupRestore.namedBackup && backupRestore.namedBackup.enabled;
    }
}

module.exports = BackupRestoreConfig;
