import { all, takeLatest, put, fork } from "redux-saga/effects"; // eslint-disable-line n/file-extension-in-import
import { NEW_PAGE_TEMPLATE_SAVED, NEW_PAGE_TEMPLATE_SELECTED_ACTION, NEW_PAGE_TEMPLATE_CANCELLED } from "../actions";
import { putGen, closeDialogGen } from "../../../../utils/saga";
import { openNewPageDialog } from "../actionCreators";

const reopenNewPageDialogGen = function* (): Generator<any, void, any> {
    yield* closeDialogGen();
    yield put(openNewPageDialog(false /* reload */));
};

const saveSaga = function* () {
    yield takeLatest(NEW_PAGE_TEMPLATE_SAVED, function* (action: AnyAction) {
        const { payload: selectedTemplateId } = action;
        yield* putGen(NEW_PAGE_TEMPLATE_SELECTED_ACTION, selectedTemplateId);
        yield* closeDialogGen();
    });
};

const cancelSaga = function* () {
    yield takeLatest(NEW_PAGE_TEMPLATE_CANCELLED, reopenNewPageDialogGen);
};

export default function* (): ForksSaga {
    yield all([
        fork(saveSaga),
        fork(cancelSaga)
    ]);
}
