import u from 'updeep';
import type { Rect } from "../../../PagesTree/pagesLogic/EmptyRects";
import type { AnyComponent, ComponentsMap, ComponentChange } from "../../../../redux/modules/children/workspace/flowTypes";
import isStretchComponentKind from "../../../oneweb/isStretchComponentKind";

export const
    getCmpCenter = (cmp: AnyComponent): number => cmp.top + (cmp.height / 2),
    isIntersecting = (r1: Rect, r2: Rect) => !(r2.left >= r1.right ||
        r2.right <= r1.left ||
        r2.top >= r1.bottom ||
        r2.bottom <= r1.top),
    getCmpRect = ({ top, left, width, height }: { top: number, left: number, width: number, height: number }) => ({
        top, left, right: (left + width), bottom: (top + height)
    }),
    getCmpsRect = (cmps: Array<AnyComponent>) => {
        return cmps.reduce((acc, c) => {
            const { top, left, right, bottom } = getCmpRect(c);
            return {
                top: Math.min(acc.top, top),
                left: Math.min(acc.left, left),
                right: Math.max(acc.right, right),
                bottom: Math.max(acc.bottom, bottom),
            };
        }, { top: Number.MAX_SAFE_INTEGER, left: Number.MAX_SAFE_INTEGER, right: 0, bottom: 0 });
    },
    isBoxInsideParentBox = (cBox: Rect, pBox: Rect, isStretchCmp: boolean = false) => {
        const childCenter = Math.round(cBox.top + (cBox.bottom - cBox.top) / 2);
        if (isStretchCmp) {
            return pBox.top <= childCenter && pBox.bottom > childCenter;
        }
        return pBox.left <= cBox.left && pBox.right >= cBox.right &&
            pBox.top <= cBox.top && pBox.bottom >= cBox.bottom;
    },
    isComponentInsideComponent = (childComponent: AnyComponent, parentComponent: AnyComponent) => {
        const isParentStretchKind = isStretchComponentKind(parentComponent.kind),
            parentBox = getCmpRect(parentComponent),
            childBox = getCmpRect(childComponent);
        return isBoxInsideParentBox(childBox, parentBox, isParentStretchKind);
    },
    changeComponentsMap = (componentsMap: ComponentsMap, changes: Array<ComponentChange>): ComponentsMap => {
        return u(changes.reduce(
            (acc: any, change: ComponentChange): any => {
                const newAcc = acc;
                newAcc[change.id] = change.value || newAcc[change.id];
                return newAcc;
            }, {}
        ),
        componentsMap);
    },
    isOverLapping = (cmp: AnyComponent, cmp2: AnyComponent) => {
        return isIntersecting(getCmpRect(cmp), getCmpRect(cmp2));
    };
