import { path } from 'ramda';

import { getAppConfig } from "../../../App/epics/appConfig/appConfig.js";
import getAccessManager from '../../../../getAccessManager';

export const getControlPanelLink = (domain: string) => `${path(['oneWeb', 'crm', 'domainControlPanelUrl'], getAppConfig())}${domain}`;

export const configHideGoToCPLink = () => {
    return !getAccessManager().isTopBarCPLinkShown();
};
