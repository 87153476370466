import { stringify } from 'flatted';
import platform from 'platform';
import registerDebugModule from './registerDebugModule';
import { warn } from '../../utils/log.js';
import { getExceptionDataById } from './storage';
import { SET_APP_STATE } from "../redux/modules/actionTypes";

import type { ErrorReport } from "../reportError";

function downloadJson(dataStr: string) {
    const MIME_TYPE = "text/csv";

    const blob = new Blob([dataStr], { type: MIME_TYPE });
    window.location.href = window.URL.createObjectURL(blob);
}

const
    printHelpAfterExeption = (exceptionId: string) => {
        warn(`
To generate error report run:
                debug.generateErrorReport(${exceptionId})
To download reproduction data run:
                debug.downloadReproduceData(${exceptionId})
`);
    },
    _downloadReproduceData = (report: any) => {
        const
            appStatePart = report.exData.stateBeforeException ? `
debug.setAppStateBeforeException(
    $R.parse(${stringify(report.exData?.stateBeforeException)})
)` : '',
            actionPart = report.exData.action ? `
debug.dispatchActionThatCausedException(
    $R.parse(${stringify(report.exData.action)})
)` : '';
        downloadJson(`
${appStatePart}

${actionPart}

debug.reproduceExceptionFromData(
    $R.parse(${stringify(report.exData)})
)`);
    },
    downloadReproduceData = (exceptionId: string) => {
        // eslint-disable-next-line dot-notation
        const exData: ErrorReport = window.debug["errorReportsById"][exceptionId];
        _downloadReproduceData(exData);
    },
    generateErrorReport = (exceptionId: string) => {
        // eslint-disable-next-line dot-notation
        const report: ErrorReport = window.debug["errorReportsById"][exceptionId];
        warn(`
Error report ${report.crashReportId}
- url: ${report.url}
- app version: ${report.codeVersion}
- browser: ${platform.description || 'PLEASE FILL ME'}
- device pixel ratio: ${report.devicePixelRatio}
- useragent: ${report.useragent}
- time: ${report.time}
- pageId: ${report.pageId}
- error message: ${report.message}
- domain: ${report.domain}
- type of exception: ${report.type}
- location in code: ${report.locationInCode}
- stack trace: ${report.stackTrace}
- latest actions types chain: ${report.latestActionsTypesChain}
- last action before exception: ${report.lastActionBeforeException}
- recent network requests:
 - ${report.networkLog.join(('\n - '))}
        `);
    },
    setAppStateBeforeException = (storeState) => {
        window.$R.store.dispatch({
            type: SET_APP_STATE,
            payload: {
                storeState
            }
        });
    },
    dispatchActionThatCausedException = (action) => {
        window.$R.store.dispatch(action);
    };

registerDebugModule('downloadReproduceData', downloadReproduceData);
registerDebugModule('getExceptionDataById', getExceptionDataById);
registerDebugModule('downloadJson', downloadJson);
registerDebugModule('generateErrorReport', generateErrorReport);
registerDebugModule('setAppStateBeforeException', setAppStateBeforeException);
registerDebugModule('dispatchActionThatCausedException', dispatchActionThatCausedException);

export {
    printHelpAfterExeption
};
