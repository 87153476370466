import type { ComponentsMap } from "../../../redux/modules/children/workspace/flowTypes";
import type { Group, Groups } from "../../MobileViewEditor/flowTypes";

export const getGroupByCmpId = (
    cmpId: string,
    componentsMap: ComponentsMap,
    groups: Groups = {}
): null | undefined | Group => {
    const cmpGroupId = Object.keys(groups).find(groupId => groups[groupId].find(id => id === cmpId));
    if (!cmpGroupId) {
        return null;
    }
    return groups[cmpGroupId].map(id => componentsMap[id]);
};

export const getGroupIdByCmpId = (
    cmpId: string,
    groups: Groups = {}
): null | undefined | string => {
    const cmpGroupId = Object.keys(groups).find(groupId => groups[groupId].find(id => id === cmpId));
    if (!cmpGroupId) {
        return null;
    }
    return cmpGroupId;
};
