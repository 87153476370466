import React from 'react';
import cx from 'classnames';
import { DroppableCmpShortcut } from "../view/DroppableCmpShortcut";
import styles from '../view/Inserter.css';
import BackgroundKind from "../../oneweb/Background/kind";
import { Intl } from "../../../view/intl/injectIntl";
import { getHTMLAndDimension } from "../../Workspace/epics/componentsEval/adjustAfterUpdate";
import { getByPath } from "../../../utils/ramdaEx";
import { styleBackgroundColor } from "../../../mappers/path";
import { showBGLayer } from "../../oneweb/Text/expandedPanel/expandedPanelContent";
import { getTextColorFromCmps } from "../../Workspace/epics/componentsEval/addComponentUtils";
import ConstrastBgHelpTip from "../view/ConstrastBgHelpTip";
import { Stylesheet } from '../../Workspace/epics/stylesheets/flowTypes';

type Props = {
    block: Record<string, any>,
    height: number,
    scale: number,
    html: string,
    intl: Intl,
    dispatch: Dispatch,
    isMultiCol?: boolean,
    marginLeft: number,
    transparent?: boolean,
    showContrastBgColor?: boolean,
    isNoOverlay?: boolean,
};

const BlocksItem = ({ block, height, scale, html, dispatch, isMultiCol, marginLeft, transparent,
    showContrastBgColor, isNoOverlay }: Props) => {
    return <DroppableCmpShortcut
        key={block.id}
        className={cx(styles.droppabalContainer, styles.blockItem, {
            [styles.doubleColumn]: isMultiCol,
            [styles.contrastBgLayer]: showContrastBgColor
        })}
        payload={{ sectionOrBlockId: block.id, width: block.width, height: block.height }}
        kind={block.kind || BackgroundKind}
        dispatch={dispatch}
        isNoOverlay={isNoOverlay}
    >
        {showContrastBgColor && <ConstrastBgHelpTip dispatch={dispatch} />}
        <div
            className={cx(styles.blocksContainer, { [styles.transparent]: transparent }, block.key)}
            style={{
                height,
                marginLeft
            }}
        >
            <div
                className={styles.blocksScaleContainer}
                style={{
                    transform: `scale(${scale})`
                }}
                dangerouslySetInnerHTML={{ __html: html }}
            />
        </div>
    </DroppableCmpShortcut>;
};

type BlocksItemProps = {
    block: Record<string, any>,
    componentsEval: Record<string, any>,
    styleSheets: Stylesheet,
    globalVariables: Record<string, any>,
    intl: Intl,
    dispatch: Dispatch,
    getHeightAndScale: Function,
    canTransparentBackground?: boolean,
    noMultiColumn?: boolean,
    autoColorMode?: boolean,
    isNoOverlay?: boolean,
};

const BlocksItemWrapper = ({
    block,
    componentsEval,
    styleSheets,
    globalVariables,
    intl,
    dispatch,
    getHeightAndScale,
    canTransparentBackground,
    noMultiColumn,
    autoColorMode,
    isNoOverlay,
}: BlocksItemProps) => {
    const [state, setState] = React.useState<Record<string, any> | null>(null);

    React.useEffect(() => {
        const response = getHTMLAndDimension({
            oldComponentsMap: block.data.componentsMap,
            oldTemplate: componentsEval.scope.template,
            currentPageId: componentsEval.scope.currentPageId,
            stylesheets: styleSheets,
            siteMap: componentsEval.scope.siteMap,
            siteSettings: componentsEval.scope.siteSettings,
            componentsDependencies: componentsEval.scope.componentsDependencies,
            globalVariables
        });
        const backgroundColor = getByPath(
            styleBackgroundColor,
            block.data.componentsMap[response.parentId]
        ) || [];

        setState({
            html: response.html,
            rect: response.rect,
            parentId: response.parentId,
            transparent: !backgroundColor[4]
        });
    }, [block, componentsEval, styleSheets, globalVariables]);

    if (!state) {
        return null; // Show loading if white patch is for longer time
    }

    const { height, scale, isMultiCol, marginLeft } = getHeightAndScale(state);
    const showContrastBgColor = !autoColorMode && state.transparent && (canTransparentBackground || noMultiColumn) &&
        getTextColorFromCmps(styleSheets, block.data.componentsMap, true).some(color => !showBGLayer(color));

    return <BlocksItem
        key={block.id}
        intl={intl}
        dispatch={dispatch}
        block={block}
        height={height}
        scale={scale}
        marginLeft={marginLeft}
        html={state.html}
        transparent={canTransparentBackground && state.transparent}
        isMultiCol={isMultiCol}
        showContrastBgColor={showContrastBgColor}
        isNoOverlay={isNoOverlay}
    />;
};

export default BlocksItemWrapper;
