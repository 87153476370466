import * as R from 'ramda';
import { isShiftBarTop } from "../../../../../utils/handle/index";
import type { ComponentsAdjustmentData } from '../flowTypes';
import type {
    ComponentsMap,
    BBox,
    ComponentsTreeLinkedNode,
    ComponentsTopChange,
    ComponentChange,
    AnyComponent,
    ComponentsTopChangeMap,
    ComponentsMapExtension
} from "../../../../../redux/modules/children/workspace/flowTypes";
import {
    getComponentsBBox,
    getTreeFromAllComponents
} from "../../../../../utils/componentsMap/index";
import { getResizingChanges } from './applyResizingComponentsMutations';
import * as HandleKinds from '../../../../../utils/handle/kinds';
import { SpaceAdjusterMinimumBetweenComponentsPx } from "../../../../../constants/app";
import { getAllChildren } from "../../../../../utils/componentsMap/getComponentsTree";
import { MAX_DIMENSIONS } from './constants';
import { isHeaderOrFooterSection } from '../../../../oneweb/Section/utils';
import { isDynamicHeightKind, isHoverBoxKind } from "../../../../oneweb/componentKinds";
import type { HoverBoxComponent } from "../../../../oneweb/HoverBox/flowTypes";
import { isModernHeaderOrFooter } from "../../../../ModernLayouts/utils";
import { MIN_SECTION_HEIGHT } from '../../../../oneweb/Section/constants';
import getChangesForCenteringCmpsOnSectionHeightChange from
    "../../../../ModernLayouts/getChangesForCenteringCmpsOnSectionHeightChange";

let changesMap: ComponentsTopChangeMap = {};

const getClosestHoverBoxId = (cmpId, relationsMap, componentsMap) => {
        let parentId = cmpId;
        do {
            const relInObj = relationsMap[parentId];
            if (relInObj) {
                const { relIn: { id } } = relInObj;
                if (isHoverBoxKind(componentsMap[id].kind)) { return id; }
                parentId = id;
                continue;
            }
            return null;
        } while (parentId);
    },
    ignoreOtherModeHoverBoxComponents = (node, componentsMap) => {
        const { hoverMode } = (componentsMap[node.id] || {}) as HoverBoxComponent;
        return getAllChildren(node, componentsMap)
            .filter(({ onHover }) => hoverMode !== (!!onHover && !!onHover.show))
            .map(({ id }) => id);
    },
    getAllIgnoreComponents = (selectedComponents, nodes, relationsMap, componentsMap) => {
        const parentHoverBoxIds = selectedComponents.reduce((acc, id) => {
            const parentHoverBoxId = getClosestHoverBoxId(id, relationsMap, componentsMap);
            if (!acc.includes(parentHoverBoxId) && !selectedComponents.includes(parentHoverBoxId)) {
                return [...acc, parentHoverBoxId];
            }
            return acc;
        }, []);
        return parentHoverBoxIds.reduce((acc, hoverBoxId) => {
            const node = nodes.find(({ id }) => id === hoverBoxId);
            if (node) { return [...acc, ...ignoreOtherModeHoverBoxComponents(node, componentsMap)]; }
            return acc;
        }, []);
    };

export function getAffectedComponentsChanges({
    nodes,
    distanceMouseWasMoved,
    selectedComponentsIds = [],
    generalMaxMovementDistance,
    componentsMap
}: {
    nodes: Array<ComponentsTreeLinkedNode>,
    distanceMouseWasMoved: number,
    selectedComponentsIds?: Array<string>,
    generalMaxMovementDistance: number,
    componentsMap: ComponentsMap
}): ComponentsTopChangeMap {
    let distanceMoved: number,
        distanceParentWasMoved: number,
        nodesToScan: Array<ComponentsTreeLinkedNode> = [...nodes],
        allChildren: Array<ComponentsTreeLinkedNode> = [];

    const distanceParentWasMovedMap = {};
    // calc to affect parent. Take first selected component
    const relInParent = nodes.reduce<ComponentsTreeLinkedNode | null>((parent, node, index) => {
        if (parent) {
            return parent;
        }
        if (selectedComponentsIds.indexOf(node.id) > -1
            && nodes[index - 1] &&
            selectedComponentsIds.indexOf(nodes[index - 1].id) === -1
        ) {
            return nodes[index - 1];
        }
        return null;
    }, null);
    let distanceToMoveComponents = distanceMouseWasMoved;
    if (relInParent) {
        const relInParentId = relInParent.id;
        allChildren = getAllChildren(relInParent, componentsMap)
            .concat(selectedComponentsIds.map(id => componentsMap[id]))
            .map(({ id }) => nodes.filter(node => node.id === id)[0]);
        const
            // @ts-ignore
            lowestChildToContainer = nodes.reduce((lowestChild, node) => {
                const relInId = R.path(['relIn', 'relIn', 'id'], node),
                    relInBottom = R.path(['relIn', 'relIn', 'bottom'], node),
                    lowestChildBottom = R.path(['relIn', 'relIn', 'bottom'], lowestChild);
                if (relInId === relInParentId) {
                    if (!lowestChild) {
                        return node;
                    }
                    return lowestChildBottom > relInBottom ? lowestChild : node;
                }
                return lowestChild;
            }, null),
            lowestBottomToContainer = R.path(['relIn', 'relIn', 'bottom'], lowestChildToContainer);

        if (lowestBottomToContainer !== null && lowestBottomToContainer < -200) {
            let distanceMouseWasMovedRelative = distanceMouseWasMoved >= 0 ? distanceMouseWasMoved : 0;
            if (lowestBottomToContainer + distanceMouseWasMovedRelative < -200) {
                distanceToMoveComponents = distanceMouseWasMoved >= 0 ? 0 : distanceMouseWasMovedRelative;
            } else {
                distanceToMoveComponents -= -lowestBottomToContainer - 200;
            }
        }
    }

    while (nodesToScan.length > 0) {
        // @ts-ignore
        const node: ComponentsTreeLinkedNode = nodesToScan.shift();
        let distanceToMoveComponentsOperational = distanceToMoveComponents;

        let nodeChange: ComponentsTopChange = { id: node.id, implicit: node.containerToSelectedNodes };
        if (allChildren.indexOf(node) > -1) {
            distanceToMoveComponentsOperational = distanceMouseWasMoved;
        }
        distanceParentWasMoved = distanceToMoveComponentsOperational;

        if (distanceParentWasMoved >= 0) {
            distanceMoved = distanceParentWasMoved;
            nodeChange[node.changeType] = distanceMoved;
        } else {
            if (!R.isNil(node.maxMovementDistance)
                && node.maxMovementDistance <= SpaceAdjusterMinimumBetweenComponentsPx) {
                let maxMovementDistance = node.maxMovementDistance;

                if (maxMovementDistance < generalMaxMovementDistance) {
                    maxMovementDistance = generalMaxMovementDistance;
                }

                if (selectedComponentsIds.indexOf(node.id) > -1) {
                    generalMaxMovementDistance = maxMovementDistance;
                    distanceParentWasMoved = distanceToMoveComponentsOperational;
                }
                distanceMoved = distanceParentWasMoved < node.maxMovementDistance
                    ? node.maxMovementDistance : distanceParentWasMoved;
            } else {
                distanceMoved = distanceParentWasMoved;
            }
            nodeChange[node.changeType] = distanceMoved;
        }

        distanceParentWasMovedMap[node.id] = distanceMoved;

        changesMap[nodeChange.id] = nodeChange;
    }

    R.forEachObjIndexed((change: ComponentsTopChange) => {
        const prop = change.diffTop ? 'diffTop' : 'diffHeight';

        // @ts-ignore
        if (change[prop] !== undefined && change[prop] < 0 && change[prop] < generalMaxMovementDistance) {
            change[prop] = generalMaxMovementDistance;
        }
    }, changesMap);

    return changesMap;
}

export function calculateTree(draggableKind: string,
    componentsMap: ComponentsMap,
    selectedComponentsIds: Array<string>,
    workspaceBBox: BBox,
    relationsMap: Record<string, any>): Array<ComponentsTreeLinkedNode> {
    const selectedComponents: Array<AnyComponent> = selectedComponentsIds.map(
            (id: string): AnyComponent => componentsMap[id]
        ),
        isTopShiftBar = isShiftBarTop(draggableKind),
        selectedComponentBBox: BBox = getComponentsBBox(selectedComponents, workspaceBBox),
        selectedComponentBboxTop: number = isTopShiftBar ?
            selectedComponentBBox.top : selectedComponentBBox.bottom;
    const updateRelationsMap = Object.keys(relationsMap).reduce((acc, id) => {
        const { top, bottom } = relationsMap[id].relIn;
        if (isTopShiftBar) {
            if (!(top >= 0 && bottom > 0)) {
                acc[id] = relationsMap[id];
            }
        } else if (!(top < 0 && bottom < 0)) {
            acc[id] = relationsMap[id];
        }
        return acc;
    }, {});

    return getTreeFromAllComponents(
        componentsMap,
        {
            top: selectedComponentBboxTop,
            left: selectedComponentBBox.left,
            right: selectedComponentBBox.right,
        },
        selectedComponentsIds,
        draggableKind,
        workspaceBBox,
        updateRelationsMap
    );
}

const applyAdjustingSpaceMutations =
    ({
        componentsMap,
        selectedComponentsIds,
        start,
        current,
        handleKind,
        workspaceBBox,
        adjustmentData,
        componentsMapExtension,
        relationsMap,
    }: {
        componentsMap: ComponentsMap,
        selectedComponentsIds: Array<string>,
        start: number,
        current: number,
        handleKind: string,
        workspaceBBox: BBox,
        adjustmentData: ComponentsAdjustmentData,
        componentsMapExtension: ComponentsMapExtension,
        relationsMap: Record<string, any>
    }): ComponentChange[] => {
        changesMap = {};
        let newComponentsMap = {};
        const isHeaderOrFooter = selectedComponentsIds.some(id => isHeaderOrFooterSection(componentsMap[id]));
        if (isHeaderOrFooter) {
            newComponentsMap = { ...componentsMap };
        } else {
            Object.entries(componentsMap).forEach(([id, cmp]: [string, any]) => {
                if (!cmp.isStickyToHeader || selectedComponentsIds.indexOf(id) !== -1) {
                    newComponentsMap[id] = cmp;
                }
            });
        }
        const
            allComponentsTree: ComponentsTreeLinkedNode[] = calculateTree(
                handleKind, newComponentsMap, selectedComponentsIds, workspaceBBox, relationsMap
            ),
            selectedComponentsTreeNodes: ComponentsTreeLinkedNode[] = allComponentsTree.filter(
                (node: ComponentsTreeLinkedNode) => selectedComponentsIds.includes(node.id)
            ),
            hasHeightChanges: boolean = selectedComponentsTreeNodes.some(
                (node: ComponentsTreeLinkedNode) => node.changeType === 'diffHeight'
            );
        let
            selectedComponentsChanges: Record<string, any> = [],
            generalMaxMovementDistance = -10000;
        if (hasHeightChanges) {
            // Here components epic is not original one because componentsMap is beforeTransient but componentsMapExtension is fresh
            selectedComponentsChanges = getResizingChanges({
                components: { componentsMap: newComponentsMap, componentsMapExtension },
                componentsIds: selectedComponentsIds,
                // Position is being changed vertically only
                start: { x: 0, y: start },
                current: { x: 0, y: current },
                handleKind: HandleKinds.ResizeS,
                workspaceBBox,
                componentsAdjustmentData: adjustmentData,
                isShift: false
            }).map(
                // Cut off unnecessary data
                ({ id, value: { height, width, top } }) => ({ id, value: { height, width, top } })
            );
            generalMaxMovementDistance = selectedComponentsChanges
                .map(c => c.value.height - newComponentsMap[c.id].height)
                // take the biggest change
                .sort((b, a) => a - b)[0];
        }

        const componentsToIgnore = getAllIgnoreComponents(selectedComponentsIds, allComponentsTree, relationsMap, componentsMap);

        let changes = R.reduce(
            (acc, change: ComponentsTopChange): ComponentChange[] => {
                if (componentsToIgnore.includes(change.id)) {
                    return acc;
                }
                if (change.diffHeight || change.diffHeight === 0) {
                    acc.push({
                        id: change.id,
                        value: {
                            height: isDynamicHeightKind(newComponentsMap[change.id].kind) ?
                                newComponentsMap[change.id].height + change.diffHeight :
                                Math.min(newComponentsMap[change.id].height + change.diffHeight, MAX_DIMENSIONS.height)
                        },
                        implicit: change.implicit
                    });
                } else if (change.diffTop) {
                    acc.push({
                        id: change.id,
                        value: {
                            top: newComponentsMap[change.id].top + change.diffTop
                        }
                    });
                }
                return acc;
            }, [],
            R.values(getAffectedComponentsChanges({
                nodes: allComponentsTree,
                distanceMouseWasMoved: current - start,
                selectedComponentsIds: selectedComponentsIds,
                generalMaxMovementDistance,
                componentsMap: newComponentsMap
            }))
        );

        const selectedCmp = componentsMap[selectedComponentsIds[0]];
        if (selectedCmp
            && HandleKinds.ShiftBarBottomSelection === handleKind
            && selectedComponentsIds.length === 1
            && isModernHeaderOrFooter(selectedCmp)) {
            changes = getChangesForCenteringCmpsOnSectionHeightChange(
                changes,
                selectedCmp,
                newComponentsMap,
                Math.max(
                    changes.find(({ id }) => id === selectedComponentsIds[0]).value.height,
                    MIN_SECTION_HEIGHT
                )
            );
        }
        return changes;
    };

export default applyAdjustingSpaceMutations;
