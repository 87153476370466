import { AbTest } from "../../../server/shared/abt/AbTest.js";
import { ABT_VARIANTS_KEY } from "../../../server/shared/abt/constants.js";
import isTestEnv from "../debug/isTestEnv";

let singleton;

export const wbtgenAbt = () => {
    if (!singleton || isTestEnv()) {
        singleton = new AbTest(window[ABT_VARIANTS_KEY]);
    }
    return singleton;
};
