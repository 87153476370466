import React from 'react';
import { connect } from 'react-redux';
import { Msg } from "../view/intl/index";
import { partnerIsOnecom } from "../../../server/shared/partners/PartnerName.js";
import { getPartnerName } from "./ComponentTierManager/utils";
import { isFreeUpgradeAvailableForBnE } from "./ComponentTierManager/getRecommendedSubscriptionType";
import type { AppState, SubscriptionData } from "../redux/modules/flowTypes";

type UpgradeTextProps = {
    subscriptionData: SubscriptionData;
}

const UpgradeText = ({ subscriptionData }: UpgradeTextProps) => {
    const { availableUpgrades } = subscriptionData;
    return partnerIsOnecom(getPartnerName()) && isFreeUpgradeAvailableForBnE(availableUpgrades)
        ? <Msg k="demo.login.signup.button.label">Start free trial</Msg>
        : <Msg k="common.upgrade">Upgrade</Msg>;
};

const mapStateToProps = ({ subscriptionData }: AppState) => ({ subscriptionData });

export default connect(mapStateToProps)(UpgradeText);
