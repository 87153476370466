import React from 'react';
import Page from '../../../../PropertiesPanel/view/PropertiesPage';
import NavigationGroup from "../../../../PropertiesPanel/view/IntlNavigationGroup";
import * as pagesIds from '../ids';
import pagesTitles from '../titles';
import type { ComponentDependencies } from '../../flowTypes';

const
    id = pagesIds.MAIN,
    title = pagesTitles[id],
    view = ({ navigateToPage }: ComponentDependencies) => (
        <Page>
            <NavigationGroup
                navigateToPage={navigateToPage}
                pagesTitles={pagesTitles}
                targetPagesIds={[pagesIds.FONT, pagesIds.BUTTON]}
            />
        </Page>
    );

export { id, title, view };
