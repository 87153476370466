const { ConfigComponentList } = require("../constants.js");

class GenerateScreenshotConfig {
    shouldGenerateScreenshot() {
        const generateScreenshot = this._featuresConfig[ConfigComponentList.GENERATE_SCREENSHOT];
        return generateScreenshot && generateScreenshot.enabled;
    }
}

module.exports = GenerateScreenshotConfig;
