import React from "react";
import { ComponentMaskBackground } from '../../../presentational/ComponentMask/constants';
import ComponentMask from '../../../presentational/ComponentMask/index';
import Preview from './previewView';
import Note from './note';
import type { ViewProps } from '../flowTypes';
import { loadSubscriptionData } from "../../../App/actionCreators/index";

type state = {
    heightFromWS?: null | number
}

// TODO: add flow Props
export default class View extends React.Component<ViewProps, state> {
    constructor(props: ViewProps) {
        super(props);
        this.state = {};
    }

    updateWebshopDetails = (event: any) => {
        if (event.data.type === "UPDATE_WEBSHOP_HEIGHT") {
            this.setState({
                heightFromWS: event.data.data
            });
        } else if (event.data.type === 'UPGRADED_TO_ECOMMERCE') {
            // Dispatch appropriate action
            this.props.dispatch(loadSubscriptionData());
        }
    };

    UNSAFE_componentWillMount() {
        window.addEventListener("message", this.updateWebshopDetails, false);
    }

    componentWillUnmount() {
        window.removeEventListener("message", this.updateWebshopDetails, false);
    }

    render() {
        const props: ViewProps = this.props;
        const heightFromWS: null | undefined | number = this.state.heightFromWS;
        return (
            <React.Fragment>
                {(!props.componentTierData || (props.componentTierData && !props.componentTierData.isCompatible)) &&
                    <ComponentMask
                        maskStyle={ComponentMaskBackground.TRANSPARENT}
                        inEditMode={props.inEditMode}
                        inSelectedMode={props.inSelectedMode}
                        componentTierData={props.componentTierData}
                        componentWidth={props.component.width}
                        forceShowMask={props.componentChanging}
                        style={{ height: props.component.height }}
                    >
                        { /* @ts-ignore */ }
                        <Preview {...props} heightFromWS={heightFromWS} />
                    </ComponentMask>}
                {props.componentTierData && props.componentTierData.isCompatible &&
                    <ComponentMask
                        forceShowMask={props.componentChanging}
                        style={{ height: props.component.height }}
                        siblingContent={props.showNote && <Note dispatch={props.dispatch} />}
                        maskStyle={ComponentMaskBackground.TRANSPARENT}
                        inEditMode={props.inEditMode}
                        inSelectedMode={props.inSelectedMode}
                        componentTierData={props.componentTierData}
                        componentWidth={props.component.width}
                    >
                        { /* @ts-ignore */ }
                        <Preview {...props} heightFromWS={heightFromWS} />
                    </ComponentMask>}
            </React.Fragment>
        );
    }
}
