import CALL_API from "../../../../../redux/middleware/api/CALL_API";
import {
    GET_BLOG_DETAILS_FAILURE,
    GET_BLOG_DETAILS_REQUEST,
    GET_BLOG_DETAILS_SUCCESS
} from "./actionTypes";

/**
 * Calls the blog app to fetch the blog details for the given domain\
 * returns 404 if no blog exists for this domain
 * @param siteId - Site id/domain name for which to check
 */
export const getBlogDetails = () => {
    return {
        [CALL_API]: {
            // types define the actions which are called
            // when the request is called, request succeeded and failed
            types: [GET_BLOG_DETAILS_REQUEST, GET_BLOG_DETAILS_SUCCESS, GET_BLOG_DETAILS_FAILURE],
            endpoint: "getBlogDetails"
        }
    };
};
