import React from "react";
import { getPostContentPlaceholderStr, getPostDivId } from "../../../../../../../server/shared/blog/utils.js";
import LoadingIndicator from "../../../../../view/common/LoadingIndicator";
import styles from "../../BlogComponent/view/Blog.css";
import { BlogPreviewErrorMessage } from "../../BlogComponent/view/previewError";

const PostPreview = ({ component: { blogId, width, height }, flagPublish, selectedParentTheme, uiLocale }) => {
    const id = getPostDivId(blogId);
    const contentId = getPostContentPlaceholderStr(blogId);

    // In published site (server/lib/handlers/publish/updateBlogRelatedPageHtml.js):
    // - `data-theme-${blogId}` is being used to pass the `selectedParentTheme`
    // - `contentId` is replaced by the preview HTML contents.
    // - `contentId:error` is replaced by "true" or "false" based on error status.
    // To ensure uniqueness of the property names, `blogId` has been included.

    const extraProps: Partial<React.HTMLAttributes<HTMLDivElement>> = {};
    if (flagPublish) {
        extraProps[`data-error`] = `data-error-value-${blogId}`;
        extraProps[`data-theme-${blogId}`] = selectedParentTheme;
        extraProps[`data-locale-${blogId}`] = uiLocale;
        extraProps.style = { width: "inherit", minHeight: "inherit" };
    } else {
        extraProps["data-loading"] = true;
        extraProps.style = { width, minHeight: height };
    }

    return (
        <div id={id} data-testid="post-preview" className={selectedParentTheme} {...extraProps}>
            <div className={styles.container} data-content>
                {flagPublish && contentId}
            </div>
            <LoadingIndicator className={styles.loading} data-testid="loading-indicator" />
            <BlogPreviewErrorMessage className={styles.error} data-testid="error-message" />
        </div>
    );
};

export default PostPreview;
