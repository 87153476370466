import { makeUri } from '@libj/make-uri';
import type { AwsAmplifyCognitoUserT } from '../../../server/shared/awsServices/cognito/types';
import { AwsAmplifyCognitoUser } from '../../../server/shared/awsServices/cognito/AwsAmplifyCognitoUser.js';
import { TrialUrl } from '../../../server/shared/trial/TrialUrl.js';
import { AwsCognitoUserNativeAttribute } from '../../../server/shared/awsServices/cognito/AwsCognitoUserAttribute.js';
import { TrialUserCustomAttributes } from '../../../trial/lambda-functions/src/TrialUserCustomAttributes.js';
import { getWindowLocationQuery } from "../../src/utils/getWindowLocationQuery";
import { BuyTrialMock } from "../../../server/shared/trial/BuyTrialMock.js";
import { SubscriptionType } from "../../../server/shared/accessManager/constants.js";

/*** Main ***/

export const makeBuyTrialUrl = (
    user: AwsAmplifyCognitoUserT,
    cpUpgradeUrl: string,
    purchaseDomain?: string,
    wsbUpgradeType?: string
): string => {
    const amplifyUser = AwsAmplifyCognitoUser({ attributes: {}, storage: {}, ...user });

    // One.com CP user case

    const isOneComCpUser = amplifyUser.attributes.getOptional(TrialUserCustomAttributes.IS_ONE_COM_CP_USER);

    if (isOneComCpUser) {
        return cpUpgradeUrl.replace('{domain}', amplifyUser[AwsCognitoUserNativeAttribute.USERNAME]);
    }

    // Regular user case

    const
        trialId = amplifyUser.attributes.get(AwsCognitoUserNativeAttribute.SUB),
        token = amplifyUser.attributes.get(TrialUserCustomAttributes.HASHED_SUB_ID);

    return makeBuyTrialRegularUrl(trialId, token, purchaseDomain, wsbUpgradeType);
};

/*** Lib ***/

export function makeBuyTrialRegularUrl(
    trialId: string,
    token: string,
    purchaseDomain?: string,
    wsbUpgradeType?: string
): string {
    const query: Record<string, any> = { trialId, token };

    if (purchaseDomain) {
        query.trialDomainNameForPurchase = purchaseDomain;
    }

    if (wsbUpgradeType &&
        (wsbUpgradeType === SubscriptionType.PREMIUM ||
        wsbUpgradeType === SubscriptionType.ECOMMERCE ||
        wsbUpgradeType === SubscriptionType.NORMAL)) {
        query.wsbPackage = wsbUpgradeType.toLowerCase();
    }

    if (getWindowLocationQuery()[BuyTrialMock.MODE_NAME] === true) {
        query[BuyTrialMock.MODE_NAME] = 'true';
    }

    return makeUri({ path: TrialUrl.buy(), query });
}
