import type { FileChooserRenameDialogInProps } from "../../flowTypes";
import { extname, basename } from '../../../../../../utils/path.js';
import { makeInputDefaultState } from "../../../../../components/inputControls/input/reducerFactory/makeInputDefaultState";

export const uploadRenameDialogMakeDefaultState = ({ fileName }: FileChooserRenameDialogInProps) => {
    const
        ext = extname(fileName),
        name = basename(fileName, ext);

    return makeInputDefaultState({
        value: name
    });
};
