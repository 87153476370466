/* eslint-disable max-classes-per-file */
import * as React from 'react';
import styles from './GridLayout.css';
import Scrollbar from '../Scrollbar/index';
import type { ScrollbarTheme } from "../Scrollbar/index";
import type { ReactElementRef } from "../../../globalTypes";
import { isString } from '../../../../utils/string.js';

export type GridOnScrollHandler = (arg: Record<string, any>) => void;
const VISIBILITY_BUFFER_SIZE = 2;

class Frame {
    from: number;
    to: number;

    constructor(from: number, to: number) {
        this.from = from;
        this.to = to;
    }

    containsWithBuffer(rowIndex, buffer) {
        const
            finalFrom = this.from - buffer,
            finalTo = this.to + buffer;

        return rowIndex >= finalFrom && rowIndex <= finalTo;
    }

    containsRow(rowIndex) {
        return rowIndex >= this.from && rowIndex <= this.to;
    }

    doesntContainRow(rowIndex) {
        return !this.containsRow(rowIndex);
    }
}

type Props = {
    rowSize: number,
    height: number,
    dataSet: Array<{ [key: string]: any }>,
    cell: AnyReactComponent,
    cellHeight: number,
    gutterWidth: number,
    gutterHeight: number,
    scrollTop?: null | number,
    scrollToCell?: null | number,
    isLazy?: null | boolean,
    isFreeOneComVideo?: null | boolean,
    onScroll?: GridOnScrollHandler,
    scrollbarTheme?: ScrollbarTheme,
};

type State = {
    rows: Array<any>,
    scrollToCell: null | undefined | number,
    height: null | undefined | number,
};

export default class extends React.Component<Props, State> {
    static defaultProps = {
        gutterWidth: 0, // eslint-disable-line
        gutterHeight: 0 // eslint-disable-line
    };

    scrollToCell: null | undefined | number = null;

    cellStyle: { marginLeft: number, marginRight: number, marginTop: number, marginBottom: number };

    scrollRef: ReactElementRef<any>;

    currentScrollHeight: number = 0;
    currentScrollTop: number = 0;

    constructor({ gutterWidth, gutterHeight, cellHeight }: Props) {
        // @ts-ignore
        super();
        this.state = {
            rows: [],
            scrollToCell: null, // eslint-disable-line react/no-unused-state
            height: null
        };

        // build cell style
        const
            marginHor = Math.floor(gutterWidth / 2),
            marginVer = Math.floor(gutterHeight / 2);

        this.cellStyle = {
            marginLeft: marginHor,
            marginRight: marginHor,
            marginTop: marginVer,
            marginBottom: marginVer
        };

        this.scrollRef = React.createRef();

        // validate proxy props
        if (!cellHeight) {
            throw new Error('Missing required props.cellHeight');
        }
    }

    _getFrame(argHeight: null | undefined | number): Frame {
        const
            { height: propHeight, cellHeight } = this.props,
            height = isString(propHeight) ? argHeight || this.state.height || 0 : propHeight,
            from = this._getScrollCellOffset(),
            // @ts-ignore
            to = from + Math.ceil(parseInt(height, 10) / cellHeight);

        return new Frame(from, to);
    }

    _getScrollCellOffset() {
        const { rowSize, dataSet } = this.props,
            rowHeight = this.currentScrollHeight / (dataSet.length / rowSize);

        return this.scrollToCell
            ? Math.floor(this.scrollToCell / rowSize)
            : Math.floor(this.currentScrollTop / rowHeight);
    }

    _getRows(props: Props, height?: null | number) {
        const { dataSet, rowSize, isLazy } = props;

        const
            rowCount = Math.ceil(dataSet.length / rowSize),
            rows: Record<string, any>[] = [],
            frame = this._getFrame(height);

        for (let i = 0; i < rowCount; i++) {
            const
                start = i * rowSize,
                end = start + rowSize,
                isVisible = frame.containsWithBuffer(i, VISIBILITY_BUFFER_SIZE);

            const columns: Record<string, any> = dataSet.slice(start, end).map((props, j) => {
                // define isProxy
                let isProxy = false;
                if (isLazy) {
                    // proxy can only be outside the frame
                    if (frame.doesntContainRow(i)) {
                        // set isProxy=true for new cells or leave as is
                        const oldCell = this.state.rows.length && this.state.rows[i]
                            ? this.state.rows[i][j]
                            : null;
                        isProxy = !oldCell ? true : oldCell.isProxy;
                    }
                }

                return { props, isProxy, isVisible };
            });
            rows.push(columns);
        }

        return rows;
    }

    _getScrollToVertical() {
        if (this.props.scrollTop !== undefined && this.props.scrollTop !== null) {
            return this.props.scrollTop;
        }

        if (!this.scrollToCell) return null;

        const { cellHeight, gutterHeight } = this.props;
        return this._getScrollCellOffset() * (cellHeight + gutterHeight);
    }

    componentDidMount() {
        const scrollRef = this.scrollRef.current,
            height = scrollRef && scrollRef.view.getBoundingClientRect().height;

        this.currentScrollHeight = (scrollRef && scrollRef.view.scrollHeight) || 0;
        this.setState({ height, rows: this._getRows(this.props, height) });
    }

    UNSAFE_componentWillMount() {
        this.scrollToCell = this.props.scrollToCell;
        this.setState({ rows: this._getRows(this.props) });
    }

    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        this.scrollToCell = nextProps.scrollToCell;
        this.setState({ rows: this._getRows(nextProps) });
    }

    onScroll(scrollValues: Record<string, any>) {
        // onScroll happens even when scrollToHorizontal is used
        // so to distinguish between scrollTo command and user scroll check for this.scrollToCell
        // and reset scrollTo command so next time on user scroll we don't stuck on scrollTo position
        if (this.scrollToCell) {
            this.scrollToCell = null;
            return;
        }

        const { scrollTop, scrollHeight } = scrollValues;
        this.currentScrollHeight = scrollHeight;
        this.currentScrollTop = scrollTop;

        this.setState({
            rows: this._getRows(this.props)
        });

        if (this.props.onScroll) this.props.onScroll(scrollValues);
    }

    renderItem(cellData: any, i: number) {
        const
            { cell: cellElem, isFreeOneComVideo } = this.props,
            { isProxy, isVisible } = cellData,
            cellProps = { ...cellData.props, isFreeOneComVideo, isProxy, isVisible };

        const cell = { display: cellElem };

        return (
            <div key={i} style={this.cellStyle}>
                { /* @ts-ignore */ }
                <cell.display {...cellProps} />
            </div>
        );
    }

    render() {
        const rows: React.JSX.Element[] = [];
        for (let i = 0; i < this.state.rows.length; i++) {
            const columns = this.state.rows[i].map((cellData, j) => this.renderItem(cellData, j));
            rows.push(
                <div className={styles.row} key={i}>
                    {columns}
                </div>
            );
        }

        return (
            <Scrollbar
                height={this.props.height}
                className={styles.scrollbar}
                onScroll={this.onScroll.bind(this)}
                scrollToVertical={this._getScrollToVertical()}
                theme={this.props.scrollbarTheme}
                scrollRef={this.scrollRef}
            >
                <div>{rows}</div>
            </Scrollbar>
        );
    }
}
