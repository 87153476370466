import pathUtil from '../utils/path.js';
import { TRIAL_STORAGE_PROTOCOL } from "../../trial/services/storage/constants.js";
import * as Types from './flowTypes';
import { init as initDal } from './index';
import { makeWebSpaceUrlFactory as makeWebSpaceUrlDefaultFactory } from './makeWebSpaceUrlFactory';
import { getWbtgenTrialStorage } from "../demo/services/wbtgenTrialStorage";

export const init = (domain: string, trialUserIdentityId: string): void => {
    const webSpaceUrlTrialFactory = (path: string, query: any) => {
        return getWbtgenTrialStorage()
            .makeUserResourceUrl(trialUserIdentityId, path, query);
    };

    const makeWebSpaceUrlFactory = (version: string, defaultPath: string) => {
        const defaultFactory = makeWebSpaceUrlDefaultFactory(version, defaultPath);

        return ({ path, query = {}, options, domain }: Types.MakeDalUrlParams): string => {
            if (isTrialStorage(path, trialUserIdentityId)) {
                return webSpaceUrlTrialFactory(path, query);
            }

            return defaultFactory({ path, query, options, domain });
        };
    };

    return initDal(domain, makeWebSpaceUrlFactory);
};

function isTrialStorage(path: string, trialUserIdentityId?: string) {
    return path.indexOf(TRIAL_STORAGE_PROTOCOL) === 0
        && trialUserIdentityId
        && pathUtil.extname(path);
}
