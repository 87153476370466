import { sendGTMEvent } from "../../../../App/actionCreators/googleTagManager";
import { WSB_GTM_EVENT } from "../../../events";
import type { EventParamType } from "../../../flowTypes";
import { DASHBOARD_READY_TRACKER } from "./events";

const APP_STATUS_READY = 'APP_STATUS_READY';

const trackEventUserEnteredDashboard = (params: EventParamType) => sendGTMEvent({
    event: WSB_GTM_EVENT,
    eventCategory: DASHBOARD_READY_TRACKER.CATEGORY,
    ...params
});

export const DashboardGTMUpdaters = [
    {
        conditions: [
            APP_STATUS_READY
        ],
        reducer: ({ state }) => ({
            state,
            actionToDispatch: trackEventUserEnteredDashboard(
                {
                    eventAction: DASHBOARD_READY_TRACKER.ACTION,
                    eventLabel: DASHBOARD_READY_TRACKER.LABEL
                }
            )
        })
    }
];
