/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Select } from '../Components/Select';

import { useIntl } from '../../../../../../../view/intl/index';

import {
    AI_TEXT_CONTENT_TYPE_CHANGED
} from "../../../../epics/AITextEpic/actionTypes";

import {
    CONTENT_TYPES,
    CONTENT_TYPE_OPTIONS,
    TEXT_CLASS_CONTENT_TYPE_MAP,
    WHY_CHOOSE_US_SECTION_KEY
} from "../constants";

import styles from "../styles.css";
import { selectContentType, selectTopic } from '../../../../epics/AITextEpic/selectors';
import { makeEpicStateSelector } from '../../../../../../../epics/makeEpic';
import ComponentsEvalValueActionType from '../../../../../../Workspace/epics/componentsEval/valueActionType';
import { getSelectedComponent } from '../../../../../../Workspace/epics/componentsEval/selectorActionTypes';
import { removeEmptyTags } from '../../../../epics/EditAITextEpic/utils';
import { tinyMceDomParser } from '../../../../editorSetup';

const getContentType = (content) => {
    const updatedContent = removeEmptyTags(content);
    const defaultType = CONTENT_TYPES.paragraph1;
    if (!updatedContent) {
        return defaultType;
    }

    const processNode = (node) => {
        if (!node) {
            return defaultType;
        }
        const attributesMapClass = node.attributes?.map?.class;
        if (attributesMapClass) {
            const attributesMapClassList = attributesMapClass.split();
            // eslint-disable-next-line no-restricted-syntax
            for (const classEl of attributesMapClassList) {
                if (classEl in TEXT_CLASS_CONTENT_TYPE_MAP) {
                    return TEXT_CLASS_CONTENT_TYPE_MAP[classEl];
                }
            }
        }
        return processNode(node.firstChild);
    };

    const transformNode = tinyMceDomParser.parse(updatedContent);
    let childNode = transformNode.firstChild;
    if (childNode.next) {
        return defaultType;
    }
    return processNode(childNode);
};

export const ContentTypeField = () => {
    const intl = useIntl();
    const dispatch = useDispatch();

    const aiTextContentType = useSelector(selectContentType);
    const aiTexTopic = useSelector(selectTopic);
    const selectedComponent = getSelectedComponent(useSelector(makeEpicStateSelector(ComponentsEvalValueActionType)));

    const staticContentTypeOptions = CONTENT_TYPE_OPTIONS.map(el => {
        return {
            label: intl.msgJoint(el.label),
            value: el.value
        };
    });
    const [contentType, setContentType] = useState(aiTextContentType);
    const [contentTypeOptions, setContentTypeOptions] = useState(staticContentTypeOptions);

    useEffect(() => {
        const cType = getContentType(selectedComponent.content);
        setContentType(cType);
        dispatch({ type: AI_TEXT_CONTENT_TYPE_CHANGED, payload: cType });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (aiTexTopic !== WHY_CHOOSE_US_SECTION_KEY) {
            setContentTypeOptions(staticContentTypeOptions);
        } else {
            if (contentType === CONTENT_TYPES.paragraph2) {
                setContentType(CONTENT_TYPES.paragraph1);
                dispatch({ type: AI_TEXT_CONTENT_TYPE_CHANGED, payload: CONTENT_TYPES.paragraph1 });
            }
            setContentTypeOptions(staticContentTypeOptions.filter(el => el.value !== CONTENT_TYPES.paragraph2));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [aiTexTopic]);

    const handleChange = (e) => {
        const value = e.target.value;
        setContentType(value);
        dispatch({
            type: AI_TEXT_CONTENT_TYPE_CHANGED,
            payload: value
        });
    };

    const selectProps = {
        value: contentType,
        data: contentTypeOptions,
        handleChange,
    };

    return (
        <div className={styles.fieldWrapper}>
            <div className={styles.fieldLabel}>
                {intl.msgJoint("msg: component.text.createAIText.dialog.textBuilder.contentType.label {How long should the text be?}")}
            </div>
            <div className={styles.fieldContainer}>
                <Select {...selectProps} />
            </div>
        </div>
    );
};
