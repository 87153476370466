import { makeCombineReducer } from '../../../redux/makeReducer/index';
import siteDataEpic from '../../App/epics/siteData/index';
import currentPageNameEpic from '../../App/epics/currentPageName/index';
import browserDimensionsEpic from '../../App/epics/browserDimensions/index';
import expandedEpic from '../epics/expanded/index';
import componentsKindsToDisableMapEpic from '../epics/componentsKindsToDisableMap/index';
import isMouseOverLeftPanelEpic from '../../App/epics/isMouseOverLeftPanel/index';
import { dndAddComponentIsVisibleReducer } from "../../DndAddComponent/epic/index";
import extendedPanelEpic from '../epics/extendedPanel/index';
import { preferencesReducer } from '../../Preferences/reducer/preferencesReducer';

export default makeCombineReducer({
    combineReducers: {
        expanded: expandedEpic.reducer,
        site: siteDataEpic.reducer,
        currentPageName: currentPageNameEpic.reducer,
        browserDimensions: browserDimensionsEpic.reducer,
        componentsKindsToDisableMap: componentsKindsToDisableMapEpic.reducer,
        isMouseOverPanel: isMouseOverLeftPanelEpic.reducer,
        dndAddComponentIsVisible: dndAddComponentIsVisibleReducer,
        extendedPanel: extendedPanelEpic.reducer,
        preferences: preferencesReducer
    },
    handleActions: {}
});
