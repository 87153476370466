/* eslint-disable max-len */

import * as R from 'ramda';
import React from 'react';
import onecolor from "onecolor";
import { Style } from '@one.com/radium-one.com';
import { getAllStylesheets } from "../Workspace/epics/stylesheets/selectors";

import TableCellKind from '../oneweb/Table/globalStyle/kind';
import TableCellMapper from '../oneweb/Table/globalStyle/mapper';

import TextKind from '../oneweb/Text/globalStyle/kind';
import TextMapper from '../oneweb/Text/globalStyle/mapper';

import LinkKind from '../oneweb/Link/globalStyle/kind';
import LinkMapper from '../oneweb/Link/globalStyle/mapper';

import MenuKind from '../oneweb/Menu/globalStyle/kind';
import menuMapperFactory from '../oneweb/Menu/globalStyle/mapperFactory';

import ButtonKind from '../oneweb/Button/globalStyle/kind';
import ButtonMapper from '../oneweb/Button/globalStyle/mapper';

import type { Stylesheet } from '../Workspace/epics/stylesheets/flowTypes';
import type { Props, RenderConfig } from './flowTypes';
import {
    getThemeRulesForBackground,
    themeLinkRules,
    themeRulesBackgroundForLinkColor,
    themeLinkAccentBase,
    themeLinkTextlikeBase,
    themeLinkUnderlinedBase,
    getMenuThemeRulesFromBackgroundTheme,
    getTableThemeRulesFromBackgroundTheme,
} from "../ThemeGlobalData/themeRules";
import * as colorMapper from "../../mappers/color";
import {
    THEME_COLORS,
    ALL_BACKGROUND_THEMES,
    THEME_BUTTON_CLASS,
    ALL_BUTTON_THEMES,
    BUTTON_THEME_SECONDARY,
    THEME_TABLE_CLASS,
    THEME_TEXT_CLASS,
    THEME_BORDER_COLOR_CLASS,
} from "../ThemeGlobalData/constants";
import { THEME_LINK_CLASS_LIST, themeaccent } from '../App/epics/tinyMceEpic/editorUtils/constants';
import type { ThemeColorDataType, ThemeSiteSettingsDataType } from "../ThemeGlobalData/flowTypes";
import { getThemeStyleForButtonWithKnownTheme } from "../oneweb/Button/utils";
import ThemeStyleMenuType from '../oneweb/Menu/globalStyle/themeColorKind';
import { getCellGlobalstyleClassNameRef } from './getGlobalstyleClassName';
import { types as tableCellGlobalStyleType } from '../Globalstyles/Table/constants';
import { THEME_DEFAULT as SVG_THEME_OVERRIDE_CLASS } from '../oneweb/Social/constants';

const filterScopeSelector = (renderConfigs, selector: string|string[]) => (
    Array.isArray(selector)
        ? renderConfigs.filter(({ scopeSelector }) => selector.includes(scopeSelector))
        : renderConfigs.filter(({ scopeSelector }) => scopeSelector.includes(selector))
);

const
    notSelectorsCombined = `*:not(a):not(input):not(textarea):not(select):not(option):not(.error):not(.oneWebCntFormFileUploadInput):not(.contact-form-upload-files-text)`;

const editMenuProperties = (renderConfigs, propertiesMapping) => {
    let styles,
        backgroundImage;

    for (let menuStyles of renderConfigs) {
        /* eslint-disable-next-line no-loop-func */
        Object.keys(menuStyles.rules).forEach(key => {
            styles = menuStyles.rules[key];

            Object.keys(propertiesMapping).forEach(property => {
                if (styles[property]) {
                    styles[property] = propertiesMapping[property];
                }
            });

            backgroundImage = styles.backgroundImage;

            if (Array.isArray(backgroundImage)) {
                for (let i = 0; i < backgroundImage.length; i++) {
                    if (backgroundImage[i].includes('linear-gradient')) {
                        backgroundImage.splice(i, 1);
                    }
                }
            }
        });
    }
};

const menuMapper = {
    getScopeSelector: () => '',
    mapperFactory: menuMapperFactory
};

const
    // Find a convinient API for GS generation
    mapperByKind = {
        [TextKind]: { mapper: TextMapper },
        [LinkKind]: { mapper: LinkMapper },
        [MenuKind]: menuMapper,
        [ThemeStyleMenuType]: menuMapper,
        [ButtonKind]: { mapper: ButtonMapper }, // TODO: WBTGEN-17706
        [TableCellKind]: { mapper: TableCellMapper }
    },
    mapStylesheetToRenderConfig = (
        stylesheet: Stylesheet,
        stylesheetsIdToNameMap: MapT<string>,
        themeSettingsData: ThemeSiteSettingsDataType
    ): RenderConfig => {
        const
            configOrMapper = mapperByKind[stylesheet.type],
            config: any = configOrMapper || { mapper: R.always({}) },
            className = stylesheetsIdToNameMap[stylesheet.id],
            autoColorMode = themeSettingsData && themeSettingsData.autoColorMode;

        return ({
            stylesheet,
            stylesheetId: stylesheet.id,
            scopeSelector: config.getScopeSelector ? config.getScopeSelector({ className }) : `.${className}`,
            rules: (config.mapper ? config.mapper : config.mapperFactory({ className }))(stylesheet, autoColorMode)
        });
    },
    addThemeStylesForSvg = (themeColorsData: ThemeColorDataType, themeSettingsData: ThemeSiteSettingsDataType) => (renderConfigs) => {
        if (themeColorsData && themeSettingsData?.autoColorMode) {
            const renderConfig = renderConfigs[0];
            const blackColorFillStyle = { fill: colorMapper.toCss(themeColorsData.blackColor) };
            const whiteColorFillStyle = { fill: colorMapper.toCss(themeColorsData.whiteColor) };
            // TODO: WBTGEN-18495: clean these styles up once SVGs have all lowercase fill colors.
            renderConfig.rules[`.${SVG_THEME_OVERRIDE_CLASS} svg`] = blackColorFillStyle;
            renderConfig.rules[`.${SVG_THEME_OVERRIDE_CLASS} [fill="#000"]`] = blackColorFillStyle;
            renderConfig.rules[`.${SVG_THEME_OVERRIDE_CLASS} [fill="#000000"]`] = blackColorFillStyle;
            renderConfig.rules[`.${SVG_THEME_OVERRIDE_CLASS} [fill="#fff"]`] = whiteColorFillStyle;
            renderConfig.rules[`.${SVG_THEME_OVERRIDE_CLASS} [fill="#FFF"]`] = whiteColorFillStyle;
            renderConfig.rules[`.${SVG_THEME_OVERRIDE_CLASS} [fill="#ffffff"]`] = whiteColorFillStyle;
            renderConfig.rules[`.${SVG_THEME_OVERRIDE_CLASS} [fill="#FFFFFF"]`] = whiteColorFillStyle;

            ALL_BACKGROUND_THEMES.forEach(backgroundTheme => {
                const fillColor = colorMapper.toCss(themeColorsData[getThemeRulesForBackground(backgroundTheme, themeColorsData).text]);
                renderConfig.rules[`.contactFormContainer.${backgroundTheme} svg`] = { fill: fillColor, stroke: fillColor };
                THEME_COLORS.forEach((overrideColor) => {
                    const fColor = colorMapper.toCss(themeColorsData[overrideColor]);
                    renderConfig.rules[`.contactFormContainer.${backgroundTheme} .${overrideColor} svg`] = { fill: fColor, stroke: fColor };
                });
            });
        }
        return renderConfigs;
    },
    addThemeStylesForLogo = (themeColorsData: ThemeColorDataType, themeSettingsData: ThemeSiteSettingsDataType) => (renderConfigs) => {
        if (!themeColorsData || !themeSettingsData?.autoColorMode) {
            return renderConfigs;
        }

        const renderConfig = filterScopeSelector(renderConfigs, [".textlogo"])[0];
        if (renderConfig) {
            if (renderConfig.stylesheet.themeColor) {
                renderConfig.rules['.textlogo'] = {
                    ...renderConfig.rules['.textlogo'],
                    color: colorMapper.toCss(themeColorsData[renderConfig.stylesheet.themeColor])
                };
            } else {
                ALL_BACKGROUND_THEMES.forEach(backgroundTheme => {
                    const selector = `.${backgroundTheme}.textlogo`;
                    const color = colorMapper.toCss(
                        themeColorsData[themeRulesBackgroundForLinkColor(themeColorsData)[themeaccent][backgroundTheme]]
                    );
                    renderConfig.rules[selector] = { color };
                });
            }
        }
        return renderConfigs;
    },
    addThemeStylesForText = (themeColorsData: ThemeColorDataType, themeSettingsData: ThemeSiteSettingsDataType) => (renderConfigs) => {
        if (themeColorsData && themeSettingsData?.autoColorMode) {
            const
                textClasses = [".textnormal", ".textheading1", ".textheading2", ".textheading3"],
                renderConfigsForText: Array<any> = filterScopeSelector(renderConfigs, textClasses);
            // Need to add a new render config for theme. Instead of using text global styles.
            if (renderConfigsForText.length) {
                const
                    renderConfig = renderConfigsForText[0],
                    renderConfigRules = renderConfig.rules;
                ALL_BACKGROUND_THEMES.forEach((backgroundClass) => {
                    const
                        workspaceColorOverrideSelectorClass = `.themeTextClass.${backgroundClass} ${notSelectorsCombined}`;
                    renderConfigRules[
                        // To select the most inside matchers for background classes.
                        `div[data-kind='Component'] ${workspaceColorOverrideSelectorClass},` + // preview and publish
                        `${workspaceColorOverrideSelectorClass},` + // workspace: text and others
                        `.themeTextClass ${workspaceColorOverrideSelectorClass},` + // workspace: text and others
                        `.themeTextClass.${backgroundClass} .contact-form-field-container ${notSelectorsCombined},` + // workspace: contact form
                        `.themeTextClass.${backgroundClass}.contactFormContainer .frc-banner > a,` +
                        `.themeTextClass.${backgroundClass} .reviewHeader ${notSelectorsCombined},` +
                        `.${backgroundClass}.textnormal.textnormal-ddo,` + // TODO when mcta preview is taken: For text Mcta
                        `.${backgroundClass}.textheading1.textheading1-ddo,` + // For text Mcta
                        `.${backgroundClass}.textheading2.textheading2-ddo,` + // For text Mcta
                        `.${backgroundClass}.textheading3.textheading3-ddo`  // For text Mcta
                    ] = {
                        color: colorMapper.toCss(themeColorsData[getThemeRulesForBackground(backgroundClass, themeColorsData).text]) + ' !important'
                    };
                    THEME_COLORS.forEach((overrideColor) => {
                        renderConfigRules[
                            `div[data-kind='Component'] .themeTextClass.${backgroundClass}.${overrideColor} ${notSelectorsCombined},` + // Preview and Publish
                            `div[data-kind='Component'] .themeTextClass.${backgroundClass} .contact-form-field-container.${overrideColor} ${notSelectorsCombined},` + // contact form preview/publish
                            `div[data-kind='Component'] .themeTextClass.${backgroundClass} .reviewHeader.${overrideColor} ${notSelectorsCombined},` + // review form preview/publish
                            /* Below CSS matching is only added for Contact form success / error message to follow label colors. */
                            `div[data-kind='Component'] .themeTextClass.${backgroundClass} ${notSelectorsCombined} .${overrideColor}.contactFormResponseStatus,` + // contact form preview/publish
                            `.themeTextClass.${backgroundClass} .contact-form-field-container.${overrideColor} ${notSelectorsCombined},` + // contact form workspace
                            `.themeTextClass.${backgroundClass} .contact-form-field-container.${overrideColor} .frc-banner > a,` + // contact form workspace
                            `.themeTextClass.${backgroundClass} .reviewHeader.${overrideColor} ${notSelectorsCombined},` + // contact form workspace
                            `.themeTextClass.${backgroundClass}.${overrideColor} ${notSelectorsCombined}` // Workspace: This is intended for workspace. It is affecting affecting preview/publish also.
                        ] = {
                            color: colorMapper.toCss(themeColorsData[overrideColor]) + ' !important'
                        };
                    });
                    /**
                     * Above variables explained.
                     * themeTextClass: Custom class used. May not be needed. But keep for better identification.
                     * backgroundClass: Any of background Theme Name without color Suffix [Black, White, Secondary, Alternate, Main, Accented]
                     * overrideColor:  This should match the color name in theme Data ["mainColor", "secondaryColor", "alternateColor", "blackColor", "whiteColor", "accentColor"]
                     * One Class generated Example:
                     *      div[data-kind='Component'] .themeTextClass.Main.secondaryColor *:not(a),
                     *      .themeTextClass.Main.secondaryColor .textnormal *:not(a),
                     *      .Main .secondaryColor .textnormal *:not(a)
                     *      { color: rgba(249,227,231,1) !important; }
                     */
                });
            }
        }
        return renderConfigs;
    },
    addThemeStylesForMenu = (themeColorsData: ThemeColorDataType, themeSettingsData: ThemeSiteSettingsDataType) => (renderConfig) => {
        if (!themeColorsData || !themeSettingsData?.autoColorMode) {
            return renderConfig;
        }

        const renderConfigsForMenu: Array<any> = renderConfig.filter(({ scopeSelector }) => scopeSelector === "");

        if (renderConfigsForMenu.length) {
            const
                [menuStyles] = renderConfigsForMenu,
                mainMenuAnchorSelector = (backgroundClass) => {
                    return `.menu.${backgroundClass} ul > li > a`;
                },
                subMenuAnchorSelector = (backgroundClass) => {
                    return `.menu.${backgroundClass} ul > li > ul > li a:not(.level-0)`;
                },
                propertiesMapping = {
                    background: undefined,
                    backgroundColor: undefined,
                    borderColor: undefined,
                    borderStyle: "none", // We have to remove borderStyle along with borderColor
                    textShadow: null,
                };

            editMenuProperties(renderConfigsForMenu, propertiesMapping);

            // Main menu
            ALL_BACKGROUND_THEMES.forEach(backgroundClass => {
                const {
                    menu: {
                        normalTextColor,
                        normalBackgroundColor,
                        hoverTextColor,
                        hoverBackgroundColor,
                        selectedTextColor,
                        selectedBackgroundColor,
                    }
                } = getMenuThemeRulesFromBackgroundTheme(backgroundClass, themeColorsData);

                // Normal
                menuStyles.rules[mainMenuAnchorSelector(backgroundClass)] = {
                    backgroundColor: normalBackgroundColor + ' !important',
                    color: colorMapper.toCss(normalTextColor) + ' !important',
                };

                // Selected
                menuStyles.rules[mainMenuAnchorSelector(backgroundClass) + '.selected'] = {
                    backgroundColor: hoverBackgroundColor + ' !important',
                    color: colorMapper.toCss(hoverTextColor) + ' !important',
                };

                // Hover
                menuStyles.rules[mainMenuAnchorSelector(backgroundClass) + ':hover'] = {
                    backgroundColor: selectedBackgroundColor + ' !important',
                    color: colorMapper.toCss(selectedTextColor) + ' !important',
                };

                // expanded
                menuStyles.rules[mainMenuAnchorSelector(backgroundClass) + '.expanded'] = {
                    color: colorMapper.toCss(hoverTextColor) + ' !important'
                };

                // Rules for preview in globalstyles

                // Hover
                menuStyles.rules[`.menuPreview > .menu.${backgroundClass} ul > li:last-child > a`] = {
                    color: colorMapper.toCss(selectedTextColor) + ' !important',
                };

                // expanded
                menuStyles.rules[`.menuPreview > .menu.${backgroundClass} ul > li:nth-child(4) > a`] = {
                    color: colorMapper.toCss(hoverTextColor) + ' !important'
                };
            });

            // Sub menu
            ALL_BACKGROUND_THEMES.forEach((backgroundClass) => {
                const {
                    submenu: {
                        normalTextColor,
                        normalBackgroundColor,
                        hoverTextColor,
                        hoverBackgroundColor,
                        selectedTextColor,
                        selectedBackgroundColor,
                    }
                } = getMenuThemeRulesFromBackgroundTheme(backgroundClass, themeColorsData);

                // Normal
                menuStyles.rules[subMenuAnchorSelector(backgroundClass)] = {
                    backgroundColor: colorMapper.toCss(normalBackgroundColor) + ' !important',
                    color: colorMapper.toCss(normalTextColor) + ' !important',
                };

                // Selected
                menuStyles.rules[subMenuAnchorSelector(backgroundClass) + '.selected'] = {
                    backgroundColor: colorMapper.toCss(selectedBackgroundColor) + ' !important',
                    color: colorMapper.toCss(selectedTextColor) + ' !important',
                };

                // Hover
                menuStyles.rules[subMenuAnchorSelector(backgroundClass) + ':hover'] = {
                    backgroundColor: colorMapper.toCss(hoverBackgroundColor) + ' !important',
                    color: colorMapper.toCss(hoverTextColor) + ' !important',
                };

                // expanded
                menuStyles.rules[subMenuAnchorSelector(backgroundClass) + '.expanded'] = {
                    backgroundColor: colorMapper.toCss(hoverBackgroundColor) + ' !important',
                    color: colorMapper.toCss(hoverTextColor) + ' !important'
                };
            });
        }

        return renderConfig;
    },
    addThemeStylesForButton = (themeColorsData: ThemeColorDataType, themeSettingsData: ThemeSiteSettingsDataType) => (renderConfig) => {
        if (!themeColorsData || !themeSettingsData?.autoColorMode) {
            return renderConfig;
        }

        const renderConfigsForButtons: Array<any> = filterScopeSelector(renderConfig, '.button');

        if (renderConfigsForButtons.length) {
            const renderConfigsForButton = renderConfigsForButtons[0];

            ALL_BACKGROUND_THEMES.forEach(backgroundTheme => {
                ALL_BUTTON_THEMES.forEach((buttonTheme) => {
                    const selector = `.${backgroundTheme}.${THEME_BUTTON_CLASS}.${buttonTheme}`;
                    const themeStyle = getThemeStyleForButtonWithKnownTheme({ selectedParentTheme: backgroundTheme, buttonThemeSelected: buttonTheme, themeColorsData });
                    const hoverThemeStyle = {
                        ...themeStyle,
                        backgroundColor: (
                            buttonTheme === BUTTON_THEME_SECONDARY // $FlowFixMe: WBTGEN-16368
                                ? onecolor(themeStyle.color).alpha(0.1).cssa() // $FlowFixMe: WBTGEN-16368
                                : onecolor(themeStyle.backgroundColor).mix(themeColorsData[getThemeRulesForBackground(backgroundTheme, themeColorsData).background]).cssa()
                        ),
                    };
                    renderConfigsForButton.rules[selector] = themeStyle;
                    renderConfigsForButton.rules[`${selector}:hover`] = hoverThemeStyle;
                    renderConfigsForButton.rules[`${selector}:active`] = hoverThemeStyle;
                    renderConfigsForButton.rules[`${selector}.disabled`] = { ...themeStyle, opacity: 0.9 };

                    renderConfigsForButton.rules[`${selector}.ddo`] = buttonTheme === BUTTON_THEME_SECONDARY ? {
                        backgroundColor: colorMapper.toCss(themeColorsData[getThemeRulesForBackground(backgroundTheme, themeColorsData).background])
                    } : {};

                    renderConfigsForButton.rules[`${selector}.ddo:hover`] = buttonTheme === BUTTON_THEME_SECONDARY ? {
                        // TODO: WBTGEN-18528
                        // $FlowFixMe: WBTGEN-16368
                        backgroundColor: onecolor(themeColorsData[getThemeRulesForBackground(backgroundTheme, themeColorsData).background]).alpha(0.9).cssa()
                    } : {};
                });
            });
        }

        return renderConfig;
    },
    addThemeStylesForLink =
        (themeColorsData: ThemeColorDataType, themeSettingsData: ThemeSiteSettingsDataType) => (renderConfigs) => {
            if (themeColorsData && themeSettingsData) {
                const universalSelectorWithSpecificity = '*:not(#fake-id)',
                    { autoColorMode } = themeSettingsData,
                    renderConfigsForLink: Array<any> = filterScopeSelector(renderConfigs, ".link");
                if (renderConfigsForLink.length) {
                    const
                        renderConfig = renderConfigsForLink[renderConfigsForLink.length - 1],
                        // eslint-disable-next-line no-unused-expressions
                        renderConfigRules = {
                            ...renderConfig.rules,
                            ...themeLinkRules.themeaccent,
                            ...themeLinkRules.themeunderlined,
                            ...themeLinkRules.themetextlike
                        };
                    if (autoColorMode) {
                        renderConfigsForLink.forEach((link, index) => {
                            switch (index) {
                                case 0:
                                    renderConfigRules[link.scopeSelector] = themeLinkAccentBase;
                                    break;
                                case 1:
                                    renderConfigRules[link.scopeSelector] = themeLinkUnderlinedBase;
                                    break;
                                case 2:
                                    renderConfigRules[link.scopeSelector] = themeLinkTextlikeBase;
                                    break;
                                default:
                                    renderConfigRules[link.scopeSelector] = themeLinkAccentBase;
                            }
                        });
                        ALL_BACKGROUND_THEMES.forEach((backgroundClass) => {
                            THEME_LINK_CLASS_LIST.forEach((linkClass) => {
                                const color = colorMapper.toCss(
                                    themeColorsData[themeRulesBackgroundForLinkColor(themeColorsData)[`${linkClass}`][backgroundClass]]
                                );
                                // @ts-ignore
                                renderConfigRules[`
                                    div[data-kind='Component'] .themeTextClass.${backgroundClass} .${linkClass},
                                    div[data-kind='Component'] .themeTextClass.${backgroundClass} .${linkClass} ${universalSelectorWithSpecificity},
                                    div[data-kind='Component'] .${backgroundClass} .${linkClass},
                                    div[data-kind='Component'] .${backgroundClass} .${linkClass} ${universalSelectorWithSpecificity},
                                    .themeTextClass.${backgroundClass} .${linkClass},
                                    .themeTextClass.${backgroundClass} .${linkClass} ${universalSelectorWithSpecificity},
                                    .${backgroundClass} .${linkClass},
                                    .${backgroundClass} .${linkClass} ${universalSelectorWithSpecificity}
                                `] = { color: `${color} !important;` };
                                // @ts-ignore
                                renderConfigRules[`
                                    div[data-kind='Component'] .themeTextClass.${backgroundClass} .${linkClass}-hover,
                                    div[data-kind='Component'] .themeTextClass.${backgroundClass} .${linkClass}-hover ${universalSelectorWithSpecificity},
                                    div[data-kind='Component'] .${backgroundClass} .${linkClass}-hover,
                                    div[data-kind='Component'] .${backgroundClass} .${linkClass}-hover ${universalSelectorWithSpecificity},
                                    .themeTextClass.${backgroundClass} .${linkClass}-hover,
                                    .themeTextClass.${backgroundClass} .${linkClass}-hover ${universalSelectorWithSpecificity},
                                    .${backgroundClass} .${linkClass}-hover,
                                    .${backgroundClass} .${linkClass}-hover ${universalSelectorWithSpecificity}
                                `] = { // $FlowFixMe: WBTGEN-16368
                                    color: `${onecolor(color).mix(themeColorsData[getThemeRulesForBackground(backgroundClass, themeColorsData).background]).cssa()} !important;`
                                };
                                // @ts-ignore
                                renderConfigRules[`
                                    div[data-kind='Component'] .themeTextClass.${backgroundClass} .${linkClass}:hover,
                                    div[data-kind='Component'] .themeTextClass.${backgroundClass} .${linkClass}:hover ${universalSelectorWithSpecificity},
                                    div[data-kind='Component'] .${backgroundClass} .${linkClass}:hover,
                                    div[data-kind='Component'] .${backgroundClass} .${linkClass}:hover ${universalSelectorWithSpecificity},
                                    .themeTextClass.${backgroundClass} .${linkClass}:hover,
                                    .themeTextClass.${backgroundClass} .${linkClass}:hover ${universalSelectorWithSpecificity},
                                    .${backgroundClass} .${linkClass}:hover,
                                    .${backgroundClass} .${linkClass}:hover ${universalSelectorWithSpecificity}
                                `] = { // $FlowFixMe: WBTGEN-16368
                                    color: `${onecolor(color).mix(themeColorsData[getThemeRulesForBackground(backgroundClass, themeColorsData).background]).cssa()} !important;`
                                };
                                renderConfigRules[`.${backgroundClass} .none .${linkClass}`] = { color: 'unset' };
                                renderConfigRules[`.${backgroundClass} .none .${linkClass}-hover`] = { color: 'unset' };
                                renderConfigRules[`.${backgroundClass} .none .${linkClass}:hover`] = { color: 'unset' };
                                const addRulesForExistingNonAutoColorThemes = (scopeSelector) => {
                                    renderConfigRules[`
                                        div[data-kind='Component'] .themeTextClass.${backgroundClass} ${scopeSelector},
                                        div[data-kind='Component'] .themeTextClass.${backgroundClass} ${scopeSelector} ${universalSelectorWithSpecificity},
                                        div[data-kind='Component'] .${backgroundClass} ${scopeSelector},
                                        div[data-kind='Component'] .${backgroundClass} ${scopeSelector} ${universalSelectorWithSpecificity},
                                        .themeTextClass.${backgroundClass} ${scopeSelector},
                                        .themeTextClass.${backgroundClass} ${scopeSelector} ${universalSelectorWithSpecificity},
                                        .${backgroundClass} ${scopeSelector},
                                        .${backgroundClass} ${scopeSelector} ${universalSelectorWithSpecificity}
                                    `] = { color: `${color} !important;` };
                                    renderConfigRules[`
                                        div[data-kind='Component'] .themeTextClass.${backgroundClass} ${scopeSelector}-hover,
                                        div[data-kind='Component'] .themeTextClass.${backgroundClass} ${scopeSelector}-hover ${universalSelectorWithSpecificity},
                                        div[data-kind='Component'] .${backgroundClass} ${scopeSelector}-hover,
                                        div[data-kind='Component'] .${backgroundClass} ${scopeSelector}-hover ${universalSelectorWithSpecificity},
                                        .themeTextClass.${backgroundClass} ${scopeSelector}-hover,
                                        .themeTextClass.${backgroundClass} ${scopeSelector}-hover ${universalSelectorWithSpecificity},
                                        .${backgroundClass} ${scopeSelector}-hover,
                                        .${backgroundClass} ${scopeSelector}-hover ${universalSelectorWithSpecificity}
                                    `] = { // $FlowFixMe: WBTGEN-16368
                                        color: `${onecolor(color).mix(themeColorsData[getThemeRulesForBackground(backgroundClass, themeColorsData).background]).cssa()} !important;`
                                    };
                                    renderConfigRules[`
                                        div[data-kind='Component'] .themeTextClass.${backgroundClass} ${scopeSelector}:hover,
                                        div[data-kind='Component'] .themeTextClass.${backgroundClass} ${scopeSelector}:hover ${universalSelectorWithSpecificity},
                                        div[data-kind='Component'] .${backgroundClass} ${scopeSelector}:hover,
                                        div[data-kind='Component'] .${backgroundClass} ${scopeSelector}:hover ${universalSelectorWithSpecificity},
                                        .themeTextClass.${backgroundClass} ${scopeSelector}:hover,
                                        .themeTextClass.${backgroundClass} ${scopeSelector}:hover ${universalSelectorWithSpecificity},
                                        .${backgroundClass} ${scopeSelector}:hover,
                                        .${backgroundClass} ${scopeSelector}:hover ${universalSelectorWithSpecificity}
                                    `] = { // $FlowFixMe: WBTGEN-16368
                                        color: `${onecolor(color).mix(themeColorsData[getThemeRulesForBackground(backgroundClass, themeColorsData).background]).cssa()} !important;`
                                    };
                                    renderConfigRules[`.${backgroundClass} .none ${scopeSelector}`] = { color: 'unset' };
                                    renderConfigRules[`.${backgroundClass} .none ${scopeSelector}-hover`] = { color: 'unset' };
                                    renderConfigRules[`.${backgroundClass} .none ${scopeSelector}:hover`] = { color: 'unset' };
                                };
                                switch (linkClass) {
                                    case "themeaccent":
                                        if (renderConfigsForLink[0]) {
                                            renderConfigsForLink.forEach((link, index) => {
                                                const scopeSelectorLink1 = renderConfigsForLink[index].scopeSelector;
                                                if (index !== 1 && index !== 2) {
                                                    addRulesForExistingNonAutoColorThemes(scopeSelectorLink1);
                                                }
                                            });
                                        }
                                        break;
                                    case "themeunderlined":
                                        if (renderConfigsForLink[1]) {
                                            const scopeSelectorLink2 = renderConfigsForLink[1].scopeSelector;
                                            addRulesForExistingNonAutoColorThemes(scopeSelectorLink2);
                                        }
                                        break;
                                    case "themetextlike":
                                        if (renderConfigsForLink[2]) {
                                            const scopeSelectorLink3 = renderConfigsForLink[2].scopeSelector;
                                            addRulesForExistingNonAutoColorThemes(scopeSelectorLink3);
                                        }
                                        break;
                                    default:
                                        break;
                                }
                            });
                        });
                    }
                    renderConfig.rules = renderConfigRules;
                }
            }
            return renderConfigs;
        },
    addThemeStylesForTable = (themeColorsData: ThemeColorDataType, themeSettingsData: ThemeSiteSettingsDataType) => (renderConfigs) => {
        if (themeSettingsData?.autoColorMode && themeColorsData) {
            const cellClasses = Object.values(tableCellGlobalStyleType).map((ref: any) => `.${getCellGlobalstyleClassNameRef(ref)}`),
                renderConfigsForTable: Array<any> = filterScopeSelector(renderConfigs, cellClasses);

            if (renderConfigsForTable.length) {
                renderConfigsForTable.forEach(config => {
                    config.rules = Object.keys(config.rules).reduce((newRules, k) => { // eslint-disable-line no-param-reassign
                        const nonGradientBackgroundImage =
                            R.when(Array.isArray, R.filter(R.startsWith('url')))(config.rules[k].backgroundImage);
                        return {
                            ...newRules,
                            [k]: {
                                ...R.omit(['color', 'backgroundColor', 'borderColor', 'textShadow'], config.rules[k]),
                                backgroundImage: R.isEmpty(nonGradientBackgroundImage) ? 'none' : nonGradientBackgroundImage
                            },
                        };
                    }, {});
                });

                ALL_BACKGROUND_THEMES.forEach((backgroundTheme) => {
                    const tableThemeRules = getTableThemeRulesFromBackgroundTheme(backgroundTheme, themeColorsData);
                    Object.values(tableCellGlobalStyleType).forEach((ref: any) => {
                        const cellClass = `.${getCellGlobalstyleClassNameRef(ref)}`;

                        renderConfigsForTable[0].rules[`
                            .${THEME_TABLE_CLASS}.${backgroundTheme} ${cellClass} .${THEME_TEXT_CLASS}.${backgroundTheme},
                            .${THEME_TABLE_CLASS}.${backgroundTheme} ${cellClass} .${THEME_TEXT_CLASS}.${backgroundTheme} ${notSelectorsCombined}
                        `] = { color: `${colorMapper.toCss(themeColorsData[tableThemeRules[ref]])} !important` };

                        THEME_COLORS.forEach((themeColor) => {
                            renderConfigsForTable[0].rules[`
                                .${THEME_TABLE_CLASS}.${backgroundTheme} ${cellClass} .${THEME_TEXT_CLASS}.${backgroundTheme}.${themeColor},
                                .${THEME_TABLE_CLASS}.${backgroundTheme} ${cellClass} .${THEME_TEXT_CLASS}.${backgroundTheme}.${themeColor} ${notSelectorsCombined}
                            `] = { color: `${colorMapper.toCss(themeColorsData[themeColor])} !important` };
                        });
                    });

                    renderConfigsForTable[0].rules[
                        `.${backgroundTheme}.cellnormal.cellnormal-ddo,
                         .${backgroundTheme}.cellalternate.cellalternate-ddo,
                         .${backgroundTheme}.cellheading1.cellheading1-ddo,
                         .${backgroundTheme}.cellheading2.cellheading2-ddo`
                    ] = {
                        color: colorMapper.toCss(themeColorsData[getThemeRulesForBackground(backgroundTheme, themeColorsData).text]) + ' !important'
                    };
                });
            }
        }
        return renderConfigs;
    },
    addThemeStylesForBorderColor = (themeColorsData: ThemeColorDataType, themeSettingsData: ThemeSiteSettingsDataType) => (renderConfig) => {
        if (!themeColorsData || !themeSettingsData?.autoColorMode) {
            return renderConfig;
        }

        const renderConfigsForButtons: Array<any> = filterScopeSelector(renderConfig, '.button');
        if (!renderConfigsForButtons.length) {
            return renderConfig;
        }

        const renderConfigsForButton = renderConfigsForButtons[0];
        ALL_BACKGROUND_THEMES.forEach(backgroundTheme => {
            const selector = `.${backgroundTheme}.${THEME_BORDER_COLOR_CLASS}`;
            const color = colorMapper.toCss(themeColorsData[getThemeRulesForBackground(backgroundTheme, themeColorsData).text]);
            renderConfigsForButton.rules[selector] = { borderColor: color };
        });
        return renderConfig;
    },
    filterNullProps = R.map(renderConfig => ({
        ...renderConfig,
        rules: R.mapObjIndexed((num, key, obj) => {
            let tmp = obj[key];
            Object.keys(tmp).forEach(key1 => {
                tmp = R.isNil(tmp[key1]) ? R.dissoc(key1, tmp) : tmp;
            });
            return tmp;
        }, renderConfig.rules)
    }));

export default class extends React.PureComponent<Props> {
    render() {
        const
            { props } = this,
            themeSettingsData = R.prop('themeSettingsData', props),
            themeColorsData = R.prop('themeColorsData', props),
            renderConfigs = R.pipe(
                R.prop('stylesheets'),
                getAllStylesheets,
                R.map(stylesheets => {
                    const stylesheetsIdToNameMap = R.prop('stylesheetsIdToNameMap', props);
                    return mapStylesheetToRenderConfig(stylesheets, stylesheetsIdToNameMap, themeSettingsData);
                }),
                addThemeStylesForSvg(themeColorsData, themeSettingsData),
                addThemeStylesForLogo(themeColorsData, themeSettingsData),
                addThemeStylesForText(themeColorsData, themeSettingsData),
                addThemeStylesForLink(themeColorsData, themeSettingsData),
                addThemeStylesForMenu(themeColorsData, themeSettingsData),
                addThemeStylesForButton(themeColorsData, themeSettingsData),
                addThemeStylesForTable(themeColorsData, themeSettingsData),
                addThemeStylesForBorderColor(themeColorsData, themeSettingsData),
                filterNullProps
            )(props),
            allRules = renderConfigs.reduce((acc, { rules }) => ({ ...acc, ...rules }), {});

        return (<Style rules={allRules} />);
    }
}
