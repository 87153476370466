import { map } from "ramda";
import { GROUPING_OPTIONS, INTL_DAYS_OF_THE_WEEK } from "./constants";
import { getStartDayOfTheWeek } from "./previewUtils";
import { formatMessage } from "../../../view/intl/CurrentLocale";
import { parseMsgJoint } from "../../../../../server/shared/utils/parseMsgJoint.js";

export const defaultState = {
    closedLabel: formatMessage({
        id: "component.openingHours.closed",
        defaultMessage: "Closed"
    }),
    dayLabels: map(msgJointInput => {
        const [id, defaultMessage] = parseMsgJoint(msgJointInput);
        return formatMessage({
            id,
            defaultMessage
        });
    }, INTL_DAYS_OF_THE_WEEK),
    groupDays: GROUPING_OPTIONS.GROUP,
    hourFormat: formatMessage({
        id: "component.openingHours.hourFormat",
        defaultMessage: "HH"
    }),
    horizontalAlignment: "center",
    mobileHorizontalAlignment: null,
    mobileDown: false,
    open24HoursLabel: formatMessage({
        id: "component.openingHours.open24Hours",
        defaultMessage: "Open 24 hours"
    }),
    startDayOfTheWeek: getStartDayOfTheWeek(),
    textStyle: {
        fontSize: null,
        mobileFontSize: null,
        color: null,
        themeColor: null,
        fontFamily: null,
        daysBold: true,
        daysItalic: null,
        daysUnderline: null,
        daysCase: null,
        hoursBold: false,
        hoursItalic: null,
        hoursUnderline: null,
        hoursCase: null,
        letterSpacing: null,
        lineHeight: null
    },
    timeComponentSeparator: formatMessage({
        id: "component.openingHours.timeComponentSeparator",
        defaultMessage: ":"
    })
};
