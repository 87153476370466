import React from "react";
import { connect } from "react-redux";
import cx from "classnames";
import { getDAL } from "../../../../dal";
import { Msg, Intl, injectIntl } from "../../../view/intl";
import logout from "../actionCreators/logout";
import type { AppState } from "../../../redux/modules/flowTypes";
import type { TopBarState } from "../flowTypes";
import styles from "./OnboardingTopBar.css";
import { isDemoSubscriptionType } from '../../App/epics/subscriptionData/isDemoSubscriptionType';
import { demoSignOutAction } from "../../../../demo/actions";
import HorizontalSpacer from '../../../view/common/HorizontalSpacer';
import TopBarItemMenu from "./TopBarItemMenu";
import topBarItemMenuStyles from "./TopBarItemMenu/TopBarItemMenu.css";
import { TopBarLanguages } from './TopBarUserProfile/TopBarLanguages';
import languagesVAT from '../epics/languages/valueActionType';
import topBarUserProfileStyles from './TopBarUserProfile/TopBarUserProfile.css';
import { TOP_BAR_HELP_PRESSED } from '../actionTypes';
import { localeCodeToShortTitleLocale } from '../../../utils/localeUtils';
import { OnecomLogo } from "../../../view/Logo";
import { configHideGoToCPLink, getControlPanelLink } from "./TopBarUserProfile/utils";

type OnboardingTopbarViewProps = {
    intl: Intl;
    state: TopBarState;
    subscriptionType: string;
    dispatch: Dispatch;
    showLocale?: boolean;
    enableLogoLink?: boolean;
    showHelp?: boolean;
};

const languageIcon = <span className={cx(styles.languageIcon)} />;
const arrowIcon = <span className={cx(styles.arrowDown)} />;

const OnboardingTopBarView = injectIntl(({
    intl,
    state,
    subscriptionType,
    dispatch,
    showLocale = true,
    enableLogoLink = false,
    showHelp = true
}: OnboardingTopbarViewProps) => {
    const
        { languages } = state,
        isDemo = isDemoSubscriptionType(subscriptionType),
        onLogout = () => dispatch(isDemo ? demoSignOutAction() : logout({ fromTemplateSelector: true }));

    const mappedUiLocale = localeCodeToShortTitleLocale(languages.uiLocale);
    return (
        <div className={styles.topBarContainer}>
            <div className={styles.topBar}>
                {configHideGoToCPLink() ? (
                    <span className={cx(!enableLogoLink && styles.disableLink)}>
                        <OnecomLogo large className={styles.logoStyle} />
                    </span>
                ) : (
                    <a
                        className={cx(!enableLogoLink && styles.disableLink)}
                        href={getControlPanelLink(getDAL().getDomain())}
                        aria-label="Logo"
                    >
                        <OnecomLogo large className={styles.logoStyle} />
                    </a>
                )}
                <div className={styles.right}>
                    <div className={styles.rightContent}>
                        {showLocale && <React.Fragment>
                            <div className={cx(topBarItemMenuStyles.containerWrapper, topBarUserProfileStyles.languageItem)}>
                                <TopBarItemMenu
                                    selected={false}
                                    className={styles.onboardingTopBarMenuItem}
                                    LeftSideIcon={languageIcon}
                                    RightSideIcon={arrowIcon}
                                    // TODO: Ideally, this "style" shouldn't be required (to override the padding specified in <TopBarItemMenu>)
                                    style={{ padding: 0 }}
                                >
                                    {mappedUiLocale}
                                </TopBarItemMenu>
                                <TopBarLanguages
                                    state={languages}
                                    dispatch={dispatch}
                                    className={styles.languagesContainer}
                                    fromTemplateSelector
                                />
                            </div>
                            <HorizontalSpacer x={35} />
                        </React.Fragment>}
                        {
                            showHelp &&
                            <React.Fragment>
                                <a
                                    className={styles.onboardingTopBarItem}
                                    href={intl.msgJoint('msg: common.faq.url {https://help.one.com/hc/en-us/categories/115000796585-Website-Builder}')}
                                    target="_blank"
                                    onClick={() => dispatch({ type: TOP_BAR_HELP_PRESSED })}
                                >
                                    <Msg k="getHelp">Get help</Msg>
                                </a>
                                <HorizontalSpacer x={35} />
                            </React.Fragment>
                        }
                        <Msg
                            onClick={onLogout}
                            k="common.logout"
                            className={styles.onboardingTopBarItem}
                        >
                            Log out
                        </Msg>
                    </div>
                </div>
            </div>
        </div>
    );
});

const
    mapStateToProps = (appState: AppState) => {
        const { epics } = appState;

        return {
            languages: epics[languagesVAT].state,
            state: appState.topBar,
            subscriptionType: appState.subscriptionData.subscriptionType
        };
    },
    OnboardingTopBar = connect(mapStateToProps)(OnboardingTopBarView);

export {
    OnboardingTopBar
};
