import { makeDiReducer } from '@sepo27/redux-di';
import type { WhatsNewState, WhatsNewCombinedItem } from '../types';
import { Lit } from '../../../lit';
import type { PreferencesState } from '../../Preferences/types';
import { isWhatsNewItem } from '../functions/isWhatsNewItem';
import { WhatsNewListWithExtensionCombined } from '../whatsNewExtension';
import { HIDE_WHATS_NEW_TIP_ACTION, SHOW_WHATS_NEW_TIP_ACTION } from '../actions';
import { START_QUICK_TOUR } from '../../QuickTour/actionTypes';
import { parseIntDec } from "../../../../utils/number";
import { isWsbFeatureEnabled } from "../../ComponentTierManager/utils";
import { AUTH_COOKIE_EXISTS } from '../../App/epics/auth/actionTypes';
import { TUTORIAL_VIDEO_CLICKED } from '../../TopBar/actionTypes';

// @ts-ignore
export const WhatsNewInitialState: WhatsNewState = {
    [Lit.list]: [],
    [Lit.latestTimestamp]: null,
    [Lit.count]: 0,
    [Lit.showTip]: false,
};

type Dependencies = {
    preferences: PreferencesState,
};

export const getWhatsNewList = () => {
    return [...WhatsNewListWithExtensionCombined]
        .filter(item => !item.wsbFeature || isWsbFeatureEnabled(item.wsbFeature))
        .sort((a: WhatsNewCombinedItem, b: WhatsNewCombinedItem) => {
            const
                aTime = new Date(a.releaseDate).getTime(),
                bTime = new Date(b.releaseDate).getTime();

            if (aTime === bTime) {
                const
                    aId = parseIntDec(a.id),
                    bId = parseIntDec(b.id);

                return aId > bId ? -1 : 1;
            }

            return aTime > bTime ? -1 : 1;
        });
};

export const whatsNewReducer = makeDiReducer(
    WhatsNewInitialState,
    { preferences: '@preferences' },
    (state: WhatsNewState, action: AnyAction, deps: Dependencies) => {
        if (action.type === AUTH_COOKIE_EXISTS) {   //We changed since isWsbFeatureEnabled is dependent on DAL
            return {
                ...state,
                [Lit.list]: getWhatsNewList(),
            };
        }

        if (action.type === SHOW_WHATS_NEW_TIP_ACTION) {
            return {
                ...state,
                [Lit.showTip]: true,
            };
        }

        if (action.type === HIDE_WHATS_NEW_TIP_ACTION || action.type === START_QUICK_TOUR || action.type === TUTORIAL_VIDEO_CLICKED) {
            return {
                ...state,
                [Lit.showTip]: false,
            };
        }

        if (
            deps.preferences
            && deps.preferences.whatsNewLastSeenTimestamp
            && deps.preferences.whatsNewLastSeenTimestamp !== state[Lit.latestTimestamp]
        ) {
            const
                { preferences: { [Lit.whatsNewLastSeenTimestamp]: latestTimestamp } } = deps,
                { list } = state,
                nextCount = list.reduce(
                    (acc, item: WhatsNewCombinedItem) => (
                        isWhatsNewItem(item.releaseDate, latestTimestamp)
                            ? acc + 1
                            : acc
                    ),
                    0,
                );

            return { ...state, latestTimestamp, count: nextCount };
        }

        return state;
    },
);
