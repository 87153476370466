export const postStatus = {
    DRAFT: "DRAFT",
    UNPUBLISHED: "UNPUBLISHED",
    PUBLISHED: "PUBLISHED"
};

export const DEFAULT_THEME_COLORS = {
    mainColor: "#555088",
    accentColor: "#ffd754",
    whiteColor: "#ffffff",
    blackColor: "#000000",
};

// if the blog api takes longer than this amount in ms we will continue with publish
export const PUBLISHED_POSTS_CHECK_TIMEOUT = 1000;
