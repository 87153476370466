import React from "react";
import { StepStart } from "./StepViews/stepStart";
import { StepAI } from "./StepViews/stepAI";
import { StepBlogView } from "./StepViews/stepBlogView";
import {
    BlogErrorButtonType,
    BlogErrorTextsType,
    BlogErrorType,
    BlogLayout,
    ErrorBackButton,
    OnboardingOption,
    OnboardingStates
} from "./types";
import styles from "./OnboardingWizard.css";

/**
 * Defines the width of the onboarding dialog as a percentage of the screen width
 */
export const BLOG_ONBOARDING_DIALOG_WIDTH = 0.9;
/**
 * Defines the height of the onboarding dialog as a percentage of the screen height
 */
export const BLOG_ONBOARDING_DIALOG_HEIGHT = 0.9;

/**
 * Max amount of characters in a single line input field in the AI step
 */
export const MAX_INPUT_LENGTH = 100;
/**
 * Max amount of characters in a multiline textarea field in the AI step
 */
export const MAX_TEXTAREA_LENGTH = 250;

const getImageClassFromLayout = ({ selectedLayout }) => {
    return selectedLayout === BlogLayout.list ? styles.imageLayoutList : styles.imageLayoutGrid;
};

/**
 * Map describing which onboarding options include which steps \
 * and the required height for the corresponding step
 */
export const OnboardingSteps: OnboardingStates = {
    start: [
        {
            view: props => <StepStart {...props} />,
            getImageClass: ({ selectedOption }) => {
                return selectedOption === OnboardingOption.AI
                    ? styles.imageOptionAI
                    : styles.imageOptionBlank;
            }
        }
    ],
    [OnboardingOption.AI]: [
        {
            view: props => <StepAI {...props} />,
            getImageClass: () => {
                return styles.imageAI;
            }
        },
        {
            view: props => <StepBlogView {...props} />,
            getImageClass: getImageClassFromLayout
        }
    ],
    [OnboardingOption.blank]: [
        {
            view: props => <StepBlogView {...props} />,
            getImageClass: getImageClassFromLayout
        }
    ]
};

// This message will be added below the description after a retry if desired
const retryMsg =
    "msg: component.blog.onboarding.error.retryMsg {Try again or close to write your own posts without AI.}";

/**
 * Messages and button configuration for the different error scenarios
 */
export const BlogErrorTexts: BlogErrorTextsType = {
    [BlogErrorType.openAIConnection]: {
        icon: "error_general.png",
        title: "msg: component.blog.onboarding.error.openAIConnection.title {Connection error}",
        description:
            "msg: component.blog.onboarding.error.openAIConnection.description {Couldn’t connect to OpenAI. Please try again.}",
        descriptionAfterRetry:
            "msg: component.blog.onboarding.error.openAIConnection.descriptionAfterRetry {Unable to connect to OpenAI at the moment.}",
        retrySuffix: retryMsg,
        primaryButton: BlogErrorButtonType.tryAgain,
        secondaryButton: BlogErrorButtonType.back,
        secondaryButtonAfterRetry: BlogErrorButtonType.close,
        backButtonType: ErrorBackButton.returnToPreviousStep
    },
    [BlogErrorType.openAIHighTraffic]: {
        icon: "error_high_traffic.png",
        description: `msg: component.blog.onboarding.error.openAIHighTraffic.description 
            {We couldn’t process your request due to high traffic at OpenAI. Please try again.}`,
        descriptionAfterRetry: `msg: component.blog.onboarding.error.openAIHighTraffic.descriptionAfterRetry 
            {Due to high traffic at OpenAI, we can’t process your request.}`,
        retrySuffix: retryMsg,
        primaryButton: BlogErrorButtonType.tryAgain,
        secondaryButton: BlogErrorButtonType.back,
        secondaryButtonAfterRetry: BlogErrorButtonType.close,
        backButtonType: ErrorBackButton.returnToPreviousStep
    },
    [BlogErrorType.openAILanguage]: {
        icon: "error_language.png",
        title: "msg: component.blog.onboarding.error.openAILanguage.title {Unable to create text}",
        description: `msg: component.blog.onboarding.error.openAILanguage.description 
            {Your request doesn’t follow OpenAI’s content guidelines. Check if it might contain any 
            inappropriate or harmful language and rewrite it to try again. You can also close and write your posts without AI.}`,
        primaryButton: BlogErrorButtonType.back,
        secondaryButton: BlogErrorButtonType.close,
        backButtonType: ErrorBackButton.returnToInputStep
    },
    [BlogErrorType.openAILimitReached]: {
        icon: "error_limit.png",
        description: `msg: component.blog.onboarding.error.openAILimitReached.description 
            {You’ve reached your maximum usage limit for today. Please try again tomorrow or start writing your own posts.}`,
        primaryButton: BlogErrorButtonType.back,
        secondaryButton: BlogErrorButtonType.close,
        backButtonType: ErrorBackButton.startWithBlankBlog
    },
    [BlogErrorType.internal]: {
        icon: "error_general.png",
        title: "msg: component.blog.onboarding.error.internal.title {Couldn’t create blog}",
        description: `msg: component.blog.onboarding.error.internal.description 
            {There was an error while creating your blog. Please try again and contact our support if the issue persists. }`,
        primaryButton: BlogErrorButtonType.tryAgain,
        secondaryButton: BlogErrorButtonType.close
    }
};
