import { regexpsValidator } from '../../utils';
import { type LinkValidator } from '../../types';

export const isFacebookLinkValid: LinkValidator = regexpsValidator([
    /^(?:https:\/\/)?www\.facebook\.com\/watch\/\?v=\d+/,
    /^(?:https:\/\/)?fb.watch\/\w+/,
    /^(?:https:\/\/)?www\.facebook\.com\/\w+\/videos\/\d+/,
    /^(?:https:\/\/)?www\.facebook\.com\/\w+\/photos\//,
    /^(?:https:\/\/)?www\.facebook\.com\/\w+\/posts\/\w+/,
]);
