import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import View from "./index";
import BaseComponent from "../../../../view/oneweb/BaseComponent";
import ComponentMask from "../../../presentational/ComponentMask/index";
import { ComponentMaskBackground } from "../../../presentational/ComponentMask/constants";
import { isBookingsDialogOpenSelector } from "../selectors";
import { BookingsTryForFreeNote } from "./note";
import { getBookingsScriptUrl } from '../utils';

export default (props: any) => {
    const isBookingsDialogOpen = useSelector(isBookingsDialogOpenSelector);
    const { locale, showTryForFree, noteClosed } = props;
    const scriptUrl = getBookingsScriptUrl(false, locale);
    // try for free note should only be shown when the user is below premium tier
    // and it hasn't been dismissed yet
    const showNote = showTryForFree && !noteClosed;
    useEffect(() => {
        let scriptEle;
        const fetchScript = async () => {
            try {
                const response = await fetch(scriptUrl),
                    scriptText = await response.text();
                scriptEle = document.createElement('script');
                scriptEle.textContent = scriptText;
                document.body.appendChild(scriptEle);
            } catch (error) {
                console.error('Failed to fetch script:', error);
            }
        };
        fetchScript();
        //cleanup scripts from body
        return () => {
            if (!scriptEle) { return; }
            document.body.removeChild(scriptEle);
        };
    }, []);

    useEffect(() => {
        if (isBookingsDialogOpen || !window.refreshBookings) { return; }
        window.refreshBookings();
    }, [isBookingsDialogOpen]);

    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <BaseComponent {...props}>
            <ComponentMask
                maskStyle={ComponentMaskBackground.LIGHT}
                siblingContent={showNote && <BookingsTryForFreeNote />}
                {...props}
            >
                {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                <View {...props} isBookingsDialogOpen={isBookingsDialogOpen} />
            </ComponentMask>
        </BaseComponent>
    );
};
