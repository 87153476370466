// TODO: use tree-shakeable imports (import isLength from 'validator/es/lib/isLength';)
import isLength from 'validator/lib/isLength'; // eslint-disable-line n/file-extension-in-import
import type { InputValidationFunction } from "../flowTypes";

export type LengthInputValidationOptions = {
    min: number;
    max?: number;
} | {
    max: number;
    min?: number;
};

export type LengthInputValidation = InputValidationFunction<LengthInputValidationOptions>

const defaultOptions = {
    min: 1,
    max: 100
};

const Reason = {
    EXACT_LENGTH: 'EXACT_LENGTH',
    RANGE: 'RANGE',
    MIN_ONLY: 'MIN_ONLY',
    MAX_ONLY: 'MAX_ONLY'
};

const Message: MapT<string> = {
    [Reason.EXACT_LENGTH]: 'msg: validation.length.exact {The {_name} must be exactly {length} characters.}',
    [Reason.RANGE]: 'msg: validation.length.range {The {_name} must be between {min} and {max} characters.}',
    [Reason.MIN_ONLY]: 'msg: validation.length.min {The {_name} must be more than {min} characters.}',
    [Reason.MAX_ONLY]: 'msg: validation.length.max {The {_name} length must be less than {max} characters.}'
};

const makeError = (reason, params) => ({ reason, msg: [Message[reason], params] });

const lengthInputValidation: LengthInputValidation = (value, options = defaultOptions) => {
    const { min, max } = options;

    let
        isValid,
        error;
    if (min !== undefined && max !== undefined) {
        if (min > max) throw new Error('Min has to be less than max for lengthInputValidator');

        isValid = isLength(value, { min, max });
        error = (min === max)
            ? makeError(Reason.EXACT_LENGTH, { length: min })
            : makeError(Reason.RANGE, { min, max });
    } else if (min !== undefined) {
        isValid = isLength(value, { min });
        error = makeError(Reason.MIN_ONLY, { min });
    } else if (max !== undefined) {
        isValid = isLength(value, { max });
        error = makeError(Reason.MAX_ONLY, { max });
    } else {
        throw new Error('At least one of min|max should be provided for lengthInputValidation');
    }

    if (isValid === true) {
        return { isValid };
    } else {
        return { isValid: false, error };
    }
};

export {
    lengthInputValidation as default,
    Reason as LengthInputValidationReason,
    lengthInputValidation
};
