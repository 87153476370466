import React from 'react';
import * as pageIds from '../ids';
import pagesTitles from '../titles';

import Page from '../../../../PropertiesPanel/view/PropertiesPage';

import VerticalSpacer from '../../../../../view/common/VerticalSpacer';

import type { ButtonPageComponentDependencies } from '../../flowTypes';
import PropertyGlobalstyleSelector from '../../../../../view/common/PropertyGlobalstyleSelector';
import { injectIntl } from '../../../../../view/intl';
import { buttonGlobalStyleKind } from '../../../../ThemeGlobalData/utils/getColorsBundle';
import { getAllStylesheets, getFirstButtonStyleId } from '../../../../Workspace/epics/stylesheets/selectors';
import { MCTA_THEME_OPTIONS_BUTTON } from '../../../../componentMainActions/constants';
import { themeColoroptionRenderer } from '../../../Button/globalStyle/themeColoroptionRenderer';
import * as actionTypes from "../../actionTypes";
import buttonOptionsMapper from '../../../Button/globalStyle/options';
import { optionRenderer } from '../../../Button/globalStyle/optionRenderer';
import PropertyContainer from '../../../../../view/common/PropertyContainer';
import InputField from '../../../../../view/common/Input/PropertiesInputField';
import { lengthInputValidation } from '../../../../inputControls/input/commonValidations';
import { BUTTON_TEXT_MAX_LENGTH } from '../../constants';

const
    id = pageIds.BUTTON,
    title = pagesTitles[id],
    view = injectIntl((props: ButtonPageComponentDependencies) => {
        const {
            selectedComponent,
            selectedComponentProps,
            dispatch,
            intl,
            stylesheets,
            stylesheetsIdToNameMap,
            themeSettingsData: { autoColorMode }
        } = props;

        const
            styles = getAllStylesheets(stylesheets),
            selectedParentTheme = selectedComponentProps.selectedParentTheme;

        let
            optRenderer,
            options,
            colorThemeOnChangeAction,
            selected,
            onChangeAction,
            currentStylesheetId;

        if (autoColorMode) {
            currentStylesheetId = getFirstButtonStyleId(stylesheets);
            options = MCTA_THEME_OPTIONS_BUTTON;

            const btnName = stylesheetsIdToNameMap[currentStylesheetId];
            optRenderer = (value, label) => themeColoroptionRenderer(value, label, selectedParentTheme, btnName);

            onChangeAction = actionTypes.BOOKINGS_BUTTON_THEME_CHANGED;
            colorThemeOnChangeAction = (value) => {
                dispatch({ type: onChangeAction, payload: value });
            };
            selected = selectedComponent.buttonThemeSelected;
        } else {
            options = buttonOptionsMapper(styles, intl);
            onChangeAction = actionTypes.BOOKINGS_BUTTON_STYLE_CHANGED;
            optRenderer = (value, label) => optionRenderer(value, label, stylesheetsIdToNameMap);
            selected = selectedComponent.buttonStyleId;
        }

        return (
            <Page>
                <VerticalSpacer />
                <PropertyGlobalstyleSelector
                    label="msg: common.buttonStyle {Button style}"
                    globalStyleId={selected || `${options[0].value}`}
                    onChangeAction={onChangeAction}
                    options={options}
                    optionRenderer={optRenderer}
                    kind={buttonGlobalStyleKind}
                    dispatch={dispatch}
                    showEditLink
                    colorThemeOnChangeAction={colorThemeOnChangeAction}
                    autoColorMode={autoColorMode}
                    currentStylesheetId={currentStylesheetId}
                />
                <VerticalSpacer />
                <PropertyContainer label="msg: component.button.buttonText {Button text}">
                    <InputField
                        value={selectedComponent.buttonText || ''}
                        onChange={payload => dispatch({ type: actionTypes.BOOKINGS_BUTTON_TEXT_CHANGED, payload, amendToSelf: true })}
                        usePropsValue
                        stopOnKeyDownEventPropagation
                        inputValidations={[{
                            validation: lengthInputValidation, options: { max: BUTTON_TEXT_MAX_LENGTH },
                        }]}
                        rejectInvalidValue
                        trimValueOnPaste
                        placeholder="msg: component.button.buttonText {Button text}"
                        counter={{ maxLength: BUTTON_TEXT_MAX_LENGTH }}
                    />
                </PropertyContainer>
                <VerticalSpacer y={20} />
            </Page>
        );
    });

export { id, title, view };
