/**
 * TODO:
 * - remove reference to dal when dal is refactored
 * - crop feature ?
 */

import { getDAL } from "../../index";
import { trim } from "../../../utils/string.js";
import { encodeWebspaceUri } from "../webspaceUriTransformers";
import type { ResizeCropT } from "./flowTypes";

export type ResizeWebspaceImgSrcParams = {
    path: string,
    width: number,
    height: number,
    etag?: string,
    sourceContentType?: string,
    crop?: ResizeCropT,
    viewerDomain?: string,
    ignoreAspectRatio?: boolean,
};

export const resizeWebspaceImgSrc = (params: ResizeWebspaceImgSrcParams): string => {
    const
        { path, width, height, etag, sourceContentType, crop, viewerDomain, ignoreAspectRatio } = params,
        query: Record<string, any> = {
            withoutEnlargement: null // do not upscale
        };

    query.resize = `${width},${height}`;
    if (etag) query.etag = encodeWebspaceUri(`"${trim(etag, '"')}"`);
    if (sourceContentType) query.sourceContentType = encodeWebspaceUri(sourceContentType);
    if (crop) query.crop = crop;
    if (ignoreAspectRatio) query.ignoreAspectRatio = null;

    return getDAL().makeWebSpaceUrl({ path, query, domain: viewerDomain });
};
