import * as R from 'ramda';
import ReactDOMServer from "react-dom/server";
import React from "react";
import componentsRegistry from '../../../../view/oneweb/registry/index';
import type {
    EpicState,
    AdjustmentHookConfig,
    ComponentEvalAdjustAfterUpdate,
    AdjustAfterPushdownHookConfig
} from './flowTypes';
import type {
    AnyComponent,
    ComponentsIds,
    ComponentsMap
} from '../../../../redux/modules/children/workspace/flowTypes';
import * as updateReasons from './updateReasons';
import { PUSHDOWN_COMPLETE } from "./actionTypes";
import * as undoManagerUpdateReasons from "../../../../epics/undoManager/updateReasons";
import { setComponentsMap, setPushDownHappened } from "./setters";
import flattening from "../../../Preview/flattening/Flattening";
import { generateStylesheetsIdToNameMap } from "../stylesheets/idToNameMap";
import { getDAL } from "../../../../../dal/index";
import syncRelation from "../relations/syncRelationsWithComponentsMap";
import getPageGoogleFonts from "../../../Fonts/getPageGoogleFonts";
import CustomGenerateHtml from "../../../Preview/View/CustomGenerateHtml";
import { calcRelIn } from "../relations/calcRelIn";
import isTestEnv from "../../../../debug/isTestEnv";
import expandTemplateWidthAndAdjustComponents from "../../../Preview/expandTemplateWidthAndAdjustComponents";
import ImageKind from "../../../oneweb/Image/kind";
import memo from "../../../../../utils/memo";
import { processSectionBlockComponents } from "./addComponentUtils";
import { BACKGROUND_THEME_WHITE } from "../../../ThemeGlobalData/constants";
import {
    isSectionComponent,
    removeEmptySpacesBetweenSections,
    setSectionHeight
} from "../../../oneweb/Section/utils";
import { createAttachments, getChildToParentMap } from "../componentAttachements/util";
import { getAttachmentsForDynamicHeightCmp, getChangedHeightsCmpInfoMap, } from "../../../Preview/flattening/util";
import type { DynamicHeightsComponentsInfoMap } from "../../../Preview/flattening/util";
import type { Attachments } from "../componentAttachements/flowTypes";
import type { ParentCmpsHeightUpdatesMap } from "../../../Preview/View/flowTypes";
import { componentsEvalDefaultState } from "./constants";
import { isModernLayoutSection } from '../../../ModernLayouts/preview_utils';
import { Stylesheet } from '../stylesheets/flowTypes';
import { TemplateComponent } from '../../../oneweb/Template/flowTypes';
import { ADD_REMOVE_WEBSHOP_STRIP } from '../../../ModernLayouts/actionTypes';

// arguments: prevChangeMap, nextChangeMap
export const mergeChangesMap = R.mergeDeepWithKey((k, l, r) => {
    if (k === 'top' || k === 'height') {
        return Math.max(l, r);
    }
    return r;
});

const skipPushDownReasons = [
    updateReasons.RESIZED_BY_MOUSE,
    updateReasons.RESIZED_FROM_PROP_PANEL,
    updateReasons.SHIFTBAR,
    undoManagerUpdateReasons.UNDO,
    undoManagerUpdateReasons.REDO,
    ADD_REMOVE_WEBSHOP_STRIP
];

type ChainParentsMap = Record<string, string[]>;
type Chain = string[];
type NestedChain = Array<Chain>;
type ComponentDiffMap = {
    [id: string]: number
}

function produceChain(searchedComponentId: string, componentChainParentsMap: ChainParentsMap): NestedChain {
    const parents = componentChainParentsMap[searchedComponentId],
        allChains: Array<Array<string>> = [];
    if (parents.length === 1) {
        return [[parents[0]]].concat(produceChain(parents[0], componentChainParentsMap));
    }
    for (const _parent of parents) {
        const chain = [_parent],
            furtherChains = produceChain(_parent, componentChainParentsMap);
        if (furtherChains.length > 0) {
            furtherChains.forEach(deeperChain => {
                allChains.push([...chain, ...deeperChain]);
            });
        } else {
            allChains.push(chain);
        }
    }
    return allChains;
}

export function getAllChains(searchedComponentId: string, componentChainParentsMap: ChainParentsMap): Chain[] {
    const parents = componentChainParentsMap[searchedComponentId];
    let allChains: Array<Array<string>> = [];
    for (const _parent of parents) {
        const chain = [_parent],
            furtherChains = produceChain(_parent, componentChainParentsMap);
        if (furtherChains.length > 0) {
            furtherChains.forEach(deeperChain => {
                allChains.push([...chain, ...deeperChain]);
            });
        } else {
            allChains.push(chain);
        }
    }
    return allChains;
}

export function findLongestChain(allChains: Chain[], componentDiffMap: ComponentDiffMap): Chain {
    let longestLength = 0;
    return allChains.reduce((longestChain, chain) => {
        const chainLength = calcChainLength(chain, componentDiffMap);
        if (chainLength > longestLength) {
            longestLength = chainLength;
            return chain;
        }
        return longestChain;
    }, []);
}

function calcChainLength(chain: Chain, componentDiffMap: ComponentDiffMap) {
    return chain.reduce((length, componentId) => length + componentDiffMap[componentId], 0);
}

type AnyAdjustmentHookConfig = AdjustmentHookConfig<AnyComponent, Record<string, any>, AnyValue, Record<string, any>>

function getComponentBeforeTransientChanges(componentId, epicState: EpicState) {
    if (epicState.scope.stateBeforeTransientChanges) {
        return epicState.scope.stateBeforeTransientChanges.componentsMap[componentId];
    } else {
        return undefined;
    }
}

function getComponentExtensionBeforeTransientChanges(componentId, epicState: EpicState) {
    if (epicState.scope.stateBeforeTransientChanges) {
        return epicState.scope.stateBeforeTransientChanges.componentsMapExtension[componentId];
    } else {
        return undefined;
    }
}

export const MIN_DISTANCE_TO_PUSH_DOWN = 15;

export function skipPushDown(selectedComponentID: string, nextComponentsMap: ComponentsMap,
    componentsWithChangedHeight: Array<Array<any>>) {
    if (!selectedComponentID) {
        return false;
    }
    const selectedComponent = nextComponentsMap[selectedComponentID];
    if (!selectedComponent) {
        return false;
    }
    const cmpWithChangedHeight = componentsWithChangedHeight.find(([id]) => selectedComponentID === id);
    if (!cmpWithChangedHeight) {
        return false;
    }
    const oldHeight = cmpWithChangedHeight[1].height,
        newHeight = cmpWithChangedHeight[2].height,
        heightChange = newHeight - oldHeight;
    if (heightChange <= 0) {
        return false;
    }
    let siblingCmp;
    Object.keys(nextComponentsMap).forEach(cmpId => {
        if (cmpId !== selectedComponentID &&
            R.path([cmpId, 'relIn', 'id'], nextComponentsMap) === R.path(['relIn', 'id'], selectedComponent)
            && R.path([cmpId, 'top'], nextComponentsMap) > selectedComponent.top + selectedComponent.height) {
            if (!siblingCmp || siblingCmp.top > nextComponentsMap[cmpId].top) {
                siblingCmp = nextComponentsMap[cmpId];
            }
        }
    });
    if (!siblingCmp) {
        return false;
    }
    if ((siblingCmp.top - MIN_DISTANCE_TO_PUSH_DOWN) > selectedComponent.top + newHeight) {
        return true;
    }
    return false;
}

type AnyAdjustAfterPushdownHookConfig = AdjustAfterPushdownHookConfig<AnyComponent>

const adjustComponentsOnAfterPushdown = componentsMap => {
    const adjustedComponentsMap = {};
    R.keys(componentsMap).forEach(componentId => {
        let component = componentsMap[componentId];
        const
            componentKind = component.kind,
            hookPath = [componentKind, 'adjustAfterPushdownHookConfig'],
            adjustAfterPushdownHookConfig: null | undefined | AnyAdjustAfterPushdownHookConfig = R.path(hookPath, componentsRegistry);

        if (!adjustAfterPushdownHookConfig) {
            return;
        }

        const newComponent = adjustAfterPushdownHookConfig.hook({ component });
        if (newComponent !== component) {
            adjustedComponentsMap[componentId] = newComponent;
        }
    });

    return {
        ...componentsMap,
        ...adjustedComponentsMap
    };
};

export const updateParentComponentHeightsAndPushDown = (
    dynamicCmpAttachments: Attachments,
    attachments: Attachments,
    componentsMap: ComponentsMap
) => {
    let sectionChanges: Array<{ section: AnyComponent, height: number }> = [],
        newComponentsMap = { ...componentsMap };
    const updateChildrenHeight = (childrenIds) => {
        childrenIds.reduce((acc, id) => {
            if (acc[id]) { return acc; }
            acc[id] = true;
            const cmp = componentsMap[id],
                cmpBottom = cmp.top + cmp.height,
                children: ComponentsIds = dynamicCmpAttachments[id] || [];

            if (children.length) {
                updateChildrenHeight(children);
                const childrenBottom = children.reduce((bottom, id): number => {
                    const { top, height } = newComponentsMap[id];
                    return Math.max(top + height, bottom);
                }, cmpBottom);
                if (childrenBottom > cmpBottom) {
                    const newHeight = childrenBottom - cmp.top;
                    if (isSectionComponent(cmp)) {
                        sectionChanges.push({
                            section: cmp,
                            height: newHeight
                        });
                    } else {
                        newComponentsMap = {
                            ...newComponentsMap,
                            [id]: { ...newComponentsMap[id], height: newHeight }
                        };
                    }
                }
            }
            return acc;
        }, {});
    };
    updateChildrenHeight(Object.keys(dynamicCmpAttachments));
    sectionChanges.sort((a, b) => a.section.top - b.section.top);
    sectionChanges.forEach(({ section, height }) => {
        newComponentsMap = setSectionHeight(height, section.id, newComponentsMap, attachments);
    });
    return newComponentsMap;
};

// TODO: 2175
const adjustAfterUpdate: ComponentEvalAdjustAfterUpdate =
    ({ prevState: prevEpicState, nextState: nextEpicState, updateReason }) => {
        const
            defaultTemplateOffset = componentsEvalDefaultState.scope.templateOffset,
            prevScope = prevEpicState.scope,
            prevStateBeforeTransientChanges = prevScope.stateBeforeTransientChanges,
            prevIsTransient = !!prevStateBeforeTransientChanges,
            prevComponentsAdjustmentData = prevScope.componentsAdjustmentData,
            prevComponentsDependencies = prevScope.componentsDependencies,
            inserterSectionOrBlockData = prevScope.inserterSectionOrBlockData,

            nextScope = nextEpicState.scope,
            nextStateBeforeTransientChanges = nextScope.stateBeforeTransientChanges,
            nextIsTransient = !!nextStateBeforeTransientChanges,
            nextComponentsAdjustmentData = nextScope.componentsAdjustmentData,
            nextComponentsDependencies = nextScope.componentsDependencies,

            prevState = prevEpicState.state,
            prevComponentsMap = prevState.componentsMap,
            prevComponentsMapExtension = prevState.componentsMapExtension,
            nextState = nextEpicState.state,
            nextComponentsMap = nextState.componentsMap,
            nextTemplateWidth = nextScope.templateWidth,
            nextTemplateOffset = nextScope.templateOffset || defaultTemplateOffset,
            nextComponentsMapExtension = nextState.componentsMapExtension,
            nextAttachments = nextScope.attachments,
            adjustedComponents = {},
            adjustedComponentsExtensions = {},
            componentsWithChangedHeight: Array<any> = [];

        let
            atLeastOneChangeInComponentsMap = false,
            atLeastOneChangeInComponentsMapExtension = false,
            relationNeededFlag = nextScope.relationNeededFlag,
            nextEpicStateAfterFlag = nextEpicState,
            selectedComponentID;

        let editModeComponentId = nextScope.editModeComponentId;
        if (editModeComponentId
            && !!prevComponentsMap[editModeComponentId]
            && (isModernLayoutSection(prevComponentsMap[editModeComponentId])
                !== isModernLayoutSection(nextComponentsMap[editModeComponentId]))
        ) {
            nextEpicStateAfterFlag = R.assocPath(['scope', 'editModeComponentId'], null, nextEpicState);
        }

        R.keys(nextState.componentsMap).forEach(componentId => {
            const
                nextComponent = nextComponentsMap[componentId],
                isSelectedComponent = nextScope.selectedComponentsIds.indexOf(componentId) !== -1,
                componentKind = nextComponent.kind,
                hookPath = [componentKind, 'adjustmentHookConfig'],
                adjustmentHookConfig: null | undefined | AnyAdjustmentHookConfig = R.path(hookPath, componentsRegistry);

            if (!adjustmentHookConfig) {
                return;
            }

            const
                prevHookProps = {
                    isTransient: isSelectedComponent ? prevIsTransient : false,
                    adjustmentData: prevComponentsAdjustmentData[componentId],
                    componentDependencies: prevComponentsDependencies[componentKind],
                    component: prevComponentsMap[componentId],
                    componentsMap: prevComponentsMap,
                    componentExtension: prevComponentsMapExtension[componentId],
                    originalComponent: getComponentBeforeTransientChanges(componentId, prevEpicState),
                    originalComponentExtension: getComponentExtensionBeforeTransientChanges(componentId, prevEpicState),
                    templateOffset: prevScope.templateOffset || defaultTemplateOffset,
                    mhfCmpsData: prevScope.mhfCmpsData,
                },
                nextHookProps = {
                    isTransient: isSelectedComponent ? nextIsTransient : false,
                    adjustmentData: nextComponentsAdjustmentData[componentId],
                    attachments: nextAttachments,
                    componentsMap: nextComponentsMap,
                    templateWidth: nextTemplateWidth,
                    templateOffset: nextTemplateOffset,
                    mhfCmpsData: nextScope.mhfCmpsData,
                    componentDependencies: nextComponentsDependencies[componentKind],
                    component: nextComponentsMap[componentId],
                    componentExtension: nextComponentsMapExtension[componentId],
                    originalComponent: getComponentBeforeTransientChanges(componentId, nextEpicState),
                    originalComponentExtension: getComponentExtensionBeforeTransientChanges(componentId, nextEpicState)
                };

            if (
                (
                    prevHookProps.isTransient === nextHookProps.isTransient &&
                    prevHookProps.adjustmentData === nextHookProps.adjustmentData &&
                    prevHookProps.componentDependencies === nextHookProps.componentDependencies &&
                    prevHookProps.component === nextHookProps.component &&
                    prevHookProps.componentExtension === nextHookProps.componentExtension &&
                    prevHookProps.originalComponent === nextHookProps.originalComponent &&
                    prevHookProps.mhfCmpsData === nextHookProps.mhfCmpsData
                ) || !adjustmentHookConfig.shouldCallHook(prevHookProps, nextHookProps)
            ) {
                return;
            }

            let [
                componentAfterAdjust,
                componentExtensionAfterAdjust
            ] = adjustmentHookConfig.hook(nextHookProps, prevHookProps, updateReason);

            if (componentAfterAdjust !== nextComponent) {
                let nextComponentHeight = Math.round(componentAfterAdjust.height);
                if (componentAfterAdjust.height !== nextComponentHeight) {
                    componentAfterAdjust.height = nextComponentHeight;
                }
                adjustedComponents[componentId] = componentAfterAdjust;
                if (componentAfterAdjust.height !== nextComponent.height) {
                    componentsWithChangedHeight.push([componentId, nextComponent, componentAfterAdjust]);
                }
                atLeastOneChangeInComponentsMap = true;
            }

            if (componentExtensionAfterAdjust &&
                componentExtensionAfterAdjust !== nextComponentsMapExtension[componentId]
            ) {
                adjustedComponentsExtensions[componentId] = componentExtensionAfterAdjust;
                atLeastOneChangeInComponentsMapExtension = true;
            }

            if (isSelectedComponent) {
                selectedComponentID = nextComponent.id;
            }
        });

        if (updateReason === undoManagerUpdateReasons.UNDO_INITIAL_STATE) {
            relationNeededFlag = true;
            nextEpicStateAfterFlag = R.assocPath(['scope', 'relationNeededFlag'], relationNeededFlag, nextEpicState);
        }

        if (updateReason === updateReasons.RELATIONS_UPDATED) {
            relationNeededFlag = false;
            nextEpicStateAfterFlag = R.assocPath(['scope', 'relationNeededFlag'], relationNeededFlag, nextEpicState);
        }

        nextEpicStateAfterFlag = R.assocPath(
            ['scope', 'nextComponentsDependencies'], nextScope.componentsDependencies, nextEpicStateAfterFlag
        );

        if ((atLeastOneChangeInComponentsMap || atLeastOneChangeInComponentsMapExtension) && !relationNeededFlag) {
            const
                stateAfterAdjust = {
                    componentsMap: atLeastOneChangeInComponentsMap ? {
                        ...nextComponentsMap,
                        ...adjustedComponents
                    } : nextState.componentsMap,
                    componentsMapExtension: atLeastOneChangeInComponentsMapExtension ? {
                        ...nextComponentsMapExtension,
                        ...adjustedComponentsExtensions
                    } : nextState.componentsMapExtension
                },
                epicStateAfterAdjust = R.assoc('state', stateAfterAdjust, nextEpicStateAfterFlag);

            if (componentsWithChangedHeight.length > 0
                && skipPushDownReasons.indexOf(updateReason) === -1
                // Adjustment data can arrive during transient changes, causing push down, thus denying it.
                && (!prevIsTransient || inserterSectionOrBlockData)
                && !skipPushDown(selectedComponentID, nextComponentsMap, componentsWithChangedHeight)
            ) {
                const {
                        stylesheets = {},
                        siteMap,
                        siteSettings,
                        currentPageId,
                        template,
                        nextComponentsDependencies
                    } = epicStateAfterAdjust.scope,
                    { updates, relInsMap } = compute({
                        oldComponentsMap: nextComponentsMap,
                        oldTemplate: {
                            ...template,
                            width: nextScope.templateWidth
                        },
                        currentPageId: currentPageId,
                        stylesheets: stylesheets,
                        siteMap: siteMap,
                        siteSettings: siteSettings,
                        componentsDependencies: nextComponentsDependencies,
                        componentsWithChangedHeight: componentsWithChangedHeight,
                        inserterSectionOrBlockData: inserterSectionOrBlockData,
                        attachments: nextAttachments
                    });

                const updateCmpIds = Object.keys(updates);
                if (updateCmpIds.length) {
                    let newComponentsMap = { ...stateAfterAdjust.componentsMap };
                    let newHeightChangedComponentIds = new Set(componentsWithChangedHeight.map(([id]) => id));
                    updateCmpIds.forEach(key => {
                        if (updates[key].height) {
                            newHeightChangedComponentIds.add(key);
                        }
                        newComponentsMap[key] = {
                            ...newComponentsMap[key],
                            ...updates[key]
                        };
                    });
                    const childToParentMap = getChildToParentMap(nextAttachments);
                    const dynamicHeightsCmpInfo = getChangedHeightsCmpInfoMap(
                            Array.from(newHeightChangedComponentIds),
                            newComponentsMap,
                            childToParentMap
                        ),
                        dynamicHeightComponentAttachments = getAttachmentsForDynamicHeightCmp(nextAttachments, dynamicHeightsCmpInfo);
                    newComponentsMap = updateParentComponentHeightsAndPushDown(
                        dynamicHeightComponentAttachments,
                        nextAttachments,
                        newComponentsMap
                    );

                    const result = calcRelIn(
                        Object.keys(newComponentsMap).map(id => newComponentsMap[id]),
                        newComponentsMap,
                        { changes: relInsMap },
                        template.width
                    );
                    const newRelInChanges = result.changes;
                    Object.keys(newRelInChanges).forEach(id => {
                        if (newComponentsMap[id] && newRelInChanges[id] && newRelInChanges[id].relIn) {
                            newComponentsMap[id] = {
                                ...newComponentsMap[id],
                                relIn: newRelInChanges[id].relIn
                            };
                        }
                    });

                    newComponentsMap = adjustComponentsOnAfterPushdown(newComponentsMap);
                    return {
                        state: R.pipe(
                            setComponentsMap(newComponentsMap),
                            R.when(isTestEnv, setPushDownHappened(true))
                        )(epicStateAfterAdjust),
                        afterUpdateActions: [{
                            type: PUSHDOWN_COMPLETE,
                            payload: {
                                componentIds: newHeightChangedComponentIds,
                                newRelInChanges
                            }
                        }]
                    };
                } else {
                    return {
                        state: epicStateAfterAdjust
                    };
                }
            } else {
                return {
                    state: epicStateAfterAdjust
                };
            }
        }

        return { state: nextEpicStateAfterFlag };
    };

export {
    adjustAfterUpdate as default
};

const getUpdatesFromDom = (componentsMap, inserterSectionOrBlockData, PushDownOffScreenRenderDomId) => {
    let updates = {},
        nodes: Array<any> = [].slice.call(document.querySelectorAll(`#${PushDownOffScreenRenderDomId} div[data-id][data-kind]`));

    // right now, we have 1 parent node and its immediate child both having data-id
    nodes.forEach(node => {
        //this is required bcoz some cmps internally do not have height and query selector selects them also
        if (node.getAttribute("data-specific-kind")) {
            return;
        }
        const
            id = node.getAttribute("data-id"),
            rect = node.getBoundingClientRect(),
            top = Math.round(rect.top),
            bottom = Math.round(rect.bottom),
            height = bottom - top,
            cmp = componentsMap[id];
        if (id && (cmp.top !== top || (cmp.height !== height && height > 0))) {
            updates[id] = { top, height };
        }
    });
    return updates;
};

export const getParentCmpsHeightUpdatesMap = (
    heightChangedCmps: ComponentsIds,
    componentsMap: ComponentsMap,
    flattComponentsMap: ComponentsMap,
    dynamicHeightsCmpInfo: DynamicHeightsComponentsInfoMap,
    dynamicHeightComponentAttachments: Attachments
): ParentCmpsHeightUpdatesMap => {
    const parentCmpsHeightUpdatesMap = {},
        getFinalBottom = (children, overlappingChildren, currentBottom): number => {
            return children.reduce((bottom: number, childId: string) => {
                const { height } = parentCmpsHeightUpdatesMap[childId] || flattComponentsMap[childId],
                    { top } = componentsMap[childId],
                    childBottom = height + top;

                // containers with overlapping
                if (overlappingChildren.includes(childId) && childBottom > bottom) {
                    const { bottomOverlap } = dynamicHeightsCmpInfo[childId],
                        diff = (childBottom - bottomOverlap) - bottom;
                    return diff > 0 ? bottom + diff : bottom;
                }
                return Math.max(bottom, childBottom);
            }, currentBottom);
        };

    const updateChildrenHeights = (acc: Object, id: string) => {
        if (acc[id]) { return acc; }
        acc[id] = true;

        const comp = flattComponentsMap[id],
            cmpHeight = parentCmpsHeightUpdatesMap[id] ? parentCmpsHeightUpdatesMap[id].height : comp.height,
            children = dynamicHeightComponentAttachments[id] || [];
        if (children.length) {
            // first increase all children height
            children.forEach(childId => updateChildrenHeights(acc, childId));
            const overlappingChildren = children.filter(
                    (id) => dynamicHeightsCmpInfo[id] && dynamicHeightsCmpInfo[id].bottomOverlap > 0
                ),
                currentBottom = componentsMap[id].top + cmpHeight;

            const finalBottom = getFinalBottom(children, overlappingChildren, currentBottom);

            const diff = finalBottom - currentBottom;
            if (diff > 0) {
                parentCmpsHeightUpdatesMap[id] = { height: cmpHeight + diff, oldHeight: componentsMap[id].height };
            }
            return acc;
        }
        return acc;
    };

    Object.keys(dynamicHeightComponentAttachments).reduce(updateChildrenHeights, {});
    return parentCmpsHeightUpdatesMap;
};

function compute({
    oldComponentsMap,
    oldTemplate,
    currentPageId,
    stylesheets,
    siteMap,
    siteSettings,
    componentsDependencies,
    componentsWithChangedHeight,
    inserterSectionOrBlockData,
    attachments
}) {
    let
        { componentsMap, template, relInsMap } = expandTemplateWidthAndAdjustComponents(oldComponentsMap, oldTemplate),
        newComponentsMap = R.pipe(
            removeEmptySpacesBetweenSections(attachments),
            cm => syncRelation(relInsMap, cm, template, currentPageId)
        )(componentsMap),
        data = flattening({
            componentsMap: newComponentsMap,
            template,
            isForWorkspace: true
        }),
        flattComponentsMap = data ? data.componentsMap : {},
        newAttachments = createAttachments({ componentsMap }),
        childToParentMap = getChildToParentMap(newAttachments);

    let heightChangedCmps: Array<string> = [];
    componentsWithChangedHeight.forEach(([id, , newCmp]) => {
        if (flattComponentsMap && flattComponentsMap[id].height !== newCmp.height) {
            heightChangedCmps.push(id);
            flattComponentsMap[id].height = newCmp.height; // for components height changed by user explicitly like Gallery
            flattComponentsMap[id].width = newCmp.width;
        }
    });

    if (!heightChangedCmps.length) {
        return { updates: {}, relInsMap };
    }

    const dynamicHeightsCmpInfo = getChangedHeightsCmpInfoMap(heightChangedCmps, componentsMap, childToParentMap),
        dynamicHeightComponentAttachments = getAttachmentsForDynamicHeightCmp(attachments, dynamicHeightsCmpInfo);

    const parentCmpsHeightUpdatesMap = getParentCmpsHeightUpdatesMap(
        heightChangedCmps,
        componentsMap,
        flattComponentsMap,
        dynamicHeightsCmpInfo,
        dynamicHeightComponentAttachments
    );

    if (flattComponentsMap) {
        Object.keys(parentCmpsHeightUpdatesMap).forEach(id => {
            flattComponentsMap[id].height = parentCmpsHeightUpdatesMap[id].height;
        });
    }

    const renderProps = {
        show: true,
        componentsMap: data ? data.componentsMap : null,
        page: { id: currentPageId },
        data,
        mobileData: {},
        previewHTML: '',
        siteMap,
        siteSettings,
        currentPageId,
        domain: getDAL().getDomain(),
        template,
        globalStyles: {
            stylesheets
        },
        stylesheetsIdToNameMap: generateStylesheetsIdToNameMap(stylesheets),
        componentsDependencies,
        globalVariables: componentsDependencies.globalVariables,
        fonts: getPageGoogleFonts(componentsMap).concat(getPageGoogleFonts(stylesheets)),
        pushDown: true,
        isForInserter: !!inserterSectionOrBlockData,
        parentCmpsHeightUpdatesMap,
        dynamicHeightsCmpInfo,
        dynamicHeightComponentAttachments,
        childToParentMap,
    };
    let updates = {};
    if (!isTestEnv()) {
        const PushDownOffScreenRenderDomId = "PushDownOffScreenRenderDomId";

        let root = document.getElementById(PushDownOffScreenRenderDomId);
        if (!root || (root.parentNode !== document.body)) {
            root = document.createElement("div");
            root.setAttribute('id', PushDownOffScreenRenderDomId);
            document.body.appendChild(root);
        }
        // @ts-ignore
        root.innerHTML = ReactDOMServer.renderToStaticMarkup(<CustomGenerateHtml {...renderProps} />);
        updates = getUpdatesFromDom(oldComponentsMap, inserterSectionOrBlockData, PushDownOffScreenRenderDomId);

        root.innerHTML = '';
    }

    return {
        updates,
        relInsMap
    };
}

export const getHTMLAndDimension = memo(function ({
    oldComponentsMap,
    oldTemplate,
    currentPageId,
    stylesheets,
    siteMap,
    siteSettings,
    componentsDependencies,
    globalVariables,
    isParentFullContainer = false,
    excludeMHFComponents = false,
    webshopMHFData = { policies: [], paymentMethods: [] },
    forMHFLayoutItemInLeftPanel = false
}: {
    oldComponentsMap: Record<string, any>;
    oldTemplate: TemplateComponent;
    currentPageId: string;
    stylesheets: Stylesheet;
    siteMap: Record<string, any>;
    siteSettings: any;
    componentsDependencies: Record<string, any>;
    globalVariables: Record<string, any>;
    isParentFullContainer: boolean;
    excludeMHFComponents: boolean;
    webshopMHFData: any;
    forMHFLayoutItemInLeftPanel: boolean;
}) {
    let
        {
            componentsMap,
            template,
            relInsMap
        } = expandTemplateWidthAndAdjustComponents(oldComponentsMap, oldTemplate, excludeMHFComponents),
        newComponentsMap = syncRelation(relInsMap, componentsMap, template, currentPageId);

    newComponentsMap = processSectionBlockComponents({
        componentsMap: newComponentsMap,
        newCmpIds: null,
        stylesheets,
        globalVariables,
        componentsDependencies,
        templateWidth: oldTemplate.width,
        templateSelectedTheme: BACKGROUND_THEME_WHITE,
        isParentFullContainer,
        forMHFLayoutItemInLeftPanel
    });
    const data = flattening({
        componentsMap: newComponentsMap,
        template
    });
    const attachments = createAttachments({ componentsMap }),
        childToParentMap = getChildToParentMap(attachments),
        dynamicHeightsCmpInfo = getChangedHeightsCmpInfoMap(
            data && data.floatingComponents ? data.floatingComponents.map(({ id }) => id) : [],
            newComponentsMap,
            childToParentMap
        ),
        dynamicHeightComponentAttachments = getAttachmentsForDynamicHeightCmp(attachments, dynamicHeightsCmpInfo);

    const renderProps = {
        show: true,
        componentsMap: newComponentsMap,
        page: { id: currentPageId },
        data,
        mobileData: {},
        previewHTML: '',
        siteMap,
        siteSettings,
        currentPageId,
        currentPageName: '',
        shareHeaderAndFirstSectionBgImg: {
            enabled: false,
            offsetTop: 0
        },
        domain: getDAL().getDomain(),
        template: { ...template, selectedTheme: BACKGROUND_THEME_WHITE },
        globalStyles: {
            stylesheets
        },
        stylesheetsIdToNameMap: generateStylesheetsIdToNameMap(stylesheets),
        componentsDependencies,
        dynamicHeightsCmpInfo,
        dynamicHeightComponentAttachments,
        childToParentMap,
        globalVariables: componentsDependencies.globalVariables,
        fonts: getPageGoogleFonts(componentsMap).concat(getPageGoogleFonts(stylesheets)),
        pushDown: true,
        renderViewForTheseComponentsKind: {
            [ImageKind]: true
        },
        isForInserter: true,
        previewLoaded: () => {},
        subscriptionData: {
            subscriptionType: {}
        },
        mobileStyle: {
            background: {},
            title: {},
            defaultLogo: {},
            menu: {
                closed: {},
                open: {}
            }
        },
        menuState: {
            homeMenuItem: {},
            menuItems: []
        },
        webshopMHFData,
    };
    if (!isTestEnv()) {
        const PushDownOffScreenRenderDomId = "PushDownOffScreenRenderForBlocksDomId";

        let root = document.getElementById(PushDownOffScreenRenderDomId);
        if (!root || (root.parentNode !== document.body)) {
            root = document.createElement("div");
            root.setAttribute('id', PushDownOffScreenRenderDomId);
            document.body.appendChild(root);
        }
        const html = ReactDOMServer.renderToStaticMarkup(<CustomGenerateHtml {...renderProps} />);
        root.innerHTML = html;

        const templateEle: any = root.querySelector('.template');

        let parentComponent = isParentFullContainer ? templateEle :
            (root.querySelector('.template > div > div[data-kind*="HOVERBOX"]')
            || root.querySelector('.template > div > div[data-kind*="BACKGROUND"]')
            || root.querySelector('.template > div > div[data-kind*="TEXT"]'));

        const menuEle: any = root.querySelector('[data-specific-kind="MENU"] .menu');
        // menu is hidden first in preview. but, its not getting shown in header layout panel
        if (menuEle) {
            menuEle.style.display = 'block';
        }
        if (templateEle) {
            templateEle.style.display = 'inline-block';
        }

        parentComponent = parentComponent || templateEle;

        const parentComponentHTML = parentComponent && parentComponent.outerHTML;

        const rect = parentComponent && parentComponent.getBoundingClientRect();
        const parentId = parentComponent && parentComponent.dataset.id;

        root.innerHTML = '';

        return { html: parentComponentHTML, rect, parentId, props: renderProps };
    }
    return { html: "", rect: {}, parentId: "", props: renderProps };
});
