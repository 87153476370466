import React from 'react';
import classNames from 'classnames';
import Icons from '../../../view/Icons/index';
import { GLOBAL_STYLES_DIALOG_OPENED } from './actionTypes';
import type { GlobalstyleDialogInvokerPropTypes } from './flowTypes';
import styles from './GlobalstyleDialogInvoker.css';
import TEXT_GLOBAL_STYLES_KIND from "../../oneweb/Text/globalStyle/kind";

const
    openGlobalStylesDialogAC = (stylesheetId: string, kind: string) => ({
        type: GLOBAL_STYLES_DIALOG_OPENED,
        payload: { stylesheetId, kind }
    }),
    openTextGlobalStylesDialogAction = () => openGlobalStylesDialogAC('', TEXT_GLOBAL_STYLES_KIND),

    GlobalstyleDialogInvoker =
        ({ disabled = false, onClick }: GlobalstyleDialogInvokerPropTypes) => {
            const cogClasses = classNames({
                [styles.cog]: true,
                [styles.disabled]: disabled
            });

            return (
                <Icons.BLUE_COG
                    border
                    onClick={onClick}
                    className={cogClasses}
                />
            );
        };

export {
    GlobalstyleDialogInvoker,
    openGlobalStylesDialogAC,
    openTextGlobalStylesDialogAction,
};
