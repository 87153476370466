import validation from 'one-validation/validation'; // eslint-disable-line n/file-extension-in-import
import Preview from "./view/Preview";
import kind from "./kind";
import { encryptStr } from '../../../../../server/shared/utils/encryptionUtils.js';
import { getFontStyle } from "./fontsUtility";
import { LINE_HEIGHT_PERCENTAGE } from "./view/constants";
import { getThemeColorsFromStylesheet } from "../../Workspace/epics/stylesheets/selectors";
import { replaceTagsWithContent } from '../Text/view/replaceTagsWithContent';
import { getConditionalScript } from "../constants";
import type { GenerateScriptsProps } from "../flowTypes";
import type { CalcProps, CmpSpecificScriptProps, CmpSpecificStyles } from "../../Preview/flowTypes";
import type { ContactFormComponent } from "./flowTypes";
import { getPoliciesScriptTemplate } from './getPoliciesScriptTemplate';
import getAccessManager from '../../../getAccessManager';

export const getDefaultSubjectAsString = (pageName: string, domain: string) => {
    return `New message via contact form on ${domain} - ${pageName} page`; // TODO - Should we send translated text?
};

export const getDefaultErrorMsgAsString = () => 'Something went wrong. Please try again later.';

const FormLabelsSeparator = "====";
const MarketingConsentLabelName = "Marketing Consent";

export default {
    kind,
    view: Preview,
    calcProps: ({
        component,
        stylesheetsIdToNameMap,
        globalStyles,
        globalVariables,
        isServerPreview,
        siteMap,
        siteSettings: { themeSettingsData, privacyPolicy },
        selectedParentTheme,
        domain,
    }: CalcProps<ContactFormComponent>) => {
        const
            { autoColorMode } = themeSettingsData,
            themeColorsData = getThemeColorsFromStylesheet(globalStyles);
        return {
            component,
            globalStyles,
            stylesheetsIdToNameMap,
            showMask: false,
            globalVariables,
            isServerPreview: isServerPreview || false,
            site: siteMap,
            themeSettingsData,
            privacyPolicy,
            themeColorsData,
            selectedParentTheme: autoColorMode && selectedParentTheme,
            domain
        };
    },
    getSpecificScript: ({
        component,
        globalVariables,
        site,
        isServerPreview,
        pageName,
        domain,
        config,
        siteSettings
    }: CmpSpecificScriptProps<ContactFormComponent>) => {
        // Below line is needed to get the contactForm config from the oneWeb
        // as it is passed with full config from generateTrialHtml for GPT screenshot
        const configToUse = config?.oneWeb ? config.oneWeb : config;

        const {
            id, recipientEmail, subject, successMessage,
            formElementsOrder, formElements,
            isCaptchaEnabled, captchaLang,
        } = component;
        let formElementsErrorMessages = {},
            formPostURL = '',
            formLabels = '',
            formLabelsTypeMappings = "",
            allFieldErrorMessage = {};

        formElementsOrder.forEach((item, index) => {
            try {
                const element = formElements[item];

                let errorMessage = element.errorMessage;
                errorMessage = replaceTagsWithContent(errorMessage, { globalVariables, site });
                errorMessage = encodeURIComponent(errorMessage);

                const message = element.isRequired ? errorMessage : null;
                formElementsErrorMessages[index] = message;
                allFieldErrorMessage[index] = errorMessage;
            } catch (e) {
                throw new Error("contactForm component formElementsOrder not in sync with formElements data");
            }
        });

        if (isServerPreview) {
            const { partnerName } = config;
            const { contactForm: { sysV3UserFormPostUrl, secretKey } } = configToUse;
            formLabels = formElementsOrder
                .map(element => formElements[element].name)
                .concat(MarketingConsentLabelName)
                .join(FormLabelsSeparator);

            formLabelsTypeMappings = JSON.stringify(Object
                .keys(formElements)
                .reduce((acc, type) => {
                    const label = formElements[type].name;
                    return { ...acc, [label]: type === 'website' ? 'url' : type };
                }, {}));

            formPostURL = [
                sysV3UserFormPostUrl,
                '/form-mail/domain/',
                encryptStr(secretKey, domain),
                `?partner=${partnerName.toLowerCase()}`
            ].join("");
        }

        const data = JSON.stringify({
            formDOMId: id,
            postURL: formPostURL,
            formLabels,
            formLabelsTypeMappings,
            recipientEmail: encodeURIComponent(recipientEmail || ""),
            subject: encodeURIComponent(subject || getDefaultSubjectAsString(pageName, domain)),
            successMessage: encodeURIComponent(successMessage),
            errorMessage: encodeURIComponent(getDefaultErrorMsgAsString()),
            previewMode: !isServerPreview,
            formElementsErrorMessages: JSON.stringify(formElementsErrorMessages),
            allFieldErrorMessage: JSON.stringify(allFieldErrorMessage),
            isCaptchaEnabled: isCaptchaEnabled && getAccessManager().isContactFormCaptchaEnabled(),
            captchaLang,
            emailRegex: validation.email.source,
            urlRegex: '^(' + validation.fragments.httpUrlIdn.source + ')$',
        });

        const privacyPolicy = JSON.stringify({ content: siteSettings?.privacyPolicy?.content });

        return `
            (function (){
                oneJQuery(window).on("load", function() {
                    if (oneJQuery(".oneWebCntForm").length) { +
                        oneJQuery(".oneWebCntForm .contact-form-submit-btn").removeClass("submit-btn-disabled");
                        new window.OnewebContactForm(${data});
                    }
                });
            })();
            ${getPoliciesScriptTemplate(privacyPolicy)}
        `;
    },
    getSpecificStyles: ({ component, globalStyles, autoColorMode }: CmpSpecificStyles<ContactFormComponent>): string => {
        const
            { textStyle } = getFontStyle({
                component,
                globalStyles,
                lineHeightPercentage: LINE_HEIGHT_PERCENTAGE,
                autoColorMode
            }),
            { fontFamily, fontSize, color } = textStyle,
            cls = '.contactFormContainer .oneWebCntForm, .contactFormContainer .contactFormResponseStatus';
        let css = cls +
            '{' +
                'font-family:' + fontFamily + ';' +
                'font-size:' + fontSize + 'px;' +
            '}';

        if (color) {
            css += cls + '{color:' + color + ';}';
        }
        if (!autoColorMode) {
            const captchaIconCls = '.contactFormContainer .frc-container .frc-icon';
            const captchaBtnCls = '.contactFormContainer .frc-container .frc-content .frc-button';
            const frcBannerCls = '.contactFormContainer .frc-banner a';
            if (color) {
                css += captchaIconCls + '{fill:' + color + '; stroke:' + color + ';}';
                css += captchaBtnCls + '{color:' + color + ';}';
                css += frcBannerCls + '{color:' + color + ';}';
            }
        }
        return css;
    },
    generateScripts: ({ isPublicPreview }: GenerateScriptsProps): string[] => [
        // @ts-ignore
        getConditionalScript('/renderStatic/contactForm/contactFormValidationPublicPreview.js', isPublicPreview),
        getConditionalScript('/renderStatic/contactForm/contactFormValidation.js', !isPublicPreview)
    ],
    js: ['/renderStatic/contactForm/friendlyCaptchaWidget.min.js']

};
