export enum OnlineShopStatus {
    NoShop = "NoShop",
    Active = "Active",
    Inactive = "Inactive"
}

export type OnlineShopType = {
    onlineShopStatus: OnlineShopStatus | null;
    isSIATCustomer: boolean | null,
    isLoadingOpenOrders: boolean;
    isLoadingOutOfStockProducts: boolean;
    isLoadingRevenue: boolean;
    isLoadingProducts: boolean;
    openOrders: number;
    outofstockProducts: number;
    revenue: Array<Record<string, number | string>> | -1;
    products: number;
    openDashboard: boolean;
    shopAppRoute: string;
};

export type MoveOnlineDialogProps = {
    config: any;
    dialogProps: any;
};
