import * as R from 'ramda';
import makeEpic from '../../../../epics/makeEpic';
import { createScheduledAction, cancelScheduledAction } from "../../../../redux/middleware/schedule/actionCreators";
import currentPageIdValueActionType from '../../../App/epics/currentPageId/valueActionType';
import valueActionType from './valueActionType';
import * as Actions from '../../actionTypes';
import {
    maxFakePublishProgress, PublishStatusCheckDuration,
    PUBLISH_STATUS, UpdatePublishProgressAnimationDuration
} from '../../constants';
import checkPublishStatus from '../../actionCreators/checkPublishStatus';
import { updatePublishStatus } from '../../actionCreators/index';
import { PUBLISH, SITE_DATA_LOAD_SUCCESS_PAGES_EXIST } from '../../../App/actionTypes';
import { PAGE_DATASET_SAVE_SUCCESS } from "../../../App/epics/pageDataset/actions";
import { TipsCloseOnActions } from "../../../Tooltip/epics/tooltip/index";
import { valueActionType as siteDataValueActionType } from "../../../App/epics/siteData/valueActionType";
import { getDAL } from "../../../../../dal/index";
import { optionalReceiveOnly, receiveOnly, optional } from "../../../../epics/makeCondition";
import { openDialog } from "../../../App/actionCreators/index";
import * as dialogIds from "../../view/dialogIds";
import { SaveStatus } from "../../../Workspace/epics/saveStatus/SaveStatus";
import { undoManagerValueActionType } from "../../../../epics/undoManager/valueActionType";
import { workspaceSaveStatusValueActionType } from '../../../Workspace/epics/saveStatus/valueActionType';
import { FETCH_FAILED_ERROR } from '../../../../../dal/dalErrors';
import closeDialog from "../../../App/actionCreators/closeDialog";
import PublishSiteWitDialogId from "../../view/Publish/PublishSitewit/PublishSiteWitDialogId";
import { sendShownErrorEventToAecAC } from "../../../App/actionCreators/sendEventToAecActionCreator";
import { appConfigPublishWithSSLSelector } from "../../../App/epics/appConfig/selectors";
import { CLOSE_DIALOG } from "../../../../redux/modules/actionTypes";
import { setWindowTitle } from "../../../../redux/middleware/window/actionCreators";
import { WORKSPACE_READY } from "../../../Workspace/actionTypes";
import { valueActionType as PublishConfigEpicVAT } from "../../../SiteSettings/publishConfig/publishConfigGlobalDataEpic";
import { getFinalURL } from "../../../SiteSettings/publishConfig/utils";
import { calculateUpgradeTierBeforeSeamlessUpgrade } from "../../../ComponentTierManager/actionCreators";
import { GmbPublishDialogId } from '../../../SiteSettings/BusinessListing/GoogleMyBusiness/GmbPublishDialog/config';
import { gmbDoNotShowPublishDialogLocalStorageKey } from '../../../SiteSettings/BusinessListing/GoogleMyBusiness/constants';
import { GMB_PUBLISH_UPDATE_USER_CONSENT_ACTION } from '../../../SiteSettings/BusinessListing/GoogleMyBusiness/actionTypes';
import localStorage from "../../../../utils/localStorage";
import { checkPublishInProgress } from './utils';
import { SIMPLESITE } from "../../../../../../server/shared/constants.js";

const MAX_PUBLISH_RETRIES = 3;
const publishProgressDefaultState = {
    status: PUBLISH_STATUS.UNPUBLISHED,
    progress: 0,
    progressAnimation: {
        current: 0,
        target: 0,
        remaining: 0
    }
};
const defaultState = {
    publishStatus: PUBLISH_STATUS.DEFAULT,
    showPublishProgress: false,
    publicationId: null,
    lastPublished: null,
    notLaunched: false,
    publishProgress: publishProgressDefaultState
};
const defaultScope = {
    isSiteMapChanged: false,
    publishedStackIndex: -1,
    publishingStackIndex: -1,
    publicRootUrl: null,
    serverPublishStatus: null,
    publishRetries: 0,
    publishProgress: {
        status: PUBLISH_STATUS.PUBLISHING,
        progress: 0
    },
    isSimpleSiteUser: false
};

/**
 * IMPORTANT: formatMessage calls are intentionally duplicated in the code for same l10 key.
 *            Moving them out of the reducer function calls will not work as l10 will not be
 *            loaded at the time and translation will return blank string.
 */
import { formatMessage } from "../../../../view/intl/CurrentLocale";
import { FEATURE_PUBLISH } from '../../view/Upgrade/tracking/contants';

export const
    TRACKING_SCRIPT_NEWLY_ADDED = "trackingScriptNewlyAdded",
    TRACKING_SCRIPT_IN_PUBLISHED_PAGE = 'trackingScriptInPublishedPage';

const setPublishStatus = R.assoc('publishStatus');
const setPublishingStackIndex = R.assoc('publishingStackIndex');
const setPublishedStackIndex = R.assoc('publishedStackIndex');
const setShowPublishSuccessPopup = R.assoc('showPublishSuccessPopup');
const resetPublishedStackIndex = setPublishedStackIndex(defaultScope.publishedStackIndex);
const setPublicRootUrl = R.assoc('publicRootUrl');
const setServerPublishedStatus = R.assoc('serverPublishStatus');
const setTrackingScriptNewlyAdded = R.assoc(TRACKING_SCRIPT_NEWLY_ADDED);
const setTrackingScriptInPublishedPage = R.assoc(TRACKING_SCRIPT_IN_PUBLISHED_PAGE);
const setSiteMapChangedStatus = R.assoc('isSiteMapChanged');
const ANIMATE_PUBLISH_PROGRESS_IN_WINDOW_TITLE = 'ANIMATE_PUBLISH_PROGRESS_IN_WINDOW_TITLE';
const {
    needsStandardToPublish,
    needsPremiumToPublish,
    needsEcommerceToPublish
} = require('../../../../../../server/shared/accessManager/errors.js');

const reducerComposer = (updaterState, updaterScope, updaterAction) => {
    return {
        state: updaterState,
        scope: updaterScope,
        actionToDispatch: updaterAction
    };
};

const publishSuccessPopupCloseUpdaters = [
    Actions.CLOSE_PUBLISHING_SUCCESS_POPUP,
    ...TipsCloseOnActions
].map(action => ({
    conditions: [action],
    reducer: ({ state, scope }) => {
        return {
            state: R.when(R.propEq('showPublishSuccessPopup', true), setShowPublishSuccessPopup(false), state),
            scope
        };
    }
}));

const getProgressPercentage = (percentageInfo) => {
    return Math.min(100, percentageInfo);
};

const checkNetworkFailure = (err, publishRetries) => {
    return err && err.message === FETCH_FAILED_ERROR && publishRetries < MAX_PUBLISH_RETRIES;
};

const getPublishLink = ({ pageId, publicRootUrl, domain, siteMap, publishConfig }) => {
    let siteUrl,
        linkPath = '',
        pageRef = siteMap.getPageRefByPageId(pageId);

    const getUrlPath = (refId, pageId) => ((pageId !== siteMap.homePageId) ?
        siteMap.getPageRefUrlPath(refId).join('/') : '');

    if (publicRootUrl) {
        siteUrl = publicRootUrl.replace(/\/$/, '');
    } else {
        siteUrl = 'http://' + domain;
    }

    if (pageRef.public) {
        linkPath = getUrlPath(pageRef.id, pageRef.pageId);
        siteUrl += linkPath.length > 0 ? ('/' + linkPath) : '';
    } else {
        siteMap.getItems().some(item => {
            if (item.public) {
                linkPath = getUrlPath(item.id, item.pageId);
                siteUrl += ('/' + linkPath);
                pageRef = item;
                return true;
            }
            return false;
        });
    }

    if (linkPath !== '') {
        siteUrl += pageRef.getItems().length > 0 ? '/' : '.html';
    }

    // Fix the URL based on publishConfig
    siteUrl = getFinalURL(siteUrl, publishConfig);

    return siteUrl;
};

const getPublishStatus = ({
    saveStatus, publishStatusFromServer, currentCommandsStackIndex, publishedStackIndex, publishingStackIndex
}) => {
    switch (publishStatusFromServer) {
        case PUBLISH_STATUS.UNPUBLISHED:
            return PUBLISH_STATUS.UNPUBLISHED;
        case PUBLISH_STATUS.PUBLISHING:
            return PUBLISH_STATUS.PUBLISHING;
        case PUBLISH_STATUS.ACCEPTED:
            return PUBLISH_STATUS.ACCEPTED;
        case PUBLISH_STATUS.PUBLISHED:
            if (
                saveStatus === SaveStatus.CAN_NOT_SAVE &&
                currentCommandsStackIndex === publishedStackIndex &&
                currentCommandsStackIndex === publishingStackIndex
            ) {
                return PUBLISH_STATUS.PUBLISHED;
            } else {
                return PUBLISH_STATUS.UNPUBLISHED;
            }
        default:
            // TODO: Throw a crashreport
            return PUBLISH_STATUS.DEFAULT;
    }
};

export default makeEpic({
    defaultState,
    defaultScope,
    valueActionType,
    afterUpdate: ({ prevState, nextState, nextScope }) => {
        let afterUpdateActions: Array<Action> = [];

        if (checkPublishInProgress(prevState.publishStatus) && nextState.publishStatus === PUBLISH_STATUS.PUBLISHED) {
            setSiteMapChangedStatus(false, nextScope);
            afterUpdateActions.push(setWindowTitle(formatMessage({ id: 'title', defaultMessage: 'Website Builder' })));
        } else if (checkPublishInProgress(prevState.publishStatus) &&
            nextState.publishStatus === PUBLISH_STATUS.UNPUBLISHED) {
            afterUpdateActions.push({ type: Actions.CLOSE_PUBLISHING_PROGRESS_POPUP });
        }

        return { state: nextState, scope: nextScope, afterUpdateActions };
    },
    updaters: [
        {
            conditions: [
                receiveOnly(siteDataValueActionType),
                SITE_DATA_LOAD_SUCCESS_PAGES_EXIST
            ],
            reducer: ({ state, scope, values: [siteMap] }) => {
                const pageIdsMarkedForPublish = siteMap.getPageIdsNotMarkedAsDontPublish(),
                    actionToDispatch = pageIdsMarkedForPublish.length ? checkPublishStatus() : null,
                    isSimpleSiteUser = siteMap && siteMap.origin && siteMap.origin.from === SIMPLESITE;
                return { state,
                    scope: {
                        ...scope,
                        isSimpleSiteUser
                    },
                    actionToDispatch };
            }
        },
        {
            conditions: [
                receiveOnly(siteDataValueActionType),
                receiveOnly(PublishConfigEpicVAT),
                optionalReceiveOnly(currentPageIdValueActionType),
                Actions.SHOW_PUBLISHED_POPUP
            ],
            reducer: ({
                values: [
                    siteMap,
                    publishConfig,
                    pageId
                ],
                state,
                scope
            }) => {  // eslint-disable-line no-unused-vars
                const
                    domain = getDAL().getDomain(),
                    actionToDispatch = R.path([TRACKING_SCRIPT_NEWLY_ADDED], state) ?
                        openDialog(PublishSiteWitDialogId) : null;

                return {
                    state: {
                        ...state,
                        showPublishProgress: false,
                        showPublishSuccessPopup: true,
                        publishSiteLink: getPublishLink({
                            pageId,
                            publicRootUrl: scope.publicRootUrl || ('http://' + domain + '/'),
                            domain,
                            siteMap,
                            publishConfig
                        })
                    },
                    scope,
                    actionToDispatch
                };
            }
        },
        {
            conditions: [PAGE_DATASET_SAVE_SUCCESS],
            reducer: ({ state, scope }) => {
                if (checkPublishInProgress(state.publishStatus)) {
                    return { state, scope };
                } else {
                    return {
                        state: {
                            ...state,
                            publishStatus: PUBLISH_STATUS.UNPUBLISHED
                        },
                        scope
                    };
                }
            }
        },
        {
            conditions: [
                WORKSPACE_READY,
                optional(Actions.PUBLISH_SITE_SUCCESS),
                Actions.CHECK_PUBLISH_STATUS_FAILURE
            ],
            reducer: ({ values: [, publishStatusFromPublishRequestObj, err], state, scope }) => {
                let publishRetries = scope.publishRetries;

                if (!(scope.publishingStackIndex === -1 && !publishStatusFromPublishRequestObj)) {
                    if (checkNetworkFailure(err, publishRetries)) {
                        publishRetries++;
                        return {
                            state,
                            scope: R.assoc('publishRetries', publishRetries, scope),
                            actionToDispatch: checkPublishStatus()
                        };
                    } else {
                        return {
                            state: R.evolve({
                                publishStatus: () => PUBLISH_STATUS.UNPUBLISHED,
                                showPublishProgress: R.F
                            }, state),
                            scope: R.assoc('publishRetries', 0, scope),
                            multipleActionsToDispatch: [
                                openDialog(dialogIds.PublishingFailedDialogId),
                                setWindowTitle(formatMessage({ id: 'title', defaultMessage: 'Website Builder' }))
                            ]
                        };
                    }
                }

                return {
                    state: R.evolve({
                        publishStatus: () => PUBLISH_STATUS.UNPUBLISHED
                    }, state),
                    scope,
                    actionToDispatch: setWindowTitle(formatMessage({ id: 'title', defaultMessage: 'Website Builder' }))
                };
            }
        },
        {
            conditions: [
                Actions.PUBLISH_SITE_FAILURE,
                receiveOnly(appConfigPublishWithSSLSelector),
                receiveOnly(PublishConfigEpicVAT),
                receiveOnly(siteDataValueActionType)
            ],
            reducer: ({
                state,
                scope,
                values: [
                    err,
                    appConfigPublishWithSSL,
                    publishConfig,
                    siteData
                ]
            }) => {
                let multipleActionsToDispatch: Array<Action> = [],
                    publishRetries = scope.publishRetries,
                    shownDialogId: string | null = null;

                if (checkNetworkFailure(err, publishRetries)) {
                    publishRetries++;
                    return reducerComposer(
                        state,
                        { ...scope, publishRetries },
                        { type: PUBLISH, payload: { force: false } }
                    );
                } else if (err && err.errorData) {
                    const ed = err.errorData;
                    let urls;
                    const gmbDoNotShowPublishDialogAgain = localStorage.get(gmbDoNotShowPublishDialogLocalStorageKey);

                    if (Array.isArray(ed.contactFormMissingEmails) && ed.contactFormMissingEmails.length) {
                        multipleActionsToDispatch = [
                            openDialog(
                                dialogIds.ContactFormEmailValidationFailed,
                                {
                                    contactFormMissingEmails: ed.contactFormMissingEmails
                                }
                            )
                        ];
                    } else if (ed.overwrittenAssets && ed.overwrittenAssets.length) {
                        urls = ed.overwrittenAssets.map(asset => asset.url);
                        const siteOriginFrom = siteData.origin && siteData.origin.from;
                        multipleActionsToDispatch = [
                            openDialog(
                                dialogIds.OverwriteAssetsDialogId,
                                {
                                    urls,
                                    publishConfig,
                                    appConfigPublishWithSSL,
                                    siteOriginFrom,
                                    publicationId: state.publicationId
                                }
                            )
                        ];
                        shownDialogId = dialogIds.OverwriteAssetsDialogId;
                    } else if (ed.clobberedAssets && ed.clobberedAssets.length) {
                        urls = ed.clobberedAssets.map(asset => asset.url);
                        const siteOriginFrom = siteData.origin && siteData.origin.from;
                        multipleActionsToDispatch = [
                            openDialog(
                                dialogIds.OverwriteClobberedAssetsDialogId,
                                {
                                    urls,
                                    publishConfig,
                                    appConfigPublishWithSSL,
                                    siteOriginFrom,
                                    publicationId: state.publicationId
                                }
                            )
                        ];
                        shownDialogId = dialogIds.OverwriteClobberedAssetsDialogId;
                    } else if (needsStandardToPublish(ed) || needsPremiumToPublish(ed) || needsEcommerceToPublish(ed)) {
                        // TODO: isPremiumComponentUsed value get for User
                        multipleActionsToDispatch = [
                            calculateUpgradeTierBeforeSeamlessUpgrade("publish:publishCheck", FEATURE_PUBLISH)
                        ];
                        shownDialogId = dialogIds.SeamlessUpgradeDialogId;
                    } else if (ed.gmbSyncMismatch) {
                        if (gmbDoNotShowPublishDialogAgain && gmbDoNotShowPublishDialogAgain.doNotShowPublishDialog === true) {
                            multipleActionsToDispatch = [{ type: GMB_PUBLISH_UPDATE_USER_CONSENT_ACTION }];
                        } else {
                            multipleActionsToDispatch = [openDialog(GmbPublishDialogId, ed.gmbSyncMismatch)];
                        }
                    } else {
                        multipleActionsToDispatch = [
                            openDialog(dialogIds.PublishingFailedDialogId),
                            sendShownErrorEventToAecAC("publishing failed")
                        ];
                    }
                }

                return {
                    state: { ...state, publishStatus: PUBLISH_STATUS.UNPUBLISHED, showPublishProgress: false },
                    scope: { ...scope, publishRetries: 0, serverPublishStatus: null, shownDialogId },
                    multipleActionsToDispatch
                };
            }
        },
        {
            conditions: [
                receiveOnly(undoManagerValueActionType),
                Actions.PUBLISH_SITE_REQUEST
            ],
            reducer: ({ values: [{ undoState }], state, scope }) => {
                return {
                    state: R.evolve({
                        publishStatus: () => PUBLISH_STATUS.PUBLISHING,
                        saveAndPublish: R.F,
                        showPublishProgress: R.T
                    }, state),
                    scope: R.evolve({
                        publishingStackIndex: () => undoState.commandsStackIndex,
                        serverPublishStatus: () => PUBLISH_STATUS.PUBLISHING
                    }, scope),
                    actionToDispatch: closeDialog()
                };
            }
        },
        {
            conditions: [siteDataValueActionType],
            reducer: ({ state, scope }) => ({
                state: setPublishStatus(PUBLISH_STATUS.UNPUBLISHED, state),
                scope: R.pipe(
                    resetPublishedStackIndex,
                    setSiteMapChangedStatus(true)
                )(scope)
            })
        },
        {
            conditions: [Actions.CLOSE_PUBLISHING_PROGRESS_POPUP],
            reducer: ({ state, scope }) => ({
                state: { ...state, showPublishProgress: false, showReadMoreTip: false },
                scope
            })
        },
        {
            conditions: [Actions.PUBLISHING_TIP_READ_MORE_CLICKED],
            reducer: ({ state, scope }) => ({
                state: { ...state, showReadMoreTip: true },
                scope
            })
        },
        {
            conditions: [
                receiveOnly(workspaceSaveStatusValueActionType),
                receiveOnly(undoManagerValueActionType),
                Actions.CHECK_PUBLISH_STATUS_SUCCESS
            ],
            reducer: ({ values: [saveStatus, { undoState }, publishStatusFromReqObj], state, scope }) => {
                const
                    publishStatusUpdaterAction = updatePublishStatus({
                        statusResponse: publishStatusFromReqObj,
                        saveStatus,
                        undoState
                    }),
                    { trackingScriptNewlyAdded, trackingScriptInPublishedPage, lastPublished } = publishStatusFromReqObj,
                    multipleActionsToDispatch: Array<Action> = [publishStatusUpdaterAction],
                    { publishProgress } = state;

                let progress = publishProgress.progress,
                    pagesPublished = parseInt(publishStatusFromReqObj.pagesPublished, 10),
                    pagesToPublish = parseInt(publishStatusFromReqObj.pagesToPublish, 10),
                    publishingWindowTitle =
                        formatMessage({ id: 'publish.publishing', defaultMessage: 'Publishing...' });

                pagesPublished = isNaN(pagesPublished) ? 0 : pagesPublished;
                pagesToPublish = isNaN(pagesToPublish) ? 0 : pagesToPublish;

                let progressAnimation = { ...state.publishProgress.progressAnimation };
                if (pagesToPublish > 0) {
                    // Use 90% of the current progres to have some breathing time
                    progress = Math.max(
                        progress,
                        maxFakePublishProgress,
                        Math.floor((pagesPublished / pagesToPublish) * 90)
                    );

                    if (publishProgress.progress < progress) {
                        progressAnimation = {
                            current: Math.max(maxFakePublishProgress, publishProgress.progress),
                            target: progress,
                            remaining: Math.floor(PublishStatusCheckDuration / UpdatePublishProgressAnimationDuration)
                        };

                        multipleActionsToDispatch.push(createScheduledAction({
                            actionToDispatch: {
                                type: ANIMATE_PUBLISH_PROGRESS_IN_WINDOW_TITLE,
                                payload: progressAnimation
                            },
                            timeout: UpdatePublishProgressAnimationDuration
                        }));
                    }
                } else if (publishStatusFromReqObj.status === PUBLISH_STATUS.PUBLISHING) {
                    multipleActionsToDispatch.push(
                        setWindowTitle(`(${getProgressPercentage(progress)}%) ${publishingWindowTitle}`)
                    );
                } else if (
                    publishStatusFromReqObj.status === PUBLISH_STATUS.PUBLISHED ||
                    publishStatusFromReqObj.status === PUBLISH_STATUS.UNPUBLISHED
                ) {
                    multipleActionsToDispatch.push(setWindowTitle(
                        formatMessage({ id: 'title', defaultMessage: 'Website Builder' })
                    ));
                }

                const newState = R.pipe(
                    setTrackingScriptNewlyAdded(trackingScriptNewlyAdded),
                    setTrackingScriptInPublishedPage(trackingScriptInPublishedPage)
                )(state);

                if (!lastPublished) {
                    newState.notLaunched = true;
                }

                newState.publishProgress = ({ status: publishStatusFromReqObj.status, progress, progressAnimation });
                newState.publicationId = publishStatusFromReqObj.publicationId;
                newState.lastPublished = lastPublished;

                return ({
                    state: newState,
                    scope,
                    multipleActionsToDispatch
                });
            }
        },
        {
            conditions: [ANIMATE_PUBLISH_PROGRESS_IN_WINDOW_TITLE],
            reducer: ({ state, scope, values: [payload] }) => {
                if (
                    state.publishStatus === PUBLISH_STATUS.PUBLISHED ||
                    state.publishStatus === PUBLISH_STATUS.UNPUBLISHED
                ) {
                    return {
                        state,
                        scope,
                        actionToDispatch: cancelScheduledAction({ type: ANIMATE_PUBLISH_PROGRESS_IN_WINDOW_TITLE })
                    };
                }

                const { current, target, remaining } = payload,
                    progress = current + Math.floor((target - current) / remaining),
                    publishingWindowTitle =
                        formatMessage({ id: 'publish.publishing', defaultMessage: 'Publishing...' }),
                    multipleActionsToDispatch: Action[] = [
                        setWindowTitle(`(${getProgressPercentage(progress)}%) ${publishingWindowTitle}`),
                    ];

                let progressAnimation = { ...state.publishProgress.progressAnimation };

                if (remaining > 1) {
                    progressAnimation = {
                        current: progress,
                        target,
                        remaining: (remaining - 1)
                    };

                    multipleActionsToDispatch.push(
                        createScheduledAction({
                            actionToDispatch: {
                                type: ANIMATE_PUBLISH_PROGRESS_IN_WINDOW_TITLE,
                                payload: progressAnimation
                            },
                            timeout: UpdatePublishProgressAnimationDuration
                        })
                    );
                } else {
                    multipleActionsToDispatch.push(
                        cancelScheduledAction({ type: ANIMATE_PUBLISH_PROGRESS_IN_WINDOW_TITLE })
                    );
                }

                return {
                    state: R.evolve({
                        publishProgress: {
                            progressAnimation: () => progressAnimation
                        }
                    }, state),
                    scope,
                    multipleActionsToDispatch
                };
            }
        },
        {
            conditions: [Actions.PUBLISH_SITE_REQUEST],
            reducer: ({ state, scope, values: [{ isResumed }] }) => {
                const publishingWindowTitle = formatMessage({
                    id: 'publish.publishing',
                    defaultMessage: 'Publishing...'
                });

                return {
                    state,
                    scope,
                    multipleActionsToDispatch: [
                        setWindowTitle(`(${isResumed ? state.publishProgress.progressAnimation.current : 0}%) ${publishingWindowTitle}`),
                        createScheduledAction({
                            actionToDispatch: {
                                type: ANIMATE_PUBLISH_PROGRESS_IN_WINDOW_TITLE,
                                payload: {
                                    current: isResumed ? state.publishProgress.progressAnimation.current : 0,
                                    target: maxFakePublishProgress,
                                    remaining: (PublishStatusCheckDuration / UpdatePublishProgressAnimationDuration)
                                }
                            },
                            timeout: UpdatePublishProgressAnimationDuration
                        })
                    ]
                };
            }
        },
        {
            conditions: [
                receiveOnly(workspaceSaveStatusValueActionType),
                receiveOnly(undoManagerValueActionType),
                Actions.PUBLISH_SITE_SUCCESS
            ],
            reducer: ({ values: [saveStatus, { undoState }, publishStatusFromReqObj], state, scope }) => {
                const
                    publishStatusUpdaterAction = updatePublishStatus({
                        statusResponse: publishStatusFromReqObj,
                        saveStatus,
                        undoState
                    });

                return {
                    state,
                    scope,
                    actionToDispatch: publishStatusUpdaterAction
                };
            }
        },
        {
            conditions: [
                workspaceSaveStatusValueActionType,
                receiveOnly(undoManagerValueActionType)
            ],
            reducer: ({ values: [saveStatus, { undoState }], state, scope }) => {
                let currentCommandStackIndex = undoState.commandsStackIndex;
                let publishedStackIndex = scope.publishedStackIndex;
                let publishingStackIndex = scope.publishingStackIndex;
                let newState = state;
                let newScope = scope;

                if (!checkPublishInProgress(scope.serverPublishStatus)) {
                    if (
                        (
                            saveStatus === SaveStatus.CAN_NOT_SAVE &&
                            currentCommandStackIndex === publishedStackIndex &&
                            currentCommandStackIndex === publishingStackIndex
                        ) ||
                        (
                            saveStatus === SaveStatus.CAN_NOT_SAVE &&
                            publishedStackIndex === -1 &&
                            publishingStackIndex === -1 &&
                            currentCommandStackIndex === 0 &&
                            scope.isSiteMapChanged === false
                        )
                    ) {
                        newState = {
                            ...state,
                            publishStatus: PUBLISH_STATUS.PUBLISHED
                        };
                    } else {
                        newState = {
                            ...state,
                            publishStatus: PUBLISH_STATUS.UNPUBLISHED
                        };
                    }
                }
                return {
                    state: newState,
                    scope: newScope
                };
            }
        },
        {
            conditions: [
                Actions.PUBLISH_STATUS_UPDATER,
                receiveOnly(siteDataValueActionType)
            ],
            reducer: ({
                values: [
                    payload,
                    sitemap
                ],
                state,
                scope
            }) => {
                let publishStatusFromServerObj = payload.statusResponse;
                let saveStatus = payload.saveStatus;
                let undoState = payload.undoState;

                const setPublishStatusToResult = result => {
                    return {
                        state: setPublishStatus(getPublishStatus({
                            saveStatus,
                            publishStatusFromServer: publishStatusFromServerObj.status,
                            currentCommandsStackIndex: undoState.commandsStackIndex,
                            publishedStackIndex: result.scope.publishedStackIndex,
                            publishingStackIndex: result.scope.publishingStackIndex
                        }), result.state),
                        scope: result.scope,
                        multipleActionsToDispatch: result.multipleActionsToDispatch || [],
                        actionToDispatch: result.actionToDispatch || null,
                    };
                };

                if (checkPublishInProgress(publishStatusFromServerObj.status)) {
                    let
                        publishStatus = checkPublishStatus(),
                        actionToDispatch = createScheduledAction({
                            actionToDispatch: publishStatus,
                            timeout: PublishStatusCheckDuration
                        });
                    return {
                        state: setPublishStatus(publishStatusFromServerObj.status, state),
                        actionToDispatch,
                        scope: {
                            ...scope,
                            serverPublishStatus: publishStatusFromServerObj.status
                        }
                    };
                } else if (publishStatusFromServerObj.status === PUBLISH_STATUS.PUBLISHED) {
                    let newScope = scope;
                    let multipleActionsToDispatch = [{ type: Actions.SHOW_PUBLISHED_POPUP }];
                    multipleActionsToDispatch.push({ type: Actions.USER_PUBLISH_ACTION_SUCCESSFUL });

                    if (saveStatus === SaveStatus.CAN_NOT_SAVE && scope.publishedStackIndex === -1 &&
                        scope.publishingStackIndex === -1) {
                        multipleActionsToDispatch = [];
                    }
                    if (undoState.commandsStackIndex === 0 &&
                        scope.publishingStackIndex === -1 &&
                        (
                            state.publishStatus === PUBLISH_STATUS.UNPUBLISHED ||
                            state.publishStatus === PUBLISH_STATUS.PUBLISHING
                        ) // eslint-disable-line max-len
                    ) {
                        newScope = R.pipe(
                            setPublishingStackIndex(undoState.commandsStackIndex),
                            setPublishedStackIndex(undoState.commandsStackIndex)
                        )(scope);
                    }

                    const
                        setPublishedStackIndexWhenPrevStatusWasPublishing = R.when(
                            () => (checkPublishInProgress(state.publishStatus)),
                            setPublishedStackIndex(newScope.publishingStackIndex)
                        );

                    return setPublishStatusToResult({
                        state: {
                            ...state,
                            publishProgress: publishProgressDefaultState
                        },
                        scope: R.pipe(
                            setPublicRootUrl(publishStatusFromServerObj.publicRootUrl),
                            setPublishedStackIndexWhenPrevStatusWasPublishing,
                            setServerPublishedStatus(null)
                        )(newScope),
                        multipleActionsToDispatch
                    });
                } else if (publishStatusFromServerObj.status === PUBLISH_STATUS.UNPUBLISHED) {
                    let actionToDispatch: Action | null = null;

                    if (checkPublishInProgress(scope.serverPublishStatus)) {
                        if (
                            publishStatusFromServerObj.lastPublicationError &&
                            publishStatusFromServerObj.lastPublicationError.statusCode === 500
                        ) {
                            const { lastPublicationError } = publishStatusFromServerObj;
                            const hasInValidLinks = lastPublicationError &&
                                lastPublicationError.data &&
                                lastPublicationError.data.invalidUrls &&
                                lastPublicationError.data.invalidUrls.length;
                            if (hasInValidLinks) {
                                actionToDispatch = openDialog(
                                    dialogIds.PublishingFailedInvalidLinksDialogId,
                                    {
                                        sitemap,
                                        lastPublicationError
                                    }
                                );
                            } else {
                                actionToDispatch = openDialog(
                                    dialogIds.PublishingFailedDialogId,
                                    {
                                        sitemap,
                                        lastPublicationError: publishStatusFromServerObj.lastPublicationError
                                    }
                                );
                            }
                        } else {
                            actionToDispatch = setWindowTitle(
                                formatMessage({ id: 'title', defaultMessage: 'Website Builder' })
                            );
                        }
                    }

                    return setPublishStatusToResult({
                        state: {
                            ...state,
                            publishProgress: publishProgressDefaultState
                        },
                        scope: {
                            ...scope,
                            publishRetries: 0,
                            serverPublishStatus: null
                        },
                        actionToDispatch
                    });
                }
                return setPublishStatusToResult({ state, scope });
            }
        },
        {
            conditions: [CLOSE_DIALOG],
            reducer: ({ state, scope }) => {
                return {
                    state,
                    scope: {
                        ...scope,
                        shownDialogId: null
                    },
                    multipleActionsToDispatch: [
                        setWindowTitle(formatMessage({ id: 'title', defaultMessage: 'Website Builder' })),
                        cancelScheduledAction({ type: ANIMATE_PUBLISH_PROGRESS_IN_WINDOW_TITLE })
                    ]
                };
            }
        },
        {
            conditions: [Actions.PUBLISH_CANCELLED_AFTER_ERROR_DIALOG],
            reducer: ({ state, scope }) => {
                return {
                    state,
                    scope,
                    multipleActionsToDispatch: [
                        setWindowTitle(formatMessage({ id: 'title', defaultMessage: 'Website Builder' })),
                        cancelScheduledAction({ type: ANIMATE_PUBLISH_PROGRESS_IN_WINDOW_TITLE })
                    ]
                };
            }
        },
        ...publishSuccessPopupCloseUpdaters
    ]
});
