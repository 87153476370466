import u from 'updeep';

// TODO: use tree-shakeable imports (import isURL from 'validator/es/lib/isURL';)
import isURL from 'validator/lib/isURL'; // eslint-disable-line n/file-extension-in-import
import { makeCombineReducer, makeDefaultStateReducers } from "../../../../../redux/makeReducer/index";
import * as ActionTypes from "../actionTypes";
import defaultState from "./defaultState";
import { LcTabName } from "../constants";
import {
    GlobalVariableAddressUrlKey, GlobalVariableContactEmailKey, GlobalVariablePhoneNumberKey, JS_PROTOCOL_PATTERN
} from "../../../../../constants";
import makeInputReducer from '../../../../../components/inputControls/input/reducerFactory/makeInputReducer';
import { telInputValidation } from '../../../../../components/inputControls/input/commonValidations/telInputValidation';
import {
    GENERAL_GLOBAL_DATA_ADDRESS_CHANGED, GENERAL_GLOBAL_DATA_EMAIL_CHANGED, GENERAL_GLOBAL_DATA_PHONE_CHANGED
} from '../../../../../components/SiteSettings/General/generalGlobalDataEpic/actionTypes';
import { autofixUrlScheme } from "../../../../../utils/autofixUrlScheme";

const { renderAddressToStringSingleline } = require('../../../../../../../server/shared/address/utils.js');

const validationRules = {
        protocols: ['http', 'https'],
        require_protocol: true
    }, phoneInputReducer = makeInputReducer({
        actionType: ActionTypes.LC_PHONE_INPUT_CHANGE_ACTION,
        validations: [{ validation: telInputValidation }],
    });

export default makeCombineReducer({
    combineReducers: {
        ...makeDefaultStateReducers(defaultState),
        [LcTabName.PHONE]: (prevState, action) => {
            if (prevState === undefined) {
                return defaultState[LcTabName.PHONE];
            }

            if (action.type === ActionTypes.LC_PHONE_INPUT_CHANGE_ACTION) {
                const nextInputState = phoneInputReducer(prevState.input, action);
                return { ...prevState, input: nextInputState, value: nextInputState.value };
            } else if (action.type === ActionTypes.LC_PHONE_OPTION_CHANGE_ACTION) {
                return { ...prevState, value: action.payload };
            } else if (action.type === ActionTypes.LC_RESET_PHONE_INPUT) {
                const nextInputState = phoneInputReducer(undefined, action);
                return { ...prevState, input: nextInputState, value: nextInputState.value };
            }

            return prevState;
        },
        [LcTabName.EMAIL]: makeCombineReducer({
            combineReducers: {
                ...makeDefaultStateReducers(defaultState[LcTabName.EMAIL]),
                subject: makeInputReducer({
                    actionType: ActionTypes.LC_EMAIL_SUBJECT_INPUT_CHANGE_ACTION,
                    validations: [],
                }),
            },
        }),
    },
    handleActions: {
        [ActionTypes.LC_PAGE_SELECT]: (state, { payload: id }) => {
            return u({ [LcTabName.PAGE]: { selection: id } }, state);
        },
        [ActionTypes.LC_PAGE_EXPAND]: (state, { payload: { pageIds } }) => {
            return u({ [LcTabName.PAGE]: { expandedIds: pageIds } }, state);
        },
        [ActionTypes.LC_FILE_SELECT]: (state, { payload: { resource } }) => {
            return u({ [LcTabName.FILE]: { selection: resource } }, state);
        },
        [ActionTypes.LC_URL_SET]: (state, { payload: { url, isValid } }) => {
            return u({ [LcTabName.URL]: { selection: url, isValid } }, state);
        },
        [ActionTypes.LC_URL_CORRECT_SCHEMA]: state => {
            if (state[LcTabName.URL].selection === '#addressUrl') return state; // TODO replace #addressUrl with variable

            const { [LcTabName.URL]: { selection: url, isValid } } = state;

            if (JS_PROTOCOL_PATTERN.test(url)) {
                return state;
            }

            // Correct url schema

            const correctUrl = autofixUrlScheme(url, { scheme: 'http' });
            if (isValid && !correctUrl.fixed) return state;

            if (correctUrl.fixed) {
                return u({
                    [LcTabName.URL]: {
                        selection: correctUrl.url,
                        isValid: isURL(correctUrl.url, validationRules)
                    }
                }, state);
            }

            return state;
        },
        [ActionTypes.LC_EMAIL_SET]: (state, { payload }) => {
            return u({ [LcTabName.EMAIL]: { selection: payload } }, state);
        },
        [GENERAL_GLOBAL_DATA_EMAIL_CHANGED]: (state) => {
            if (state.activeTabName === LcTabName.EMAIL) {
                return u({ [LcTabName.EMAIL]: { selection: `#${GlobalVariableContactEmailKey}` } }, state);
            }
            return state;
        },
        [ActionTypes.LC_LOCATION_OPTION_CHANGE_ACTION]: (state, { payload: { selection, name } }) => {
            return u({ [LcTabName.LOCATION]: { selection, name } }, state);
        },
        [GENERAL_GLOBAL_DATA_ADDRESS_CHANGED]: (state, { payload }) => {
            if (state.activeTabName === LcTabName.LOCATION) {
                return u({
                    [LcTabName.LOCATION]: {
                        selection: `#${GlobalVariableAddressUrlKey}`,
                        name: renderAddressToStringSingleline(payload),
                    }
                }, state);
            }
            return state;
        },
        [ActionTypes.LC_SWITCH_TAB]: (state, { payload: { tabName } }) => {
            return { ...state, activeTabName: tabName };
        },
        [ActionTypes.LC_LOAD_EMAIL_SUCCESS]: (state, { payload: { email } }) => {
            return u({ [LcTabName.EMAIL]: { emails: email } }, state);
        },
        [ActionTypes.LC_TOGGLE_OPEN_IN_NEW_WINDOW]: state => {
            return { ...state, openInNewWindow: !state.openInNewWindow };
        },
        [ActionTypes._LC_RESET_CURRENT_RESULT_VALUE]: ({ activeTabName }) => {
            return { ...defaultState, activeTabName, currentResult: null };
        },
        [ActionTypes.LC_SECTION_LINK_PAGE_SELECT]: (state, { payload: selection }) => {
            const sectionId = state[LcTabName.SECTION_LINK].pageId === selection ?
                    state[LcTabName.SECTION_LINK].sectionId : null,
                isValid = sectionId && selection;
            return u({ [LcTabName.SECTION_LINK]: { selection, sectionId, isValid } }, state);
        },
        [ActionTypes.LC_SECTION_LINK_SECTION_SELECT]: (state, { payload: sectionId }) => {
            const isValid = !!(sectionId && state[LcTabName.SECTION_LINK].selection);
            return u({ [LcTabName.SECTION_LINK]: { sectionId, isValid } }, state);
        },
        [GENERAL_GLOBAL_DATA_PHONE_CHANGED]: (state) => {
            if (state.activeTabName === LcTabName.PHONE) {
                return u({ [LcTabName.PHONE]: { value: `#${GlobalVariablePhoneNumberKey}` } }, state);
            }
            return state;
        },
    },
});
