import * as R from 'ramda';
import { $ } from 'tinymce';
import {
    GLOBAL_STYLE_SELECTOR,
    isBookmarkNode,
    isValidContentBlock, getClosestContentBlock,
    unwrapNode, getTextGlobalStyle
} from '../../utils/nodeUtils/utils';
import { isEmptyNode, isTextNode } from '../../../../../../../utils/dom';
import {
    JS_TO_CSS_STYLE_MAPPING,
    TEXT_GLOBAL_STYLE_RULES,
    LINK_GLOBAL_STYLE_RULES
} from '../../../../../../oneweb/Text/constants';
import { getSelectedNodes } from '../../utils/nodeUtils/getSelectedNodes';
import type { TinyMceEditor } from "../../../flowTypes";

const shouldInheritParentStylesSelector = `a, ${GLOBAL_STYLE_SELECTOR}`;

// Requirement: to fix the case
// <span style="color: #somecolor"><span class="globalStyle"></span></span>
// Above case should be converted to <span style="color: #somecolor"><span style="color: #somecolor" class="globalStyle"></span></span>
const inheritStylesFromParents = (node: HTMLElement, selector: string, styles: string[]) => {
    let currentNode: HTMLElement = node;
    while (
        currentNode.parentNode instanceof HTMLElement &&
        !isValidContentBlock(currentNode.parentNode) &&
        (currentNode === node || !$(currentNode).is(selector))
    ) {
        currentNode = currentNode.parentNode;

        // eslint-disable-next-line
        styles.forEach(style => {
            if (currentNode.style && currentNode.style.getPropertyValue(style) && !node.style.getPropertyValue(style)) {
                node.style.setProperty(style, currentNode.style.getPropertyValue(style));
                node.dataset.mceStyle = node.style.cssText; // eslint-disable-line
            }
        });
    }
};

// Requirement: to fix the case
// <span (class="globalStyle" || style="font-size: Mpx")><span style="font-size: Npx"></span><span style="font-size: Ppx"></span></span> (M > N & M > P)
// Above case should be converted to <span (class="globalStyle") style="font-size: Npx")><span></span></span>
// Also normalizes and reduces the html code (TODO: change to only fontSize if getting complicated)
const mergeStyles = (node: HTMLElement, styles) => {
    // Merge consecutive text nodes, to avoid removing visible text nodes with white space characters
    node.normalize();

    let childNodes = Array.from(node.childNodes);
    let hasTextNodes = false;
    let hasNonGlobalStyleNodes = false;

    childNodes = childNodes.filter(childNode => {
        if (!isTextNode(childNode)) {
            if (!isBookmarkNode(childNode)) {
                // $FlowFixMe: WBTGEN-9962: childNodes can be text or comment nodes, put check
                mergeStyles(childNode as HTMLElement, styles);

                if (!getTextGlobalStyle(childNode)) {
                    hasNonGlobalStyleNodes = true;
                }

                return true;
            }
        } else if (isEmptyNode(childNode)) {
            node.removeChild(childNode);
        } else {
            hasTextNodes = true;
        }

        return false;
    });

    // If there is any text node, the styles are being used
    if (childNodes.length && !hasTextNodes) {
        // If node has class and every child also has class, remove node class
        const globalStyle = getTextGlobalStyle(node);
        if (globalStyle && !hasNonGlobalStyleNodes) {
            node.removeAttribute('class');
        }

        styles.forEach(style => {
            // $FlowFixMe: WBTGEN-9962: use getPropertyValue instead
            const parentStyle = node.style[style];

            let
                commonChildStyle: any = null,
                allChildrenOverrideParentStyle = true;

            childNodes.forEach((childNode: any) => {
                // $FlowFixMe: WBTGEN-9962: use getPropertyValue instead
                const currentStyle = childNode.style[style];
                if (!currentStyle) {
                    allChildrenOverrideParentStyle = false;
                } else if (
                    style !== 'text-decoration' &&
                    currentStyle === parentStyle &&
                    !$(childNode).is(shouldInheritParentStylesSelector)
                ) {
                    // $FlowFixMe: WBTGEN-9962: Node don't have style property, Element does, fix types
                    childNode.style.removeProperty(style);
                    // $FlowFixMe: WBTGEN-9962: Node don't have dataset or style property, fix types
                    childNode.dataset.mceStyle = childNode.style.cssText; // eslint-disable-line
                    // $FlowFixMe: WBTGEN-9962: Node don't have style property, Element does, fix types
                    if (!childNode.style.cssText) {
                        // $FlowFixMe: WBTGEN-9962: Node don't have removeAttribute method, Element does, fix types
                        childNode.removeAttribute('style');
                    }

                    allChildrenOverrideParentStyle = false;
                } else if (commonChildStyle === null) {
                    commonChildStyle = currentStyle;
                } else if (currentStyle !== commonChildStyle) {
                    commonChildStyle = false;
                }
            });

            if (allChildrenOverrideParentStyle) {
                // If all children override parent style, remove it from parent
                node.style.removeProperty(style);

                if (style === 'font-size' && !commonChildStyle) {
                    // if the style is font-size and all children have different styles, set the parent style to first style
                    // $FlowFixMe: WBTGEN-9962: Node don't have style property, Element does, fix types
                    node.style.setProperty(style, (childNodes[0] as any).style[style]);
                }

                node.dataset.mceStyle = node.style.cssText; // eslint-disable-line
            }
        });

        if (!node.style.cssText) {
            node.removeAttribute('style');
        }
    }
};

const swapAnchorNode = (anchorNode, childNode) => {
    const newGlobalStyleNode: HTMLElement = document.createElement('span');

    if (childNode.className) {
        newGlobalStyleNode.className = childNode.className;
        childNode.removeAttribute('class');
    }

    Array.from(childNode.style).forEach((style: any) => {
        if (!LINK_GLOBAL_STYLE_RULES.includes(style)) {
            newGlobalStyleNode.style[style] = childNode.style[style];
            childNode.style.removeProperty(style);
        }
    });

    newGlobalStyleNode.dataset.mceStyle = newGlobalStyleNode.style.cssText;
    childNode.dataset.mceStyle = childNode.style.cssText; // eslint-disable-line
    if (!childNode.style.cssText) childNode.removeAttribute('style');

    anchorNode.parentNode.replaceChild(newGlobalStyleNode, anchorNode);
    newGlobalStyleNode.appendChild(anchorNode);
};

const hasOtherStyles = (node, styles) => (
    node.style ? Array.from(node.style || []).some(style => !styles.includes(style)) : false
);

const fixNode = (node, changedStyles, isGlobalStyleChange, isLinkChange) => {
    node.normalize();
    const $node = $(node);

    if (isGlobalStyleChange || isLinkChange) {
        let anchorNodes;
        if ($node.is('a')) {
            anchorNodes = $node;
        } else {
            anchorNodes = $node.find('a');
            if (!anchorNodes.length) {
                anchorNodes = $node.closest('a');
            }
        }

        anchorNodes.each(function (this: any) {
            if (this.childNodes.length !== 1) return;

            const childNode = this.childNodes[0];
            if ($(childNode).is(GLOBAL_STYLE_SELECTOR) || hasOtherStyles(childNode, LINK_GLOBAL_STYLE_RULES)) {
                swapAnchorNode(this, childNode);
            }
        });
    } else if (changedStyles.length) {
        // globalstyle should not override manually applied styles if the manual style is applied later
        // globalstyle should override manually applied styles if applied after manually applied styles
        const textStyles = R.intersection(changedStyles, TEXT_GLOBAL_STYLE_RULES);
        const linkStyles = R.intersection(changedStyles, LINK_GLOBAL_STYLE_RULES);

        if (textStyles.length) {
            $node.find(GLOBAL_STYLE_SELECTOR).each(function (this: any) {
                inheritStylesFromParents(this, GLOBAL_STYLE_SELECTOR, textStyles);
            });
        }

        if (linkStyles.length) {
            $node.find('a').each(function (this: any) {
                inheritStylesFromParents(this, 'a', linkStyles);
            });
        }
    }
};

const fixContentBlock = (node, changedStyles) => {
    const childNodes = node.childNodes;

    for (let i = 0; i < childNodes.length; i++) { //NOSONAR
        let currentNode = childNodes[i];

        if (isBookmarkNode(currentNode)) {
            continue;
        }

        if (isTextNode(currentNode)) {
            if (isEmptyNode(currentNode)) {
                node.removeChild(currentNode);
                i--;
            }
            continue;
        }

        // $FlowFixMe: WBTGEN-9962: childNodes can be text or comment Nodes, put checks
        mergeStyles(currentNode, changedStyles);
    }

    // clean up
    $(node).find('span:not([class]):not([style]):not([id])').each(function (this: any) {
        unwrapNode(this);
    });
};

export const fixGlobalStyles = (
    editor: TinyMceEditor,
    changedStyles: string[],
    isGlobalStyleChange: boolean,
    isLinkChange: boolean
) => {
    const selectedNodes = getSelectedNodes(editor);

    // do not delete the line below, when removed it causes weird selection behavior on toggling list formats
    //
    // steps to verify the behavior:
    // drop a text component, enter two words in two lines, select all using cmd+a, set font size to 6, apply list, remove list
    // expected: the selection is preserved
    // when removing the line below: selection is lost
    //
    // the behavior also leads to other bugs and is identified while working on WBTGEN-9935
    const bookmark = editor.selection.getBookmark();

    const styles = changedStyles.map(style => JS_TO_CSS_STYLE_MAPPING[style] || style);

    let prevContentBlock;
    selectedNodes.forEach((node: HTMLElement) => {
        if (!isTextNode(node)) {
            fixNode(node, styles, isGlobalStyleChange, isLinkChange);
        }

        const closestContentBlock = isValidContentBlock(node) ? node : getClosestContentBlock(node);

        if (closestContentBlock && prevContentBlock !== closestContentBlock) {
            fixContentBlock(closestContentBlock, styles);
        }

        prevContentBlock = closestContentBlock;
    });

    editor.selection.moveToBookmark(bookmark);
};
