import {
    CONTACT_FORM,
    WEB_SHOP,
    HOVERBOX,
    FeaturedProductsComponentKind,
    ProductWidgetKind,
    BOOKINGS,
    BLOG
} from "../componentKinds";

export const
    SCROLL = 'scroll',
    AUTO = 'auto',
    NonTemplateComponents = {
        [WEB_SHOP]: true,
        [CONTACT_FORM]: true,
        [HOVERBOX]: true,
        [FeaturedProductsComponentKind]: true,
        [ProductWidgetKind]: true,
        [BOOKINGS]: true,
        [BLOG]: true,
    },
    // IMPORTANT: Whenever a new category is introduces, it should be added as last element in TemplateCategoryArrayForGA
    // as the index of categories are used for tracking in GA. Strictly do not add any new item between existing items
    TemplateCategoryArrayForGA = [
        'business_and_services',
        'portfolios',
        'family_and_recreation',
        'food_and_hospitality',
        'events',
        'music',
        'online_store',
        'one_page',
        'new',
    ],
    // TODO: DRY with wbtgen/src/components/TemplateSelector/TemplateCategory.js ?
    TemplateCategory: Record<string, string> = TemplateCategoryArrayForGA.reduce((acc, category) => ({
        ...acc,
        [category.toUpperCase()]: category
    }), {}),
    TEMPLATE_CATEGORY_ALL = 'all',
    PREMIUM_TEMPLATE_CATEGORY = 'premium';
