import ComponentTypes from '../../src/view/oneweb/registry/ComponentTypes';
import { OldComponentTypes } from './componentTypesMap';
import * as TemplateMappers from './mappers/Template/index';
import * as BlockMappers from './mappers/Block/index';
import * as ImageMappers from './mappers/Image/index';
import * as LogoMappers from './mappers/Logo/index';
import * as ButtonMappers from './mappers/Button/index';
import * as HoverBoxMappers from './mappers/HoverBox/index';
import * as MenuMappers from './mappers/Menu/index';
import * as GalleryMappers from './mappers/Gallery/index';
import * as TextMappers from './mappers/Text/index';
import * as VideoMappers from './mappers/Video/index';
import * as VideoFileMappers from './mappers/VideoFile/index';
import * as ContactFormMappers from './mappers/ContactForm/index';
import * as FacebookPageMappers from './mappers/FacebookPage/index';
import * as ShareButtonsMapper from './mappers/ShareButtons/index';
import * as WebshopMappers from './mappers/Webshop/index';
import * as WebShopPoliciesMappers from './mappers/WebShopPolicies/index';
import * as WebShopPaymentMethods from './mappers/WebShopPaymentMethods/index';
import * as ImageSliderMappers from './mappers/ImageSlider/index';
import * as CodeMappers from './mappers/Code/index';
import * as DocumentMappers from './mappers/Document/index';
import * as GoogleReviewsMappers from './mappers/GoogleReviews/index';
import * as TableMappers from './mappers/Table/index';
import * as SocialMappers from './mappers/Social/index';
import * as FeaturedProductsMappers from './mappers/FeaturedProducts/index';
import * as InstagramGalleryMappers from './mappers/InstagramGallery/index';
import * as FacebookFeedGalleryMappers from './mappers/FacebookFeedGallery/index';
import {
    emailComponentMapperBack,
    emailComponentMapperTo
} from '../../src/components/oneweb/TextLike/Email/emailComponentConfig';
import {
    phoneComponentMapperBack,
    phoneComponentMapperTo
} from '../../src/components/oneweb/TextLike/Phone/phoneComponentConfig';
import {
    addressComponentMapperBack,
    addressComponentMapperTo
} from '../../src/components/oneweb/TextLike/Address/addressComponentConfig';
import * as SvgMappers from './mappers/Svg/index';
import * as OpeningHoursMappers from './mappers/OpeningHours/index';
import * as BookingsMappers from "./mappers/Bookings/index";
import * as BlogMappers from './mappers/Blog/blogMapper';
import * as PostMappers from './mappers/Blog/postMapper';

/* Widgets */
import { WidgetsTwitterMappers } from './mappers/Widgets/Twitter';
import { WidgetsInstagramMappers } from './mappers/Widgets/Instagram';
import { WidgetsFacebookMappers } from './mappers/Widgets/Facebook';
import { WidgetsSoundcloudMappers } from './mappers/Widgets/Soundcloud';
import { WidgetsSpotifyMappers } from './mappers/Widgets/Spotify';
import { WidgetsVimeoMappers } from './mappers/Widgets/Vimeo';
import { WidgetsTiktokMappers } from './mappers/Widgets/Tiktok';
import { WidgetsGoogleMapsMappers } from './mappers/Widgets/GoogleMaps';
import { WidgetsBingMapsMappers } from './mappers/Widgets/BingMaps';
import { WidgetsPinterestMappers } from './mappers/Widgets/Pinterest';
import { WidgetsEventbriteMappers } from './mappers/Widgets/Eventbrite';
import { WidgetsGoFundMeMappers } from './mappers/Widgets/GoFundMe';
import { WidgetsMailchimpMappers } from './mappers/Widgets/Mailchimp';
import { WidgetsPaypalMappers } from './mappers/Widgets/Paypal';
import { WidgetsBookingsMappers } from './mappers/Widgets/Bookings';

export const to = {
    [OldComponentTypes.TEMPLATE]: TemplateMappers.to,
    [OldComponentTypes.BLOCK]: BlockMappers.to,
    [OldComponentTypes.IMAGE]: ImageMappers.to,
    // @ts-ignore
    [OldComponentTypes.LOGO]: LogoMappers.to,
    [OldComponentTypes.BUTTON]: ButtonMappers.to,
    [OldComponentTypes.MENU]: MenuMappers.to,
    [OldComponentTypes.GALLERY]: GalleryMappers.to,
    [OldComponentTypes.TEXT]: TextMappers.to,
    [OldComponentTypes.VIDEO]: VideoMappers.to,
    [OldComponentTypes.VIDEO_FILE]: VideoFileMappers.to,
    [OldComponentTypes.SHAREBUTTONS]: ShareButtonsMapper.to,
    [OldComponentTypes.FACEBOOKPAGE]: FacebookPageMappers.to,
    [OldComponentTypes.FACEBOOKPAGELIKE]: FacebookPageMappers.to, // Added as migrating script 29 misses some cases
    [OldComponentTypes.CONTACTFORM]: ContactFormMappers.to,
    [OldComponentTypes.TABLE]: TableMappers.to,
    [OldComponentTypes.DOCUMENT]: DocumentMappers.to,
    [OldComponentTypes.CODE]: CodeMappers.to,
    [OldComponentTypes.IMAGESLIDER]: ImageSliderMappers.to,
    [OldComponentTypes.WEBSHOP]: WebshopMappers.to,
    [ComponentTypes.WEBSHOP_POLICIES]: WebShopPoliciesMappers.to,
    [ComponentTypes.WEBSHOP_PAYMENT_METHODS]: WebShopPaymentMethods.to,
    [OldComponentTypes.INSTAGRAM_GALLERY]: InstagramGalleryMappers.to,
    [ComponentTypes.INSTAGRAM_GALLERY]: InstagramGalleryMappers.to,
    [OldComponentTypes.FACEBOOK_FEED_GALLERY]: FacebookFeedGalleryMappers.to,
    // WARNING: even for new component that does not have existence in old wsb we have to create mapper that whitelist all used properties.
    // Do not user R.identity in place of mapper. Check social links for example.
    [ComponentTypes.SOCIAL_LINKS]: SocialMappers.to,
    [ComponentTypes.EMAIL_KIND]: emailComponentMapperTo,
    [ComponentTypes.PHONE_KIND]: phoneComponentMapperTo,
    [ComponentTypes.ADDRESS_KIND]: addressComponentMapperTo,
    [ComponentTypes.SVG_KIND]: SvgMappers.to,
    [ComponentTypes.SECTION]: BlockMappers.to,
    [ComponentTypes.HOVERBOX]: HoverBoxMappers.to,
    [OldComponentTypes.FEATURED_PRODUCTS]: FeaturedProductsMappers.to,
    [ComponentTypes.OPENING_HOURS]: OpeningHoursMappers.to,
    [ComponentTypes.GOOGLE_REVIEWS]: GoogleReviewsMappers.to,
    [ComponentTypes.PRODUCT_WIDGET]: FeaturedProductsMappers.to,
    [ComponentTypes.WIDGETS_TWITTER]: WidgetsTwitterMappers.to,
    [ComponentTypes.WIDGETS_INSTAGRAM]: WidgetsInstagramMappers.to,
    [ComponentTypes.WIDGETS_FACEBOOK]: WidgetsFacebookMappers.to,
    [ComponentTypes.WIDGETS_SPOTIFY]: WidgetsSpotifyMappers.to,
    [ComponentTypes.WIDGETS_PINTEREST]: WidgetsPinterestMappers.to,
    [ComponentTypes.WIDGETS_VIMEO]: WidgetsVimeoMappers.to,
    [ComponentTypes.WIDGETS_GOOGLEMAPS]: WidgetsGoogleMapsMappers.to,
    [ComponentTypes.WIDGETS_BINGMAPS]: WidgetsBingMapsMappers.to,
    [ComponentTypes.WIDGETS_SOUNDCLOUD]: WidgetsSoundcloudMappers.to,
    [ComponentTypes.WIDGETS_PINTEREST]: WidgetsPinterestMappers.to,
    [ComponentTypes.WIDGETS_TIKTOK]: WidgetsTiktokMappers.to,
    [ComponentTypes.WIDGETS_EVENTBRITE]: WidgetsEventbriteMappers.to,
    [ComponentTypes.WIDGETS_GOFUNDME]: WidgetsGoFundMeMappers.to,
    [ComponentTypes.WIDGETS_MAILCHIMP]: WidgetsMailchimpMappers.to,
    [ComponentTypes.WIDGETS_PAYPAL]: WidgetsPaypalMappers.to,
    [ComponentTypes.WIDGETS_BOOKINGS]: WidgetsBookingsMappers.to,
    [ComponentTypes.BOOKINGS_KIND]: BookingsMappers.to,
    [ComponentTypes.BLOG_KIND]: BlogMappers.to,
    [ComponentTypes.POST_KIND]: PostMappers.to,
};

export const afterMappingTo = {
    [OldComponentTypes.IMAGE]: ImageMappers.afterMappingTo,
    [OldComponentTypes.WEBSHOP]: WebshopMappers.afterMappingTo
};

export const back = {
    [OldComponentTypes.TEMPLATE]: TemplateMappers.back,
    [ComponentTypes.BACKGROUND]: BlockMappers.back,
    [ComponentTypes.STRIP]: BlockMappers.back,
    [ComponentTypes.SECTION]: BlockMappers.back,
    [ComponentTypes.HOVERBOX]: HoverBoxMappers.back,
    [ComponentTypes.IMAGE]: ImageMappers.back,
    [ComponentTypes.LOGO]: LogoMappers.back,
    [ComponentTypes.BUTTON]: ButtonMappers.back,
    [ComponentTypes.MENU]: MenuMappers.back,
    [ComponentTypes.GALLERY]: GalleryMappers.back,
    [ComponentTypes.TEXT]: TextMappers.back,
    [ComponentTypes.VIDEO]: VideoMappers.back,
    [ComponentTypes.VIDEO_FILE]: VideoFileMappers.back,
    [ComponentTypes.SHAREBUTTONS]: ShareButtonsMapper.back,
    [ComponentTypes.FACEBOOKPAGE]: FacebookPageMappers.back,
    [OldComponentTypes.FACEBOOKPAGELIKE]: FacebookPageMappers.back,
    [ComponentTypes.CONTACTFORM]: ContactFormMappers.back,
    [ComponentTypes.IMAGESLIDER]: ImageSliderMappers.back,
    [ComponentTypes.TABLE]: TableMappers.back,
    [ComponentTypes.DOCUMENT]: DocumentMappers.back,
    [ComponentTypes.CODE]: CodeMappers.back,
    [ComponentTypes.WEBSHOP]: WebshopMappers.back,
    [ComponentTypes.WEBSHOP_POLICIES]: WebShopPoliciesMappers.back,
    [ComponentTypes.WEBSHOP_PAYMENT_METHODS]: WebShopPaymentMethods.back,
    [ComponentTypes.SOCIAL_LINKS]: SocialMappers.back,
    [ComponentTypes.EMAIL_KIND]: emailComponentMapperBack,
    [ComponentTypes.PHONE_KIND]: phoneComponentMapperBack,
    [ComponentTypes.ADDRESS_KIND]: addressComponentMapperBack,
    [ComponentTypes.SVG_KIND]: SvgMappers.back,
    [ComponentTypes.FEATURED_PRODUCTS_KIND]: FeaturedProductsMappers.back,
    [OldComponentTypes.INSTAGRAM_GALLERY]: InstagramGalleryMappers.back,
    [ComponentTypes.INSTAGRAM_GALLERY]: InstagramGalleryMappers.back,
    [ComponentTypes.FACEBOOK_FEED_GALLERY]: FacebookFeedGalleryMappers.back,
    [ComponentTypes.OPENING_HOURS]: OpeningHoursMappers.back,
    [ComponentTypes.GOOGLE_REVIEWS]: GoogleReviewsMappers.back,
    [ComponentTypes.PRODUCT_WIDGET]: FeaturedProductsMappers.back,
    [ComponentTypes.WIDGETS_TWITTER]: WidgetsTwitterMappers.back,
    [ComponentTypes.WIDGETS_INSTAGRAM]: WidgetsInstagramMappers.back,
    [ComponentTypes.WIDGETS_FACEBOOK]: WidgetsFacebookMappers.back,
    [ComponentTypes.WIDGETS_SPOTIFY]: WidgetsSpotifyMappers.back,
    [ComponentTypes.WIDGETS_PINTEREST]: WidgetsPinterestMappers.back,
    [ComponentTypes.WIDGETS_VIMEO]: WidgetsVimeoMappers.back,
    [ComponentTypes.WIDGETS_GOOGLEMAPS]: WidgetsGoogleMapsMappers.back,
    [ComponentTypes.WIDGETS_BINGMAPS]: WidgetsBingMapsMappers.back,
    [ComponentTypes.WIDGETS_SOUNDCLOUD]: WidgetsSoundcloudMappers.back,
    [ComponentTypes.WIDGETS_PINTEREST]: WidgetsPinterestMappers.back,
    [ComponentTypes.WIDGETS_TIKTOK]: WidgetsTiktokMappers.back,
    [ComponentTypes.WIDGETS_EVENTBRITE]: WidgetsEventbriteMappers.back,
    [ComponentTypes.WIDGETS_GOFUNDME]: WidgetsGoFundMeMappers.back,
    [ComponentTypes.WIDGETS_MAILCHIMP]: WidgetsMailchimpMappers.back,
    [ComponentTypes.WIDGETS_PAYPAL]: WidgetsPaypalMappers.back,
    [ComponentTypes.WIDGETS_BOOKINGS]: WidgetsBookingsMappers.back,
    [ComponentTypes.BOOKINGS_KIND]: BookingsMappers.back,
    [ComponentTypes.BLOG_KIND]: BlogMappers.back,
    [ComponentTypes.POST_KIND]: PostMappers.back,
};
