import React from 'react';
import View from './index';
import BaseComponent from '../../../../../view/oneweb/BaseComponent';
import ComponentMask from "../../../../presentational/ComponentMask/index";
import { ComponentMaskBackground } from "../../../../presentational/ComponentMask/constants";

export default (props: any) => {
    return (
        <BaseComponent {...props}>
            <ComponentMask maskStyle={ComponentMaskBackground.LIGHT} {...props}>
                <View {...props} />
            </ComponentMask>
        </BaseComponent>
    );
};
