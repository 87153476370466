import React from 'react';

import { getAppConfig } from '../../../../../wbtgen/src/components/App/epics/appConfig/appConfig.js';
import AppConfig from '../../../../../wbtgen/src/utils/AppConfig';

import loadingIndicatorCss from './templateCss.js';

const appConfig = AppConfig(getAppConfig());

export const Loader = (props: { className?: string; children?: React.ReactNode; }) => {
    const partnerName = appConfig.get('partnerName');

    return (
        <React.Fragment>
            <style
                type="text/css"
                dangerouslySetInnerHTML={{ __html: loadingIndicatorCss }}
            />
            <div
                style={{ width: '100%', height: '100%' }}
                className={props.className}
                data-testid="dashboardLoader"
            >
                <div className="dot-loader">
                    <img
                        className="loader-animation"
                        src={`/dashboard-assets/loader/${partnerName}.gif`}
                        alt=""
                    />
                    {props.children}
                    <div className="loader-overlay" />
                </div>
            </div>
        </React.Fragment>
    );
};
