import React from "react";
import { useSelector } from "react-redux";
import styles from "./ExpandedPanelBlog.css";
import type { ExtendedPanelContentProps } from "../../../../Panel/view/inserter";
import VerticalSpacer from "../../../../../view/common/VerticalSpacer";
import { selectContainsBlog } from "../../../../App/epics/siteData/selectors";
import { BlogInserterViews } from "./inserterConfig";
import Icons from "../../../../../view/Icons";
import {
    checkComponentSubscriptionCompatibility,
    getComponentTierDataFromComponentKind
} from "../../../../ComponentTierManager/utils";
import BlogKind from "../kind";
import { subscriptionDataSelector } from "../../../../App/epics/subscriptionData/selectors";
import { isFreeUpgradeAvailable } from "../../../../TopBar/epics/checkFreeUpgrade/utils";

export const BlogViews = (props: ExtendedPanelContentProps): AnyReactElement => {
    const { subscriptionType } = props;
    const blogExists = useSelector(selectContainsBlog);
    const subscriptionData = useSelector(subscriptionDataSelector);
    const blogCmpTierData = getComponentTierDataFromComponentKind(BlogKind);
    const isBlogCompatible = checkComponentSubscriptionCompatibility(
        blogCmpTierData.componentTierType,
        subscriptionType
    );

    let InserterView: AnyReactComponent = BlogInserterViews.GET_STARTED;
    if (blogExists) {
        // only show manage blog screen if the user is allowed to
        // otherwise show upgrade to premium
        if (isBlogCompatible) {
            InserterView = BlogInserterViews.MANAGE;
        } else if (isFreeUpgradeAvailable(subscriptionData)) {
            InserterView = BlogInserterViews.FREE_UPGRADE;
        } else {
            InserterView = BlogInserterViews.UPGRADE;
        }
    }

    return (
        <div className={styles.panelContainer}>
            <VerticalSpacer y={24} />

            <Icons.BLOG_INSERTER />

            <VerticalSpacer y={16} />

            <InserterView isBlogCompatible={isBlogCompatible} subscriptionData={subscriptionData} />
        </div>
    );
};
