import { injectIntl } from '../../../../view/intl/index';
import { WIDGETS_PINTEREST_SHOW_UPDATE_LINK_DIALOG } from './actionTypes';
import { ctaOnClickFactory, linkExtractor } from '../utils';
import { PinterestTypes } from './constants';
import { getEmbedPinDo } from './utils';

const CtaButtonTextView = injectIntl((props) => {
    const { selectedComponent: { link }, intl } = props,
        embedPinDo = getEmbedPinDo(link);

    if (embedPinDo === PinterestTypes.board) {
        return intl.msgJoint("msg: widgets.pinterest.cta.changeBoard {Change board}");
    } else if (embedPinDo === PinterestTypes.user) {
        return intl.msgJoint("msg: widgets.pinterest.cta.changeUser {Change user}");
    } else if (embedPinDo === PinterestTypes.pin) {
        return intl.msgJoint("msg: widgets.pinterest.cta.changePin {Change pin}");
    }

    return intl.msgJoint("msg: widgets.cta.changeWidget {Change widget}");
});

const componentMainActions = {
    CtaButtonTextView,
    editButtonText: 'msg: common.settings {Settings}',
    ctaOnClick: ctaOnClickFactory(
        WIDGETS_PINTEREST_SHOW_UPDATE_LINK_DIALOG,
        component => ({ link: linkExtractor(component) })
    ),
};

export { componentMainActions };

