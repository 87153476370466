import type { Element } from '../../../flowTypes';

const noop = () => {};
type reducer = (a: Element, b?: Element, isFirst?: boolean) => Element | void;

/**
 * execute action when the ranges of two elements match based on given criteria
 * Expects that both the types of elements passed are sorted
 */
export const executeOnMatch = (
    A: Array<Element>,
    B: Array<Element>,
    onMatch: reducer = noop,
    noMatch: reducer = noop,
    [rewindCondition, forwardCondition, matchCondition]: Array<Function>
) => {
    if (!B.length) {
        return A.map(a => (noMatch(a) || a));
    }

    let index = 0;
    const result: Element[] = [];
    for (let a of A) {
        while (B[index] && rewindCondition(a, B[index])) index--;
        if (!B[index]) index++;

        while (B[index] && forwardCondition(a, B[index])) index++;
        if (!B[index]) index--;

        let matchFound = false;
        while (B[index] && matchCondition(a, B[index])) {
            a = onMatch(a, B[index++], !matchFound) || a;   // NOSONAR
            matchFound = true;
        }

        if (!B[index]) index--;
        if (!matchFound) a = noMatch(a) || a;   // NOSONAR
        result.push(a);
    }

    return result;
};

export const executeOnEnclosingMatch = (
    A: Array<Element>,
    B: Array<Element>,
    onMatch?: reducer,
    noMatch?: reducer
) => (
    executeOnMatch(A, B, onMatch, noMatch, [
        (a, b) => b.start > a.start,
        (a, b) => b.end < a.end,
        (a, b) => b.start <= a.start && b.end >= a.end
    ])
);

export const executeOnExactMatch = (
    A: Array<Element>,
    B: Array<Element>,
    onMatch?: reducer,
    noMatch?: reducer
) => (
    executeOnMatch(A, B, onMatch, noMatch, [
        (a, b) => b.start > a.start,
        (a, b) => b.end < a.end,
        (a, b) => b.start === a.start && b.end === a.end
    ])
);

export const executeOnAnyMatch = (
    A: Array<Element>,
    B: Array<Element>,
    onMatch?: reducer,
    noMatch?: reducer
) => (
    executeOnMatch(A, B, onMatch, noMatch, [
        (a, b) => b.end >= a.start,
        (a, b) => b.end <= a.start,
        (a, b) => b.start < a.end && b.end > a.start
    ])
);

// Used only for list items
export const executeOnStartMatch = (
    A: Array<Element>,
    B: Array<Element>,
    onMatch?: reducer,
    noMatch?: reducer
) => (
    executeOnMatch(A, B, onMatch, noMatch, [
        (a, b) => b.start > a.start,
        (a, b) => b.end <= a.start,
        (a, b) => b.start <= a.start && b.end > a.start
    ])
);
