import { ComponentNames } from "../../constants";
import { componentMainActions } from "../BlogComponent/componentMainActions";
import { POST_MIN_HEIGHT, POST_MIN_WIDTH } from "./constants";
import PostKind from "./kind";
import previewConfig from "./previewConfig";
import reducer from "./reducer/index";
import view from "./view/index";
import workspace from "./view/workspace";
import makeStateSelectorReducer from "../../../../epics/makeStateSelectorReducer";
import currentPageIdEpic from "../../../App/epics/currentPageId";
import siteDataEpic from "../../../App/epics/siteData";
import { colorThemeSiteSettingsEpic } from "../../../SiteSettings/ColorThemeData/colorThemeSiteSettingsEpic";
import { generalGlobalDataEpic } from "../../../SiteSettings/General/generalGlobalDataEpic/generalGlobalDataEpic";
import stylesheetsEpic from "../../../Workspace/epics/stylesheets";
import { localeReducer as locale } from '../../Template/epics/template/index';

export default {
    kind: PostKind,
    label: ComponentNames[PostKind],
    shortcutIconName: 'post',

    // user can't make the component smaller than these dimensions
    minDimensions: {
        width: POST_MIN_WIDTH,
        height: POST_MIN_HEIGHT,
    },

    // config for default parameters (e.g. default dimensions)
    reducer,

    // config for what the component should render
    view,
    workspace,

    // mobile has the same view for now
    mobileEditorConfig: {
        ...previewConfig,
    },

    // config for the settings buttons of the component
    componentMainActions,

    dependsOn: {
        locale,
        siteData: siteDataEpic.reducer,
        globalstyles: stylesheetsEpic.reducer,
        currentPageId: currentPageIdEpic.reducer,
        themeSettingsData: colorThemeSiteSettingsEpic.reducer,
        generalData: makeStateSelectorReducer(
            generalGlobalDataEpic.reducer,
            generalGlobalDataEpic.valueActionType,
            ({ websiteCategories }) => ({ websiteCategories })
        )
    },

    // calculates view props for workspace
    calcRenderProps: ({ component, componentDependencies, selectedParentTheme }) => {
        const {
            siteData,
            globalstyles,
            currentPageId,
            locale,
            generalData,
            themeSettingsData: { autoColorMode }
        } = componentDependencies;
        return {
            component,
            siteData,
            globalstyles,
            currentPageId,
            locale,
            generalData,
            selectedParentTheme,
            autoColorMode
        };
    },
};
