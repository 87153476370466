const { ConfigComponentList } = require("../constants.js");

class SiteSettingsConfig {
    isSeoTabShown() {
        const siteSettings = this._featuresConfig[ConfigComponentList.SITE_SETTINGS];
        return siteSettings && siteSettings.seoTab && siteSettings.seoTab.show;
    }

    isSocialShareTabShown() {
        const siteSettings = this._featuresConfig[ConfigComponentList.SITE_SETTINGS];
        return siteSettings && siteSettings.socialShareTab && siteSettings.socialShareTab.show;
    }

    isTrackingTabShown() {
        const siteSettings = this._featuresConfig[ConfigComponentList.SITE_SETTINGS];
        return siteSettings && siteSettings.trackingTab && siteSettings.trackingTab.show;
    }

    isSecurityTabShown() {
        const siteSettings = this._featuresConfig[ConfigComponentList.SITE_SETTINGS];
        return siteSettings && siteSettings.securityTab && siteSettings.securityTab.show;
    }

    isBusinessListingTabShown() {
        const siteSettings = this._featuresConfig[ConfigComponentList.SITE_SETTINGS];
        return siteSettings && siteSettings.businessListingTab && siteSettings.businessListingTab.show;
    }

    isGoogleAdsTabShown() {
        const siteSettings = this./*::#*/_featuresConfig[ConfigComponentList.SITE_SETTINGS];
        return siteSettings && siteSettings.trackingTab && siteSettings.trackingTab.googleAds && siteSettings.trackingTab.googleAds.show;
    }
}

module.exports = SiteSettingsConfig;
