import React from 'react';
import LinkButton from '../../../../../view/common/Button/LinkButton';
import VerticalSpacer from '../../../../../view/common/VerticalSpacer';
import PropertyContainer from '../../../../../view/common/PropertyContainer/index';
import Page from '../../../../PropertiesPanel/view/PropertiesPage';
import * as actionTypes from "../../actionTypes";
import * as pageIds from '../ids';
import pagesTitles from '../titles';
import type { Props } from '../flowTypes';

const
    id = pageIds.LINK,
    title = pagesTitles[id],
    view = ({ site, selectedComponent, dispatch, sectionComponents, globalVariables }: Props) => (
        <Page>
            <VerticalSpacer />
            <PropertyContainer label="msg: common.link {Link}">
                <LinkButton
                    linkAction={selectedComponent.linkAction}
                    site={site}
                    sectionComponents={sectionComponents}
                    dispatch={dispatch}
                    setLinkAction={actionTypes.BUTTON_SET_LINK}
                    clearLinkAction={actionTypes.BUTTON_REMOVE_LINK}
                    globalVariables={globalVariables}
                />
            </PropertyContainer>
        </Page>
    );

export { id, title, view };
