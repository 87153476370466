import { HEADER_STYLE_EXCLUDED, ELEMENT_IGNORED_PROPS } from "./constants";
import { include, except } from '../helpers/index';
import type { Element } from '../../flowTypes';

// header style excluded from header tag like highligh
// if highlight is inside header tag it will be applied to the entire line
// not only to higlighted text
const includeExcludedHeaderStyle = include([...HEADER_STYLE_EXCLUDED, ...ELEMENT_IGNORED_PROPS]);
const excludeIgnoredProps = except(ELEMENT_IGNORED_PROPS);

export default (paragraphs: Array<Element>, styles: Array<Element>) => {
    // @ts-ignore
    return styles.reduce((result, style) => {
        if (!style.globalId) {
            return result;
        }

        // get paragraph which has full style and is a heading (it will be added to the heading)
        const paragraph = paragraphs.find((p) => {
            return p.start === style.start && p.end === style.end;
        });

        if (!paragraph) {
            return result;
        }

        // get header styles from style
        const headerStyles = includeExcludedHeaderStyle(style);
        if (!Object.keys(excludeIgnoredProps(headerStyles)).length) {
            return result;
        }

        return [
            ...result,
            headerStyles
        ];
    }, [] as Element[]); // NOSONAR
};
