/* @flow */

const applyMixins = require('./mixin.js');
const { WSBSubToPartnerConfigType, SubscriptionType } = require('./constants.js');
const FileChooserConfig = require('./componentsConfig/FileChooserConfig.js');
const GoogleMyBusinessConfig = require('./componentsConfig/GoogleMyBusinessConfig.js');
const GoogleReviewsConfig = require('./componentsConfig/GoogleReviewsConfig.js');
const OnlineShopConfig = require('./componentsConfig/OnlineShopConfig.js');
const HdImagesConfig = require('./componentsConfig/HdImagesConfig.js');
const SiteSettingsConfig = require('./componentsConfig/SiteSettingsConfig.js');
const VideoFileConfig = require('./componentsConfig/VideoFileConfig.js');
const WidgetsBookingsConfig = require('./componentsConfig/WidgetsBookingsConfig.js');
const TopBarConfig = require('./componentsConfig/TopBarConfig.js');
const SocialWidgetsConfig = require('./componentsConfig/SocialWidgetsConfig.js');
const GenericWidgetsConfig = require('./componentsConfig/GenericWidgetsConfig.js');
const MultipageOnboardingConfig = require('./componentsConfig/MultipageOnboardingConfig.js');
const ContactsConfig = require('./componentsConfig/ContactsConfig.js');
const DashboardConfig = require('./componentsConfig/DashboardConfig.js');
const BackupRestoreConfig = require('./componentsConfig/BackupRestoreConfig.js');
const GenerateScreenshotConfig = require('./componentsConfig/GenerateScreenshotConfig.js');
const ContactFormConfig = require('./componentsConfig/ContactFormConfig.js');
const BookingsConfig = require('./componentsConfig/BookingsConfig.js');
const BlogConfig = require('./componentsConfig/BlogConfig.js');

const matchSubscriptionType = (currentSubscription) => (
    currentSubscription === SubscriptionType.DEMO ? SubscriptionType.TRIAL : currentSubscription
);

const getFeaturesConfig = (subscriptionConfig, partnerName, currentSubscription) => {
    const wsbSubToPartnerConfigSubMap = WSBSubToPartnerConfigType[partnerName];
    if (!wsbSubToPartnerConfigSubMap) {
        throw new Error("Invalid subscription feature management config");
    }
    const partnerSubsConfig = wsbSubToPartnerConfigSubMap[currentSubscription];
    return subscriptionConfig[partnerSubsConfig];
};

class AccessManager {
    /*:: #_partnerName: string; */
    /*:: #_subscriptionConfig: Object; */
    /*:: #_currentSubscription: string; */
    /*:: #_featuresConfig: Object; */

    constructor(subscriptionConfig/*: Object */ = {}, partnerName/*: string */ = '') {
        this./*::#*/_partnerName = partnerName;
        this./*::#*/_subscriptionConfig = subscriptionConfig;
        this.setCurrentSubscription(SubscriptionType.UNKNOWN);
    }

    getCurrentSubscription() {
        return this./*::#*/_currentSubscription;
    }

    setCurrentSubscription(subs/*: Object */) {
        this./*::#*/_currentSubscription = matchSubscriptionType(subs);
        this./*::#*/_featuresConfig = getFeaturesConfig(
            this./*::#*/_subscriptionConfig, this./*::#*/_partnerName, this./*::#*/_currentSubscription
        );
    }
}

applyMixins(AccessManager, [
    HdImagesConfig,
    VideoFileConfig,
    OnlineShopConfig,
    GoogleReviewsConfig,
    GoogleMyBusinessConfig,
    WidgetsBookingsConfig,
    SiteSettingsConfig,
    FileChooserConfig,
    TopBarConfig,
    SocialWidgetsConfig,
    GenericWidgetsConfig,
    MultipageOnboardingConfig,
    ContactsConfig,
    DashboardConfig,
    BackupRestoreConfig,
    GenerateScreenshotConfig,
    ContactFormConfig,
    BookingsConfig,
    BlogConfig
]);

module.exports = {
    AccessManager,
};
