import { assocPath, path } from 'ramda';
import type {
    LayoutData,
    NewLayoutDataPerSection,
    ModernLayoutMap,
    ModernLayoutOptions,
    Dimensions,
    LeftPanelEpicState,
    ModernLayoutToggleOptions
} from "./flowTypes";
import type {
    ComponentsMap,
    AnyComponent,
    ComponentsMapExtension, BBox, ComponentsIds
} from "../../redux/modules/children/workspace/flowTypes";
import type { ComponentDependencies } from "../PropertiesPanel/flowTypes";
import { createNewComponent } from "../Workspace/epics/componentsEval/createNewComponent";
import type { SectionComponent, ModernSectionComponent } from "../oneweb/Section/flowTypes";
import {
    getHeaderSection,
    getFooterSection,
    isHeaderOrFooterSection,
    isSectionComponent,
    isHeaderSection,
    getFirstPageSection,
    isFooterSection,
    getLastPageSection, getHeaderAndFooterSection
} from "../oneweb/Section/utils";
import {
    BUTTON, LOGO_KIND, MENU,
    WEBSHOP_POLICIES, WEBSHOP_PAYMENT_METHODS, isSectionKind, isContactComponentKind
} from "../oneweb/componentKinds";
import { getCmpsRect, getCmpRect, isBoxInsideParentBox, isComponentInsideComponent } from "../Workspace/epics/componentsEval/utils";
import { getParentSectionForBBox } from "../Workspace/epics/isPageMode/utils";
import {
    getSectionWithNoBgImage,
    getSectionWithNoGradientColor,
    getSectionWithUpdatedBgColor
} from "../Workspace/epics/componentsEval/addComponentUtils";
import { BACKGROUND_THEME_WHITE } from "../ThemeGlobalData/constants";
import {
    isModernLayoutSection,
    getAllCmpIdsMapInLayout,
    getAllComponentIdsInModernSection,
    getVResponsiveCmpIdsMapInLayout,
    replaceComponentIdsInLayout,
    getFlattenedOptions,
    createRelInIdsAsPerLayout
} from "./preview_utils";
import {
    H_ALIGN,
    invalidMHFStateReasons,
    MinGapBtwCols,
    minMHFSectionWidth,
    responsiveRowPadding, RowEdgeGapInPercent,
    sectionNames
} from "./constants";
import getUpdatedLayoutBasedOnOptions from './getUpdatedLayoutBasedOnOptions';
import type { WebShopMHFEpicState } from "../oneweb/WebShopMHF/flowTypes";
import isGhost from "../oneweb/Code/isGhost";
import { addEdgeGapsToWidth } from "./maxWidthUtils";
import { MCTA_RESIZE_OPTIONS } from "../oneweb/Button/constants";
import { webShopStripBindingId } from "./layoutsData/webshopMHFDataUtils";
import { MIN_SECTION_HEIGHT } from '../oneweb/Section/constants';

type CmpsState = {
    componentsMap: ComponentsMap,
    componentsMapExtension: ComponentsMapExtension
};
type NewCmpsWithIDMap = CmpsState & { oldToNewIdMap: Record<string, any> };
type NewCmpsWithIDs = NewCmpsWithIDMap & {
    newComponentsIds: Array<string>,
    newComponentsMap: ComponentsMap,
};
interface UpdateLayoutInfoParams {
    cmpId: string;
    active: boolean;
    layoutId: string;
    layout: Record<string, any>;
    minWidth: number;
    componentsMap: ComponentsMap;
    options: ModernLayoutOptions;
    layoutVersion: number;
    sectionProps: Record<string, any>;
}

const
    NoAdjustComponents: MapT<boolean> = {
        [LOGO_KIND]: true
    },
    getTemplateSectionByName = (name: string, componentsMap: ComponentsMap) => {
        if (name === sectionNames.header) {
            return getHeaderSection(componentsMap);
        }
        return getFooterSection(componentsMap);
    },

    replaceComponentIdsInOptions = (
        options: ModernLayoutOptions,
        oldToNewIdMap: Record<string, any>,
        isLayoutUpgraded: boolean = false,
        flattendOldOptions: ModernLayoutOptions = [],
    ): ModernLayoutOptions => {
        const oldLayoutOptionsMap = flattendOldOptions.reduce((acc, o) => ({ ...acc, [o.id]: o }), {});
        return options.map(option => {
            const mappedOptionId = oldToNewIdMap[option.bindingId],
                oldOption = oldLayoutOptionsMap[mappedOptionId];
            let updatedOption = { ...option, id: mappedOptionId || option.id };

            if (option.children) {
                const children = replaceComponentIdsInOptions(option.children, oldToNewIdMap, isLayoutUpgraded, flattendOldOptions);
                updatedOption = { ...updatedOption, children };
            }
            if (isLayoutUpgraded) {
                const hasVisibleChildren = (updatedOption.children || []).some(({ show }) => !!show);

                updatedOption.show = !!mappedOptionId;
                // consider the old option while upgrade.
                if (oldOption) {
                    updatedOption.show = oldOption.show;
                }
                // if atleast one child is true then set the option true
                if (updatedOption.children && updatedOption.children.length) {
                    updatedOption.show = hasVisibleChildren;
                }
            }
            return updatedOption;
        }, {});
    },
    updateLayoutInfoInSection = (params: UpdateLayoutInfoParams) => {
        const {
            cmpId, active, layoutId, layout, minWidth,
            componentsMap, options, layoutVersion, sectionProps
        } = params;
        return assocPath(
            [cmpId, 'modernLayout'],
            { active, layoutId, layout, minDimensions: { width: minWidth }, options, layoutVersion, sectionProps }
        )(componentsMap);
    },
    setSectionLayoutMinDimension = (section: SectionComponent, dimensions: Dimensions) => {
        const { height, width } = dimensions;
        if (section.modernLayout) {
            return assocPath(['modernLayout', 'minDimensions'], { height, width }, section);
        }
        return section;
    },
    createRelInIdsAsPerSectionLayout = (componentsMap: ComponentsMap, sectionId: string) => {
        const section = componentsMap[sectionId];
        let newCmpsMap = { ...componentsMap };
        if (section && isModernLayoutSection(section)) {
            let { layout } = section.modernLayout;
            newCmpsMap = createRelInIdsAsPerLayout(newCmpsMap, layout);
        }
        return newCmpsMap;
    },
    createNewComponents = (
        components: Array<AnyComponent>,
        cmpsMap: ComponentsMap,
        componentDependencies: ComponentDependencies,
        noAdjustComponentKinds: Record<string, any> = {},
        isForInserter: boolean = false,
    ): NewCmpsWithIDMap => {
        let componentsMap = {},
            componentsMapExtension = {},
            oldToNewIdMap = {};
        components.forEach((c) => {
            let { kind, id } = c,
                {
                    component,
                    componentExtension
                    // @ts-ignore
                } = createNewComponent(c, '', cmpsMap, false, componentDependencies, noAdjustComponentKinds[kind]);

            if (!component) { return; }
            if (isForInserter && isContactComponentKind(component.kind)) {
                component = assocPath(['specific', 'showPlaceholderValue'], true, component);
            }
            componentsMap = { ...componentsMap, [component.id]: component };
            oldToNewIdMap[id] = component.id;
            componentsMapExtension = { ...componentsMapExtension, [component.id]: componentExtension || {} };
        });
        return { componentsMap, componentsMapExtension, oldToNewIdMap };
    },
    getCmpsMapWithUpdatedSectionColor = (cmpsMap: ComponentsMap, sectionId: string, opacity?: number) => {
        let updatedCmp = getSectionWithUpdatedBgColor(cmpsMap[sectionId], '#FFFFFF', opacity);
        updatedCmp = getSectionWithNoGradientColor(updatedCmp);
        updatedCmp = getSectionWithNoBgImage(updatedCmp);
        updatedCmp = assocPath(["selectedTheme"], BACKGROUND_THEME_WHITE, updatedCmp);
        return {
            ...cmpsMap,
            [sectionId]: updatedCmp
        };
    },
    getPropsForSidebarDesktopPreview = (props: any, layoutItem: Record<string, any>) => {
        const {
            data,
            siteMap,
            siteSettings,
            currentPageId,
            currentPageName,
            shareHeaderAndFirstSectionBgImg,
            mobileData,
            previewHTML,
            template,
            fonts,
            domain,
            componentsMap,
            dynamicHeightsCmpInfo,
            dynamicHeightComponentAttachments,
            childToParentMap,
            webshopMHFData,
            ...remainingProps
        } = props;

        return {
            preview: {
                data,
                siteMap,
                siteSettings,
                currentPageId,
                currentPageName,
                shareHeaderAndFirstSectionBgImg,
                mobileData,
                previewHTML,
                template,
                fonts,
                domain,
                componentsMap,
                dynamicHeightsCmpInfo,
                dynamicHeightComponentAttachments,
                childToParentMap,
                webshopMHFData,
            },
            isInSidebar: true,
            layoutItem,
            ...remainingProps
        };
    },
    getCmpToBeCreated = (layoutCmpMap: Record<string, any>, cmpIds: Array<string>) => {
        const cmpMap: any[] = [];
        Object.keys(layoutCmpMap).forEach(cmpId => {
            if (cmpIds.includes(cmpId)) {
                cmpMap.push(layoutCmpMap[cmpId]);
            }
        });
        return cmpMap;
    },
    getComponentIds = (options: ModernLayoutToggleOptions[], cmpsToDuplicateMap: Record<string, any>) => {
        const onComponentIds = getFlattenedOptions(options).filter(option => option.show).map(option => option.id);
        return getCmpToBeCreated(cmpsToDuplicateMap, onComponentIds);
    },
    upgradeComponentsFromLayoutAndRearrange = (
        layoutItem: LayoutData,
        sectionId: string,
        cmpsState: CmpsState,
        oldToNewIdMap: Record<string, any>,
        flattendOldOptions: ModernLayoutOptions,
        sectionProps: Record<string, any>,
        isActive: boolean
    ) => {
        const { layout: oldLayoutMap, id: layoutId, version: layoutVersion, options } = layoutItem,
            layoutMap = replaceComponentIdsInLayout(oldLayoutMap, oldToNewIdMap, sectionId),
            optionsMap = replaceComponentIdsInOptions(options, oldToNewIdMap, true, flattendOldOptions);
        let newComponentsMap = updateLayoutInfoInSection({
            cmpId: sectionId,
            active: isActive,
            layoutId,
            layout: layoutMap,
            minWidth: minMHFSectionWidth,
            componentsMap: cmpsState.componentsMap,
            options: optionsMap,
            layoutVersion,
            sectionProps
        });
        return newComponentsMap;
    },
    createNewComponentsInSection = (
        cmpsState: CmpsState,
        cmpDeps: ComponentDependencies,
        sectionId: string,
        layoutItem: LayoutData,
        newCmpIds: Array<string>
    ) => {
        const componentsMap = cmpsState.componentsMap,
            section = componentsMap[sectionId],
            mhfData = (section.modernLayout || {}),
            options = mhfData.options,
            { componentsMap: cmpsToDuplicateMap, id: layoutId, version: layoutVersion } = layoutItem,
            componentsToCreate = getCmpToBeCreated(cmpsToDuplicateMap, newCmpIds),
            {
                oldToNewIdMap,
                componentsMap: duplicatedCmpsMap
            } = createNewComponents(componentsToCreate, componentsMap, cmpDeps, NoAdjustComponents),
            layoutMap = replaceComponentIdsInLayout(mhfData.layout, oldToNewIdMap, sectionId),
            optionsMap = replaceComponentIdsInOptions(options, oldToNewIdMap);

        let newComponentsMap = { ...componentsMap, ...duplicatedCmpsMap };
        newComponentsMap = updateLayoutInfoInSection({
            cmpId: sectionId,
            active: true,
            layoutId,
            layout: layoutMap,
            minWidth: minMHFSectionWidth,
            componentsMap: newComponentsMap,
            options: optionsMap,
            layoutVersion,
            sectionProps: mhfData.sectionProps
        });

        return {
            componentsMap: newComponentsMap,
            oldToNewIdMap
        };
    },
    isBoxInModernHeader = (box: BBox, componentsMap: ComponentsMap) => {
        const header = getHeaderSection(componentsMap) || {},
            { active = false } = header.modernLayout || {};
        return active && isBoxInsideParentBox(box, getCmpRect(header), true);
    },
    isBoxInModernFooter = (box: BBox, componentsMap: ComponentsMap) => {
        const footer = getFooterSection(componentsMap) || {},
            { active = false } = footer.modernLayout || {};
        return active && isBoxInsideParentBox(box, getCmpRect(footer), true);
    },
    isBoxInModernHeaderOrFooter = (box: BBox, componentsMap: ComponentsMap) => {
        return isBoxInModernHeader(box, componentsMap) || isBoxInModernFooter(box, componentsMap);
    },
    isCmpsInsideModernHeaderOrFooter = (cmpsToCheck: Array<AnyComponent>, componentsMap: ComponentsMap) => {
        return isBoxInModernHeaderOrFooter(getCmpsRect(cmpsToCheck), componentsMap);
    },
    isModernHeaderOrFooter = (component: AnyComponent) => {
        return isHeaderOrFooterSection(component) && isModernLayoutSection(component);
    },
    isComponentPlacedInHeader = (id: string, componentsMap: ComponentsMap) => {
        const header = getHeaderSection(componentsMap),
            component = componentsMap[id];
        return header && component && isComponentInsideComponent(component, header);
    },
    someComponentsPlacedInModernSection = (cIds: ComponentsIds, componentsMap: ComponentsMap) => {
        return cIds.some(cId => {
            const component = componentsMap[cId],
                box = getCmpRect(component),
                parentSection = getParentSectionForBBox(getCmpRect(component), componentsMap);
            if (!parentSection || cIds.includes(parentSection.id)) {
                return false;
            }
            const isModernSection = !!parentSection && isSectionComponent(parentSection) && isModernLayoutSection(parentSection);
            return isModernSection && isBoxInsideParentBox(box, getCmpRect(parentSection), true);
        });
    },
    getIsModernHeaderNeverActivated = (componentsMap: ComponentsMap) => {
        const header = getHeaderSection(componentsMap);
        return header && !header.modernLayout;
    },
    getIsModernFooterNeverActivated = (componentsMap: ComponentsMap) => {
        const footer = getFooterSection(componentsMap);
        return footer && !footer.modernLayout;
    },
    getIsBoxBelowModernFooter = (box: BBox, componentsMap: ComponentsMap) => {
        const footerSection = getFooterSection(componentsMap);
        if (!footerSection) {
            return false;
        }
        const isModernFooterActivated = footerSection && isModernLayoutSection(footerSection),
            footerRect = getCmpRect(footerSection);
        return isModernFooterActivated && footerRect.bottom < box.bottom;
    },
    getIsModernHeaderFooterNeverActivated = (componentsMap: ComponentsMap) => {
        return getIsModernHeaderNeverActivated(componentsMap) && getIsModernFooterNeverActivated(componentsMap);
    },
    tryToPlaceComponentInToNonModernSection = (
        component: AnyComponent, componentsMap: ComponentsMap, scroll: Record<string, any>, viewportHeight: number, topDiff: number = 0
    ): AnyComponent => {
        const parentSection = getParentSectionForBBox(getCmpRect(component), componentsMap);
        if (!parentSection || !isModernLayoutSection(parentSection) || [scroll.x, scroll.y].includes(undefined) || !viewportHeight) {
            return component;
        }
        let top: number;
        if (isHeaderSection(parentSection)) {
            top = getFirstPageSection(componentsMap).top;
        } else if (isFooterSection(parentSection)) {
            top = getLastPageSection(componentsMap).top;
        } else {
            return component;
        }
        const newComponent = { ...component, top: top + topDiff },
            cmpCenter = newComponent.top + (newComponent.height / 2),
            { top: cmpTop, bottom: cmpBottom } = getCmpRect(newComponent),
            isTopAndBottomWithinView = (top, bottom = top) => top >= scroll.y && bottom <= (scroll.y + viewportHeight),
            isCmpVisibleOnPaste = isTopAndBottomWithinView(cmpTop, cmpBottom) ||
                (isTopAndBottomWithinView(cmpCenter) && newComponent.height >= 300);

        return isCmpVisibleOnPaste ? newComponent : component;
    },
    calcNewHeightOfContainerBasedOnChildren = (containerId: string, children: Array<AnyComponent>): number => {
        let newHeight = 0;
        children.forEach(cmp => {
            if (cmp.relIn && cmp.relIn.id === containerId) {
                newHeight = Math.max((cmp.relIn.top + cmp.height - cmp.relIn.bottom), newHeight);
            }
        });
        return newHeight;
    },
    getModernLayoutYourItemHeight = (leftPanel: LeftPanelEpicState, layoutItemId: string, scale: number) => {
        const layoutItem = leftPanel.layoutItemHeights[layoutItemId];
        return (layoutItem && layoutItem.height) ? (layoutItem.height * scale) : 0;
    }, getCmpsMapForLayout = (
        section: SectionComponent,
        componentsMap: ComponentsMap,
        MHFStoredLayoutsData: NewLayoutDataPerSection,
        layoutId: string
    ) => {
        // $FlowFixMe: section.modernLayout property existence is already checked in isModernLayoutSection(section)
        if (isModernLayoutSection(section) && layoutId === section.modernLayout.layoutId) {
            let newComponentsMap = { [section.id]: { ...section, top: 0 } };
            const { newLayout, cmpsMap } = getUpdatedLayoutBasedOnOptions(section, componentsMap);
            Object.keys(getAllCmpIdsMapInLayout(newLayout, section.id))
                .forEach(id => {
                    const cmp = cmpsMap[id];
                    if (!cmp) {
                        return;
                    }
                    let { width, kind } = cmp;
                    if (kind === MENU || kind === BUTTON) {
                        width = Math.min(100, width);
                    }
                    newComponentsMap[id] = {
                        ...cmp,
                        top: cmp.top - section.top,
                        width
                    };
                });
            return newComponentsMap;
        }
        if (MHFStoredLayoutsData[layoutId]) {
            let newComponentsMap = {}, sectionHeight = 0;
            const { cmpsMap } = MHFStoredLayoutsData[layoutId];
            let cmpOnSection = cmpsMap[Object.keys(cmpsMap)
                    .find(id => cmpsMap[id].relIn && cmpsMap[id].relIn.id === section.id) || ''],

                diff = cmpOnSection ? (cmpOnSection.top - section.top - cmpOnSection.relIn.top) : 0;

            Object.keys(cmpsMap).forEach(id => {
                const cmp = cmpsMap[id];
                if (!cmp) {
                    return;
                }
                let { width, kind } = cmp;
                if (kind === MENU) {
                    width = Math.min(100, width);
                }
                newComponentsMap[id] = {
                    ...cmpsMap[id],
                    top: cmpsMap[id].top - section.top - diff,
                    width
                };
                const relIn: Record<string, any> = cmpsMap[id].relIn;
                sectionHeight = Math.max(
                    sectionHeight,
                    relIn.top + cmpsMap[id].height - relIn.bottom
                );
            });
            newComponentsMap[section.id] = {
                ...section,
                ...section.modernLayout.sectionProps.new[layoutId],
                top: 0,
                height: sectionHeight
            };
            return newComponentsMap;
        }
        return null;
    },
    isVerticallyResponsiveModernSection = (section: AnyComponent) => {
        if (!isModernHeaderOrFooter(section)) {
            return false;
        }
        // @ts-ignore
        const { modernLayout: { layout } = {} } = section;
        return layout && layout[section.id] && !!layout[section.id].vResponsive;
    },
    isAddWebshopMHFCmps = (webshopMHFData: WebShopMHFEpicState, componentsMap: ComponentsMap,
        layoutId: string, mhfData: Record<string, any>) => {
        if (webshopMHFData.showSpecialFooterStrip) {
            const toggleOffCmpsMap = mhfData.toggleOffData.footer && mhfData.toggleOffData.footer[layoutId],
                cmpsMap = { ...componentsMap, ...toggleOffCmpsMap };
            if (webshopMHFData.data && webshopMHFData.data.policies && webshopMHFData.data.policies.length) {
                return !Object.keys(cmpsMap).some(key => cmpsMap[key].kind === WEBSHOP_POLICIES);
            }
            return !Object.keys(cmpsMap).some(key => cmpsMap[key].kind === WEBSHOP_POLICIES ||
                cmpsMap[key].kind === WEBSHOP_PAYMENT_METHODS);
        }
        return false;
    },
    disableMhfComponentToggle = (
        options: ModernLayoutOptions,
        parentId: string,
        currentCmpFlag: boolean
    ) => {
        const optionsWithOutWebshop = options.filter(option => option.bindingId !== webShopStripBindingId);
        const hasSingleComponent = optionsWithOutWebshop.filter(option => option.show).length === 1;
        let disableCmp = false;
        if (currentCmpFlag && hasSingleComponent) {
            if (parentId) {
                const parentOptions = optionsWithOutWebshop.find(option => option.id === parentId);
                if (parentOptions && parentOptions.children) {
                    disableCmp = parentOptions.children.filter(option => option.show).length === 1;
                }
            } else {
                disableCmp = true;
            }
        }
        return disableCmp;
    },
    fetchCmpFromOldOptions = oldOption => {
        return (oldOption && oldOption.id && oldOption.id !== oldOption.bindingId)
            ? oldOption.id : undefined;
    },
    fetchKindToIdMap = cmpsMap => {
        let kindToIdMap = {};

        cmpsMap.forEach((c: AnyComponent) => {
            const { kind, id } = c;
            kindToIdMap[kind] = id;
        });

        return kindToIdMap;
    },
    getCmpToBindingIdMap = (currentLayout, cmpsMap, oldOptions) => {
        const layoutOptions = getFlattenedOptions(currentLayout.options),
            oldLayoutOptions = getFlattenedOptions(oldOptions),
            kindToIdMap = fetchKindToIdMap(cmpsMap);
        let oldToNewCmpMap = {};

        layoutOptions.forEach((currentOption) => {
            const { bindingId } = currentOption,
                oldOption = oldLayoutOptions.find(oldOption => oldOption.bindingId === bindingId);
            oldToNewCmpMap[bindingId] = (oldLayoutOptions.length) ?
                fetchCmpFromOldOptions(oldOption) :
                kindToIdMap[currentLayout.componentsMap[bindingId].kind];
        });

        return oldToNewCmpMap;
    },
    fetchModernSectionCmps = (componentsMap, newCmps, { layoutId, layout }, sectionName, sectionId) => {
        const sectionCmpsMap = newCmps ? newCmps[sectionName][layoutId].cmpsMap : componentsMap,
            allCmpsIdsInSection = getAllCmpIdsMapInLayout(layout, sectionId),
            allCmpsInSection = Object.values(sectionCmpsMap)
                .filter((cmp: AnyComponent) => allCmpsIdsInSection.hasOwnProperty(cmp.id));

        return allCmpsInSection;
    },
    fetchModernSections = (componentsMap) => {
        const { header = {}, footer = {} } = getHeaderAndFooterSection(componentsMap);
        return [
            { ...header, sectionName: sectionNames.header },
            { ...footer, sectionName: sectionNames.footer }
        ];
    },
    upgradeModernSections = (
        componentsMap: ComponentsMap,
        componentsMapExtension: ComponentsMapExtension,
        layoutsMap: Record<string, any>,
        newCmps?: Record<string, any>
    ) => {
        let updatedCmpsMap = { ...componentsMap };
        const sectionComponents = fetchModernSections(updatedCmpsMap);

        sectionComponents.forEach((cmp: Record<string, any>) => {
            if (cmp) {
                const { sectionName, id: sectionId, modernLayout = {} } = cmp,
                    upgradedLayout = layoutsMap[sectionName][modernLayout.layoutId],
                    oldOptions = path([sectionId, 'modernLayout', 'options'], updatedCmpsMap) || [],
                    allCmpsInSection = fetchModernSectionCmps(updatedCmpsMap, newCmps, modernLayout, sectionName, sectionId),
                    flattendOldOptions = getFlattenedOptions(oldOptions),
                    oldToNewIdMap = getCmpToBindingIdMap(upgradedLayout, allCmpsInSection, flattendOldOptions),
                    sectionProps = path([sectionId, 'modernLayout', 'sectionProps'], updatedCmpsMap) || {};

                updatedCmpsMap = upgradeComponentsFromLayoutAndRearrange(
                    upgradedLayout,
                    sectionId,
                    {
                        componentsMap: updatedCmpsMap,
                        componentsMapExtension
                    },
                    oldToNewIdMap,
                    flattendOldOptions,
                    sectionProps,
                    modernLayout.active
                );
            }
        });

        return updatedCmpsMap;
    },
    getMHFCmpMinWidth = (component, componentExtension) => {
        const minWidth = componentExtension &&
            componentExtension.minDimensions &&
            componentExtension.minDimensions.width;
        if (component.kind === MENU) {
            return minWidth || 400;
        }
        if (component.kind === BUTTON) {
            return minWidth || MCTA_RESIZE_OPTIONS[component.modernLayoutOptions.size].width;
        }
        return minWidth || component.width;
    },
    arrangeCmps = (
        cmpsMap: ComponentsMap,
        layout: ModernLayoutMap,
        layoutId: string,
        componentsExtension: ComponentsMapExtension = {}
    ) => {
        let newCmpsMap = { ...cmpsMap },
            sectionCmpIdsMap = {},
            isSection = isSectionKind(cmpsMap[layoutId].kind),
            // @ts-ignore
            layoutPadding = layout[layoutId].style && layout[layoutId].style.padding,
            firstRowTop = newCmpsMap[layoutId].top +
                (layout[layoutId].vResponsive ? responsiveRowPadding : 0) +
                ((!isSection && layoutPadding) ? layoutPadding.top : 0),
            maxRowWidth = 0,
            minHeight = 0,
            lastRowBottom = firstRowTop,
            rows = layout[layoutId].rows,
            cmpWidthsMap = {},
            cmpHeightsMap = {};
        rows.forEach((row, r) => {
            let extraTop = (row.vResponsive && (!rows[r - 1] || !rows[r - 1].vResponsive) ? responsiveRowPadding : 0) +
                (row.style && row.style.margin ? row.style.margin.top || 0 : 0),
                rowTop = lastRowBottom + extraTop,
                rowBottom = rowTop,
                colRight = Math.max(newCmpsMap[layoutId].left, 0),
                colDimensions = {};

            row.cols.forEach(col => {
                let prevCmp: any = null;
                if (!col.cmps) { return; }
                col.cmps.forEach(({ id: cmpId, style }) => {
                    if (!newCmpsMap[cmpId]) {
                        return;
                    }
                    sectionCmpIdsMap[cmpId] = true;
                    let cmpLeft = colRight,
                        cmpTop = rowTop;
                    if (col.hPositioned) {
                        let marginLeft = (style && style.margin && style.margin.left) || 0;
                        if (prevCmp) {
                            cmpLeft = prevCmp.left + cmpWidthsMap[prevCmp.id] + marginLeft;
                            colRight -= MinGapBtwCols;
                        } else {
                            cmpLeft = colRight;
                        }
                    } else {
                        let marginTop = (style && style.margin && style.margin.top) || 0;
                        if (prevCmp) {
                            cmpTop = prevCmp.top + prevCmp.height + marginTop;
                            cmpLeft = prevCmp.left;
                        } else {
                            cmpTop = rowTop;
                        }
                    }
                    if (!colDimensions[col.hAlign]) {
                        colDimensions[col.hAlign] = {
                            left: cmpLeft
                        };
                    }
                    newCmpsMap[cmpId] = {
                        ...newCmpsMap[cmpId],
                        left: cmpLeft,
                        top: cmpTop
                    };
                    cmpWidthsMap[cmpId] = getMHFCmpMinWidth(newCmpsMap[cmpId], componentsExtension[cmpId]);
                    cmpHeightsMap[cmpId] = newCmpsMap[cmpId].height;
                    if (layout[cmpId]) {
                        let result = arrangeCmps(newCmpsMap, layout, cmpId, componentsExtension);
                        newCmpsMap = result.newCmpsMap;
                        sectionCmpIdsMap = {
                            ...sectionCmpIdsMap,
                            ...result.sectionCmpIdsMap
                        };
                        cmpWidthsMap = {
                            ...cmpWidthsMap,
                            ...result.cmpWidthsMap,
                            [cmpId]: result.minWidth
                        };
                        maxRowWidth = Math.max(result.minWidth, maxRowWidth);
                        minHeight = Math.max(result.minHeight, minHeight);
                        cmpHeightsMap = {
                            ...cmpHeightsMap,
                            ...result.cmpHeightsMap,
                            [cmpId]: result.minHeight
                        };
                    }
                    rowBottom = Math.max(
                        newCmpsMap[cmpId].top + cmpHeightsMap[cmpId],
                        rowBottom
                    );

                    colRight = Math.max(
                        newCmpsMap[cmpId].left + cmpWidthsMap[cmpId],
                        colRight
                    );

                    colDimensions[col.hAlign].right = colRight;

                    colRight += MinGapBtwCols;
                    prevCmp = newCmpsMap[cmpId];
                });
            });
            if (colRight > 0) {
                colRight -= MinGapBtwCols;
                if (Object.keys(colDimensions).length === 3) {
                    colRight += Math.abs(

                        (colDimensions[H_ALIGN.right].right - colDimensions[H_ALIGN.right].left) -

                        (colDimensions[H_ALIGN.left].right - colDimensions[H_ALIGN.left].left)
                    );
                }
            }
            maxRowWidth = Math.max(colRight - Math.max(newCmpsMap[layoutId].left, 0), maxRowWidth);
            const extraBottom = (row.vResponsive && (!rows[r + 1] || !rows[r + 1].vResponsive) ? responsiveRowPadding : 0);
            lastRowBottom = (rowBottom > rowTop) ? (rowBottom + extraBottom) : (rowBottom - extraTop);
        });
        lastRowBottom += (layout[layoutId].vResponsive ? responsiveRowPadding : 0) +
            // @ts-ignore
            ((!isSection && layoutPadding) ? layoutPadding.bottom : 0);
        minHeight = Math.max(lastRowBottom - newCmpsMap[layoutId].top, minHeight);
        newCmpsMap[layoutId] = {
            ...newCmpsMap[layoutId],
            width: Math.max(newCmpsMap[layoutId].width, maxRowWidth),
            height: Math.max(newCmpsMap[layoutId].height, minHeight)
        };
        return {
            newCmpsMap,
            sectionCmpIdsMap,
            minHeight,
            minWidth: isSectionKind(cmpsMap[layoutId].kind) ? addEdgeGapsToWidth(maxRowWidth, RowEdgeGapInPercent) : maxRowWidth,
            cmpWidthsMap,
            cmpHeightsMap
        };
    },
    pushDownOrPullUpCmpsIfHeaderHeightChanges = (
        cmpsMap,
        sectionId,
        oldSectionHeight,
        sectionCmpIdsMap
    ) => {
        const section = cmpsMap[sectionId],
            diff = section.height - oldSectionHeight;
        if (section.top === 0 && diff !== 0) {
            let newCmpsMap = { ...cmpsMap };
            Object.keys(newCmpsMap).forEach(id => {
                const cmp = newCmpsMap[id];
                if (cmp.id !== sectionId && !isGhost(cmp) && !sectionCmpIdsMap[id]) {
                    newCmpsMap[id] = {
                        ...newCmpsMap[id],
                        top: newCmpsMap[id].top + diff
                    };
                }
            });

            return newCmpsMap;
        }
        return cmpsMap;
    },
    arrangeCmpsAsPerLayout = (cmpsMap: ComponentsMap, sectionId: string): ComponentsMap => {
        //This logic places cmps just after one another so that they are not overlapping with previous cmps
        //actual positions will come from ui after responsiveness is triggered by css
        let section = cmpsMap[sectionId];
        if (section && isModernLayoutSection(section)) {
            let { newLayout } = getUpdatedLayoutBasedOnOptions(section),
                { newCmpsMap, sectionCmpIdsMap } = arrangeCmps(cmpsMap, newLayout, sectionId);
            //push down or pull up the cmps below header if header height changes
            newCmpsMap = pushDownOrPullUpCmpsIfHeaderHeightChanges(
                newCmpsMap,
                sectionId,
                section.height,
                sectionCmpIdsMap
            );
            return newCmpsMap;
        }
        return cmpsMap;
    },
    arrangeComponentsAndUpdateSectionHeight = (
        componentsMap: ComponentsMap,
        sectionId: string,
        isForInserter: boolean = false,
    ) => {
        let newComponentsMap = componentsMap,
            sectionCmp = newComponentsMap[sectionId];
        if (isForInserter && !!sectionCmp) {
            newComponentsMap = {
                ...newComponentsMap,
                [sectionId]: { ...sectionCmp, top: 0 }
            };
        }
        newComponentsMap = arrangeCmpsAsPerLayout(newComponentsMap, sectionId);
        newComponentsMap = createRelInIdsAsPerSectionLayout(newComponentsMap, sectionId);

        return newComponentsMap;
    },
    createComponentsFromLayoutAndRearrange = (
        layoutItem: LayoutData,
        section: SectionComponent,
        cmpsState: CmpsState,
        cmpDeps: ComponentDependencies,
        templateWidth: number,
        isForInserter: boolean = false,
    ): NewCmpsWithIDs => {
        let updatedLayoutItem = layoutItem,
            updatedSection = section;

        const { id: sectionId } = updatedSection,
            componentsMapExtension = cmpsState.componentsMapExtension,
            componentsMap = {
                ...cmpsState.componentsMap,
                // [sectionId]: updatedSection
            },
            {
                layout: oldLayoutMap,
                componentsMap: cmpsToDuplicateMap,
                id: layoutId,
                version: layoutVersion,
                options
            } = updatedLayoutItem,
            componentsToCreate = getComponentIds(options, cmpsToDuplicateMap),
            {
                oldToNewIdMap,
                componentsMap: duplicatedCmpsMap,
                componentsMapExtension: duplicatedCmpsMapExtension
            } = createNewComponents(componentsToCreate, componentsMap, cmpDeps, NoAdjustComponents, isForInserter),
            layoutMap = replaceComponentIdsInLayout(oldLayoutMap, oldToNewIdMap, sectionId),
            optionsMap = replaceComponentIdsInOptions(options, oldToNewIdMap);
        let newComponentsMap = { ...componentsMap, ...duplicatedCmpsMap };
        // @ts-ignore it may be a bug
        newComponentsMap = updateLayoutInfoInSection({
            cmpId: sectionId,
            active: true,
            layoutId,
            layout: layoutMap,
            minWidth: minMHFSectionWidth,
            componentsMap: newComponentsMap,
            options: optionsMap,
            layoutVersion
        });
        const { cmpsMap } = getUpdatedLayoutBasedOnOptions(newComponentsMap[sectionId], newComponentsMap);
        newComponentsMap = arrangeComponentsAndUpdateSectionHeight(
            cmpsMap,
            updatedSection.id,
            isForInserter
        );

        const newComponentsIds = Object.keys(duplicatedCmpsMap);
        if (isForInserter) {
            newComponentsIds.push(sectionId);
            newComponentsMap = getCmpsMapWithUpdatedSectionColor(newComponentsMap, sectionId, 1);
        }
        return {
            componentsMap: newComponentsMap,
            componentsMapExtension: { ...componentsMapExtension, ...duplicatedCmpsMapExtension },
            newComponentsIds,
            oldToNewIdMap,
            newComponentsMap: newComponentsIds.reduce((acc, id) => { acc[id] = newComponentsMap[id]; return acc; }, {})
        };
    },
    updateModernSectionHeightAndCenterChildComponents = (
        { oldHeight, newHeight, sectionId }: { oldHeight: number, newHeight: number, sectionId: string, },
        componentsMap: ComponentsMap
    ): MapT<{ height?: number, top?: number }> => {
        const section = componentsMap[sectionId];
        let cmpChanges = {};

        if (section && !isModernLayoutSection(section)) {
            return cmpChanges;
        }

        // $FlowFixMe: checked for section in above statements
        const cmpsInside = getAllComponentIdsInModernSection(section, componentsMap),
            // $FlowFixMe: checked for modern section in above statements
            { modernLayout: { layout, minDimensions = {} } } = section,
            minSectionHeight = minDimensions.height || MIN_SECTION_HEIGHT,
            finalSectionHeight = newHeight < minSectionHeight ? minSectionHeight : newHeight,
            diff = Math.round((finalSectionHeight - oldHeight)),
            vResponsiveIdsMap = getVResponsiveCmpIdsMapInLayout(sectionId, layout);

        if (finalSectionHeight !== newHeight) {
            cmpChanges[sectionId] = { height: finalSectionHeight };
        }
        if (diff === 0) { return cmpChanges; }

        cmpsInside.forEach(id => {
            let diffToApply = diff;
            const cmp = componentsMap[id];
            if (vResponsiveIdsMap[id]) {
                diffToApply = Math.ceil(diff / 2);
            }
            cmpChanges[id] = { top: cmp.top + diffToApply };
        });
        return cmpChanges;
    },
    validateMHFBeforeSave = (componentsMap: ComponentsMap): Record<string, any>[] => {
        const uuidRegex = /([a-f0-9]{8}-[a-f0-9]{4}-4[a-f0-9]{3}-[89ab][a-f0-9]{3}-[a-f0-9]{12})/i;
        const { header, footer } = getHeaderAndFooterSection(componentsMap),
            isModernHeader = !!header && isModernLayoutSection(header),
            isModernFooter = !!footer && isModernLayoutSection(footer);
        let errors: any[] = [];
        if (!isModernHeader && !isModernFooter) {
            return [{ isValid: true, isStopSave: false }];
        }
        if (isModernHeader !== isModernFooter) {
            errors.push({
                isValid: false,
                isStopSave: true,
                data: {
                    reason: invalidMHFStateReasons.MODERN_LAYOUT_ACTIVE_NOT_IN_SYNC
                }
            });
            return errors;
        }
        [header, footer].forEach(({ id }) => {
            const section: ModernSectionComponent = componentsMap[id],
                sectionChildrenIds = getAllComponentIdsInModernSection(section, componentsMap),
                // @ts-ignore
                { modernLayout: { options } = {} } = section,
                flattendOptions = getFlattenedOptions(options);
            // checks if section components are in template
            sectionChildrenIds.some(id => {
                const cmp = componentsMap[id];
                if (cmp && !cmp.inTemplate) {
                    errors.push({
                        isValid: false,
                        isStopSave: true,
                        data: {
                            reason: invalidMHFStateReasons.MODERN_LAYOUT_COMPONENT_NOT_IN_TEMPLATE,
                            componentId: id
                        }
                    });
                    return true;
                }
                return false;
            });
            flattendOptions.some(option => {
                const { id, show = false } = option;
                if (uuidRegex.test(id) && !componentsMap[id] && show) {
                    errors.push({
                        isValid: false,
                        isStopSave: true,
                        data: {
                            reason: invalidMHFStateReasons.MODERN_LAYOUT_COMPONENT_IS_ON_AND_NOT_AVAILABLE,
                            componentId: id
                        }
                    });
                    return true;
                }
                return false;
            });
        });
        return errors;
    };

export {
    getTemplateSectionByName,
    replaceComponentIdsInOptions,
    setSectionLayoutMinDimension,
    arrangeComponentsAndUpdateSectionHeight,
    getPropsForSidebarDesktopPreview,
    createComponentsFromLayoutAndRearrange,
    tryToPlaceComponentInToNonModernSection,
    calcNewHeightOfContainerBasedOnChildren,
    isModernHeaderOrFooter,
    someComponentsPlacedInModernSection,
    isBoxInModernHeaderOrFooter,
    isCmpsInsideModernHeaderOrFooter,
    getCmpsMapWithUpdatedSectionColor,
    getModernLayoutYourItemHeight,
    getCmpsMapForLayout,
    isComponentPlacedInHeader,
    getIsModernHeaderFooterNeverActivated,
    getIsBoxBelowModernFooter,
    disableMhfComponentToggle,
    getComponentIds,
    createNewComponents,
    upgradeComponentsFromLayoutAndRearrange,
    createNewComponentsInSection,
    isAddWebshopMHFCmps,
    isVerticallyResponsiveModernSection,
    upgradeModernSections,
    arrangeCmpsAsPerLayout,
    arrangeCmps,
    updateModernSectionHeightAndCenterChildComponents,
    validateMHFBeforeSave,
};
