import { Dispatch } from "redux";
import {
    SET_INSTAGRAM_ACCESS_CREDENTIALS_ACTION,
    GET_INSTAGRAM_USER_MEDIA_FAILURE_ACTION,
    GET_INSTAGRAM_ACCESS_TOKEN_SUCCESS_ACTION,
    DISCONNECT_INSTAGRAM_SUCCESS_ACTION,
    OPEN_CONNECT_INSTAGRAM_ACCOUNT_ACTION,
    getInstagramUserMediaAction,
    setInstagramAccessCredentials,
    unSetInstagramAccessToken, GET_INSTAGRAM_ACCESS_TOKEN_FAILURE_ACTION,
} from "./actions";
import type { AppStore } from "../../../redux/modules/flowTypes";
import { addInfoMessage } from "../../Toaster/actionCreators";
import { TOKEN_EXPIRED_MESSAGE } from "../../../../../server/shared/instagram/constants.js";
import InstagramDisconnectedDialogId from './instagramDisconnectedDialog/instagramDisconnectedDialogId';
import { openDialog } from "../../App/actionCreators/index";
import { createScheduledAction } from "../../../redux/middleware/schedule/actionCreators";
import { INSTAGRAM_ACCOUNT_DOMAIN_NAME } from "../../../../../server/shared/instagram/instagramCookie.js";
import { getDAL } from "../../../../dal/index";
import { PopupWindowHeight, PopupWindowWidth } from "../../oneweb/InstagramGallery/constants/configurationDialog";
import { navigateToPopupLocationAction } from "../../../redux/middleware/window/actionCreators";
import { InstagramUrl } from "../../../../../server/shared/instagram/InstagramUrl.js";
import { isWsbDemo } from "../../../debug/isWsbDemo";
import urlBuild from "../../../../utils/urlBuild";
import { setCookie } from "../../../services/cookie";
import { INSTAGRAM_GALLERY_CHECK_CONNECTED_COOKIE_VALUE } from "../../oneweb/InstagramGallery/actionTypes";
import { totalInstagramGalleryComponentsSelector } from "../selectors";

const makeInstagramConnectURL = () => {
    return urlBuild(InstagramUrl.ROOT + InstagramUrl.AUTH_CONNECT, {
        query: {
            isTrial: isWsbDemo()
        }
    });
};

export const socialAccountsInstagramMiddleware = (store: AppStore) => (next: Dispatch) => (action: Action) => {
    if (action.type === SET_INSTAGRAM_ACCESS_CREDENTIALS_ACTION) {
        store.dispatch(getInstagramUserMediaAction(action.payload.instagramAccessToken));
    }

    if (action.type === GET_INSTAGRAM_USER_MEDIA_FAILURE_ACTION) {
        store.dispatch(unSetInstagramAccessToken());
    }

    if (action.type === GET_INSTAGRAM_ACCESS_TOKEN_SUCCESS_ACTION) {
        store.dispatch(setInstagramAccessCredentials(action.payload.accessToken, action.payload.igUsername));
    }

    if (action.type === DISCONNECT_INSTAGRAM_SUCCESS_ACTION) {
        store.dispatch(addInfoMessage(
            "cookie.banner.footer.tipText",
            "msg: cookie.banner.footer.tipText {Remember to publish your site to make these changes live.}"
        ));
    }

    if (action.type === GET_INSTAGRAM_ACCESS_TOKEN_FAILURE_ACTION) {
        const totalInstagramGalleryComponents = totalInstagramGalleryComponentsSelector(store.getState());
        if (
            action.payload
            && action.payload.message
            && action.payload.message === TOKEN_EXPIRED_MESSAGE
            && totalInstagramGalleryComponents > 0
        ) {
            // Dispatch the dialog a bit later to let the workspace settle in and show the dialog after a delay.
            store.dispatch(
                createScheduledAction({
                    actionToDispatch: openDialog(InstagramDisconnectedDialogId),
                    timeout: 2 * 1000 // Time in milliseconds
                })
            );
        }
    }

    if (action.type === OPEN_CONNECT_INSTAGRAM_ACCOUNT_ACTION) {
        setCookie(INSTAGRAM_ACCOUNT_DOMAIN_NAME, getDAL().getDomain(), { domain: document.location.hostname }, true);
        const leftPosition = window.innerWidth ? (window.innerWidth - PopupWindowWidth) / 2 : 0;
        const topPosition = window.innerHeight ? (window.innerHeight - PopupWindowHeight) / 2 : 0;
        // eslint-disable-next-line max-len
        const windowSettings = `height=${PopupWindowHeight},width=${PopupWindowHeight},top=${topPosition},left=${leftPosition},scrollbars=yes,resizable`;
        store.dispatch(navigateToPopupLocationAction(
            makeInstagramConnectURL(),
            'Connect Instagram',
            windowSettings,
        ));
        store.dispatch({ type: INSTAGRAM_GALLERY_CHECK_CONNECTED_COOKIE_VALUE });
    }

    return next(action);
};
