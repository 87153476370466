import oneColor from "onecolor";
import view from "./view/preview";
import kind, { FeaturedProductsComponentKind } from "./kind";
import type { FeaturedProductsComponent } from "./flowTypes";
import type { CalcProps, CmpSpecificStyles } from "../../Preview/flowTypes";
import * as stylesheetSelectors from "../../Workspace/epics/stylesheets/selectors";
import fontConfigMappers from '../../../mappers/block/font';
import './view/mobile.css';
import { getWidgetId, getBackgroundColor } from './utils';
import {
    getColorsForWebshopPublishScript,
    getViewdetailsButtonStyleForPreview,
    getFirstButtonClassname,
    getWebshopLinkStyleForPreview,
    getWebshopLinkClassname,
    getViewdetailsButtonClassnames,
    getWebshopInitScriptUrl,
    getDiscountDetailsStyle,
} from "../WebShop/utils";
import { themeaccent } from "../../App/epics/tinyMceEpic/editorUtils/constants";
import { BUTTON_THEME_PRIMARY, BUTTON_THEME_SECONDARY } from "../../ThemeGlobalData/constants";
import type { DataSite } from '../../../../dal/model/index';
import type { SiteSettings } from '../../App/epics/siteSettings/flowTypes';
import type { GenerateScriptsProps } from '../flowTypes';

const doesShopComponentExistOnVisiblePage = (siteSettings: SiteSettings, siteMap: DataSite): boolean => {
    let result = false;
    const pageIds = siteMap.getAllPageRefs().filter((pageRef) => !pageRef.hidden).map((pageRef) => pageRef.pageId);
    const webshopPageIds = Array.isArray(siteSettings.webshopPageIds) ? siteSettings.webshopPageIds : [];
    if (webshopPageIds.length > 0) {
        result = webshopPageIds.some((shopPageId) => pageIds.indexOf(shopPageId) !== -1);
    }
    return result;
};

export default {
    view,
    kind,
    calcProps: (
        calcPropsValue: CalcProps<FeaturedProductsComponent>
    ) => {
        const {
            globalStyles,
            isServerPreview,
            locale,
            componentsDependencies,
            component,
            template,
            templateSelectorPreviewMode,
            domain,
            selectedParentTheme,
            siteSettings,
            siteMap
        } = calcPropsValue;

        const
            { themeSettingsData } = siteSettings,
            { autoColorMode } = themeSettingsData,
            linkClassName = getWebshopLinkClassname(globalStyles),
            linkStyle = getWebshopLinkStyleForPreview({
                autoColorMode,
                globalStyles,
                selectedParentTheme
            });

        const buttonClassname = getFirstButtonClassname(autoColorMode, globalStyles, selectedParentTheme);
        const backgroundColor = getBackgroundColor(selectedParentTheme, globalStyles);

        if (isServerPreview) {
            const viewDetailsButtonClassname = getViewdetailsButtonClassnames(
                autoColorMode,
                component.viewDetailsButtonId,
                globalStyles,
                selectedParentTheme,
                component.viewDetailsButtonThemeSelected || BUTTON_THEME_PRIMARY
            );
            const buyNowButtonClassName = getViewdetailsButtonClassnames(
                autoColorMode,
                component.buyNowButtonId,
                globalStyles,
                selectedParentTheme,
                component.buyNowButtonThemeSelected || BUTTON_THEME_SECONDARY
            );
            return {
                isServerPreview,
                locale,
                buttonClassname,
                viewDetailsButtonClassname,
                // Only for webshop in publish page. Parent theme will be added in webshop root.
                linkClassName: autoColorMode ? `${linkClassName} ${themeaccent}` : linkClassName,
                domain,
                templateSelectorPreviewMode,
                component,
                shopComponentExists: doesShopComponentExistOnVisiblePage(siteSettings, siteMap),
                themeSettingsData,
                backgroundColor,
                buyNowButtonClassName,
            };
        }

        const featuredProductsComponentDependencies = componentsDependencies && componentsDependencies.FEATURED_PRODUCTS;
        const { buttonClassname: viewDetailsButtonClassname, buttonStyle: viewDetailsButtonStyle } =
            getViewdetailsButtonStyleForPreview(
                autoColorMode,
                component.viewDetailsButtonId,
                globalStyles,
                selectedParentTheme,
                component.viewDetailsButtonThemeSelected || BUTTON_THEME_PRIMARY
            );
        const { buttonClassname: buyNowButtonClassName, buttonStyle: buyNowButtonStyle } =
            getViewdetailsButtonStyleForPreview(
                autoColorMode,
                component.buyNowButtonId,
                globalStyles,
                selectedParentTheme,
                component.buyNowButtonThemeSelected || BUTTON_THEME_PRIMARY
            );
        const currentLocale = featuredProductsComponentDependencies ?
            featuredProductsComponentDependencies.localeDetails.current : template.locale;

        return {
            linkClassName,
            linkStyle,
            buttonClassname,
            viewDetailsButtonClassname,
            viewDetailsButtonStyle,
            siteFonts: featuredProductsComponentDependencies ? featuredProductsComponentDependencies.siteFonts : [],
            locale: currentLocale,
            component,
            mobilePreview: featuredProductsComponentDependencies ? featuredProductsComponentDependencies.mobilePreview : false,
            shopComponentExists: doesShopComponentExistOnVisiblePage(siteSettings, siteMap),
            selectedParentTheme,
            themeSettingsData,
            globalStyles,
            backgroundColor,
            buyNowButtonClassName,
            buyNowButtonStyle,
        };
    },
    generateScripts: ({ domain, config }: GenerateScriptsProps) => ([
        `${getWebshopInitScriptUrl(domain, FeaturedProductsComponentKind, config)}`,
    ]),
    getSpecificStyles: (
        cmpSpecificStyles: CmpSpecificStyles<FeaturedProductsComponent>
    ): null | undefined | string => {
        const {
            component,
            globalStyles,
            autoColorMode,
            selectedParentTheme
        } = cmpSpecificStyles;

        const widgetId = getWidgetId(component.id);
        const {
            fontColor,
            focusColor,
            labelBgColor,
            labelTextColor,
            promoRibbonBgColor,
            promoRibbonTextColor,
            hoverColor
        } = getColorsForWebshopPublishScript({
            autoColorMode,
            globalStyles,
            selectedParentTheme,
            component
        });

        const
            globalTextStyle = stylesheetSelectors.textNormalGlobalstyle(globalStyles),
            fontFamily = fontConfigMappers.family((component.font || globalTextStyle.font).toString()),
            lightFontColor = oneColor(fontColor).alpha(0.5),
            lighterFontColor = oneColor(fontColor).alpha(0.2),
            lightFocusColor = oneColor(focusColor).alpha(0.5),
            lightFocusBgColor = oneColor(focusColor).alpha(0.1),
            lightFocusContrastColor = oneColor(oneColor(lightFocusColor).lightness() > 0.5 ? '#000000' : '#ffffff'),
            fontColorCssa = oneColor(fontColor).cssa(),
            discountDetailsStyle = getDiscountDetailsStyle(autoColorMode, globalStyles, selectedParentTheme);

        return [
            `#${widgetId} {`,
            `    font-family: ${fontFamily};`,
            `}`,
            `#${widgetId},`,
            `#${widgetId} a.link,`,
            `#${widgetId} a.link:link,`,
            `#${widgetId} a.link:visited,`,
            `#${widgetId} a.link:hover,`,
            `#${widgetId} a.link:active,`,
            `#${widgetId} input,`,
            `#${widgetId} select,`,
            `#${widgetId} .text-color {`,
            `    color: ${fontColorCssa};`,
            `}`,
            `#${widgetId} .text-color svg {`,
            `    fill: ${fontColorCssa};`,
            `}`,
            `#${widgetId} .bg-light-focus:hover {`,
            `    color: ${oneColor(lightFocusContrastColor).cssa()} !important;`,
            `    background-color: ${oneColor(lightFocusColor).cssa()} !important`,
            `}`,
            `#${widgetId} .border-fcolor,`,
            `#${widgetId} .border-light-color {`,
            `    border-color: ${oneColor(fontColor).cssa()} !important;`,
            `}`,
            `#${widgetId} .border-light-font-color {`,
            `    border-color: ${oneColor(lightFontColor).cssa()} !important;`,
            `}`,
            `#${widgetId} .focus,`,
            `#${widgetId} .mobile-slider a {`,
            `    color: ${oneColor(focusColor).cssa()} !important;`,
            `}`,
            `#${widgetId} .focus svg {`,
            `    fill: ${oneColor(focusColor).cssa()} !important;`,
            `}`,
            `#${widgetId} .bg-extra-light-focus {`,
            `    border-color: ${oneColor(lighterFontColor).cssa()} !important;`,
            `}`,
            `#${widgetId} .bg-extra-light-focus:hover {`,
            `    background-color: ${oneColor(lightFocusBgColor).cssa()} !important;`,
            `}`,
            `#${widgetId} .overlay-container:hover .overlay {`,
            `    background-color: ${oneColor(hoverColor).cssa()} !important;`,
            `}`,
            `#${widgetId} .product-label {`,
            `    background-color: ${oneColor(labelBgColor).cssa()} !important;`,
            `    color: ${oneColor(labelTextColor).cssa()} !important;`,
            `}`,

            `#${widgetId} .discount-ribbon-label {`,
            `    background-color: ${oneColor(promoRibbonBgColor).cssa()} !important;`,
            `    color: ${oneColor(promoRibbonTextColor).cssa()} !important;`,
            `}`,

            `#${widgetId} ${discountDetailsStyle}`,
        ].join("\n");
    },
    getSpecificScript: (
        scriptParam: Record<string, any>
    ): string => {
        let result = '';
        const { autoColorMode, component, globalStyles, selectedParentTheme, isServerPreview } = scriptParam;
        const { id, products } = component;
        const { buttonStyle: buyNowButtonStyle } =
            getViewdetailsButtonStyleForPreview(
                autoColorMode,
                component.buyNowButtonId,
                globalStyles,
                selectedParentTheme,
                component.buyNowButtonThemeSelected || BUTTON_THEME_PRIMARY
            );
        if (!isServerPreview) {
            result = `(function () {
                    oneJQuery(document).ready(function() {
                        window.addEventListener("message", function(event) {
                            if (event.data && event.data.type) {
                                if (event.data.type === "SHOW_PRODUCT_DETAILS") {
                                    var product = event.data.data;
                                    window.top.$R.store.dispatch({type: 'FEATURED_PRODUCTS_SHOW_PRODUCT_PREVIEW',
                                        payload: { product: product }});
                                }
                                if (event.data.type === "SHOW_PRODUCT_POPUP") {
                                    var eventData = event.data.data;
                                    if (eventData) {
                                        var product = eventData.product;
                                        var widgetParams = eventData.widgetParams;
                                        if (one && one.application && one.application.appModal) {
                                            one.application.appModal({
                                                component: 'product',
                                                params: {
                                                  featuredProductWidgetParams: widgetParams,
                                                  product: product,
                                                  productPosition: { top: window.scrollY, left: window.scrollX }
                                                }
                                              });
                                        }
                                    }
                                }
                                if(event.data.type === "UPDATE_FEATURED_PRODUCTS_HEIGHT") {
                                    var featuredProductsDiv = oneJQuery('div[data-id="${id}"][data-specific-kind="FEATURED_PRODUCTS"]');
                                    if (!featuredProductsDiv.length) {
                                        featuredProductsDiv = oneJQuery('div[data-id="${id}"][data-specific-kind="PRODUCT_WIDGET"]');
                                    }
                                    var featuredProductsIframe = featuredProductsDiv.find("iframe");
                                    var eventData = event.data.data;
                                    var widgetId = eventData.widgetId;
                                    var widgetDiv = featuredProductsIframe.contents().find('#'+widgetId);
                                    if (widgetDiv && widgetDiv.length === 1) {
                                        var widgetHeight = eventData.widgetHeight;
                                        featuredProductsDiv.css("min-height", widgetHeight);
                                        featuredProductsIframe.attr("height", widgetHeight);
                                    }
                                }

                                if(event.data.type === "FETCH_PRODUCT_IDS_FROM_CMP") {
                                    var eventData = event.data.data;
                                    if (eventData) {
                                        var widgetId = eventData.widgetId;

                                        var featuredProductsDiv = oneJQuery('div[data-id="${id}"][data-specific-kind="FEATURED_PRODUCTS"]');
                                        if (!featuredProductsDiv.length) {
                                            featuredProductsDiv = oneJQuery('div[data-id="${id}"][data-specific-kind="PRODUCT_WIDGET"]');
                                        }
                                        var iframeElements = featuredProductsDiv.find("iframe");
                                        if (iframeElements && iframeElements.length > 0) {
                                            var iframeComponent = iframeElements[0];
                                            if (iframeComponent
                                                && iframeComponent.contentWindow
                                                && typeof iframeComponent.contentWindow.postMessage === 'function') {
                                                iframeComponent.contentWindow.postMessage(
                                                    {
                                                        type: 'RECEIVE_PRODUCT_IDS_FROM_CMP',
                                                        data: { widgetId: widgetId, productIds: "${products}" }
                                                    },
                                                    new URL(iframeComponent.src).origin
                                                );
                                            }
                                        }

                                    }
                                }

                                if(event.data.type === "FETCH_BUY_NOW_BUTTON_STYLES_FROM_CMP") {
                                    var eventData = event.data.data;
                                    console.log('Event: ', event);
                                    if (eventData) {
                                        var widgetId = eventData.widgetId;

                                        var featuredProductsDiv = oneJQuery('div[data-id="${id}"][data-specific-kind="FEATURED_PRODUCTS"]');
                                        if (!featuredProductsDiv.length) {
                                            featuredProductsDiv = oneJQuery('div[data-id="${id}"][data-specific-kind="PRODUCT_WIDGET"]');
                                        }
                                        var iframeElements = featuredProductsDiv.find("iframe");
                                        if (iframeElements && iframeElements.length > 0) {
                                            var iframeComponent = iframeElements[0];
                                            if (iframeComponent
                                                && iframeComponent.contentWindow
                                                && typeof iframeComponent.contentWindow.postMessage === 'function') {
                                                iframeComponent.contentWindow.postMessage(
                                                    {
                                                        type: 'RECEIVE_BUY_NOW_BUTTON_STYLES_FROM_CMP',
                                                        data: { widgetId: widgetId,
                                                            buyNowButtonStyle: '${buyNowButtonStyle.replace(/\n/g, "")}' }
                                                    },
                                                    new URL(iframeComponent.src).origin
                                                );
                                            }
                                        }

                                    }
                                }

                            }
                        }, false);
                    });
                })();`;
        }

        return result;
    }
};
