import AppConfig from "./utils/AppConfig";
import { getAppConfig } from "./components/App/epics/appConfig/appConfig.js";

export const initTrackingAnalytics = () => {
    const appConfig = AppConfig(getAppConfig());

    try {
        window.hotjarTrackingId = appConfig.get('oneWeb.frontEnd.hotjar.trackingId');
    } catch (e) {
        // do nothing
    }

    try {
        window.gtmTrackingId = appConfig.get('oneWeb.frontEnd.partnerConfig.gtmTrackingId');
        window.gtmTrackingServer = appConfig.get('oneWeb.frontEnd.partnerConfig.gtmTrackingServer');
    } catch (e) {
        // do nothing
    }
};
