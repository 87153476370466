import fetch from '../src/services/fetch';
import type { DalRequest, DalRequestSaveMethod } from './flowTypes';
import { getCache } from './dalCache';
import { log } from './networkLog';
import { isWsbDemo } from '../src/debug/isWsbDemo';

const logHelper = (json) => {
    let extraInfo = '';
    json.update.forEach(function (item) {
        extraInfo += ' - ';
        if (item.type) {
            extraInfo += item.type + ':';
        }
        if (item.id) {
            extraInfo += item.id + ':';
        }
        if (item.etag) {
            extraInfo += item.etag;
        }
    });
    return extraInfo;
};

export const makeRequest = (config: DalRequest) => {
    const
        { url, options, isStream } = config,
        dataversionnumber = window.DATAVERSIONNUMBER || '',
        finalOptions = {
            ...(options || {}),
            credentials: "same-origin"
        };
    if (config.noHeaders) {
        // do nothing
    } else {
        finalOptions.headers = { ...(options.headers || {}), dataversionnumber };
    }

    let extraInfo = '';
    // LAZY: This "url.indexOf()" check is not perfect, but it is good enough for all practical purposes
    if (url.indexOf('/doc/batch') !== -1) {
        if (options && options.body) {
            try {
                const json = JSON.parse(options.body);
                try {
                    if (Array.isArray(json.update)) {
                        extraInfo += logHelper(json);
                    } else {
                        // Ideally, the code should never reach here
                        extraInfo = 'batch-request-unexpected-data';
                    }
                } catch (e: any) {
                    // Ideally, the code should never reach here
                    extraInfo = 'batch-request-could-not-log-debug-info-correctly';
                }
            } catch (e: any) {
                // Ideally, the code should never reach here
                extraInfo = 'batch-request-with-invalid-json-body';
            }
        }
    }
    log(`${url}${extraInfo} ${config.options && config.options.method} request`);
    if (isStream) {
        return fetch(url, { ...finalOptions, useWindowFetch: isWsbDemo() });
    }
    return fetch(url, finalOptions)
        .then(
            (response: Response) => {
                log(`${url} ${response.status} response`);

                return response.json()
                    .then(body => {
                        if (response.status === 200 && Array.isArray(body.update)) {
                            log(`${url}${logHelper(body)}`);
                        }
                        return ({ response, body });
                    })
                    .catch((e: Error) => {
                        // TODO: WBTGEN-1455
                        // check for case when server returns empty body for some requests (e.g. DELETE, PUT)
                        // assume any error in this catch is about JSON parse error
                        // (otherwise we would need to maintain all possible error messages from browsers)
                        //console.log('inside catch', response.ok, options.emptyResponse);
                        if (response.ok && options.emptyResponse) {
                            return {
                                response: new Response(null, { status: 200, statusText: 'Success' }),
                                body: { ok: true }
                            };
                        }
                        //console.log('inside catch - before throwing error', e);
                        throw e;
                    });
            }
        );
};

export const makeCachedRequest = (cacheKey: string, requestConfig: DalRequest, forceFetch: boolean = false): Promise<any> => {
    const data = getCache().get(cacheKey);
    if (!forceFetch && data) return Promise.resolve(data);

    return makeRequest(requestConfig).then(responseSet => {
        getCache().set(cacheKey, responseSet);
        return responseSet;
    });
};

export const makeSaveRequest = (url: string, data: Record<string, any>, method: DalRequestSaveMethod = 'PUT') => makeRequest({
    url,
    options: {
        method,
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    }
});

export const makeDeleteRequest = (url: string, emptyResponse: boolean = true) => makeRequest({
    url,
    options: {
        method: 'DELETE',
        emptyResponse
    }
});
