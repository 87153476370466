import { arrayMove } from "react-sortable-hoc";
import splitData, { getCurrentData } from "../splitData/utils";
import { boxTop, cmpTypes } from "../../constants";
import calcStyles from "../../calcStyles";
import mergeData from "./mergeData";
import tagPageOrTemplateToRelations from "./tagPageOrTemplateToRelations";
import { calcDisableArrows } from "../hoveringAndSelection/index";
import { MOBILE_EDITOR_COMPONENT_MOVED,
    MOBILE_EDITOR_COMPONENT_UPDATE_ARROWS_AFTER_MOVE,
    MOBILE_EDITOR_UPDATED_SCROLL_TOP } from "../../actionTypes";
import getMdStartFromId from "../getMdStartFromId";
import type { ArrowsDisabledState, ReorderCmpsProps } from "../../flowTypes";
import scrollTopWhenUsingArrow from './scrollTopWhenUsingArrows';
import isStretchComponentKind from "../../../oneweb/isStretchComponentKind";
import getCmpTypeById from "../../getCmpTypeById";

const
    adjustRelations = (relations, children, movedCmpId, componentsMap, groups) => {
        let newRelations = [...relations, { id: movedCmpId }];
        children.forEach((id, idx) => {
            const cmpRelations = newRelations.filter((relation) => relation.id === id);
            if (cmpRelations.length) {
                newRelations = newRelations.filter(relation => relation.id !== id);
                let relation: any = {};
                if (idx === 0) {
                    relation.top = boxTop;
                } else {
                    relation.top = children[idx - 1];
                }

                if (idx < (children.length - 1)) {
                    relation.bottom = children[idx + 1];
                }
                newRelations.push({ id, ...relation });
            }
        });
        return tagPageOrTemplateToRelations(newRelations, componentsMap, groups);
    };

const getPrevOrNextIndexOfCmp = (cmpId, data, sequence, cmpMap, up) => {
    const index = sequence.indexOf(cmpId);
    const currentIndex = up ? index - 1 : index + 1;
    if (currentIndex > -1 && currentIndex < sequence.length) {
        if (getCmpTypeById(sequence[currentIndex]) === cmpTypes.component) {
            const { id, kind } = cmpMap[sequence[currentIndex]];
            if (isStretchComponentKind(kind) && !data[id]) {
                return getPrevOrNextIndexOfCmp(id, data, sequence, cmpMap, up);
            }
        }
        return currentIndex;
    }
    return up ? 0 : sequence.length - 1;
};

export default ({
    componentsMap,
    selectedCmpId,
    currentScrollTop,
    cmpHeights,
    state,
    scope,
    up,
    down,
    children: moveDataChildren = []
}: ReorderCmpsProps) => {
    const {
        root,
        data,
        wrappedCmpsMap,
        relationsForView,
        mdStartFromId,
        styles: oldStyles,
        groupsForView: groups
    } = state;
    let children: any = null,
        { data: newData, mobileDownData } = splitData({ data, wrappedCmpsMap, mdStartFromId, templateId: root }),
        { currentData, isMdData, parentId } = getCurrentData({ data: newData, mobileDownData, selectedCmpId });
    if (up || down) {
        children = currentData[parentId];
        const index = children.indexOf(selectedCmpId);
        const nextIndex = getPrevOrNextIndexOfCmp(selectedCmpId, data, children, componentsMap, up);
        children = arrayMove(children, index, nextIndex);
    } else {
        children = moveDataChildren.slice();
    }
    const
        latestRelations = adjustRelations(relationsForView, children, selectedCmpId, componentsMap, groups),
        newMdStartFromId = isMdData ? getMdStartFromId({
            topLevelChildren: children,
            groups,
            componentsMap,
            mdStartFromId
        }) : mdStartFromId;
    currentData = {
        ...currentData,
        [parentId]: [...children]
    };
    let latestData: any = null;
    if (isMdData) {
        latestData = mergeData({ data: newData, mobileDownData: currentData, templateId: root });
    } else {
        latestData = mergeData({ data: currentData, mobileDownData, templateId: root });
    }
    let cmpStyles = calcStyles(root, latestData, componentsMap, groups),
        actionsToDispatch: Action[] = [];
    const payload: ArrowsDisabledState = calcDisableArrows(children, selectedCmpId, currentData, componentsMap);
    actionsToDispatch.push(
        {
            type: MOBILE_EDITOR_COMPONENT_UPDATE_ARROWS_AFTER_MOVE,
            payload
        }
    );

    let newScrollTopValue = currentScrollTop;
    if (currentScrollTop !== -1) {
        newScrollTopValue = scrollTopWhenUsingArrow({ root,
            currentScrollTop,
            cmpHeights,
            oldData: data,
            oldStyles,
            latestData,
            cmpStyles,
            selectedCmpId,
            down });
        actionsToDispatch.push({
            type: MOBILE_EDITOR_UPDATED_SCROLL_TOP,
            payload: { newScrollTopValue, updateScrollTop: true }
        });
    }

    return {
        state: {
            ...state,
            data: latestData,
            relations: latestRelations,
            relationsForView: latestRelations,
            styles: cmpStyles,
            mdStartFromId: newMdStartFromId
        },
        multipleActionsToDispatch: actionsToDispatch,
        scope,
        updateReason: MOBILE_EDITOR_COMPONENT_MOVED
    };
};
