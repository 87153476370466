import ReactDOMServer from "react-dom/server"; // eslint-disable-line n/file-extension-in-import

import React from "react";
import { DataSite } from "../../../../dal/model/index";
import { DATA_PAGE_REF_TYPE } from "../../../../dal/model/utils/dataSiteItemTypes";
import type { MobileHeaderState, MenuState } from "../../Mobile/header/types";
import { MobileHeader } from "../../Mobile/header/MobileHeader";
import type { MobileStyle } from "../../Mobile/style/types";
import getBrowserScrollbarWidth from "../../../redux/modules/getBrowserScrollbarWidth";
import { SiteSettings } from '../../App/epics/siteSettings/flowTypes';

export type GetMobileHtmlProps = {
    siteData: DataSite;
    currentPageId: string;
    header: MobileHeaderState;
    mobileStyle?: MobileStyle;
    menuState: MenuState;
    hideMenu?: boolean;
    viewerDomain?: string;
    globalVariables: Record<string, any>;
    siteSettings: SiteSettings
};

const shouldRender = ({ menuItems, homeMenuItem }: MenuState): boolean => {
    // Fix for ONEWEB-8757
    const items = menuItems;

    if (items.length === 0) {
        return false;
    }

    const firstItem = items[0];

    if (items.length === 1 && firstItem.type === DATA_PAGE_REF_TYPE && firstItem.children.length === 0) {
        if (homeMenuItem && homeMenuItem.hidden) {
            return true;
        }

        return false;
    }

    return true;
};

function getMobileHtml({
    siteData,
    currentPageId,
    header: headerState,
    mobileStyle,
    menuState,
    hideMenu,
    viewerDomain,
    globalVariables,
    siteSettings
}: GetMobileHtmlProps) {
    const pageName = siteData.getPageRefByPageId(currentPageId).name,
        scrollBarWidth = getBrowserScrollbarWidth();
    return `${ReactDOMServer.renderToString(
        <MobileHeader
            state={headerState}
            // @ts-ignore
            mobileStyle={mobileStyle}
            pageName={pageName}
            menuState={menuState}
            hideMenu={hideMenu}
            viewerDomain={viewerDomain}
            goHomeOnLogoClick
            site={siteData}
            globalVariables={globalVariables}
            scrollBarWidth={scrollBarWidth}
            siteSettings={siteSettings}
        />
    )}\n`;
}

export { getMobileHtml, shouldRender };
