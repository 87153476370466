import m from 'memoizee';
import bodyStyles from './FileChooserBody/FileChooserBody.css';
import listStyles from './FileChooserList/FileChooserList.css';
import getStyleIntValue from "../../../utils/getStyleIntValue";
import Resource, { getShortName } from "../../../redux/modules/children/fileChooser/Resource";
import {
    FC_NAVIGATE_SUB_DIRECTORY,
    FC_DELETE_RESOURCE,
} from "../../../redux/modules/children/fileChooser/actionTypes";
import Url from "../../../../utils/Url";
import urlBuild from "../../../../utils/urlBuild";
import * as pathUtils from "../../../../utils/path.js";
import fcToggleResourceSelection from "../../../redux/modules/children/fileChooser/actionCreators/fcToggleResourceSelection";
import { decodeWebspaceUri } from "../../../../dal/utils/webspaceUriTransformers";
import {
    testFcContentType,
    testFcImageContentType
} from "../../../redux/modules/children/fileChooser/utils/testFcContentType";
import { FcContentTypes } from "../../../redux/modules/children/fileChooser/FcContentTypes";
import { parseIntDec } from '../../../../utils/number';

// TODO: generic url builder ?
// TODO: replace hardcoded paths for icons
const getDefaultResourceImagePath = (resource: Resource): string => {
    if (testFcContentType(resource.getContentType(), FcContentTypes.HTML)) {
        return '/wbtgen/images/html.ico.svg';
    }
    return '/wbtgen/images/doc.ico.svg';
};

const getResourceImageIconPath = (resource: Resource): string => {
    if (testFcImageContentType(resource.getContentType())) {
        return resource.getImageIconPath();
    }
    return getDefaultResourceImagePath(resource);
};

const getListColumnWidths = m((tableWidth: number) => {
    const widthMap: any = {};

    // build width map
    Object.keys(listStyles).forEach(k => {
        if (k.indexOf('col') !== 0) return;

        const
            name = k.replace(/col|Width/g, '').toLowerCase(),
            val = typeof listStyles[k] === 'string' && listStyles[k].indexOf('%') > -1
                ? Math.floor((parseIntDec(listStyles[k]) / 100) * tableWidth)
                : parseIntDec(listStyles[k]);

        widthMap[name] = val;
    });

    return widthMap;
});

const objSum = function (obj: Record<string, number>) {
    return Object.values(obj).reduce((pv: number, v: number) => pv + v);
};

const getListTableWidth = (showPreview: boolean): number => {
    let width = objSum(getListColumnWidths());
    if (!showPreview) {
        width += getStyleIntValue(bodyStyles, 'previewWidth');
    }
    return width;
};

const isResourceSelected = (resource: Resource, selection: Array<Resource>): boolean => {
    return selection.map((r: Resource) => r.id).indexOf(resource.id) !== -1;
};

const cellCommonMapDispatchToProps = (dispatch: Dispatch) => ({
    deleteResource: (resource: Resource) => {
        dispatch({ type: FC_DELETE_RESOURCE, payload: { resource } });
    },
});

const directoryCellMapDispatchToProps = (dispatch: Dispatch) => ({
    ...cellCommonMapDispatchToProps(dispatch),
    navigateSubDirectory: (resource: Resource) => {
        dispatch({ type: FC_NAVIGATE_SUB_DIRECTORY, payload: { subDirectory: resource.href } });
    },
});

const resourceCellMapDispatchToProps = (dispatch: Dispatch) => ({
    ...cellCommonMapDispatchToProps(dispatch),
    toggleResourceSelection: (resource: Resource) => {
        dispatch(fcToggleResourceSelection(resource));
    }
});

const getFcTitleAndLabel = (name: string, length: number) => {
    const
        encoded = decodeWebspaceUri(name),
        label = getShortName(encoded, length),
        title = (encoded !== label) ? encoded : null;

    return { label, title };
};

type FileManagerUrlParams = {
    currentPath: string;
    loginUrl: string;
    loginTarget: string;
    targetDomain: string;
    mailId: null | undefined | string;
};

const buildFileManagerUrl = (params: FileManagerUrlParams): string => {
    const
        { currentPath, loginUrl: loginUrlString, loginTarget, targetDomain, mailId } = params,
        loginUrl = Url(loginUrlString),
        scheme = loginUrl.protocol,
        host = loginUrl.host,
        query: Record<string, any> = { loginTarget, targetDomain },
        webSpacePath = ':targetDomain/:targetDomain/files/:currentPath',
        trimmedCurrentPath = pathUtils.trim(currentPath),
        webSpacePathParams = {
            targetDomain,
            currentPath: trimmedCurrentPath ? trimmedCurrentPath + '/' : '',
        },
        hash = { [urlBuild(webSpacePath, { pathParams: webSpacePathParams, absolutePath: false })]: null };
    if (mailId) query.username = mailId;

    return urlBuild(loginUrl.path, { scheme, host, query, hash });
};

export {
    getDefaultResourceImagePath,
    getResourceImageIconPath,
    getListColumnWidths,
    getListTableWidth,
    isResourceSelected,
    directoryCellMapDispatchToProps,
    resourceCellMapDispatchToProps,
    buildFileManagerUrl,
    getFcTitleAndLabel,
    cellCommonMapDispatchToProps,
};
