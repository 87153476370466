import propertiesPanel from "./propertiesPanel";
import { ComponentNames } from "../constants";
import { componentMainActions } from "./componentMainActions";
import { BOOKINGS_MIN_HEIGHT, BOOKINGS_MIN_WIDTH } from "./constants";
import { BookingsCalcRenderProps } from "./flowTypes";
import selectedLanguageEpic from "../../TopBar/epics/languages/index";
import BookingsKind from "./kind";
import reducer from "./reducer";
import view from "./view/index";
import workspace from "./view/workspace";
import siteFontsReducer from "../../Fonts/siteFontsReducer";
import { colorThemeSiteSettingsEpic } from "../../SiteSettings/ColorThemeData/colorThemeSiteSettingsEpic";
import makeStateSelectorReducer from "../../../epics/makeStateSelectorReducer";
import stylesheetsEpic from "../../Workspace/epics/stylesheets";
import { getThemeColorsFromStylesheet } from "../../Workspace/epics/stylesheets/selectors";
import stylesheetsIdToNameMapEpic from "../../Workspace/epics/stylesheets/idToNameMap";
import subsciptionEpic from "../../App/epics/subscriptionData";
import { handleActions } from "../../../redux/makeReducer";
import { BOOKINGS_CLOSE_NOTE } from "./actionTypes";
import { isEcommerceSubscription, isPremiumSubscription } from "../../App/epics/subscriptionData/utils";
import { getBookingParams } from "./utils";

export default {
    kind: BookingsKind,
    label: ComponentNames[BookingsKind],
    shortcutIconName: "bookings",
    propertiesPanel,

    // user can't make the component smaller than these dimensions
    minDimensions: {
        width: BOOKINGS_MIN_WIDTH,
        height: BOOKINGS_MIN_HEIGHT
    },

    calcRenderProps: (props: BookingsCalcRenderProps) => {
        const locale = props.componentDependencies.localeDetails.current;
        const {
            component,
            selectedParentTheme,
            componentDependencies: { subscriptionData: { subscriptionType }, stylesheetsIdToNameMap }
        } = props;
        const bookingParams = getBookingParams({ component, selectedParentTheme, stylesheetsIdToNameMap });
        const showTryForFree =
            !isPremiumSubscription(subscriptionType) && !isEcommerceSubscription(subscriptionType);

        return {
            ...props.componentDependencies,
            locale,
            component,
            selectedParentTheme,
            showTryForFree,
            bookingParams
        };
    },

    // config for default parameters (e.g. default dimensions)
    reducer,

    dependsOn: {
        localeDetails: selectedLanguageEpic.reducer,
        themeColorsData: makeStateSelectorReducer(stylesheetsEpic.reducer, stylesheetsEpic.valueActionType, getThemeColorsFromStylesheet),
        themeSettingsData: colorThemeSiteSettingsEpic.reducer,
        stylesheetsIdToNameMap: stylesheetsIdToNameMapEpic.reducer,
        siteFonts: siteFontsReducer,
        subscriptionData: subsciptionEpic.reducer,
        noteClosed: handleActions({
            [BOOKINGS_CLOSE_NOTE]: () => true
        }, false),
    },

    // config for what the component should render
    view,
    workspace,

    // mobile has the same view for now
    mobileEditorConfig: {
        kind: BookingsKind,
        view
    },

    // config for the settings buttons of the component
    componentMainActions,
};
