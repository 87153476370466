import React from "react";
import * as Icon from './Icons.css';
import { ComponentNames } from "../../oneweb/constants";
import { BlogViews as Content } from "../../oneweb/Blog/BlogComponent/expandedPanel/expandedPanelContent";
import type { ExtendedPanelContentProps } from "../view/inserter";
import BlogKind from "../../oneweb/Blog/BlogComponent/kind";

export default {
    kind: BlogKind,
    shortCut: {
        iconClassName: Icon.blog,
        text: ComponentNames[BlogKind]
    },
    extendedPanelData: {
        title: 'msg: component.blog.label {Blog}',
        content: (props: ExtendedPanelContentProps) => <Content {...props} />
    }
};
