const { ConfigComponentList } = require("../constants.js");

class BlogConfig {
    isBlogEnabled() {
        const blog = this._featuresConfig[ConfigComponentList.BLOG];
        return blog && blog.enabled;
    }
}

module.exports = BlogConfig;
