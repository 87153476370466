// if it is image and it is wrapped then mobile down is not applicable
import Image from "../../oneweb/Image/kind";
import type { GetMdStartFromIdProps, Groups } from "../flowTypes";
import type { ComponentsMap } from "../../../redux/modules/children/workspace/flowTypes";
import { hasChildren } from "../applyMobileDown";
import { cmpTypes } from "../constants";
import getCmpTypeById from "../getCmpTypeById";

export const getTopLevelMobileDownCmps = (
    componentsMap: ComponentsMap,
    groups: Groups,
    topLevelChildren: Array<string> = []
): Array<string> => {
    return topLevelChildren.filter(tId => {
        const cmpType = getCmpTypeById(tId);
        if (cmpType === cmpTypes.copy) {
            return true;
        }
        if (cmpType === cmpTypes.group) {
            return groups[tId] &&
                groups[tId].some(cId =>
                    componentsMap[cId] && componentsMap[cId].inTemplate && componentsMap[cId].mobileDown);
        }
        // if it is image and any of its parents is wrapped, then mobile down is not applicable
        // if it is image and if it has children then mobile down is not applicable
        const { id, kind, inTemplate, mobileDown } = componentsMap[tId];
        return (inTemplate && (!id || (mobileDown && (kind !== Image || (!hasChildren(componentsMap, id))))));
    });
};

export default ({
    topLevelChildren,
    groups,
    componentsMap,
    mdStartFromId
}: GetMdStartFromIdProps) => {
    const mobileDownCmps = getTopLevelMobileDownCmps(
        componentsMap,
        groups,
        topLevelChildren
    );
    return mobileDownCmps.length ? mobileDownCmps[0] : mdStartFromId;
};
