import React from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { GOOGLE_RECPATCHA_VALIDATE } from "../actionTypes";
import type { InvisibleGoogleReCaptchaProps, GoogleReCaptchaProps } from "../flowTypes";

class GoogleReCaptchaView extends React.Component<GoogleReCaptchaProps> {
    constructor(props: GoogleReCaptchaProps) {
        super(props);

        /* Set locale for google recaptcha */
        window.recaptchaOptions = {
            lang: props.locale,
            useRecaptchaNet: true,
            removeOnUnmount: false,
        };
    }

    render() {
        const { sitekey, dispatch } = this.props;

        return (
            <ReCAPTCHA
                sitekey={sitekey}
                onChange={response => dispatch({ type: GOOGLE_RECPATCHA_VALIDATE, payload: response })}
                theme="light"
                type="image"
                size="normal"
            />
        );
    }
}

class GoogleInvisibleReCaptchaView extends React.Component<InvisibleGoogleReCaptchaProps> {
    constructor(props: InvisibleGoogleReCaptchaProps) {
        super(props);
        this.onChangeCb = this.onChangeCb.bind(this);
    }
    onChangeCb = (response: string) => {
        const { onChangeFunction, additionalProps } = this.props;
        onChangeFunction({ response, ...additionalProps });
    };
    render() {
        const { sitekey, recaptchaRef, currentLanguage } = this.props;

        return (
            <ReCAPTCHA
                hl={currentLanguage}
                ref={recaptchaRef}
                size="invisible"
                sitekey={sitekey}
                onChange={this.onChangeCb}
            />
        );
    }
}

export {
    GoogleReCaptchaView,
    GoogleInvisibleReCaptchaView
};
