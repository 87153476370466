import type { NewPageDialogDependencies, NewPageDialogState, NewPageDialogCalcRenderProps } from "../flowTypes";
import linkPageFormCalcRenderProps from "../../linkPageForm/view/linkPageFormCalcRenderProps";
import sectionLinkFormCalcRenderProps from "../../sectionLinkForm/view/sectionLinkFormCalcRenderProps";
import { NewPageTab } from "../constants";
import * as ActionTypes from '../actions';

type Input = {
    dependencies: NewPageDialogDependencies;
    state: NewPageDialogState;
};

const linkPageActions = {
    setLinkAction: ActionTypes.NEW_PAGE_LINK_SET_LINK_TO_FILE_OR_PAGE,
    unsetLinkAction: ActionTypes.NEW_PAGE_LINK_UNSET_LINK_TO_FILE_OR_PAGE,
    urlOnChangeAction: ActionTypes.NEW_PAGE_LINK_URL_CHANGE,
    nameOnChangeAction: ActionTypes.NEW_PAGE_LINK_NAME_CHANGE,
    openInNewWindowToggleAction: ActionTypes.NEW_PAGE_LINK_OPEN_IN_NEW_WINDOW_TOGGLE,
    isHiddenToggleAction: ActionTypes.NEW_PAGE_LINK_IS_HIDDEN_TOGGLE
};

const sectionLinkActions = {
    nameOnChangeAction: ActionTypes.NEW_SECTION_LINK_NAME_CHANGE,
    pageOnChangeAction: ActionTypes.NEW_SECTION_LINK_PAGE_CHANGE,
    sectionOnChangeAction: ActionTypes.NEW_SECTION_LINK_SECTION_CHANGE,
};

export default ({ state, dependencies }: Input): NewPageDialogCalcRenderProps => {
    const
        { site, generalData, subscriptionData, sectionComponents, currentPageId, componentsMap } = dependencies,
        {
            isLayoutLoading,
            [NewPageTab.LAYOUT_PAGE]: layoutPageState,
            [NewPageTab.LINK_PAGE]: linkPageState,
            [NewPageTab.SECTION_LINK]: sectionLinkState,
            activeTab
        } = state,
        linkPage = linkPageFormCalcRenderProps({
            dependencies: { site },
            state: linkPageState,
            actions: linkPageActions
        }),
        sectionLink = sectionLinkFormCalcRenderProps({
            dependencies: { site, sectionComponents, currentPageId },
            state: sectionLinkState,
            actions: sectionLinkActions
        });

    let isReadyToSave;
    const gmbCategories = (generalData.gmbCategories || generalData.websiteCategories || "").split(",");
    const recommendedLayouts: { id: string, label: string, items: any[] } = {
        id: '0',
        label: 'msg: common.recommended {Recommended}',
        items: []
    };
    const items: any[] = [];
    if (layoutPageState.repositorySiteData) {
        const layouts = layoutPageState.repositorySiteData.pageLayouts;

        layouts.forEach(pageLayout => {
            if (
                pageLayout.gmbCategories &&
                pageLayout.gmbCategories.length &&
                pageLayout.gmbCategories.some(category => gmbCategories.includes(category))
            ) {
                items.push(pageLayout);
            }
        });
        if (items.length) {
            // Atleast one layout is matching the gmb category only then generic layouts are considred
            const blankLayout = layouts.find(pageLayout => pageLayout.category === "BLANK_PAGE");
            if (items.length < 4) {
                items.push(...layouts.filter(pageLayout => pageLayout.category === "GENERIC"));
            }
            recommendedLayouts.items = [
                {
                    ...blankLayout,
                    category: "RECOMMENDED",
                    isBlankLayout: true
                },
                ...items.slice(0, 4)
            ];
        }
    }

    if (activeTab === NewPageTab.LAYOUT_PAGE) {
        isReadyToSave = !!(layoutPageState.selectedLayout && layoutPageState.selectedTemplate);
    } else if (activeTab === NewPageTab.LINK_PAGE) {
        isReadyToSave = linkPage.isFormValid;
    } else {
        isReadyToSave = sectionLink.isFormValid;
    }
    return {
        activeTab,
        [NewPageTab.LAYOUT_PAGE]: {
            page: {
                ...layoutPageState,
                recommendedLayouts,
                isLoading: isLayoutLoading
            },
            footer: {
                template: layoutPageState.selectedTemplate
            }
        },
        [NewPageTab.LINK_PAGE]: linkPage,
        [NewPageTab.SECTION_LINK]: sectionLink,
        isReadyToSave,
        subscriptionData,
        site,
        componentsMap
    };
};
