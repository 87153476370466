
import type { AppStore } from "../../../redux/modules/flowTypes";

import { unsetCookie, getCookie } from "../../../services/cookie";
import {
    INSTAGRAM_ACCESS_TOKEN_COOKIE_NAME, INSTAGRAM_ACCOUNT_DOMAIN_NAME,
    INSTAGRAM_CONNECTED_COOKIE_NAME
} from "../../../../../server/shared/instagram/instagramCookie.js";
import {
    INSTAGRAM_GALLERY_CHECK_CONNECTED_COOKIE_VALUE,
    INSTAGRAM_GALLERY_PP_DISCONNECT_BTN_PRESSED
} from "./actionTypes";

import { componentConfigurationCompleteAction } from "../../../redux/modules/children/workspace/actionCreators";
import { setInstagramAccessCredentials, disconnectInstagramAction } from "../../SocialAccounts/instagram/actions";
import { setInstagramAccessCredentialsInDemoAction } from "../../SocialAccounts/instagram/trialActions";
import { createScheduledAction } from "../../../redux/middleware/schedule/actionCreators";
import { MAX_COOKIE_CHECK_COUNT } from "./constants/index";
import { isWsbDemo } from "../../../debug/isWsbDemo";

let COOKIE_CHECKED_COUNTER = 0;

const cleanupInstagramCookies = () => {
    // Clean up cookies so that the new connect request doesn't get confused.
    unsetCookie(INSTAGRAM_CONNECTED_COOKIE_NAME, { domain: '' }, true);
    unsetCookie(INSTAGRAM_ACCESS_TOKEN_COOKIE_NAME, { domain: '' }, true);
    unsetCookie(INSTAGRAM_ACCOUNT_DOMAIN_NAME, { domain: '' }, true);
};

export const instagramGalleryMiddleware = (store: AppStore) => (next: Dispatch) => (action: Action) => {
    if (action.type === INSTAGRAM_GALLERY_CHECK_CONNECTED_COOKIE_VALUE) {
        const cookie = getCookie(INSTAGRAM_CONNECTED_COOKIE_NAME);
        const accessTokenData = getCookie(INSTAGRAM_ACCESS_TOKEN_COOKIE_NAME);
        if (cookie && cookie === 'true' && accessTokenData) {
            const accessTokenResponse = JSON.parse(accessTokenData);
            cleanupInstagramCookies();
            store.dispatch(
                setInstagramAccessCredentials(
                    accessTokenResponse.access_token,
                    accessTokenResponse.igUsername
                )
            );

            if (isWsbDemo()) {
                store.dispatch(
                    setInstagramAccessCredentialsInDemoAction(
                        accessTokenResponse.access_token,
                        accessTokenResponse.igUsername,
                        accessTokenResponse.expires_in,
                    )
                );
            }
        } else if (cookie && cookie === 'false') {
            cleanupInstagramCookies();
            store.dispatch(componentConfigurationCompleteAction());
        } else if (COOKIE_CHECKED_COUNTER < MAX_COOKIE_CHECK_COUNT) {
            COOKIE_CHECKED_COUNTER += 1;
            store.dispatch(createScheduledAction({
                actionToDispatch: { type: INSTAGRAM_GALLERY_CHECK_CONNECTED_COOKIE_VALUE },
                timeout: 1000, // Refresh every 1 seconds.
            }));
        }
    }

    if (action.type === INSTAGRAM_GALLERY_PP_DISCONNECT_BTN_PRESSED) {
        store.dispatch(disconnectInstagramAction());
    }

    return next(action);
};
