export const BLOG_MIN_WIDTH = 680;
export const BLOG_MIN_HEIGHT = 100;

export const BLOG_DEFAULT_WIDTH = 1050;
export const BLOG_DEFAULT_HEIGHT = 250;

// dialog height will be reduced by that amount compared to the screen height
export const BLOG_DIALOG_HEIGHT_OFFSET = 40;

export const MANAGE_BLOG_TOOLTIP_MSG =
    "msg: component.blog.cta.premiumTooltip {Blog is a Premium feature - upgrade now to start writing your own posts.}";
