import React from "react";
import { useSelector } from "react-redux";
import { ListItemIcon } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import cx from "classnames";

import { useIsMobileOrTablet } from "../../../hooks/useIsMobileOrTablet";
import { isOnlineShopAllowedForTier } from "./utils";
import { isFlagMatch } from "../../../utils/configUtils";

import imageStyles from "../../Images/imageStyle.css";
import { subscriptionTypeSelector, selectIsSIATCustomer } from "../../../common/selectors";

const useStyles = makeStyles(
    theme => ({
        itemIcon: {
            minWidth: "16px"
        },
        upgradeIcon: {
            [theme.breakpoints.down("sm")]: {
                width: "32px",
                height: "32px"
            }
        }
    }),
    { name: "RightSideIcon" }
);

export const RightSideIcon = () => {
    const classes = useStyles();

    const subscriptionType = useSelector(subscriptionTypeSelector);
    const isSiatAllowedFromShopApi = useSelector(selectIsSIATCustomer);
    const shopAllowed = isOnlineShopAllowedForTier(subscriptionType, isSiatAllowedFromShopApi);
    const isMobileOrTablet = useIsMobileOrTablet();

    if (!(isSiatAllowedFromShopApi === null || shopAllowed)) {
        return (
            <ListItemIcon className={classes.itemIcon} data-testid="online-shop-sidebar-item-right-icon">
                <div
                    className={cx(imageStyles.upgradeIcon, classes.upgradeIcon)}
                    data-testid="online-shop-sidebar-item-upgrade-icon"
                />
            </ListItemIcon>
        );
    }

    if (isFlagMatch("oneWeb.webshop.features.enableWsbRedirect", false) && isMobileOrTablet) {
        return (
            <ListItemIcon className={classes.itemIcon} data-testid="online-shop-sidebar-item-right-icon">
                <div className={imageStyles.redirectIcon} />
            </ListItemIcon>
        );
    }

    return null;
};
