import { REQUIRED_FIELDS } from "../../AIText/Dialogs/EditAIText/EditingAssistant/constants";
import { tinyMceDomParser } from "../../editorSetup";
import { getDAL } from '../../../../../../dal';

const openAiSlackErrorType = 'openAi';
const tagList = ['ol', 'ul', 'li', 'a'];

export const validateRequiredFields = (state) => {
    let errorFields: string[] = [];
    Object.keys(REQUIRED_FIELDS).forEach(key => {
        const name = REQUIRED_FIELDS[key];
        if (!state[name]) {
            errorFields.push(name);
        }
    });
    return errorFields;
};

export const updateErrorFields = (errorFields, name, value) => {
    if (errorFields.includes(name)) {
        if (REQUIRED_FIELDS[name] && value) {
            let index = errorFields.indexOf(name);
            if (index !== -1) {
                errorFields.splice(index, 1);
                return errorFields;
            }
        }
    }
    return errorFields;
};

export const getPlainContent = (content) => {
    const parser = new window.DOMParser();
    const doc = parser.parseFromString(content, 'text/html');
    const contentBody = doc.querySelector('body');
    return contentBody?.textContent?.trim() || '';
};

export const removeEmptyTags = (content) => {
    let updatedContent = '';
    const parser = new window.DOMParser();
    const doc = parser.parseFromString(content, 'text/html');
    const elements = doc.querySelector('body')?.childNodes;
    if (elements) {
        const filteredElements = Array.from(elements).filter(el => el.textContent?.trim().length);

        const serializer = new window.XMLSerializer();
        for (const filteredElement of filteredElements) {
            if (filteredElement.nodeName === '#text') {
                updatedContent += `<span>${filteredElement.nodeValue}</span>`;
            } else {
                const text = serializer.serializeToString(filteredElement).replace(/ xmlns="[^"]+"/, '');
                updatedContent += text.trim();
            }
        }
    }

    return updatedContent;
};

export const transformOnInsert = (content, colorMap = {}) => {
    let updatedContent = content;

    const classMap = {
        h1: 'textheading1',
        h2: 'textheading2',
        h3: 'textheading3'
    };

    const openTagRegex = /<(h[1-6]*|p)>/g;
    let match;
    // eslint-disable-next-line no-cond-assign
    while ((match = openTagRegex.exec(updatedContent)) !== null) {
        const tag = match[1];

        const r = new RegExp(`<${tag}>`, 'g');
        let replaceContent = '<p';
        if (tag in classMap) {
            replaceContent += ` class="${classMap[tag]}"`;
        }
        if (tag in colorMap && colorMap[tag]) {
            replaceContent += ` style="color: ${colorMap[tag]};"`;
        }
        replaceContent += '>';

        updatedContent = updatedContent.replace(r, replaceContent);
    }

    const closeTagRegex = /<\/(h[1-6]*)>/g;
    // eslint-disable-next-line no-cond-assign
    while ((match = closeTagRegex.exec(updatedContent)) !== null) {
        const tag = match[1];

        const r = new RegExp(`</${tag}>`, 'g');
        updatedContent = updatedContent.replace(r, `</p>`);
    }

    // adding class to `a tags`
    const aTagRegex = /<a\b([^>]*)>(.*?)<\/a>/g;
    // eslint-disable-next-line no-cond-assign
    while ((match = aTagRegex.exec(updatedContent)) !== null) {
        updatedContent = updatedContent.replace(match[0], `<a${match[1]} class="link1" target="_blank">${match[2]}</a>`);
    }

    return updatedContent;
};

export const simplifyContent = (content) => {
    const transformNode = tinyMceDomParser.parse(removeEmptyTags(content));

    let currentHref = '';
    let currentStyleMap = {
        color: null,
        text: ''
    };
    let colorMap = {};
    const textTypeList = ['h1', 'h2', 'h3', 'p'];
    const simplifyContentMap = {
        textheading1: 'h1',
        textheading2: 'h2',
        textheading3: 'h3',
        textnormal: 'p'
    };
    const isExternalLink = (link) => {
        return link.startsWith('http');
    };

    const hrefHandler = (text, tag) => {
        if (currentHref) {
            return `<${tag} href="${currentHref}">${text}</${tag}>`;
        } else {
            return `<${tag}>${text}</${tag}>`;
        }
    };

    const updateCurrentText = (text, tag) => {
        if (!text) return '';
        if (tag === 'a') {
            return hrefHandler(text, tag);
        } else if (tag) {
            return `<${tag}>${text}</${tag}>`;
        } else {
            return text;
        }
    };

    const getColour = (text) => {
        if (!text) {
            return null;
        }
        const regex = /(?<!-)\bcolor\s*:\s*([^;]+);/g;
        let match;
        // eslint-disable-next-line no-cond-assign
        if ((match = regex.exec(text)) !== null) {
            return match[1];
        }

        return null;
    };

    const isEnclosedInHorPTag = (text) => {
        return text.match(/^<([hp][1-6]*)>(.*?)<\/\1>/g);
    };

    const isEnclosedInTag = (text) => {
        return text.match(/^<([a-z]+[1-6]*)>(.*?)<\/\1>/g);
    };

    const processNode = (node, first = false, parentNodeTag = null) => {
        let currentNodeTag;
        if (!node) {
            return '';
        }

        if (node.name === '#text') {
            return node.value + processNode(node.next, first, parentNodeTag);
        } else if (tagList.includes(node.name)) {
            if (node.name === 'a') {
                const attributesMap = node.attributes?.map;
                currentHref = '';
                if (attributesMap?.href && isExternalLink(attributesMap.href)) {
                    currentHref = attributesMap.href;
                }
            }
            currentNodeTag = node.name;
        } else {
            const attributesMapClass = node.attributes?.map?.class;
            if (attributesMapClass) {
                const className = attributesMapClass.split().find(classEl => classEl in simplifyContentMap);
                currentNodeTag = simplifyContentMap[className];
            } else if (first && node.name === simplifyContentMap.textnormal) {
                currentNodeTag = simplifyContentMap.textnormal;
            }
        }

        let childNode = node.firstChild;
        let childText = processNode(childNode, false, currentNodeTag);

        if (first && isEnclosedInHorPTag(childText)) {
            currentNodeTag = null;
        } else if (first && !isEnclosedInTag(childText)) {
            currentNodeTag = currentNodeTag || simplifyContentMap.textnormal;
        }

        if (currentNodeTag && textTypeList.includes(currentNodeTag) && !isEnclosedInHorPTag(childText)) {
            childText = childText.replace(/<([hp][1-6]*)>|<\/([hp][1-6]*)>/g, '');
        }

        if (!currentStyleMap.color && currentStyleMap.text !== childText) {
            const nodeStyle = node.attributes?.map?.style;
            currentStyleMap = {
                color: getColour(nodeStyle),
                text: childText
            };
        }

        let tag = parentNodeTag || currentNodeTag;
        if (first && tag && !(tag in colorMap)) {
            colorMap[tag] = currentStyleMap.color;
            currentStyleMap = {
                color: null,
                text: ''
            };
        }

        let nextNode = node.next;
        const finalText = updateCurrentText(childText, currentNodeTag) + processNode(nextNode, first);
        return finalText;
    };

    let childNode = transformNode.firstChild;
    const simplifiedContent = processNode(childNode, true);

    return { simplifiedContent, colorMap };
};

export const removeUnwantedTags = (text: string) => {
    let tempDiv = document.createElement('div');
    tempDiv.innerHTML = text;

    const validTags = new Set(['h1', 'h2', 'h3', 'div', 'p', 'span', 'ol', 'ul', 'li', 'a']);

    tempDiv.querySelectorAll('*').forEach(tag => {
        if (!validTags.has(tag.localName) || !tag.textContent) {
            let parent = tag.parentElement;
            tag.remove();

            while (parent && !parent.textContent) {
                const newParent = parent?.parentElement;
                parent.remove();
                parent = newParent;
            }
        }
    });

    return tempDiv.innerHTML;
};

export const sanitizeDisplayHtml = (text: string) => {
    if (!text) return '';
    // replace unfinished tags
    const cleanedText = text.replace(/<(\/|(\/\w*))?$/g, '');
    // process clean text to make valid html
    const div = document.createElement('div');
    div.innerHTML = cleanedText;
    const updatedText = removeUnwantedTags(div.innerHTML);
    return updatedText;
};

export const createSlackAlert = (content, e) => {
    const sendSlackAlert = (getDAL() || {}).sendSlackAlert;
    if (sendSlackAlert) {
        sendSlackAlert({
            type: openAiSlackErrorType,
            message: "Open AI parsing Error\n\nParsing failed for the content: \n```" + content + "```\n\n" + e
        });
    }
};
