import BACKGROUND from "./Background/kind";
import HOVERBOX from "./HoverBox/kind";
import BUTTON from "./Button/kind";
import CODE from "./Code/kind";
import CONTACT_FORM from "./ContactForm/kind";
import DOCUMENT from "./Document/kind";
import FACEBOOK from "./Facebook/kind";
import GALLERY from "./Gallery/kind";
import IMAGE from "./Image/kind";
import IMAGE_SLIDER from "./ImageSlider/kind";
import MENU from "./Menu/kind";
import SHARE_BUTTONS from "./ShareButtons/kind";
import STRIP from "./Strip/kind";
import SECTION from "./Section/kind";
import TABLE from "./Table/kind";
import TEXT from "./Text/kind";
import VIDEO from "./Video/kind";
import VIDEO_FILE from "./Video/VideoFile/kind";
import WEB_SHOP from "./WebShop/kind";
import WEBSHOP_POLICIES from "./WebShopMHF/WebShopPolicies/kind";
import WEBSHOP_PAYMENT_METHODS from "./WebShopMHF/WebShopPaymentMethods/kind";
import GOOGLE_REVIEWS from './GoogleReviews/kind';
import { SOCIAL_KIND } from './Social/kind';
import { LOGO_KIND } from './Logo/kind';
import { EMAIL_KIND } from './TextLike/Email/kind';
import { PHONE_KIND } from './TextLike/Phone/kind';
import { ADDRESS_KIND } from './TextLike/Address/kind';
import { SVG_KIND } from "./Svg/kind";
import FeaturedProductsComponentKind from "./FeaturedProducts/kind";
import { OPENING_HOURS_KIND } from './OpeningHours/kind';
import ProductWidgetKind from './ProductWidget/kind';
import FACEBOOK_CHAT from './FacebookChat/kind';
import BOOKINGS from "./Bookings/kind";
import BLOG from "./Blog/BlogComponent/kind";
import POST from "./Blog/Post/kind";

const BackgroundTypes = [
    BACKGROUND,
    HOVERBOX,
];

const contactComponentsMap = {
    [PHONE_KIND]: true,
    [ADDRESS_KIND]: true,
    [EMAIL_KIND]: true,
};

const isContactComponentKind = (kind: string) => {
    return !!contactComponentsMap[kind];
};

const isBoxKind = (kind: string) => BackgroundTypes.includes(kind);

const isSectionKind = (kind: string) => kind === SECTION,
    isStripKind = (kind: string) => kind === STRIP,
    isDynamicHeightKind = (kind: string) => kind === SECTION || kind === FeaturedProductsComponentKind,
    isHoverBoxKind = (kind: string) => kind === HOVERBOX;

export {
    isSectionKind,
    isStripKind,
    isHoverBoxKind,
    isBoxKind,
    isDynamicHeightKind,
    isContactComponentKind,

    STRIP,
    SECTION,
    BACKGROUND,
    HOVERBOX,
    BUTTON,
    CODE,
    CONTACT_FORM,
    GALLERY,
    FACEBOOK,
    DOCUMENT,
    SHARE_BUTTONS,
    MENU,
    IMAGE_SLIDER,
    IMAGE,
    WEB_SHOP,
    WEBSHOP_POLICIES,
    WEBSHOP_PAYMENT_METHODS,
    VIDEO,
    VIDEO_FILE,
    TEXT,
    TABLE,
    BOOKINGS,
    BLOG,
    POST,

    GOOGLE_REVIEWS,
    SOCIAL_KIND,
    ADDRESS_KIND,
    PHONE_KIND,
    EMAIL_KIND,
    LOGO_KIND,
    SVG_KIND,
    OPENING_HOURS_KIND,
    FeaturedProductsComponentKind,
    ProductWidgetKind,
    FACEBOOK_CHAT
};
