// @ts-nocheck
export default (styleName, mapper) => (val, styles = {}) => {
    if (styleName === 'font-size' && val === null) {
        // Sometimes size is null instead of undefined
        return '';
    }

    if (typeof val !== 'undefined' && val !== null) {
        if (mapper) {
            return `${styleName}: ${mapper(val, styles)}`;
        } else {
            return `${styleName}: ${val}`;
        }
    } else {
        return '';
    }
};
