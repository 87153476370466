import * as R from "ramda";
import { $Keys } from "utility-types";
import {
    calcA,
    getMediaAssetsToBeShown,
    getCaptionFontStyle,
    makeCaptionStyle,
    makeCaptionStylePadding,
    extractEssentialDataFromFbPost,
    getCaptionBoxBorderColorForTheme
} from './utils';
import type {
    FacebookFeedGalleryComponent,
    FacebookFeedGalleryCalcRenderPropsResult,
    FacebookFeedGalleryComponentDependsOn,
    FacebookFeedGalleryImageRenderProps,
    GalleryComponentExtension,
} from './flowTypes';
import type { CalcRenderer } from '../../Workspace/epics/componentsProps/flowTypes';
import {
    IMAGE_RATIO_NUM_MAP,
    MinThumbnailSize,
    FACEBOOK_FEED_VIDEO,
    captionDefaults,
    CAPTION_CONTAINER_HEIGHT_MAP,
    EXPANDED_PROFILE_IMAGE_HOLDER_SIZE
} from "./constants/index";
import { makeComponentBorderStyle } from "../../../mappers/border/index";
import { IMAGE_RATIO } from "../../presentational/ImageRatioComboBox/constants";
import { trunc1 } from "../../../../utils/number";
import type { FacebookPostAsset } from "../../SocialAccounts/Facebook/FacebookFeed/types";
import { findSuitableTextColorName } from "../../ThemeGlobalData/utils/commonUtils";
import { getThemeRulesForBackground } from "../../ThemeGlobalData/themeRules";
import { replaceTagsWithContent } from "../Text/view/replaceTagsWithContent";
import { CaptionStyles } from "../../presentational/CaptionStyle/constants";
import makeStyle from "../Background/makeStyle";
import { BACKGROUND_THEME_WHITE } from '../../ThemeGlobalData/constants';
import { getAssetUrl } from '../../../utils/assetUtils';
import dayjs from "../../../dayjs";

type CalcMaxThumbnalDimensionParams = {
    width: number,
    columns: number,
    spacingPx: number,
    imageRatio: $Keys<typeof IMAGE_RATIO>,
    images: Array<FacebookPostAsset>,
};

type FacebookFeedGalleryCalcRenderProps = CalcRenderer<
    FacebookFeedGalleryComponent, GalleryComponentExtension,
    FacebookFeedGalleryComponentDependsOn, FacebookFeedGalleryCalcRenderPropsResult
>

const emptyFacebookFeedMediaAsset: FacebookPostAsset = {
    id: '',
    created_time: '',
    permalink_url: '',
    // @ts-ignore
    media_type: 'IMAGE',
};

const
    calcImageProps =
        ({
            image: fbPostData, maxThumbnailWidth, maxThumbnailHeight,
            onClickAction, componentId, index, col, row, spacingPx, site, captionsEnabled, captionsAlignment,
            captionTitleTextStyle, captionDescriptionTextStyle, imageStyle, previewBackupTime,
            themeSettingsData, themeColorsData, selectedParentTheme, globalVariables, totalColumns, profileImageAsset
        }) => {
            const image = extractEssentialDataFromFbPost(fbPostData);
            const
                thumbnailWidth = maxThumbnailWidth,
                thumbnailHeight = maxThumbnailHeight,
                imageSrc = image.media_type === FACEBOOK_FEED_VIDEO ? image.thumbnail_url : image.media_url,
                isFirstRow = row === 1,
                isFirstCol = col === 1,
                offsetLeft = isFirstCol ? 0 : spacingPx,
                offsetTop = isFirstRow ? 0 : spacingPx,
                imageBorderStyle = makeComponentBorderStyle({
                    style: {
                        ...imageStyle,
                        ...(themeSettingsData.autoColorMode ? {
                            border: {
                                ...imageStyle.border,
                                color: (() => {
                                    let color;
                                    if (imageStyle.border && imageStyle.border.themeColor) {
                                        color = [...themeColorsData[imageStyle.border && imageStyle.border.themeColor]];
                                    } else {
                                        color = [...themeColorsData[
                                            findSuitableTextColorName(
                                                // $FlowFixMe: WBTGEN-16368: selectedParentTheme won't be null when autoColorMode is true
                                                getThemeRulesForBackground(selectedParentTheme, themeColorsData).background, themeColorsData
                                            )
                                        ]];
                                    }
                                    if (imageStyle.border && imageStyle.border.color) {
                                        color[4] = imageStyle.border.color[4]; // opacity
                                    }
                                    return color;
                                })()
                            }
                        } : {})
                    }
                }),
                containerStyle = {
                    width: maxThumbnailWidth,
                    marginLeft: offsetLeft,
                    marginTop: offsetTop,
                    textAlign: captionsAlignment,
                },
                imgStyle = {
                    width: thumbnailWidth,
                    height: thumbnailHeight
                },
                result: FacebookFeedGalleryImageRenderProps = {
                    container: { style: containerStyle },
                    // @ts-ignore
                    img: { src: imageSrc, srcSet: '', style: imgStyle },
                    imageAvailable: !!imageSrc,
                    divStyle: {
                        ...imageBorderStyle,
                        width: thumbnailWidth,
                        height: thumbnailHeight
                    },
                    mediaType: image.media_type
                };

            if (captionsEnabled) {
                result.captionWrapperStyle = makeCaptionStyle(maxThumbnailWidth);
                const
                    createdAt = dayjs(new Date(image.created_time)),
                    titleText = createdAt.format('D MMMM [at] HH:mm');
                result.title = {
                    text: replaceTagsWithContent(titleText || '', { globalVariables, site }),
                    props: {
                        style: captionTitleTextStyle,
                    }
                };
                result.caption = {
                    text: replaceTagsWithContent(image.message || '', { globalVariables, site }),
                    props: {
                        style: captionDescriptionTextStyle
                    }
                };
                result.container.style.height = thumbnailHeight + CAPTION_CONTAINER_HEIGHT_MAP[totalColumns];
            }

            let profileImageSrc = '';
            if (!result.imageAvailable && profileImageAsset) {
                profileImageSrc = getAssetUrl(
                    profileImageAsset,
                    { resize: `${EXPANDED_PROFILE_IMAGE_HOLDER_SIZE},${EXPANDED_PROFILE_IMAGE_HOLDER_SIZE}`,
                        ignoreAspectRatio: true }
                );
            }
            const a = calcA({
                onClickAction,
                caption: image.message || '',
                componentId,
                index,
                image,
                previewBackupTime,
                site,
                imageAvailable: !!imageSrc,
                profileImageSrc
            });

            // @ts-ignore
            if (a) result.a = a;

            return result;
        },
    calcGalleryMaxThumbnailDimensions = (
        { width, columns, spacingPx }: CalcMaxThumbnalDimensionParams
    ) => {
        const
            imageRatio = columns === 1 ? IMAGE_RATIO.LANDSCAPE : IMAGE_RATIO.SQUARE,
            imagesAvailableWidth = width - ((columns - 1) * spacingPx),
            maxThumbnailWidth = imagesAvailableWidth / columns,
            maxThumbnailWidthTrunc = trunc1(maxThumbnailWidth),
            ratio = IMAGE_RATIO_NUM_MAP[imageRatio],
            thumbnailHeight = Math.max(trunc1(maxThumbnailWidth / ratio), MinThumbnailSize.height);
        return {
            maxThumbnailWidth: Math.max(maxThumbnailWidthTrunc, MinThumbnailSize.width),
            maxThumbnailHeight: thumbnailHeight
        };
    },
    calcRenderProps: FacebookFeedGalleryCalcRenderProps = ({
        componentId,
        component,
        componentExtension,
        componentDependencies: {
            site,
            globalStyles,
            globalVariables,
            socialAccounts,
            stylesheetsIdToNameMap,
            themeColorsData,
            themeSettingsData,
        },
        previewBackupTime,
        isServerPreview,
        selectedParentTheme,
    }) => {
        const
            {
                columns,
                rows,
                spacingPx,
                captionsAlignment,
                showMoreBtn,
                fontSizePx,
                captionsEnabled,
                onClickAction,
                imageStyle,
                id,
                mobileSettings,
                captionStyle,
                captionMinHeight,
                previousCaptionStyle,
                buttonThemeSelected,
                captionBoxStyle = {},
                profileImageAsset,
                width,
                showMoreTxt,
                hideErrorBanner,
            } = component,
            boxStyle = captionStyle === CaptionStyles.CUSTOM
                ? { ...captionDefaults.box[previousCaptionStyle], ...captionBoxStyle }
                : { ...captionDefaults.box[captionStyle] },
            captionBoxBGBorderStyle = makeStyle(
                { ...component, style: { ...boxStyle } },
                themeSettingsData.autoColorMode ? {
                    backgroundColorFromTheme: (() => {
                        if (captionStyle === CaptionStyles.CUSTOM) {
                            if (
                                captionBoxStyle.background
                                && captionBoxStyle.background.colorData
                                && captionBoxStyle.background.colorData.themeColor
                            ) {
                                return themeColorsData[captionBoxStyle.background.colorData.themeColor];
                            } else if (previousCaptionStyle === CaptionStyles.CLASSIC) {
                                // @ts-ignore TODO: fix TS
                                return themeColorsData[captionDefaults.box[CaptionStyles.CLASSIC].background.colorData.themeColor];
                            } else {
                                return null;
                            }
                        } else if (captionStyle === CaptionStyles.TEMPLATE) {
                            return null;
                        } else { // CaptionStyles.CLASSIC
                            // @ts-ignore TODO: fix TS
                            return themeColorsData[captionDefaults.box[CaptionStyles.CLASSIC].background.colorData.themeColor];
                        }
                    })(),
                    borderColorFromTheme: themeColorsData[
                        getCaptionBoxBorderColorForTheme( // @ts-ignore WBTGEN-16368: selectedParentTheme won't be null when autoColorMode is true
                            captionStyle, previousCaptionStyle, captionBoxStyle.border, selectedParentTheme, themeColorsData
                        )
                    ],
                } : {}
            ),
            captionBoxPaddingStyle = makeCaptionStylePadding({ ...boxStyle, background: null /*since we are only interested in padding*/ }),
            captionBoxStyles = {
                ...captionBoxBGBorderStyle,
                ...captionBoxPaddingStyle
            },
            { captionTitleTextStyle, captionDescriptionTextStyle } = getCaptionFontStyle( // @ts-ignore WBTGEN-16368
                component, globalStyles, themeColorsData, themeSettingsData.autoColorMode, selectedParentTheme
            ),
            globalStyleId = R.path(['style', 'globalId'], component),
            globalStyleClass = stylesheetsIdToNameMap[globalStyleId],
            { maxThumbnailWidth, maxThumbnailHeight } = calcGalleryMaxThumbnailDimensions(component),
            isSocialAccountsFacebookFeedAssetsLoading = socialAccounts.facebookFeed.facebookFeedAssetsLoading;
        let facebookFeedMediaAssets;
        if (showMoreBtn) {
            facebookFeedMediaAssets = getMediaAssetsToBeShown(socialAccounts.facebookFeed.facebookFeedAssetUrls, columns, rows * 2);
        } else {
            facebookFeedMediaAssets = getMediaAssetsToBeShown(socialAccounts.facebookFeed.facebookFeedAssetUrls, columns, rows);
        }

        if (isServerPreview) {
            // Adding empty media assets as placeholders while publishing so that those can be replaced and
            // populated while information is fetched dynamically from the PHP handler
            if (showMoreBtn) {
                facebookFeedMediaAssets = Array(columns * rows * 2).fill(emptyFacebookFeedMediaAsset);
            } else {
                facebookFeedMediaAssets = Array(columns * rows).fill(emptyFacebookFeedMediaAsset);
            }
        }

        const
            imagesProps = facebookFeedMediaAssets.map((mediaAsset, index) => calcImageProps({
                image: mediaAsset,
                maxThumbnailWidth,
                maxThumbnailHeight,
                spacingPx,
                captionsEnabled,
                col: 1 + (index % columns),
                row: 1 + Math.floor(index / columns),
                index,
                captionsAlignment,
                onClickAction,
                componentId,
                site,
                previewBackupTime,
                imageStyle: !imageStyle ? {} : imageStyle,
                captionTitleTextStyle,
                captionDescriptionTextStyle,
                globalVariables,
                themeColorsData,
                themeSettingsData,
                selectedParentTheme,
                totalColumns: columns,
                profileImageAsset
            }));

        return {
            width,
            columns,
            rows,
            componentId: id,
            captionsEnabled,
            images: imagesProps,
            mobileSettings,
            captionBoxStyles,
            captionMinHeight,
            captionsContainerHeight: componentExtension ? componentExtension.highestCaptionPx : 0,
            isSocialAccountsFacebookFeedAssetsLoading,
            isServerPreview: isServerPreview || false,
            showMoreBtn,
            fontSizePx,
            globalStyleClass,
            selectedParentTheme: selectedParentTheme || BACKGROUND_THEME_WHITE,
            themeColorsData,
            themeSettingsData,
            buttonThemeSelected,
            profileImageAsset,
            captionDescriptionLineHeight: captionDescriptionTextStyle.lineHeight,
            captionDescriptionFontSize: captionDescriptionTextStyle.fontSize,
            showMoreTxt,
            hideErrorBanner,
        };
    };

export default calcRenderProps;

export {
    calcGalleryMaxThumbnailDimensions
};
