/*
 * NOTE: Keep this file synced with BlogBuilder -> client/src/components/Common/MessageChannel/message.ts
 */

export enum Message {
    INIT = "init",
    AUTOCOLOR = "autocolor",
    THEME_COLORS = "theme-colors",
    RECENT_COLORS = "recent-colors",
    LOCALE = "locale",
    PAGE_CHANGE = "page-change",
    REFRESH_APP = "refresh-app",
    FILE_MANAGER_LINK = 'file-manager-link',
    SUBSCRIPTION_TYPE = "subscription-type",
    EDITOR_LANGUAGE = "editor-language",
}

type MessageListener<T = any> = (payload: T, event?: MessageEvent) => any;

/*
  This service uses the Channel Messaging API to communicate between Manage Blog IFrame and the Website Builder.
  Learn more at https://developer.mozilla.org/en-US/docs/Web/API/Channel_Messaging_API
*/
export class MessageHandler {
    private channel = new MessageChannel();
    private latest = new Map<Message, any>();
    private queue: Array<{ type: Message; payload: any }> = [];
    private listeners = new Map<Message, Set<MessageListener>>();

    constructor(content: Window) {
        this.channel.port1.onmessage = (e) => this.onMessage(e);
        content.postMessage({ type: Message.INIT }, '*', [this.channel.port2]);
    }

    close() {
        this.queue = [];
        this.listeners.clear();
        this.channel.port1.close();
        this.channel.port2.close();
    }

    private processQueue() {
        while (this.queue.length) {
            const first = this.queue.shift()!;
            this.channel.port1.postMessage(first);
        }
    }

    private onMessage(e: MessageEvent) {
        const { type, payload } = e.data;
        if (Object.values(Message).includes(type)) {
            if (type === Message.INIT) {
                this.processQueue();
            }
            this.latest.set(type, payload);
            this.listeners.get(type)?.forEach(listener => {
                listener(payload, e);
            });
        }
    }

    public send<T>(type: Message, payload?: T) {
        this.queue.push({ type, payload });
        this.processQueue();
    }

    public getLatest<T>(type: Message): T | undefined {
        return this.latest.get(type);
    }

    public removeListener<T>(
        type: Message,
        listener: MessageListener<T>
    ) {
        this.listeners.get(type)?.delete(listener);
    }

    public addListener<T>(
        type: Message,
        listener: MessageListener<T>,
        options?: { signal?: AbortSignal; once?: boolean; immediate?: boolean }
    ) {
        if (!this.listeners.has(type)) {
            this.listeners.set(type, new Set());
        }
        const listeners = this.listeners.get(type)!;
        let cb = listener;
        if (options?.once) {
            cb = (...args) => {
                listener(...args);
                listeners.delete(cb);
            };
        } else {
            options?.signal?.addEventListener("abort", () => {
                listeners.delete(cb);
            });
        }
        listeners.add(cb);
        if (options?.immediate && this.latest.has(type)) {
            cb(this.latest.get(type));
        }
    }
}
