import React, { useMemo } from "react";
import { connect } from "react-redux";
import cx from "classnames";

import { makeEpicStateSelector } from "../../../../epics/makeEpic";
import { createContextValue } from "../wizardController";
import { OnboardingTopBar } from "../../../TopBar/view/OnboardingTopBar";
import DYNAMIC_TEMPLATE_EPIC from "../Epic/valueActionType";
import { DynamicTemplateContext } from "./Context";
import Scrollbar from '../../../../view/common/Scrollbar';

import styles from "./DynamicOnboarding.css";
import { WelcomeOnboardingView } from "./Welcome";
import { TemplateLoaderAnimation } from "./Animations/TemplateLoaderAnimation";
import { DYNAMIC_TEMPLATE_GET_AI_CONTENT } from "../Epic/actionTypes";
import { TrialOnboardingView } from "./TrialWelcome";
import LoadingIndicator from "../../../../view/common/LoadingIndicator";

const OnBoardWizard_ = (props) => {
    const {
        dynamicTemplateState,
        dynamicTemplateState: {
            isLoading,
            showWelcomePage,
            failedAttempt,
            selectedTemplate,
            showShopWelcome
        },
        dispatch
    } = props;
    const shouldShowAnimationFullScreen = isLoading && failedAttempt.action?.type === DYNAMIC_TEMPLATE_GET_AI_CONTENT;
    const contextValue = useMemo(() =>
        createContextValue(dynamicTemplateState, dispatch), [dynamicTemplateState, dispatch]);

    const {
        wizardState: {
            step: {
                view: StepView,
                animation: AnimationView
            }
        }
    } = contextValue;

    if (showWelcomePage) {
        if (isLoading) {
            return <LoadingIndicator />;
        }
        if (selectedTemplate && !!Object.keys(selectedTemplate).length) {
            return <TrialOnboardingView
                selectedTemplate={selectedTemplate}
                dispatch={dispatch}
            />;
        }
        return <WelcomeOnboardingView showShopWelcome={showShopWelcome} />;
    }

    return (
        <DynamicTemplateContext.Provider value={contextValue}>
            <div className={styles.onBoardingContainer}>
                <div className={cx(styles.wizardContainer, shouldShowAnimationFullScreen && styles.wizardContainerHide)}>
                    {
                        !shouldShowAnimationFullScreen && (
                            <React.Fragment>
                                <div className={styles.topBar}>
                                    <OnboardingTopBar {...({ showHelp: false, enableLogoLink: true })} />
                                </div>
                                <Scrollbar height="100%">
                                    <div className={styles.wizardBody}>
                                        <StepView />
                                    </div>
                                </Scrollbar>
                            </React.Fragment>
                        )
                    }
                </div>
                <div className={cx(styles.animationContainer)}>
                    {
                        shouldShowAnimationFullScreen ?
                            <TemplateLoaderAnimation /> :
                            <AnimationView />

                    }
                </div>
            </div>
        </DynamicTemplateContext.Provider>
    );
};

const mapStateToProps = (appState) => ({
    dynamicTemplateState: makeEpicStateSelector(DYNAMIC_TEMPLATE_EPIC)(appState),
});

const OnBoardWizard = connect(mapStateToProps)(OnBoardWizard_);

export {
    OnBoardWizard as default
};
