import * as R from 'ramda';
import type { TinyMceEditor } from "../../../flowTypes";

const ATTRIBUTES_TO_COMPARE: Array<string> = [
    'style',
    'class',
    'href',
    'target'
];

const NODES_TO_COMPARE: Array<string> = [
    'A',
    'SPAN'
];

const toAttrMap = (attrs: any) => attrs.filter(attr => ATTRIBUTES_TO_COMPARE.includes(attr.name))
    .reduce((map, attr) => ({
        ...map,
        [attr.name]: attr.value
    }), {});

const compareAttr = (a: any, b: any) => {
    return R.equals(
        a ? toAttrMap(a) : '',
        b ? toAttrMap(b) : ''
    );
};

const compareNode = (nodeA: Record<string, any>, nodeB: Record<string, any>) => {
    if (nodeA.nodeName !== nodeB.nodeName || !NODES_TO_COMPARE.includes(nodeA.nodeName)) {
        return false;
    }
    return compareAttr(Array.from(nodeA.attributes), Array.from(nodeB.attributes));
};

const copyChildNodes = (nodeA: Record<string, any>, nodeB: Record<string, any>) => {
    while (nodeB.firstChild) {
        nodeA.appendChild(nodeB.firstChild);
    }
};

const mergeNode = (node: Node) => {
    const childNodes: any[] = Array.from(node.childNodes);
    childNodes.forEach(node => mergeNode(node));

    let i = 0;
    while (i < childNodes.length - 1) {
        if (compareNode(childNodes[i], childNodes[i + 1])) {
            copyChildNodes(childNodes[i], childNodes[i + 1]);
            childNodes[i + 1].remove();
            i++;
        }
        i++;
    }
};

export const mergeNodes = (editor: TinyMceEditor, node?: HTMLElement) => {
    const selectedNode = node || editor.getBody().firstChild as Node;
    mergeNode(selectedNode);
};
