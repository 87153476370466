import * as colorMapper from '../Base/color';
import * as gradientMapper from "./gradient";
import type { BackgroundIn, ColorIn } from "./flowTypes";
import type { Background, AssetSize, AssetRepeat, AssetPosition } from "../../../../src/mappers/background/flowTypes";
import { BackgroundSize } from "../../../../src/components/presentational/BackgroundImageSettings/options";
import { DefaultGradient } from "../../../../src/components/presentational/ColorProperty/constants";
import * as array from "../../../../utils/array.js";
import { scrollEffects } from "../../../../src/components/oneweb/commonView/ScrollEffects/constants";
import { Color } from '../../../../src/mappers/flowTypes';
import { ImageAsset } from '../../../../src/components/App/flowTypes';

const
    BACKGROUND_SIZE_FILL = 'fill',
    BACKGROUND_SIZE_FIT = 'fit',
    DEFAULT_SIZE = 100;
const mapColor = (background: null | undefined | BackgroundIn) => {
    let color: null | ColorIn = null;
    if (background) {
        if (background.color) color = background.color;
        else if (
            !background.color
            && background.gradient
            && !array.equal(background.gradient.stops[0][0], DefaultGradient.color)
        ) {
            color = background.gradient.stops[0][0];
        }
    }

    if (color) color = colorMapper.toHsl(color);

    return color;
};

const mapSizeTo = (size: null | undefined | AssetSize): AssetSize => {
    if (size === BACKGROUND_SIZE_FILL) return BackgroundSize.FILL;
    else if (size === BACKGROUND_SIZE_FIT) return BackgroundSize.FIT;
    else if (!size) return DEFAULT_SIZE;
    return size;
};

const mapSizeBack = (size: AssetSize): AssetSize => {
    if (size === BackgroundSize.FILL) return BACKGROUND_SIZE_FILL;
    else if (size === BackgroundSize.FIT) return BACKGROUND_SIZE_FIT;
    return size;
};

const getScrollEffectData = (bgData) => {
    if (!bgData || !bgData.asset) {
        return {};
    }
    let scrollEffectData = {};
    if (bgData.hasOwnProperty('scrollEffect')) {
        scrollEffectData = { scrollEffect: bgData.scrollEffect };
    } else if (bgData.fixed) {
        scrollEffectData = { scrollEffect: scrollEffects.reveal };
    }
    return scrollEffectData;
};

const to = (background?: null | BackgroundIn): null | undefined | Background => {
    // @ts-ignore
    return background && {
        colorData: {
            color: mapColor(background),
            gradient: background.gradient && gradientMapper.to(background.gradient)
        },
        assetData: background.asset && {
            asset: background.asset,
            repeat: background.repeat,
            overlay: background.overlay,
            position: background.position,
            size: mapSizeTo(background.size),
            ...getScrollEffectData(background),
            // @ts-ignore
            opacity: !isNaN(background.opacity) ? background.opacity : 1,
        }
    };
};

const back = (background: null | undefined | Background): null | undefined | BackgroundIn => {
    if (!background) return null;

    let
        color: null | Color = null,
        gradient = null,
        asset: ImageAsset | null | undefined = null,
        repeat: AssetRepeat | null = null,
        overlay: string | null | undefined = null,
        position: AssetPosition | null = null,
        size: AssetSize | null = null,
        opacity: number | null = null;

    const { colorData = { color: null, gradient: null } } = background;
    if (colorData.color && !colorData.gradient) {
        color = colorData.color;
    } else if (colorData.gradient) {
        const stopValues = gradientMapper.fadePointToStops(colorData.gradient.fadePoint);
        // @ts-ignore
        gradient = { ...gradientMapper.back(colorData.gradient),
            stops: [
                [(colorData && colorData.color) || DefaultGradient.color, stopValues[0]],
                [colorData && colorData.gradient && colorData.gradient.color, stopValues[1]]
            ] };
    }

    const { assetData } = background;
    if (assetData) {
        ({ asset, repeat, overlay, position, opacity } = assetData);
        size = mapSizeBack(assetData.size);
    }
    return {
        color,
        gradient,
        asset,
        repeat,
        overlay,
        position,
        size,
        ...getScrollEffectData(assetData),
        opacity
    };
};

export { to, back };
