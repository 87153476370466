import { isDashboardShopRoute } from "../../../../../../server/lib/handlers/routeUtils.js";
import { DASHBOARD_SHOP_ROUTE } from "../../../../../../server/shared/routes.js";
import { getAppConfig } from "../../../../../../wbtgen/src/components/App/epics/appConfig/appConfig.js";
import AppConfig from "../../../../../../wbtgen/src/utils/AppConfig";
import { isPartnerSubdomain } from "../../../../../../wbtgen/src/components/ComponentTierManager/partnerSubdomainNameUtils";
import { getDAL } from "../../../../../../wbtgen/dal";

import { isShopInAllTiersEnabled } from "../../../common/config";
import { OnlineShopStatus } from "./types";
import { SHOP_PAGES_ROUTES } from "./constants";
import { isOnlineShopCmpsNotAllowedBySubscriptionType } from "../../../../../../wbtgen/src/components/TemplateSelector_DEPRECATED/view/utils";
import { OnlineShopMessageTypes, WebshopAdminIframeId } from "./OnlineShopDashboard/constants";
import { isEcommerceSubscription } from "../../../../../../wbtgen/src/components/App/epics/subscriptionData/utils";

export const prefixShopRoute = (route: string): string => {
    const appConfig = AppConfig(getAppConfig());
    const pathPrefix = appConfig.getOptional("oneWeb.webshop.iframeUrlPathPrefix", "");

    if (!pathPrefix || route.startsWith(pathPrefix)) {
        return route;
    }

    return pathPrefix + route;
};

const unprefixShopRoute = (route: string): string => {
    const appConfig = AppConfig(getAppConfig());
    const pathPrefix = appConfig.getOptional("oneWeb.webshop.iframeUrlPathPrefix", "");

    if (!pathPrefix || !route.startsWith(pathPrefix)) {
        return route;
    }

    return route.substring(pathPrefix.length);
};

export const mapShopRouteToUrl = (route: string): string => {
    const appConfig = AppConfig(getAppConfig());
    const shopAppUrl = appConfig.get("oneWeb.webshop.appUrl");

    return shopAppUrl + prefixShopRoute(route);
};

export const mapShopRouteToLocationRoute = (route: string): string => {
    return DASHBOARD_SHOP_ROUTE + unprefixShopRoute(route);
};

export const mapShopApiRouteToUrl = (route: string): string => {
    const appConfig = AppConfig(getAppConfig());
    const shopAppUrl = appConfig.get("oneWeb.webshop.appUrl");

    // api routes are not prefixed
    return shopAppUrl + route;
};

export const isOnlineShopAllowedForTier = (subscriptionType: string, isSiatAllowedFromShopApi: boolean | null = true) => (
    !isPartnerSubdomain() &&
    !isOnlineShopCmpsNotAllowedBySubscriptionType() &&
    ((isShopInAllTiersEnabled && isSiatAllowedFromShopApi) || isEcommerceSubscription(subscriptionType))
);

type OnlineShopStatusApiResponse = {
    isWebshopSetupDone: boolean;
    isDigitalShopSetupDone: boolean;
    isPaymentRequired: boolean;
    isSIATCustomer: boolean;
};

export const onlineShopStatus = (options: OnlineShopStatusApiResponse): OnlineShopStatus => {
    if (options?.isWebshopSetupDone) {
        if (options?.isPaymentRequired) {
            return OnlineShopStatus.Inactive;
        } else {
            return OnlineShopStatus.Active;
        }
    }

    return OnlineShopStatus.NoShop;
};

export const isOnlineShopActive = (status: OnlineShopStatus | null): boolean => {
    return status === OnlineShopStatus.Active;
};

export const getShopRouteFromLocation = () => {
    if (isDashboardShopRoute(location.pathname)) {
        const path = (
            location.pathname.substring(DASHBOARD_SHOP_ROUTE.length) ||
            SHOP_PAGES_ROUTES.HOME
        );

        return prefixShopRoute(path) + location.search;
    }
    return null;
};

export const getShopIframeToken = () => {
    return getDAL().getAppViewToken('webshop');
};

export const getShopUrlWithToken = (shopUrl: string, token: string) => {
    const url = new URL(shopUrl);
    url.searchParams.append('ws-req-app', 'wsb');
    url.searchParams.append('ws-shrt-tkn', token);
    return url.toString();
};

export const getShopUrlWithTokenFromRoute = (route: string, token: string) => {
    return getShopUrlWithToken(mapShopRouteToUrl(route), token);
};

export const shopIframePostMessage = (message: { type: OnlineShopMessageTypes; payload?: any; }) => {
    const webshopIframe = document.getElementById(WebshopAdminIframeId) as Nullable<HTMLIFrameElement>;

    if (webshopIframe && webshopIframe.contentWindow) {
        const urlOrigin = new URL(webshopIframe.src).origin;
        webshopIframe.contentWindow.postMessage(message, urlOrigin);
    }
};
