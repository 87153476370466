import { all, fork, put } from 'redux-saga/effects'; // eslint-disable-line n/file-extension-in-import
import loadPageDataSet from "../actionCreators/loadPageDataSet";
import { siteDataReceiveOnlyExternalValueActionType } from '../../App/epics/siteData/valueActionType';
import { getLastPageId } from "../utils/lastPageId";
import { PAGE_TREE_PAGE_SELECTED } from "../Tree/actionTypes";
import { DataSite, DataPageRef } from "../../../../dal/model";
import makeTakeEffectSaga from "../../../epics/makeTakeEffectSaga";
import Url from "../../../../utils/Url";
import { debugCustomPageId } from "../../../utils/isDebug";
import { PAGE_TREE_LOAD_PAGE } from "../actionTypes";
import checkUnsavedChangesSaga from "../../UnsavedChanges/checkUnsavedChangesSaga";
import { isLinkPage } from "../../../utils/page";

const loadPageDataGen = function* (pageId: string) {
    yield put(loadPageDataSet(pageId));
};

const onSiteMapLoadSagaWorker = function* (site: DataSite): Generator<Record<string, any>, void, any> {
    let pageId;

    if (debugCustomPageId()) {
        const url = Url(window.location.href);
        pageId = url.query.get('pageId');
    } else {
        pageId = getLastPageId();
        // WBTGEN-3153 - Check if the last page id from localStorage does not exits in site
        if (pageId && !site.getOptionalPageRefByPageId(pageId)) {
            pageId = null;
        }
    }

    if (!pageId) {
        let page: null | undefined | DataPageRef = site.getHomePageRef();
        if (!page) {
            // @ts-ignore
            page = site.getItems().find(isLinkPage);
        }

        if (!page) throw new Error('Empty site map');
        else if (!page.pageId) throw new Error("Page ref doesn't have page id");
        pageId = page.pageId;
    }

    yield* loadPageDataGen(pageId);
};

const onPageSelectedSagaWorker = function* (pageId: string): Generator<Record<string, any>, void, any> {
    const checked = yield* checkUnsavedChangesSaga();
    if (!checked) return;

    if (!pageId) throw new Error('Missing pageId to load page data for');
    yield* loadPageDataGen(pageId);
};

const listener = function* (): ForksSaga {
    yield all([
        fork(makeTakeEffectSaga('takeLatest', siteDataReceiveOnlyExternalValueActionType, onSiteMapLoadSagaWorker)),
        fork(makeTakeEffectSaga('takeLatest', PAGE_TREE_PAGE_SELECTED, onPageSelectedSagaWorker)),
        fork(makeTakeEffectSaga('takeLatest', PAGE_TREE_LOAD_PAGE, loadPageDataGen))
    ]);
};

export {
    listener as default,
    onSiteMapLoadSagaWorker,
    onPageSelectedSagaWorker
};
