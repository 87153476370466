enum Status {
    LOADING = 'LOADING',
    LOADED = 'LOADED',
    PRELOADED = 'PRELOADED',
    FAILED = 'FAILED'
}

type LoaderOptions = {
    force ?: true;
    id ?: string;
};

type ScriptStatus = {
    [key: string]: Status;
};

const loadScript = (() => {
    const loadedScripts = <ScriptStatus>{};

    const
        getScriptByStatus = (status: Status) => (scriptSrc: string): boolean => loadedScripts[scriptSrc] === status,
        isScriptLoading = getScriptByStatus(Status.LOADING),
        isScriptLoaded = getScriptByStatus(Status.LOADED),
        isScriptFailed = getScriptByStatus(Status.FAILED);

    const
        setStatus = (status: Status) => (scriptSrc: string): void => {
            loadedScripts[scriptSrc] = status;
        },
        setScriptLoading = setStatus(Status.LOADING),
        setScriptLoaded = setStatus(Status.LOADED),
        setScriptFailed = setStatus(Status.FAILED);

    const loader = (scriptSrc: string, options: LoaderOptions = {}): Promise<string> => {
        const { force = false } = options;

        if (!force) {
            if (isScriptLoaded(scriptSrc)) {
                return Promise.resolve(Status.PRELOADED);
            } else if (isScriptFailed(scriptSrc)) {
                return Promise.reject(Status.FAILED);
            } else if (isScriptLoading(scriptSrc)) {
                return new Promise((resolve, reject) => {
                    const waitForStatusChange = () => setTimeout(() => {
                        if (isScriptLoaded(scriptSrc)) {
                            resolve(Status.PRELOADED);
                        } else if (isScriptFailed(scriptSrc)) {
                            reject(Status.FAILED);
                        } else {
                            waitForStatusChange();
                        }
                    }, 200);

                    waitForStatusChange();
                });
            }
        }

        setScriptLoading(scriptSrc);

        return new Promise((resolve, reject) => {
            const script = document.createElement('script');
            script.src = scriptSrc;

            if (options.id) script.id = options.id;

            document.head.appendChild(script);

            const
                onSuccess = () => {
                    setScriptLoaded(scriptSrc);
                    resolve(Status.LOADED);
                },
                onFailure = () => {
                    setScriptFailed(scriptSrc);
                    reject(Status.FAILED);
                };

            script.addEventListener('error', onFailure);
            script.addEventListener('load', onSuccess);
        });
    };

    return loader;
})();

export { loadScript, Status };
