/* eslint-disable max-len */

// TODO: decouple image editor ? should be separate button

import React from 'react';
import { openVideoChooserAction } from "./actions";
import { FileChooserButton } from "../../PropertiesPanel/view/components/FileChooserButton/FileChooserButton";
import type { FcContentTypePattern } from "../../../redux/modules/children/fileChooser/utils/testFcContentType";
import type { MsgJointInput } from "../../../view/intl/index";
import type { ImageAsset } from "../../App/flowTypes";
import type { AppDispatch } from "../../../redux/modules/flowTypes";
import { VideoUtils } from '../../../utils/fileUtils';
import { FmTabId } from "../dialog/constants";

const getLabelFromAsset = asset => asset.url.split('/').slice(-1)[0];

type Props = {
    onKeyDown: React.KeyboardEventHandler<HTMLButtonElement>;
    asset: null | undefined | ImageAsset;
    changeAction: string,
    removeAction: string,
    headLabel?: MsgJointInput,
    chooseLabel: MsgJointInput,
    dispatch: AppDispatch,
    extraImageContentTypes?: FcContentTypePattern,
    headlessMode?: true,
    hideFreeOneComImagesTab?: boolean,
    hideShutterstockImagesTab?: true,
    hideMyImagesTab?: true,
    isImageEditorAllowed?: boolean,
    removeButtonTitle?: string,
    labelMaxWidth?: null | number,
    tabIndex?: number,
    className ?: string,
};

export const VideoChooserBtnPP = (props: Props) => {
    const
        {
            asset,
            changeAction,
            removeAction,
            removeButtonTitle,
            chooseLabel,
            headLabel,
            extraImageContentTypes,
            dispatch,
            headlessMode,
            hideFreeOneComImagesTab,
            hideShutterstockImagesTab,
            hideMyImagesTab,
            labelMaxWidth,
            tabIndex,
            onKeyDown,
            className
        } = props,
        fileChooserProps = {
            onKeyDown,
            filename: asset && getLabelFromAsset(asset),
            fileChangeAction: openVideoChooserAction({
                onSaveAction: changeAction,
                isMultiSelect: false,
                forwardToComponent: true,
                extraImageContentTypes,
                headlessMode,
                hideFreeOneComImagesTab,
                hideShutterstockImagesTab,
                hideMyImagesTab,
                activeTabId: FmTabId.MY_VIDEOS
            }),
            removeAction: asset ? { type: removeAction } : null,
            showAssetInfo: asset && VideoUtils.isVideoFile(getLabelFromAsset(asset)),
            removeButtonTitle,
            chooseLabel,
            headLabel,
            dispatch,
            headlessMode,
            hideFreeOneComImagesTab,
            hideShutterstockImagesTab,
            hideMyImagesTab,
            labelMaxWidth,
            tabIndex,
            className
        };

    return (<FileChooserButton {...fileChooserProps} />);
};
