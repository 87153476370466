/* eslint-disable max-classes-per-file */
import React from "react";
import { useSelector } from "react-redux";
import cx from "classnames";
import { dispatchForwardToSelectedComponent } from '../../../redux/forwardTo';
import { BLOCK_NATIVE_CONTEXT_MENU_CLASS } from "../../../constants";
import { injectIntl } from "../../../view/intl/index";
import { isString } from '../../../../utils/string.js';
import NoMouseEventsPropagation from "../../../view/common/NoMouseEventsPropagation";
import {
    HIDE_CONTEXT_MENU, CONTEXT_MENU_SELECT_OVERLAPPING_COMPONENT,
    CONTEXT_MENU_MOUSE_ENTER_OVERLAPPING_COMPONENT, CONTEXT_MENU_MOUSE_LEAVE_OVERLAPPING_COMPONENT
} from '../actionTypes';
import { ContextMenuDimensions } from "./constants";
import { getSubMenuRelativePosition, getSubMenuPosition } from "./utils";
import { siteDataValueActionType } from "../../App/epics/siteData/valueActionType";
import { makeEpicStateSelector } from "../../../epics/makeEpic";
import { subscriptionDataSelector } from '../../App/epics/subscriptionData/selectors';
import type { Position } from "../../../redux/modules/flowTypes";
import type {
    ContextMenuViewProps,
    ContextMenuPropType,
    ContextMenuItemPropType,
    ContextMenuExpandableItemPropType,
    ContextMenuGroupPropType,
    MenuStatus,
    ContextMenuGroupStateType
} from "../flowTypes";
import styles from "./ContextMenu.css";
import { RenderPremiumIcon } from "../../PagesTree/Tree/view/RenderPremiumIcon";

type Props = {
    state: ContextMenuViewProps,
    dispatch: Dispatch
}

const TIMEOUT_INTERVAL: number = 700;
const ContextMenuExpandableItem = (props: ContextMenuExpandableItemPropType) => {
    const
        {
            itemIndex, title, groupDirection, contextMenuGroups, position,
            dimensions, handleClick, className, openMenu, closeMenu, status,
            handleMouseEnter, handleMouseLeave,
        } = props,
        onMouseEnter = () => openMenu(itemIndex),
        onLeaveLeave = () => closeMenu(itemIndex, setTimeout(() => {
            if (status.isOpen) {
                closeMenu(itemIndex);
            }
        }, TIMEOUT_INTERVAL)),
        groupsCount = contextMenuGroups.length,
        groupsItemsCount = contextMenuGroups.reduce((acc, cur) => acc + (cur.group ? cur.group.length : 1), 0),
        subMenuPosition = getSubMenuPosition(
            position,
            dimensions,
            itemIndex,
            groupsCount,
            groupsItemsCount,
            groupDirection
        );

    let optionalProps: Record<string, any> = {};

    if (handleMouseEnter) optionalProps.handleMouseEnter = handleMouseEnter;
    if (handleMouseLeave) optionalProps.handleMouseLeave = handleMouseLeave;

    return (
        <div
            className={cx(styles.contextMenuItemExpandable, className)}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onLeaveLeave}
            {...NoMouseEventsPropagation}
            onClick={e => e.stopPropagation()}
        >
            {title}
            {
                status.isOpen &&
                // eslint-disable-next-line @typescript-eslint/no-use-before-define
                <ContextMenu
                    contextMenuGroups={contextMenuGroups}
                    position={subMenuPosition}
                    dimensions={dimensions}
                    handleClick={handleClick}
                    {...optionalProps}
                />
            }
        </div>
    );
};

const CLOSED_MENU: MenuStatus = { isOpen: false };
const
    ContextMenuItem = injectIntl(({
        title,
        actionInput,
        className,
        shortcut,
        disabled,
        icon: Icon,
        payload,
        handleClick,
        handleMouseEnter,
        handleMouseLeave,
        intl,
        toolTip,
        showPremiumIcon
    }: ContextMenuItemPropType) => {
        const siteDataSelector = makeEpicStateSelector(siteDataValueActionType);
        const siteData = useSelector(siteDataSelector);
        const subscriptionData = useSelector(subscriptionDataSelector);
        let optionalProps: Record<string, any> = {};

        if (handleMouseEnter) optionalProps.onMouseEnter = () => handleMouseEnter({ actionInput, payload });
        if (handleMouseLeave) optionalProps.onMouseLeave = () => handleMouseLeave({ actionInput, payload });

        return (
            <div
                className={
                    cx(styles.contextMenuItem, className, { [styles.disabled]: disabled, [styles.wrapIcon]: !!Icon })
                }
                {...NoMouseEventsPropagation}
                onClick={e => handleClick({ e, actionInput, payload, hideContextMenu: !disabled, disabled })}
                {...optionalProps}
                data-title={toolTip}
                data-title-position="right"
            >
                {
                    Icon && <Icon className={styles.componentIcon} fill="gray60" />
                }
                {isString(title) ? intl.msgJoint(title as string) : title}
                {shortcut}
                {
                    (
                        showPremiumIcon && showPremiumIcon(siteData, subscriptionData) ? (
                            <RenderPremiumIcon iconClass={styles.premiumIcon} />
                        ) : null
                    )
                }
            </div>
        );
    });

class ContextMenuGroup extends React.Component<ContextMenuGroupPropType, ContextMenuGroupStateType> {
    constructor(props: ContextMenuGroupPropType) {
        super(props);
        this.state = { subMenus: [] };
        this.mounted = false;
    }

    mounted: boolean;

    componentDidMount() {
        this.mounted = true;
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    openMenu = (index: number) => {
        if (this.mounted) {
            let subMenus = this.state.subMenus.map((item, idx) => {     // eslint-disable-line react/no-access-state-in-setstate
                if (item && item.timeout) {
                    clearTimeout(item.timeout);
                }
                return {
                    isOpen: index === idx
                };
            });
            subMenus[index] = {
                isOpen: true
            };

            this.setState({ subMenus });
        }
    };

    closeMenu = (index: number, timeout: ReturnType<typeof setTimeout>) => {
        if (this.mounted) {
            let subMenus: Array<MenuStatus> = [...this.state.subMenus];     // eslint-disable-line react/no-access-state-in-setstate
            if (timeout) {
                if (subMenus[index]) {
                    subMenus[index].timeout = timeout;
                } else {
                    clearTimeout(timeout);
                }
            } else {
                subMenus[index] = {
                    isOpen: false
                };
            }
            this.setState({ subMenus });
        }
    };

    render() {
        const
            {
                group: contextMenuItems,
                groupClassName,
                position,
                dimensions,
                handleClick,
                handleMouseEnter,
                handleMouseLeave,
            } = this.props;

        let optionalProps: Record<string, any> = {};

        if (handleMouseEnter) optionalProps.handleMouseEnter = handleMouseEnter;
        if (handleMouseLeave) optionalProps.handleMouseLeave = handleMouseLeave;

        if (!contextMenuItems.length) { return null; }
        return (<div className={cx(styles.contextMenuGroup, groupClassName)}>
            {
                contextMenuItems.map((contextMenuItem: AnyValue, index: number) => (
                    contextMenuItem.contextMenuGroups ?
                        <ContextMenuExpandableItem
                            key={index}
                            itemIndex={index}
                            title={contextMenuItem.title}
                            groupDirection={contextMenuItem.groupDirection}
                            contextMenuGroups={contextMenuItem.contextMenuGroups}
                            className={contextMenuItem.className}
                            position={position}
                            dimensions={dimensions}
                            handleClick={handleClick}
                            openMenu={this.openMenu}
                            closeMenu={this.closeMenu}
                            status={this.state.subMenus[index] || CLOSED_MENU}
                            {...optionalProps}
                        /> :
                        <ContextMenuItem
                            key={index}
                            title={contextMenuItem.title}
                            actionInput={contextMenuItem.actionInput}
                            shortcut={contextMenuItem.shortcut}
                            disabled={contextMenuItem.disabled}
                            icon={contextMenuItem.icon}
                            payload={contextMenuItem.payload}
                            className={contextMenuItem.className}
                            componentId={contextMenuItem.componentId}
                            handleClick={handleClick}
                            toolTip={contextMenuItem.toolTip}
                            showPremiumIcon={contextMenuItem.showPremiumIcon}
                            {...optionalProps}
                        />
                ))
            }
        </div>);
    }
}

class ContextMenu extends React.Component<ContextMenuPropType> {       // eslint-disable-line react/no-multi-comp
    shouldComponentUpdate(nextProps: ContextMenuPropType): boolean {
        const
            { position: { x, y }, dimensions } = this.props,
            { browserHeight: bh, browserWidth: bw } = dimensions;

        return (
            x !== nextProps.position.x ||
            y !== nextProps.position.y ||
            bh !== nextProps.dimensions.browserHeight ||
            bw !== nextProps.dimensions.browserWidth
        );
    }

    render() {
        const
            {
                contextMenuGroups,
                position: { x, y }, dimensions,
                handleClick, handleMouseEnter, handleMouseLeave
            } = this.props,
            style = { top: y, left: x, width: ContextMenuDimensions.containerWidth };

        let
            optionalProps: Record<string, any> = {},
            prevPosition = { x, y },
            prevItemsCount = 0;

        if (handleMouseEnter) optionalProps.handleMouseEnter = handleMouseEnter;
        if (handleMouseLeave) optionalProps.handleMouseLeave = handleMouseLeave;

        return (
            <div className={styles.contextMenu} style={style}>
                {
                    contextMenuGroups.map((contextMenuGroup: ContextMenuGroupPropType, index: number) => {
                        const subMenuPosition: Position = getSubMenuRelativePosition(prevPosition, prevItemsCount);
                        prevPosition = subMenuPosition;
                        prevItemsCount = contextMenuGroup.group.length;
                        return (
                            <ContextMenuGroup
                                key={index}
                                // @ts-ignore
                                group={contextMenuGroup.group}
                                // @ts-ignore
                                position={subMenuPosition}
                                // @ts-ignore
                                dimensions={dimensions}
                                // @ts-ignore
                                handleClick={handleClick}
                                {...optionalProps}
                                {...contextMenuGroup}
                            />
                        );
                    })
                }
            </div>
        );
    }
}

const ContextMenuView = (props: Props) => {
    const
        { state: { contextMenuGroups, dimensions, position }, dispatch } = props,
        forwardedDispatch = dispatchForwardToSelectedComponent(dispatch),
        dispatchAction = (actionInput, payload) => {
            if (actionInput) {
                const action = typeof actionInput === 'function'
                    ? actionInput(payload)
                    : { type: actionInput, payload };

                forwardedDispatch(action);
            }
        },
        handleClick = ({ e, actionInput, payload, hideContextMenu, disabled }: Record<string, any>) => {
            if (disabled) return;

            e.stopPropagation();

            if (Array.isArray(actionInput)) {
                actionInput.forEach(action => dispatchAction(action, payload));
            } else {
                dispatchAction(actionInput, payload);
            }

            if (hideContextMenu) dispatch({ type: HIDE_CONTEXT_MENU });
        },
        handleMouseEnter = ({ actionInput, payload }) => {
            if (actionInput === CONTEXT_MENU_SELECT_OVERLAPPING_COMPONENT) {
                dispatch({ type: CONTEXT_MENU_MOUSE_ENTER_OVERLAPPING_COMPONENT, payload });
            }
        },
        handleMouseLeave = ({ actionInput, payload }) => {
            if (actionInput === CONTEXT_MENU_SELECT_OVERLAPPING_COMPONENT) {
                dispatch({ type: CONTEXT_MENU_MOUSE_LEAVE_OVERLAPPING_COMPONENT, payload });
            }
        };

    return (
        <div className={BLOCK_NATIVE_CONTEXT_MENU_CLASS}>
            <ContextMenu
                dimensions={dimensions}
                contextMenuGroups={contextMenuGroups}
                position={position}
                dispatch={dispatch}
                handleClick={handleClick}
                handleMouseEnter={handleMouseEnter}
                handleMouseLeave={handleMouseLeave}
            />
            <div
                className={styles.contextMenuCover}
                onMouseDown={e => handleClick({
                    e,
                    actionInput: HIDE_CONTEXT_MENU,
                    payload: null,
                    hideContextMenu: true
                })}
            />
        </div>
    );
};

export { ContextMenuView };
