import { $Keys } from "utility-types";
import React from 'react';
import cx from 'classnames';
import { ExtendedPanelTooltip } from "../../Tooltip/ids";
import styles from "./Inserter.css";
import { SHOW_TOOLTIP_FOR_EXTENDED_PANEL, CLOSE_TOOLTIP } from "../../Tooltip/actionTypes";
import { PointerDirections } from "../../Tooltip/constants";
import type { ReactElementRef } from "../../../globalTypes";

type HelpTipProps = {
    msg: string;
    calcPosition?: Function;
    dispatch: Function;
    pointerDirection?: $Keys<typeof PointerDirections>;
    helpTipClassName?: string
}

const calcToRightAndVCenter = ({ top, width, height, left }: DOMRect) => {
    // position relative to the help tip
    return { x: left + width, y: (top + (height / 2)) - 4 };
};

export default class HelpTip extends React.Component<HelpTipProps> {
    ref: ReactElementRef<any>;
    constructor(props: HelpTipProps) {
        super(props);
        this.onMouseEnterHandler = this.onMouseEnterHandler.bind(this);
        this.onMouseLeaveHandler = this.onMouseLeaveHandler.bind(this);
        this.ref = React.createRef();
    }

    onMouseEnterHandler() {
        const
            clientRect = this.ref.current && this.ref.current.getBoundingClientRect(),
            { top, width, height, left } = clientRect || {},
            { msg: text, calcPosition = calcToRightAndVCenter, dispatch, pointerDirection } = this.props;

        if (clientRect) {
            dispatch({
                type: SHOW_TOOLTIP_FOR_EXTENDED_PANEL,
                payload: {
                    position: calcPosition(clientRect),
                    elementDimensions: { top, width, height, left },
                    id: ExtendedPanelTooltip,
                    customClass: styles.helpTipContainer,
                    msg: { text },
                    pointerDirection
                }
            });
        }
    }

    onMouseLeaveHandler() {
        this.props.dispatch({ type: CLOSE_TOOLTIP });
    }

    render() {
        const { helpTipClassName } = this.props;
        const iconClassName = (helpTipClassName) ? styles[helpTipClassName] : styles.inserterQuestionTip;
        return (<span
            className={cx(styles.inserterHelpTip, iconClassName)}
            ref={this.ref}
            onMouseEnter={this.onMouseEnterHandler}
            onMouseLeave={this.onMouseLeaveHandler}
        />);
    }
}
