const { ConfigComponentList } = require("../constants.js");

class GenericWidgetsConfig {
    isGenericWidgetAllowed() {
        const genericWidgets = this._featuresConfig[ConfigComponentList.GENERIC_WIDGETS];
        return genericWidgets && genericWidgets.enabled;
    }
}

module.exports = GenericWidgetsConfig;
