
import { dialogManagerStateFromAppStateSelector, openedDialogIdsSelector } from "../../../DialogManager/epic/selectors";
import { BlogManagementDialogId } from "./BlogManagementDialog/dialogId";

const isBlogManagementDialogOpenSelector = (appState) => {
    const openDialogIds = openedDialogIdsSelector(dialogManagerStateFromAppStateSelector(appState));
    return openDialogIds.includes(BlogManagementDialogId);
};

export {
    isBlogManagementDialogOpenSelector
};
