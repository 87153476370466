import { getIntl } from "../../../view/intl/injectIntl";

const messages = {
    MENU_MORE: 'msg: common.More {More}',
    L1_F_TEXT: 'msg: layout1.footer.text {Simple. Easy. Clean.}',
    L3_F_HEADING1: `msg: layout3.footer.heading1 {We’d love your input}`,
    L3_F_HEADING3: `msg: layout3.footer.heading3 {We’re constantly striving to improve our services. Please reach out to us with your feedback.}`, // eslint-disable-line
    ContactUs: `msg: common.contactUsText {Contact us}`,
    GiveFeedback: `msg: common.giveFeedback {Give feedback}`,
    Heading1: `msg: common.gs.textStyle.heading1 {Heading 1}`,
    Heading2: `msg: common.gs.textStyle.heading2 {Heading 2}`,
    Heading3: 'msg: common.gs.textStyle.heading3 {Heading 3}',
    Logo: `msg: common.logo {Logo}`,
    Menu: `msg: component.menu.label {Menu}`,
    Booking: `msg: component.button.booking.label {Booking}`,
    Buttons: 'msg: common.buttonsComponent {Buttons}',
    SocialIcons: 'msg: common.socialIcons {Social icons}',
    ContactComponents: 'msg: common.contactComponents {Contact components}',
    PhoneNumber: 'msg: common.phoneNumber {Phone number}',
    Mail: 'msg: common.email {Email}',
    Address: 'msg: common.address {Address}',
    TermsAndPrivacyPolicy: 'msg: common.termsAndPrivacyPolicy {Terms and Privacy Policy}',
    PaymentMethods: 'msg: common.paymentMethods {Payment methods}',
};

const getMLayoutMessages = (): Record<string, any> => {
    const intl = getIntl(); // NOSONAR
    return Object.keys(messages).reduce((acc, key) => ({
        ...acc,
        [key]: intl ? intl.msgJoint(messages[key]) : messages[key],
    }), {});
};

export {
    getMLayoutMessages
};
