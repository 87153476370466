/* eslint-disable max-len */

import React from 'react';
import { Msg } from '../../../../view/intl/index';
import getCenteredDialogConfig from '../../../DialogManager/getCenteredDialogConfig';
import { closeDialog } from '../../../App/actionCreators/index';
import WarningDialog from "../../../../view/common/dialogs/WarningDialog/index";

type Props = {
    dispatch: Dispatch;
    isFbFeed?: boolean;
};
const
    FacebookDisableInfoDialogId = 'FacebookDisableInfoDialogId',
    width = 490,
    height = 350,
    FacebookDisableInfoDialog = ({ dispatch, isFbFeed }: Props) => {
        return (
            <WarningDialog
                title="msg: imageEditor.common.error {Error}"
                mctaText="msg: common.ok {OK}"
                mctaHandler={() => dispatch(closeDialog())}
            >
                {
                    isFbFeed && <Msg k="facebook.accountDisableMsg.fbFeed">
                        We’re sorry, but this feature is currently not available. We're working on resolving our connection with Facebook. In the meantime, delete this component in order to publish your website. Please come back later and try again. We apologise for the inconvenience.
                    </Msg>
                }

                {
                    !isFbFeed && <Msg k="facebook.accountDisableMsg.fbChat">
                        We’re sorry, but this feature is currently not available. We're working on resolving our connection with Facebook. Please come back later and try again. We apologise for the inconvenience.
                    </Msg>
                }
            </WarningDialog>
        );
    },
    dialog = getCenteredDialogConfig(width, height, FacebookDisableInfoDialog);

export { FacebookDisableInfoDialogId, dialog };
