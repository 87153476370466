export enum BlogInserterViewTypes {
    GET_STARTED = "GET_STARTED",
    MANAGE = "MANAGE",
    FREE_UPGRADE = "FREE_UPGRADE",
    UPGRADE = "UPGRADE"
}

export type BlogInserterDetails = {
    [key in BlogInserterViewTypes]: AnyReactComponent;
};
