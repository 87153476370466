import React from 'react';
import { Link } from "@mui/material";

import { openDialogAction } from "../../../Dialog/actions";
import { HELP_VIDEO_DIALOG } from "../../../Help/HelpVideoDialog";
import { PartnerName } from '../../../../../../../server/shared/partners/PartnerName.js';
import { partnerName } from "../../../../common/config";

export enum SITE_TASKS_KEYS {
    robotstxt = "robotstxt",
    consenlpro = "consenlpro",
    no404 = "no404",
    sslexists = "sslexists",
    newcont = "newcont",
    dupetitle = "dupetitle",
    dupecont = "dupecont",
    longurl = "longurl",
    responsivesite = "responsivesite",
    clicktocall = "clicktocall",
    googleplace = "googleplace",
    sitemap = "sitemap",
}

export enum CONTENT_KEYS {
    title,
    description,
    whatShouldIDo,
    whyIsThisImportant,
    tipText,
    bannerText,
    ctaText,
}

export const SITE_TASKS = {
    [SITE_TASKS_KEYS.robotstxt]: {
        [CONTENT_KEYS.title]: "msg: seoScan.task.robotstxt.title {Include a robots.txt file in your website}",
        [CONTENT_KEYS.description]: "msg: seoScan.task.robotstxt.text1 {The robots.txt file tells search engines how to crawl the pages of your website.}",
        [CONTENT_KEYS.whatShouldIDo]: "msg: seoScan.task.robotstxt.text2 {You don’t need to do anything. We automatically create the robots.txt file for you when you publish your website.}",
        [CONTENT_KEYS.whyIsThisImportant]: "msg: seoScan.task.robotstxt.text3 {A robots.txt file ensures that pages of your website that aren’t public yet, don’t get listed in search engine results. This can, for example, be useful when you have hidden pages on your website.}"
    },
    [SITE_TASKS_KEYS.consenlpro]: {
        [CONTENT_KEYS.title]: "msg: seoScan.task.consenlpro.title {Link to your website}",
        [CONTENT_KEYS.description]: "msg: seoScan.task.consenlpro.text1 {Make sure your website and related social media profiles are connected.}",
        [CONTENT_KEYS.whatShouldIDo]: "msg: seoScan.task.consenlpro.text2 {Go to your relevant social media profiles, for example on Facebook, Instagram or LinkedIn, and add a link to your website to the profile.}",
        [CONTENT_KEYS.whyIsThisImportant]: "msg: seoScan.task.consenlpro.text3 {The number of so-called “inbound-links” is a major factor in search engine rankings and increases the possibility of people finding and visiting your website. Since you own these social media profiles, you have a great opportunity to create trustworthy links to your website.}",
        [CONTENT_KEYS.ctaText]: "msg: common.gotIt {Got it}",
    },
    [SITE_TASKS_KEYS.no404]: {
        [CONTENT_KEYS.title]: "msg: seoScan.task.no404.title {Fix broken links}",
        [CONTENT_KEYS.description]: "msg: seoScan.task.no404.text1 {When a link leads to a non-existent page, visitors get a “Page not found” or “404” error. There are broken links on these pages on your website:}",
        [CONTENT_KEYS.whatShouldIDo]: "msg: seoScan.task.no404.text2 {Find the broken links on the pages listed above, and replace them with links that work. Check your website regularly for broken links.}",
        [CONTENT_KEYS.whyIsThisImportant]: "msg: seoScan.task.no404.text3 {Broken links create a bad user experience for your website visitors. They also negatively affect the SEO score of your website, because search engines rank websites with broken links lower.}",
        [CONTENT_KEYS.bannerText]: "msg: seoScan.task.no404.banner.text {Find the broken links on the relevant pages and replace them with links that work. Check your website regularly for broken links.}",
        [CONTENT_KEYS.ctaText]: "msg: common.goToEditor {Go to editor}",
    },
    [SITE_TASKS_KEYS.sslexists]: {
        [CONTENT_KEYS.title]: "msg: seoScan.task.sslexist.title {Use SSL/HTTPS}",
        [CONTENT_KEYS.description]: "msg: seoScan.task.sslexist.text1 {Using your SSL certificate by activating HTTPS increases the security of your website.}",
        [CONTENT_KEYS.whatShouldIDo]: "msg: seoScan.task.sslexist.text2 {In your Website Builder dashboard, go to Settings and then Security. On the Security page, activate SSL/HTTPS with the toggle.}",
        [CONTENT_KEYS.whyIsThisImportant]: "msg: seoScan.task.sslexist.text3 {SSL encrypts the connection between browser and server and that way makes the communication more secure. Websites with HTTPS rank higher in search engine results, and users trust websites more that use this security technology.}",
        [CONTENT_KEYS.bannerText]: "msg: seoScan.task.sslexists.banner.text {Go to Settings and then Security. On the Security page, activate SSL/HTTPS with the toggle.}",
        [CONTENT_KEYS.ctaText]: "msg: common.goToSecurityPage {Go to Security page}",
    },
    [SITE_TASKS_KEYS.newcont]: {
        [CONTENT_KEYS.title]: "msg: seoScan.task.newcont.title {Update your content}",
        [CONTENT_KEYS.description]: "msg: seoScan.task.newcont.text1 {You haven’t added new content or made updates to your website in the last 15 days.}",
        [CONTENT_KEYS.whatShouldIDo]: "msg: seoScan.task.newcont.text2 {Make sure you update your content on a regular basis. You can either add something new or change existing content, for example, rewrite a paragraph.}",
        [CONTENT_KEYS.whyIsThisImportant]: "msg: seoScan.task.newcont.text3 {Among the criteria to rank your websites is the so-called freshness score. Based on this, websites that frequently get updated are usually placed higher in the search results.}",
        [CONTENT_KEYS.ctaText]: "msg: common.goToEditor {Go to editor}",
        [CONTENT_KEYS.bannerText]: "msg: seoScan.task.newcont.banner.text {Make sure you update your content on a regular basis. You can either add something new or change existing content, for example, rewrite a paragraph.}",
    },
    [SITE_TASKS_KEYS.dupetitle]: {
        [CONTENT_KEYS.title]: "msg: seoScan.task.dupetitle.title {Change duplicate titles}",
        [CONTENT_KEYS.description]: "msg: seoScan.task.dupetitle.text1 {Each page on your website should have its own unique title. At the moment, the following pages on your site have duplicate titles:}",
        [CONTENT_KEYS.whatShouldIDo]: "msg: seoScan.task.dupetitle.text2 {Go to the SEO page in the dashboard and change the titles of some of the pages mentioned above. Make sure that every page title only exists once when you republish your website.}",
        [CONTENT_KEYS.whyIsThisImportant]: "msg: seoScan.task.dupetitle.text3 {Duplicate titles confuse search engines and compete against each other for keyword ranking. Unique titles also make it easier for your visitors to find the page that is relevant for them.}",
        [CONTENT_KEYS.bannerText]: "msg: seoScan.task.dupetitle.banner.text {Change the titles of some of the pages mentioned in the SEO scan. Make sure that every page title only exists once when you republish your website.}",
        [CONTENT_KEYS.ctaText]: "msg: common.goToSeoPage {Go to SEO page}",
    },
    [SITE_TASKS_KEYS.dupecont]: {
        [CONTENT_KEYS.title]: "msg: seoScan.task.dupetitle.title {Change duplicate titles}",
        [CONTENT_KEYS.description]: "msg: seoScan.task.dupetitle.text1 {Each page on your website should have its own unique title. At the moment, the following pages on your site have duplicate titles:}",
        [CONTENT_KEYS.whatShouldIDo]: "msg: seoScan.task.dupetitle.text2 {Go to the SEO page in the dashboard and change the titles of some of the pages mentioned above. Make sure that every page title only exists once when you republish your website.}",
        [CONTENT_KEYS.whyIsThisImportant]: "msg: seoScan.task.dupetitle.text3 {Duplicate titles confuse search engines and compete against each other for keyword ranking. Unique titles also make it easier for your visitors to find the page that is relevant for them.}"
    },
    [SITE_TASKS_KEYS.longurl]: {
        [CONTENT_KEYS.title]: "msg: seoScan.task.longUrl.title {Avoid long web addresses (URLs)}",
        [CONTENT_KEYS.description]: "msg: seoScan.task.longUrl.text1 {A good web address is precise, clear and short. The following pages have URLs that are too long:}",
        [CONTENT_KEYS.whatShouldIDo]: "msg: seoScan.task.longUrl.text2 {Go to the editor and change the URL(s) in the page settings. We recommend keeping them shorter than 75 characters. Make sure that all links work after changing the URLs.}",
        [CONTENT_KEYS.whyIsThisImportant]: "msg: seoScan.task.longUrl.text3 {Long web addresses can be confusing for visitors who want to find out what the page is about by simply looking at the URL. Well-structured URLs also rank better in search engines.}",
        [CONTENT_KEYS.bannerText]: "msg: seoScan.task.longUrl.banner.text {Change the URL(s) in the page settings. We recommend keeping them shorter than 75 characters. Make sure that all links work after changing the URLs.}",
        [CONTENT_KEYS.ctaText]: "msg: common.goToEditor {Go to editor}",
    },
    [SITE_TASKS_KEYS.responsivesite]: {
        [CONTENT_KEYS.title]: "msg: seoScan.task.responsivesite.title {Optimise for mobile devices}",
        [CONTENT_KEYS.description]: "msg: seoScan.task.responsivesite.text1 {It's important that your website not only looks great in the browser on a laptop but also on smaller screens of smartphones and tablets.}",
        [CONTENT_KEYS.whatShouldIDo]: (intl: Intl) => {
            return intl.msgJoint([
                "msg: seoScan.task.responsivesite.text2 {Go to your editor and activate mobile view to see what your website looks like on mobile devices. In our <link>guide</link> you’ll find helpful tips on how to best use the mobile editor.}",
                {
                    link: (...chunks: string[]) => (
                        <Link
                            href={intl.msgJoint('msg: mobileLogo.learnMore {https://help.one.com/hc/en-us/articles/360002274197}')}
                            target="_blank"
                        >
                            {chunks}
                        </Link>
                    )
                }
            ]);
        },
        [CONTENT_KEYS.whyIsThisImportant]: "msg: seoScan.task.responsivesite.text3 {Today, most people worldwide visit websites from their phone or tablet, rather than their desktop device. That’s why search engines give websites that are optimised for mobile devices better rankings in their search results.}",
        [CONTENT_KEYS.bannerText]: (intl: Intl, classes: Record<string, string>) => {
            return intl.msgJoint([
                "msg: seoScan.task.responsivesite.banner.text {Activate mobile view to see what your website looks like on mobile devices. In our <link>guide</link> you’ll find helpful tips on how to best use the mobile editor.}",
                {
                    link: (...chunks: string[]) => (
                        <Link
                            href={intl.msgJoint('msg: mobileLogo.learnMore {https://help.one.com/hc/en-us/articles/360002274197}')}
                            target="_blank"
                            className={classes.link}
                        >
                            {chunks}
                        </Link>
                    )
                }
            ]);
        },
        [CONTENT_KEYS.ctaText]: "msg: common.goToEditor {Go to editor}",
    },
    [SITE_TASKS_KEYS.clicktocall]: {
        [CONTENT_KEYS.title]: "msg: seoScan.task.clicktocall.title {Add a “Call now“ button}",
        [CONTENT_KEYS.description]: "msg: seoScan.task.clicktocall.text1 {People who are visiting your site from a mobile phone should be able to easily contact you by simply tapping a phone number.}",
        [CONTENT_KEYS.whatShouldIDo]: "msg: seoScan.task.clicktocall.text2 {Go to the editor and add a button to the header of your website. Make sure the button links to your phone number and call it something like “Call now”.}",
        [CONTENT_KEYS.whyIsThisImportant]: "msg: seoScan.task.clicktocall.text3 {So-called “Click-to-call” links provide a great user experience because they make it easy for your visitors to get in touch with you.}",
        [CONTENT_KEYS.bannerText]: "msg: seoScan.task.clicktocall.banner.text {Add a button to the header of your website. Make sure the button links to your phone number and call it something like “Call now”.}",
        [CONTENT_KEYS.ctaText]: "msg: common.goToEditor {Go to editor}",
    },
    [SITE_TASKS_KEYS.googleplace]: {
        [CONTENT_KEYS.title]: "msg: seoScan.task.googleplace.title {Verify your business on Google}",
        [CONTENT_KEYS.description]: "msg: seoScan.task.googleplace.text1 {Connect your website to Google My Business to ensure that your business can be found on Google Search and Google Maps.}",
        [CONTENT_KEYS.whatShouldIDo]: (intl: Intl) => {
            return intl.msgJoint([
                "msg: seoScan.task.googleplace.text2 {Go to the Business listing page and either create a new listing or connect an existing listing to your website. You can find all steps in <link>our guide</link>.}",
                {
                    link: (...chunks: string[]) => (
                        <Link
                            href={intl.msgJoint('msg: gmb.learnMore {https://help.one.com/hc/en-us/articles/360018987477}')}
                            target="_blank"
                        >
                            {chunks}
                        </Link>
                    )
                }
            ]);
        },
        [CONTENT_KEYS.whyIsThisImportant]: "msg: seoScan.task.googleplace.text3 {Google My Business is a great way to increase your local SEO score. Visitors can find your business information and your website across Google platforms, for example on Google Maps, and will be able to contact you easily.}",
        [CONTENT_KEYS.bannerText]: "msg: seoScan.task.googleplace.banner.text {Go to the Business listing page and either create a new listing or connect an existing listing to your website. You can find all steps in our guide.}",
        [CONTENT_KEYS.ctaText]: "msg: common.goToBusinessListing {Go to Business listing}",
    },
    [SITE_TASKS_KEYS.sitemap]: {
        [CONTENT_KEYS.title]: "msg: seoScan.task.sitemap.title {Include a sitemap in your website}",
        [CONTENT_KEYS.description]: "msg: seoScan.task.sitemap.text1 {A sitemap is an HTML file that includes a list of all pages of your website as well as general information, for example, how often you update your website and when it was last updated.}",
        [CONTENT_KEYS.whatShouldIDo]: "msg: seoScan.task.sitemap.text2 {You don’t need to do anything. We automatically create the sitemap for you, when you publish your website.}",
        [CONTENT_KEYS.whyIsThisImportant]: "msg: seoScan.task.sitemap.text3 {The sitemap helps search engines find out about all the pages on your website. It makes sure that they can find your website easily and have it show up in search results.}",
        [CONTENT_KEYS.ctaText]: "msg: common.gotIt {Got it}",
    }
};

export enum PAGE_TASKS_KEYS {
    nodescsingle = "nodescsingle",
    lowcontentsingle = "lowcontentsingle",
    imgaltsingle = "imgaltsingle",
    longtitlesingle = "longtitlesingle",
    notitlesingle = "notitlesingle",
    keyword = "keyword",
    keywordinurl = "keywordinurl",
    keywordintitle = "keywordintitle",
    keyonh1 = "keyonh1",
    keyonhxorpara = "keyonhxorpara",
}

export const PAGE_TASKS = {
    [PAGE_TASKS_KEYS.nodescsingle]: {
        [CONTENT_KEYS.title]: "msg: seoScan.task.nodescsingle.title {Write a meta description of this page}",
        [CONTENT_KEYS.description]: "msg: seoScan.task.nodescsingle.text1 {This page is missing a meta description. Adding a meta description for every page of your website allows you to control what users see when your page appears in search results.}",
        [CONTENT_KEYS.whatShouldIDo]: "msg: seoScan.task.nodescsingle.text2 {Go to the SEO page and edit the field \"Page meta description\". It should be a short summary of the page using relevant keywords.}",
        [CONTENT_KEYS.whyIsThisImportant]: "msg: seoScan.task.nodescsingle.text3 {The meta description serves as a preview of what can be found on your page. Although search engines don't factor this description heavily into rankings, it can greatly impact whether or not people decide to click on the link to visit your website.}",
        [CONTENT_KEYS.bannerText]: "msg: seoScan.task.nodescsingle.banner.text {Add a short summary of this page in the field \"Page meta description\".}",
        [CONTENT_KEYS.ctaText]: "msg: common.goToSeoPage {Go to SEO page}",
    },
    [PAGE_TASKS_KEYS.lowcontentsingle]: {
        [CONTENT_KEYS.title]: "msg: seoScan.task.lowcontentsingle.title {Add more content to this page}",
        [CONTENT_KEYS.description]: "msg: seoScan.task.lowcontentsingle.text1 {This page could use more content. It's recommended to have at least 300 words on each page to rank higher in search engine results and increase visitor engagement.}",
        [CONTENT_KEYS.whatShouldIDo]: "msg: seoScan.task.lowcontentsingle.text2 {Go to the editor and add more content to this page. Make sure the content is relevant to your website visitors. You can, for example, add more information about your company or write longer product descriptions.}",
        [CONTENT_KEYS.whyIsThisImportant]: "msg: seoScan.task.lowcontentsingle.text3 {Longer content makes it easier for search engines to understand what the page is about. Based on this, the page will show up higher in relevant search results. On top of that, users prefer pages with more information.}",
        [CONTENT_KEYS.bannerText]: "msg: seoScan.task.lowcontentsingle.banner.text {This page could use more content. Add content and make sure it's relevant to your website visitors.}",
        [CONTENT_KEYS.ctaText]: "msg: common.goToEditor {Go to editor}",
    },
    [PAGE_TASKS_KEYS.imgaltsingle]: {
        [CONTENT_KEYS.title]: "msg: seoScan.task.imgaltsingle.title {Add alt text for all images}",
        [CONTENT_KEYS.description]: "msg: seoScan.task.imgaltsingle.text1 {Alternative text (alt text) is missing for some images on this page. Alt text is a hidden description of an image that search engines use to analyse the full content of your website.}",
        [CONTENT_KEYS.whatShouldIDo]: "msg: seoScan.task.imgaltsingle.text2 {Add alt text to all images on this page. To edit alt text, go to the editor, select an image, click \"Edit image\" and then \"SEO\". Keep the text at 125 characters or less and make sure it describes what you see in the image.}",
        [CONTENT_KEYS.whyIsThisImportant]: "msg: seoScan.task.imgaltsingle.text3 {Not only does this text make your website accessible to those with visual impairments, but it also helps search engines index visual content on your website and boost its SEO value.}",
        [CONTENT_KEYS.bannerText]: "msg: seoScan.task.imgaltsingle.text2 {Add alt text to all images on this page. To edit alt text, go to the editor, select an image, click \"Edit image\" and then \"SEO\". Keep the text at 125 characters or less and make sure it describes what you see in the image.}",
        [CONTENT_KEYS.ctaText]: "msg: common.goToEditor {Go to editor}",
    },
    [PAGE_TASKS_KEYS.longtitlesingle]: {
        [CONTENT_KEYS.title]: "msg: seoScan.task.longtitlesingle.title {Shorten your page title}",
        [CONTENT_KEYS.description]: "msg: seoScan.task.longtitlesingle.text1 {The title for this page is too long. Ensure that your page titles are 70 characters or less.}",
        [CONTENT_KEYS.whatShouldIDo]: "msg: seoScan.task.longtitlesingle.text2 {Go to the SEO page and shorten the page title text. Make sure to include relevant keywords, while keeping it concise.}",
        [CONTENT_KEYS.whyIsThisImportant]: "msg: seoScan.task.longtitlesingle.text3 {Page titles are one of the main factors search engines look at when ranking a page for relevance. They serve as a preview to potential visitors to your website, so it must be clear to them what they should expect to find if they open the page.}",
        [CONTENT_KEYS.bannerText]: "msg: seoScan.task.longtitlesingle.banner.text {Shorten the title of this page. Make sure to include relevant keywords, while keeping it concise.}",
        [CONTENT_KEYS.ctaText]: "msg: common.goToSeoPage {Go to SEO page}",
    },
    [PAGE_TASKS_KEYS.keyword]: {
        [CONTENT_KEYS.title]: "msg: seoScan.task.keyword.title {Choose a keyword for this page}",
        [CONTENT_KEYS.description]: [
            ...(partnerName === PartnerName.ONECOM ? [
                "msg: seoScan.task.keyword.text1 {Keywords are the main words or phrases that describe what your website is about. Each page should have its own focus keyword. It should be used across the page in headers and paragraphs. It’s recommended to also include variations of the focus keyword throughout the page.}",
                (intl: Intl, _: Record<string, string>, dispatch: Dispatch) => {
                    return intl.msgJoint([
                        "msg: seoScan.task.keyword.text2 {Watch this <link>video</link> and learn from our marketing experts what a good keyword is.}",
                        {
                            link: (...chunks: string[]) => (
                                <Link
                                    onClick={(_event: any) => {
                                        _event.preventDefault();
                                        dispatch(openDialogAction(HELP_VIDEO_DIALOG, {
                                            url: "https://www.youtube.com/embed/cvTiifJLtOg?rel=0&autoplay=1"
                                        }));
                                    }}
                                >
                                    {chunks}
                                </Link>
                            )
                        }
                    ]);
                }
            ] : [
                "msg: seoScan.task.keyword.text1 {Keywords are the main words or phrases that describe what your website is about. Each page should have its own focus keyword. It should be used across the page in headers and paragraphs. It’s recommended to also include variations of the focus keyword throughout the page.}"
            ])

        ],
        [CONTENT_KEYS.whatShouldIDo]: "msg: seoScan.task.keyword.text3 {Decide on a focus keyword for this page and add it below. Make sure to include that keyword and variations of it in the content of this page.}",
        [CONTENT_KEYS.whyIsThisImportant]: "msg: seoScan.task.keyword.text4 {Keywords are crucial for your search engine ranking. You can think of them as a way for search engines to filter the huge number of existing websites to show people exactly what they’re looking for.}",
        [CONTENT_KEYS.tipText]: "msg: seoScan.task.keyword.tip.text {If you’re unsure which keyword to choose, think about what you would put in the search field of search engines if you wanted to find a website like yours.}",
    },
    [PAGE_TASKS_KEYS.keywordinurl]: {
        [CONTENT_KEYS.title]: "msg: seoScan.task.keywordinurl.title {Include a keyword in your page URL}",
        [CONTENT_KEYS.description]: "msg: seoScan.task.keywordinurl.text1 {You can think of your URL as your second page title. Website visitors and search engines will understand much better what your page is about if you have the focus keyword included in your URL.}",
        [CONTENT_KEYS.whatShouldIDo]: "msg: seoScan.task.keywordinurl.text2 {Go to the Page names tab in your page settings and add the keyword to the page URL.}",
        [CONTENT_KEYS.whyIsThisImportant]: "msg: seoScan.task.keywordinurl.text3 {If you use a keyword in your page URL, it will be easier for website visitors to understand if this page is relevant to them or not.}",
        [CONTENT_KEYS.bannerText]: "msg: seoScan.task.keywordinurl.banner.text {Add this pages keyword to the URL.}",
        [CONTENT_KEYS.ctaText]: "msg: common.goToEditor {Go to editor}",
    },
    [PAGE_TASKS_KEYS.keywordintitle]: {
        [CONTENT_KEYS.title]: "msg: seoScan.task.keywordintitle.title {Include a keyword in your page title}",
        [CONTENT_KEYS.description]: "msg: seoScan.task.keywordintitle.text1 {The page title is the first glimpse people get of your website. Including the focus keyword in the title makes it easier for visitors to find what they’re looking for.}",
        [CONTENT_KEYS.whatShouldIDo]: [
            "msg: seoScan.task.keywordintitle.text2 {Go to the SEO page in the dashboard and add the focus keyword of this page to your page title. Make sure the keyword represents what the page is about and sounds natural in the page title.}",
            "msg: seoScan.task.keywordintitle.text3 {Example page title: The Danish vintage furniture company - Hansen Upholstery}",
        ],
        [CONTENT_KEYS.whyIsThisImportant]: "msg: seoScan.task.keywordintitle.text4 {Using the focus keyword in your page title increases the possibility that your website will show up high in search results. Also, it helps visitors to easily see if the page is relevant for them.}",
        [CONTENT_KEYS.bannerText]: "msg: seoScan.task.keywordintitle.banner.text {Add the focus keyword of this page to your page title. Make sure the keyword represents what the page is about and sounds natural in the page title.}",
        [CONTENT_KEYS.ctaText]: "msg: common.goToSeoPage {Go to SEO page}",
    },
    [PAGE_TASKS_KEYS.keyonh1]: {
        [CONTENT_KEYS.title]: "msg: seoScan.task.keyonh1.title {Use your page keyword in a main header on this page}",
        [CONTENT_KEYS.description]: "msg: seoScan.task.keyonh1.text1 {Search engines pay particular attention to the main headers on each page, also called “Heading 1”. Your page will rank higher in search results, if you use your selected keyword in the main heading.}",
        [CONTENT_KEYS.whatShouldIDo]: "msg: seoScan.task.keyonh1.text2 {Go to the editor, select the text component for one of your headers on this page, click “Edit text”, and change the text style to “Heading 1” in the dropdown menu. Make sure to update the text to include your selected keyword for this page if it doesn’t already.}",
        [CONTENT_KEYS.whyIsThisImportant]: "msg: seoScan.task.keyonh1.text4 {Using the page keyword in the heading 1 will improve your visibility in search engine results. It also makes it easier for your website visitors to see if the page is relevant for them.}",
        [CONTENT_KEYS.bannerText]: "msg: seoScan.task.keyonh1.banner.text {Select the text component for one of your headers on this page. Click “Edit text” and change the style of the text to “Heading 1” in the dropdown menu. Include your selected keyword for this page in the header text.}",
        [CONTENT_KEYS.ctaText]: "msg: common.goToEditor {Go to editor}",
    },
    [PAGE_TASKS_KEYS.keyonhxorpara]: {
        [CONTENT_KEYS.title]: "msg: seoScan.task.keyonhx.title {Use variations of your keyword in the texts on this page}",
        [CONTENT_KEYS.description]: "msg: seoScan.task.keyonhx.text1 {It’s important for your search engine ranking that the texts on your page refer to your focus keyword, without simply repeating the exact keyword. Instead, you can use variations of the keyword you chose, for example, synonyms or similar phrases.}",
        [CONTENT_KEYS.whatShouldIDo]: "msg: seoScan.task.keyonhx.text2 {Go to the editor and add your selected keyword at least once in the text on this page. Then, use variations of your selected keyword in different places across your text. Make sure the keyword and variations sound natural in the context when you add them.}",
        [CONTENT_KEYS.whyIsThisImportant]: "msg: seoScan.task.keyonhx.text3 {Using your selected keyword and variations of it throughout the texts on your page increases the chance that this page will rank higher in search engine results. However, repeating the exact keyword too often can have a negative affect on your ranking.}",
        [CONTENT_KEYS.bannerText]: "msg: seoScan.task.keyonhx.banner.text {Add your selected keyword and variations of it to the texts across this page.}",
        [CONTENT_KEYS.ctaText]: "msg: common.goToEditor {Go to editor}",
    }
};

export enum ONGOING_TASKS_KEYS {
    backlinks = "backlinks",
    updatecontent = "updatecontent"
}

export const ONGOING_TASKS = {
    [ONGOING_TASKS_KEYS.backlinks]: {
        [CONTENT_KEYS.title]: "msg: seoScan.task.backlinks.title {Get relevant websites to link to your site}",
        [CONTENT_KEYS.description]: "msg: seoScan.task.backlinks.text1 {Search engines evaluate your website as more trustworthy and relevant if other, related websites link to it. In SEO terms, this is called \"backlinks\".}",
        [CONTENT_KEYS.whatShouldIDo]: "msg: seoScan.task.backlinks.text2 {This is all about networking and creating connections in your industry online. Reach out to owners of other websites that are highly relevant to you and your website’s target audience and see if they will add a link to your website in their content.}",
        [CONTENT_KEYS.whyIsThisImportant]: "msg: seoScan.task.backlinks.text3 {The more relevant websites that link to your site, the more search engines consider it relevant and rank it higher in search results. Having other websites link to your site is also a great way to gain more organic traffic because it makes people aware of your website and can help you reach your target audience.}"
    },
    [ONGOING_TASKS_KEYS.updatecontent]: {
        [CONTENT_KEYS.title]: "msg: seoScan.task.updatecontent.title {Update your content regularly}",
        [CONTENT_KEYS.description]: "msg: seoScan.task.updatecontent.text1 {Make sure to update your content regularly to keep your website high in search engine rankings. In order to let search engines know that your website is up to date, we recommend adding or editing content every 2 weeks.}",
        [CONTENT_KEYS.whatShouldIDo]: "msg: seoScan.task.updatecontent.text2 {You could add a new page detailing your services, write new FAQ answers, update information about your recent projects, for example. Be sure to use keywords that your potential visitors might use when searching, like locations and the names of your products and services.}",
        [CONTENT_KEYS.whyIsThisImportant]: "msg: seoScan.task.updatecontent.text3 {When deciding which websites to rank first in their listings, search engines consider the relevance of a website\'s content. This is, amongst other things, based on how up to date the content of your website is.}"
    }
};

export const GO_TO_EDITOR_TO_PUBLISH_CONFIG = {
    [CONTENT_KEYS.title]: "msg: seoScan.publishToSeeScore.title {Publish to see score}",
    [CONTENT_KEYS.bannerText]: "msg: seoScan.publishToSeeScore.message {Once you've published your website, we can show you its SEO score and suggest how to improve it.}",
};

export enum TASK_TYPE {
    page = "page",
    site = "site",
    ongoing = "ongoing"
}

export const TOTAL_TASKS_IN_A_PAGE = 9;
export const KEYWORD_DEPENDANT_TASKS = 4;
