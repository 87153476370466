import type { TagName, TinyMceEditor } from "../../../flowTypes";
import { makeExistingParentTagSelector } from "../../utils/selectors/makeExistingParentTagSelector";
import { getSelectedTextNodes } from "../../utils/nodeUtils/getSelectedNodes";

const isVerticalAlign = (editor: TinyMceEditor, tagName: TagName) => {
    if (!editor || editor.removed) {
        return false;
    }

    const selectedNodes = getSelectedTextNodes(editor);
    const firstNode = selectedNodes[0];
    if (!firstNode) {
        return false;
    }

    const root = editor.getBody();
    const tagSelector = makeExistingParentTagSelector(root, [tagName]);
    // $FlowFixMe: WBTGEN-9962: Nodes don't have tag, put check
    const firstTag = tagSelector(firstNode as HTMLElement);
    if (firstTag && firstTag.tagName === tagName) {
        return true;
    }

    return false;
};

export const isSuperscript = (editor: TinyMceEditor) => isVerticalAlign(editor, 'SUP');
export const isSubscript = (editor: TinyMceEditor) => isVerticalAlign(editor, 'SUB');
