/* eslint-disable max-len */

import React from 'react';
import cx from 'classnames';
import { connect } from "react-redux";
import styles from './TrialImportPage.css';
import { trialImportAppSel } from "../selectors";
import { injectIntl } from "../../../../src/view/intl/index";
import type { TrialImportPageViewPropTypes } from "../flowTypes";
import type { AppState } from "../../../../src/redux/modules/flowTypes";
import Msg from "../../../../src/view/intl/Msg";
import TrialLanguageSelector from "../../login/main/TrialLanguageSelector";
import { makeEpicStateSelector } from "../../../../src/epics/makeEpic";
import languagesVAT from "../../../../src/components/TopBar/epics/languages/valueActionType";
import logout from "../../../../src/components/TopBar/actionCreators/logout";
import Button from "../../../../src/view/common/Button/Button";
import { trialImportDataAction } from "../actions";
import { returnToOnboardingAction } from "../../../../src/components/TemplateSelector_DEPRECATED/actionTypes";
import { getAppConfig } from "../../../../src/components/App/epics/appConfig/appConfig.js";
import TermsnConditionsUrlLink from '../../dataPrivacyLinks/TermsnConditionsUrlLink';
import PrivacyPolicyUrlLink from '../../dataPrivacyLinks/PrivacyPolicyUrlLink';
import CookiePolicyUrlLink from '../../dataPrivacyLinks/CookiePolicyUrlLink';

class TrialImportPageView extends React.Component <TrialImportPageViewPropTypes> {
    continueToWebsiteBuilder = () => {
        this.props.dispatch(returnToOnboardingAction());
    };
    initiateTrialImport = (trialImportEmail, trialImportSubID, trialImportToken) => {
        this.props.dispatch(trialImportDataAction(trialImportEmail, trialImportSubID, trialImportToken));
    };

    render() {
        const {
                intl,
                dispatch,
                languages,
                state: {
                    trialDataImporting,
                    trialImportAvailable,
                    trialImportEmail,
                    trialImportSubID,
                    trialImportToken,
                    trialImportAvailabilityScreenShot,
                },
            } = this.props,
            appConfig = getAppConfig(),
            trialConfig = appConfig.oneWeb.trial;

        const
            endDateYear = new Date().getFullYear(),
            startYear = trialConfig.startYear,
            partnerName = trialConfig.partnerName,
            termsOfUse = (
                <TermsnConditionsUrlLink
                    className={styles.footerMsgLink}
                />),
            privacyPolicy = (
                <PrivacyPolicyUrlLink
                    className={styles.footerMsgLink}
                />
            ),
            cookiePolicy = (
                <CookiePolicyUrlLink
                    className={styles.footerMsgLink}
                />
            );

        const trialAvailableDynamicScreenShotStyle = trialImportAvailabilityScreenShot ?
            {
                background: `url(${trialImportAvailabilityScreenShot}) center center no-repeat`,
            } : {};

        if (trialImportAvailable) {
            return (
                <div className={styles.trialImportPageContainer}>
                    <div className={styles.logoContainer}>
                        <span className={styles.oneComIcon} />
                        <span
                            className={styles.logout}
                            onClick={() => {
                                dispatch(logout({}));
                            }}
                        >
                            {intl.msgJoint('msg: common.logout {Log out}')}
                        </span>
                    </div>
                    <div className={styles.importActionContainer}>
                        <div className={styles.webSitePreviewContainer}>
                            <div className={styles.previewTopBar} />
                            <div className={styles.windowButtons}>&nbsp;</div>
                            <div className={styles.websitePreview} style={trialAvailableDynamicScreenShotStyle} />
                        </div>

                        <div className={styles.actionItemContainer}>
                            <div className={styles.keepProgress}>
                                {intl.msgJoint('msg: trial.import.keepProgress {Keep your progress?}')}
                            </div>
                            <span className={styles.keepProgressMsg}>
                                {/* eslint-disable-next-line max-len */}
                                {intl.msgJoint('msg: trial.import.keepProgressMsg {Good work! Looks like you already started building a website with the trial version of Website Builder. Would you like to continue where you left off?}')}
                            </span>
                            <Button
                                onClick={() => {
                                    this.continueToWebsiteBuilder();
                                }}
                                className={cx(styles.importButton, styles.noThanksButton)}
                            >
                                {intl.msgJoint('msg: trial.import.noThanks {No thanks}')}
                            </Button>

                            <Button
                                onClick={() => {
                                    this.initiateTrialImport(trialImportEmail, trialImportSubID, trialImportToken);
                                }}
                                className={cx(styles.importButton, {
                                    [styles.loadingButton]: trialDataImporting
                                })}
                            >
                                {!trialDataImporting && intl.msgJoint('msg: trial.import.keepProgressButton {Yes, keep my progress}')}
                                {trialDataImporting && <div className={styles.loader} />}
                            </Button>
                        </div>

                    </div>

                    <div className={cx(styles.trialImportFooter, styles.trialImportFooterLanguageSelectorStyles)}>
                        <div className={styles.invisibleItem} />
                        <Msg
                            k="demo.footer.msg"
                            params={{ endYear: endDateYear,
                                startYear,
                                partnerName,
                                termsOfUse,
                                privacyPolicy,
                                cookiePolicy }}
                        >
                            {`Copyright © {startYear} - {endYear} {partnerName}. All rights reserved. {cookiePolicy}, {privacyPolicy} and {termsOfUse}.`}
                        </Msg>

                        <div className={styles.languageSelector}>
                            <TrialLanguageSelector
                                // @ts-ignore
                                dispatch={dispatch}
                                languages={languages}
                            />
                        </div>
                    </div>
                </div>
            );
        }
        return '';
    }
}

const languagesEpicStateSelector = makeEpicStateSelector(languagesVAT);

const mapStateToProps = (appState: AppState) => ({
    state: trialImportAppSel(appState),
    languages: languagesEpicStateSelector(appState)
});

export const TrialImportPage = connect(mapStateToProps)(injectIntl(TrialImportPageView));
