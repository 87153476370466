import { shouldAddGoogleAdsCode } from "../../../../../../server/shared/utils/shouldAddGoogleAdsCode.js";
import { getChatWidgetData } from "../../../../../../server/shared/utils/getChatWidgetData.js";

export const wrapTrackingScriptForConsent = (code: string, category: string, funcName: string) => {
    return `
        (function() {
            function ${funcName}() {
                if (getTermlyConsentForCategory("${category}")) {
                    window.removeEventListener("TermlyConsentUpdated", ${funcName});
                    ${code}
                }
            }

            window.addEventListener("TermlyConsentUpdated", ${funcName});

            ${funcName}();
        }());
    `;
};

export const createGtmLibScriptTag = ({ gaAllowed, userHasEcommerceSubscription, siteSettings }) => {
    // WBTGEN-16354 WBTGEN-18549
    const trackingData = siteSettings.trackingData;

    if ((gaAllowed || userHasEcommerceSubscription) && trackingData) {
        const ids = trackingData.gaMeasurementId || '';
        const unprioritisedIds = (
            ids
                .split(',')
                .map(id => id.trim())
                .filter(x => x)
        );

        const
            arrGtmHyphenTags: string[] = [],
            arrGtHyphenTags: string[] = [],
            arrGHyphenTags: string[] = [],
            arrRemainingTags: string[] = []; // It would remain empty as of the current validations implementation

        unprioritisedIds.forEach((id) => {
            const upperCaseId = id.toUpperCase();
            if (upperCaseId.indexOf('GTM-') === 0) {
                arrGtmHyphenTags.push(id);
            } else if (upperCaseId.indexOf('GT-') === 0) {
                arrGtHyphenTags.push(id);
            } else if (upperCaseId.indexOf('AW-') === 0) {
                arrGtHyphenTags.push(id);
            } else if (upperCaseId.indexOf('G-') === 0) {
                arrGHyphenTags.push(id);
            } else {
                arrRemainingTags.push(id);
            }
        });

        const prioritisedIds = (
            ([] as string[]) // NOSONAR
                .concat(arrGtHyphenTags)
                .concat(arrGHyphenTags)
                .concat(arrRemainingTags)
        );

        if (prioritisedIds.length || arrGtmHyphenTags.length) {
            let scriptCode = '';

            if (prioritisedIds.length) {
                // https://developers.google.com/tag-platform/gtagjs/install
                const gtCode = "window.dataLayer = window.dataLayer || [];" +
                    "  function gtag(){dataLayer.push(arguments);}" +
                    "  gtag('js', new Date());" +
                    // https://community.cookiepro.com/s/article/UUID-d81787f6-685c-2262-36c3-5f1f3369e2a7
                    // https://developers.google.com/tag-platform/tag-manager/templates/consent-apis#developer_id
                    "  gtag('set', 'developer_id.dNjUxNG', true);" +
                    (function () {
                        let str = '';
                        prioritisedIds.forEach((id) => {
                            str += "  gtag('config', '" + id + "');";
                        });
                        return str;
                    }());

                const gtScriptCode =
                    `const gtmLibScriptTag = document.createElement('script');
                    gtmLibScriptTag.setAttribute('type', 'text/javascript');
                    gtmLibScriptTag.setAttribute('key', 'g-tag-created-lib-script');
                    const scriptSrc = "https://www.googletagmanager.com/gtag/js?id=${prioritisedIds[0]}";
                    gtmLibScriptTag.src = scriptSrc;
                    document.getElementById('tracking-scripts-container').appendChild(gtmLibScriptTag);`;

                scriptCode = scriptCode + gtCode + gtScriptCode;
            }

            if (arrGtmHyphenTags.length) {
                const gtmCode = `window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                        'gtm.start': new Date().getTime(),
                        event: 'gtm.js'
                    });`;

                const gtmScriptCode = arrGtmHyphenTags.map(gtmId => {
                    return `
                        var firstScript = document.getElementsByTagName('script')[0];
                        var newScript = document.createElement('script');
                        newScript.async = true;
                        newScript.src = 'https://www.googletagmanager.com/gtm.js?id=${gtmId}';
                        firstScript.parentNode.insertBefore(newScript, firstScript);
                    `;
                }).join(' ');

                scriptCode = scriptCode + gtmCode + gtmScriptCode;
            }

            return scriptCode;
        }
    }

    return '';
};

export const getFacebookPixelCode = ({ fbPixelAllowed, userHasEcommerceSubscription, siteSettings }) => {
    // WBTGEN-16354 WBTGEN-18548
    const trackingData = siteSettings.trackingData;

    if ((fbPixelAllowed || userHasEcommerceSubscription) && trackingData) {
        if (trackingData.pixelId) {
            const code = "!function(f,b,e,v,n,t,s)" +
                "  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?" +
                "  n.callMethod.apply(n,arguments):n.queue.push(arguments)};" +
                "  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';" +
                "  n.queue=[];t=b.createElement(e);t.async=!0;" +
                "  t.src=v;s=b.getElementsByTagName(e)[0];" +
                "  s.parentNode.insertBefore(t,s)}(window, document,'script'," +
                "  'https://connect.facebook.net/en_US/fbevents.js');" +
                "  fbq('init', '" + trackingData.pixelId + "');" +
                "  fbq('track', 'PageView');";

            return code;
        }
    }

    return '';
};

export const getFBChatScript = ({ siteSettings }) => {
    const chatWidgetData = getChatWidgetData(siteSettings);
    const code = "window.fbAsyncInit = function() {" +
        " FB.init({" +
        " xfbml            : true," +
        " version          : 'v17.0'" +
        " });" +
        " };" +
        " (function(d, s, id) {" +
        " var js, fjs = d.getElementsByTagName(s)[0];" +
        " if (d.getElementById(id)) return;" +
        " js = d.createElement(s); js.id = id;" +
        " js.src = 'https://connect.facebook.net/" + chatWidgetData.chatWidgetLanguage + "/sdk/xfbml.customerchat.js';" +
        " fjs.parentNode.insertBefore(js, fjs);" +
        " }(document, 'script', 'facebook-jssdk'));";

    return code;
};

export const getGoogleAdsCode = ({ trackingScript, siteSettings }) => {
    if (trackingScript && shouldAddGoogleAdsCode(trackingScript, siteSettings)) {
        // eslint-disable-next-line
        const trackingCode = trackingScript.replace(/<script([\s\S]*?)>([\s\S]*?)<\/script>/g, function(_, __, code) { //NOSONAR
            return code;
        });

        return trackingCode;
    }
    return '';
};

export const getKlikenTrackingCode = ({ thirdPartyConnections }) => {
    const analytics = thirdPartyConnections && thirdPartyConnections.analytics;

    if (analytics) {
        const { status, enabled, accountId, enabledAt } = analytics;
        // TODO: WBTGEN-24918: To correct this with isAnalyticsEnabled util.
        const
            isAnalyticsEnabledOld = (typeof enabled === 'undefined' && (status === 'ENABLED' || enabledAt)),
            isAnalyticsEnabled = enabled || isAnalyticsEnabledOld;

        if (isAnalyticsEnabled && accountId) {
            const code = `
                var _swaMa = ["${accountId}"];
                "undefined" == typeof swonestats && (function (e, a, t) {
                function s() {
                    for (; i[0] && "loaded" == i[0][f];)
                        (c = i.shift())[o] = !d.parentNode.insertBefore(c, d);
                }
                for (var n,r,c,i = [],d = e.scripts[0],o = "onreadystatechange",f = "readyState";(n = t.shift());)
                    (r = e.createElement(a)),
                        "async" in d
                            ? ((r.async = !1), e.head.appendChild(r))
                            : d[f]
                                ? (i.push(r), (r[o] = s))
                                : e.write("<" + a + ' src="' + n + '" defer></' + a + ">"),
                        (r.src = n);
                })(document, "script", ["//analytics.sitewit.com/swonestats/" + _swaMa[0] + "/noconnect/sw.js"]);
            `;

            return code;
        }
    }

    return '';
};
