import { makeCombineReducer, makeComponentBaseReducers, makeDefaultStateReducers } from "../../../../../redux/makeReducer";
import BlogKind from "../kind";
import { defaultState } from "./defaultState";

export default makeCombineReducer({
    combineReducers: {
        ...makeComponentBaseReducers(BlogKind),

        // component has these default dimensions when inserted
        ...makeDefaultStateReducers(defaultState)
    },
    handleActions: {}
});
