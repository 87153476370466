import { tinyMceDomParser, tinyMceHtmlSerializer } from "../../editorSetup";
import {
    CONTENT_TYPE_TO_CLASS_MAP,
    CONTENT_TYPES,
    REQUIRED_FIELDS,
    VALIDATION_FIELDS
} from "../../AIText/Dialogs/CreateAIText/constants";

export const transformAITextOnReplace = (oldContent, content, contentType = CONTENT_TYPES.paragraph1) => {
    let transformedContent = '';
    const transformNode = tinyMceDomParser.parse(oldContent);
    let currentNode = transformNode.firstChild;

    while (currentNode && currentNode.name.includes("div")) {
        const childNode = tinyMceHtmlSerializer.serialize(currentNode);
        if (childNode.includes("data-wrap-id=")) {
            transformedContent = transformedContent + childNode;
            currentNode = currentNode.next;
        }
    }

    const addAttributes = (currentNode) => {
        if (currentNode) {
            if (currentNode.name !== '#text' && currentNode.name !== "br") {
                transformedContent += `<${currentNode.name}`;

                const attributes = currentNode.attributes;
                if (attributes && attributes.map) {
                    // eslint-disable-next-line no-loop-func
                    Object.keys(attributes.map).forEach(key => {
                        if (key === 'class') {
                            transformedContent += ` ${key}='${CONTENT_TYPE_TO_CLASS_MAP[contentType]}'`;
                        } else {
                            transformedContent += ` ${key}='${attributes.map[key]}'`;
                        }
                    });
                }
                transformedContent += `>`;

                addAttributes(currentNode.firstChild);

                transformedContent += `</${currentNode.name}>`;
            } else if (currentNode.name === '#text' || currentNode.name === "br") {
                transformedContent += content;
            }
        } else {
            transformedContent += content;
        }
    };
    addAttributes(currentNode);

    return transformedContent;
};

export const isInValidField = (min, max, value = "") => {
    const fieldData = value ? value.trim() : '';
    return fieldData.length < min || fieldData.length > max;
};

export const validateRequiredFields = (state) => {
    let errorFields: string[] = [];
    Object.keys(REQUIRED_FIELDS).forEach(key => {
        const name = REQUIRED_FIELDS[key];
        if (!state[name]) {
            errorFields.push(name);
        }
    });
    return errorFields;
};

export const validateInputFields = (state) => {
    let errorFields: string[] = [];
    Object.keys(VALIDATION_FIELDS).forEach(key => {
        const { min = 0, max }: { min?: number, max: number } = VALIDATION_FIELDS[key] || {};
        if (isInValidField(min, max, state[key])) {
            errorFields.push(key);
        }
    });
    errorFields.push(...validateRequiredFields(state));
    return errorFields;
};

export const updateErrorFields = (errorFields, name, value) => {
    if (errorFields.includes(name)) {
        if (REQUIRED_FIELDS[name] && value) {
            let index = errorFields.indexOf(name);
            if (index !== -1) {
                errorFields.splice(index, 1);
                return errorFields;
            }
        }

        const { min = 0, max }: { min?: number, max: number } = VALIDATION_FIELDS[name] || {};
        if (!isInValidField(min, max, value)) {
            let index = errorFields.indexOf(name);
            if (index !== -1) {
                errorFields.splice(index, 1);
            }
        }
    }

    return errorFields;
};
