import { parseIframeCode, parseAHrefCode } from '../utils';
import { type BingMapsParams } from './types';

export const parseBingMap = (dom: string): BingMapsParams => {
    const params = <BingMapsParams>{};
    const code = dom.replace(/\r|\n/, '').replace(/>\s+</g, '><');  // NOSONAR - Replace this alternation with a character class

    const iframeHTML = code.match(/<iframe (?:[^>]+)>/);
    if (Array.isArray(iframeHTML) && iframeHTML[0]) {
        const { src, width, height } = parseIframeCode(`${iframeHTML[0]}</iframe>`);
        if (src) params.link = src;
        if (width) params.width = width;
        if (height) params.height = height;
    }

    const mapLink = code.match(/<a (?:[^>]+>)/g);
    if (Array.isArray(mapLink)) {
        if (mapLink[0]) {
            const { href: href0 } = parseAHrefCode(mapLink[0]);
            params.largeMapLink = href0;
        }
        if (mapLink[1]) {
            const { href: href1 } = parseAHrefCode(mapLink[1]);
            params.dirMapLink = href1;
        }
    }

    return params;
};
