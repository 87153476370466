import type { SizeValues } from "../flowTypes";
import {
    MIN_DIMENSIONS,
    MAX_DIMENSIONS
} from '../../Workspace/epics/componentsEval/userInteractionMutations/constants';

export const
    _calculateValues = (value: string, maxSize: number, minSize: number): SizeValues => {
        let values: string | string[] = value.trim();
        if (values.indexOf('.') !== -1) {
            values = values.split('.');
            values = values[0] + '.' + (values[1] ? values[1][0] : '');
        }
        if (values === '') {
            return {
                actualValue: minSize,
                displayValue: ''
            };
        }
        if (values === '0') {
            return {
                actualValue: minSize,
                displayValue: minSize + ''
            };
        }
        if (+values) {
            const actualValue = Math.min(Math.max(+values, minSize), maxSize);
            return {
                actualValue,
                displayValue: (actualValue === maxSize || +values < minSize ? actualValue : values) + ''
            };
        }
        return {
            actualValue: +value,
            displayValue: value + ''
        };
    },
    calculateValues = (value: string, width?: boolean) => _calculateValues(
        value,
        (width ? MAX_DIMENSIONS.width : MAX_DIMENSIONS.height),
        (width ? MIN_DIMENSIONS.width : MIN_DIMENSIONS.height)
    );
