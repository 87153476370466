/* @flow */

const { PartnerName } = require("./PartnerName.js");

const PartnerTld = {
    [PartnerName.ONECOM]: 'one.com',
    [PartnerName.HOSTNET]: 'hostnet.nl',
    [PartnerName.CHECKDOMAIN]: 'checkdomain.de'
};

const PartnerTldRegexGroup =
    '(?:'
    + Object
        .values(PartnerTld)
        .join('|')
        .replace(/\./g, '\\.')
    + ')'

module.exports = { PartnerTld, PartnerTldRegexGroup };
