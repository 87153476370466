import { SubscriptionType } from "../../../../server/shared/accessManager/constants.js";
import {
    isBasicSubscription,
    isNormalSubscription,
    isPremiumSubscription,
    isEcommerceSubscription
} from "../App/epics/subscriptionData/utils";
import type { AvailableUpgrade, SubscriptionData } from '../../redux/modules/flowTypes';

export const isFreeUpgradeAvailable = (upgrade: AvailableUpgrade): boolean => {
    let flag = upgrade.freeUpgradeAvailable === true;

    if (upgrade.freeMonths) {
        flag = flag && (upgrade.freeMonths > 0);
    }

    return flag;
};

export const isFreeUpgradeAvailableForBnE = (availableUpgrades: AvailableUpgrade[]): boolean => {
    const bnePlan = availableUpgrades.find(availableUpgrade => {
        return isEcommerceSubscription(availableUpgrade.addonProductToUpgrade);
    });

    return bnePlan ? isFreeUpgradeAvailable(bnePlan) : false;
};

export const getRecommendedSubscriptionType = (
    subscriptionData: SubscriptionData,
    subscriptionTypeForSeamlessUpgrade: string,
    source: string,
) => {
    const { subscriptionType, availableUpgrades = [] } = subscriptionData;

    const upgradesMap = availableUpgrades.reduce((acc, upgrade) => ({
        ...acc,
        [upgrade.addonProductToUpgrade]: upgrade,
    }), {});

    if (isBasicSubscription(subscriptionType)) {
        if (isNormalSubscription(subscriptionTypeForSeamlessUpgrade)) {
            if (isFreeUpgradeAvailable(upgradesMap[SubscriptionType.PREMIUM])) {
                return SubscriptionType.PREMIUM;
            } else if (isFreeUpgradeAvailable(upgradesMap[SubscriptionType.ECOMMERCE])) {
                return SubscriptionType.ECOMMERCE;
            } else if (
                isFreeUpgradeAvailable(upgradesMap[SubscriptionType.NORMAL]) === false &&
                source.indexOf("publish") > -1
            ) {
                return SubscriptionType.NORMAL;
            }

            return SubscriptionType.PREMIUM;
        } else if (
            isPremiumSubscription(subscriptionTypeForSeamlessUpgrade) &&
            isFreeUpgradeAvailable(upgradesMap[SubscriptionType.PREMIUM]) === false &&
            isFreeUpgradeAvailable(upgradesMap[SubscriptionType.ECOMMERCE])
        ) {
            return SubscriptionType.ECOMMERCE;
        }
    } else if (
        isNormalSubscription(subscriptionType) &&
        isPremiumSubscription(subscriptionTypeForSeamlessUpgrade)
    ) {
        if (
            source.indexOf("publish") > -1 &&
            isFreeUpgradeAvailable(upgradesMap[SubscriptionType.PREMIUM]) === false &&
        isFreeUpgradeAvailable(upgradesMap[SubscriptionType.ECOMMERCE])
        ) {
            return SubscriptionType.ECOMMERCE;
        }
    }

    return subscriptionTypeForSeamlessUpgrade;
};
