import { is, isEmpty } from 'ramda';

type GenericObject = {
    [key: string]: any;
};

type SingleNodeConfig = {
    nodeName ?: string;
    innerHTML?: string;
    innerText ?: string;
    attributes ?: GenericObject;
    children ?: Array<SingleNodeConfig>;
    validator ?: (node: HTMLElement) => boolean;
    parser ?: (node: HTMLElement) => GenericObject;
};

const fetchPropsFromDOM = (node: HTMLElement, config: SingleNodeConfig): GenericObject => {
    let props = {};

    if (!isEmpty(config) && node && node.nodeName === config.nodeName) {
        if (typeof config.validator === 'function' && config.validator(node) === false) {
            return props;
        } else if (config.parser && is(Function, config.parser)) {
            props = config.parser(node);
        } else if (config.innerHTML && is(String, config.innerHTML)) {
            props[config.innerHTML] = node.innerHTML;
        } else if (config.innerText && is(String, config.innerText)) {
            props[config.innerText] = node.innerText;
        }

        const { attributes = {} } = config;
        props = Object
            .keys(attributes)
            .reduce((acc, key) => {
                const attrKey: string = attributes[key];
                return node.attributes[key]
                    ? { ...acc, [attrKey]: node.attributes[key].nodeValue }
                    : acc;
            }, props);

        props = (config.children || []).reduce((acc, childConfig, index) => {
            const childProps = fetchPropsFromDOM(<HTMLElement>node.childNodes[index], childConfig);
            return { ...acc, ...childProps };
        }, props);
    }

    return props;
};

export const parseDOM = (configs: Array<SingleNodeConfig>, domString: string, getNode: boolean = false): GenericObject => {
    let params = {};
    let htmlNode = <HTMLElement><unknown>null;
    let node = (new window.DOMParser()).parseFromString(domString, 'text/html');

    for (let i = 0; i < node.childNodes.length; i++) { // NOSONAR
        if (node.childNodes[i].nodeName === 'HTML') {
            htmlNode = <HTMLElement>node.childNodes[i];
            break;
        }
    }

    if (htmlNode && htmlNode.childNodes[1] && htmlNode.childNodes[1].nodeName === 'BODY') {
        const nodes = htmlNode.childNodes[1].childNodes;

        // eslint-disable-next-line unicorn/no-for-loop
        for (let j = 0; j < configs.length; j++) {
            const propsFromDOM = fetchPropsFromDOM(<HTMLElement>nodes[j], configs[j]);
            params = { ...params, ...propsFromDOM };
        }
        if (getNode) {
            // eslint-disable-next-line dot-notation
            params['htmlNode'] = nodes[0];
        }
    }

    return params;
};
