import * as React from 'react';
import cx from 'classnames';
import Tree, { TreeNode } from 'rc-tree-one.com';

import styles from './PagesTree.css';
import { onMouseDown } from '../../../../utils/mouse';
import * as Actions from '../actionTypes';
import { openLinkPageInfoDialog, openSectionLinkInfoDialog, loadSectionComponentsForPage } from "../actionCreators";
import {
    isPageRef,
    isLinkPage,
    mapDataSiteItem,
    isSectionLink,
    isSubPageRestrictedPage,
    isBlogPage
} from "../../../../../dal/model/utils/dataSiteItemUtils";
import type { DataSiteItem } from "../../../../../dal/model/utils/dataSiteItemUtils"; // eslint-disable-line
import { DataSite, DataPageRef } from "../../../../../dal/model";
import moveItemInSiteData from "../../utils/moveItemInSiteData";
import { saveSiteDataAction } from "../../../App/epics/siteData/actionCreators";
import { SaveStatus } from "../../../Workspace/epics/saveStatus/SaveStatus";
import { disallowedToMoveHomePage } from "../../utils/disallowedToMoveHomePage";
import LoadingIndicator from '../../../../view/common/LoadingIndicator';
import { Node, OnDragEnterParams, Props, State } from './types';
import { TitleContents } from './TitleContents';

export default class PagesTree extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            site: new DataSite(props.site), // clone
            selected: { node: props.selectedId, ancestor: null },
            toggleNode: null,
            draggingNode: null,
            hideSelectionTag: false,
            disableDragHovers: false,
            showTreeDropTarget: false
        };

        this.onDragEnter = this.onDragEnter.bind(this);

        this.onTreeDropTargetTopDrop = this.onTreeDropTargetTopDrop.bind(this);

        this.onTreeDropTargetBottomDragOver = this.onTreeDropTargetBottomDragOver.bind(this);
        this.onTreeDropTargetBottomDragLeave = this.onTreeDropTargetBottomDragLeave.bind(this);
        this.onTreeDropTargetBottomDrop = this.onTreeDropTargetBottomDrop.bind(this);

        this.onRightClick = this.onRightClick.bind(this);
        this.createIndexMap = this.createIndexMap.bind(this);
    }

    getPageOffsetTop(pageId: string) {
        // @ts-ignore
        const node = this.refs.tree._treeNodeInstances.find(n => n.props.eventKey === pageId);
        return node ? node.refs.li.offsetTop : null;
    }

    _find(id: string): Node {
        let node: any = null;
        const doFind = (siblingItems, id, parent = null) => {
            for (let i = 0; !node && i < siblingItems.length; i++) {
                const item = { ...siblingItems[i] };
                item.parent = parent; // store parent along the way
                if (item.id === id) node = { item, siblingItems, index: i };
                else if (item.items && item.items.length) doFind(item.items, id, item);
            }
        };
        doFind(this.state.site.getItems(), id);

        if (!node) throw new Error("Cannot find PagesTree node by id: " + id);

        return node;
    }

    _getResultPage(id: string): DataSiteItem {
        return mapDataSiteItem(this._find(id).item);
    }

    _findParentPage(id: string): DataSiteItem {
        const items = this.state.site.getItems();
        let item = items.find(e => isPageRef(e) && e.pageId && id === e.pageId);

        if (!item) throw new Error("Cannot find parent page node by parentId: " + id);
        return item;
    }

    _findBranch(nodeId: string) {
        const
            node = this._find(nodeId),
            branch = [nodeId];

        let parent = node.item.parent;
        while (parent) {
            branch.unshift(parent.id);
            parent = parent.parent;
        }

        return {
            contains: (nodeId: string) => branch.indexOf(nodeId) !== -1,
            getFirstCollapsed(expandedKeys: Array<string>): string | null | undefined {
                // eslint-disable-next-line no-restricted-syntax
                for (const leafId of branch) {
                    if (expandedKeys.indexOf(leafId) === -1) return leafId;
                }
                return null;
            },
            branch
        };
    }

    _updateSelectionTag(selectedNode: string | null | undefined) {
        if (this.props.useSelectionTag && selectedNode) {
            const
                branch = this._findBranch(selectedNode),
                // @ts-ignore
                expandedKeys = this.refs.tree.state.expandedKeys;

            const selectedAncestor = branch.getFirstCollapsed(expandedKeys);
            this.setState({ selected: { node: selectedNode, ancestor: selectedAncestor } });
        }
    }

    _animation() {
        const tree = this;

        let selectionTagShouldBeHidden = false;
        const { toggleNode, selected: { node: selectedNodeKey } } = this.state;
        if (toggleNode) {
            const { props: { eventKey: toggleNodeKey } } = toggleNode;
            selectionTagShouldBeHidden = Boolean(
                selectedNodeKey && selectedNodeKey !== toggleNodeKey
                && this._findBranch(selectedNodeKey).contains(toggleNodeKey)
            );
        }

        const animate = (node, done, expand) => {
            /* eslint-disable no-param-reassign */
            if (selectionTagShouldBeHidden) {
                tree.setState({ hideSelectionTag: true });
            }

            const height = node.offsetHeight;

            const animationStartHeight = expand ? 0 : height;
            const animationEndHeight = expand ? height : 0;

            node.style.maxHeight = `${animationStartHeight}px`;
            node.style.overflow = "hidden";

            setTimeout(() => {
                node.style.maxHeight = `${animationEndHeight}px`;

                tree.setState({ toggleNode: null, hideSelectionTag: false });
                tree._updateSelectionTag(tree.state.selected.node);
            });

            setTimeout(() => {
                node.style.maxHeight = '';
                node.style.overflow = '';

                done();
            }, 200);
            /* eslint-enable */
        };

        return {
            enter(node: any, done: Function) {
                animate(node, done, true);
            },
            leave(node: any, done: Function) {
                animate(node, done, false);
            }
        };
    }

    onSelect() {
        const
            [, { node }] = arguments,
            page = this._getResultPage(node.props.eventKey);
        this._selectPage(page);
    }

    _selectPage(page: DataSiteItem) {
        const { saveStatus } = this.props;

        if (isPageRef(page)) {
            if (this._canSelectItem(page)) {
                if (!saveStatus || saveStatus !== SaveStatus.CAN_SAVE) {
                    this.setState({ selected: { node: page.id, ancestor: null } });
                }
                if (this.props.onSelect) this.props.onSelect(page);
            }
        } else if (isLinkPage(page)) {
            this.props.dispatch(openLinkPageInfoDialog(page));
        } else if (this._canSelectItem(page)) { // DataSectionLink
            this.props.dispatch(loadSectionComponentsForPage(page));
            this.props.dispatch(openSectionLinkInfoDialog(page));
        }
    }

    _canSelectItem(item: DataSiteItem) {
        if (this.props.disableTemplateChange) {
            const selectedPageId: string = this.state.selected.node as string;
            const currentPage = this._getResultPage(selectedPageId) as DataPageRef;

            if (isPageRef(item)) {
                return currentPage.templateId === item.templateId;
            } else if (isSectionLink(item)) {
                const parentPageItem = this._findParentPage(item.pageId) as DataPageRef;
                return currentPage.templateId === parentPageItem.templateId;
            }
        }

        return !this.props.disableTemplateChange;
    }

    onExpand(expandedKeys: Array<string>, { node: toggleNode }: any) {
        this.setState({ toggleNode });
        if (this.props.onExpand) this.props.onExpand(expandedKeys);
    }

    onRightClick({ event: { nativeEvent: event }, node: { props: { eventKey: nodeKey } } }: any) {
        if (!this.props.enableContextMenu) return;

        const
            item = this.state.site.getItemById(nodeKey),
            position = { x: event.pageX, y: event.pageY };

        this.props.dispatch({
            type: Actions.PAGE_TREE_PAGE_OPEN_CONTEXT_MENU,
            payload: { pageTreeItem: item, position }
        });
    }

    onDragStart(e: any) {
        this.setState({ draggingNode: e.node.props.eventKey });
    }

    onDragEnter(params: OnDragEnterParams) {
        const
            { site: siteData, draggingNode: itemId } = this.state,
            { node: { props: { id: toItemId } }, enterGap: gap } = params,
            disableDragHovers = disallowedToMoveHomePage({ siteData, itemId: itemId!, toItemId, gap });

        this.setState({ disableDragHovers });

        if (this.props.onDragEnter) this.props.onDragEnter(params);
    }

    onDragEnd(e) {
        // fix issue that target is slightly transparent after drop
        const target = e.event.target.parentNode.parentNode;
        setTimeout(() => { target.style.opacity = 1; }, 0);

        this.setState({ draggingNode: null });
    }

    onDrop(info: Record<string, any>) {
        const
            dragKey = info.dragNode.props.eventKey,
            dropKey = info.node.props.eventKey,
            { item: dragItem } = this._find(dragKey),
            { item: dropItem } = this._find(dropKey),
            siteData = this.state.site,
            itemId = dragItem.id,
            toItemId = dropItem.id,
            gap = info.dropToGap ? info.dropEdge : 0;
        const newSite = moveItemInSiteData({ siteData, itemId, toItemId, gap });
        if (newSite === false) return;

        const newState = {
            site: newSite,
            draggingNode: null,
            selected: { node: this.state.selected.node, ancestor: this.state.selected.ancestor }
        };

        // update "selection tag"
        const selectedKey = this.state.selected.node;
        // @ts-ignore
        if (this.props.useSelectionTag && selectedKey && selectedKey !== dragItem) {
            const branch = this._findBranch(selectedKey);
            if (branch.contains(dragItem.id)) {
                newState.selected.ancestor = dragItem.id;
            }
        }

        this.setState(newState);
        this.props.dispatch(saveSiteDataAction({ saveSiteDataInput: { site: newSite } }));
    }

    onTreeDropTargetTopDrop() {
        const
            // @ts-ignore
            dragNode = this.refs.tree.dragNode,
            node = { props: { eventKey: this.state.site.getTopMostRootPage().id } },
            dropToGap = true,
            dropEdge = -1;

        this.onDrop({ dragNode, node, dropToGap, dropEdge });
    }

    onTreeDropTargetBottomDragOver() {
        // @ts-ignore
        const dragNode = this.refs.tree.dragNode;
        if (!this.state.site.isBottomMostRootPage(dragNode.props.id)) {
            this.setState({ showTreeDropTarget: true });
        }
    }

    onTreeDropTargetBottomDragLeave() {
        if (this.state.showTreeDropTarget) {
            this.setState({ showTreeDropTarget: false });
        }
    }

    onTreeDropTargetBottomDrop() {
        if (this.state.showTreeDropTarget) {
            const
                // @ts-ignore
                dragNode = this.refs.tree.dragNode,
                node = { props: { eventKey: this.state.site.getBottomMostRootPage().id } },
                dropToGap = true,
                dropEdge = 1;
            this.onDrop({ dragNode, node, dropToGap, dropEdge });
            this.setState({ showTreeDropTarget: false });
        }
    }

    componentDidMount() {
        this._updateSelectionTag(this.state.selected.node);
    }

    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        if (nextProps.site !== this.props.site) {
            this.setState({ site: new DataSite(nextProps.site) }); // cloning, might be slow ...
        }
        if (this.props.selectedId !== nextProps.selectedId) {
            this._updateSelectionTag(nextProps.selectedId);
        }
    }

    shouldComponentUpdate(nextProps: Props, nextState: State) {
        return this.props.selectedId !== nextProps.selectedId
            || this.props.site !== nextProps.site
            || this.props.activeHoverItemId !== nextProps.activeHoverItemId
            || this.state.selected !== nextState.selected
            || this.state.disableDragHovers !== nextState.disableDragHovers
            || this.state.showTreeDropTarget !== nextState.showTreeDropTarget
            || this.props.isLoading !== nextProps.isLoading
            || this.props.showPageSettings !== nextProps.showPageSettings
            || this.props.enableContextMenu !== nextProps.enableContextMenu
            || this.props.disableTemplateChange !== nextProps.disableTemplateChange
            || this.props.expandAll !== nextProps.expandAll
            || this.props.expandPageIds !== nextProps.expandPageIds
            || this.props.subscriptionType !== nextProps.subscriptionType;
    }

    createIndexMap = (nodes) => {
        let map = {};
        // eslint-disable-next-line unicorn/no-for-loop
        for (let i = 0; i < nodes.length; i++) {
            // eslint-disable-next-line no-param-reassign
            map[nodes[i]] = i;
        }

        return map;
    };

    renderNode(page: DataSiteItem) {
        const
            {
                useSelectionTag,
                draggable,
                activeHoverItemId,
                pageCustomClassName,
                pageCustomClassNameIds
            } = this.props,
            {
                hideSelectionTag,
                selected: { node: selectedNode, ancestor: selectedAncestor },
                disableDragHovers,
                site,
            } = this.state,
            isSelected = selectedNode === page.id,
            isSelectedAncestor = selectedAncestor === page.id;

        const disabled = !this._canSelectItem(page);

        const classNameSpec = {
            [styles.dragging]: this.state.draggingNode === page.id,
            refPage: isPageRef(page) && !isBlogPage(page),
            [styles.activeHover]: activeHoverItemId === page.id,
            bottomMostPage: site.isBottomMostPage(page.id),
            [styles.disabled]: disabled
        };
        if (pageCustomClassName && pageCustomClassNameIds) {
            classNameSpec[pageCustomClassName] = pageCustomClassNameIds.indexOf(page.id) !== -1;
        }
        const className = cx(classNameSpec);

        const selectionTag = useSelectionTag && (isSelected || isSelectedAncestor) && !hideSelectionTag
            ? () => <span className={typeof useSelectionTag === 'string' ? useSelectionTag : ''} />
            : null;

        const separator = draggable ? () => <span className={styles.separator} /> : null;

        const titleContentProps = {
            site,
            page,
            subscriptionType: this.props.subscriptionType,
            showPageSettings: this.props.showPageSettings,
            globalVariables: this.props.globalVariables,
            disabled: !this._canSelectItem(page)
        };

        return (
            <TreeNode
                ref={"TreeNode-" + page.id}
                id={page.id}
                title={<TitleContents {...titleContentProps} />}
                selectionTag={selectionTag}
                separator={separator}
                className={className}
                disableDragHovers={disableDragHovers}
                key={page.id}
                disabled={disabled}
            >
                {
                    isPageRef(page) && page.items.length && !isSubPageRestrictedPage(page)
                        ? page.items.map(this.renderNode.bind(this))
                        : null
                }
            </TreeNode>
        );
    }

    _expandablePageIds(): string[] {
        const pageRefs = this.state.site.getAllPageRefs();

        const isExpandable = (pageRef: DataPageRef): boolean => {
            return this._canSelectItem(pageRef) ||
                pageRef.getPageRefs().some(isExpandable);
        };

        return pageRefs
            .filter(isExpandable)
            .map(pageRef => pageRef.id);
    }

    render() {
        const {
                draggable,
                expandAll,
                expandPageIds,
                autoExpandParent = false,
                className = '',
                itemLeftShift = 0,
                itemLeftShiftProp,
                skipLinkPages,
                isLoading
            } = this.props,
            { showTreeDropTarget } = this.state;

        const expandedKeys = expandAll
            ? this._expandablePageIds()
            : expandPageIds;

        return (
            <div
                className={cx(styles.tree, className)}
                onMouseDown={e => onMouseDown(
                    e,
                    () => {},
                    () => e.stopPropagation()
                )}
            >
                {draggable && <div className={styles.treeDropTargetTop} onDrop={this.onTreeDropTargetTopDrop} />}
                <Tree
                    // public api
                    onSelect={this.onSelect.bind(this)}
                    draggable={draggable}
                    defaultExpandAll={expandAll}
                    defaultExpandedKeys={expandPageIds}
                    expandedKeys={expandedKeys}
                    onExpand={this.onExpand.bind(this)}
                    itemLeftShift={itemLeftShift}
                    itemLeftShiftProp={itemLeftShiftProp}
                    // private
                    ref="tree"
                    showLine
                    selectedKeys={[this.state.selected.node]}
                    onDragStart={this.onDragStart.bind(this)}
                    onDragEnter={this.onDragEnter}
                    onDrop={this.onDrop.bind(this)}
                    onDragEnd={this.onDragEnd.bind(this)}
                    openAnimation={this._animation()}
                    autoExpandParent={autoExpandParent}
                    onRightClick={this.onRightClick}
                >
                    {
                        skipLinkPages
                            ? this.state.site.getPageRefs().map(p => this.renderNode(p))
                            : this.state.site.getItems().map(p => this.renderNode(p))
                    }
                </Tree>
                {draggable && (
                    <div
                        className={cx(styles.treeDropTargetBottom, { [styles.visible]: showTreeDropTarget })}
                        onDragOver={this.onTreeDropTargetBottomDragOver}
                        onDragLeave={this.onTreeDropTargetBottomDragLeave}
                        onDrop={this.onTreeDropTargetBottomDrop}
                    ><div /></div>
                )}
                {isLoading && <LoadingIndicator className={styles.loadingBox} />}
            </div>
        );
    }
}
