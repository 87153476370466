/* eslint-disable max-len, no-useless-escape */

import React from "react";
import cx from "classnames";
import { Flex } from "../../view/reflexbox/index";
import epic from "./epic";
import { SecondaryButton } from '../../view/common/Button/index';
import { shouldCancelStart, getImageNameFromUrl, validateLongImageName } from "./utils";
import { width, height, activeNodeClassName } from "./constants";
import defaultImageData from "./defaultImageData";
import SortableList from "./sortableList";
import siteDataEpic from "../App/epics/siteData/index";
import { missingAssetUrls } from "../MissingAssets/epic/index";
import * as actionTypes from "./actionTypes";
import * as actionCreators from "./actionCreators";
import PropertyContainer from "../../view/common/PropertyContainer/index";
import LinkButton from "../../view/common/Button/LinkButton";
import InputField from "../../view/common/Input/InputField";
import Textarea from "../../view/common/Input/Textarea";
import { injectIntl, Intl } from "../../view/intl/index";
import { closeDialog } from "../App/actionCreators/index";
import VerticalSpacer from "../../view/common/VerticalSpacer";
import configurationDialogId from "./dialogId";
import {
    getCenterPositionForDialog,
    makeUpdateDialogHeightOnBrowserDimensionsChanged
} from "../DialogManager/utility";
import { Dialog, DialogTitleBox, DialogBody, DialogFooter } from "../../view/common/dialogs/baseDialog/index";
import { getAssetUrl } from "../../utils/assetUtils";
import type { GalleryImage } from "../oneweb/Gallery/flowTypes";
import type { ImageAsset } from "../App/flowTypes";
import type { BrowserDimensions } from "../../redux/modules/children/dimensions/index";
import { DataSite } from "../../../dal/model/index";
import { selectedComponentIdReducer } from "../Workspace/epics/componentsEval/index";
import CheckBoxWithLabel from "../../view/common/CheckBox/CheckBoxWithLabel";
import styles from "./SortImageDialog.css";
import allowImageEditor from "../../view/common/dialogs/ImageEditorDialog/allowImageEditor";
import { TooltipPosition } from "../presentational/Tooltip/constants";
import { QuestionTip } from "../presentational/Tooltip/questionTip/QuestionTip";
import { FileUploader } from "../FileUploader/index";
import {
    LIMIT
} from "../oneweb/Gallery/constants/index";
import Resource from "../../redux/modules/children/fileChooser/Resource";
import LazyImage from "../../view/common/LazyImage/index";
import { globalVariablesEpic } from "../App/epics/globalVariablesEpic";
import { sectionComponentsEpic } from '../PagesTree/sectionComponents/epic/sectionComponentsEpic';
import type { SectionComponents } from '../PagesTree/sectionComponents/epic/sectionComponentsEpic';
import browserDimensionsEpic from "../App/epics/browserDimensions/index";
import { GLOBAL_STYLES_DIALIG_MIN_HEIGHT } from "../Globalstyles/constants";

type Props = {
    state: {
        images: Array<GalleryImage>,
        activeNodeIndex: number,
        captionsEnabled: boolean,
        titleBorderColor: string,
        captionBorderColor: string,
        uploading: boolean,
        ghostImages: Array<Resource>
    },
    sectionComponents: SectionComponents,
    site: DataSite,
    globalVariables: Record<string, any>,
    componentId: string,
    missingAssetUrls: Array<string>,
    hdImages: boolean,
    intl: Intl,
    dispatch: Dispatch
}

const
    PreviewContainerWidth = parseInt(styles.IMG_PREVIEW_MAX_WIDTH, 10),
    PreviewContianerHeight = parseInt(styles.IMG_PREVIEW_MAX_HEIGHT, 10),
    PreviewContainerRatio = PreviewContainerWidth / PreviewContianerHeight,
    dropTitleMessage = 'msg: SortImages.dropZone.title {Drag and drop your files here}',
    updateOnBrowserDimensionsChanged = makeUpdateDialogHeightOnBrowserDimensionsChanged({
        minHeight: GLOBAL_STYLES_DIALIG_MIN_HEIGHT,
        maxHeight: height
    });

export const
    renderImagePreview = (asset: null | undefined | ImageAsset, imageAvailable: boolean, intl: Intl, onEditIconClick: Function, activeNodeIndex: number, isImageEditorAllowed: boolean) => {
        if (asset) {
            const assetRatio = (asset.width / asset.height);

            let expectedWidth, expectedHeight;

            if (assetRatio < PreviewContainerRatio) {
                expectedHeight = PreviewContianerHeight;
                expectedWidth = asset.width * (expectedHeight / asset.height);
            } else {
                expectedWidth = PreviewContainerWidth;
                expectedHeight = asset.height * (expectedWidth / asset.width);
            }

            const
                leftPosition = ((PreviewContainerWidth - expectedWidth) / 2) + 23,
                bottomPosition = ((PreviewContianerHeight - expectedHeight) / 2) + 3,
                imageAttributes = {
                    src: getAssetUrl(asset, { resize: ',' + expectedHeight }),
                    role: "presentation",
                    style: {
                        width: expectedWidth,
                        height: expectedHeight
                    }
                };
            return (
                <div className={cx(styles.paddingRBL, styles.imageSelected)} style={{ position: 'relative' }}>
                    <div className={styles.imagePreview}>
                        <LazyImage {...imageAttributes} showTransparency />
                    </div>
                    {isImageEditorAllowed &&
                    <div
                        data-title={intl.msgJoint("msg: common.edit {Edit}")}
                        className={styles.editIconWrapperPreview}
                        style={{ left: leftPosition, bottom: bottomPosition }}
                        onMouseDown={e => e.stopPropagation()}
                        onClick={() => onEditIconClick(asset, activeNodeIndex)}
                    />}
                </div>
            );
        }

        return (
            <div className={styles.noImageSelected}>
                {intl.msgJoint("msg: common.sortImage.noImageSelected {No image selected}")}
            </div>
        );
    };

class SortableComponentClass extends React.Component<Props> {
    componentWillUnmount() {
        this.props.dispatch({
            type: actionTypes.SORT_IMAGES_DIALOG_UNMOUNT
        });
    }

    onDrop = (e: DragEvent, files: FileList) => {
        const { dispatch } = this.props;
        dispatch({
            type: actionTypes.SORT_IMAGES_UPLOAD_FILES,
            payload: files
        });
    };

    render() {
        const {
            state: {
                activeNodeIndex,
                captionsEnabled,
                ghostImages,
                images,
                uploading
            },
            hdImages,
            site,
            globalVariables,
            sectionComponents,
            componentId,
            missingAssetUrls,
            intl,
            dispatch
        } = this.props;
        const
            activeNode = {
                ...defaultImageData,
                ...images[activeNodeIndex],
                title: ((images[activeNodeIndex] && images[activeNodeIndex].title) || '').substr(0, LIMIT.title.hard)
            },
            isImageEditorAllowed = allowImageEditor(),
            asset = activeNode.asset ? activeNode.asset : null,
            imageAvailable = asset ? missingAssetUrls.indexOf(asset.url) === -1 : false,
            imageName = activeNode.asset ? validateLongImageName(getImageNameFromUrl(activeNode.asset.url, false)) : null,
            imageSelected = activeNodeIndex > -1,
            updateCaption = caption => dispatch(actionCreators.updateImageCaption(caption)),
            updateTitle = title => dispatch(actionCreators.updateTitle(title)),
            onSortStart = ({ node, index }) => {
                node.className = activeNodeClassName;   // eslint-disable-line
                dispatch(actionCreators.imageSelectedToSort(index));
            },
            onSortEnd = payload => dispatch(actionCreators.sortingEnded(payload)),
            onDeleteIconClick = index => dispatch(actionCreators.deleteImageIconClick(index)),
            onImagePreviewEditIconClick = (asset, index) => dispatch(actionCreators.onImagePreviewEditClick(asset, index)),
            onImageListItemEditIconClick = (asset, index) => dispatch(actionCreators.onImageListItemEditClick(asset, index));
        return (
            <Dialog>
                <DialogTitleBox
                    title="msg: common.sortImage.title {Sort & edit images}"
                    subTitle="msg: common.sortImage.subtitle {Drag to change the order. Click to edit images.}"
                    className={styles.title}
                    titleClass={styles.titleSortAndEdit}
                    subTitleClass={styles.subTitleSortAndEdit}
                >
                    <SecondaryButton
                        disabled={uploading}
                        className={styles.addImageButton}
                        onClick={() => dispatch({ type: actionTypes.SORT_IMAGES_DIALOG_ADD_IMAGES_BTN_CLICKED })}
                    >
                        {intl.msgJoint("msg: common.addImages {Add images}")}
                    </SecondaryButton>
                </DialogTitleBox>
                <DialogBody className={styles.dialogBody}>
                    <Flex style={{ height: '100%' }}>
                        <div className={styles.container}>
                            <FileUploader
                                className={styles.overlay}
                                uploadIconClass={styles.uploadIcon}
                                hideMessage={!!images.length}
                                placeholderClass={styles.placeholder}
                                dropZoneClass={styles.dropZone}
                                titleClass={styles.dropZoneTitle}
                                onDrop={this.onDrop}
                                showOverlay={!images.length && !ghostImages.length}
                                title={!images.length ? dropTitleMessage : undefined}
                                style={{ alignItems: 'start' }}
                            >
                                <SortableList
                                    helperClass={cx(styles.sortableHelperWithCursorOverride)}
                                    delay={50}
                                    images={images}
                                    ghostImages={ghostImages}
                                    activeNodeIndex={activeNodeIndex}
                                    shouldCancelStart={shouldCancelStart}
                                    onSortStart={onSortStart}
                                    onSortEnd={onSortEnd}
                                    hdImages={hdImages}
                                    axis="xy"
                                    lockToContainerEdges
                                    site={site}
                                    componentId={componentId}
                                    missingAssetUrls={missingAssetUrls}
                                    onEditIconClick={onImageListItemEditIconClick}
                                    onDeleteIconClick={onDeleteIconClick}
                                    isImageEditorAllowed={isImageEditorAllowed}
                                />
                            </FileUploader>
                        </div>
                        <div className={styles.imageDetails}>
                            {renderImagePreview(asset, imageAvailable, intl, onImagePreviewEditIconClick, activeNodeIndex, isImageEditorAllowed)}
                            <div className={cx(styles.paddingRBL, styles.imageTitle)}>{imageName}</div>
                            <VerticalSpacer y={15} />
                            {
                                imageSelected &&
                                <div className={cx(styles.paddingRBL, styles.imageInputsBox)}>
                                    <div>
                                        <PropertyContainer
                                            style={{ position: "relative" }}
                                            label="msg: common.sortImage.titleAlt {Title (alt)}"
                                            labelIcon={{
                                                icon: styles.captionIcon,
                                                popup: {
                                                    message: 'msg: common.image.title.helpText {This title will also be used as “alt” text for the image. Add \"alt\" texts to all your images to push pages higher up on search results and boost your SEO.}',
                                                    className: cx(styles.captionLabelIconPopopClassname, styles.titleLabelIconPopupClassname),
                                                    tipClassname: styles.captionLabelIconPopopTipClassname
                                                }
                                            }}
                                        >
                                            <InputField
                                                placeholder="msg: common.sortImage.imageTitle.placeholder {Add your title here}"
                                                className={cx(styles.altText, styles.adjustmentInput)}
                                                value={activeNode.title}
                                                onChange={updateTitle}
                                                usePropsValue={true}
                                                onAfterPaste={updateTitle}
                                                maxLength={LIMIT.title.hard}
                                                counter={{
                                                    maxLength: LIMIT.title.hard
                                                }}
                                            />
                                        </PropertyContainer>
                                        <VerticalSpacer y={15} />
                                        <PropertyContainer
                                            style={{ position: "relative" }}
                                            label="msg: common.description {Description}"
                                            vsHeight={6}
                                            labelIcon={{
                                                icon: styles.captionIcon,
                                                popup: {
                                                    message: "msg: common.sortImage.caption.helpText {Image text added here will be visible when captions are enabled}",
                                                    className: styles.captionLabelIconPopopClassname,
                                                    tipClassname: styles.captionLabelIconPopopTipClassname
                                                }
                                            }}
                                        >
                                            <div className={styles.textareaCaptionWrapper}>
                                                <Textarea
                                                    placeholder="msg: common.sortImage.caption.placeHolder {Add your description here}"
                                                    className={cx(styles.caption, styles.adjustmentInput)}
                                                    value={activeNode.caption}
                                                    onChange={updateCaption}
                                                    onAfterPaste={updateCaption}
                                                    maxLength={LIMIT.caption.max}
                                                    counter={{
                                                        threshold: LIMIT.caption.threshold,
                                                        maxLength: LIMIT.caption.max,
                                                        HTML: <QuestionTip
                                                            className={styles.countIcon}
                                                            position={TooltipPosition.TOP}
                                                            theme={{
                                                                container: styles.iconPopup,
                                                                tipInfo: styles.iconInfo
                                                            }}
                                                        >{intl.msgJoint("msg: common.sortImage.caption.warningMessage {After 500 characters, the text will be truncated and the complete text is only shown when you click the image to see it in full screen view.}")}
                                                        </QuestionTip>
                                                    }}
                                                />
                                            </div>
                                        </PropertyContainer>
                                        <VerticalSpacer y={5} />
                                        <PropertyContainer>
                                            <CheckBoxWithLabel
                                                isChecked={captionsEnabled}
                                                onClick={() => dispatch({ type: actionTypes.SORT_IMAGES_DIALOG_TOGGLE_CAPTION })}
                                                label="msg: common.sortImage.showCaptions {Show captions (all images)}"
                                            />
                                        </PropertyContainer>
                                        <VerticalSpacer y={15} />
                                        <PropertyContainer label="msg: common.link {Link}" valueClassName={styles.adjustmentInputLinkLabel}>
                                            <LinkButton
                                                setLinkText="msg: common.sortImage.setLink {Add link}"
                                                maxLinkNameWidth={237}
                                                linkAction={activeNode.action}
                                                site={site}
                                                globalVariables={globalVariables}
                                                sectionComponents={sectionComponents}
                                                dispatch={dispatch}
                                                setLinkAction={actionTypes.SORT_IMAGES_DIALOG_SET_IMAGE_LINK}
                                                clearLinkAction={actionTypes.SORT_IMAGES_DIALOG_UNSET_IMAGE_ACTION_CLICK}
                                            />
                                        </PropertyContainer>
                                        <VerticalSpacer y={10} />
                                    </div>
                                </div>
                            }
                        </div>
                    </Flex>
                </DialogBody>
                <DialogFooter
                    className={styles.footer}
                    mctaText="msg: common.save {Save}"
                    mctaHandler={() => dispatch({ type: actionTypes.SORT_IMAGES_DIALOG_SAVE_BTN_CLICKED })}
                    sctaHandler={() => dispatch(closeDialog())}
                    disabled={uploading}
                />
            </Dialog>
        );
    }
}

export const
    SortableComponent = injectIntl(SortableComponentClass),
    config = {
        component: SortableComponent,
        confFactory: ({ browserWidth, browserHeight }: BrowserDimensions) => ({
            configurationDialogId,
            position: getCenterPositionForDialog(width, height, browserWidth, browserHeight),
            modal: true,
            dimensions: { width, height }
        }),
        reducer: epic.reducer,
        dependsOn: {
            site: siteDataEpic.reducer,
            globalVariables: globalVariablesEpic.reducer,
            componentId: selectedComponentIdReducer,
            missingAssetUrls,
            sectionComponents: sectionComponentsEpic.reducer,
            browserDimensions: browserDimensionsEpic.reducer,
        },
        updateOnBrowserDimensionsChanged
    };

export default config;
