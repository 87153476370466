import React from 'react';
import VerticalSpacer from '../../../../../view/common/VerticalSpacer';
import { Msg } from '../../../../../view/intl';
import styles from './Blog.css';
import cx from 'classnames';
import { globalStyleTextClass, types } from '../../../../Globalstyles/Text/constants';
import { THEME_TEXT_CLASS } from '../../../../ThemeGlobalData/constants';

export const BlogLoadFailureMessage: React.FC<React.HTMLAttributes<HTMLDivElement>> = (props) => {
    return (
        <div {...props} className={cx(styles.errorMessageContainer, props.className, THEME_TEXT_CLASS)}>
            <div className={styles.errorMessageIcon} />
            <VerticalSpacer y={8} />
            <h3 className={cx(styles.errorMessageContent, globalStyleTextClass[types.HEADING3])}>
                <Msg k="components.blog.error.content">
                    Failed to load blog
                </Msg>
            </h3>
            <div className={cx(styles.errorMessageSubContent, globalStyleTextClass[types.NORMAL])}>
                <Msg k="components.blog.error.subContent">
                    An error occurred and we couldn’t load your blog. Please try again and contact our support if the issue persists.
                </Msg>
            </div>
        </div>
    );
};
