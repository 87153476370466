import React from "react";
import { useDispatch, useSelector } from "react-redux";
import cx from "classnames";
import { BlogInserterDetails, BlogInserterViewTypes } from "./types";
import styles from "./ExpandedPanelBlog.css";
import { Msg } from "../../../../../view/intl";
import VerticalSpacer from "../../../../../view/common/VerticalSpacer";
import { EXTENDED_PANEL_CLOSE } from "../../../../Panel/actionTypes";
import { BLOG_WIZARD_OPEN, BLOG_UPGRADE_CLICKED } from "../../OnboardingWizard/epic/actionTypes";
import { selectPageAction } from "../../../../PagesTree/Tree/actionCreators";
import { openDialog } from "../../../../App/actionCreators";
import BlogManagementDialogId from "../BlogManagementDialog/dialogId";
import { selectIsOnBlogPage } from "../../../../App/epics/pageDataset/selectors";
import { selectBlogPageId } from "../../../../App/epics/siteData/selectors";
import { getComponentTierDataFromComponentKind } from "../../../../ComponentTierManager/utils";
import BlogKind from "../kind";
import { calculateUpgradeTierBeforeSeamlessUpgrade } from "../../../../ComponentTierManager/actionCreators";
import { EventWSBSources } from "../../../../EventTracking/constants";

const buttonHandlers = {
    CREATE_BLOG: ({ dispatch }) => {
        // open blog creation dialog
        dispatch({ type: EXTENDED_PANEL_CLOSE });
        dispatch({ type: BLOG_WIZARD_OPEN });
    },
    MANAGE_BLOG: ({ dispatch }) => {
        // open blog management iframe
        dispatch(openDialog(BlogManagementDialogId));
        dispatch({ type: EXTENDED_PANEL_CLOSE });
    },
    GO_TO_BLOG_PAGE: ({ dispatch, blogPageId }) => {
        // go to the page containing the blog component
        dispatch({ type: EXTENDED_PANEL_CLOSE });
        dispatch(selectPageAction(blogPageId));
    },
    UPGRADE: ({ dispatch, subscriptionData }) => {
        // open the upgrade dialog
        const upgradeContentBodyMsg = getComponentTierDataFromComponentKind(
            BlogKind
        ).upgradeDialogMsg({
            dispatch,
            subscriptionData
        });
        dispatch({ type: BLOG_UPGRADE_CLICKED, payload: { source: EventWSBSources.Inserter } });
        dispatch(
            calculateUpgradeTierBeforeSeamlessUpgrade(
                "componentToolbar:upgradeBtn",
                BlogKind,
                upgradeContentBodyMsg
            )
        );
    }
};

const PremiumTag = () => {
    return (
        <div className={cx(styles.infoTag, styles.premium)} data-testid="premium-tag">
            <Msg k="common.premium">Premium</Msg>
        </div>
    );
};

export const BlogInserterViews: BlogInserterDetails = {
    [BlogInserterViewTypes.GET_STARTED]: ({ isBlogCompatible }) => {
        const dispatch = useDispatch();

        return (
            <div data-testid="get-started-view">
                {!isBlogCompatible && (
                    <div>
                        <PremiumTag />
                        <VerticalSpacer y={16} />
                    </div>
                )}
                <div data-testid="panel-heading" className={styles.heading}>
                    <Msg k="extendedPanel.blog.nonExistent.heading">
                        Engage your audience with a blog
                    </Msg>
                </div>
                <VerticalSpacer y={8} />
                <div data-testid="panel-description1" className={styles.description}>
                    <Msg k="extendedPanel.blog.nonExistent.description1">
                        Do you use your website to sell products, offer services or share your
                        passions and experiences? No matter who you are, a blog is a great tool to
                        promote your website and attract new visitors.
                    </Msg>
                </div>
                <VerticalSpacer y={8} />
                <div data-testid="panel-description2" className={styles.description}>
                    <Msg k="extendedPanel.blog.nonExistent.description2">
                        Add your blog page now and start writing!
                    </Msg>
                </div>
                <VerticalSpacer y={32} />
                <div className={styles.buttonContainer}>
                    <button
                        type="button"
                        className={styles.primaryButton}
                        onClick={() => buttonHandlers.CREATE_BLOG({ dispatch })}
                        data-testid="create-blog-button"
                    >
                        <Msg k="extendedPanel.blog.button.createBlog">Get started</Msg>
                    </button>
                </div>
            </div>
        );
    },
    [BlogInserterViewTypes.MANAGE]: () => {
        const dispatch = useDispatch();
        const blogPageId = useSelector(selectBlogPageId);
        const isOnBlogPage = useSelector(selectIsOnBlogPage);

        return (
            <div data-testid="manage-view">
                <div data-testid="panel-heading" className={styles.heading}>
                    <Msg k="extendedPanel.blog.existent.heading">Manage your blog</Msg>
                </div>
                <VerticalSpacer y={8} />
                <div data-testid="panel-description1" className={styles.description}>
                    <Msg k="extendedPanel.blog.existent.description1">
                        Keep your blog updated to continue engaging with your website audience.
                    </Msg>
                </div>
                <VerticalSpacer y={8} />
                <div data-testid="panel-description2" className={styles.description}>
                    <Msg k="extendedPanel.blog.existent.description2">
                        You can write new posts and edit existing ones in your blog administration.
                    </Msg>
                </div>
                <VerticalSpacer y={32} />
                <div className={styles.buttonContainer}>
                    <button
                        type="button"
                        className={styles.primaryButton}
                        onClick={() => buttonHandlers.MANAGE_BLOG({ dispatch })}
                        data-testid="manage-blog-button"
                    >
                        <Msg k="extendedPanel.blog.button.manageBlog">Manage Blog</Msg>
                    </button>
                    <VerticalSpacer y={16} />
                    {/* only show go to blog button when user is not on the blog page already */}
                    {!isOnBlogPage && (
                        <button
                            type="button"
                            className={styles.secondaryButton}
                            onClick={() => buttonHandlers.GO_TO_BLOG_PAGE({ dispatch, blogPageId })}
                            data-testid="goto-blog-button"
                        >
                            <Msg k="extendedPanel.blog.button.goToBlog">Go to Blog</Msg>
                        </button>
                    )}
                </div>
            </div>
        );
    },
    [BlogInserterViewTypes.FREE_UPGRADE]: ({ subscriptionData }) => {
        const dispatch = useDispatch();

        return (
            <div data-testid="free-upgrade-view">
                <div>
                    <PremiumTag />
                    <VerticalSpacer y={16} />
                </div>
                <div data-testid="panel-heading" className={styles.heading}>
                    <Msg k="extendedPanel.blog.nonExistent.heading">
                        Engage your audience with a blog
                    </Msg>
                </div>
                <VerticalSpacer y={8} />
                <div data-testid="panel-description1" className={styles.description}>
                    <Msg k="extendedPanel.blog.freeUpgrade.description">
                        Blog is a Premium feature. Upgrade for free to start sharing your stories
                        and promote your business.
                    </Msg>
                </div>
                <VerticalSpacer y={32} />
                <div className={styles.buttonContainer}>
                    <button
                        type="button"
                        className={cx(styles.primaryButton, styles.upgradeButton)}
                        onClick={() => buttonHandlers.UPGRADE({ dispatch, subscriptionData })}
                        data-testid="upgrade-button"
                    >
                        <Msg k="common.freeUpgrade">Free Upgrade</Msg>
                    </button>
                </div>
            </div>
        );
    },
    [BlogInserterViewTypes.UPGRADE]: ({ subscriptionData }) => {
        const dispatch = useDispatch();

        return (
            <div data-testid="upgrade-view">
                <div>
                    <PremiumTag />
                    <VerticalSpacer y={16} />
                </div>
                <div data-testid="panel-heading" className={styles.heading}>
                    <Msg k="extendedPanel.blog.nonExistent.heading">
                        Engage your audience with a blog
                    </Msg>
                </div>
                <VerticalSpacer y={8} />
                <div data-testid="panel-description1" className={styles.description}>
                    <Msg k="extendedPanel.blog.upgrade.description">
                        Blog is a Premium feature. Upgrade to start sharing your stories and promote
                        your business.
                    </Msg>
                </div>
                <VerticalSpacer y={32} />
                <div className={styles.buttonContainer}>
                    <button
                        type="button"
                        className={cx(styles.primaryButton, styles.upgradeButton)}
                        onClick={() => buttonHandlers.UPGRADE({ dispatch, subscriptionData })}
                        data-testid="upgrade-button"
                    >
                        <Msg k="common.upgrade">Upgrade</Msg>
                    </button>
                </div>
            </div>
        );
    }
};
