/* eslint-disable max-len */
import { SubscriptionType } from "../../../../../../../server/shared/accessManager/constants.js";

export const
    //PREMIUM_TEMPLATES_OFFER = 'Premium Templates',
    UPTO_200_PUBLISHED_PAGES_OFFER = 'Up to 200 published pages',
    COOKIE_BANNER_OFFER = 'Cookie Banner - easy GDPR compliance',
    EMBEDED_CUSTOM_CODE_OFFER = 'Embed custom code',
    FREE_IMAGES_OFFER = 'Free images',
    SOCIAL_MEDIA_COMPONENTS_OFFER = 'Social media components',
    ONLINE_SHOP_OFFER = 'Online shop',
    ONLINE_SHOP_OFFER_UNLIMITED = 'Online shop: unlimited products',
    ONLINE_SHOP_OFFER_UNLIMITED_PRODUCTS = 'Unlimited products',
    ONLINE_SHOP_OFFER_X_PRODUCTS = 'Online shop: up to 3 products',
    ONLINE_SHOP_OFFER_UPTO_X_PRODUCTS = 'Up to 3 products',
    ONLINE_SHOP_NO_OFFER = 'ONLINE_SHOP_NO_OFFER',
    //STANDARD_TEMPLATES_OFFER = '140+ standard templates',
    UPTO_5_PUBLISHED_PAGES_OFFER = 'Up to 5 published pages',
    _1_PUBLISHED_PAGE_OFFER = '1 published page',
    CHAT_WIDGET = 'Chat widget',
    SEO_SCAN = 'SEO scan',
    AI_POWERED_WRITING_ASSISTANCE = 'AI-powered Writing Assistant',
    ACCEPT_PAYMENTS = 'Accept payments',
    WIDGETS = 'Widgets',
    PREMIUM_WIDGETS = 'Premium widgets',
    BACKUP_AND_RESTORE = 'Backup and Restore',
    HD_IMAGE_SUPPORT = 'HD image support',
    GOOGLE_BUSINESS_LISTING = 'Google Business listing',
    GOOGLE_REVIEWS = 'Google Reviews',
    FACEBOOK_PIXEL = 'Facebook Pixel',
    GOOGLE_ANALYTICS = 'Google Analytics',
    UNLIMITED_PUBLISHED_PAGES = 'Unlimited published pages',
    BASIC_SEO_SETTINGS = 'Basic SEO settings',
    BASIC_ANALYTICS = 'Basic analytics',
    UpgradeDialogPlanTypeMap = {
        [SubscriptionType.BASIC]: "msg: topbar.subscription.basic {BASIC}",
        [SubscriptionType.NORMAL]: "msg: topbar.subscription.normal {STARTER}",
        [SubscriptionType.PREMIUM]: "msg: topbar.subscription.premium {PREMIUM}",
        [SubscriptionType.ECOMMERCE]: "msg: topbar.subscription.ecommerce {BUSINESS + ECOMMERCE}",
    },
    OfferListLocalizationKeyMap = {
        //[PREMIUM_TEMPLATES_OFFER]: `msg: upgrade.plan.offer.premiumTemplates {Premium Templates}`,
        [UPTO_200_PUBLISHED_PAGES_OFFER]: `msg: upgrade.plan.offer.upto200.published.pages {Up to 200 published pages}`,
        [UNLIMITED_PUBLISHED_PAGES]: `msg: upgrade.plan.offer.unlimited.published.pages {Unlimited published pages}`,
        [COOKIE_BANNER_OFFER]: `msg: upgrade.plan.offer.cookiebanner {Cookie Banner - easy GDPR compliance}`,
        [EMBEDED_CUSTOM_CODE_OFFER]: `msg: upgrade.plan.offer.embed.custom.code {Embed custom code}`,
        [FREE_IMAGES_OFFER]: `msg: upgrade.plan.offer.free.images {Free images}`,
        [SOCIAL_MEDIA_COMPONENTS_OFFER]: `msg: upgrade.plan.offer.social.media.components {Social media components}`,
        [ONLINE_SHOP_OFFER]: `msg: component.webshop {Online Shop}`,
        [ONLINE_SHOP_OFFER_UNLIMITED]: `msg: upgrade.plan.offer.online.shop.unlimited {Online shop: unlimited products}`,
        [ONLINE_SHOP_OFFER_UNLIMITED_PRODUCTS]: `msg: upgrade.plan.offer.online.shop.unlimitedProducts {Unlimited products}`,
        [ONLINE_SHOP_OFFER_X_PRODUCTS]: [`msg: upgrade.plan.offer.online.shop.xProducts {Online shop: up to {numberOfProducts} products}`, { numberOfProducts: 3 }],
        [ONLINE_SHOP_OFFER_UPTO_X_PRODUCTS]: [`msg: upgrade.plan.offer.online.shop.UpToProducts {Up to {numberOfProducts} products}`, { numberOfProducts: 3 }],
        //[STANDARD_TEMPLATES_OFFER]: `msg: upgrade.plan.offer.standard.templates {140+ standard templates}`,
        [UPTO_5_PUBLISHED_PAGES_OFFER]: `msg: upgrade.plan.offer.upto5.published.pages {Up to 5 published pages}`,
        [_1_PUBLISHED_PAGE_OFFER]: `msg: upgrade.plan.offer.1published.page {1 published page}`,
        [CHAT_WIDGET]: `msg: upgrade.plan.offer.chat.widget {Chat widget}`,
        [SEO_SCAN]: 'msg: common.marketgoo {SEO scan}',
        [AI_POWERED_WRITING_ASSISTANCE]: 'msg: common.AIWritingAssistance {AI-powered Writing Assistant}',
        [ACCEPT_PAYMENTS]: 'msg: common.acceptPayments {Accept payments}',
        [WIDGETS]: 'msg: component.widgets.label {Widgets}',
        [PREMIUM_WIDGETS]: 'msg: component.premiumWidgets.label {Premium widgets}',
        [BACKUP_AND_RESTORE]: 'msg: common.backupAndRestore {Backup and Restore}',
        [HD_IMAGE_SUPPORT]: 'msg: common.hdImagesSupport {HD image support}',
        [GOOGLE_BUSINESS_LISTING]: 'msg: common.googleBusinessListing {Google Business listing}',
        [GOOGLE_REVIEWS]: 'msg: common.googleReviews {Google reviews}',
        [FACEBOOK_PIXEL]: 'msg: ecommerce.feature.fbPixel {Facebook Pixel}',
        [GOOGLE_ANALYTICS]: 'msg: ecommerce.feature.ga {Google Analytics}',
        [BASIC_SEO_SETTINGS]: 'msg: common.basicSeoSettings {Basic SEO settings}',
        [BASIC_ANALYTICS]: 'msg: common.basicAnalytics {Basic analytics}'
    },
    BasicKeyFeatures = [
        _1_PUBLISHED_PAGE_OFFER,
        ONLINE_SHOP_NO_OFFER,
        BASIC_SEO_SETTINGS,
        BASIC_ANALYTICS
    ],
    StarterKeyFeatures = [
        UPTO_5_PUBLISHED_PAGES_OFFER,
        ONLINE_SHOP_NO_OFFER,
        BASIC_SEO_SETTINGS,
        BASIC_ANALYTICS
    ],
    PremiumKeyFeatures = [
        UNLIMITED_PUBLISHED_PAGES,
        ONLINE_SHOP_NO_OFFER,
        BASIC_SEO_SETTINGS,
        BASIC_ANALYTICS
    ],
    EcommerceKeyFeatures = [
        UNLIMITED_PUBLISHED_PAGES,
        ONLINE_SHOP_OFFER,
        SEO_SCAN,
        GOOGLE_ANALYTICS
    ],
    BasicOffersList = [
        _1_PUBLISHED_PAGE_OFFER,
        ONLINE_SHOP_OFFER_X_PRODUCTS,
        AI_POWERED_WRITING_ASSISTANCE,
        ACCEPT_PAYMENTS,
        WIDGETS,
        FREE_IMAGES_OFFER
    ],
    StarterOffersList = [
        UPTO_5_PUBLISHED_PAGES_OFFER,
        ONLINE_SHOP_OFFER_X_PRODUCTS,
        AI_POWERED_WRITING_ASSISTANCE,
        ACCEPT_PAYMENTS,
        WIDGETS,
        FREE_IMAGES_OFFER,
        HD_IMAGE_SUPPORT
    ],
    PremiumOffersList = [
        UNLIMITED_PUBLISHED_PAGES,
        ONLINE_SHOP_OFFER_X_PRODUCTS,
        AI_POWERED_WRITING_ASSISTANCE,
        ACCEPT_PAYMENTS,
        PREMIUM_WIDGETS,
        FREE_IMAGES_OFFER,
        HD_IMAGE_SUPPORT,
        BACKUP_AND_RESTORE,
        SOCIAL_MEDIA_COMPONENTS_OFFER,
        EMBEDED_CUSTOM_CODE_OFFER
    ],
    EcommerceOffersList = [
        UNLIMITED_PUBLISHED_PAGES,
        ONLINE_SHOP_OFFER_UNLIMITED,
        AI_POWERED_WRITING_ASSISTANCE,
        ACCEPT_PAYMENTS,
        PREMIUM_WIDGETS,
        FREE_IMAGES_OFFER,
        HD_IMAGE_SUPPORT,
        BACKUP_AND_RESTORE,
        SOCIAL_MEDIA_COMPONENTS_OFFER,
        EMBEDED_CUSTOM_CODE_OFFER,
        GOOGLE_BUSINESS_LISTING,
        GOOGLE_REVIEWS,
        GOOGLE_ANALYTICS,
        FACEBOOK_PIXEL,
        SEO_SCAN
    ];
