/* eslint-disable max-len */
import React from "react";
import cx from "classnames";
import registry from './Fields/View/index';
import BaseElement from './Fields/index';
import * as selectors from '../selectors';
import SubmitButton from './SubmitButton/index';
import { type ContactFormUIProps } from './flowTypes';
import { getFontStyle } from "../fontsUtility";
import { LINE_HEIGHT_PERCENTAGE } from "./constants";
import { createSelectedParentThemeWithThemeTextClass } from "../../../ThemeGlobalData/utils/commonUtils";
import { getFirstButtonStyleId } from "../../../Workspace/epics/stylesheets/selectors";
import { THEME_BUTTON_CLASS } from '../../../ThemeGlobalData/constants';
import Captcha from "./Captcha";
import { MarketingConsentView } from "./MarketingConsent";
import getAccessManager from "../../../../getAccessManager";

export default ({
    component,
    globalStyles,
    stylesheetsIdToNameMap,
    showMask,
    measureRef,
    styles,
    globalVariables,
    site,
    isWorkspace,
    isServerPreview,
    themeSettingsData,
    selectedParentTheme,
    privacyPolicy,
}: ContactFormUIProps) => {
    const { autoColorMode } = themeSettingsData,
        containerClassName = component.styleForm.themeOverrideColor,
        { textStyle, buttonStyle, fuButtonStyle } = getFontStyle({
            component,
            globalStyles,
            lineHeightPercentage: LINE_HEIGHT_PERCENTAGE,
            autoColorMode
        }),
        formElements = selectors.formElementsSelector(component),
        formElementsOrder = selectors.formElementsOrderSelector(component),
        { fontSize } = textStyle,
        hiddenStyle = {
            opacity: 0,
            position: "absolute",
            zIndex: -999,
            pointerEvents: "none"
        },
        { buttonThemeSelected, fuButtonThemeSelected } = component;
    const buttonProps = {
        classList: autoColorMode ? [buttonThemeSelected, THEME_BUTTON_CLASS, selectedParentTheme] : [],
        stylesheetsIdToNameMap,
        disabled: !isWorkspace,
        buttonStyle,
        component,
        globalVariables,
        site,
        globalSubmitBtnClassName: 'contact-form-submit-btn',
        autoColorMode,
        firstButtonStylesheetId: getFirstButtonStyleId(globalStyles)
    };
    const fuButtonProps = {
        classList: autoColorMode ? [fuButtonThemeSelected, THEME_BUTTON_CLASS, selectedParentTheme] : [],
        stylesheetsIdToNameMap,
        fuButtonStyle,
        component,
        autoColorMode,
        firstButtonStylesheetId: getFirstButtonStyleId(globalStyles),
        selectedParentTheme
    };
    const enableCaptcha = getAccessManager().isContactFormCaptchaEnabled();
    const isFileInputPresent = Object.keys(formElements).some((key) => {
        return formElements[key].inputType === 'file';
    });

    const isMarketingConsentEnabled = (
        formElements.email &&
        formElements.email.isRequired &&
        component.isMarketingConsentEnabled
    );

    return (
        <div
            className={cx(
                "contactFormContainer",
                createSelectedParentThemeWithThemeTextClass({ selectedParentTheme })
            )}
        >
            <form
                id={component.id}
                className={`${styles.container} oneWebCntForm`}
                encType={isFileInputPresent ? 'multipart/form-data' : 'application/x-www-form-urlencoded'}
                ref={measureRef}
            >
                <div className="contact-form-text-style-div" style={textStyle}>
                    {showMask && <div className={styles.previewMask} />}
                    {formElementsOrder.map((eltName, idx) => {
                        if (registry[formElements[eltName].inputType]) {
                            return (
                                [
                                    <BaseElement
                                        key={`${eltName}-${idx}-field`}
                                        element={formElements[eltName]}
                                        fontSize={fontSize}
                                        globalVariables={globalVariables}
                                        {...fuButtonProps}
                                        site={site}
                                        containerClassName={containerClassName}
                                    />,
                                    <span
                                        key={`${eltName}-${idx}-error`}
                                        className={`${styles.errorMsg} error`}
                                    >&nbsp;</span>
                                ]
                            );
                        }
                        return <div key={eltName} />;
                    })}
                    {
                        [
                            <BaseElement
                                style={hiddenStyle}
                                key="hidden-field"
                                element={{
                                    errorMessage: "Please enter a valid text",
                                    inputType: "text",
                                    isRequired: false,
                                    //Setting Random value so that autocomplete browser skip this
                                    name: "some-randome-random",
                                    values: null,
                                    autocomplete: "nsfsddfewpfgdfasssdfsfworsdfd"
                                }}
                                fontSize={12}
                                globalVariables={globalVariables}
                                {...fuButtonProps}
                                site={site}
                            />,
                            <span
                                //@ts-ignore
                                style={hiddenStyle}
                                key="hidden-field-error"
                                className={`${styles.errorMsg} error`}
                            >&nbsp;</span>
                        ]
                    }

                    <MarketingConsentView
                        textStyle={textStyle}
                        isMarketingConsentEnabled={isMarketingConsentEnabled}
                        marketingConsentCheckBoxText={component.marketingConsentCheckBoxText}
                        readPrivacyPolicyText={component.readPrivacyPolicyText}
                        privacyPolicy={privacyPolicy}
                        globalVariables={globalVariables}
                        site={site}
                    />
                    <div className={styles.captchaSubmitBtnCtn}>
                        {
                            enableCaptcha &&
                            component.isCaptchaEnabled &&
                            <div className={cx('contact-form-field-container', containerClassName, styles.captchaCtn)}>
                                <Captcha
                                    isServerPreview={isServerPreview}
                                    textStyle={textStyle}
                                    autoColorMode={autoColorMode}
                                    captchaLang={component.captchaLang}
                                />
                            </div>
                        }
                        <div className={styles.submitBtnCtn}>
                            <SubmitButton {...buttonProps} />
                        </div>
                    </div>
                </div>
                <div id="contactFormResponseContainer" className={cx(containerClassName, "contactFormResponseStatus")} />
            </form>
        </div>
    );
};
