import { BOOKINGS_DEFAULT_HEIGHT, BOOKINGS_DEFAULT_WIDTH, DEFAULT_BUTTON_TEXT } from "./constants";

export const defaultState = {
    width: BOOKINGS_DEFAULT_WIDTH,
    height: BOOKINGS_DEFAULT_HEIGHT,
    fontFamily: null,
    fontColor: null,
    fontColorTheme: null,
    titleFontColor: null,
    titleFontColorTheme: null,
    buttonThemeSelected: null,
    buttonStyleId: null,
    buttonText: DEFAULT_BUTTON_TEXT
};
