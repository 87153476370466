import { useIntl } from "../../../../view/intl";
import React, { useEffect, useState, useMemo } from "react";

export const OnboardingLoadingText = () => {
    const intl = useIntl();
    const [currentMessageIndex, setCurrentMessageIndex] = useState(0);

    // Memoize the messages so they remain stable between renders.
    const onboardingLoadingMessages = useMemo(
        () => [
            intl.msgJoint("msg: component.blog.onboarding.loading.start {Creating your blog...}"),
            intl.msgJoint("msg: component.blog.onboarding.loading.setup_layout {Setting up your layout...}"),
            intl.msgJoint("msg: component.blog.onboarding.loading.writing_posts {Writing your first posts...}"),
            intl.msgJoint("msg: component.blog.onboarding.loading.finding_images {Finding matching images for you...}")
        ],
        [intl]
    );

    // Total maximum duration (in milliseconds) for all messages.
    const maxDuration = 15000;

    // Generate random durations for each message that add up to maxDuration.
    const durations = useMemo(() => {
        const randomFactors = onboardingLoadingMessages.map(() => Math.random());
        const totalFactor = randomFactors.reduce((acc, curr) => acc + curr, 0);
        return randomFactors.map(factor => (factor / totalFactor) * maxDuration);
    }, [onboardingLoadingMessages, maxDuration]);

    useEffect(() => {
        if (currentMessageIndex < onboardingLoadingMessages.length - 1) {
            const timeout = setTimeout(() => {
                setCurrentMessageIndex(currentMessageIndex + 1);
            }, durations[currentMessageIndex]);

            return () => clearTimeout(timeout);
        }
    }, [currentMessageIndex, durations, onboardingLoadingMessages.length]);

    return <div data-testid="dialog-loading-text">{onboardingLoadingMessages[currentMessageIndex]}</div>;
};
