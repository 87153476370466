import type { AppStore } from "../../../../redux/modules/flowTypes";
import {
    CANCEL_FAILED_SHUTTERSTOCK_PARTIAL_DOWNLOADS_ACTION,
    DOWNLOAD_SHUTTERSTOCK_IMAGES_FAILURE_ACTION,
    DOWNLOAD_SHUTTERSTOCK_IMAGES_REQUEST_ACTION,
    DOWNLOAD_SHUTTERSTOCK_IMAGES_SUCCESS_ACTION,
    SHUTTERSTOCK_SAVE_ACTION,
    RETRY_FAILED_SHUTTERSTOCK_PARTIAL_DOWNLOADS_ACTION,
    closeShutterstockPartialDownloadsDialogAction,
    downloadShutterstockImagesApiActionAction,
    openShutterstockUploadingDialogAction,
    closeShutterstockUploadingDialogAction,
    storeSuccessfulshutterstockPartialDownloadsAction,
    openShutterstockPartialDownloadsDialogAction,
    openMaxDownloadsPerRequestDialogAction,
} from "../../shutterstock/actions";
import {
    shutterstockFailedPartialDownloadsAppSel,
    shutterstockSelectedImagesAppSel,
    shutterstockSelectedImagesIdsAppSel,
    shutterstockSuccessfulPartialDownloadsAppSel
} from "../../shutterstock/selectors";
import {
    isMultiSelectFcAppSel,
    fcDialogActiveTabIdAppSel
} from "../../../../redux/modules/children/fileChooser/selectors";
import { sstockQuotaAppStateSel } from "../../../App/epics/appConfig/selectors";
import { SstockErrorReason } from "../../../../../dal/constants";
import type { PostSstockImageDownloadsResponse, SstockImageDownload } from "../../../../../dal/flowTypes";
import { makeUuid } from "../../../../../utils/makeUuid";
import { ASSET_IMAGE_DAL_TYPE } from "../../../../../dal/index";
import type { ShutterstockSelectedImageItem } from "../../shutterstock/types";
import { sendFreeOnecomCrashReport } from "../crashReport";
import { closeDialog, openDialog } from "../../../App/actionCreators/index";
import { fcResetAction } from "../../../../redux/modules/children/fileChooser/actionCreators/index";
import { makeActionForwardToSelectedComponent } from "../../../../redux/forwardTo";
import { openApiErrorHandlerDialog } from '../../../../redux/middleware/api/errorHandler/actions';
import { WebspaceErrors } from "../../../../../../server/shared/webspace/WebspaceErrors.js";
import { InsufficientStorageErrorDialog } from '../../../../view/common/FileChooser/dialogIds';

// TODO: refactor
let
    isMultiSelect,
    onSaveAction;

const completeDownload = ({ selectedImages, downloads, storeDispatch }) => {
    const assets = downloads.map((d: SstockImageDownload) => {
        const anImage: null | undefined | ShutterstockSelectedImageItem = selectedImages.find(img => img.imageId === d.imageId);
        if (!anImage) {
            throw new Error(`Could not find downloaded image ${d.imageId} in state`);
        }

        return {
            id: makeUuid(),
            type: ASSET_IMAGE_DAL_TYPE,
            etag: d.webspaceEtag,
            url: d.webspaceUrl,
            contentType: d.contentType,
            filesize: anImage.fileSize,
            alpha: null,
            bpp: null,
            width: d.imageDimensions.width,
            height: d.imageDimensions.height,
            animated: false,
            recommendedFormat: null,
        };
    });

    const
        saveAction = makeActionForwardToSelectedComponent({
            // $FlowFixMe onSaveAction will be set
            type: onSaveAction,
            payload: isMultiSelect ? { assets } : { asset: assets[0] }
        });

    isMultiSelect = null;
    onSaveAction = null;

    storeDispatch(closeDialog());
    storeDispatch(fcResetAction());
    return storeDispatch(saveAction);
};

export const freeOneComSaveMiddleware = (store: AppStore) => (next: Dispatch) => (action: Action) => {
    if (action.type === SHUTTERSTOCK_SAVE_ACTION) {
        ({ isMultiSelect, onSaveAction } = action.payload);

        const
            state = store.getState(),
            activeTabId = fcDialogActiveTabIdAppSel(state),
            requestImages = shutterstockSelectedImagesAppSel(activeTabId)(state).map(({ imageId, description }) => ({
                imageId,
                description
            })),
            request = {
                images: requestImages
            };

        store.dispatch(downloadShutterstockImagesApiActionAction(activeTabId, request));

        return next(action);
    }

    if (action.type === DOWNLOAD_SHUTTERSTOCK_IMAGES_REQUEST_ACTION) {
        const
            // @ts-ignore this can be a bug
            selectedImageIds = shutterstockSelectedImagesIdsAppSel(store.getState()),
            plural = selectedImageIds.length > 1;

        store.dispatch(openShutterstockUploadingDialogAction({ plural, videos: false }));
    }

    if (action.type === DOWNLOAD_SHUTTERSTOCK_IMAGES_SUCCESS_ACTION) {
        store.dispatch(closeShutterstockUploadingDialogAction());

        const
            state = store.getState(),
            activeTabId = fcDialogActiveTabIdAppSel(state),
            res: PostSstockImageDownloadsResponse = action.payload,
            { downloads } = res,
            appState = store.getState(),
            successfulPartialDownloads = shutterstockSuccessfulPartialDownloadsAppSel(activeTabId)(appState),
            allDownloads = [...downloads, ...successfulPartialDownloads];

        return completeDownload({
            selectedImages: shutterstockSelectedImagesAppSel(activeTabId)(appState),
            downloads: allDownloads,
            storeDispatch: store.dispatch,
        });
    }

    if (action.type === DOWNLOAD_SHUTTERSTOCK_IMAGES_FAILURE_ACTION) {
        store.dispatch(closeShutterstockUploadingDialogAction());

        const { payload: { error } } = action;
        const activeTabId = fcDialogActiveTabIdAppSel(store.getState());

        sendFreeOnecomCrashReport({
            message: action.payload,
            additionalInfo: {
                actionEndpointParams: action.endpointParams,
                actionApiTag: action.apiTag,
            },
        });

        if (error === SstockErrorReason.MAX_DOWNLOADS_PER_REQUEST) {
            const quotaConfig = sstockQuotaAppStateSel(store.getState());
            store.dispatch(openMaxDownloadsPerRequestDialogAction(activeTabId, quotaConfig.perRequest));
        } else if (error === SstockErrorReason.PARTIAL_DOWNLOAD) {
            const
                { payload: { message: downloads } } = action,
                appState = store.getState(),
                isMultiSelect = isMultiSelectFcAppSel(appState),
                { insufStorErrRes: insufficientStorageErrorImages, retrErrorRes: retriableErrorImages } =
                    shutterstockSelectedImagesAppSel(activeTabId)(appState).reduce(({ insufStorErrRes, retrErrorRes }, img) => {
                        const failure = downloads.find(
                            d => d.imageId === img.imageId && !d.ok,
                        );
                        if (failure) {
                            (failure.webspaceUploadError === WebspaceErrors.INSUFFICIENT_STORAGE ?
                                insufStorErrRes : retrErrorRes).push(img);
                        }
                        return { insufStorErrRes, retrErrorRes };
                        // NOSONAR
                    }, { insufStorErrRes: [] as any[], retrErrorRes: [] as any[] });

            store.dispatch(storeSuccessfulshutterstockPartialDownloadsAction(activeTabId, downloads));
            if (insufficientStorageErrorImages.length) {
                store.dispatch(openDialog(InsufficientStorageErrorDialog,
                    { fileNames: insufficientStorageErrorImages.map((resource) => resource.description) }));
            }
            if (retriableErrorImages.length) {
                store.dispatch(openShutterstockPartialDownloadsDialogAction({
                    kind: activeTabId,
                    multi: isMultiSelect,
                    images: retriableErrorImages,
                }));
            }
        } else {
            store.dispatch(openApiErrorHandlerDialog({
                messages: 'msg: common.downloadingFailed {Sorry, downloading failed.}',
            }));
        }
    }

    if (action.type === RETRY_FAILED_SHUTTERSTOCK_PARTIAL_DOWNLOADS_ACTION) {
        const
            appState = store.getState(),
            activeTabId = fcDialogActiveTabIdAppSel(appState),
            failedPartialDownloads = shutterstockFailedPartialDownloadsAppSel(activeTabId)(appState);

        if (failedPartialDownloads.length) {
            const request = {
                images: failedPartialDownloads.map(
                    ({ imageId, description }) => ({ imageId, description }),
                ),
            };

            store.dispatch(downloadShutterstockImagesApiActionAction(activeTabId, request));
            store.dispatch(closeShutterstockPartialDownloadsDialogAction());
        } else {
            throw new Error('Expecting to have failed partial downloads when retrying');
        }
    }

    if (action.type === CANCEL_FAILED_SHUTTERSTOCK_PARTIAL_DOWNLOADS_ACTION) {
        const
            appState = store.getState(),
            activeTabId = fcDialogActiveTabIdAppSel(appState);

        store.dispatch(closeShutterstockPartialDownloadsDialogAction());

        return completeDownload({
            selectedImages: shutterstockSelectedImagesAppSel(activeTabId)(appState),
            downloads: shutterstockSuccessfulPartialDownloadsAppSel(activeTabId)(appState),
            storeDispatch: store.dispatch,
        });
    }

    return next(action);
};
