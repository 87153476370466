const { ConfigComponentList } = require("../constants.js");

class GoogleReviewsConfig {
    isGoogleReviewsAllowed() {
        const googleReviews = this._featuresConfig[ConfigComponentList.GOOGLE_REVIEWS];
        return googleReviews && googleReviews.enabled;
    }
}

module.exports = GoogleReviewsConfig;
