import iterate from './iterate';
import { GOOGLE_PREFIX } from '../presentational/AddGoogleFont/constants';
import fontFamilies from "../presentational/FontFamily/fontFamilies";

const matchRegEx = /font-family\s*:\s*(['"]?|(?:&quot;)?)([\w\s]+)(?:\1)/g;
const capturingGroup = 2;

export const findFontFamilyInContent = (content: string) => {
    const matches: Array<string> = [];
    if (typeof content === 'string' && content.length) {
        let match = matchRegEx.exec(content);
        while (match) {
            matches.push(match[capturingGroup]);
            match = matchRegEx.exec(content);
        }
    }
    return matches;
};

export default function getPageGoogleFonts(data: any) {
    const
        googleFonts: Array<string> = [],
        addFontToList = (entry) => {
            if (entry) {
                let font = entry;

                if (typeof font === 'string') {
                    if (font.indexOf(GOOGLE_PREFIX) === -1 && fontFamilies.indexOf(font) === -1) {
                        font = `${GOOGLE_PREFIX}${font}`;
                    }

                    if (font.indexOf(GOOGLE_PREFIX) === 0 && googleFonts.indexOf(font) === -1) {
                        googleFonts.push(font);
                    }
                }
            }
        },
        visit = part => {
            // Handle font from style of components.
            const font = part.fontType || part.font || part.fontFamily;
            addFontToList(font);

            // Handle fonts from text and table component, stored in field 'content' using regex.
            const content = part.content,
                fonts = findFontFamilyInContent(content);

            if (fonts.length) {
                fonts.forEach(addFontToList);
            }
        };

    iterate(visit, data);

    return googleFonts;
}
