const { ConfigComponentList } = require("../constants.js");

class WidgetsBookingsConfig {
    isBoookingsWidgetAllowed() {
        const bookingsWidget = this._featuresConfig[ConfigComponentList.WIDGETS_BOOKINGS];
        return bookingsWidget && bookingsWidget.enabled;
    }
}

module.exports = WidgetsBookingsConfig;
