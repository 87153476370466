import * as R from 'ramda';
import makeEpic from "../../../../epics/makeEpic";
import valueActionType from './valueActionType';
import { customSendReport } from "../../../../customSendCrashReport";
import { COMPONENTS_MAP_INITIALIZED } from "../../../Workspace/epics/componentsEval/actionTypes";
import { PAGE_DATA_LOAD_SUCCESS } from "../../../PagesTree/actionTypes";
import {
    PAGE_DATASET_LOADED,
    PAGE_DATASET_UPATER_AFTER_SITE_DOCUMENTS_UPDATE_SUCCESS,
    PAGE_DATASET_UPDATED_AFTER_SAVE
} from "./updateReasons";
import { receiveOnly, whenApiTag } from '../../../../epics/makeCondition';
import { ReceiveOnlyComponentsMap } from "../../../Workspace/epics/componentsEval/selectorActionTypes";
import styleSheetValueActionType from '../../../Workspace/epics/stylesheets/valueActionType';
import templateValueActionType from '../../../oneweb/Template/epics/template/valueActionType';
import {
    SAVE,
    SITE_DOCUMENTS_UPDATE_FAILURE,
    SITE_DOCUMENTS_UPDATE_SUCCESS,
    SITE_DOCUMENTS_UPDATE_REQUEST
} from "../../actionTypes";
import { mapComponentsMapToPageData } from "../../../../../dal/pageMapAdapter/index";
import { back as mapTemplateData } from "../../../../../dal/pageMapAdapter/mappers/Template/index";
import * as templateStyleMapper from '../../../oneweb/Template/epics/template/styleMapper';
import { preparePersistentModel } from "../../../../../dal/model/utils/index";
import * as globalstyleMappers from '../../../../../dal/pageMapAdapter/mappers/Globalstyles/index';
import { updateSiteDocuments } from "../../actionCreators/index";
import applyPersistedModelPatch from "../../../../../dal/utils/applyPersistedModelPatch";
import {
    PAGE_DATASET_SAVE_SUCCESS,
    PAGE_DATASET_SAVE_FAILURE,
    UPDATE_PAGE_TEMPLATE_SUCCESS_ACTION,
    UPDATE_PAGE_SAVE_STATUS_ON_NOCHANGE
} from "./actions";
import DataPageSet from "../../../../../dal/model/DataPageSet";
import heightsToSaveValueActionType from '../../../Workspace/epics/heightsToSave/valueActionType';
import { PAGE_DATA_SET_REQUEST_PUBLIC_API_TAG } from "./constants";
import { templateDataIsChanged } from "./templateDataIsChanged";
import { objectCollectionsAreDiff } from "../../../../utils/objectsDiffChecks";
import { stylesheetDataIsChanged, themeDataIsChanged } from "./stylesheetDataIsChanged";
import mobileEditorChanges from '../../../MobileViewEditor/epics/reorder/valueActionType';
import isMobileDataChanged from "../../../MobileViewEditor/isMobileDataChanged";
import extractMobileData from "../../../MobileViewEditor/extractMobileData";
import { DataPageTemplate } from "../../../../../dal/model/index";
import { siteDataValueActionType } from "../siteData/valueActionType";
import { siteSettingsValueActionType } from "../siteSettings/valueActionType";
import { OldComponentTypes } from '../../../../../dal/pageMapAdapter/componentTypesMap';
import ComponentTypes from '../../../../view/oneweb/registry/ComponentTypes';
import { TextComponentKind } from '../../../oneweb/Text/kind';
import { undoManagerValueActionType } from '../../../../epics/undoManager/valueActionType';
import RELATIONS_VALUE_ACTION_TYPE from '../../../Workspace/epics/relations/valueActionType';
import { SITE_SETTINGS_DB_LAYER_DATA_UPDATED } from '../siteSettings/actionTypes';
import { resetDefaultHeightForHeaderFooter } from "../../../Workspace/epics/defaultHeaderFooterHeights/utils";
import defaultHeaderFooterHeightsVAT from "../../../Workspace/epics/defaultHeaderFooterHeights/valueActionType";
import {
    shareHeaderAndFirstSectionBgImgEpic
} from '../../../oneweb/Section/epics/shareHeaderAndFirstSectionBgImgEpic';
import { isCmpsMapValidBeforeSave, getSessionStorageIsCmpsMapValid } from './isCmpsMapValidBeforeSave';
import {
    getCrashTraceStringified
} from "../../../../redux/middleware/crashTraceCollector";
import AUTO_COLOR_LEFT_PANEL_EPIC_VALUE from "../../../AutoColorLeftPanel/epic/valueActionType";
import { THEME_COLORS } from "../../../ThemeGlobalData/constants";
import { getFooterSection, getHeaderSection } from "../../../oneweb/Section/utils";
import mhfOldDataVAT from "../../../ModernLayouts/epics/oldData/valueActionType";
import { validateMHFBeforeSave } from '../../../ModernLayouts/utils';
import { generateScreenshot } from '../../../Workspace/epics/saveStatus/action';
import getAccessManager from "../../../../getAccessManager";
// import isTestEnv from "../../../../debug/isTestEnv";

const getHeightsUpdatedComponentsMap = (oldComponentsMap, heights) => {
    const componentsMap = {};
    let hasChanges = false;

    Object.keys(oldComponentsMap).forEach(id => {
        let height = oldComponentsMap[id].height;
        if (heights[id] && oldComponentsMap[id].height !== heights[id].heightToSave) {
            height = heights[id].heightToSave;
            hasChanges = true;
        }
        componentsMap[id] = { ...oldComponentsMap[id], height };
    });
    return hasChanges ? componentsMap : oldComponentsMap;
};

export default makeEpic({
    valueActionType,
    defaultScope: {
        negativeTopComponentIds: [],
    },
    dispatchOutside: true,
    updaters: [
        {
            conditions: [whenApiTag(PAGE_DATA_LOAD_SUCCESS, PAGE_DATA_SET_REQUEST_PUBLIC_API_TAG)],
            reducer: ({ values: [pageDataSet], scope }) => ({
                state: pageDataSet,
                scope,
                updateReason: PAGE_DATASET_LOADED
            })
        },
        {
            conditions: [
                ReceiveOnlyComponentsMap,
                receiveOnly(styleSheetValueActionType),
                receiveOnly(templateValueActionType),
                receiveOnly(heightsToSaveValueActionType),
                receiveOnly(siteDataValueActionType),
                receiveOnly(mobileEditorChanges),
                receiveOnly(siteSettingsValueActionType),
                receiveOnly(undoManagerValueActionType),
                receiveOnly(SITE_SETTINGS_DB_LAYER_DATA_UPDATED),
                receiveOnly(defaultHeaderFooterHeightsVAT),
                receiveOnly(shareHeaderAndFirstSectionBgImgEpic.valueActionType),
                receiveOnly(AUTO_COLOR_LEFT_PANEL_EPIC_VALUE),
                receiveOnly(mhfOldDataVAT),
                SAVE
            ],
            reducer: ({
                state,
                scope,
                values: [
                    componentsMap,
                    updatedStylesheet,
                    templateData,
                    heights,
                    site,
                    mobileEditorChanges,
                    siteSettings,
                    undoEpicData,
                    siteSettingsDbLayerData,
                    { isHeader: isDefaultHeaderHeightSet, isFooter: isDefaultFooterHeightSet },
                    shareHeaderAndFirstSectionBgImg,
                    { show: autoColorLeftPanelIsOpen, initialPalette },
                    mhfData
                ]
            }) => { // eslint-disable-line no-unused-vars
                try {
                    const mhfValidators = validateMHFBeforeSave(componentsMap),
                        isCmpsMapValid = isCmpsMapValidBeforeSave(componentsMap, true);
                    let isValid = true,
                        isStopSave = false;
                    [...mhfValidators, isCmpsMapValid].forEach(v => {
                        const { isValid: _isValid = true, isStopSave: _isStopSave = false } = v;
                        if (!_isValid) {
                            const onPageLoadData = getSessionStorageIsCmpsMapValid();
                            customSendReport({
                                message: "SAVED_WITH_WRONG_DATA",
                                additionalInfo: {
                                    response: {
                                        desc: 'Saved with corrupted components data',
                                        onPageLoadData,
                                        data: {
                                            ...isCmpsMapValid.data,
                                            pageId: state.page.id,
                                            templateId: state.template.id,
                                        },
                                        actionsTraceStr: getCrashTraceStringified()
                                    },
                                }
                            });
                        }
                        isValid = isValid && _isValid;
                        isStopSave = isStopSave || _isStopSave;
                    });
                    if (!isValid && isStopSave) {
                        return {
                            state,
                            scope,
                            actionToDispatch: {
                                type: SITE_DOCUMENTS_UPDATE_FAILURE,
                                payload: {
                                    desc: 'Save failed due to corrupted components data',
                                    data: {
                                        ...isCmpsMapValid.data,
                                        pageId: state.page.id,
                                        templateId: state.template.id,
                                    },
                                    actionsTraceStr: getCrashTraceStringified(),
                                }
                            }
                        };
                    }
                } catch (e) {
                    // Do nothing
                }

                let newComponentsMap = resetDefaultHeightForHeaderFooter(getHeightsUpdatedComponentsMap(componentsMap, heights),
                    { isDefaultHeaderHeightSet, isDefaultFooterHeightSet });
                let header = getHeaderSection(componentsMap),
                    otherCmpsMap = {};

                if (header && header.modernLayout) {
                    otherCmpsMap = header.modernLayout.active ?
                        mhfData.oldData.header.cmpsMap :
                        R.pathOr({}, ['newData', 'header', header.modernLayout.layoutId, 'cmpsMap'], mhfData);
                    otherCmpsMap = { ...otherCmpsMap, ...mhfData.toggleOffData.header[header.modernLayout.layoutId] };
                }
                let footer = getFooterSection(componentsMap);
                if (footer && footer.modernLayout) {
                    otherCmpsMap = {
                        ...otherCmpsMap,
                        ...(footer.modernLayout.active ?
                            mhfData.oldData.footer.cmpsMap :
                            R.pathOr({}, ['newData', 'footer', footer.modernLayout.layoutId, 'cmpsMap'], mhfData)),
                        ...mhfData.toggleOffData.footer[footer.modernLayout.layoutId]
                    };
                }
                newComponentsMap = {
                    ...newComponentsMap,
                    ...otherCmpsMap
                };
                const
                    nextState: Record<string, any> = {},
                    documentsUpdate: Record<string, any> = {},
                    pageData = mapComponentsMapToPageData(
                        newComponentsMap,
                        updatedStylesheet,
                        site
                    );

                const { pageMobileData, templateMobileData } = extractMobileData(mobileEditorChanges, newComponentsMap);
                // check if page should be updated
                if (objectCollectionsAreDiff(state.page.items, pageData.page.items) ||
                    isMobileDataChanged(state.page.mobileData, pageMobileData) ||
                    state.page.shareHeaderAndFirstSectionBgImg !== shareHeaderAndFirstSectionBgImg.enabledBeforeModernLayout ||
                    state.page.shareModernHeaderAndFirstSectionBgImg !== shareHeaderAndFirstSectionBgImg.enabledInModernLayout
                ) {
                    const page = preparePersistentModel(
                        state.page,
                        {
                            items: pageData.page.items,
                            mobileData: pageMobileData,
                            shareHeaderAndFirstSectionBgImg: shareHeaderAndFirstSectionBgImg.enabledBeforeModernLayout,
                            shareModernHeaderAndFirstSectionBgImg: shareHeaderAndFirstSectionBgImg.enabledInModernLayout,
                            shareBgImgOffsetTop: shareHeaderAndFirstSectionBgImg.offsetTop, // TODO WBTGEN-16805 remove once all customer migratied to have consistent data without overlaps
                        }
                    );
                    nextState.page = page;
                    documentsUpdate.page = page;
                } else {
                    nextState.page = state.page;
                }

                // check if template should be updated
                const templateIsChanged = templateDataIsChanged({
                    template: state.template,
                    templateData,
                    nextTemplateItems: pageData.template.items
                });
                if (templateIsChanged || isMobileDataChanged(state.template.mobileData, templateMobileData)) {
                    const nextTemplate = preparePersistentModel(
                        new DataPageTemplate({ ...mapTemplateData(templateData), etag: state.template.etag }),
                        {
                            items: pageData.template.items,
                            mobileData: templateMobileData,
                        }
                    );
                    nextState.template = nextTemplate;
                    documentsUpdate.template = nextTemplate;
                } else {
                    nextState.template = state.template;
                }

                const
                    { styles } = globalstyleMappers.back(globalstyleMappers.to(state.stylesheet)),
                    { styles: nextStyles } = globalstyleMappers.back(updatedStylesheet),
                    themeData = R.prop('theme')(state.stylesheet),
                    nextThemeData = R.prop('theme')(updatedStylesheet),
                    themeIsChanged = themeDataIsChanged({ themeData, nextThemeData }),
                    stylesheetIsChanged = stylesheetDataIsChanged({
                        styles,
                        nextStyles,
                        templateDataStyle: templateData.style
                    });

                if (stylesheetIsChanged || themeIsChanged) {
                    let nextStylesheet = state.stylesheet;
                    if (stylesheetIsChanged) {
                        nextStylesheet = templateStyleMapper.back(
                            templateData,
                            preparePersistentModel(nextStylesheet, { styles: nextStyles })
                        );
                    }
                    if (themeIsChanged) {
                        let newThemeData;

                        if (autoColorLeftPanelIsOpen && initialPalette) {
                            /*
                                Set initialPalette as new theme data as we do not want to save updted
                                theme data while auto color left panel is open
                            */
                            newThemeData = {};
                            newThemeData.colors = THEME_COLORS.reduce((acc, themeColor, index) => {
                                return { ...acc, [themeColor]: initialPalette[index] };
                            }, {});
                        }
                        nextStylesheet = preparePersistentModel(nextStylesheet, { theme: newThemeData || nextThemeData }, true);
                    }
                    nextState.stylesheet = nextStylesheet;
                    documentsUpdate.stylesheet = nextStylesheet;
                } else {
                    nextState.stylesheet = state.stylesheet;
                }

                // Handle site settings
                const siteSettingsIsChanged = !R.equals(siteSettings.current, siteSettings.original);

                if (siteSettingsIsChanged) {
                    documentsUpdate.siteSettings = { ...siteSettings.current, ...siteSettingsDbLayerData };
                }

                if (Object.keys(documentsUpdate).length) {
                    const textComponentsWithNullContent =
                        [
                            ...(R.pathOr([], ['page', 'items'])(documentsUpdate)),
                            ...(R.pathOr([], ['template', 'items'])(documentsUpdate)),
                        ]
                            .filter(({ type }) => [OldComponentTypes[TextComponentKind], ComponentTypes[TextComponentKind]].includes(type))
                            .filter(({ content }) => content === null)
                            .map(({ id }) => id);
                    if (R.not(R.isEmpty(textComponentsWithNullContent))) {
                        let relevantUndoStateStack = undoEpicData.undoState.commandsStack;
                        let unprocessedIndex = 0;
                        while (
                            // Note: 256 KB is the limit, using 250 to allow some breathing space
                            JSON.stringify(relevantUndoStateStack).length > (250 * 1024)
                            && unprocessedIndex < relevantUndoStateStack.length
                        ) {
                            relevantUndoStateStack[unprocessedIndex] = R.evolve({
                                affectedEpics: R.pipe(
                                    R.omit([RELATIONS_VALUE_ACTION_TYPE]),
                                    R.mapObjIndexed(R.evolve({
                                        newStateParts: R.map(R.mapObjIndexed((v, k) => {
                                            return textComponentsWithNullContent.includes(k) ? v : 0;
                                        }))
                                    }))
                                )
                            })(relevantUndoStateStack[unprocessedIndex]);
                            unprocessedIndex++;
                        }
                        const relevantUndoState = {
                            index: undoEpicData.undoState.commandsStackIndex,
                            length: undoEpicData.undoState.commandsStack.length,
                            stack: R.reverse(relevantUndoStateStack), // reversed to prevent truncation of the latest undo points
                        };
                        return {
                            state,
                            scope,
                            actionToDispatch: {
                                type: SITE_DOCUMENTS_UPDATE_FAILURE,
                                payload: {
                                    response: { desc: 'Text component found with null content', data: relevantUndoState },
                                    useLenientTruncation: true,
                                }
                            }
                        };
                    }
                    return {
                        scope,
                        state: nextState,
                        actionToDispatch: updateSiteDocuments(documentsUpdate),
                        updateReason: PAGE_DATASET_UPDATED_AFTER_SAVE
                    };
                } else {
                    return {
                        state,
                        scope,
                        actionToDispatch: { type: UPDATE_PAGE_SAVE_STATUS_ON_NOCHANGE }
                    };
                }
            }
        },
        /*
            // TODO WBTGEN-16799
            Date: 28th July 2019,
            We need to remove below 2 updaters after 1 year, if no kibana crash report found
        */
        {
            conditions: [COMPONENTS_MAP_INITIALIZED],
            reducer: ({ state, scope, values: [{ componentsMap }] }) => {
                return {
                    state,
                    scope: {
                        ...scope,
                        negativeTopComponentIds: R.pipe(
                            R.values,
                            R.filter(comp => comp.top < 0),
                            R.map(comp => [comp.id, true]),
                            R.fromPairs
                        )(componentsMap)
                    }
                };
            }
        },
        {
            conditions: [
                ReceiveOnlyComponentsMap,
                SITE_DOCUMENTS_UPDATE_REQUEST
            ],
            reducer: ({ state, scope, values: [componentsMap, documents] }) => {
                let removeIds = [];
                if (
                    R.pipe(
                        R.values,
                        R.any(comp => !scope.negativeTopComponentIds[comp.id] && comp.top < 0)
                    )(componentsMap)
                ) {
                    customSendReport({
                        message: "COMPONENT_NEGATIVE_TOP",
                        additionalInfo: {
                            pageId: documents.page && documents.page.id,
                            templateId: documents.template && documents.template.id,
                            styleSheetId: documents.stylesheet && documents.stylesheet.id
                        }
                    });
                } else {
                    removeIds = R.pipe(
                        R.values, R.map(comp => comp.id), R.filter(comp => comp.top >= 0)
                    )(componentsMap);
                }
                return {
                    state,
                    scope: { ...scope, negativeTopComponentIds: R.omit(removeIds, scope.negativeTopComponentIds) }
                };
            }
        },
        {
            conditions: [SITE_DOCUMENTS_UPDATE_SUCCESS],
            reducer: ({ state, scope, values: [updatePatch] }) => {
                const newPageDataSet = Object.keys(state).reduce((acc, docType) => {
                    if (updatePatch[docType] && state[docType].id === updatePatch[docType].id) {
                        return {
                            ...acc,
                            [docType]: applyPersistedModelPatch(state[docType], updatePatch[docType])
                        };
                    }
                    return { [docType]: state[docType], ...acc };
                }, {});

                return {
                    scope,
                    state: new DataPageSet(newPageDataSet),
                    actionToDispatch: { type: PAGE_DATASET_SAVE_SUCCESS },
                    updateReason: PAGE_DATASET_UPATER_AFTER_SITE_DOCUMENTS_UPDATE_SUCCESS
                };
            }
        },
        {
            conditions: [SITE_DOCUMENTS_UPDATE_SUCCESS],
            reducer: ({ state, scope }) => {
                return {
                    scope,
                    state,
                    actionToDispatch: getAccessManager().shouldGenerateScreenshot() ? generateScreenshot() : null
                };
            }
        },
        {
            keepFullActions: true,
            conditions: [SITE_DOCUMENTS_UPDATE_FAILURE],
            reducer: ({ state, scope, values: [{ payload }] }) => {
                return {
                    scope,
                    state,
                    // $FlowFixMe
                    actionToDispatch: { type: PAGE_DATASET_SAVE_FAILURE, payload, response: payload }
                };
            }
        },
        {
            conditions: [UPDATE_PAGE_TEMPLATE_SUCCESS_ACTION],
            keepFullActions: true,
            reducer: ({ values: [{ endpointParams: newDocs, payload: updatePatch }], scope }) => ({
                state: new DataPageSet({
                    page: applyPersistedModelPatch(newDocs.page, updatePatch.page),
                    template: applyPersistedModelPatch(newDocs.template, updatePatch.template),
                    stylesheet: applyPersistedModelPatch(newDocs.stylesheet, updatePatch.stylesheet)
                }),
                scope,
                updateReason: PAGE_DATASET_LOADED
            })
        }
    ]
});
