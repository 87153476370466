export const webShopMHFMockData = {
    "isShopPublished": true,
    "paymentMethods": [
        {
            "key": "Paypal",
            "images": [
                "https://webshop.group-cdn.one/paypal.3bd17ec4.svg"
            ],
            "name": null
        },
        {
            "key": "Stripe",
            "name": null
        },
        {
            "images": null,
            "key": "Manual",
            "name": "Bank Transfer"
        },
        {
            "key": "Ideal",
            "images": [
                "https://webshop.group-cdn.one/ideal.ce7159f4.svg"
            ],
            "name": null
        },
        {
            "key": "Giropay",
            "images": [
                "https://webshop.group-cdn.one/giropay.ebff196a.svg"
            ],
            "name": null
        },
        {
            "key": "Sofort",
            "images": [
                "https://webshop.group-cdn.one/sofort.96ebc62d.svg"
            ],
            "name": null
        },
        {
            "key": "CreditCard",
            "images": [
                "https://webshop.group-cdn.one/visa.0ebb52d9.svg",
                "https://webshop.group-cdn.one/master-card.063b13c0.svg",
                "https://webshop.group-cdn.one/american-express.444731bb.svg"
            ],
            "name": null
        }
    ],
    "policies": [
        {
            "name": "Privacy policy"
        },
        {
            "name": "Sales terms & conditions"
        },
        {
            "name": "Refund policy"
        }
    ]
};
