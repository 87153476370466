import { BLOG_DEFAULT_HEIGHT, BLOG_DEFAULT_WIDTH, BLOG_MIN_HEIGHT, BLOG_MIN_WIDTH } from "../constants";

export const defaultState = {
    width: BLOG_DEFAULT_WIDTH,
    height: BLOG_DEFAULT_HEIGHT,
    blogId: "",
    layout: "list",
    mobileHide: false,
};

export const defaultExtensionState = {
    minDimensions: {
        width: BLOG_MIN_WIDTH,
        height: BLOG_MIN_HEIGHT
    }
};

