/* eslint-disable max-classes-per-file */

import { WEBSPACE_MAX_IMAGE_RESOLUTION, MAX_ALLOWED_SVG_SIZE_BYTES } from "../../../server/shared/webspace/constants.js";
import { FileUtils, VideoUtils } from "./fileUtils";

class Invalids {
    unsupported: Array<File>;
    pixelSize: Array<File>;
    svgSize: Array<File>;
    badImage: Array<File>;
    bigVideos: Array<File>;
    videoUploadLimitExceeded: Array<File>;

    constructor() {
        this.unsupported = [];
        this.pixelSize = [];
        this.svgSize = [];
        this.badImage = [];
        this.bigVideos = [];
        this.videoUploadLimitExceeded = [];
    }
}

class FilterResult {
    invalids: Invalids;
    valids: Array<File>;

    constructor(valids: Array<File>, invalids: Invalids) {
        this.valids = valids;
        this.invalids = invalids;
    }
}

const
    isImage = (file: File) => {
        return file.type.substring(0, 6) === 'image/';
    },
    sizeIsNotValid = (width: number, height: number) => ((width * height) - WEBSPACE_MAX_IMAGE_RESOLUTION) > 1000000;

class FileListValidator {
    supportedTypesPattern: null | undefined | RegExp;

    filter(files: FileList, props: any = {}): Promise<FilterResult> {
        const
            invalids = new Invalids(),
            valids: any = [],
            chain: (Promise<void> | boolean)[] = [];

        Object.keys(files).forEach(i => {
            const f = files[i].type ? files[i] : FileUtils.updateFileTypeIfMissing(files[i]);

            // validate supported types
            if (f.type && this.supportedTypesPattern && !this.supportedTypesPattern.test(f.type)) {
                invalids.unsupported.push(f);
                chain.push(true);
                return;
            }

            // validate image
            if (f.name.endsWith('.svg')) {
                if (f.size > MAX_ALLOWED_SVG_SIZE_BYTES) {
                    invalids.svgSize.push(f);
                } else {
                    valids.push(f);
                }
            } else if (isImage(f)) {
                chain.push(new Promise(resolve => {
                    const
                        img = new Image();

                    img.onload = function () {
                        // @ts-ignore
                        if (sizeIsNotValid(this.width, this.height)) {
                            invalids.pixelSize.push(f);
                        } else {
                            valids.push(f);
                        }
                        resolve();
                    };
                    img.onerror = () => {
                        invalids.badImage.push(f);
                        resolve();
                    };
                    img.src = URL.createObjectURL(f);
                }));
                return;
            } else if (VideoUtils.isVideoFile(f.name)) {
                if (VideoUtils.isVideoExceedingMaxSize(f)) {
                    invalids.bigVideos.push(f);
                } else if (props.videoUploadInProgress) {
                    invalids.videoUploadLimitExceeded.push(f);
                } else {
                    valids.push(VideoUtils.renameFileToMP4(f));
                }
            } else {
                valids.push(f);
            }
        });

        return Promise.all(chain).then(() => new FilterResult(valids, invalids));
    }

    setSupportedTypes(inTypes: Array<string> | string) {
        if (!inTypes.length) this.supportedTypesPattern = null;
        else {
            const types = Array.isArray(inTypes) ? inTypes : [inTypes];
            this.supportedTypesPattern = new RegExp('^(?:%types%)'.replace('%types%', types.join('|')));
        }
    }
}

export { FileListValidator as default, FilterResult, Invalids, sizeIsNotValid };
