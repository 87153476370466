import * as R from 'ramda';

import { WEBSPACE_PREFIX } from '../../../../../dal/index';
import * as globalStylesMapper from "../../../../../dal/pageMapAdapter/mappers/Globalstyles/index";

import { CLOSE_DIALOG } from '../../../../redux/modules/actionTypes';

import makeUuid from '../../../../../utils/makeUuid';
import { traverse } from '../../../../utils/ramdaEx';
import { getAssetsReplacementTransformations } from '../../../../utils/assetUtils';

import { PrimaryButtonType } from '../../../../view/common/Button/PrimaryButton';
import { SecondaryButtonType } from '../../../../view/common/Button/SecondaryButton';
import { StripTypes } from '../../../../view/common/dialogs/baseDialog/index';

import { reset, receiveOnly } from '../../../../epics/makeCondition';
import makeEpic, { fullEpicUndoablePath } from '../../../../epics/makeEpic';
import * as undoManagerUpdateReasons from '../../../../epics/undoManager/updateReasons';

import textGlobalstyleEpicUpdaters from '../../../Globalstyles/Text/epicUpdaters';
import { epicUpdaters as tableGlobalstyleEpicUpdaters } from "../../../Globalstyles/Table/epicUpdaters";
import deleteGlobalstyleDialogId from '../../../Globalstyles/deleteGlobalstyleDialogId';

import { openDialog, updateDialog, closeDialog } from '../../../App/actionCreators/index';
import pageDatasetLoadedActionType from "../../../App/epics/pageDataset/pageDatasetLoadedActionType";

import MenuKind from '../../../oneweb/Menu/kind';
import ButtonKind from '../../../oneweb/Button/kind';
import TextKind from '../../../oneweb/Text/kind';
import ContactFormKind from "../../../oneweb/ContactForm/kind";

import MenuGlobalstyle from '../../../oneweb/Menu/globalStyle/kind';
import ButtonGlobalstyle from '../../../oneweb/Button/globalStyle/kind';
import { checkStylesheetDeletable } from '../../actionCreators/index';

import currentOpenedStylesheetValueActionType from "../currentOpenedStylesheet/valueActionType";
import currentOpenedStylesheetIdValueActionType from "../currentOpenedStylesheetId/valueActionType";
import { ReceiveOnlyComponentsMap } from '../componentsEval/selectorActionTypes';

import * as stylesheetsSelectors from './selectors';

import * as updateReasons from './updateReasons';
import * as actionTypes from "./actionTypes";
import valueActionType from './valueActionType';

import textContentHasLinkGlobalstyle from "../../../../../../server/shared/textContentHasLinkGlobalstyle.js";
import makeStateSelectorReducer from '../../../../epics/makeStateSelectorReducer';
import type { Stylesheet, Stylesheets } from "./flowTypes";
import { THEME_COLORS } from "../../../ThemeGlobalData/constants";
import {
    DONE_AUTO_COLOR_LEFT_PANEL
} from '../../../AutoColorLeftPanel/actionTypes';
import AUTO_COLOR_LEFT_PANEL_EPIC_VALUE from "../../../AutoColorLeftPanel/epic/valueActionType";
import { COLOR_THEME_SITE_SETTINGS_EPIC_VALUE } from "../../../SiteSettings/ColorThemeData/colorThemeSiteSettingsVAT";

let dialogClosed = false;

const
    updateByIndexReducer = (stylesheet: Stylesheet, stylesheets: Stylesheets): Stylesheets => {
        const
            index = stylesheetsSelectors.indexByIdSelector(R.path(['id'], stylesheet))(stylesheets),
            oldStylesheet = R.path(['styles', index], stylesheets);

        if (oldStylesheet !== stylesheet) {
            return R.assocPath(['styles', index], stylesheet, stylesheets);
        }
        return stylesheets;
    },
    getClonedStylesheet = (stylesheet: Stylesheet, stylesheets: Stylesheets): Stylesheet => {
        const
            type = R.prop('type', stylesheet),
            nextIndex = stylesheetsSelectors.getNextStyleIndex(type)(stylesheets),
            clonedStylesheet = R.evolve({
                id: R.always(makeUuid()),
                ref: R.replace(/\d+/, nextIndex),
                name: R.replace(/\d+/, nextIndex),
                index: R.always(nextIndex)
            })(stylesheet);

        return clonedStylesheet;
    },
    duplicateCurrentStylesheet = (stylesheetId: string, stylesheets: Stylesheets): Stylesheets => {
        const
            selectedStylesheet = stylesheetsSelectors.stylesheetByIdSelector(stylesheetId)(stylesheets),
            clonedStylesheet = getClonedStylesheet(selectedStylesheet, stylesheets);

        return R.evolve({
            styles: R.append(clonedStylesheet)
        }, stylesheets);
    },
    deleteCurrentStylesheet = (stylesheetId: string, stylesheets: Stylesheets) => {
        const
            styles = stylesheetsSelectors.getAllStylesheets(stylesheets),
            stylesheetArrayIndex = stylesheetsSelectors.indexByIdSelector(stylesheetId)(stylesheets),
            newStyles = styles.slice(0, stylesheetArrayIndex).concat(styles.slice(stylesheetArrayIndex + 1));

        return R.evolve({ styles: R.always(newStyles) }, stylesheets);
    },
    notUndoableReasonsMap = {
        [updateReasons.REPLACE_MISSING_ASSETS]: true,
        [updateReasons.DELETE_MISSING_ASSETS]: true,
        [actionTypes.UPDATE_THEME_PALETTE]: true
    },
    deleteGlobastyleDialogDefaultProps = {
        showSpinner: false,
        stripType: StripTypes.INFO,
        buttonType: SecondaryButtonType,
        buttonText: 'msg: common.ok {OK}',
        actionToDispatch: closeDialog()
    },
    dialogAction = (dialogFn, dialogProps = {}) => dialogFn(
        deleteGlobalstyleDialogId,
        { ...deleteGlobastyleDialogDefaultProps, ...dialogProps }
    ),
    openDialogAction = props => dialogAction(openDialog, props),
    updateDialogAction = props => dialogAction(updateDialog, props),
    stylesheetInUseByComponents =
        (id, nameInComponent, componentsMap) =>
            Object
                .keys(componentsMap)
                .some(componentId => {
                    const component = componentsMap[componentId];

                    if (ButtonKind === component.kind) {
                        return component.style.globalId === id;
                    } else if (MenuKind === component.kind) {
                        return (component.style.globalId === id || component.subStyle.globalId === id);
                    } else if (ContactFormKind === component.kind) {
                        return component.styleButton.globalId === id;
                    } else if (TextKind === component.kind) {
                        if (component.content) {
                            return textContentHasLinkGlobalstyle(component.content, nameInComponent);
                        } else if (Array.isArray(component.links) && component.links.length > 0) {
                            return component.links.some(link => {
                                return link.style.globalId === id;
                            });
                        }
                    }
                    return false;
                });

const stylesheetsEpic = makeEpic({
    valueActionType,
    dispatchOutside: true,
    undo: {
        isUndoableChange: ({ updateReason, sourceAction }) => {
            if (sourceAction && sourceAction.type === DONE_AUTO_COLOR_LEFT_PANEL) {
                return true;
            }

            return !notUndoableReasonsMap[updateReason];
        },
        undoablePaths: fullEpicUndoablePath
    },
    updaters: [
        {
            conditions: [actionTypes.STYLESHEET_REPLACE_MISSING_ASSETS],
            reducer: ({ values: [assetsReplacements], state: epicState }) => {
                const
                    { styles } = epicState,
                    assetsReplacementTransformations = getAssetsReplacementTransformations(assetsReplacements, styles),
                    newStyles = R.pipe(...assetsReplacementTransformations)(styles),
                    newStylesheets = { ...epicState, styles: newStyles };

                return {
                    state: newStylesheets,
                    updateReason: updateReasons.REPLACE_MISSING_ASSETS
                };
            }
        },
        {
            conditions: [actionTypes.STYLESHEET_DELETE_MISSING_ASSETS],
            reducer: ({ values: [missingAssets], state: epicState }) => {
                const
                    { styles } = epicState,
                    process = (paths, value, context) => {
                        if (value instanceof Object &&
                            value.hasOwnProperty("url") &&
                            value.url.indexOf(WEBSPACE_PREFIX) === 0 &&
                            missingAssets.indexOf(value.url) > -1
                        ) {
                            context.assetPaths.push(paths);
                        }
                        return context;
                    },
                    updatedStyles = styles.map(style => {
                        if (style.type === ButtonGlobalstyle || style.type === MenuGlobalstyle) {
                            // @ts-ignore assetPaths can be changed to paths
                            const { assetPaths } = traverse(style, process, { assetPaths: [] });
                            return assetPaths.reduce((acc, assetPath) => R.assocPath(assetPath, null, acc), style);
                        }
                        return style;
                    });

                return {
                    state: R.merge(epicState, { styles: updatedStyles }),
                    updateReason: updateReasons.DELETE_MISSING_ASSETS
                };
            }
        },
        {
            conditions: [
                receiveOnly(AUTO_COLOR_LEFT_PANEL_EPIC_VALUE),
                receiveOnly(COLOR_THEME_SITE_SETTINGS_EPIC_VALUE),
                pageDatasetLoadedActionType
            ],
            reducer: ({ state, values: [{ show: autoColorLeftPanelOpen }, { autoColorMode }, pageData] }) => {
                const styles = globalStylesMapper.to(pageData.stylesheet);

                const themeStyles: Record<string, any> = {};

                if (autoColorLeftPanelOpen && autoColorMode &&
                    state && state.theme && state.theme.colors
                ) {
                    themeStyles.theme = { colors: state.theme.colors };
                }

                return ({
                    state: {
                        ...styles,
                        ...themeStyles
                    },
                    updateReason: undoManagerUpdateReasons.UNDO_INITIAL_STATE
                });
            }
        },
        {
            conditions: [currentOpenedStylesheetValueActionType],
            reducer: ({ values: [stylesheet], state }) => {
                if (stylesheet) {
                    const possiblyNewState = updateByIndexReducer(stylesheet, state);
                    if (possiblyNewState === state) {
                        return { state };
                    }

                    return ({
                        state: possiblyNewState,
                        updateReason: updateReasons.STYLE_UPDATED
                    });
                }
                return { state };
            }
        },
        {
            conditions: [
                reset(currentOpenedStylesheetIdValueActionType),
                actionTypes.DUPLICATE_CURRENT_STYLESHEET
            ],
            reducer: ({ values: [stylesheetId], state: stylesheets }) => {
                const
                    updatedStylesheets = duplicateCurrentStylesheet(stylesheetId, stylesheets),
                    newStylesheet = R.last(stylesheetsSelectors.getAllStylesheets(updatedStylesheets));

                return {
                    state: updatedStylesheets,
                    actionToDispatch: {
                        type: actionTypes.STYLESHEET_DUPLICATED, payload: { stylesheetId: newStylesheet.id }
                    },
                    updateReason: updateReasons.STYLE_DUPLICATED
                };
            }
        },
        {
            conditions: [
                actionTypes.DELETE_CURRENT_STYLESHEET,
                receiveOnly(currentOpenedStylesheetValueActionType),
                ReceiveOnlyComponentsMap
            ],
            reducer: ({ values: [, { id: stylesheetId, ref, type }, componentsMap], state: stylesheets }) => {
                dialogClosed = false;
                const
                    styleCountByType = R.pipe(
                        stylesheetsSelectors.getAllStylesheets,
                        stylesheetsSelectors.getStylesByType(type),
                        R.length
                    )(stylesheets),
                    stylesheetNameInComponent = ref.replace(/[^\w]+/g, '');

                // Stylesheet is in use in at least one of the component from current page or template.
                if (styleCountByType === 1) {
                    return {
                        state: stylesheets,
                        actionToDispatch: openDialogAction({
                            title: 'msg: gs.deleteGlobalstyle.cantDeleteStyle.title {Cannot delete style}',
                            message: 'msg: gs.atLeastOneStyle.message {Sorry, this style cannot be deleted. You need at least one style.}', // eslint-disable-line max-len
                            stripType: StripTypes.WARNING,
                            buttonType: PrimaryButtonType
                        })
                    };
                } else if (stylesheetInUseByComponents(stylesheetId, stylesheetNameInComponent, componentsMap)) {
                    return {
                        state: stylesheets,
                        actionToDispatch: openDialogAction({
                            title: 'msg: gs.deleteGlobalstyle.cantDeleteStyle.title {Cannot delete style}',
                            message: 'msg: gs.deleteGlobalstyle.cantDeleteStyle.message {Sorry, you cannot delete a style that is already in use.}', // eslint-disable-line max-len
                            stripType: StripTypes.WARNING,
                            buttonType: PrimaryButtonType
                        })
                    };
                }

                // Check if the stylesheet is in use in other pages from the current template
                return {
                    state: stylesheets,
                    multipleActionsToDispatch: [
                        openDialogAction({
                            showSpinner: true,
                            title: 'msg: gs.deletingStyle.title {Deleting style}',
                            message: null,
                            buttonText: 'msg: common.cancel {Cancel}'
                        }),
                        checkStylesheetDeletable(stylesheetId, stylesheetNameInComponent)
                    ]
                };
            }
        },
        {
            conditions: [
                actionTypes.CHECK_STYLESHEET_DELETABLE_SUCCESS,
                receiveOnly(currentOpenedStylesheetValueActionType)
            ],
            reducer: ({ values: [response, { id: stylesheetId, type: stylesheetType }], state: stylesheets }) => {
                if (dialogClosed || response.length > 0) {
                    return {
                        state: stylesheets,
                        actionToDispatch: updateDialogAction({
                            title: 'msg: gs.deleteGlobalstyle.cantDeleteStyle.title {Cannot delete style}',
                            message: 'msg: gs.deleteGlobalstyle.cantDeleteStyle.message {Sorry, you cannot delete a style that is already in use.}', // eslint-disable-line max-len
                            stripType: StripTypes.WARNING,
                            buttonType: PrimaryButtonType
                        })
                    };
                }

                return {
                    state: deleteCurrentStylesheet(stylesheetId, stylesheets),
                    multipleActionsToDispatch: [
                        { type: actionTypes.STYLESHEET_DELETED, payload: { stylesheetType } },
                        updateDialogAction({
                            title: 'msg: gs.styleDeleted.title {Style deleted}',
                            success: true,
                            message: 'msg: gs.styleDeleted.message {The selected style was deleted.}',
                            stripType: StripTypes.INFO,
                            buttonType: PrimaryButtonType
                        })
                    ],
                    updateReason: updateReasons.STYLE_UPDATED
                };
            }
        },
        {
            conditions: [actionTypes.CHECK_STYLESHEET_DELETABLE_FAILURE],
            reducer: ({ state }) => ({
                state,
                actionToDispatch: updateDialogAction({
                    title: "msg: common.errorOccurred {An error occurred}",
                    message: "msg: common.tryAgainOrContactSupport {Please try again later or contact our support for further assistance.}", // eslint-disable-line max-len
                    stripType: StripTypes.WARNING,
                    buttonType: PrimaryButtonType,
                    buttonText: "msg: common.Ok {Ok}"
                })
            })
        },
        {
            conditions: [actionTypes.UPDATE_STYLESHEET],
            reducer: ({ values: [updatedStyle], state }) => {
                let newState = updateByIndexReducer(updatedStyle, state);
                return {
                    state: newState,
                    updateReason: updateReasons.DROPDOWN_WIDTH_CHANGED
                };
            }
        },
        {
            conditions: [CLOSE_DIALOG],
            reducer: ({ state }) => {
                dialogClosed = true;
                return { state };
            }
        },
        ...textGlobalstyleEpicUpdaters,
        ...tableGlobalstyleEpicUpdaters,
        {
            conditions: [actionTypes.UPDATE_THEME_PALETTE],
            reducer: ({ state, values: [palette] }) => {
                return {
                    state: {
                        ...state,
                        theme: {
                            colors: {
                                ...(THEME_COLORS.reduce((acc, themeColor, index) => {
                                    return { ...acc, [themeColor]: palette[index] };
                                }, {})),
                            },
                        },
                    },
                    updateReason: actionTypes.UPDATE_THEME_PALETTE
                };
            }
        },
    ]
});

const textHeading1FontSizeReducer = makeStateSelectorReducer(
    stylesheetsEpic.reducer,
    stylesheetsEpic.valueActionType,
    stylesheetsSelectors.textHeading1FontSize
);

const link1GlobalStyleIdReducer = makeStateSelectorReducer(
    stylesheetsEpic.reducer,
    stylesheetsEpic.valueActionType,
    stylesheetsSelectors.link1Id
);

const logoGlobalStyleReducer = makeStateSelectorReducer(
    stylesheetsEpic.reducer,
    stylesheetsEpic.valueActionType,
    stylesheetsSelectors.textLogoGlobalstyle
);

export {
    stylesheetsEpic,
    stylesheetsEpic as default,
    updateByIndexReducer,
    textHeading1FontSizeReducer,
    logoGlobalStyleReducer,
    link1GlobalStyleIdReducer,
    getClonedStylesheet
};
