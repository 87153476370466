import React from "react";
import cx from 'classnames';
import * as styles from './ComponentMainActions.css';
import type { CTAFactoryProps } from './flowTypes';
import type { AnyComponent } from '../../../../redux/modules/children/workspace/flowTypes';
import { isModernLayoutSection } from "../../../../components/ModernLayouts/preview_utils";
import { BetaTag } from "../../../common/Tag";

export default (props: CTAFactoryProps<AnyComponent>): React.JSX.Element => {
    const
        {
            dispatch, buttonText, intl, className, ctaButtonClass, onClickActionType,
            editCta, CtaButtonTextView, selectedComponent, getCtaTooltipProps
        } = props,
        isModernSection = !!selectedComponent && isModernLayoutSection(selectedComponent),
        onClick = props.onClick && props.onClick.bind(null, props);

    // check if the CTA button should have a toolip
    let ctaTooltipProps: any = null;
    if (getCtaTooltipProps) {
        ctaTooltipProps = getCtaTooltipProps(props);
    }

    return (
        <div
            className={cx(className, ctaButtonClass)}
            onClick={() => {
                (onClick || (() => onClickActionType && dispatch({ type: onClickActionType })))();
            }}
            data-title={ctaTooltipProps && ctaTooltipProps.text && intl.msgJoint(ctaTooltipProps.text)}
            data-title-position={ctaTooltipProps && ctaTooltipProps.position}
            data-title-class={ctaTooltipProps && ctaTooltipProps.class}
            data-testid="cta-button"
        >
            {isModernSection && !editCta &&
                <BetaTag
                    containerClassName={cx(styles.tagContainer)}
                    labelClassName={cx(styles.tagLabel)}
                />}
            {
                (CtaButtonTextView && !editCta) ?
                    <CtaButtonTextView {...props} /> :
                    <span>{intl.msgJoint(buttonText)}</span>
            }
        </div>
    );
};
