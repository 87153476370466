/* eslint-disable max-len */
import GMB_CATEGORIES_TO_SUB_CATEGORIES_MAP from "../../../../../resources/repository/gmb-categories/gmb-categories-to-subcategories.json";
import GMB_CATEGORIES_TO_DESIGNS_MAP from "../../../../../resources/repository/gmb-categories/gmb-categories-to-designs.json";

const gmbCategoriesToSubCategories = function (gmbCategories: Array<string>) {
    return gmbCategories.reduce((subCategoriesList: Array<string>, gmbCategory) => {
        const subCategories: Array<string> = GMB_CATEGORIES_TO_SUB_CATEGORIES_MAP[gmbCategory] || [];
        subCategories.forEach(subCategory => {
            if (!subCategoriesList.includes(subCategory)) {
                subCategoriesList.push(subCategory);
            }
        });
        return subCategoriesList;
    }, []);
};

const getValidDesigns = (allowedDesigns: Array<string>, designs: Array<string> = []) => {
    return designs.filter(design => allowedDesigns.includes(design));
};

const getMappedDesigns = (items, designsMapper, allowedDesigns) => {
    return items.reduce((itemsWithDesigns, item) => {
        const designs = designsMapper[item],
            validDesigns = getValidDesigns(allowedDesigns, designs);

        if (validDesigns.length) {
            itemsWithDesigns.push(validDesigns);
        }

        return itemsWithDesigns;
    }, []);
};

/*
    Example -
        items = [[1,2], [3,4,5], [6,7]], limit = 7
        pickedItems = [1,3,6,2,4,7,5]
*/
const pickItemsInAscendingOrder = (items: string[][], limit: number, prePickedItems: Array<string> = []): Array<string> => {
    const pickedItems = [...prePickedItems],
        itemsToPick = limit - prePickedItems.length;

    for (let i = 0; i < itemsToPick; i++) {
        for (const row of items) {
            const item = row[i];

            if (item && !pickedItems.includes(item)) {
                pickedItems.push(item);

                if (pickedItems.length === limit) {
                    return pickedItems;
                }
            }
        }
    }

    return pickedItems;
};

const suggestDesignsBySubCategories = function ({
    allowedDesigns,
    subCategories,
    subCategoriesToDesignsMap,
    limit,
    prePickedDesigns = []
}: {
    allowedDesigns: Array<string>,
    subCategories: Array<string>,
    subCategoriesToDesignsMap: Object,
    limit: number,
    prePickedDesigns: Array<string>
}) {
    let suggestedDesigns: Array<string> = [...prePickedDesigns];
    const subCategoriesMappingWithDesigns = getMappedDesigns(subCategories, subCategoriesToDesignsMap, allowedDesigns);
    suggestedDesigns = pickItemsInAscendingOrder(subCategoriesMappingWithDesigns, limit, suggestedDesigns);

    if (suggestedDesigns.length < limit) {
        const subCategoriesToDesignsValues = Object.values(subCategoriesToDesignsMap),
            validSubCategoriesToDesigns = subCategoriesToDesignsValues.map(designs => getValidDesigns(allowedDesigns, designs));
        suggestedDesigns = pickItemsInAscendingOrder(validSubCategoriesToDesigns, limit, suggestedDesigns);
    }

    if (suggestedDesigns.length < limit) {
        suggestedDesigns = pickItemsInAscendingOrder([allowedDesigns], limit, suggestedDesigns);
    }

    suggestedDesigns = suggestedDesigns.slice(0, limit);
    return suggestedDesigns;
};

const getDesignsByGMBCategories = (gmbCategories, allowedDesigns, limit) => {
    const categoriesWithDesigns = getMappedDesigns(gmbCategories, GMB_CATEGORIES_TO_DESIGNS_MAP, allowedDesigns);
    return pickItemsInAscendingOrder(categoriesWithDesigns, limit);
};

const suggestDesignsByGmbCategories = function ({ allowedDesigns, gmbCategories, subCategoriesToDesignsMap, minLimit = 3 }) {
    let suggestedDesignsBySubCategories: Array<string> = getDesignsByGMBCategories(gmbCategories, allowedDesigns, minLimit);

    if (suggestedDesignsBySubCategories.length < minLimit) {
        const subCategories = gmbCategoriesToSubCategories(gmbCategories);
        suggestedDesignsBySubCategories = suggestDesignsBySubCategories({
            allowedDesigns,
            subCategories,
            subCategoriesToDesignsMap,
            limit: minLimit,
            prePickedDesigns: suggestedDesignsBySubCategories
        });
    }

    return suggestedDesignsBySubCategories;
};

export { suggestDesignsByGmbCategories };
