/* eslint-disable max-len */
/* eslint-disable react/jsx-curly-brace-presence */
import React from 'react';
import makeAppStandardErrorDialog from "../../../App/dialogs/makeAppStandardErrorDialog";
import { PAGE_TREE_PAGE_DELETE_CONFIRM } from "../actionTypes";
import { WarningConfirmationDialog } from '../../../../view/common/dialogs/WarningConfirmationDialog';
import { Msg } from '../../../../view/intl';
import styles from "./styles.css";
import VerticalSpacer from '../../../../view/common/VerticalSpacer';
import { bookingsPageType } from '../../../oneweb/Bookings/events';

type Props = {
    dispatch: Dispatch;
};

export const DeleteBookingsPageDialog = ({ dispatch }: Props) => (
    <WarningConfirmationDialog
        title="msg: bookings.deletePage.title {Delete page and deactivate Bookings?}"
        confirmLabel="msg: delete {Delete}"
        onConfirm={() => dispatch({ type: PAGE_TREE_PAGE_DELETE_CONFIRM, payload: { pageType: bookingsPageType } })}
        dispatch={dispatch}
    >
        <Msg k="bookings.deletePage.description">
            {`Bookings will immediately be deactivated: You will lose access to your appointment overview, but existing appointments won’t get cancelled. Customers won’t be able to book new services on your website anymore. You can add the “Bookings” feature again later and your services will be saved.`}
        </Msg>
        <VerticalSpacer y={8} />
        <div className={styles.bookingsBody}>
            <div className={styles.infoLogo} />
            <Msg k="bookings.deletePage.info">
                {"Note: You need to publish to remove the page from your live website."}
            </Msg>
        </div>
    </WarningConfirmationDialog>
);

export default makeAppStandardErrorDialog(DeleteBookingsPageDialog);
