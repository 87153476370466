import kind from './kind';
import view from './view/index';
import type { CalcProps } from '../../../Preview/flowTypes';
import type { WebShopPoliciesComponent } from '../flowTypes';
import { CmpSpecificScriptProps } from "../../../Preview/flowTypes";
import { getPoliciesScriptTemplate } from '../../ContactForm/getPoliciesScriptTemplate';

export default {
    kind,
    view,
    calcProps: ({
        component,
        webshopMHFData,
        globalStyles: stylesheets,
        siteSettings,
        selectedParentTheme,
    }: CalcProps<WebShopPoliciesComponent>) => ({
        ...component,
        specialFooterData: {
            ...webshopMHFData,
            privacyPolicyContent: siteSettings?.privacyPolicy?.content
        },
        stylesheets,
        selectedParentTheme: siteSettings?.themeSettingsData?.autoColorMode && selectedParentTheme
    }),
    getSpecificScript: ({
        siteSettings
    }: CmpSpecificScriptProps<WebShopPoliciesComponent>) => {
        const privacyPolicy = JSON.stringify({ content: siteSettings?.privacyPolicy?.content });
        return getPoliciesScriptTemplate(privacyPolicy);
    }
};
