import * as R from 'ramda';
import * as object from "../../utils/object.js";

const AppConfig = (data: Record<string, any>) => {
    if (!data) throw new Error('Missing appConfig data');

    return {
        get(inPath: Array<string> | string): AnyValue {
            const
                path = Array.isArray(inPath) ? inPath : inPath.split('.'),
                value = R.path(path, data);

            if (value === undefined) throw new Error(`Missing appConfig value by path: ${path.join('.')}`);

            if (object.isObject(value)) return AppConfig(value);
            return value;
        },
        getOptional(inPath: Array<string> | string, defaultValue: AnyValue = undefined): AnyValue {
            const path = Array.isArray(inPath) ? inPath : inPath.split('.');

            let value = R.path(path, data);
            if (value === undefined) value = defaultValue;

            if (value && object.isObject(value)) return AppConfig(value);
            return value;
        }
    };
};

export default AppConfig;
