/* @flow */

const { AwsCognitoUserNativeAttribute } = require("./AwsCognitoUserAttribute.js");
const { FuncProxy } = require('../../utils/proxy/FuncProxy.js');
const { BagProxy } = require('../../utils/proxy/BagProxy.js');
const { AwsCognitoClientStorageRef } = require("./constants.js");

/*::
    import type { AwsAmplifyCognitoUserT } from './types'
 */

/*** Main ***/

const AwsAmplifyCognitoUser = (user/*: AwsAmplifyCognitoUserT */) => FuncProxy(user, {
    storage: StorageProxy(user),
    attributes: BagProxy(user.attributes),
});

/*** Lib ***/

function StorageProxy (user) {
    const storage = user.storage;

    return BagProxy(storage, {
        getCognitoIdentityServiceProvider(...keys/*: string[] */)/*: string */ {
            const k = [AwsCognitoClientStorageRef.IDENTITY_SERVICE_PROVIDER, ...keys].join('.');
            return this.get(k);
        },

        getIdentityId() {
            const identityIdKey = Object
                .keys(storage)
                .find(k => k.indexOf(AwsCognitoClientStorageRef.IDENTITY_ID_PREFIX) === 0);

            return this.get(identityIdKey);
        },

        isEmailVerified() {
            const userData = JSON.parse(
                this.getCognitoIdentityServiceProvider(
                    user.pool.clientId,
                    user.username,
                    AwsCognitoClientStorageRef.USER_DATA,
                )
            );
            let isEmailVerified = false;
            userData.UserAttributes.forEach(userAttribute => {
                if (userAttribute.Name === AwsCognitoUserNativeAttribute.EMAIL_VERIFIED && userAttribute.Value === 'true') {
                    isEmailVerified = true;
                }
            })
            return isEmailVerified;
        },

        getLastAuthEmail() {
            const userData = JSON.parse(
                this.getCognitoIdentityServiceProvider(
                    user.pool.clientId,
                    user.username,
                    AwsCognitoClientStorageRef.USER_DATA,
                )
            );
            let lastAuthEmail = userData.Username;
            userData.UserAttributes.forEach(userAttribute => {
                if (userAttribute.Name === AwsCognitoUserNativeAttribute.EMAIL) {
                    lastAuthEmail = userAttribute.Value;
                }
            })
            return lastAuthEmail;
        },

        getAccessToken()/*: string */ {
            return this.getCognitoIdentityServiceProvider(
                user.pool.clientId,
                user.username,
                AwsCognitoClientStorageRef.ACCESS_TOKEN,
            );
        },

        getSubId() {
            const userData = JSON.parse(
                this.getCognitoIdentityServiceProvider(
                    user.pool.clientId,
                    user.username,
                    AwsCognitoClientStorageRef.USER_DATA,
                )
            );
            let userSubId = userData.Username;
            userData.UserAttributes.forEach(userAttribute => {
                if (userAttribute.Name === AwsCognitoUserNativeAttribute.SUB) {
                    userSubId = userAttribute.Value;
                }
            })
            return userSubId;
        },
    });
}

module.exports = {
    AwsAmplifyCognitoUser,
};
