import { EventsRegistryValueType } from "./types";

const createCommonEventTrackingHandler = (actionTypes: Array<string>, config: EventsRegistryValueType) => {
    return actionTypes.reduce((acc, actionType) => {
        return { ...acc, [actionType]: config };
    }, {});
};

export {
    createCommonEventTrackingHandler
};
