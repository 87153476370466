// This code is copy pasted from tinymce node_modules/tinymce/plugins/paste/plugin.js

function isWordContent(content) {
    return /<font face="Times New Roman"|class="?Mso|style="[^"]*\bmso-|style='[^']*\bmso-|w:WordDocument/i.test(content) || /class="OutlineElement/.test(content) || /id="?docs\-internal\-guid\-/.test(content); // eslint-disable-line
}

// @ts-ignore
let global$3 = tinymce.util.Tools.resolve('tinymce.util.Tools'); // eslint-disable-line
// @ts-ignore
let global$6 = tinymce.util.Tools.resolve('tinymce.html.DomParser'); // eslint-disable-line
// @ts-ignore
let global$8 = tinymce.util.Tools.resolve('tinymce.html.Schema'); // eslint-disable-line
// @ts-ignore
let global$7 = tinymce.util.Tools.resolve('tinymce.html.Node'); // eslint-disable-line
// @ts-ignore
let global$9 = tinymce.util.Tools.resolve('tinymce.html.Serializer'); // eslint-disable-line

let getRetainStyleProps = function (editor) {
    return editor.getParam('paste_retain_style_properties');
};
let getWordValidElements = function (editor) {
    let defaultValidElements = '-strong/b,-em/i,-u,-span,-p,-ol,-ul,-li,-h1,-h2,-h3,-h4,-h5,-h6,' + '-p/div,-a[href|name],sub,sup,strike,br,del,table[width],tr,' + 'td[colspan|rowspan|width],th[colspan|rowspan|width],thead,tfoot,tbody'; // eslint-disable-line
    return editor.getParam('paste_word_valid_elements', defaultValidElements);
};
let shouldConvertWordFakeLists = function (editor) {
    return editor.getParam('paste_convert_word_fake_lists', true);
};

function convertFakeListsToProperLists(node) {
    let currentListNode, prevListNode, lastLevel = 1;
    function getText(node) {
        let txt = '';
        if (node.type === 3) {
            return node.value;
        }
        if (node.firstChild) {
            do {
                txt += getText(node.firstChild);
            } while (node.next);
        }
        return txt;
    }
    function trimListStart(node, regExp) {
        if (node.type === 3) {
            if (regExp.test(node.value)) {
                node.value = node.value.replace(regExp, ''); // eslint-disable-line
                return false;
            }
        }
        if (node.firstChild) {
            do {
                if (!trimListStart(node.firstChild, regExp)) {
                    return false;
                }
            } while (node.next);
        }
        return true;
    }
    function removeIgnoredNodes(node) {
        if (node._listIgnore) {
            node.remove();
            return;
        }
        if (node.firstChild) {
            do {
                removeIgnoredNodes(node.firstChild);
            } while (node.next);
        }
    }
    function isBulletList(text) {
        return /^[\s\u00a0]*[\u2022\u00b7\u00a7\u25CF]\s*/.test(text);
    }
    function isNumericList(text) {
        let found, patterns;
        patterns = [
            /^[IVXLMCD]{1,2}\.[ \u00a0]/,
            /^[ivxlmcd]{1,2}\.[ \u00a0]/,
            /^[a-z]{1,2}[\.\)][ \u00a0]/,
            /^[A-Z]{1,2}[\.\)][ \u00a0]/,
            /^\d+\.[ \u00a0]/,
            /^[\u3007\u4e00\u4e8c\u4e09\u56db\u4e94\u516d\u4e03\u516b\u4e5d]+\.[ \u00a0]/,
            /^[\u58f1\u5f10\u53c2\u56db\u4f0d\u516d\u4e03\u516b\u4e5d\u62fe]+\.[ \u00a0]/
        ];
        text = text.replace(/^[\u00a0 ]+/, ''); // eslint-disable-line
        global$3.each(patterns, function (pattern) { // eslint-disable-line
            if (pattern.test(text)) {
                found = true;
                return false;
            }
        });
        return found;
    }
    function convertParagraphToLi(paragraphNode, listName, start) {
        let level = paragraphNode._listLevel || lastLevel;
        if (level !== lastLevel) {
            if (level < lastLevel) {
                if (currentListNode) {
                    currentListNode = currentListNode.parent.parent;
                }
            } else {
                prevListNode = currentListNode;
                currentListNode = null;
            }
        }
        if (!currentListNode || currentListNode.name !== listName) {
            prevListNode = prevListNode || currentListNode;
            currentListNode = new global$7(listName, 1);
            if (start > 1) {
                currentListNode.attr('start', '' + start);
            }
            paragraphNode.wrap(currentListNode);
        } else {
            currentListNode.append(paragraphNode);
        }
        paragraphNode.name = 'li';// eslint-disable-line
        if (level > lastLevel && prevListNode) {
            prevListNode.lastChild.append(currentListNode);
        }
        lastLevel = level;
        removeIgnoredNodes(paragraphNode);
        trimListStart(paragraphNode, /^\u00a0+/);
        trimListStart(paragraphNode, /^\s*([\u2022\u00b7\u00a7\u25CF]|\w+\.)/);
        trimListStart(paragraphNode, /^\u00a0+/);
    }
    let elements: ChildNode[] = [];
    let child = node.firstChild;
    while (typeof child !== 'undefined' && child !== null) {
        elements.push(child);
        child = child.walk();
        if (child !== null) {
            while (typeof child !== 'undefined' && child.parent !== node) {
                child = child.walk();
            }
        }
    }
    for (const element of elements) {
        node = element;// eslint-disable-line
        if (node.name === 'p' && node.firstChild) {
            let nodeText = getText(node);
            if (isBulletList(nodeText)) {
                // @ts-ignore TODO: Fix TS
                convertParagraphToLi(node, 'ul');
                continue;
            }
            if (isNumericList(nodeText)) {
                let matches = /([0-9]+)\./.exec(nodeText);  //NOSONAR
                let start = 1;
                if (matches) {
                    start = parseInt(matches[1], 10);
                }
                convertParagraphToLi(node, 'ol', start);
                continue;
            }
            if (node._listLevel) {
                convertParagraphToLi(node, 'ul', 1);
                continue;
            }
            currentListNode = null;
        } else {
            prevListNode = currentListNode;
            currentListNode = null;
        }
    }
}

function filter(content, items) {
    global$3.each(items, function (v) {
        if (v.constructor === RegExp) {
            content = content.replace(v, ''); // eslint-disable-line
        } else {
            content = content.replace(v[0], v[1]); // eslint-disable-line
        }
    });
    return content;
}

function filterStyles(editor, validStyles, node, styleValue) {
    let outputStyles = {}, matches;
    let styles = editor.dom.parseStyle(styleValue);
    global$3.each(styles, function (value, name) {
        switch (name) { // eslint-disable-line
            case 'mso-list':
                matches = /\w+ \w+([0-9]+)/i.exec(styleValue);  //NOSONAR
                if (matches) {
                    node._listLevel = parseInt(matches[1], 10);// eslint-disable-line
                }
                if (/Ignore/i.test(value) && node.firstChild) {
                    node._listIgnore = true;// eslint-disable-line
                    node.firstChild._listIgnore = true;// eslint-disable-line
                }
                break;
            case 'horiz-align':
                name = 'text-align';// eslint-disable-line
                break;
            case 'vert-align':
                name = 'vertical-align';// eslint-disable-line
                break;
            case 'font-color':
            case 'mso-foreground':
                name = 'color';// eslint-disable-line
                break;
            case 'mso-background':
            case 'mso-highlight':
                name = 'background';// eslint-disable-line
                break;
            case 'font-weight':
            case 'font-style':
                if (value !== 'normal') {
                    outputStyles[name] = value;
                }
                return;
            case 'mso-element':
                if (/^(comment|comment-list)$/i.test(value)) {
                    node.remove();
                    return;
                }
                break;
        }
        if (name.indexOf('mso-comment') === 0) {
            node.remove();
            return;
        }
        if (name.indexOf('mso-') === 0) {
            return;
        }
        if (getRetainStyleProps(editor) === 'all' || (validStyles && validStyles[name])) {
            outputStyles[name] = value;
        }
    });
    if (/(bold)/i.test(outputStyles['font-weight'])) {
        delete outputStyles['font-weight'];
        node.wrap(new global$7('b', 1));
    }
    if (/(italic)/i.test(outputStyles['font-style'])) {
        delete outputStyles['font-style'];
        node.wrap(new global$7('i', 1));
    }
    outputStyles = editor.dom.serializeStyle(outputStyles, node.name);
    if (outputStyles) {
        return outputStyles;
    }
    return null;
}

function filterWordContent(editor, content) {
    let retainStyleProperties, validStyles;
    retainStyleProperties = getRetainStyleProps(editor);
    if (retainStyleProperties) {
        validStyles = global$3.makeMap(retainStyleProperties.split(/[, ]/));
    }
    content = filter(content, [ // eslint-disable-line
        /<br class="?Apple-interchange-newline"?>/gi,
        /<b[^>]+id="?docs-internal-[^>]*>/gi,
        /<!--[\s\S]+?-->/gi,
        /<(\/?(script[^>]*>.*?<\/script>|(?:\?xml|img|meta|link|style|\w:\w+)(?=[\s\/>])))[^>]*>/gi, //NOSONAR
        [
            /<(\/?)s>/gi,
            '<$1strike>'
        ],
        [
            /&nbsp;/gi,
            '\xA0'
        ],
        [
            /<span\s+style\s*=\s*"\s*mso-spacerun\s*:\s*yes\s*;?\s*"\s*>([\s\u00a0]*)<\/span>/gi,   //NOSONAR
            function (str, spaces) {
                return spaces.length > 0 ? spaces.replace(/./, ' ').slice(Math.floor(spaces.length / 2)).split('').join('\xA0') : ''; // eslint-disable-line
            }
        ]
    ]);
    let validElements = getWordValidElements(editor);
    let schema = global$8({
        valid_elements: validElements,
        valid_children: '-li[p]'
    });
    global$3.each(schema.elements, function (rule) {
        if (!rule.attributes.class) {
            rule.attributes.class = {}; // eslint-disable-line
            rule.attributesOrder.push('class');
        }
        if (!rule.attributes.style) {
            rule.attributes.style = {}; // eslint-disable-line
            rule.attributesOrder.push('style');
        }
    });
    let domParser = global$6({}, schema);
    domParser.addAttributeFilter('style', function (nodes) {
        let i = nodes.length, node;
        while (i--) {
            node = nodes[i];
            node.attr('style', filterStyles(editor, validStyles, node, node.attr('style')));
            if (node.name === 'span' && node.parent && !node.attributes.length) {
                node.unwrap();
            }
        }
    });
    domParser.addAttributeFilter('class', function (nodes) {
        let i = nodes.length, node, className;
        while (i--) {
            node = nodes[i];
            className = node.attr('class');
            if (/^(MsoCommentReference|MsoCommentText|msoDel)$/i.test(className)) {
                node.remove();
            }
            node.attr('class', null);
        }
    });
    domParser.addNodeFilter('del', function (nodes) {
        let i = nodes.length;
        while (i--) {
            nodes[i].remove();
        }
    });
    domParser.addNodeFilter('a', function (nodes) {
        let i = nodes.length, node, href, name;
        while (i--) {
            node = nodes[i];
            href = node.attr('href');
            name = node.attr('name');
            if (href && href.indexOf('#_msocom_') !== -1) {
                node.remove();
                continue;
            }
            if (href && href.indexOf('file://') === 0) {
                href = href.split('#')[1];
                if (href) {
                    href = '#' + href;
                }
            }
            if (!href && !name) {
                node.unwrap();
            } else {
                if (name && !/^_?(?:toc|edn|ftn)/i.test(name)) {
                    node.unwrap();
                    continue;
                }
                node.attr({
                    href,
                    name
                });
            }
        }
    });
    let rootNode = domParser.parse(content);
    if (shouldConvertWordFakeLists(editor)) {
        convertFakeListsToProperLists(rootNode);
    }
    content = global$9({ validate: editor.settings.validate }, schema).serialize(rootNode); // eslint-disable-line
    return content;
}

export {
    isWordContent,
    filterWordContent
};
