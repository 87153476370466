import { identity, prop, pipe } from 'ramda';
import { parseIntDec } from '../../../../utils/number';
import { trim, unescapeHTML } from '../../../../utils/string.js';
import { WidgetsRegistry, WidgetsAllowedForAllSubscriptions } from './constants';
import { WidgetsGoogleMapsKind } from './GoogleMaps/kind';
import {
    type HrefParser,
    type HrefParserOutput,
    type IframeParser,
    type IframeParserOutput,
    type LinkValidator,
    type MatchParams,
    type Parser,
    type PayloadExtractor,
    type RegExpMap,
    type StringOrNumberOrBooleanOrNull,
} from './types';

export const getCalcRenderProps = state => ({
    component,
    componentExtension: { isTransient },
    componentDependencies = { generalData: { addressLocation: null, addressText: null, addressUrl: null } },
    isServerPreview = false,
}) => {
    const props = {
        isTransient,
        isServerPreview,
        ...Object
            .keys(state)
            .concat(['width', 'height'])
            .reduce(
                (acc, prop) => ({ ...acc, [`${prop}`]: component[prop] }), {}
            )
    };

    const { addressLocation, addressText, addressUrl } = componentDependencies.generalData;

    if (componentDependencies &&
        component.kind === WidgetsGoogleMapsKind &&
        !component.addressText &&
        addressText
    ) {
        // purposely not using the getAddressDataFromDependencies function in google maps utils
        (props as any).addressText = addressText; // NOSONAR
        (props as any).addressLocation = addressLocation; // NOSONAR
        (props as any).addressUrl = addressUrl; // NOSONAR
    }

    return props;
};

/**
 * NOTE: Usage of widgets requires user to have subscription PREMIUM or above, except some as below
 */

export const isWidgetAllowedForAllSubscriptions = (kind: string): boolean => WidgetsAllowedForAllSubscriptions.includes(kind);

export const isGenericWidget = (kind: string): boolean => {
    return WidgetsRegistry.reduce((acc, wr) => {
        return acc || wr.subCategories.some(wrSubcat => wrSubcat.kind === kind);
    }, false);
};

export const getWidgetsCount = (componentsMap) => {
    return Object
        .keys(componentsMap)
        .reduce((count, cmpId) => {
            return isGenericWidget(componentsMap[cmpId].kind) ? (count + 1) : count;
        }, 0);
};

export const linkExtractor = prop('link');
export const codeExtractor = prop('code');
export const identityExtractor = identity;

const IframeRegExpMap: RegExpMap = {
    // @ts-ignore
    width: { regex: / width="(\d+)"/, postMatch: parseIntDec },
    // @ts-ignore
    height: { regex: / height="(\d+)"/, postMatch: parseIntDec },
    src: { regex: / src="([^"]+)"/, postMatch: pipe(trim, unescapeHTML) },
};

const AHrefRegExpMap: RegExpMap = {
    href: { regex: / href="([^"]+)"/, postMatch: trim },
    // @ts-ignore
    width: { regex: / data-width="([^"]+)"/, postMatch: parseIntDec },
    // @ts-ignore
    height: { regex: / data-height="([^"]+)"/, postMatch: parseIntDec },
    theme: { regex: / data-theme="([^"]+)"/, postMatch: trim },
    locale: { regex: / data-lang="([^"]+)"/, postMatch: trim },
    doNotTrack: { regex: /( data-dnt="true")/, postMatch: v => !!v },
};

export const getMatch = (str: string, params: MatchParams): StringOrNumberOrBooleanOrNull => {
    const tmp = str.match(params.regex);
    const postMatch = typeof params.postMatch === 'function' ? params.postMatch : identity;
    return Array.isArray(tmp) && tmp.length > 1 ? postMatch(tmp[1]) : null;
};

const codeParseFactory =
    (initialRegex: RegExp, regexMap: RegExpMap) =>
        (code: string, defaults: object = {}): HrefParserOutput | IframeParserOutput => {
            if (initialRegex.test(code)) {
                const parsedOutput = Object
                    .keys(regexMap)
                    .reduce((acc, key) => {
                        const match = getMatch(code, regexMap[key]);
                        if (match !== null) {
                            acc[key] = match;
                        }
                        return acc;
                    }, {});

                return { ...defaults, ...parsedOutput };
            }

            return {};
        };

export const parseIframeCode: IframeParser = codeParseFactory(/<iframe /, IframeRegExpMap);

export const parseAHrefCode: HrefParser = codeParseFactory(/^<a /, AHrefRegExpMap);

export const validateLinkOrCode = (linkValidators: Array<LinkValidator>, parser: Parser): LinkValidator =>
    data => {
        const linkValid = linkValidators.some(validator => validator(data));

        if (linkValid) {
            return true;
        }

        const parsedData = parser(data);
        if (typeof parsedData === 'object' && parsedData && Object.keys(parsedData).length > 0) {
            return true;
        }

        return false;
    };

export const regexpsValidator = (regexps: RegExp[]): LinkValidator =>
    str =>
        regexps.some(regexp => regexp.test(str));
/*
export const ctaOnClickFactory = (action: string) =>
    ({ dispatch, selectedComponent: { addressLocation, addressText } }) =>
        dispatch({ type: action, payload: { addressLocation, addressText } });
*/
export const ctaOnClickFactory = (action: string, payloadExtractor: PayloadExtractor) =>
    ({ dispatch, selectedComponent }) =>
        dispatch({ type: action, payload: payloadExtractor(selectedComponent) });
