import React from "react";

const onNavigate = () => {};//it is required for typecheck

const DynamicTemplateContext = React.createContext({
    gmbKey: '',
    catchAllConceptKey: '',
    businessName: '',
    keywords: '',
    contactInfo: {
        phone: '',
        email: '',
        address: '',
        completeAddress: {},
    },
    isGroup: null,
    isGroupSet: false,
    isNewGmbKey: false,
    wizardState: {
        currentStep: 0,
        totalSteps: 0,
        isFirstStep: false,
        isLastStep: false,
        skippable: false,
        goBack: onNavigate,
        goNext: onNavigate
    },
    purpose: [],
    concept: {
        name: '',
        type: '',
        optionSet: {
            set: [],
            multiSelect: false,
            key: ''
        }
    },
    conceptMap: {},
    isLoading: false,
    agreeToTerms: false,
    shopDisabled: false,
    showGenericConcept: false,
});

export {
    DynamicTemplateContext
};
