import { isBookingsPage, isBlogPage } from "../../../../../dal/model/utils/dataSiteItemUtils";
import { makeEpicStateSelector } from "../../../../epics/makeEpic";
import pageDatasetVAT from "./valueActionType";

const pageDatasetEpicSelector = makeEpicStateSelector(pageDatasetVAT);

export const selectIsOnBookingsPage = (state): boolean =>
    isBookingsPage(pageDatasetEpicSelector(state)?.page);

export const selectIsOnBlogPage = (state): boolean =>
    isBlogPage(pageDatasetEpicSelector(state)?.page);
