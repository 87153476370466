import makeStateSelectorReducer from "../../../../epics/makeStateSelectorReducer";
import currentPageIdEpic from "../../../App/epics/currentPageId";
import siteDataEpic from "../../../App/epics/siteData";
import { colorThemeSiteSettingsEpic } from "../../../SiteSettings/ColorThemeData/colorThemeSiteSettingsEpic";
import { generalGlobalDataEpic } from "../../../SiteSettings/General/generalGlobalDataEpic/generalGlobalDataEpic";
import stylesheetsEpic from "../../../Workspace/epics/stylesheets";
import { localeReducer as locale } from "../../Template/epics/template/index";
import { ComponentNames } from "../../constants";
import { componentMainActions } from "./componentMainActions";
import BlogKind from "./kind";
import reducer from "./reducer/index";
import view from "./view/index";
import workspace from "./view/workspace";
import subsciptionEpic from "../../../App/epics/subscriptionData";
import { BLOG_MIN_HEIGHT, BLOG_MIN_WIDTH } from "./constants";
import adjustmentHookConfig from "./adjustmentHookConfig";

const calcRenderProps = ({ component, componentDependencies, selectedParentTheme, componentExtension, isMVEFocus }) => {
    const {
        siteData,
        globalstyles,
        currentPageId,
        locale,
        generalData,
        themeSettingsData: { autoColorMode },
    } = componentDependencies;

    return {
        component,
        siteData,
        globalstyles,
        currentPageId,
        locale,
        generalData,
        selectedParentTheme,
        autoColorMode,
        isMVEFocus,
        componentExtension
    };
};

export default {
    kind: BlogKind,
    label: ComponentNames[BlogKind],
    shortcutIconName: "blog",

    // user can't make the component smaller than these dimensions
    minDimensions: {
        width: BLOG_MIN_WIDTH,
        height: BLOG_MIN_HEIGHT
    },

    // config for default parameters (e.g. default dimensions)
    reducer,
    adjustmentHookConfig,

    // config for what the component should render
    view,
    workspace,

    // mobile has the same view but in mobile format
    mobileEditorConfig: {
        kind: BlogKind,
        view,
        calcProps: props => {
            return {
                ...calcRenderProps(props),
                isMobileEditor: true
            };
        }
    },

    // config for the settings buttons of the component
    componentMainActions,

    dependsOn: {
        locale,
        siteData: siteDataEpic.reducer,
        globalstyles: stylesheetsEpic.reducer,
        currentPageId: currentPageIdEpic.reducer,
        themeSettingsData: colorThemeSiteSettingsEpic.reducer,
        generalData: makeStateSelectorReducer(
            generalGlobalDataEpic.reducer,
            generalGlobalDataEpic.valueActionType,
            ({ websiteCategories }) => ({ websiteCategories })
        ),
        subscriptionData: subsciptionEpic.reducer
    },

    // calculates view props for workspace
    calcRenderProps
};
