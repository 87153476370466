import kind from "./kind";
import { componentDependencies } from "../Background/previewConfig";
import calcRenderProps from "../Background/calcRenderProps";
import backgroundViewFactory from '../Background/view/backgroundViewFactory';
import styles from './view/StripPreview.css';
import { makeGetBackgroundSpecificStyles } from '../Background/makeGetBackgroundSpecificStyles';
import { getThemeColorsFromStylesheet } from "../../Workspace/epics/stylesheets/selectors";
import type { CalcProps } from '../../Preview/flowTypes';
import type { StripComponent } from './flowTypes';

export default {
    kind,
    view: backgroundViewFactory(styles),
    calcProps: ({
        component,
        siteSettings: { themeSettingsData },
        globalStyles,
        paddingBottom
    }: CalcProps<StripComponent>) => {
        return calcRenderProps({
            component,
            paddingBottom,
            componentDependencies: {
                ...componentDependencies, themeSettingsData, themeColorsData: getThemeColorsFromStylesheet(globalStyles)
            }
        });
    },
    getSpecificStyles: makeGetBackgroundSpecificStyles(),
    js: [
        '/renderStatic/background/lazyload.js',
        '/renderStatic/strip/stickyStrip.js',
        '/renderStatic/strip/parallax.js'
    ]
};
