import { WINDOW_RESIZED } from '../../../redux/modules/actionTypes';
import { makeCombineReducer, makeDefaultStateReducers } from "../../../redux/makeReducer/index";
import {
    BACK_TO_EDITOR,
    SITE_SUBSCRIPTION_BACKUP_RESTORE_DATA_LOAD_SUCCESS,
    UPGRADE_SUCCESS
} from "../../App/actionTypes";
import * as actionTypes from "../actionTypes";
import defaultState from './defaultState';
import { MobileViewOrientations } from "../../Preview/View/Mobile/constants";
import { siteDataValueActionType } from '../../App/epics/siteData/valueActionType';

export default makeCombineReducer({
    combineReducers: makeDefaultStateReducers(defaultState),
    handleActions: {
        [BACK_TO_EDITOR]: ({ dimensions, hideIntro, isBackupAndRestoreAllowed, siteMap }) => ({
            ...defaultState, dimensions, hideIntro, isBackupAndRestoreAllowed, siteMap
        }),
        [actionTypes.BACKUP_RESTORE_REQUEST]: (state) => ({ ...state, isRestoring: true }),
        [actionTypes.BACKUP_RESTORE_FAIL]: (state) => ({ ...state, isRestoring: false }),
        [actionTypes.BACKUP_CLICKED]: (state) => ({ ...state, loading: true, opened: true }),
        [actionTypes.BACKUP_PREVIEW_RECORD_CHANGED]: (state,
            { payload: { pageIdToLoad, timestamp } }) => ({
            ...state,
            pageIdToLoad,
            timestamp
        }),
        [actionTypes.BACKUP_SET_PREVIEW_INTERFACE]: (state,
            { payload: { pageIdToLoad, timestamp, timeline } }) => ({
            ...state,
            pageIdToLoad,
            timestamp,
            timeline,
            loading: false
        }),
        [actionTypes.BACKUP_UPDATE_SAVEPOINT_PREVIEW]: (state,
            { payload: { updatedTimeline, title } }) => ({
            ...state,
            timeline: state.timeline.map(t => {
                return t.time === updatedTimeline ? { ...t, title } : t;
            })
        }),
        [actionTypes.BACKUP_DESKTOP_PREVIEW]: (state) => ({
            ...state, isMobileView: false
        }),
        [actionTypes.BACKUP_MOBILE_PREVIEW]: (state) => ({
            ...state, isMobileView: true
        }),
        [actionTypes.BACKUP_MOBILE_PREVIEW_ORIENTATION_TOGGLE]: (state) => ({
            ...state,
            mobileView: {
                orientation: state.mobileView.orientation === MobileViewOrientations.PORTRAIT ?
                    MobileViewOrientations.LANDSCAPE : MobileViewOrientations.PORTRAIT
            }
        }),

        [actionTypes.BACKUP_INTRO_TOGGLE_SHOW]: (state) => ({
            ...state, hideIntro: !state.hideIntro
        }),
        [WINDOW_RESIZED]: (state, { payload: dimensions }) => ({
            ...state, dimensions
        }),
        [actionTypes.BACKUP_TOGGLE_LEFT_PANEL]: (state) => ({
            ...state, panelExpanded: !state.panelExpanded
        }),
        [SITE_SUBSCRIPTION_BACKUP_RESTORE_DATA_LOAD_SUCCESS]: (state, { payload: { subscribed } }) => ({
            // we fetch subscription data for backup and restore only for non-premium users
            ...state,
            isBackupAndRestoreAllowed: subscribed
        }),
        [UPGRADE_SUCCESS]: state => ({
            ...state,
            isBackupAndRestoreAllowed: true
        }),
        [siteDataValueActionType]: (state, { payload }) => ({
            ...state,
            siteMap: payload
        }),
    }
});
