import React from 'react';
import cx from 'classnames';
import GalleryKind, {
    GallerySquareType,
    GalleryRoundType,
    GalleryCaptionsType,
    GalleryRoundedType,
} from "../../oneweb/Gallery/kind";
import * as Icon from './Icons.css';
import { Gallery } from "./constants";
import SliderKind, {
    ImageSliderBasicType,
    ImageSliderNavigationType,
    ImageSliderCaptionBoxType,
    ImageSliderPaginationType
} from "../../oneweb/ImageSlider/kind";
import { DroppableCmpShortcut, ExtendedPanelShortCut } from "../view/DroppableCmpShortcut";
import type { ExtendedPanelContentProps } from "../view/inserter";
import * as styles from '../view/Inserter.css';
import VerticalSpacer from "../../../view/common/VerticalSpacer";
import EPSeparator from "../view/EPSeparator";

const
    Galleries = 'msg: extendedPanel.Galleries {Galleries}',
    Squared = 'msg: extendedPanel.Squared {Squared}',
    Rounded = 'msg: extendedPanel.Rounded {Rounded}',
    Circled = 'msg: extendedPanel.Circled {Circled}',
    Captions = 'msg: extendedPanel.Captions {Captions}',
    Sliders = 'msg: extendedPanel.Sliders {Image sliders}',
    Simple = 'msg: extendedPanel.Simple {Simple}',
    Arrows = 'msg: extendedPanel.Arrows {Arrows}',
    Dots = 'msg: extendedPanel.Dots {Dots}',
    Caption = 'msg: extendedPanel.Caption {Caption}';

const ShortCut = ({ iconClassName, intl, text }) => {
    return (
        <div className={Icon.galleryShortCutMainContainer}>
            <div className={Icon.galleryShortCutContainer}>
                <div className={cx(Icon.galleryShortCut, iconClassName)} />
                <div>{intl.msgJoint(text)}</div>
            </div>
        </div>
    );
};

const Content = (props: ExtendedPanelContentProps) => {
    const { intl } = props;

    return (
        <div>
            <VerticalSpacer y={22} />
            <div className={styles.subTitle}>{intl.msgJoint(Galleries)}</div>
            <VerticalSpacer y={12} />
            <div className={styles.shortCutsContainer}>
                <DroppableCmpShortcut {...props} kind={GalleryKind} payload={{ type: GallerySquareType }}>
                    <ShortCut iconClassName={Icon.gallerySquared} intl={intl} text={Squared} />
                </DroppableCmpShortcut>
                <DroppableCmpShortcut {...props} kind={GalleryKind} payload={{ type: GalleryRoundedType }}>
                    <ShortCut iconClassName={Icon.galleryRounded} intl={intl} text={Rounded} />
                </DroppableCmpShortcut>
                <DroppableCmpShortcut {...props} kind={GalleryKind} payload={{ type: GalleryRoundType }}>
                    <ShortCut iconClassName={Icon.galleryCircled} intl={intl} text={Circled} />
                </DroppableCmpShortcut>
                <DroppableCmpShortcut {...props} kind={GalleryKind} payload={{ type: GalleryCaptionsType }}>
                    <ShortCut iconClassName={Icon.galleryWithCaptions} intl={intl} text={Captions} />
                </DroppableCmpShortcut>
            </div>
            <VerticalSpacer y={24} />
            <EPSeparator />
            <VerticalSpacer y={22} />
            <div className={styles.subTitle}>{intl.msgJoint(Sliders)}</div>
            <VerticalSpacer y={12} />
            <div className={styles.shortCutsContainer}>
                <DroppableCmpShortcut {...props} kind={SliderKind} payload={{ type: ImageSliderBasicType }}>
                    <ExtendedPanelShortCut iconClassName={cx(Icon.sliderNormal, Icon.stretch)} intl={intl} text={Simple} />
                </DroppableCmpShortcut>
                <DroppableCmpShortcut {...props} kind={SliderKind} payload={{ type: ImageSliderPaginationType }}>
                    <ExtendedPanelShortCut iconClassName={cx(Icon.sliderWithDots, Icon.stretch)} intl={intl} text={Dots} />
                </DroppableCmpShortcut>
                <DroppableCmpShortcut {...props} kind={SliderKind} payload={{ type: ImageSliderNavigationType }}>
                    <ShortCut iconClassName={Icon.sliderWithArrows} intl={intl} text={Arrows} />
                </DroppableCmpShortcut>
                <DroppableCmpShortcut {...props} kind={SliderKind} payload={{ type: ImageSliderCaptionBoxType }}>
                    <ShortCut iconClassName={Icon.sliderWithCaption} intl={intl} text={Caption} />
                </DroppableCmpShortcut>
            </div>
            <VerticalSpacer y={22} />
        </div>
    );
};

export default {
    kind: GalleryKind,
    shortCut: {
        iconClassName: Icon.gallery,
        text: Gallery
    },
    extendedPanelData: {
        title: 'msg: extendedPanel.galleryTitle {Gallery}',
        content: (props: ExtendedPanelContentProps) => <Content {...props} />
    }
};
