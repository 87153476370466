import { assocPath, pathOr, prop } from 'ramda';
import cx from "classnames";
import ScaleStrategy from "./scaleStrategy";
import {
    getDefaultDimensions,
    getCropDimensions,
    getCropModeStyles,
    getStretchModeStyles,
    getBackgroundCropModeStyles
} from "./componentSelectors";
import { getComponentZIndex } from '../../Workspace/zIndex';
import getImageUrl from "./getImageUrl/index";
import { makeComponentBorderStyle } from "../../../mappers/border/index";
import { calcA } from "../Gallery/utils";
import type {
    ViewModeCalcRenderProps,
    EditModeCalcRenderProps,
} from "./flowTypes";
import getOnClickAction from './getOnClickAction';
import { ImagePixelRatios } from './constants';
import { defaultLogoText } from "../Logo/defaultLogoText";
import getLinkAbsoluteUrl from "../../../view/common/dialogs/LinkChooserDialog/utils/getLinkAbsoluteUrl";
import { replaceTagsWithContent } from "../Text/view/replaceTagsWithContent";
import { JS_VOID_VALUE } from '../../../constants';
import { getThemeRulesForBackground } from '../../ThemeGlobalData/themeRules';

const
    setBrokenLink = assocPath(['linkAction', 'link'], {
        type: "url",
        value: JS_VOID_VALUE
    }),
    fixFloat = num => parseFloat(num.toPrecision(15)),
    getTitleAltAttr = (title: string, alt: string | null | undefined) => {
        let attrs: Record<string, any> = {};
        if (alt || title) {
            attrs.alt = alt || title;
        }
        if (title) {
            attrs.title = title;
        }
        return attrs;
    },
    getLogoImageDimensions = component => {
        const { width: cw, height: ch, asset: { width: imageWidth, height: imageHeight }, scale } = component;
        const availableWidth = cw * scale;
        const availableHeight = ch * scale;

        let height = availableHeight;
        let width = availableHeight * (imageWidth / imageHeight);

        if (width > availableWidth) {
            height = availableWidth * (imageHeight / imageWidth);
            width = availableWidth;
        }
        return { width: fixFloat(width), height: fixFloat(height) };
    },
    getMarginLeft = (component, imageWidth) => {
        switch (component.logoHorizontalAlignment) {
            case 'center':
                return Math.round((component.width - imageWidth) / 2);
            case 'right':
                return component.width - imageWidth;
            default:
                return 0;
        }
    },
    getMarginTop = (component, imageHeight) => {
        if (imageHeight < component.height) {
            return (component.height - imageHeight) / 2;
        }
        return 0;
    },
    getLogoModeStyles = (component, imageDimensions) => ({
        width: `${imageDimensions.width}px`,
        height: `${imageDimensions.height}px`,
        marginLeft: `${getMarginLeft(component, imageDimensions.width)}px`,
        marginTop: `${getMarginTop(component, imageDimensions.height)}px`
    }),
    scaleStrategyProp = 'data-scalestrategy',
    getImageClassName = styles => cx(styles.imageComponent, styles.noPointerEvents, styles.cropMode),
    getBackgroundUrl = (urls, defaultUrl) => {
        const
            hdUrl = urls[ImagePixelRatios[ImagePixelRatios.length - 1]];

        return hdUrl || defaultUrl;
    },
    getBackgroundStyles = (cssUrls, src) => ({
        backgroundColor: 'transparent',
        backgroundPosition: 'top',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundImage: `url("${getBackgroundUrl(cssUrls, src)}")`
    }),

    stretchModeRenderProps = ({
        componentId,
        component,
        site,
        additionalProps,
        isWorkspace,
        hasChildren,
        styles,
        hdImages,
        previewBackupTime,
        domain,
        altForLogo,
        autoColorMode,
        themeColorsData,
        selectedParentTheme,
    }): ViewModeCalcRenderProps => {
        const
            { width, height, asset, alt, title, rotation } = component,
            divStyle: Record<string, any> = {
                ...makeComponentBorderStyle(
                    component,
                    (
                        autoColorMode
                            ? prop(
                                pathOr(
                                    getThemeRulesForBackground(selectedParentTheme, themeColorsData).text,
                                    ['style', 'border', 'themeColor'],
                                    component
                                ),
                                themeColorsData
                            )
                            : null
                    )
                ),
                width,
                height,
                overflow: "hidden"
            },
            linkAction = component.linkAction,
            a = !isWorkspace && calcA({
                onClickAction: getOnClickAction(component),
                caption: title,
                componentId,
                index: 0,
                image: { asset, action: linkAction, caption: "", title, rotation },
                previewBackupTime,
                site
            }),
            defaultDimensions = getDefaultDimensions(component),
            imageDimensions = component.scaleStrategy === ScaleStrategy.LOGO ?
                getLogoImageDimensions(component)
                : { width: defaultDimensions.innerWidth, height: defaultDimensions.imageHeight };

        let
            imageAttributes: Record<string, any> = {},
            divClassName = '';
        const { src, srcSet, cssUrls } = getImageUrl(
            component.scaleStrategy === ScaleStrategy.LOGO ? { ...component, ...imageDimensions } : component,
            isWorkspace,
            hdImages,
            additionalProps.imageAvailable,
            domain
        );
        const style: Object = component.scaleStrategy === ScaleStrategy.LOGO ?
            getLogoModeStyles(component, imageDimensions) : getStretchModeStyles(component);
        const imageStyle: Object = {
            ...style,
            display: 'block'
        };
        if (isWorkspace) {
            divClassName = cx(styles.imageComponent, { [`${styles.imgNotFound}`]: !additionalProps.imageAvailable });
            imageAttributes = {
                [scaleStrategyProp]: component.scaleStrategy,
                style: imageStyle,
                src,
            };
        } else {
            divClassName = cx(styles.imageComponent);
            if (hasChildren) {
                Object.assign(divStyle, getBackgroundStyles(cssUrls, src));
                divStyle.overflow = 'visible'; // WBTGEN-3973 if it has children overflow always visible
                divStyle.minHeight = height;
                divStyle.height = 'inherit';
            } else {
                imageAttributes = {
                    [scaleStrategyProp]: component.scaleStrategy,
                    style: imageStyle,
                    src,
                    srcSet,
                    height: fixFloat(imageDimensions.height),
                    width: fixFloat(imageDimensions.width)
                };

                if (component.kind === 'LOGO' && altForLogo) {
                    imageAttributes.alt = altForLogo;
                }

                // only image part should be clickable link in logo mode
                if (component.scaleStrategy === ScaleStrategy.LOGO && a) {
                    a.style = {
                        display: 'block',
                        marginLeft: imageAttributes.style.marginLeft,
                        width: imageAttributes.style.width
                    };
                }

                // We are using styles generated for workspace image render, so we need to remove some styles
                // that are not needed since they are generated from server which handles rotation, dimensions, etc
                // in workspace we use margin to center the image correctly but on server
                // we get exact size images from server so make margins 0
                if (component.scaleStrategy !== ScaleStrategy.LOGO) {
                    imageAttributes.style.margin = 0;
                }
                if (component.scaleStrategy === ScaleStrategy.LOGO && a) {
                    delete imageAttributes.style.marginLeft;
                }
                delete imageAttributes.style.transform;
                delete imageAttributes.style.width;
                delete imageAttributes.style.height;
            }
        }
        imageAttributes = {
            ...imageAttributes,
            ...getTitleAltAttr(title, alt)
        };

        const props = {
            mode: 'view',
            divStyle,
            divClassName,
            imageAttributes,
            width,
            height,
            selectedParentTheme,
            ...additionalProps,
            a: a || undefined
        };

        return props;
    },
    cropModeRenderProps = ({
        componentId,
        component,
        site,
        additionalProps,
        isWorkspace,
        hasChildren,
        styles,
        hdImages,
        previewBackupTime,
        domain,
        autoColorMode,
        themeColorsData,
        selectedParentTheme,
    }) => {
        const
            { width, height, asset, alt, title, rotation } = component,
            divStyle = makeComponentBorderStyle(
                component,
                (
                    autoColorMode
                        ? prop(
                            pathOr(
                                getThemeRulesForBackground(selectedParentTheme, themeColorsData).text,
                                ['style', 'border', 'themeColor'],
                                component
                            ),
                            themeColorsData
                        )
                        : null
                )
            ),
            linkAction = component.linkAction,
            a = !isWorkspace && calcA({
                onClickAction: getOnClickAction(component),
                caption: title,
                componentId,
                index: 0,
                image: { asset, action: linkAction, caption: "", title, rotation },
                previewBackupTime,
                site
            });

        let imageAttributes: Record<string, any> = {};

        let divClassName = '';
        const { src, srcSet, cssUrls } = getImageUrl(
            component,
            isWorkspace,
            hdImages,
            additionalProps.imageAvailable,
            domain
        );

        if (isWorkspace) {
            divClassName = cx(getImageClassName(styles),
                { [`${styles.imgNotFound}`]: !additionalProps.imageAvailable });
            imageAttributes = {
                [scaleStrategyProp]: component.scaleStrategy,
                style: { ...getCropModeStyles(component), display: 'block' },
                src,
            };
        } else {
            divClassName = cx(styles.imageComponent, styles.cropMode);
            if (hasChildren) {
                // in preview we render image as a background
                Object.assign(divStyle, getBackgroundStyles(cssUrls, src));
                divStyle.minHeight = height;
                divStyle.height = 'inherit';
            } else {
                // TODO reorganize css classes and styles for the various cases
                // Cases: WBTGEN-3973
                // 1) No overlapping components
                // 2) Overlap < 50%
                // 3) Overlap >= 50%
                divStyle.overflow = 'hidden';
                const cropDimensions = getCropDimensions(component);
                imageAttributes = {
                    [scaleStrategyProp]: component.scaleStrategy,
                    style: { ...getCropModeStyles(component), display: 'block' },
                    src,
                    srcSet,
                    height: fixFloat(cropDimensions.innerHeight),
                    width: fixFloat(cropDimensions.innerWidth)
                };

                // We are using styles generated for workspace image render, so we need to remove some styles
                // that are not needed since they are generated from server which handles rotation, dimensions, etc
                // in workspace we use margin to center the image correctly but on server
                // we get exact size images from server so make margins 0
                imageAttributes.style.margin = 0;
                // imageAttributes.style.width = 'inherit !important';
                delete imageAttributes.style.transform;

                delete imageAttributes.style.height;
                delete imageAttributes.style.width;
            }
        }
        imageAttributes = {
            ...imageAttributes,
            ...getTitleAltAttr(title, alt)
        };

        const props = {
            mode: 'view',
            divStyle,
            divClassName,
            imageAttributes,
            width,
            height,
            selectedParentTheme,
            ...additionalProps,
            a: a || undefined
        };

        return props;
    },
    editCropModeRenderProps = (
        component, additionalProps, styles, autoColorMode, themeColorsData, selectedParentTheme
    ): EditModeCalcRenderProps => {
        const
            { scaleStrategy, width, height } = component,
            imageUrl = getImageUrl(component, true, false).src,
            style = getCropModeStyles(component),
            backgroundImageStyle = getBackgroundCropModeStyles(component),
            borderStyle = makeComponentBorderStyle(
                component,
                (
                    autoColorMode
                        ? prop(
                            pathOr(
                                getThemeRulesForBackground(selectedParentTheme, themeColorsData).text,
                                ['style', 'border', 'themeColor'],
                                component
                            ),
                            themeColorsData
                        )
                        : null
                )
            );

        return {
            mode: 'edit',
            fgImage: {
                divStyle: {
                    position: 'absolute',
                    width,
                    height,
                    overflow: 'hidden',
                    ...borderStyle
                },
                imageAttributes: {
                    className: getImageClassName(styles),
                    [scaleStrategyProp]: scaleStrategy,
                    style: { ...style, zIndex: getComponentZIndex(component) },
                    src: imageUrl
                }
            },
            bgImage: {
                divStyle: { position: 'absolute' },
                imageAttributes: {
                    className: styles.imageComponentEditBackgroundImage,
                    style: { ...backgroundImageStyle, zIndex: getComponentZIndex(component) - 1 },
                    src: imageUrl,
                    key: "image-background-drop"
                }
            },
            width,
            height,
            selectedParentTheme,
            ...additionalProps
        };
    },
    editModeCalcRenderProps = (
        component, additionalProps, styles, autoColorMode, themeColorsData, selectedParentTheme
    ): EditModeCalcRenderProps => {
        return editCropModeRenderProps(component, additionalProps, styles, autoColorMode, themeColorsData, selectedParentTheme);
    },
    viewModeCalcRenderProps = ({
        componentId,
        component,
        site,
        additionalProps,
        isWorkspace,
        hasChildren,
        styles,
        hdImages,
        previewBackupTime,
        domain,
        altForLogo,
        autoColorMode,
        themeColorsData,
        selectedParentTheme
    }): ViewModeCalcRenderProps => {
        if (
            component.scaleStrategy === ScaleStrategy.STRETCH
            || component.scaleStrategy === ScaleStrategy.FIT
            || component.scaleStrategy === ScaleStrategy.LOGO
        ) {
            return stretchModeRenderProps({
                componentId,
                component,
                site,
                additionalProps,
                isWorkspace,
                hasChildren,
                styles,
                hdImages,
                previewBackupTime,
                domain,
                altForLogo,
                autoColorMode,
                themeColorsData,
                selectedParentTheme,
            });
        }

        return cropModeRenderProps({
            componentId,
            component,
            site,
            additionalProps,
            isWorkspace,
            hasChildren,
            styles,
            hdImages,
            previewBackupTime,
            domain,
            autoColorMode,
            themeColorsData,
            selectedParentTheme,
        });
    },
    calcRenderProps: any = (
        {
            component: inComponent,
            componentDependencies: { missingAssetUrls, site, globalVariables, isLogo, themeColorsData, themeSettingsData },
            inEditMode,
            isWorkspace,
            hasChildren,
            hdImages,
            previewBackupTime,
            viewerDomain,
            selectedParentTheme,
        },
        styles
    ) => {
        if (isLogo && !globalVariables.logoAsset) {
            const homePageRef = site.getHomePageRef();
            return {
                componentId: inComponent.id,
                websiteTitle: globalVariables.websiteTitle
                    || globalVariables['i18n.websiteTitle']
                    || defaultLogoText,
                horizontalAlignment: inComponent.logoHorizontalAlignment,
                scale: inComponent.logoTitleScale,
                href: homePageRef ?
                    getLinkAbsoluteUrl({ type: "page", value: homePageRef.id }, site, previewBackupTime)
                    : null,
                selectedParentTheme,
            };
        }

        let component = inComponent;
        let domain;

        if (component.linkAction && component.linkAction.link && component.linkAction.link.value) {
            if (component.linkAction.link.type === 'email' || component.linkAction.link.type === 'location') {
                const { value: { selection } } = component.linkAction.link;

                const replacedValue = replaceTagsWithContent(selection, { globalVariables, site }, isWorkspace);
                if (replacedValue !== selection) {
                    if (replacedValue) {
                        component = assocPath(['linkAction', 'link', 'value', 'selection'], replacedValue, component);
                    } else {
                        component = setBrokenLink(component);
                    }
                }
            } else if (component.linkAction.link.type === 'file' || component.linkAction.link.type === 'sectionLink') {
                // nothing to replace
            } else {
                const { value } = component.linkAction.link;

                const replacedValue = replaceTagsWithContent(value, { globalVariables, site }, isWorkspace);
                if (replacedValue !== value) {
                    if (replacedValue) {
                        component = assocPath(['linkAction', 'link', 'value'], replacedValue, component);
                    } else {
                        component = setBrokenLink(component);
                    }
                }
            }
        }

        const isLogoAndLogoAssetAvailable = isLogo && globalVariables.logoAsset;

        if (isLogoAndLogoAssetAvailable) {
            component = { ...component, asset: globalVariables.logoAsset, alt: globalVariables.logoAltText };
            domain = viewerDomain;
        }

        const
            componentId = component.id,
            additionalProps = {
                inEditMode,
                scaleStrategy: component.scaleStrategy,
                imageAvailable: isLogoAndLogoAssetAvailable
                    || (missingAssetUrls ? missingAssetUrls.indexOf(component.asset.url) === -1 : true)
            };

        let props;

        if (inEditMode && component.scaleStrategy === ScaleStrategy.CROP && additionalProps.imageAvailable) {
            props = editModeCalcRenderProps(component, additionalProps, styles, themeSettingsData.autoColorMode, themeColorsData, selectedParentTheme);
        } else {
            const altForLogo = globalVariables.websiteTitle;
            props = viewModeCalcRenderProps({
                componentId,
                component,
                site,
                additionalProps,
                isWorkspace,
                hasChildren,
                styles,
                hdImages,
                previewBackupTime,
                domain,
                altForLogo,
                themeColorsData,
                selectedParentTheme,
                autoColorMode: themeSettingsData.autoColorMode,
            });
        }

        return props;
    };

export default calcRenderProps;
