import React from 'react';
import cx from 'classnames';
import styles from './FourField.css';
import ppStyles from '../../PropertiesPanel/view/PropertiesPanel.css';
import NumberField from "../../../view/common/Input/NumberField";
import PropertyContainer from "../../../view/common/PropertyContainer/index";
import { injectIntl } from '../../../view/intl/index';
import {
    DefaultFourFields,
    DefaultFourFieldsLocked,
    FourFieldsCorners,
    FourFieldsDefaultValueRenderMap
} from "./constants";
import type { FourFieldsProps, FourFieldsValidatorErrors } from "./flowTypes";
import * as array from '../../../../utils/array.js';
import LongTextTip from '../../../view/common/LongTextTip/index';
import getStyleIntValue from "../../../utils/getStyleIntValue";

type State = {
    values: Tuple4<number>,
    locked: boolean,
    isDirty: boolean,
    errors: null | undefined | FourFieldsValidatorErrors,
};

const
    ERRORS_PADDING = getStyleIntValue(styles, 'errorsPadding'),
    ERRORS_MESSAGE_HEIGHT = Math.floor(getStyleIntValue(styles, 'errorsFontSize') * 1.42857),
    ERRORS_TIP_HEIGHT = getStyleIntValue(styles, 'errorsTipHeight'),
    ERRORS_TIP_SHIFT = getStyleIntValue(styles, 'errorsTipShift');

class FourFields extends React.Component<FourFieldsProps, State> {
    // eslint-disable-next-line react/static-property-placement
    static defaultProps = {
        valuesRenderMap: FourFieldsDefaultValueRenderMap
    };

    lockTitle: null | undefined | string;
    isDispatchScheduledForAnimationFrame: boolean = false;

    constructor(props: FourFieldsProps) {
        super(props);
        const [values, locked] = this.getValuesAndLock(props);
        this.state = { values, locked, isDirty: false, errors: null };
        this.toggleLock = this.toggleLock.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onChangeMap = Object.keys(FourFieldsCorners).reduce((acc, k) => ({
            ...acc,

            [FourFieldsCorners[k]]: this.onChange.bind(this, props.valuesRenderMap?.[FourFieldsCorners[k]])
        }), {});
        this.onBlur = this.onBlur.bind(this);

        this.lockTitle = this.props.title && this.props.intl.msgJoint(this.props.title);
    }

    getValuesAndLock(props: FourFieldsProps): [Tuple4<number>, boolean] { // eslint-disable-line class-methods-use-this
        const
            { values: inValues } = props,
            values = props.values
                ? [inValues[0], inValues[1], inValues[2], inValues[3]] as Tuple4<number> // flow blames error when destructure tupple
                : DefaultFourFields,
            locked = isNaN(values.reduce((a, b) => { return a === b ? a : NaN; })) // values are different
                ? false
                : DefaultFourFieldsLocked;

        return [values, locked];
    }

    validateValue(value: number, valueIndex: number): boolean {
        const { validators } = this.props;
        if (!validators) return true;

        const
            allValidators = Array.isArray(validators) ? validators : [validators],
            errors = allValidators.reduce((acc: any[], { validator, message }) => (
                validator(value) ? acc : [...acc, message]
            ), []);

        if (errors.length) {
            this.setState({ errors: { messages: errors, valueIndex } });
            return false;
        }
        return true;
    }

    toggleLock() {
        this.setState((state) => ({ locked: !state.locked }));
    }

    onChange(valueIndex: number, value: number) {
        let { values } = this.state;

        // validate
        if (!this.validateValue(value, valueIndex)) return;

        if (this.state.locked) {
            values = [value, value, value, value];
        } else {
            values = values.slice() as Tuple4<number>;
            values[valueIndex] = value;
        }

        this.setState({ values, isDirty: true, errors: null });

        const { onChangeAction, restrictDispatchTillAnimationFrame, dispatch } = this.props;
        if (restrictDispatchTillAnimationFrame) {
            if (!this.isDispatchScheduledForAnimationFrame) {
                this.isDispatchScheduledForAnimationFrame = true;
                window.requestAnimationFrame(() => {
                    dispatch({ type: onChangeAction, payload: this.state.values });
                    this.isDispatchScheduledForAnimationFrame = false;
                });
            }
        } else {
            dispatch({ type: onChangeAction, payload: values });
        }
    }

    onChangeMap: MapT<(e: React.SyntheticEvent) => void>;

    onBlur() {
        this.setState({ isDirty: false, errors: null });
    }

    refsMap: Record<string, any> = {};

    containerRef: any;

    UNSAFE_componentWillReceiveProps(nextProps: FourFieldsProps) {
        if (this.props !== nextProps) {
            if (this.state.isDirty) {
                /**
                 * This else if case has been added because in table component PP, size and spacing page, the fields can get new values
                 * from outside and it causes the isDirty field in state not to be reset. Hence this case fixes the problem for now.
                 * - sepo@one.com vishalr@one.com
                 */
                this.setState({ isDirty: false });
            } else {
                const
                    { values: oldValues } = this.state,
                    [values, locked] = this.getValuesAndLock(nextProps);

                if (!array.equal(values, oldValues)) {
                    this.setState({ values, locked });
                }
            }
        }
    }

    renderErrors() {
        const { errors } = this.state;
        if (!errors) return null;

        const
            pageElement = (() => {
                let parent = this.containerRef.parentNode;
                while (parent.nodeName !== 'BODY' && !parent.className.includes(ppStyles.page)) {
                    parent = parent.parentNode;
                }
                return parent;
            })(),
            pageContainerRect = pageElement.getBoundingClientRect(),
            { valueIndex, messages } = errors,
            inputEl = this.refsMap[valueIndex],
            inputRect = inputEl.getBoundingClientRect(),
            errorsHeight = (ERRORS_MESSAGE_HEIGHT * messages.length) + (ERRORS_PADDING * 2),
            errorsFit = inputRect.top + inputRect.height - pageContainerRect.top + errorsHeight,
            errorsTop = errorsFit < pageContainerRect.height
                ? inputEl.offsetTop + inputRect.height + ERRORS_TIP_SHIFT
                : inputEl.offsetTop - errorsHeight - ERRORS_TIP_SHIFT,
            errorsStyle = { top: errorsTop },
            errorsTipLeft = inputEl.offsetLeft + (inputRect.width / 2) - (ERRORS_TIP_HEIGHT / 2),
            errorsTipStyle = errorsFit < pageContainerRect.height
                ? { top: -(ERRORS_TIP_HEIGHT / 2), left: errorsTipLeft }
                : { bottom: -(ERRORS_TIP_HEIGHT / 2), left: errorsTipLeft };

        return (
            <div className={styles.errors} style={errorsStyle}>
                <span className={styles.errorsTip} style={errorsTipStyle} />
                <ul>
                    {messages.map((msg, i) => (
                        <li key={i}><LongTextTip>{this.props.intl.msgJoint(msg)}</LongTextTip></li>
                    ))}
                </ul>
            </div>
        );
    }

    renderField(corner: string) {
        const
            { state: { values, errors }, props: { icons, disabled, valuesRenderMap } } = this,
            showValues = !(disabled && values === DefaultFourFields),

            valueIndex = valuesRenderMap?.[corner],
            value = showValues ? values[valueIndex] : '',
            Icon = icons[valueIndex],
            iconContainer = <div className={styles.fieldImageContainer}>{Icon}</div>,
            className = cx(
                styles.inputField,
                {
                    [styles.disabled]: disabled,
                    [styles.error]: errors && errors.valueIndex === valueIndex
                }
            );

        return (
            <label className={styles.label}>
                <div className={styles.horizontalGroup}>
                    {(corner === FourFieldsCorners.TOP_LEFT || corner === FourFieldsCorners.BOTTOM_LEFT) &&
                        iconContainer}
                    <NumberField
                        inputRef={ref => { this.refsMap[valueIndex] = ref; }}
                        className={className}
                        value={value}
                        onChange={this.onChangeMap[corner]}
                        onBlur={this.onBlur}
                        allowDecimals={false}
                        usePropsValue
                        max={1000}
                        disabled={disabled}
                    />
                    {(corner === FourFieldsCorners.TOP_RIGHT || corner === FourFieldsCorners.BOTTOM_RIGHT) &&
                        iconContainer}
                </div>
            </label>
        );
    }

    render() {
        const { state: { locked: isLocked }, props: { label, disabled, showTopAndBottomElements = true, showLeftAndRightElements = true } } = this, // eslint-disable-line max-len
            areSomeElementsMissing = !(showLeftAndRightElements && showTopAndBottomElements);
        return (
            <PropertyContainer
                label={label}
                disabled={disabled}
                className={styles.container}
                containerRef={ref => { this.containerRef = ref; }}
                htmlFor="fourFields"
            >
                <div className={styles.fourField}>
                    <div className={styles.verticalGroup}>
                        {showTopAndBottomElements && this.renderField(FourFieldsCorners.TOP_LEFT)}
                        {!areSomeElementsMissing && <div className={styles.verticalSpacer}>&nbsp;</div> }
                        {showLeftAndRightElements && this.renderField(FourFieldsCorners.BOTTOM_LEFT)}
                    </div>
                    <div className={cx(styles.lockContainer,
                        { [styles.verticalSpacerLimitedProperties]: areSomeElementsMissing })}
                    >
                        <div
                            data-title={this.lockTitle}
                            className={cx({
                                [styles.locked]: isLocked,
                                [styles.unlocked]: !isLocked,
                                [styles.disabled]: disabled
                            })}
                            onClick={this.toggleLock}
                        />
                    </div>
                    <div className={styles.verticalGroup}>
                        {showLeftAndRightElements && this.renderField(FourFieldsCorners.TOP_RIGHT)}
                        {!areSomeElementsMissing && <div className={styles.verticalSpacer}>&nbsp;</div>}
                        {showTopAndBottomElements && this.renderField(FourFieldsCorners.BOTTOM_RIGHT)}
                    </div>
                </div>
                {this.renderErrors()}
            </PropertyContainer>
        );
    }
}

export default injectIntl(FourFields);
