/* eslint-disable max-len */
/**
 * All UI related data for whatsnew is kept here.
 * This is to prevent ./whatsNewList.js file from getting polluted with UI data as it is used in l10n scripts.
 */

import { SubscriptionType } from "../../../../server/shared/accessManager/constants.js";

import { makeLinkIntlTag } from '../../view/intl/index';
import { loadTemplateSelector } from "../TemplateSelector_DEPRECATED/actionCreators/loadTemplateSelector";
import { TEMPLATE_FILTER } from "../TemplateSelector_DEPRECATED/constants";
import type { WhatsNewExtensionT, WhatsNewCombinedListT } from "./types";
import { PANEL_ITEM_LEFT_MOUSE_DOWN } from "../Panel/actionTypes";
import VideoKind from "../oneweb/Video/kind";
import { closeDialogAction } from "../../../../src/dashboard/src/components/Dialog/actions";
import { OPEN_SEO_SCAN_FROM_WHATS_NEW } from "../../../../src/dashboard/src/components/WhatsNew/actions";
import { SOCIAL_KIND } from "../oneweb/Social/kind";

const { WhatsNewList } = require('../../../notifications/whatsNewList');

const
    WhatsNewExtension: WhatsNewExtensionT = {
        '37': {
            makeDescriptionParams: ({ dispatch, linkClassName }) => ({
                link: makeLinkIntlTag({
                    onClick: (e) => {
                        e.preventDefault();
                        dispatch({
                            type: PANEL_ITEM_LEFT_MOUSE_DOWN,
                            payload: { componentKind: VideoKind }
                        });
                        dispatch(closeDialogAction());
                    },
                    className: linkClassName
                })
            })
        },
        '38': {
            makeDescriptionParams: ({ dispatch, linkClassName }) => ({
                link: makeLinkIntlTag({
                    onClick: (e) => {
                        e.preventDefault();
                        dispatch(loadTemplateSelector({
                            source: "What's new Widgets & new website templates",
                            // @ts-ignore
                            filter: TEMPLATE_FILTER.new
                        }));
                    },
                    className: linkClassName
                })
            })
        },
        '39': {
            makeDescriptionParams: ({
                dispatch,
                linkClassName,
                subscriptionType,
                disabledLinkClassName
            }) => {
                if (subscriptionType && subscriptionType === SubscriptionType.ECOMMERCE) {
                    return {
                        link: makeLinkIntlTag({
                            onClick: (e) => {
                                e.preventDefault();
                                dispatch({ type: OPEN_SEO_SCAN_FROM_WHATS_NEW });
                            },
                            className: linkClassName
                        })
                    };
                } else {
                    return {
                        link: makeLinkIntlTag({
                            onClick: (e) => { e.preventDefault(); },
                            className: disabledLinkClassName
                        })
                    };
                }
            }
        },
        '44': {
            makeDescriptionParams: ({ dispatch, linkClassName }) => ({
                link: makeLinkIntlTag({
                    onClick: (e) => {
                        e.preventDefault();
                        dispatch({
                            type: PANEL_ITEM_LEFT_MOUSE_DOWN,
                            payload: { componentKind: SOCIAL_KIND }
                        });
                        dispatch(closeDialogAction());
                    },
                    className: linkClassName
                })
            })
        },
    },
    WhatsNewListWithExtensionCombined: WhatsNewCombinedListT = WhatsNewList.reduce(
        (acc, item) => ([...acc, { ...item, ...WhatsNewExtension[item.id] }]),
        [] as WhatsNewCombinedListT
    );

export {
    WhatsNewListWithExtensionCombined
};
