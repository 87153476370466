import React, { useEffect, useRef, useState } from "react";
import cx from "classnames";
import { useDispatch } from "react-redux";
import getCenteredDialogConfig from "../../../DialogManager/getCenteredDialogConfig";
import { Dialog, DialogBody, StripTypes } from "../../../../view/common/dialogs/baseDialog";
import styles from "./BookingsManagementDialog.css";
import { BOOKINGS_DIALOG_HEIGHT_OFFSET } from "../constants";
import { getCenterPositionForDialog } from "../../../DialogManager/utility";
import { getBookingsIframeToken, getBookingsUrlWithToken } from "../utils";
import LoadingIndicator from "../../../../view/common/LoadingIndicator";
import { ErrorMessage } from "../../../../../../src/dashboard/src/components/Error/ErrorBoundary/ErrorMessage";
import { BookingsMessageTypes } from "../../../../../../src/dashboard/src/apps/Bookings/types";
import { BOOKINGS_EDITOR_LOAD_TIMEOUT, IframeLoadingStatus } from "./constants";
import { Msg } from "../../../../view/intl";
import { CLOSE_DIALOG } from "../../../../redux/modules/actionTypes";
import { TO_SHOW_PUBLISH_BUTTON_TOOLTIP } from "../../../Tooltip/stickyTooltip/actionTypes";

const BookingsManagementDialog = (props) => {
    const dispatch = useDispatch();
    const iframeRef = useRef<HTMLIFrameElement>(null);
    const errorTimeout = useRef<NodeJS.Timeout>();
    const [iframeUrl, setIframeUrl] = useState('');
    const [loadingStatus, setLoadingStatus] = useState<string | null>(IframeLoadingStatus.LOADING);
    const { iframePath } = props;

    const handleIframeLoadingFailure = () => {
        setLoadingStatus(IframeLoadingStatus.ERROR);
    };
    const clearErrorTimer = () => {
        clearTimeout(errorTimeout.current);
    };
    const resetErrorTimer = () => {
        clearTimeout(errorTimeout.current);
        errorTimeout.current = setTimeout(
            handleIframeLoadingFailure,
            BOOKINGS_EDITOR_LOAD_TIMEOUT
        );
    };
    const handleIframeLoadingStart = () => {
        setIframeUrl('');
        resetErrorTimer();
        setLoadingStatus(IframeLoadingStatus.LOADING);
    };
    const reloadIframe = () => {
        handleIframeLoadingStart();

        getBookingsIframeToken()
            .then((response) => {
                const token = response?.body?.token;

                if (token) {
                    setIframeUrl(getBookingsUrlWithToken(token, iframePath));
                } else {
                    throw new Error("Invalid response");
                }
            })
            .catch(handleIframeLoadingFailure);
    };

    const handleIframeLoadingComplete = () => {
        // avoid showing iframe if loading takes longer than
        // the fixed timeout.
        if (loadingStatus === IframeLoadingStatus.ERROR) {
            return;
        }

        clearErrorTimer();
        setLoadingStatus(IframeLoadingStatus.LOADED);
    };

    const messageHandler = (event: any) => {
        const iframe = iframeRef.current;

        if (iframe && iframe.src) {
            const isIframeMessage = iframe.src.startsWith(event.origin);

            if (isIframeMessage && event.data) {
                switch (event.data.type) {
                    case BookingsMessageTypes.BOOKINGS_LOADING_COMPLETE:
                        handleIframeLoadingComplete();
                        break;

                    case BookingsMessageTypes.BOOKINGS_RELOADING:
                        reloadIframe();
                        break;

                    case BookingsMessageTypes.BOOKINGS_PUBLISH:
                        dispatch({ type: CLOSE_DIALOG });
                        dispatch({ type: TO_SHOW_PUBLISH_BUTTON_TOOLTIP });
                        break;

                    default:
                        break;
                }
            }
        }
    };

    useEffect(() => {
        reloadIframe();
        window.addEventListener("message", messageHandler, false);
        return () => {
            clearErrorTimer();
            window.removeEventListener("message", messageHandler, false);
        };
    }, []);

    const isLoaded = loadingStatus === IframeLoadingStatus.LOADED;

    return (
        <React.Fragment>
            <Dialog stripType={StripTypes.NONE} showCloseBtn={false}>
                <DialogBody className={styles.bookingsDialog} data-testid="bookings-dialog">
                    <div data-testid="top-bar" className={styles.topBar}>
                        <div className={styles.backButton} onClick={() => dispatch({ type: CLOSE_DIALOG })}>
                            <span className={styles.backButtonIcon} />
                            <span className={styles.backButtonText}>
                                <Msg k="preview.toolbar.backToEditor">Back to editor</Msg>
                            </span>
                        </div>
                        <div className={styles.title}>
                            <Msg k="bookings">Bookings</Msg>
                        </div>
                    </div>
                    <div className={styles.container}>
                        {loadingStatus === IframeLoadingStatus.LOADING &&
                            <LoadingIndicator className={styles.loader} data-testid="loadingBookings" />}
                        {loadingStatus === IframeLoadingStatus.ERROR && <ErrorMessage
                            classes={{ root: styles.error }}
                            icon="large"
                            reloadButton
                            reloadHandler={reloadIframe}
                        />}
                        <iframe
                            className={cx({ [styles.iframeLoading]: !isLoaded })}
                            data-testid="bookings-iframe"
                            src={iframeUrl}
                            ref={iframeRef}
                        />
                    </div>
                </DialogBody>
            </Dialog>
        </React.Fragment>
    );
};

/**
 * Calculcates the dimensions of the management dialog \
 * It occupies the full view width and a reduced amount of the height
 * @param browserWidth - Browser width in pixel
 * @param browserHeight - Brower height in pixel
 * @returns Dimensions
 */
const GetDialogDimensions = (browserWidth: number, browserHeight: number) => {
    const width = browserWidth;
    const height = browserHeight - BOOKINGS_DIALOG_HEIGHT_OFFSET;

    return {
        width,
        height
    };
};

const config = {
    // create the dialog with the management dialog component
    ...getCenteredDialogConfig({
        component: BookingsManagementDialog,
        dragEnabled: false,
        dialogClassName: styles.dialogContainer
    }),

    // set the general dialog parameters in configuration factory
    confFactory: ({ browserWidth, browserHeight }) => {
        const { width, height } = GetDialogDimensions(browserWidth, browserHeight);
        const { left } = getCenterPositionForDialog(width, height, browserWidth, browserHeight);

        return {
            dimensions: { width, height },
            // stick dialog to the bottom center
            position: { left, bottom: 0 },
            // modal is set so the dialog is not closed if clicked outside
            // only the x will close the dialog
            modal: true
        };
    },

    // recalculate the dialog dimensions in case the browser dimensions change
    updateOnBrowserDimensionsChanged: (dialogConfig, browserDimensions) => {
        const { width, height } = GetDialogDimensions(
            browserDimensions.width,
            browserDimensions.height
        );

        return {
            ...dialogConfig,
            dimensions: { width, height }
        };
    },

    // the dialog should keep the position at the bottom center
    // otherwise it would be set to middle centered position by default
    keepPositionAtBottomCenter: true
};

export default config;
