import * as React from 'react';
import fetch from '../../../../src/services/fetch';
import styles from "../signIn/components/Signin.css";
import InputField from "../../../../src/view/common/Input/InputField";
import { Msg } from '../../../../src/view/intl/index';
import { useMergeState } from "../../../../src/utils/react/useMergeState";
import { isInvalidEmail as isInvalidEmailValidator } from "../validators";
import { normalizeEmail } from "../../../../../server/shared/utils/normalizeEmail.js";
import { TrialUrl } from '../../../../../server/shared/trial/TrialUrl.js';
import Combobox from '../../../../src/view/common/Combobox/index';

type Props = {
    onChange: (username: string) => void,
    onEmailChange?: (val: string) => void,
    onEmailEnterPress?: React.MouseEventHandler,
    // TODO: should be combined with email error
    errorChildren?: React.ReactNode,
};

const InitialState = {
    email: '',
    accounts: {
        initEmail: false,
        isMulti: false,
        list: [],
        current: null,
    },
    isLoading: false,
};

/*** Main ***/

export const TrialAccountCombo = React.forwardRef((props: Props, ref) => {
    const { onChange, onEmailChange, onEmailEnterPress, errorChildren } = props;

    const
        [state, setState] = useMergeState(InitialState),
        { email, accounts, isLoading } = state,
        isInvalidEmail = isInvalidEmailValidator(email),
        isInvalidEmailError = email && isInvalidEmail;

    React.useEffect(() => {
        const { initEmail, isMulti, current } = accounts;

        if (!initEmail) {
            return;
        } else if (isMulti && current) {
            onChange(current);
        } else if (!isMulti) {
            onChange(email);
        }
    }, [accounts]);

    React.useImperativeHandle(ref, () => ({
        dispatch: () => {
            if (isInvalidEmail) {
                return;
            }
            yieldAccounts(state, setState);
        },
    }));

    return (
        <React.Fragment>
            <div className={styles.emailContainer}>
                <Msg k="common.email" className={styles.inputLabel}>Email</Msg>
                <InputField
                    autoFocus
                    value={email}
                    onChange={val => {
                        setState({ email: val });
                        if (onEmailChange) {
                            onEmailChange(val);
                        }
                    }}
                    onEnterPress={onEmailEnterPress}
                    disabled={isLoading}
                />
                {isInvalidEmailError && (
                    <Msg k="demo.login.error.invalidEmail" className={styles.invalidEmailError}>
                        Check that the email is valid.
                    </Msg>
                )}
                {errorChildren}
            </div>
            <Accounts {...accounts} setAccounts={accounts => setState({ accounts })} />
        </React.Fragment>
    );
});

/*** Lib ***/

function Accounts({ initEmail, isMulti, list, current, setAccounts }) {
    if (!initEmail || !isMulti) {
        return null;
    }

    const
        options = list.map(acc => ({ value: acc, label: acc })),
        onChange = ({ value }) => {
            setAccounts({ current: value });
        };

    return (
        <div>
            <Msg k="trial.username" className={styles.inputLabel}>Username</Msg>
            <Combobox
                options={options}
                value={current}
                onChange={onChange}
                // @ts-ignore
                dispatch={() => null}
            />
        </div>
    );
}

function yieldAccounts(state, setState) {
    const { email, accounts } = state;

    if (accounts.initEmail && accounts.initEmail === email) {
        setState({ accounts: { ...accounts } });
        return;
    }

    const normalEmail = normalizeEmail(email);

    setState({ isLoading: true });

    fetch(TrialUrl.accounts(normalEmail))
        .then(res => res.json())
        .then(({ accounts }) => {
            setState({
                accounts: {
                    initEmail: email,
                    isMulti: accounts.length > 1 || (accounts[0] && accounts[0] !== normalEmail),
                    list: accounts,
                    current: null,
                },
                isLoading: false,
            });
        });
}
