import CALL_API from "../../../redux/middleware/api/CALL_API";
import {
    BACKUP_NAME_SAVEPOINT, BACKUP_NAME_SAVEPOINT_FAILURE,
    BACKUP_NAME_SAVEPOINT_SUCCESS

} from "../actionTypes";
import type { ApiAction } from "../../../redux/middleware/api/flowTypes";
import NamedBackup from "../../../../dal/model/NamedBackup";

export default function ({ payload, requestType }: { payload: NamedBackup, requestType: 'edit' | 'add' }): any {
    const action: ApiAction = {
        [CALL_API]: {
            types: [
                BACKUP_NAME_SAVEPOINT,
                BACKUP_NAME_SAVEPOINT_SUCCESS,
                BACKUP_NAME_SAVEPOINT_FAILURE
            ],
            endpoint: "namedBackup",
            endpointParams: { payload, requestType }
        }
    };

    return action;
}
