/* eslint-disable react/jsx-fragments */
/* eslint-disable max-len */
import React from 'react';
import * as R from 'ramda';
import cx from "classnames";
import { Flex } from '../../../../view/reflexbox';
import { injectIntl } from '../../../../view/intl';
import makePages from "../../../PropertiesPanel/view/makePages";
import addGoogleFontView from '../../../PropertiesPanel/view/AddGoogleFont';
import NavigationGroup from '../../../PropertiesPanel/view/IntlNavigationGroup';
import Page from '../../../PropertiesPanel/view/PropertiesPage';
import VerticalSpacer from '../../../../view/common/VerticalSpacer';
import * as mobileView from '../../../PropertiesPanel/view/MobileView/page';
import PropertiesButton from '../../../../view/common/Button/PropertiesButton';
import styles from '../textLike.css';
import stylesheetsIdToNameMapEpic from '../../../Workspace/epics/stylesheets/idToNameMap';
import { DEFAULT_MCTA_COMBOBOX_MENUSTYLE } from '../../../componentMainActions/constants';
import * as selectors from '../../../Workspace/epics/stylesheets/selectors';
import stylesheetsEpic from '../../../Workspace/epics/stylesheets';
import HorizontalSpacer from '../../../../view/common/HorizontalSpacer';
import { MctaVerticalBar, MctaButton } from '../../../componentMainActions';
import { titleTextSmaller, titleTextSmallerErr, titleTextBigger, titleTextBiggerErr, titleClearFormatting, ComponentNames } from '../../constants';
import FontSizeSelector from '../../../presentational/FontSize';
import ColorProperty from '../../../presentational/ColorProperty';
import { DEFAULT_TEXT_TOOLBAR_COLOR_BOX_STYLE, MAX_FONT_SIZE, MIN_FONT_SIZE } from '../../Text/constants';
import { StatefulAlignmentControls } from '../../../componentMainActions/MctaAlignmentControls/alignmentControls';
import {
    TextLike,
    MCTA_RESIZE_OPTIONS,
    MIN_ICON_SIZE,
    MAX_ICON_SIZE,
    MIN_ICON_SPACING,
    MAX_ICON_SPACING,
} from '../constants';
import FontFamilySelector from '../../../presentational/FontFamily';
import siteFontsReducer from '../../../Fonts/siteFontsReducer';
import PPSlider from '../../../../view/common/Slider/PPSlider';
import ChangeLetterCaseButtonGroup from "../../../presentational/IconButtonsGroup/ChangeLetterCase";
import Icons from '../../../../view/Icons';
import { TOGGLE_MOBILE_DOWN_ACTION } from '../../../PropertiesPanel/view/MobileView/actions';
import mctaStyles from '../../../componentMainActions/MctaButton/styles.css';
import { replaceTagsWithContent } from '../../Text/view/replaceTagsWithContent';
import type { ChangeOldComponentDataBeforeImportProps } from "../../../../view/oneweb/flowTypes";
import { globalVariablesEpic } from '../../../App/epics/globalVariablesEpic';
import PropertyContainer from '../../../../view/common/PropertyContainer';
import PropertiesCheckBox from '../../../../view/common/CheckBox/PropertiesCheckBox';
import TextFormatButtonGroup from '../../../presentational/IconButtonsGroup/TextFormat';
import { DEFAULT_LINE_HEIGHT } from '../../../App/epics/tinyMceEpic/editorUtils/constants';
import { MIN_CHARACTER_SPACING, MAX_CHARACTER_SPACING } from '../../../presentational/CharacterSpacing/constants';
import Separator from '../../../presentational/PropertiesPanel/Separator';
import MctaPropertyContainer from '../../../componentMainActions/MctaPropertyContainer';
import {
    TEXT_LIKE_PREFIX_BOLD_CHANGED,
    TEXT_LIKE_PREFIX_ITALIC_CHANGED,
    TEXT_LIKE_PREFIX_UNDERLINE_CHANGED,
    TEXT_LIKE_VALUE_BOLD_CHANGED,
    TEXT_LIKE_VALUE_ITALIC_CHANGED,
    TEXT_LIKE_VALUE_UNDERLINE_CHANGED,
    TEXT_LIKE_FONT_SIZE_CHANGED,
    TEXT_LIKE_COLOR_CHANGED,
    TEXT_LIKE_COLOR_CHANGED_AUTO_COLOR,
    TEXT_LIKE_COLOR_REMOVED_AUTO_COLOR,
    TEXT_LIKE_HORIZONTAL_ALIGNMENT_CHANGED,
    TEXT_LIKE_CLEAR_FORMATTING,
    TEXT_LIKE_SHOW_CUSTOM_TITLE_FIRST_TOGGLE,
    TEXT_LIKE_CUSTOM_PREFIX_TEXT_CHANGED,
    TEXT_LIKE_SHOW_ON_ONE_LINE_TOGGLE,
    TEXT_LIKE_FONT_FAMILY_CHANGED,
    TEXT_LIKE_CHARACTER_SPACING_CHANGED,
    TEXT_LIKE_LINE_SPACING_CHANGED,
    TEXT_LIKE_PP_VALUE_ALL_UPPERCASE_BTN_CLICKED,
    TEXT_LIKE_PP_VALUE_LOWERCASE_BTN_CLICKED,
    TEXT_LIKE_PP_PREFIX_ALL_UPPERCASE_BTN_CLICKED,
    TEXT_LIKE_PP_PREFIX_LOWERCASE_BTN_CLICKED,
    TEXT_LIKE_MOBILE_INC_FONT_BTN_PRESSED,
    TEXT_LIKE_MOBILE_DEC_FONT_BTN_PRESSED,
    TEXT_LIKE_MOBILE_INC_SVG_SIZE_BTN_PRESSED,
    TEXT_LIKE_MOBILE_DEC_SVG_SIZE_BTN_PRESSED,
    TEXT_LIKE_MOBILE_TEXT_ALIGNMENT_CHANGED,
    TEXT_LIKE_MOBILE_CLEAR_FORMATTING,
    TEXT_LIKE_SHOW_ICON_TOGGLE,
    TEXT_LIKE_ICON_SIZE_CHANGED,
    TEXT_LIKE_ICON_TEXT_SPACING_CHANGED,
    TEXT_LIKE_MCTA_CHANGE_SIZE,
    TEXT_LIKE_MOBILE_MCTA_CHANGE_SIZE
} from '../actionTypes';
import {
    titleImageBigger,
    titleImageBiggerErr,
    titleImageSmaller,
    titleImageSmallerErr
} from "../../../MobileViewEditor/view/Groups/MCTA/ImageMctaScaler";
import { colorThemeSiteSettingsEpic } from "../../../SiteSettings/ColorThemeData/colorThemeSiteSettingsEpic";
import { getThemeRulesForBackground } from '../../../ThemeGlobalData/themeRules';
import { findSuitableTextColorName } from "../../../ThemeGlobalData/utils/commonUtils";
import { THEME_COLOR_PICKER, DEFAULT_COLOR_PICKER } from '../../../ColorPicker/constants';
import { getThemeColorsFromStylesheet } from "../../../Workspace/epics/stylesheets/selectors";

import type {
    DefaultComponentState,
    ComponentState,
    WorkspaceConfigProps,
    HookProps,
    CalcRenderPropsResult,
    ExtractReturnType,
} from "../types";
import { isModernLayoutActivatedEpic } from "../../../Workspace/epics/isModernLayoutActivatedEpic";

import { textLikeComponentFactory } from "./textLikeComponentFactory";
import { fontSizeToDefaultIconSize } from "../utils/fontSizeToDefaultIconSize";
import { fontSizeToDefaultIconSpacing } from "../utils/fontSizeToDefaultIconSpacing";
import { capTextFontSize } from "../utils/capTextFontSize";
import { getTextLikeDefaultState } from "../utils/getTextLikeDefaultState";
import { EMAIL_KIND } from "../Email/kind";
import { PHONE_KIND } from "../Phone/kind";
import BaseComponent from '../../../../view/oneweb/BaseComponent';
import ComponentMask from '../../../presentational/ComponentMask';
import { ComponentMaskBackground } from '../../../presentational/ComponentMask/constants';
import type { ReactElementRef } from "../../../../globalTypes";
import { updateComponentsMapExtension } from '../../../Workspace/epics/componentsEval/adjustmentDataDispatchCache';
import { textLikeCalcRenderProps } from "./textLikeCalcRenderProps";

const toggleGenericProp = (prop, generic) => ({ ...generic, [prop]: !generic[prop] });

const calculateDimensionsOfElements =
    (elements: HTMLCollection | Array<HTMLElement>): { width: number, height: number } | null => {
        const items = Array.from(elements),
            box = items.reduce(({ left, right, top, bottom }, item) => {
                const { left: l, right: r, top: t, bottom: b } = item.getBoundingClientRect();
                return {
                    left: Math.min(left, Math.floor(l)),
                    right: Math.max(right, Math.ceil(r)),
                    top: Math.min(top, Math.floor(t)),
                    bottom: Math.max(bottom, Math.ceil(b))
                };
            }, { top: Infinity, bottom: -Infinity, left: Infinity, right: -Infinity }),
            width = Math.ceil(box.right - box.left),
            height = Math.ceil(box.bottom - box.top);
        if ([width, height].includes(Infinity)) {
            return null;
        }
        return { width, height };
    };

class OffScreenRender extends React.Component<any> {
    contentRef: ReactElementRef;
    dimensions: { width: number, height: number } | null;
    resizeObserver: ResizeObserver;

    constructor(props) {
        super(props);
        this.contentRef = React.createRef();
        this.dimensions = null;
        this.resizeObserver = new ResizeObserver(this.updateComponentExtensionData.bind(this));
    }

    updateComponentExtensionData() {
        const { current } = this.contentRef,
            dimensions = calculateDimensionsOfElements(current ? current.children : []),
            { componentId } = this.props;
        if (
            dimensions !== null &&
            (
                !this.dimensions ||
                this.dimensions.width !== dimensions.width ||
                this.dimensions.height !== dimensions.height
            )
        ) {
            this.dimensions = dimensions;
            updateComponentsMapExtension(componentId, { maxDimensions: dimensions });
        }
    }

    componentDidMount() {
        this.updateComponentExtensionData();
        const { current } = this.contentRef,
            children: Array<Element> = current ? Array.from(current.children) : [];
        children.forEach(ele => { this.resizeObserver.observe(ele); });
    }

    componentWillUnmount() {
        this.resizeObserver.disconnect();
    }

    render() {
        const { maxWidth = 10000 } = (this.props.mhfCmpData || {}),
            style = {
                width: maxWidth
            },
            childView = this.props.childElement(this.contentRef);
        return (
            <div className={styles.offScreen}>
                <div className={styles.initialDimensions} style={style}>
                    {childView}
                </div>
            </div>
        );
    }
}

export const textLikeWorkspaceConfigFactory = ({
    View,
    label,
    kind,
    reducer,
    dependsOn,
    pp,
    mcta,
    isValueSet,
    getPrefixInitialValue,
    computeComponentDependenciesForPreview,
    epicUpdaters,
    defaultLineSpacing,
    defaultWidth = 330,
    defaultHeight = 100,
    sequenceNumberUnderMore,
    getStateAdjustmentsOnComponentAdd,
    getPlaceholderText
}: WorkspaceConfigProps<any, any>) => {
    const TEXT_LIKE_PP_ADD_OR_UPDATE_VALUE_BTN_PRESSED = 'TEXT_LIKE_PP_ADD_OR_UPDATE_VALUE_BTN_PRESSED_' + kind;
    const PagesIds = {
        MAIN: 'MAIN',
        STYLE: 'STYLE',
    };
    const ppPageTitles = {
        [PagesIds.MAIN]: ComponentNames[kind],
        [PagesIds.STYLE]: 'msg: common.Style {Style}',
        [mobileView.id]: mobileView.title
    };

    const defaultState: DefaultComponentState<any> = getTextLikeDefaultState({ kind, defaultWidth, defaultHeight, reducer });

    const { previewConfig } = textLikeComponentFactory({
        View,
        kind,
        computeComponentDependenciesForPreview,
        epicUpdaters,
        getPlaceholderText
    });

    const hook = ({ component, componentExtension, adjustmentData, mhfCmpsData }: HookProps) => {
        const
            { id, kind } = component,
            { minDimensions } = adjustmentData || { minDimensions: undefined },
            minHeightInAdjustmentData = minDimensions ? minDimensions.height : 1;

        const minHeightInExtension = R.path(['minDimensions', 'height'], componentExtension);
        const minWidthInExtension = R.path(['minDimensions', 'width'], componentExtension);
        let minWidth = 100;

        if (component.generic.showIcon && component.generic.horizontalAlignment !== 'center') {
            minWidth += component.generic.iconSize + component.generic.iconTextSpacing;
        }

        const iconSize = component.generic.showIcon ? component.generic.iconSize : 0;
        const iconTextSpacing = component.generic.showIcon ? component.generic.iconTextSpacing : 0;
        const minHeight = Math.max((component.generic.horizontalAlignment === 'center' ? iconSize + iconTextSpacing + minHeightInAdjustmentData : iconSize) || 1, minHeightInAdjustmentData);

        let updatedComponentExtension = componentExtension;

        if (minHeightInExtension !== minHeight || minWidth !== minWidthInExtension) {
            const isMHFComponent = mhfCmpsData[id],
                isEmailOrPhone = [EMAIL_KIND, PHONE_KIND].includes(kind),
                { maxDimensions: { width: maxWidth = minWidth } = {} } = componentExtension || {};
            updatedComponentExtension = {
                ...componentExtension,
                minDimensions: {
                    height: R.max(1, minHeightInAdjustmentData),
                    width: isMHFComponent && isEmailOrPhone ? maxWidth : minWidth,
                }
            };
        }

        let nextComponent = component;

        if (nextComponent.height < minHeight) {
            nextComponent = { ...nextComponent, height: minHeight };
        }
        if (mhfCmpsData[id]) {
            const { maxDimensions } = componentExtension || {},
                { maxWidth } = mhfCmpsData[id];
            if (maxDimensions && !isNaN(maxWidth)) {
                nextComponent = { ...nextComponent, width: Math.min(maxDimensions.width, maxWidth) };
            }
        }

        return [
            nextComponent,
            updatedComponentExtension
        ];
    };

    const workspace = (props: CalcRenderPropsResult<any, Record<any, ExtractReturnType>>) => {
        return (
            <BaseComponent {...props}>
                <ComponentMask maskStyle={ComponentMaskBackground.LIGHT}>
                    {
                        props.isWorkspace && props.mhfCmpData &&
                        <OffScreenRender
                            {...props}
                            // @ts-ignore
                            childElement={(offScreenRef) => <View {...props} offScreenRef={offScreenRef} />}
                        />
                    }
                    {/* @ts-ignore */}
                    <View {...props} />
                </ComponentMask>
            </BaseComponent>
        );
    };

    return {
        config: {
            view: View,
            workspace,
            kind,
            label,
            shortcutIconName: `${kind.toLowerCase()}_inserter_icon`,
            shouldBeAvailableUnderMore: true,
            sequenceNumberUnderMore,
            calcRenderProps: textLikeCalcRenderProps(getPlaceholderText),
            dependsOn: {
                stylesheetsIdToNameMap: stylesheetsIdToNameMapEpic.reducer,
                globalVariables: globalVariablesEpic.reducer,
                stylesheets: stylesheetsEpic.reducer,
                themeSettingsData: colorThemeSiteSettingsEpic.reducer,
                isModernLayoutActivated: isModernLayoutActivatedEpic.reducer,
                ...dependsOn
            },
            adjustComponentOnAdd: (
                defaultState: ComponentState<any>,
                deps: any,
            ): ComponentState<any> => {
                const textNormalFontSize = selectors.textNormalGlobalstyle(deps.stylesheets).size;
                return ({
                    ...defaultState,
                    generic: {
                        ...defaultState.generic,
                        iconSize: R.path(['generic', 'iconSize'], defaultState) || fontSizeToDefaultIconSize(textNormalFontSize),
                        iconTextSpacing: fontSizeToDefaultIconSpacing(textNormalFontSize),
                        textStyle: { ...defaultState.generic.textStyle },
                        customPrefixText: getPrefixInitialValue(),
                    },
                    specific: {
                        ...defaultState.specific,
                        ...getStateAdjustmentsOnComponentAdd()
                    }
                });
            },
            controlsDependsOn: {
                siteFonts: siteFontsReducer,
            },
            componentMainActions: {
                editButtonText: 'msg: common.edit {Edit}',
                mobileViewEditorV: (props: {
                    dispatch: Dispatch,
                    selectedComponent: ComponentState<any>,
                    mobileMCTA: { availableSpace: { top: number, bottom: number } },
                    stylesheets: Object,
                    isModernLayoutActivated: boolean,
                    isInsideHeaderOrFooter: boolean,
                }) => {
                    const {
                            dispatch,
                            selectedComponent: {
                                generic: { horizontalAlignment, mobileHorizontalAlignment, mobileFontSize, mobileIconSize, showIcon, iconSize, textStyle: { fontSize } },
                                id: cmpId,
                                modernLayoutOptions
                            },
                            mobileMCTA,
                            stylesheets,
                            isModernLayoutActivated,
                            isInsideHeaderOrFooter
                        } = props,
                        textNormalStyle = selectors.textNormalGlobalstyle(stylesheets),
                        { availableSpace } = mobileMCTA,
                        renderFontSize = mobileFontSize || capTextFontSize(fontSize || textNormalStyle.size),
                        renderIconSize = mobileIconSize || iconSize,
                        canIncrease = renderFontSize < MAX_FONT_SIZE,
                        canDecrease = renderFontSize > MIN_FONT_SIZE,
                        canIncreaseSVG = renderIconSize < MAX_ICON_SIZE,
                        canDecreaseSVG = renderIconSize > MIN_ICON_SIZE;

                    return <div>
                        {isModernLayoutActivated && isInsideHeaderOrFooter ?
                            <React.Fragment>
                                <MctaPropertyContainer label="msg: component.menu.propPanel.aligmentLabel {Alignment:}">
                                    <StatefulAlignmentControls
                                        actionTypeToDispatch={TEXT_LIKE_MOBILE_TEXT_ALIGNMENT_CHANGED}
                                        selectedCmpId={cmpId}
                                        availableSpace={availableSpace}
                                        alignment={mobileHorizontalAlignment || horizontalAlignment}
                                        dispatch={dispatch}
                                        kind={TextLike}
                                    />
                                </MctaPropertyContainer>
                                <HorizontalSpacer x={5} />
                                <MctaVerticalBar />
                                <HorizontalSpacer x={5} />
                                <StatefulAlignmentControls
                                    label="msg: common.iconSizeColon {Icon size:}"
                                    actionTypeToDispatch={TEXT_LIKE_MOBILE_MCTA_CHANGE_SIZE}
                                    size={modernLayoutOptions && modernLayoutOptions.mobileSize}
                                    dispatch={dispatch}
                                    kind={TextLike}
                                />
                            </React.Fragment>
                            : <React.Fragment>
                                <StatefulAlignmentControls
                                    actionTypeToDispatch={TEXT_LIKE_MOBILE_TEXT_ALIGNMENT_CHANGED}
                                    selectedCmpId={cmpId}
                                    availableSpace={availableSpace}
                                    alignment={mobileHorizontalAlignment || horizontalAlignment}
                                    dispatch={dispatch}
                                    kind={TextLike}
                                />
                                <HorizontalSpacer x={5} />
                                <MctaVerticalBar />
                                <HorizontalSpacer x={5} />
                                <MctaButton
                                    btnClass={canDecrease ? '' : mctaStyles.disabled}
                                    title={canDecrease ? titleTextSmaller : titleTextSmallerErr}
                                    isErrorTitle={!canDecrease || undefined}
                                    icon="FONT_SIZE_DECREASE"
                                    onClick={(() => canDecrease && dispatch({ type: TEXT_LIKE_MOBILE_DEC_FONT_BTN_PRESSED, payload: renderFontSize - 1 }))}
                                />
                                <HorizontalSpacer x={5} />
                                <MctaVerticalBar />
                                <HorizontalSpacer x={5} />
                                <MctaButton
                                    btnClass={canIncrease ? '' : mctaStyles.disabled}
                                    title={canIncrease ? titleTextBigger : titleTextBiggerErr}
                                    isErrorTitle={!canIncrease || undefined}
                                    icon="FONT_SIZE_INCREASE"
                                    onClick={(() => canIncrease && dispatch({ type: TEXT_LIKE_MOBILE_INC_FONT_BTN_PRESSED, payload: renderFontSize + 1 }))}
                                />
                                <HorizontalSpacer x={5} />
                                <MctaVerticalBar />
                                <HorizontalSpacer x={5} />
                                {showIcon && <React.Fragment>
                                    <MctaButton
                                        btnClass={cx({ [mctaStyles.disabled]: !canDecreaseSVG })}
                                        title={canDecreaseSVG ? titleImageSmaller : titleImageSmallerErr}
                                        isErrorTitle={!canDecreaseSVG}
                                        icon={canDecreaseSVG ? "GROUP_IMAGE_SMALLER" : "GROUP_IMAGE_SMALLER_DISABLED"}
                                        onClick={canDecreaseSVG ? () => dispatch({ type: TEXT_LIKE_MOBILE_DEC_SVG_SIZE_BTN_PRESSED, payload: renderIconSize - 1 }) : null}
                                    />
                                    <HorizontalSpacer x={5} />
                                    <MctaVerticalBar />
                                    <HorizontalSpacer x={5} />
                                    <MctaButton
                                        btnClass={cx({ [mctaStyles.disabled]: !canIncreaseSVG })}
                                        title={canIncreaseSVG ? titleImageBigger : titleImageBiggerErr}
                                        isErrorTitle={!canIncreaseSVG}
                                        icon={canIncreaseSVG ? "GROUP_IMAGE_BIGGER" : "GROUP_IMAGE_BIGGER_DISABLED"}
                                        onClick={canIncreaseSVG ? () => dispatch({ type: TEXT_LIKE_MOBILE_DEC_SVG_SIZE_BTN_PRESSED, payload: renderIconSize + 1 }) : null}
                                    />
                                    <HorizontalSpacer x={5} />
                                    <MctaVerticalBar />
                                    <HorizontalSpacer x={5} />
                                </React.Fragment>}
                                <MctaButton
                                    title={titleClearFormatting}
                                    icon="CLEARFORMATING"
                                    onClick={() => dispatch({ type: TEXT_LIKE_MOBILE_CLEAR_FORMATTING })}
                                />
                            </React.Fragment>}
                    </div>;
                },
                editView: injectIntl(({
                    intl,
                    dispatch,
                    selectedComponent,
                    stylesheets,
                    selectedComponentProps,
                    themeSettingsData,
                    children,
                    isModernLayoutActivated,
                    isInsideHeaderOrFooter
                }) => {
                    const textColorTitle = intl.msgJoint("msg: mcta.textColor {Text colour}");
                    const textNormalStyle = selectors.textNormalGlobalstyle(stylesheets);
                    const themeColorsData = getThemeColorsFromStylesheet(stylesheets);

                    return <div>
                        {isModernLayoutActivated && isInsideHeaderOrFooter ?
                            <React.Fragment>
                                <HorizontalSpacer x={5} />
                                <MctaPropertyContainer label="msg: component.menu.propPanel.aligmentLabel {Alignment:}">
                                    <StatefulAlignmentControls
                                        actionTypeToDispatch={TEXT_LIKE_HORIZONTAL_ALIGNMENT_CHANGED}
                                        alignment={selectedComponent.generic.horizontalAlignment}
                                        dispatch={dispatch}
                                        kind={TextLike}
                                    />
                                </MctaPropertyContainer>
                                <HorizontalSpacer x={5} />
                                <MctaVerticalBar />
                                <HorizontalSpacer x={5} />
                                <StatefulAlignmentControls
                                    label="msg: common.iconSizeColon {Icon size:}"
                                    actionTypeToDispatch={TEXT_LIKE_MCTA_CHANGE_SIZE}
                                    size={selectedComponent.modernLayoutOptions && selectedComponent.modernLayoutOptions.size}
                                    dispatch={dispatch}
                                    kind={TextLike}
                                />
                                <HorizontalSpacer x={5} />
                            </React.Fragment>
                            : <React.Fragment>
                                <div>
                                    <MctaPropertyContainer label="msg: common.fontSize {Font size}" style={{ marginLeft: 5 }}>
                                        <FontSizeSelector
                                            onChangeAction={TEXT_LIKE_FONT_SIZE_CHANGED}
                                            selected={selectedComponent.generic.textStyle.fontSize || textNormalStyle.size}
                                            dispatch={dispatch}
                                            searchable={false}
                                            menuStyle={DEFAULT_MCTA_COMBOBOX_MENUSTYLE}
                                            mcta
                                        />
                                    </MctaPropertyContainer>
                                </div>
                                <HorizontalSpacer x={5} />
                                <MctaVerticalBar />
                                <HorizontalSpacer x={5} />
                                <MctaPropertyContainer label="msg: common.color {Colour}">
                                    {
                                        themeSettingsData.autoColorMode
                                            ? <ColorProperty
                                                colorPickerKind={THEME_COLOR_PICKER}
                                                themeColorsData={themeColorsData}
                                                themeColor={selectedComponent.generic.themeOverrideColor}
                                                defaultThemeColor={findSuitableTextColorName(
                                                    getThemeRulesForBackground(selectedComponentProps.selectedParentTheme, themeColorsData).background,
                                                    themeColorsData
                                                )}
                                                onChangeAction={TEXT_LIKE_COLOR_CHANGED_AUTO_COLOR}
                                                dispatch={dispatch}
                                                title={textColorTitle}
                                                colorBoxStyle={DEFAULT_TEXT_TOOLBAR_COLOR_BOX_STYLE}
                                                showCaret
                                            />
                                            : <ColorProperty
                                                colorPickerKind={DEFAULT_COLOR_PICKER}
                                                dispatch={dispatch}
                                                onChangeAction={TEXT_LIKE_COLOR_CHANGED}
                                                onTheFlyChange
                                                color={selectedComponent.generic.textStyle.color || textNormalStyle.color}
                                                title={textColorTitle}
                                                colorBoxStyle={DEFAULT_TEXT_TOOLBAR_COLOR_BOX_STYLE}
                                                showCaret
                                            />
                                    }
                                </MctaPropertyContainer>
                                <HorizontalSpacer x={5} />
                                <MctaVerticalBar />
                                <HorizontalSpacer x={5} />
                                <StatefulAlignmentControls
                                    actionTypeToDispatch={TEXT_LIKE_HORIZONTAL_ALIGNMENT_CHANGED}
                                    alignment={selectedComponent.generic.horizontalAlignment}
                                    dispatch={dispatch}
                                    kind={TextLike}
                                />
                                <HorizontalSpacer x={5} />
                                <MctaVerticalBar />
                                <HorizontalSpacer x={5} />
                                <MctaButton
                                    title={titleClearFormatting}
                                    icon="REMOVE_FORMAT"
                                    onClick={() => dispatch({
                                        type: TEXT_LIKE_CLEAR_FORMATTING,
                                        payload: { textNormalFontSize: textNormalStyle.size }
                                    })}
                                />
                                {children && <HorizontalSpacer x={5} />}
                                {children}
                            </React.Fragment>}
                    </div>;
                }),
                CtaButtonTextView: injectIntl(props => {
                    return <span className={isValueSet(props) ? null : styles.noValueSet}>
                        {props.intl.msgJoint(isValueSet(props) ? mcta.changeValueLabel : mcta.addValueLabel)}
                    </span>;
                }),
                ctaOnClick: ({ dispatch }: { dispatch: Dispatch }) =>
                    dispatch({ type: TEXT_LIKE_PP_ADD_OR_UPDATE_VALUE_BTN_PRESSED })
            },
            propertiesPanel: {
                pages: makePages(
                    {
                        id: PagesIds.MAIN,
                        view: injectIntl((props) => {
                            const { navigateToPage, intl, dispatch } = props;

                            const targetPagesIds = [PagesIds.STYLE, mobileView.id];

                            const makeBtn = (text) => (
                                <PropertiesButton
                                    text={text}
                                    onClick={() => props.dispatch({ type: TEXT_LIKE_PP_ADD_OR_UPDATE_VALUE_BTN_PRESSED })}
                                />
                            );

                            if (isValueSet(props)) {
                                return (
                                    <Page>
                                        <VerticalSpacer y={15} />
                                        <div className={styles.mainPageIntroText}>{props.intl.msgJoint(pp.mainPageIntroText)}</div>
                                        <VerticalSpacer y={18.5} />
                                        {makeBtn(pp.updateValueBtnLabel)}
                                        <VerticalSpacer y={5} />
                                        {
                                            pp.mainPageRender({
                                                intl,
                                                dispatch,
                                                component: props.selectedComponent,
                                                deps: props,
                                                TEXT_LIKE_SHOW_CUSTOM_TITLE_FIRST_TOGGLE,
                                                TEXT_LIKE_CUSTOM_PREFIX_TEXT_CHANGED,
                                                TEXT_LIKE_SHOW_ON_ONE_LINE_TOGGLE
                                            })
                                        }
                                        <PropertiesCheckBox
                                            containerClassName={styles.checkboxOverride}
                                            isChecked={props.selectedComponent.generic.showIcon}
                                            label="msg: common.showIcon {Show icon}"
                                            onClick={() => props.dispatch({ type: TEXT_LIKE_SHOW_ICON_TOGGLE })}
                                        />
                                        <VerticalSpacer y={20} />
                                        <NavigationGroup
                                            navigateToPage={navigateToPage}
                                            pagesTitles={ppPageTitles}
                                            targetPagesIds={targetPagesIds}
                                        />
                                    </Page>
                                );
                            }
                            return (
                                <Page>
                                    <VerticalSpacer y={15} />
                                    <div className={styles.mainPageIntroText}>{props.intl.msgJoint(pp.mainPageIntroText)}</div>
                                    <VerticalSpacer y={18.5} />
                                    {makeBtn(pp.addValueBtnLabel)}
                                    <VerticalSpacer y={21.5} />
                                    <div className={styles.mainPageFooterText}>
                                        <div>
                                            <VerticalSpacer y={4} />
                                            <Icons.HINT_LARGE style={{ height: 22, width: 22, fill: '#262626' }} />
                                        </div>
                                        <HorizontalSpacer x={12} />
                                        {props.intl.msgJoint(pp.mainPageFooterText)}
                                    </div>
                                </Page>
                            );
                        }),
                        title: ComponentNames[kind]
                    },
                    {
                        id: PagesIds.STYLE,
                        view: injectIntl((props) => {
                            const { dispatch, selectedComponent, siteFonts, navigateToPage, stylesheets } = props;

                            const textNormalStyle = selectors.textNormalGlobalstyle(stylesheets);
                            const lineHeight = selectedComponent.generic.textStyle.lineHeight === null ? (defaultLineSpacing || DEFAULT_LINE_HEIGHT) : selectedComponent.generic.textStyle.lineHeight;
                            const makeCaseBtns = ({ lowercase, uppercase }) => (
                                <ChangeLetterCaseButtonGroup lowercase={lowercase} uppercase={uppercase} dispatch={dispatch} />
                            );

                            const prefixBold = props.selectedComponent.generic.textStyle.prefixBold === null ? textNormalStyle.bold : props.selectedComponent.generic.textStyle.prefixBold;
                            const prefixItalic = props.selectedComponent.generic.textStyle.prefixItalic === null ? textNormalStyle.italic : props.selectedComponent.generic.textStyle.prefixItalic;
                            const prefixUnderline = props.selectedComponent.generic.textStyle.prefixUnderline === null ? textNormalStyle.underline : props.selectedComponent.generic.textStyle.prefixUnderline;
                            const bold = props.selectedComponent.generic.textStyle.bold === null ? textNormalStyle.bold : props.selectedComponent.generic.textStyle.bold;
                            const italic = props.selectedComponent.generic.textStyle.italic === null ? textNormalStyle.italic : props.selectedComponent.generic.textStyle.italic;
                            const underline = props.selectedComponent.generic.textStyle.underline === null ? textNormalStyle.underline : props.selectedComponent.generic.textStyle.underline;

                            return (
                                <Page>
                                    <VerticalSpacer y={14} />
                                    <PropertiesCheckBox
                                        containerClassName={styles.checkboxOverride}
                                        isChecked={props.selectedComponent.generic.showIcon}
                                        label="msg: common.showIcon {Show icon}"
                                        onClick={() => props.dispatch({ type: TEXT_LIKE_SHOW_ICON_TOGGLE })}
                                    />
                                    {
                                        props.selectedComponent.generic.showIcon &&
                                        <React.Fragment>
                                            <VerticalSpacer y={14} />
                                            <PPSlider
                                                label="msg: common.iconSize {Icon size}"
                                                valueLabel={`${selectedComponent.generic.iconSize}px`}
                                                value={selectedComponent.generic.iconSize}
                                                min={MIN_ICON_SIZE}
                                                max={MAX_ICON_SIZE}
                                                step={1}
                                                onChange={iconSize => dispatch({
                                                    type: TEXT_LIKE_ICON_SIZE_CHANGED,
                                                    payload: iconSize,
                                                    amendToSelf: true
                                                })}
                                            />
                                            <VerticalSpacer y={14} />
                                            <PPSlider
                                                label="msg: common.iconSpacing {Icon spacing}"
                                                valueLabel={`${selectedComponent.generic.iconTextSpacing}px`}
                                                value={selectedComponent.generic.iconTextSpacing}
                                                min={MIN_ICON_SPACING}
                                                max={MAX_ICON_SPACING}
                                                step={1}
                                                onChange={iconTextSpacing => dispatch({
                                                    type: TEXT_LIKE_ICON_TEXT_SPACING_CHANGED,
                                                    payload: iconTextSpacing,
                                                    amendToSelf: true
                                                })}
                                            />
                                        </React.Fragment>
                                    }
                                    <Separator />
                                    <PropertyContainer label="msg: common.font {Font}">
                                        <FontFamilySelector
                                            selected={selectedComponent.generic.textStyle.fontFamily || textNormalStyle.font}
                                            onChangeAction={TEXT_LIKE_FONT_FAMILY_CHANGED}
                                            siteFonts={siteFonts}
                                            onAddFontClicked={() => navigateToPage(addGoogleFontView.id, { source: TextLike })}
                                            isPropertiesPanel
                                            forwardToSelectedComponent
                                        />
                                    </PropertyContainer>
                                    <VerticalSpacer y={19} />
                                    <PPSlider
                                        label="msg: common.characterSpacing {Character spacing}"
                                        min={MIN_CHARACTER_SPACING}
                                        max={MAX_CHARACTER_SPACING}
                                        value={selectedComponent.generic.textStyle.letterSpacing || 0}
                                        valueLabel={`${selectedComponent.generic.textStyle.letterSpacing || 0}`}
                                        onChange={spacing => dispatch({ type: TEXT_LIKE_CHARACTER_SPACING_CHANGED, payload: spacing, amendToSelf: true })}
                                    />
                                    {
                                        (pp.showLineSpacingOptionForSingleLineMode || !selectedComponent.generic.showOnOneLine) &&
                                        <React.Fragment>
                                            <VerticalSpacer y={14} />
                                            <PPSlider
                                                label="msg: common.lineSpacing {Line spacing}"
                                                valueLabel={`${lineHeight}`}
                                                value={lineHeight}
                                                min={0}
                                                max={2.4}
                                                step={0.1}
                                                onChange={spacing => dispatch({
                                                    type: TEXT_LIKE_LINE_SPACING_CHANGED,
                                                    payload: spacing,
                                                    amendToSelf: true
                                                })}
                                            />
                                        </React.Fragment>
                                    }
                                    <Separator />
                                    {
                                        selectedComponent.generic.showCustomTitleFirst &&
                                        <React.Fragment>
                                            <PropertyContainer label="msg: textlike.customTitle {Custom title}" vsHeight={6}>
                                                <Flex align="center" justify="flex-start">
                                                    <TextFormatButtonGroup
                                                        bold={{ isSelected: prefixBold,
                                                            onClickAction: TEXT_LIKE_PREFIX_BOLD_CHANGED }}
                                                        italic={{ isSelected: prefixItalic, onClickAction: TEXT_LIKE_PREFIX_ITALIC_CHANGED }}
                                                        underline={{ isSelected: prefixUnderline,
                                                            onClickAction: TEXT_LIKE_PREFIX_UNDERLINE_CHANGED }}
                                                        dispatch={dispatch}
                                                    />
                                                    <HorizontalSpacer x={18} />
                                                    {makeCaseBtns({
                                                        lowercase: {
                                                            isSelected: selectedComponent.generic.textStyle.prefixCase === 'lowercase',
                                                            onClickAction: TEXT_LIKE_PP_PREFIX_LOWERCASE_BTN_CLICKED,
                                                        },
                                                        uppercase: {
                                                            isSelected: selectedComponent.generic.textStyle.prefixCase === 'uppercase',
                                                            onClickAction: TEXT_LIKE_PP_PREFIX_ALL_UPPERCASE_BTN_CLICKED,
                                                        },
                                                    })}
                                                </Flex>
                                            </PropertyContainer>
                                            <VerticalSpacer y={12} />
                                        </React.Fragment>
                                    }
                                    <PropertyContainer label={pp.valueStyleLabel} vsHeight={6}>
                                        <Flex align="center" justify="flex-start">
                                            <TextFormatButtonGroup
                                                bold={{ isSelected: bold,
                                                    onClickAction: TEXT_LIKE_VALUE_BOLD_CHANGED }}
                                                italic={{ isSelected: italic, onClickAction: TEXT_LIKE_VALUE_ITALIC_CHANGED }}
                                                underline={{ isSelected: underline,
                                                    onClickAction: TEXT_LIKE_VALUE_UNDERLINE_CHANGED }}
                                                dispatch={dispatch}
                                            />
                                            {!pp.hideCapitalizationOptionForValue && <React.Fragment>
                                                <HorizontalSpacer x={18} />
                                                {makeCaseBtns({
                                                    lowercase: {
                                                        isSelected: selectedComponent.generic.textStyle.valueCase === 'lowercase',
                                                        onClickAction: TEXT_LIKE_PP_VALUE_LOWERCASE_BTN_CLICKED,
                                                    },
                                                    uppercase: {
                                                        isSelected: selectedComponent.generic.textStyle.valueCase === 'uppercase',
                                                        onClickAction: TEXT_LIKE_PP_VALUE_ALL_UPPERCASE_BTN_CLICKED,
                                                    },
                                                })}
                                            </React.Fragment>}
                                        </Flex>
                                    </PropertyContainer>
                                </Page>
                            );
                        }),
                        title: ppPageTitles[PagesIds.STYLE]
                    },
                    mobileView,
                    addGoogleFontView
                ),
                defaultPageId: PagesIds.MAIN
            },
            reducer: (state: ComponentState<any> = defaultState, action: AnyAction) => {
                let newState = state;
                let generic = state.generic;

                if (action.type === TEXT_LIKE_SHOW_CUSTOM_TITLE_FIRST_TOGGLE) {
                    generic = toggleGenericProp('showCustomTitleFirst', generic);
                }
                if (action.type === TEXT_LIKE_SHOW_ON_ONE_LINE_TOGGLE) {
                    generic = toggleGenericProp('showOnOneLine', generic);
                }
                if (action.type === TEXT_LIKE_CUSTOM_PREFIX_TEXT_CHANGED) {
                    generic = { ...generic, customPrefixText: action.payload };
                }

                if (action.type === TEXT_LIKE_VALUE_BOLD_CHANGED) {
                    generic = { ...generic, textStyle: { ...generic.textStyle, bold: action.payload } };
                }
                if (action.type === TEXT_LIKE_VALUE_ITALIC_CHANGED) {
                    generic = { ...generic, textStyle: { ...generic.textStyle, italic: action.payload } };
                }
                if (action.type === TEXT_LIKE_VALUE_UNDERLINE_CHANGED) {
                    generic = { ...generic, textStyle: { ...generic.textStyle, underline: action.payload } };
                }
                if (action.type === TEXT_LIKE_PREFIX_BOLD_CHANGED) {
                    generic = { ...generic, textStyle: { ...generic.textStyle, prefixBold: action.payload } };
                }
                if (action.type === TEXT_LIKE_PREFIX_ITALIC_CHANGED) {
                    generic = { ...generic, textStyle: { ...generic.textStyle, prefixItalic: action.payload } };
                }
                if (action.type === TEXT_LIKE_PREFIX_UNDERLINE_CHANGED) {
                    generic = { ...generic, textStyle: { ...generic.textStyle, prefixUnderline: action.payload } };
                }
                if (action.type === TEXT_LIKE_FONT_SIZE_CHANGED) {
                    generic = { ...generic, textStyle: { ...generic.textStyle, fontSize: action.payload } };
                }
                if (action.type === TEXT_LIKE_COLOR_CHANGED) {
                    generic = { ...generic, textStyle: { ...generic.textStyle, color: action.payload.color } };
                }
                if (action.type === TEXT_LIKE_COLOR_CHANGED_AUTO_COLOR) {
                    generic = { ...generic, themeOverrideColor: action.payload.themeColor };
                }
                if (action.type === TEXT_LIKE_COLOR_REMOVED_AUTO_COLOR) {
                    generic = { ...generic, themeOverrideColor: null };
                }
                if (action.type === TEXT_LIKE_FONT_FAMILY_CHANGED) {
                    generic = { ...generic, textStyle: { ...generic.textStyle, fontFamily: action.payload } };
                }
                if (action.type === TEXT_LIKE_CHARACTER_SPACING_CHANGED) {
                    generic = { ...generic, textStyle: { ...generic.textStyle, letterSpacing: action.payload } };
                }
                if (action.type === TEXT_LIKE_LINE_SPACING_CHANGED) {
                    generic = { ...generic, textStyle: { ...generic.textStyle, lineHeight: action.payload } };
                }
                if (action.type === TEXT_LIKE_PP_VALUE_ALL_UPPERCASE_BTN_CLICKED) {
                    generic = { ...generic, textStyle: { ...generic.textStyle, valueCase: generic.textStyle.valueCase === 'uppercase' ? null : 'uppercase' } };
                }
                if (action.type === TEXT_LIKE_PP_VALUE_LOWERCASE_BTN_CLICKED) {
                    generic = { ...generic, textStyle: { ...generic.textStyle, valueCase: generic.textStyle.valueCase === 'lowercase' ? null : 'lowercase' } };
                }
                if (action.type === TEXT_LIKE_PP_PREFIX_ALL_UPPERCASE_BTN_CLICKED) {
                    generic = { ...generic, textStyle: { ...generic.textStyle, prefixCase: generic.textStyle.prefixCase === 'uppercase' ? null : 'uppercase' } };
                }
                if (action.type === TEXT_LIKE_PP_PREFIX_LOWERCASE_BTN_CLICKED) {
                    generic = { ...generic, textStyle: { ...generic.textStyle, prefixCase: generic.textStyle.prefixCase === 'lowercase' ? null : 'lowercase' } };
                }
                if (action.type === TEXT_LIKE_HORIZONTAL_ALIGNMENT_CHANGED) {
                    generic = { ...generic, horizontalAlignment: action.payload };
                }
                if (action.type === TEXT_LIKE_SHOW_ICON_TOGGLE) {
                    generic = { ...generic, showIcon: !generic.showIcon };
                }
                const propToUpdateWhenIconSettingsChanges = generic.horizontalAlignment === 'center' ? 'height' : 'width';
                if (action.type === TEXT_LIKE_ICON_SIZE_CHANGED) {
                    newState = { ...newState, [propToUpdateWhenIconSettingsChanges]: newState[propToUpdateWhenIconSettingsChanges] - (generic.iconSize - action.payload) };
                    generic = { ...generic, iconSize: action.payload };
                }
                if (action.type === TEXT_LIKE_ICON_TEXT_SPACING_CHANGED) {
                    newState = { ...newState, [propToUpdateWhenIconSettingsChanges]: newState[propToUpdateWhenIconSettingsChanges] - (generic.iconTextSpacing - action.payload) };
                    generic = { ...generic, iconTextSpacing: action.payload };
                }
                if (action.type === TEXT_LIKE_CLEAR_FORMATTING) {
                    generic = {
                        ...generic,
                        horizontalAlignment: defaultState.generic.horizontalAlignment,
                        textStyle: {
                            ...generic.textStyle,
                            bold: defaultState.generic.textStyle.bold,
                            italic: defaultState.generic.textStyle.italic,
                            underline: defaultState.generic.textStyle.underline,
                            prefixBold: defaultState.generic.textStyle.prefixBold,
                            prefixItalic: defaultState.generic.textStyle.prefixItalic,
                            prefixUnderline: defaultState.generic.textStyle.prefixUnderline,
                            fontSize: defaultState.generic.textStyle.fontSize,
                            color: defaultState.generic.textStyle.color,
                            fontFamily: defaultState.generic.textStyle.fontFamily,
                            letterSpacing: defaultState.generic.textStyle.letterSpacing,
                            lineHeight: defaultState.generic.textStyle.lineHeight,
                            prefixCase: defaultState.generic.textStyle.prefixCase,
                            valueCase: defaultState.generic.textStyle.valueCase,
                        },
                        iconTextSpacing: fontSizeToDefaultIconSpacing(action.payload.textNormalFontSize),
                        iconSize: fontSizeToDefaultIconSize(action.payload.textNormalFontSize),
                    };
                }

                if (action.type === TEXT_LIKE_MOBILE_INC_FONT_BTN_PRESSED) {
                    generic = { ...generic, mobileFontSize: action.payload };
                }
                if (action.type === TEXT_LIKE_MOBILE_DEC_FONT_BTN_PRESSED) {
                    generic = { ...generic, mobileFontSize: action.payload };
                }
                if (action.type === TEXT_LIKE_MOBILE_DEC_SVG_SIZE_BTN_PRESSED) {
                    generic = { ...generic, mobileIconSize: action.payload };
                }
                if (action.type === TEXT_LIKE_MOBILE_INC_SVG_SIZE_BTN_PRESSED) {
                    generic = { ...generic, mobileIconSize: action.payload };
                }
                if (action.type === TEXT_LIKE_MOBILE_TEXT_ALIGNMENT_CHANGED) {
                    generic = { ...generic, mobileHorizontalAlignment: action.payload.value };
                }
                if (action.type === TEXT_LIKE_MOBILE_CLEAR_FORMATTING) {
                    generic = {
                        ...generic,
                        mobileFontSize: defaultState.generic.mobileFontSize,
                        mobileIconSize: defaultState.generic.mobileIconSize,
                        mobileHorizontalAlignment: defaultState.generic.mobileHorizontalAlignment
                    };
                }

                if (action.type === TEXT_LIKE_MCTA_CHANGE_SIZE) {
                    const size = action.payload;
                    generic = { ...generic, iconSize: MCTA_RESIZE_OPTIONS[size].defaultSize };
                    newState = { ...newState, modernLayoutOptions: { ...state.modernLayoutOptions, size } };
                }

                if (action.type === TEXT_LIKE_MOBILE_MCTA_CHANGE_SIZE) {
                    const size = action.payload;
                    generic = { ...generic, mobileIconSize: MCTA_RESIZE_OPTIONS[size].mobileSize };
                    newState = { ...newState, modernLayoutOptions: { ...state.modernLayoutOptions, mobileSize: size } };
                }

                const specific = reducer(newState.specific, action);

                if (generic !== state.generic || specific !== state.specific) {
                    newState = { ...newState, generic, specific };
                }

                if (action.type === TOGGLE_MOBILE_DOWN_ACTION) {
                    newState = { ...newState, mobileDown: !state.mobileDown };
                }

                return newState;
            },
            showNewLabel: true,
            minDimensions: { width: 16, height: 16 },
            adjustmentHookConfig: {
                hook,
                shouldCallHook: (prev: any, { adjustmentData }: { adjustmentData: Object }) => !!adjustmentData
            },
            mobileEditorConfig: previewConfig,
            changeOldComponentDataBeforeImport: ({
                component,
                globalVariables
            }: ChangeOldComponentDataBeforeImportProps<ComponentState<any>>): ComponentState<any> => {
                return {
                    ...component,
                    generic: {
                        ...component.generic,
                        customPrefixText: replaceTagsWithContent(component.generic.customPrefixText, { globalVariables })
                    },
                    specific: {
                        ...component.specific,
                        ...getStateAdjustmentsOnComponentAdd()
                    }
                };
            },
        },
    };
};
