import getDefaultState from './getDefaultState';
import type { ContactFormElements, FormElement } from "./flowTypes";
import type { FormElementsUI } from "./view/flowTypes";

export const
    extractKey = (type: string): string => Object.keys(getDefaultState()).filter(key => type.indexOf(key) !== -1)[0],

    convertFieldForUI = (fieldObject: FormElement) => {
        let newField: FormElement & { displayName: string, displayErrorMessage: string, values? } = {
            ...fieldObject,
            displayName: fieldObject.name,
            displayErrorMessage: fieldObject.errorMessage,
            values: []
        };
        if (Array.isArray(fieldObject.values)) {
            newField.values = fieldObject.values.map(value => ({ value, displayValue: value }));
        }
        return newField;
    },

    addDisplayValues = (formElements: ContactFormElements) => {
        const keys = Object.keys(formElements);
        if (keys.length > 0) {
            let newFormElements = {};
            keys.forEach(key => {
                newFormElements[key] = convertFieldForUI(formElements[key]);
            });
            return newFormElements;
        }
        return formElements;
    },

    getValueObj = (valObj) => (typeof valObj === "string" ? valObj : valObj.value),

    removeDisplayValues = (formElements: FormElementsUI) => {
        const keys = Object.keys(formElements);
        if (keys.length > 0) {
            let newFormElements = {};
            keys.forEach(key => {
                newFormElements[key] = { ...formElements[key] };
                delete newFormElements[key].displayName;
                delete newFormElements[key].displayErrorMessage;
                if (Array.isArray(formElements[key].values)) {
                    newFormElements[key].values = formElements[key].values.map(getValueObj);
                }
            });
            return newFormElements;
        }
        return formElements;
    },

    replaceDisplayValues = (formElements: FormElementsUI, field: string) => {
        let newField = { ...formElements[field] };
        newField.displayName = newField.name;
        newField.displayErrorMessage = newField.errorMessage;
        return { ...formElements, [field]: newField };
    },

    isFormElementsChanged = (
        oldFormElements: ContactFormElements,
        newFormElements: FormElementsUI,
        oldFormElementsOrder: Array<string>,
        newFormElementsOrder: Array<string>
    ) => {
        let newValues,
            key,
            i,
            j,
            k,
            val,
            keys;
        if (oldFormElementsOrder.length !== newFormElementsOrder.length) {
            return true;
        }
        if (oldFormElementsOrder.length > 0) {
            for (i = 0; i < oldFormElementsOrder.length; i++) {
                key = oldFormElementsOrder[i];
                if (key !== newFormElementsOrder[i]) {
                    return true;
                }
                keys = Object.keys(oldFormElements[key]);
                for (j = 0; j < keys.length; j++) {
                    if (oldFormElements[key].hasOwnProperty(keys[j])) {
                        val = oldFormElements[key][keys[j]];
                        if (val && Array.isArray(val)) {
                            newValues = newFormElements[key].values;
                            if (newValues) {
                                if (val.length !== newValues.length) {
                                    return true;
                                }
                                for (k = 0; k < val.length; k++) {
                                    if (val[k] !== newValues[k].value) {
                                        return true;
                                    }
                                }
                            }
                        } else if (val !== newFormElements[key][keys[j]]) {
                            return true;
                        }
                    }
                }
            }
        }
        return false;
    };
