/* eslint-disable max-len */

import * as R from "ramda";
import type { AnyComponent } from "../../oneweb/flowTypes";
import type { TransformComponentType, ThemeBackgroundType } from "../flowTypes";
import { memoMax, memoMaxDynamicNumOfArgs, memoMaxFixedNumOfArgs } from "../../../../utils/memo";
import { MaxComponentsOnPage } from "../../../constants/app";
import getParentThemeComponent from "./getParentThemeComponent";
import { isBackgroundComponentKind } from "../../oneweb/isBackgroundComponentKind";
import type { NonBackgroundComponent } from "../../../redux/modules/children/workspace/flowTypes";
import type { BackgroundComponent } from "../../oneweb/Background/flowTypes";
import p from "../../../utils/pipePath";
import { styleBackgroundColor, styleBackgroundAsset, styleBackgroundOpacity } from "../../../mappers/path";
import { THRESHOLD_PARENT_OPACITY } from "../constants";

const
    transformNonBackgroundComponentDataForThemeMemoizedObj = memoMax(
        (id, kind, x1, x2, y1, y2, orderIndex, onHoverProp, onHoverShow) => ({ // NOSONAR
            id, kind, x1, x2, y1, y2, orderIndex, onHoverProp, onHoverShow
        }), MaxComponentsOnPage
    ),
    transformNonBackgroundComponentDataForTheme = memoMax((component: NonBackgroundComponent): TransformComponentType => {
        const { id, left, top, height, width, kind, orderIndex, onHover } = component;
        const
            onHoverProp = !!onHover,
            onHoverShow = !!(onHover && onHover.show);
        return transformNonBackgroundComponentDataForThemeMemoizedObj(
            id, kind, left, left + width, top, top + height, orderIndex, onHoverProp, onHoverShow
        );
    }, MaxComponentsOnPage * 3),
    transformNonBackgroundComponentsMapDataForTheme = memoMax(
        (componentsMap: { [k: string]: AnyComponent }): Array<TransformComponentType> =>
            R.values(componentsMap)
                .filter(({ kind }) => !isBackgroundComponentKind(kind))
                .map(transformNonBackgroundComponentDataForTheme),
        3
    ),
    transformBackgroundComponentDataForThemeMemoizedObj = memoMax(
        (id, kind, x1, x2, y1, y2, orderIndex, selectedTheme, onHoverProp, onHoverShow) => ({ // NOSONAR
            id, kind, x1, x2, y1, y2, orderIndex, selectedTheme, onHoverProp, onHoverShow
        }), MaxComponentsOnPage
    ),
    transformBackgroundComponentDataForTheme = memoMax((component: BackgroundComponent): TransformComponentType => {
        const { id, left, top, height, width, kind, orderIndex, selectedTheme, onHover } = component;
        const
            onHoverProp = !!onHover,
            onHoverShow = !!(onHover && onHover.show);
        return transformBackgroundComponentDataForThemeMemoizedObj(
            id, kind, left, left + width, top, top + height, orderIndex, selectedTheme, onHoverProp, onHoverShow
        );
    }, MaxComponentsOnPage * 3),
    transformBackgroundComponentsMapDataForTheme = memoMaxFixedNumOfArgs(
        (componentsMap: { [k: string]: AnyComponent }, threshold = THRESHOLD_PARENT_OPACITY): Array<TransformComponentType> =>
            R.values(componentsMap)
                .filter(({ kind }) => isBackgroundComponentKind(kind))
                /* Remove items whose opacity is less than or equal to 20. This fix can be removed when color merging logic is implemented.*/
                /* This will solve so many footer templates where transparent box is present. */
                /* Opacity is present inside color. Path is different for client and server data. */
                /* No background color should be treated same as opacity zero. (when image is not there or image opacity is less than threshold) */
                .filter((component) => {
                    const
                        backgroundColor = R.path(p(styleBackgroundColor))(component),
                        backgroundImage = R.path(p(styleBackgroundAsset))(component),
                        backgroundImageOpactiy = R.path(p(styleBackgroundOpacity))(component);
                    return (backgroundImage && backgroundImageOpactiy > threshold) ||
                        (backgroundColor && backgroundColor.length && backgroundColor[4] > threshold);
                })
                .map(transformBackgroundComponentDataForTheme),
        3,
        2
    ),
    getMemoizedList = memoMaxDynamicNumOfArgs((...transformedComponentsList) => transformedComponentsList, MaxComponentsOnPage * 3),
    /* Memoization should not be used since this function is used in server */
    findThemeOfParent = (
        component: TransformComponentType,
        componentsList: Array<TransformComponentType>,
        templateSelectedTheme: ThemeBackgroundType
    ): ThemeBackgroundType|string => {
        const parentThemeComponent = getParentThemeComponent(component, componentsList);

        if (parentThemeComponent && parentThemeComponent.selectedTheme) {
            return parentThemeComponent.selectedTheme;
        }

        return templateSelectedTheme || '';
    },
    findThemeOfParentWithMemo = memoMax(findThemeOfParent, MaxComponentsOnPage * 3),
    getParentThemeMapWithTransformedComponents = memoMax((transformedNonParentComponentsList, transformedParentComponentsList, templateSelectedTheme) => {
        return transformedNonParentComponentsList.reduce((acc, transformedComponent) => {
            const id = transformedComponent.id,
                selectedParentTheme = findThemeOfParentWithMemo(
                    transformedComponent,
                    transformedParentComponentsList,
                    templateSelectedTheme
                );
            acc[id] = selectedParentTheme;
            return acc;
        }, {});
    }, MaxComponentsOnPage * 3),
    getParentThemeMap = memoMaxFixedNumOfArgs((componentsMap: { [k: string]: AnyComponent }, templateSelectedTheme: ThemeBackgroundType, threshold = THRESHOLD_PARENT_OPACITY): { [k: string]: string } => {
        if (R.isEmpty(componentsMap)) {
            return {};
        }
        const
            transformedNonParentComponentsList = getMemoizedList(...transformNonBackgroundComponentsMapDataForTheme(componentsMap)),
            transformedParentComponentsList = getMemoizedList(...transformBackgroundComponentsMapDataForTheme(componentsMap, threshold));
        return getParentThemeMapWithTransformedComponents(
            transformedNonParentComponentsList,
            transformedParentComponentsList,
            templateSelectedTheme
        );
    }, 3, 3),
    getParentThemeForComponent = memoMax((
        component: AnyComponent,
        componentsMap: { [k: string]: AnyComponent },
        templateSelectedTheme: ThemeBackgroundType
    ) => {
        const
            transformedParentComponentsList = transformBackgroundComponentsMapDataForTheme(
                R.filter((cmp) => cmp !== component, componentsMap)
            ),
            transformedComponent = transformNonBackgroundComponentDataForTheme(component);
        return findThemeOfParentWithMemo(
            transformedComponent,
            transformedParentComponentsList,
            templateSelectedTheme
        );
    }, 3);

export {
    getParentThemeMap,
    findThemeOfParent,
    transformNonBackgroundComponentsMapDataForTheme,
    transformBackgroundComponentsMapDataForTheme,
    getParentThemeForComponent,
};
