import React from "react";
import cx from "classnames";

import { renderAddressToStringSingleline } from "../../../../server/shared/address/utils.js";

import { Box } from "../../view/reflexbox";
import VerticalSpacer from "../../view/common/VerticalSpacer";
import { getDAL } from "../../../dal";
import { openingHoursToOpeningHoursMap } from "../oneweb/OpeningHours/utils";
import { OpeningHours } from "../SiteSettings/BusinessListing/GoogleMyBusiness/OpeningHours";
import type { Address } from "./googleMaps";
import type { OpeningHour } from "../oneweb/OpeningHours/flowTypes";

import styles from "./MapsGmbDataPreview.css";

type Props = {
    addressFields: Address;
    openingHours: OpeningHour[];
    phoneNumber: any;
    className?: string;
};

export const MapsGmbDataPreview = ({ addressFields, openingHours, phoneNumber, className }: Props) => {
    const openingHoursMap = (openingHours && openingHours.length && openingHoursToOpeningHoursMap(openingHours)) || {};

    const addressStr = renderAddressToStringSingleline({
        addressName: "",
        addressStreetAddress: addressFields.addressStreetAddress || "",
        addressCity: addressFields.addressCity || "",
        addressZip: addressFields.addressZip || "",
        addressArea: addressFields.addressArea || "",
        addressCountryCode: addressFields.addressCountryCode || ""
    });

    return (
        <Box className={cx(styles.gmbDetailsContainer, className)}>
            {addressFields.addressName && (
                <React.Fragment>
                    <div className={styles.name}>{addressFields.addressName}</div>
                    <VerticalSpacer y={24} />
                </React.Fragment>
            )}
            {addressStr && (
                <React.Fragment>
                    <div className={styles.address}>{addressStr}</div>
                    <VerticalSpacer y={14} />
                </React.Fragment>
            )}
            <div className={styles.website}>{getDAL().getDomain()}</div>
            <VerticalSpacer y={14} />
            {phoneNumber && (
                <React.Fragment>
                    <div className={styles.phoneNumber}>{phoneNumber}</div>
                    <VerticalSpacer y={14} />
                </React.Fragment>
            )}
            {Object.keys(openingHoursMap).length > 0 && (
                <div className={styles.openHours}>
                    <OpeningHours openingHours={openingHours} />
                </div>
            )}
        </Box>
    );
};
