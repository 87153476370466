import { include } from '../../helpers/setOperations';
import type { Element, Stylesheet } from '../../../flowTypes';
import TextGlobalStylekind from '../../../../../components/oneweb/Text/globalStyle/kind';

const fixPadding = (paragraph, padding) => {
    const style: React.CSSProperties = {};

    if (padding && Array.isArray(padding)) {
        // todo rewrite it
        if (padding.length === 4) {
            if (padding[0]) {
                style.paddingTop = padding[0];
            }
            if (padding[1]) {
                style.paddingRight = padding[1];
            }
            if (padding[2]) {
                style.paddingBottom = padding[2];
            }
            if (padding[3]) {
                style.paddingLeft = padding[3];
            }
        } else if (padding.length === 2) {
            if (padding[0]) {
                style.paddingTop = padding[0];
                style.paddingBottom = padding[0];
            }
            if (padding[1]) {
                style.paddingLeft = padding[1];
                style.paddingRight = padding[1];
            }
        }
    }

    return {
        ...style,
        ...paragraph
    };
};

export default (
    defaultGlobalStyles: Array<Stylesheet> = [], // NOSONAR
    styles: Array<Style>,
    paragraphs: Array<Style>,
    stylesToInclude: string[],
    includeFontSize: boolean = false
): Array<Element> => {
    const includeStyles = include(stylesToInclude);

    // @ts-ignore default paragraph should always be there in globalstyles
    const defaultFontSize = defaultGlobalStyles
        .find(globalStyle => (
            globalStyle.type === TextGlobalStylekind &&
            globalStyle.ref === 'normal'
        // @ts-ignore
        )).size;

    return paragraphs.map((paragraph) => {
        const { start, end } = paragraph;
        const { padding, ...styledParagraph } = (() => {
            let fontSize = defaultFontSize;
            const styledParagraph = styles.reduce((a, style) => {
                if (includeFontSize && (style.end > start && style.start < end)) {
                    if (style.size && style.size < fontSize) {
                        fontSize = style.size;
                    } else if (style.globalName) {
                        const globalStyle = defaultGlobalStyles
                            .find(global => global.name === style.globalName);

                        // @ts-ignore
                        if (globalStyle && globalStyle.size && globalStyle.size < fontSize) {
                            // @ts-ignore
                            fontSize = globalStyle.size;
                        }
                    }
                }

                if (style.start <= start && style.end >= start) {
                    return { ...a, ...includeStyles(style) };
                }

                return a;
            }, paragraph);

            if (fontSize !== defaultFontSize) {
                styledParagraph.size = fontSize;
            }

            return styledParagraph;
        })();

        return fixPadding(styledParagraph, padding);
    });
};
