import React from "react";
import type { SideBarItemDef } from "../../SideBar/types";

import { ContactsTooltip } from "./ContactsTooltip";
import { showContactsTooltipFactory } from "./utils";

import imageStyles from "../../Images/imageStyle.css";
import getAccessManager from "../../../../../../wbtgen/src/getAccessManager";

export const CONTACTS_IFRAME_LOAD_TIMEOUT = 30 * 1000;

export const CONTACTS_SIDE_BAR_ITEM_ID = "CONTACTS_SIDE_BAR_ITEM_ID";

export const ContactsSideBarItem: SideBarItemDef = {
    id: CONTACTS_SIDE_BAR_ITEM_ID,
    icon: imageStyles.contacts,
    title: "msg: common.contacts {Contacts}",
    tooltip: <ContactsTooltip />,
    showTooltip: showContactsTooltipFactory(),
    disabled: () => !getAccessManager().isContactsShown(),
};

export enum ContactsMessageTypes {
    // Contacts
    CONTENT_LOADING = 'content-loading',
    IFRAME_REQUESTING_PRIVACY_POLICY = 'iframe-requesting-privacy-policy',
    OVERLAY = 'overlay',
    NAVIGATE_TO_PATH = 'navigateToPath',
    SHOP_ROUTE_CHANGE_REQUEST = 'shop-route-change-request',
    IFRAME_RELOADING = 'contacts-reloading',
    // WSB
    PATH_UPDATE = 'pathUpdate',
    IFRAME_PAGE_GLOBAL_STYLES = 'iframe-page-global-styles',
    SHOW_CONTACT_DETAILS = 'SHOW_CONTACT_DETAILS'
}
