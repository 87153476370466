// @ts-nocheck
import Mousetrap from "mousetrap";
import "mousetrap/plugins/global-bind/mousetrap-global-bind"; // eslint-disable-line n/file-extension-in-import
import * as appActionTypes from "../../../components/App/actionTypes";
import * as kbdActionTypes from "../../../components/App/kbdActionTypes";
import * as workspaceActionTypes from "../../../components/Workspace/actionTypes";
import { arrayToTrueMap } from "../../../utils/arrayToTrueMap";

const
    cmdC = 'command+c',
    ctrlC = 'ctrl+c',
    cmdX = 'command+x',
    ctrlX = 'ctrl+x',
    cmdA = 'command+a',
    ctrlA = 'ctrl+a',
    cmdD = 'command+d',
    ctrlD = 'ctrl+d',
    cmd0 = 'command+0',
    ctrl0 = 'ctrl+0',
    shiftUp = 'shift+up',
    shiftDown = 'shift+down',
    shiftLeft = 'shift+left',
    shiftRight = 'shift+right',
    cmdZ = 'command+z',
    ctrlZ = 'ctrl+z',
    ctrlY = 'ctrl+y',
    ctrlS = 'ctrl+s',
    cmdS = 'command+s',
    cmdShiftZ = 'command+shift+z',
    ctrlShiftZ = 'ctrl+shift+z',
    del = 'del',
    backspace = 'backspace',
    up = 'up',
    down = 'down',
    left = 'left',
    right = 'right',
    ctrlJ = 'ctrl+j',
    cmdJ = 'command+j',
    ctrlP = 'ctrl+p',
    cmdP = 'command+p',
    enter = 'enter',
    // undoRedoKeys = [cmdZ, ctrlZ, ctrlShiftZ, ctrlY, cmdShiftZ], TODO WBTGEN-5971 use app undo redo globally
    saveKeys = [ctrlS, cmdS],
    globalHotKeysTrueMap = arrayToTrueMap([...saveKeys]),
    registry = {
        esc: appActionTypes.ESC_KEY_PRESSED,

        [enter]: appActionTypes.ENTER_KEY_PRESSED,

        [cmdC]: workspaceActionTypes.COPY_SELECTED_COMPONENTS,
        [ctrlC]: workspaceActionTypes.COPY_SELECTED_COMPONENTS,

        [cmdX]: workspaceActionTypes.CUT_SELECTED_COMPONENTS,
        [ctrlX]: workspaceActionTypes.CUT_SELECTED_COMPONENTS,

        [cmdA]: workspaceActionTypes.SELECT_ALL_COMPONENTS,
        [ctrlA]: workspaceActionTypes.SELECT_ALL_COMPONENTS,

        [cmdD]: workspaceActionTypes.DUPLICATE_SELECTED_COMPONENTS,
        [ctrlD]: workspaceActionTypes.DUPLICATE_SELECTED_COMPONENTS,

        [ctrl0]: appActionTypes.CTRL_0_PRESSED,
        [cmd0]: appActionTypes.CTRL_0_PRESSED,

        [del]: kbdActionTypes.DEL_BTN_PRESS,
        [backspace]: kbdActionTypes.BACKSPACE_BTN_PRESS,

        [up]: kbdActionTypes.UP_PRESS,
        [down]: kbdActionTypes.DOWN_PRESS,
        [left]: kbdActionTypes.LEFT_PRESS,
        [right]: kbdActionTypes.RIGHT_PRESS,
        [shiftUp]: kbdActionTypes.UP_PRESS,
        [shiftDown]: kbdActionTypes.DOWN_PRESS,
        [shiftLeft]: kbdActionTypes.LEFT_PRESS,
        [shiftRight]: kbdActionTypes.RIGHT_PRESS,
        // WBTGEN-2896 Replace wbtgen/src/view/Workspace/hotKeys/index.js with isKeyPressed epic

        [cmdZ]: appActionTypes.CTRL_Z_PRESSED,
        [ctrlZ]: appActionTypes.CTRL_Z_PRESSED,

        [cmdShiftZ]: appActionTypes.CTRL_SHIFT_Z_PRESSED,
        [ctrlShiftZ]: appActionTypes.CTRL_SHIFT_Z_PRESSED,
        [ctrlY]: appActionTypes.CTRL_SHIFT_Z_PRESSED,

        [ctrlS]: appActionTypes.CTRL_S_PRESSED,
        [cmdS]: appActionTypes.CTRL_S_PRESSED,

        [ctrlJ]: appActionTypes.CTRL_J_PRESSED,
        [cmdJ]: appActionTypes.CTRL_J_PRESSED,

        [ctrlP]: appActionTypes.CTRL_P_PRESSED,
        [cmdP]: appActionTypes.CTRL_P_PRESSED
    };

const skipPreventDefault = [cmd0, ctrl0, enter];

export function bind(store) {
    const
        requestMap = {};

    Object.keys(registry).forEach(hotKey => {
        Mousetrap[globalHotKeysTrueMap[hotKey] ? 'bindGlobal' : 'bind'](hotKey, e => {
            const record = registry[hotKey];
            const actionType = record;

            if (skipPreventDefault.includes(hotKey)) {
                // do nothing
            } else {
                e.preventDefault();
            }

            // Clipboard access cannot be delayed after the keypress event due to security reasons
            if ([cmdC, ctrlC, cmdX, ctrlX].indexOf(hotKey) >= 0) {
                store.dispatch({ type: actionType });
            } else if (!requestMap[actionType]) {
                const state = store.getState();
                if (
                    saveKeys.includes(hotKey) &&
                    state.selectedWorkspace &&
                    state.selectedWorkspace.isModernLayoutOnBoardingPanelOpen
                ) {
                    return;
                }
                requestMap[actionType] = true;
                window.requestAnimationFrame(() => {
                    requestMap[actionType] = false;
                    store.dispatch({ type: actionType });
                });
            }
        });
    });
}

export function unbind() {
    Object.keys(registry).forEach(hotKey => {
        Mousetrap.unbind(hotKey);
    });
}
