import memoizee from 'memoizee';
import { getNumRowsAndCols } from "./utils";
import type { CalcRenderer } from '../../Workspace/epics/componentsProps/flowTypes';
import type { TableComponent, TableComponentDependsOn, TableCalcRenderPropsResult } from './flowTypes';
import { memoMax } from "../../../../utils/memo";
import { BACKGROUND_THEME_WHITE } from '../../ThemeGlobalData/constants';

type TableCalcRenderProps = CalcRenderer<TableComponent, void, TableComponentDependsOn, TableCalcRenderPropsResult>

const
    memoCell = memoMax(
        (content, style, minHeight, isWorkspace) => ({
            content,
            minHeight,
            isWorkspace,
            style
        }),
        100000
    ),
    makeCellProps = ({
        cell: { content, style, cellInfo: { minHeight } },
        isWorkspace
    }) => memoCell(
        content,
        style,
        minHeight,
        isWorkspace,
    ),
    getColsProps = memoizee(
        ({ width: componentWidth, cells }) => {
            const colsProps: Record<string, any>[] = [],
                { numCols } = getNumRowsAndCols(cells);

            for (let i = 0; i < numCols; i++) {
                const { cellInfo: { flexWidth } } = cells[i];
                colsProps.push({ width: Math.floor(componentWidth * flexWidth) });
            }

            return colsProps;
        },
        {
            normalizer: (args) => {
                return JSON.stringify(args[0]);
            },
            max: 10
        }
    ),
    getRowsProps = memoizee(
        ({ cells }, isWorkspace) => {
            const
                rowsProps: any = [],
                { numRows, numCols } = getNumRowsAndCols(cells);

            for (let i = 0; i < numRows; i++) {
                rowsProps[i] = [];
                for (let j = 0; j < numCols; j++) {
                    const index = (numCols * i) + j;
                    rowsProps[i].push(
                        makeCellProps({
                            cell: cells[index],
                            isWorkspace
                        })
                    );
                }
            }

            return rowsProps;
        },
        {
            normalizer: (args) => {
                return [JSON.stringify(args[0]), args[1]];
            },
            max: 10
        }
    ),
    calcRenderProps: TableCalcRenderProps = ({
        component,
        componentDependencies: {
            stylesheetsIdToNameMap,
            tableEditModeState,
            stylesheets,
            globalVariables,
            site,
            themeSettingsData,
            themeColorsData,
        },
        selectedParentTheme,
        inEditMode,
        isWorkspace
    }) => {
        const
            { width, cells } = component,
            { numRows, numCols } = getNumRowsAndCols(cells);

        const { commonCellsData } = component;

        const
            colsProps = getColsProps({ width, cells }),
            rowsProps = getRowsProps({ cells }, isWorkspace);

        return {
            width,
            colsProps,
            rowsProps,
            commonCellsData,
            numRows,
            numCols,
            inEditMode,
            tableEditModeState,
            stylesheetsIdToNameMap,
            componentId: component.id,
            stylesheets: !isWorkspace ? stylesheets : null,
            globalVariables,
            site: !isWorkspace ? site : null,
            ...({ selectedParentTheme: selectedParentTheme || BACKGROUND_THEME_WHITE }),
            themeSettingsData,
            themeColorsData
        };
    };

export default calcRenderProps;
