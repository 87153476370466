const { ConfigComponentList } = require("../constants.js");

class MultipageOnboardingConfig {
    isMultipageOnboardngAllowed() {
        const multipageOnboarding = this._featuresConfig[ConfigComponentList.MULTIPAGE_ONBOARDING];
        return multipageOnboarding && multipageOnboarding.enabled;
    }
}

module.exports = MultipageOnboardingConfig;
