/* eslint-disable @typescript-eslint/no-use-before-define */

import colorString from 'color-string';
import mapObj from '@atomic-app/map-obj';
import styleAttr from 'style-attr';
import miniSvgDataUri from 'mini-svg-data-uri';
import RecursiveIterator from 'recursive-iterator';
import { parse, parseSync, stringify } from './svgsonUtils';

const diffInPixels = function (pixels1: Uint8ClampedArray, pixels2: Uint8ClampedArray) {
    let diffCount = 0;
    const pixels1Length = pixels1.length;
    const pixels2Length = pixels2.length;
    for (let i = 0; i < pixels1Length || i < pixels2Length; i++) {
        if (pixels1[i] !== pixels2[i]) {
            diffCount++;
        }
    }
    return diffCount;
};

const getPixelsForSvgDataUriAsync = function (passedSvgAsDataUri, width = 100, height = 100) {
    return new Promise(function (resolve) {
        const img = document.createElement('img');
        img.src = passedSvgAsDataUri;
        const canvasForImg = document.createElement('canvas');
        img.onload = function () {
            canvasForImg.setAttribute('width', String(width));
            canvasForImg.setAttribute('height', String(height));

            // document.body.appendChild(canvasForImg);
            // @ts-ignore
            canvasForImg.getContext('2d').drawImage(img, 0, 0, width, height);

            // @ts-ignore
            const pixelsForGivenImage = canvasForImg.getContext('2d').getImageData(0, 0, width, height).data;
            resolve([null, pixelsForGivenImage]);
        };
        img.onerror = function () {
            resolve([{ error: 'Unexpected error' }]);
        };
    });
};

const collapseSVGJsonToSetStyleAttributeAsString = function (svgJson) {
    const mappedSvgJson = mapObj(svgJson, function (key, value) {
        if (key === 'style') {
            const parsedValue = styleAttr.stringify(value);
            return [key, parsedValue];
        }

        return [key, value];
    }, {
        deep: true
    });

    return mappedSvgJson;
};

export const doesFillMakeAnyDifferenceAsync = function (givenSvgAsJson: Object): Promise<boolean> {
    return new Promise(function (resolve) {
        let loadCounter = 0;

        let copyOfGivenSvgAsJson = JSON.parse(JSON.stringify(givenSvgAsJson));
        copyOfGivenSvgAsJson = collapseSVGJsonToSetStyleAttributeAsString(copyOfGivenSvgAsJson);
        const givenSvgAsText = stringify(copyOfGivenSvgAsJson);
        const givenSvgAsDataUri = miniSvgDataUri(givenSvgAsText);
        const imgGiven = document.createElement('img');
        imgGiven.src = givenSvgAsDataUri;
        const canvasForGivenImage = document.createElement('canvas');
        imgGiven.onload = function () {
            loadCounter++;

            canvasForGivenImage.setAttribute('width', String(100));
            canvasForGivenImage.setAttribute('height', String(100));

            // @ts-ignore
            canvasForGivenImage.getContext('2d').drawImage(imgGiven, 0, 0, 100, 100);

            afterBothLoad(); // eslint-disable-line no-use-before-define
        };

        const modifiedSvgAsJson = JSON.parse(JSON.stringify(givenSvgAsJson));
        modifiedSvgAsJson.attributes = modifiedSvgAsJson.attributes || {};
        modifiedSvgAsJson.attributes.fill = 'rgba(151,49,101,1)'; // LAZY: This is not an ideal approach. Just picked up a random and relatively uncommon color.

        const modifiedSvgAsText = stringify(modifiedSvgAsJson);
        const modifiedSvgAsDataUri = miniSvgDataUri(modifiedSvgAsText);

        const imgModified = document.createElement('img');
        imgModified.src = modifiedSvgAsDataUri;
        const canvasForModifiedImage = document.createElement('canvas');
        imgModified.onload = function () {
            loadCounter++;

            canvasForModifiedImage.setAttribute('width', String(100));
            canvasForModifiedImage.setAttribute('height', String(100));

            // @ts-ignore
            canvasForModifiedImage.getContext('2d').drawImage(imgModified, 0, 0, 100, 100);

            afterBothLoad(); // eslint-disable-line no-use-before-define
        };

        const afterBothLoad = function () {
            if (loadCounter < 2) {
                return;
            }

            // @ts-ignore
            const pixelsForGivenImage = canvasForGivenImage.getContext('2d').getImageData(0, 0, 100, 100).data;
            // @ts-ignore
            const pixelsForModifiedImage = canvasForModifiedImage.getContext('2d').getImageData(0, 0, 100, 100).data;

            let imagesMatch = true;
            for (let i = 0, il = pixelsForGivenImage.length; i < il; i++) {
                if (pixelsForGivenImage[i] !== pixelsForModifiedImage[i]) {
                    imagesMatch = false;
                    break;
                }
            }

            if (imagesMatch) {
                // Fill is not being used
                resolve(false);
            } else {
                // Fill is being used
                resolve(true);
            }
        };
    });
};

interface SvgJson {
    attributes? : {
        fill?: string
    }
}

export const getFillColorMapFromSvgJson = function (svgJson: SvgJson) {
    const fillColorMap = {};

    if (
        svgJson.attributes &&
        svgJson.attributes.fill &&
        colorString.get.rgb(svgJson.attributes.fill)
    ) {
        // fillColorMap[svgJson.attributes.fill.toLowerCase()] = svgJson.attributes.fill;
        const colorValue = `rgba(${colorString.get.rgb(svgJson.attributes.fill)})`;
        fillColorMap[colorValue] = colorValue;
    } else {
        const blackRgba = `rgba(${colorString.get.rgb('#000000')})`;
        fillColorMap[blackRgba] = blackRgba;
    }
    // TODO: reads colors which are not being used, like clippath
    return fillColorMap;
};

const expandSVGJsonToIncludeStyleAttribute = function (svgJson) {
    const mappedSvgJson = mapObj(svgJson, function (key, value) {
        if (key === 'style') {
            const parsedValue = styleAttr.parse(value);
            return [key, parsedValue];
        }

        return [key, value];
    }, {
        deep: true
    });

    return mappedSvgJson;
};

export const getMentionedColorsMapFromSvgJson = async function (originalSvgJson: Object, options: { withProminence?: boolean } = {}) {
    const {
        withProminence
    } = options;

    let iterator = new RecursiveIterator(originalSvgJson);

    const mentionedColorsMap = {};

    for (let { node, path } of iterator) { // eslint-disable-line no-restricted-syntax
        if (['attributes.fill'].includes(path.join('.'))) {
            continue;
        }
        if (typeof node === 'string') {
            const colorInRgba = colorString.get.rgb(node);
            if (colorInRgba) {
                // mentionedColorsMap[node.toLowerCase()] = node;

                const colorValue = `rgba(${colorInRgba})`;
                mentionedColorsMap[colorValue] = colorValue;
            }
        }
    }

    const prominence: Array<{
        colorCode: string;
        diffInBasisPoints: number;
    }> = [];

    if (withProminence) {
        const widthToConsider = 100;
        const heightToConsider = 100;

        const originalSvgText = svgJsonToSvgText(originalSvgJson, { readThroughStyleAttributes: true }); // eslint-disable-line no-use-before-define
        const originalSvgAsDataUri = svgTextToDataUri(originalSvgText); // eslint-disable-line no-use-before-define
        // @ts-ignore
        const [, pixelsOfOriginalSvg] = await getPixelsForSvgDataUriAsync(originalSvgAsDataUri, widthToConsider, heightToConsider);

        const keys = Object.keys(mentionedColorsMap);
        for (const colorCode of keys) {
            const mapping = {};
            mapping[colorCode] = 'rgba(151,49,101,1)'; // LAZY: This is not an ideal approach. Just picked up a random and relatively uncommon color.
            const modifiedSvgJson = mapSvgJsonWithColors(originalSvgJson, mapping); // eslint-disable-line no-use-before-define
            const modifiedSvgText = svgJsonToSvgText(modifiedSvgJson, { readThroughStyleAttributes: true }); // eslint-disable-line no-use-before-define
            const modifiedSvgAsDataUri = svgTextToDataUri(modifiedSvgText); // eslint-disable-line no-use-before-define
            // @ts-ignore
            const [, pixelsOfModifiedSvg] = await getPixelsForSvgDataUriAsync(modifiedSvgAsDataUri, widthToConsider, heightToConsider); // eslint-disable-line no-await-in-loop

            const diffInBasisPoints = diffInPixels(pixelsOfOriginalSvg, pixelsOfModifiedSvg);

            prominence.push({
                colorCode,
                diffInBasisPoints
            });
        }

        prominence.sort(function (a, b) {
            if (a.diffInBasisPoints < b.diffInBasisPoints) {
                return -1;
            }
            if (a.diffInBasisPoints > b.diffInBasisPoints) {
                return 1;
            }
            return 0;
        });
        prominence.reverse();
    }

    return [null, {
        mentionedColorsMap,
        prominence
    }];

    // return mentionedColorsMap;
};

export const mapSvgJsonWithColors = function (svgJson: SvgJson, colorsMap: { [k: string]: string }, defaultFillColor?: string): Object {
    let svgJsonWithFillColor = svgJson;
    if (defaultFillColor && (svgJson.attributes && !svgJson.attributes.fill)) {
        svgJsonWithFillColor = { ...svgJson, attributes: { ...svgJson.attributes, fill: defaultFillColor } };
    }
    // TODO: can optimise by specifically checking keys of interest
    const mappedSvgJson = mapObj(svgJsonWithFillColor, function (key, value) {
        if (typeof value === 'string') {
            const colorInRgba = colorString.get.rgb(value);
            if (colorInRgba) {
                // const valueLowerCase = value.toLowerCase();

                const colorValue = `rgba(${colorInRgba})`;
                // if (colorsMap[valueLowerCase]) {
                if (colorsMap[colorValue]) {
                    // const newValue = colorsMap[valueLowerCase];
                    const newValue = colorsMap[colorValue];
                    return [key, newValue];
                } else {
                    return [key, value];
                }
            } else {
                return [key, value];
            }
        } else {
            return [key, value];
        }
    }, {
        deep: true
    });

    return mappedSvgJson;
};

export const svgTextToDataUri = function (svgText: string): string {
    const output = miniSvgDataUri(svgText);
    return output;
};

type optionsType = { readThroughStyleAttributes?: boolean };

export const svgTextToSvgJsonSync = function (svgText: string, options: optionsType = {}): Object {
    const {
        readThroughStyleAttributes
    } = options;
    let output = parseSync(svgText);

    if (readThroughStyleAttributes) {
        output = expandSVGJsonToIncludeStyleAttribute(output);
    }

    return output;
};

export const svgTextToSvgJsonAsync = async function (svgText: string, options: optionsType = {}) {
    const {
        readThroughStyleAttributes
    } = options;
    let output = await parse(svgText); //NOSONAR

    if (readThroughStyleAttributes) {
        output = expandSVGJsonToIncludeStyleAttribute(output);
    }

    return output;
};

export const svgJsonToSvgText = function (svgAsJson: Object, options: optionsType = {}): string {
    const {
        readThroughStyleAttributes
    } = options;
    let preprocessedSvgAsJson = svgAsJson;
    if (readThroughStyleAttributes) {
        preprocessedSvgAsJson = mapObj(preprocessedSvgAsJson, function (key, value) {
            if (key === 'style') {
                const parsedValue = styleAttr.stringify(value);
                return [key, parsedValue];
            }
            return [key, value];
        }, {
            deep: true
        });
    }

    // @ts-ignore
    const output = stringify(preprocessedSvgAsJson);
    return output;
};
