import * as R from "ramda";
import cx from "classnames";
import React from "react";
import { connect } from "react-redux";
import { Flex } from "../../../../view/reflexbox/index";
import styles from "./ExpandedPanelButton.css";
import {
    getAllStylesheets,
    getStylesByType,
    stylesheetAppSel,
    getThemeColorsFromStylesheet
} from "../../../Workspace/epics/stylesheets/selectors";
import ButtonGlobalStyleKind from "../globalStyle/kind";
import BaseComponentPreview from "../../../../view/oneweb/BaseComponentPreview";
import { makeEpicStateSelector } from "../../../../epics/makeEpic";
import { stylesheetIdToNameMapVAT } from "../../../Workspace/epics/stylesheets/idToNameMapValueActionTypes";
import { DroppableCmpShortcut } from "../../../Panel/view/DroppableCmpShortcut";
import ButtonKind from "../kind";
import buttonStyles from "../view/Button.css";
import { buttonViewFactory } from "../view/index";
import VerticalSpacer from "../../../../view/common/VerticalSpacer";
import { EPSeparator } from "../../../Panel/view/EPSeparator";
import { EditGlobalStyle } from "../../../Panel/view/EditExtendedPanelContent";
import { Msg } from "../../../../view/intl/index";
import { extendedPanelBGColor, contrastBgLayer, contrastBgColor } from "../../../Panel/view/Inserter.css";
import { getColorDistance } from "../../../../utils/colorUtils";
import { EXTENDED_PANEL_TEXT_STYLE_STATE_EPIC_VALUE } from "../../Text/epics/ExtendedPanelScaleStateEpic/valueActionType";
import MeasuredText from "../../Text/expandedPanel/MeasuredText";
import { EXTENDED_PANEL_BTN_TEXT_MEASURED_WIDTH } from "../../Text/epics/ExtendedPanelScaleStateEpic/actionTypes";
import ConstrastBgHelpTip from "../../../Panel/view/ConstrastBgHelpTip";
import {
    ALL_BUTTON_THEMES,
    BACKGROUND_THEME_WHITE,
    THEME_BUTTON_CLASS,
    THEME_BUTTON_PREVIEW_TEXT
} from "../../../ThemeGlobalData/constants";
import { getThemeStyleForButtonWithKnownTheme } from "../utils";
import Icons from "../../../../view/Icons/index";
import showAutoColorLeftPanel from "../../../AutoColorLeftPanel/actionCreators/showAutoColorLeftPanel";
import { EXTENDED_PANEL_CLOSE } from '../../../Panel/actionTypes';
import { colorThemeDataEpicStateFromAppStateSelector } from "../../../SiteSettings/ColorThemeData/selectorActionTypes";

const getAllButtonStyles = (styleSheets) => {
    return R.pipe(
        getAllStylesheets,
        getStylesByType(ButtonGlobalStyleKind),
    )(styleSheets);
};

const ButtonView = buttonViewFactory({ style: {} });
const buttonTextColorPath = ['inactive', 'text', 'color'],
    buttonBGColorPath = ['inactive', 'block', 'background', 'colorData', 'color'],
    buttonBorderPath = ['inactive', 'block', 'border'];

const
    showBGLayerFactory = (textColor, bgColor, borderColor, isBorderShown, panelBg, layerBg) => {
        const threshold = 0.12;
        let color = bgColor;
        if (isBorderShown) {
            color = borderColor;
        } else if (!color) {
            color = textColor || '#000';
        }
        const d1 = getColorDistance(color, panelBg),
            d2 = getColorDistance(color, layerBg);
        return d1 > threshold || d1 > d2;
    },
    showBGLayer = (btnStyle, panelBg, layerBg) => {
        const
            textColor = R.path(buttonTextColorPath, btnStyle),
            bgColor = R.path(buttonBGColorPath, btnStyle),
            border = R.path(buttonBorderPath, btnStyle),
            isBorderShown = !!border.color && border.style !== 'none' && border.width.some((val) => val);
        return showBGLayerFactory(textColor, bgColor, border.color, isBorderShown, panelBg, layerBg);
    },
    showBGLayerForTheme = (themeStyle, panelBg, layerBg) => {
        const
            {
                backgroundColor: bgColor,
                color: textColor,
                border,
            } = themeStyle,
            borderColor = textColor, // Border color will be same as text color for theme
            isBorderShown = border !== "none";
        return showBGLayerFactory(textColor, bgColor, borderColor, isBorderShown, panelBg, layerBg);
    };

const buttonTemplates = ({
    styleSheets,
    stylesheetsIdToNameMap,
    extendedPanelStyle = {},
    themeSettingsData: { autoColorMode },
    ...rest
}) => {
    const
        { intl, dispatch } = rest,
        globalButtonStyles = getAllButtonStyles(styleSheets),
        buttonPreviewText = intl.msgJoint('msg: common.buttons {Button}'),
        firstButtonGlobalStyle = globalButtonStyles[0],
        getDroppableButtonComponent = ({
            globalId,
            index,
            showContrastBgColor,
            buttonPreviewText,
            buttonThemeSelected = null,
        }) => {
            const { width = 0, height = 0, scale = 1 } = extendedPanelStyle[globalId] || {};
            const payload = {
                style: {
                    globalId
                },
                buttonThemeSelected,
                width,
                height
            };
            let styleContainer = {},
                buttonStyle = {};
            if (scale < 1) {
                styleContainer = { height: Math.ceil(height * scale) };
                buttonStyle = {
                    transform: `scale(${scale})`,
                    transformOrigin: 'center 0'
                };
            }
            return <React.Fragment key={index}>
                { /* @ts-ignore */ }
                <DroppableCmpShortcut
                    kind={ButtonKind}
                    className={cx(styles.item, {
                        [contrastBgLayer]: showContrastBgColor,
                        [styles.contrastBgLayer]: showContrastBgColor
                    })}
                    payload={payload}
                    {...rest}
                    isOverlayExtended
                >
                    {showContrastBgColor && <ConstrastBgHelpTip dispatch={dispatch} kind={ButtonKind} />}

                    <div style={styleContainer}>
                        <BaseComponentPreview
                            className={cx(
                                styles.buttonPreview,
                                buttonThemeSelected,
                                BACKGROUND_THEME_WHITE,
                                THEME_BUTTON_CLASS
                            )}
                            globalStyleIds={[stylesheetsIdToNameMap[globalId]]}
                            style={buttonStyle}
                        >
                            <ButtonView text={buttonPreviewText} styles={buttonStyles} />
                        </BaseComponentPreview>
                    </div>
                    <MeasuredText
                        onResize={({ width, height }) => {
                            dispatch({
                                type: EXTENDED_PANEL_BTN_TEXT_MEASURED_WIDTH,
                                payload: {
                                    width,
                                    height,
                                    textStyleName: globalId,
                                }
                            });
                        }}
                        className={cx(styles.btnMeasuredText)}
                        dispatch={dispatch}
                    >
                        <BaseComponentPreview
                            className={styles.buttonPreview}
                            globalStyleIds={[stylesheetsIdToNameMap[globalId]]}
                        >
                            <ButtonView text={buttonPreviewText} styles={buttonStyles} />
                        </BaseComponentPreview>
                    </MeasuredText>
                    { !autoColorMode && <Msg k="extendedPanel.button.style.label" className={styles.btnTitle} params={{ index: index + 1 }}>
                        {`Button style {index}`}
                    </Msg>}
                </DroppableCmpShortcut>
                { autoColorMode && <VerticalSpacer y={10} /> }
            </React.Fragment>;
        },
        getButtonGlobalStyles = () => {
            if (autoColorMode) {
                const
                    { id: globalId } = firstButtonGlobalStyle,
                    themeColorsData = getThemeColorsFromStylesheet(styleSheets),
                    buttonThemeGlobalStyles = ALL_BUTTON_THEMES.map((buttonTheme, index) => {
                        const
                            themeStyle = getThemeStyleForButtonWithKnownTheme({
                                selectedParentTheme: BACKGROUND_THEME_WHITE,
                                buttonThemeSelected: buttonTheme,
                                themeColorsData,
                            }),
                            showContrastBgColor = !showBGLayerForTheme(themeStyle, extendedPanelBGColor, contrastBgColor),
                            buttonPreviewText = intl.msgJoint(THEME_BUTTON_PREVIEW_TEXT[buttonTheme]);
                        return getDroppableButtonComponent({
                            globalId,
                            index,
                            showContrastBgColor,
                            buttonPreviewText,
                            // @ts-ignore
                            buttonThemeSelected: buttonTheme
                        });
                    });
                return buttonThemeGlobalStyles;
            } else {
                const buttonGlobalStyles = globalButtonStyles.map((globalStyle, index) => {
                    const
                        { id: globalId } = globalStyle,
                        showContrastBgColor = !showBGLayer(globalStyle, extendedPanelBGColor, contrastBgColor);
                    return getDroppableButtonComponent({
                        globalId,
                        index,
                        showContrastBgColor,
                        buttonPreviewText,
                        buttonThemeSelected: null
                    });
                });
                return buttonGlobalStyles;
            }
        };
    return (
        <div>
            {
                autoColorMode &&
                <React.Fragment>
                    <VerticalSpacer y={20} />
                    <div>
                        <Flex align="flex-end">
                            <Icons.COLOR_THEME_ICON_BLUE iconStyle={{ paddingBottom: '3px' }} />
                            <div className={styles.colorThemeOn}>
                                <Msg k="common.gs.common.colorThemeIsOn.label">Colour theme is on:</Msg>
                            </div>
                        </Flex>
                        <div className={styles.colorThemeDesc}>
                            <Msg k="common.gs.common.colorThemeIsOn.button.previews">
                                The previews here are examples of how the colours will often apply to your button styles.
                            </Msg>
                            &nbsp;
                            <div
                                onClick={() => {
                                    dispatch({ type: EXTENDED_PANEL_CLOSE });
                                    dispatch(showAutoColorLeftPanel());
                                }}
                                style={{ display: 'inline' }}
                            >
                                <Msg k="common.editColorTheme" className={styles.editColorTheme}>Edit colour theme</Msg>
                            </div>
                        </div>
                    </div>
                    <VerticalSpacer y={19} />
                    <EPSeparator />
                    <VerticalSpacer y={18} />
                </React.Fragment>
            }
            {!autoColorMode && <VerticalSpacer y={24} />}
            <EditGlobalStyle
                globalStyleKind={ButtonGlobalStyleKind}
                intl={intl}
                dispatch={dispatch}
                styleSheetId={firstButtonGlobalStyle.id}
            />
            { getButtonGlobalStyles() }
        </div>
    );
};

const mapStateToProps = (appState) => ({
    styleSheets: stylesheetAppSel(appState),
    stylesheetsIdToNameMap: makeEpicStateSelector(stylesheetIdToNameMapVAT)(appState),
    extendedPanelStyle: makeEpicStateSelector(EXTENDED_PANEL_TEXT_STYLE_STATE_EPIC_VALUE)(appState),
    themeSettingsData: colorThemeDataEpicStateFromAppStateSelector(appState),
});

const ButtonTemplateView = connect(mapStateToProps)(buttonTemplates);
export { ButtonTemplateView };
