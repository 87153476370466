/* eslint-disable max-len */

import React from "react";
import { connect } from 'react-redux';
import Measure from 'react-measure';
import cx from 'classnames';
import { injectIntl } from "../../../view/intl/index";
import { CLOSE_TOOLTIP, TOOLTIP_CLIENT_RECT } from "../actionTypes";
import { STICKY_TOOLTIP_CLIENT_RECT, CLOSE_STICKY_TOOLTIP } from '../stickyTooltip/actionTypes';
import styles from './Tooltip.css';
import type { TooltipProps, TooltipState } from "../flowTypes";
import Checkbox from '../../../view/common/CheckBox/PropertiesCheckBox';
import Button from '../../../view/common/Button/Button';
import { getPremiumPackageNameFromMetadata } from '../../ComponentTierManager/utils';

const
    mapStateToProps = ({ tooltip, subscriptionData: { metadata: subscriptionMetadata } }) => ({
        ...tooltip,
        subscriptionMetadata,
    }),
    onMouseDown = e => { e.preventDefault(); e.stopPropagation(); };

export class ToolTip extends React.Component<TooltipProps, TooltipState> {
    constructor(props: TooltipProps) {
        super(props);
        this.state = { timer: null };
    }

    static defaultProps = {
        timeout: 8000
    };

    UNSAFE_componentWillReceiveProps(nextProps: TooltipProps) {
        if (nextProps.style && nextProps.timeout) {
            if (this.state.timer) {
                clearTimeout(this.state.timer);
            }
            const timer = setTimeout(() => {
                this.props.dispatch({ type: nextProps.sticky ? CLOSE_STICKY_TOOLTIP : CLOSE_TOOLTIP });
            }, nextProps.timeout);
            this.setState({ timer });
        }
    }

    msgJointWithGenericParams(message: string, params: { [k: string]: string } = {}) {
        const { subscriptionMetadata, intl } = this.props,
            premiumPackageNameFromMetadata = getPremiumPackageNameFromMetadata(subscriptionMetadata);

        return intl.msgJoint([message, {
            ...params,
            premiumPackageName: premiumPackageNameFromMetadata || intl.msgJoint('msg: common.premium {Premium}'),
        }]);
    }

    render() {
        const
            {
                show,
                style,
                headerStyle,
                textStyle,
                text2Style,
                text3Style,
                tipInfoStyle,
                tipIconIsWhite = false,
                pointerStyle,
                msg,
                showIcon = true,
                intl,
                dispatch,
                showCrossIcon = false,
                onCrossIconClick = () => {},
                onActionBtnClick = () => {},
                crossIconStyle,
                chkBoxEnabled = false,
                tipIconSize = 'small',
                showPointer = true,
                customHTML,
                customClass,
                showTipText = true,
                sticky = false,
                isActionLink = false,
                showHeadersWithoutTip = false,
                useCustomTextStyle = false,
                customTextClass,
                linkTextCustomClass,
                buttonCustomClass = "",
                showImage,
                imageClass
            } = this.props;

        if (!show) {
            return null;
        }

        const
            msgJointWithGenericParams = this.msgJointWithGenericParams.bind(this);
        let text = '';

        if (show && msg.text) {
            if (msg.params) {
                const params = msg.params.componentKind
                    ? { ...msg.params, componentKind: intl.msgJoint(msg.params.componentKind) }
                    : msg.params;

                text = msgJointWithGenericParams(msg.text, params);
            } else {
                text = msgJointWithGenericParams(msg.text);
            }
        }

        const linkText = msg.linkText && <a
            //$FlowFixMe
            className={cx(styles.linkTextStyle, { [linkTextCustomClass as string]: linkTextCustomClass })}
            href={msg.linkUrl ? msgJointWithGenericParams(msg.linkUrl) : '#'}
            target={msg.linkUrl ? "_blank" : ""}
            onClick={() => {
                if (msg && msg.linkCustomActionDispatch && msg.linkCustomActionDispatch.actionType) {
                    if (msg.linkCustomActionDispatch.closeTipOnLinkClick) {
                        dispatch({ type: sticky ? CLOSE_STICKY_TOOLTIP : CLOSE_TOOLTIP });
                    }

                    if (msg.linkCustomActionDispatch && msg.linkCustomActionDispatch.actionType) {
                        dispatch({
                            type: msg.linkCustomActionDispatch.actionType,
                            payload: msg.linkCustomActionDispatch.actionPayload,
                        });
                    }
                }
            }}
        >
            {msgJointWithGenericParams(msg.linkText)}
        </a>;

        return (
            <Measure
                offset
                scroll
                onResize={({ offset }) => {
                    dispatch({ type: sticky ? STICKY_TOOLTIP_CLIENT_RECT : TOOLTIP_CLIENT_RECT, payload: offset });
                }}
            >
                {
                    ({ measureRef }) =>
                        <div
                            ref={measureRef}
                            style={style}
                            className={cx(styles.container, customClass || "", { [styles.sticky]: sticky })}
                            onMouseDown={onMouseDown}
                        >
                            {showPointer && <div className={styles.pointer} style={pointerStyle} />}
                            <div className={styles.tipInfo} style={tipInfoStyle}>
                                {
                                    showIcon &&
                                    <div
                                        className={cx(styles.tipIcon, {
                                            [styles.mediumTipIcon]: tipIconSize === 'medium',
                                            [styles.largeTipIcon]: tipIconSize === 'large',
                                            [styles.whiteTipIcon]: tipIconIsWhite
                                        })}
                                    >
                                        <div className={styles.hintIcon} />
                                    </div>
                                }
                                <div className={styles.tipText} style={textStyle}>
                                    <div className={cx({ [styles.tipHeaderBar]: showIcon || showCrossIcon })}>
                                        {
                                            showIcon &&
                                            <div className={styles.tip} style={headerStyle}>
                                                <div>
                                                    {
                                                        (showTipText ? msgJointWithGenericParams('msg: tip {Tip: }') + ' ' : '') +
                                                        (msg.header ? msgJointWithGenericParams(msg.header) : '')
                                                    }
                                                </div>
                                                {
                                                    msg.subHeader &&
                                                    <div className={styles.tipSubHeader}>
                                                        {msgJointWithGenericParams(msg.subHeader) }
                                                    </div>
                                                }
                                            </div>
                                        }
                                        {
                                            showCrossIcon &&
                                            <div
                                                className={cx({
                                                    [styles.close]: true,
                                                    [styles.crossIcon]: true,
                                                    [styles.crossIconIsWhite]: tipIconIsWhite
                                                })}
                                                style={crossIconStyle}
                                                onClick={() => {
                                                    dispatch({ type: sticky ? CLOSE_STICKY_TOOLTIP : CLOSE_TOOLTIP });
                                                    onCrossIconClick();
                                                }}
                                            />
                                        }
                                    </div>

                                    {
                                        showImage &&
                                            <div className={imageClass} />
                                    }

                                    {(showHeadersWithoutTip && msg.header) &&
                                        <div className={styles.header} style={headerStyle}>{msgJointWithGenericParams(msg.header)}</div>}

                                    {/*$FlowFixMe */}
                                    <div className={cx({ [styles.textDescription]: useCustomTextStyle }, { [customTextClass as string]: customTextClass })}>
                                        {text} {msg.inlineLink && linkText}
                                    </div>
                                    {msg.text1 && <div className={styles.text1Style}>{msgJointWithGenericParams(msg.text1)}</div>}
                                    {msg && msg.linkCustomActionDispatch && msg.linkCustomActionDispatch.addLinkSpace && <span>&nbsp;</span>}
                                    {
                                        !msg.inlineLink && linkText
                                    }
                                    {
                                        msg.chkBoxTxt &&
                                        <Checkbox
                                            label={msg.chkBoxTxt}
                                            isChecked={chkBoxEnabled}
                                            className={cx({
                                                [styles.chkBoxLableStyle]: true,
                                                [styles.chkBoxLableStyleSpecificityWorkaround]: true
                                            })}
                                            onClick={() => {
                                                dispatch({
                                                    type: msg.chkBoxAction,
                                                    payload: !chkBoxEnabled
                                                });
                                            }}
                                        />
                                    }
                                </div>
                                {msg.text2 && <div style={text2Style}>
                                    {msgJointWithGenericParams(msg.text2)}
                                </div>}
                                {msg.text3 && <div style={text3Style}>
                                    {msgJointWithGenericParams(msg.text3)}
                                </div>}
                                {msg.actiontext && <Button
                                    className={cx({
                                        [styles.actionClsBtn]: !isActionLink, [styles.actionClsLink]: isActionLink
                                    }, {
                                        [buttonCustomClass]: buttonCustomClass
                                    })}
                                    onClick={() => {
                                        dispatch({ type: sticky ? CLOSE_STICKY_TOOLTIP : CLOSE_TOOLTIP });
                                        if (buttonCustomClass) {
                                            dispatch(onActionBtnClick());
                                        } else {
                                            onActionBtnClick();
                                        }
                                    }}
                                >
                                    {msgJointWithGenericParams(msg.actiontext)}
                                </Button>}
                                <div className={styles.customBox}>
                                    <div>{customHTML}</div>
                                </div>
                            </div>
                        </div>
                }
            </Measure>
        );
    }
}

export default injectIntl(connect(mapStateToProps)(ToolTip));
