import * as R from 'ramda';
import makeEpic from '../../../epics/makeEpic';
import { when, receiveOnly } from '../../../epics/makeCondition';
import valueActionType from './valueActionType';
import {
    DIRECTORY_CONTENTS_STATUS_FETCH_REQUEST,
    DIRECTORY_CONTENTS_STATUS_FETCH_SUCCESS
} from './directoryContentFetchActions';
import {
    COMPONENTS_MAP_REPLACE_MISSING_ASSETS,
    COMPONENTS_MAP_DELETE_MISSING_ASSETS
} from '../../Workspace/epics/componentsEval/actionTypes';
import {
    PASTE_FROM_SYSTEM_CLIPBOARD
} from '../../Workspace/actionTypes';
import {
    STYLESHEET_REPLACE_MISSING_ASSETS,
    STYLESHEET_DELETE_MISSING_ASSETS
} from '../../Workspace/epics/stylesheets/actionTypes';
import {
    SOCIAL_SHARE_DELETE_MISSING_ASSET,
    SOCIAL_SHARE_REPLACE_MISSING_ASSET
} from "../../SiteSettings/socialShareGlobalData/actionTypes";
import {
    GENERAL_INFO_DELETE_MISSING_ASSETS,
    GENERAL_INFO_REPLACE_MISSING_ASSETS
} from "../../SiteSettings/General/actionTypes";
import * as appDialogIds from '../../App/dialogs/appDialogIds';
import * as actionTypes from '../actionTypes';
import * as statusTypes from '../../Workspace/epics/status/types';
import missingAssetDialogId from '../dialogId';
import statusValueActionType from '../../Workspace/epics/status/valueActionType';
import stylesheetsValueActionTypes from '../../Workspace/epics/stylesheets/valueActionType';
import makeStateSelectorReducer from '../../../epics/makeStateSelectorReducer';
import { openImageChooserAction } from "../../FileManager/imageChooser/actions";
import { openVideoChooserAction } from '../../FileManager/videoChooser/actions';
import { FmTabId } from '../../FileManager/dialog/constants';
import { openSvgChooserAction } from "../../FileManager/actions";
import path from '../../../../utils/path.js';
import { getDirectoryAndFileFromAssetUrl, fetchAssetsUrls } from '../../../utils/assetUtils';
import { openDialog, updateDialog } from '../../App/actionCreators/index';
import type { MissingAssetEpicState } from './flowTypes';
import type { ImageAsset } from '../../App/flowTypes';
import { PAGE_DATASET_SAVE_FAILURE } from "../../App/epics/pageDataset/actions";
import { closeMissingAssetsDialogAction } from "../actionCreators";
import { ReceiveOnlyComponentsMap } from "../../Workspace/epics/componentsEval/selectorActionTypes";
import generalGlobalDataEpicVAT from "../../SiteSettings/General/generalGlobalDataEpic/valueActionType";
import { socialShareGlobalDataEpic } from "../../SiteSettings/socialShareGlobalData/socialShareGlobalDataEpic";
import { CLOSE_DIALOG } from "../../../redux/modules/actionTypes";

/**
 * WBTGEN-6738:
 *
 * This issue was caused because the state of the epic was not reset on close of the dialog.
 * Hence new updaters have been added which, on close of the dialog, will reset the state of epic.
 */

import ButtonKind from "../../oneweb/Button/kind";
import CodeKind from "../../oneweb/Code/kind";
import ContactFormKind from "../../oneweb/ContactForm/kind";
import DocumentKind from "../../oneweb/Document/kind";
import FacebookPageKind from "../../oneweb/Facebook/kind";
import MenuKind from "../../oneweb/Menu/kind";
import ShareButtonsKind from "../../oneweb/ShareButtons/kind";
import TextKind from "../../oneweb/Text/kind";
import VideoKind from "../../oneweb/Video/kind";
import WebshopKind from "../../oneweb/WebShop/kind";
import { isConflictErrorAction } from '../../../redux/middleware/api/errorHandler/conflictDialog/isConflictErrorAction';
import { sendShownErrorEventToAecAC } from "../../App/actionCreators/sendEventToAecActionCreator";
import { TEMPLATE_DELETE_MISSING_ASSET, TEMPLATE_REPLACE_MISSING_ASSET } from "../../oneweb/Template/actionTypes";
import { VIDEO_COMPONENT_VAT } from '../../oneweb/Video/epics/videoComponent/videoComponentVAT';
import VIDEO_TRANSCODE_STATUS_VAT from '../../oneweb/Video/epics/checkTranscodeStatus/valueActionType';
import { VIDEO_FILE_UPDATE } from "../../oneweb/Video/actionTypes";
import { decodeWebspaceUri, encodeWebspaceUri } from '../../../../dal/utils/webspaceUriTransformers';

const ComponentKindsToSkip = [
    ButtonKind,
    CodeKind,
    ContactFormKind,
    DocumentKind,
    FacebookPageKind,
    MenuKind,
    ShareButtonsKind,
    TextKind,
    VideoKind,
    WebshopKind
];
const dropComponentsWithNoImage = componentsMap => Object
    .keys(componentsMap)
    .filter(componentId => {
        const { kind } = componentsMap[componentId];
        return ComponentKindsToSkip.indexOf(kind) === -1;
    })
    .reduce((acc, componentId) => ({
        ...acc,
        [componentId]: componentsMap[componentId]
    }), {});

const removeUploadingVideosFromMissingAssetData = (assetsList, videoUploadingList, videoTranscodingList) => {
    const transcodingVideosList = Object.keys(videoTranscodingList)
        .filter(key => ['progressing', 'uploaded'].includes(videoTranscodingList[key].status));
    return assetsList.filter(assetPath => {
        let fileName = assetPath.split('/').pop();
        return !videoUploadingList.some(name => name === fileName) && !transcodingVideosList.includes(fileName);
    });
};

const
    defaultState = {
        pageAssetUrls: [],
        missingAssetUrls: [],
        replacementState: {},
        showUploadButtons: false,
        saveButtonDisabled: true,
        assetToReplace: null
    },
    encodeDecode = str => encodeWebspaceUri(decodeWebspaceUri(str)),
    getEpicState = ({ componentsMap, stylesheets, siteSettingsGeneralData, socialShareData,
        videoUploadingList, videoTranscodingList, epicState }) => {
        const
            componentsMapFiltered = dropComponentsWithNoImage(componentsMap),
            assetsFromComponentsMap = removeUploadingVideosFromMissingAssetData(
                Object.keys(componentsMapFiltered).reduce(
                    (acc: string[], id) => acc.concat(fetchAssetsUrls(componentsMapFiltered[id])),
                    []
                ),
                videoUploadingList.uploadingList,
                videoTranscodingList.transcodeStatus
            ),
            assetsFromStylesheets = stylesheets.styles.reduce(
                (acc, style) => acc.concat(fetchAssetsUrls(style)), []
            ),
            assetsFromSiteSettingsGeneralData = fetchAssetsUrls(siteSettingsGeneralData),
            assetsFromSocialShareData = fetchAssetsUrls(socialShareData),
            pageAssetUrls = R.uniq([
                ...assetsFromComponentsMap,
                ...assetsFromStylesheets,
                ...assetsFromSiteSettingsGeneralData,
                ...assetsFromSocialShareData
            ]),
            actionToDispatch: AnyAction = {
                type: DIRECTORY_CONTENTS_STATUS_FETCH_REQUEST,
                payload: R.uniq(pageAssetUrls.map(url => url.replace(path.basename(url), '')))
            };

        return { state: R.merge(epicState, { pageAssetUrls }), actionToDispatch };
    },
    showMissingAssetDialogIfAssetMissing = ({ values: [directoryContents], state: epicState }) => {
        const
            { pageAssetUrls } = epicState,
            missingAssetUrls = pageAssetUrls.filter(url => {
                const
                    { directory, file } = getDirectoryAndFileFromAssetUrl(url),
                    directoryCache = directoryContents[directory];

                return !(directoryCache.entries
                    ? directoryCache.entries.some(entry => encodeDecode(entry.href) === encodeDecode(file))
                    : false
                );
            }),
            replacementState = missingAssetUrls.reduce((acc, url) => R.merge(acc, { [url]: null }), {}),
            assetMissing = missingAssetUrls.length > 0,
            directoryMissing = Object.keys(directoryContents).some(dir => directoryContents[dir].error),
            actionsToDispatch: Action[] = [];

        if (assetMissing) {
            actionsToDispatch.push(sendShownErrorEventToAecAC('missing assets'));
        }

        if (assetMissing || directoryMissing) {
            actionsToDispatch.push(openDialog(missingAssetDialogId));
        }

        return {
            state: R.evolve({
                replacementState: () => replacementState,
                missingAssetUrls: () => missingAssetUrls,
                pageAssetUrls: () => []
            }, epicState),
            multipleActionsToDispatch: actionsToDispatch
        };
    },
    updatedSelectedMissingAssetEpicStateUpdater = (asset: ImageAsset, epicState: MissingAssetEpicState) => {
        const
            { replacementState, assetToReplace } = epicState,
            updatedReplacementState = assetToReplace
                ? { ...replacementState, [assetToReplace]: asset }
                : replacementState,
            saveButtonDisabled = Object.keys(updatedReplacementState)
                .some(assetUrl => R.isNil(updatedReplacementState[assetUrl]));

        return {
            state: R.evolve({
                assetToReplace: () => null,
                saveButtonDisabled: () => saveButtonDisabled,
                replacementState: () => updatedReplacementState
            }, epicState)
        };
    },
    epic = makeEpic({
        defaultState,
        valueActionType,
        updaters: [
            ...[
                PASTE_FROM_SYSTEM_CLIPBOARD,
                when(statusValueActionType, ({ payload: status }) => status === statusTypes.READY)
            ].map(action => {
                return {
                    conditions: [
                        ReceiveOnlyComponentsMap,
                        receiveOnly(stylesheetsValueActionTypes),
                        receiveOnly(generalGlobalDataEpicVAT),
                        receiveOnly(socialShareGlobalDataEpic.valueActionType),
                        receiveOnly(VIDEO_COMPONENT_VAT),
                        receiveOnly(VIDEO_TRANSCODE_STATUS_VAT),
                        action
                    ],
                    reducer: ({
                        values: [componentsMap, stylesheets, siteSettingsGeneralData, socialShareData,
                            videoUploadingList, videoTranscodingList],
                        state: epicState
                    }) => {
                        return getEpicState({
                            componentsMap,
                            stylesheets,
                            siteSettingsGeneralData,
                            socialShareData,
                            videoUploadingList,
                            videoTranscodingList,
                            epicState
                        });
                    }
                };
            }),
            {
                conditions: [DIRECTORY_CONTENTS_STATUS_FETCH_SUCCESS],
                reducer: showMissingAssetDialogIfAssetMissing
            },
            {
                conditions: [actionTypes.MISSING_ASSET_DIALOG_REPLACE_IMAGES_BUTTON_CLICKED],
                reducer: ({ state: epicState }) => ({
                    state: R.merge(epicState, { showUploadButtons: true }),
                    actionToDispatch: updateDialog(missingAssetDialogId)
                })
            },
            {
                conditions: [actionTypes.MISSING_ASSET_DIALOG_UPLOAD_BUTTON_CLICKED],
                reducer: ({ values: [assetToReplace], state: epicState }) => {
                    let fileExtention = assetToReplace.slice(assetToReplace.lastIndexOf('.') + 1).toLowerCase(),
                        params: Record<string, any> = { onSaveAction: actionTypes.MISSING_ASSET_DIALOG_IMAGE_CHOSEN },
                        actionCreator;
                    switch (fileExtention) {
                        case 'svg': actionCreator = openSvgChooserAction; break;
                        case 'mp4':
                            actionCreator = openVideoChooserAction;
                            params = {
                                ...params,
                                hideFreeOneComImagesTab: false,
                                hideShutterstockImagesTab: true,
                                hideMyImagesTab: true,
                                activeTabId: FmTabId.MY_VIDEOS
                            };
                            break;
                        default: actionCreator = openImageChooserAction;
                    }
                    return { state: R.merge(epicState, { assetToReplace }), actionToDispatch: actionCreator(params) };
                },
            },
            {
                conditions: [actionTypes.MISSING_ASSET_DIALOG_IMAGE_CHOSEN],
                reducer: ({ values: [{ asset }], state: epicState }) =>
                    updatedSelectedMissingAssetEpicStateUpdater(asset, epicState)
            },
            {
                conditions: [actionTypes.MISSING_ASSET_DIALOG_SAVE_BUTTON_CLICKED],
                reducer: ({ state: epicState }) => {
                    const replacementState: Record<string, any> = epicState.replacementState;

                    const multipleActionsToDispatch = [
                        { type: STYLESHEET_REPLACE_MISSING_ASSETS, payload: replacementState },
                        { type: SOCIAL_SHARE_REPLACE_MISSING_ASSET, payload: replacementState },
                        { type: GENERAL_INFO_REPLACE_MISSING_ASSETS, payload: replacementState },
                        { type: TEMPLATE_REPLACE_MISSING_ASSET, payload: replacementState },
                        { type: 'REPLACE_ACTIONS_DISPATCHED' },
                        { type: COMPONENTS_MAP_REPLACE_MISSING_ASSETS, payload: replacementState },
                    ];

                    return {
                        state: {
                            ...epicState,
                            missingAssetUrls: []
                        },
                        multipleActionsToDispatch
                    };
                }
            },
            {
                conditions: [actionTypes.MISSING_ASSET_DIALOG_DELETE_ASSETS_BUTTON_CLICKED],
                reducer: ({ state: epicState }) => {
                    const missingAssetUrls: Array<string> = epicState.missingAssetUrls;

                    const multipleActionsToDispatch = [
                        { type: STYLESHEET_DELETE_MISSING_ASSETS, payload: missingAssetUrls },
                        { type: GENERAL_INFO_DELETE_MISSING_ASSETS, payload: missingAssetUrls },
                        { type: TEMPLATE_DELETE_MISSING_ASSET, payload: missingAssetUrls },
                        { type: SOCIAL_SHARE_DELETE_MISSING_ASSET, payload: missingAssetUrls },
                        { type: 'DELETE_ACTIONS_DISPATCHED' },
                        { type: COMPONENTS_MAP_DELETE_MISSING_ASSETS, payload: missingAssetUrls },
                    ];

                    return { state: epicState, multipleActionsToDispatch };
                }
            },
            ...['REPLACE_ACTIONS_DISPATCHED', 'DELETE_ACTIONS_DISPATCHED'].map(action => {
                return {
                    conditions: [action],
                    reducer: ({ state }) => {
                        return {
                            state,
                            actionToDispatch: { type: actionTypes.MISSING_ASSET_DIALOG_CLOSE_DIALOG }
                        };
                    }
                };
            }),
            {
                conditions: [actionTypes.MISSING_ASSET_DIALOG_CLOSE_DIALOG],
                reducer: ({ state }) => ({ state, actionToDispatch: closeMissingAssetsDialogAction() })
            },
            {
                conditions: [actionTypes.MISSING_ASSET_REPLACE_IMAGE],
                reducer: ({ values: [{ asset }], state: epicState }) => {
                    return { state: {
                        ...epicState,
                        missingAssetUrls: epicState.missingAssetUrls.filter(url => url !== asset.url)
                    } };
                }
            },
            {
                conditions: [VIDEO_FILE_UPDATE],
                reducer: ({ values: [{ asset }], state: epicState }) => {
                    return { state: {
                        ...epicState,
                        missingAssetUrls: epicState.missingAssetUrls.filter(url => url !== asset.url)
                    } };
                }
            },
            {
                keepFullActions: true,
                conditions: [PAGE_DATASET_SAVE_FAILURE],
                reducer: ({ values: [action], dispatchAsync }) => {
                    // 409 Conflict is taken care of generically by api/errorHandler
                    if (isConflictErrorAction(PAGE_DATASET_SAVE_FAILURE, action)) {
                        return { state: defaultState };
                    }
                    dispatchAsync({ type: CLOSE_DIALOG });
                    setTimeout(() => dispatchAsync(openDialog(appDialogIds.SiteDataSaveFailureDialogId)));
                    return {
                        state: defaultState,
                    };
                }
            }
        ]
    }),
    reducerFactory = ({ prop, defaultValue }) => makeStateSelectorReducer(
        epic.reducer,
        valueActionType,
        R.pipe(R.path([prop]), R.defaultTo(defaultValue))
    ),
    missingAssetUrls = reducerFactory({ prop: 'missingAssetUrls', defaultValue: [] });

export {
    epic as default,
    missingAssetUrls,
    defaultState
};
