import React from "react";
import { useDispatch } from "react-redux";
import styles from "./note.css";
import DialogCloseBtn from "../../../../view/common/dialogs/baseDialog/DialogCloseBtn";
import { Msg } from "../../../../view/intl";
import { BOOKINGS_CLOSE_NOTE } from "../actionTypes";

export const BookingsTryForFreeNote = () => {
    const dispatch = useDispatch();

    return (
        <div className={styles.note} data-testid="note-container">
            <Msg k="component.bookings.tryForFreeNote">
                Try for free to edit and manage your services.
            </Msg>
            <DialogCloseBtn
                className={styles.closeBtn}
                onClick={() => {
                    dispatch({ type: BOOKINGS_CLOSE_NOTE });
                }}
            />
        </div>
    );
};
