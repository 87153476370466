import * as React from 'react';
import cx from "classnames";
import styles from './TermsForTrial.css';
import { TooltipCom } from "../../../src/components/presentational/Tooltip/TooltipCom";
import type { ReactElementRef } from "../../../src/globalTypes";
import Msg from "../../../src/view/intl/Msg";
import { DemoLoginMessages } from "../login/main/messages";
import type { TermsForTrialTipProps, TermsForTrialState } from "../login/types";
import { TooltipPosition } from "../../../src/components/presentational/Tooltip/constants";
import { isCompactScreen, isMobileDevice } from "../login/validators";
import { partnerIsCheckdomain } from '../../../../server/shared/partners/PartnerName.js';
import { getAppConfig } from "../../../src/components/App/epics/appConfig/appConfig.js";

const DisplayTrialTerms = ({
    intl,
    onTipClose,
    targetRef
}) => {
    const
        {
            termsForTrialTipHeader,
            termsForTrialTipDeleteTerm,
            termsForTrialTipSubscriptionTerm,
            termsForTrialTipEmailTerm,
            termsForTrialTipPersonalDataDisclaimer
        } = DemoLoginMessages,
        terms = [termsForTrialTipDeleteTerm, termsForTrialTipSubscriptionTerm, termsForTrialTipEmailTerm],
        appConfig = getAppConfig(),
        trialConfig = appConfig.oneWeb.trial;

    if (partnerIsCheckdomain(trialConfig.partnerName)) {
        terms.push(termsForTrialTipPersonalDataDisclaimer);
    }

    return (
        <div className={styles.termContainer} ref={targetRef}>
            <div className={styles.crossIcon} onClick={onTipClose} />
            <div className={cx(styles.termsHeading, {
                [styles.termsHeadingForMobile]: isMobileDevice() && isCompactScreen()
            })}
            >
                {intl.msgJoint(termsForTrialTipHeader)}
            </div>
            <div className={styles.termBoxContainer}>
                {
                    terms.map(term => {
                        return (
                            <div className={styles.termLabelContainer} key={term}>
                                <span className={styles.checkMarkIcon} />
                                {intl.msgJoint(term) }
                            </div>
                        );
                    })
                }
            </div>
        </div>
    );
};

export class TermsForTrialTip extends React.Component<TermsForTrialTipProps, TermsForTrialState> {
    state = { show: false };
    timer: undefined | ReturnType<typeof setTimeout> = undefined;

    ref:ReactElementRef<HTMLSpanElement>;
    targetRef: ReactElementRef<HTMLDivElement>;

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    componentDidMount(): any {
        document.addEventListener('click', this.handleClickOutside, true);
    }

    constructor() {
        // @ts-ignore
        super();
        this.ref = React.createRef();
        this.targetRef = React.createRef();
    }

    handleClickOutside = (event: Event) => {
        // @ts-ignore
        if (this.targetRef && this.targetRef.current && !this.targetRef.current.contains(event.target)) {
            this.toolTipClose(event);
        }
    };

    toolTipOpen = (event: Event) => {
        event.stopPropagation();
        this.timer = undefined;
        this.setState({ show: true });
    };

    toolTipClose = (event: Event) => {
        event.stopPropagation();
        this.setState({ show: false });
    };

    render() {
        const
            { show } = this.state,
            {
                intl,
                ...restProps
            } = this.props,
            tooltipProps = {
                theme: {
                    // eslint-disable-next-line max-len
                    container: isMobileDevice() && isCompactScreen() ? styles.termsForTrialTipContainerForMobile : styles.termsForTrialTipContainer,
                    pointer: styles.termsForTrialTipPointer,
                    tipInfo: styles.termsForTrialTipInfo
                },
                isSmallTipInfo: false,
                ...restProps,
                show,
                targetRef: this.ref,
                distance: 2,
                position: TooltipPosition.TOP,
            };

        return (
            <React.Fragment>
                <span
                    // @ts-ignore
                    onClick={this.toolTipOpen}
                    ref={this.ref}
                >
                    <Msg k="demo.signup.termsAndCondition.termsForTrial">Terms for trial</Msg>
                </span>
                <TooltipCom {...tooltipProps} onClick={(event) => { event.stopPropagation(); }}>
                    <DisplayTrialTerms
                        intl={intl}
                        onTipClose={this.toolTipClose}
                        targetRef={this.targetRef}
                    />
                </TooltipCom>
            </React.Fragment>
        );
    }
}
