import * as R from "ramda";
import valueActionType from './valueActionType';
import { makeSiteSettingsChildEpic } from '../../../App/epics/siteSettings/makeSiteSettingsChildEpic';
import { SITE_SETTINGS_MIGRATE_HOOK, SAVE_SITE_SETTINGS } from '../../../App/epics/siteSettings/actionTypes';
import {
    GENERAL_GLOBAL_DATA_ADDRESS_CHANGED,
    GENERAL_GLOBAL_DATA_EMAIL_CHANGED,
    GENERAL_GLOBAL_DATA_PHONE_CHANGED,
    GENERAL_GLOBAL_DATA_SET_CONTACT_EMAIL,
    GENERAL_GLOBAL_DATA_SET_PHONE_NUMBER,
    GENERAL_GLOBAL_DATA_SET_OPENING_HOURS,
    GENERAL_GLOBAL_DATA_UPDATE_GMB_CATEGORIES,
    GENERAL_GLOBAL_DATA_TOGGLE_VIRTUAL_ADDRESS,
    GENERAL_GLOBAL_DATA_POSTAL_CODE_FETCH_SUCCESS,
} from './actionTypes';
import {
    GENERAL_INFO_DELETE_MISSING_ASSETS,
    GENERAL_INFO_REPLACE_MISSING_ASSETS,
    GENERAL_INFO_WEBSITE_TITLE_INPUT_CHANGED,
    GENERAL_INFO_WEBSITE_TITLE_INPUT_CHANGED_FROM_GMB_CREATE_STEP,
    GENERAL_INFO_WEBSITE_DESCRIPTION_CHANGED,
    GENERAL_INFO_LOGO_CHOOSEN,
    GENERAL_REMOVE_LOGO_PRESSED,
    GENERAL_INFO_LOGO_ALT_TEXT_INPUT_CHANGED,
    GENERAL_INFO_FAVICON_CHOOSEN,
    GENERAL_REMOVE_FAVICON_PRESSED,
    GENERAL_INFO_ADDRESS_NAME_INPUT_CHANGED,
    GENERAL_INFO_ADDRESS_STREET_ADDRESS_INPUT_CHANGED,
    GENERAL_INFO_ADDRESS_ZIP_INPUT_CHANGED,
    GENERAL_INFO_ADDRESS_CITY_INPUT_CHANGED,
    GENERAL_INFO_ADDRESS_AREA_INPUT_CHANGED,
    GENERAL_INFO_ADDRESS_COUNTRY_CODE_CHANGED,
    GENERAL_INFO_ADDRESS_GOOGLE_PLACE_RESPONSE,
    GENERAL_INFO_ADDRESS_CHANGED,
} from '../actionTypes';
import type { ImageAsset } from "../../../App/flowTypes";
import {
    SET_MVE_HEADER_LOGO_ACTION,
    UNSET_MVE_HEADER_LOGO_ACTION,
    CHANGE_MVE_WEBSITE_TITLE_ACTION
} from '../../../MobileViewEditor/header/propertiesPanel/actions';
import { withSelector, receiveOnly, optionalReceiveOnly } from '../../../../epics/makeCondition';
import makeStateSelectorReducer from '../../../../epics/makeStateSelectorReducer';
import {
    LOGO_PP_WEBSITE_TITLE_CHANGED,
    LOGO_PP_LOGO_ASSET_CHOOSEN,
    LOGO_PP_REMOVE_LOGO_ASSET_PRESSED,
    LOGO_MCTA_LOGO_ASSET_CHOOSEN,
    LOGO_ASSET_UPDATE_ON_REPLACE
} from '../../../oneweb/Logo/actionTypes';
import { ONBOARDING_STATE_UPDATED } from '../../../Onboarding/actionTypes';
import { makeAddressUrl } from '../../../oneweb/TextLike/Address/addressFormats';
import type {
    AddressLocation,
    AddressViewport
} from '../../../googleMaps/googleMaps';
import { getPinCodeUsingGeocodeAction } from '../../../googleMaps/googleMaps';
import { OWNER_INFO_SUCCESS } from '../../../App/actionTypes';
import {
    GlobalVariableAddressNameKey,
    GlobalVariableAddressStreetAddressKey,
    GlobalVariableAddressZipKey,
    GlobalVariableAddressCityKey,
    GlobalVariableAddressAreaKey,
    GlobalVariableAddressCountryCodeKey,
    GlobalVariableAddressUrlKey,
} from '../../../../constants';
import {
    openingHoursWithGmbTimeToMsTime,
    siteSettingsOpeningHoursMapToOpeningHours
} from '../../../oneweb/OpeningHours/utils';
import type { OpeningHour, SiteSettingsOpeningHoursMap } from '../../../oneweb/OpeningHours/flowTypes';
import { COPY_GMB_DATA_TO_GENERAL_DATA } from "../../BusinessListing/GoogleMyBusiness/actionTypes";
import type { GmbDataType } from '../../BusinessListing/flowTypes';
import type { EpicUpdater } from '../../../../epics/flowTypes';
import { getDefultLocaleId } from "../../../Locale/getDefaultLocale";
import { WORKSPACE_READY } from "../../../Workspace/actionTypes";
import {
    openSiteSettingsDialog,
    getSiteSettingsActiveTabId,
    clearSiteSettingsActiveTabId
} from "../../SiteSettingsDialog/actionCreators";
import { type MobileSiteSettings } from "../../../Mobile/types";
import { MobileHeaderTitleType } from '../../../Mobile/header/constants';
import { DYNAMIC_TEMPLATE_SINGLE_PAGE_IMPORT, POST_DYNAMIC_TEMPLATE_SUCCESS } from "../../../TemplateSelector_DEPRECATED/actionTypes";
import DYNAMIC_TEMPLATE_EPIC from "../../../Onboarding/Dynamic/Epic/valueActionType";
import { validGmbKeyList } from "../../../Onboarding/Dynamic/validGmbKeyList";

const DEFAULT_LOCALE = getDefultLocaleId();

export type GeneralGlobalData = {
    name?: string,
    logoAsset?: ImageAsset,
    favicon?: ImageAsset,
    logoAltText?: string | null,
    websiteTitle?: string | null,
    websiteDescription?: string | null,
    phoneNumber?: string | null,
    contactEmail?: string | null,
    audienceLocale?: string | null,

    hasPhysicalAddress?: boolean | null,
    address?: string,
    addressUrl?: string,

    addressName?: string,
    addressId?: string,
    addressPlaceId?: string,
    addressLocation?: AddressLocation,
    addressViewport?: AddressViewport,
    addressStreetAddress?: string,
    addressCity?: string,
    addressArea?: string | null,
    addressZip?: string,
    addressCountry?: string,
    addressCountryCode?: string,
    addressFloor?: string,
    addressStreetNumber?: number,
    addressStreetName?: string,
    isVirtualAddress?: boolean,

    gmbCategories?: string,
    websiteCategories?: string,

    googleMapSrc?: string,

    openingHours?: OpeningHour[],
    mobile?: { logo: { show: boolean } }
}

export const generalGlobalDataInitialState: GeneralGlobalData = {
    hasPhysicalAddress: false,
    isVirtualAddress: false
};

const
    logoAssetChoosenReducer = (state, { payload: { asset: logoAsset } }) => ({ ...state, logoAsset }),
    logoAssetRemovedReducer = state => ({ ...state, logoAsset: null }),
    faviconChoosenReducer = (state, { payload: { asset: favicon } }) => ({ ...state, favicon }),
    faviconRemovedReducer = state => ({ ...state, favicon: null });

export const addressFields = [
    'address',
    GlobalVariableAddressUrlKey,
    GlobalVariableAddressNameKey,
    'addressId',
    'addressPlaceId',
    'addressLocation',
    'addressViewport',
    GlobalVariableAddressStreetAddressKey,
    GlobalVariableAddressCityKey,
    GlobalVariableAddressAreaKey,
    GlobalVariableAddressZipKey,
    GlobalVariableAddressCountryCodeKey,
    'addressFloor',
];

export const defaultAddressFieldsState = {
    address: '',
    addressUrl: '',
    audienceLocale: DEFAULT_LOCALE,
    addressName: '',
    addressId: '',
    addressPlaceId: '',
    addressLocation: { lat: 0, lng: 0 },
    addressViewport: { south: 0, west: 0, north: 0, east: 0 },
    addressStreetAddress: '',
    addressCity: '',
    addressArea: '',
    addressZip: '',
    addressCountryCode: '',
    addressFloor: '',
};

const makeReducer = field => (state, { payload }) => ({ ...state, [field]: payload });
const makeUpdateAddressReducer = field => (state, { payload }) => {
    const nextState = ({ ...state, [field]: payload });

    return { ...nextState, addressUrl: makeAddressUrl(nextState) };
};

export const updateMobileGlobalDataOnTemplateImport =
    ({ logoAsset, websiteTitle = "" }: { logoAsset: ImageAsset | null | undefined, websiteTitle: string }) =>
        (state: MobileSiteSettings) => {
            const updatedValue = {
                title: { show: R.F, type: () => MobileHeaderTitleType.PAGE },
                logo: { show: R.F }
            };

            if (logoAsset) {
                updatedValue.logo.show = R.T;
            }

            if (websiteTitle && websiteTitle.trim()) {
                updatedValue.title = { show: R.T, type: () => MobileHeaderTitleType.WEBSITE };
            }
            if (!updatedValue.logo.show() && !updatedValue.title.show()) {
                updatedValue.logo.show = R.T;
            }

            return R.evolve(updatedValue, state);
        };

export const generalGlobalDataEpic = makeSiteSettingsChildEpic/* ::<GeneralGlobalData, empty> */({
    valueActionType,
    key: 'generalData',
    defaultState: generalGlobalDataInitialState,
    dispatchOutside: true,
    undo: true,
    updaters: ([
        {
            conditions: [WORKSPACE_READY],
            reducer: ({ state }) => {
                const payload = getSiteSettingsActiveTabId();
                if (payload) {
                    clearSiteSettingsActiveTabId();

                    return {
                        state,
                        actionToDispatch: openSiteSettingsDialog(JSON.parse(payload)),
                    };
                }

                return {
                    state
                };
            }
        },
        {
            conditions: [
                optionalReceiveOnly(OWNER_INFO_SUCCESS),
                SITE_SETTINGS_MIGRATE_HOOK,
            ],
            reducer: ({ state, values: [ownerInfo] }) => {
                let nextState = state;
                if (state.addressStreetNumber) {
                    const { addressStreetNumber, addressStreetName, ...rest } = state;
                    nextState = {
                        ...rest,
                        addressStreetAddress: addressStreetName + ', ' + addressStreetNumber
                    };
                }

                if (!state.addressCountryCode && ownerInfo && ownerInfo.country) {
                    nextState = { ...nextState, addressCountryCode: ownerInfo.country };
                }

                if (nextState === state) {
                    return { state };
                } else {
                    return {
                        state: nextState,
                        updateReason: SITE_SETTINGS_MIGRATE_HOOK
                    };
                }
            }
        }, {
            // This in case OWNER_INFO comes later then site settings
            conditions: [
                OWNER_INFO_SUCCESS,
                receiveOnly(SITE_SETTINGS_MIGRATE_HOOK),
            ],
            reducer: ({ state, values: [ownerInfo] }) => {
                if (state.addressCountryCode) {
                    return {
                        state
                    };
                }
                return {
                    state: { ...state, addressCountryCode: ownerInfo.country },
                    actionToDispatch: { type: SAVE_SITE_SETTINGS },
                    updateReason: OWNER_INFO_SUCCESS
                };
            }
        }, {
            conditions: [
                withSelector(valueActionType, s => s.addressName),
                withSelector(valueActionType, s => s.addressStreetAddress),
                withSelector(valueActionType, s => s.addressZip),
                withSelector(valueActionType, s => s.addressCity),
                withSelector(valueActionType, s => s.addressArea),
                withSelector(valueActionType, s => s.addressCountryCode),
            ],
            reducer: ({ state }) => {
                const addressMapKeys = [
                    GlobalVariableAddressNameKey,
                    GlobalVariableAddressStreetAddressKey,
                    GlobalVariableAddressZipKey,
                    GlobalVariableAddressCityKey,
                    GlobalVariableAddressAreaKey,
                    GlobalVariableAddressCountryCodeKey,
                    GlobalVariableAddressUrlKey,
                ];
                const payload = addressMapKeys.reduce((acc, k) => ({ ...acc, [k]: state[k] || '' }), {});
                return { state, actionToDispatch: ({ type: GENERAL_GLOBAL_DATA_ADDRESS_CHANGED, payload } as AnyAction) };
            },
        }, {
            conditions: [withSelector(valueActionType, s => s.contactEmail)],
            reducer: ({ state, values: [payload] }) => ({
                state, actionToDispatch: ({ type: GENERAL_GLOBAL_DATA_EMAIL_CHANGED, payload } as AnyAction)
            }),
        }, {
            conditions: [withSelector(valueActionType, s => s.phoneNumber)],
            reducer: ({ state, values: [payload] }) => ({
                state, actionToDispatch: ({ type: GENERAL_GLOBAL_DATA_PHONE_CHANGED, payload } as AnyAction),
            }),
        }, {
            conditions: [ONBOARDING_STATE_UPDATED],
            reducer: ({ state, values: [onboardingState] }) => {
                const newState = { ...state, ...onboardingState };
                const { logoAsset, websiteTitle } = newState;
                newState.mobile = { logo: { show: false }, ...newState.mobile, };
                newState.mobile = updateMobileGlobalDataOnTemplateImport({ logoAsset, websiteTitle })(newState.mobile);
                return {
                    state: newState,
                    updateReason: ONBOARDING_STATE_UPDATED,
                    actionToDispatch: { type: SAVE_SITE_SETTINGS },
                };
            },
        },
        {
            conditions: [
                receiveOnly(DYNAMIC_TEMPLATE_EPIC),
                DYNAMIC_TEMPLATE_SINGLE_PAGE_IMPORT
            ],
            reducer: ({ state, values: [{ businessName, gmbKey, contactInfo }, { designSiteSettings }] }) => {
                const { logoAsset } = state;
                const isValidGmbKey = validGmbKeyList.includes(gmbKey);
                const newState = {
                    ...state,
                    websiteTitle: businessName,
                    gmbCategories: isValidGmbKey ? gmbKey : '',
                    websiteCategories: gmbKey,
                    phoneNumber: contactInfo?.phone,
                    contactEmail: contactInfo?.email,
                    address: contactInfo?.address,
                    ...(contactInfo?.completeAddress || {})
                };
                newState.mobile = updateMobileGlobalDataOnTemplateImport({ logoAsset, websiteTitle: businessName })(newState.mobile);
                if (
                    !newState.openingHours &&
                    designSiteSettings?.generalData?.openingHours?.length
                ) {
                    newState.openingHours = designSiteSettings.generalData.openingHours;
                }

                return {
                    state: newState,
                    updateReason: DYNAMIC_TEMPLATE_SINGLE_PAGE_IMPORT,
                };
            },
        },
        {
            conditions: [
                POST_DYNAMIC_TEMPLATE_SUCCESS
            ],
            reducer: ({ state, values: [{ siteSettings }] }) => {
                const { generalData } = siteSettings;
                return {
                    state: { ...state, ...generalData },
                    updateReason: POST_DYNAMIC_TEMPLATE_SUCCESS,
                };
            },
        },
        {
            conditions: [GENERAL_INFO_ADDRESS_CHANGED],
            reducer: ({ values: [address], state }) => {
                return {
                    state: {
                        ...state,
                        ...addressFields.reduce((a, f) => ({ ...a, [f]: defaultAddressFieldsState[f] }), {}),
                        address,
                    },
                    updateReason: GENERAL_INFO_ADDRESS_CHANGED
                };
            }
        },
        {
            conditions: [GENERAL_GLOBAL_DATA_TOGGLE_VIRTUAL_ADDRESS],
            reducer: ({ state }) => {
                return {
                    state: {
                        ...state,
                        isVirtualAddress: !state.isVirtualAddress
                    },
                    updateReason: GENERAL_GLOBAL_DATA_TOGGLE_VIRTUAL_ADDRESS
                };
            }
        },
        {
            conditions: [COPY_GMB_DATA_TO_GENERAL_DATA],
            reducer: ({ values: [gmbData], state }: { values: [GmbDataType], state: GeneralGlobalData }) => {
                const { addressFields, openingHours: { periods }, ...rest } = gmbData;
                return {
                    state: {
                        ...state,
                        ...addressFields,
                        ...(periods.length ? { openingHours: openingHoursWithGmbTimeToMsTime(periods) } : {}),
                        ...rest,
                    },
                    updateReason: GENERAL_INFO_ADDRESS_CHANGED
                };
            }
        },
        {
            conditions: [GENERAL_INFO_ADDRESS_GOOGLE_PLACE_RESPONSE],
            reducer: ({ values: [address], state, dispatchAsync }) => {
                const
                    { websiteTitle } = state,
                    { addressName, addressZip } = address;
                if (!addressZip) {
                    const latlng = {
                        lat: parseFloat(address.addressLocation.lat),
                        lng: parseFloat(address.addressLocation.lng),
                    };
                    /* Make this action conditionally if not used for GMB.
                     * Currently this updater is used only for GMB and fetching postalCode is important in GMB create.
                    **/
                    getPinCodeUsingGeocodeAction({
                        dispatch: dispatchAsync,
                        geoCodeObj: { location: latlng },
                        dispatchActionType: GENERAL_GLOBAL_DATA_POSTAL_CODE_FETCH_SUCCESS
                    });
                }
                /* Address Name is set as website title always to match the user entered company name in GMB create flow.
                *  This may not be true for connect flow. Some address will have the company name as address already and
                *  we need to use it.
                *  ie, addressName is not set to website title */
                return {
                    state: {
                        ...state,
                        ...address,
                        addressName: websiteTitle || addressName
                    },
                    updateReason: GENERAL_INFO_ADDRESS_GOOGLE_PLACE_RESPONSE
                };
            }
        },
        {
            conditions: [GENERAL_INFO_WEBSITE_TITLE_INPUT_CHANGED_FROM_GMB_CREATE_STEP],
            reducer: ({ values: [websiteTitle], state }) => {
                return {
                    state: {
                        ...state,
                        websiteTitle,
                        addressName: websiteTitle
                    },
                    updateReason: GENERAL_INFO_WEBSITE_TITLE_INPUT_CHANGED_FROM_GMB_CREATE_STEP
                };
            }
        },
        {
            conditions: [GENERAL_GLOBAL_DATA_POSTAL_CODE_FETCH_SUCCESS],
            reducer: ({ values: [{ addressZip }], state }) => {
                return {
                    state: { ...state, addressZip },
                    updateReason: GENERAL_GLOBAL_DATA_POSTAL_CODE_FETCH_SUCCESS
                };
            }
        },
        {
            conditions: [GENERAL_INFO_DELETE_MISSING_ASSETS],
            reducer: ({ values: [missingAssets], state }) => {
                let updatedState = { ...state };

                if (state.logoAsset && state.logoAsset.url && missingAssets.indexOf(state.logoAsset.url) > -1) {
                    delete updatedState.logoAsset;
                }

                if (state.favicon && state.favicon.url && missingAssets.indexOf(state.favicon.url) > -1) {
                    delete updatedState.favicon;
                }

                return {
                    state: updatedState,
                    updateReason: GENERAL_INFO_DELETE_MISSING_ASSETS
                };
            }
        },
        {
            conditions: [GENERAL_INFO_REPLACE_MISSING_ASSETS],
            reducer: ({ values: [replacements], state }) => {
                let updatedState = { ...state };

                if (state.logoAsset && state.logoAsset.url && replacements[state.logoAsset.url]) {
                    updatedState.logoAsset = replacements[state.logoAsset.url];
                }

                if (state.favicon && state.favicon.url && replacements[state.favicon.url]) {
                    updatedState.favicon = replacements[state.favicon.url];
                }

                return {
                    state: updatedState,
                    updateReason: GENERAL_INFO_REPLACE_MISSING_ASSETS
                };
            }
        }
    ] as Array<EpicUpdater<GeneralGlobalData, {}, string>>),
    handleActions: {
        // MVE
        [SET_MVE_HEADER_LOGO_ACTION]: logoAssetChoosenReducer,
        [UNSET_MVE_HEADER_LOGO_ACTION]: logoAssetRemovedReducer,
        [CHANGE_MVE_WEBSITE_TITLE_ACTION]: makeReducer('websiteTitle'),

        // SITE SETTINGS GENERAL INFO TAB
        [GENERAL_GLOBAL_DATA_SET_OPENING_HOURS]: (state, { payload: openingHoursMap }: { payload: SiteSettingsOpeningHoursMap }) => {
            return {
                ...state,
                openingHours: siteSettingsOpeningHoursMapToOpeningHours(openingHoursMap),
            };
        },
        [GENERAL_GLOBAL_DATA_UPDATE_GMB_CATEGORIES]: makeReducer('gmbCategories'),
        [GENERAL_GLOBAL_DATA_SET_CONTACT_EMAIL]: makeReducer('contactEmail'),
        [GENERAL_GLOBAL_DATA_SET_PHONE_NUMBER]: makeReducer('phoneNumber'),
        [GENERAL_INFO_WEBSITE_TITLE_INPUT_CHANGED]: makeReducer('websiteTitle'),
        [GENERAL_INFO_WEBSITE_DESCRIPTION_CHANGED]: makeReducer('websiteDescription'),
        [GENERAL_INFO_LOGO_CHOOSEN]: logoAssetChoosenReducer,
        [GENERAL_INFO_LOGO_ALT_TEXT_INPUT_CHANGED]: makeReducer('logoAltText'),
        [LOGO_ASSET_UPDATE_ON_REPLACE]: logoAssetChoosenReducer,
        [GENERAL_REMOVE_LOGO_PRESSED]: logoAssetRemovedReducer,
        [GENERAL_INFO_FAVICON_CHOOSEN]: faviconChoosenReducer,
        [GENERAL_REMOVE_FAVICON_PRESSED]: faviconRemovedReducer,
        [GENERAL_INFO_ADDRESS_NAME_INPUT_CHANGED]: makeUpdateAddressReducer('addressName'),
        [GENERAL_INFO_ADDRESS_STREET_ADDRESS_INPUT_CHANGED]: makeUpdateAddressReducer('addressStreetAddress'),
        [GENERAL_INFO_ADDRESS_ZIP_INPUT_CHANGED]: makeUpdateAddressReducer('addressZip'),
        [GENERAL_INFO_ADDRESS_CITY_INPUT_CHANGED]: makeUpdateAddressReducer('addressCity'),
        [GENERAL_INFO_ADDRESS_AREA_INPUT_CHANGED]: makeUpdateAddressReducer('addressArea'),
        [GENERAL_INFO_ADDRESS_COUNTRY_CODE_CHANGED]: (state, { payload }) => {
            const nextState = ({ ...state, addressCountryCode: payload, addressArea: null });

            return { ...nextState, addressUrl: makeAddressUrl(nextState) };
        },

        // LOGO COMPONENT
        [LOGO_PP_WEBSITE_TITLE_CHANGED]: makeReducer('websiteTitle'),
        [LOGO_MCTA_LOGO_ASSET_CHOOSEN]: logoAssetChoosenReducer,
        [LOGO_PP_LOGO_ASSET_CHOOSEN]: logoAssetChoosenReducer,
        [LOGO_PP_REMOVE_LOGO_ASSET_PRESSED]: logoAssetRemovedReducer
    }
});

export const AudienceLocaleSelector =
    withSelector(generalGlobalDataEpic.valueActionType, ({ audienceLocale }) => audienceLocale);

const makePropReducer = prop => makeStateSelectorReducer(
    generalGlobalDataEpic.reducer,
    generalGlobalDataEpic.valueActionType,
    state => state[prop] || null
);

export const logoAssetReducer = makePropReducer('logoAsset');
