import { makeCombineReducer, makeComponentBaseReducers, makeDefaultStateReducers } from "../../../../../redux/makeReducer";
import { POST_DEFAULT_HEIGHT, POST_DEFAULT_WIDTH } from "../constants";
import PostKind from "../kind";

export default makeCombineReducer({
    combineReducers: {
        ...makeComponentBaseReducers(PostKind),

        // component has these default dimensions when inserted
        ...makeDefaultStateReducers({
            width: POST_DEFAULT_WIDTH,
            height: POST_DEFAULT_HEIGHT,
            blogId: "",
        })
    },
    handleActions: {}
});
