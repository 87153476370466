export const getPoliciesScriptTemplate = (privacyPolicy) => `
    (function (){
        if (typeof policiesDialogOnClose === "undefined") {
            function policiesDialogOnClose() {
                oneJQuery(".policiesDialogVisibilityContainer").hide();
                oneJQuery(".policiesDialogVisibilityContainer .policiesDialogBody").children().remove();
            }

            oneJQuery(window).on("load", function() {
                if (oneJQuery(".openPrivacyPolicyDialog").length) {
                    oneJQuery(".openPrivacyPolicyDialog").on("click", function () {
                        oneJQuery(".policiesDialogVisibilityContainer .policiesDialogBody").children().remove();
                        oneJQuery(".policiesDialogVisibilityContainer").show();
                        oneJQuery(".policiesDialogVisibilityContainer .policiesDialogBody").html(${privacyPolicy}.content);
                    });

                    oneJQuery(".policiesDialogVisibilityContainer .policiesDialogClose").on("click", policiesDialogOnClose);
                    oneJQuery(".policiesDialogVisibilityContainer .policiesDialogFooterBtn").on("click", policiesDialogOnClose);
                }
            });
        }
    })();
`;
