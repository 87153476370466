import { FcContentTypes } from "../../../redux/modules/children/fileChooser/FcContentTypes";
import { supportedDocumentRegex } from "../../oneweb/Document/constants";
import Resource from "../../../redux/modules/children/fileChooser/Resource";
import { imageChooserSupportedImagesMap } from "../../../../../src/dashboard/src/components/ImageChooser/config";

const supportedImagesRegex = new RegExp(FcContentTypes.SUPPORTED_IMAGE, 'i');
const supportedSvgRegex = new RegExp(FcContentTypes.SVG, 'i');

const makeRegex = (element: string) => {
    return new RegExp(element, 'i');
};

export const
    getUnsupportedFiles = (files: FileList) => {
        const invalidFiles: Array<string> = [];
        for (const file of files) {
            if (supportedDocumentRegex.test(file.name) || supportedImagesRegex.test(file.type)) {
                continue;
            }
            invalidFiles.push(file.name);
        }
        return invalidFiles;
    },
    getSvgFiles = (files: Array<Resource>): Array<Resource> => {
        return files.filter((file: Resource) => supportedSvgRegex.test(file.contentType));
    },
    getImageFiles = (files: Array<Resource>): Array<Resource> => {
        return files.filter((file: Resource) => supportedImagesRegex.test(file.contentType));
    },
    getDocFiles = (files: Array<Resource>): Array<Resource> => {
        // @ts-ignore
        return files
            .filter(file => (
                supportedDocumentRegex.test(file.getName())
                && !supportedSvgRegex.test(file.contentType)
                && !supportedImagesRegex.test(file.contentType)
            ))
            .map((file: Resource) => file.getWebSpaceProtocolRelativePath());
    },
    hasDocFile = (files: Array<Resource>): boolean => Object
        .keys(files)
        .some(i => (
            supportedDocumentRegex.test(files[i].name)
            && !supportedImagesRegex.test(files[i].type)
        )),
    isValidImageFiles = (files: Array<Resource>, source: string = ''): boolean => Object
        .keys(files).every(i => {
            if (source) {
                return makeRegex(imageChooserSupportedImagesMap[source]).test(files[i].type);
            }
            return supportedImagesRegex.test(files[i].type);
        });
