import React from 'react';
import getAccessManager from '../../../../../getAccessManager';
import Page from '../../../../PropertiesPanel/view/PropertiesPage';
import * as pagesIds from './ids';
import pagesTitles from './titles';
import type { Props } from '../flowTypes';
import NavigationGroup from "../../../../PropertiesPanel/view/IntlNavigationGroup";

const
    id = pagesIds.MAIN,
    title = pagesTitles[id],
    view = ({ navigateToPage }: Props) => {
        const pageIds = [
            pagesIds.STYLE,
            pagesIds.BUTTONS,
            pagesIds.EMAILSETTINGS,
            pagesIds.SUCCESSMESSAGE
        ];

        if (getAccessManager().isContactFormCaptchaEnabled()) {
            pageIds.push(pagesIds.CAPTCHA);
        }

        pageIds.unshift(pagesIds.MARKETING_CONSENT);

        return (
            <Page>
                <NavigationGroup
                    navigateToPage={navigateToPage}
                    pagesTitles={pagesTitles}
                    targetPagesIds={pageIds}
                />
            </Page>
        );
    };

export { id, title, view };
