// @ts-nocheck
import React from "react";
import cx from 'classnames';
import { connect } from 'react-redux';
import Measure from 'react-measure';
import { pure } from 'recompose';
import * as registry from '../../../oneweb/registry/index';
import { dispatchForwardToSelectedComponent } from '../../../../redux/forwardTo';
import injectIntl from '../../../intl/injectIntl';
import HorizontalSpacer from '../../../common/HorizontalSpacer';
import EditCTA from './editCtaView';
import Cta from './ctaView';
import NoMouseEventsPropagationContainer from './noMouseEventsPropagationContainer';
import { MCTA_RESIZED, MCTA_ON_MOUSE_ENTER, MCTA_ON_MOUSE_LEAVE } from "./actionTypes";
import { COMPONENT_EDIT } from '../../../../redux/modules/children/workspace/actionTypes';
import type { ComponentMainActionsConfig, ViewProps } from './flowTypes';
import ContextMenuIcon from '../../../../components/PropertiesPanel/view/ContextMenuIcon';
import { PinSettingsIcon } from '../../../../components/PropertiesPanel/view/pinSettingsIcon';
import styles from './ComponentMainActions.css';
import {
    selectedComponentPropsSelector,
    someSelectedComponentsPlacedInModernLayout,
    someSelectedComponentsPlacedInWebShopFooterStrip
} from '../../../../components/Workspace/selectors';
import CheckBoxWithLabel from "../../../common/CheckBox/CheckBoxWithLabel";
import * as workspaceActionTypes from "../../../../components/Workspace/actionTypes";
import ContextInfoIcon from "../../../../components/PropertiesPanel/view/ContextInfoIcon";
import { isVerticalMenu } from '../../../../components/oneweb/Menu/utils';
import { MctaVerticalBar } from "../../../../components/componentMainActions/index";
import { checkSubscriptionCompatibilityFromKind, isShopRelatedComponent } from "../../../../components/ComponentTierManager/utils";
import { UpgradeUserSubscriptionButton } from "../../../../components/ComponentTierManager/view/UpgradeUserSubscriptionButton";
import { isWsbDemo } from "../../../../debug/isWsbDemo";
import { makeEpicStateSelector } from "../../../../epics/makeEpic";
import checkFreeUpgradeValueActionType from '../../../../components/TopBar/epics/checkFreeUpgrade/valueActionType';
import { isModernLayoutSection } from "../../../../components/ModernLayouts/preview_utils";
import { TextComponentKind } from "../../../../components/oneweb/Text/kind";

class MCTAView extends React.Component<ViewProps> {
    render() {
        const
            { isMouseOverMCTA, componentMainActions, dispatch, intl, editModeComponentId: editMode,
                subscriptionData, checkFreeUpgrade, isComponentPlacedInModernLayout,
                isComponentPlacedInWebShopFooterStrip } = this.props,
            {
                dependencies,
                controlsDependenciesForSelectedComponent = {},
                selectedComponent,
                selectedComponentNotGhost,
                selectedComponentId,
                selectedComponentKind,
                mctaTop,
                mctaLeft,
                propertiesPanelPageId,
                selectedComponentIsInsideHeaderOrFooter,
                isModernLayoutActive,
                isFooterBlank,
                isHeaderBlank
            } = componentMainActions,
            { subscriptionType } = subscriptionData,
            { isFreeUpgrade } = checkFreeUpgrade;

        const componentMainActionsConfig: ComponentMainActionsConfig =
                registry.getComponentMainActionsConfig(selectedComponentKind),
            { editView, ctaButtons } = componentMainActionsConfig,
            View = editMode ? editView : EditCTA,
            className = !editMode ? styles.primaryToolBar : styles.secondaryToolBar,
            isNoSettingInEditMode = componentMainActionsConfig.isNoSettingInEditMode &&
                componentMainActionsConfig.isNoSettingInEditMode(),
            isCTAUrgent = componentMainActionsConfig.isCTAUrgent
                && componentMainActionsConfig.isCTAUrgent(selectedComponent, dependencies),
            viewProps = {
                selectedComponent,
                selectedComponentProps: this.props.selectedComponentProps,
                ...dependencies,
                ...controlsDependenciesForSelectedComponent,
                ...componentMainActionsConfig,
                isInsideHeaderOrFooter: selectedComponentIsInsideHeaderOrFooter,
                isModernLayoutActive,
                dispatch: dispatchForwardToSelectedComponent(dispatch),
                showPrimaryButton: selectedComponentKind === TextComponentKind &&
                    dependencies.generalPreferences &&
                    !dependencies.generalPreferences.hideWritingAssistantTooltip
            },
            { selectedComponentIsHeader, selectedComponentIsFooter } = controlsDependenciesForSelectedComponent || {},
            showPin = !!(selectedComponent && selectedComponent.pin) && !(selectedComponentIsHeader || selectedComponentIsFooter),
            // For WebshopRelatedComponent
            isSiatAllowedFromShopApi = isShopRelatedComponent(selectedComponentKind) &&
            dependencies?.webshopSubscription?.onlineShopSetupApiResponse?.isSIATCustomer,
            // Special handling for CODE component
            isComponentPremiumCompatible = checkSubscriptionCompatibilityFromKind(selectedComponentKind, subscriptionType) || isSiatAllowedFromShopApi,
            showOnlyInThisPageOption = (selectedComponentNotGhost && (selectedComponentIsInsideHeaderOrFooter
                || isVerticalMenu(selectedComponentNotGhost)
                    || selectedComponentNotGhost.isStickyToHeader) && !isComponentPlacedInModernLayout
                    && !isComponentPlacedInWebShopFooterStrip);

        const isModernSection = !!selectedComponent && isModernLayoutSection(selectedComponent);

        let mctaStyle = {
            visibility: typeof mctaTop === 'undefined' ? 'hidden' : '',
            top: mctaTop,
            // if the component is inside the page borders, don't let the buttons move outside the border so they stay visible
            left: mctaLeft >= 0 || selectedComponent.left < 0 ? mctaLeft : 0,
        };

        if (selectedComponent && selectedComponent.width && !isComponentPremiumCompatible) {
            // set the width of the button container, so the upgrade button can be shown on the right side
            mctaStyle = {
                ...mctaStyle,
                width: selectedComponent.width
            };
        }

        if (componentMainActionsConfig.hideMcta) {
            return null;
        }

        return (
            <div>
                <Measure
                    bounds
                    onResize={({ bounds: { width, height } }) => dispatch({
                        type: MCTA_RESIZED,
                        payload: {
                            width: Math.ceil(width),
                            height: Math.ceil(height)
                        }
                    })}
                >
                    {({ measureRef }) => (
                        <div
                            ref={measureRef}
                            style={mctaStyle}
                            className={styles.toolBarContainer}
                            onMouseEnter={() => dispatch({ type: MCTA_ON_MOUSE_ENTER })}
                            data-title-position="top"
                        >
                            <div
                                className={styles.toolBarMCTAContainer}
                            >
                                {
                                    ((View && !isCTAUrgent && (!editMode || !isNoSettingInEditMode || showOnlyInThisPageOption)) &&
                                        <div
                                            className={cx(className, {
                                                [styles.modernHFContainer]: isModernLayoutActive &&
                                                selectedComponentIsInsideHeaderOrFooter
                                            })}
                                            onClick={() => dispatch({ type: COMPONENT_EDIT })}
                                        >
                                            <View intl={intl} {...viewProps} editCta>
                                                {showOnlyInThisPageOption &&
                                                    <div className={styles.checkBox}>
                                                        {!isNoSettingInEditMode && <MctaVerticalBar />}
                                                        <HorizontalSpacer />
                                                        <CheckBoxWithLabel
                                                            label={'msg: common.mcta.showInThisPageOnly {Show only on this page}'} /* eslint-disable-line */
                                                            intl={intl}
                                                            isChecked={!selectedComponentNotGhost.inTemplate}
                                                            onClick={() =>
                                                                dispatch({ type: selectedComponentNotGhost.inTemplate
                                                                    ? workspaceActionTypes.MOVE_SELECTED_COMPONENTS_TO_PAGE
                                                                    : workspaceActionTypes.MOVE_SELECTED_COMPONENTS_TO_TEMPLATE })} /* eslint-disable-line */
                                                        />
                                                        <HorizontalSpacer x={5} />
                                                    </div>}
                                            </View>
                                        </div>
                                    )
                                }
                                {View && !isCTAUrgent && <HorizontalSpacer />}
                                {(componentMainActionsConfig.ctaButtonText || componentMainActionsConfig.CtaButtonTextView) // eslint-disable-line max-len
                                && (!componentMainActionsConfig.isCtaVisible || componentMainActionsConfig.isCtaVisible({
                                    ...dependencies,
                                    ...controlsDependenciesForSelectedComponent,
                                    selectedComponent,
                                    propertiesPanelPageId,
                                    isComponentPremiumCompatible
                                }))
                                && ([
                                    <NoMouseEventsPropagationContainer key={1}>
                                        <Cta intl={intl} urgent={isCTAUrgent} {...viewProps} />
                                    </NoMouseEventsPropagationContainer>,
                                    <HorizontalSpacer key={2} />
                                ])}
                                {
                                    ctaButtons && ctaButtons.map((button, index) => {
                                        const { isVisible, view, onClick, btnClass = '' } = button;
                                        if (isVisible && !isVisible({ editMode })) { return null; }
                                        return (
                                            <React.Fragment key={index}>
                                                <NoMouseEventsPropagationContainer>
                                                    <Cta
                                                        {...button}
                                                        intl={intl}
                                                        urgent={isCTAUrgent}
                                                        {...viewProps}
                                                        CtaButtonTextView={view}
                                                        ctaOnClick={onClick}
                                                        ctaButtonClass={btnClass}
                                                    />
                                                </NoMouseEventsPropagationContainer>
                                                <HorizontalSpacer key={index + ctaButtons.length} />
                                            </React.Fragment>
                                        );
                                    })
                                }
                                { !(isComponentPlacedInModernLayout || isModernSection
                                    || isComponentPlacedInWebShopFooterStrip) && <ContextMenuIcon dispatch={dispatch} MCTA /> }
                                {(selectedComponentIsHeader || selectedComponentIsFooter) && [<HorizontalSpacer key={1} />,
                                    <ContextInfoIcon
                                        key={2}
                                        dispatch={dispatch}
                                        MCTA
                                        isHeaderSection={selectedComponentIsHeader}
                                        isFooterSection={selectedComponentIsFooter}
                                        isFooterBlank={isFooterBlank}
                                        isHeaderBlank={isHeaderBlank}
                                    />]}
                                {
                                    showPin && [
                                        <HorizontalSpacer key={1} />,
                                        <PinSettingsIcon dispatch={dispatch} key={2} componentId={selectedComponentId} />
                                    ]
                                }
                                {
                                    !isComponentPremiumCompatible && !isWsbDemo() &&
                                    <React.Fragment>
                                        <HorizontalSpacer key={1} />
                                        <UpgradeUserSubscriptionButton
                                            dispatch={dispatch}
                                            subscriptionData={subscriptionData}
                                            isFreeUpgrade={isFreeUpgrade}
                                            componentKind={selectedComponentKind}
                                            source="componentToolbar:upgradeBtn"
                                        />
                                    </React.Fragment>
                                }
                            </div>
                        </div>
                    )}
                </Measure>
                {
                    isMouseOverMCTA &&
                    <div
                        className={styles.toolBarCover}
                        onMouseOver={() => dispatch({ type: MCTA_ON_MOUSE_LEAVE, payload: selectedComponentKind })}
                    />
                }
            </div>
        );
    }
}

const checkFreeUpgradeStateSelector = makeEpicStateSelector(checkFreeUpgradeValueActionType);

export default pure(injectIntl(connect(appState => ({
    selectedComponentProps: selectedComponentPropsSelector(appState),
    subscriptionData: appState.subscriptionData,
    checkFreeUpgrade: checkFreeUpgradeStateSelector(appState),
    isComponentPlacedInModernLayout: someSelectedComponentsPlacedInModernLayout(appState),
    isComponentPlacedInWebShopFooterStrip: someSelectedComponentsPlacedInWebShopFooterStrip(appState),
}))(MCTAView)));
