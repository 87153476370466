import cx from "classnames";
import React from "react";
import styles from "./Blog.css";
import { globalStyleTextClass, types } from "../../../../Globalstyles/Text/constants";
import dynamicMessages from "../../../../../../l10n/messages/dynamicMessages.json";
import { getLocaleCookie } from "../../../../Locale/cookie";

const ERROR_MSG_KEY = "component.blog.preview.error.description";

export const BlogPreviewErrorMessage = ({
    className,
    ...otherProps
}: React.HTMLAttributes<HTMLDivElement>) => {
    // TODO: replace with editor locale, once it is available during publishing
    const locale = getLocaleCookie();
    let errorMessage = "";

    if (dynamicMessages[ERROR_MSG_KEY]) {
        if (dynamicMessages[ERROR_MSG_KEY][locale]) {
            errorMessage = dynamicMessages[ERROR_MSG_KEY][locale];
        } else {
            // default to english text if the translated value is not available
            errorMessage = dynamicMessages[ERROR_MSG_KEY]["en"];
        }
    }

    return (
        <div
            {...otherProps}
            className={cx(
                styles.errorMessageContainer,
                className,
                globalStyleTextClass[types.NORMAL]
            )}
        >
            {errorMessage}
        </div>
    );
};
