export const BLOG_AI_INPUT_UPDATE = "BLOG_AI_INPUT_UPDATE";
export const BLOG_LAYOUT_UPDATE = "BLOG_LAYOUT_UPDATE";
export const BLOG_ONBOARDING_OPTION_UPDATE = "BLOG_ONBOARDING_OPTION_UPDATE";

export const CREATE_BLOG_API_CALL = "CREATE_BLOG_API_CALL";
export const GET_BLOG_DETAILS_REQUEST = "GET_BLOG_DETAILS_REQUEST";
export const GET_BLOG_DETAILS_SUCCESS = "GET_BLOG_DETAILS_SUCCESS";
export const GET_BLOG_DETAILS_FAILURE = "GET_BLOG_DETAILS_FAILURE";

export const BLOG_WIZARD_OPEN = "BLOG_WIZARD_OPEN";
export const CHECK_FOR_EXISTING_POSTS = "CHECK_FOR_EXISTING_POSTS";
export const BLOG_WIZARD_RESET_AND_CLOSE = "BLOG_WIZARD_RESET_AND_CLOSE";
export const BLOG_WIZARD_COMPLETED = "BLOG_WIZARD_COMPLETED";
export const BLOG_ERROR_RESET = "BLOG_ERROR_RESET";
export const BLOG_UPGRADE_CLICKED = "BLOG_UPGRADE_CLICKED";
