import typeCheck from "../../utils/typeCheck";

type SingleResult = Action | Promise<any>
type JoinDisaptchResults = (...args: Array<Array<SingleResult> | SingleResult>) => Array<SingleResult>
export const joinDisaptchResults: JoinDisaptchResults = function () {
    const results: any = [];

    const args = Array.from(arguments);
    for (const arg of args) {
        if (typeCheck(arg).isArray()) {
            arg.forEach(result => results.push(result));
        } else {
            results.push(arg);
        }
    }

    return results;
};
