/* global $ */

import React from 'react';
import ReactDOMServer from "react-dom/server"; // eslint-disable-line n/file-extension-in-import
import cx from 'classnames';
import { IntlProvider, IntlContext } from "react-intl";
import { SortableContainer, SortableElement, arrayMove } from 'react-sortable-hoc';
import * as styles from "./MobileViewEditor.css";
import registry from '../../../view/oneweb/registry/index';
import Strip from '../../oneweb/Strip/kind';
import Text from '../../oneweb/Text/kind';
import ImageSlider from '../../oneweb/ImageSlider/kind';
import Menu from '../../oneweb/Menu/kind';
import Facebook from '../../oneweb/Facebook/kind';
import { getAllStylesheets } from "../../Workspace/epics/stylesheets/selectors";
import { getGlobalstyleClassNameFromStyle } from "../../RenderGlobalstyles/getGlobalstyleClassName";
import * as Actions from "../actionTypes";
import type { EditorProps, Sequence, SortEndProps, GetCmpWrapperClassNameProps } from "../flowTypes";
import {
    CmpsKindsWrappedAndNeedsMoreSpace,
    CmpsKindsWrappedView,
    CmpsBorderGetSpecificStyles,
    cmpTypes,
    marginLeftRightDraggableItem,
} from "../constants";
import { getDAL } from "../../../../dal/index";
import getId from "../getId";
import getCmpTypeById from "../getCmpTypeById";
import isStretchComponentKind from "../../oneweb/isStretchComponentKind";
import getMobileViewAlignmentClass from "../../Preview/View/getMobileViewAlignmentClass";
import { getGroupTypeByData, getMobileContentAreaWidth } from "../util";
import GroupViews from './Groups/view/index';
import { isHeaderOrFooterSection } from "../../oneweb/Section/utils";
import { isBoxKind, SECTION } from "../../oneweb/componentKinds";
import {
    checkSubscriptionCompatibilityFromKind,
    getComponentTierDataFromComponentKind,
    isShopRelatedComponent
} from "../../ComponentTierManager/utils";
import { PremiumFeatureComponentMaskLabel } from "../../ComponentTierManager/view/PremiumFeatureComponentMaskLabel";
import InstagramGalleryKind from "../../oneweb/InstagramGallery/kind";

const getGlobalStyleClassName = ({ style }, stylesheets) => {
    const
        styles = getAllStylesheets(stylesheets),
        stylesheet = (style && style.globalId) ? (styles.filter(styleObj => styleObj.id === style.globalId)[0]) : null;

    return stylesheet ? getGlobalstyleClassNameFromStyle(stylesheet) : '';
};
const getCmpWrapperClassName = ({
    cmpId,
    cmp,
    kind,
    mouseOverCmpId,
    selectedCmpId,
    hasChildren,
    topLevel,
    arrowHover,
    wrappedComponents,
    mouseOverHiddenCmpId,
    settings = {}
}: GetCmpWrapperClassNameProps): string => {
    let className = (
        hasChildren ||
        (isStretchComponentKind(kind, !!(cmp && cmp.stretch))) ||
        isBoxKind(kind)
    ) ? styles.block : styles.component;
    if (kind === Text && wrappedComponents && wrappedComponents.length) {
        const needsMoreSpace = wrappedComponents.some(({ kind }) => CmpsKindsWrappedAndNeedsMoreSpace[kind]);
        if (needsMoreSpace) {
            className += ` ${styles.wrapNeedsMoreSpace}`;
        }
    }
    if (kind === cmpTypes.group) {
        const groupIdParts = cmpId.split('-');
        className += ` ${groupIdParts[0]}-${groupIdParts[1]}`;
        let align = (settings[cmpId] || {}).align;
        if (align) {
            className += ` align-${align}`;
        }
    }
    if (selectedCmpId === cmpId) {
        className += ` ${styles.selected}`;
        if (arrowHover) {
            className += ` ${styles.arrowHover}`;
        }
    } else if (mouseOverCmpId === cmpId) {
        className += ` ${styles.outline}`;
    } else if (mouseOverHiddenCmpId === cmpId) {
        className += ` ${styles.hiddenCmpHover} ${styles.arrowHover}`;
    }
    if (topLevel) {
        className += ` topLevel`;
    }
    className += ` ${getMobileViewAlignmentClass(cmp)}`;

    return className;
};

const SortableCmp = SortableElement(({ cmpStyles, id, children, topLevel, index1, kind, stretch = false }: any) => {
    let className = styles.draggableItem + ` mobileV`;
    let style = (cmpStyles[id] || {});
    if (!topLevel && index1 > 0) {
        className += ` deepLiGt1`;
    }
    if (topLevel && !isStretchComponentKind(kind, stretch)) {
        style = { ...style, ...marginLeftRightDraggableItem };
    }
    return (<li className={className} style={style}>{children}</li>);
});

const getCmpProps = (props) => {
    const
        {
            item: component,
            item: { id, kind },
            data,
            dispatch,
            wrappedCmpsMap,
            componentsMap,
            siteMap,
            template,
            stylesheetsIdToNameMap,
            cmpWidths,
            globalStyles,
            componentsDependencies,
            rootContainerRect,
            isReordered,
            isMVEEditMode,
            selectedCmpBgPosition,
            actualId,
            settings,
            mobileEltExtension,
            selectedCmpId,
            componentExtensions,
            siteSettings,
            editorContainerWidth,
            parentThemeMap
        } = props,

        { calcProps } = registry[kind].mobileEditorConfig as Record<string, any>;
    let componentProps: Record<string, any> = {
        selectedCmpId,
        dispatch,
        ...componentsDependencies[kind],
        componentDependencies: componentsDependencies[kind],
        componentsDependencies,
        siteMap,
        settings,
        mobileEltExtension,
        componentExtensions,
        isServerPreview: false,
        template,
        isWorkspace: false,
        domain: getDAL().getDomain(),
        component,
        siteSettings,
        globalStyles: globalStyles.stylesheets,
        globalStyleClass: getGlobalStyleClassName(component, globalStyles.stylesheets),
        stylesheetsIdToNameMap,
        rootContainerRect,
        renderedWidth: cmpWidths[actualId],
        isMVEFocus: true,
        isMVEEditMode,
        selectedCmpBgPosition,
        selectedParentTheme: parentThemeMap[id]
    };
    if (kind === Text && !component.wrap) {
        componentProps.wrappedComponents = wrappedCmpsMap[id].map(wId => componentsMap[wId]);

        componentProps.renderedWrappedComponentContentsMap = componentProps.wrappedComponents.reduce(
            (acc, wrappedComponent) => {
                acc[wrappedComponent.id] = ReactDOMServer.renderToStaticMarkup(
                    renderWrappedComponent(// eslint-disable-line
                        props, wrappedComponent, data, componentsMap
                    )
                );
                return acc;
            }, {}
        );
    }
    const hasChildren = !!(data[actualId] && data[actualId].length),
        getComponent = (component, renderedWidth) => {
            if (component.kind === ImageSlider) {
                const width = component.stretch ? renderedWidth
                    : Math.min(getMobileContentAreaWidth(editorContainerWidth), component.width, renderedWidth);
                let newCmp = {
                    ...component,
                    width,
                    height: component.stretch ? component.height * (width / template.width)
                        : (width * (component.height / component.width))
                };
                if (component.wrap) {
                    return {
                        ...newCmp,
                        images: [newCmp.images[0]]
                    };
                }
                return newCmp;
            }
            return component;
        };

    if (calcProps) {
        const updatedComponent = getComponent(component, cmpWidths[actualId]);
        componentProps = {
            ...componentProps,
            component: updatedComponent,
            ...calcProps({
                componentId: component.id,
                ...componentProps,
                component: updatedComponent,
                hasChildren,
                renderedWidth: cmpWidths[id],
                componentExtension: { shouldComponentUpdate: false },
                componentDependencies: componentsDependencies[kind],
                isReordered,

                shortcut: registry[kind].shortcut,
                componentsMap
            })
        };
    }
    // if strip is empty set height to 0
    if (isStretchComponentKind(kind) && !(data[id] && data[id].length)) {
        componentProps = { ...componentProps, height: 0 };
    }
    return componentProps;
};

const renderWrappedComponent = (props, wrappedCmp, data, componentsMap) => { // eslint-disable-line no-use-before-define
    const
        { id, kind } = wrappedCmp,
        showCmp = kind !== ImageSlider || !!props.cmpWidths[id],
        componentProps = getCmpProps({ ...props, actualId: id, item: wrappedCmp }),
        hasChildren = !!(data[id] && data[id].length),
        { locale, messages } = props.context.intl;

    let ComponentView = registry[kind].mobileEditorConfig?.view;
    if (CmpsKindsWrappedView[kind]) {
        ComponentView = registry[kind].shortcut;
        componentProps.panelExpanded = true;
    }
    if (hasChildren) {
        componentProps.children = <div>
            {data[id].map(wid => renderWrappedComponent(props, componentsMap[getId(wid)], data, componentsMap))}
        </div>;
    }
    return (
        <IntlProvider key={id} locale={locale} messages={messages}>
            <div
                id={id}
                data-id={id}
                data-specific-kind={kind}
                className={
                    getCmpWrapperClassName({ cmpId: id, cmp: componentsMap[id], kind, hasChildren })
                }
            >
                {showCmp && <ComponentView {...componentProps} />}
            </div>
        </IntlProvider>
    );
};

const SortableChild = SortableElement((props: any) => {
    const
        {
            data,
            index1,
            styles: cmpStyles,
            topLevel,
            cmpWidths,
            item: { id, kind, stretch = false },
            item,
            actualId,
            componentsMap,
            mouseOverCmpId,
            selectedCmpId,
            onSortStart1,
            onSortEnd1,
            arrowHover,
            mouseOverHiddenCmpId,
            disableSort,
            subscriptionType
        } = props,
        componentProps = getCmpProps(props),
        { componentTierType } = getComponentTierDataFromComponentKind(componentProps.component.kind),
        checkIsCompatibleForShop = () => {
            return isShopRelatedComponent(kind) && componentProps.webshopSubscription?.onlineShopSetupApiResponse?.isSIATCustomer;
        },
        { view: ComponentView } = registry[kind].mobileEditorConfig as Record<string, any>,
        hasChildren = !!(data[actualId] && data[actualId].length),
        showCmp = (kind !== ImageSlider && kind !== Facebook) || !!cmpWidths[actualId],
        isCompatible = checkSubscriptionCompatibilityFromKind(kind, subscriptionType) || checkIsCompatibleForShop(),
        isComponentUnselectedAndTierIncompatible = (id !== selectedCmpId) && !isCompatible;

    if (hasChildren) {
        const containerProps = {
            ...props,
            topLevel: false,
            root: actualId || id,
            onSortStart: onSortStart1,
            onSortEnd: onSortEnd1,
            lockAxis: "y",
            lockToContainerEdges: true
        };
        // eslint-disable-next-line
        componentProps.children = <SortableParent {...containerProps} disabled={disableSort || isHeaderOrFooterSection(item)} />;
    }
    return (
        // @ts-ignore
        <SortableCmp
            index={index1}
            id={actualId}
            cmpStyles={cmpStyles}
            index1={index1}
            topLevel={topLevel}
            kind={kind}
            stretch={{ stretch }}
            disabled={disableSort || isHeaderOrFooterSection(item)}
        >
            <div
                key={actualId}
                id={actualId}
                data-id={actualId}
                data-stretch={stretch}
                data-specific-kind={kind}
                className={
                    getCmpWrapperClassName({
                        cmpId: actualId,
                        cmp: componentsMap[actualId],
                        kind,
                        mouseOverCmpId,
                        selectedCmpId,
                        hasChildren,
                        topLevel,
                        arrowHover,
                        wrappedComponents: componentProps.wrappedComponents,
                        mouseOverHiddenCmpId
                    })
                }
            >
                {showCmp && <ComponentView {...componentProps} />}
                <div className={cx(styles.mask, {
                    [styles.componentTierIncompatible]: isComponentUnselectedAndTierIncompatible,
                    [styles.stretch]: stretch
                })}
                >
                    {isComponentUnselectedAndTierIncompatible &&
                        // @ts-ignore
                        <PremiumFeatureComponentMaskLabel
                            width={componentProps.component.width}
                            componentTierType={componentTierType}
                        />}
                </div>
            </div>
        </SortableCmp>
    );
});

const setDropZoneClassName = (cmpId: string, isAddClassName: boolean) => {
    let containerEl = $('div[data-id="' + cmpId + '"]').parents('[data-id]:first');
    if (!containerEl.length) {
        containerEl = $(`.${styles.editorContainer}:last`);
    }
    if (isAddClassName) {
        containerEl.addClass(`${styles.dropZoneArea}`);
    } else {
        containerEl.removeClass(`${styles.dropZoneArea}`);
    }
};

const SortableGroup = SortableElement((props: any) => {
    const
        {
            index1,
            styles: cmpStyles,
            groups,
            componentsMap,
            topLevel,
            actualId,
            mouseOverCmpId,
            selectedCmpId,
            arrowHover,
            mouseOverHiddenCmpId,
            disableSort,
            settings
        } = props;
    const group = groups[actualId],
        groupType = getGroupTypeByData(group.map(id => componentsMap[id])),
        GroupView = GroupViews[groupType],
        groupProps = group.map(id => {
            let componentProps = getCmpProps({ ...props, item: componentsMap[id] });
            const
                { kind } = componentsMap[id],

                { extendCmpPropsForGroupItem } = registry[kind].mobileEditorConfig as Record<string, any>;
            if (extendCmpPropsForGroupItem) {
                componentProps = extendCmpPropsForGroupItem({
                    componentProps,
                    settings,
                    actualId,
                    componentsMap,
                    groupType,
                    group: group.map(id => componentsMap[id])
                });
            }

            return { ...componentProps, groupId: actualId, groupType };
        });

    return (
        // @ts-ignore
        <SortableCmp
            disabled={disableSort}
            index={index1}
            id={actualId}
            cmpStyles={cmpStyles}
            index1={index1}
            topLevel={topLevel}
        >
            <div
                key={actualId}
                id={actualId}
                data-id={actualId}
                data-specific-kind={cmpTypes.group}
                className={
                    getCmpWrapperClassName({
                        cmpId: actualId,
                        cmp: componentsMap[actualId],
                        kind: cmpTypes.group,
                        mouseOverCmpId,
                        selectedCmpId,
                        hasChildren: false,
                        topLevel,
                        arrowHover,
                        mouseOverHiddenCmpId,
                        settings
                    })
                }
            >
                <div className={styles.group}>
                    <GroupView groupProps={groupProps} />
                </div>
                <div className={styles.mask} />
            </div>
        </SortableCmp>
    );
});

const SortableParent = SortableContainer((props: any) => {
    const
        { data, root, componentsMap, disableSort } = props,
        children = data[root];

    if (!children || !children.length) {
        return null as any;
    }

    return (
        <ul className={styles.draggableContainer}>
            {children ? children.map((item, i) => {
                const cmpId = children[i];
                let baseProps = {
                    ...props,
                    actualId: cmpId,
                    collection: "" + root,
                    key: `item-${cmpId}`,
                    index: i,
                    index1: i,
                    lockAxis: "y",
                    lockToContainerEdges: true
                };
                if (getCmpTypeById(cmpId) === cmpTypes.group) {
                    return <SortableGroup disabled={disableSort} {...baseProps} />;
                }
                const
                    component = componentsMap[getId(cmpId)],
                    { kind } = component;

                if (kind === Menu) {
                    return null;
                }
                const childProps = {
                    ...baseProps,
                    item: component
                };
                return <SortableChild {...childProps} disabled={disableSort || isHeaderOrFooterSection(component)} />;
            }) : null}
        </ul>
    );
});

const outerWidthCmpsKindsMap = {
    [Strip]: true,
    [SECTION]: true
};

const getRenderedCmpWidth = (elt, kind, stretch = false) => {
    if (outerWidthCmpsKindsMap.hasOwnProperty(kind) || stretch === true) {
        return Math.round(elt.outerWidth());
    }

    return Math.round(elt.outerWidth()) - (elt.hasClass(styles.component) ? 36 : 0);
};

class Editor extends React.Component<EditorProps, void> {
    onSortEnd: Function;
    recomputeSpecificStylesTimeoutId: ReturnType<typeof setTimeout> | null;

    constructor(props: EditorProps) {
        super(props);
        this.onSortEnd = (values) => {
            this.onSortEnd2(values, this.props.data);
        };
        this.recomputeSpecificStylesTimeoutId = null;
    }

    getSpecificStyles() {
        const
            {
                dispatch,
                componentsMap,
                mobileData: { data },
                mobileView: { cmpWidths },
                editorContainerWidth
            } = this.props,
            componentsSpecificStyles = Object.keys(componentsMap)
                .filter((componentId) => {
                    const { kind } = componentsMap[componentId];
                    return CmpsBorderGetSpecificStyles.hasOwnProperty(kind) || kind === InstagramGalleryKind;
                })
                .reduce((accStyles, componentId) => {
                    const
                        component = componentsMap[componentId],
                        { mobileEditorConfig } = registry[component.kind];

                    return accStyles + (mobileEditorConfig && mobileEditorConfig.getSpecificStyles
                        ? mobileEditorConfig.getSpecificStyles({
                            component,
                            widthInMobile: cmpWidths[component.id] || editorContainerWidth,
                            hasChildren: (data[componentId] && data[componentId].length),
                            globalStyles: this.props.globalStyles.stylesheets // should be assigned as globalStyles as generateHtml sends it by that name. Suggested by Sathish.
                        }) : '');
                }, '');
        if (componentsSpecificStyles) {
            dispatch({
                type: Actions.MOBILE_EDITOR_COMPONENTS_SPECIFIC_STYLES_ON_LOAD,
                payload: componentsSpecificStyles
            });
        }
        return componentsSpecificStyles;
    }

    updateComponentWidth() {
        const { dispatch, componentsMap } = this.props,
            cmpClass = '.' + styles.content + ' li.mobileV > div, .mceNonEditable .' +
                styles.component + ', .mceNonEditable .' + styles.block;

        let componentWrappers = $(cmpClass),
            cmpWidths = {};
        componentWrappers.each(function (index, elt) {
            const container = $(elt),
                id = $(elt).attr('data-id'),
                cmp = componentsMap[getId(id)],
                kind = id && (getCmpTypeById(id) === cmpTypes.group ? cmpTypes.group :
                    (cmp && cmp.kind));
            if (kind) {
                cmpWidths[id] = getRenderedCmpWidth(
                    container,
                    kind,
                    !!(cmp && cmp.stretch)
                );
            }
        });
        dispatch({ type: Actions.MOBILE_EDITOR_COMPONENT_WIDTHS_ON_LOAD, payload: cmpWidths });
    }

    recomputeSpecificStyles = () => {
        if (!this.recomputeSpecificStylesTimeoutId) {
            this.recomputeSpecificStylesTimeoutId = setTimeout(() => {
                this.updateComponentWidth();
                this.getSpecificStyles();
                this.recomputeSpecificStylesTimeoutId = null;
            }, 0);
        }
    };

    componentDidMount() {
        this.recomputeSpecificStyles();
        const rootContainerElem = $('.' + styles.content)[0];
        if (rootContainerElem) {
            this.props.dispatch({
                type: Actions.MOBILE_EDITOR_ROOT_CONTAINER_RECT,
                payload: rootContainerElem.getBoundingClientRect()
            });
        }
    }

    componentDidUpdate(prevProps: EditorProps) {
        if (prevProps.componentsMap !== this.props.componentsMap) {
            this.recomputeSpecificStyles();
        }

        if (!prevProps.mobileView.reload && this.props.mobileView.reload) {
            this.updateComponentWidth();
        }
    }

    onSortStart = () => {
        const { dispatch, hoveringAndSelection: { mouseOverCmpId }, data } = this.props;
        if (mouseOverCmpId) {
            dispatch({
                type: Actions.MOBILE_EDITOR_COMPONENT_DRAGGING_STARTED,
                payload: { data, contentScrollTop: $('.' + styles.content)[0].scrollTop }
            });
            setDropZoneClassName(mouseOverCmpId, true);
        }
    };

    onSortEnd2({ collection, oldIndex, newIndex }: SortEndProps, data: Sequence) {
        const { dispatch, hoveringAndSelection: { selectedCmpId } } = this.props;
        if (selectedCmpId) {
            setDropZoneClassName(selectedCmpId, false);
        }

        if (oldIndex !== newIndex) {
            let list = [...data[collection]];

            list = arrayMove(list, oldIndex, newIndex);

            dispatch({
                type: Actions.MOBILE_EDITOR_COMPONENT_MOVED,
                payload: { parentId: collection, children: list, movedCmpIndex: newIndex }
            });
        }
        dispatch({ type: Actions.MOBILE_EDITOR_COMPONENT_DRAGGING_ENDED });
    }

    render() {
        const
            {
                mobileView: {
                    wrappedCmpsMap,
                    styles,
                    root,
                    cmpWidths,
                    settings,
                    isReordered,
                    groupsForView: groups,
                    mobileEltExtension
                },

                hoveringAndSelection: {
                    mouseOverCmpId,
                    selectedCmpId,
                    arrowHover,
                    isDragging,
                    rootContainerRect,
                    isMVEEditMode,
                    selectedCmpBgPosition,
                },
                hiddenComponents: {
                    mouseOverHiddenCmpId
                },
                componentExtensions,
                data,
                componentsMap,
                siteMap,
                template,
                globalStyles,
                stylesheetsIdToNameMap,
                componentsDependencies,
                dispatch,
                siteSettings,
                disableSort,
                editorContainerWidth,
                parentThemeMap,
                subscriptionData: {
                    subscriptionType
                }
            } = this.props;

        let props = {
            data,
            wrappedCmpsMap,
            groups,
            template,
            styles,
            componentsMap,
            globalStyles,
            mouseOverCmpId,
            selectedCmpId,
            siteSettings,
            stylesheetsIdToNameMap,
            componentsDependencies,
            siteMap,
            cmpWidths,
            settings,
            componentExtensions,
            mobileEltExtension,
            root,
            topLevel: true,
            lockAxis: "y",
            lockToContainerEdges: true,
            onSortStart: this.onSortStart,
            onSortStart1: this.onSortStart,
            onSortEnd: this.onSortEnd,
            onSortEnd1: this.onSortEnd,
            dispatch,
            arrowHover,
            isDragging,
            rootContainerRect,
            isReordered,
            isMVEEditMode,
            selectedCmpBgPosition,
            mouseOverHiddenCmpId,
            disableSort,
            editorContainerWidth,
            parentThemeMap,
            subscriptionType
        };

        if (!editorContainerWidth || !Object.keys(componentsMap).length) return null;

        return (
            <IntlContext.Consumer>{
                ({ locale, messages }) => {
                    const finalProps = {
                        ...props,
                        context: { intl: { locale, messages } },
                    };

                    return (
                        <SortableParent disabled={disableSort} {...finalProps} />
                    );
                }
            }</IntlContext.Consumer>
        );
    }
}

export default Editor;
