/* eslint-disable max-len */

import React from 'react';
import cx from 'classnames';

import { SubscriptionType } from "../../../../../server/shared/accessManager/constants.js";

import { Box, Flex } from "../../../view/reflexbox";
import { injectIntl, Msg } from '../../../view/intl';
import { UpgradeButton } from '../../../view/common/Button';
import HorizontalSpacer from '../../../view/common/HorizontalSpacer';
import VerticalSpacer from '../../../view/common/VerticalSpacer';
import getCenteredDialogConfig from '../../DialogManager/getCenteredDialogConfig';
import { Dialog, DialogBody, DialogFooterBare } from '../../../view/common/dialogs/baseDialog';
import subscriptionDataEpic from '../../App/epics/subscriptionData';
import { getPremiumPackageNameFromMetadata } from '../../ComponentTierManager/utils';
import styles from '../view/Backup.css';
import OpenUpgradePlanDialog from "../../TopBar/view/Upgrade/UpgradePlansDialog/OpenUpgradePlanDialog";
import { closeDialog } from '../../App/actionCreators';
import { upgradeButtonClickedAction, upgradeCanceledAction, upgradeDialogOpenedAction } from '../../../../../src/dashboard/src/components/Main/UpgradePlansDialog/actions';
import { FEATURE_BACKUP_RESTORE } from '../../TopBar/view/Upgrade/tracking/contants';
import { UpgradeCancelReason } from '../../EventTracking/constants';
import { UpgradePlansDialogId } from '../../TopBar/view/dialogIds';

const
    dialogId = 'BackupsUpgradeDialog',
    width = 490,
    height = 435,
    BackupUpgradeDialog = injectIntl(({ intl, dispatch, subscriptionData }) => {
        const { metadata: subscriptionMetadata, availableUpgrades } = subscriptionData;
        const premiumPackageNameFromMetadata = getPremiumPackageNameFromMetadata(subscriptionMetadata);
        const upgradeData = availableUpgrades?.find((upgrade) => (
            upgrade.addonProductToUpgrade === SubscriptionType.PREMIUM
        ));
        const isFreeUpgrade = upgradeData?.freeUpgradeAvailable;

        return (
            <Dialog
                onClose={() => {
                    dispatch(closeDialog());
                    dispatch(upgradeCanceledAction(UpgradeCancelReason.UserCancelled));
                }}
            >
                <DialogBody>
                    <VerticalSpacer y={38} />
                    <Box>
                        <Flex align="center" justify="center">
                            <Msg
                                className={cx(styles.dialogTitle, styles.bold)}
                                k="component.backup.upgrade.dialog.title"
                                params={{ packageName: premiumPackageNameFromMetadata || intl.msgJoint('msg: common.premium {Premium}') }}
                            >{`
                                {packageName} feature
                            `}</Msg>
                        </Flex>
                        <VerticalSpacer y={32} />
                        <Flex align="center" justify="center">
                            <div className={styles.backupIcon} />
                        </Flex>
                        <VerticalSpacer y={31} />
                        <Flex>
                            <HorizontalSpacer x={58} />
                            <Box>
                                <Msg
                                    className={styles.bold}
                                    k="component.backup.upgrade.dialog.message"
                                    params={{ packageName: premiumPackageNameFromMetadata || intl.msgJoint('msg: common.premium {Premium}') }}
                                >{`
                                    Upgrade to {packageName} today to unlock backups and access other great features.
                                `}</Msg>
                                <VerticalSpacer y={17} />
                                <Flex>
                                    <div className={cx(styles.listItem, styles.listItemUpgrade)} />
                                    <HorizontalSpacer x={9} />
                                    <Msg k="component.backup.upgrade.dialog.benefits.backup">Backups every time you save</Msg>
                                </Flex>
                                <VerticalSpacer y={7} />
                                <Flex>
                                    <div className={cx(styles.listItem, styles.listItemUpgrade)} />
                                    <HorizontalSpacer x={9} />
                                    <Msg k="component.backup.upgrade.dialog.benefits.preview">Preview backups</Msg>
                                </Flex>
                                <VerticalSpacer y={7} />
                                <Flex>
                                    <div className={cx(styles.listItem, styles.listItemUpgrade)} />
                                    <HorizontalSpacer x={9} />
                                    <Msg k="component.backup.upgrade.dialog.benefits.restore">Restore your website from up to 200 saved versions</Msg>
                                </Flex>
                                <VerticalSpacer y={12} />
                                <Msg
                                    className={styles.displayBlock}
                                    k="component.backup.upgrade.dialog.benefits.backupAndRestore"
                                    params={{ packageName: premiumPackageNameFromMetadata || intl.msgJoint('msg: common.premium {Premium}') }}
                                >{`
                                    Backup & Restore is included in Website Builder {packageName}
                                `}</Msg>
                                <a
                                    target="_blank"
                                    className={styles.learnMore}
                                    href={intl.msgJoint("msg: help.links.upgradeLearnMoreLink {https://help.one.com/hc/en-us/articles/115005592665}")}
                                >
                                    <Msg k="component.backup.upgrade.dialog.learnMore">Learn more</Msg>
                                </a>
                            </Box>
                        </Flex>
                    </Box>
                </DialogBody>
                <DialogFooterBare className={styles.flexJustifyCenter}>
                    <Flex align="center" justify="center">
                        <UpgradeButton
                            onClick={() => {
                                dispatch(closeDialog());
                                dispatch(OpenUpgradePlanDialog(subscriptionData, 'RestorePage:LeftPanelLearnMoreDialog'));
                                dispatch(upgradeButtonClickedAction({
                                    feature: FEATURE_BACKUP_RESTORE,
                                    freeUpgrade: isFreeUpgrade,
                                    // hardcoded value since premium is expected
                                    // as the upgrade type for backup & restore feature.
                                    upgradeType: SubscriptionType.PREMIUM
                                }));
                                dispatch(upgradeDialogOpenedAction({
                                    feature: FEATURE_BACKUP_RESTORE,
                                    dialogId: UpgradePlansDialogId
                                }));
                            }}
                        >
                            <Msg k="common.upgrade">Upgrade</Msg>
                        </UpgradeButton>
                    </Flex>
                </DialogFooterBare>
            </Dialog>
        );
    }),
    dialog = {
        ...getCenteredDialogConfig(width, height, BackupUpgradeDialog),
        updateConfFn: (config, dimensions, props) => ({ ...config, props }),
        dependsOn: {
            subscriptionData: subscriptionDataEpic.reducer,
        }
    };

export { dialogId, dialog };
